import React from 'react'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'

const activeStyle = {
  color: 'rgb(9, 38, 213)',
}

const ActiveLink = ({ children, to, end = false, disableActive = false, ...props }) => {
  const [isHovering, setIsHovering] = React.useState(false)
  const resolved = useResolvedPath(to)
  const doesMatch = useMatch({ path: resolved.pathname, end })

  return (
    <Link
      style={disableActive ? null : doesMatch || isHovering ? activeStyle : null}
      to={to}
      {...props}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {children}
    </Link>
  )
}

export default ActiveLink

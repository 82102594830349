import { useState, useRef, React } from 'react'
import { Formik, Field, Form } from 'formik'
import { useTeamLeaderContext } from '../../../../context/teamLeaderContext'
import Modal from '../../../../../../components/Modal'
import useGenerateMultipleObjectives from '../../../../../../api/mutation/useGenerateMultipleObjectives'
import { colors } from '../../../../../../utils/colors'
import * as Yup from 'yup'
import ModalSaveButton, {
  ModalSaveButtonFitted,
} from '../../../../../../components/ModalSubmitButton'
import InPageLoader from '../../../../../../components/InPageLoader'
import useAddMultipleObjectivesUsingAudio from '../../../../../../api/mutation/addMultipleObjectivesUsingAudio'
import AudioRecorder from '../../../../../../components/AudioRecorder'
import { useTranslation } from 'react-i18next'
import { getCompanyConfigSettings } from '../../../../../../utils/auth'

const AddMultipleObjectivesModal = () => {
  const { t } = useTranslation(['TeamLeaderWizard'])
  const { state, dispatch } = useTeamLeaderContext()

  const [isLoading, setIsLoading] = useState(false)
  const [displayError, setDisplayError] = useState(false)

  const closeModal = () => {
    dispatch({ type: 'CLOSE_MULTIPLE_OBJ_FORM_MODAL' })
    setDisplayError(false)
  }
  const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

  const mutationGenerateMultipleObjectives = useGenerateMultipleObjectives()
  const getGeneratedObjectives = async (values, resetForm) => {
    setIsLoading(true)
    await mutationGenerateMultipleObjectives.mutateAsync(
      { objective_text: values.objectiveText },
      {
        onSuccess: (response) => {
          addGoals(response, resetForm)
        },
        onError: (e) => {
          setDisplayError(true)
          setIsLoading(false)
          resetForm()
        },
      },
    )
  }

  const addGoals = (objs, resetForm = null) => {
    let lastObjLocaleId
    for (let obj of objs) {
      lastObjLocaleId = state.lastObjLocaleId++

      let returnObj = {
        type: 'ADD_GOAL',
        objType: obj.category === 'BAU' ? 3 : 1,
        statement: obj.objective,
        isValid: true,
        localeId: state.lastObjLocaleId,
      }

      if (obj.category === 'BAU') {
        returnObj.level1Id = null
        returnObj.level2Id = null
        returnObj.possibleMeasure = null
      }

      dispatch(returnObj)
    }
    dispatch({
      type: 'UPDATE_LAST_OBJ_LOCALE_ID',
      lastObjLocaleId: state.lastObjLocaleId,
    })
    setIsLoading(false)
    closeModal()
    if (resetForm != null && resetForm != undefined) resetForm()
  }

  const mutationGenerateObjectivesUsingAudio = useAddMultipleObjectivesUsingAudio()

  const generateObjectivesUsingAudio = async (audio) => {
    setIsLoading(true)
    await mutationGenerateObjectivesUsingAudio.mutateAsync(
      { audioFile: audio },
      {
        onSuccess: (response) => {
          addGoals(response, null)
          setIsLoading(false)
          closeModal()
        },
        onError: (e) => {
          setDisplayError(true)
          setIsLoading(false)
        },
      },
    )
  }

  return (
    <>
      {state.isMultipleObjModalOpen === true && (
        <Modal closeModal={closeModal} width={'60vw'}>
          {isLoading ? (
            <InPageLoader displayErrorText={true} />
          ) : (
            <Formik
              enableReinitialize
              initialValues={{
                objectiveText: '',
              }}
              validationSchema={Yup.object().shape({
                objectiveText: Yup.string().required(t('Common:formError.required')),
              })}
              onSubmit={(values, { resetForm }) => {
                getGeneratedObjectives(values, resetForm)
              }}
            >
              {({ errors, touched, resetForm, setFieldValue }) => (
                <div style={{ textAlign: 'left' }}>
                  <span>{t('addMultipleObjectivesModal.explanation')}</span>

                  <Form className="objective-form">
                    <div style={{ width: '100%' }}>
                      <div className="form-field">
                        <Field
                          component="textarea"
                          rows="8"
                          id="objectiveText"
                          name="objectiveText"
                          style={{ width: '100%', height: '100%' }}
                          maxLength="512"
                          onFocus={() => {
                            setDisplayError(false)
                          }}
                        />
                        {errors.objectiveText && touched.objectiveText && (
                          <div className="validation-error">{errors.objectiveText}</div>
                        )}
                        {displayError && (
                          <span style={{ color: 'red' }}>
                            {t('Common:modalMessages.somethingWentWrongPleaseTryAgain')}
                          </span>
                        )}
                        <div
                          className="modal-dual-btn"
                          style={{ display: 'flex', flexDirection: 'row', gap: '1.5rem' }}
                        >
                          <ModalSaveButtonFitted
                            isLoading={isLoading}
                            tag={t('Common:commonButtons.cancel')}
                            onClick={() => {
                              resetForm()
                              closeModal()
                            }}
                          />
                          <ModalSaveButtonFitted tag={t('Common:commonButtons.save')} />
                          <AudioRecorder
                            start={false}
                            handleSubmit={generateObjectivesUsingAudio}
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          )}
        </Modal>
      )}
    </>
  )
}

export default AddMultipleObjectivesModal

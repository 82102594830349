import React from 'react'
import { Formik, Field, Form } from 'formik'
import AddAreaLeaderSchema from './formSchema'
import { useSetupWizardContext } from '../../../../../../context/setupWizardContext'
import { LeadersContainer } from '../../../../../../../Governance/components/ManageLeaders/styles'

const AddAreaLeaderModalForm = ({ handleCancel, setResetForm }) => {
  const { state, dispatch } = useSetupWizardContext()

  const takenEmails = [
    ...state.data.areaLeader,
    ...state.data.teamLeader,
    ...state.data.individualContributor,
  ].map((leader) => leader.email)

  // will run after Yup ValidationSchema
  const validateEmail = (value) => {
    let error
    if (takenEmails.includes(value.toLowerCase())) {
      error = 'Email is already assigned.'
    }

    return error
  }

  const formikRef = React.useRef()

  React.useEffect(() => {
    if (formikRef.current !== undefined) {
      setResetForm(() => formikRef.current.resetForm)
    }
  }, [formikRef])

  return (
    <>
      <input id="taken-email-input-hidden" type="hidden" value={takenEmails.join(',')} />
      <Formik
        innerRef={formikRef}
        initialValues={{
          // NOTE: set initial values back to empty strings post demo (AM-RAVL)
          name: '',
          email: '',
          title: '',
          area: '',
          leader: '',
          // NOTE: Review how these are retrieved/set, and set in context if same for all employees
        }}
        validationSchema={AddAreaLeaderSchema}
        onSubmit={(values, { resetForm }) => {
          let valuesWithLowerCaseEmail = {
            ...values,
            email: values.email.toLowerCase(),
          }

          dispatch({ type: 'ADD_AREALEADER', areaLeader: valuesWithLowerCaseEmail })
          resetForm()
        }}
      >
        {({ errors, touched, resetForm }) => (
          <Form className="form" id="area-leader-modal-form">
            <div className="form-field">
              <label htmlFor="name">Name</label>
              <Field id="name" name="name" />
              {errors.name && touched.name && <div className="validation-error">{errors.name}</div>}
            </div>

            <div className="form-field">
              <label htmlFor="title">Title</label>
              <Field id="title" name="title" />
              {errors.title && touched.title && (
                <div className="validation-error">{errors.title}</div>
              )}
            </div>

            <div className="form-field">
              <label htmlFor="area">Area Name</label>
              <Field id="area" name="area" />
              {errors.area && touched.area && <div className="validation-error">{errors.area}</div>}
            </div>

            <div className="form-field">
              <label htmlFor="email">Email</label>
              <Field id="email" name="email" validate={validateEmail} />
              {errors.email && touched.email && (
                <div className="validation-error">{errors.email}</div>
              )}
            </div>

            <div className="form-field">
              <label htmlFor="leader">Reports to</label>
              <Field as="select" id="leader" name="leader">
                <option value="">Please select a leader</option>
                {state.data.enterpriseLeader.map((leader, index) => (
                  <option key={`leader-option-${index}`} value={leader.name}>
                    {leader.name} - {leader.title}
                  </option>
                ))}
              </Field>
              {errors.leader && touched.leader && (
                <div className="validation-error">{errors.leader}</div>
              )}
            </div>

            <div className="modal-dual-btn">
              <button
                className="dual-btn negative-btn"
                onClick={() => {
                  handleCancel()
                }}
                type="button"
              >
                CANCEL
              </button>
              <button type="submit" className="dual-btn positive-btn">
                SAVE
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default AddAreaLeaderModalForm

import React, { useState, useEffect } from 'react'
import { useCapacityContext } from '../../context/capacityContext'
import AdditionalCapacityTable from '../../../../components/AllocSubWizard/AdditionalCapacityTable'
import { useTranslation } from 'react-i18next'

const CapacityAdditionalCapacity = () => {
  const { t } = useTranslation(['Common'])
  return (
    <>
      <div className="wizard-step">
        <h2 className="wizard-step__title">{t('capacityAllocation.additionalCapacityTitle')}</h2>
        <div className="wizard-step__overview">
          <div className="wizard-step__overview__paragraph">
            {t('capacityAllocation.additionalCapacitySubtitle')}
          </div>
        </div>

        <div className="wizard-step__main-content-container">
          <div className="objectivesTeamMapping">
            <AdditionalCapacityTable
              contextHandler={useCapacityContext}
              isAddCoOwnersEnabled={true}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default CapacityAdditionalCapacity

import React, { useState, useEffect } from 'react'
import { useTeamLeaderContext } from '../../context/teamLeaderContext'
import help from '../../../../assets/help.svg'
import './styles.css'
import InfoModal from './components/InfoModal'
import MeasureHelpModal from './components/MeasureHelpModal'
import { useTranslation } from 'react-i18next'

const EnterpriseStep = () => {
  const { t } = useTranslation(['TeamLeaderWizard'])
  const { state, dispatch } = useTeamLeaderContext()

  const bauObjectives = state.data.objectives.filter((obj) => obj.type === 3)

  const goals = React.useMemo(() => {
    const returnGoals = []

    if (state.data.linkageData.higherObjectives) {
      state.data.linkageData.higherObjectives.forEach((goal) => {
        if (goal.level === 1) {
          returnGoals.push({
            levelOneGoal: goal,
            levelTwoGoals:
              state.data.linkageData.higherObjectives.filter(
                (test) => test.parentId === goal.id && test.level === 2,
              ) ?? [],
          })
        }
      })

      // This handles orphan level 2 objectives. We create a dummy level 1 objecitve with the ID 0,
      // which can be used to represent the dropdown option for "Not linked to an enterprise objective".
      returnGoals.push({
        levelOneGoal: {
          id: '0', // This should be a string because others are strings too (why??)
          statement: t('enterpriseStep.notLinkedEnterpriseObjective'),
        },
        levelTwoGoals:
          state.data.linkageData.higherObjectives.filter(
            (test) =>
              (test.parentId === undefined || parseInt(test.parentId) === 0) && test.level === 2,
          ) ?? [],
      })

      return returnGoals
    }
  }, [state.data.linkageData.higherObjectives])

  const [disableAreaObjective, setDisableAreaObjective] = useState(
    new Array(state.data.objectives.length).fill(true),
  )

  const updateDropdownStatus = () => {
    const updatedDropdownArray = [...disableAreaObjective]
    for (let i = 0; i < state.data.objectives.length; i++) {
      if (state.data.objectives[i].type === 3) {
        continue
      }
      if (
        state.data.objectives[i].level1Id !== 'none' &&
        state.data.objectives[i].level1Id !== undefined &&
        state.data.objectives[i].level1Id !== null
      ) {
        updatedDropdownArray[i] = false
      }
    }
    setDisableAreaObjective(updatedDropdownArray)
  }

  useEffect(() => {
    updateDropdownStatus()
  }, [])

  const handleLevel1GoalChange = (level1Id, objectiveIndex) => {
    if (state.data.objectives[objectiveIndex]) {
      if (level1Id === 'none') {
        level1Id = null
      }
      const objectiveToUpdate = {
        ...state.data.objectives[objectiveIndex],
        level1Id: level1Id,
        level2Id: null,
      }

      const updatedDropdownArray = [...disableAreaObjective]
      if (level1Id === null) {
        updatedDropdownArray[objectiveIndex] = true
      } else {
        updatedDropdownArray[objectiveIndex] = false
      }
      setDisableAreaObjective(updatedDropdownArray)

      dispatch({
        type: 'UPDATE_OBJECTIVE',
        objectiveIndex,
        objective: objectiveToUpdate,
      })
    }
  }

  const handleLevel2GoalChange = (level2Id, objectiveIndex) => {
    if (state.data.objectives[objectiveIndex]) {
      if (level2Id === 'none') {
        level2Id = null
      }
      const objectiveToUpdate = {
        ...state.data.objectives[objectiveIndex],
        level2Id: level2Id,
      }

      dispatch({
        type: 'UPDATE_OBJECTIVE',
        objectiveIndex,
        objective: objectiveToUpdate,
      })
    }
  }

  const handlePossibleMeasureChange = (possibleMeasure, objectiveIndex) => {
    if (state.data.objectives[objectiveIndex]) {
      if (possibleMeasure === 'none') {
        possibleMeasure = null
      }
      const objectiveToUpdate = {
        ...state.data.objectives[objectiveIndex],
        possibleMeasure: possibleMeasure,
      }

      dispatch({
        type: 'UPDATE_OBJECTIVE',
        objectiveIndex,
        objective: objectiveToUpdate,
      })
    }
  }

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
  }, [])

  return (
    <>
      <InfoModal
        isOpen={state.isEnterpriseHelpModalOpen}
        handleClose={() => {
          dispatch({ type: 'CLOSE_ENTERPRISE_INFO_MODAL' })
        }}
        stratDescData={state.data?.stratDescData ?? []}
      />

      <MeasureHelpModal
        isOpen={state.isEnterpriseMeasureModalOpen}
        handleClose={() => {
          dispatch({ type: 'CLOSE_ENTERPRISE_MEASURE_MODAL' })
        }}
      />

      <div className="wizard-step">
        <h2 className="wizard-step__title">{t('enterpriseStep.title')}</h2>
        <div className="wizard-step__overview">
          <div className="wizard-step__overview__paragraph">
            <span>{t('enterpriseStep.subtitle')}</span>
          </div>
        </div>

        <div className="wizard-step__main-content-container">
          <table style={{ width: '100%', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
            <tr className="header-row" style={{ width: '100%' }}>
              <th style={{ width: '30%' }}>
                <span>{t('enterpriseStep.objective')}</span>
              </th>
              <th style={{ width: '30%' }}>
                <span>{t('enterpriseStep.enterpriseObjective')}</span>
                {/* <img
                  className="header-help-btn"
                  onClick={() => {
                    dispatch({ type: 'OPEN_ENTERPRISE_INFO_MODAL' })
                  }}
                  src={help}
                  alt="Help button to learn more about Enterprise Objectives"
                /> */}
              </th>
              <th style={{ width: '30%' }}>
                <span>{t('enterpriseStep.areaObjective')}</span>
              </th>
            </tr>
            {state.data.objectives.map(
              (objective, objIndex) =>
                !bauObjectives.includes(objective) && (
                  <tr key={`obj-${objIndex}`} style={{ width: '100%' }}>
                    <td style={{ width: '30%' }}>
                      <span>{objective.statement}</span>
                    </td>

                    <td style={{ width: '30%' }}>
                      <select
                        className="enterprise-goal-select"
                        defaultValue={objective.level1Id ? objective.level1Id : 'none'}
                        data-testid={`enterprise-obj-${objIndex}`}
                        onChange={(e) => {
                          handleLevel1GoalChange(e.target.value, objIndex)
                        }}
                      >
                        <option value="none">
                          {t('enterpriseStep.selectEnterpriseObjective')}
                        </option>

                        {goals.map((goal, goalIndex) => {
                          return goal.levelTwoGoals.length > 0 ? (
                            <option
                              key={`level1-${goalIndex}`}
                              id={goal.levelOneGoal.id}
                              value={goal.levelOneGoal.id}
                            >
                              {goal.levelOneGoal.statement}
                            </option>
                          ) : (
                            ''
                          )
                        })}
                      </select>
                    </td>
                    <td style={{ width: '30%' }}>
                      <select
                        className="exec-goal-select"
                        onChange={(e) => handleLevel2GoalChange(e.target.value, objIndex)}
                        value={objective.level2Id !== null ? objective.level2Id : 'none'}
                        disabled={disableAreaObjective[objIndex]}
                        data-testid={`area-obj-${objIndex}`}
                      >
                        <option value="none">{t('enterpriseStep.selectAreaObjective')}</option>

                        {goals
                          .find((goal) => goal.levelOneGoal.id === objective.level1Id)
                          ?.levelTwoGoals.map((level2Goal, l2Index) => (
                            <option
                              value={level2Goal.id}
                              key={`${objIndex}-level2-${l2Index}`}
                              id={level2Goal.id}
                            >
                              {level2Goal.statement}
                            </option>
                          ))}
                      </select>
                    </td>
                  </tr>
                ),
            )}
          </table>
        </div>
      </div>
    </>
  )
}

export default EnterpriseStep

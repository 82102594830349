const objWrapper = {
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
}

const objTitle = {
  fontSize: '1rem',
  fontWeight: 'bold',
  padding: '0.5rem 0',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
}

const objItem = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0 0.5rem 1.5rem 0rem',
}

export { objWrapper, objTitle, objItem }

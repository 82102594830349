import React, { useRef, useState, useEffect } from 'react'
import './TopScrollbar.css'

function TopScrollbar({ children }) {
  const containerRef = useRef(null)
  const fakeScrollbarRef = useRef(null)
  const [contentWidth, setContentWidth] = useState(0)
  const [width, setWidth] = useState(0)
  const divRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)
  useEffect(() => {
    const container = containerRef.current

    const updateWidth = () => {
      const viewportWidth = container.clientWidth
      const contentWidth = container.scrollWidth
      const visibleRatio = viewportWidth / contentWidth

      const newThumbWidth = visibleRatio * viewportWidth
      setContentWidth(newThumbWidth)
      setWidth(contentWidth)
    }

    window.addEventListener('resize', updateWidth)
    updateWidth()
    const checkVisibility = () => {
      if (divRef.current) {
        const rect = divRef.current.getBoundingClientRect()
        const windowHeight = window.innerHeight || document.documentElement.clientHeight
        const newIsVisible = rect.top >= 0 && rect.bottom <= windowHeight
        setIsVisible(newIsVisible)
      }
    }

    // Attach the event listener to the window to track scroll and resize events.
    window.addEventListener('scroll', checkVisibility)
    window.addEventListener('resize', checkVisibility)

    // Initial check when the component mounts.
    checkVisibility()

    const observer = new MutationObserver(updateWidth)
    observer.observe(container, {
      childList: true,
      subtree: true,
      attributes: true,
    })

    return () => {
      window.removeEventListener('resize', updateWidth)
      window.removeEventListener('scroll', checkVisibility)
      window.removeEventListener('resize', checkVisibility)
      observer.disconnect()
    }
  }, [])

  const handleFakeScroll = (event) => {
    containerRef.current.scrollLeft = event.target.scrollLeft
  }

  const handleScroll = (event) => {
    fakeScrollbarRef.current.scrollLeft = event.target.scrollLeft
  }

  return (
    <div>
      <div ref={divRef}>
        <div
          className={`fake-scrollbar ${!isVisible ? 'sticky' : ''}`}
          ref={fakeScrollbarRef}
          onScroll={handleFakeScroll}
        >
          <div style={{ width: `${width}px` }}>
            <div style={{ width: `${contentWidth}px` }}></div>
          </div>
        </div>
      </div>
      <div className="content" onScroll={handleScroll} ref={containerRef} id="container">
        {children}
      </div>
    </div>
  )
}

export default TopScrollbar

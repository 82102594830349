import i18next from 'i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import { getCompanyConfigSettings } from './utils/auth'

const languageSettings = getCompanyConfigSettings('languageSettings')

i18next
  .use(
    resourcesToBackend((language, namespace) => import(`./locales/${language}/${namespace}.json`)),
  )
  .init({
    lng: languageSettings !== false ? languageSettings : 'en',
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: 'en',
  })

export default i18next

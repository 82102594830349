import Select from 'react-select'
import React from 'react'

export default function SelectField(props) {
  return (
    <div style={{ textAlign: 'left', width: props.width, marginTop: props.marginTop }}>
      <Select
        {...props}
        value={props?.value}
        isMulti={props.isMulti}
        isClearable={props.isClearable}
        onChange={props.onChange}
        style={props.style}
      />
    </div>
  )
}

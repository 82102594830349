import React, { useState, useEffect } from 'react'

import AddSubActivity from './common/AddSubActivity'

import Modal from '../../components/Modal/index'

import { postHttpRequest, getHttpRequest } from '../../api/query/dynamicAPI'
import { CREATE_SUB_ACTIVITY } from '../../api/constant/route'

import { GET_SINGLE_SUB_ACTIVITY } from '../../api/constant/route'

import './style.scss'

const SubActivity = ({ objectiveId, task, view, setModelView, setIsRefreshObjective, taskId }) => {
  const [subActivities, setSubActivities] = useState([])
  const [isRefreshSubActivities, setIsRefreshSubActivities] = useState(false)
  const [refreshParentComponent, setRefreshParentComponent] = useState(false)

  useEffect(() => {
    getSubActivites()
  }, [isRefreshSubActivities])

  function getSubActivites() {
    getHttpRequest(`${GET_SINGLE_SUB_ACTIVITY}/${taskId}`)
      .then((response) => {
        if (response && response.subActivitiesList && response.subActivitiesList.length) {
          setSubActivities(response.subActivitiesList)
        }
        if (!response) {
          return
        }
      })
      .catch((error) => {})
  }

  const onSubmit = () => {
    const postObj = {}
    postObj.objectiveId = objectiveId
    postObj.activityId = taskId
    postObj.subActivities = subActivities

    postHttpRequest(CREATE_SUB_ACTIVITY, postObj)
      .then((response) => {
        if (response) {
          setIsRefreshObjective((old) => !old)
          setModelView(false)
          return
        } else {
        }
      })
      .catch((error) => {})
  }

  return (
    <>
      <div className="kanban-modal">
        <Modal
          title={'Subactivity'}
          isModalOpen={view}
          data-testid="Add Objective"
          closeModal={() => setModelView(false)}
          width="700px"
        >
          <form className="objective-form">
            {refreshParentComponent === false && (
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div
                  className="column-input-objective"
                  style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                >
                  <p className="add-task-des-wrapper"> {task.description} </p>
                </div>
              </div>
            )}
            <AddSubActivity
              subActivities={subActivities}
              setSubActivities={setSubActivities}
              taskId={taskId}
              setIsRefreshSubActivities={setIsRefreshSubActivities}
              setRefreshParentComponent={setRefreshParentComponent}
            />
            {refreshParentComponent === false && (
              <div className="modal-dual-btn">
                <button
                  className="dual-btn negative-btn"
                  onClick={() => {
                    setModelView(false)
                  }}
                  type="submit"
                >
                  CANCEL
                </button>
                <button className="dual-btn positive-btn" type="button" onClick={onSubmit}>
                  {taskId ? 'UPDATE' : 'SAVE'}
                </button>
              </div>
            )}
          </form>
        </Modal>
      </div>
    </>
  )
}
export default SubActivity

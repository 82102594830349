import { FormikProvider } from 'formik'
import React from 'react'
import { useSetupWizardContext } from '../../../../context/setupWizardContext'
import './styles.css'

const GovernanceCadencesTable = () => {
  const { state, dispatch } = useSetupWizardContext()

  const today = new Date()
  let tomorrow = new Date()
  tomorrow.setDate(today.getDate() + 1)
  const formattedTomorrow = tomorrow.toISOString().split('T')[0]

  return (
    <div className="governance-cadence-table">
      <div className="manage-cadences">
        <div className="cadence-form-container shadow">
          <div className="form-field">
            <label>Frequency Of Governance Cycles</label>
            <div className="double-input">
              <input
                className="number-of-input"
                type="number"
                min={12}
                defaultValue={state.data.cadencePreferences.frequency}
                onChange={(e) => {
                  if (!e.target.checkValidity()) {
                    e.target.reportValidity()
                    return
                  }
                  dispatch({ type: 'CHANGE_CADENCE_FREQUENCY', frequency: Number(e.target.value) })
                }}
              />
              <span>{`(weeks)`}</span>
            </div>
          </div>

          <div className="form-field">
            <label>Start Date</label>
            <input
              type="date"
              min={formattedTomorrow}
              defaultValue={state.data.cadencePreferences.startDate}
              onChange={(e) => {
                if (!e.target.checkValidity()) {
                  e.target.reportValidity()
                  return
                }
                dispatch({ type: 'CHANGE_CADENCE_START_DATE', startDate: e.target.value })
              }}
            />
          </div>

          <div className="form-field">
            <label>TL Wizard Due Date</label>
            <input
              type="date"
              min={formattedTomorrow}
              defaultValue={state.data.cadencePreferences.tlWizardDueDate}
              onChange={(e) => {
                if (!e.target.checkValidity()) {
                  e.target.reportValidity()
                  return
                }
                dispatch({
                  type: 'CHANGE_CADENCE_TL_WIZ_DUE_DATE',
                  tlWizardDueDate: e.target.value,
                })
              }}
            />
          </div>

          <button className="big-btn">SAVE</button>
        </div>
      </div>
    </div>
  )
}

export default GovernanceCadencesTable

import React, { useState } from 'react'
import Modal from '../Modal'
import useGetEmployeesObjective from '../../api/query/useGetEmployeesForObjective'
import InPageLoader from '../InPageLoader'
import ObjectiveRow from '../../pages/Home/components/Objectives/components/ObjectiveRow'
import useEditObjectiveUnComplete from '../../api/mutation/editObjectiveUncomplete'
import useGetCompletedObj from '../../api/query/useGetCompletedObj'
import { useTranslation } from 'react-i18next'

const CompletedObjectivesModal = ({ isModalOpen, closeModal, objLevel }) => {
  const {
    data: objectiveData,
    isLoading: objectiveDataIsLoading,
    isFetching: objectiveDataIsFetching,
  } = useGetCompletedObj(objLevel)

  const mutationEditObjectiveUncomplete = useEditObjectiveUnComplete()
  const { t } = useTranslation(['Dashboard'])

  const handleUncomplete = async (objId) => {
    if (window.confirm(t('completedObjectivesModal.confirmUncompleteMsg'))) {
      await mutationEditObjectiveUncomplete.mutateAsync(parseInt(objId), {
        onSuccess: (response) => {
          closeModal()
        },
        onError: (response) => {},
      })
    }
  }

  return (
    <>
      <Modal
        title={t('completedObjectivesModal.completedObjectivesTitle')}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
      >
        <>
          {objectiveData?.objectives?.length > 0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                textAlign: 'left',
              }}
            >
              {objectiveData?.objectives?.map((obj, objIndex) => (
                <React.Fragment>
                  {obj.complete && (
                    <ObjectiveRow
                      key={`objrow-${objIndex}`}
                      objective={obj}
                      handleUncomplete={() => {
                        handleUncomplete(parseInt(obj.id))
                      }}
                      fromCompletedObjModal={true}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          )}
          {objectiveData?.objectives?.length === 0 && (
            <span>{t('completedObjectivesModal.noCompletedObjectivesMsg')}</span>
          )}

          {objectiveDataIsLoading && <InPageLoader inComponent={true} />}
        </>
      </Modal>
    </>
  )
}

export default CompletedObjectivesModal

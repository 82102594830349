import { useQuery } from '@tanstack/react-query'
import { getHttpRequest } from './dynamicAPI'
import { GET_TL_WIZARD_STATE } from '../../api/constant/route'

const getTLWizardStateData = async () => {
  const queryString = GET_TL_WIZARD_STATE
  const data = await getHttpRequest(queryString)
  return data
}

export default function useTLWizardStateData() {
  return useQuery(['tlWizardState'], () => getTLWizardStateData(), {
    refetchOnWindowFocus: false,
  })
}

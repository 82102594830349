import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Button from '../../../../components/Button'
import { milestoneSubRoutes, routes } from '../../../../utils/routes'
import { CardContainer, CardContent, StepHeader, StepCaption, ButtonContainer } from '../../styles'
import { useTranslation } from 'react-i18next'
import { getConsolidatedConfigSettings, isAreaLeader } from '../../../../utils/auth'
import { getCompanyConfigSettings } from '../../../../utils/auth'
import { getHttpRequest, postHttpRequest } from '../../../../api/query/dynamicAPI'
import { useUpdateContext } from '../../context/updateContext'
import Toast from '../../../../common/toast'
const CardsContainer = styled.div`
  display: flex;
  gap: 2rem;
  width: 80%;
`

const UpdateIntro = () => {
  const { t } = useTranslation(['UpdateWizard', 'Common'])
  const skipTlWizIcsPage = getConsolidatedConfigSettings('skip_tl_wiz_ics_page')
  const tlSkipActivityWiz = getConsolidatedConfigSettings('tl_skip_activity_wiz')
  const all = skipTlWizIcsPage === false && tlSkipActivityWiz === false && isAreaLeader() === false
  const showToturial =
    isAreaLeader() ||
    (getCompanyConfigSettings('showKpiUpdatePageInUW') === true &&
      getConsolidatedConfigSettings('tl_skip_capacity_wiz') === true)
  const uwTutorialOff = getConsolidatedConfigSettings('uw_tutorial_off')

  const [isChecked, setIsChecked] = React.useState(uwTutorialOff)
  const { state, dispatch } = useUpdateContext()
  // ideally we should reset state.tourIndex to 0, but since we don't pass it direactly (0,1,2 instead), I thinkg it's fine for now
  React.useEffect(() => {
    if (showToturial) {
      ;(async () => {
        try {
          let response = await getHttpRequest('/get_employees_user_preferences')
          setIsChecked(response.uwTutorialOff)
        } catch (error) {
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
          })
        }
      })()
    }
  }, [])
  React.useEffect(() => {
    // update
    // we should only update the state if the tutorial is supposedly shoen
    if (showToturial) {
      ;(async () => {
        try {
          await postHttpRequest('/edit_employees_user_preferences', {
            uwTutorialOff: isChecked,
          })
          dispatch({
            type: 'UPDATE_UW_TUTORIAL_OFF',
            isTutorialOff: isChecked,
          })
        } catch (error) {
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
          })
        }
      })()
    }
  }, [isChecked])

  const handleUwTutorialCheck = (e) => {
    setIsChecked((prev) => !prev)
  }
  return (
    <>
      <div>
        <StepHeader>{t('introStep.header')}</StepHeader>
        <StepCaption></StepCaption>
      </div>
      <CardsContainer>
        {(skipTlWizIcsPage === true || isAreaLeader() || all) && (
          <CardContainer>
            <CardContent>
              <h3>{t('introStep.box1Header')}</h3>
              <div>{t('introStep.box1Text')}</div>
            </CardContent>
          </CardContainer>
        )}
        {(skipTlWizIcsPage === true || isAreaLeader() || all) && (
          <CardContainer>
            <CardContent>
              <h3>{t('introStep.box2Header')}</h3>
              <div>{t('introStep.box2Text')}</div>
            </CardContent>
          </CardContainer>
        )}
        {(tlSkipActivityWiz === true || all) && (
          <CardContainer>
            <CardContent>
              <h3>{t('introStep.box3Header')}</h3>
              <div>{t('introStep.box3Text')}</div>
            </CardContent>
          </CardContainer>
        )}
      </CardsContainer>
      {showToturial && (
        <div style={{ marginTop: '2rem', width: '80%' }}>
          <input type="checkbox" checked={isChecked} onChange={(e) => handleUwTutorialCheck(e)} />
          <span style={{ marginLeft: '0.5rem' }}>{t('introStep.checkbox')}</span>
        </div>
      )}
    </>
  )
}

export default UpdateIntro

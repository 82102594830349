import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getSuggestedObjectiveData = async (suggestionId) => {
  const { data } = await axios.get(`${BASE_URL}/get_suggested_objective/${suggestionId}`)
  return data
}

export default function useSuggestedObjectiveData(suggestionId) {
  return useQuery(['suggested-obj1', suggestionId], () => getSuggestedObjectiveData(suggestionId), {
    refetchOnWindowFocus: false,
    enabled: suggestionId !== undefined && suggestionId !== null,
  })
}

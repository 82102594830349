import { React, useState } from 'react'
import { Formik, Field, Form } from 'formik'
import Modal from '../Modal'
import { ModalSaveButtonFitted } from '../ModalSubmitButton'

import { colors } from '../../utils/colors'
import InPageLoader from '../InPageLoader/index'
import * as Yup from 'yup'
import useGenerateMultipleActivities from '../../api/mutation/useGenerateMultipleActivities'
import useAddMultipleActivitiesUsingAudio from '../../api/mutation/addMultipleActivitiesUsingAudio'
import AudioRecorder from '../AudioRecorder'
import { getCompanyConfigSettings } from '../../utils/auth'
import { useTranslation } from 'react-i18next'

const initialValues = {
  activitiesText: '',
}
const AddMultipleActivitiesModal = ({
  objectiveId,
  handleClose,
  setIsRefreshObjective,
  handleAddActivities = null,
}) => {
  const { t } = useTranslation(['Common'])

  const [isLoading, setIsLoading] = useState(false)

  const [formValues, setFormValues] = useState(initialValues)
  const [displayError, setDisplayError] = useState(false)

  const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

  const mutationGenerateActivitiesUsingAudio = useAddMultipleActivitiesUsingAudio()
  const isSpanishLangEnabled = getCompanyConfigSettings('languageSettings') === 'es'

  const generateActivitiesUsingAudio = async (audio) => {
    setIsLoading(true)
    await mutationGenerateActivitiesUsingAudio.mutateAsync(
      { audioFile: audio, objectiveId: objectiveId },
      {
        onSuccess: (response) => {
          setIsLoading(false)
          setIsRefreshObjective((old) => !old)
          handleClose()

          if (handleAddActivities !== null) {
            handleAddActivities(response.activities)
          }
        },
        onError: (e) => {
          setDisplayError(true)
          setIsLoading(false)
        },
      },
    )
  }

  const mutationGenerateActivitiesUsingText = useGenerateMultipleActivities()

  const generateActivitiesUsingText = async (values, resetForm) => {
    setIsLoading(true)
    await mutationGenerateActivitiesUsingText.mutateAsync(
      { activitiesText: values.activitiesText, objectiveId: objectiveId },
      {
        onSuccess: (response) => {
          setIsLoading(false)
          setIsRefreshObjective((old) => !old)
          handleClose()

          if (handleAddActivities !== null) {
            handleAddActivities(response.activities)
          }
        },
        onError: (e) => {
          setDisplayError(true)
          setIsLoading(false)
          resetForm()
        },
      },
    )
  }

  return (
    <>
      <Modal
        closeModal={() => {
          handleClose()
        }}
        width={'60vw'}
      >
        {isLoading ? (
          <InPageLoader displayErrorText={true} />
        ) : (
          <div>
            <div style={{ textAlign: 'left', paddingBottom: '1rem' }}>
              {t('addMultipleActivitiesModal.title')}
              <br />
              <br />
              {t('addMultipleActivitiesModal.example')}
            </div>
            <Formik
              initialValues={formValues}
              validationSchema={Yup.object().shape({
                activitiesText: Yup.string().required('Required'),
              })}
              onSubmit={(values, { resetForm }) => {
                generateActivitiesUsingText(values, resetForm)
              }}
              enableReinitialize
            >
              {({ errors, touched, resetForm, setFieldValue }) => (
                <Form className="objective-form">
                  <div style={{ width: '100%' }}>
                    <div className="form-field">
                      <Field
                        component="textarea"
                        rows="8"
                        id="activitiesText"
                        name="activitiesText"
                        style={{ width: '100%', height: '100%' }}
                        maxLength="512"
                        onFocus={() => {
                          setDisplayError(false)
                        }}
                      />
                      {errors.activitiesText && touched.activitiesText && (
                        <div className="validation-error">{errors.activitiesText}</div>
                      )}
                      {displayError && (
                        <span style={{ color: 'red' }}>Something went wrong. Please try again</span>
                      )}
                      <div className="modal-dual-btn" style={{ columnGap: '2rem' }}>
                        <div
                          class="fitted-button blue"
                          onClick={() => {
                            resetForm()
                            handleClose()
                          }}
                          type="button"
                          color={colors.grey}
                        >
                          CANCEL
                        </div>
                        <ModalSaveButtonFitted
                          isLoading={
                            mutationGenerateActivitiesUsingText.isLoading ||
                            mutationGenerateActivitiesUsingText.isFetching ||
                            isLoading
                          }
                        />
                        <div
                          onClick={() => {
                            setDisplayError(false)
                          }}
                        >
                          {!isSpanishLangEnabled && (
                            <AudioRecorder handleSubmit={generateActivitiesUsingAudio} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </Modal>
    </>
  )
}

export default AddMultipleActivitiesModal

import React from 'react'
import PendingActivitiesList from '../EmailReminderTab/components/PendingActivitiesList'
import { useLocation } from 'react-router-dom'
export const PrintMode = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const page = queryParams.get('page')

  React.useEffect(() => {
    setTimeout(() => {
      window.print()
    }, 1000)
  }, [])

  return (
    <>
      {page === 'EmailRemainder' && <PendingActivitiesList fromHomePage={true} printMode={true} />}
    </>
  )
}

export default PrintMode

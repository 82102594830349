import React, { useState, useEffect } from 'react'
import { useTeamLeaderContext } from '../../context/teamLeaderContext'
import TeamCapacityAllocationTable from '../../../../components/AllocSubWizard/TeamCapacityAllocationTable'
import { useTranslation } from 'react-i18next'

const TeamCapacityAllocation = () => {
  const { t } = useTranslation(['Common'])
  const { state, dispatch } = useTeamLeaderContext()
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
  }, [])

  return (
    <>
      <div className="wizard-step">
        <h2 className="wizard-step__title">
          {t('capacityAllocation.teamCapacityAllocationTitle')}
        </h2>
        {state.data.employees.length > 0 ? (
          <>
            <div className="wizard-step__overview">
              <div className="wizard-step__overview__paragraph">
                {t('capacityAllocation.teamCapacityAllocationSubtitle')}
              </div>
            </div>

            <div className="wizard-step__main-content-container">
              <TeamCapacityAllocationTable contextHandler={useTeamLeaderContext} />
            </div>
          </>
        ) : (
          'You do not have any team members.'
        )}
      </div>
    </>
  )
}

export default TeamCapacityAllocation

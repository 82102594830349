import React from 'react'
import { useSetupWizardContext } from '../../context/setupWizardContext'
import plus from '../../../../assets/plus.svg'
import deleteCross from '../../../../assets/delete-cross.svg'
import CaseMappingModal from './components/CaseMappingModal'

const CaseMapping = () => {
  const { state, dispatch } = useSetupWizardContext()
  const [mode, setMode] = React.useState()
  const saveCaseMappingModal = (tlEmpowerment, mappedPeople) => {
    let teamleaders = JSON.parse(JSON.stringify(state?.data?.teamLeader))
    for (let i = 0; i < teamleaders?.length; i++) {
      if (teamleaders[i].email === tlEmpowerment) {
        teamleaders[i]['empoweredTo'] = mappedPeople
      }
    }
    dispatch({ type: 'UPDATE_TEAMLEADERS', teamLeaders: teamleaders })
    closeCaseMappingModal()
  }

  const openCaseMappingModal = () => {
    dispatch({ type: 'OPEN_CASE_MAPPING_MODAL' })
  }

  const closeCaseMappingModal = () => {
    dispatch({ type: 'CLOSE_CASE_MAPPING_MODAL' })
  }

  return (
    <>
      <div
        className="wizard-container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {state.isCaseMappingModal === true && (
          <CaseMappingModal
            mode={mode}
            closeModal={closeCaseMappingModal}
            handleSubmit={saveCaseMappingModal}
            teamLeaders={state?.data?.teamLeader}
          />
        )}

        <div className="wizard-step">
          <h2 className="wizard-step__title">Decision Making Scope</h2>
          <div className="wizard-step__overview">
            <div className="wizard-step__overview__paragraph">
              Please indicate if any Team Leaders are also managing other Team Leaders and should
              have the ability to make cross-team trade-offs with this group of Team Leaders.
            </div>
          </div>

          <div className="wizard-step__main-content-container">
            {state?.data?.teamLeader.length > 0 && (
              <div style={{ display: 'grid', gridTemplateColumns: '16rem 16rem 6rem' }}>
                <b>Managing Team Leaders</b>
                <b>Reporting Team Leaders</b>
              </div>
            )}
            {state?.data?.teamLeader.map((tl, tlIndex) => (
              <>
                {tl?.empoweredTo && (
                  <div style={{ display: 'grid', gridTemplateColumns: '16rem 16rem 6rem' }}>
                    <div>{tl?.email}</div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {tl?.empoweredTo?.map((person, personIndex) => (
                        <span>{person}</span>
                      ))}
                    </div>
                    <div>
                      {' '}
                      <div
                        className="delete-button"
                        onClick={() => {
                          dispatch({ type: 'DELETE_CASE_MAPPING', email: tl.email })
                        }}
                      >
                        <img className="delete-btn" src={deleteCross} alt="Click to edit symbol" />
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))}

            {state?.data?.teamLeader?.length >= 2 && (
              <div
                className="add-btn"
                onClick={() => {
                  setMode('ADD')
                  openCaseMappingModal()
                }}
                style={{ width: '15rem' }}
              >
                <img className="plus" src={plus} alt="TransforML Logo" />
                <span>ADD REPORTING LINE</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default CaseMapping

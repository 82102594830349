import * as Yup from 'yup'

const AddEmployeeSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  area: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  title: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  leader: Yup.string().required('Required'),
})

export default AddEmployeeSchema

import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const updateDecisionLogOutcome = async (outcomeData) => {
  const { data } = await axios.post(`${BASE_URL}/update_decision_log_outcome`, outcomeData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export default function useUpdateDecisionLogOutcome() {
  return useMutation((data) => updateDecisionLogOutcome(data))
}

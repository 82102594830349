import React from 'react'
import EmployeeMeiBoxes from '../../components/DashboardMei'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import { useTranslation } from 'react-i18next'
import MeiModal from '../Home/components/AdminDashboard/components/TeamDoing/component/MeiModal'
import MeiHelpModal from '../Home/components/AdminDashboard/components/TeamDoing/component/MeiHelpModal'
import InPageLoader from '../../components/InPageLoader'
import { getInheritedLevel, hasRole } from '../../utils/auth'
import Toast from '../../common/toast'
import ApplicationLayout from '../../components/ApplicationLayout'
import { shortenName } from '../../utils/general'
import MeiHistory from './component/MeiHistory'
import { useFeatureFlagsContext } from '../../utils/featureFlagsContext'
import MeiCxoBox from './component/MeiCxoBox'
import { useLocation } from 'react-router-dom'

const CxoMgmtScore = () => {
  const [alData, setAlData] = React.useState(null)
  const { t } = useTranslation(['Dashboard'])

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [isHelpModalOpen, setIsHelpModalOpen] = React.useState(false)
  const [data, setData] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)

  const { flags } = useFeatureFlagsContext()
  const location = useLocation()
  const isFromQuickLinks = location?.state?.isFromQuickLinks ?? false

  React.useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        let params = {
          isFromQuickLinks: isFromQuickLinks,
        }
        let response = await getHttpRequest('/get_mei_data', { params: params })
        setAlData(response.data)
        setIsLoading(false)
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Something went wrong',
        })
      }
    })()
  }, [])

  if (!(getInheritedLevel() == 1 || hasRole('cxo') || isFromQuickLinks)) {
    return (
      <p style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '2rem' }}>
        {t('chartsCard.notChartData')}
      </p>
    )
  }

  return (
    <ApplicationLayout>
      {isModalOpen && data && (
        <MeiModal data={data} isModalOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} />
      )}
      {isHelpModalOpen && (
        <MeiHelpModal isModalOpen={isHelpModalOpen} closeModal={() => setIsHelpModalOpen(false)} />
      )}
      {isLoading ? (
        <InPageLoader />
      ) : (
        <MeiCxoBox
          setData={setData}
          alData={alData}
          setIsModalOpen={setIsModalOpen}
          setIsHelpModalOpen={setIsHelpModalOpen}
        />
      )}
      {flags.enable_cxo_mei_history_dashboard == 'true' && <MeiHistory />}
    </ApplicationLayout>
  )
}

export default CxoMgmtScore

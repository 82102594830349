import React from 'react'
import EmployeeMeiBoxes from '../../components/DashboardMei'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import { useTranslation } from 'react-i18next'
import MeiModal from '../Home/components/AdminDashboard/components/TeamDoing/component/MeiModal'
import MeiHelpModal from '../Home/components/AdminDashboard/components/TeamDoing/component/MeiHelpModal'
import InPageLoader from '../../components/InPageLoader'
import { getInheritedLevel, hasRole } from '../../utils/auth'
import Toast from '../../common/toast'
import ApplicationLayout from '../../components/ApplicationLayout'
import { shortenName } from '../../utils/general'

const CxoMgmtScoreL2Beta = () => {
  const [alData, setAlData] = React.useState(null)
  const { t } = useTranslation(['Dashboard'])

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [isHelpModalOpen, setIsHelpModalOpen] = React.useState(false)
  const [data, setData] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        let response = await getHttpRequest('/get_mei_data?l2ReportsBeta')
        setAlData(response)
        setIsLoading(false)
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Something went wrong',
        })
      }
    })()
  }, [])

  if (!(getInheritedLevel() == 1 || hasRole('cxo'))) {
    return (
      <p style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '2rem' }}>
        {t('chartsCard.notChartData')}
      </p>
    )
  }

  return (
    <ApplicationLayout>
      {isModalOpen && data && (
        <MeiModal
          data={data}
          isModalOpen={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
          showNoKpi={false}
        />
      )}
      {isHelpModalOpen && (
        <MeiHelpModal isModalOpen={isHelpModalOpen} closeModal={() => setIsHelpModalOpen(false)} />
      )}
      {isLoading ? (
        <InPageLoader />
      ) : (
        <div className="dashboard_wrapper" style={{ marginTop: '5%' }}>
          <div className="charts_outer_box">
            <h1 className="charts_heading">{t('chartsCard.cxoMeiTitle')}</h1>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1rem' }}>
              <div
                style={{
                  color: '#0926D5',
                  textDecoration: 'underline',
                  marginLeft: '0.5rem',
                }}
              >
                <span
                  style={{ cursor: 'pointer', fontSize: '14px' }}
                  onClick={() => {
                    setIsHelpModalOpen(true)
                  }}
                >
                  ({t('managementExecIndex.seeHelp')})
                </span>
              </div>
            </div>
            <div className="chart_container" style={{ overflowX: 'auto' }}>
              {alData !== null && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <EmployeeMeiBoxes
                    metrics={alData}
                    isCxo={false}
                    setIsModalOpen={setIsModalOpen}
                    setData={setData}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </ApplicationLayout>
  )
}

export default CxoMgmtScoreL2Beta

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { POST_TL_WIZARD_STATE } from '../../../api/constant/route'
import { postHttpRequest } from '../../../api/query/dynamicAPI'
import { teamLeaderSubRoutes, routes } from '../../../utils/routes'
import { useTeamLeaderContext } from '../context/teamLeaderContext'
import { getConsolidatedConfigSettings } from '../../../utils/auth'

function tlwStepsGenerator() {
  return [
    teamLeaderSubRoutes.intro,
    teamLeaderSubRoutes.team,
    teamLeaderSubRoutes.objectives,
    teamLeaderSubRoutes.activities,
    teamLeaderSubRoutes.enterprise,
    teamLeaderSubRoutes.objectivesTeamMapping,
    teamLeaderSubRoutes.teamCapacityAllocation,
    teamLeaderSubRoutes.meetingTopics,
  ]
}

export function stepsLabelsGenerator() {
  return {
    INTRO: 0,
    TEAM_ALLOC: 1,
    OBJECTIVES: 2,
    OBJECTIVE_TYPE: 3,
    LINKAGE: 4,
    OBJ_TEAM_MAPPING: 5,
    TEAM_CAPACITY_ALLOC: 6,
    MEETING_TOPICS: 7,
  }
}

export default function useTeamLeaderWizardSteps(existingStep) {
  const teamLeaderSteps = tlwStepsGenerator()
  const steps = stepsLabelsGenerator()
  const [tlArray, setTlArray] = React.useState([
    true, //INTRO 0
    true, //TEAM_ALLOC 1
    true, //OBJECTIVES 2
    true, //OBJECTIVE_TYPE 3
    true, //LINKAGE 4
    true, //OBJ_TEAM_MAPPING 5
    true, //TEAM_CAPACITY_ALLOC 6
    true, //MEETING_TOPICS 7
  ])
  const skipTlWizIcsPage = getConsolidatedConfigSettings('skip_tl_wiz_ics_page') === true
  const navigate = useNavigate()
  const [currentStepIndex, setCurrentStepIndex] = React.useState(parseInt(existingStep))
  const { state } = useTeamLeaderContext()

  React.useEffect(() => {
    if (currentStepIndex >= 0) {
      navigate(`/${routes.teamleader}/${teamLeaderSteps[currentStepIndex]}`)
    }
  }, [currentStepIndex, existingStep, navigate])

  //TODO Swetha: Commenting the popup on browser close
  // window.addEventListener("beforeunload", (ev) => {
  //   if (window.location.href.indexOf('Blocking') === -1) {
  //     ev.preventDefault();
  //     navigate(`/${routes.teamleader}/${teamLeaderSteps[currentStepIndex]}`)
  //     return ev.returnValue = 'You have unsaved data. If you want to save progress, click cancel and navigate to the next page.';
  //   }
  //   else{
  //     return ''
  //   }
  // });

  const handleNext = () => {
    let tempTlArray = [...tlArray]

    if (currentStepIndex <= teamLeaderSteps.length - 1) {
      const bauObjectives = state.data.objectives.filter((obj, objIndex) => obj.type === 3) ?? []

      if (skipTlWizIcsPage === true) {
        tempTlArray[steps.TEAM_ALLOC] = false
        tempTlArray[steps.OBJ_TEAM_MAPPING] = false
        tempTlArray[steps.TEAM_CAPACITY_ALLOC] = false
      }

      tempTlArray[steps.OBJ_TEAM_MAPPING] = state.data.employees.length !== 0
      tempTlArray[steps.TEAM_CAPACITY_ALLOC] = state.data.employees.length !== 0

      // If num of BAU objectives != total objectives, then show linkage page (set this to true).
      tempTlArray[steps.LINKAGE] = bauObjectives.length !== state.data.objectives.length

      let stepIncrease = 1
      while (true) {
        if (tempTlArray[currentStepIndex + stepIncrease] === true) {
          const nextStep = currentStepIndex + stepIncrease
          setCurrentStepIndex(nextStep)
          saveTLWizardState(state.data, nextStep)
          break
        }
        stepIncrease++

        if (currentStepIndex + stepIncrease === teamLeaderSteps.length) {
          break
        }
      }
    }
    setTlArray([...tempTlArray])
  }

  const handlePrevious = () => {
    let tempTlArray = [...tlArray]
    if (Boolean(currentStepIndex)) {
      const bauObjectives = state.data.objectives.filter((obj, objIndex) => obj.type === 3) ?? []

      if (skipTlWizIcsPage === true) {
        tempTlArray[steps.TEAM_ALLOC] = false
        tempTlArray[steps.OBJ_TEAM_MAPPING] = false
        tempTlArray[steps.TEAM_CAPACITY_ALLOC] = false
      }
      tempTlArray[steps.OBJ_TEAM_MAPPING] = state.data.employees.length !== 0
      tempTlArray[steps.TEAM_CAPACITY_ALLOC] = state.data.employees.length !== 0

      // If num of BAU objectives != total objectives, then show linkage page (set this to true).
      tempTlArray[steps.LINKAGE] = bauObjectives.length !== state.data.objectives.length

      let stepDecrease = 1
      while (true) {
        if (tempTlArray[currentStepIndex - stepDecrease] === true) {
          const prevStep = currentStepIndex - stepDecrease
          setCurrentStepIndex(prevStep)
          saveTLWizardState(state.data, prevStep)
          break
        }
        stepDecrease++

        if (currentStepIndex - stepDecrease === -1) {
          break
        }
      }
    }
    setTlArray([...tempTlArray])
  }

  async function saveTLWizardState(tlWizardState, step) {
    const postObj = {}
    postObj.tlWizardState = tlWizardState
    postObj.step = step
    postHttpRequest(POST_TL_WIZARD_STATE, postObj)
      .then((response) => {
        if (response) {
          return
        } else {
        }
      })
      .catch((error) => {})
  }

  return {
    currentStepIndex,
    steps: teamLeaderSteps,
    handleNext,
    handlePrevious,
  }
}

import React, { useState } from 'react'
import Modal from '../Modal'
import useGetEmployeesObjective from '../../api/query/useGetEmployeesForObjective'
import InPageLoader from '../InPageLoader'
import { useTranslation } from 'react-i18next'

const EmployeeListModal = ({ title, isModalOpen, closeModal, objective }) => {
  const { t } = useTranslation(['Common'])
  const {
    data: employeesList,
    isLoading: employeeListIsLoading,
    isFetching: employeeListIsFetching,
  } = useGetEmployeesObjective(objective.objId)

  return (
    <>
      <Modal title={title} isModalOpen={isModalOpen} closeModal={closeModal}>
        <>
          {employeesList ? (
            <div>
              <h3>
                {t('employeeListModal.objective')} : {objective.statement}
              </h3>
              {employeesList?.employees?.map((emp, empIndex) => (
                <ul style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <li style={{ alignSelf: 'flex-start' }}>{emp?.name}</li>
                </ul>
              ))}
            </div>
          ) : (
            <InPageLoader inComponent={true} />
          )}
        </>
      </Modal>
    </>
  )
}

export default EmployeeListModal

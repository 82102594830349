import React from 'react'
import { defaultState, SetupWizardProvider } from './context/setupWizardContext'
import SetupWizard from './components/SetupWizard'
import useSetupInitData from '../../api/query/useSetupInitData'
import NavigationLoginless from '../../components/NavigationLoginless'

const SetupWizardPage = () => {
  const { data, isLoading, error } = useSetupInitData()
  const existingStep = localStorage.getItem('sw-step')
  const existingData = localStorage.getItem('sw-data')

  if (isLoading) {
    return '...'
  }

  if (error) {
    // TODO: Error Component
    return 'Error'
  }

  let initialState = {
    ...defaultState,
    data: {
      ...defaultState.data,
      industryWideMeasures: [...data.industryWideMeasures],
    },
  }

  if (existingData) {
    const parsedExistingData = JSON.parse(existingData)

    initialState.data = {
      ...parsedExistingData,
      industryWideMeasures: [...data.industryWideMeasures],
    }
  }

  return (
    <>
      <NavigationLoginless />
      <SetupWizardProvider initialState={initialState}>
        <SetupWizard existingStep={existingStep ?? 0} />
      </SetupWizardProvider>
    </>
  )
}

export default SetupWizardPage

const actionContainer = {
  display: 'flex',
  gap: '0.5rem',
  padding: '0.5rem 0rem',
  flexWrap: 'wrap',
}

const objectiveRowContainer = {
  borderBottom: '1px solid #eeeeee',
}

const dataContainer = {
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
}

export { actionContainer, objectiveRowContainer, dataContainer }

import React, { useState } from 'react'
import Modal from '../../../components/Modal'
import { getHttpRequest } from '../../../api/query/dynamicAPI'
import { useTranslation } from 'react-i18next'
import useUwManageUpdate from '../../../api/mutation/useUwManageUpdate'

const RequestUpdateModal = ({ closeModal, objectiveId }) => {
  const { t } = useTranslation(['ObjectiveMap', 'Common'])

  const [textInput, setTextInput] = React.useState(null)

  const textInputChangeHandler = (event) => {
    setTextInput(event.target.value)
  }
  const mutationUpdate = useUwManageUpdate()

  const manageUpdate = async () => {
    let data = {}
    data.action = 'add'

    data.update = {
      description: textInput,
      objId: objectiveId,
      topicType: 'update',
      isRequestUpdate: true,
    }

    await mutationUpdate.mutateAsync(data, {
      onSuccess: () => {
        setTextInput(null)
        closeModal()
      },
      onError: () => {
        closeModal()
      },
    })
  }

  return (
    <>
      <Modal
        isModalOpen={true}
        closeModal={() => {
          closeModal()
        }}
        width={'40rem'}
        style={{ padding: '0rem 0rem 1rem 0rem' }}
        title={t('modalTitle.requestUpdate')}
      >
        <>
          <div className="form-field action-field">
            <label>{t('menu.statement')}</label>
            <textarea
              rows="6"
              id="multiSubActivityText"
              name="multiSubActivityText"
              value={textInput}
              onChange={(e) => {
                textInputChangeHandler(e)
              }}
              style={{
                resize: 'none',

                border: 'none',
                borderRadius: '10px',
                padding: '0.5rem',
                backgroundColor: 'rgb(242, 242, 242)',
                fontSize: '14px',
                width: '32rem',
              }}
            />
          </div>
          <button
            style={{
              width: '6rem',
              marginTop: '0.5rem',
              height: '2rem',
              borderRadius: '4px',
              padding: '0',
              color: 'white',
              border: 'none',
              cursor: 'pointer',
              backgroundColor: 'rgb(237, 82, 46)',
            }}
            onClick={() => {
              manageUpdate()
            }}
            type="submit"
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {t('Common:commonButtons.add')}
            </div>
          </button>
        </>
      </Modal>
    </>
  )
}

export default RequestUpdateModal

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useKPIContext } from '../../context/kpiContext'

const KPITimeframeQuestion = () => {
  const { t } = useTranslation(['KPIWizard', 'Common'])
  const [timeframe, setTimeframe] = React.useState(null)
  const { state, dispatch } = useKPIContext()

  const timeframeChangeHandler = (e) => {
    setTimeframe(e.target.value)
  }

  React.useEffect(() => {
    if (state.answers.timeframe !== null) {
      setTimeframe(state.answers.timeframe)
    }
  }, [])

  React.useEffect(() => {
    dispatch({ type: 'SET_TIMEFRAME', value: timeframe })
  }, [timeframe])

  return (
    <>
      <div className="left-aligned-wizard-step" style={{ width: '100%' }}>
        <h2
          className="wizard-step__title"
          style={{
            margin: '1rem 0 1rem 0',
            textAlign: 'left',
            color: 'rgb(91,132,203)',
            maxWidth: '100%',
          }}
        >
          {state?.obj?.statement}
        </h2>
        <h2
          className="wizard-step__title"
          style={{ margin: '1rem 0 1rem 0', textAlign: 'left', maxWidth: '100%', fontSize: '24px' }}
        >
          {t('timeframeQuestion')}
        </h2>
        <div
          className="left-aligned-wizard-step__single-answer-container"
          style={{ padding: '1rem', marginTop: '0.5rem' }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', fontSize: '16px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <label className="kpi-wizard-radio-input" style={{ justifyContent: 'normal' }}>
                <input
                  style={{}}
                  type="radio"
                  name="type"
                  value="1"
                  checked={timeframe === '1'}
                  onChange={timeframeChangeHandler}
                />
                <span style={{ marginLeft: '22px', fontSize: '20px' }}>
                  {' '}
                  {t('inTheNextQuarter')}{' '}
                </span>
              </label>
              <label className="kpi-wizard-radio-input" style={{ justifyContent: 'normal' }}>
                <input
                  style={{}}
                  type="radio"
                  name="type"
                  value="2"
                  checked={timeframe === '2'}
                  onChange={timeframeChangeHandler}
                />
                <span style={{ marginLeft: '22px', fontSize: '20px' }}>
                  {' '}
                  {t('longerThanTheNextQuarter')}{' '}
                </span>
              </label>
              <label className="kpi-wizard-radio-input" style={{ justifyContent: 'normal' }}>
                <input
                  style={{}}
                  type="radio"
                  name="type"
                  value="3"
                  checked={timeframe === '3'}
                  onChange={timeframeChangeHandler}
                />
                <span style={{ marginLeft: '22px', fontSize: '20px' }}>
                  {' '}
                  {t('theWorkIsOnGoing')}{' '}
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default KPITimeframeQuestion

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getObjectiveById = async (objId) => {
  const { data } = await axios.get(`${BASE_URL}${`/get_obj_by_id?obj_id=${objId}`}`)
  return data
}

export default function useGetObjectiveById(objId) {
  return useQuery(['singleObjective', objId], () => getObjectiveById(objId), {
    refetchOnWindowFocus: false,
    enabled: Boolean(objId),
  })
}

import React from 'react'
import './style.css'
import ChatInput from './component/ChatInput'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import BotMessage from './component/BotMessage'
import SummaryMessage from './component/SummaryMessage'
import TeamPerformanceMessage from './component/TeamPerformanceMessage'
import { useTranslation } from 'react-i18next'

const ChatBot = () => {
  const { t } = useTranslation(['Common'])
  const [isCollapsed, setIsCollapsed] = React.useState(
    JSON.parse(localStorage.getItem('isCollapsed') || 'true'),
  )

  const [messageBubbles, setMessageBubbles] = React.useState(
    JSON.parse(
      localStorage.getItem('messageBubbles') ||
        JSON.stringify([
          {
            message: `
        <div>
            <span>Hi! I'm Odin, your AI chief-of-staff. I'm constantly learning and improving. Right now, I can help you with the following:</span>
            <ul>
                <li>Finding objectives, meeting topics, or actions that are related to something you are interested in. For example, you can say “what is going on with cyber security projects?”</li>
                <li>Explore what your team has been working on. For example, you can say “what is my team doing?”</li>
                <li>See what others are doing related to a particular topic. For example you can say “Find me everything related to X that others are working on”</li>
                <li>See what a particular person is working on. For example you can ask “What has Jo worked on recently” or “what topics has Jo added recently?”</li>
                <li>Doing all of the above for a particular date range. For example you can ask "What has my team done in the last 5 days"</li>
            </ul>
            <span>Give it a try. I'm happy to help.</span>
        </div>
    `,
            isUser: false,
            isFetched: false,
          },
        ]),
    ),
  )
  const [isLoading, setIsLoading] = React.useState(false)
  const [primaryDateMode, setPrimaryDateMode] = React.useState(null)

  React.useEffect(() => {
    // load data from local storage
    const localDataMessageBuggles = JSON.parse(
      localStorage.getItem('messageBubbles') ||
        JSON.stringify([
          {
            message: `
                <div>
                    <span>Hi! I'm Odin, your AI chief-of-staff. I'm constantly learning and improving. Right now, I can help you with the following:</span>
                    <ul>
                        <li>Finding objectives, meeting topics, or actions that are related to something you are interested in. For example, you can say “what is going on with cyber security projects?”</li>
                        <li>Explore what your team has been working on. For example, you can say “what is my team doing?”</li>
                        <li>See what others are doing related to a particular topic. For example you can say “Find me everything related to X that others are working on”</li>
                        <li>See what a particular person is working on. For example you can ask “What has Jo worked on recently” or “what topics has Jo added recently?”</li>
                        <li>Doing all of the above for a particular date range. For example you can ask "What has my team done in the last 5 days"</li>
                    </ul>
                    <span>Give it a try. I'm happy to help.</span>
                </div>
            `,
            isUser: false,
            isFetched: false,
          },
        ]),
    )
    setMessageBubbles(localDataMessageBuggles)

    const localDataIsCollapsed = JSON.parse(localStorage.getItem('isCollapsed') || 'true')
    setIsCollapsed(localDataIsCollapsed)
  }, [])

  React.useEffect(() => {
    if (messageBubbles.length > 0) {
      localStorage.setItem('messageBubbles', JSON.stringify(messageBubbles))
    }
  }, [messageBubbles])

  React.useEffect(() => {
    if (isCollapsed !== undefined) {
      localStorage.setItem('isCollapsed', JSON.stringify(isCollapsed))
    }
  }, [isCollapsed])

  const handleErrorCondition = (empty_response = false) => {
    setMessageBubbles((prev) => {
      // remove the last message which is ... from bot
      const updatedMessages = prev.slice(0, -1)

      let error_text = empty_response
        ? 'I am sorry, I did not find any relevant search results for your input.'
        : 'I am sorry, I am an early version of the D+ assistant. At this point, I can only help with search requests'
      updatedMessages.push({ message: error_text, isUser: false, isFetched: false })
      return updatedMessages
    })
  }

  const sendText = async (text) => {
    try {
      // useLLM = false for now
      let result = await getHttpRequest(`/search_v1?searchText=${text}&useLLM=${false}`)
      console.log('LOGS: ')
      console.log(JSON.parse(result.logs))

      if (result?.isTeamPerformanceQuery) {
        let teamPerformances = result.teamPerformanceResponses
        setMessageBubbles((prev) => {
          let updatedMessages = prev.slice(0, -1)
          let team_status_texts = []
          let msg = `Here's how your team is doing:`
          team_status_texts.push(msg)
          team_status_texts.push(...teamPerformances)
          updatedMessages.push({
            message: team_status_texts,
            isUser: false,
            isFetched: true,
            isConsolidationRequired: false,
            searchTerm: text,
            isTeamPerformanceQuery: true,
          })
          return updatedMessages
        })
        return
      }

      let searchVal =
        result.searchTerm != ''
          ? result.searchTerm[0].toUpperCase() + result.searchTerm.slice(1)
          : ''
      let lastUpdated = result.lastUpdated
      let searchEmpName = result.searchEmpName ? result.searchEmpName : ''
      if (lastUpdated == 1) {
        lastUpdated = '1 day'
      } else if (lastUpdated % 7 == 0 && lastUpdated != 0) {
        lastUpdated = lastUpdated / 7 == 1 ? ' week' : lastUpdated / 7 + ' weeks'
      } else {
        lastUpdated = lastUpdated + ' days'
      }

      setPrimaryDateMode(result.primaryDateMode)
      if (!result.searchResponses.length && !result.searchResponsesExactMatches.length) {
        handleErrorCondition(true)
      } else {
        setMessageBubbles((prev) => {
          // remove the last message which is ... from bot
          let updatedMessages = prev.slice(0, -1)

          if (result.searchResponsesExactMatches?.length > 0) {
            let exact_texts = []
            let msg = `Sure! Here is some stuff you might be interested in:`
            exact_texts.push(msg)
            exact_texts.push(...result.searchResponsesExactMatches)

            updatedMessages.push({
              message: exact_texts,
              isUser: false,
              isFetched: true,
              isConsolidationRequired: result.searchResponsesExactMatches.length > 5,
              searchTerm: searchVal,
              lastUpdated: lastUpdated,
              isTeamPerformanceQuery: false,
              searchEmpName: searchEmpName,
            })
          }

          if (result.searchResponses?.length > 0) {
            let similar_texts = []
            let msg = `You might also be interested in these similar things:`
            similar_texts.push(msg)
            similar_texts.push(...result.searchResponses)

            updatedMessages.push({
              message: similar_texts,
              isUser: false,
              isFetched: true,
              isConsolidationRequired: false,
              searchTerm: searchVal,
              lastUpdated: lastUpdated,
              isTeamPerformanceQuery: false,
              searchEmpName: searchEmpName,
            })
          }

          return updatedMessages
        })
      }
    } catch (err) {
      console.log('err')
      console.log(err)
      handleErrorCondition()
    }
  }

  return (
    <div className="chatbot-container">
      <div className="chatbot-header" style={{ display: 'flex', flexDirection: 'row' }}>
        <div>{t('Common:odin.askOdin')}</div>
        <div
          style={{ marginLeft: 'auto', cursor: 'pointer' }}
          onClick={() => setIsCollapsed((prev) => !prev)}
        >
          <span class="material-symbols-outlined">{isCollapsed ? 'add' : 'remove'}</span>
        </div>
      </div>

      {true && (
        <div className={`chatbot ${!isCollapsed ? 'expanded-chatbot' : ''}`}>
          <div className="message-container" style={{ height: '50vh' }}>
            {messageBubbles.length > 0 && (
              <>
                {/* Filtered and processed messages */}
                {messageBubbles.map((messageBubble, index) => {
                  // Skip rendering if it's a user message or unfetched message
                  if (messageBubble.isUser || messageBubble.isFetched === false) {
                    return (
                      <div
                        key={index}
                        className={`message-bubble ${
                          messageBubble.isUser ? 'user-bubble' : 'bot-bubble'
                        }`}
                      >
                        <div dangerouslySetInnerHTML={{ __html: messageBubble.message }} />
                      </div>
                    )
                  }
                  // Render team performance messages
                  if (messageBubble?.isTeamPerformanceQuery) {
                    return (
                      <div
                        key={index}
                        className={`message-bubble ${
                          messageBubble.isUser ? 'user-bubble' : 'bot-bubble'
                        }`}
                      >
                        <span>{messageBubble.message[0]}</span>
                        <TeamPerformanceMessage message={messageBubble.message.slice(1)} />
                      </div>
                    )
                  }
                  // Render consolidated messages first
                  else if (messageBubble?.isConsolidationRequired) {
                    return (
                      <div
                        key={index}
                        className={`message-bubble ${
                          messageBubble.isUser ? 'user-bubble' : 'bot-bubble'
                        }`}
                      >
                        <div>
                          {/* once we found something, display template message first, followed by the results */}
                          <span>{messageBubble.message[0]}</span>
                          <SummaryMessage
                            message={messageBubble.message}
                            searchTerm={messageBubble.searchTerm}
                            lastUpdated={messageBubble.lastUpdated}
                            searchEmpName={messageBubble.searchEmpName}
                          />
                        </div>
                      </div>
                    )
                  } else {
                    // Render non-consolidated messages
                    return (
                      <div
                        key={index}
                        className={`message-bubble ${
                          messageBubble.isUser ? 'user-bubble' : 'bot-bubble'
                        }`}
                      >
                        <div>
                          {/* once we found something, display template message first, followed by the results */}
                          <span>{messageBubble.message[0]}</span>
                          <ul>
                            {messageBubble.message.slice(1).map((textDict, idx) => (
                              <li
                                key={index}
                                style={{
                                  marginTop: '10px',
                                  animationName: 'fadeIn',
                                  animationDuration: '0.2s',
                                  animationDelay: `${index * 0.1}s`, // delay of 0.5s per item
                                  animationFillMode: 'both',
                                }}
                              >
                                <BotMessage
                                  message={textDict}
                                  primaryDateMode={primaryDateMode}
                                  lastUpdated={messageBubble.lastUpdated}
                                  searchEmpName={messageBubble.searchEmpName}
                                />
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )
                  }
                })}
              </>
            )}
          </div>
          <ChatInput
            sendText={sendText}
            messageBubbles={messageBubbles}
            setMessageBubbles={setMessageBubbles}
          />
        </div>
      )}
    </div>
  )
}

export default ChatBot

import React from 'react'
import Modal from '../Modal'
import ModalSaveButton from '../ModalSubmitButton'
import useSuggestedActivitiesData from '../../api/query/useSuggestedActivitiesData'
import InPageLoader from '../InPageLoader'
import { colors } from '../../utils/colors'
import { useTranslation } from 'react-i18next'

const SuggestedActivitiesModal = ({
  objective,
  handleClose,
  addActivities,
  handleOpenErrorModalMessage,
}) => {
  const {
    data: activitiesData,
    isFetching: isActivitiesFetching,
    error: activitiesError,
  } = useSuggestedActivitiesData(objective.id)
  const { t } = useTranslation(['Common'])

  //Swetha : Commenting as the Error Modal is giving issues.
  // React.useEffect(() => {
  //   if (activitiesError) {
  //     handleOpenErrorModalMessage(
  //       'We’re having some difficulty generating suggestions. Please try again.'
  //     )
  //     handleClose()
  //   }
  // }, [activitiesError])

  const [isChecked, setIsChecked] = React.useState(
    new Array(activitiesData?.activities.length).fill(false),
  )

  if (isActivitiesFetching) {
    return (
      <Modal closeModal={handleClose} width={'35rem'}>
        <InPageLoader inComponent={true} />
      </Modal>
    )
  }
  if (!isActivitiesFetching && activitiesError) {
    handleOpenErrorModalMessage(t('suggestActivitiesModal.errorMessage'))
  }

  const handleCheckBoxClick = (index, e) => {
    let newChecks = [...isChecked]
    newChecks[index] = e.target.checked
    setIsChecked(newChecks)
  }

  const handleSubmit = () => {
    let activities = []
    for (let i = 0; i < isChecked.length; i++) {
      if (isChecked[i] === true) {
        let activity = {
          description: activitiesData?.activities[i],
          progress: 'NOT_STARTED',
          objectiveId: objective.id,
          milestoneId: null,
        }
        activities.push(activity)
      }
    }

    addActivities(activities)

    handleClose()
  }

  return (
    <>
      <Modal
        closeModal={() => {
          handleClose()
        }}
        width={'35rem'}
      >
        <div style={{ textAlign: 'left' }}>
          <b>{objective.statement}</b>
        </div>
        <br />
        <div style={{ textAlign: 'left', paddingBottom: '1rem' }}>
          {t('suggestActivitiesModal.title')}
        </div>
        <div style={{ textAlign: 'left' }}>
          <div style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>
            {activitiesData?.activities.map((activities, index) => (
              <>
                <div>{activities}</div>
                <div>
                  <input
                    className="capacity-checkbox"
                    type="checkbox"
                    checked={isChecked[index]}
                    onChange={(e) => handleCheckBoxClick(index, e)}
                    value={index}
                  />
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="modal-dual-btn">
          <button
            className="dual-btn negative-btn"
            onClick={() => {
              handleClose()
            }}
            type="button"
            color={colors.grey}
          >
            {t('commonButtons.cancel')}
          </button>
          <ModalSaveButton
            onClick={() => {
              handleSubmit()
            }}
          />
        </div>
      </Modal>
    </>
  )
}

export default SuggestedActivitiesModal

import React from 'react'

export const createTranslatedSteps = (t) => ({
  allMyActions: [
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.allMyAction1')}</div>,
      // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'right',
      target: '.top-top-top #uw-back-btn',
      spotlightPadding: 20,
      // scrollTo: false,
      disableBeacon: true,
      id: '1',
    },
  ],
  overdueKpiModal: [
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.overdurKpiModal1')}</div>,
      // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'auto',
      target: '#kpi-overdue-table-kpi-name-0',
      spotlightPadding: 20,
      disableBeacon: true,
      id: '6',
    },
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.overdurKpiModal2')}</div>,
      // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'auto',
      target: '#kpi-overdue-table-buttons-group-0-0',
      spotlightPadding: 20,
      disableBeacon: true,
      id: '7',
    },
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.overdurKpiModal3')}</div>,
      // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'top',
      target: '#uw-kpi-update-modal-status',
      spotlightPadding: 20,
      disableBeacon: true,
      id: '8',
    },
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.overdurKpiModal4')}</div>,
      // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'auto',
      target: '#uw-kpi-update-modal-doc',
      spotlightPadding: 20,
      disableBeacon: true,
      id: '9',
    },
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.overdurKpiModal5')}</div>,
      // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'auto',
      target: '#uw-kpi-update-modal-note',
      spotlightPadding: 20,
      disableBeacon: true,
      id: '10',
    },
    // {
    //   content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.overdurKpiModal6')}</div>,
    //   // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
    //   placement: 'auto',
    //   target: '#uw-kpi-update-modal-meeting-topic',
    //   spotlightPadding: 20,
    //   disableBeacon: true,
    //   id: '11',
    // },
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.overdurKpiModal7')}</div>,
      // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'auto',
      target: '#uw-kpi-update-modal-update-btn',
      spotlightPadding: 20,
      disableBeacon: true,
      id: '12',
    },
  ],
  meetingTopics: [
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.meetingTopics1')}</div>,
      // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'right',
      // target: '#meeting-topics-obj-blocker-header-true',
      target: '#blockers-faced',
      // spotlightPadding: -5,
      disableBeacon: true,
      id: '13',
    },
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.meetingTopics2')}</div>,
      // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'right',
      target: '#meeting-topics-add-topics-group',
      spotlightPadding: 20,
      disableBeacon: true,
      id: '14',
    },
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.meetingVoiceButton')}</div>,
      placement: 'right',
      target: '#voice-button',
      spotlightPadding: 15,
      disableBeacon: true,
      id: '15',
    },
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.meetingTopics3')}</div>,
      // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'right',
      target: '#kpi-button',
      spotlightPadding: 20,
      disableBeacon: true,
      id: '16',
    },
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.meetingTopics4')}</div>,
      // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'left',
      target: '.top-top-top .submit-update-wizard-button .submit-update-wizard-button-inner',
      spotlightPadding: 20,
      disableBeacon: true,
      id: '17',
    },
  ],
})

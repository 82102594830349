import React, { useState, useEffect } from 'react'
import { useExcoExerciseContext } from '../../context/ExcoExerciseContext'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'

const AIExerciseIntro = () => {
  const { state, dispatch } = useExcoExerciseContext()
  const { t } = useTranslation(['UpdateWizard'])
  const { handleNext, isLastStep } = useOutletContext()

  const [teamName, setTeamName] = useState('')

  return (
    <>
      <div className="wizard-step">
        <h2 className="wizard-step__title">An Introductory Exercise to AI and Prompts</h2>
        <div style={{ display: 'flex', flexDirection: 'column', width: '30rem' }}>
          <span>
            {' '}
            We've recently added an AI bot into our online experience. Your team will need to pass 3
            exercises to create the right prompts to help Maria find the product that fits her
            needs.{' '}
          </span>
        </div>
        &nbsp; &nbsp; &nbsp;
        <div
          className="sleek-button sleek-full-blue"
          onClick={() => {
            dispatch({
              type: 'SAVE_TEAM_NAME',
              teamName: teamName,
            })
            dispatch({
              type: 'SAVE_LAST_PAGE_VISITED',
              lastPageVisited: 1,
            })
            handleNext()
          }}
        >
          {' '}
          Get Started
        </div>
        {/* </div> */}
      </div>
    </>
  )
}

export default AIExerciseIntro

import React from 'react'
import governanceReducer from './governanceReducer'

export const defaultState = {
  isEmployeeModalOpen: false,
  editCeoLeaderIndex: null,
  editCsuiteLeaderIndex: null,
  editAreaLeaderIndex: null,
  editTeamLeaderIndex: null,
  editTeamMemberIndex: null,

  isAreaLeaderSelected: false,
  isCeoLeaderSelected: false,
  isCsuiteLeaderSelected: false,

  selectedAreaLeaderEId: 0,
  selectedCsuiteLeaderEId: 0,
  selectedCeoLeaderEId: 0,

  isAreaLeaderListUpdated: false,
  isTeamLeaderListUpdated: false,
  isCeoLeaderListUpdated: false,
  isCsuiteLeaderListUpdated: false,
  isTeamMemberListUpdated: false,

  role: '',
  eLeaderId: null,
  data: {
    // allAreaLeadersList is for team leader dropdown
    allAreaLeadersList: [],
    // allCsuiteLeadersList is for area leader dropdown
    allCsuiteLeadersList: [],
    allTeamLeadersList: [],

    teamLeadersList: [],
    csuiteLeadersList: [],
    ceoLeadersList: [],
    // areaLeadersList is for results of all ALs under a Csuite leader
    areaLeadersList: [],
    teamMembersList: [],
  },
}

const GovernanceContext = React.createContext({
  state: defaultState,
  dispatch: () => {},
})

export const GovernanceProvider = ({ children, initialState }) => {
  const [state, dispatch] = React.useReducer(governanceReducer, initialState)
  const context = {
    state,
    dispatch,
  }

  return <GovernanceContext.Provider value={context}>{children}</GovernanceContext.Provider>
}

const useGovernanceContext = () => {
  const context = React.useContext(GovernanceContext)
  if (!context) {
    throw new Error(
      'useGovernanceContext must be used within a GovernanceProvider. Wrap a parent component in <GovernanceProvider> to fix this error',
    )
  }
  return context
}

export { useGovernanceContext }

// StreamAudioRecorder.js
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useKanbanVoice } from '../../pages/kanban/kanbanContext/KanbanContext'
import Swal from 'sweetalert2'

const StreamAudioRecorderKanban = () => {
  console.log('StreamAudioRecorderKanban')
  const { state, startRecording, stopRecording } = useKanbanVoice()
  console.log('styate: ', state)

  const { t } = useTranslation(['Kanban', 'Common'])

  // this useEffect will be triggered everytime you go to different page
  // because it's part of the navigation, and it will be re-initialized,
  // since <Navigation /> is part of the ApplicationLayout/>...

  return (
    <div style={{ display: 'flex', flexDirection: 'row', marginRight: '1rem' }}>
      {state.hasKanbanVoiceStarted ? (
        <div
          className="fitted-button blue"
          onClick={async () => {
            const result = await Swal.fire({
              title: t('Common:kanbanVoice.endWarning'),
              showCancelButton: true,
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
            })
            if (result.isConfirmed) {
              await stopRecording()
            }
          }}
          style={{ pointerEvents: 'auto', cursor: 'pointer' }}
        >
          {state.isStoppingRecording ? (
            <span
              className="loading-spinner"
              style={{ width: '1rem', height: '1rem', marginRight: '0.5rem' }}
            ></span>
          ) : (
            <span className="material-symbols-outlined icon-size" style={{ marginRight: '0.1rem' }}>
              stop_circle
            </span>
          )}
          <span
            style={{
              display: 'flex',
              fontSize: '16px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {' '}
            {state.isStoppingRecording
              ? t('Common:transcription.processingEnd')
              : t('Common:transcription.summarizing')}
          </span>
        </div>
      ) : (
        <div
          className="fitted-button blue"
          onClick={async () => {
            const result = await Swal.fire({
              title: t('Common:kanbanVoice.micOnlyPrompt'),
              showCancelButton: true,
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
            })
            console.log('result: ', result)
            if (result.isConfirmed) {
              startRecording(false)
            } else {
              startRecording(true)
            }
          }}
          style={{ pointerEvents: 'auto', cursor: 'pointer' }}
        >
          <span className="material-symbols-outlined icon-size" style={{ marginRight: '0.1rem' }}>
            play_arrow
          </span>
          <span
            style={{
              display: 'flex',
              fontSize: '16px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {' '}
            {t('Kanban:startKanbanVoice')}
          </span>
        </div>
      )}
    </div>
  )
}

export default StreamAudioRecorderKanban

import React, { useState } from 'react'

import { Handle, Position } from '@xyflow/react'
import TextArea from 'rc-textarea'
import { useTranslation } from 'react-i18next'

function MindMapTopicNode({ data, isConnectable }) {
  const [inputDescription, setInputDescription] = useState(data.label)
  // to avoid adding 'New: click to edit' to the discarded list
  const isNewTopic = () => inputDescription.trim() === 'New: click to edit'

  return (
    <div className="nodrag text-updater-node">
      <>
        {' '}
        <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
      </>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '170px',
            textAlign: 'left',
            backgroundColor: `${data.borderColor}`,
            borderRadius: '10px',
            padding: '10px',
            color: 'black',
            fontSize: '26px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '5px',
              boxShadow: 'rgb(204, 204, 204) 0px 0px 6px',
              borderRadius: '4px',
              backgroundColor: 'white',
              alignItems: 'center',
              height: '80px',
              // overflowY: 'auto',
            }}
          >
            <TextArea
              autoSize
              // rows={2}
              type="text"
              name="activity"
              style={{
                minHeight: '4rem',
                // height: '65px',
                maxHeight: '80px',
                width: '100%',
                resize: 'none',
                border: '1px solid rgb(238, 238, 238)',
                backgroundColor: 'rgba(238, 238, 238, 0.2)',
                borderRadius: '2.5px',
                fontFamily: "'Open Sans', 'Arial', 'Helvetica', 'system-ui', sans-serif",
              }}
              onChange={(e) => {
                setInputDescription(e.target.value)
              }}
              onBlur={() => {
                data.updateMindMap(inputDescription, data.label, inputDescription, data.index)
              }}
              onFocus={() => {
                data.lockMindMap()
              }}
              value={inputDescription}
              disabled={false}
            />
          </div>
        </div>
        {!data.hasChildren && !isNewTopic() && (
          <div
            style={{
              width: '25px',
              color: 'black',
              padding: '2px',
              paddingLeft: '10px',
              fontSize: '10px',
              position: 'relative',
              bottom: '15px',
              border: '0px solid #cccccc',
              borderRadius: '0px 0px 20px 0px',
              fontWeight: 'bold',
              backgroundColor: `${data.borderColor}`,
              alignSelf: 'flex-end',
            }}
            onClick={() => data.removeItem(inputDescription, data.index)}
          >
            <span class="material-symbols-outlined" style={{ fontSize: '20px' }}>
              close
            </span>
          </div>
        )}
        {!isNewTopic() && (
          <div
            class="fitted-button"
            style={{
              width: '20px',
              color: 'black',
              padding: '2px',
              fontSize: '10px',
              position: 'relative',
              bottom: data.hasChildren ? '70px' : '95px',
              left: '22px',
              border: '0px solid #cccccc',
              fontWeight: 'bold',
              backgroundColor: `${data.borderColor}`,
              alignSelf: 'flex-end',
            }}
            onClick={() => {
              data.addNewItem(data)
            }}
          >
            <span class="material-symbols-outlined">add</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default MindMapTopicNode

import React from 'react'
import { CardContainer } from '../PeopleManagment/styles'
import { getEmployeesListWithSupportTokens } from '../../../../api/services/employee.services'
import { postAccessToken } from '../../../../api/services/employee.services'
import Modal from '../../../../components/Modal'

const Support = () => {
  const [allEmplsList, setAllEmplsList] = React.useState([])
  const [showUrlModal, setShowUrlModal] = React.useState(false)
  const [tokenUrl, setTokenUrl] = React.useState(false)

  React.useEffect(() => {
    // getLeaderList()
    getAllEmps()
  }, [])

  const handleGetAccessToken = async (eJwt) => {
    const result = await postAccessToken(eJwt)
    if (result && result.token_id) {
      let loc = `https://dplus.transforml.co/api/omni/chestnut2/${result.token_id}?token=${result.pkg}`
      setTokenUrl(loc)
      setShowUrlModal(true)
    } else {
      alert('Something went wrong')
    }
    // if (result == "OK"){
    //   Toast.fire({
    //     icon: 'success',
    //     title: "Success",
    // });
    // }
  }

  async function getAllEmps() {
    const result = await getEmployeesListWithSupportTokens()
    if (result && result?.employees) setAllEmplsList(result?.employees)
  }

  return (
    <>
      {showUrlModal && (
        <Modal
          title={'URL'}
          closeModal={() => {
            setShowUrlModal(false)
          }}
          width={'50vw'}
        >
          <div style={{ wordWrap: 'break-word', width: '45vw', textAlign: 'left' }}>
            <a href={tokenUrl}>{tokenUrl}</a>
          </div>
        </Modal>
      )}

      <CardContainer>
        <table>
          {allEmplsList.map((data, key) => (
            <tr key={key}>
              <td>{data.name}</td>
              <td>
                <button onClick={() => handleGetAccessToken(data.maskedEId)}>Get token</button>
              </td>
            </tr>
          ))}
        </table>
      </CardContainer>
    </>
  )
}

export default Support

// MeetingRecordingProvider.js
import React, { useReducer, useRef, useEffect } from 'react'
import KanbanVoiceReducer, { defaultState } from './KanbanReducer'
import { getHttpRequest, postHttpRequest } from '../../../api/query/dynamicAPI'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'

const CHUNK_DURATION = 500
// send audio data every 5 seconds
const QUEUE_PEEK_INTERVAL = 5000
// const SEND_INTERVAL = 9000
// every 10 seconds, we send 20 secomds of data
const numChunksIn10seconds = (20 * 1000) / (CHUNK_DURATION * 1.0)
const EXPIRED_CHECK_INTERVAL = 10000

const KanbanContext = React.createContext({
  state: defaultState,
  dispatch: () => {},
  startRecording: () => Promise.resolve(),
  stopRecording: () => Promise.resolve(),
  accumulateDataChunks: () => {},
  sendAudioData: () => Promise.resolve(),
})

const KanbanVoiceProvider = ({ children }) => {
  const { t } = useTranslation(['Common'])

  const [state, dispatch] = useReducer(KanbanVoiceReducer, defaultState)

  const mediaRecorderRef = useRef(null)
  const audioContextRef = useRef(null)
  const chunksRef = useRef([])
  const headerChunkRef = useRef(null)
  const sendingIntervalRef = useRef(null)
  const dataIntervalRef = useRef(null)
  const expirationCheckIntervalRef = useRef(null)

  const displayStreamRef = useRef(null)
  const audioStreamRef = useRef(null)
  const timeoutRef = useRef(null)
  const stateRef = useRef(state)
  const dataQueue = useRef([])

  // const isReverting = useRef(false)

  const lastAudioApiCall = useRef(0)

  const initialDelayRef = useRef(null)
  // const isUpdatingMMRef = useRef(false)

  const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

  const sourceIdEventSource = useRef(null)

  useEffect(() => {
    return () => {
      cleanup()
    }
  }, [])

  useEffect(() => {
    stateRef.current = state
  }, [state])

  const startRecording = async (useMicOnly = false) => {
    try {
      dataQueue.current = []

      const response = await postHttpRequest('/meeting/start_stop_meeting_recording', {
        isStopRecording: false,
        isKanbanVoice: true,
      })

      const meetingInstanceId = response.meetingInstanceId
      const kanbanInstanceId = response.associatedInstanceId

      let displayStream = null
      if (!useMicOnly) {
        displayStream = await navigator.mediaDevices.getDisplayMedia({
          audio: true,
          video: true,
        })
        displayStreamRef.current = displayStream
      }

      const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false })

      // displayStreamRef.current = displayStream
      audioStreamRef.current = audioStream

      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)()

      const audioSourceNode = audioContextRef.current.createMediaStreamSource(audioStream)

      const destinationNode = audioContextRef.current.createMediaStreamDestination()
      audioSourceNode.connect(destinationNode)

      if (displayStream) {
        const displaySourceNode = audioContextRef.current.createMediaStreamSource(displayStream)
        displaySourceNode.connect(destinationNode)
      }

      mediaRecorderRef.current = new MediaRecorder(destinationNode.stream)

      dispatch({ type: 'SET_STREAM', stream: destinationNode.stream })

      dispatch({
        type: 'START_RECORDING',
        meetingInstanceId,
        kanbanInstanceId,
        expiredTs: Math.floor(Date.now() / 1000) + 60 * 75,
      })

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          if (headerChunkRef.current === null) {
            headerChunkRef.current = event.data
          } else {
            chunksRef.current.push({
              timestamp: Math.floor(Date.now() / 1000),
              data: event.data,
            })
            if (chunksRef.current.length > numChunksIn10seconds) {
              chunksRef.current.shift()
            }
          }
        }
      }

      mediaRecorderRef.current.start(CHUNK_DURATION)

      // dataIntervalRef.current = setInterval(() => {
      //   accumulateDataChunks()
      // }, SEND_INTERVAL)

      sendingIntervalRef.current = setInterval(() => {
        accumulateDataChunks()
        sendAudioData(meetingInstanceId, true)
      }, QUEUE_PEEK_INTERVAL)

      expirationCheckIntervalRef.current = setInterval(() => {
        checkExpiration()
      }, EXPIRED_CHECK_INTERVAL)

      sourceIdEventSource.current = null
    } catch (err) {
      console.log(err)
      const result = await Swal.fire({
        title: t('Common:transcription.recordingError'),
        icon: 'error',
        confirmButtonText: 'OK',
      })
    }
  }

  const cleanup = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop()

      audioContextRef.current.close()
    }

    if (sendingIntervalRef.current) {
      clearInterval(sendingIntervalRef.current)
    }
    if (dataIntervalRef.current) {
      clearInterval(dataIntervalRef.current)
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    if (expirationCheckIntervalRef.current) {
      clearInterval(expirationCheckIntervalRef.current)
    }

    if (displayStreamRef.current) {
      displayStreamRef.current.getTracks().forEach((track) => track.stop())
    }

    if (audioStreamRef.current) {
      audioStreamRef.current.getTracks().forEach((track) => track.stop())
    }
  }

  const checkExpiration = async () => {
    let currentTimestamp = Math.floor(Date.now() / 1000)
    if (currentTimestamp > stateRef.current.expiredTs) {
      await stopRecording()
    }
  }

  const stopRecording = async () => {
    dispatch({
      type: 'SET_IS_STOPPING_RECORDING',
      isStoppingRecording: true,
    })

    accumulateDataChunks()
    await sendAudioData(stateRef.current.meetingInstanceId, true)

    cleanup()

    const response = await postHttpRequest('/meeting/start_stop_meeting_recording', {
      isStopRecording: true,
      meetingInstanceId: stateRef.current.meetingInstanceId,
    })
    if (response.error) {
      console.error('Error stopping recording:', response.error)
    }

    dispatch({ type: 'STOP_RECORDING' })
  }

  const accumulateDataChunks = () => {
    // TODO do we need chunksRef.current.length < numChunksIn10seconds?
    // 1. user speaks for 5 seoncds then stops (triggers VAD)
    if (chunksRef.current.length === 0 || headerChunkRef.current === null) return

    const formData = new FormData()
    const blobParts = [headerChunkRef.current].concat(chunksRef.current.map((chunk) => chunk.data))
    const combinedBlob = new Blob(blobParts, { type: 'audio/webm' })
    formData.append('audio', combinedBlob, 'combined_chunks.webm')
    formData.append('timestamp_start', chunksRef.current[0].timestamp.toString())
    formData.append(
      'timestamp_end',
      chunksRef.current[chunksRef.current.length - 1].timestamp.toString(),
    )
    dataQueue.current = []
    dataQueue.current.push({ data: formData })
  }

  //TODO  do we need isProcessingDataQueue.current?
  // 1. if we don't care about transcript, I think we can have multiple requests at the same time
  // else, we still need it
  const sendAudioData = async (meetingInstanceId, user_init = true) => {
    try {
      if (
        dataQueue.current.length === 0 ||
        !headerChunkRef.current ||
        stateRef.current.isRecordingStop ||
        stateRef.current.selectedObjectiveId === null
      )
        return

      let selectedObjId = stateRef.current.selectedObjectiveId

      let formData = dataQueue.current.shift()
      formData.data.append(
        'objActionMap',
        JSON.stringify(stateRef.current.objActionMap[selectedObjId] ?? []),
      )

      lastAudioApiCall.current = Math.floor(Date.now() / 1000)
      let response = await postHttpRequest(
        `/meeting/process_audio_chunks_kanban/${meetingInstanceId}/${selectedObjId}`,
        formData.data,
      )
      // explicitly check for all these conditions

      console.log('Chunks sent successfully')
      // dispatch({
      //   type: 'SET_GEMINI_RESPONSE',
      //   geminiResponse: response.response + 'obj_id: ' + selectedObjId,
      // })
      if (response.extra_data) {
        let side_effects = JSON.parse(response.extra_data)
        if (side_effects.should_reload) {
          dispatch({
            type: 'SET_SHOULD_REFRESH_OBJ_ID',
            shouldRefreshObjId: selectedObjId,
            request: response.response ? JSON.parse(response.response) : null,
          })
        }
        if (side_effects.last_updated_milestone_id) {
          dispatch({
            type: 'SET_LAST_UPDATED_MILESTONE_ID',
            lastUpdatedMilestoneId: side_effects.last_updated_milestone_id,
          })
        }
      }
    } catch (error) {
      console.error('Error sending chunks to API:', error)
    }
  }

  // sleep time expects milliseconds
  function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time))
  }

  return (
    <KanbanContext.Provider
      value={{
        state,
        dispatch,
        startRecording,
        stopRecording,
        accumulateDataChunks,
        sendAudioData,
      }}
    >
      {children}
    </KanbanContext.Provider>
  )
}

const useKanbanVoice = () => {
  const context = React.useContext(KanbanContext)
  if (!context) {
    throw new Error('useKanbanVoice must be used within a KanbanVoiceProvider.')
  }
  return context
}

export { KanbanVoiceProvider, useKanbanVoice }

import React from 'react'
import edit from '../../../../assets/edit.svg'
import deleteCross from '../../../../assets/delete-cross.svg'
import plus from '../../../../assets/plus.svg'
import exclaim from '../../../../assets/exclaim.svg'
import help from '../../../../assets/help.svg'
import checkmark from '../../../../assets/checkmark.svg'
import { useSetupWizardContext } from '../../context/setupWizardContext'
import ObjectiveTypeHelpModal from '../../../../components/ObjectiveTypeHelpModal'
import SetupAddEditObjectiveModal from '../SetupAddEditObjectiveModal'

import './styles.css'

const AreaLevelObjectiveStep = () => {
  const { state, dispatch } = useSetupWizardContext()

  const openHelpModal = () => {
    dispatch({ type: 'OPEN_OBJ_HELP_MODAL' })
  }

  const closeHelpModal = () => {
    dispatch({ type: 'CLOSE_OBJ_HELP_MODAL' })
  }

  const GOAL_KEY = 'areaLevelObjectives'
  const GOAL_TYPE = 'AREA_LEVEL'

  return (
    <div className="area-objective-step-component">
      <ObjectiveTypeHelpModal isOpen={state.isObjHelpModalOpen} handleClose={closeHelpModal} />
      <SetupAddEditObjectiveModal goalKey={GOAL_KEY} goalType={GOAL_TYPE} />

      <div className="step-section objectives-step">
        <h2>Let’s define our Area Level Objectives</h2>
        <div className="objective-instructions">
          <div className="instruction">
            <img src={checkmark} alt="checkmark" />
            <span>
              There should include both objectives that tie back to an Enterprise objective as well
              as objectives that are specific to the Area only.
            </span>
          </div>
          <div className="instruction">
            <img src={checkmark} alt="checkmark" />
            <span>
              An example of an Area Specific Objective could be “streamline the user interface of
              the mobile app,” which might not be an enterprise objective but may be important to
              the Area.
            </span>
          </div>
        </div>

        <div className="goal-container shadow">
          {state.data[GOAL_KEY] ? (
            state.data[GOAL_KEY].map((objective, index) => (
              <div className="goal" key={`obj-${index}`}>
                <div className="input-wrapper">
                  {!objective.isValid && <img src={exclaim} className="warning" alt="Warning" />}

                  <div className={`goal-input ${objective.isValid ? 'valid' : 'invalid'}`}>
                    <span className="objective-statement">{objective.statement}</span>
                  </div>
                </div>

                <div
                  className="edit-button"
                  onClick={() => {
                    dispatch({ type: 'OPEN_OBJ_FORM_MODAL', editObjectiveIndex: index })
                  }}
                >
                  <img className="edit-btn" src={edit} alt="Click to edit symbol" />
                </div>
                <div
                  className="delete-button"
                  onClick={() => {
                    dispatch({ type: 'DELETE_AREA_LEVEL_GOAL', objectiveIndex: index })
                  }}
                >
                  <img className="delete-btn" src={deleteCross} alt="Click to edit symbol" />
                </div>
              </div>
            ))
          ) : (
            <b className="no-objectives-text">You don't currently have any objectives...</b>
          )}
          <div
            className="add-btn"
            onClick={() => {
              dispatch({ type: 'OPEN_OBJ_FORM_MODAL', editObjectiveIndex: null })
            }}
          >
            <img className="plus" src={plus} alt="TransforML Logo" />
            <span>ADD OBJECTIVE</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AreaLevelObjectiveStep

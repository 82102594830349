export default function validate(form) {
  const errors = {}

  if ('name' in form && !form.name) {
    errors.name = 'Field label is required'
  }

  if ('fieldType' in form && !form.fieldType) {
    errors.fieldType = 'Type is required'
  }

  if (form?.fieldType === 4 && 'listId' in form && form.listId === null) {
    errors.listId = 'List option is required'
  }

  if (form?.fieldType === 6 && 'listId' in form && form.listId === null) {
    errors.listId = 'Pre-built List option is required'
  }

  return errors
}

import React from 'react'

import { useSetupWizardContext } from '../../context/setupWizardContext'
import './styles.css'
import TeamLeaderCarousel from './components/TeamLeaderCarousel'

const IndividualContStep = () => {
  const { state } = useSetupWizardContext()

  return (
    <>
      <div className="step-section team-step">
        <h2>Now let’s add the Team Members under each Team Leader</h2>

        <p style={{ textAlign: 'left' }} className="step-details-p">
          These should be the individual contributors that directly reports to each Team Leader.
          Later on, the Team Leader can add other team members that may be helping out on their
          team.
        </p>

        <TeamLeaderCarousel
          individualContributorArray={state.data.individualContributor}
          teamLeaderArray={state.data.teamLeader}
        />
      </div>
    </>
  )
}

export default IndividualContStep

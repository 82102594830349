import React from 'react'
import Modal from '../Modal'
import { useTranslation } from 'react-i18next'
import './style.css'
import Textarea from 'rc-textarea'
import { postHttpRequest } from '../../api/query/dynamicAPI'
import Swal from 'sweetalert2'
import Toast from '../../common/toast'

const ObjMapSavedObjsModal = ({
  isModalOpen,
  closeModal,
  savedSearchedObjectives = [],
  totalCardDisplay = [],
  setSavedSearchedObjectives = () => {},
  setSearchedObjectives,
  setIsSavedResultsClicked,
}) => {
  const { t } = useTranslation(['ObjectiveMap', 'Common'])

  const findMatchingObjectives = (goalIds) => {
    let results = totalCardDisplay.filter((card) => goalIds.includes(card.id))
    return results
  }

  const changeText = (text, index) => {
    const dataArray = [...savedSearchedObjectives]
    const group = dataArray[index]
    group.groupName = text
    setSavedSearchedObjectives(dataArray)
  }

  const updateGroup = async ({ isFav, name, isBeingDeleted, index }) => {
    const dataArray = [...savedSearchedObjectives]
    const group = dataArray[index]

    let postBody = {
      searchId: group.searchId,
    }
    postBody.isFav = isFav
    group.isFav = isFav

    postBody.groupName = name
    group.groupName = name

    if (isBeingDeleted === true) {
      const result = await Swal.fire({
        title: t('Common:modalMessages.areYouSureDeleteThis'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: t('Common:commonButtons.yes'),
        cancelButtonText: t('Common:commonButtons.no'),
      })

      if (!result.isConfirmed) {
        return
      }
    }

    postBody.isDeleted = isBeingDeleted
    group.isDeleted = isBeingDeleted

    try {
      const result = await postHttpRequest('/update_saved_objectives', postBody)
      if (result) {
        setSavedSearchedObjectives(dataArray)
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.updatedSuccessfully'),
        })
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={() => {
        closeModal()
      }}
      width={'75%'}
      showCard={true}
      title={t('menu.savedSearchesTitle')}
    >
      <div style={{ textAlign: 'left', fontSize: '20px' }}>
        {totalCardDisplay.length > 0 && savedSearchedObjectives.length > 0 ? (
          <div className="saved-searched-objectives-table">
            <div className="chart-log-meeting-header"></div>
            <div className="chart-log-meeting-header title-border">{t('menu.name')}</div>
            <div className="chart-log-meeting-header title-obj-map-border">
              {t('menu.objectives')}
            </div>
            <div className="chart-log-meeting-header">{''}</div>

            {savedSearchedObjectives?.map((group, index) => {
              if (group.isDeleted) {
                return <></>
              }
              let matchingObjsFromtotalCardDisplay = findMatchingObjectives(group.goalIds)
              return (
                <>
                  <div
                    className="chart-log-meeting-description"
                    style={{ gridColumn: '1 / 10' }}
                  ></div>
                  <React.Fragment key={`objective-${group.searchId}`}>
                    <div style={{ margin: '0 0 0 3rem' }}>
                      <button class="icon-button-no-border" style={{ display: 'inline-block' }}>
                        <span
                          class={
                            group.isFav
                              ? 'material-symbols-outlined icon-size'
                              : 'material-symbols-outlined icon-size'
                          }
                          style={{ color: group.isFav ? 'red' : '' }}
                          onClick={() => {
                            updateGroup({
                              isFav: !group.isFav,
                              name: group.groupName,
                              isBeingDeleted: false,
                              index,
                            })
                          }}
                          onKeyDown={() => {
                            updateGroup({
                              isFav: !group.isFav,
                              name: group.groupName,
                              isBeingDeleted: false,
                              index,
                            })
                          }}
                        >
                          flag
                        </span>
                      </button>
                    </div>
                    <div>
                      <Textarea
                        style={{ border: 'none' }}
                        autoSize
                        className="body_bottom_text"
                        value={group.groupName}
                        onChange={(e) => changeText(e.target.value, index)}
                        onBlur={(e) =>
                          updateGroup({
                            isFav: group.isFav,
                            name: group.groupName,
                            isBeingDeleted: false,
                            index,
                          })
                        }
                        disabled={false}
                      ></Textarea>
                    </div>
                    <div style={{ width: '90%' }}>
                      {matchingObjsFromtotalCardDisplay?.map((objective, objInd) => {
                        return (
                          <div class=" tooltip">
                            <div className="goal-text">{objective.goal}</div>
                            <span class="tooltiptext" style={{ width: '20rem', left: '10%' }}>
                              {objective.goal ?? ''}{' '}
                            </span>
                          </div>
                        )
                      })}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div
                        class="fitted-button red"
                        onClick={() => {
                          updateGroup({
                            isFav: group.isFav,
                            name: group.groupName,
                            isBeingDeleted: true,
                            index,
                          })
                        }}
                        onKeyDown={() => {
                          updateGroup({
                            isFav: group.isFav,
                            name: group.groupName,
                            isBeingDeleted: true,
                            index,
                          })
                        }}
                      >
                        <span class=" tooltip" style={{ height: '20px', width: '20px' }}>
                          <span class="material-symbols-outlined icon-size">close</span>
                          <span class="tooltiptext" style={{ width: '5rem', marginLeft: '-50px' }}>
                            {t('menu.delete')}{' '}
                          </span>
                        </span>
                      </div>

                      <div
                        className="fitted-button blue"
                        onClick={() => {
                          setSearchedObjectives(matchingObjsFromtotalCardDisplay)
                          setIsSavedResultsClicked(true)
                          closeModal()
                        }}
                        style={{ marginLeft: '1rem' }}
                      >
                        <span class=" tooltip" style={{ height: '20px', width: '20px' }}>
                          <span className="material-symbols-outlined icon-size">
                            arrow_forward_ios
                          </span>
                          <span class="tooltiptext" style={{ width: '7rem', marginLeft: '-70px' }}>
                            {t('menu.select')}{' '}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div class="row-border" style={{ gridColumn: '1 / 10' }}></div>
                  </React.Fragment>
                </>
              )
            })}
          </div>
        ) : (
          <div>
            <div style={{ textAlign: 'center' }}>{t('menu.noData')}</div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default ObjMapSavedObjsModal

import React, { useState } from 'react'
import './style.css'
import { dayMonthFilter } from '../../../utils/time'
import { shortenName } from '../../../utils/general'
import { useNavigate } from 'react-router-dom'
import { getCompanyConfigSettings, hasRole } from '../../../utils/auth'
import { routes } from '../../../utils/routes'
import CardModal from '../../CardModal'
import ObjMapActivitiesModal from '../../../pages/objectiveFlow/modelComponent/activitiesModal'
import CommentsModal2 from '../../CommentsModal2'
import { removeMentionMarkupFromText } from '../../../utils/parseMarkup'

const SummaryMessage = ({ message, searchTerm, lastUpdated, searchEmpName }) => {
  const [objectiveState, setObjectiveState] = useState({
    count: 0,
    message: '',
    details: [],
    showDetails: false,
  })

  const [topicState, setTopicState] = useState({
    count: 0,
    message: '',
    details: [],
    showDetails: false,
  })

  const [milestoneState, setMilestoneState] = useState({
    count: 0,
    message: '',
    details: [],
    showDetails: false,
  })

  const [commentState, setCommentState] = useState({
    count: 0,
    message: '',
    details: [],
    showDetails: false,
  })

  const navigate = useNavigate()
  const productSettings = getCompanyConfigSettings('productSettings')
  const isDelegateTl = parseInt(localStorage.getItem('dType')) === 5 ? true : false

  const [topicId, setTopicId] = React.useState(0)
  const [topicType, setTopicType] = React.useState(0)
  const [isTopicModalOpen, setIsTopicModalOpen] = React.useState(false)
  const [isActivitiesModalOpen, setIsActivitiesModalOpen] = React.useState(false)
  const [objId, setObjId] = React.useState(0)
  const [activityId, setActivityId] = React.useState(0)

  const [isCommentsModalOpen, setIsCommentsModalOpen] = React.useState(false)
  const [objectiveSelected, setObjectiveSelected] = React.useState(null)
  const [commentId, setCommentId] = React.useState(0)

  React.useEffect(() => {
    processMessage(message)
  }, [message])

  const processMessage = (message) => {
    let objectiveOwnersList = new Set()
    let objProgressCount = 0
    let objNoProgressCount = 0
    let objCount = 0

    let topicOwnersList = new Set()
    let topicCount = 0
    let topicProgressCount = 0
    let topicNoProgressCount = 0
    let topicResolvedCount = 0

    let milestoneCount = 0
    let newMilestoneCount = 0
    let closedMilestoneCount = 0
    let updatedMilestoneCount = 0
    let oldMilestoneCount = 0

    let cCount = 0
    let commentsOwnerList = new Set()

    message.forEach((item) => {
      const { textType, name, isRecentlyUpdated, closedTime, milestoneStatus, description } = item
      if (textType === 'OBJECTIVE_TYPE') {
        if (isRecentlyUpdated) {
          objProgressCount += 1
        } else {
          objNoProgressCount += 1
        }
        objCount += 1

        if (!objectiveOwnersList.has(name)) {
          objectiveOwnersList.add(shortenName(name))
        }
      }

      if (textType === 'UNIFIED_TOPIC_TYPE') {
        topicCount += 1
        if (isRecentlyUpdated) {
          topicProgressCount += 1
        } else {
          topicNoProgressCount += 1
        }

        if (!topicOwnersList.has(name)) {
          topicOwnersList.add(shortenName(name))
        }

        if (closedTime && closedTime > 0) {
          topicResolvedCount += 1
        }
      }

      if (textType === 'MILESTONE_TYPE') {
        milestoneCount += 1

        if (milestoneStatus === 'new') {
          newMilestoneCount += 1
        } else if (milestoneStatus === 'closed') {
          closedMilestoneCount += 1
        } else if (milestoneStatus === 'updated') {
          updatedMilestoneCount += 1
        } else if (milestoneStatus === 'old') {
          oldMilestoneCount += 1
        }
      }

      if (textType === 'COMMENT_TYPE') {
        cCount += 1
        if (!commentsOwnerList.has(name)) {
          commentsOwnerList.add(shortenName(name))
        }
      }
    })

    generateObjectiveMessage(objectiveOwnersList, objCount, objProgressCount, objNoProgressCount)
    generateTopicMessage(
      topicOwnersList,
      topicCount,
      topicProgressCount,
      topicNoProgressCount,
      topicResolvedCount,
    )

    const counts = {
      new: newMilestoneCount,
      closed: closedMilestoneCount,
      updated: updatedMilestoneCount,
      'not updated': oldMilestoneCount,
    }

    generateMilestoneMessage(milestoneCount, counts, searchTerm)

    if (cCount > 0) {
      let commentMsg = ''
      if (searchTerm !== '') commentMsg = `${searchTerm} was mentioned in ${cCount}`
      else {
        let [ownersListString, _] = generateOwnersList(commentsOwnerList)
        commentMsg = `<b>${ownersListString}</b> added ${cCount}`
      }
      commentMsg += cCount > 1 ? ' comments' : ' comment'
      setCommentState({
        count: cCount,
        message: commentMsg,
        details: message.filter((item) => item.textType === 'COMMENT_TYPE'),
        showDetails: commentState.showDetails,
      })
    }
  }

  const generateOwnersList = (ownersList) => {
    let ownersListArray = Array.from(ownersList)
    let ownersListString =
      ownersListArray.length > 1
        ? ownersListArray.slice(0, -1).join(', ') + ' and ' + ownersListArray.slice(-1)
        : ownersListArray.join(', ')

    return [ownersListString, ownersListArray.length]
  }

  const generateObjectiveMessage = (
    objectiveOwnersList,
    objCount,
    objProgressCount,
    objNoProgressCount,
  ) => {
    let [objOwners, noOfOwners] = generateOwnersList(objectiveOwnersList)

    let objMsg = ''
    if (objCount > 0) {
      let searchTermMsg = ' related to ' + searchTerm + ','
      if (noOfOwners > 1) {
        objMsg = `<b>${objOwners}</b> own ${objCount} objectives`
      } else {
        objMsg = `<b>${objOwners}</b> owns ${objCount} objective`
      }

      objMsg += searchTerm !== '' ? searchTermMsg : ','

      if (objProgressCount > 0 && objProgressCount === objCount) {
        objMsg += ' where there has been recent progress'
      } else if (objNoProgressCount > 0 && objNoProgressCount === objCount) {
        objMsg += ' where there has been no progress recently'
      } else {
        objMsg += generateObjectivePartialProgressMessage(objProgressCount, objNoProgressCount)
      }

      setObjectiveState({
        count: objCount,
        message: objMsg,
        details: message.filter((item) => item.textType === 'OBJECTIVE_TYPE'),
        showDetails: objectiveState.showDetails,
      })
    }
  }

  const generateObjectivePartialProgressMessage = (progressCount, noProgressCount) => {
    let str = ` of which ${progressCount}  ${
      progressCount > 1 ? 'have' : 'has'
    } shown progress recently and ${noProgressCount} ${
      noProgressCount > 1 ? 'have' : 'has'
    } no activity in the ${lastUpdated}`
    return str
  }

  const generateTopicMessage = (
    topicOwnersList,
    topicCount,
    topicProgressCount,
    topicNoProgressCount,
    topicResolvedCount,
  ) => {
    let [topicOwners, noOfOwners] = generateOwnersList(topicOwnersList)

    let topicMsg = ''
    if (topicCount > 0) {
      let searchTermMsg = ' related to ' + searchTerm + ','

      if (noOfOwners > 1) {
        topicMsg = `<b>${topicOwners}</b> have added ${topicCount} topics`
      } else {
        topicMsg = `<b>${topicOwners}</b> has added ${topicCount}`
        topicMsg += topicCount > 1 ? ' topics' : ' topic'
      }

      topicMsg += searchTerm !== '' ? searchTermMsg : ','

      if (topicProgressCount > 0 && topicProgressCount === topicCount) {
        topicMsg += ' where there has been movement recently'
      } else if (topicNoProgressCount > 0 && topicNoProgressCount === topicCount) {
        topicMsg += ' where there has been no movement recently'
      } else {
        topicMsg += generateTopicPartialProgressMessage(
          topicProgressCount,
          topicNoProgressCount,
          topicResolvedCount,
        )
      }

      // if (topicResolvedCount > 0 && searchTerm !== '') {
      //     let hasHave = topicResolvedCount > 1 ? "have" : "has";
      //     topicMsg += ` and ${topicResolvedCount} of them ${hasHave} been resolved`;
      // }

      setTopicState({
        count: topicCount,
        message: topicMsg,
        details: message.filter((item) => item.textType === 'UNIFIED_TOPIC_TYPE'),
        showDetails: topicState.showDetails,
      })
    }
  }

  const generateTopicPartialProgressMessage = (
    progressCount,
    noProgressCount,
    topicResolvedCount,
  ) => {
    let topicstr = ` of which ${progressCount} ${progressCount > 1 ? 'have' : 'has'} movement${
      topicResolvedCount > 0 ? ',' : ' and '
    }  ${noProgressCount} ${
      noProgressCount > 1 ? 'have' : 'has'
    } no update in the last ${lastUpdated}`
    return topicstr
  }

  const generateMilestoneMessage = (milestoneCount, counts, searchTerm) => {
    let milestoneMsg = ''

    if (milestoneCount > 0) {
      const actions = []

      for (const [actionType, count] of Object.entries(counts)) {
        if (count > 0) {
          actions.push(
            `${count} ${count === 1 ? 'is' : 'are'} ${count === 1 ? actionType : actionType}`,
          )
        }
      }
      let searchTermMsg = searchTerm !== '' ? 'related to ' + searchTerm : ''
      let assignedMsg = searchTerm == '' ? 'assigned' : ''
      let searchEmpNameValue = searchEmpName.toLowerCase().trim()

      if (searchEmpNameValue !== '') {
        if (searchEmpNameValue === 'me') {
          searchEmpNameValue = 'you'
        } else if (searchEmpNameValue === 'my_team') {
          searchEmpNameValue = 'your team'
        } else if (searchEmpNameValue === 'other_team') {
          searchEmpNameValue = 'the other team'
        } else {
          searchEmpNameValue = searchEmpName
        }
      }

      assignedMsg += searchEmpNameValue !== '' ? ' to ' + `<b>${searchEmpNameValue}</b>` : ''

      if (milestoneCount === 1) {
        const singularActionType = Object.keys(counts).find((actionType) => counts[actionType] > 0)
        milestoneMsg = `There is ${counts[singularActionType]} ${singularActionType} action ${assignedMsg} ${searchTermMsg} in the last ${lastUpdated}.`
      } else {
        const actionDescription = actions.join(', ').replace(/,([^,]*)$/, ' and$1')
        milestoneMsg = `There are ${milestoneCount} actions ${assignedMsg} ${searchTermMsg} of which ${actionDescription} in the last ${lastUpdated}.`
      }

      setMilestoneState({
        count: milestoneCount,
        message: milestoneMsg,
        details: message.filter((item) => item.textType === 'MILESTONE_TYPE'),
        showDetails: milestoneState.showDetails,
      })
    }
  }

  const toggleDetails = (type) => {
    const stateSetter = {
      objective: setObjectiveState,
      topic: setTopicState,
      milestone: setMilestoneState,
      comment: setCommentState,
    }[type]

    stateSetter((prevState) => ({
      ...prevState,
      showDetails: !prevState.showDetails,
    }))
  }

  const renderObjectiveDetails = (details) => (
    <ul>
      {details.map((item, index) => (
        <li key={index}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <span>
              <b>{shortenName(item.name)}</b> owns the objective {truncateText(item.description)}
              {item.isRecentlyUpdated ? (
                <span>, which has made progress in the last {lastUpdated}</span>
              ) : (
                <span>, which does not seem to have any activity for the last {lastUpdated}</span>
              )}
            </span>
            {renderModalIcon('objective', item)}
          </div>
        </li>
      ))}
    </ul>
  )

  const topicTypeMap = {
    1: 'Blocker',
    2: 'Guidance',
    3: 'Update',
  }

  const renderTopicDetails = (details) => (
    <ul>
      {details.map((item, index) => {
        const topicType = topicTypeMap[item.topicType] || 'topic'

        const actionTime = item.closedTime !== 0 ? item.closedTime : item.openedTime
        const actionVerb = item.closedTime !== 0 ? 'resolved' : 'added'

        return (
          <li key={index}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <span>
                On {dayMonthFilter(actionTime)},<b>{shortenName(item.name)}</b> has {actionVerb} the{' '}
                {topicType} {truncateText(item.description)} <b>on</b> {truncateText(item.goalDesc)}
                {item.closedTime == 0 && (
                  <>
                    {item.isRecentlyUpdated ? (
                      <span>, which has made progress in the last {lastUpdated}</span>
                    ) : (
                      <span>
                        , which does not seem to have any activity for the last {lastUpdated}
                      </span>
                    )}
                  </>
                )}
              </span>
              {renderModalIcon('topic', item)}
            </div>
          </li>
        )
      })}
    </ul>
  )

  const renderMilestoneDetails = (details) => (
    <ul>
      {details.map((item, index) => {
        const statusMap = {
          new: 'added',
          closed: 'completed',
          updated: 'updated',
        }

        const status = statusMap[item.milestoneStatus] || 'added'

        const actionTime = item.closedTime !== 0 ? item.closedTime : item.openedTime
        let associatedDescription = ''
        let associatedType = ''
        if (item.umtId) {
          associatedDescription = item.topicDesc
          associatedType = topicTypeMap[item.topicType] || 'Topic'
        } else {
          associatedDescription = item.goalDesc
          associatedType = 'Objective'
        }
        let searchEmpNameLower = searchEmpName.toLowerCase().trim()
        let isSearchEmpNameValid =
          searchEmpNameLower !== '' &&
          searchEmpNameLower !== 'me' &&
          searchEmpNameLower !== 'my_team' &&
          searchEmpNameLower !== 'other_team'
            ? true
            : false

        let assignedToMsg = isSearchEmpNameValid ? `assigned to` : ''
        let assignedToValue = isSearchEmpNameValid ? `${searchEmpName}` : ''

        return (
          <li key={index}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <span>
                On {dayMonthFilter(actionTime)},<b>{shortenName(item.name)}</b> {status} the action{' '}
                {truncateText(removeMentionMarkupFromText(item.description))} {assignedToMsg}{' '}
                <b>{assignedToValue}</b> <b>on</b> {associatedType}{' '}
                {truncateText(associatedDescription)}
              </span>
              {renderModalIcon('milestone', item)}
            </div>
          </li>
        )
      })}
    </ul>
  )

  const renderCommentDetails = (details) => (
    <ul>
      {details.map((item, index) => {
        let associatedDescription = ''
        let associatedType = ''
        let commentType = item.isCommentDecision ? 'decision' : 'comment'
        if (item.milestoneId) {
          associatedDescription = item.milestoneDesc
          associatedType = 'Action'
        } else if (item.umtId) {
          associatedDescription = item.topicDesc
          associatedType = topicTypeMap[item.topicType] || 'Topic'
        } else {
          associatedDescription = item.goalDesc
          associatedType = 'Objective'
        }

        return (
          <li key={index}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <span>
                On {dayMonthFilter(item.openedTime)},<b>{shortenName(item.name)}</b> added a{' '}
                {commentType} {truncateText(removeMentionMarkupFromText(item.description))}{' '}
                <b>on</b> {associatedType} {truncateText(associatedDescription)}
              </span>
              {renderModalIcon('comment', item)}
            </div>
          </li>
        )
      })}
    </ul>
  )

  const renderListItems = (type, state, toggleDetails) => (
    <li>
      <div dangerouslySetInnerHTML={{ __html: state.message }}></div>{' '}
      <span className="see-more-link" onClick={() => toggleDetails(type)}>
        [{state.showDetails ? 'See Less' : 'See More'}]
      </span>
      {state.showDetails && (
        <>
          {type === 'objective' && renderObjectiveDetails(state.details)}
          {type === 'topic' && renderTopicDetails(state.details)}
          {type === 'milestone' && renderMilestoneDetails(state.details)}
          {type === 'comment' && renderCommentDetails(state.details)}
        </>
      )}
    </li>
  )

  const renderModalIcon = (type, id) => (
    <div
      onClick={() => openModal(type, id)}
      style={{ width: 'fit-content', height: 'fit-content' }}
    >
      {/* external link icon */}
      <svg width="24px" height="24px" viewBox="0 0 24 24" style={{ cursor: 'pointer' }}>
        <g strokeWidth="2.1" stroke="#666" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <polyline points="17 13.5 17 19.5 5 19.5 5 7.5 11 7.5"></polyline>
          <path d="M14,4.5 L20,4.5 L20,10.5 M20,4.5 L11,13.5"></path>
        </g>
      </svg>
    </div>
  )

  const openModal = async (type, item) => {
    if (type === 'objective') {
      const isMultiView =
        hasRole('area') ||
        hasRole('cxo') ||
        hasRole('ceo') ||
        hasRole('cos') ||
        (hasRole('team') && isDelegateTl)

      let url = productSettings ? `/${routes.summaryOfObjectives}` : `/${routes.objectivemap}`
      navigate(url, {
        state: {
          objId: item.id,
        },
      })
      // This is needed to trigger a reload of ObjectiveMap if we are already on it.
      // Otherwise, the state change (objId: message.id) will not trigger a reload.
      navigate(0)
    } else if (type === 'topic') {
      setTopicType(item.topicType)
      setTopicId(item.id)
      setIsTopicModalOpen(true)
    } else if (type === 'milestone') {
      if (item.umtId !== 0) {
        setTopicType(item.topicType)
        setTopicId(item.umtId)
        setIsTopicModalOpen(true)
      } else {
        // regular activity
        setObjId(item.goalId)
        setIsActivitiesModalOpen(true)
        setActivityId(item.id)
      }
    } else if (type === 'comment') {
      if (item.umtId !== 0) {
        setTopicType(item.topicType)
        setTopicId(item.umtId)
        setIsTopicModalOpen(true)
      } else {
        setObjectiveSelected({
          statement: item.goalDesc,
          id: item.goalId,
        })
        setIsCommentsModalOpen(true)
        setCommentId(item.id)
      }
    }
  }

  const closeCommentsModal = () => {
    setIsCommentsModalOpen(false)
    setObjectiveSelected(null)
    setCommentId(0)
  }

  const truncateText = (text) => {
    if (text.length <= 60) {
      return text
    } else {
      return text.slice(0, 60) + '...'
    }
  }

  return (
    <>
      {isTopicModalOpen === true && topicId !== 0 && topicType !== 0 && (
        <CardModal
          isModalOpen={isTopicModalOpen}
          closeModal={() => {
            setIsTopicModalOpen(false)
            setTopicId(0)
            setTopicType(0)
            // refreshBGUScorecardFn()
          }}
          umtId={topicId}
          topicType={topicType}
        />
      )}
      {isActivitiesModalOpen && objId !== 0 && activityId !== 0 && (
        <ObjMapActivitiesModal
          closeModal={() => {
            setIsActivitiesModalOpen(false)
            setObjId(0)
            setActivityId(0)
          }}
          objectiveId={objId}
          mode={'activities'}
          activityId={activityId}
        />
      )}
      {isCommentsModalOpen === true && objectiveSelected && commentId !== 0 && (
        <CommentsModal2
          objective={{ id: objectiveSelected.id, statement: objectiveSelected?.statement }}
          handleCancel={closeCommentsModal}
          displayAllComments={true}
          commentId={commentId}
        />
      )}
      <ul>
        {objectiveState.count > 0 && renderListItems('objective', objectiveState, toggleDetails)}
        {topicState.count > 0 && renderListItems('topic', topicState, toggleDetails)}
        {milestoneState.count > 0 && renderListItems('milestone', milestoneState, toggleDetails)}
        {commentState.count > 0 && renderListItems('comment', commentState, toggleDetails)}
      </ul>
    </>
  )
}

export default SummaryMessage

import React from 'react'
import { useSetupWizardContext } from '../../context/setupWizardContext'
import ObjectiveForm from './ObjectiveForm'
import ObjectiveExampleText from '../../../../components/ObjectiveExampleText'
import Modal from '../../../../components/Modal'
import './styles.css'

const SetupAddEditObjectiveModal = ({ goalKey, goalType }) => {
  const { state, dispatch } = useSetupWizardContext()
  const [resetForm, setResetForm] = React.useState(null)

  const closeModal = () => {
    dispatch({ type: 'CLOSE_OBJ_FORM_MODAL' })
    resetForm()
  }

  return (
    <>
      {state.isObjFormModalOpen === true && (
        <Modal title={'Add A New Objective'} closeModal={closeModal}>
          <ObjectiveExampleText />

          <ObjectiveForm goalKey={goalKey} goalType={goalType} setResetForm={setResetForm} />
        </Modal>
      )}
    </>
  )
}

export default SetupAddEditObjectiveModal

import React from 'react'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/core.css'
import { useTranslation } from 'react-i18next'
import { getCompanyConfigSettings } from '../../utils/auth'

function TopicsReportDropdownMenu(props) {
  const { t } = useTranslation(['MeetingHub'])

  return (
    <div style={{ margin: 'auto 0' }}>
      <Menu
        menuClassName="soo-menu"
        menuButton={
          <MenuButton style={{ backgroundColor: 'white', border: 'none', padding: '0' }}>
            <span style={{ alignSelf: 'center' }} class="material-symbols-outlined">
              more_vert
            </span>
          </MenuButton>
        }
      >
        <>
          <MenuItem style={{ textAlign: 'left' }} onClick={props.onClikcViewDetails}>
            {t('meetingTopicReport.viewDetails')}
          </MenuItem>
          {!props.fromChangesReport && (
            <MenuItem style={{ textAlign: 'left' }} onClick={props.onClickUnresolve}>
              {t('meetingTopicReport.unresolved')}
            </MenuItem>
          )}
        </>
      </Menu>
    </div>
  )
}

export default TopicsReportDropdownMenu

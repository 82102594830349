import React, { useState, useEffect } from 'react'
import InPageLoader from '../../../../components/InPageLoader'
import { useDemandIntakeContext } from '../../context/demandIntakeContext'

const F2wAvailability = () => {
  const { state, dispatch } = useDemandIntakeContext()

  return (
    <>
      <div className="wizard-step" style={{ margin: '0' }}>
        <h2 className="wizard-step__title">Team members you have available for your objectives</h2>
        <div className="wizard-step__overview">
          <div className="wizard-step__overview__paragraph">
            These are the people whose capacity isn't assigned to other teams. If you want to edit
            an assignment, you can modify from the ‘My Flow to Work members assignments’ on the
            Dashboard.
          </div>
        </div>

        <div
          className="wizard-step__main-content-container"
          style={{ display: 'flex', minWidth: 'auto', padding: '4rem' }}
        >
          {/* Might need to re-add loader, but now the data is adding from before */}
          {/* {state.isLoading === false 
           ? ( */}
          <div className="table-wrapper-for-f2w-employees" style={{ display: 'flex' }}>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                rowGap: '1rem',
                columnGap: '2rem',
              }}
            >
              {state.data.employees.map((emp, empIndex) => (
                <>
                  {emp?.teamAllocPct > 0 && (
                    <>
                      <div>
                        <b>{emp?.name}</b>
                      </div>
                      <div>{emp?.teamAllocPct} %</div>
                    </>
                  )}
                </>
              ))}
            </div>
          </div>
          {/* ) 
           : (
             <InPageLoader />
          )} */}
        </div>
      </div>
    </>
  )
}

export default F2wAvailability

import React from 'react'
import capacityReducer from './capacityReducer'

export const defaultState = {
  data: {
    objectives: [],
    linkageData: [],
    employees: [],
  },
  allocSubWiz: {
    updateObjectiveId: null,
    errorTeamCapAllocTable: false,
    triggerSelectAllEmp: false,
  },
}

const CapacityContext = React.createContext({
  state: defaultState,
  dispatch: () => {},
})

export const CapacityProvider = ({ children, initialState }) => {
  const [state, dispatch] = React.useReducer(capacityReducer, initialState)

  const context = {
    state,
    dispatch,
  }

  return <CapacityContext.Provider value={context}>{children}</CapacityContext.Provider>
}

const useCapacityContext = () => {
  const context = React.useContext(CapacityContext)
  if (!context) {
    throw new Error(
      'useCapacityContext must be used within a CapacityProvider. Wrap a parent component in <CapacityProvider> to fix this error',
    )
  }

  return context
}

export { useCapacityContext }

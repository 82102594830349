import React from 'react'
import { useTranslation } from 'react-i18next'
import MeetingTranscription from '../MeetingTranscription'

const MeetingSummarization = ({
  hasSummarizationStarted,
  isScoreCardCollapsed,
  setIsScoreCardCollapsed,
}) => {
  const { t } = useTranslation(['MeetingHub', 'Common'])

  const toggleScoreCardCollapse = () => {
    setIsScoreCardCollapsed(!isScoreCardCollapsed)
  }

  return (
    <>
      <div className={`scorecard-menu ${isScoreCardCollapsed ? ' collapsed' : 'expanded'}`}>
        {isScoreCardCollapsed ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div class="fitted-button" onClick={toggleScoreCardCollapse}>
              <span class="material-symbols-outlined">right_panel_open</span>
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div class="fitted-button" onClick={toggleScoreCardCollapse}>
                <span class="material-symbols-outlined" id="meetingHubTutorial4">
                  chevron_right
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  paddingRight: '1rem',
                  paddingTop: '0.3rem',
                  paddingLeft: '0.5rem',
                  fontSize: '13px',
                  color: '#949494',
                }}
              >
                {hasSummarizationStarted
                  ? t('transcript.summarizingMeetingInProgress')
                  : t('transcript.startSummarizingMeeting')}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                fontSize: '16px',
                fontWeight: 'bold',
                textAlign: 'left',
                marginLeft: '1rem',
                marginTop: '1rem',
                marginBottom: '0.3rem',
                color: '#5780ca',
              }}
            >
              {t('transcript.title')}
            </div>
            <div className="divider"></div>
          </div>
        )}

        <div
          class="bgu-container"
          style={{
            visibility: isScoreCardCollapsed ? 'hidden' : 'visible',
            overflow: 'auto',
          }}
        >
          <MeetingTranscription />
        </div>
      </div>
    </>
  )
}

export default MeetingSummarization

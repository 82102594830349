import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const uwManageDecision = async (decisionData) => {
  const { data } = await axios.post(`${BASE_URL}/uw_manage_decision`, decisionData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export default function useUwManageDecision() {
  const queryClient = useQueryClient()

  return useMutation((data) => uwManageDecision(data), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      queryClient.invalidateQueries('uw_meeting_topics')
    },
  })
}

import { Position, MarkerType } from '@xyflow/react'
import { color, transition } from 'd3'

// this helper function returns the intersection point
// of the line between the center of the intersectionNode and the target node
function getNodeIntersection(intersectionNode, targetNode) {
  // https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
  const { width: intersectionNodeWidth, height: intersectionNodeHeight } = intersectionNode.measured
  const intersectionNodePosition = intersectionNode.internals.positionAbsolute
  const targetPosition = targetNode.internals.positionAbsolute

  const w = intersectionNodeWidth / 2
  const h = intersectionNodeHeight / 2

  const x2 = intersectionNodePosition.x + w
  const y2 = intersectionNodePosition.y + h
  const x1 = targetPosition.x + targetNode.measured.width / 2
  const y1 = targetPosition.y + targetNode.measured.height / 2

  const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h)
  const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h)
  const a = 1 / (Math.abs(xx1) + Math.abs(yy1))
  const xx3 = a * xx1
  const yy3 = a * yy1
  const x = w * (xx3 + yy3) + x2
  const y = h * (-xx3 + yy3) + y2

  return { x, y }
}

// returns the position (top,right,bottom or right) passed node compared to the intersection point
function getEdgePosition(node, intersectionPoint) {
  const n = { ...node.internals.positionAbsolute, ...node }
  const nx = Math.round(n.x)
  const ny = Math.round(n.y)
  const px = Math.round(intersectionPoint.x)
  const py = Math.round(intersectionPoint.y)

  if (px <= nx + 1) {
    return Position.Left
  }
  if (px >= nx + n.measured.width - 1) {
    return Position.Right
  }
  if (py <= ny + 1) {
    return Position.Top
  }
  if (py >= n.y + n.measured.height - 1) {
    return Position.Bottom
  }

  return Position.Top
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(source, target) {
  const sourceIntersectionPoint = getNodeIntersection(source, target)
  const targetIntersectionPoint = getNodeIntersection(target, source)

  const sourcePos = getEdgePosition(source, sourceIntersectionPoint)
  const targetPos = getEdgePosition(target, targetIntersectionPoint)

  return {
    sx: sourceIntersectionPoint.x,
    sy: sourceIntersectionPoint.y,
    tx: targetIntersectionPoint.x,
    ty: targetIntersectionPoint.y,
    sourcePos,
    targetPos,
  }
}

function handleItem(
  item,
  index,
  {
    x,
    y,
    topicColor,
    onRemoveItem,
    lockMindMap,
    updateMindMapText,
    isReadOnly,
    forwardOpenModal,
    switchActionDecision,
    topicText,
    isConverted,
    nodes,
    topicIndex,
    overallIndex,
  },
) {
  const itemY = y + (overallIndex + 1) * 175 - 50

  const itemId = `item-${Math.random().toString(18).substr(2)}`

  nodes.push({
    id: itemId,
    type: 'mindmapItemNode',
    data: {
      label: item.statement,
      type: item.type,
      borderColor: topicColor,
      removeItem: onRemoveItem,
      isRecommended: item.isRecommended === true,
      lockMindMap: lockMindMap,
      updateMindMap: updateMindMapText,
      index: index,
      topicIndex: topicIndex,
      switchActionDecision: switchActionDecision,
      topicText: topicText,
      readOnly: isReadOnly,
      forwardOpenModal: forwardOpenModal,
      isConverted: isConverted,
      id: item.id,
      isAddedInLastRound: item.isAddedInLastRound ?? false,
    },
    position: { x: x, y: itemY },
  })
}

export function createNodesAndEdges(
  data,
  onRemoveItem,
  updateMindMapText,
  lockMindMap,
  isReadOnly,
  addNewItem,
  switchActionDecision,
  forwardOpenModal,
  addNewTopic,
) {
  const nodes = []
  const edges = []
  // const center = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
  const center = { x: window.innerWidth / 2 - 350, y: 0 }

  const colors = [
    'rgb(233,227,211)',
    'rgb(212,227,237)',
    'rgb(226,246,211)',
    'rgb(255,248,184)',
    'rgb(246,226,221)',
  ]

  let colorIndex = 0

  const getNextColor = () => {
    const color = colors[colorIndex]
    colorIndex = (colorIndex + 1) % colors.length
    return color
  }

  const estimateNodeSize = (label) => {
    const avgCharWidth = 8
    const lineHeight = 20
    const maxWidth = 150
    const words = label.split(' ')
    let lines = 1
    let currentLineLength = 0

    words.forEach((word) => {
      if ((currentLineLength + word.length) * avgCharWidth > maxWidth) {
        lines++
        currentLineLength = word.length
      } else {
        currentLineLength += word.length + 1
      }
    })

    return {
      width: Math.min(label.length * avgCharWidth, maxWidth),
      height: lines * lineHeight,
    }
  }

  // Add problem statement as the center node with a default border color
  nodes.push({
    id: 'problem',
    type: 'mindmapProblemNode',
    data: {
      label: data.problem,
      borderColor: '#000000', // Black border for the central node
      addNewTopic: addNewTopic,
    },
    position: {
      x: center.x - 100,
      y: center.y,
    },
  })

  const topicCount = data.action_plans.length

  data.action_plans.forEach((topic, index) => {
    const x = center.x + (index - (topicCount - 1) / 2) * 240
    const y = center.y + 150

    const topicColor = getNextColor()
    // force generating a unique id for each topic node otherwise react-flow will not update the node
    const topicId = `topic-${index}-source-${Math.random().toString(18)}`

    // Add topic node with colored border
    nodes.push({
      id: topicId,
      type: 'mindmapTopicNode',
      data: {
        label: topic.topic,
        borderColor: topicColor, // Set border color for topic node
        updateMindMap: updateMindMapText,
        lockMindMap: lockMindMap,
        index: index,
        // readOnly: isReadOnly,
        hasChildren:
          topic.decisions.length +
            topic.actions.length +
            (topic?.comments?.length ?? 0) +
            (topic?.recommended_actions?.length ?? 0) >
          0,
        removeItem: onRemoveItem,
        addNewItem: addNewItem,
      },
      position: { x, y },
    })

    edges.push({
      id: `edge-problem-${topicId}`,
      source: 'problem',
      target: topicId,
      type: 'default',
      style: { stroke: topicColor, strokeWidth: 4 },
    })

    let decisions = topic.decisions.map((decision) => ({
      statement: decision.text,
      type: 'decision',
      isConverted: decision?.is_converted ?? false,
      id: decision.id,
      isAddedInLastRound: decision.isAddedInLastRound,
    }))
    let comments =
      topic?.comments?.map((comment) => ({
        statement: comment.text,
        type: 'comment',
        isConverted: comment?.is_converted ?? false,
        id: comment.id,
        isAddedInLastRound: comment.isAddedInLastRound,
      })) ?? []
    let actions = topic.actions.map((action) => ({
      statement: action.text,
      type: 'action',
      isConverted: action?.is_converted ?? false,
      id: action.id,
      isAddedInLastRound: action.isAddedInLastRound,
    }))

    decisions.forEach((decision, ind) =>
      handleItem(decision, ind, {
        x,
        y,
        topicColor,
        onRemoveItem,
        lockMindMap,
        updateMindMapText,
        isReadOnly,
        forwardOpenModal,
        switchActionDecision,
        topicText: topic.topic,
        isConverted: decision.isConverted,
        nodes,
        topicIndex: index,
        overallIndex: ind,
      }),
    )
    comments.forEach((comment, ind) =>
      handleItem(comment, ind, {
        x,
        y,
        topicColor,
        onRemoveItem,
        lockMindMap,
        updateMindMapText,
        isReadOnly,
        forwardOpenModal,
        switchActionDecision,
        topicText: topic.topic,
        isConverted: comment.isConverted,
        nodes,
        topicIndex: index,
        overallIndex: ind + decisions.length,
      }),
    )
    actions.forEach((action, ind) =>
      handleItem(action, ind, {
        x,
        y,
        topicColor,
        onRemoveItem,
        lockMindMap,
        updateMindMapText,
        isReadOnly,
        forwardOpenModal,
        switchActionDecision,
        topicText: topic.topic,
        isConverted: action.isConverted,
        nodes,
        topicIndex: index,
        overallIndex: ind + decisions.length + comments.length,
      }),
    )

    if (topic?.recommended_actions?.length > 0) {
      let recommendedActions = topic.recommended_actions.map((recommendedAction) => ({
        statement: recommendedAction.text,
        type: 'action',
        isRecommended: true,
        isConverted: recommendedAction?.is_converted ?? false,
        id: recommendedAction.id,
      }))
      recommendedActions.forEach((recommendedAction, ind) =>
        handleItem(recommendedAction, ind, {
          x,
          y,
          topicColor,
          onRemoveItem,
          lockMindMap,
          updateMindMapText,
          isReadOnly,
          forwardOpenModal,
          switchActionDecision,
          topicText: topic.topic,
          isConverted: recommendedAction.isConverted,
          nodes,
          topicIndex: index,
          overallIndex: ind + decisions.length + comments.length + actions.length,
        }),
      )
    }
  })

  return { nodes, edges }
}

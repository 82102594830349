const updateReducer = (state, action) => {
  switch (action.type) {
    case 'ALLOC_SUB_WIZ__OPEN_ADD_OBJECTIVE_MODAL':
      return {
        ...state,
        isAddObjectiveModalOpen: true,
      }
    case 'ALLOC_SUB_WIZ__CLOSE_ADD_OBJECTIVE_MODAL':
      return {
        ...state,
        isAddObjectiveModalOpen: false,
      }
    case 'OPEN_ACTIVITY_MODAL':
      return {
        ...state,
        isActivityModalOpen: true,
      }
    case 'CLOSE_ACTIVITY_MODAL':
      return {
        ...state,
        isActivityModalOpen: false,
      }
    case 'OPEN_SUGGESTED_ACTIVITY_MODAL':
      return {
        ...state,
        suggestedActivitiesModalOpen: true,
      }
    case 'CLOSE_SUGGESTED_ACTIVITY_MODAL':
      return {
        ...state,
        suggestedActivitiesModalOpen: false,
      }

    case 'ADD_ACTIVITY':
      return {
        ...state,
        data: {
          ...state.data,
          milestones: [...state.data.milestones, action.activity],
        },
      }
    case 'ADD_ACTIVITIES':
      return {
        ...state,
        data: {
          ...state.data,
          milestones: [...state.data.milestones, ...action.activities],
        },
      }

    case 'EDIT_ACTIVITY':
      let milestonesEdit = state.data.milestones
      milestonesEdit[action.activityIndex] = action.activity

      return {
        ...state,
        data: {
          ...state.data,
          milestones: [...milestonesEdit],
        },
      }

    case 'DELETE_ACTIVITY':
      //for backend purposes milestones should not be deleted but only marked as deleted
      //if they are coming from backend,if they are just newly created there is no problem
      //with actually deleting them
      if (state.data.milestones[action.activityIndex].milestoneId === null) {
        let milestonesDelete = state.data.milestones.filter(
          (m, index) => index !== action.activityIndex,
        )
        return {
          ...state,
          data: {
            ...state.data,
            milestones: [...milestonesDelete],
          },
        }
      } else {
        state.data.milestones[action.activityIndex].mode = 'deleted'
      }
    case 'ALLOC_SUB_WIZ__OPEN_TEAMMEMBER_FORM_MODAL':
      return {
        ...state,
        isTeamMemberFormOpen: true,
        allocSubWiz: {
          ...state.allocSubWiz,
          updateObjectiveId: action.id,
        },
      }

    case 'ALLOC_SUB_WIZ__CLOSE_TEAMMEMBER_FORM_MODAL':
      return {
        ...state,
        isTeamMemberFormOpen: false,
        allocSubWiz: {
          ...state.allocSubWiz,
          updateObjectiveId: null,
        },
      }

    case 'ALLOC_SUB_WIZ__MOUNT_ALL_EMP':
      return {
        ...state,
        data: {
          ...state.data,
          employees: action.employees,
        },
      }

    case 'ALLOC_SUB_WIZ__ADD_MEMBERS_TO_OBJECTIVE': {
      return {
        ...state,
        data: {
          ...state.data,
          objectives: action.objectives,
        },
      }
    }
    case 'ALLOC_SUB_WIZ__SET_ALLOCATED_CAPACITY': {
      return {
        ...state,
        data: {
          ...state.data,
          objectives: action.objectives,
        },
      }
    }
    case 'ALLOC_SUB_WIZ__SET_ALLOCATED_CAPACITY_ERROR': {
      return {
        ...state,
        data: {
          ...state.data,
          objectives: action.objectives,
        },
      }
    }
    case 'ALLOC_SUB_WIZ__DISABLE_NEXT': {
      return {
        ...state,
        disableNext: true,
      }
    }
    case 'ALLOC_SUB_WIZ__ENABLE_NEXT': {
      return {
        ...state,
        disableNext: false,
      }
    }

    case 'ALLOC_SUB_WIZ__HAS_ERROR__TEAM_CAP_ALLOC_TABLE': {
      return {
        ...state,
        allocSubWiz: {
          ...state.allocSubWiz,
          errorTeamCapAllocTable: true,
        },
      }
    }

    case 'ALLOC_SUB_WIZ__NOT_HAS_ERROR__TEAM_CAP_ALLOC_TABLE': {
      return {
        ...state,
        allocSubWiz: {
          ...state.allocSubWiz,
          errorTeamCapAllocTable: false,
        },
      }
    }

    case 'ALLOC_SUB_WIZ__HAS_ERROR__ADDITIONAL_CAP_ALLOC_TABLE': {
      return {
        ...state,
        allocSubWiz: {
          ...state.allocSubWiz,
          errorAdditionalCapTable: true,
        },
      }
    }

    case 'ALLOC_SUB_WIZ__NOT_HAS_ERROR__ADDITIONAL_CAP_ALLOC_TABLE': {
      return {
        ...state,
        allocSubWiz: {
          ...state.allocSubWiz,
          errorAdditionalCapTable: false,
        },
      }
    }

    case 'ALLOC_SUB_WIZ_SET_SELECT_ALL_EMP': {
      return {
        ...state,
        allocSubWiz: {
          ...state.allocSubWiz,
          triggerSelectAllEmp: action.value,
        },
      }
    }

    case 'UPDATE_OBJECTIVES':
      const sortedObjectives = action.objectives.sort((a, b) => {
        if (a.isShared) {
          return b.isGeneralObjective ? -1 : 1
        } else if (a.isGeneralObjective) {
          return 1
        } else if (b.isShared) {
          return -1
        } else if (b.isGeneralObjective) {
          return -1
        } else {
          return 0
        }
      })

      return {
        ...state,
        data: {
          ...state.data,
          objectives: sortedObjectives,
        },
      }
    case 'UPDATE_EMPLOYEES':
      return {
        ...state,
        data: {
          ...state.data,
          employees: action.employees,
        },
      }
    case 'SET_ACTIVITY_STEP_ERROR':
      return {
        ...state,
        error: {
          ...state.error,
          milestoneStep: true,
        },
      }
    case 'REMOVE_ACTIVITY_STEP_ERROR':
      return {
        ...state,
        error: {
          ...state.error,
          milestoneStep: false,
        },
      }
    case 'OPEN_ERROR_MODAL':
      return {
        ...state,
        isErrorModalOpen: true,
      }
    case 'OPEN_ERROR_MODAL_WITH_MESSAGE':
      return {
        ...state,
        suggestedActivitiesModalOpen: false,
        errorModalMessage: action.message,
        isErrorModalOpen: true,
      }
    case 'CLOSE_ERROR_MODAL':
      return {
        ...state,
        errorModalMessage: null,
        isErrorModalOpen: false,
      }
    case 'OPEN_RESOLVE_BLOCKER_MODAL':
      return {
        ...state,
        isResolveBlockerModalOpen: true,
      }
    case 'CLOSE_RESOLVE_BLOCKER_MODAL':
      return {
        ...state,
        isResolveBlockerModalOpen: false,
      }
    case 'SET_BLOCKER_ERROR': {
      let newBlockerErrors = { ...state.blockerErrors }
      newBlockerErrors[action.objId] = true
      return {
        ...state,
        blockerErrors: newBlockerErrors,
      }
    }
    case 'UNSET_BLOCKER_ERROR': {
      let newBlockerErrors = { ...state.blockerErrors }
      newBlockerErrors[action.objId] = false
      return {
        ...state,
        blockerErrors: newBlockerErrors,
      }
    }
    case 'OPEN_MULTI_ACTIVITIES_MODAL': {
      return {
        ...state,
        isMultiActivitiesModalOpen: true,
      }
    }
    case 'CLOSE_MULTI_ACTIVITIES_MODAL': {
      return {
        ...state,
        isMultiActivitiesModalOpen: false,
      }
    }
    case 'SET_IS_TOUR_ACTIVE': {
      let isToursActiveEdit = state.joyRide.isToursActive
      isToursActiveEdit[action.tourIndex] = action.isTourActive
      return {
        ...state,
        joyRide: {
          ...state.joyRide,
          isToursActive: [...isToursActiveEdit],
        },
      }
    }
    case 'UPDATE_TOUR_INNER_STEP_INDEX': {
      return {
        ...state,
        joyRide: {
          ...state.joyRide,
          tourInnerStepIndex: action.tourInnerStepIndex,
        },
      }
    }
    case 'UPDATE_TOUR_STEP_INDEX': {
      return {
        ...state,
        joyRide: {
          ...state.joyRide,
          tourIndex: action.tourStepIndex,
        },
      }
    }
    case 'UPDATE_TOUR_STEP_NAME': {
      return {
        ...state,
        joyRide: {
          ...state.joyRide,
          stepName: action.stepName,
        },
      }
    }
    case 'UPDATE_TOUR_CALLBACK': {
      return {
        ...state,
        joyRide: {
          ...state.joyRide,
          customJoyRideCallBackFn: action.customJoyRideCallBackFn,
        },
      }
    }
    case 'SET_IS_TOUR_ACTIVE_AND_TOUR_KPI_STEP_INDEX': {
      let isToursActiveEdit = state.joyRide.isToursActive
      isToursActiveEdit[action.tourIndex] = action.isTourActive
      return {
        ...state,
        joyRide: {
          ...state.joyRide,
          isToursActive: [...isToursActiveEdit],
          tourInnerStepIndex: action.tourInnerStepIndex,
        },
      }
    }
    case 'UPDATE_TOUR_STEPS': {
      return {
        ...state,
        joyRide: {
          ...state.joyRide,
          steps: {
            ...state.joyRide.steps,
            allMyActions: state.joyRide.steps.allMyActions
              .concat(action.tourSteps)
              .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id)),
          },
        },
      }
    }
    case 'UPDATE_UW_TUTORIAL_OFF': {
      return {
        ...state,
        joyRide: {
          ...state.joyRide,
          isTutorialOff: action.isTutorialOff,
        },
      }
    }
    case 'INIT_STEPS': {
      return {
        ...state,
        joyRide: {
          ...state.joyRide,
          steps: action.steps,
        },
      }
    }
    case 'REMOVE_DOC_FROM_STEPS': {
      console.log(state.joyRide.steps.overdueKpiModal)
      console.log(state.joyRide.steps.overdueKpiModal.filter((step) => step.id != action.id))
      return {
        ...state,
        joyRide: {
          ...state.joyRide,
          steps: {
            ...state.joyRide.steps,
            overdueKpiModal: state.joyRide.steps.overdueKpiModal.filter(
              (step) => step.id != action.id,
            ),
          },
        },
      }
    }

    default:
      throw new Error(`Action: ${action.type} not implemented in Update reducer.`)
  }
}

export default updateReducer

import { select } from 'd3'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'

const NoteEditor = ({
  title,
  setTitleHandler,
  note,
  setNoteHandler,
  isConvertModalOpen,
  setIsConvertModalOpen,
  isVoiceNoteModalOpen,
  setIsVoiceNoteModalOpen,
  deleteNote,
  noteId,
}) => {
  const { t } = useTranslation(['MyNotebook', 'Common'])

  return (
    <div className="my-notebook-notes-right-subcontainer">
      <textarea
        rows="1"
        id="title"
        name="title"
        value={title}
        onChange={setTitleHandler}
        placeholder={t('MyNotebook:noteTitle')}
        style={{
          resize: 'none',
          backgroundColor: 'white',
          fontSize: '14px',
          padding: '0.5rem',
          borderRadius: '5px',
          border: '1px solid lightgray',
        }}
      />
      <textarea
        rows="15"
        id="note"
        name="note"
        value={note}
        onChange={setNoteHandler}
        placeholder={t('MyNotebook:noteText')}
        style={{
          resize: 'none',
          backgroundColor: 'white',
          fontSize: '14px',
          padding: '0.5rem',
          borderRadius: '5px',
          border: '1px solid lightgray',
          marginTop: '1rem',
        }}
      />
      <div className="my_notebook_notes_dual_button_container-inner">
        <button
          type="button"
          className="btn-card tonedown-red-btn"
          style={{ width: '2.5rem' }}
          onClick={async () => {
            const result = await Swal.fire({
              title: t('Common:modalMessages.areYouSureDeleteThisNote'),
              icon: 'question',
              showCancelButton: true,
              confirmButtonText: t('Common:commonButtons.yes'),
              cancelButtonText: t('Common:commonButtons.no'),
            })
            if (result.isConfirmed) {
              deleteNote(noteId)
            }
          }}
        >
          <span class="material-symbols-outlined icon-size">delete</span>
        </button>

        <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
          <button
            type="button"
            className="btn-card tonedown-blue-btn"
            style={{ width: '2.5rem' }}
            onClick={() => {
              setIsVoiceNoteModalOpen(true)
            }}
          >
            <span class="material-symbols-outlined icon-size">mic</span>
          </button>

          <button
            className="sleek-button sleek-full-blue"
            onClick={() => setIsConvertModalOpen(true)}
          >
            {t('MyNotebook:convert')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default NoteEditor

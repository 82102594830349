import { useTranslation } from 'react-i18next'
import Modal from '../Modal'
import { useEffect, useState } from 'react'
import SelectField from '../CustomMultipleSelect/singleOption'
import { getMeetingTopic } from '../../api/services/meetingTopic.services'
import { ModalSaveButtonFitted } from '../ModalSubmitButton'
import { set } from 'lodash'

const LinkReminderToTopicModal = ({ closeModal, setLinkedTopic, ifFollowUp = false }) => {
  const { t } = useTranslation('Common')
  const [isLoading, setIsLoading] = useState(false)
  const [topicsList, setTopicsList] = useState([])
  const [selectedTopic, setSelectedTopic] = useState(null)

  useEffect(() => {
    setIsLoading(true)
    getTopicsList()
    setIsLoading(false)
  }, [])

  const getTopicsList = async () => {
    setIsLoading(true)
    const result = await getMeetingTopic(false, false, undefined)
    setIsLoading(false)
    if (result) {
      let finalArray = []
      finalArray = finalArray.concat(result.blockedObjectives)
      finalArray = finalArray.concat(result.decisionObjectives)
      finalArray = finalArray.concat(result.updateObjectives)
      finalArray = finalArray.concat(result.adminTopicObjectives)
      // const tempTopicsList = (finalArray || []).map((item) => ({
      //     name: item.actionNeeded,
      //     id: item.umtId,
      // }))
      setTopicsList(finalArray)
    }
  }

  const handleChangeExistingMeetingTopicId = (value) => {
    console.log(value)
    setSelectedTopic(value)
  }

  return (
    <>
      <Modal
        style={{ zIndex: 104 }}
        width={'40rem'}
        title={
          ifFollowUp
            ? t('linkReminderToTopicModal.followupTitle')
            : t('linkReminderToTopicModal.title')
        }
        closeModal={closeModal}
      >
        {!isLoading && (
          <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="form-field">
                <SelectField
                  isMulti={false}
                  placeholder={t('linkReminderToTopicModal.placeholder')}
                  name="selectMeetingTopic"
                  value={selectedTopic}
                  onChange={(option) => {
                    handleChangeExistingMeetingTopicId(option)
                  }}
                  getOptionLabel={(option) => option.actionNeeded}
                  getOptionValue={(option) => option}
                  options={topicsList}
                  width={'20rem'}
                  isClearable={true}
                  menuShouldScrollIntoView={false}
                  menuPosition="fixed"
                />
              </div>
            </div>
          </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ModalSaveButtonFitted
            isLoading={isLoading}
            tag={t('Common:commonButtons.save')}
            onClick={() => {
              setLinkedTopic(selectedTopic)
              closeModal()
            }}
          />
        </div>
      </Modal>
    </>
  )
}

export default LinkReminderToTopicModal

import React from 'react'
import Modal from '../../../../components/Modal'
import { useTranslation } from 'react-i18next'
import { postHttpRequest } from '../../../../api/query/dynamicAPI'
import { convertTimestampToDate } from '../../../../utils/time'
import { EDIT_SUB_ACTIVITY } from '../../../../api/constant/route'

const SingleSubactivityModal = ({ closeModal, subactivity, setIsRefreshObjective = () => {} }) => {
  const [showErrorMessage, setShowErrorMessage] = React.useState(false)
  const [startDate, setStartDate] = React.useState(null)
  const [dueDate, setDueDate] = React.useState(null)
  const { t } = useTranslation(['Common'])

  React.useEffect(() => {
    if (subactivity) {
      setStartDate(subactivity.startDate)
      setDueDate(subactivity.dueDate)
    }
  }, [])

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value
    setStartDate(newStartDate)
    // Clear error message when user adjusts the start date
    if (dueDate && newStartDate && newStartDate > dueDate) {
      setShowErrorMessage(true)
    } else {
      setShowErrorMessage(false)
    }
  }

  const handleDueDateChange = (event) => {
    const newDueDate = event.target.value
    setDueDate(newDueDate)
    if (startDate && newDueDate && newDueDate < startDate) {
      setShowErrorMessage(true)
    } else {
      setShowErrorMessage(false)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!showErrorMessage) {
      const postObject = {
        subActivityId: subactivity.subActivityId,
        startDate: startDate,
        dueDate: dueDate,
      }
      try {
        const response = await postHttpRequest(EDIT_SUB_ACTIVITY, postObject)
        if (response) {
          setIsRefreshObjective((old) => !old)
          closeModal()
          return
        } else {
        }
      } catch (error) {
        console.error('Error submitting the form:', error)
      }
    }
  }

  return (
    <Modal closeModal={closeModal} style={{ zIndex: 1010 }}>
      <form onSubmit={handleSubmit}>
        <div className="form-field action-field form-field action-field--kanban">
          <label>{t('date.startDate')}</label>
          <input
            type="date"
            name="startDate"
            placeholder="yyyy-mm-dd"
            value={startDate}
            onChange={handleStartDateChange}
          />
        </div>
        <div className="form-field action-field form-field action-field--kanban">
          <label>{t('date.dueDate')}</label>
          <input
            type="date"
            name="dueDate"
            placeholder="yyyy-mm-dd"
            value={dueDate}
            onChange={handleDueDateChange}
          />
        </div>
        {showErrorMessage && (
          <div style={{ textAlign: 'center', marginBottom: '15px' }} className="validation-error">
            {t('date.errorMessage')}
          </div>
        )}
        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <button
            type="submit"
            className="sleek-button sleek-blue"
            style={{ cursor: 'pointer', height: '2.2rem', backgroundColor: 'white' }}
          >
            {t('date.save')}
          </button>
        </div>
      </form>
    </Modal>
  )
}

export default SingleSubactivityModal

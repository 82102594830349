import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const deleteObjectiveCheck = async (objId) => {
  const queryString = `/delete_objective_check/${objId}`
  const { data } = await axios.post(`${BASE_URL}${queryString}`)

  return data
}

export default function useDeleteObjectiveCheck() {
  return useMutation((objId) => deleteObjectiveCheck(objId))
}

import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const editTeamLeaderAllocation = async (allocData, teamLeaderId) => {
  const queryString =
    teamLeaderId === null
      ? '/demand_allocation_updates'
      : `/demand_allocation_updates/${teamLeaderId}`

  const { data } = await axios.post(`${BASE_URL}${queryString}`, allocData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export default function useEditTeamLeaderAllocation(teamLeaderId = null) {
  return useMutation((data) => editTeamLeaderAllocation(data, teamLeaderId))
}

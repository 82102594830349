import React from 'react'
import { CardContainer } from '../PeopleManagment/styles'
import { getInternalSupportPermissionsList } from '../../../../api/services/employee.services'
import SelectSingleField from '../../../../components/CustomMultipleSelect/singleOption.jsx'

const InternalSupport = () => {
  const [allEmplsList, setAllEmplsList] = React.useState([])
  const [maskedEId, setMaskedEId] = React.useState(null)
  const [showError, setShowError] = React.useState(false)

  React.useEffect(() => {
    getEmpList()
  }, [])

  const handleGetAccessToken = async () => {
    if (!maskedEId) {
      setShowError(true)
      return
    }
    setShowError(false)
    const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)
    window.location.href = `${BASE_URL}/f/start_admin_support_session/${maskedEId}`
  }

  async function getEmpList() {
    const result = await getInternalSupportPermissionsList()

    if (result && result?.employees) setAllEmplsList(result?.employees)
  }

  const handlePersonChange = (e) => {
    setMaskedEId(e?.maskedEId)
    setShowError(false)
  }

  return (
    <>
      <CardContainer>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ whiteSpace: 'nowrap', marginBottom: '1rem' }}>
            <b>Select a person to switch profile</b>
          </div>
          <SelectSingleField
            placeholder={'Select a person'}
            name="selectPerson"
            value={allEmplsList.find((person) => person.maskedEId === maskedEId)}
            options={allEmplsList}
            onChange={handlePersonChange}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.maskedEId}
            width={'30%'}
          />
        </div>
        {showError && <span className="validation-error">*Required</span>}
        <div
          className="sleek-button sleek-blue"
          style={{ cursor: 'pointer', height: '1.5rem', marginTop: '1rem' }}
          onClick={() => {
            handleGetAccessToken()
          }}
        >
          Go to this person
        </div>
      </CardContainer>
    </>
  )
}

export default InternalSupport

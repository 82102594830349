import React from 'react'
import ApplicationLayout from '../../components/ApplicationLayout'
import { BrainstormProvider } from './Recorder/BrainstormContext'
import BrainstormComponent from './Component/BrainstormComponent'
import { ReactFlowProvider } from '@xyflow/react'

const Brainstorm = () => {
  const hideNavigation = localStorage.getItem('tfex') === 'true'

  return (
    <ApplicationLayout fromSetUpAgent={hideNavigation}>
      <ReactFlowProvider>
        <BrainstormProvider>
          <BrainstormComponent />
        </BrainstormProvider>
      </ReactFlowProvider>
    </ApplicationLayout>
  )
}

export default Brainstorm

import React, { useState } from 'react'
import { CardContainerModel, CardHeader } from '../styles'

import { Formik, Field, Form } from 'formik'
import SelectField from '../../../../../components/CustomMultipleSelect/customSelect'

import InPageLoader from '../../../../../components/InPageLoader/index'

import '../style.scss'
import Modal from '../../../../../components/Modal'
import { moveEmployeePeopleManagment } from '../../../../../api/services/employee.services'

import { MoveSchema } from './schemas/index'

const initialValues = {
  title: '',
  assign: '',
}

const ManageLeaders = ({
  view,
  setIsMoveShow,
  selectedTeamLeader,
  teamLeaderList,
  moveId,
  setIsReset,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const [formValues, setFormValues] = useState(initialValues)

  async function submitMove(values) {
    const postObj = values
    if (!values?.assign?.eId) return
    postObj.eLeaderId = values?.assign?.eId
    setIsLoading(true)
    const result = await moveEmployeePeopleManagment(moveId, postObj)
    setIsLoading(false)
    if (result) {
      setIsReset((old) => !old)
      setIsMoveShow(false)
      return
    }
  }

  if (isLoading) {
    return (
      <>
        {' '}
        <InPageLoader />{' '}
      </>
    )
  }

  return (
    <>
      <Modal
        title={'Move'}
        isModalOpen={false}
        data-testid="Add Objective"
        closeModal={() => setIsMoveShow(false)}
        width="700px"
      >
        <div className="move-modal">
          <CardContainerModel>
            <div className="current-team-leader-wrapper">
              <CardHeader>
                Current Team Leader:
                <p className="current-team-leader-name">
                  {teamLeaderList.find((tl) => tl.eId == selectedTeamLeader)?.name ?? ''}
                </p>
              </CardHeader>
            </div>

            <Formik
              initialValues={formValues}
              validationSchema={MoveSchema}
              onSubmit={submitMove}
              enableReinitialize
            >
              {({ values, errors, touched, resetForm, setFieldValue, validateForm }) => (
                <Form className="objective-form">
                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <div
                      className="column-input-objective"
                      style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                    >
                      <div className="form-field action-field form-field action-field--kanban">
                        <label>Title :</label>
                        <Field
                          id="title"
                          name="title"
                          placeholder="Add title"
                          autoComplete="off"
                          value={values.title}
                          className={` ${
                            errors.title && touched.title ? 'input-error' : ''
                          }  custom-input`}
                        />
                        {errors.title && touched.title && (
                          <span className="validation-error">{errors.title}</span>
                        )}
                      </div>

                      <div className="row-kanban-inputs">
                        <div className="form-field action-field form-field action-field--kanban">
                          <label>Team Leaders To:</label>
                          <div data-testid="moveSelectTeamLeader">
                            <Field {...SelectField} name="assign">
                              {({ field }) => (
                                <SelectField
                                  field={field}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.eId}
                                  options={teamLeaderList}
                                  // isMulti={true}
                                  // isClearable={true}
                                />
                              )}
                            </Field>
                          </div>
                          {errors.assign && touched.assign && (
                            <span className="validation-error">Team Leader is Required !</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal-dual-btn">
                    <button
                      className="dual-btn negative-btn"
                      onClick={() => {
                        resetForm()
                        setIsMoveShow(false)
                      }}
                      type="button"
                    >
                      CANCEL
                    </button>
                    <button className="dual-btn positive-btn" type="submit">
                      Save
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </CardContainerModel>
        </div>
      </Modal>
    </>
  )
}

export default ManageLeaders

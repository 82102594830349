import React from 'react'
import useLinkageData from '../../../../api/query/useLinkageData'
import useHigherObjectives from '../../../../api/query/useHigherObjectives'
import EnterpriseObjectiveCard from './EnterpriseObjectiveCard'
import AreaObjectiveCard from './AreaObjectiveCard'
import TeamObjectiveCard from './TeamObjectiveCard'
import ManageObjectiveModal from './components/ManageObjectiveModal'
import InitNumberModal from '../../../../components/InitNumberModal'
import InPageLoader from '../../../../components/InPageLoader'

const ManageObjectives = () => {
  const {
    data: higherObjData,
    isLoading: isHigherObjLoading,
    error: higherObjError,
  } = useHigherObjectives()

  const [isInitModalOpen, setInitModalOpen] = React.useState(false)
  if (isHigherObjLoading) {
    return <InPageLoader inComponent={true} />
  }
  if (higherObjData) {
    let enterpriseObjectives = higherObjData?.enterpriseObjectives ?? []
    for (let obj of enterpriseObjectives) {
      obj.numChildren = higherObjData?.areaObjectives.filter((child, childIndex) => {
        return parseInt(obj.objId) === parseInt(child.parentId)
      }).length
    }
  }

  if (higherObjData) {
    let areaObjectives = higherObjData?.areaObjectives ?? []
    for (let obj of areaObjectives) {
      obj.parentStatement = higherObjData?.enterpriseObjectives.filter((child, childIndex) => {
        return parseInt(obj.parentId) === parseInt(child.objId)
      })
    }
  }

  return (
    <>
      {isInitModalOpen === true && (
        <InitNumberModal
          isModalOpen={isInitModalOpen}
          closeModal={() => {
            setInitModalOpen(false)
          }}
        />
      )}
      <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
        {!isHigherObjLoading && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem', width: '80%' }}>
            <EnterpriseObjectiveCard
              objectives={higherObjData?.enterpriseObjectives}
              enterpriseLeaders={higherObjData?.enterpriseLeaders}
              ceoObjectives={higherObjData?.ceoObjectives}
            ></EnterpriseObjectiveCard>
            <AreaObjectiveCard
              areaLeaders={higherObjData?.areaLeaders}
              areaObjectives={higherObjData?.areaObjectives}
              enterpriseObjectives={higherObjData?.enterpriseObjectives}
            ></AreaObjectiveCard>
            <TeamObjectiveCard setInitModalOpen={setInitModalOpen}></TeamObjectiveCard>
          </div>
        )}
      </div>
    </>
  )
}
export default ManageObjectives

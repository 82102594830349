import { useState, useEffect } from 'react'
import Modal from '../Modal'
import { useTranslation } from 'react-i18next'
import {
  addMultipleCommentsDecisionsActionsToMeetingTopic,
  addMultipleMeetingTopics,
} from '../../api/services/meetingTopic.services'
import InPageLoader from '../InPageLoader'
import TextArea from 'rc-textarea'
import SelectField from '../CustomMultipleSelect/singleOption'

const ReviewGeneratedCommentsActionsModal = ({
  isModalOpen,
  closeModal,
  generatedCommentsActions,
  topicId,
  objId,
  handleCancel,
  userList = [],
  fromAi = false,
}) => {
  const [commentsActions, setCommentsActions] = useState(generatedCommentsActions)
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation(['Common'])

  const handleTextChange = (index, newText) => {
    const updatedCommentsActions = [...commentsActions]
    updatedCommentsActions[index].name = newText
    setCommentsActions(updatedCommentsActions)
  }

  const handleTopicTypeChange = (index, type) => {
    const updatedCommentsActions = [...commentsActions]
    updatedCommentsActions[index].type = type
    if (type !== 'Action') {
      updatedCommentsActions[index].assigneeIds = []
    }
    setCommentsActions(updatedCommentsActions)
  }

  const handleIncludeChange = (index, isChecked) => {
    const updatedCommentsActions = [...commentsActions]
    updatedCommentsActions[index].include = isChecked
    setCommentsActions(updatedCommentsActions)
  }

  const handleSave = () => {
    // Filter topics based on include flag but don't include 'include' property
    const filteredItems = commentsActions
      .filter((commentAction) => commentAction.include)
      .map(({ name, type, assigneeIds }) => ({
        description: name,
        type: type.toLowerCase(),
        topicId,
        assigneeIds: assigneeIds,
      }))
    setIsLoading(true)
    addMultipleCommentsDecisionsActionsToMeetingTopic(filteredItems, topicId, objId)
      .then((response) => {
        const data = response
        setIsLoading(false)
        if (data) {
          closeModal()
        }
      })
      .catch((error) => {})
  }

  const handleAssigneeChange = (index, value) => {
    const updatedCommentsActions = [...commentsActions]
    updatedCommentsActions[index].assigneeIds = value.map((v) => v.id)
    setCommentsActions(updatedCommentsActions)
  }

  return (
    <div className="review-generated-topics-modal">
      <Modal closeModal={closeModal} width={'50rem'}>
        {isLoading && <InPageLoader inComponent={true} />}
        {!isLoading && (
          <div className="review-generated-topics-list" style={{ marginTop: '1.3rem' }}>
            <div className="review-generated-actions-comments-list-row">
              <div
                className="review-generated-topics-list-header"
                style={{ paddingLeft: '0.5rem' }}
              >
                {t('reviewGeneratedTopicsModal.description')}
              </div>
              <div className="review-generated-topics-list-header">
                {t('reviewGeneratedTopicsModal.assignedTo')}
              </div>
              <div className="review-generated-topics-list-header">
                {t('reviewGeneratedTopicsModal.category')}
              </div>
              <div className="review-generated-topics-list-header">
                {t('reviewGeneratedTopicsModal.include')}
              </div>
            </div>
            {commentsActions.map((commentAction, index) => {
              let assigneeIds = commentAction?.assigneeIds ?? []
              let assigneeList = userList.filter((user) => assigneeIds.includes(user.id))
              return (
                <div key={index} className="review-generated-actions-comments-list-row">
                  <div className="review-generated-topics-list-item">
                    <TextArea
                      rows={3}
                      type="text"
                      onChange={(e) => {
                        handleTextChange(index, e.target.value)
                      }}
                      value={commentAction.name}
                    />
                  </div>
                  <div className="review-generated-topics-list-item" style={{ width: '12rem' }}>
                    <SelectField
                      isMulti={true}
                      placeholder={t('formPlaceHolder.select')}
                      name="assign"
                      value={assigneeList}
                      options={userList}
                      onChange={(value) => {
                        handleAssigneeChange(index, value)
                      }}
                      getOptionLabel={(option) => option.display}
                      getOptionValue={(option) => option.id}
                      isClearable={true}
                      menuShouldScrollIntoView={false}
                      menuPosition="fixed"
                      isDisabled={commentAction.type !== 'Action'}
                      styles={{
                        valueContainer: (provided, state) => ({
                          ...provided,
                          height: '4rem',
                          overflow: 'auto',
                        }),
                      }}
                    />
                  </div>
                  <div className="review-generated-topics-list-item">
                    <select
                      value={commentAction.type}
                      onChange={(e) => handleTopicTypeChange(index, e.target.value)}
                      className={`action-box}`}
                    >
                      <option value="Comment">Comment</option>
                      <option value="Decision">Decision</option>
                      <option value="Action">Action</option>
                    </select>
                  </div>
                  <div className="review-generated-topics-list-item">
                    <input
                      type="checkbox"
                      checked={commentAction.include}
                      onChange={(e) => handleIncludeChange(index, e.target.checked)}
                    />
                  </div>
                  <div class="row-border" style={{ marginTop: '0.5rem' }}></div>
                </div>
              )
            })}
            <div className="review-generated-topics-buttons-container">
              {!fromAi && (
                <div
                  className="sleek-button sleek-blue"
                  style={{ cursor: 'pointer', height: '1.5rem' }}
                  onClick={() => {
                    handleCancel()
                  }}
                >
                  {t('commonButtons.tryAgain')}
                </div>
              )}
              <div
                className="sleek-button sleek-blue"
                style={{ cursor: 'pointer', height: '1.5rem' }}
                onClick={() => {
                  handleSave()
                }}
              >
                {t('commonButtons.saveLowerCase')}
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}

export default ReviewGeneratedCommentsActionsModal

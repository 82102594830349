import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const editUpdateWizard = async (allocData) => {
  const { data } = await axios.post(`${BASE_URL}/update_wizard_submit`, allocData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export default function useEditUpdateWizard() {
  return useMutation((data) => editUpdateWizard(data))
}

import React, { useState, useEffect } from 'react'
import '../TeamCapacityAllocationTable/style.scss'
import PlusIcon from '../../../assets/plus.svg'
import { useTranslation } from 'react-i18next'

import AddCoOwnerModal from '../AddCoOwnerModal'
import { getCompanyConfigSettings } from '../../../utils/auth'

const AdditionalCapacityTable = ({ contextHandler, isAddCoOwnersEnabled = false }) => {
  const { t } = useTranslation(['Common'])
  const { state, dispatch } = contextHandler()
  const [rows, setRows] = useState([])
  const productSettings = getCompanyConfigSettings('productSettings')
  const loggedInName = localStorage.getItem('tfmnm')

  const calcTotal = () => {
    const arrayOfAllocation = state.data.objectives.map((objective) =>
      objective?.team?.reduce((total, obj) => {
        if (obj.value) {
          return total + obj.value
        }
        return total
      }, 0),
    )
    return arrayOfAllocation
  }
  const setAdditionalCapacity = (e, objectiveIndex) => {
    const objectiveToBeUpdatedID = state?.data?.objectives[objectiveIndex].id
    const clonedObjectives = JSON.parse(JSON.stringify(state?.data?.objectives))

    clonedObjectives.map((objective) => {
      if (objective.id === objectiveToBeUpdatedID) {
        objective.missingFte = parseFloat(e.target.value) / 100
        if (e.target.value === '') {
          objective.pendingTransferList = []
        } else if (isAddCoOwnersEnabled && productSettings && objective.isCopy) {
          objective.pendingTransferList = [
            { eId: -1, name: loggedInName, allocation: e.target.value },
          ]
        }
      }
      return objective
    })
    dispatch({ type: 'UPDATE_OBJECTIVES', objectives: clonedObjectives })
  }
  const getAdditionalCapacityValue = (objectiveIndex) => {
    if (state?.data?.objectives[objectiveIndex]?.missingFte) {
      return state?.data?.objectives[objectiveIndex]?.missingFte * 100
    }
    const currentRow = state?.data?.objectives[objectiveIndex]
    if (currentRow?.missingFte) {
      return state?.data?.objectives[objectiveIndex]?.missingFte * 100
    } else if (currentRow?.isExistingPendingTransferList === true) {
      return currentRow?.pendingTransferList.reduce((accumulator, currentValue) => {
        return accumulator + parseInt(currentValue.allocation)
      }, 0)
    }
  }

  const displayErrors = (obj) => {
    const negativeErr = state.data.objectives
      .filter((obj2) => obj2.id === obj.id)
      .filter((obj3) => {
        if (obj3) {
          return obj3.missingFte < 0
        }
      })

    if (negativeErr.length > 0) {
      return <p className="error-message">{t('additionalCapacityTable.errorMessage1')}</p>
    }
    return null
  }

  useEffect(() => {
    let initialRows = []
    if (productSettings && isAddCoOwnersEnabled) {
      initialRows = state?.data?.objectives.map((obj) => ({
        inputValue: obj?.missingFte * 100,
        showAddCoOwnersButton:
          obj?.missingFte > 0 && obj.isExistingPendingTransferList === false && !obj.isCopy,
        objData: obj,
      }))
    } else {
      initialRows = state?.data?.objectives.map((obj) => ({
        inputValue: obj?.missingFte * 100,
        showAddCoOwnersButton: false,
        objData: obj,
      }))
    }
    setRows(initialRows)

    const negativeErr = state.data.objectives
      .map((obj, i) => obj.missingFte < 0)
      .some((err) => err === true)

    if (negativeErr) {
      dispatch({ type: 'ALLOC_SUB_WIZ__HAS_ERROR__ADDITIONAL_CAP_ALLOC_TABLE' })
    } else {
      dispatch({ type: 'ALLOC_SUB_WIZ__NOT_HAS_ERROR__ADDITIONAL_CAP_ALLOC_TABLE' })
    }
  }, [state.data.objectives])

  return (
    <>
      {state?.isCoOwnerModalOpen === true ? (
        <AddCoOwnerModal contextHandler={contextHandler} />
      ) : null}

      <table className="capacity-table">
        <tbody>
          <tr className="table-objective-mapping-head">
            <th className="goal-cell"></th>
            <th className="goal-cell">{t('additionalCapacityTable.assigned')}</th>
            <th className="goal-cell" style={{ textAlign: 'left' }}>
              {t('additionalCapacityTable.additionalCapacityNeeded')}{' '}
            </th>
            {isAddCoOwnersEnabled && productSettings && (
              <>
                <th className="goal-cell" style={{ textAlign: 'left' }}>
                  {t('additionalCapacityTable.capacityRequiredFrom')}{' '}
                </th>

                <th></th>
              </>
            )}
          </tr>
          {rows?.map((row, index) => (
            <>
              <tr key={index}>
                <td className="goal-cell">
                  <span className="member-name">{row?.objData.statement}</span>
                </td>
                <td className="goal-cell total-number">{calcTotal()[index] || 0}%</td>
                <td className="goal-cell total-number">
                  <input
                    htmlFor="test1"
                    data-testid={`goal-${index}`}
                    key={index}
                    className="fte-input custom-input border-change goal-cell"
                    type="number"
                    min="0"
                    onChange={(e) => setAdditionalCapacity(e, index)}
                    value={getAdditionalCapacityValue(index)}
                    disabled={
                      isAddCoOwnersEnabled &&
                      productSettings &&
                      row?.objData?.pendingTransferList.length > 0 &&
                      row?.objData?.isExistingPendingTransferList === true
                    }
                  />{' '}
                  %
                </td>
                {isAddCoOwnersEnabled && productSettings && (
                  <>
                    <td className="goal-cell ">
                      {row?.objData?.pendingTransferList?.length > 0 ? (
                        <div>
                          {row?.objData?.pendingTransferList.map((item, key) => (
                            <div>
                              <span key={item.eId + key}>{item.name}</span>
                              {key !== row?.objData?.pendingTransferList.length - 1 && (
                                <span>,</span>
                              )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </td>
                    {row.showAddCoOwnersButton && (
                      <td className="goal-cell ">
                        <button
                          className="btn btn-add"
                          data-testid={`Add-${index}`}
                          style={{ height: '2rem', width: '2rem' }}
                          onClick={() =>
                            dispatch({
                              type: 'ALLOC_SUB_WIZ_OPEN_COOWNER_MODAL',
                              id: row.objData.id,
                            })
                          }
                        >
                          <img src={PlusIcon} alt="" />
                        </button>
                      </td>
                    )}
                  </>
                )}
              </tr>
              <div className="error-wrapper">{displayErrors(row?.objData)}</div>
            </>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default AdditionalCapacityTable

import React from 'react'
import Modal from '../Modal'
import { Formik, Form } from 'formik'
import { ModalSaveButtonFitted } from '../ModalSubmitButton'
import { useTranslation } from 'react-i18next'

const buttonStatusStyle = {
  boxShadow: 'inset orange 0 0 0 5px',
  lineHeight: '30px',
  fontWeight: 'bold',
  color: '#009688',
}

const ObjectiveRagModal = ({ isModalOpen, closeModal, objective, handleSubmit, button_num }) => {
  const { t } = useTranslation(['Common'])
  const [buttonChecked, setButton] = React.useState(button_num)
  return (
    <>
      <Modal
        title={t('objectiveRagModal.editRag')}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        width={'25rem'}
      >
        <Formik
          enableReinitialize
          // don;t delete this
          // https://stackoverflow.com/a/66336093
          initialValues={{
            rag: objective.rag !== undefined ? objective.rag : objective.objectiveRag,
          }}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(objective, buttonChecked)
            closeModal()
          }}
        >
          {(errors, touched, resetForm, values, setFieldValue, validateForm) => (
            <Form>
              <div
                className="form-field"
                style={{ alignItems: 'center', justifyContent: 'center' }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    columnGap: '2rem',
                  }}
                >
                  <div
                    style={
                      buttonChecked === 0
                        ? {
                            ...buttonStatusStyle,
                            backgroundColor: 'green',
                            width: '2rem',
                            height: '2rem',
                          }
                        : { backgroundColor: 'green', width: '2rem', height: '2rem' }
                    }
                    onClick={() => setButton(0)}
                  ></div>
                  <div
                    style={
                      buttonChecked === 1
                        ? {
                            ...buttonStatusStyle,
                            backgroundColor: 'yellow',
                            width: '2rem',
                            height: '2rem',
                          }
                        : { backgroundColor: 'yellow', width: '2rem', height: '2rem' }
                    }
                    onClick={() => setButton(1)}
                  ></div>
                  <div
                    style={
                      buttonChecked === 2
                        ? {
                            ...buttonStatusStyle,
                            backgroundColor: 'red',
                            width: '2rem',
                            height: '2rem',
                          }
                        : { backgroundColor: 'red', width: '2rem', height: '2rem' }
                    }
                    onClick={() => setButton(2)}
                  ></div>
                </div>
              </div>

              <div className="modal-dual-btn" style={{ columnGap: '2rem' }}>
                <div
                  class="fitted-button blue"
                  onClick={() => {
                    closeModal()
                  }}
                  type="button"
                >
                  {t('commonButtons.cancel')}
                </div>
                <ModalSaveButtonFitted></ModalSaveButtonFitted>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default ObjectiveRagModal

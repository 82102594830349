import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getObjectivesByOwnerId = async (objectiveId) => {
  const queryString = `/get_objectives_by_owner_id`

  const { data } = await axios.get(`${BASE_URL}${queryString}`)
  return data
}

export default function useGetObjectivesByOwnerId() {
  return useQuery(['update_wiz_objectives'], () => getObjectivesByOwnerId(), {
    refetchOnWindowFocus: false,
  })
}

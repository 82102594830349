import React from 'react'
import { getTeamLeaderList } from '../../../../api/services/employee.services'
import InPageLoader from '../../../../components/InPageLoader'
import SelectSingleField from '../../../../components/CustomMultipleSelect/singleOption'
import { useTranslation } from 'react-i18next'
import { getHttpRequest } from '../../../../api/query/dynamicAPI'
import Toast from '../../../../common/toast'
import MeiCxoBox from '../../../CxoMgmtScore/component/MeiCxoBox'
import MeiModal from '../../../Home/components/AdminDashboard/components/TeamDoing/component/MeiModal'
import MeiHelpModal from '../../../Home/components/AdminDashboard/components/TeamDoing/component/MeiHelpModal'

const MeiReports = ({ isInModal = false }) => {
  const [cxoLeaderList, setCxoLeaderList] = React.useState([])

  const [isLoading, setIsLoading] = React.useState(false)
  const [isMeiLoading, setIsMeiLoading] = React.useState(false)

  const [cxoEid, setCxoEid] = React.useState(null)

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [isHelpModalOpen, setIsHelpModalOpen] = React.useState(false)
  const [data, setData] = React.useState(null)
  const [alData, setAlData] = React.useState(null)

  const { t } = useTranslation(['Common', 'Dashboard'])

  React.useEffect(() => {
    getLeaderList()
  }, [])

  React.useEffect(() => {
    if (cxoEid) {
      getMei(cxoEid)
    }
  }, [cxoEid])

  const getLeaderList = async () => {
    setIsLoading(true)
    const result = await getTeamLeaderList('?level=1')
    setIsLoading(false)
    if (result && result?.employees) setCxoLeaderList(result?.employees)
  }

  const getMei = async (eId) => {
    try {
      setIsMeiLoading(true)
      let response = await getHttpRequest(`/get_mei_data/${eId}`)
      setAlData(response.data)
      setIsMeiLoading(false)
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }

  const handleOwnerChange = (e) => {
    setCxoEid(e?.eId)
  }

  if (isLoading) {
    return <InPageLoader />
  }

  return (
    <>
      {isModalOpen && data && (
        <MeiModal data={data} isModalOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} />
      )}
      {isHelpModalOpen && (
        <MeiHelpModal isModalOpen={isHelpModalOpen} closeModal={() => setIsHelpModalOpen(false)} />
      )}
      <div style={{ width: '90%', display: 'flex', flexDirection: 'column' }}>
        {!isInModal && (
          <h1 className="charts_heading" style={{ marginLeft: '16px' }}>
            {t('Dashboard:chartsCard.cxoMeiTitle')}
          </h1>
        )}
        <div style={{ marginLeft: '15px', zIndex: '20' }}>
          <SelectSingleField
            placeholder={t('formPlaceHolder.selectLeader')}
            name="selectCxo"
            value={cxoLeaderList.find((person) => person.eId === cxoEid)}
            options={cxoLeaderList}
            onChange={handleOwnerChange}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.eId}
            width={'30%'}
          />
        </div>
        {isMeiLoading ? (
          <InPageLoader />
        ) : (
          <>
            {alData && (
              <MeiCxoBox
                setData={setData}
                alData={alData}
                setIsModalOpen={setIsModalOpen}
                setIsHelpModalOpen={setIsHelpModalOpen}
              />
            )}
          </>
        )}
      </div>
    </>
  )
}

export default MeiReports

import React, { useEffect, useState } from 'react'
import Modal from '../Modal'
import { useTranslation } from 'react-i18next'
import {
  getEmailFollowUps,
  getEmailReminder,
} from '../../../src/api/services/meetingTopic.services'
import './styles.css'
import CardModal from '../CardModal'
import { convertTimeStamToMMDDYYYY, formatISODateTime } from '../../utils/time'
import SetEmailFollowUpModal from '../SetEmailReminderModal/SetEmailFollowUpModal'
import { shortenName } from '../../utils/general'

const ViewFollowUpModal = ({ closeModal }) => {
  const { t } = useTranslation('Common')
  const [reminderList, setReminderList] = useState([])
  const [isCardModalOpen, setIsCardModalOpen] = useState(false)
  const [selectedTopic, setSelectedTopic] = useState(null)
  const [isAddEditReminderModalOpen, setIsAddEditReminderModalOpen] = useState(false)
  const [selectedReminder, setSelectedReminder] = useState(null)
  const [linkedTopic, setLinkedTopic] = useState(null)
  const [mode, setMode] = useState('add')

  useEffect(() => {
    getAllReminders()
  }, [])

  const getAllReminders = async () => {
    try {
      const result = await getEmailFollowUps()
      if (result) {
        setReminderList(result.reminders)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const getFrequency = (frequency) => {
    if (frequency == 1) {
      return t('setEmailReminderModal.once')
    } else if (frequency == 2) {
      return t('setEmailReminderModal.daily')
    } else if (frequency == 3) {
      return t('setEmailReminderModal.weekly')
    } else if (frequency == 4) {
      return t('setEmailReminderModal.biweekly')
    } else if (frequency == 5) {
      return t('setEmailReminderModal.monthly')
    }
  }

  const reload = () => {
    getAllReminders()
  }

  return (
    <Modal
      closeModal={closeModal}
      includeCloseIcon={true}
      width="80rem"
      title={t('reviewFollowUpModal.title')}
      titleStyle={{ color: 'rgb(91,132,203)', textAlign: 'left' }}
      modalTitleContainerStyle={{ justifyContent: 'flex-start' }}
    >
      {isAddEditReminderModalOpen && (
        <SetEmailFollowUpModal
          isModalOpen={isAddEditReminderModalOpen}
          closeModal={() => {
            setLinkedTopic(null)
            setIsAddEditReminderModalOpen(false)
          }}
          mode={mode}
          selectedReminder={selectedReminder}
          passedLinkedTopic={linkedTopic}
          reload={reload}
        />
      )}
      {isCardModalOpen && selectedTopic && (
        <CardModal
          isModalOpen={isCardModalOpen}
          closeModal={() => {
            setIsCardModalOpen(false)
            setSelectedTopic(null)
          }}
          umtId={selectedTopic.umtId}
          topicType={selectedTopic.topicType}
        />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex_end',
            alignItems: 'center',
            width: '100%',
            marginBottom: '1rem',
          }}
        >
          <div
            style={{
              marginLeft: 'auto',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ marginRight: '1rem', color: 'rgb(91,132,203)' }}>
              {t('reviewFollowUpModal.addFollowUp')}
            </div>
            <div
              className="my-reminders-meeting-content"
              style={{ paddingLeft: '0rem', textAlign: 'left' }}
            >
              <div
                class="fitted-button blue"
                onClick={() => {
                  setMode('add')
                  setIsAddEditReminderModalOpen(true)
                }}
              >
                <span
                  class="material-symbols-outlined icon-size"
                  style={{ color: 'rgb(91,132,203)' }}
                >
                  add
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="my-reminders-container"
          style={{ width: '100%', marginBottom: '3rem', padding: '1.5rem' }}
        >
          <div className="my-follow-up-inner-grid">
            <div className="my-reminders-meeting-header" style={{ textAlign: 'left' }}>
              {t('reminderEditModal.name')}
            </div>
            <div
              className="my-reminders-meeting-header"
              style={{ paddingLeft: '0rem', textAlign: 'left' }}
            >
              {t('reminderEditModal.frequency')}
            </div>
            <div
              className="my-reminders-meeting-header"
              style={{ paddingLeft: '0rem', textAlign: 'left' }}
            >
              {t('reminderEditModal.nextTime')}
            </div>
            <div
              className="my-reminders-meeting-header"
              style={{ paddingLeft: '0rem', textAlign: 'left' }}
            >
              {t('reminderEditModal.message')}
            </div>
            <div
              className="my-reminders-meeting-header"
              style={{ paddingLeft: '0rem', textAlign: 'left' }}
            >
              {t('reminderEditModal.linkedTopic')}
            </div>
            <div
              className="my-reminders-meeting-header"
              style={{ paddingLeft: '0rem', textAlign: 'left' }}
            >
              {t('reminderEditModal.toList')}
            </div>
            <div
              className="my-reminders-meeting-header"
              style={{ paddingLeft: '0rem', textAlign: 'left' }}
            ></div>
          </div>

          {reminderList &&
            reminderList.length > 0 &&
            reminderList.map((reminder, index) => {
              return (
                <div className="my-follow-up-inner-grid" style={{ marginBottom: '1rem' }}>
                  <div
                    className="my-reminders-meeting-content"
                    style={{ paddingLeft: '0rem', textAlign: 'left' }}
                  >
                    {reminder.reminderName}
                  </div>
                  <div
                    className="my-reminders-meeting-content"
                    style={{ paddingLeft: '0rem', textAlign: 'left' }}
                  >
                    {getFrequency(reminder.frequency)}
                  </div>
                  <div
                    className="my-reminders-meeting-content"
                    style={{ paddingLeft: '0rem', textAlign: 'left' }}
                  >
                    {formatISODateTime(reminder.rawTime)}
                  </div>
                  <div
                    className="my-reminders-meeting-content"
                    style={{ paddingLeft: '0rem', textAlign: 'left' }}
                  >
                    {reminder.attachedMessage}
                  </div>
                  {reminder.linkTopicId == 0 ? (
                    <div
                      className="my-reminders-meeting-content"
                      style={{ paddingLeft: '0rem', textAlign: 'left' }}
                    >
                      --
                    </div>
                  ) : (
                    <div
                      className="my-reminders-meeting-content"
                      style={{
                        paddingLeft: '0rem',
                        textAlign: 'left',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        color: 'blue',
                      }}
                      onClick={() => {
                        setSelectedTopic({
                          umtId: reminder.linkTopicId,
                          topicType: reminder.linkTopicType,
                        })
                        setIsCardModalOpen(true)
                      }}
                    >
                      {reminder.linkTopicType == 1
                        ? 'See Blocker'
                        : reminder.linkTopicType == 2
                          ? 'See Decision'
                          : reminder.linkTopicType == 3
                            ? 'See Update'
                            : 'See Admin'}
                    </div>
                  )}
                  <div
                    className="my-reminders-meeting-content"
                    style={{ paddingLeft: '0rem', textAlign: 'left' }}
                  >
                    {shortenName(reminder.sendToName[0])}
                    {reminder.sendToName.length > 1 ? ` +${reminder.sendToName.length - 1}` : ''}
                  </div>
                  <div
                    className="my-reminders-meeting-content"
                    style={{ paddingLeft: '0rem', textAlign: 'left' }}
                  >
                    <div
                      class="fitted-button blue"
                      onClick={() => {
                        setSelectedReminder(reminder)
                        setLinkedTopic(
                          reminder.linkTopicId == 0
                            ? null
                            : {
                                umtId: reminder.linkTopicId,
                                topicType: reminder.linkTopicType,
                                actionNeeded: reminder.linkTopicName,
                              },
                        )
                        setMode('edit')
                        setIsAddEditReminderModalOpen(true)
                      }}
                    >
                      <span class="material-symbols-outlined icon-size">edit</span>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </Modal>
  )
}

export default ViewFollowUpModal

import React, { useState, useEffect } from 'react'
import { useExcoExerciseContext } from '../../context/ExcoExerciseContext'
import { useTranslation } from 'react-i18next'

const AIExerciseResult = () => {
  const { state, dispatch } = useExcoExerciseContext()
  const { t } = useTranslation(['UpdateWizard'])

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
    const remainingSeconds = time % 60
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`
  }

  const formatGPTVersion = (version) => {
    if (version === 'gpt-35') {
      return 'GPT-3.5'
    } else if (version === 'gpt-4') {
      return 'GPT-4'
    }
  }

  return (
    <>
      <div className="wizard-step">
        {/* <h2 className="wizard-step__title">Result</h2> */}
        <div className="wizard-step__main-content-container">
          <b style={{ fontSize: '20px' }}> Results</b>
          <div className="grid-container-title">
            <div className="grid-item"></div>
            <div className="grid-item-title">
              <b>Time</b>
            </div>
            <div className="grid-item-title">
              <b>GPT Version</b>
            </div>
          </div>
          <div className="grid-container">
            <div className="grid-item">
              <b>Exercise 1</b>
            </div>
            <div className="grid-item">{formatTime(state.timerQuestion1)}</div>
            <div className="grid-item">{formatGPTVersion(state.gptVersionQuestion1)}</div>

            <div className="grid-item">
              <b>Exercise 2</b>
            </div>
            <div className="grid-item">{formatTime(state.timerQuestion2)}</div>
            <div className="grid-item">{formatGPTVersion(state.gptVersionQuestion2)}</div>

            <div className="grid-item">
              <b>Exercise 3</b>
            </div>
            <div className="grid-item">{formatTime(state.timerQuestion3)}</div>
            <div className="grid-item">{formatGPTVersion(state.gptVersionQuestion3)}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AIExerciseResult

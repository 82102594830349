import React from 'react'

const FeatureFlagsContext = React.createContext({
  flags: {},
  dispatch: () => {},
})

export const FeatureFlagsProvider = ({ children, initialState1 }) => {
  // initialState = {testFlag:'hello world'}
  const initialState = JSON.parse(localStorage.getItem('rfconf'))
  const [flags, flagDispatch] = React.useReducer((s, a) => {
    return {}
  }, initialState)

  const context = {
    flags,
    flagDispatch,
  }

  return <FeatureFlagsContext.Provider value={context}>{children}</FeatureFlagsContext.Provider>
}

const useFeatureFlagsContext = () => {
  const context = React.useContext(FeatureFlagsContext)
  if (!context) {
    throw new Error(
      'useFeatureFlagsContext must be used within a TeamLeaderProvider. Wrap a parent component in <TeamLeaderProvider> to fix this error',
    )
  }

  return context
}

export { useFeatureFlagsContext }

import MeiReports from '../../pages/Governance/components/MeiReports'
import Modal from '../Modal'
import { useTranslation } from 'react-i18next'
import './style.css'

const MeiReportsModal = ({ closeModal }) => {
  const { t } = useTranslation(['Dashboard'])
  return (
    <div className="mei-report-modal">
      <Modal width={'85%'} height={'80%'} closeModal={closeModal}>
        <div className="modal-gray-box" style={{ width: '100%' }}>
          <div className="modal-inner-content">
            <div className="tutorial-management-modal-title" style={{ marginBottom: '10px' }}>
              {t('Dashboard:chartsCard.cxoMeiTitle')}
            </div>
            <MeiReports isInModal={true} />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default MeiReportsModal

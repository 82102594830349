import React from 'react'
import deleteCross from '../../../../../../assets/delete-cross.svg'
import { useSetupWizardContext } from '../../../../context/setupWizardContext'
import '../../styles.css'
const DeleteButton = (props) => {
  const { dispatch } = useSetupWizardContext()

  return (
    <>
      <div
        className="delete-button"
        onClick={() => {
          dispatch({ type: 'DELETE_TEAMLEADER', teamLeaderIndex: props.index })
        }}
      >
        <img className="delete-btn" src={deleteCross} alt="Click to edit symbol" />
      </div>
    </>
  )
}
export default DeleteButton

import React from 'react'

const TempHome = () => {
  React.useEffect(() => {
    window.location.href = 'https://dplus.transforml.co/'
  }, [])

  return (
    <div>
      You are being redirected to Decision+. Please{' '}
      <a href="https://dplus.transforml.co/">
        <b>click here</b>
      </a>{' '}
      if you are not redirected.
    </div>
  )
}

export default TempHome

import React from 'react'
import Modal from '../../../../../../components/Modal'
import AudioToTextModal from '../../../../../../components/AudioToTextModal/AudioToTextModal'
import TextArea from 'rc-textarea'
import { getHttpRequest, postHttpRequest } from '../../../../../../api/query/dynamicAPI'
import Toast from '../../../../../../common/toast'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { ModalSaveButtonFitted } from '../../../../../../components/ModalSubmitButton'
import { removeMentionMarkupFromText } from '../../../../../../utils/parseMarkup'
import { getCompanyConfigSettings } from '../../../../../../utils/auth'
import { addDotAfterSentence } from '../../../../../../utils/general'

const AiMemoQuestionsModal = ({
  isModalOpen,
  closeModal,
  memoAction,
  setMemoMaterial = () => {},
  setIsAiMemoDraftModalOpen = () => {},
  setShowEng = () => {},
  showEng,
}) => {
  console.log(memoAction)
  const { t } = useTranslation(['Common'])
  const [isAudioToTextModalOpen, setIsAudioToTextModalOpen] = React.useState(false)
  const [contextSentence, setContextSentence] = React.useState('')
  const [audioText, setAudioText] = React.useState('')
  // const [originalContextSentence, setOriginalContextSentence] = React.useState('')

  const [isFetchingResponse, setIsFetchingResponse] = React.useState(false)
  const [memoQuestions, setMemoQuestions] = React.useState([])
  const [gptGeneratedQuestions, setGptGeneratedQuestions] = React.useState([])

  const [textAreas, setTextAreas] = React.useState({})
  const [selectedQuestionIndex, setSelectedQuestionIndex] = React.useState(-1)
  const [log_id, setLogId] = React.useState('')
  const productSettings = getCompanyConfigSettings('productSettings')

  const contextEng = 'What is the purpose of this memo? Who is it intended for?'
  const contextEsp = '¿Cuál es el propósito de este memorando? ¿A quién está destinado?'

  const additionalQuestionEng = 'Anything else that is relevant?'
  const additionalQuestionEsp = 'Algo más que sea relevante y te gustaría agregar?'

  // const [showEng, setShowEng] = React.useState(getCompanyConfigSettings('languageSettings') !== 'es')
  const [hideToggle, setHideToggle] = React.useState(false)

  React.useEffect(() => {
    if (selectedQuestionIndex === -1) {
      setContextSentence(
        (prevContextSentence) =>
          prevContextSentence + addDotAfterSentence(prevContextSentence) + audioText,
      )
    } else {
      setTextAreas((textArea) => ({
        ...textAreas,
        [[`question${selectedQuestionIndex}`]]:
          textArea[`question${selectedQuestionIndex}`] +
          addDotAfterSentence(textArea[`question${selectedQuestionIndex}`]) +
          audioText,
      }))
      setSelectedQuestionIndex(-1)
    }
  }, [audioText])

  const handleContextTextChange = (e) => {
    setContextSentence(e.target.value)
  }

  const handleQuestionTextChange = (e, setFieldValue) => {
    const { name, value, selectionStart } = e.target
    console.log(name, value)
    setFieldValue(name, value)
    setTextAreas({ ...textAreas, [name]: value })
    const textarea = document.getElementById(name)
    if (textarea) {
      requestAnimationFrame(() => {
        textarea.selectionStart = selectionStart
        textarea.selectionEnd = selectionStart
      })
    }
  }

  React.useEffect(() => {
    if (memoQuestions.length > 0 && gptGeneratedQuestions.length > 0) {
      setTextAreas(
        memoQuestions.reduce((acc, _, index) => ({ ...acc, [`question${index}`]: '' }), {}),
      )
    }
  }, [memoQuestions])

  React.useEffect(() => {
    console.log('textAreas: ', textAreas)
  }, [textAreas])

  const populateMemoQuestions = async () => {
    setIsFetchingResponse(true)
    try {
      let response = await getHttpRequest('/get_ai_memo_questions', {
        params: {
          context: contextSentence,
          action: removeMentionMarkupFromText(memoAction.description),
          actionId: memoAction.milestoneId,
          language: showEng ? 'ENGLISH' : 'SPANISH',
        },
      })
      let allQuestions = response.questions
      setGptGeneratedQuestions(allQuestions)
      allQuestions = [...allQuestions, showEng ? additionalQuestionEng : additionalQuestionEsp]
      setMemoQuestions(allQuestions)
      setLogId(response.log_invocation_id)
      setHideToggle(true)
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
    setIsFetchingResponse(false)
  }

  const buildQuestionAnswerPair = () => {
    return memoQuestions
      .filter((_, index) => {
        return (textAreas?.[`question${index}`] ?? '') !== ''
      })
      .map((question, index) => {
        return {
          question: question,
          answer: textAreas?.[`question${index}`] ?? '',
        }
      })
  }

  const prepareDratMemoData = () => {
    setMemoMaterial({
      actionText: removeMentionMarkupFromText(memoAction.description),
      actionId: memoAction.milestoneId,
      context: contextSentence,
      questions: buildQuestionAnswerPair(),
      log_id: log_id,
    })
    setIsAiMemoDraftModalOpen(true)
  }

  const validationSchema = Yup.object().shape({
    question0: Yup.string().required(t('Common:required')),
    question1: Yup.string().required(t('Common:required')),
    question2: Yup.string().required(t('Common:required')),
    question3: Yup.string().required(t('Common:required')),
  })

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      width="85%"
      modalContainerStyle={{ width: '90%' }}
      title={removeMentionMarkupFromText(memoAction.description)}
      titleStyle={{ color: 'rgb(91,132,203)', textAlign: 'left' }}
      modalTitleContainerStyle={{ marginBottom: '0rem', justifyContent: 'flex-start' }}
    >
      {isAudioToTextModalOpen && (
        <AudioToTextModal
          startRecording={true}
          isModalOpen={isAudioToTextModalOpen}
          type="meetingHubCommentsActions"
          closeModal={() => {
            setIsAudioToTextModalOpen(false)
          }}
          setAudioText={setAudioText}
          displayText={
            selectedQuestionIndex === -1
              ? showEng
                ? contextEng
                : contextEsp
              : memoQuestions[selectedQuestionIndex]
          }
        />
      )}
      <div style={{ width: '90%' }}>
        {!hideToggle && !productSettings && (
          <div
            style={{
              marginLeft: 'auto',
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '0.5rem',
              width: '9rem',
            }}
          >
            <div class="tooltip">
              <span class="material-symbols-outlined">info</span>
              <span class="tooltiptext" style={{ fontSize: '15px', width: '20rem' }}>
                {t('aiMemo.info')}{' '}
              </span>
            </div>
            <span>{showEng ? t('aiMemo.eng') : t('aiMemo.esp')}</span>
            <div
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
              onClick={() => {
                let newVal = !showEng
                let allQuestions = [
                  ...gptGeneratedQuestions,
                  newVal ? additionalQuestionEng : additionalQuestionEsp,
                ]
                setMemoQuestions(allQuestions)
                setShowEng((prev) => !prev)
              }}
            >
              {!showEng && (
                <span
                  style={{ fontSize: '35px', color: 'rgb(176, 173, 173)' }}
                  class="material-symbols-outlined"
                >
                  toggle_off
                </span>
              )}

              {showEng && (
                <span
                  style={{ fontSize: '35px', color: 'rgb(176, 173, 173)' }}
                  class="material-symbols-outlined"
                >
                  toggle_on
                </span>
              )}
            </div>
          </div>
        )}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div
              style={{
                textAlign: 'left',
                marginBottom: '1rem',
                marginTop: !hideToggle && !productSettings ? '0rem' : '2rem',
              }}
            >
              <b>{showEng ? contextEng : contextEsp}</b>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div className="review-generated-topics-list-item" style={{ width: '100%' }}>
                <TextArea
                  rows={3}
                  type="text"
                  onChange={(e) => {
                    handleContextTextChange(e)
                  }}
                  value={contextSentence}
                  style={{ height: '5rem', backgroundColor: '#f1f0f0', width: '100%' }}
                  disabled={isFetchingResponse || gptGeneratedQuestions.length > 0}
                  spellCheck={false}
                />
              </div>
              {gptGeneratedQuestions.length === 0 && (
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 'auto' }}>
                  <button
                    type="button"
                    className="btn-card tonedown-blue-btn"
                    style={{ width: '2.5rem' }}
                    onClick={(e) => {
                      setIsAudioToTextModalOpen(true)
                    }}
                  >
                    <span class="material-symbols-outlined icon-size">mic</span>
                  </button>
                </div>
              )}
            </div>
            {gptGeneratedQuestions.length === 0 && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '0.75rem',
                  width: '100%',
                }}
              >
                {contextSentence !== '' && (
                  <div style={{ marginLeft: 'auto' }}>
                    <div
                      className="fitted-button modal-save-button blue"
                      style={{ padding: '0rem 0.6rem 0rem 0.6rem' }}
                      onClick={(e) => {
                        populateMemoQuestions(contextSentence)
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {isFetchingResponse === true ? (
                          <div
                            style={{
                              width: '0.3rem',
                              height: '0.3rem',
                              margin: '0',
                              borderTopColor: 'white',
                            }}
                            className="loading-spinner icon-size-l"
                          ></div>
                        ) : null}
                        <span>{t('Common:commonButtons.nextSmall')}</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {/* <div style={{textAlign:'left'}}> */}
          {memoQuestions.length > 0 && gptGeneratedQuestions.length > 0 && (
            <Formik
              enableReinitialize
              initialValues={textAreas}
              validationSchema={validationSchema}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={(values, resetForm) => {
                // Handle form submission
                // submitForm(values)
                prepareDratMemoData()
                resetForm()
              }}
            >
              {({ errors, touched, resetForm, setFieldValue, values }) => (
                <Form
                  className="objective-form"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    textAlign: 'left',
                  }}
                >
                  {memoQuestions.map((question, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        marginTop: '1rem',
                      }}
                    >
                      <div style={{ marginBottom: '1rem' }}>
                        <b>{`${index + 1}. ${question}`}</b>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div
                          className="review-generated-topics-list-item"
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'left',
                            width: '100%',
                          }}
                        >
                          {/* <TextArea
                        name={index}
                        rows={3}
                        type="text"
                        onChange={(e) => {
                          handleQuestionTextChange(e)
                        }}
                        value={textAreas[index]}
                        style={{height:'5rem', backgroundColor: '#f1f0f0', width:'100%'}}
                        // disabled={isFetchingResponse}
                    /> */}
                          <Field
                            id={`question${index}`}
                            name={`question${index}`}
                            onChange={(e) => {
                              handleQuestionTextChange(e, setFieldValue)
                            }}
                            autoComplete="off"
                            component="textarea"
                            style={{ height: '5rem', backgroundColor: '#f1f0f0', width: '100%' }}
                          />
                          {errors[`question${index}`] && touched[`question${index}`] && (
                            <div className="validation-error" style={{ width: '100%' }}>
                              {errors[`question${index}`]}
                            </div>
                          )}
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column', marginLeft: 'auto' }}
                        >
                          <button
                            type="button"
                            className="btn-card tonedown-blue-btn"
                            style={{ width: '2.5rem' }}
                            onClick={(e) => {
                              setSelectedQuestionIndex(index)
                              setIsAudioToTextModalOpen(true)
                            }}
                          >
                            <span class="material-symbols-outlined icon-size">mic</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div style={{ display: 'flex', marginLeft: 'auto', marginTop: '1rem' }}>
                    <button
                      className="fitted-button modal-save-button blue"
                      style={{ padding: '0rem 0.6rem 0rem 0.6rem' }}
                      type="submit"
                    >
                      <span>{t('Common:commonButtons.draft')}</span>
                    </button>
                    {/* <ModalSaveButtonFitted
                id='ai-memo-questions-modal-save-button'
                tag={t('Common:commonButtons.draft')} 
              /> */}
                  </div>
                </Form>
              )}
            </Formik>
          )}
          {/* </div> */}
          {/* <div onClick={() => {
            prepareDratMemoData()
          }}>
            Draft
          </div> */}
        </div>
      </div>
    </Modal>
  )
}

export default AiMemoQuestionsModal

import React from 'react'
import WizardButton from '../../../../../../components/WizardButton'
import { useTranslation } from 'react-i18next'

const SubmitUpdateWizard = ({ submitIsLoading, currentStep, setErrorMessage, handleSubmit }) => {
  const { t } = useTranslation(['KPIWizard', 'Common'])
  const [isDisabled, setIsDisabled] = React.useState(false)

  return (
    <>
      <div className="submit-update-wizard-button">
        <WizardButton
          isLoading={submitIsLoading}
          onClick={() => {
            handleSubmit()
            setIsDisabled(true)
            setTimeout(function () {
              setIsDisabled(false)
            }, 10000)
          }}
          disabled={isDisabled}
          text={t('saveKpis')}
        />
      </div>
    </>
  )
}

export default SubmitUpdateWizard

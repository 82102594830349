import React from 'react'
import { Link } from 'react-router-dom'
import { routes, changesReportSubRoutes } from '../../../utils/routes'
import { useLocation } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

const ChangesReportHeaders = ({}) => {
  const { t } = useTranslation(['ChangesReport'])

  const location = useLocation()

  return (
    <div className="meetingview-nav-container">
      <Link
        to={`/${routes.changesReport}/${changesReportSubRoutes.actions}`}
        className={`nav-option ${
          location.pathname.endsWith(changesReportSubRoutes.actions) ? 'active' : ''
        }`}
      >
        <span className="nav-option-text">{t('tabTitle.actions')}</span>
      </Link>
      <Link
        to={`/${routes.changesReport}/${changesReportSubRoutes.meetingTopics}`}
        className={`nav-option ${
          location.pathname.endsWith(changesReportSubRoutes.meetingTopics) ? 'active' : ''
        }`}
      >
        <span className="nav-option-text">{t('tabTitle.meetingTopics')}</span>
      </Link>
      <Link
        to={`/${routes.changesReport}/${changesReportSubRoutes.kpis}`}
        className={`nav-option ${
          location.pathname.endsWith(changesReportSubRoutes.kpis) ? 'active' : ''
        }`}
      >
        <span className="nav-option-text">{t('tabTitle.kpis')}</span>
      </Link>
    </div>
  )
}

export default ChangesReportHeaders

import React, { useRef, useState, useEffect } from 'react'
import '../../MeetingMultiView/components/MeetingTopics/TopScrollbar.css'
import { useNavbarContext } from '../../../utils/navBarContext'

function MeiTopScrollbar({ children }) {
  const containerRef = useRef(null)
  const fakeScrollbarRef = useRef(null)
  const [contentWidth, setContentWidth] = useState(0)
  const [width, setWidth] = useState(0)

  const { isNavbarCollapsed, handleNavbarStateChange } = useNavbarContext()
  let minusValue = isNavbarCollapsed ? 200 : 400
  const [boxWidth, setBoxWidth] = useState(window.innerWidth - minusValue)

  useEffect(() => {
    let minusValue = isNavbarCollapsed ? 200 : 400
    const handleResize = () => {
      setBoxWidth(window.innerWidth - minusValue)
    }

    window.addEventListener('resize', handleResize)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []) // Empty dependency array ensures this effect runs only once after initial render

  useEffect(() => {
    let minusValue = isNavbarCollapsed ? 200 : 400

    setBoxWidth(window.innerWidth - minusValue)
  }, [isNavbarCollapsed])

  useEffect(() => {
    const container = containerRef.current

    const updateWidth = () => {
      const viewportWidth = container.clientWidth
      const contentWidth = container.scrollWidth
      const visibleRatio = viewportWidth / contentWidth

      const newThumbWidth = visibleRatio * viewportWidth
      setContentWidth(newThumbWidth)
      setWidth(contentWidth)
    }

    updateWidth()

    const observer = new MutationObserver(updateWidth)
    observer.observe(container, {
      childList: true,
      subtree: true,
      attributes: true,
    })
    return () => {
      observer.disconnect()
    }
  }, [])

  const handleFakeScroll = (event) => {
    containerRef.current.scrollLeft = event.target.scrollLeft
  }

  const handleScroll = (event) => {
    fakeScrollbarRef.current.scrollLeft = event.target.scrollLeft
  }

  return (
    <div style={{ width: boxWidth }}>
      <div className="fake-scrollbar" onScroll={handleFakeScroll} ref={fakeScrollbarRef}>
        <div style={{ width: `${width}px` }}>
          <div style={{ width: `${contentWidth}px` }}></div>
        </div>
      </div>
      <div className="content" onScroll={handleScroll} ref={containerRef}>
        {children}
      </div>
    </div>
  )
}

export default MeiTopScrollbar

import { useTranslation } from 'react-i18next'
import Modal from '../Modal'
import { useEffect, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { ModalSaveButtonFitted } from '../ModalSubmitButton'
import {
  setEmailReminder,
  updateEmailReminder,
  unlinkReminderTopic,
} from '../../../src/api/services/meetingTopic.services'
import LinkReminderToTopicModal from './LinkReminderToTopicModal'
import CardModal from '../CardModal'
import * as Yup from 'yup'
import { getDayOfWeek, getTimZoneOptions } from '../../utils/time'
import Select from 'react-select'
import { getConsolidatedConfigSettings } from '../../utils/auth'

const SetEmailReminderModal = ({
  closeModal,
  passedLinkedTopic = null,
  selectedReminder = null,
  mode = 'add',
  reload = () => {},
}) => {
  console.log('selectedReminder', selectedReminder)
  const { t } = useTranslation('Common')
  const [linkedTopic, setLinkedTopic] = useState(passedLinkedTopic)
  const [isCardModalOpen, setIsCardModalOpen] = useState(false)
  const [isLinkReminderToMeetingTopicModalOpen, setIsLinkReminderToMeetingTopicModalOpen] =
    useState(false)
  const language = getConsolidatedConfigSettings('language_settings') || 'en'
  const frequencyOptions = [
    {
      label: t('setEmailReminderModal.once'),
      value: 'once',
    },
    {
      label: t('setEmailReminderModal.daily'),
      value: 'daily',
    },
    {
      label: t('setEmailReminderModal.weekly'),
      value: 'weekly',
    },
    {
      label: t('setEmailReminderModal.biweekly'),
      value: 'bi-weekly',
    },
    {
      label: t('setEmailReminderModal.monthly'),
      value: 'monthly',
    },
  ]

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const timeZoneOptions = getTimZoneOptions()

  const handleSubmit = async (values) => {
    let formData = new FormData()
    formData.append('name', values.name)
    formData.append('frequency', values.frequency)
    formData.append('nextOccurrence', values.nextOccurrence)
    formData.append('attachedMessage', values.attachedMessage)
    formData.append('timeZone', values.timeZone)
    if (linkedTopic) {
      formData.append('umtId', linkedTopic.umtId)
    }
    if (values.monthFrequency && values.frequency == 'monthly') {
      formData.append('monthFrequency', values.monthFrequency)
    }
    if (mode == 'add') {
      await setEmailReminder(formData)
    } else if (mode == 'edit') {
      formData.append('reminderId', selectedReminder.reminderId)
      await updateEmailReminder(formData)
    }
    reload()
  }

  useEffect(() => {
    console.log(linkedTopic)
  }, [linkedTopic])

  const getActionBox = (topicType) => {
    let type
    let color
    if (topicType === 1) {
      type = 'blocker'
      color = 'rgb(237, 82, 46)'
    }
    if (topicType === 2) {
      type = 'guidance'
      color = 'blue'
    }
    if (topicType === 3) {
      type = 'update'
      color = 'gray'
    }
    if (topicType === 4) {
      type = 'admin'
      color = '#9933FF'
    }
    return (
      <div className="meeting-topic-tagbox" style={{ backgroundColor: color }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '0.8rem',
          }}
        >
          {t(`Common:objReportModal.${type}`)}
        </div>
      </div>
    )
  }

  const emialReminderModalSchema = (requiredMessage) => {
    return Yup.object().shape({
      name: Yup.string().required(requiredMessage),
      frequency: Yup.string().required(requiredMessage),
      nextOccurrence: Yup.date().required(requiredMessage),
      timeZone: Yup.string().required(requiredMessage),
      monthFrequency: Yup.array().when('frequency', {
        is: 'monthly',
        then: Yup.array().min(2, requiredMessage).required(requiredMessage),
      }),
    })
  }

  const validationSchema = emialReminderModalSchema(t('Common:required'))

  const unLinkTopic = async () => {
    let res = await unlinkReminderTopic(selectedReminder.reminderId)
    setLinkedTopic(null)
    if (res?.success) {
      setLinkedTopic(null)
      reload()
    }
  }

  const calculateMonthlyFrequency = (date) => {
    console.log(date)
    //caculate which day of the week
    const day = getDayOfWeek(date)
    let monthFrequencyOption = [
      {
        label:
          language == 'en'
            ? 'First ' + t('setEmailReminderModal.' + day) + ' of the month'
            : 'Premier ' + t('setEmailReminderModal.' + day) + ' du mois',
        value: [1, day],
      },
      {
        label:
          language == 'en'
            ? 'Second ' + t('setEmailReminderModal.' + day) + ' of the month'
            : 'Deuxième ' + t('setEmailReminderModal.' + day) + ' du mois',
        value: [2, day],
      },
      {
        label:
          language == 'en'
            ? 'Third ' + t('setEmailReminderModal.' + day) + ' of the month'
            : 'Troisième ' + t('setEmailReminderModal.' + day) + ' du mois',
        value: [3, day],
      },
      {
        label:
          language == 'en'
            ? 'Fourth ' + t('setEmailReminderModal.' + day) + ' of the month'
            : 'Quatrième ' + t('setEmailReminderModal.' + day) + ' du mois',
        value: [4, day],
      },
    ]
    // console.log(monthFrequencyOption)
    return monthFrequencyOption
  }

  return (
    <Modal
      closeModal={closeModal}
      includeCloseIcon={true}
      width="35rem"
      title={t('setEmailReminderModal.title')}
      titleStyle={{ color: 'rgb(91,132,203)', textAlign: 'left' }}
      modalTitleContainerStyle={{ justifyContent: 'flex-start' }}
    >
      {isLinkReminderToMeetingTopicModalOpen && (
        <LinkReminderToTopicModal
          closeModal={() => {
            setIsLinkReminderToMeetingTopicModalOpen(false)
          }}
          setLinkedTopic={setLinkedTopic}
        />
      )}
      {isCardModalOpen && (
        <CardModal
          isModalOpen={isCardModalOpen}
          closeModal={() => {
            setIsCardModalOpen(false)
          }}
          topicType={linkedTopic.topicType}
          umtId={linkedTopic.umtId}
        />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <Formik
          enableReinitialize
          initialValues={{
            name: mode == 'edit' ? selectedReminder.reminderName : '',
            frequency: mode == 'edit' ? frequencyOptions[selectedReminder.frequency - 1].value : '',
            nextOccurrence: mode == 'edit' ? selectedReminder.rawTime : '',
            attachedMessage: mode == 'edit' ? selectedReminder.attachedMessage : '',
            timeZone: mode == 'edit' ? selectedReminder.timeZone : timeZone,
            monthFrequency:
              mode == 'edit'
                ? selectedReminder.whichWeekOfTheMonth != 0
                  ? [selectedReminder?.whichWeekOfTheMonth, selectedReminder?.whichDayOfTheWeek]
                  : []
                : [],
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values)
            closeModal()
            resetForm()
          }}
        >
          {({ errors, touched, resetForm, setFieldValue, values }) => (
            <Form className="objective-form">
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '30rem',
                  }}
                >
                  <div className="form-field">
                    <label>{t('setEmailReminderModal.name')}</label>
                    <Field
                      type="text"
                      maxLength="200"
                      id="name"
                      name="name"
                      autoComplete="off"
                      style={{ width: '20rem' }}
                    />
                    {errors.name && touched.name && (
                      <div className="validation-error">{errors.name}</div>
                    )}
                  </div>

                  <div className="form-field">
                    <label>{t('setEmailReminderModal.frequency')}</label>
                    <Field
                      as="select"
                      id="frequency"
                      name="frequency"
                      style={{ width: '21rem', height: '2.5rem' }}
                    >
                      <option></option>
                      {frequencyOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Field>
                    {errors.frequency && touched.frequency && (
                      <div className="validation-error">{errors.frequency}</div>
                    )}
                  </div>

                  <div className="form-field">
                    <label>{t('setEmailReminderModal.nextOccurrence')}</label>
                    <Field name="nextOccurrence">
                      {({ field, form }) => (
                        <input
                          {...field}
                          type="datetime-local"
                          style={{
                            width: '20rem',
                            backgroundColor: 'white',
                            border: '1px solid #D9D9D9',
                          }}
                          onChange={(e) => {
                            const newValue = e.target.value
                            form.setFieldValue('nextOccurrence', newValue)

                            form.setFieldValue('monthFrequency', [])
                            console.log('monthFrequency cleared:', form.values.monthFrequency)
                          }}
                        />
                      )}
                    </Field>
                    {errors.nextOccurrence && touched.nextOccurrence && (
                      <div className="validation-error">{errors.nextOccurrence}</div>
                    )}
                  </div>

                  {values.frequency == 'monthly' && values.nextOccurrence != '' && (
                    <div className="form-field">
                      <label>{t('setEmailReminderModal.monthFrequency')}</label>
                      <Field name="monthFrequency">
                        {({ field, form }) => (
                          <div style={{ width: '21rem' }}>
                            <Select
                              options={calculateMonthlyFrequency(new Date(values.nextOccurrence))}
                              value={calculateMonthlyFrequency(
                                new Date(values.nextOccurrence),
                              ).find(
                                (option) =>
                                  option.value[0] == values.monthFrequency[0] &&
                                  option.value[1] == values.monthFrequency[1],
                              )}
                              onChange={(selected) => {
                                console.log(selected)
                                form.setFieldValue('monthFrequency', selected ? selected.value : [])
                              }}
                              styles={{ width: '20rem' }}
                              // use a key to force the component to re-render when the value changes
                              key={values.nextOccurrence}
                            />
                            {errors.monthFrequency && touched.monthFrequency && (
                              <div className="validation-error">{errors.monthFrequency}</div>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                  )}

                  <div className="form-field">
                    <label>{t('setEmailReminderModal.timeZone')}</label>
                    <Field
                      as="select"
                      id="timeZone"
                      name="timeZone"
                      style={{
                        width: '21rem',
                        height: '2.5rem',
                        backgroundColor: 'white',
                        border: '1px solid #D9D9D9',
                      }}
                    >
                      <option></option>
                      {timeZoneOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Field>
                    {errors.timeZone && touched.timeZone && (
                      <div className="validation-error">{errors.timeZone}</div>
                    )}
                  </div>

                  <div className="form-field">
                    <label>{t('setEmailReminderModal.attachedMessage')}</label>
                    <Field
                      component="textarea"
                      maxLength="200"
                      id="attachedMessage"
                      name="attachedMessage"
                      autoComplete="off"
                      style={{ width: '20rem', resize: 'none' }}
                      rows="4"
                    />
                    {/* {errors.name && touched.name && (
                                        <div className="validation-error">{errors.name}</div>
                                    )} */}
                  </div>

                  {/* link to topic */}

                  <div className="form-field">
                    <label>{t('setEmailReminderModal.linkToTopic')}</label>
                    {linkedTopic ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          gap: '1rem',
                        }}
                      >
                        {getActionBox(linkedTopic.topicType)}
                        <span
                          style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                          onClick={() => {
                            setIsCardModalOpen(true)
                          }}
                        >
                          {linkedTopic.actionNeeded}
                        </span>
                        <span
                          style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                          onClick={unLinkTopic}
                        >
                          {t('setEmailReminderModal.unLink')}
                        </span>
                      </div>
                    ) : (
                      <div
                        className={'no-border-button no-border-button-gray  tooltip'}
                        onClick={() => {
                          setIsLinkReminderToMeetingTopicModalOpen(true)
                        }}
                      >
                        <span class="material-symbols-outlined icon-size"> add_link</span>
                        <span class="tooltiptext" style={{ left: '90%' }}>
                          {' '}
                          {t('Common:tooltip.linkMeetingTopic')}{' '}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', marginTop: '1rem', justifyContent: 'flex-end' }}>
                <ModalSaveButtonFitted
                  // isLoading={isLoading}
                  id="uw-kpi-update-modal-update-btn"
                  tag={t('Common:commonButtons.save')}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

export default SetEmailReminderModal

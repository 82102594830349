import React from 'react'
import edit from '../../../../assets/edit.svg'
import deleteCross from '../../../../assets/delete-cross.svg'
import plus from '../../../../assets/plus.svg'
import exclaim from '../../../../assets/exclaim.svg'
import help from '../../../../assets/help.svg'
import checkmark from '../../../../assets/checkmark.svg'
import { useSetupWizardContext } from '../../context/setupWizardContext'
import ObjectiveTypeHelpModal from '../../../../components/ObjectiveTypeHelpModal'
import SetupAddEditObjectiveModal from '../../components/SetupAddEditObjectiveModal'

import './styles.css'

const TopLevelObjectiveStep = () => {
  const { state, dispatch } = useSetupWizardContext()

  const openHelpModal = () => {
    dispatch({ type: 'OPEN_OBJ_HELP_MODAL' })
  }

  const closeHelpModal = () => {
    dispatch({ type: 'CLOSE_OBJ_HELP_MODAL' })
  }

  const GOAL_KEY = 'topLevelObjectives'
  const GOAL_TYPE = 'TOP_LEVEL'

  return (
    <div className="enterprise-objective-step-component">
      <ObjectiveTypeHelpModal isOpen={state.isObjHelpModalOpen} handleClose={closeHelpModal} />
      <SetupAddEditObjectiveModal goalKey={GOAL_KEY} goalType={GOAL_TYPE} />

      <div className="step-section objectives-step">
        <h2>Let’s define our Enterprise Objectives</h2>
        <ul style={{ width: '45vw' }} className="diamond-yellow-list">
          <li className="diamond-yellow">
            These should be outlined in your company’s enterprise strategy. Most strategies have
            5-10 objectives that are to be achieved over a time horizon of 2-5 years.
          </li>
          <li className="diamond-yellow">
            For each objective, we will ask you to state <b>What</b> is means, <b>Why</b> it
            matters, and <b>How</b> it will be achieved. Make sure you write these in simple
            language to help team leaders understand the strategy and how their work ladders up.
          </li>
        </ul>

        <div className="goal-container shadow">
          {state.data[GOAL_KEY] ? (
            state.data[GOAL_KEY].map((objective, index) => (
              <div className="goal" key={`obj-${index}`}>
                <div className="input-wrapper">
                  {!objective.isValid && <img src={exclaim} className="warning" alt="Warning" />}

                  <div className={`goal-input ${objective.isValid ? 'valid' : 'invalid'}`}>
                    <span className="objective-statement">{objective.statement}</span>
                  </div>
                </div>

                <div
                  className="edit-button"
                  onClick={() => {
                    dispatch({ type: 'OPEN_OBJ_FORM_MODAL', editObjectiveIndex: index })
                  }}
                >
                  <img className="edit-btn" src={edit} alt="Click to edit symbol" />
                </div>
                <div
                  className="delete-button"
                  onClick={() => {
                    dispatch({ type: 'DELETE_TOP_LEVEL_GOAL', objectiveIndex: index })
                  }}
                >
                  <img className="delete-btn" src={deleteCross} alt="Click to edit symbol" />
                </div>
              </div>
            ))
          ) : (
            <b className="no-objectives-text">You don't currently have any objectives...</b>
          )}
          <div
            className="add-btn"
            onClick={() => {
              dispatch({ type: 'OPEN_OBJ_FORM_MODAL', editObjectiveIndex: null })
            }}
          >
            <img className="plus" src={plus} alt="TransforML Logo" />
            <span>ADD OBJECTIVE</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopLevelObjectiveStep

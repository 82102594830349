import React, { useState, useEffect } from 'react'
import { useTeamLeaderContext } from '../../context/teamLeaderContext'
import ExternalMembersAlloc from '../../../../components/ExternalMemberAlloc'
import { useOutletContext } from 'react-router-dom'

import './styles.css'

function intOr(val) {
  let res = parseInt(val)
  if (res === undefined || isNaN(res)) res = 0
  return res
}

const ExternalMembers = () => {
  const { state, dispatch } = useTeamLeaderContext()
  const employees = state.data.employees
  const objectives = state.data.objectives
  const [
    setErrorMessage,
    handleNext,
    handlePrevious,
    setExternalMemberAllocValid,
    setExternaMemberAllocErrorArray,
    trigger,
  ] = useOutletContext()

  const [externalEmpAlocs, setExternalEmpAlocs] = useState(
    state.data.externalEmpAlocs === undefined || state.data.externalEmpAlocs === null
      ? {}
      : JSON.parse(JSON.stringify(state.data.externalEmpAlocs)),
  )

  React.useEffect(() => {
    dispatch({ type: 'UPDATE_EXTERNALEMPALOCS', externalEmpAlocs: externalEmpAlocs })
  }, [externalEmpAlocs])

  return (
    <div className="wizard-step">
      <h2 className="wizard-step__title">
        Let's indicate what the borrowed team members will be working on
      </h2>
      <div style={{ margin: '0' }} className="wizard-step__overview">
        <div className="wizard-step__overview__paragraph">
          <span>
            Enter the percentage of time that each borrowed team member will be spending on your
            team's objectives.
          </span>
        </div>
        <div className="wizard-step__overview__paragraph">
          <span>
            Each team member's total should not exceed what has been agreed upon with their original
            team.
          </span>
        </div>
      </div>
      {/* <p style={{ display: 'flex',flexDirection:"column", justifyContent: 'center', alignItems: 'center' }}>
          <div>
          Enter the percentage of time that each borrowed team member will be spending on your team's objectives.
          </div>
          
          <div>
            Each team member's total should not exceed what has been agreed upon with their original
            team.
          </div>
        </p> */}
      <div
        className="wizard-step__main-content-container"
        style={{ width: 'fit-content', minWidth: '60vw' }}
      >
        <ExternalMembersAlloc
          state={state}
          employees={employees}
          objectives={objectives}
          externalEmpAlocsH={externalEmpAlocs}
          setExternalEmpAlocs={setExternalEmpAlocs}
          setExternalMemberAllocValid={setExternalMemberAllocValid}
          setExternaMemberAllocErrorArray={setExternaMemberAllocErrorArray}
          disableEditingZero={false} // We allow editing 0 allocation because this is hte initial wizard.
          trigger={trigger}
        />
      </div>
    </div>
  )
}

export default ExternalMembers

import * as yup from 'yup'

const taskSchema = yup.object().shape({
  assign: yup.object().shape({
    eId: yup.string().required('Select Team Leader is Required !'),
    name: yup.string().required('Select Team Leader is Required !'),
  }),
})

export default taskSchema

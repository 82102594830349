import React from 'react'
import { defaultState, KPIProvider } from './context/kpiContext'
import KPIWizard from './components/KPIWizard'
import Loading from '../../components/Loading'
import ApplicationLayout from '../../components/ApplicationLayout'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const KPIWizardPage = () => {
  const location = useLocation()
  const showObjOnly = location?.state?.showObjOnly ?? null
  const existingStep = showObjOnly ? 2 : 0

  const { t } = useTranslation(['KPIWizard'])

  const initialState = {
    ...defaultState,
    locationState: location.state,
  }

  return (
    <ApplicationLayout>
      <KPIProvider initialState={initialState}>
        <div
          className="wizard-container"
          style={{ display: 'flex', flexDirection: 'column', margin: '0 2rem 0 2rem' }}
        >
          <KPIWizard existingStep={existingStep} />
        </div>
      </KPIProvider>
    </ApplicationLayout>
  )
}

export default KPIWizardPage

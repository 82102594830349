import React, { useState } from 'react'
import Modal from '../Modal'
import * as Yup from 'yup'
import { Formik, Field, Form, useFormikContext, useField } from 'formik'
import { ModalSaveButtonFitted } from '../ModalSubmitButton'
import { postHttpRequest } from '../../api/query/dynamicAPI'
import { useTranslation } from 'react-i18next'
import Toast from '../../common/toast'
import './style.css'
import { isAreaLeader, isTeamLeader } from '../../utils/auth'

const TutorialManagementModal = ({ isModalOpen, closeModal }) => {
  const { t } = useTranslation(['Common'])
  const initialValue = JSON.parse(localStorage.getItem('cfg'))
  const [mhChecked, setMhchecked] = React.useState(initialValue.mh_tutorial_off)
  const [uwChecked, setUwchecked] = React.useState(initialValue.uw_tutorial_off)
  const handleMhChange = () => {
    setMhchecked((prevState) => !prevState)
  }
  const handleUwChange = () => {
    setUwchecked((prevState) => !prevState)
  }

  const updateLocalStorage = () => {
    let cv = localStorage.getItem('cfg')
    let config = JSON.parse(cv)
    config.uw_tutorial_off = uwChecked
    config.mh_tutorial_off = mhChecked
    let updatedConfig = JSON.stringify(config)
    localStorage.setItem('cfg', updatedConfig)
  }

  return (
    <>
      <div className="tutorial-management-modal">
        <Modal isModalOpen={isModalOpen} closeModal={closeModal} width={'40rem'}>
          <div className="modal-gray-box">
            <div className="modal-inner-content">
              <Formik
                initialValues={{
                  decisionPlus: false,
                  meetingHub: false,
                  kanban: false,
                  updateWizard: false,
                }}
                onSubmit={({ decisionPlus, meetingHub, kanban, updateWizard }) => {
                  ;(async () => {
                    const data = {
                      mhTutorialOff: mhChecked,
                      uwTutorialOff: uwChecked,
                      kbTutorialoff: kanban,
                      onTutorialoff: decisionPlus,
                    }
                    try {
                      await postHttpRequest('/edit_employees_user_preferences', data)
                      updateLocalStorage()
                    } catch (error) {
                      Toast.fire({
                        icon: 'error',
                      })
                    }
                  })()
                  closeModal()
                }}
              >
                {() => (
                  <Form className="form-field">
                    <div className="tutorial-management-modal-title">
                      {t('tutorialManagementModal.title')}
                    </div>
                    <div className="tutorial-management-modal-text">
                      {' '}
                      {t('tutorialManagementModal.message')}
                    </div>
                    <div className="tutorial-management-modal-text-unavailable">
                      <label class="custom-checkbox">
                        <Field type="checkbox" name="decisionPlus" checked={false} hidden />
                        <span class="checkmark"></span>
                      </label>
                      <span>{t('tutorialManagementModal.overallNavigation')}</span>
                    </div>
                    <div className="tutorial-management-modal-text">
                      <label class="custom-checkbox">
                        <Field
                          type="checkbox"
                          name="meetingHub"
                          checked={mhChecked}
                          onChange={handleMhChange}
                          hidden
                        />
                        <span class="checkmark"></span>
                      </label>
                      <span>{t('tutorialManagementModal.meetingHub')}</span>
                    </div>
                    <div className="tutorial-management-modal-text-unavailable">
                      <label class="custom-checkbox">
                        <Field type="checkbox" name="kanban" checked={false} hidden />
                        <span class="checkmark"></span>
                      </label>
                      <span>{t('tutorialManagementModal.kanban')}</span>
                    </div>
                    {(isAreaLeader() || isTeamLeader()) && (
                      <div className="tutorial-management-modal-text">
                        <label class="custom-checkbox">
                          <Field
                            type="checkbox"
                            name="updateWizard"
                            checked={uwChecked}
                            onChange={handleUwChange}
                          />
                          <span class="checkmark"></span>
                        </label>
                        <span>{t('tutorialManagementModal.updateWizard')}</span>
                      </div>
                    )}
                    <div style={{ textAlign: 'right', width: '100%' }}>
                      <button
                        type="submit"
                        className="sleek-button sleek-blue"
                        style={{
                          cursor: 'pointer',
                          height: '2.2rem',
                          backgroundColor: 'white',
                          float: 'right',
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default TutorialManagementModal

import { getHttpRequest, postHttpRequest } from '../query/dynamicAPI'
import {
  GET_SINGLE_SUB_ACTIVITY,
  CREATE_SUB_ACTIVITY,
  API_ERROR_MESSAGE,
  GET_SUB_ACTIVITIES,
} from '../../api/constant/route'
import Toast from '../../common/toast'

export async function getSingleSubActivityByActivityId(activityId) {
  try {
    const data = await getHttpRequest(`${GET_SINGLE_SUB_ACTIVITY}/${activityId}`)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function createorUpdateSubActivity(postData) {
  try {
    const data = await postHttpRequest(`${CREATE_SUB_ACTIVITY}`, postData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getSubActivityByActivityId(activityId) {
  try {
    const data = await getHttpRequest(`${GET_SUB_ACTIVITIES}/${activityId}`)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

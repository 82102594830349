import React from 'react'
import { useTranslation } from 'react-i18next'
import { shortenName } from '../../utils/general'
import { dayMonthFilter } from '../../utils/time'
import { removeMentionMarkupFromText } from '../../utils/parseMarkup'

const NotificationRow = ({
  description,
  empName,
  notificationText,
  createdDate,
  seeMoreClickHandler,
  actionId,
  clearDictionary,
  handleChangeCheckbox,
  index,
}) => {
  const { t } = useTranslation(['Notifications', 'Common'])

  const containerRef = React.useRef(null)

  React.useEffect(() => {
    const container = containerRef.current
    if (container.scrollWidth > container.clientWidth) {
      container.classList.add('ellipsis-with-quote')
    }
  }, [])

  return (
    <div>
      <div key={index} style={{ padding: '0.5rem 0 1rem 0' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <input
              type="checkbox"
              checked={clearDictionary[actionId] === true}
              onClick={() => {
                handleChangeCheckbox()
              }}
            />
            <span className="notification-type" ref={containerRef}>
              {removeMentionMarkupFromText(description)}{' '}
            </span>
          </div>
          <div
            className="notification-metadata"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', gap: '0.2rem' }}>
              <span>{shortenName(empName)}</span>
            </div>
            <span>{dayMonthFilter(createdDate)}</span>
          </div>
          <div className="notification-text">{removeMentionMarkupFromText(notificationText)}</div>
          <div
            className="notification-link"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
            onClick={() => {
              seeMoreClickHandler()
            }}
          >
            {t('Notifications:seeMore')}
          </div>
        </div>
      </div>
      <div class="row-border"></div>
    </div>
  )
}
export default NotificationRow

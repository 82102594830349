import React from 'react'
import { Formik, Field, Form } from 'formik'
import './styles.css'
import plus from '../../../../assets/plus.svg'
import deleteCross from '../../../../assets/delete-cross.svg'
import AddEmployeeModal from './../../../../components/EmployeeModal/components/AddEmployeeModal'
import { useTeamLeaderContext } from '../../context/teamLeaderContext'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

const TeamStep = () => {
  const { state, dispatch } = useTeamLeaderContext()
  const { t } = useTranslation(['TeamLeaderWizard', 'Common'])

  const updateEmployeeAllocation = (allocation, index) => {
    const employees = state.data.employees
    employees[index].teamAllocPct = allocation

    dispatch({ type: 'UPDATE_EMPLOYEE_ALLOCATION', employees })
  }

  React.useEffect(() => {
    let tempNonReportingEmpsRealIndexes = []
    for (let i = 0; i < state.data.employees.length; i++) {
      if (state.data.employees[i].isDirectReport !== true) {
        tempNonReportingEmpsRealIndexes.push(i)
      }
    }
    dispatch({
      type: 'UPDATE_NONREPORTINGEMPSREALINDEXES',
      nonReportingEmpsRealIndexes: tempNonReportingEmpsRealIndexes,
    })
  }, [state.data.employees])

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
  }, [])

  const TimeOnTeamSchema = Yup.object().shape({
    timeOnTeam: Yup.number()
      .min(0, t('Common:validationMessages.lessThan0'))
      .max(100, t('Common:validationMessages.greaterThan100'))
      .required(t('Common:required')),
  })

  return (
    <>
      <AddEmployeeModal state={state} dispatch={dispatch} />

      <div className="step-section team-step">
        <h2>{t('teamStep.title')}</h2>
        {state.isFlowToWorkLeader === true && (
          <div className="objective-instructions">
            <div className="instruction">
              <span>{t('teamStep.f2wMsg')}</span>
            </div>
          </div>
        )}

        <div className="table-wrapper shadow">
          <table>
            <tbody>
              <tr className="header-row">
                <th>
                  <span>{t('teamStep.teamMember')}</span>
                </th>
                <th>
                  <span>{t('teamStep.email')}</span>
                </th>
                <th style={{ justifyContent: 'center', alignItems: 'center' }}>
                  <span>{t('teamStep.timeOnTeam')}</span>
                </th>
                <th style={{ width: '1rem' }}>
                  <span></span>
                </th>
              </tr>
              {state.data.employees?.map((employee, index) => (
                <tr id={employee.name} key={`employee-${index}`}>
                  <td data-testid={`employee-name-${index}`}>{employee.name}</td>
                  <td
                    data-testid={`employee-email-${index}`}
                    style={{ overflowWrap: 'break-word' }}
                  >
                    {employee.email}
                  </td>
                  <td style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Formik
                      enableReinitialize
                      initialValues={{
                        timeOnTeam: employee.teamAllocPct,
                      }}
                      validationSchema={TimeOnTeamSchema}
                    >
                      {({ errors, touched, resetForm }) => (
                        <Form
                          className="form"
                          onChange={(e) => {
                            updateEmployeeAllocation(parseInt(e.target.value), index)
                          }}
                        >
                          <div>
                            <Field
                              className="time-input"
                              id="timeOnTeam"
                              name="timeOnTeam"
                              type="number"
                              data-testid={`employee-timeOnTeam-${index}`}
                            />
                            %
                          </div>
                          {/* TODO: tie errors to ability to click next  */}
                          {errors.timeOnTeam && touched.timeOnTeam && (
                            <div className="validation-error">{errors.timeOnTeam}</div>
                          )}
                        </Form>
                      )}
                    </Formik>
                  </td>
                  <td style={{ width: '1rem' }}>
                    {(employee.isDirectReport === false ||
                      employee.isDirectReport === undefined) && (
                      <div
                        className="delete-button"
                        onClick={() => {
                          dispatch({ type: 'DELETE_EMPLOYEE', localeId: employee.localeId })
                        }}
                      >
                        <img className="delete-btn" src={deleteCross} alt="Click to edit symbol" />
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div
            className="add-btn"
            onClick={() => {
              dispatch({ type: 'OPEN_EMPLOYEE_MODAL' })
            }}
          >
            <img className="plus" src={plus} alt="Graphic of plus sign" />
            <span>{t('teamStep.addTeamMember')}</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default TeamStep

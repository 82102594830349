import React, { useState, useEffect } from 'react'
import AddCustomField from './component/CustomField/addCustomField'
import { useLocation, useNavigate } from 'react-router-dom'

import InPageLoader from '../../components/InPageLoader/index'
import ApplicationLayout from '../../components/ApplicationLayout'
import CloseIcon from '../../assets/close-icon-white.svg'

import { customFieldList } from '../../utils/activityStatus'
import './style.scss'

import {
  getGoalCustomTemplate,
  addGoalCustomTemplate,
  editGoalCustomTemplate,
} from '../../api/services/customTemplate.service'

const initialMandatory = [
  {
    fieldId: '1',
    name: 'Due Date',
    fieldType: 3,
    required: true,
  },
  {
    fieldId: '2',
    name: 'Assign To',
    fieldType: 4,
    required: true,
  },
]

const initialValues = {
  selectedField: '',
}

const CreateTask = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [mode, setMode] = useState('Add')

  const [mandatory, setMandatoryField] = useState([])
  const [customFieldData, setCustomFieldData] = useState([])
  const [formValues, setFormValues] = useState(initialValues)
  const [isLoading, setIsLoading] = useState(false)
  const [isCustomModel, setIsCustomModel] = useState(false)

  const [editCustomData, setEditCustomData] = useState('')
  const [error, setError] = useState('')

  const queryParams = new URLSearchParams(location.search)
  const objectiveId = queryParams.get('objectiveId')
  const [templateId, setTemplateId] = useState(null)

  React.useEffect(() => {
    getCustomTemplate()
  }, [])

  // Custom comparator function to sort the objects
  function comparator(obj1, obj2, order) {
    let index1 = order.indexOf(obj1.fieldId)
    let index2 = order.indexOf(obj2.fieldId)

    if (index1 < index2) {
      return -1
    } else if (index1 > index2) {
      return 1
    }
    return 0
  }

  const getCustomTemplate = async () => {
    setIsLoading(true)
    const result = await getGoalCustomTemplate(objectiveId)
    setIsLoading(false)

    if (result?.template_data?.templateId) {
      //set template id hook
      setTemplateId(result?.template_data?.templateId)
      setMode('Edit')
    }

    if (result?.template_data?.customFieldsSchema?.customFields) {
      //set custom Fields hook
      let listFields = Object.values(result?.template_data?.customFieldsSchema?.customFields)

      if (result?.template_data?.customFieldsSchema?.defaultForActivityNameKey) {
        //set activity name field
        let key = result?.template_data?.customFieldsSchema?.defaultForActivityNameKey
        for (let i = 0; i < listFields.length; i++) {
          if (listFields[i].isDeleted === true) {
            //if it comes deleted from the backend don't include it on the fe structure
            listFields.splice(i, 1)
            continue
          }

          if (parseInt(listFields[i].fieldId) === parseInt(key)) {
            //set field with activity name
            listFields[i].defaultForActivityName = true
          }

          if (listFields[i].fieldType === 4) {
            listFields[i].listId = listFields[i].userDefinedListId
          }

          if (listFields[i].fieldType === 6) {
            listFields[i].listId = listFields[i].dynamicListId
          }
        }
      }

      if (result?.template_data?.customFieldsSchema?.orderedCustomFields) {
        // Sort the custom field list to match the index order of appereance in orderedCustomFields
        listFields.sort((obj1, obj2) =>
          comparator(obj1, obj2, result?.template_data?.customFieldsSchema?.orderedCustomFields),
        )
      }

      setCustomFieldData(listFields)
    }

    //set mandatory fields
    let tempMandatory = [...initialMandatory]

    if (
      result?.template_data?.isDueDateMandatory !== null ||
      result?.template_data?.isDueDateMandatory !== undefined
    ) {
      //set 'due date' mandatory field
      for (let i = 0; i < mandatory.length; i++) {
        if (mandatory[i].name === 'Due Date') {
          tempMandatory[i].required = result?.template_data?.isDueDateMandatory
        }
      }
    }

    if (
      result?.template_data?.isIcAssignmentMandatory !== null ||
      result?.template_data?.isIcAssignmentMandatory !== undefined
    ) {
      //set 'assigned' mandatory field
      for (let i = 0; i < mandatory.length; i++) {
        if (mandatory[i].name === 'Assign To') {
          tempMandatory[i].required = result?.template_data?.isIcAssignmentMandatory
        }
      }
    }
    setMandatoryField(tempMandatory)
  }

  const mandatoryInputChangedHandler = (e, index) => {
    const mainTemp = [...mandatory]
    mainTemp[index].required = !mainTemp[index].required
    setMandatoryField(mainTemp)
  }

  const cutomInputChangedHandler = (e, index, type) => {
    if (type === 'required') {
      const mainTemp = [...customFieldData]
      mainTemp[index].required = !mainTemp[index].required
      setCustomFieldData(mainTemp)
    }
    if (type === 'activity') {
      const mainTemp = [...customFieldData]

      mainTemp.forEach((element, key) => {
        if (key == index) {
          mainTemp[key].defaultForActivityName = true
          mainTemp[index].required = true
        } else {
          mainTemp[key].defaultForActivityName = false
        }
      })

      setCustomFieldData(mainTemp)
    }
  }

  const renderType = (data) => {
    const searchIndex = customFieldList.findIndex((x) => x.type == data)
    return customFieldList[searchIndex].name
  }

  const removeCustomField = (index) => {
    const temp_List = [...customFieldData]
    if (mode === 'Add') {
      temp_List.splice(index, 1)
    } else {
      temp_List[index].isDeleted = !temp_List[index].isDeleted
    }
    setCustomFieldData(temp_List)
  }

  const editCustomField = (index) => {
    const temp_List = [...customFieldData]
    setEditCustomData(temp_List[index])
    setIsCustomModel(true)
  }

  const submitCustomTemplate = async () => {
    if (customFieldData.length == 0) {
      setError('Add at least one custom field.')
      return
    }

    //check if there is a field defined as the activity name
    let IsThereActivityName = false
    customFieldData.map((field) => {
      if (field.defaultForActivityName === true) {
        IsThereActivityName = true
      }
    })

    if (IsThereActivityName === false) {
      setError(`You need to define a 'free text' or 'number' type field as the activity name.`)
      return
    }

    if (
      window.confirm(
        `Are you sure you want to ${mode === 'Add' ? 'create' : 'edit'} this template?`,
      )
    ) {
      setError('')
      const postData = {}
      postData.goalId = objectiveId
      postData.mandatoryFields = mandatory
      postData.customFields = customFieldData

      if (mode === 'Add') {
        postData.templateId = null
        setIsLoading(true)
        const result = await addGoalCustomTemplate(postData)
        setIsLoading(false)
        if (result) {
          navigate(`/kanban`)
        }
      } else if (mode === 'Edit') {
        postData.templateId = templateId
        setIsLoading(true)
        const result = await editGoalCustomTemplate(postData)
        setIsLoading(false)
        if (result) {
          navigate(`/kanban`)
        }
      }
    }
  }
  if (isLoading) {
    return <>{isLoading ? <InPageLoader /> : ''}</>
  }
  return (
    <>
      <ApplicationLayout />
      <div className="step-section capacity-step">
        <h2>
          {mode === 'Add' ? 'Create Custom Activity Template' : 'Edit Custom Activity Template'}
        </h2>
        <div className="capacity-table shadow objective-list-view">
          <div className="mandatory-field">
            <h6> Mandatory Fields</h6>

            <table className="activity-table-template create-custom-activity-table">
              <thead>
                <tr>
                  <th style={{ textAlign: 'left' }}>Field Label</th>
                  <th style={{ textAlign: 'left' }}>Type</th>
                  <th style={{ textAlign: 'left' }}>Required</th>
                </tr>
              </thead>
              <tbody>
                {mandatory.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td style={{ textAlign: 'left' }}>{data.name}</td>
                      <td style={{ textAlign: 'left' }}>{renderType(data.fieldType)}</td>
                      <td style={{ textAlign: 'left' }}>
                        <input
                          type="checkBox"
                          id="required"
                          name="required"
                          checked={data.required}
                          onChange={(e) => mandatoryInputChangedHandler(e, index)}
                          className="custom-input"
                          disabled={mode === 'Edit'}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>

          {customFieldData.length > 0 ? (
            <div className="mandatory-field">
              <h6> Custom Fields</h6>
              <table className="activity-table-template create-custom-activity-table">
                <thead>
                  <tr>
                    <th style={{ textAlign: 'left' }}>Field Label</th>
                    <th style={{ textAlign: 'left' }}>Type</th>
                    <th style={{ textAlign: 'left' }}>Required</th>
                    <th style={{ textAlign: 'left' }}>Display as Activity Name</th>
                    <th style={{ textAlign: 'left' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {customFieldData.map((data, index) => {
                    return (
                      <tr key={index} style={{ color: data.isDeleted ? '#D0D4DA' : 'black' }}>
                        <td style={{ textAlign: 'left' }}>{data.name}</td>
                        <td style={{ textAlign: 'left' }}>{renderType(data.fieldType)}</td>
                        <td style={{ textAlign: 'left' }}>
                          <input
                            type="checkBox"
                            id="required"
                            name="required"
                            checked={data.required}
                            onChange={(e) => cutomInputChangedHandler(e, index, 'required')}
                            className="custom-input"
                            disabled={data?.defaultForActivityName === true || mode === 'Edit'}
                          />
                        </td>

                        <td style={{ textAlign: 'left' }}>
                          {data.defaultForActivityName}
                          <input
                            type="radio"
                            name="Activity"
                            value={data.defaultForActivityName == true}
                            checked={data.defaultForActivityName}
                            onChange={(e) => cutomInputChangedHandler(e, index, 'activity')}
                            className="custom-input"
                            disabled={
                              !(data?.fieldType === 1 || data?.fieldType === 2) || mode === 'Edit'
                            }
                          />
                        </td>
                        <td>
                          <>
                            {((data?.defaultForActivityName !== true && mode === 'Edit') ||
                              mode === 'Add') && (
                              <div
                                className="check-wrapper"
                                onClick={() => removeCustomField(index)}
                              >
                                <span
                                  className="close-team-member-icon"
                                  style={{
                                    backgroundColor:
                                      data?.isDeleted === true ? '#D0D4DA' : 'rgb(237, 82, 46)',
                                  }}
                                >
                                  <img src={CloseIcon} alt="" />
                                </span>
                              </div>
                            )}
                            {data?.isDeleted === false && (
                              <div className="check-wrapper" onClick={() => editCustomField(index)}>
                                <span class="material-symbols-outlined icon-size">edit_note</span>
                              </div>
                            )}
                          </>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            ''
          )}

          {error ? <span className="error error-add-commnet">{error}</span> : null}

          <div className="add-more-field-action-wrapper">
            <button
              className="add-btn-comment"
              type="button"
              onClick={() => {
                setIsCustomModel(true)
                setError('')
              }}
            >
              Add More Fields
            </button>
          </div>

          <div className="modal-dual-btn">
            <button className="dual-btn positive-btn" type="button" onClick={submitCustomTemplate}>
              SAVE
            </button>
          </div>

          {isCustomModel ? (
            <AddCustomField
              formValues={formValues}
              view={isCustomModel}
              setModelView={setIsCustomModel}
              setCustomFieldData={setCustomFieldData}
              customFieldData={customFieldData}
              setEditCustomData={setEditCustomData}
              editCustomData={editCustomData}
              mode={mode}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  )
}
export default CreateTask

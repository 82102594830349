const capacityReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_OBJECTIVES':
      const sortedObjectives = action.objectives.sort((a, b) => {
        if (a.isShared) {
          return b.isGeneralObjective ? -1 : 1
        } else if (a.isGeneralObjective) {
          return 1
        } else if (b.isShared) {
          return -1
        } else if (b.isGeneralObjective) {
          return -1
        } else {
          return 0
        }
      })

      return {
        ...state,
        data: {
          ...state.data,
          objectives: sortedObjectives,
        },
      }
    case 'UPDATE_EMPLOYEES':
      return {
        ...state,
        data: {
          ...state.data,
          employees: action.employees,
        },
      }

    case 'ALLOC_SUB_WIZ__OPEN_TEAMMEMBER_FORM_MODAL':
      return {
        ...state,
        isTeamMemberFormOpen: true,
        allocSubWiz: {
          ...state.allocSubWiz,
          updateObjectiveId: action.id,
        },
      }
    case 'ALLOC_SUB_WIZ__OPEN_ADD_OBJECTIVE_MODAL':
      return {
        ...state,
        isAddObjectiveModalOpen: true,
      }
    case 'ALLOC_SUB_WIZ__CLOSE_ADD_OBJECTIVE_MODAL':
      return {
        ...state,
        isAddObjectiveModalOpen: false,
      }
    case 'ALLOC_SUB_WIZ__CLOSE_TEAMMEMBER_FORM_MODAL':
      return {
        ...state,
        isTeamMemberFormOpen: false,
        allocSubWiz: {
          ...state.allocSubWiz,
          updateObjectiveId: null,
        },
      }

    case 'ALLOC_SUB_WIZ__MOUNT_ALL_EMP':
      return {
        ...state,
        data: {
          ...state.data,
          employees: action.employees,
        },
      }

    case 'ALLOC_SUB_WIZ__ADD_MEMBERS_TO_OBJECTIVE': {
      return {
        ...state,
        data: {
          ...state.data,
          objectives: action.objectives,
        },
      }
    }
    case 'ALLOC_SUB_WIZ__SET_ALLOCATED_CAPACITY': {
      return {
        ...state,
        data: {
          ...state.data,
          objectives: action.objectives,
        },
      }
    }
    case 'ALLOC_SUB_WIZ__SET_ALLOCATED_CAPACITY_ERROR': {
      return {
        ...state,
        data: {
          ...state.data,
          objectives: action.objectives,
        },
      }
    }
    case 'ALLOC_SUB_WIZ__DISABLE_NEXT': {
      return {
        ...state,
        disableNext: true,
      }
    }
    case 'ALLOC_SUB_WIZ__ENABLE_NEXT': {
      return {
        ...state,
        disableNext: false,
      }
    }

    case 'ALLOC_SUB_WIZ__HAS_ERROR__TEAM_CAP_ALLOC_TABLE': {
      return {
        ...state,
        allocSubWiz: {
          ...state.allocSubWiz,
          errorTeamCapAllocTable: true,
        },
      }
    }

    case 'ALLOC_SUB_WIZ__NOT_HAS_ERROR__TEAM_CAP_ALLOC_TABLE': {
      return {
        ...state,
        allocSubWiz: {
          ...state.allocSubWiz,
          errorTeamCapAllocTable: false,
        },
      }
    }

    case 'ALLOC_SUB_WIZ__HAS_ERROR__ADDITIONAL_CAP_ALLOC_TABLE': {
      return {
        ...state,
        allocSubWiz: {
          ...state.allocSubWiz,
          errorAdditionalCapTable: true,
        },
      }
    }

    case 'ALLOC_SUB_WIZ__NOT_HAS_ERROR__ADDITIONAL_CAP_ALLOC_TABLE': {
      return {
        ...state,
        allocSubWiz: {
          ...state.allocSubWiz,
          errorAdditionalCapTable: false,
        },
      }
    }

    case 'ALLOC_SUB_WIZ_SET_SELECT_ALL_EMP': {
      return {
        ...state,
        allocSubWiz: {
          ...state.allocSubWiz,
          triggerSelectAllEmp: action.value,
        },
      }
    }
    case 'ALLOC_SUB_WIZ_OPEN_COOWNER_MODAL':
      return {
        ...state,
        isCoOwnerModalOpen: true,
        allocSubWiz: {
          ...state.allocSubWiz,
          updateObjectiveId: action.id,
        },
      }
    case 'ALLOC_SUB_WIZ_CLOSE_COOWNER_MODAL':
      return {
        ...state,
        isCoOwnerModalOpen: false,
        isNewPendingList: true,
        allocSubWiz: {
          ...state.allocSubWiz,
          updateObjectiveId: null,
        },
      }

    default:
      throw new Error(`Action: ${action.type} not implemented in Capacity reducer.`)
  }
}
export default capacityReducer

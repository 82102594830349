import React, { useState, useEffect } from 'react'
import Modal from '../../../../components/Modal/index'
import { manageTopicUW } from '../../../../api/services/meetingTopic.services'

const BlockerModal = ({ view, setModelView, objectiveId, suffelTaskId }) => {
  const [text, setText] = useState('')

  const [error, setError] = useState('')

  const onSubmit = async () => {
    if (!text) {
      setError('Description Required!')
    } else {
      setError('')
    }

    const blockerToAdd = {
      action: 'add',
      blocker: {
        description: text,
        objId: parseInt(objectiveId),
        topicType: 'blocker',
      },
    }

    const result = await manageTopicUW(1, blockerToAdd)

    if (result.success) {
      setModelView(false)
    }
  }

  return (
    <div className="kanban-modal">
      <Modal
        title={'Create Blocker'}
        isModalOpen={view}
        data-testid="Add Objective"
        closeModal={() => setModelView(false)}
        width="700px"
      >
        <>
          <div className="form-field form-field action-field--kanban">
            <label>Description</label>
            <textarea
              type="text"
              id="text"
              name="text"
              value={text}
              onChange={(e) => setText(e.target.value)}
              className="custom-input"
            >
              {' '}
            </textarea>
            {error ? <span className="error error-add-commnet">{error}</span> : null}
          </div>
          <div className="add-comment-action">
            <button className="add-btn-comment" type="button" onClick={() => onSubmit()}>
              Add
            </button>
          </div>
        </>
      </Modal>
    </div>
  )
}
export default BlockerModal

import styled from 'styled-components'

const StyledButton = styled.button`
  border: none;
  border-radius: ${(props) => props.borderRadius};
  cursor: pointer;
  padding: ${(props) => props.padding};
  white-space: nowrap;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  width: ${(props) => props.width};
  font-size: ${(props) => props.fontSize};

  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
`

export { StyledButton }

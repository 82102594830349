import _ from 'lodash'
import useLinkageData from '../../../api/query/useLinkageData'
import useTLWizardStateData from '../../../api/query/getTLWizardStateData'

export default function useAreaLeaderData(tlId) {
  const { data: linkageData, isLoading: isLinkageLoading, error: linkageError } = useLinkageData()

  const isLoading = isLinkageLoading
  const error = linkageError

  return {
    isLoading: isLoading,
    error: error,
    data: {
      objectives: [],
      linkageData: linkageData?.linkageData ?? [],
      stratDescData: linkageData?.companyStratDescriptions ?? [],
      existingStep: 0, //default step
    },
  }
}

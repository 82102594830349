import React from 'react'
import ObjectiveForm from './ObjectiveForm'
import Modal from '../Modal'
import { useTranslation } from 'react-i18next'
import { getGoalsByIds } from '../../../src/api/services/objectives.services'
import InPageLoader from '../InPageLoader'

const KanbanAddEditObjectiveModal = ({
  objId,
  closeModal,
  isAddEditObjectiveModalOpen,
  setEditedObj,
  mode,
  setObjData,
  loadAgain,
  tags,
}) => {
  const { t } = useTranslation(['Dashboard'])
  const [objectiveData, setObjectiveData] = React.useState({})
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    if (objId) {
      loadObj(objId)
    }
  }, [objId])

  async function loadObj(objId) {
    setLoading(true)
    let result = await getGoalsByIds([objId])
    console.log('result.objectives[0]', result.objectives[0])
    if (result && result.objectives[0]) {
      console.log(result)
      setObjectiveData(result?.objectives[0])
    }
    setLoading(false)
  }

  console.log('objectiveData', objectiveData)

  return (
    <>
      {isAddEditObjectiveModalOpen === true && (
        <Modal
          title={
            mode === 'add'
              ? t('addObjectivesModal.addObjective')
              : t('addObjectivesModal.editObjective')
          }
          closeModal={() => {
            closeModal()
          }}
          width={'40rem'}
        >
          {loading && <InPageLoader inComponent={true} />}
          {!loading && (
            <ObjectiveForm
              objId={mode === 'edit' ? objId : null}
              isAddEditObjectiveModalOpen={isAddEditObjectiveModalOpen}
              closeModal={closeModal}
              action={mode}
              setEditedObj={setEditedObj}
              objData={objectiveData}
              setObjData={setObjData}
              loadAgain={loadAgain}
              existingTags={tags}
            ></ObjectiveForm>
          )}
        </Modal>
      )}
    </>
  )
}
export default KanbanAddEditObjectiveModal

import React, { useState, useRef, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Toast from '../../../../common/toast'
import {
  meetingCommentAdd,
  meetingMilestonAdd,
  getSingleMeetingTopicDetails,
  useUpdateMeetingLogs,
  applyAiPresentationSuggestEdit,
} from '../../../../api/services/meetingTopic.services'
import Card from '../../../MeetingMultiView/components/MeetingTopics/common/Card'
import './popup.scss'
import { useTranslation } from 'react-i18next'
import { hasRole } from '../../../../utils/auth'
import {
  meetingMultiViewSubRoutes,
  routes,
  // teamMeetingViewSubRoutes,
} from '../../../../utils/routes'
import { getObjectivUserList } from '../../../../api/services/employee.services'
import AgendaSlide from '../MeetingTopics/common/component/AgendaSlide'
import RowSlide from '../MeetingTopics/common/component/RowSlide'
import TwoColumnSlide from '../MeetingTopics/common/component/TwoColumnSlide'
import TextArea from 'rc-textarea'
import AudioToTextModal from '../../../../components/AudioToTextModal/AudioToTextModal'
import { addDotAfterSentence } from '../../../../utils/general'

const PresentationDeck = () => {
  const [slides, setSlides] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation()
  const navigate = useNavigate()
  const { umtId, topicType, finalSlides } = location.state
  const { t } = useTranslation(['Common'])

  const [objData, setObjData] = useState(null)

  const [page, setPage] = useState(1)
  const [userList, setUserList] = React.useState([])
  const [flag, setFlag] = useState(false)
  const [resolved, setResolved] = useState(false)
  const readOnly = location.state?.isReadOnly

  const [suggestEdit, setSuggestEdit] = useState('')
  const [isAudioToTextModalOpen, setIsAudioToTextModalOpen] = useState(false)
  const [audioText, setAudioText] = useState('')

  const getAllEmployees = async () => {
    const response = await getObjectivUserList(0)
    if (response?.employees)
      setUserList(response.employees.map((emp) => ({ display: emp.name, id: emp.eId })))
  }

  useEffect(() => {
    setSuggestEdit((prev) => prev + addDotAfterSentence(prev) + audioText)
  }, [audioText])

  useEffect(() => {
    if (location.state) {
      getScreenData()
      getAllEmployees()
      if (finalSlides) {
        setSlides(JSON.parse(finalSlides))
      }
    }
  }, [location.state])

  async function getScreenData() {
    const postObj = { umtId: umtId, topicType: topicType }
    const result = await getSingleMeetingTopicDetails(postObj)
    setIsLoading(false)
    if (result) {
      if (result?.blockedObjectives.length > 0) {
        setObjData(result?.blockedObjectives[0])
        setFlag(result?.blockedObjectives[0]?.isPriority)
        setResolved(!result?.blockedObjectives[0]?.status)
      } else if (result?.decisionObjectives.length > 0) {
        setObjData(result?.decisionObjectives[0])
        setFlag(result?.decisionObjectives[0]?.isPriority)
        setResolved(!result?.decisionObjectives[0]?.status)
      } else if (result?.updateObjectives.length > 0) {
        setObjData(result?.updateObjectives[0])
        setFlag(result?.updateObjectives[0]?.isPriority)
        setResolved(!result?.updateObjectives[0]?.status)
      } else if (result?.adminTopicObjectives.length > 0) {
        setObjData(result?.adminTopicObjectives[0])
        setFlag(result?.adminTopicObjectives[0]?.isPriority)
        setResolved(!result?.adminTopicObjectives[0]?.status)
      } else {
        closeMeetingDeckView()
      }
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  async function createActivity(data, comment, index, mentions = []) {
    const formData = {
      milestoneDesc: comment,
      goalId: data?.objectiveId,
      mentions: mentions.map((mention) => mention.id),
    }

    formData.umtId = data?.umtId

    const result = await meetingMilestonAdd(formData)
    setIsLoading(false)
    if (result) {
      getScreenData()
      Toast.fire({
        icon: 'success',
        title: t('Common:modalMessages.actionUpdatedSuccesfully'),
      })
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  async function createComment(data) {
    // setIsLoading(true);
    const result = await meetingCommentAdd(data)
    setIsLoading(false)
    if (result) {
      getScreenData()
      Toast.fire({
        icon: 'success',
        title: t('Common:modalMessages.commentUpdatedSuccessfully'),
      })
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const mutationUpdateMeetingLogs = useUpdateMeetingLogs()

  async function createFlagStatus(data, type) {
    const formData = {}
    if (type == 'flag') {
      formData.isPriority = !data?.isPriority
    } else if (type == 'status') {
      formData.status = !data?.status
    }

    formData.umtId = data?.umtId

    await mutationUpdateMeetingLogs.mutateAsync(formData, {
      onSuccess: () => {
        getScreenData()
      },
      onError: () => {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      },
    })
  }
  const closeMeetingDeckView = () => {
    const isDelegateTl = parseInt(localStorage.getItem('dType')) === 5

    const redirectTo = location.state?.redirectTo
    let targetUrl
    if (redirectTo === 'kanban') {
      targetUrl = `/${routes.kanban}`
    } else if (redirectTo === 'decisionLog') {
      if (hasRole('team') && !isDelegateTl) {
        targetUrl = `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.decisionLog}`
      } else {
        targetUrl = `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.decisionLog}`
      }
    } else if (redirectTo === 'myDecisionHistory') {
      if (hasRole('team') && !isDelegateTl) {
        targetUrl = `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.myDecisions}`
      } else {
        targetUrl = `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.myDecisions}`
      }
    } else if (redirectTo === 'meetingTopics') {
      if ((hasRole('team') || hasRole('ic')) && !isDelegateTl) {
        targetUrl = `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.meetingTopics}`
      } else {
        targetUrl = `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.meetingTopics}`
      }
    } else if (redirectTo === 'home') {
      targetUrl = `/${routes.home}`
    }

    const filters = {
      readOnly: readOnly,
    }

    navigate(targetUrl, { state: { data: filters } })
  }

  const generateSlides = () => {
    return (
      <div>
        {slides?.Slides?.map((slide, index) => {
          if (index !== page - 1) {
            return null
          }
          if (slide.slide_data.type == 'agenda') {
            return (
              <div key={index}>
                <AgendaSlide agendaItems={slide.slide_data.agenda_items} />
              </div>
            )
          } else if (slide.slide_data.type == 'rows') {
            return (
              <div key={index}>
                <RowSlide slideData={slide.slide_data} />
              </div>
            )
          } else if (slide.slide_data.type == 'two_columns') {
            return (
              <div key={index}>
                <TwoColumnSlide slideData={slide.slide_data} />
              </div>
            )
          }
        })}
      </div>
    )
  }

  const handleApllySuggestEdit = async (umtId, suggestEdit) => {
    if (suggestEdit.trim() !== '') {
      setIsLoading(true)
      let result = await applyAiPresentationSuggestEdit(umtId, suggestEdit, true)
      setIsLoading(false)
      if (result) {
        setSlides(result)
        setPage(1)
      }
    }
  }

  const handleChangeSuggestEdit = (e) => {
    setSuggestEdit(e.target.value)
  }

  return (
    <div className="slider-wrapper">
      <div className="slide-container">
        <div>
          {true ? (
            <>
              {isLoading && <span style={{ color: 'white' }}>Loading...</span>}
              {!isLoading && (
                <>
                  <nav>
                    <ul className="pager">
                      <li className="next">
                        <button
                          disabled={page === 1}
                          onClick={() => setPage(page - 1)}
                          className="fitted-button blue"
                        >
                          <span style={{ width: '0.4rem' }}></span>
                          <span className="material-symbols-outlined icon-size">
                            <span class="material-symbols-outlined">arrow_back_ios</span>{' '}
                          </span>
                        </button>
                      </li>
                      <li className="next" style={{ display: 'flex', gap: '1rem' }}>
                        <button
                          disabled={page === slides?.Slides?.length}
                          onClick={() => setPage(page + 1)}
                          className="fitted-button blue"
                        >
                          <span style={{ width: '0.4rem' }}></span>
                          <span className="material-symbols-outlined icon-size">
                            <span class="material-symbols-outlined">arrow_forward_ios</span>{' '}
                          </span>
                        </button>

                        {/* <button
                          onClick={() => closeMeetingDeckView()}
                          className="fitted-button blue"
                        >
                          <span className="material-symbols-outlined icon-size">
                            <span class="material-symbols-outlined">close</span>{' '}
                          </span>
                        </button> */}
                      </li>
                    </ul>
                  </nav>
                  {/* {loadPdf && (
                    <canvas ref={canvasRef} />
                  )} */}
                  <div
                    style={{
                      width: '100%',
                      minHeight: '80rem',
                      backgroundColor: 'white',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div style={{ marginTop: '1rem', flexGrow: '1' }}>
                      <div style={{ minHeight: '30rem' }}>{generateSlides()}</div>
                      <div
                        className="review-generated-topics-list-item"
                        style={{
                          width: '50%',
                          marginTop: '0.5rem',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                        }}
                      >
                        <TextArea
                          type="text"
                          onChange={(e) => {
                            handleChangeSuggestEdit(e)
                          }}
                          value={suggestEdit}
                          style={{
                            height: '5rem',
                            backgroundColor: 'white',
                            width: '100%',
                            border: '1px solid grey',
                          }}
                          spellCheck={false}
                        />
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <button
                            type="button"
                            className="btn-card tonedown-blue-btn"
                            style={{ width: '2.5rem', marginTop: '0.5rem' }}
                            onClick={(e) => {
                              setIsAudioToTextModalOpen(true)
                            }}
                          >
                            <span className="material-symbols-outlined icon-size">mic</span>
                          </button>
                          <div
                            onClick={(e) => {
                              handleApllySuggestEdit(umtId, suggestEdit)
                            }}
                            style={{
                              cursor: 'pointer',
                              color: 'rgb(91,132,203)',
                              fontSize: '1.1rem',
                              marginTop: '0.5rem',
                              alignItems: 'center',
                              marginLeft: '1rem',
                            }}
                          >
                            <span>Apply</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            ''
          )}
        </div>
      </div>
      {!location.state?.fromKanban && (
        <div className="comments-wrapper">
          {objData && (
            <Card
              data={objData}
              createFlagStatus={createFlagStatus}
              flag={flag}
              createComment={createComment}
              createActivity={createActivity}
              showFull={false}
              loadAgain={getScreenData}
              refreshBGUScorecardFn={() => {}}
              resolved={resolved}
              viewOnlyMode={readOnly}
              flagStatusLoading={
                mutationUpdateMeetingLogs.isLoading || mutationUpdateMeetingLogs.isFetching
              }
              userList={userList}
              fromPresentationDeck={true}
            />
          )}
        </div>
      )}
      {isAudioToTextModalOpen && (
        <AudioToTextModal
          startRecording={true}
          isModalOpen={isAudioToTextModalOpen}
          type="meetingHubCommentsActions"
          closeModal={() => {
            setIsAudioToTextModalOpen(false)
          }}
          setAudioText={setAudioText}
        />
      )}
    </div>
  )
}
export default PresentationDeck

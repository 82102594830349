import React, { useState, useEffect } from 'react'
import { useCapacityContext } from '../../context/capacityContext'
import TeamCapacityAllocationTable from '../../../../components/AllocSubWizard/TeamCapacityAllocationTable'
import { useOutletContext } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const CapacityTeamCapacityAllocation = () => {
  const { t } = useTranslation(['Common'])
  return (
    <>
      <div className="wizard-step">
        <div
          className="wizard-step__title"
          style={{ fontWeight: 'bold', fontSize: '1.4rem', margin: '0.5rem' }}
        >
          {t('capacityAllocation.teamCapacityAllocationTitle')}
        </div>
        <div className="wizard-step__overview" style={{ maxWidth: '85%' }}>
          <div className="wizard-step__overview__paragraph">
            {t('capacityAllocation.teamCapacityAllocationSubtitle')}
          </div>
        </div>

        <div
          className="wizard-step__main-content-container"
          style={{ padding: '1.5rem', minWidth: '80%', maxWidth: '80%' }}
        >
          <TeamCapacityAllocationTable contextHandler={useCapacityContext} />
        </div>
      </div>
    </>
  )
}

export default CapacityTeamCapacityAllocation

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getAreaLeaderTeamLeaders = async (areaLeaderId) => {
  const url =
    areaLeaderId === null
      ? `${BASE_URL}/team_leaders_for_area_leader`
      : `${BASE_URL}/team_leaders_for_area_leader/${areaLeaderId}`

  const { data } = await axios.get(url)
  return data
}

export default function useAreaLeaderTeamLeaders(areaLeaderId = null) {
  return useQuery(
    ['areaLeaderObjectives', areaLeaderId],
    () => getAreaLeaderTeamLeaders(areaLeaderId),
    {
      refetchOnWindowFocus: false,
    },
  )
}

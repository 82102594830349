import * as Yup from 'yup'

const ObjectiveFormSchema = Yup.object().shape({
  ownerName: Yup.string().required('Required'),
  // ownerTitle: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  statement: Yup.string().required('Required'),
  // linkedTopLevelGoal: Yup.string().required('Required'),
  what: Yup.string().min(2, 'Too Short!').required('Required'),
  why: Yup.string().min(2, 'Too Short!').required('Required'),
  how: Yup.string().min(2, 'Too Short!').required('Required'),
})

export default ObjectiveFormSchema

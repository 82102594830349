import TextArea from 'rc-textarea'
import Modal from '../Modal'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../utils/routes'
import {
  sendTlNotificationEmail,
  setEmployeeWizardStatus,
} from '../../api/services/employee.services'

const SendMessageModal = ({ isModalOpen, closeModal, emailInfo }) => {
  console.log('emailInfo', emailInfo)
  const navigate = useNavigate()
  const { t } = useTranslation(['ObjectiveMap', 'commonButtons'])
  const userName = localStorage.getItem('tfmnm')
  const initialText = `Hi Team,\n\nI'm excited to be testing out a new solution called Decision+ that will help us create transparency, make faster decisions, and get things done!\nIn the link in this email, you will be taken into the tool and an AI consultant will get you set up in a few minutes. Give it a try!\n\nRegards,\n${userName}`
  const [textAreaValue, setTextAreaValue] = useState(initialText)

  const handleTextAreaChange = (e) => {
    setTextAreaValue(e.target.value)
  }

  function convertTextToParagraphs(text) {
    // Split the text into lines
    const lines = text.split('\n').filter((line) => line.trim() !== '')

    // Convert lines to HTML paragraphs
    const paragraphs = lines.map((line) => `<p>${line}</p>`)

    // Join the paragraphs
    return paragraphs.join('\n')
  }

  const sendEmail = async () => {
    let result = await setEmployeeWizardStatus(true)
    let res = await sendTlNotificationEmail(emailInfo, convertTextToParagraphs(textAreaValue))
    navigate(`/${routes.meetingMultiView}`, {
      state: { fromSetUpAgent: true },
    })
    navigate(0)
    console.log(convertTextToParagraphs(textAreaValue))
  }
  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      width="70rem"
      modalContainerStyle={{ width: '93%' }}
      showCard={true}
      title={t('ObjectiveMap:modalTitle.sendEmailTitle')}
      titleStyle={{ color: 'rgb(91,132,203)' }}
      modalTitleContainerStyle={{ marginBottom: '0rem', justifyContent: 'flex-start' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ textAlign: 'left', marginTop: '2rem' }}>
          <span style={{ fontSize: '1.7rem' }}>
            {t('ObjectiveMap:setUpMessages.emailInstructions')}
          </span>
        </div>
        <div
          className="review-generated-topics-list-item"
          style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '1rem' }}
        >
          <TextArea
            rows={10}
            type="text"
            value={textAreaValue}
            onChange={handleTextAreaChange}
            style={{
              height: '10rem',
              backgroundColor: '#f1f0f0',
              width: '90%',
              borderRadius: '10px',
              whiteSpace: 'pre-wrap',
            }}
            spellCheck={false}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
          <div
            onClick={sendEmail}
            className="sleek-button sleek-full-blue"
            style={{ fontSize: '1rem', height: '2rem', cursor: 'pointer' }}
          >
            {t('Common:commonButtons.send')}
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default SendMessageModal

import React from 'react'
import target from '../../../../../assets/target.svg'
import deleteCross from '../../../../../assets/delete-cross.svg'
import AddEditForm from './AddEditForm'
import useAddEditHigherObjective from '../../../../../api/mutation/addEditHigherObjective'
import ErrorModal from '../../../../../components/ErrorModal'
import Modal from '../../../../../components/Modal'
import { useTranslation } from 'react-i18next'

const ManageObjectiveModal = ({
  isOpen,
  isEdit,
  handleClose,
  objective,
  objectiveType,
  leaders,
  enterpriseObjectives = [],
  isAreaDashboard = false,
  isCeo = false,
  isAdmin = false,
}) => {
  const mutationAddEditObjective = useAddEditHigherObjective()
  const [objectiveWidgetErrorMessage, setObjectiveWidgetErrorMessage] = React.useState(null)
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const { t } = useTranslation(['Common'])

  const handleSubmit = async (values, resetform) => {
    let obj = {
      statement: `${values.statement}`,
      ownerId: values.leader,
      objectiveType: objectiveType,
      objId: objective?.objId ?? parseInt(objective?.id),
    }
    if (!isCeo && (objectiveType === 'area' || objectiveType === 'cxo')) {
      obj = {
        ...obj,
        parentId: values.parentId,
        rights: [...values.rights],
        tags: [...values.tags],
      }
    } else if (objectiveType === 'enterprise') {
      obj = {
        ...obj,
        what: values.what,
        why: values.why,
        how: values.how,
      }
    }

    if (isAreaDashboard) {
      obj = {
        ...obj,
        isAreaDashboard: true,
      }
    }

    await mutationAddEditObjective.mutateAsync(obj, {
      onSuccess: (response) => {
        if (!response.ok) {
          setObjectiveWidgetErrorMessage('There was an error, please try again.')
          openErrorModal()
          handleClose()
          resetform()
        } else {
          handleClose()
          resetform()
        }
      },
      onError: () => {
        openErrorModal()
        handleClose()
        resetform()
      },
    })
  }

  const openErrorModal = () => {
    setIsErrorModalOpen(true)
  }

  const closeErrorModal = () => {
    setIsErrorModalOpen(false)
    setObjectiveWidgetErrorMessage(null)
  }

  const [resetForm, setResetForm] = React.useState(null)

  return (
    <>
      <ErrorModal
        isOpen={isErrorModalOpen}
        handleClose={closeErrorModal}
        errorMessage={objectiveWidgetErrorMessage}
      />
      {isOpen === true && (
        <Modal
          title={isEdit ? t('areaObjective.editObj') : t('areaObjective.newObj')}
          closeModal={() => {
            handleClose()
            resetForm()
          }}
        >
          <AddEditForm
            handleSubmit={handleSubmit}
            objective={objective}
            handleClose={handleClose}
            leaders={leaders}
            objectiveType={objectiveType}
            enterpriseObjectives={enterpriseObjectives}
            isEdit={isEdit}
            setResetForm={setResetForm}
            isLoading={mutationAddEditObjective.isLoading || mutationAddEditObjective.isFetching}
            isAreaDashboard={isAreaDashboard}
            isCeo={isCeo}
            isAdmin={isAdmin}
          ></AddEditForm>
        </Modal>
      )}
    </>
  )
}

export default ManageObjectiveModal

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getObjectivesInformation = async (tlId, objId, isShowCompleted, showFullList, showAll) => {
  const { data } = await axios.post(
    `${BASE_URL}/get_goals_information_v2?is-completed=${isShowCompleted}`,
    { tlId: tlId, showFullList: showFullList, objId: objId, showAll: showAll },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  )
  return JSON.stringify(data) === '{}' ? [] : data
}

export default function useGetObjectivesInformation_v2(
  tlId = null,
  objId = null,
  isShowCompleted = false,
  showFullList = false,
  refetchData = false,
  showAll = false,
) {
  return useQuery(
    ['objective-info', isShowCompleted, showFullList, refetchData, showAll],
    () => getObjectivesInformation(tlId, objId, isShowCompleted, showFullList, showAll),
    {
      refetchOnWindowFocus: false,
    },
  )
}

import React, { useState, useEffect } from 'react'
import { Formik, Field, Form } from 'formik'
import AddEmployeeSchema from './formSchema'
import { useTranslation } from 'react-i18next'

const AddEmployeeForm = ({ handleSubmit, handleCancel, editEmployee, setResetForm }) => {
  const { t } = useTranslation(['TeamLeaderWizard'])
  const [hidden, setHidden] = useState(false)

  useEffect(() => {
    if (editEmployee === undefined || editEmployee === null) {
      setHidden(false)
    } else if (editEmployee !== null) {
      setHidden(true)
    }
  }, [editEmployee])

  const formikRef = React.useRef()

  React.useEffect(() => {
    if (formikRef.current !== undefined) {
      setResetForm(() => formikRef.current.resetForm)
    }
  }, [formikRef])

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={{
        // NOTE: set initial values back to empty strings post demo (AM-RAVL)
        name: editEmployee?.name ? editEmployee?.name : '',
        email: editEmployee?.email ?? '',
        teamAllocPct: editEmployee?.teamAllocPct ?? '',
      }}
      validationSchema={AddEmployeeSchema}
      onSubmit={(values, { resetForm }) => {
        let values2 = {
          ...values,
          email: values.email.toLowerCase(),
        }
        handleSubmit(values2)
        resetForm()
      }}
    >
      {({ errors, touched, resetForm }) => (
        <Form className="form">
          <div className="form-container">
            <div className="form-field">
              <label htmlFor="name">{t('addEmployeeModal.name')}</label>
              {hidden === true ? (
                <div>{editEmployee?.name}</div>
              ) : (
                <Field id="name" name="name" hidden={hidden} />
              )}
              {errors.name && touched.name && <div className="validation-error">{errors.name}</div>}
            </div>

            <div className="form-field">
              <label htmlFor="email">{t('addEmployeeModal.email')}</label>
              {hidden === true ? (
                <div>{editEmployee?.email}</div>
              ) : (
                <Field id="email" name="email" hidden={hidden} />
              )}
              {errors.email && touched.email && (
                <div className="validation-error">{errors.email}</div>
              )}
            </div>

            <div className="form-field">
              <label htmlFor="teamAllocPct">{t('addEmployeeModal.allocationPercent')}</label>
              <Field id="teamAllocPct" name="teamAllocPct" type="number" />
              {errors.teamAllocPct && touched.teamAllocPct && (
                <div className="validation-error">{errors.teamAllocPct}</div>
              )}
            </div>
          </div>

          <div className="modal-dual-btn">
            <button
              className="dual-btn negative-btn"
              onClick={() => {
                resetForm()
                handleCancel()
              }}
              type="button"
            >
              {t('Common:commonButtons.cancel')}
            </button>
            <button className="dual-btn positive-btn" type="submit">
              {t('Common:commonButtons.save')}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddEmployeeForm

import React, { useEffect, useState } from 'react'
import ApplicationLayout from '../../components/ApplicationLayout'
import { getPicaasoProcessForTeamLeader } from '../../api/services/objectives.services'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import SendMessageModal from '../../components/ConfirmEmailModal/SendMessageModal'
import { useNavigate } from 'react-router-dom'
import useSocket from '../../common/useSocket'
import './styles.css'
import useAudioAgentPlugin from '../../components/AudioAgentPlugin'
import ObjectiveMapSetupAgent from '../objectiveFlow/SetupAgentMode'
import { routes } from '../../utils/routes'
import { hasRole } from '../../utils/auth'
import { getEmailFromName } from '../../utils/general'
import MatchEmployeesModal from '../../components/MatchEmployeesModal'
import MatchEmployeesModalWithObjectives from '../../components/MatchEmployeesModalWithObjectives'

const L3SetupConsultant = () => {
  const [objectiveList, setObjectiveList] = useState([])
  const [objId, setObjId] = useState(null)
  const [singleObjId, setSingleObjId] = useState(null)
  const [showObjMap, setShowObjMap] = useState(true)

  const { t } = useTranslation(['Common', 'ObjectiveMap'])

  const [newEmployee, setNewEmployee] = useState([])
  const [isObjectiveMapFinished, setIsObjectiveMapFinished] = useState(false)
  const [isConfirmEmailModalOpen, setIsConfirmEmailModalOpen] = useState(false)
  const [isSendEmialModalOpen, setIsSendEmailModalOpen] = useState(false)
  const [newEmployeeWithId, setNewEmployeeWithId] = useState([])
  const [degradedCanvas, setDegradedCanvas] = useState(null)
  const [isSetUpCompleted, setIsSetUpCompleted] = React.useState(false)
  const [loadingObjectiveMap, setLoadingObjectiveMap] = useState(false)
  const isFirstValidL1ObjGeneration = React.useRef(null)
  // const enableRecommendations = getConsolidatedConfigSettings('enable_recommendations_in_setup_agent') === true
  const [existingHierachy, setExistingHierachy] = useState(null)
  const [existingTopics, setExistingTopics] = useState(null)
  const [token, setToken] = useState(null)

  const socket = useSocket('ai_consultant_l3', setToken)
  const navigate = useNavigate()
  const isDemo = localStorage.getItem('is_demo') === 'true'

  useEffect(() => {
    sendMessages()
  }, [])

  const sendMessages = async (audioText = null) => {
    const response = await getPicaasoProcessForTeamLeader(
      '',
      true,
      '',
      0,
      0,
      isObjectiveMapFinished,
      true,
    )
    if (response) {
      let degradedCanvas = response?.canvasResponse
        ? degradeCanvas(JSON.parse(JSON.stringify(response.canvasResponse)))
        : null
      setDegradedCanvas(degradedCanvas)
      // let parsedMessage = JSON.parse(response.message)
      // setMessageList(parsedMessage)
      setIsObjectiveMapFinished(response.finishObjectiveHierachy)
      setExistingHierachy(response.existingHierachy)
      setExistingTopics(response.existingTopics)
    }
  }

  const onSocketStarted = () => {
    if (existingTopics && existingHierachy) {
      audioAgentPlugin.updateSharedState({
        existing_hierachy: JSON.parse(existingHierachy),
        existing_topics: JSON.parse(existingTopics),
        current_objective_hierachy: existingHierachy,
      })
    }
  }

  const onFinalJson = (data) => {
    if (data) {
      setLoadingObjectiveMap(false)
      // console.log('data.json_str =', data.json_str)
      let response = data
      let employeeList = []
      if (response.canvasResponse) {
        setObjectiveList(response.canvasResponse)
        let degradedCanvas = degradeCanvas(JSON.parse(JSON.stringify(response.canvasResponse)))

        // Signals that we have some data now for L1.
        if (degradedCanvas) {
          if (isFirstValidL1ObjGeneration.current === null) {
            isFirstValidL1ObjGeneration.current = true
            audioAgentPlugin.updateSharedState({ isFirstValidL1ObjGeneration: true })
          } else {
            isFirstValidL1ObjGeneration.current = false
            audioAgentPlugin.updateSharedState({ isFirstValidL1ObjGeneration: false })
          }
        }

        setDegradedCanvas(degradedCanvas)

        if (!isObjectiveMapFinished && response.directReportList.length > 0) {
          console.log('response.directReportList', response.directReportList)
          setNewEmployee(response.directReportList)
          let level3_cards = response.canvasResponse.levelCards.filter(
            (card) => card.level === 3,
          )[0]
          if (level3_cards?.level) {
            for (let group of level3_cards.cardDisplayGroups) {
              for (let card of group.cards) {
                if (employeeList.some((e) => e.name === card.owner)) {
                  continue
                } else {
                  employeeList.push({ name: card.owner, id: card.ownerId })
                }
              }
            }
          }
          console.log('employeeList', employeeList)
          setNewEmployeeWithId(employeeList)
        }
      }
      setIsObjectiveMapFinished(response.finishObjectiveHierachy)
      if (response.finishObjectiveHierachy) {
        audioAgentPlugin.updateSharedState({ isObjectiveMapFinished: true })
      }
    }
  }

  const reloadObjectiveMap = (value) => {
    setLoadingObjectiveMap(value)
  }

  const closeModal = () => {
    setIsConfirmEmailModalOpen(false)
  }

  const saveObjectivesCallback = async () => {
    // let result = await setEmployeeWizardStatus(true)
    // let res = await finishCompanySetUp('area')
    // console.log(result)
    closeModal()
    navigate(`/${routes.meetingMultiView}`, {
      state: { fromSetUpAgent: true },
    })
    navigate(0)
    // setIsSendEmailModalOpen(true)
  }

  const audioAgentPlugin = useAudioAgentPlugin({
    socket: socket,
    speechEndCallback: () => {},
    onFinalJsonCallback: onFinalJson,
    socketStartedCallback: onSocketStarted,
    reload: reloadObjectiveMap,
    saveObjectivesCallback: saveObjectivesCallback,
    use_case: 'ai_consultant_l3',
    token: token,
    undoCallback: () => {
      setShowObjMap(false)
      const timeout = setTimeout(() => setShowObjMap(true), 1000)
    },
  })

  const degradeCanvas = (canvas) => {
    let level_cards = canvas.levelCards
    let newLevelCards = level_cards
      .filter((level_card) => level_card.level !== 1)
      .map((level_card) => {
        if (level_card.level === 2 || level_card.level === 3) {
          level_card.level -= 1
          return level_card
        }
      })
    canvas.levelCards = newLevelCards
    return canvas
  }

  const saveObjectives = async () => {
    audioAgentPlugin.setIsAudioPaused(true)
    const result = await Swal.fire({
      title: 'Do you want to finalize the setup?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    })

    if (result.isConfirmed && socket) {
      console.log('emit save_picasso_objectives')
      socket.emit('save_picasso_objectives', {
        newEmployeeWithIdInfo: [],
      })
    }
  }

  const exampleJson = {
    levelCards: [
      {
        level: 1,
        cardDisplayGroups: [
          {
            ownerId: 1001,
            cards: [
              {
                id: '2',
                goal: 'This is where your strategic priority will go',
                owner: '',
                ownerId: 1001,
                parentId: 1,
                cardPermissions: {
                  canZoom: true,
                  canEdit: false,
                  canSeeActivities: false,
                  canSeeHistory: false,
                  canAdd: false,
                  canSeeObjStatistics: false,
                  canKpi: false,
                  canSeeFiles: false,
                  canDeleteComplete: false,
                  canAddTopic: false,
                  canSeeRecommendations: false,
                },
                allocation: 0,
                teamCapacity: 0,
                progressPercent: 0,
                startDate: '',
                endDate: '',
                customizationStatus: 'UNKNOWN_CUSTOMIZATION',
                blocked: false,
                lackingCapacity: false,
                deprioritized: false,
                icsDetails: [],
                hasOverdueActivities: false,
                hasAnyProgressLastTwoWeeks: false,
                isDeactivated: false,
                rag: 'GREEN',
                isShared: false,
                numOfChildrenActive: 0,
                numOfChildrenNotDeleted: 0,
                topicList: [],
                level1GoalId: 0,
                status: '',
                objectiveId: 0,
                ownerEId: 0,
                recommendations: [],
                isRecommended: false,
                ignoreOwnerMatch: true,
              },
            ],
            cardEdges: [],
          },
        ],
        thisLevelSegementDisplayNames: ['Sasha Noorani'],
        thisLevelSegementIds: [1001],
        nextLevelSegementDisplayNames: [],
        nextLevelSegementIds: [],
        thisLevelSegementDisplayName: '',
        dropDownMappings: [],
      },
      {
        level: 2,
        cardDisplayGroups: [
          {
            ownerId: 1002,
            cards: [
              {
                id: '3',
                goal: "This is where your direct report's priorities will go",
                owner: '',
                ownerId: 1002,
                parentId: 2,
                cardPermissions: {
                  canZoom: true,
                  canEdit: false,
                  canSeeActivities: false,
                  canSeeHistory: false,
                  canAdd: false,
                  canSeeObjStatistics: false,
                  canKpi: false,
                  canSeeFiles: false,
                  canDeleteComplete: false,
                  canAddTopic: false,
                  canSeeRecommendations: false,
                },
                topicList: [
                  {
                    description:
                      "This is where we'll note down any challenges or discussion topics for the priorities",
                    status: 'new',
                    meetingTopicId: 0,
                    objId: 0,
                    topicType: 0,
                    isOpen: false,
                    isPriority: false,
                    openedTime: 0,
                    closedTime: 0,
                    actionItem: '',
                    topicId: 0,
                    blockerId: 0,
                    mtId: 0,
                    hasMeetingDeck: false,
                    comments: [],
                    milestones: [],
                    manualClass: 'UNKNOWN_CATEGORY',
                    mlClass: 'UNKNOWN_CATEGORY',
                    topicOwnerId: 0,
                    topicOwnerName: '',
                    umtId: 0,
                    createdById: 0,
                    kpiName: '',
                    kpiAssociatedName: '',
                    kpiAssociatedId: '',
                    hideDropdown: true,
                  },
                ],
                allocation: 0,
                teamCapacity: 0,
                progressPercent: 0,
                startDate: '',
                endDate: '',
                customizationStatus: 'UNKNOWN_CUSTOMIZATION',
                blocked: false,
                lackingCapacity: false,
                deprioritized: false,
                icsDetails: [],
                hasOverdueActivities: false,
                hasAnyProgressLastTwoWeeks: false,
                isDeactivated: false,
                rag: 'GREEN',
                isShared: false,
                numOfChildrenActive: 0,
                numOfChildrenNotDeleted: 0,
                level1GoalId: 0,
                status: '',
                objectiveId: 0,
                ownerEId: 0,
                recommendations: [],
                isRecommended: false,
                ignoreOwnerMatch: true,
              },
            ],
            cardEdges: [],
          },
        ],
        thisLevelSegementDisplayNames: ['Anna'],
        thisLevelSegementIds: [1002],
        nextLevelSegementDisplayNames: [],
        nextLevelSegementIds: [],
        thisLevelSegementDisplayName: '',
        dropDownMappings: [],
      },
    ],
    isReviewMode: false,
    sharedCardsGroup: [],
  }

  return (
    <ApplicationLayout fromSetUpAgent={true}>
      <link
        rel="preload"
        href="https://cdn.jsdelivr.net/npm/onnxruntime-web@1.19.0/dist/ort-wasm-simd-threaded.wasm"
        as="fetch"
        type="application/wasm"
        crossorigin="anonymous"
      />
      {isConfirmEmailModalOpen && isDemo && (
        <MatchEmployeesModal
          isModalOpen={isConfirmEmailModalOpen}
          closeModal={() => {
            setIsConfirmEmailModalOpen(false)
          }}
          newEmployee={newEmployee}
          objectiveList={objectiveList}
          setIsSendEmailModalOpen={setIsSendEmailModalOpen}
          newEmployeeWithId={newEmployeeWithId}
          setIsSetUpCompleted={setIsSetUpCompleted}
          updateSharedCache={audioAgentPlugin.updateSharedState}
          fromSocket={true}
          socket={socket}
        />
      )}
      {isConfirmEmailModalOpen && !isDemo && (
        <MatchEmployeesModalWithObjectives
          isModalOpen={isConfirmEmailModalOpen}
          closeModal={() => {
            setIsConfirmEmailModalOpen(false)
          }}
          newEmployee={newEmployee}
          objectiveList={objectiveList}
          setIsSendEmailModalOpen={setIsSendEmailModalOpen}
          newEmployeeWithId={newEmployeeWithId}
          setIsSetUpCompleted={setIsSetUpCompleted}
          updateSharedCache={audioAgentPlugin.updateSharedState}
          fromSocket={true}
          socket={socket}
        />
      )}
      {isSendEmialModalOpen && (
        <SendMessageModal
          isModalOpen={isSendEmialModalOpen}
          closeModal={() => {
            setIsSendEmailModalOpen(false)
          }}
        />
      )}
      <div style={{ display: 'block', marginTop: '3rem', display: 'flex', flexDirection: 'row' }}>
        {audioAgentPlugin.audioPlayer}

        <div
          style={{
            width: '70%',
            overflow: 'hidden',
            marginTop: '3rem',
            border: '1px solid lightgray',
            margin: '0 0 0 0.2rem',
            marginRight: '1rem',
          }}
        >
          {loadingObjectiveMap && <div className="obj_map_loader" />}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span
              style={{
                fontSize: '1.5rem',
                color: 'rgb(68, 114, 196)',
                padding: '1rem 0 0 1rem',
                fontWeight: 'bold',
              }}
            >
              Strategic Objectives Map
            </span>
            <span style={{ fontSize: '1rem', color: '#AAC2F1', padding: '0 0 0 1rem' }}>
              I will help you build it, tell me about your priorities.
            </span>
          </div>
          {(degradedCanvas?.levelCards?.length === 0 ||
            degradedCanvas?.levelCards === null ||
            degradedCanvas?.levelCards === undefined) && (
            <ObjectiveMapSetupAgent objectiveList={exampleJson} expandedMode={true} />
          )}
          {showObjMap && (
            <ObjectiveMapSetupAgent objectiveList={degradedCanvas} expandedMode={true} />
          )}
        </div>
        <div
          style={{
            //position: 'fixed',
            //bottom: '0',
            //right: '0',
            //border: '1px solid lightgray',
            borderLeft: 'none',
            width: '30%',
            //height: '94%',
            marginRight: '1rem',
            // borderLeft: '1px solid grey',
            // borderRight: '1px solid grey',
            // borderRadius: '10px 10px 0 0',
          }}
        >
          {/* {enableRecommendations && audioAgentPlugin.checkboxRec} */}
          {audioAgentPlugin.chatBox}

          {isObjectiveMapFinished && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginLeft: '1.5rem',
              }}
            >
              <div
                onClick={saveObjectives}
                class="sleek-button sleek-full-blue"
                style={{
                  display: 'flex',
                  width: '3rem',
                  fontSize: '1rem',
                  height: '2rem',
                  marginTop: '1rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                {t('commonButtons.finalize')}
              </div>
            </div>
          )}
        </div>
      </div>
    </ApplicationLayout>
  )
}
export default L3SetupConsultant

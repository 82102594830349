import React from 'react'
import { CardContainer, CardHeader } from './styles'
import styled from 'styled-components'
import edit from '../../../../assets/edit.svg'
import deleteCross from '../../../../assets/delete-cross.svg'
import plus from '../../../../assets/plus.svg'
import ManageObjectiveModal from './components/ManageObjectiveModal'
import EditMenuModal from './components/EditMenuModal'
import useDeleteHigherObjective from '../../../../api/mutation/deleteHigherObjective'
import ErrorModal from '../../../../components/ErrorModal'

const Grid = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 0.8fr 0.3fr;
  row-gap: 0.5em;
  column-gap: 0.5rem;
  padding-top: 1rem;
  width: 100%;
`

const GridHeader = styled.div`
  font-weight: bold;
  justify-content: center;
  padding: 0.2rem 0.5rem;
`

const GridContent = styled.div`
  justify-content: center;
  padding: 0.2rem 0.5rem;
  word-break: break-word;
`

const EnterpriseObjectiveCard = ({ objectives = [], enterpriseLeaders, ceoObjectives = [] }) => {
  const [objective, setObjective] = React.useState(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const [isEditMenuOpen, setIsEditMenuOpen] = React.useState(false)
  const [indexToEdit, setIndexToEdit] = React.useState(null)
  const [isEdit, setIsEdit] = React.useState(false)
  const mutationDeleteHigherObjective = useDeleteHigherObjective()
  const [objectiveWidgetErrorMessage, setObjectiveWidgetErrorMessage] = React.useState(null)
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)

  const handleOpenObjectiveModal = (objective, index = null) => {
    if (index !== null) {
      setIndexToEdit(index)
      setIsEdit(true)
    } else {
      setIsEdit(false)
    }
    setObjective(objective)
    setIsOpen(true)
  }

  const handleOpenEditMenuModal = (objective, index = null) => {
    if (index !== null) {
      setIndexToEdit(index)
      setIsEdit(true)
    } else {
      setIsEdit(false)
    }
    setObjective(objective)
    setIsEditMenuOpen(true)
  }

  const handleCloseModal = () => {
    setIndexToEdit(null)
    setObjective(null)
    setIsOpen(false)
  }

  const handleCloseEditMenuModal = () => {
    setIsEditMenuOpen(false)
  }

  const openErrorModal = () => {
    setIsErrorModalOpen(true)
  }

  const closeErrorModal = () => {
    setIsErrorModalOpen(false)
    setObjectiveWidgetErrorMessage(null)
  }

  return (
    <>
      <ErrorModal
        isOpen={isErrorModalOpen}
        handleClose={closeErrorModal}
        errorMessage={objectiveWidgetErrorMessage}
      />

      <ManageObjectiveModal
        enterpriseObjectives={ceoObjectives}
        leaders={enterpriseLeaders}
        objectiveType={'enterprise'}
        isCeo={false}
        objective={objective}
        isOpen={isOpen}
        isEdit={isEdit}
        index={indexToEdit}
        handleClose={handleCloseModal}
        setObjective={setObjective}
        setIsEdit={setIsEdit}
        setIndexToEdit={setIndexToEdit}
        isAdmin={true}
      ></ManageObjectiveModal>

      <EditMenuModal
        objective={objective}
        isOpen={isEditMenuOpen}
        isEdit={isEdit}
        index={indexToEdit}
        handleClose={handleCloseEditMenuModal}
        setObjective={setObjective}
        setIsEdit={setIsEdit}
        setIndexToEdit={setIndexToEdit}
        handleOpenObjectiveModal={handleOpenObjectiveModal}
      ></EditMenuModal>

      <CardContainer basis="100%">
        <CardHeader>Enterprise Objectives</CardHeader>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1rem',
            width: '100%',
            position: 'relative',
          }}
        >
          <div className="underline-admin"></div>
        </div>
        <Grid>
          <GridHeader>Objective</GridHeader>
          <GridHeader>Owner</GridHeader>
          <GridHeader></GridHeader>
          {objectives.map((obj, index) => (
            <React.Fragment key={`objectives-${index}`}>
              <GridContent>{obj.statement}</GridContent>
              <GridContent>
                {enterpriseLeaders.map((leader, leaderIndex) => {
                  if (leader.eId === obj.ownerId) {
                    return leader.name
                  }
                })}
              </GridContent>
              <GridContent
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}
              >
                <div
                  class="fitted-button blue"
                  onClick={(e) => {
                    if (obj.numChildren > 0) {
                      handleOpenEditMenuModal(obj, index)
                    } else {
                      handleOpenObjectiveModal(obj, index)
                    }
                  }}
                >
                  <span class="material-symbols-outlined icon-size">edit</span>
                </div>
              </GridContent>
            </React.Fragment>
          ))}
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
          <button
            type="button"
            className="fitted-button blue"
            style={{ background: 'white', padding: '0.4rem', height: '35px', fontWeight: 'bold' }}
            onClick={() => handleOpenObjectiveModal(null, null)}
          >
            ADD OBJECTIVE
          </button>
          {/* <div
            className="add-btn"
            onClick={() => {
              handleOpenObjectiveModal(null, null)
            }}
          >
            <img className="plus" src={plus} alt="TransforML Logo" />
            <span>ADD OBJECTIVE</span>
          </div> */}
        </div>
      </CardContainer>
    </>
  )
}

export default EnterpriseObjectiveCard

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getEmployeeData_2_3 = async () => {
  const queryString = '/get_employees_level_2_3'

  const { data } = await axios.get(`${BASE_URL}${queryString}`)
  return data
}

export default function useEmployeeData_2_3() {
  return useQuery(['employees_level_2_3'], () => getEmployeeData_2_3(), {
    refetchOnWindowFocus: false,
  })
}

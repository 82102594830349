import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const uploadMeetingDeck = async (formData) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/upload_meeting_deck`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  } catch (error) {
    console.error('Error uploading PPT file:', error)
    return null
  }
}

export default function useUploadMeetingDeck() {
  const queryClient = useQueryClient()

  return useMutation((formData) => uploadMeetingDeck(formData), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      queryClient.invalidateQueries('uw_meeting_topics')
    },
  })
}

import { useEffect, useState } from 'react'
import Modal from '../../../../../../components/Modal'
import { postHttpRequest } from '../../../../../../api/query/dynamicAPI'
import Toast from '../../../../../../common/toast'
import InPageLoader from '../../../../../../components/InPageLoader'
import AgendaSlide from './AgendaSlide'
import RowSlide from './RowSlide'
import TwoColumnSlide from './TwoColumnSlide'
import { ModalSaveButtonFitted } from '../../../../../../components/ModalSubmitButton'
import { useTranslation } from 'react-i18next'
import {
  applyAiPresentationSuggestEdit,
  deleteAiPresentationData,
} from '../../../../../../api/services/meetingTopic.services'
import TextArea from 'rc-textarea'
import AudioToTextModal from '../../../../../../components/AudioToTextModal/AudioToTextModal'
import { addDotAfterSentence } from '../../../../../../utils/general'

const AiPresentationSlideModal = ({
  isModalOpen,
  closeModal,
  meetingTopic,
  slidesMaterial,
  umtId,
  existingSlides,
  loadAgain,
  setIsAiPresentationDraftModalOpen,
  setExistingSuggestions,
  setExistingSlides,
  existingSuggestions,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [gptGeneratedSlides, setGptGeneratedSlides] = useState([])
  const [showIndex, setShowIndex] = useState(0)
  const { t } = useTranslation(['Common'])
  const [suggestEdit, setSuggestEdit] = useState('')
  const [isAudioToTextModalOpen, setIsAudioToTextModalOpen] = useState(false)
  const [audioText, setAudioText] = useState('')

  useEffect(() => {
    getSlides()
  }, [slidesMaterial])

  useEffect(() => {
    setSuggestEdit((prev) => prev + addDotAfterSentence(prev) + audioText)
  }, [audioText])

  const getSlides = async () => {
    setIsLoading(true)
    try {
      setExistingSuggestions(existingSuggestions)
      setExistingSlides(null)
      setIsAiPresentationDraftModalOpen(false)
      if (existingSlides && existingSlides?.Slides.length > 0) {
        setGptGeneratedSlides(existingSlides?.Slides ?? [])
        setIsLoading(false)
        return
      }
      let response = await postHttpRequest('/generate_ai_slides', slidesMaterial)
      setGptGeneratedSlides(response?.Slides)
      loadAgain()
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const generateSlides = () => {
    return (
      <div>
        {gptGeneratedSlides?.map((slide, index) => {
          if (index !== showIndex) {
            return null
          }
          if (slide.slide_data.type == 'agenda') {
            return (
              <div key={index}>
                <AgendaSlide agendaItems={slide.slide_data.agenda_items} />
              </div>
            )
          } else if (slide.slide_data.type == 'rows') {
            return (
              <div key={index}>
                <RowSlide slideData={slide.slide_data} />
              </div>
            )
          } else if (slide.slide_data.type == 'two_columns') {
            return (
              <div key={index}>
                <TwoColumnSlide slideData={slide.slide_data} />
              </div>
            )
          }
        })}
      </div>
    )
  }

  const handlePrevSlide = () => {
    setShowIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex))
  }

  const handleNextSlide = () => {
    setShowIndex((prevIndex) =>
      prevIndex < gptGeneratedSlides.length - 1 ? prevIndex + 1 : prevIndex,
    )
  }

  const handleDelete = async () => {
    await deleteAiPresentationData(umtId)
    setExistingSuggestions(null)
    setExistingSlides(null)
    loadAgain()
    closeModal()
  }

  const handleBack = () => {
    setIsAiPresentationDraftModalOpen(true)
    closeModal()
  }

  const handleChangeSuggestEdit = (e) => {
    setSuggestEdit(e.target.value)
  }

  const handleApllySuggestEdit = async (umtId, suggestEdit) => {
    if (suggestEdit.trim() !== '') {
      setIsLoading(true)
      let result = await applyAiPresentationSuggestEdit(umtId, suggestEdit, true)
      setIsLoading(false)
      if (result) {
        setGptGeneratedSlides(result?.Slides)
        loadAgain()
        setShowIndex(0)
      }
    }
  }

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      width="95%"
      height={isLoading ? '18rem' : '55rem'}
      modalContainerStyle={{ width: '93%' }}
      showCard={true}
      title={meetingTopic?.actionNeeded}
      titleStyle={{ color: 'rgb(91,132,203)', textAlign: 'left' }}
    >
      <div style={{ textAlign: 'center', padding: '20px', position: 'relative' }}>
        {isAudioToTextModalOpen && (
          <AudioToTextModal
            startRecording={true}
            isModalOpen={isAudioToTextModalOpen}
            type="meetingHubCommentsActions"
            closeModal={() => {
              setIsAudioToTextModalOpen(false)
            }}
            setAudioText={setAudioText}
          />
        )}
        {isLoading ? (
          <>
            <div style={{ fontSize: '1.5rem', marginBottom: '2rem' }}>
              <b>{t('aiPresentationModal.generatingSlides')}</b>
            </div>
            <InPageLoader inComponent={true} />
          </>
        ) : (
          <>
            <div
              style={{
                border: '1px solid lightgray',
                width: '70%',
                margin: '0 auto',
                height: '80%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                minHeight: '30rem',
                minWidth: '40rem',
              }}
            >
              {generateSlides(gptGeneratedSlides)}
            </div>
            <div
              style={{
                position: 'absolute',
                top: '33%',
                left: '50%',
                width: '85%',
                display: 'flex',
                justifyContent: 'space-between',
                transform: 'translate(-50%, -50%)',
                gap: '30%',
              }}
            >
              <div
                style={{
                  fontSize: '2rem',
                  cursor: 'pointer',
                  userSelect: 'none',
                }}
                onClick={handlePrevSlide}
              >
                <span className="material-symbols-outlined icon-size">
                  <span class="material-symbols-outlined">arrow_back_ios</span>{' '}
                </span>
              </div>
              <div
                style={{
                  fontSize: '2rem',
                  cursor: 'pointer',
                  userSelect: 'none',
                }}
                onClick={handleNextSlide}
              >
                <span className="material-symbols-outlined icon-size">
                  <span class="material-symbols-outlined">arrow_forward_ios</span>{' '}
                </span>
              </div>
            </div>
            <div style={{ marginTop: '0.5rem', color: 'grey', fontSize: '1.3rem' }}>
              {showIndex + 1}/{gptGeneratedSlides.length}
            </div>
            <div>
              <div
                className="modal-dual-btn"
                style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}
              >
                <ModalSaveButtonFitted
                  tag={t('Common:commonButtons.back')}
                  onClick={() => {
                    handleBack()
                  }}
                />

                <ModalSaveButtonFitted
                  tag={t('Common:commonButtons.delete')}
                  onClick={() => {
                    handleDelete()
                  }}
                />
              </div>
              <div
                style={{
                  width: '50%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <div>{t('Common:aiPresentationModal.suggestEdit')}</div>
              </div>
              <div
                className="review-generated-topics-list-item"
                style={{
                  width: '50%',
                  marginTop: '0.5rem',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                <TextArea
                  type="text"
                  onChange={(e) => {
                    handleChangeSuggestEdit(e)
                  }}
                  value={suggestEdit}
                  style={{
                    height: '5rem',
                    backgroundColor: 'white',
                    width: '100%',
                    border: '1px solid grey',
                  }}
                  spellCheck={false}
                />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <button
                    type="button"
                    className="btn-card tonedown-blue-btn"
                    style={{ width: '2.5rem', marginTop: '0.5rem' }}
                    onClick={(e) => {
                      setIsAudioToTextModalOpen(true)
                    }}
                  >
                    <span className="material-symbols-outlined icon-size">mic</span>
                  </button>
                  <div
                    onClick={(e) => {
                      handleApllySuggestEdit(umtId, suggestEdit)
                    }}
                    style={{
                      cursor: 'pointer',
                      color: 'rgb(91,132,203)',
                      fontSize: '1.1rem',
                      marginTop: '0.5rem',
                      alignItems: 'center',
                      marginLeft: '1rem',
                    }}
                  >
                    <span>Apply</span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default AiPresentationSlideModal

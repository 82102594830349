import React, { useEffect, useState } from 'react'
// import { useDemandIntakeContext } from '../../context/demandIntakeContext'
import Modal from '../../Modal'
import './style.scss'
import { useTranslation } from 'react-i18next'

const AddTeamMemberModal = ({ handleSubmit, contextHandler }) => {
  const { t } = useTranslation(['Common'])
  const [selected, setSelected] = useState([])
  const { state, dispatch } = contextHandler()
  useEffect(() => {
    if (!state.isTeamMemberFormOpen) {
      return
    }

    const { data, allocSubWiz } = state
    const updateObjectiveId = allocSubWiz.updateObjectiveId
    const { objectives } = data
    const currObjective = objectives.find((o) => o.id === updateObjectiveId)
    const selectedTeam =
      currObjective &&
      currObjective.team &&
      currObjective.team.map((u) => ({ eId: u.eId, canDelete: u.canDelete, name: u.name }))
    setSelected(selectedTeam || [])
  }, [state.isTeamMemberFormOpen])

  const addTeamMembers = () => {
    const { data, allocSubWiz } = state

    let updateObjectiveId = allocSubWiz.updateObjectiveId

    const { objectives, employees } = data
    if (updateObjectiveId !== null) {
      const clone = [...objectives]
      let team = selected.map((s) => s)

      const index = objectives.findIndex((o) => o.id === updateObjectiveId)

      if (index > -1) {
        let obj = clone[index]
        let newTeam = []
        // Check which of the people exist right now.
        if (obj.team !== undefined) {
          for (let t of team) {
            let origVal = obj.team.find((e) => e.eId === t.eId)

            if (origVal !== undefined) {
              newTeam.push(origVal)
            } else {
              newTeam.push({ eId: t.eId, name: t.name, canDelete: true })
            }
          }
          obj.team = newTeam
        } else {
          obj.team = team
        }

        dispatch({ type: 'ALLOC_SUB_WIZ__ADD_MEMBERS_TO_OBJECTIVE', objectives: clone })
        dispatch({ type: 'ALLOC_SUB_WIZ__CLOSE_TEAMMEMBER_FORM_MODAL' })
      }
    }
  }

  const toggleUser = (user) => {
    const found = selected.findIndex((u) => u.eId == user.eId)
    const clone = [...selected]
    if (found > -1) {
      clone.splice(found, 1)
    } else {
      clone.push({ eId: user.eId, name: user.name, canDelete: true })
    }
    setSelected(clone)
  }

  const getChecked = (id) => {
    // Explicitly compare against `undefined` in case ID is 0.
    return selected.find((s) => id == s.eId) !== undefined
  }

  const selectAll = () => {
    let tempSelected = []
    let employees = state?.data?.employees?.filter((emp) => emp.isDeactivated === false) ?? []
    for (let emp of employees) {
      tempSelected.push({ eId: emp.eId, name: emp.name, canDelete: true })
    }
    setSelected(tempSelected)
  }

  const clearAll = () => {
    setSelected([])
  }

  return (
    <>
      <Modal
        title={t('addTeamMemberAllocationModal.title')}
        closeModal={() => {
          dispatch({ type: 'ALLOC_SUB_WIZ__CLOSE_TEAMMEMBER_FORM_MODAL' })
          setSelected([])
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <span
            style={{ color: 'rgb(82 119 255)', cursor: 'pointer' }}
            onClick={() => {
              selectAll()
            }}
          >
            {t('filter.selectAll')}
          </span>{' '}
          &nbsp;| &nbsp;
          <span
            style={{ color: 'rgb(82 119 255)', cursor: 'pointer' }}
            onClick={() => {
              clearAll()
            }}
          >
            {t('filter.clear')}
          </span>
          &nbsp;
        </div>
        {state?.data?.employees
          ?.filter((emp) => emp.isDeactivated === false)
          ?.map((emp, index) => (
            <ul key={index} className="list-members">
              <li className="single-member">
                <span className="member-text">{emp.name}</span>
                <div className="checkbox-wrapper">
                  <input
                    data-testid={emp.name}
                    id={index}
                    type="checkbox"
                    checked={getChecked(emp.eId)}
                    onClick={() => toggleUser(emp)}
                  />
                </div>
              </li>
            </ul>
          ))}
        <div className="modal-dual-btn">
          <button
            className="dual-btn negative-btn"
            onClick={() => {
              dispatch({ type: 'ALLOC_SUB_WIZ__CLOSE_TEAMMEMBER_FORM_MODAL' })
              setSelected([])
            }}
          >
            {t('commonButtons.cancel')}
          </button>
          <button className="dual-btn positive-btn" onClick={addTeamMembers} data-testid="save">
            {t('commonButtons.save')}
          </button>
        </div>
      </Modal>
    </>
  )
}
export default AddTeamMemberModal

import _ from 'lodash'
import useLinkageData from '../../../api/query/useLinkageData'
import useTeamCapacityData from '../../../api/query/getTeamCapacity'
export default function useDemandIntakeData(tlId = null) {
  const { data: linkageData, isLoading: isLinkageLoading, error: linkageError } = useLinkageData()
  const {
    data: employeeData,
    isLoading: isEmployeeLoading,
    error: employeeError,
  } = useTeamCapacityData()

  const isLoading = isLinkageLoading || isEmployeeLoading
  const error = linkageError || employeeError

  return {
    isLoading: isLoading,
    error: error,
    data: {
      objectives: [],
      objectivesCopy: [],
      linkageData: linkageData?.linkageData ?? [],
      employees: employeeData?.employees ?? [],
      nonReportingEmpsRealIndexes: [],
      externalEmpAlocs: {},
    },
  }
}

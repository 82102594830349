import React, { useState, useEffect } from 'react'
import { useCapacityContext } from '../../context/capacityContext'
import F2wAvailability from '../../../../components/F2wAvalability'

const CapacityF2wAvailability = () => {
  const { state, dispatch } = useCapacityContext()
  return (
    <>
      <F2wAvailability employees={state?.data?.employees} />
    </>
  )
}

export default CapacityF2wAvailability

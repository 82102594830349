import React, { useState, useEffect } from 'react'
import 'react-datasheet-grid/dist/style.css'
import ApplicationLayout from '../../components/ApplicationLayout'
import InPageLoader from '../../components/InPageLoader/index'
import { useLocation, useNavigate } from 'react-router-dom'

import { getNewImportedSpreadsheetData } from '../../api/services/spreadsheet.srvices'

import SAVE_IMPORTED_SPREADSHEET_DATA from '../../api/services/SAVE_IMPORTED_SPREADSHEET_DATA'
import useSaveSpreadsheetData from '../../api/mutation/useSaveSpreadsheetData'
import { API_SPREADHSHEET_SAVE_SUCCESS_MESSAGE, API_ERROR_MESSAGE } from '../../api/constant/route'
import MainSpreadSheet from './mainSpreadSheet'
import Toast from '../../common/toast'

const ImportSpreadSheet = () => {
  const [objectiveId, setObjectiveId] = useState('')
  const location = useLocation()
  const navigate = useNavigate()

  const queryParams = new URLSearchParams(location.search)
  const [loadingError, setLoadingError] = useState(false)

  const [mainResult, setMainResult] = useState(null)

  const [errorMessage, setErrorMessage] = useState(null)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const importId = queryParams.get('importId')
    getObjectiveTask(importId)
  }, [])

  async function getObjectiveTask(importId) {
    setIsLoading(true)
    const result = await getNewImportedSpreadsheetData(importId)
    setIsLoading(false)

    if (result) {
      setMainResult(result.saved_data)
      setObjectiveId(result.obj_id)
    }
  }

  const mutationSaveSpreadsheetData = SAVE_IMPORTED_SPREADSHEET_DATA()
  //Returns if there are any unsaved changes left
  const saveData = async (
    createdRowIds,
    updatedRowIds,
    deletedRowIds,
    idMapping,
    actdata,
    columnMetadata,
  ) => {
    let setChangesObj = {
      // createdRowIds: Array.from(createdRowIds),
      createdRowIds: Array.from({ length: actdata.length }, (v, i) => i),
      // updatedRowIds: Array.from(updatedRowIds),
      // deletedRowIds: Array.from(deletedRowIds),
      updatedRowIds: Array.from([]),
      deletedRowIds: Array.from([]),
      activityData: actdata,
      idMapping: idMapping,
      columnMetadata: columnMetadata,
      objectiveId: objectiveId,
    }

    let changes_activities = { changes_activities: setChangesObj }
    setIsLoading(true)

    await mutationSaveSpreadsheetData.mutateAsync(changes_activities, {
      onSuccess: (response) => {
        setIsLoading(false)
        Toast.fire({
          icon: 'success',
          title: 'Your imported data was saved successfully.',
        })
        navigate(`/spreadsheets?objId=${objectiveId}`)
        localStorage.setItem('tfst', Date.now() / 1000)
        return false
      },
      onError: (response) => {
        setIsLoading(false)
        return true
      },
    })
  }

  let hasErrors =
    mainResult?.saved_data?.errors && Object.keys(mainResult?.saved_data?.errors).length > 0

  return (
    <>
      <ApplicationLayout />

      {loadingError && <span> There was an error loading the data. </span>}
      {!loadingError && (
        <>
          {hasErrors === true ? (
            <>
              There were some errors. Please fix them and click the "Save Data" button to finish
              importing your data.{' '}
            </>
          ) : (
            <>
              Please verify your data and click the "Save Data" button to finish importing your
              data.{' '}
            </>
          )}
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '30px' }}>
            {errorMessage && <div className="validation-error">{errorMessage}</div>}
            {isLoading === true && <InPageLoader inComponent={true} />}
            {isLoading === false && mainResult && (
              <MainSpreadSheet mainResult={mainResult} saveData={saveData} />
            )}
          </div>
        </>
      )}
    </>
  )
}

export default ImportSpreadSheet

import React, { useState } from 'react'

import { Handle, Position } from 'reactflow'
import './customNode.scss'
import ActivityIcon from '../../../assets/activity.svg'
import MessageIcon from '../../../assets/message.svg'
import ZoomIcon from '../../../assets/zoom.svg'
import ZoomOutIcon from '../../../assets/zoomOut.svg'

const handleStyle = { left: 10 }

function CustomNode({ data, isConnectable }) {
  const SelectFirstCharacter = (name) => {
    return name
    // .charAt(2);
  }

  const renderListOfUserNames = (data) => {
    return (
      <>
        {data.slice(0, 4).map((item, key) => (
          <span key={item.eId + key}>{SelectFirstCharacter(item.name)},</span>
        ))}
        {data.length > 4 ? (
          <span className="d-flex align-items-center">{data.length - 4} more</span>
        ) : null}
      </>
    )
  }

  return (
    <div className="nodrag text-updater-node">
      <div className={`custom-box-node light-grey-box custom-box-node-assignee`}>
        <ul className="node-text-list">
          <li className="single-node">
            <p>There are no connected objectives at this level for the current selection.</p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default CustomNode

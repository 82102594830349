import React, { useState, useEffect } from 'react'
import Modal from '../Modal'
import useAddComment from '../../api/mutation/addCommentHome'
import { sanitizeString } from '../../utils/general'
import { useTranslation } from 'react-i18next'
import CommentForm2 from '../Comments/CommentForm2'
import ActivityActionForm from '../Comments/ActivityActionForm'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import InPageLoader from '../InPageLoader'
import { getObjectivUserList } from '../../api/services/employee.services'
import { removeMentionMarkupFromText } from '../../utils/parseMarkup'

const CommentsModal2 = ({
  objective,
  handleCancel,
  taskId = null,
  topicId = null,
  displayAllComments = false,
  setIsRefreshObjective,
  showIsCompleted = false,
  actTitle = null,
  callbackFn = () => {},
}) => {
  const { t } = useTranslation(['Common'])
  const mutationAddComment = useAddComment()

  const [filteredComments, setFilteredComments] = React.useState([])
  const [isCommentLoading, setIsCommentLoading] = React.useState(false)
  const [modalTitle, setModalTitle] = React.useState('')
  const [reload, setReload] = React.useState(false)
  const [userList, setUserList] = React.useState([])
  const [mentionsList, setMentionsList] = React.useState([])

  const reloadFunction = () => {
    setIsCommentLoading(true)
    setReload(!reload)

    if (setIsRefreshObjective != undefined && setIsRefreshObjective != null) {
      setIsRefreshObjective((old) => !old)
    }

    callbackFn()
    setIsCommentLoading(false)
  }

  React.useEffect(() => {
    ;(async () => {
      const response = await getObjectivUserList(0)
      if (response?.employees)
        setUserList(response.employees.map((emp) => ({ display: emp.name, id: emp.eId })))
    })()
  }, [])

  React.useEffect(() => {
    setIsCommentLoading(true)
    // Assuming getHttpRequest accepts a second parameter for request options
    const requestOptions = {
      params: {
        objId: taskId ? null : objective.id,
        activityId: taskId ?? null,
      },
    }

    ;(async () => {
      try {
        // Modify getHttpRequest to accept requestOptions
        let result = await getHttpRequest('/get_comments_modal', requestOptions)
        setFilteredComments(result.comments)
        if (taskId) {
          setModalTitle(removeMentionMarkupFromText(result?.activityStatement))
        } else {
          setModalTitle(result?.objStatement)
        }
      } catch (error) {
        // Handle error, e.g., display an error message or log the error
        console.error('Error fetching comments:', error)
      }
      setIsCommentLoading(false)
    })()
  }, [objective.id, taskId, reload]) // Add dependencies if needed

  const handleSubmit = async (values, objectiveId, taskId, topicId) => {
    let objToSubmit = {
      comments: [
        {
          text: sanitizeString(values.text),
          objId: objectiveId,
          mentions: mentionsList.map((mention) => mention.id),
        },
      ],
    }
    if (taskId) {
      objToSubmit.comments[0].activityId = taskId
    } else if (topicId) {
      alert('0x001 topicId: ' + topicId)
      objToSubmit.comments[0].topicId = topicId
    }

    await mutationAddComment.mutateAsync(objToSubmit, {
      onSuccess: (response) => {
        if (response) {
          if (setIsRefreshObjective != undefined && setIsRefreshObjective != null) {
            setIsRefreshObjective((old) => !old)
          }
          callbackFn()
          setReload(!reload)
        } else {
          //handleError(true)
        }
      },
      onError: () => {
        //handleError(false)
      },
    })
  }

  return (
    <>
      <div className="comments-modal">
        <Modal
          title={modalTitle}
          closeModal={handleCancel}
          titleStyle={{ color: 'rgb(91,132,203)', textAlign: 'left' }}
          width={taskId ? '40rem' : '40rem'}
        >
          {isCommentLoading && <InPageLoader inComponent={true} />}

          {!isCommentLoading && (
            <>
              {taskId ? (
                <ActivityActionForm
                  filteredComments={filteredComments}
                  isCommentLoading={isCommentLoading}
                  objectiveId={parseInt(objective?.id)}
                  handleSubmit={handleSubmit}
                  isLoading={mutationAddComment.isLoading || mutationAddComment.isFetching}
                  taskId={taskId}
                  topicId={topicId}
                  displayAllComments={displayAllComments}
                  objectiveStatement={objective?.statement}
                  reloadFunction={reloadFunction}
                  userList={userList}
                  setMentionsList={setMentionsList}
                />
              ) : (
                <CommentForm2
                  filteredComments={filteredComments}
                  isCommentLoading={isCommentLoading}
                  objectiveId={parseInt(objective?.id)}
                  handleSubmit={handleSubmit}
                  isLoading={mutationAddComment.isLoading || mutationAddComment.isFetching}
                  taskId={taskId}
                  topicId={topicId}
                  displayAllComments={displayAllComments}
                  objectiveStatement={objective?.statement}
                  reloadFunction={reloadFunction}
                  userList={userList}
                  setMentionsList={setMentionsList}
                />
              )}
            </>
          )}
        </Modal>
      </div>
    </>
  )
}

export default CommentsModal2

import Axios from '../axios/_instance'

let abortController = new AbortController()

export async function getHttpRequest(url, postData = {}, config = {}) {
  const path = `${url}`

  const requestConfig = {
    signal: abortController.signal,
    ...config,
  }

  const { data } = await Axios.get(path, postData, requestConfig)
  return data
}

export async function postHttpRequest(url, postData, config = {}) {
  const path = `${url}`

  const requestConfig = {
    signal: abortController.signal,
    ...config,
  }
  const { data } = await Axios.post(path, postData, requestConfig)
  return data
}

export async function getHttpRequestBinary(url, postData = {}, config = {}) {
  const path = `${url}`

  const requestConfig = {
    signal: abortController.signal,
    responseType: 'arraybuffer',
    params: postData,
    ...config,
  }

  const { data } = await Axios.get(path, requestConfig)
  return data
}

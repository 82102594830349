import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getEmpObjMapping = async (tlId) => {
  const queryString = tlId === null ? '/emp_obj_alloc' : `/emp_obj_alloc/${tlId}`

  const { data } = await axios.get(`${BASE_URL}${queryString}`)

  return data
}

export default function useEmpObjMapping(tlId = null) {
  return useQuery(['emp-obj-mapping'], () => getEmpObjMapping(tlId), {
    refetchOnWindowFocus: false,
  })
}

import React, { useState, useEffect } from 'react'
import { useCapacityContext } from '../../context/capacityContext'
import ObjTeamMappingTable from '../../../../components/AllocSubWizard/ObjTeamMappingTable'
import AddTeamMemberModal from '../../../../components/AllocSubWizard/AddTeamMemberModal'
//import useObjectiveData from '../../../../api/query/getObjectiveData'
//import useEmpObjMapping from '../../../../api/query/useEmpObjMapping'
import { useTranslation } from 'react-i18next'
import CapacityAddObjectiveModal from '../../../../components/AllocSubWizard/AddObjectiveModal'

const CapacityObjectivesTeamMapping = () => {
  const { t } = useTranslation(['Common'])
  const { state, dispatch } = useCapacityContext()

  return (
    <>
      {state.isTeamMemberFormOpen === true ? (
        <AddTeamMemberModal contextHandler={useCapacityContext} />
      ) : null}
      {state.isAddObjectiveModalOpen === true ? (
        <CapacityAddObjectiveModal contextHandler={useCapacityContext} />
      ) : null}

      <div className="wizard-step">
        <div
          className="wizard-step__title"
          style={{ fontWeight: 'bold', fontSize: '1.4rem', margin: '0.5rem' }}
        >
          {t('capacityAllocation.capacityObjectiveTitle')}
        </div>
        <div className="wizard-step__overview">
          <div className="wizard-step__overview__paragraph">
            {t('capacityAllocation.capacityObjectiveSubtitle')}
          </div>
        </div>

        <div
          className="wizard-step__main-content-container"
          style={{ width: '65vw', padding: '2rem' }}
        >
          <div className="objectivesTeamMapping" style={{ width: '95%' }}>
            <ObjTeamMappingTable contextHandler={useCapacityContext} />
          </div>
        </div>
      </div>
    </>
  )
}

export default CapacityObjectivesTeamMapping

import React from 'react'
import { getHttpRequest, postHttpRequest } from '../../../../api/query/dynamicAPI'
import { useTranslation } from 'react-i18next'
import Toast from '../../../../common/toast'
import styled from 'styled-components'

const ObjectiveStatement = styled.div`
  font-size: 16px;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 1.5rem;
`
const ClassifyTopics = () => {
  const { t } = useTranslation(['MeetingHub'])
  const [meetingTopicsData, setMeetingTopicsData] = React.useState([])

  const categoryMap = {
    0: 'UNKNOWN_CATEGORY',
    1: 'PROCESS',
    2: 'PRODUCT',
    3: 'RESOURCE',
    4: 'APPROVAL',
    5: 'DIRECTION',
    6: 'PRIORITIZATION',
  }

  React.useEffect(() => {
    ;(async () => {
      let result = await getHttpRequest('/get_blockers_guidances_for_classification')
      initDropDown(result)
    })()
  }, [])

  const initDropDown = (result) => {
    setMeetingTopicsData(result.objectives ?? [])
  }

  const handleHumanLabelChange = (topicId, type, objId) => (e) => {
    let mts = [...meetingTopicsData]

    const matchingObj = mts.find((obj) => obj.objId == objId)
    let topic = {}
    if (!matchingObj) return

    if (type == 1) {
      topic = matchingObj.blockers.find((t) => t.mtId == topicId)
    } else if (type == 2) {
      topic = matchingObj.decisions.find((t) => t.mtId == topicId)
    }
    topic.manualClass = categoryMap[e.target.value]
    setMeetingTopicsData(mts)
  }

  const saveData = async () => {
    let postData = {
      newData: meetingTopicsData,
    }
    try {
      await postHttpRequest('/save_blockers_guidances_for_classification', postData)
      Toast.fire({
        icon: 'success',
        title: t('Common:modalMessages.updatedSuccessfully'),
      })
    } catch (e) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const validateAllValues = () => {
    return meetingTopicsData.some((obj) => {
      return (
        obj.blockers?.some((blocker) => blocker.manualClass == 'UNKNOWN_CATEGORY') ||
        obj.decisions?.some((decision) => decision.manualClass == 'UNKNOWN_CATEGORY')
      )
    })
  }

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value)
  }
  return (
    <>
      {meetingTopicsData?.length > 0 ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            width: '88%',
          }}
        >
          <div className="shadow" style={{ width: '100%', marginBottom: '3rem', padding: '2rem' }}>
            <div className="my-decisions-meeting-table-classification">
              <div className="my-decisions-meeting-header"></div>
              <div className="my-decisions-meeting-header">{t('myDecisions.topic')}</div>

              <div className="my-decisions-inner-grid-classification">
                <div className="my-decisions-meeting-header" style={{ paddingLeft: '0rem' }}>
                  {t('myDecisions.humanLabel')}
                </div>
                <div className="my-decisions-meeting-header" style={{ paddingLeft: '0rem' }}>
                  {t('myDecisions.mlLabel')}
                </div>
              </div>
              <div className="divider my-decisions-meeting-description-classification"></div>

              {meetingTopicsData.map((objective, index) => {
                const { blockers, decisions } = objective
                const totalRows = (blockers?.length ?? 0) + (decisions?.length ?? 0)
                return (
                  <React.Fragment key={`objective-${index}`}>
                    {totalRows > 0 && (
                      <>
                        <div className="decision-log-meeting-description">
                          <b>
                            <ObjectiveStatement>{objective.objDescription}</ObjectiveStatement>
                          </b>
                          <div className="divider"></div>
                        </div>
                        {blockers?.map((topic, index) => (
                          <React.Fragment key={`topic-${index}`}>
                            <div></div>
                            <div className="meeting-topic-blocker">{topic.description}</div>

                            <div
                              className="my-decisions-inner-grid-classification"
                              key={'blocker-' + topic.mtId}
                            >
                              <select
                                value={getKeyByValue(categoryMap, topic?.manualClass)}
                                onChange={(e) =>
                                  handleHumanLabelChange(topic.mtId, 1, objective.objId)(e)
                                }
                                style={{ height: '38px', width: '250px' }}
                              >
                                <option value="0">UNKNOWN_CATEGORY</option>
                                <option value="1">Process</option>
                                <option value="2">Product</option>
                                <option value="3">Resource</option>
                              </select>
                              <select
                                value={getKeyByValue(categoryMap, topic?.mlClass)}
                                disabled
                                style={{ height: '38px', width: '250px' }}
                              >
                                <option value="0">UNKNOWN_CATEGORY</option>
                                <option value="1">Process</option>
                                <option value="2">Product</option>
                                <option value="3">Resource</option>
                              </select>
                            </div>
                          </React.Fragment>
                        ))}
                        {decisions?.map((topic, index) => (
                          <React.Fragment key={`topic-${index}`}>
                            <div></div>
                            <div className="meeting-topic-decision">{topic.description}</div>
                            <div
                              className="my-decisions-inner-grid-classification"
                              key={'guidance-' + topic.mtId}
                            >
                              <select
                                value={getKeyByValue(categoryMap, topic?.manualClass)}
                                onChange={(e) =>
                                  handleHumanLabelChange(topic.mtId, 2, objective.objId)(e)
                                }
                                style={{ height: '38px', width: '250px' }}
                              >
                                <option value="0">UNKNOWN_CATEGORY</option>
                                <option value="4">Approval</option>
                                <option value="5">Decision</option>
                                <option value="6">Prioritization</option>
                              </select>
                              <select
                                value={getKeyByValue(categoryMap, topic?.mlClass)}
                                disabled
                                style={{ height: '38px', width: '250px' }}
                              >
                                <option value="0">UNKNOWN_CATEGORY</option>
                                <option value="4">Approval</option>
                                <option value="5">Decision</option>
                                <option value="6">Prioritization</option>
                              </select>
                            </div>
                          </React.Fragment>
                        ))}
                      </>
                    )}
                  </React.Fragment>
                )
              })}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
              <button
                // style={{ right:'0' }}
                onClick={() => saveData()}
                className="move-btn-table import_export_btn btn_cmn"
              >
                Save Data
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div>No Blockers/Guidance</div>
      )}
    </>
  )
}

export default ClassifyTopics

import React from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { dateFilter } from '../../utils/time'
import Button from '../Button'
import { colors } from '../../utils/colors'
import AddCommentButton from '../AddCommentButton'
import InPageLoader from '../InPageLoader'
import './styles.css'
import { linkifyString } from '../../utils/general'
import useSetCommentsPinUnpin from '../../api/mutation/setCommentsPinUnpin'
import { useTranslation } from 'react-i18next'

const CommentForm = (props) => {
  return <></>
}

export default CommentForm

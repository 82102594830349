import React, { useRef } from 'react'
import useGetEmployeesUserConfigs from '../../../../../../api/query/useGetEmployeesUserConfig'
import useEditEmployeesUserConfig from '../../../../../../api/mutation/useEditEmployeesUserConfig'
import {
  updateConfigEnum,
  userConfigEnum,
  userConfigTLEnum,
} from '../../../../../../utils/enumValues'
import Modal from '../../../../../../components/Modal'
import Toast from '../../../../../../common/toast'
import InPageLoader from '../../../../../../components/InPageLoader'
import { getCompanyConfigSettings, hasRole } from '../../../../../../utils/auth'
import './styles.css'
import { getUserConfigFlagMetadata } from '../../../../../../api/services/employee.services.jsx'
import { useTranslation } from 'react-i18next'

const TLConfigModal = ({ isModalOpen, closeModal, selectedAreaLeader }) => {
  const areaLeaderId = selectedAreaLeader?.eId ?? '0000'
  const { data: employeesData, isLoading: isEmployeesDataLoading } =
    useGetEmployeesUserConfigs(areaLeaderId)
  const [flagMetadata, setFlagMetadata] = React.useState(null)
  const [empFlagValueMapping, setEmpFlagValueMapping] = React.useState(null)
  const [usersTouched, setUsersTouched] = React.useState([])
  const [categories, setCategories] = React.useState([])
  const [categoryFilterValue, setCategoryFilterValue] = React.useState('Objectives')

  const { t } = useTranslation(['Common'])

  const getUserConfigFlagMetadata2 = async () => {
    const data = await getUserConfigFlagMetadata()

    if (data) {
      console.log('flag metadata', data)
      setFlagMetadata(data)
      let categoriesOfFlags = []
      for (let flag of data.flag_metadata_list) {
        if (!categoriesOfFlags.includes(flag.category)) {
          categoriesOfFlags.push(flag.category)
        }
      }
      console.log('categories', categoriesOfFlags)
      categoriesOfFlags.sort((a, b) => a.localeCompare(b))
      setCategories(categoriesOfFlags)
      setCategoryFilterValue(categoriesOfFlags[0])
    }
  }

  React.useEffect(() => {
    getUserConfigFlagMetadata2()
  }, [])

  const [tls, setTLUsers] = React.useState(employeesData ? employeesData?.employees : [])

  React.useEffect(() => {
    if (employeesData?.employees && flagMetadata) {
      setTLUsers(employeesData?.employees)

      let newEmpFlagValueMapping = {}

      for (let employee of employeesData?.employees ?? []) {
        newEmpFlagValueMapping[employee.e_id] = {}

        let hasDefaultFlag = false

        for (let flag of flagMetadata.flag_metadata_list) {
          newEmpFlagValueMapping[employee.e_id][flag.name] =
            employee.user_config && employee.user_config[flag.name] !== undefined
              ? employee.user_config[flag.name]
              : false

          // One of the following flags should be enabled for the user
          if (
            [
              'tl_show_full_update_wiz',
              'tl_skip_capacity_wiz',
              'tl_skip_activity_wiz',
              'tl_skip_full_update_wiz',
            ].includes(flag.name) &&
            newEmpFlagValueMapping[employee.e_id][flag.name]
          ) {
            hasDefaultFlag = true
          }
        }

        // If none of the flags are enabled, enable the default flag
        if (!hasDefaultFlag) {
          newEmpFlagValueMapping[employee.e_id]['tl_show_full_update_wiz'] = true
        }
      }

      setEmpFlagValueMapping(newEmpFlagValueMapping)
    }
  }, [employeesData, flagMetadata])

  const handleTLCheckboxChange = (e, eId, flag) => {
    let tmpEmpFlagValueMapping = { ...empFlagValueMapping }
    let newUsersTouched = [...usersTouched]

    if (eId === -1) {
      for (let user of tls) {
        if (tmpEmpFlagValueMapping[user.e_id]) {
          tmpEmpFlagValueMapping[user.e_id][flag.name] = e.target.checked
        }
      }
      newUsersTouched = tls.map((user) => user.e_id)
      console.log('newUsersTouched', newUsersTouched)
    } else {
      tmpEmpFlagValueMapping[eId][flag.name] = !tmpEmpFlagValueMapping[eId][flag.name]
      if (!newUsersTouched.includes(eId)) {
        newUsersTouched.push(eId)
      }
    }

    setEmpFlagValueMapping(tmpEmpFlagValueMapping)
    setUsersTouched(newUsersTouched)
  }

  const checkIfAllSelected = (flag) => {
    if (!empFlagValueMapping) {
      return false
    }
    for (let user of tls) {
      if (!empFlagValueMapping[user.e_id][flag.name]) {
        return false
      }
    }
    return true
  }

  const handleTLRadioChange = (e, eId, flag, groupId) => {
    let tmpEmpFlagValueMapping = { ...empFlagValueMapping }
    console.log('tempEmpFlagValueMapping', tmpEmpFlagValueMapping)
    console.log('flagMetadata', flagMetadata)
    if (eId === -1) {
      for (let user of tls) {
        // Find all flags in this group and set them to false
        for (let flagMxGroup of flagMetadata.flag_mx_groups) {
          if (flagMxGroup.group_id === groupId) {
            for (let flagName of flagMxGroup.flag_names) {
              tmpEmpFlagValueMapping[user.e_id][flagName] = false
            }
          }
        }
        tmpEmpFlagValueMapping[user.e_id][flag.name] = true
      }
      setEmpFlagValueMapping(tmpEmpFlagValueMapping)
      setUsersTouched(tls.map((user) => user.e_id))
    } else {
      // Find all flags in this group and set them to false
      for (let flagMxGroup of flagMetadata.flag_mx_groups) {
        if (flagMxGroup.group_id === groupId) {
          for (let flagName of flagMxGroup.flag_names) {
            tmpEmpFlagValueMapping[eId][flagName] = false
          }
        }
      }

      tmpEmpFlagValueMapping[eId][flag.name] = true
      setEmpFlagValueMapping(tmpEmpFlagValueMapping)
      setUsersTouched([...usersTouched, eId])
    }
  }

  const editUserConfigsMutation = useEditEmployeesUserConfig()

  const saveData = async () => {
    // Only send users who were touched.
    let empFlagValueMappingCopy = {}
    for (let user of tls) {
      if (usersTouched.includes(user.e_id)) {
        empFlagValueMappingCopy[user.e_id] = empFlagValueMapping[user.e_id]
      }
    }

    console.log('empFlagValueMappingCopy', empFlagValueMappingCopy)

    await editUserConfigsMutation.mutateAsync(
      { flagData: empFlagValueMappingCopy },
      {
        onSuccess: (response) => {
          Toast.fire({
            icon: 'success',
            title: 'Data saved successfully',
          })
        },
        onError: () => {
          Toast.fire({
            icon: 'error',
            title: 'Something went wrong',
          })
        },
      },
    )
  }

  let flagIdToFlag = {}
  if (flagMetadata) {
    for (let flag of flagMetadata.flag_metadata_list) {
      flagIdToFlag[flag.name] = flag
    }
  }

  // First we will render all mutually exclusive groups
  let flagsNamesCoveredByMxGroups = []
  let mxGroups = []
  if (flagMetadata) {
    for (let mxGroup of flagMetadata.flag_mx_groups) {
      let flags = []
      for (let flag_name of mxGroup.flag_names) {
        flags.push(flagIdToFlag[flag_name])
        flagsNamesCoveredByMxGroups.push(flag_name)
      }
      mxGroups.push({ flags: flags, groupId: mxGroup.group_id })
    }
  }

  let flagsNotCoveredByMxGroups = []
  if (flagMetadata) {
    for (let flag of flagMetadata.flag_metadata_list) {
      if (!flagsNamesCoveredByMxGroups.includes(flag.name)) {
        flagsNotCoveredByMxGroups.push(flag)
      }
    }
  }

  return (
    <div className="tlconfig-modal">
      <Modal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        style={{ padding: '0rem 0rem 1rem 0rem' }}
        fixHorizontalScroll={true}
        // includeCloseIcon={employeesData?.employees?.length > 0 ? false : true}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '2rem',
              width: '65%',
              // overflowX:'auto'
            }}
          >
            <div style={{ alignSelf: 'start', marginLeft: '3rem' }}>
              Team Leaders for
              <select className="accountable-select" defaultValue={areaLeaderId}>
                <option value={selectedAreaLeader?.eId} key={`${selectedAreaLeader?.eId}`}>
                  {selectedAreaLeader?.name}
                </option>
              </select>
            </div>
            {isEmployeesDataLoading && (
              <div>
                <InPageLoader inComponent={true} />
              </div>
            )}
            {!isEmployeesDataLoading && employeesData && employeesData?.employees?.length > 0 && (
              <>
                <div style={{ width: '90vw', overflowX: 'scroll' }}>
                  <div
                    style={{
                      marginTop: '2rem',
                      marginLeft: '3rem',
                      display: 'flex',
                      flexWrap: 'wrap',
                    }}
                  >
                    {categories.map((category, index) => (
                      <div
                        key={index}
                        style={{
                          backgroundColor: category === categoryFilterValue ? '#4472c4' : '#a6a6a6',
                          marginRight: '1rem',
                          marginTop: '1rem',
                        }}
                        className="option-button"
                        onClick={() => setCategoryFilterValue(category)}
                      >
                        {category}
                      </div>
                    ))}
                  </div>

                  <table style={{ marginBottom: '1rem', paddingTop: '1rem' }}>
                    <thead style={{ width: '100%' }}>
                      <tr>
                        <th style={{ width: '10rem' }}>Name</th>
                        {mxGroups.map((mxGroup, mxGroupIndex) =>
                          mxGroup.flags
                            .filter(
                              (flag) =>
                                categoryFilterValue === 'All' ||
                                flag.category === categoryFilterValue,
                            )
                            .map((flag) => (
                              <th style={{ width: '12rem' }}>
                                <span
                                  className="tooltip"
                                  style={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                  }}
                                >
                                  {flag.description}
                                  {flag.full_description !== undefined &&
                                    flag.full_description !== null &&
                                    flag.full_description !== '' && (
                                      <span className="tooltiptext" style={{ width: '18rem' }}>
                                        {flag.full_description}
                                      </span>
                                    )}
                                </span>
                              </th>
                            )),
                        )}
                        {flagsNotCoveredByMxGroups
                          .filter(
                            (flag) =>
                              categoryFilterValue === 'All' ||
                              flag.category === categoryFilterValue,
                          )
                          .map((flag) => (
                            <th key={flag.name} style={{ width: '12rem' }}>
                              <span
                                className="tooltip"
                                style={{
                                  display: 'inline-flex',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                }}
                              >
                                {flag.description}
                                {flag.full_description !== undefined &&
                                  flag.full_description !== null &&
                                  flag.full_description !== '' && (
                                    <span className="tooltiptext" style={{ width: '18rem' }}>
                                      {flag.full_description}
                                    </span>
                                  )}
                              </span>
                            </th>
                          ))}
                      </tr>
                    </thead>
                    <tbody style={{ width: '100%' }}>
                      <tr key={'all'}>
                        <td style={{ width: '10rem' }}>All</td>

                        {mxGroups.map((mxGroup, mxGroupIndex) =>
                          mxGroup.flags
                            .filter(
                              (flag) =>
                                categoryFilterValue === 'All' ||
                                flag.category === categoryFilterValue,
                            )
                            .map((flag) => (
                              <td style={{ width: '12rem' }}>
                                <div>
                                  <input
                                    type="radio"
                                    checked={checkIfAllSelected(flag)}
                                    onChange={(e) =>
                                      handleTLRadioChange(e, -1, flag, mxGroup.groupId)
                                    }
                                  />
                                </div>
                              </td>
                            )),
                        )}

                        {flagsNotCoveredByMxGroups
                          .filter(
                            (flag) =>
                              categoryFilterValue === 'All' ||
                              flag.category === categoryFilterValue,
                          )
                          .map((flag) => (
                            <td key={flag.name} style={{ width: '12rem' }}>
                              <div>
                                <input
                                  type="checkbox"
                                  checked={checkIfAllSelected(flag)}
                                  onChange={(e) => handleTLCheckboxChange(e, -1, flag)}
                                />
                              </div>
                            </td>
                          ))}
                      </tr>
                      {tls?.map((user, userIndex) => (
                        <tr key={user.e_id}>
                          <td style={{ width: '10rem' }}>{user.name}</td>

                          {mxGroups.map((mxGroup, mxGroupIndex) =>
                            mxGroup.flags
                              .filter(
                                (flag) =>
                                  categoryFilterValue === 'All' ||
                                  flag.category === categoryFilterValue,
                              )
                              .map((flag) => (
                                <td style={{ width: '12rem' }}>
                                  <div>
                                    <input
                                      id={`${user.e_id}-${flag.name}`}
                                      type="radio"
                                      checked={
                                        empFlagValueMapping &&
                                        empFlagValueMapping[user?.e_id] &&
                                        empFlagValueMapping[user?.e_id][flag.name]
                                      }
                                      onChange={(e) =>
                                        handleTLRadioChange(e, user.e_id, flag, mxGroup.groupId)
                                      }
                                    />
                                  </div>
                                </td>
                              )),
                          )}

                          {flagsNotCoveredByMxGroups
                            .filter(
                              (flag) =>
                                categoryFilterValue === 'All' ||
                                flag.category === categoryFilterValue,
                            )
                            .map((flag) => (
                              <td key={flag.name} style={{ width: '12rem' }}>
                                <div>
                                  <input
                                    id={`${user.e_id}-${flag.name}`}
                                    type="checkbox"
                                    checked={
                                      empFlagValueMapping &&
                                      empFlagValueMapping[user?.e_id] &&
                                      empFlagValueMapping[user?.e_id][flag.name]
                                    }
                                    onChange={(e) => handleTLCheckboxChange(e, user.e_id, flag)}
                                  />
                                </div>
                              </td>
                            ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
        {!isEmployeesDataLoading && employeesData && employeesData?.employees?.length > 0 && (
          <div
            style={{ marginTop: '1rem', display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            <div className="modal-dual-btn" style={{ marginTop: '0rem' }}>
              <button
                className="fitted-button blue"
                style={{
                  background: 'white',
                  padding: '0.4rem',
                  height: '35px',
                  fontWeight: 'bold',
                  marginRight: '1rem',
                }}
                onClick={() => {
                  closeModal()
                }}
                type="button"
              >
                {t('commonButtons.cancelSmall')}
              </button>

              <button
                type="button"
                className="fitted-button blue"
                style={{
                  background: 'white',
                  padding: '0.4rem',
                  height: '35px',
                  fontWeight: 'bold',
                }}
                onClick={() => saveData(tls)}
              >
                {t('commonButtons.save')}
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}

export default TLConfigModal

import Navigation from '../Navigation'
import ChatBot from '../ChatBot'
import { getConsolidatedConfigSettings } from '../../utils/auth'
import { useNavbarContext } from '../../utils/navBarContext'
import React, { useState } from 'react'

const ApplicationLayout = ({ children, loginProtected, fromSetUpAgent }) => {
  const enableUserBot = getConsolidatedConfigSettings('enable_user_chatbot') === true
  const { isNavbarCollapsed, handleNavbarStateChange } = useNavbarContext()

  return (
    <div>
      <Navigation
        loginProtected={loginProtected}
        children={children}
        onNavbarStateChange={handleNavbarStateChange}
        isNavbarCollapsed={isNavbarCollapsed}
        fromSetUpAgent={fromSetUpAgent}
      />
      {/* {enableUserBot && <ChatBot />} */}
    </div>
  )
}

export default ApplicationLayout

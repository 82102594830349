import React from 'react'
// import Loading from '../../components/Loading'
import { useLocation } from 'react-router-dom'
import { getHttpRequest, postHttpRequest } from '../../../api/query/dynamicAPI'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { linkifyString, shortenName } from '../../../utils/general'
import InPageLoader from '../../../components/InPageLoader'
import ApplicationLayout from '../../../components/ApplicationLayout'
import { getCompanyConfigSettings } from '../../../utils/auth'
import { removeMentionMarkupFromText } from '../../../utils/parseMarkup'

const DecisionLogDigest = () => {
  const { t } = useTranslation(['MeetingHub', 'Common'])
  const isProductSettingsEnabled = getCompanyConfigSettings('productSettings')

  async function fetchDecisionLogViaEmail(emailId) {
    try {
      const data = await postHttpRequest(`/digest/decision_log/data/${emailId}`, {})
      return data
    } catch (e) {
      // Toast.fire({
      //   icon: 'error',
      //   title: API_ERROR_MESSAGE,
      // });
    }
  }

  async function getDecisionLogViaEmail(emailId) {
    setLoading(true)
    const result = await fetchDecisionLogViaEmail(emailId)
    setLoading(false)

    if (result) {
      if (result.error && result.error === 403) {
        setAuthError(true)
        return
      }

      setDecisionLogData(result.log)
      setForUser(result.user)
    }
  }

  const [decisionLogData, setDecisionLogData] = useState(null)
  const [forUser, setForUser] = useState(null)
  const [authError, setAuthError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const emailId = queryParams.get('digest_id')
    getDecisionLogViaEmail(emailId)
  }, [])

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const emailId = queryParams.get('digest_id')

  const isLogEditable = false
  const isTeamMeetingView = false

  const renderComments = (comment, objective, topic) => {
    return (
      <div key={comment.commentId} className="decision-log-inner-grid-area-view">
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 0.03fr', gap: '1rem' }}>
          <div
            className="decision-log-comment"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              gap: '1rem',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                color: '#A9A9A9',
                border: '1px solid #A9A9A9',
                padding: '0.1rem 0.2rem',
                borderRadius: '0.5rem',
                width: '4.5rem',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '13.33px',
                }}
              >
                {comment.isDecision ? t('decisionLog.decision') : t('decisionLog.comment')}
              </div>
            </div>
            <div>{linkifyString(comment.commentText)}</div>
          </div>
        </div>
        <div>{shortenName(comment.commentOwnerName)}</div>
      </div>
    )
  }

  const renderMilestones = (milestone, objective, topic) => {
    return (
      <div key={milestone.milestoneId} className="decision-log-inner-grid-area-view">
        <div
          key={milestone.milestoneId}
          style={{ display: 'grid', gridTemplateColumns: '1fr 0.03fr', gap: '1rem' }}
        >
          <div
            className="decision-log-milestone"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              gap: '1rem',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                backgroundColor: '#A9A9A9',
                color: 'white',
                border: '1px solid  white',
                padding: '0.1rem 0.2rem',
                borderRadius: '0.5rem',
                width: '4.5rem',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '13.33px',
                }}
              >
                {t('decisionLog.action')}
              </div>
            </div>
            {
              <div style={{ width: '40rem' }}>
                {linkifyString(removeMentionMarkupFromText(milestone.milestoneDescription))}
              </div>
            }
          </div>
        </div>
        <div>
          {milestone?.assignees?.map((assignee, index) => (
            <span key={index}>
              {index > 0 && ', '}
              {shortenName(assignee.name)}
            </span>
          ))}
        </div>
      </div>
    )
  }

  if (authError) {
    return (
      <ApplicationLayout>
        <b>Unauthorized Access</b>
      </ApplicationLayout>
    )
  }

  if (loading) {
    return (
      <ApplicationLayout>
        <InPageLoader />
      </ApplicationLayout>
    )
  }

  return (
    <>
      <ApplicationLayout>
        <>
          <div
            style={{ marginTop: '4rem', marginLeft: '1.5rem', position: 'absolute', width: '95%' }}
          >
            <div
              style={{
                color: 'rgb(91, 132, 203)',
                textAlign: 'left',
                fontSize: '2rem',
                fontWeight: 'bold',
                marginBottom: '1rem',
              }}
            >
              Decision Log
            </div>
            <div style={{ color: '#4472c4', textAlign: 'left', fontSize: '1.2rem' }}>
              {decisionLogData?.logName}
            </div>
            <form action="/resolve_email_action" method="POST">
              {decisionLogData?.objectives?.length > 0 ? (
                <div>
                  <div style={{ marginBottom: '1rem' }}>
                    <div className="decision-log-meeting-table">
                      <div className="decision-log-meeting-header"></div>
                      {decisionLogData?.objectives?.map((objective, index) => {
                        const { topics } = objective
                        const totalRows = topics?.length ?? 0
                        return (
                          <React.Fragment key={`objective-${index}`}>
                            {totalRows > 0 && (
                              <React.Fragment>
                                <div
                                  className="decision-log-meeting-description"
                                  style={{ paddingTop: '2rem', maxWidth: '90%' }}
                                >
                                  <b>
                                    {/* only show initiative number if not general and initiative id exists */}
                                    {isProductSettingsEnabled &&
                                    !objective?.isGeneralObjective &&
                                    objective?.initiativeId &&
                                    objective.isGeneralObjective === false ? (
                                      <span>
                                        {objective?.initiativeId.toString()?.padStart(5, '0')} -{' '}
                                        {objective.objDescription}
                                      </span>
                                    ) : (
                                      <span>{objective.objDescription}</span>
                                    )}
                                  </b>
                                </div>
                                <div className="decision-log-meeting-description">
                                  <div className="underline-meeting"></div>
                                </div>
                                {topics?.length > 0 && (
                                  <>
                                    {topics.map((topic) => (
                                      <>
                                        <div
                                          className="decision-log-meeting-description"
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: '1rem',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <div
                                            style={{
                                              backgroundColor:
                                                topic.topicType === 1
                                                  ? 'rgb(237, 82, 46)'
                                                  : topic.topicType === 2
                                                    ? 'rgb(9, 38, 213)'
                                                    : topic.topicType === 3
                                                      ? 'rgb(127, 131, 155)'
                                                      : '#9933ff',
                                              color: 'white',
                                              padding: '0.2rem 0.2rem',
                                              borderRadius: '0.5rem',
                                              width: '5rem',
                                              fontWeight: 'bold',
                                              height: 'fit-content',
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                fontSize: '13.33px',
                                              }}
                                            >
                                              {topic.topicType === 1
                                                ? t('Common:filter.blocker')
                                                : topic.topicType === 2
                                                  ? t('Common:filter.guidance')
                                                  : topic.topicType === 3
                                                    ? t('Common:filter.update')
                                                    : t('Common:filter.admin')}
                                            </div>
                                          </div>
                                          <b style={{ maxWidth: '53rem' }}>
                                            {linkifyString(topic.description)}
                                          </b>
                                          <div></div>
                                        </div>
                                        <div className="decision-log-meeting-description">
                                          <div className="underline-meeting-light"></div>
                                        </div>
                                        <div>
                                          {topic?.comments?.length > 0 && (
                                            <>
                                              {topic.comments.map((comment) => (
                                                <>{renderComments(comment, objective, topic)}</>
                                              ))}
                                            </>
                                          )}
                                          {topic?.milestones?.length > 0 && (
                                            <>
                                              {topic.milestones.map((milestone) => (
                                                <>{renderMilestones(milestone, objective, topic)}</>
                                              ))}
                                            </>
                                          )}
                                        </div>
                                      </>
                                    ))}
                                  </>
                                )}
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        )
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{ padding: '2rem 0rem 2rem 10rem' }}>
                  {t('decisionLog.thereAreNoLogsAtThisTime')}
                </div>
              )}
            </form>
            <p>&nbsp;</p>
          </div>
        </>
      </ApplicationLayout>
    </>
  )
}

export default DecisionLogDigest

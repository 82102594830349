import { getHttpRequest, postHttpRequest } from '../query/dynamicAPI'
import Toast from '../../common/toast'
import { API_ERROR_MESSAGE } from '../constant/route'

export async function getNotifications() {
  try {
    const data = await getHttpRequest(`/get_notifications`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getNotificationsCount() {
  try {
    const data = await getHttpRequest(`/get_notifications_count`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function clearNotificationsRequest(postData) {
  try {
    const data = await postHttpRequest(`/clear_notifications`, postData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

import React from 'react'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/core.css'
import { useTranslation } from 'react-i18next'

function MyNotebookDropdownMenu(props) {
  //props.type can be 'note' or 'task'
  const { t } = useTranslation(['MyNotebook'])

  return (
    <div>
      <Menu
        menuClassName="soo-menu"
        menuButton={
          <MenuButton
            style={{
              backgroundColor: !props.aiGenPendingApproval ? 'white' : '#f2f2f2',
              border: 'none',
              padding: '0',
            }}
          >
            <span
              style={{ alignSelf: 'center', transform: 'rotate(90deg)' }}
              class="material-symbols-outlined"
            >
              more_vert
            </span>
          </MenuButton>
        }
      >
        <>
          {props.type === 'task' && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                props.onClickDeleteTask()
              }}
            >
              {t('deleteTask')}
            </MenuItem>
          )}

          {props.type === 'task' && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                props.onClickConvertToAction()
              }}
            >
              {t('moveActionToAnObjective')}
            </MenuItem>
          )}

          {props.type === 'task' && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                props.onClickConvertToMeetingTopic()
              }}
            >
              {t('convertToMeetingTopic')}
            </MenuItem>
          )}
        </>
      </Menu>
    </div>
  )
}
export default MyNotebookDropdownMenu

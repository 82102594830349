import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getActivitiesChartOptions = async (objectiveId) => {
  const queryString = `/activities_chart_options/${objectiveId}`

  const { data } = await axios.get(`${BASE_URL}${queryString}`)
  return data
}

export default function useGetActivitiesChartOptions(objectiveId) {
  return useQuery(
    ['activities-chart-options', objectiveId],
    () => getActivitiesChartOptions(objectiveId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(objectiveId),
    },
  )
}

import React, { useState, useEffect } from 'react'
import { useExcoExerciseContext } from '../../context/ExcoExerciseContext'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import '../../styles.scss'
import FacilitatorAprovalModal from '../FacilitatorApprovalModal/index.jsx'

const AIExerciseQuestion3 = () => {
  const { handleNext, isLastStep, submitAnswer, isLoading } = useOutletContext()
  const { state, dispatch } = useExcoExerciseContext()
  const { t } = useTranslation(['UpdateWizard'])
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1)
    }, 1000)

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId)
  }, []) // Empty dependency array ensures the effect runs only once on mount

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
    const remainingSeconds = time % 60
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`
  }

  const [textInput, setTextInput] = useState('')
  const textInputChangeHandler = (e) => {
    setTextInput(e.target.value)
  }
  const [resultText, setResultText] = useState('')
  const resultTextChangeHandler = (e) => {
    setResultText(e.target.value)
  }

  const [gptVersion, setGptVersion] = useState('gpt-35')
  const gptVersionChangeHandler = (e) => {
    setGptVersion(e.target.value)
  }

  const [resultPasses, setResultPasses] = useState(null)

  const [isFacilitatorApprovalModalOpen, setIsFacilitatorApprovalModalOpen] = useState(false)

  return (
    <>
      {isFacilitatorApprovalModalOpen && (
        <FacilitatorAprovalModal
          handleNext={() => {
            handleNext()
          }}
          handleClose={() => {
            setIsFacilitatorApprovalModalOpen(false)
          }}
          saveTimer={() => {
            dispatch({
              type: 'SAVE_QUESTION_TIMER',
              questionNumber: 3,
              time: seconds,
            })
          }}
          saveLastPageVisited={() => {
            dispatch({
              type: 'SAVE_LAST_PAGE_VISITED',
              lastPageVisited: 4,
            })
          }}
        />
      )}

      <div className="wizard-step">
        <h2 className="wizard-step__title">Exercise 3: Reason </h2>
        <div className="wizard-step__main-content-container">
          <div
            className="ai-workshop"
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <div className="score-container">
              <span>Timer: {formatTime(seconds)}</span>
            </div>

            <div className="content-container">
              <div className="prompt-container">
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                  <span>
                    <b>
                      Maria asks the chatbot: <br /> (automatically added to prompt to GPT)
                    </b>
                    <br />
                    <br />
                    "I want any laptop from 2021 with a 4 star or higher review and at least 16 GB
                    or more. A high resolution monitor would be nice. I prefer a laptop that stands
                    out and isn't boring looking."
                  </span>

                  <span>
                    <b>Goal of this exercise:</b>
                    <br />
                    <br />
                    <span>
                      Our search returned 2 laptops that might be a good fit for Maria. Write a
                      prompt that will suggest the best laptop to her and give a reason why. The
                      result should be in the format: Maria, your perfect &#60;item type&#62; is
                      &#60;item&#62; because &#60;rationale&#62;.
                    </span>
                    <br />
                    <br />
                    <span>
                      <b>
                        The laptops we have are: <br /> (automatically added to prompt to GPT)
                      </b>
                    </span>
                    <br />
                    <br />
                    <span>
                      (Item_type = "laptop", brand = "Apple", Specs = (Speed = "5", RAM = "16", HD
                      space = "5", Battery_life = "5", "High_resolution_monitor = "yes"), Model_year
                      = "2021", User_rating = "4", Color = "Pink")
                    </span>
                    <br />
                    <br />
                    <span>
                      (Item_type = "laptop", brand = "Lenovo", Specs = (Speed = "5", RAM = "32", HD
                      space = "5", Battery_life = "5", "High_resolution_monitor = "yes"), Model_year
                      = "2021", User_rating = "5", Color = "Black")
                    </span>
                  </span>
                </div>
                <br />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '1rem',
                  }}
                >
                  <span>Settings:</span>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '1rem',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (gptVersion === 'gpt-35') {
                        setGptVersion('gpt-4')
                      } else {
                        setGptVersion('gpt-35')
                      }
                    }}
                  >
                    <span>GPT 3.5</span>
                    {gptVersion === 'gpt-35' && (
                      <span
                        style={{ fontSize: '35px', color: 'rgb(176, 173, 173)' }}
                        class="material-symbols-outlined"
                      >
                        toggle_off
                      </span>
                    )}

                    {gptVersion === 'gpt-4' && (
                      <span
                        style={{ fontSize: '35px', color: '#4472c4' }}
                        class="material-symbols-outlined"
                      >
                        toggle_on
                      </span>
                    )}
                    <span>GPT 4.0</span>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <div
                  className="sleek-button sleek-full-grey"
                  style={{
                    width: '100%',
                    padding: 'unset',
                    height: 'unset',
                    borderRadius: '5px',
                  }}
                  onClick={() => {
                    if (window.confirm('Are you sure?')) {
                      //update timer for question1
                      dispatch({
                        type: 'SAVE_QUESTION_TIMER',
                        questionNumber: 1,
                        time: seconds,
                      })
                      dispatch({
                        type: 'SAVE_LAST_PAGE_VISITED',
                        lastPageVisited: 2,
                      })
                      handleNext()
                    }
                  }}
                >
                  {' '}
                  For facilitator user only{' '}
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <span>Write your prompt here:</span>
                <textarea
                  rows="21"
                  id="textInput"
                  name="textInput"
                  value={textInput}
                  onChange={(e) => {
                    textInputChangeHandler(e)
                  }}
                  style={{
                    resize: 'none',
                    borderRadius: '10px',
                    backgroundColor: 'white',
                    fontSize: '14px',
                    width: '20rem',
                  }}
                />
                <div
                  className="sleek-button sleek-full-blue"
                  style={{ width: '100%', padding: 'unset', height: 'unset', borderRadius: '5px' }}
                  onClick={() => {
                    if (isLoading === false) {
                      if (textInput != null && textInput.trim() !== '') {
                        submitAnswer(3, gptVersion, textInput, setResultPasses, setResultText)
                      }
                    }
                  }}
                >
                  Execute My Prompt{' '}
                  {isLoading === true ? (
                    <div
                      style={{ width: '0.5rem', height: '0.5rem', margin: '0' }}
                      className="loading-spinner"
                    ></div>
                  ) : null}
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <span>Result:</span>
                <textarea
                  rows="10"
                  id="resultText"
                  name="resultText"
                  value={resultText}
                  onChange={(e) => {
                    resultTextChangeHandler(e)
                  }}
                  style={{
                    resize: 'none',
                    borderRadius: '10px',
                    backgroundColor: 'white',
                    fontSize: '14px',
                    width: '20rem',
                  }}
                />
                <b>Expected Answer:</b>

                <span style={{ width: '20rem' }}>
                  Maria, your perfect laptop is the Apple laptop because it meets all your
                  requirements of being a 2021 model with a 4 star or higher review and at least 16
                  GB of RAM. Additionally, it has a high-resolution monitor and its pink color
                  ensures it stands out and isn't boring looking.
                </span>
                {resultPasses !== null && (
                  <div
                    className="sleek-button sleek-full-grey"
                    style={{
                      width: '100%',
                      padding: 'unset',
                      height: 'unset',
                      borderRadius: '5px',
                    }}
                    onClick={() => {
                      setIsFacilitatorApprovalModalOpen(true)
                    }}
                  >
                    {' '}
                    Complete{' '}
                  </div>
                )}
                {resultPasses === false && (
                  <div
                    className="sleek-button sleek-full-red"
                    style={{
                      width: '100%',
                      padding: 'unset',
                      height: 'unset',
                      borderRadius: '5px',
                    }}
                  >
                    {' '}
                    Wrong answer, try again{' '}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AIExerciseQuestion3

import { useState, React } from 'react'
import Modal from '../Modal'
import { colors } from '../../utils/colors'
import InPageLoader from '../InPageLoader'
import AudioRecorder from '../AudioRecorder'
import { useTranslation } from 'react-i18next'
import useAddMultipleMeetingTopicsUsingAudio from '../../api/mutation/addMultipleItemsUsingAudio'

const MultiAddUsingAudioModal = ({
  isModalOpen,
  closeModal,
  type,
  setGeneratedText,
  startRecording,
}) => {
  const { t } = useTranslation(['Common'])

  const [isLoading, setIsLoading] = useState(false)
  const [displayError, setDisplayError] = useState(false)

  const mutationAddMultipleMeetingTopicsUsingAudio = useAddMultipleMeetingTopicsUsingAudio()

  const generateItems = async (audio) => {
    setIsLoading(true)

    await mutationAddMultipleMeetingTopicsUsingAudio.mutateAsync(
      { audioFile: audio, callType: type },
      {
        onSuccess: (response) => {
          doSuccessSteps(response)
        },
        onError: (e) => {
          doFailureSteps()
        },
      },
    )
  }

  const doSuccessSteps = (response) => {
    setGeneratedText(response)
    setIsLoading(false)
    closeModal()
  }

  const doFailureSteps = () => {
    setDisplayError(true)
    setIsLoading(false)
  }

  return (
    <>
      {isModalOpen == true && (
        <Modal closeModal={closeModal} width={'60vw'}>
          {isLoading ? (
            <InPageLoader displayErrorText={true} />
          ) : (
            <div className="modal-gray-box">
              <div className="modal-inner-content">
                <div className="modal-inner-title">
                  {type === 'meetingHubCommentsActions'
                    ? t('multiAddTextModal.addCommentsAndActions')
                    : t('multiAddTextModal.addMeetingTopics')}
                </div>
                <div style={{ textAlign: 'left' }}>
                  <span>
                    {type === 'meetingHubCommentsActions'
                      ? t('multiAddTextModal.commentsActionsTitle')
                      : t('multiAddTextModal.topicsTitle')}
                  </span>
                </div>
                <div className="modal-dual-btn" style={{ gap: '1rem' }}>
                  <div
                    className="sleek-button sleek-blue"
                    style={{ cursor: 'pointer', height: '1.5rem' }}
                    onClick={() => {
                      closeModal()
                    }}
                  >
                    {t('commonButtons.cancelLowercase')}
                  </div>
                  <AudioRecorder
                    handleSubmit={generateItems}
                    isNewDesign={true}
                    start={startRecording}
                  />
                </div>
                {displayError && (
                  <span style={{ color: 'red' }}>
                    {t('Common:modalMessages.somethingWentWrongPleaseTryAgain')}
                  </span>
                )}
              </div>
            </div>
          )}
        </Modal>
      )}
    </>
  )
}

export default MultiAddUsingAudioModal

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getPendingActionData = async () => {
  const { data } = await axios.get(`${BASE_URL}/get_pending_action_data`)
  return data
}

export default function usePendingActionData() {
  return useQuery(['pending-action'], () => getPendingActionData(), {
    refetchOnWindowFocus: false,
  })
}

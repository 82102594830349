import React, { useState } from 'react'
import { postHttpRequest } from '../../../../api/query/dynamicAPI'

// import { UPDATE_TASK_COMMENT } from '../../../../api/constant/route'

import './style.scss'

const AddComment = ({ setCommentList, taskId }) => {
  const [comment, setComment] = useState('')
  const [error, setError] = useState('')

  const onSubmitComment = () => {
    if (comment === '') return setError('Message Required !')
    setError('')

    const obj = {
      comment,
      id: '12',
      user: 'AZ',
      taskId,
    }

    setCommentList((oldArray) => [...oldArray, obj])
    setComment('')

    /* if (taskId) {
      postHttpRequest(UPDATE_TASK_COMMENT, obj)
        .then((response) => {
          if (response) {
            return
          } else {
          }
        })
        .catch((error) => {})
    } */
  }

  function inputChangedHandler(event) {
    // Get name of changed input, and its corresponding value
    const { name, value } = event.target
    if (name === 'comment') setComment(value)
  }

  return (
    <>
      <div className="form-field form-field action-field--kanban">
        <label>Comments</label>
        <input
          type="text"
          id="comment"
          name="comment"
          value={comment}
          onChange={inputChangedHandler}
          className="custom-input"
        />
        {error ? <span className="error error-add-commnet">{error}</span> : null}
      </div>
      <div className="add-comment-action">
        <button className="add-btn-comment" type="button" onClick={() => onSubmitComment()}>
          Add
        </button>
      </div>
    </>
  )
}
export default AddComment

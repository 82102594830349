import React, { useState, useEffect, useRef } from 'react'
import {
  getTeamMembers,
  getEmployeeMainObjective,
} from '../../../api/services/objectives.services.jsx'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { capacitySubRoutes, routes } from '../../../utils/routes.js'
import InPageLoader from '../../../components/InPageLoader/index.jsx'

const TeamsMembers = () => {
  const { t } = useTranslation(['Dashboard', 'Common'])
  const [teamsMembers, setTeamsMembers] = useState([])
  const [objIcMappings, setObjIcMappings] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    getTopic()
    getMainObjectives()
  }, [])

  async function getTopic() {
    setIsLoading(true)
    const result = await getTeamMembers()
    setIsLoading(false)
    if (result && result.employees?.length > 0) {
      setTeamsMembers(result.employees)
    }
  }

  async function getMainObjectives() {
    setIsLoading(true)
    const result = await getEmployeeMainObjective(true)
    setIsLoading(false)
    if (result && result.objIcMappings.length > 0) {
      setObjIcMappings(result.objIcMappings)
    }
  }

  function returnDataList(eId) {
    const filteredObjIcMappings = objIcMappings.filter((obj) => obj.eId === eId)

    return filteredObjIcMappings.length === 0 ? (
      <p>--</p>
    ) : (
      filteredObjIcMappings.map((obj, index) => (
        <p key={index} className="table_comments">
          {obj?.objDesc}
        </p>
      ))
    )
  }

  function calculateAlloc(eId) {
    const filteredObjIcMappings = objIcMappings.filter((obj) => obj.eId === eId)
    const totalAlloc = filteredObjIcMappings.reduce((accumulator, obj) => {
      return accumulator + (obj?.allocation || 0)
    }, 0)
    return totalAlloc
  }

  return (
    <>
      <div className="card">
        <div className="card_header">
          <h2 className="card_title">{t('teamMembersCard.title')}</h2>
          <span
            className="view_all"
            onClick={() =>
              navigate(`/${routes.capacity}/${capacitySubRoutes.objectivesTeamMapping}`, {
                state: { isObjectivesTab: true },
              })
            }
          >
            {t('teamMembersCard.editCapacity')}
          </span>
        </div>
        <div className="Members_card_body">
          {isLoading ? (
            <InPageLoader />
          ) : (
            <table>
              <thead>
                <tr>
                  <th style={{ width: '10%' }}>{t('teamMembersCard.name')}</th>
                  <th style={{ width: '10%' }}>{t('teamMembersCard.allocated')}</th>
                  <th style={{ width: '70%' }}>{t('teamMembersCard.mainObjectives')}</th>
                  <th style={{ width: '10%', textAlign: 'center' }}></th>
                </tr>
              </thead>
              <tbody>
                {teamsMembers.map((data, index) => {
                  return (
                    <tr>
                      <th>{data.name}</th>
                      <td>{calculateAlloc(data.eId)}%</td>
                      <td className="comments">{returnDataList(data.eId)}</td>
                      <td>
                        {data?.level && data?.level == 3 ? (
                          <button
                            type="button"
                            className="fitted-button blue"
                            onClick={() =>
                              navigate(
                                `/${routes.capacity}/${capacitySubRoutes.objectivesTeamMapping}`,
                                { state: { isObjectivesTab: true, teamLeaderId: data?.eId } },
                              )
                            }
                          >
                            {' '}
                            <span className="material-symbols-outlined icon-size">edit </span>
                          </button>
                        ) : null}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  )
}
export default TeamsMembers

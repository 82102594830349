import React from 'react'
import { useTranslation } from 'react-i18next'

const SubmitDemandIntakeWizard = ({ currentStep, setErrorMessage, handleSubmit }) => {
  const { t } = useTranslation(['Common'])
  return (
    <button
      className="dual-next-btn dual-btn"
      onClick={() => {
        handleSubmit()
      }}
    >
      <span>{t('commonButtons.complete')}</span>
    </button>
  )
}

export default SubmitDemandIntakeWizard

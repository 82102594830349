import React from 'react'
import ObjectiveForm from './ObjectiveForm'
import Modal from '../Modal'
import { useTranslation } from 'react-i18next'

const AddEditObjectiveModal = ({
  objId,
  closeModal,
  isAddEditObjectiveModalOpen,
  setEditedObj,
  mode,
  objData,
  setObjData,
  loadAgain,
  isFromObjectiveMap,
}) => {
  const { t } = useTranslation(['Dashboard'])

  return (
    <>
      {isAddEditObjectiveModalOpen === true && (
        <Modal
          title={
            mode === 'add'
              ? t('addObjectivesModal.addObjective')
              : t('addObjectivesModal.editObjective')
          }
          closeModal={() => {
            closeModal()
          }}
          width={'30rem'}
        >
          <ObjectiveForm
            objId={mode === 'edit' ? objId : null}
            isAddEditObjectiveModalOpen={isAddEditObjectiveModalOpen}
            closeModal={closeModal}
            action={mode}
            setEditedObj={setEditedObj}
            objData={objData}
            setObjData={setObjData}
            loadAgain={loadAgain}
            isFromObjectiveMap={isFromObjectiveMap}
          ></ObjectiveForm>
        </Modal>
      )}
    </>
  )
}
export default AddEditObjectiveModal

import React from 'react'
import { useTranslation } from 'react-i18next'

const OldTagInput = ({ existingTags = [], setTagsExternally = function () {} }) => {
  const { t } = useTranslation(['Common'])
  function compare(a, b) {
    if (a.tagName < b.tagName) {
      return -1
    }
    if (a.tagName > b.tagName) {
      return 1
    }
    return 0
  }

  const [input, setInput] = React.useState('')
  const sortedExistingTags = existingTags.sort(compare)
  const [tags, setTags] = React.useState(sortedExistingTags)
  const [errorMessage, setErrorMessage] = React.useState(null)

  const addTag = () => {
    if (input === '' || !input.replace(/\s/g, '').length) {
      setErrorMessage(t('formError.addValidTag'))
      return
    }

    for (let i = 0; i < tags.length; i++) {
      if (tags[i].tagName.toUpperCase() === input.toUpperCase().trim()) {
        setErrorMessage(t('formError.tagAlreadyExists'))
        return
      }
    }

    setTags([...tags, { tagName: input.trim() }])
    setInput('')
  }
  const deleteTag = (index) => {
    let newTags = JSON.parse(JSON.stringify(tags))
    if (newTags[index]['tagId']) {
      //if tag is comming from backend mark as deleted
      newTags[index]['isDeleted'] = true
    } else {
      //if it tag is not coming from database delete completely
      newTags = JSON.parse(JSON.stringify(tags)).filter((tag, tagIndex) => tagIndex !== index)
    }
    setTags([...newTags])
  }

  React.useEffect(() => {
    setTagsExternally([...tags])
  }, [tags])

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="form-field action-field">
            <input
              id="tagInput"
              name="tagInput"
              autoComplete="off"
              type="text"
              value={input}
              onInput={(e) => {
                setInput(e.target.value)
                setErrorMessage(null)
              }}
              placeholder={t('formPlaceHolder.addTags')}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  addTag()
                }
              }}
            />
          </div>
          <button
            onClick={(e) => {
              addTag()
            }}
            style={{
              width: '2rem',
              height: '2rem',
              backgroundColor: 'rgb(237, 82, 46)',
              border: 'none',
              borderRadius: '2.5px',
              cursor: 'pointer',
              fontSize: '14px',
              color: '#fff',
              alignSelf: 'center',
              marginBottom: '1rem',
              marginLeft: '1rem',
            }}
            type="button"
          >
            <b>+</b>
          </button>
        </div>
        {errorMessage && <div style={{ color: 'red', textAlign: 'start' }}> {errorMessage} </div>}
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {tags?.map((tag, index) => (
            <>
              {tag.isDeleted !== true && (
                <div
                  style={{
                    padding: '0.3rem',
                    margin: '0.5rem',
                    backgroundColor: '#A2AAB5',
                    display: 'flex',
                    flexDirection: 'row',
                    borderRadius: '2.5px',
                  }}
                >
                  {tag.tagName}
                  <span
                    class="modal-close material-symbols-outlined"
                    style={{
                      cursor: 'pointer',
                      width: '1rem',
                      height: '1rem',
                      marginLeft: '0.5rem',
                    }}
                    onClick={() => {
                      deleteTag(index)
                    }}
                  >
                    close
                  </span>
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </>
  )
}

export default OldTagInput

import React, { useState, useEffect } from 'react'
import { useDemandIntakeContext } from '../../context/demandIntakeContext'
import ObjTeamMappingTable from '../../../../components/AllocSubWizard/ObjTeamMappingTable'
import AddTeamMemberModal from '../../../../components/AllocSubWizard/AddTeamMemberModal'
import useObjectiveData from '../../../../api/query/getObjectiveData'
import useEmpObjMapping from '../../../../api/query/useEmpObjMapping'
import InPageLoader from '../../../../components/InPageLoader'
import { useTranslation } from 'react-i18next'

const DemandIntakeObjectivesTeamMapping = () => {
  const { t } = useTranslation(['Common'])
  const { state, dispatch } = useDemandIntakeContext()
  const [objectiveInputKey, setObjectiveInputKey] = React.useState(0)
  const {
    data: objectiveData,
    isLoading: objectiveDataIsLoading,
    error: objectiveError,
    isFetching: objectiveDataIsFetching,
  } = useObjectiveData()

  const {
    data: empObjMappingData,
    isLoading: isEmpObjMappingLoading,
    error: empObjMappingError,
    isFetching: isEmpObjMappingFetching,
  } = useEmpObjMapping()

  React.useEffect(() => {
    if (
      objectiveData === null ||
      objectiveData === undefined ||
      empObjMappingData == null ||
      empObjMappingData == undefined
    ) {
      return
    }

    // Explicitly update the textboxes because they use defaultValue and that is not updated on
    // state change. However, refreshing the key for the <tr> causes a rerender.
    setObjectiveInputKey(objectiveInputKey + 1)

    let objectives = [...objectiveData.objectives]

    for (let { eId, objId, allocation } of empObjMappingData.objIcMappings) {
      for (let obj of objectives) {
        if (parseInt(obj.id) === objId) {
          const teamEntry = {
            eId: eId,
            value: allocation,
            name: state.data.employees.filter((emp) => emp.eId === eId)[0].name,
          }

          if (obj.team) {
            obj.team.push(teamEntry)
          } else {
            obj.team = [teamEntry]
          }
        }
      }
    }

    dispatch({ type: 'UPDATE_OBJECTIVES', objectives: objectives })
  }, [objectiveData, empObjMappingData])

  if (isEmpObjMappingFetching || objectiveDataIsFetching) {
    return <InPageLoader />
  }

  return (
    <>
      {state.isTeamMemberFormOpen === true ? (
        <AddTeamMemberModal contextHandler={useDemandIntakeContext} />
      ) : null}

      <div className="wizard-step">
        <h2 className="wizard-step__title">{t('capacityAllocation.capacityObjectiveTitle')}</h2>
        <div className="wizard-step__overview">
          <div className="wizard-step__overview__paragraph">
            {t('capacityAllocation.capacityObjectiveSubtitle')}
          </div>
        </div>

        <div className="wizard-step__main-content-container">
          <div className="objectivesTeamMapping">
            <ObjTeamMappingTable contextHandler={useDemandIntakeContext} />
          </div>
        </div>
      </div>
    </>
  )
}

export default DemandIntakeObjectivesTeamMapping

import React from 'react'
import { defaultState, ExcoExerciseProvider } from './context/ExcoExerciseContext'
import AIExerciseWizard from './components/AIExerciseWizard'
import useExcoExerciseData from './hooks/useExcoExerciseData'
import Loading from '../../components/Loading'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ExcoExerciseWizardPage = () => {
  const location = useLocation()

  const { data, isLoading, error } = useExcoExerciseData()

  let localStorageState = localStorage.getItem('AIWorkshopExerciseState')
  let tempState = localStorageState ? JSON.parse(localStorageState) : null
  const existingStep = tempState?.lastPageVisited ? tempState?.lastPageVisited : 0
  const { t } = useTranslation(['UpdateWizard'])

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return 'Error'
  }

  const initialState = {
    ...defaultState,
    data,
    locationState: location.state,
  }

  return (
    <ExcoExerciseProvider initialState={initialState}>
      <div
        className="wizard-container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AIExerciseWizard existingStep={existingStep} />
      </div>
    </ExcoExerciseProvider>
  )
}

export default ExcoExerciseWizardPage

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '../Modal'
import Textarea from 'rc-textarea'
import ModalSaveButton from '../ModalSubmitButton'
import { MentionsInput, Mention } from 'react-mentions'

const UpdateTextCommentModal = ({
  closeModal,
  updateComment,
  originalCommentText,
  isLoading,
  userList,
  commentId,
  mentionState = [],
  setMentionState = () => {},
}) => {
  const { t } = useTranslation(['Common'])
  const [commentText, setCommentText] = React.useState('')

  const changeText = (text, index) => {
    setCommentText(text)
  }

  React.useEffect(() => {
    if (originalCommentText) {
      setCommentText(originalCommentText)
    }
  }, [originalCommentText])

  return (
    <>
      <Modal
        isModalOpen={true}
        closeModal={() => {
          closeModal()
        }}
        width={'50%'}
        style={{ padding: '0rem 0rem 1rem 0rem' }}
        title={t('Common:commonButtons.update')}
      >
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '0.4rem',
              justifyContent: '1rem',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <MentionsInput
              // className='body_bottom_box'
              value={commentText}
              onChange={(e, v, nv, mentions) => {
                // setMentionsList(mentions)
                let mention = mentionState?.find((mention) => mention.id === commentId)
                if (mention) {
                  setMentionState((prev) =>
                    prev.map((item) =>
                      item.id === commentId ? { ...item, mentions: mentions } : item,
                    ),
                  )
                } else {
                  setMentionState([...mentionState, { id: commentId, mentions: mentions }])
                }
                changeText(e.target.value)
              }}
              allowSuggestionsAboveCursor={true}
              style={{
                width: '100%',
                minHeight: '5rem',
                border: '0.5px solid gray',
                borderRadius: '10px',
                height: '100%',
                backgroundColor: 'rgba(238, 238, 238, 0.2)',
                padding: '2px',
                suggestions: {
                  top: '30px',
                  right: '50px',
                  list: {
                    maxHeight: 80,
                    overflowY: 'auto',
                  },
                  item: {
                    '&focused': {
                      backgroundColor: '#d3d3d3',
                    },
                  },
                },
                input: {
                  overflow: 'auto',
                },
                highlighter: {
                  boxSizing: 'border-box',
                  overflow: 'hidden',
                  height: 70,
                  border: '4px solid transparent',
                },
              }}
              spellCheck={false}
            >
              <Mention
                trigger="@"
                data={userList}
                // renderSuggestion={this.renderUserSuggestion}
                markup="@[__display__](user:__id__)"
                displayTransform={(id, display) => `@${display}`}
                renderSuggestion={(suggestion, search, highlightedDisplay) => (
                  <div className="user">{highlightedDisplay}</div>
                )}
                appendSpaceOnAdd={true}
                // style={{backgroundColor:'rgb(206, 228, 229)'}}
              />
            </MentionsInput>
          </div>
          <div
            className="modal-dual-btn"
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '1rem',
            }}
          >
            <button
              className="fitted-button modal-save-button blue"
              onClick={() => {
                closeModal()
              }}
              type="button"
            >
              {t('commonButtons.cancel')}
            </button>

            <ModalSaveButton
              onClick={() => {
                updateComment(commentText)
              }}
              isLoading={isLoading}
            />
          </div>
        </>
      </Modal>
    </>
  )
}

export default UpdateTextCommentModal

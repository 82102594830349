import React from 'react'
import kpiReducer from './kpiReducer'

export const defaultState = {
  obj: null,
  answers: {
    teamOverview: null,
    timeframe: null,
    longtermAnswer: null,
    shorttermAnswer: null,
  },
  suggestedKpisToSave: null,
  suggestedKpisToSaveWithError: null,
}

const KPIContext = React.createContext({
  state: defaultState,
  dispatch: () => {},
})

export const KPIProvider = ({ children, initialState }) => {
  const [state, dispatch] = React.useReducer(kpiReducer, initialState)

  const context = {
    state,
    dispatch,
  }

  return <KPIContext.Provider value={context}>{children}</KPIContext.Provider>
}

const useKPIContext = () => {
  const context = React.useContext(KPIContext)
  if (!context) {
    throw new Error(
      'useKPIContext must be used within a KPIProvider. Wrap a parent component in <KPIProvider> to fix this error',
    )
  }

  return context
}

export { useKPIContext }

import { useMutation } from '@tanstack/react-query'
import { type } from '@testing-library/user-event/dist/type'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const addMultipleItemsUsingAudio = async (inputData) => {
  const formData = new FormData()
  formData.append('audio', inputData.audioFile, 'audio.wav')

  let url = ''
  if (inputData.callType === 'meetingTopic') {
    url = `/generate_multiple_meeting_topics_using_audio`
  } else if (inputData.callType === 'meetingHubCommentsActions') {
    url = `/generate_multiple_comments_decisions_actions_using_audio`
  } else if (inputData.callType === 'text') {
    url = `/convert_audio_to_text`
  } else if (inputData.callType === 'text') {
    url = `/convert_audio_to_text`
  }

  const { data } = await axios.post(`${BASE_URL}${url}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return data
}

export default function useAddMultipleItemsUsingAudio() {
  return useMutation((data) => addMultipleItemsUsingAudio(data), {
    refetchOnWindowFocus: false,
    retry: 0,
    onSuccess: (response) => {
      return response
    },
    onError: (response) => {
      return response
    },
  })
}

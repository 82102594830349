import { useState, React } from 'react'
import Modal from '../Modal'
import { colors } from '../../utils/colors'
import InPageLoader from '../InPageLoader'
// import AudioRecorder from '../AudioRecorder'
import { useTranslation } from 'react-i18next'
import useAddMultipleMeetingTopicsUsingAudio from '../../api/mutation/addMultipleItemsUsingAudio'
import Toast from '../../common/toast'
import AudioRecorderV2 from '../AudioRecorderV2'
import { addDotAfterSentence } from '../../utils/general'

const AudioToTextModal = ({
  isModalOpen,
  closeModal,
  startRecording,
  setAudioText = () => {},
  displayText = '',
  answer = '',
}) => {
  const { t } = useTranslation(['Common'])

  const [isLoading, setIsLoading] = useState(false)
  const [displayError, setDisplayError] = useState(false)

  const mutationAddMultipleMeetingTopicsUsingAudio = useAddMultipleMeetingTopicsUsingAudio()

  const generateItems = async (audio) => {
    setIsLoading(true)
    try {
      await mutationAddMultipleMeetingTopicsUsingAudio.mutateAsync(
        { audioFile: audio, callType: 'text' },
        {
          onSuccess: (response) => {
            doSuccessSteps(response)
          },
          onError: (e) => {
            doFailureSteps()
          },
        },
      )
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongPleaseTryAgain'),
      })
    }
  }

  const doSuccessSteps = (response) => {
    setAudioText((answer != null ? answer : '') + addDotAfterSentence(answer) + response ?? '')
    setIsLoading(false)
    closeModal()
  }

  const doFailureSteps = () => {
    setDisplayError(true)
    setIsLoading(false)
  }

  return (
    <>
      <Modal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        width={'25vw'}
        modalContainerStyle={{ width: '99%', padding: '0px' }}
      >
        {isLoading ? (
          <InPageLoader displayErrorText={true} />
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              wordBreak: 'break-word',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {/* <div style={{ textAlign: "left", marginBottom:'1rem' }}>
                            <span>
                                {displayText}
                            </span>
                        </div> */}
            {/* <div className='modal-gray-box'> */}
            {/* <div className='modal-inner-content'> */}

            {/* <div className="modal-dual-btn" style={{ gap: '1rem' }}> */}
            {/* <div
                                        className="sleek-button sleek-blue"
                                        style={{ cursor: 'pointer', height: '1.5rem' }}
                                        onClick={() => {
                                            closeModal()
                                        }}
                                    >
                                        {t('commonButtons.cancelLowercase')}
                                    </div> */}
            <AudioRecorderV2
              handleSubmit={generateItems}
              isNewDesign={false}
              start={startRecording}
              displayText={displayText}
            />

            {/* </div> */}
            {displayError && (
              <span style={{ color: 'red' }}>
                {t('Common:modalMessages.somethingWentWrongPleaseTryAgain')}
              </span>
            )}
            {/* </div> */}
            {/* </div> */}
          </div>
        )}
      </Modal>
    </>
  )
}

export default AudioToTextModal

import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const uwManageUpdate = async (updateData) => {
  const { data } = await axios.post(`${BASE_URL}/uw_manage_update`, updateData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export default function useUwManageUpdate() {
  const queryClient = useQueryClient()

  return useMutation((data) => uwManageUpdate(data), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      queryClient.invalidateQueries('uw_meeting_topics')
    },
  })
}

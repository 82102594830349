import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const submitTeamLeaderWizard = async (teamLeaderData) => {
  const { data } = await axios.post(`${BASE_URL}/team_leader_wizard_answer_v2`, teamLeaderData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export default function useSubmitTeamLeaderWizard() {
  return useMutation((data) => submitTeamLeaderWizard(data))
}

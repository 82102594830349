import React from 'react'
import { getTeamLeaderList } from '../../../../api/services/employee.services'
import InPageLoader from '../../../../components/InPageLoader'
import { getHttpRequest, postHttpRequest } from '../../../../api/query/dynamicAPI'
import Toast from '../../../../common/toast'
import ModalSaveButton from '../../../../components/ModalSubmitButton'

const LogMei = () => {
  const [cxoLeaderList, setCxoLeaderList] = React.useState([])

  const [isLoading, setIsLoading] = React.useState(false)

  const [isSaveLoading, setIsSaveLoading] = React.useState(false)

  const [vacations, setVacations] = React.useState([])

  const [dateVal, setDateVal] = React.useState('')

  React.useEffect(() => {
    setIsLoading(true)
    getLeaderList()
    getCompanyVacations()
    setIsLoading(false)
  }, [])

  const getMeiAndSave = async () => {
    try {
      let postBody = {
        cxos: [],
      }
      setIsSaveLoading(true)
      for (let cxo of cxoLeaderList) {
        let response = await getHttpRequest(`/get_mei_data/${cxo.eId}`)
        cxo.mei = response
        postBody.cxos.push(cxo)
      }
      let response = await postHttpRequest('/save_mei_data', postBody)
      if (response.success) {
        Toast.fire({
          icon: 'success',
          title: 'Data saved successfully',
        })
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Something went wrong',
        })
      }
      setIsSaveLoading(false)
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }

  const saveVacationDate = async () => {
    try {
      if (!dateVal) {
        Toast.fire({
          icon: 'error',
          title: 'Please select a date',
        })
        return
      }
      let response = await postHttpRequest('/save_company_vacation', {
        date: dateVal,
      })
      if (response.success === false) {
        Toast.fire({
          icon: 'error',
          title: 'Duplicate Vacation Date',
        })
      } else {
        Toast.fire({
          icon: 'success',
          title: 'Skip Week Saved',
        })
        getCompanyVacations()
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }

  const getCompanyVacations = async () => {
    try {
      let response = await getHttpRequest('/get_all_company_vacations')
      setVacations(response?.data)
    } catch (error) {
      console.log(error)
    }
  }

  const getLeaderList = async () => {
    const result = await getTeamLeaderList('?level=1')
    if (result && result?.employees) setCxoLeaderList(result?.employees)
  }

  if (isLoading) {
    return <InPageLoader />
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
        <span style={{ fontSize: '20px' }}>Log MEI Data For CXOs</span>
        <div style={{ marginTop: '0.3rem', margin: '0 auto' }}>
          <ModalSaveButton onClick={getMeiAndSave} isLoading={isSaveLoading} />
        </div>
      </div>
      <div style={{ marginTop: '2rem' }}>
        {cxoLeaderList &&
          cxoLeaderList.map((cxo, index) => (
            <div key={index} style={{ fontSize: '15px', fontWeight: 'bold' }}>
              {cxo.name}
            </div>
          ))}
      </div>

      <div style={{ marginTop: '5rem' }}>
        <div className="form-field">
          <label>Select Company Vacations (MUST BE A MONDAY)</label>
          <input
            name="dueDateFilter"
            type="date"
            onChange={(e) => setDateVal(e.target.value)}
          ></input>
          <div style={{ marginTop: '1rem' }}>
            <ModalSaveButton onClick={saveVacationDate} isLoading={isSaveLoading} />
          </div>
        </div>

        <span style={{ fontSize: '20px' }}>Skipped Weeks</span>
        <ul>
          {vacations.sort().map((vacation, index) => (
            <li key={index}>{vacation}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default LogMei

import React, { useState, useEffect } from 'react'
import './styles.css'
import { useTeamLeaderContext } from '../../context/teamLeaderContext'
import CapacityConfirmModal from './../../../../components/CapacityConfirmModal'
import { useNavigate, useOutletContext } from 'react-router-dom'

// function objDurationToFloat(durationStr) {
//   if (durationStr === 'half_cycle') return 0.5
//   if (durationStr === 'quarter_cycle') return 0.25

//   return 1.0
// }

const CapacityStep = () => {
  const { state, dispatch } = useTeamLeaderContext()

  const [totalTeamAvailableFTEs, setTotalTeamAvailableFTEs] = React.useState(0)
  const [totalObjRequiredFTEs, setTotalObjRequiredFTEs] = React.useState(0)

  const { setErrorMessage, handleNext, handlePrevious } = useOutletContext()

  const [disableDurationObjective, setDurationAreaObjective] = useState(
    new Array(state.data.objectives.length).fill(false),
  )

  React.useEffect(() => {
    const result = state.data.employees.reduce((accumulator, employee) => {
      return accumulator + employee.teamAllocPct
    }, 0)

    setTotalTeamAvailableFTEs(result / 100)
    dispatch({ type: 'UPDATE_TOTAL_TEAM_AVAILABLE_FTES', totalTeamAvailableFTEs: result / 100 })
  }, [state.data.employees])

  React.useEffect(() => {
    const totalRequired = state.data.objectives.reduce((accumulator, objective) => {
      return accumulator + objective.requiredFte
    }, 0)
    setTotalObjRequiredFTEs(totalRequired)
    dispatch({ type: 'UPDATE_TOTAL_OBJ_REQUIRED_FTES', totalObjRequiredFTEs: totalRequired })
  }, [state.data.objectives])

  React.useEffect(() => {
    dispatch({
      type: 'UPDATE_CAPACITY_CHECK',
      isOverCapacity: totalObjRequiredFTEs > totalTeamAvailableFTEs,
    })
  }, [totalObjRequiredFTEs, totalTeamAvailableFTEs, dispatch])

  // const handleCycleUpdate = (cycle, objectiveIndex) => {
  //   if (state.data.objectives[objectiveIndex]) {
  //     if (cycle === 'none') {
  //       cycle = null
  //     }
  //     const objectiveToUpdate = { ...state.data.objectives[objectiveIndex], cycle }

  //     dispatch({ type: 'UPDATE_OBJECTIVE', objective: objectiveToUpdate, objectiveIndex })
  //   }
  // }

  const handleDurationWeeksUpdate = (durationWeeks, objectiveIndex) => {
    if (state.data.objectives[objectiveIndex]) {
      // if (durationWeeks.length === 0) {
      //   durationWeeks = 0
      // }
      durationWeeks = parseInt(durationWeeks)
      const objectiveToUpdate = { ...state.data.objectives[objectiveIndex], durationWeeks }

      dispatch({ type: 'UPDATE_OBJECTIVE', objective: objectiveToUpdate, objectiveIndex })
    }
  }

  const handleRequiredFTEUpdate = (requiredFte, objectiveIndex) => {
    let tempObjectives = [...state.data.objectives]
    for (let i = 0; i < state.data.objectives.length; i++) {
      tempObjectives[i].missingFte = 0
      // Set this to false in case the user resets the alloc, and let the excess cap modal set
      // it again.
      tempObjectives[i].lackingCapacity = false

      if (i === objectiveIndex) {
        tempObjectives[i].requiredFte = requiredFte
        // allocatedFte is being set for now to requiredFte assuming this objective doesn't lack
        // capacity. But in case this objective is lacking capacity, it will be updated via the
        // CapacityConfirmModal submit handler.
        tempObjectives[i].allocatedFte = requiredFte
      }
    }
    dispatch({ type: 'UPDATE_OBJECTIVES', objectives: tempObjectives })
  }

  // const blockBauDropdowns = () => {
  //   const updatedDropdownArray = [...disableDurationObjective]
  //   for (let i = 0; i < state.data.objectives.length; i++) {
  //     if (state.data.objectives[i].type === 3) {
  //       updatedDropdownArray[i] = true
  //       handleCycleUpdate('full_cycle', i)
  //     }
  //   }
  //   setDurationAreaObjective(updatedDropdownArray)
  // }

  useEffect(() => {
    //blockBauDropdowns()
  }, [])

  const handleCloseCapacityConfirmModal = () => {
    dispatch({ type: 'CLOSE_CAPACITY_CONFIRM_MODAL' })
  }

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
  }, [])

  return (
    <>
      <CapacityConfirmModal
        objectives={state.data.objectives}
        isCapacityConfirmModalOpen={state.isCapacityConfirmModalOpen}
        handleClose={handleCloseCapacityConfirmModal}
        handleNext={handleNext}
        totalObjRequiredFTEs={totalObjRequiredFTEs}
        totalTeamAvailableFTEs={totalTeamAvailableFTEs}
        assumeNoAllocatedFte={false}
      />
      <div className="wizard-step">
        <h2 className="wizard-step__title">
          How long is this objective going to take and how much capacity will it need?
        </h2>
        <div className="wizard-step__overview">
          <div className="wizard-step__overview__paragraph">
            <span>Enter 0 if you do not wish to assign any capacity for now.</span>
          </div>
        </div>

        <table className="wizard-step__main-content-container">
          <tbody>
            <tr>
              <th className="goal-cell">
                <span>Objective</span>
              </th>
              <th>
                <span>Estimated Duration (Weeks)</span>
              </th>
              <th>
                <span>Allocation for the upcoming two weeks (in FTE)</span>
              </th>
            </tr>
            {state.data.objectives.map((objective, index) => (
              <tr key={`objective-${index}`}>
                <td className="goal-cell">
                  <span>{objective.statement}</span>
                </td>
                <td>
                  {objective.type !== 3 ? (
                    <input
                      style={{ width: '5vw' }}
                      defaultValue={objective.durationWeeks ? objective.durationWeeks : '1'}
                      name="durationWeeks"
                      type="number"
                      min="1"
                      onChange={(e) => {
                        handleDurationWeeksUpdate(e.target.value, index)
                      }}
                      disabled={disableDurationObjective[index]}
                    />
                  ) : (
                    // <select
                    //   name="duration_obj"
                    //   defaultValue={objective.cycle ? objective.cycle : 'none'}
                    //   onChange={(e) => {
                    //     handleCycleUpdate(e.target.value, index)
                    //   }}
                    //   disabled={disableDurationObjective[index]}
                    // >
                    //   <option value="none">Select estimated duration of the objective </option>
                    //   <option value="exceeds_cycle">Greater than a full cycle (3 months)</option>
                    //   <option value="full_cycle">Full cycle (3 months)</option>
                    //   <option value="half_cycle">Half cycle (1.5 months)</option>
                    //   <option value="quarter_cycle">Quarter cycle (3 weeks)</option>
                    // </select>
                    <div>N/A</div>
                  )}
                </td>
                <td className="capacity-cell">
                  <input
                    className="fte-input"
                    onChange={(e) => {
                      handleRequiredFTEUpdate(parseFloat(e.target.value), index)
                    }}
                    type="number"
                    step="0.1"
                    min="0"
                    defaultValue={objective.requiredFte ? objective.requiredFte : '0'}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default CapacityStep

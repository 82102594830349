import React from 'react'
import demandIntakeReducer from './demandIntakeReducer'

export const defaultState = {
  demandIntakeAction: null,
  demandIntakeActionObj: null,
  newRequiredFte: null,
  newOrEditedObjId: null,
  isObjFormModalOpen: false,
  isCapacityConfirmModalOpen: false,
  suggestedObjective: null,
  objectiveAssociation: null,
  allocatedFTEs: 0,
  isOverCapacity: false,
  linkedObjectiveId: null,
  isTeamMemberFormOpen: false,
  data: {
    objectives: [],
    objectivesCopy: [],
    linkageData: [],
    employees: [],
    nonReportingEmpsRealIndexes: [],
    externalEmpAlocs: {},
  },
  allocSubWiz: {
    updateObjectiveId: null,
    errorTeamCapAllocTable: false,
    triggerSelectAllEmp: false,
  },
}

const DemandIntakeContext = React.createContext({
  state: defaultState,
  dispatch: () => {},
})

export const DemandIntakeProvider = ({ children, initialState }) => {
  const [state, dispatch] = React.useReducer(demandIntakeReducer, initialState)

  const context = {
    state,
    dispatch,
  }

  return <DemandIntakeContext.Provider value={context}>{children}</DemandIntakeContext.Provider>
}

const useDemandIntakeContext = () => {
  const context = React.useContext(DemandIntakeContext)
  if (!context) {
    throw new Error(
      'useDemandIntakeContext must be used within a DemandIntakeProvider. Wrap a parent component in <DemandIntakeProvider> to fix this error',
    )
  }

  return context
}

export { useDemandIntakeContext }

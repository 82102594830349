import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ApplicationLayout from '../../components/ApplicationLayout'
import Loading from '../../components/Loading'
import MyNotebookHeaders from './Headers'
import { useState, useEffect } from 'react'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import '../MeetingView/styles.css'
import { useNavbarContext } from '../../utils/navBarContext'
import Toast from '../../common/toast'
import './styles.scss'

const MyNotebookPage = () => {
  const { t } = useTranslation(['Common'])

  const location = useLocation()
  const { pathname } = location

  const [isLoading, setIsLoading] = React.useState(false)

  return (
    <ApplicationLayout>
      <div
        className="my-notebook-container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
        }}
      >
        <div className="my-notebook">
          <div className="page_title">{t('navigation.myNotebook')}</div>
          <MyNotebookHeaders />
          <div className="my-notebook-view-body" style={{}}>
            <div className={`my-notebook-container`}>
              <Outlet
                context={{
                  isLoading,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </ApplicationLayout>
  )
}

export default MyNotebookPage

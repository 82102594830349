import React from 'react'
import { useTranslation } from 'react-i18next'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import { getConsolidatedConfigSettings } from '../../utils/auth'
import Toast from '../../common/toast'
import MeiModal from '../../pages/Home/components/AdminDashboard/components/TeamDoing/component/MeiModal'
import { getShowTlMei } from '../../utils/auth.js'
import { getCurrentMondayDate } from '../../utils/general'

const NavigationMEI = () => {
  const { t } = useTranslation(['Common'])
  const [metrics, setMetrics] = React.useState(null)
  const [status, setStatus] = React.useState('')
  const [isPrepared, setIsPrepared] = React.useState(false)
  const [isCoordinated, setIsCoordinated] = React.useState(false)
  const [isEnoughActionsClosed, setIsEnoughActionsClosed] = React.useState(false)
  const [kpiStatementTypeState, setKpiStatementTypeState] = React.useState(2)
  const [modalData, setModalData] = React.useState(null)
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [isOnVacation, setIsOnVacation] = React.useState(false)

  let icShowTlMei = getShowTlMei() === 'true'

  React.useEffect(() => {
    let lastFetchMeiTs = localStorage.getItem('lastFetchMeiTs')

    //refetch MEI data api if it has been more than 3 seconds since last fetch or if it has never been fetched on login
    if (
      (lastFetchMeiTs && Date.now() - lastFetchMeiTs > 3000) ||
      lastFetchMeiTs === null ||
      lastFetchMeiTs === undefined
    ) {
      refreshMei()
    } else {
      let lastFetchMeiDataJSON = localStorage.getItem('lastFetchMeiData')
      if (lastFetchMeiDataJSON) {
        let lastFetchMeiData = JSON.parse(lastFetchMeiDataJSON)
        setMetrics(lastFetchMeiData)
        processMetrics(lastFetchMeiData)
      }
    }
  }, [])

  //call this for ALs and Tls
  const refreshMei = async () => {
    try {
      let result = await getHttpRequest('/my_mei')
      setMetrics(result)
      processMetrics(result)
      localStorage.setItem('lastFetchMeiTs', Date.now())
      localStorage.setItem('lastFetchMeiData', JSON.stringify(result))
    } catch (err) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }

  const processMetrics = (metrics) => {
    // let failedPreparedFigure = -1
    // let failedCoordinatedFigure = -1
    // let failedEnoughActionsClosedFigure = -1
    // let failedClosingTimeAcceptableFigure = -1
    // let kpiStatementType = 0
    // let md = {}
    // md.rawData = {}

    const thresholds = {
      BenchmarkActionClosedTime: 28,
      BenchmarkTopicsCounts: metrics?.mei_thresholds?.prepared_target ?? 1,
      BenchmarkCompletionPercentage: metrics?.mei_thresholds?.taskmaster_target ?? 0.4,
      BenchmarkCoordiatedAvg: metrics?.mei_thresholds?.coordinated_target ?? 1,
    }
    // md.thresholds = thresholds
    // // if TL is logged in, length of these arrays should always be 1
    // for (let owner_data of metrics.prepared_metrics) {
    //   let topics_counts_data = owner_data.topics_counts_data
    //   // there is no data at all
    //   if (Object.keys(topics_counts_data).length === 0) {
    //     failedPreparedFigure = 0
    //     md.rawData.topicsAdded = 0
    //     continue
    //   }
    //   let sum = 0.0
    //   // one key represents one type of week [last_week, etc], max is four, min is zero
    //   Object.keys(topics_counts_data).forEach((key) => {
    //     sum += topics_counts_data[key]
    //   })
    //   // average topics over the past four weeks >= 1
    //   if (sum / 4 >= thresholds.BenchmarkTopicsCounts) {
    //     setIsPrepared(true)
    //     md.isPrepared = true
    //   } else {
    //     setIsPrepared(false)
    //     md.isPrepared = false
    //   }
    //   failedPreparedFigure = sum / 4
    //   md.rawData.topicsAdded = sum
    // }

    // for (let owner_data of metrics.coordinated_metrics) {
    //   // for this owner, avg decision/action per blocker/guidance is at least one
    //   if (owner_data.avg >= thresholds.BenchmarkCoordiatedAvg) {
    //     setIsCoordinated(true)
    //     md.isCoordinated = true

    //     // now failedCoordinatedFigure is -1 which then will be ignored in the modal
    //   } else {
    //     setIsCoordinated(false)
    //     md.isCoordinated = false
    //   }
    //   if (owner_data.avg === 999) {
    //     failedCoordinatedFigure = 0
    //   } else {
    //     failedCoordinatedFigure = parseFloat(owner_data.avg).toFixed(1)
    //   }

    //   if(owner_data?.current_future_vacations?.length > 0){
    //     let monday_formatted = getCurrentMondayDate()
    //     if(owner_data.current_future_vacations.includes(monday_formatted)){
    //       setIsOnVacation(true)
    //       md.isOnVacation = true
    //     }
    //     else{
    //       md.isOnVacation = false
    //     }
    //   }
    // }

    // for (let owner_data of metrics.task_master) {
    //   // More than 40% of actions created in last 4 weeks are closed
    //   if (
    //     owner_data.completion_percentage >= thresholds.BenchmarkCompletionPercentage ||
    //     owner_data.num_actions_created_past_four_weeks === 0
    //   ) {
    //     setIsEnoughActionsClosed(true)
    //     md.isEnoughActionsClosed = true
    //   } else {
    //     setIsEnoughActionsClosed(false)
    //     md.isEnoughActionsClosed = false
    //   }
    //   failedEnoughActionsClosedFigure = parseFloat(owner_data.completion_percentage).toFixed(3)
    //   md.rawData.numActionsCreated = owner_data.num_actions_created_past_four_weeks_now
    //   md.rawData.numActionsClosed = owner_data.num_completed
    //   // Average time to close action is <= 4 weeks
    //   // or if no completed actions, we pretend it is good since we don't want to show the message for 0 compleed actions
    //   // if((owner_data.avg_completion_time > 0 && owner_data.avg_completion_time <= thresholds.BenchmarkActionClosedTime) || owner_data.num_completed === 0){
    //   //     setIsClosingTimeAcceptable(true)
    //   // }else{
    //   //     setIsClosingTimeAcceptable(false)
    //   //     failedClosingTimeAcceptableFigure = (parseFloat(owner_data.avg_completion_time).toFixed(1))
    //   // }
    // }
    // // order matters here
    // for (let owner_data of metrics.kpi_updates) {
    //   // show 'no kpis' msg if no objectives or no kpis (exclude completd kpis)
    //   if (owner_data.objectives_num === 0 || owner_data.kpi_num === 0) {
    //     kpiStatementType = 1
    //   } else {
    //     // user has kpis, if there is any overdue kpi
    //     if (owner_data.kpi_num > 0 && owner_data.is_kpi_update_overdue) {
    //       kpiStatementType = 2
    //       // user has kpis, if there is no overdue kpi,
    //     } else if (owner_data.kpi_num > 0 && !owner_data.is_kpi_update_overdue) {
    //       kpiStatementType = 3
    //       // but there is grey kpi
    //       if (owner_data.has_grey_kpis) {
    //         kpiStatementType = 4
    //       }
    //     }
    //   }
    //   setKpiStatementTypeState(kpiStatementType)
    // }
    let all_employees = metrics?.all_employees_status ?? []
    all_employees.forEach((employee) => (employee.thresholds = thresholds))

    // md.failedPreparedFigure = failedPreparedFigure
    // md.failedCoordinatedFigure = failedCoordinatedFigure
    // md.failedEnoughActionsClosedFigure = failedEnoughActionsClosedFigure
    // md.failedClosingTimeAcceptableFigure = failedClosingTimeAcceptableFigure
    // md.kpiStatementType = kpiStatementType

    // md.rawData.qualifiedCounts = metrics.coordinated_metrics[0].qualified_counts
    // md.rawData.numOpenTopics = metrics.coordinated_metrics[0].num_open_topics
    // md.rawData.numActionsCreatedPastFourWeeksNow = metrics.task_master[0].num_actions_created_past_four_weeks_now
    // md.rawData.numCompleted = metrics.task_master[0].num_completed
    // md.rawData.numActionsCreatedPastFourWeeks = metrics.task_master[0].num_actions_created_past_four_weeks
    // md.rawData.overdueKpiCount = metrics.kpi_updates[0].overdue_kpi_count
    if (all_employees.length > 0) {
      setModalData(all_employees[0])
    }
  }

  return (
    <>
      {isModalOpen && modalData && (
        <MeiModal
          data={modalData}
          isModalOpen={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
          fromTLDashboard={true}
          refreshMei={refreshMei}
        />
      )}

      <div>
        <div style={{ marginRight: 'auto', fontSize: '14px', fontWeight: 'normal' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {metrics && modalData && (
              <>
                {modalData?.mei_status?.is_good ? (
                  <>
                    <div
                      className={`rag-letter-box ${
                        modalData?.mei_status?.is_on_vacation ? 'gray-rag-box' : 'green-rag-box'
                      }`}
                      style={{ height: '1.2rem', width: '1.2rem' }}
                    >
                      <span class="icon-size">G</span>
                    </div>
                    &nbsp;&nbsp;
                    <span
                      style={{
                        color: '#4472c4',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      onClick={() => {
                        setIsModalOpen(true)
                      }}
                    >
                      {icShowTlMei ? t('navigation.teamsMEI') : t('navigation.myMEI')}
                    </span>
                  </>
                ) : (
                  <>
                    <div
                      className={`rag-letter-box ${
                        modalData?.mei_status?.is_on_vacation ? 'gray-rag-box' : 'red-rag-box'
                      }`}
                      style={{ height: '1.2rem', width: '1.2rem' }}
                    >
                      <span class="icon-size">R</span>
                    </div>
                    &nbsp;&nbsp;
                    <span
                      style={{
                        color: '#4472c4',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      onClick={() => {
                        setIsModalOpen(true)
                      }}
                    >
                      {icShowTlMei ? t('navigation.teamsMEI') : t('navigation.myMEI')}
                    </span>
                  </>
                )}
              </>
            )}

            {<></>}
          </div>
        </div>
      </div>
    </>
  )
}

export default NavigationMEI

import React from 'react'
import { useTranslation } from 'react-i18next'
import Toast from '../../../common/toast'
import { getHttpRequest, postHttpRequest } from './../../../api/query/dynamicAPI'
import '../styles.scss'
import { dayMonthFilter } from '../../../utils/time'
import SelectTasksModal from '../SelectTasksModal'
import InPageLoader from '../../../components/InPageLoader'
import NotesList from './NotesList'
import NoteEditor from './NoteEditor'
import AudioToTextModal from '../../../components/AudioToTextModal/AudioToTextModal'

const MyNotebookNotes = () => {
  const { t } = useTranslation(['MyNotebook', 'Common'])
  const [isLoading, setIsLoading] = React.useState(false)
  const [notes, setNotes] = React.useState(null)
  const notesRef = React.useRef(notes)

  const [title, setTitle] = React.useState('')
  const titleRef = React.useRef(title)
  const [note, setNote] = React.useState('')
  const noteRef = React.useRef(note)
  const [noteId, setNoteId] = React.useState(null)
  const noteIdRef = React.useRef(noteId)
  const [selectedIndex, setSelectedIndex] = React.useState(null)
  const [isSaving, setIsSaving] = React.useState(false)
  const [iconIsSaving, setIconIsSaving] = React.useState(false)
  const [isConvertModalOpen, setIsConvertModalOpen] = React.useState(false)
  const [isVoiceNoteModalOpen, setIsVoiceNoteModalOpen] = React.useState(false)

  const prevTitleRef = React.useRef(title)
  const prevNoteRef = React.useRef(note)

  React.useEffect(() => {
    getNotes(true)

    const intervalId = setInterval(() => {
      if (
        (titleRef.current && titleRef.current !== prevTitleRef.current) ||
        (noteRef.current && noteRef.current !== prevNoteRef.current)
      ) {
        saveNote()
        prevTitleRef.current = titleRef.current
        prevNoteRef.current = noteRef.current
      }
    }, 2000)

    return () => clearInterval(intervalId)
  }, [])

  React.useEffect(() => {
    titleRef.current = title
  }, [title])

  React.useEffect(() => {
    notesRef.current = notes
  }, [notes])

  React.useEffect(() => {
    noteRef.current = note
  }, [note])

  React.useEffect(() => {
    noteIdRef.current = noteId
  }, [noteId])

  const getNotes = async (isFirstLoad = false) => {
    try {
      setIsLoading(true)
      let response = await getHttpRequest(`/get_my_notebook_notes`)
      if (response.myNotebookNotesList) {
        setNotes(response.myNotebookNotesList)
        if (isFirstLoad) {
          setSelectedIndex(0)
          setTitle(response.myNotebookNotesList[0]?.noteTitle)
          setNoteId(response.myNotebookNotesList[0]?.noteId)
          setNote(response.myNotebookNotesList[0]?.noteDescription)
        }
      }
      setIsLoading(false)
    } catch (error) {
      Toast.fire({
        icon: 'error',
      })
    }
  }

  const saveNote = async () => {
    if (isSaving || (noteRef?.current === undefined && titleRef?.current === undefined)) return
    setIsSaving(true)
    setIconIsSaving(true)

    let postObj = {
      note_id: noteIdRef?.current ?? null,
      note_title: titleRef?.current ?? null,
      note_description: noteRef?.current ?? null,
    }

    postHttpRequest(`/save_my_notebook_note`, postObj)
      .then((response) => {
        if (response.success === true) {
          let tempNotes = notesRef.current
          let found = false
          for (let i = 0; i < tempNotes.length; i++) {
            if (tempNotes[i].noteId === response.note_id) {
              tempNotes[i].noteTitle = response.note_title
              tempNotes[i].noteDescription = response.note_text
              found = true
            }
          }
          if (!found) {
            let newNote = {
              noteId: response.note_id,
              noteTitle: response.note_title,
              noteDescription: response.note_text,
            }
            tempNotes = [newNote, ...tempNotes]
            setNoteId(response.note_id)
          }
          setNotes(tempNotes)
        } else {
          Toast.fire({
            icon: 'error',
          })
        }
        setIsSaving(false)

        setTimeout(() => {
          setIconIsSaving(false)
        }, 2000)
      })
      .catch((error) => {
        setIsSaving(false)
        setTimeout(() => {
          setIconIsSaving(false)
        }, 2000)
      })
  }

  const createNewNote = () => {
    setTitle('')
    setNote('')
    setNoteId(null)
    setSelectedIndex(0)
  }

  const deleteNote = async (noteId) => {
    let postObj = {
      note_id: noteId,
    }

    try {
      const response = await postHttpRequest(`/delete_my_notebook_note`, postObj)

      if (response.success === true) {
        const noteIndex = notes.findIndex((note) => note.noteId === noteId)

        if (noteIndex !== -1) {
          notes.splice(noteIndex, 1)

          if (notes.length === 0) {
            // Handle empty notes array
            setSelectedIndex(null)
            setTitle('')
            setNoteId('')
            setNote('')
          } else {
            // Circular array logic
            const newIndex = noteIndex % notes.length
            setSelectedIndex(newIndex)
            setTitle(notes[newIndex].noteTitle)
            setNoteId(notes[newIndex].noteId)
            setNote(notes[newIndex].noteDescription)
          }
        }
      } else {
        Toast.fire({
          icon: 'error',
        })
      }
    } catch (error) {
      console.error('Error deleting note:', error)
      Toast.fire({
        icon: 'error',
        title: 'Failed to delete note',
      })
    }
  }

  const setAudioText = (newText) => {
    if (note) {
      setNote(note.trim() + ' ' + newText)
    } else {
      setNote(newText)
    }
  }

  return (
    <>
      {isConvertModalOpen && (
        <SelectTasksModal
          noteId={noteId}
          noteDescription={note}
          noteTitle={title}
          closeModal={() => setIsConvertModalOpen(false)}
        />
      )}

      {isVoiceNoteModalOpen && (
        <AudioToTextModal
          isModalOpen={isVoiceNoteModalOpen}
          closeModal={() => setIsVoiceNoteModalOpen(false)}
          startRecording={true}
          setAudioText={setAudioText}
          displayText={t('MyNotebook:listening') + '...'}
        />
      )}
      <div>
        {isLoading && (
          <div
            style={{
              width: '70vw',
              padding: '2rem 0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <InPageLoader isLoading={isLoading} inComponent={true} />
          </div>
        )}
        {!isLoading && (
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '21rem',
                    justifyContent: 'flex-end',
                  }}
                >
                  <div
                    style={{ margin: '0 2.5rem 1rem 0.5rem' }}
                    className="sleek-button sleek-blue"
                    onClick={createNewNote}
                  >
                    <span className="material-symbols-outlined icon-size-l">add </span>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    paddingBottom: '1rem',
                  }}
                >
                  {iconIsSaving === true && (
                    <span className="material-symbols-outlined icon-size-l">cloud_upload</span>
                  )}
                  {iconIsSaving === true && (
                    <span style={{ paddingLeft: '1rem' }}>
                      {t('MyNotebook:saving')} {'...'}
                    </span>
                  )}

                  {iconIsSaving === false && (
                    <span className="material-symbols-outlined icon-size-l">cloud_done </span>
                  )}
                  {iconIsSaving === false && (
                    <span style={{ paddingLeft: '1rem' }}>{t('MyNotebook:saved')}</span>
                  )}
                </div>
              </div>

              <div
                style={{ display: 'flex', flexDirection: 'row' }}
                className="my-notebook-notes-container"
              >
                <NotesList
                  notes={notes}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  setTitle={setTitle}
                  setNoteId={setNoteId}
                  setNote={setNote}
                />
                <NoteEditor
                  title={title}
                  setTitleHandler={(e) => setTitle(e.target.value)}
                  note={note}
                  setNoteHandler={(e) => setNote(e.target.value)}
                  noteId={noteId}
                  isConvertModalOpen={isConvertModalOpen}
                  setIsConvertModalOpen={setIsConvertModalOpen}
                  isVoiceNoteModalOpen={isVoiceNoteModalOpen}
                  setIsVoiceNoteModalOpen={setIsVoiceNoteModalOpen}
                  deleteNote={deleteNote}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default MyNotebookNotes

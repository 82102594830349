import React from 'react'
import { objItem, objTitle, objWrapper } from './styles.js'
import { objectiveDurationEnumValue } from './../../utils/enumValues'
import { useTranslation } from 'react-i18next'

const ObjectiveDetails = ({ linkageData, obj }) => {
  const { t } = useTranslation(['Dashboard'])

  const companyObjectiveStatement =
    linkageData?.higherObjectives
      .filter((goal, goalIndex) => parseInt(goal.id) === parseInt(obj.topLevelObjectiveId))
      .map((goal) => {
        return goal.statement
      }) ?? t('objectiveDetails.notLinked')

  const areaObjectiveStatement =
    linkageData?.higherObjectives
      .filter((goal, goalIndex) => parseInt(goal.id) === parseInt(obj.parentId))
      .map((goal) => {
        return goal.statement
      }) ?? t('objectiveDetails.noAreaObjective')

  const measureObjectiveStatement =
    linkageData?.possibleMeasures
      .filter((measure, pmIndex) => parseInt(measure.id) === parseInt(obj.impactMeasureId))
      .map((measure) => {
        return measure.name
      }) ?? t('objectiveDetails.noPrimaryEnterprise')

  return (
    <>
      {obj && (
        <div>
          <div style={{ marginBottom: '1.5rem', fontSize: '18px' }}>
            <b>{t('objectiveDetails.objective')}</b>
          </div>
          <div style={objWrapper}>
            <div style={objTitle}>{t('objectiveDetails.statement')}</div>
            <div style={objItem}>{obj.statement}</div>

            <div style={objTitle}>{t('objectiveDetails.IsItBAU')}</div>
            <div style={objItem}>
              {obj.typeId === 3 ? t('objectiveDetails.yes') : t('objectiveDetails.no')}
            </div>

            {obj.typeId === 1 && (
              <div>
                <div style={objTitle}>{t('objectiveDetails.enterpriseObjectiveLink')}</div>
                <div style={objItem}>{companyObjectiveStatement}</div>
              </div>
            )}

            {(obj.typeId === 1 || obj.typeId === 2) && (
              <div>
                <div style={objTitle}></div>
                <div style={objItem}>{areaObjectiveStatement}</div>

                <div style={objTitle}>{t('objectiveDetails.primaryEnterpriseMeasure')}</div>
                <div style={objItem}>{measureObjectiveStatement}</div>
              </div>
            )}

            <div style={objTitle}>{t('objectiveDetails.allocation')}</div>
            <div style={objItem}>{obj.allocatedFte}</div>
          </div>
        </div>
      )}
    </>
  )
}

export default ObjectiveDetails

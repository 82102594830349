// meetingRecordingReducer.js
export const defaultState = {
  meetingInstanceId: null,
  kanbanInstanceId: null,
  hasKanbanVoiceStarted: false,
  isObjRecordingStarted: {},
  isRecordingStop: false,
  stream: null,
  selectedObjectiveId: null,
  geminiResponse: '',
  objActionMap: {},
  shouldRefreshObjId: {
    objId: 0,
    counter: 0,
    request: null,
  },
  expiredTs: 0,
  lastUpdatedMilestoneId: null,
  isStoppingRecording: false,
}

const KanbanVoiceReducer = (state, action) => {
  switch (action.type) {
    case 'START_RECORDING':
      return {
        ...state,
        hasKanbanVoiceStarted: true,
        meetingInstanceId: action.meetingInstanceId,
        kanbanInstanceId: action.kanbanInstanceId,
        isRecordingStop: false,
        expiredTs: action.expiredTs,
        isStoppingRecording: false,
        // dataQueue: [],
      }
    case 'STOP_RECORDING':
      console.log('STOP_RECORDING')
      return {
        ...state,
        hasKanbanVoiceStarted: false,
        isRecordingStop: true,
        meetingInstanceId: null,
        kanbanInstanceId: null,
        stream: null,
        expiredTs: 0,
        lastUpdatedMilestoneId: null,
        isStoppingRecording: false,
        isObjRecordingStarted: {},
        shouldRefreshObjId: {
          objId: 0,
          counter: 0,
          request: null,
        },
        selectedObjectiveId: null,
        geminiResponse: '',
        objActionMap: {},
      }
    case 'SET_STREAM':
      console.log('SET_STREAM: ', action.stream)
      return {
        ...state,
        stream: action.stream,
      }
    case 'SET_OBJ_RECORDING_STARTED':
      return {
        ...state,
        //   assigned all other objIds to false
        isObjRecordingStarted: {
          ...Object.keys(state.isObjRecordingStarted).reduce((acc, objId) => {
            acc[objId] = false
            return acc
          }, {}),
          [action.objId]: action.newValue,
        },
        selectedObjectiveId: action.newValue ? action.objId : null,
      }
    case 'SET_IS_STOPPING_RECORDING':
      return {
        ...state,
        isStoppingRecording: action.isStoppingRecording,
      }
    case 'SET_GEMINI_RESPONSE':
      return {
        ...state,
        geminiResponse: action.geminiResponse,
      }
    case 'ADD_TO_OBJ_ACTIONS_MAP':
      // const existingActions = state.objActionMap[action.objId] ?? []
      // const newActions = action.payload.filter(
      //   (newAction) =>
      //     !existingActions.some(
      //       (existingAction) => existingAction.milestoneId == newAction.milestoneId,
      //     ),
      // )
      // const updatedActions = [...existingActions, ...newActions]

      return {
        ...state,
        objActionMap: {
          ...state.objActionMap,
          [action.objId]: action.payload,
        },
      }
    case 'SET_SHOULD_REFRESH_OBJ_ID':
      return {
        ...state,
        shouldRefreshObjId: {
          objId: action.shouldRefreshObjId,
          counter: state.shouldRefreshObjId.counter + 1,
          request: action.request,
        },
      }
    case 'SET_LAST_UPDATED_MILESTONE_ID':
      return {
        ...state,
        lastUpdatedMilestoneId: action.lastUpdatedMilestoneId,
      }
    default:
      throw new Error(`Action: ${action.type} not implemented in meetingRecordingReducer.`)
  }
}

export default KanbanVoiceReducer

import Modal from '../../../../components/Modal'
import { useTranslation } from 'react-i18next'
//import './style.css'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
import { useEffect, useState } from 'react'
import { getHttpRequest, postHttpRequest } from '../../../../api/query/dynamicAPI'
import Toast from '../../../../common/toast'
import { useNavigate } from 'react-router-dom'
import InPageLoader from '../../../../components/InPageLoader'

const PersonalizedKanbanModal = ({ closeModal, milestoneLabelId }) => {
  const { t } = useTranslation(['Kanban'])
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState([])
  const [milestoneLabelsList, setMilestoneLabelsList] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    setIsLoading(true)
    getMilestoneLabels()
    setIsLoading(false)
  }, [])

  useEffect(() => {
    setIsLoading(true)
    if (milestoneLabelId) {
      setSelectedOption({
        value: milestoneLabelId,
        label: milestoneLabelsList.find((item) => item.id === milestoneLabelId)?.name,
      })
    }
    setIsLoading(false)
  }, [milestoneLabelsList])

  const getMilestoneLabels = async () => {
    let result
    try {
      let companyMilestoneLabels = await getHttpRequest(`/get_all_milestones_labels_for_company`)

      if (companyMilestoneLabels.success.length > 0) {
        const comLabels = companyMilestoneLabels.success.map((item) => ({
          id: item[0],
          name: item[1],
        }))
        setMilestoneLabelsList(comLabels)
      }
    } catch (err) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }

  const getLabelData = async () => {
    if (selectedOption.length == 0) {
      setErrorMessage('must select a label')
    } else {
      setErrorMessage(null)
      window.location.href = `/Kanban?milestoneLabelId=${selectedOption.value}&milestoneLabelName=${selectedOption.label}`
      closeModal()
    }
  }

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption)
  }

  return (
    <div className="tags-management-modal">
      <Modal
        width={'50%'}
        // height={'20rem'}
        closeModal={closeModal}
      >
        <div className="modal-gray-box" style={{ width: '100%' }}>
          {!isLoading && (
            <div className="modal-inner-content">
              <div className="tutorial-management-modal-title" style={{ marginBottom: '10px' }}>
                {t('Kanban:selectLabel')}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '0.5rem',
                  width: '18rem',
                }}
              >
                {/* <label>{t('Kanban:labels')}</label> */}
                <Select
                  id="selectOption"
                  value={selectedOption}
                  onChange={handleSelectChange}
                  options={milestoneLabelsList.map(({ id, name }) => ({ value: id, label: name }))}
                  isLoading={isLoading}
                  isMulti={false}
                  isClearable={false}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
                {errorMessage && (
                  <span className="validation-error">
                    <span>{t('Common:formError.pleaseSelectALabel')}</span>
                  </span>
                )}
                <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}></div>
              </div>

              <div
                className="sleek-button sleek-blue"
                onClick={getLabelData}
                style={{ marginLeft: 'auto' }}
              >
                <span>{t('Common:commonButtons.saveSmall')}</span>
              </div>
            </div>
          )}
          {isLoading && <InPageLoader inComponent={true} />}
        </div>
      </Modal>
    </div>
  )
}

export default PersonalizedKanbanModal

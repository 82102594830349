import React, { useState } from 'react'
import Modal from '../Modal'
import { useTranslation } from 'react-i18next'

const AtMentionModal = ({ isModalOpen, closeModal }) => {
  const { t } = useTranslation(['MeetingHub', 'Common'])

  return (
    <>
      <Modal isModalOpen={isModalOpen} closeModal={closeModal} width={'30rem'}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <span style={{ textAlign: 'left' }}>{t('meetingTopics.atMentionMessage')}</span>
          &nbsp; &nbsp;
          <button
            type="button"
            className="btn-card text-box-btn"
            onClick={(e) => {
              closeModal()
            }}
          >
            {t('Common:commonButtons.close')}
          </button>
        </div>
      </Modal>
    </>
  )
}

export default AtMentionModal

import React from 'react'
import ApplicationLayout from '../../components/ApplicationLayout'
import './styles.css'
import { Outlet } from 'react-router-dom'
import GovernanceHeaders from './components/Headers'
import Loading from '../../components/Loading'
import { defaultState, GovernanceProvider } from './context/governanceContext'
import useGovernanceTabData from './hooks/useGovernanceTabData'
import useAllGovernanceData from '../../api/query/useGovernanceData'
const GovernancePage = () => {
  const { data, isLoading } = useGovernanceTabData()
  const queryResults = useAllGovernanceData()
  const isGovernanceDataLoading = queryResults.some((result) => result.isLoading)

  if (isGovernanceDataLoading || isLoading) {
    return <Loading />
  }

  const initialState = {
    ...defaultState,
    data,
  }

  const govStatusData = queryResults[0].data
  const enterpriseMetricsData = queryResults[1].data
  const companyWideScorecard = queryResults[2].data

  return (
    <ApplicationLayout>
      <GovernanceProvider initialState={initialState}>
        <div className="governance-view">
          <h2>Welcome to the Governance Dashboard</h2>
          <div className="governance-view-body">
            <GovernanceHeaders />
            <br />
            <div className="governance-view-container">
              <Outlet context={{ govStatusData, enterpriseMetricsData, companyWideScorecard }} />
            </div>
          </div>
        </div>
      </GovernanceProvider>
    </ApplicationLayout>
  )
}

export default GovernancePage

import { DEFAULT_GOAL_DATA } from './setupWizardContext'

const setupWizardReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_EMPLOYEE_MODAL':
      return {
        ...state,
        isEmployeeModalOpen: true,
      }
    case 'CLOSE_EMPLOYEE_MODAL':
      return {
        ...state,
        isEmployeeModalOpen: false,
      }
    case 'ADD_ENTERPRISE_LEADER':
      return {
        ...state,
        isEmployeeModalOpen: false,
        lackingEnterpriseLeader: false,
        data: {
          ...state.data,
          enterpriseLeader: [...state.data.enterpriseLeader, action.enterpriseLeader],
        },
      }
    case 'ADD_AREALEADER':
      return {
        ...state,
        isEmployeeModalOpen: false,
        missingTeamLeaders: true,
        data: {
          ...state.data,
          areaLeader: [...state.data.areaLeader, action.areaLeader],
        },
      }
    case 'ADD_TEAMLEADER':
      return {
        ...state,
        data: {
          ...state.data,
          teamLeader: [...state.data.teamLeader, action.teamLeader],
        },
      }
    case 'ADD_INDIVIDUAL_CONTRIBUTOR':
      return {
        ...state,
        data: {
          ...state.data,
          individualContributor: [
            ...state.data.individualContributor,
            action.individualContributor,
          ],
        },
      }
    case 'ADD_IMPACT_MEASURE':
      return {
        ...state,
        data: {
          ...state.data,
          customMeasures: [...state.data.customMeasures, action.customMeasure],
        },
      }
    case 'ADD_SELECTED_MEASURE':
      return {
        ...state,
        data: {
          ...state.data,
          selectedMeasures: [...state.data.selectedMeasures, action.selectedMeasure],
        },
      }
    case 'REMOVE_SELECTED_MEASURE':
      const newSelectedMeasures = [...state.data.selectedMeasures]

      newSelectedMeasures.splice(
        newSelectedMeasures.indexOf(
          newSelectedMeasures.find((measure) => measure.id === action.measureId),
        ),
        1,
      )

      return {
        ...state,
        data: {
          ...state.data,
          selectedMeasures: [...newSelectedMeasures],
        },
      }

    case 'UPDATE_EMPLOYEE_ALLOCATION':
      return {
        ...state,
        data: {
          ...state.data,
          employees: action.employees,
        },
      }
    case 'DELETE_ENTERPRISE_LEADER': {
      const enterpriseLeads = state.data.enterpriseLeader.filter(
        (enterpriseLead, index) => index !== action.enterpriseLeaderIndex,
      )

      return {
        ...state,
        lackingEnterpriseLeader: enterpriseLeads.length === 0 ? true : false,
        data: {
          ...state.data,
          enterpriseLeader: [...enterpriseLeads],
        },
      }
    }
    case 'DELETE_AREALEADER': {
      const areaLeaderEmail = state.data.areaLeader[action.areaLeaderIndex].email
      const areaLeads = state.data.areaLeader.filter(
        (areaLead, index) => index !== action.areaLeaderIndex,
      )

      const teamLeads = state.data.teamLeader.filter(function (teamLead) {
        return teamLead.managerEmail !== areaLeaderEmail
      })

      return {
        ...state,
        data: {
          ...state.data,
          areaLeader: [...areaLeads],
          teamLeader: [...teamLeads],
        },
      }
    }
    case 'DELETE_TEAMLEADER': {
      const teamLeaderEmail = state.data.teamLeader[action.teamLeaderIndex].email
      const teamLeads = state.data.teamLeader.filter(
        (teamLead, index) => index !== action.teamLeaderIndex,
      )
      const individualConts = state.data.individualContributor.filter(function (individualCont) {
        return individualCont.managerEmail !== teamLeaderEmail
      })
      return {
        ...state,
        data: {
          ...state.data,
          teamLeader: [...teamLeads],
          individualContributor: [...individualConts],
        },
      }
    }
    case 'DELETE_INDIVIDUAL_CONTRIBUTOR': {
      const individualConts = state.data.individualContributor.filter(
        (teamLead, index) => index !== action.individualContributorIndex,
      )

      return {
        ...state,
        data: {
          ...state.data,
          individualContributor: [...individualConts],
        },
      }
    }
    case 'CHANGE_CADENCE_FREQUENCY':
      return {
        ...state,
        data: {
          ...state.data,
          cadencePreferences: {
            ...state.data.cadencePreferences,
            frequency: action.frequency,
          },
        },
      }
    case 'CHANGE_CADENCE_START_DATE':
      return {
        ...state,
        data: {
          ...state.data,
          cadencePreferences: {
            ...state.data.cadencePreferences,
            startDate: action.startDate,
          },
        },
      }
    case 'CHANGE_CADENCE_TL_WIZ_DUE_DATE':
      return {
        ...state,
        data: {
          ...state.data,
          cadencePreferences: {
            ...state.data.cadencePreferences,
            tlWizardDueDate: action.tlWizardDueDate,
          },
        },
      }
    case 'OPEN_OBJ_HELP_MODAL':
      return {
        ...state,
        isObjHelpModalOpen: true,
      }
    case 'CLOSE_OBJ_HELP_MODAL':
      return {
        ...state,
        isObjHelpModalOpen: false,
      }
    case 'OPEN_OBJ_FORM_MODAL':
      return {
        ...state,
        isObjFormModalOpen: true,
        editObjectiveIndex: action.editObjectiveIndex,
      }
    case 'CLOSE_OBJ_FORM_MODAL':
      return {
        ...state,
        isObjFormModalOpen: false,
      }
    case 'ADD_TOP_LEVEL_GOAL':
      const newTopLevelGoal = {
        ...DEFAULT_GOAL_DATA,
        statement: `${action.statement}`,
        isValid: Boolean(action.isValid),
        ownerName: action.ownerName,
        ownerTitle: action.ownerTitle,
        what: action.what,
        why: action.why,
        how: action.how,
      }

      return {
        ...state,
        isObjFormModalOpen: false,
        data: {
          ...state.data,
          topLevelObjectives: [...state.data.topLevelObjectives, newTopLevelGoal],
        },
      }
    case 'ADD_AREA_LEVEL_GOAL':
      const newAreaGoal = {
        ...DEFAULT_GOAL_DATA,
        statement: `${action.statement}`,
        isValid: Boolean(action.isValid),
        ownerName: action.ownerName,
        ownerTitle: action.ownerTitle,
        linkedTopLevelGoal: action.linkedTopLevelGoal,
      }

      return {
        ...state,
        isObjFormModalOpen: false,
        data: {
          ...state.data,
          areaLevelObjectives: [...state.data.areaLevelObjectives, newAreaGoal],
        },
      }
    case 'DELETE_AREA_LEVEL_GOAL': {
      const objs = state.data.areaLevelObjectives.filter(
        (obj, index) => index !== action.objectiveIndex,
      )

      return {
        ...state,
        data: {
          ...state.data,
          areaLevelObjectives: [...objs],
        },
      }
    }
    case 'DELETE_TOP_LEVEL_GOAL': {
      const objs = state.data.topLevelObjectives.filter(
        (obj, index) => index !== action.objectiveIndex,
      )

      return {
        ...state,
        data: {
          ...state.data,
          topLevelObjectives: [...objs],
        },
      }
    }
    case 'UPDATE_TOP_LEVEL_OBJECTIVE': {
      action.objective.statement = `${action.statement}`

      const objs = state.data.topLevelObjectives
      objs[action.objectiveIndex] = action.objective

      return {
        ...state,
        isObjFormModalOpen: false,
        data: {
          ...state.data,
          topLevelObjectives: [...objs],
        },
      }
    }
    case 'UPDATE_AREA_LEVEL_OBJECTIVE': {
      action.objective.statement = `${action.statement}`

      const objs = state.data.areaLevelObjectives
      objs[action.objectiveIndex] = action.objective

      return {
        ...state,
        isObjFormModalOpen: false,
        data: {
          ...state.data,
          areaLevelObjectives: [...objs],
        },
      }
    }
    case 'CLOSE_ERROR_MODAL':
      return {
        ...state,
        isErrorModalOpen: false,
      }
    case 'OPEN_ERROR_MODAL':
      return {
        ...state,
        isErrorModalOpen: true,
      }
    case 'OPEN_CASE_MAPPING_MODAL':
      return {
        ...state,
        isCaseMappingModal: true,
      }
    case 'CLOSE_CASE_MAPPING_MODAL':
      return {
        ...state,
        isCaseMappingModal: false,
      }
    case 'UPDATE_TEAMLEADERS':
      return {
        ...state,
        data: {
          ...state.data,
          teamLeader: action.teamLeaders,
        },
      }
    case 'DELETE_CASE_MAPPING':
      const newTeamLeaders = JSON.parse(JSON.stringify(state.data.teamLeader))
      for (let i = 0; i < newTeamLeaders.length; i++) {
        if (newTeamLeaders[i].email === action.email) {
          delete newTeamLeaders[i]['empoweredTo']
        }
      }
      return {
        ...state,
        data: {
          ...state.data,
          teamLeader: [...newTeamLeaders],
        },
      }

    default:
      throw new Error(`Action: ${action.type} not implemented in Setup Wizard reducer.`)
  }
}

export default setupWizardReducer

import React from 'react'
import {
  addWorkshopAspirationNote,
  getNoteTypeAspirationNotes,
} from '../../../../api/services/aspirationNotes.services'
import Toast from '../../../../common/toast'
import { useLocation } from 'react-router-dom'
import EditNoteModal from '../EditNoteModal'

const WorkshopFinalAspirations = ({}) => {
  const location = useLocation()
  const { noteType } = location.state

  const [leftInputText, setLeftInputText] = React.useState('')
  const [aspirationList, setAspirationList] = React.useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false)
  const [noteToEdit, setNoteToEdit] = React.useState(null)
  const [noteToEditIndex, setNoteToEditIndex] = React.useState(null)

  const leftInputTextChangeHandler = (e) => {
    setLeftInputText(e.target.value)
  }

  React.useEffect(() => {
    loadAspirationList(noteType)
  }, [])

  const handleAdd = async () => {
    let updatedAspirations = aspirationList
    updatedAspirations.Summary.push(leftInputText) // Add the new note to the existing list
    let updatedNote = JSON.stringify(updatedAspirations) // Convert back to JSON string
    await updateSummary(updatedNote)
  }

  const updateSummary = async (updatedAspirations) => {
    let noteData = {
      note: updatedAspirations,
      noteType: noteType,
    }
    let result = await addWorkshopAspirationNote(noteData)
    if (result) {
      Toast.fire({
        icon: 'success',
        title: 'Success!!',
      })
      setLeftInputText('')
      loadAspirationList(noteType)
    } else {
      Toast.fire({
        icon: 'error',
        title: 'There was an error, try again',
      })
    }
  }

  const handleEdit = async (noteId, noteText, noteType) => {
    let updatedAspirations = aspirationList
    updatedAspirations.Summary[noteToEditIndex] = noteText
    let updatedNoteString = JSON.stringify(updatedAspirations)
    await updateSummary(updatedNoteString)
  }

  const handleDelete = async (index) => {
    let updatedAspirations = aspirationList
    updatedAspirations.Summary.splice(index, 1)
    let updatedNoteString = JSON.stringify(updatedAspirations)
    await updateSummary(updatedNoteString)
  }

  async function loadAspirationList() {
    //setIsLoading(true)
    let result = await getNoteTypeAspirationNotes({ noteType: noteType })
    //setIsLoading(false)
    if (result && result.aspirationNotesList) {
      if (result && result.aspirationNotesList && result.aspirationNotesList.length > 0) {
        let aspirations = JSON.parse(result.aspirationNotesList[0].note)
        setAspirationList(aspirations)
        setLeftColumnList(aspirations.Summary)
      }
    }
  }

  const [leftColumnList, setLeftColumnList] = React.useState([])

  return (
    <>
      {isEditModalOpen && noteToEdit && (
        <EditNoteModal
          handleEdit={handleEdit}
          handleClose={() => {
            setIsEditModalOpen(false)
          }}
          noteText={noteToEdit}
          noteType={noteToEdit?.noteType}
        />
      )}

      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '80%',
            gap: '2rem',
            paddingTop: '4rem',
          }}
        >
          <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <b style={{ textAlign: 'center' }}>Final Aspiration</b>
          </span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '2rem',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>
                As Walmex we hope to leverage AI to better serve our{' '}
                <b>{noteType.split('-')[0] + 's'}</b> so that they can:
              </span>
              <br />
              <div className="divider" />
              <br />
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <textarea
                  rows="5"
                  id="leftInputText"
                  name="leftInputText"
                  value={leftInputText}
                  onChange={(e) => {
                    leftInputTextChangeHandler(e)
                  }}
                  style={{
                    resize: 'none',
                    borderRadius: '10px',
                    backgroundColor: 'white',
                    fontSize: '14px',
                    width: '25rem',
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: '7rem',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                  }}
                >
                  <div
                    className="fitted-button blue tooltip"
                    onClick={() => {
                      handleAdd()
                    }}
                  >
                    <span className="material-symbols-outlined">add</span>
                  </div>
                </div>
              </div>
              <div>
                <br />

                <div>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    {leftColumnList.map((note, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '0.5rem',
                          border: '1px solid #ccc',
                          borderRadius: '5px',
                        }}
                      >
                        <span>{note}</span>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                          <div
                            className="fitted-button red tooltip"
                            onClick={() => handleDelete(index)}
                          >
                            <span className="material-symbols-outlined">close</span>
                          </div>
                          <div
                            className="fitted-button blue tooltip"
                            onClick={() => {
                              setNoteToEditIndex(index)
                              setNoteToEdit(note)
                              setIsEditModalOpen(true)
                            }}
                          >
                            <span className="material-symbols-outlined">edit</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WorkshopFinalAspirations

import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const editEmployeesUserConfig = async (postArray) => {
  const { data } = await axios.post(`${BASE_URL}/edit_employees_user_configs`, postArray, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export default function useEditEmployeesUserConfig() {
  const queryClient = useQueryClient()

  return useMutation((postArray) => editEmployeesUserConfig(postArray), {
    onSuccess: () => {
      queryClient.invalidateQueries('employees-user-configs')
    },
  })
}

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getAllCopiesObjWithEmpAlloc = async (originalId) => {
  const queryString = `/get-all-copies-obj-with-emp-alloc/${originalId}`

  const { data } = await axios.get(`${BASE_URL}${queryString}`)
  return data
}

export default function useGetAllCopiesObjWithEmpAlloc(originalId) {
  return useQuery(
    ['copies-obj-with-emp-alloc', originalId],
    () => getAllCopiesObjWithEmpAlloc(originalId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(originalId),
    },
  )
}

import React from 'react'
// import PercentageRender from './percentageRender'
import './styles.css'

const EnterpriseMetricsTable = ({ enterpriseMetricsData }) => {
  return (
    <div className="enterprise-metrics-main-container">
      {/* {enterpriseMetricsData.param1}
        {enterpriseMetricsData.param2}
        {enterpriseMetricsData.param3} */}
      <div className="enterprise-metrics-container shadow">
        <h3 className="enterprise-metrics-title">How well are we executing?</h3>
        {/* <div className="enterprise-metrics-header-container">
          <b>Dimension</b>
          <b style={{ textAlign: 'center' }}></b>
        </div> */}
        {/* 
        <h3 className="enterprise-metrics-subtitle">How well are we deprioritizing?</h3>
        <div className="enterprise-metrics">
          {/* {strategyBreakdown.map((strat, index) => (
            <React.Fragment key={`strat-${index}`}>
              <div>{strat.measureName}</div>
              <PercentageRender value={strat.realPercent} />
            </React.Fragment>
          ))} * /}
        </div> */}

        <div className="divider"></div>

        <h3 className="enterprise-metrics-subtitle">Are we allocating optimally?</h3>
        <div className="enterprise-metrics">
          <React.Fragment key={`overalloc-1`}>
            <div>Number of people overallocated across teams</div>
            <div style={{ textAlign: 'center' }}>
              {' '}
              {enterpriseMetricsData.numOverallocatedPeople
                ? enterpriseMetricsData.numOverallocatedPeople
                : 0}{' '}
            </div>
          </React.Fragment>
        </div>

        <div className="divider"></div>

        <h3 className="enterprise-metrics-subtitle">How well are we handling blocks?</h3>
        <div className="enterprise-metrics">
          <React.Fragment key={`mttr1`}>
            <div>Average time to resolve a block (days)</div>
            <div style={{ textAlign: 'center' }}>
              {' '}
              {enterpriseMetricsData.blockerMeanResolutionDays
                ? enterpriseMetricsData.blockerMeanResolutionDays
                : 0}{' '}
            </div>
          </React.Fragment>
        </div>

        <div className="divider"></div>

        <h3 className="enterprise-metrics-subtitle">Number of blockers by strategic objective</h3>
        <div className="enterprise-metrics">
          {enterpriseMetricsData.blockersByStratObj.map((stratObjMetric, index) => (
            <React.Fragment key={`strat_obj_metric-${index}`}>
              <div>{stratObjMetric.stratObjText}</div>
              {/* {measure.realPercent && <PercentageRender value={measure.realPercent} />} */}
              <div style={{ textAlign: 'center' }}> {stratObjMetric.numBlockers} </div>
            </React.Fragment>
          ))}
          {enterpriseMetricsData?.blockersByStratObj?.length == 0 && (
            <React.Fragment key={`mttr1`}>
              <div>None</div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  )
}

export default EnterpriseMetricsTable

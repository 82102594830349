import React from 'react'
import AddAreaLeaderModal from './components/AddAreaLeaderModal'
import plus from '../../../../assets/plus.svg'
import deleteCross from '../../../../assets/delete-cross.svg'
import { useSetupWizardContext } from '../../context/setupWizardContext'
import './styles.css'

const AreaLeaderStep = () => {
  const { state, dispatch } = useSetupWizardContext()

  return (
    <div className="area-leader-step-component">
      <AddAreaLeaderModal />
      <div className="step-section team-step">
        <h2>Let’s identify the Area Leaders that will be using Decision+</h2>

        <p style={{ textAlign: 'left' }} className="step-details-p">
          Each Area should have one leader only. Area Leaders are senior individuals that will hold
          Decision Meetings with their direct reports, who should in turn manage teams (Team
          Leaders)
        </p>

        <div className="table-wrapper shadow">
          <table>
            <tbody>
              <tr className="header-row">
                <th>
                  <span>Name</span>
                </th>
                <th>
                  <span>Title</span>
                </th>
                <th>
                  <span>Area</span>
                </th>
                <th>
                  <span>Email</span>
                </th>
              </tr>

              {state.data.areaLeader?.map((areaLeader, index) => (
                <tr id={areaLeader.name} key={`employee-${index}`}>
                  <td>{areaLeader.name}</td>
                  <td>{areaLeader.title}</td>
                  <td>{areaLeader.area}</td>
                  <td>{areaLeader.email}</td>
                  <td>
                    <div
                      className="delete-button"
                      onClick={() => {
                        dispatch({ type: 'DELETE_AREALEADER', areaLeaderIndex: index })
                      }}
                    >
                      <img className="delete-btn" src={deleteCross} alt="Click to edit symbol" />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div
            className="add-btn"
            onClick={() => {
              dispatch({ type: 'OPEN_EMPLOYEE_MODAL' })
            }}
          >
            <img className="plus" src={plus} alt="Graphic of plus sign" />
            <span>ADD AREA LEADER</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AreaLeaderStep

import React from 'react'
import { useTranslation } from 'react-i18next'
import Toast from '../../../common/toast'
import MeiModal from '../../Home/components/AdminDashboard/components/TeamDoing/component/MeiModal'
import MeiHelpModal from '../../Home/components/AdminDashboard/components/TeamDoing/component/MeiHelpModal'
import InPageLoader from '../../../components/InPageLoader'
import EmployeeMeiBoxes from '../../../components/DashboardMei'
import { shortenName } from '../../../utils/general'

import SelectSingleField from '../../../components/CustomMultipleSelect/singleOption'
import { dateTimeFilter } from '../../../utils/time'
import { getHttpRequest } from '../../../api/query/dynamicAPI'

const MeiHistory = () => {
  const [historyData, setHistoryData] = React.useState([])
  const [alData, setAlData] = React.useState(null)
  const { t } = useTranslation(['Dashboard', 'Common'])

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [data, setData] = React.useState(null)

  const [selectedLogId, setSelectedLogId] = React.useState(null)

  const handleTimestampChange = (selectedOption) => {
    setSelectedLogId(selectedOption.logId)
  }

  React.useEffect(() => {
    ;(async () => {
      try {
        let response = await getHttpRequest('/get_mei_history')
        setHistoryData(response.data)
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Something went wrong',
        })
      }
    })()
  }, [])

  React.useEffect(() => {
    if (selectedLogId !== null) {
      let matchingRecord = historyData?.find(
        (log) => parseInt(log.logId) === parseInt(selectedLogId),
      )
      // values/undefined/null
      setAlData(matchingRecord?.mei?.data)
    }
  }, [selectedLogId])

  return (
    <>
      {isModalOpen && data && (
        <MeiModal data={data} isModalOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} />
      )}
      <div style={{ marginTop: '10px', paddingBottom: '50px' }}>
        <div className="dashboard_wrapper" style={{ marginTop: '8%' }}>
          <div className="charts_outer_box">
            <h1 className="charts_heading">{t('chartsCard.cxoMeiTitleHistory')}</h1>
            <div style={{ width: '50%', padding: '5px' }}>
              <SelectSingleField
                placeholder={t('Common:formPlaceHolder.selectTime')}
                name="selectTime"
                value={historyData?.filter((log) => parseInt(log.logId) == parseInt(selectedLogId))}
                options={historyData}
                onChange={handleTimestampChange}
                getOptionLabel={(option) => dateTimeFilter(option.timestampsAdded)}
                getOptionValue={(option) => option.logId}
                width={'80%'}
              />
            </div>
            <div className="chart_container" style={{ overflowX: 'auto' }}>
              {alData && alData.length > 0 && (
                <div style={{ display: 'flex', flexDirection: 'row', margin: '0 auto' }}>
                  {alData.map((metrics, index) => (
                    <div style={{ display: 'flex', flexDirection: 'column', width: '10rem' }}>
                      <h4 style={{ margin: '8px' }}>{shortenName(metrics?.name)}</h4>
                      <div style={{ marginTop: '1%' }}>
                        <EmployeeMeiBoxes
                          key={index}
                          metrics={metrics}
                          isCxo={true}
                          setIsModalOpen={setIsModalOpen}
                          setData={setData}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MeiHistory

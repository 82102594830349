import React from 'react'
import useGetEmployeesUserConfigs from '../../../../api/query/useGetEmployeesUserConfig'
import { userConfigEnum } from '../../../../utils/enumValues'
import useEditEmployeesUserConfig from '../../../../api/mutation/useEditEmployeesUserConfig'
import InPageLoader from '../../../../components/InPageLoader'
import useTeamLeadersForAreaLeader from '../../../../api/query/useTeamLeadersForAreaLeaderData'
import useAllLeadersData from '../../../../api/query/useAreaLeaderData'
import useCSuiteData from '../../../../api/query/useCSuiteData'
import { getUserConfigFlagMetadata } from '../../../../api/services/employee.services.jsx'

const AccessConfig = () => {
  const [selectedAreaLeaderId, SetSelectedAreaLeaderId] = React.useState('0000')
  const [selectedCsuiteLeaderId, setSelectedCSuiteLeaderId] = React.useState('000')
  const { data: employeesData, isLoading: isEmployeesDataLoading } =
    useGetEmployeesUserConfigs(selectedAreaLeaderId)
  const { data: areaLeadersData, isLoading: isAreaLeadersDataLoading } =
    useGetEmployeesUserConfigs(selectedCsuiteLeaderId)
  const [checkboxes, setCheckboxes] = React.useState({})

  const { data: addAreaLeaderData, isLoading: isAreaLeaderLoading } = useAllLeadersData()

  const { data: csuiteData, isLoading: isCSuiteLoading } = useCSuiteData()

  const getUserConfigFlagMetadata2 = async () => {
    const data = await getUserConfigFlagMetadata()
    return data
  }

  const [tls, setTLUsers] = React.useState(employeesData ? employeesData?.employees : [])
  const [als, setALUsers] = React.useState(areaLeadersData ? areaLeadersData?.employees : [])

  // We need this map to enfore the order of the fields
  const regularFieldsIndexMppingTL = {
    canAddEditObjs: 0,
    canChangeObjStatus: 1,
    canChangeObjPriority: 2,
    canChangeInitNumber: 3,
    allowAddingL2Activities: 4,
  }

  React.useEffect(() => {
    if (employeesData?.employees) {
      setTLUsers(employeesData?.employees)

      let flagsMetadata = getUserConfigFlagMetadata2()

      for (let employee of employeesData?.employees ?? []) {
        let keys = Object.keys(employee.regularConfigValue)

        let values = []
        for (let key of keys) {
          // so the checkboxes states of 'eid' are in the same order as the backend response
          values[regularFieldsIndexMppingTL[key]] = employee.regularConfigValue[key]
        }
        setCheckboxes((prev) => ({
          ...prev,
          [employee.eId]: values,
        }))
      }
    }
  }, [employeesData])

  React.useEffect(() => {
    if (areaLeadersData?.employees) {
      let flagsMetadata = getUserConfigFlagMetadata2()

      setALUsers(areaLeadersData?.employees)
      for (let employee of areaLeadersData?.employees ?? []) {
        setCheckboxes((prev) => ({
          ...prev,
          [employee.eId]: employee.regularConfigValue['l2UserCanSeeL2InKanban'],
        }))
      }
    }
  }, [areaLeadersData])

  const updateWizardsFields = [1, 0, 2, 3] //Uses corresponding enum values check userConfigEnum() for reference
  const regularFields = [4, 5, 6, 7, 8]
  const tlFields = [...updateWizardsFields, ...regularFields]
  const alFields = [9]

  const handleRadioChange = (e, userIndex, field) => {
    const updatedUsers = [...tls]
    updatedUsers[userIndex].userConfigValue = field
    setTLUsers(updatedUsers)
  }

  // checkbox should be handled individually
  const handleTLCheckboxChange = (e, userIndex, index) => {
    updateCheckboxes(e.target.id, index)

    const updatedUsers = [...tls]
    let keys = Object.keys(updatedUsers[userIndex].regularConfigValue)
    // toggle the bool value in regularConfigValue object
    updatedUsers[userIndex].regularConfigValue[keys[index]] =
      !updatedUsers[userIndex].regularConfigValue[keys[index]]
    setTLUsers(updatedUsers)
  }

  const updateCheckboxes = (id, index) => {
    let eid = id.split('-')[0]
    setCheckboxes((prev) => ({
      ...prev,
      [eid]: [...prev[eid].slice(0, index), !prev[eid][index], ...prev[eid].slice(index + 1)],
    }))
  }

  const handleALCheckboxChange = (e, userIndex) => {
    // updateCheckboxes(e.target.id, index)
    let eid = e.target.id.split('-')[0]
    setCheckboxes((prev) => ({
      ...prev,
      [eid]: !prev[eid],
    }))
    const updatedUsers = [...als]
    // toggle the bool value in regularConfigValue object
    updatedUsers[userIndex].regularConfigValue['l2UserCanSeeL2InKanban'] =
      !updatedUsers[userIndex].regularConfigValue['l2UserCanSeeL2InKanban']
    setALUsers(updatedUsers)
  }

  const editUserConfigsMutation = useEditEmployeesUserConfig()

  const saveData = async (data) => {
    await editUserConfigsMutation.mutateAsync(data, {
      onSuccess: (response) => {},
      onError: () => {},
    })
  }

  const OnSelectedAreaLeaderChanged = (e) => {
    SetSelectedAreaLeaderId(e.target.value)
  }

  const OnSelectedCsuiteLeaderChanged = (e) => {
    setSelectedCSuiteLeaderId(e.target.value)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <div
        style={{
          padding: '1rem 2rem',
          boxShadow: '0 5px 15px #ccc',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '20rem',
          minWidth: '40rem',
          marginBottom: '2rem',
          width: '95%',
          overflowX: 'auto',
        }}
      >
        <div>
          Area Leaders for
          <select
            className="accountable-select"
            onChange={OnSelectedCsuiteLeaderChanged}
            defaultValue="000"
          >
            <option value="000" key="000">
              Select C-suite Leader
            </option>
            {!isCSuiteLoading &&
              csuiteData?.employees.map((employee, index) => (
                <option value={employee?.eId} key={`${employee?.eId}`}>
                  {employee?.name}
                </option>
              ))}
          </select>
        </div>
        {selectedCsuiteLeaderId !== '000' && isAreaLeadersDataLoading && (
          <InPageLoader inComponent={true} />
        )}
        {areaLeadersData && areaLeadersData?.employees?.length > 0 && (
          <>
            <table>
              <thead>
                <tr>
                  <th></th>
                  {alFields.map((field) => (
                    <th key={field}>{userConfigEnum(field)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {als &&
                  als.map((user, userIndex) => (
                    <tr key={user.eId}>
                      <td>{user.name}</td>
                      {alFields.map((field) => (
                        <td key={field}>
                          <input
                            id={`${user.eId}-${field}`}
                            type="checkbox"
                            checked={checkboxes && checkboxes[user.eId]}
                            onChange={(e) => handleALCheckboxChange(e, userIndex)}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
            <button
              style={{ alignSelf: 'end' }}
              onClick={() => saveData(als)}
              className="move-btn-table import_export_btn btn_cmn"
            >
              Save Data
            </button>
          </>
        )}
      </div>
      <div
        style={{
          padding: '1rem 2rem',
          boxShadow: '0 5px 15px #ccc',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '20rem',
          minWidth: '40rem',
          marginBottom: '2rem',
          width: '95%',
          overflowX: 'auto',
        }}
      >
        <div>
          Team Leaders for
          <select
            className="accountable-select"
            onChange={OnSelectedAreaLeaderChanged}
            defaultValue="0000"
          >
            <option value="0000" key="0000">
              Select Area Leader
            </option>
            {!isAreaLeaderLoading &&
              addAreaLeaderData?.employees.map((employee, index) => (
                <option value={employee?.eId} key={`${employee?.eId}`}>
                  {employee?.name}
                </option>
              ))}
          </select>
        </div>
        {selectedAreaLeaderId !== '0000' && isEmployeesDataLoading && (
          <InPageLoader inComponent={true} />
        )}
        {employeesData && employeesData?.employees?.length > 0 && (
          <>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th
                    colSpan="4"
                    style={{ fontSize: '20px', textAlign: 'center', width: '50%', padding: '0' }}
                  >
                    Update Wizards
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
                <tr>
                  <th></th>
                  <th
                    colSpan="4"
                    style={{
                      fontSize: '30px',
                      borderBottom: '2px solid black',
                      height: '0',
                      width: '40%',
                    }}
                  ></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
                <tr>
                  <th></th>
                  {tlFields.map((field) => (
                    <th key={field}>{userConfigEnum(field)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tls &&
                  tls.map((user, userIndex) => (
                    <tr key={user.eId}>
                      <td>{user.name}</td>
                      {updateWizardsFields.map((field) => (
                        <td key={field}>
                          <input
                            type="radio"
                            checked={user.userConfigValue === field}
                            onChange={(e) => handleRadioChange(e, userIndex, field)}
                          />
                        </td>
                      ))}
                      {regularFields.map((field) => (
                        <td key={field}>
                          <input
                            id={`${user.eId}-${field}`}
                            type="checkbox"
                            checked={checkboxes && checkboxes[user.eId][field - 4]}
                            onChange={(e) => handleTLCheckboxChange(e, userIndex, field - 4)}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
            <button
              style={{ alignSelf: 'end' }}
              onClick={() => saveData(tls)}
              className="move-btn-table import_export_btn btn_cmn"
            >
              Save Data
            </button>
          </>
        )}
      </div>
    </div>
  )
}

export default AccessConfig

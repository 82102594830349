import React from 'react'
import './styles.scss'
import { useMeetingRecording } from '../../utils/meetingRecordingContext/meetingRecordingContext'
// import { useTranslation } from 'react-i18next';

const MeetingTranscription = ({}) => {
  // const { t } = useTranslation(['MeetingHub'])
  const bottomRef = React.useRef(null)

  const { state } = useMeetingRecording()

  React.useEffect(() => {
    if (state.transcription.length > 0) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
  }, [state.transcription])

  return (
    <div className="meeting-transcription-body">
      <ul>
        {state.transcription?.map((transcript, index) => {
          return (
            <>
              <li key={index} style={{ marginTop: '0.5rem' }}>
                <span style={{ fontSize: '13px', marginLeft: '-3px' }}>{transcript.summary}</span>
              </li>
            </>
          )
        })}
      </ul>
      <div ref={bottomRef} />
    </div>
  )
}

export default MeetingTranscription

import React, { useState, useEffect } from 'react'
import './styles.css'
import useAddEmployee from '../../../../api/mutation/addEmployeeHome'
import useEditEmployeeAllocation from '../../../../api/mutation/editEmployeeAllocationHome'
import employee from '../../../../assets/employee.svg'
import AddEmployeeForm from './addEmployeeForm'
import ErrorModal from '../../../ErrorModal'
import Modal from '../../../Modal'
import { useTranslation } from 'react-i18next'

const AddEmployeeModal = ({ state, dispatch }) => {
  const { t } = useTranslation(['TeamLeaderWizard'])
  const [title, setTitle] = useState('')
  const mutationAddEmployee = useAddEmployee()
  const mutationEditEmployeeAllocation = useEditEmployeeAllocation()
  const [employeeWidgetErrorMessage, setEmployeeWidgetErrorMessage] = useState(null)

  const openErrorModal = () => {
    dispatch({ type: 'OPEN_ERROR_MODAL' })
  }

  const closeErrorModal = () => {
    dispatch({ type: 'CLOSE_ERROR_MODAL' })
  }

  const editEmployee = React.useMemo(() => {
    if (state.editEmployeeIndex !== null) {
      return state.data.employees[state.editEmployeeIndex]
    } else {
      return null
    }
  }, [state.editEmployeeIndex, state.data.employees])

  useEffect(() => {
    if (state.editEmployeeIndex === undefined || state.editEmployeeIndex === null) {
      setTitle(t('addEmployeeModal.titleAdd'))
    } else if (state.editEmployeeIndex !== null) {
      setTitle(t('addEmployeeModal.titleEdit'))
    }
  }, [state.editEmployeeIndex])

  const handleCancel = () => {
    dispatch({ type: 'CLOSE_EMPLOYEE_MODAL' })
    resetForm()
  }

  const handleSubmit = async (values) => {
    // Make sure this person doesn't already exist in the list.
    let found = false
    for (let emp of state.data.employees) {
      if (emp.email === values.email.toLowerCase()) {
        alert(t('addEmployeeModal.personAlreadyInTeam'))
        return
      }
    }

    state.lastEmpLocaleId++
    dispatch({
      type: 'ADD_EMPLOYEE',
      employee: {
        ...values,
        localeId: state.lastEmpLocaleId,
        eId: state.lastEmpLocaleId,
      },
    })

    // if (editEmployee === null || editEmployee === undefined) {
    //   //Object with list of employees is being sent to backend given the syntax for the employees functions in the backend
    //   await mutationAddEmployee.mutateAsync({ employees: [{ ...values }] }, {

    //     onSuccess: (response) => {
    //     //TODO (Ceci): parse backend error to dsiplay to user
    //       if(response.ok === false){
    //         setEmployeeWidgetErrorMessage("There was an error, please try again.")
    //         openErrorModal()
    //       }else{
    //         dispatch({ type: 'ADD_EMPLOYEE', employee: { ...values } })
    //       }
    //     },
    //     onError: (response) => {
    //       openErrorModal()
    //     },
    //   })
    // } else {
    //   //Object with list of employees is being sent to backend given the syntax for the employees functions in the backend
    //   await mutationEditEmployeeAllocation.mutateAsync({ employees: [{ ...values }] }, {

    //     onSuccess: (response) => {
    //       //TODO (Ceci): parse backend error to dsiplay to user
    //       if(response.ok === false){
    //         setEmployeeWidgetErrorMessage("There was an error, please try again.")
    //         openErrorModal()
    //       }else{
    //         const employeeToEdit = {
    //           name: values.name,
    //           email: values.email,
    //           teamAllocPct: values.teamAllocPct
    //         }
    //         dispatch({
    //           type: 'UPDATE_EMPLOYEE',
    //           employee: employeeToEdit,
    //           employeeIndex: state.editEmployeeIndex,
    //         })
    //       }
    //     },
    //     onError: (response) => {
    //       openErrorModal()

    //     },
    //   })
    // }
  }

  const [resetForm, setResetForm] = React.useState(null)

  return (
    <>
      <ErrorModal
        isOpen={state.isErrorModalOpen}
        handleClose={closeErrorModal}
        errorMessage={employeeWidgetErrorMessage}
      ></ErrorModal>
      {state.isEmployeeModalOpen === true && (
        <Modal title={title} closeModal={handleCancel}>
          <AddEmployeeForm
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            editEmployee={editEmployee}
            setResetForm={setResetForm}
          />
        </Modal>
      )}
    </>
  )
}

export default AddEmployeeModal

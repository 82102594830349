import React from 'react'
import { shortenName } from '../../../utils/general'
import './style.css'
import MeiModal from '../../../pages/Home/components/AdminDashboard/components/TeamDoing/component/MeiModal'
import { useNavigate } from 'react-router-dom'
import TeamPerformanceModal from './TeamPerformanceModal'
import { hasRole } from '../../../utils/auth'
import { getCurrentMondayDate } from '../../../utils/general'
import { getHttpRequest } from '../../../api/query/dynamicAPI'
import Toast from '../../../common/toast'

const TeamPerformanceMessage = ({
  message = [],
  onlyShowModal = false,
  isTeamPerformanceModalOpen = false,
  closeTeamformanceModal = () => {},
}) => {
  const [seeMore, setSeeMore] = React.useState(false)
  const [teamWidePerformance, setTeamWidePerformance] = React.useState({
    meiRed: [],
    kpiOverdue: [],
    topic: [],
  })

  const [teamPerformanceTable, setTeamPerfpormanceTable] = React.useState([])
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [data, setData] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)

  const fetchProcessTeamPerformance = async () => {
    try {
      setIsLoading(true)
      let response = await getHttpRequest('/get_team_performance_result')
      processMessage(response?.teamPerformanceResponses ?? [])
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
    setIsLoading(false)
  }

  const count = React.useRef(0)
  React.useEffect(() => {
    // reset is necessary otherwise the table will keep adding the same data if you expand,collapase etc
    // setTeamPerfpormanceTable([])
    if (!onlyShowModal) {
      processMessage(message)
    } else {
      if (count.current === 0) {
        fetchProcessTeamPerformance()
        count.current += 1
      }
    }
  }, [message])

  const processMei = (metrics) => {
    const thresholds = {
      BenchmarkActionClosedTime: 28,
      BenchmarkTopicsCounts: metrics?.mei_thresholds?.prepared_target ?? 1,
      BenchmarkCompletionPercentage: metrics?.mei_thresholds?.taskmaster_target ?? 0.4,
      BenchmarkCoordiatedAvg: metrics?.mei_thresholds?.coordinated_target ?? 1,
    }

    let new_all_employees = metrics?.all_employees_status ?? []
    new_all_employees.forEach((employee) => (employee.thresholds = thresholds))
    return { newMeiData: new_all_employees }
  }

  const processMessage = (message) => {
    let topic = []
    let kpiOverdue = []
    let meiRed = []
    let teamPerformanceStats = []
    // each msg is a leader
    message.forEach((msg) => {
      let tlStatusRatioStr = ''
      let meiResponse = {}
      if (msg.isCxo) {
        // show green/(green+red) MEi status of TLs under AL for CXO
        let meiTlStatus = JSON.parse(msg.meiResponse)

        let green_tls =
          meiTlStatus?.all_employees_status?.filter((e) => e?.mei_status?.is_good) ?? []
        let red_tls =
          meiTlStatus?.all_employees_status?.filter((e) => !e?.mei_status?.is_good) ?? []
        let tlStatusRatio =
          green_tls.length + red_tls.length > 0
            ? green_tls.length / (green_tls.length + red_tls.length)
            : 0
        tlStatusRatioStr = `${(tlStatusRatio * 100).toFixed(1)}%`

        meiResponse = JSON.parse(msg.meiAl)
      } else {
        meiResponse = JSON.parse(msg.meiResponse)
      }

      let tableState = msg.otherTableStats

      if (tableState.topicsAdded > 0) {
        topic.push(shortenName(msg.ownerName))
      }
      let { newMeiData } = processMei(meiResponse, msg.isCxo)
      // if it is cxo, then we need to use the table state, otherwise use individual meiData
      let finalOverdueKpiNumber = msg.isCxo
        ? (tableState?.overdueKpis ?? 0)
        : (newMeiData?.[0]?.raw_data?.overdue_kpi_chatbot ?? 0)
      if (finalOverdueKpiNumber > 0) {
        kpiOverdue.push(shortenName(msg.ownerName))
      }
      let isGood = newMeiData?.every((e) => e?.mei_status?.is_good)

      if (isGood === false) {
        meiRed.push(shortenName(msg.ownerName))
      }
      teamPerformanceStats.push({
        leaderId: msg.ownerId,
        leaderName: msg.ownerName,
        meiData: newMeiData.filter((e) => e.owner_id === msg.ownerId),
        totalKpiNumber: msg.isCxo
          ? (tableState?.totalKpis ?? 0)
          : (newMeiData?.[0]?.raw_data?.kpi_num ?? 0),
        overdueKpiNumber: msg.isCxo
          ? (tableState?.overdueKpis ?? 0)
          : (newMeiData?.[0]?.raw_data?.overdue_kpi_chatbot ?? 0),
        topicsAdded: tableState?.topicsAdded ?? 0,
        actionsAdded: msg.isCxo
          ? (tableState?.actionsAdded ?? 0)
          : (newMeiData?.[0]?.raw_data?.num_actions_created ?? 0),
        actionsClosed: msg.isCxo
          ? (tableState?.actionsClosed ?? 0)
          : (newMeiData?.[0]?.raw_data?.num_actions_closed ?? 0),
        isCxo: msg.isCxo,
        tlsPerformance: msg.isCxo ? tlStatusRatioStr : '',
        topicsAddedLast4Weeks: newMeiData?.[0]?.raw_data?.topics_added ?? 0,
      })
    })

    setTeamPerfpormanceTable(
      teamPerformanceStats.sort((a, b) => a.leaderName.localeCompare(b.leaderName)),
    )
    meiRed.sort((a, b) => a.localeCompare(b))
    kpiOverdue.sort((a, b) => a.localeCompare(b))
    topic.sort((a, b) => a.localeCompare(b))
    setTeamWidePerformance({
      meiRed: meiRed,
      kpiOverdue: kpiOverdue,
      topic: topic,
    })
  }

  const CustomTableResponse = () => {
    let meiStmnt = ''
    let kpiStmnt = ''
    let topicStmnt = ''
    if (teamWidePerformance.meiRed.length > 3) {
      meiStmnt = `${teamWidePerformance.meiRed.length} people are red on their MEI`
    } else if (teamWidePerformance.meiRed.length === 0) {
      meiStmnt = 'Everyone is green on their MEI'
    } else if (teamWidePerformance.meiRed.length === 1) {
      meiStmnt = teamWidePerformance.meiRed[0] + ` is red on MEI`
    } else {
      let lastPersonName = teamWidePerformance.meiRed.pop()
      meiStmnt = `${teamWidePerformance.meiRed.join(', ')} and ${lastPersonName} are red on MEI`
    }

    if (teamWidePerformance.kpiOverdue.length > 3) {
      let helperStmnt = hasRole('cxo')
        ? `have reportees that have KPIs to update`
        : `are missing KPI updates`
      kpiStmnt = `${teamWidePerformance.kpiOverdue.length} people ` + helperStmnt
    } else if (teamWidePerformance.kpiOverdue.length === 0) {
      kpiStmnt = 'Everyone has updated their KPIs'
    } else {
      let helperStmnt = hasRole('cxo')
        ? ` ${
            teamWidePerformance.kpiOverdue.length === 1 ? 'has' : 'have'
          } reportees that have KPIs to update`
        : ` ${teamWidePerformance.kpiOverdue.length === 1 ? 'is' : 'are'} missing KPI updates`

      if (teamWidePerformance.kpiOverdue.length === 1) {
        kpiStmnt = teamWidePerformance.kpiOverdue[0] + helperStmnt
      } else {
        let lastPersonName = teamWidePerformance.kpiOverdue.pop()
        kpiStmnt =
          `${teamWidePerformance.kpiOverdue.join(', ')} and ${lastPersonName}` + helperStmnt
      }
    }

    if (teamWidePerformance.topic.length > 3) {
      topicStmnt = `${teamWidePerformance.topic.length} have topics to discuss`
    } else if (teamWidePerformance.topic.length === 0) {
      topicStmnt = 'No one has topics to discuss'
    } else if (teamWidePerformance.topic.length === 1) {
      topicStmnt = teamWidePerformance.topic[0] + ` has blockers or guidance topics to discuss`
    } else {
      let lastPersonName = teamWidePerformance.topic.pop()
      topicStmnt = `${teamWidePerformance.topic.join(
        ', ',
      )} and ${lastPersonName} have topics to discuss`
    }

    return (
      <ul>
        <li>{meiStmnt}</li>
        <li>{kpiStmnt}</li>
        <li>{topicStmnt}</li>
        <li>
          <span
            className="see-more-link"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setSeeMore((prev) => !prev)
            }}
          >
            {'Click here'}
          </span>
          <span> to see more details</span>
        </li>
      </ul>
    )
  }

  return (
    <>
      {(seeMore || isTeamPerformanceModalOpen) && (
        <TeamPerformanceModal
          seeMore={seeMore || isTeamPerformanceModalOpen}
          setSeeMore={(val) => {
            setSeeMore(val)
            closeTeamformanceModal()
          }}
          teamPerformanceTable={teamPerformanceTable}
          setData={setData}
          setIsModalOpen={setIsModalOpen}
          isCxo={hasRole('cxo')}
          isDataLoading={isLoading}
        />
      )}
      {isModalOpen && data && (
        <MeiModal
          data={data}
          isModalOpen={isModalOpen}
          closeModal={() => {
            setIsModalOpen(false)
            setData(null)
          }}
        />
      )}
      {isTeamPerformanceModalOpen === false && (
        <div>
          <CustomTableResponse />
        </div>
      )}
    </>
  )
}

export default TeamPerformanceMessage

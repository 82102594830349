import React, { useState, useEffect } from 'react'
import { useTeamLeaderContext } from '../../context/teamLeaderContext'
import { useOutletContext } from 'react-router-dom'
import AdditionalCapacityTable from '../../../../components/AllocSubWizard/AdditionalCapacityTable'
import { useTranslation } from 'react-i18next'
// import './styles.scss'

const AdditionalCapacity = () => {
  const { t } = useTranslation(['Common'])
  // const { state, dispatch } = useTeamLeaderContext()
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
  }, [])

  return (
    <>
      <>
        <div className="wizard-step">
          <h2 className="wizard-step__title">{t('capacityAllocation.additionalCapacityTitle')}</h2>
          <div className="wizard-step__overview">
            <div className="wizard-step__overview__paragraph">
              {t('capacityAllocation.additionalCapacitySubtitle')}
            </div>
          </div>

          <div className="wizard-step__main-content-container">
            <div className="objectivesTeamMapping">
              <AdditionalCapacityTable contextHandler={useTeamLeaderContext} />
            </div>
          </div>
        </div>
      </>
    </>
  )
}

export default AdditionalCapacity

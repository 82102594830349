import React from 'react'
import PendingActivitiesList from './components/PendingActivitiesList'
import ApplicationLayout from '../../components/ApplicationLayout'
import Modal from '../../components/Modal'
import './components/style.css'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import { getConsolidatedConfigSettings } from '../../utils/auth'

export const PendingActivitiesModal = ({
  isEmailReminderModalOpen,
  closeModal,
  refreshBGUScorecardFn,
  loadAgain = () => {},
}) => {
  return (
    <div className="action-reminder-modal">
      <Modal
        isModalOpen={isEmailReminderModalOpen}
        closeModal={() => {
          closeModal()
          // we can refresh MH inside pending activities list too...
          loadAgain()
        }}
        width={'90%'}
        style={{ padding: '0rem 0rem 1rem 0rem' }}
      >
        <CommonAma refreshBGUScorecardFn={refreshBGUScorecardFn} />
      </Modal>
    </div>
  )
}

export const EmailReminderTab = () => {
  const refreshBGUScorecardFn = () => {}
  return (
    <ApplicationLayout>
      <div className="dashboard_wrapper">
        <div className="charts_outer_box">
          <CommonAma refreshBGUScorecardFn={refreshBGUScorecardFn} fromHomePage={true} />
        </div>
      </div>
    </ApplicationLayout>
  )
}

export default EmailReminderTab

const CommonAma = ({ refreshBGUScorecardFn, fromHomePage = false }) => {
  const [teamLeadersData, setTeamLeadersData] = React.useState([])
  const [peopleFilterValue, setPeopleFilterValue] = React.useState(null)
  const [flaggedOnly, setShowFlaggedOnly] = React.useState(false)

  const { t } = useTranslation(['MeetingHub', 'Common'])
  //  AL's reporting to Brian Flag - allow_cxo_users_see_l3_objs
  // Brian's Flag - allow_cxo_users_see_l3_objs
  const allowCxoUsersSeeL3Objs = getConsolidatedConfigSettings('allow_cxo_users_see_l3_objs')
  const allowL2UsersSeeAllObjsUnderCxo = getConsolidatedConfigSettings(
    'allow_l2_users_see_all_objs_under_cxo',
  )
  const isDelegateTl = parseInt(localStorage.getItem('dType')) === 5 ? true : false
  const [searchFilterInput, setSearchFilterInput] = React.useState('')

  async function getReports() {
    // We get the action owners data just for Brian and his Delegate AL's. For other's we don't show this dropdown
    if (allowCxoUsersSeeL3Objs || (isDelegateTl && allowL2UsersSeeAllObjsUnderCxo)) {
      let result = null
      result = await getHttpRequest('/team_leaders_for_area_leader', {
        params: {
          includeSelf: true,
          notExcludeCos: true,
        },
      })

      setTeamLeadersData(result?.employees ?? [])
    }
  }

  React.useEffect(() => {
    getReports()
  }, [])

  const handleChangePeopleFilter = (value) => {
    setPeopleFilterValue(value.eId)
  }
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '0.5rem',
          marginLeft: '0.5rem',
          gap: '2rem',
        }}
      >
        {/* Show Action Owner Dropdown only for Brian and Alonso */}
        {allowCxoUsersSeeL3Objs === true || (isDelegateTl && allowL2UsersSeeAllObjsUnderCxo) ? (
          <div data-testid="multi-options-1" style={{ width: '18vw', textAlign: 'left' }}>
            <label for="teamLeadersFilter">
              <b>{t('Common:filter.ActionOwner')}:</b>
            </label>
            <Select
              // value={peopleFilterValue}
              onChange={handleChangePeopleFilter}
              maxMenuHeight={100}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?.eId}
              options={teamLeadersData}
            />
          </div>
        ) : null}
        <div
          style={{
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '0.5rem',
            width: '20rem',
          }}
          onClick={() => {
            let flag = !flaggedOnly
            setShowFlaggedOnly(flag)
          }}
        >
          {!flaggedOnly && (
            <span
              style={{ fontSize: '35px', color: 'rgb(176, 173, 173)' }}
              class="material-symbols-outlined"
            >
              toggle_off
            </span>
          )}

          {flaggedOnly && (
            <span style={{ fontSize: '35px', color: '#4472c4' }} class="material-symbols-outlined">
              toggle_on
            </span>
          )}
          <span>{t('Common:filter.flaggedOnly')}</span>
        </div>
        <div
          className="form-field"
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            gap: '1rem',
            marginTop: '1.5rem',
          }}
        >
          <label for="search" style={{ marginTop: '0.5rem' }}>
            <b>{t('Common:emailReminder.search')}:</b>
          </label>
          <input
            type="text"
            placeholder={t('Common:emailReminder.typeToSearch')}
            value={searchFilterInput}
            onChange={(e) => setSearchFilterInput(e.target.value)}
          />
        </div>
        {/* <div style={{ display: 'flex', flexDirection: 'row', columnGap: '0.5rem', marginTop: '1.5rem', gap: '2rem', width: '100%' }}>
          <label>{t('Common:emailReminder.search')}</label>
        <div className="form-field" style={{ marginTop: '1.5rem', width: '100%' }}>
          <input
            type="text"
            placeholder={t('Common:emailReminder.typeToSearch')}
            value={searchFilterInput}
            onChange={(e) => setSearchFilterInput(e.target.value)}
            style={{ marginLeft: 'auto' }}
          />
        </div>
        </div> */}
      </div>
      <PendingActivitiesList
        refreshBGUScorecardFn={refreshBGUScorecardFn}
        eIdFilter={peopleFilterValue}
        showFlaggedOnly={flaggedOnly}
        fromHomePage={fromHomePage}
        searchFilterInput={searchFilterInput}
      />
    </>
  )
}

import React, { useState, useEffect, useRef } from 'react'
import CommentActivity from './commentActivity'
import './card.scss'
import AddEditMeetingTopicModal from '../../../../../components/AddEditMeetingTopicModal'
import { dayMonthFilter } from '../../../../../utils/time'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import ObjectiveDetailsModal from './component/OjbectiveDetailsModal'
import {
  uploadPptFile,
  getAiPresentationData,
} from '../../../../../api/services/meetingTopic.services'
import { useNavigate, Link } from 'react-router-dom'
import {
  getCompanyConfigSettings,
  getConsolidatedConfigSettings,
  hasRole,
  isAreaLeader,
} from '../../../../../utils/auth'
import Toast from '../../../../../common/toast'
import { statusOptionsArray } from '../../../../../utils/enumValues'
import ObjectivePriorityModal from '../../../../../components/ObjectivePriorityModal'
import useObjectivePriorityUpdate from '../../../../../api/mutation/useObjectivePriorityUpdate'
import useObjectiveStatusUpdate from '../../../../../api/mutation/useObjectiveStatusUpdate'
import Select from 'react-select'
import MeetingTopicCardDropdownMenu from '../../../../../components/MeetingTopicCardDropdownMenu'
import FilingCabinetModal from '../../../../../components/FilingCabinetModal'
import { MentionsInput, Mention } from 'react-mentions'
import MultiAddUsingAudioModal from '../../../../../components/MultiAddUsingAudioModal'
import ReviewGeneratedCommentsActionsModal from '../../../../../components/ReviewGeneratedCommentsActionsModal'
import AtMentionModal from '../../../../../components/AtMentionModal'
import GanttChartModal from '../../../../../components/GanttChartModal/ganttChartModal'
import ObjectiveRagModal from '../../../../../components/ObjectiveRagModal'
import useObjectiveRagUpdate from '../../../../../api/mutation/useObjectiveRagUpdate'
import TagsManagementModal from '../../../../../components/TagsManagementModal'
import useCompleteAllActionsOnATopic from '../../../../../api/mutation/useUpdateActivityStatusUnderTopic'
import { getHttpRequest, postHttpRequest } from '../../../../../api/query/dynamicAPI'
import { saveCustomGantt } from '../../../../../api/services/objectives.services'
import { useMeetingRecording } from '../../../../../utils/meetingRecordingContext/meetingRecordingContext'
import AiPresentationQuestionModal from './component/AIPresentationQuestionmodal'
import AiPresentationModal from './component/AiPresentationModal'
import AiPresentationSlideModal from './component/AiPresentationSlideModal'
import ActionTopicUpdateModal from './component/ActionTopicUpdateModal'
import KPIUpdatesModal from '../../../../../components/KPI/KPIUpdatesModal'
import CardModal from '../../../../../components/CardModal'
import logoWithoutText from '../../../../../assets/transforml_image_no_padding.png'
import { getFirstLetters } from '../../../../../utils/name'
import MeetingDeck from '../../PopupPage'
import SetEmailReminderModal from '../../../../../components/SetEmailReminderModal'

export const PriorityFlagIcon = ({ isPriority, isIconSize = false }) => {
  return isPriority ? (
    <span class="material-symbols-outlined" style={{ color: 'red' }}>
      flag
    </span>
  ) : (
    <span className={'material-symbols-outlined ' + (isIconSize ? 'icon-size' : '')}>flag</span>
  )
}

// const Card = ({ data, index, createFlagStatus, resolved, loadAgain, reload, showInitiativesId, createComment, createActivity, refreshBGUScorecardFn, teamLeadersList }) => {
const Card = ({
  data,
  index,
  createFlagStatus,
  resolved,
  loadAgain,
  reload,
  showInitiativesId,
  createComment,
  createActivity,
  refreshBGUScorecardFn,
  teamLeadersList,
  viewOnlyMode = false,
  flagStatusLoading,
  fromHomePage = false,
  showDropDownMenu = true,
  userList = [],
  updateCardRecordingState = () => Promise.resolve(),
  isPresentationMode = false,
  fromPresentationDeck = false,
  refreshTopic = () => {},
}) => {
  const style = {
    width: '100%',
    padding: '0',
    marginTop: '9px',
    input: {
      overflow: 'auto',
    },
    highlighter: {
      boxSizing: 'border-box',
      overflow: 'hidden',
      height: 70,
      border: '4px solid transparent',
    },
    // resize: 'vertical',
    // overflow: 'auto',
    // height: '100%',
    suggestions: {
      list: {
        maxHeight: 190,
        overflowY: 'auto',
      },
      item: {
        padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        '&focused': {
          backgroundColor: '#d3d3d3',
        },
      },
    },
  }

  const { t } = useTranslation(['MeetingHub', 'Common'])
  const productSettings = getCompanyConfigSettings('productSettings')
  const showCinepolisObjectiveStatus = getCompanyConfigSettings('showCinepolisObjectiveStatus')
  const [isAddMeetingTopicOpen, setIsAddMeetingTopicOpen] = React.useState(false)
  const [isObjDetailsOpen, setIsObjDetailsOpen] = React.useState(false)
  const [objIdIn, setObjIdIn] = React.useState(null)
  const [textInputIn, setTextInputIn] = React.useState('')
  const [cardButtonTypes, setCardButtonTypes] = useState([])

  const [comment, setComment] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isPresentationLoading, setIsPresentationLoading] = useState(false)
  const navigate = useNavigate()

  const meetingDeckUploadRef = useRef(null)

  const [commentActivityData, setCommentActivityData] = useState(
    data?.activities
      ? data?.activities.sort((a, b) => {
          // 1 = true, 0 = false
          return b.isPriority - a.isPriority
        })
      : [],
  )
  const showMeetingDeck = getCompanyConfigSettings('showMeetingDeck') === true
  const [isObjectivePriorityModalOpen, setIsObjectivePriorityModalOpen] = React.useState(false)
  const mutationObjPriorityUpdate = useObjectivePriorityUpdate()
  const canChangeObjPriority =
    getConsolidatedConfigSettings('can_change_obj_priority') || isAreaLeader()
  const canChangeObjStatus =
    getConsolidatedConfigSettings('can_change_obj_status') || isAreaLeader()

  const mutationObjStatusUpdate = useObjectiveStatusUpdate()
  const [isViewFilesModalOpen, setIsViewFilesModalOpen] = React.useState(false)

  const [mentionsList, setMentionsList] = useState([])
  const [isMultiAddUsingAudioModalOpen, setIsMultiAddUsingAudioModalOpen] = React.useState(false)
  const [generatedCommentsAndActions, setGeneratedCommentsAndActions] = React.useState([])
  const [isReviewGeneratedCommentsActionsModalOpen, setIsReviewGeneratedCommentsActionsModalOpen] =
    React.useState(false)

  const [commentAndDecisionDisabled, setCommentAndDecisionDisabled] = useState(false)
  const [isAtMentionModalOpen, setIsAtMentionModalOpen] = useState(false)
  const [isGanttChartModalOpen, setIsGanttChartModalOpen] = React.useState(false)
  const [isRagModalOpen, setIsRagModalOpen] = React.useState(false)
  const [aiButtonLoading, setAiButtonLoading] = useState(false)
  const [fromAi, setFomAi] = useState(false)
  const [isCustomGantt, setIsCustomGantt] = React.useState(false)
  const enableCustomGantt = getCompanyConfigSettings('enableCustomGantt') === true

  const enableGanttChartInMh = getCompanyConfigSettings('enableGanttChartInMh')
  const mutationObjRagUpdate = useObjectiveRagUpdate()
  const updateStatusUnderTopicMutation = useCompleteAllActionsOnATopic()
  const [cardFocused, setCardFocused] = useState(false)
  const cardRef = useRef(null)

  const canUseMeetingSummarization =
    getConsolidatedConfigSettings('can_use_meeting_summarization') === true
  const { state } = useMeetingRecording()
  const [isAiPresentationModalOpen, setIsAiPresentationModalOpen] = React.useState(false)
  const [showEng, setShowEng] = React.useState(
    getCompanyConfigSettings('languageSettings') !== 'es',
  )
  const [isAiPresentationDraftModalOpen, setIsAiPresentationDraftModalOpen] = React.useState(false)
  const [presentationMateirial, setPresentationMaterial] = React.useState(null)
  const [isAiPresentationSlidesModalOpen, setIsAiPresentationSlidesModalOpen] =
    React.useState(false)
  const [slidesMaterial, setSlidesMaterial] = React.useState(null)
  const [existingSuggestions, setExistingSuggestions] = React.useState(null)
  const [existingSlides, setExistingSlides] = React.useState(null)

  const [isTopicSelectionModalOpen, setIsTopicSelectionModalOpen] = React.useState(false)
  const [isOpenKPIUpdatesModal, setIsOpenKPIUpdatesModal] = React.useState(false)
  const [selectedKpi, setSelectedKpi] = React.useState(null)

  const [isExpandedCardModal, setIsExpandedCardModal] = useState(false)
  const [isMeetingDeckOpen, setIsMeetingDeckOpen] = useState(false)
  const [isSetReminderModalOpen, setIsSetReminderModalOpen] = useState(false)

  let statusOptions = []
  if (productSettings) {
    statusOptions = [
      { value: 'NEW', label: 'Pre-PD0' },
      { value: 'NOT_STARTED', label: 'PD0' },
      { value: 'IN_PROGRESS', label: 'PD1' },
      { value: 'COMPLETE', label: 'PDf' },
      { value: 'DEPRIORITIZED', label: 'PDx' },
      { value: 'UNKNOWN_PROGRESS', label: '' },
    ]
  } else if (showCinepolisObjectiveStatus) {
    statusOptions = [
      { value: 'UNKNOWN_PROGRESS', label: '' },
      { value: 'NEW', label: 'Planning' },
      { value: 'NOT_STARTED', label: 'Implementation' },
      { value: 'IN_PROGRESS', label: 'Hypercare' },
      { value: 'HANDOVER', label: 'Handover' },
      { value: 'UNKNOWN_PROGRESS', label: '' },
    ]
  }

  const rags = {
    GREEN: 0,
    YELLOW: 1,
    RED: 2,
  }
  const [isManageTagsModalOpen, setIsManageTagsModalOpen] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState(
    isPresentationMode && state.hasSummarizationStarted,
  )

  useEffect(() => {
    setCommentActivityData(data?.activities ? data?.activities : [])
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [data?.activities])

  useEffect(() => {
    if (generatedCommentsAndActions && generatedCommentsAndActions.length > 0) {
      setIsReviewGeneratedCommentsActionsModalOpen(true)
    }
  }, [generatedCommentsAndActions])

  async function sendComment(data, isDecision = true) {
    setError('')

    const formData = {
      commentText: comment,
      goalId: data.objectiveId,
      isDecision: isDecision,
      mentions: mentionsList.map((mention) => mention.id),
    }

    formData.umtId = data.umtId

    if (state.meetingInstanceId) {
      formData.meetingInstanceId = state.meetingInstanceId
    }

    createComment(formData, index)
  }

  async function onChangePpt(e, data) {
    const files = e.target.files

    if (files.length !== 0) {
      setIsPresentationLoading(true)
      let formData = new FormData()

      formData.append('umtId', data.umtId)

      formData.append('file', files[0], files[0].name)

      let response = await uploadPptFile(formData)
      setIsPresentationLoading(false)
      if (response.upload_status === 'success') {
        loadAgain()
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.fileUploadSuccess'),
        })
      } else {
        let errorResult = response.data.upload_status
        if (errorResult === 'timeout') {
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.fileUploadRequestTimeout'),
          })
        } else if (errorResult === 'malicious') {
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.fileScanningFailed'),
          })
        } else {
          // Default error handling for other cases
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
          })
        }
      }
    }
  }

  const handleUploadMeetingDeckClick = () => {
    meetingDeckUploadRef.current.click()
  }

  const resolve = async (isResolved) => {
    const result = await Swal.fire({
      title: !isResolved
        ? t('Common:modalMessages.areYouSureResolveCompleteMeetingTopic')
        : t('Common:modalMessages.areYouSureUnresolveMeetingTopic'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })

    if (result.isConfirmed) {
      createFlagStatus(data, 'status', index)

      //add another popup to solve actions
      if (
        !isResolved &&
        data?.activities.some((activity) => activity.milestoneId != 0 && activity.progress != 1)
      ) {
        const result = await Swal.fire({
          title: t('Common:modalMessages.doyouWantToCompleteActions'),
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: t('Common:commonButtons.yes'),
          cancelButtonText: t('Common:commonButtons.no'),
        })
        if (result.isConfirmed) {
          await closAllActionsOnATopic(data.objectiveId, data.umtId)
        }
      }
    }
  }

  const closAllActionsOnATopic = async (objId, umtId) => {
    const postObject = {
      objId,
      umtId,
    }

    await updateStatusUnderTopicMutation.mutateAsync(postObject, {
      onSuccess: (response) => {
        Toast.fire({
          icon: 'success',
          title:
            "<div style='color:green'>" + t('meetingTopics.completedAnActionMessage') + '</div>',
        })
      },
      onError: () => {},
    })
  }

  let hasNoActivities = data?.activities.length === 0 ? true : false

  // TODO Swetha: Move this to backend
  const noActivitiesMessage = () => {
    return (
      <p
        style={{
          display: 'flex',
          color: 'red',
          padding: '0.3rem',
          borderRadius: '30px',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'left',
          margin: '0',
        }}
      >
        {/* borderBottom: "3px solid red", */}
        {t('meetingTopics.noActionsOrDecisionsMessage')}
      </p>
    )
  }

  // TODO Swetha: Move this to backend
  const noNewProgressMessage = () => {
    return (
      <div
        style={{
          backgroundColor: '#ED522E',
          color: 'white',
          padding: '0.1rem 0.2rem',
          borderRadius: '0.3rem',
          marginBottom: '0.3rem',
          width: 'fit-content',
        }}
      >
        {t('meetingTopics.noNewProgress')}
      </div>
    )
  }

  const getPdStatus = (status) => {
    const matchingOption = statusOptionsArray.find((option) => option.value === status)

    if (matchingOption) {
      return matchingOption.label
    } else return ''
  }

  const closeObjectivePriorityModal = () => {
    setIsObjectivePriorityModalOpen(false)
  }

  const handleObjPriorityChange = async (initiativeId, priority) => {
    await mutationObjPriorityUpdate.mutateAsync(
      {
        initiativeId: initiativeId,
        priority: priority,
      },
      {
        onSuccess: (response) => {
          if (response) {
            closeObjectivePriorityModal()
            loadAgain()
          } else {
          }
        },
      },
    )
  }

  const handleObjStatusChange = async (status, objective) => {
    let runMutation = true
    if (status.label === 'PDx' || status.label === 'PDf') {
      let result = await Swal.fire({
        title: t('Common:modalMessages.areYouSureYouWantToChangeTheStatusToPDfPDx', {
          X: status.label,
        }),
        showCancelButton: true,
        confirmButtonText: t('Common:commonButtons.yes'),
        cancelButtonText: t('Common:commonButtons.no'),
      })

      if (result.isConfirmed !== null) {
        runMutation = result.isConfirmed
      }
    }

    if (runMutation === true) {
      await mutationObjStatusUpdate.mutateAsync(
        {
          objective: objective,
          newStatus: status.value,
        },
        {
          onSuccess: (response) => {
            if (response) {
              loadAgain()
            } else {
            }
          },
        },
      )
    }
  }

  async function filterOpenOnly() {
    // if user clicks on same button twice, remove it from the array (so not underline)
    if (cardButtonTypes.includes('OpenOnly')) {
      setCardButtonTypes((prev) => prev.filter((item) => item !== 'OpenOnly'))
    } else {
      setCardButtonTypes((prev) => [...prev, 'OpenOnly'])
    }
  }

  const onClickEdit = (data) => {
    setObjIdIn(data?.objectiveId)
    setTextInputIn(data?.actionNeeded)
    setIsAddMeetingTopicOpen(true)
  }

  const onClickAiPresentation = async () => {
    const result = await getAiPresentationData(data.umtId)
    if (result?.initialSlidesGenerated != null && result?.finalSlidesGenerated == null) {
      // setPresentationMaterial(result)
      // setIsAiPresentationDraftModalOpen(true)
      setExistingSuggestions(result?.initialSlidesGenerated)
      setIsAiPresentationDraftModalOpen(true)
    } else if (result?.finalSlidesGenerated != null) {
      setExistingSuggestions(result?.initialSlidesGenerated)
      setExistingSlides(result?.finalSlidesGenerated)
      setIsAiPresentationSlidesModalOpen(true)
    } else {
      setIsAiPresentationModalOpen(true)
    }
  }

  const setGeneratedCommentsActionsForReview = (jsonResponse) => {
    let commentsAndActions = [...jsonResponse] // Use jsonResponse instead of arg
    commentsAndActions = commentsAndActions.map((newCommentOrAction) => {
      newCommentOrAction.include = true
      return newCommentOrAction // Return the modified object, not the entire array
    })
    setGeneratedCommentsAndActions(commentsAndActions)
  }

  const handleRagChange = async (obj, id) => {
    let rag = getKeyByValue(rags, id)
    let value = { rag: rag, objectiveId: obj.objectiveId }
    await mutationObjRagUpdate.mutateAsync(value, {
      onSuccess: (response) => {
        if (response) {
          loadAgain()
        } else {
        }
      },
      onError: () => {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      },
    })
  }

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value)
  }

  async function onChangeGanttFile(e) {
    const files = e.target.files

    if (files.length !== 0) {
      setIsLoading(true)
      let formData = new FormData()

      formData.append('objId', parseInt(data.objectiveId))
      formData.append('file', files[0]) // Append the file to FormData

      try {
        let response = await saveCustomGantt(formData)

        if (response.upload_status === 'success') {
          Toast.fire({
            icon: 'success',
            title: t('Common:modalMessages.fileUploadSuccess'),
          })
        } else {
          let errorResult = response.upload_status
          if (errorResult === 'timeout') {
            Toast.fire({
              icon: 'error',
              title: t('Common:modalMessages.fileUploadRequestTimeout'),
            })
          } else if (errorResult === 'malicious') {
            Toast.fire({
              icon: 'error',
              title: t('Common:modalMessages.fileScanningFailed'),
            })
          } else {
            // Default error handling for other cases
            Toast.fire({
              icon: 'error',
              title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
            })
          }
        }
      } catch (error) {
        console.error('Error uploading file:', error)
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      } finally {
        setIsLoading(false)
      }
    }
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '2rem', // Set the minimum height to 2rem
      height: '2rem', // Set the height to 2rem
      width: '10rem', // Set the minimum width to 10rem
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '2rem', // Set the height to 2rem
      padding: '0 0.5rem',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0', // Remove default margin
      padding: '0',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '2rem', // Set the height to 2rem
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 20, // Ensure the menu appears above other elements if needed
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '10rem', // Set the maximum height of the options container
      overflowY: 'auto', // Enable vertical scrolling if the content exceeds the max height
    }),
    option: (provided, state) => ({
      ...provided,
      minHeight: state.data.label === '' ? '2rem' : 'auto', // Ensure a minimum height for blank options
    }),
  }

  const handleOpenTagsModal = () => {
    setIsManageTagsModalOpen(true)
  }

  const hanldClickCardFocus = () => {
    setCardFocused(true)
  }

  const hanldClickCard = async () => {
    if (state.isCardRecordingStarted[data?.umtId]) {
      await updateCardRecordingState(data.umtId, data.objectiveId, false, 'stop')
    } else {
      await updateCardRecordingState(data.umtId, data.objectiveId, true, 'click')
    }
  }

  const handleClickOutside = (event) => {
    if (cardRef.current && !cardRef.current.contains(event.target)) {
      setCardFocused(false)
    }
  }

  return (
    <>
      {isRagModalOpen === true && (productSettings || showCinepolisObjectiveStatus) && data && (
        <ObjectiveRagModal
          objective={data}
          isModalOpen={isRagModalOpen}
          closeModal={() => {
            setIsRagModalOpen(false)
          }}
          handleSubmit={handleRagChange}
          button_num={rags[data.objectiveRag]}
        />
      )}
      {isGanttChartModalOpen === true && (
        <GanttChartModal
          isModalOpen={isGanttChartModalOpen}
          closeModal={() => {
            setIsGanttChartModalOpen(false)
          }}
          objId={data.objectiveId}
          isCustomGantt={isCustomGantt}
        />
      )}
      {isObjectivePriorityModalOpen === true && objIdIn && (
        <ObjectivePriorityModal
          objective={data}
          isModalOpen={isObjectivePriorityModalOpen}
          closeModal={closeObjectivePriorityModal}
          handleSubmit={handleObjPriorityChange}
        />
      )}
      {isAddMeetingTopicOpen && (
        <AddEditMeetingTopicModal
          isModalOpen={isAddMeetingTopicOpen}
          closeModal={() => {
            setIsAddMeetingTopicOpen(false)
            loadAgain((old) => !old)
          }}
          loadAgain={loadAgain}
          objIdIn={objIdIn}
          textInputIn={textInputIn}
          topicId={data.umtId}
          topicType={data?.topicType}
          refreshBGUScorecardFn={refreshBGUScorecardFn}
          teamLeadersList={teamLeadersList}
          passedTopicOwner={{ eId: data?.topicOwnerId, name: data?.topicOwnerName }}
          createdByName={data.createdByName}
          tags={data?.tags}
          isPriority={data?.isPriority ?? false}
          selectedLeaderToLoadMH={data.objOwnerId}
        />
      )}

      {isObjDetailsOpen && (
        <ObjectiveDetailsModal
          isModalOpen={isObjDetailsOpen}
          closeModal={() => {
            setIsObjDetailsOpen(false)
          }}
          objIdIn={objIdIn}
          showInitiativesId={showInitiativesId}
        />
      )}

      {isViewFilesModalOpen && (
        <FilingCabinetModal
          objId={parseInt(objIdIn)}
          mtId={data.umtId}
          topicType={data?.topicType}
          closeModal={() => {
            setIsViewFilesModalOpen(false)
          }}
        />
      )}

      {isMultiAddUsingAudioModalOpen && (
        <MultiAddUsingAudioModal
          startRecording={true}
          isModalOpen={isMultiAddUsingAudioModalOpen}
          type="meetingHubCommentsActions"
          setGeneratedText={setGeneratedCommentsActionsForReview}
          closeModal={() => {
            setIsMultiAddUsingAudioModalOpen(false)
          }}
        />
      )}

      {isReviewGeneratedCommentsActionsModalOpen && (
        <ReviewGeneratedCommentsActionsModal
          isModalOpen={isReviewGeneratedCommentsActionsModalOpen}
          closeModal={() => {
            setFomAi(false)
            loadAgain((old) => !old)
            setIsReviewGeneratedCommentsActionsModalOpen(false)
            refreshBGUScorecardFn()
            setGeneratedCommentsAndActions(null)
          }}
          handleCancel={() => {
            setIsReviewGeneratedCommentsActionsModalOpen(false)
            setGeneratedCommentsAndActions(null)
          }}
          generatedCommentsActions={generatedCommentsAndActions}
          topicId={data.umtId}
          objId={data.objectiveId}
          userList={userList}
          fromAi={fromAi}
        />
      )}

      {isAtMentionModalOpen && (
        <AtMentionModal
          isModalOpen={isAtMentionModalOpen}
          closeModal={() => {
            setIsAtMentionModalOpen(false)
          }}
        />
      )}
      {isManageTagsModalOpen && (
        <TagsManagementModal
          closeModal={() => {
            loadAgain((old) => !old)
            setIsManageTagsModalOpen(false)
          }}
          meetingtopic={true}
          meetingtopicId={data.umtId}
        />
      )}
      {isAiPresentationModalOpen && (
        <AiPresentationQuestionModal
          isModalOpen={isAiPresentationModalOpen}
          closeModal={() => {
            setIsAiPresentationModalOpen(false)
          }}
          topic={data}
          showEng={showEng}
          setShowEng={setShowEng}
          setIsAiPresentationDraftModalOpen={setIsAiPresentationDraftModalOpen}
          setPresentationMaterial={setPresentationMaterial}
        />
      )}

      {isAiPresentationDraftModalOpen && (
        <AiPresentationModal
          isModalOpen={isAiPresentationDraftModalOpen}
          closeModal={() => {
            setExistingSuggestions(null)
            setIsAiPresentationDraftModalOpen(false)
          }}
          meetingTopic={data}
          presentationMateirial={presentationMateirial}
          showEng={showEng}
          setIsAiPresentationQuestionModalOpen={setIsAiPresentationModalOpen}
          setIsAiPresentationSlidesModalOpen={setIsAiPresentationSlidesModalOpen}
          umtId={data.umtId}
          setSlidesMaterial={setSlidesMaterial}
          existingSuggestions={existingSuggestions}
          setExistingSuggestions={setExistingSuggestions}
          loadAgain={loadAgain}
        />
      )}

      {isAiPresentationSlidesModalOpen && (
        <AiPresentationSlideModal
          isModalOpen={isAiPresentationSlidesModalOpen}
          closeModal={() => {
            setExistingSlides(null)
            setIsAiPresentationSlidesModalOpen(false)
          }}
          meetingTopic={data}
          showEng={showEng}
          setIsAiPresentationDraftModalOpen={setIsAiPresentationDraftModalOpen}
          umtId={data.umtId}
          slidesMaterial={slidesMaterial}
          existingSlides={existingSlides}
          existingSuggestions={existingSuggestions}
          loadAgain={loadAgain}
          setExistingSuggestions={setExistingSuggestions}
          setExistingSlides={setExistingSlides}
        />
      )}
      {isTopicSelectionModalOpen && data && (
        <ActionTopicUpdateModal
          umt={{ ...data, umtDescription: data?.actionNeeded }}
          umtId={data.umtId}
          closeModal={() => {
            setIsTopicSelectionModalOpen(false)
          }}
          loadAgain={loadAgain}
          isFromTopic={true}
        />
      )}
      {isOpenKPIUpdatesModal && selectedKpi && (
        <KPIUpdatesModal
          handleCancel={() => {
            setIsOpenKPIUpdatesModal(false)
            setSelectedKpi(null)
            loadAgain()
          }}
          reloadBackground={() => {}}
          selectedKpiUpdate={selectedKpi}
          objId={data.objectiveId}
          canAddUpdate={selectedKpi.isCompleted === false}
          canEditUpdate={selectedKpi.isCompleted === false}
        />
      )}

      {isExpandedCardModal && (
        <CardModal
          isModalOpen={isExpandedCardModal}
          closeModal={() => {
            setIsExpandedCardModal(false)
          }}
          umtId={data.umtId}
          topicType={data.topicType}
        />
      )}

      {isMeetingDeckOpen && (
        <MeetingDeck
          closeModal={() => {
            setIsMeetingDeckOpen(false)
            refreshTopic(data.umtId)
          }}
          umtId={data.umtId}
          topicType={data.topicType}
          readOnly={false}
          redirectTo="meetingTopics"
          fromKanban={false}
        />
      )}
      {isSetReminderModalOpen && (
        <SetEmailReminderModal
          isModalOpen={isSetReminderModalOpen}
          closeModal={() => {
            setIsSetReminderModalOpen(false)
          }}
          // umtId={data.umtId}
        />
      )}

      <div
        className="Card"
        key={data.topicId}
        style={{
          border: cardFocused ? '3px solid #8B8AB9' : '2px solid rgba(128, 128, 128, 0.2392156863)',
          height: isPresentationMode && isCollapsed ? '18rem' : 'auto',
        }}
        ref={cardRef}
        onClick={hanldClickCardFocus}
      >
        <div className="card_header" style={{ marginBottom: '0.88rem' }}>
          {data?.isAddedByAi && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderBottom: '1px solid lightgray',
                paddingBottom: '0.5rem',
              }}
            >
              <img
                className="logo-new-folded"
                style={{ width: '1.5rem', height: 'auto', paddingRight: '0.3rem' }}
                src={logoWithoutText}
                alt="TransforML Logo"
              />
              <span
                style={{
                  fontStyle: 'italic',
                  fontWeight: 'bold',
                  fontSize: '18px',
                  color: 'rgb(91, 132, 203)',
                }}
              >
                {t('meetingTopics.ai')}
              </span>
            </div>
          )}
          <div className="top_title_box">
            {isPresentationMode && (
              <div style={{ marginLeft: 'auto' }}>
                {isCollapsed && (
                  <span
                    class="material-symbols-outlined"
                    onClick={() => {
                      setIsCollapsed(!isCollapsed)
                    }}
                    style={{ fontSize: '30px', cursor: 'pointer' }}
                  >
                    arrow_drop_up
                  </span>
                )}

                {!isCollapsed && (
                  <span
                    class="material-symbols-outlined"
                    onClick={() => {
                      setIsCollapsed(!isCollapsed)
                    }}
                    style={{ fontSize: '30px', cursor: 'pointer' }}
                  >
                    arrow_drop_down
                  </span>
                )}
              </div>
            )}
            <span
              className={`top_title

                        ${data?.topicType == '1' ? 'blocker_title' : ''}
                        ${data?.topicType == '2' ? 'decision_title' : ''}
                        ${data?.topicType == '3' ? 'update_title' : ''}
                        ${data?.topicType == '4' ? 'admin_topic_title' : ''}

                        `}
            >
              {`
                        ${data?.topicType == '1' ? t('meetingTopics.blocker') : ''}
                        ${data?.topicType == '2' ? t('meetingTopics.guidance') : ''}
                        ${data?.topicType == '3' ? t('meetingTopics.update') : ''}
                        ${data?.topicType == '4' ? t('meetingTopics.adminTopic') : ''}

                        `}
            </span>
            <span
              className={`top_line

                        ${data?.topicType == '1' ? 'top_line_blocker' : ''}
                        ${data?.topicType == '2' ? 'top_line_decision' : ''}
                        ${data?.topicType == '3' ? 'top_line_update' : ''}
                        ${data?.topicType == '4' ? 'top_line_admin_topic' : ''}

                       `}
            ></span>
            {/* TODO Swetha:  Assign it directly from backend */}
            <span>{dayMonthFilter(data?.openedTime)}</span>
            {!viewOnlyMode && (
              <span>
                {flagStatusLoading === true ? (
                  <div style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
                    <div
                      className="loading-spinner"
                      style={{ width: '1rem', height: '1rem', margin: '0.3rem' }}
                    ></div>
                  </div>
                ) : (
                  <button
                    class="icon-button-no-border"
                    type="button"
                    onClick={(e) => createFlagStatus(data, 'flag', index)}
                    onKeyDown={(e) => createFlagStatus(data, 'flag', index)}
                  >
                    <PriorityFlagIcon isPriority={data.isPriority} />
                  </button>
                )}
              </span>
            )}
            {!isPresentationMode && (
              <span
                class="material-symbols-outlined"
                style={{ cursor: 'pointer' }}
                onClick={() => setIsExpandedCardModal(true)}
              >
                open_in_full
              </span>
            )}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {state.isGeneratingActionsCommentsFromRecording[data?.umtId] ? (
              <div
                style={{ display: 'flex', flexFlow: 'column', height: '100%', margin: 'auto 0' }}
              >
                <div
                  className="loading-spinner"
                  style={{ width: '1rem', height: '1rem', marginLeft: '1rem' }}
                ></div>
              </div>
            ) : (
              <>
                {!viewOnlyMode &&
                  canUseMeetingSummarization &&
                  state.isCardRecordingStarted[data?.umtId] && (
                    <div
                      class="circle-container"
                      onClick={hanldClickCard}
                      style={{
                        cursor: 'pointer',
                        margin: 'auto 0px auto 5px',
                        width: '32px',
                        height: '32px',
                      }}
                    >
                      <div class="circle"></div>
                      <div class="icon-container">
                        <span
                          className="material-symbols-outlined icon-size"
                          style={{ fontSize: '24px' }}
                        >
                          speech_to_text
                        </span>
                      </div>
                    </div>
                  )}

                {!viewOnlyMode &&
                  canUseMeetingSummarization &&
                  !state.isCardRecordingStarted[data?.umtId] && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: 'auto 0px auto 5px',
                      }}
                    >
                      <button
                        type="button"
                        className="mic-button tonedown-blue-btn"
                        onClick={hanldClickCard}
                        style={{ width: '32px', height: '32px' }}
                      >
                        <span
                          className="material-symbols-outlined icon-size"
                          style={{ fontSize: '24px' }}
                        >
                          speech_to_text
                        </span>
                      </button>
                    </div>
                  )}
              </>
            )}
          </div>
          {!isCollapsed && (
            <>
              <span style={{ color: 'rgb(91,132,203)', textAlign: 'left' }}>
                {t('meetingTopics.topic')}
              </span>
              <span
                className="body_text line_break_div"
                id="meetingHubTutorial5"
                style={{
                  minHeight: '30px',
                  borderBottom: 'none',
                  fontSize: '16px',
                  paddingTop: '0',
                  color: 'black',
                  textAlign: 'left',
                }}
              >
                {data?.actionNeeded}{' '}
                {data.associatedKpiId && (
                  <>
                    -
                    <span
                      style={{
                        color: '#0926D5',
                        textDecoration: 'underline',
                      }}
                    >
                      <span
                        style={{ cursor: 'pointer', fontSize: '16px' }}
                        onClick={async () => {
                          try {
                            let response = await getHttpRequest(
                              `/get_kpi_details/${data.associatedKpiId}`,
                            )
                            setSelectedKpi(response)
                            setIsOpenKPIUpdatesModal(true)
                          } catch (error) {
                            Toast.fire({
                              icon: 'error',
                              title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
                            })
                            console.log('Error fetching KPI details', error)
                          }
                        }}
                      >
                        &nbsp;
                        {data?.kpiName ?? t('meetingTopics.fromKpi')}
                      </span>
                    </span>
                  </>
                )}
              </span>

              {/* TODO Swetha: Move this to backend */}

              {!hasNoActivities &&
                data?.topicType !== 3 &&
                data?.hasProgressLast7Days === false &&
                noNewProgressMessage()}
              <div className="card_header_bottom_box">
                <span class="tooltip">
                  {/* TODO Swetha : Move this to backend */}
                  <span
                    className="round_box"
                    style={{ fontSize: '15px', height: '15px', width: '15px' }}
                    id="meetingTutorial7"
                  >
                    {getFirstLetters(
                      data?.topicOwnerEmployeeName?.firstName,
                      data?.topicOwnerEmployeeName?.lastName,
                    )}
                  </span>
                  <span class="tooltiptextname" style={{ marginLeft: '-20px' }}>
                    {data?.topicOwnerName ?? ''}{' '}
                  </span>
                </span>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    columnGap: '0.5rem',
                  }}
                  id="meetingHubTutorial8"
                >
                  {data?.objLevel != 1 && (
                    <div
                      class="fitted-button blue tooltip"
                      onClick={async () => {
                        setIsCustomGantt(false)
                        setIsGanttChartModalOpen(true)
                      }}
                      title="Gantt Chart View"
                    >
                      <span class="material-symbols-outlined icon-size-l">view_timeline</span>
                    </div>
                  )}
                  {!viewOnlyMode && (
                    <>
                      <div
                        class="fitted-button blue tooltip"
                        onClick={() => {
                          setObjIdIn(data?.objectiveId)
                          setIsObjDetailsOpen(true)
                        }}
                        onKeyDown={() => {
                          setObjIdIn(data?.objectiveId)
                          setIsObjDetailsOpen(true)
                        }}
                      >
                        <span class="material-symbols-outlined">history</span>
                        <span class="tooltiptext"> {t('Common:tooltip.showDecisionHistory')} </span>
                      </div>
                      {/* Removed icon due to lack of space, commenting out in case there is some restruturing and we decide to bring it back */}
                      {/* <div
                      class="fitted-button blue tooltip"
                      onClick={() => {
                        setObjIdIn(data?.objectiveId)
                        setTextInputIn(data?.actionNeeded)
                        setIsAddMeetingTopicOpen(true)
                      }}
                      onKeyDown={() => {
                        setObjIdIn(data?.objectiveId)
                        setTextInputIn(data?.actionNeeded)
                        setIsAddMeetingTopicOpen(true)
                      }}
                    >
                      <span class="material-symbols-outlined">edit</span>
                      <span class="tooltiptext"> {t('Common:tooltip.edit')} </span>
                    </div> */}
                    </>
                  )}

                  {showMeetingDeck && (
                    <>
                      {!viewOnlyMode && (
                        <>
                          {isPresentationLoading ? (
                            <div style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
                              <div
                                className="loading-spinner"
                                style={{ width: '1rem', height: '1rem', marginLeft: '1rem' }}
                              ></div>
                            </div>
                          ) : (
                            <div
                              class="fitted-button blue tooltip"
                              onClick={handleUploadMeetingDeckClick}
                              onKeyDown={handleUploadMeetingDeckClick}
                            >
                              <input
                                type="file"
                                id="myFile"
                                name="filename"
                                ref={meetingDeckUploadRef}
                                accept=".ppt, .pptx, .pdf"
                                onChange={(e) => onChangePpt(e, data)}
                                style={{ display: 'none' }}
                                title={
                                  data.hasMeetingDeck
                                    ? t('meetingTopics.fileAlreadyExistsTooltip')
                                    : ''
                                }
                              ></input>

                              <span class="material-symbols-outlined" id="meetingHubTutorial8a">
                                upload
                              </span>
                              <span class="tooltiptext">
                                {' '}
                                {t('Common:tooltip.uploadAPresentation')}{' '}
                              </span>
                            </div>
                          )}
                        </>
                      )}
                      {data.hasMeetingDeck && (
                        <>
                          <div
                            class="fitted-button blue tooltip"
                            onClick={() => {
                              setIsMeetingDeckOpen(true)
                            }}
                          >
                            <span class="material-symbols-outlined">slideshow</span>
                            <span class="tooltiptext">
                              {' '}
                              {t('Common:tooltip.viewUploadedPresentation')}{' '}
                            </span>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {showDropDownMenu && (
                    <MeetingTopicCardDropdownMenu
                      data={data}
                      viewOnlyMode={viewOnlyMode}
                      showMeetingDeck={showMeetingDeck}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      loadAgain={() => {
                        loadAgain()
                      }}
                      onClickViewFiles={() => {
                        setObjIdIn(data?.objectiveId)
                        setIsViewFilesModalOpen(true)
                      }}
                      onClickEdit={() => {
                        onClickEdit(data)
                      }}
                      onClickUploadCustomGanttData={(e) => onChangeGanttFile(e)}
                      enableCustomGantt={enableCustomGantt}
                      onClickOpenCustomGantt={() => {
                        setIsCustomGantt(true)
                        setIsGanttChartModalOpen(true)
                      }}
                      onClickAiPresentation={() => {
                        onClickAiPresentation()
                      }}
                      fromPresentationDeck={fromPresentationDeck}
                      onClickMerge={() => {
                        setIsTopicSelectionModalOpen(true)
                      }}
                      onClickSetReminder={() => {
                        setIsSetReminderModalOpen(true)
                      }}
                    />
                  )}
                </div>
              </div>
              <div
                style={{
                  paddingTop: '0.2rem',
                  marginBottom: '0.3rem',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                <span
                  class="material-symbols-outlined icon-size"
                  style={{ cursor: 'pointer' }}
                  onClick={handleOpenTagsModal}
                >
                  sell
                </span>
                {data?.tags?.map((tag, tagIndex) => {
                  return (
                    <div
                      key={tagIndex}
                      style={{
                        backgroundColor: '#e6e6e6',
                        color: 'black',
                        padding: '0.1rem 0.2rem',
                        borderRadius: '0.5rem',
                        marginLeft: '0.5rem',
                        marginBottom: '0.3rem',
                      }}
                    >
                      {tag.tagName}
                    </div>
                  )
                })}
                {data?.meetings?.map((meeting, meetingIndex) => {
                  return (
                    <div
                      key={meetingIndex}
                      style={{
                        backgroundColor: '#FBD5CC',
                        color: 'black',
                        padding: '0.1rem 0.2rem',
                        borderRadius: '0.5rem',
                        marginLeft: '0.5rem',
                        marginBottom: '0.3rem',
                      }}
                    >
                      {meeting.meetingName}
                    </div>
                  )
                })}
              </div>
            </>
          )}
        </div>
        {!isCollapsed && (
          <>
            <div className="card_body" style={{ gap: '3px' }}>
              <span style={{ color: 'rgb(91,132,203)', textAlign: 'left' }}>
                {productSettings ? 'Initiative' : t('meetingTopics.objective')}
              </span>
              <div style={{ display: 'flex', flexDirection: 'row', color: 'grey' }}>
                {(productSettings || showCinepolisObjectiveStatus) && !data.isGeneralObjective && (
                  <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>
                    <div
                      style={{
                        backgroundColor: `${data?.objectiveRag.toLowerCase()}`,
                        width: '1.5rem',
                        height: '1.5rem',
                        marginTop: '0.2rem',
                        border: `${data?.objectiveRag === 'UNKNOWN_RAG' ? '2px solid #ededed' : 'none'}`,
                      }}
                      onClick={() => {
                        if (canChangeObjStatus) setIsRagModalOpen(true)
                      }}
                    ></div>
                    <div>
                      <div style={{ maxWidth: '10rem' }}>
                        <Select
                          placeholder={t('Common:filter.select') + '...'}
                          value={statusOptions.find((option) => option.value === data?.objStatus)}
                          onChange={(selectedOption) => handleObjStatusChange(selectedOption, data)}
                          maxMenuHeight={100}
                          getOptionLabel={(statusOptions) => statusOptions?.label}
                          getOptionValue={(statusOptions) => statusOptions?.value}
                          options={statusOptions}
                          isDisabled={!canChangeObjStatus}
                          styles={customStyles}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {/* {productSettings && data?.objStatus &&(<b>
          {getPdStatus(data?.objStatus)}
        </b>)} */}
              </div>
              {showInitiativesId && data?.initiativeId > -1 ? (
                <div
                  className="card_heading"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBlockStart: data?.complete ? '0.5rem' : '1rem',
                    wordBreak: 'break-word',
                    textAlign: 'left',
                  }}
                  id="meetingHubTutorial6"
                >
                  <>
                    <button
                      className="icon-button group-icon-button"
                      style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        fontSize: '12px',
                        marginRight: '0.2rem',
                      }}
                      onClick={() => {
                        setObjIdIn(data?.objectiveId)
                        setIsObjectivePriorityModalOpen(true)
                      }}
                      disabled={!canChangeObjPriority}
                    >
                      <span style={{ width: '5rem' }}>
                        {data.priority == 0 ? '--' : data.priority}
                      </span>
                    </button>
                    <span>
                      {data?.isCompleted && (
                        <span style={{ color: 'grey' }}> {t('Common:completedCaps')}</span>
                      )}
                      {data?.isCompleted && <b> &nbsp;{'-'} &nbsp;</b>}
                      {data?.initiativeId.toString()?.padStart(5, '0')} - {data.statement}
                    </span>
                  </>
                </div>
              ) : (
                <div
                  className="card_heading"
                  style={{
                    wordBreak: 'break-word',
                    marginBottom: '0.4rem',
                  }}
                  id="meetingHubTutorial6"
                >
                  <span>
                    {data?.isCompleted && (
                      <span style={{ color: 'grey', marginTop: '0.1rem' }}>
                        <b>{t('Common:completedCaps')}</b>
                      </span>
                    )}
                    {data?.isCompleted && <b> &nbsp;{'-'} &nbsp;</b>}
                    {data?.statement}
                  </span>
                </div>
              )}

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderTop: '1px solid rgba(128, 128, 128, 0.2392156863)',
                }}
              >
                {!viewOnlyMode && (
                  <>
                    {/* TODO Swetha: Look at the possibility if something can be done here */}
                    <MentionsInput
                      id="meetingHubTutorial9"
                      className="body_bottom_box"
                      value={comment}
                      onChange={(e, v, nv, mentions) => {
                        if (e.target.value.includes('@')) {
                          setCommentAndDecisionDisabled(true)
                        } else {
                          setCommentAndDecisionDisabled(false)
                        }
                        setMentionsList(mentions)
                        setComment(e.target.value)
                      }}
                      allowSuggestionsAboveCursor={true}
                      style={style}
                      spellCheck={false}
                    >
                      <Mention
                        trigger="@"
                        data={userList}
                        markup="@[__display__](user:__id__)"
                        displayTransform={(id, display) => `@${display}`}
                        // renderSuggestion={(suggestion, search, highlightedDisplay) => (
                        //   <div className="user">{highlightedDisplay}</div>
                        // )}
                        appendSpaceOnAdd={true}
                        // style={{backgroundColor:'rgb(206, 228, 229)'}}
                      />
                    </MentionsInput>
                  </>
                )}
              </div>
              {error ? <span className="error error-add-commnet">{error}</span> : null}
            </div>
            {!viewOnlyMode && (
              <>
                <div className="card_footer" id="meetingHubTutorial10">
                  <button
                    type="button"
                    className={
                      'btn-card text-box-btn ' +
                      (commentAndDecisionDisabled ? 'text-box-btn-disabled' : '')
                    }
                    onClick={(e) => {
                      if (commentAndDecisionDisabled) {
                        setIsAtMentionModalOpen(true)
                      } else if (/\S/.test(comment)) {
                        sendComment(data, false)
                        setComment('')
                        setError('')
                      } else {
                        setError(t('Common:formError.commentRequired'))
                      }
                    }}
                  >
                    {t('meetingTopics.commentMeeting')}
                  </button>
                  <div className="card_footer_rightside">
                    <button
                      type="button"
                      className={
                        'btn-card text-box-btn ' +
                        (commentAndDecisionDisabled ? 'text-box-btn-disabled' : '')
                      }
                      onClick={(e) => {
                        if (commentAndDecisionDisabled) {
                          setIsAtMentionModalOpen(true)
                        } else if (/\S/.test(comment)) {
                          sendComment(data)
                          setComment('')
                          setError('')
                        } else {
                          setError(t('Common:formError.commentRequired'))
                        }
                      }}
                    >
                      {t('meetingTopics.comment')}
                    </button>
                    <button
                      type="button"
                      className="btn-card text-box-btn"
                      onClick={(e) => {
                        if (/\S/.test(comment)) {
                          createActivity(data, comment, index, mentionsList)
                          setComment('')
                          setError('')
                          setCommentAndDecisionDisabled(false)
                        } else {
                          setError(t('Common:formError.actionRequired'))
                        }
                      }}
                    >
                      {t('meetingTopics.action')}
                    </button>
                  </div>
                </div>
                <div
                  style={{ paddingTop: '0', justifyContent: 'flex-start' }}
                  className="card_footer"
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {!resolved ? (
                      <button
                        type="button"
                        className="btn-card resolve-btn"
                        onClick={(e) => resolve(false)}
                        id="meetingHubTutorial12"
                      >
                        {t('meetingTopics.resolve')}
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn-card resolve-btn"
                        onClick={(e) => resolve(true)}
                      >
                        {t('meetingTopics.unresolve')}
                      </button>
                    )}
                  </div>

                  {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <button
                      type="button"
                      className="btn-card tonedown-blue-btn"
                      onClick={(e) => filterOpenOnly()}
                    >
                      {t('meetingTopics.openOnly')}
                    </button>
                    {cardButtonTypes.includes('OpenOnly') && (
                      <div className="underline-style"></div>
                    )}
                  </div> */}
                  <div
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      fontSize: '14px',
                      //marginBottom: '0.5rem',
                      gap: '0.5rem',
                    }}
                    onClick={(e) => filterOpenOnly()}
                  >
                    {!cardButtonTypes.includes('OpenOnly') && (
                      <span
                        style={{ fontSize: '35px', color: 'rgb(176, 173, 173)' }}
                        class="material-symbols-outlined"
                      >
                        toggle_off
                      </span>
                    )}

                    {cardButtonTypes.includes('OpenOnly') && (
                      <span
                        style={{ fontSize: '35px', color: '#4472c4' }}
                        class="material-symbols-outlined"
                      >
                        toggle_on
                      </span>
                    )}
                    <span>{t('meetingTopics.openOnly')}</span>
                  </div>
                </div>
                {hasNoActivities && noActivitiesMessage()}
              </>
            )}
          </>
        )}
        <CommentActivity
          refreshBGUScorecardFn={refreshBGUScorecardFn}
          reload={reload}
          commentActivityData={commentActivityData}
          setCommentActivityData={setCommentActivityData}
          loadAgain={loadAgain}
          viewOnlyMode={viewOnlyMode}
          index={index}
          cardButtonTypes={cardButtonTypes}
          userList={userList}
          umtId={data.umtId}
          isPresentationMode={isPresentationMode}
        />
      </div>
    </>
  )
}
export default Card

import React from 'react'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/core.css'
import { useTranslation } from 'react-i18next'

function ChangesReportDropdownMenu(props) {
  const { t } = useTranslation(['ChangesReport', 'Common'])
  // props.type can be "action", "kpi", "topic"
  return (
    <div style={{ textAlign: 'left', zIndex: '999' }}>
      <Menu
        menuClassName="obj-map-menu"
        menuButton={
          <MenuButton style={{ backgroundColor: 'white', border: 'none', padding: '0.1rem 0 0 0' }}>
            <span style={{ alignSelf: 'center' }} class="material-symbols-outlined icon-size">
              more_vert
            </span>
          </MenuButton>
        }
        portal={true}
      >
        <div>
          {props.type === 'action' && (
            <MenuItem
              onClick={() => {
                props.seeDetails()
              }}
            >
              {t('ChangesReport:seeDetails')}
            </MenuItem>
          )}
          {/* {props.type === "action" && (
            <MenuItem
              onClick={() => {
                props.reopenAction()
              }}
            >
              {t('ChangesReport:reopenAction')}
            </MenuItem>
          )} */}
        </div>
      </Menu>
    </div>
  )
}

export default ChangesReportDropdownMenu

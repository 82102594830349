import React from 'react'
import { Formik, Field, Form } from 'formik'
import AddImpactMeasureFormSchema from './formSchema'
import { useSetupWizardContext } from '../../../../../../context/setupWizardContext'

const AddImpactMeasureModalForm = ({ handleSubmit, handleCancel, setResetForm }) => {
  const { state, dispatch } = useSetupWizardContext()
  const formikRef = React.useRef()

  React.useEffect(() => {
    if (formikRef.current !== undefined) {
      setResetForm(() => formikRef.current.resetForm)
    }
  }, [formikRef])
  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={{
          name: '',
          unit: '',
          type: 'top-line',
        }}
        validationSchema={AddImpactMeasureFormSchema}
        onSubmit={(values, { resetForm }) => {
          let modifiedValues = { ...values, id: `c${state.data.customMeasures.length + 1}` }
          modifiedValues.topLine = modifiedValues.type === 'top-line' ? true : false
          delete modifiedValues.type

          dispatch({ type: 'ADD_IMPACT_MEASURE', customMeasure: { ...modifiedValues } })
          dispatch({
            type: 'ADD_SELECTED_MEASURE',
            selectedMeasure: { ...modifiedValues },
          })
          dispatch({ type: 'CLOSE_EMPLOYEE_MODAL' })
          resetForm()
        }}
      >
        {({ errors, touched, resetForm }) => (
          <Form className="form" id="area-leader-modal-form">
            <div style={{ display: 'flex' }} className="form-field">
              <label htmlFor="name">Measure (as it is displayed to users)</label>
              <div style={{ width: '100%' }}>
                <Field id="name" name="name" />
              </div>
              {errors.name && touched.name && <div className="validation-error">{errors.name}</div>}
            </div>

            <div className="form-field">
              <label htmlFor="unit">Unit (e.g., users/day, $/day)</label>
              <Field id="unit" name="unit" />
              {errors.unit && touched.unit && <div className="validation-error">{errors.unit}</div>}
            </div>

            <div className="form-field">
              <label htmlFor="type">Type</label>
              <Field as="select" id="type" name="type">
                <option value="top-line">Top-line</option>
                <option value="bottom-line">Bottom-line</option>
              </Field>
              {errors.type && touched.type && <div className="validation-error">{errors.type}</div>}
            </div>

            <div className="modal-dual-btn">
              <button
                className="dual-btn negative-btn"
                onClick={() => {
                  handleCancel()
                }}
                type="button"
              >
                CANCEL
              </button>
              <button type="submit" className="dual-btn positive-btn">
                SAVE
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default AddImpactMeasureModalForm

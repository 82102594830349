import React from 'react'
import ObjectiveDetails from '../../ObjectiveDetails'
import CommentForm from '../../../components/Comments/CommentForm'
import Button from '../../Button'
import target from '../../../assets/target.svg'
import { colors } from '../../../utils/colors'
import useObjectiveDataWithTeamLeaderId from '../../../api/query/getObjectiveDataWithTeamLeaderId'
import useManyObjectivesComments from '../../../api/query/getManyObjectivesComments'
import useLinkageData from '../../../api/query/useLinkageData'
import './styles.css'
import styled from 'styled-components'
import deleteCross from '../../../assets/delete-cross.svg'
import Modal from '../../Modal'
import InPageLoader from '../../InPageLoader'
import useAddComment from '../../../api/mutation/addCommentHome'
import { sanitizeString } from '../../../utils/general'
import { useTranslation } from 'react-i18next'

const ModalContent = styled.div`
  display: flex;
  gap: 4rem;
  flex-direction: row;
  @media (max-width: 1000px) and (orientation: landscape) {
    flex-direction: column;
  }
  @media (max-width: 500px) and (orientation: portrait) {
    flex-direction: column;
  }
  @media (min-width: 501px) and (max-width: 1200px) and (orientation: portrait) {
    flex-direction: column;
  }
`

const GovernanceStatusModal = ({
  isGovernanceStatusModalOpen,
  setIsGovernanceStatusModalOpen,
  teamLeader,
}) => {
  const { t } = useTranslation(['Dashboard', 'Common'])
  const { data, isLoading } = useObjectiveDataWithTeamLeaderId(teamLeader?.userId)
  const [objectiveToDisplay, setObjectiveToDisplay] = React.useState({})
  const { data: linkageData, isLoading: isLinkageLoading } = useLinkageData()
  const { data: commentData, isLoading: isCommentLoading } = useManyObjectivesComments({
    objIds: data?.objectives.map((obj) => parseInt(obj.id)) ?? [],
  })

  const mutationAddComment = useAddComment()

  React.useEffect(() => {
    if (!isLoading) setObjectiveToDisplay(data?.objectives[0])
  }, [data])

  const filteredComments =
    commentData?.comments.filter(
      (comment, commentIndex) => parseInt(objectiveToDisplay?.id) === comment?.consolidatedId,
    ) ?? []

  const closeModal = () => {
    setIsGovernanceStatusModalOpen(false)
  }

  if (isLoading || isLinkageLoading) {
    return (
      <Modal title={t('governanceStatusModal.teamLeaderObjectives')} closeModal={closeModal}>
        <InPageLoader inComponent={true} />
      </Modal>
    )
  }

  const handleCommentsSubmit = async (values, objectiveId, taskId, blockerId) => {
    let objToSubmit = { comments: [{ text: sanitizeString(values.text), objId: objectiveId }] }
    // objToSubmit.comments[0].isDecision = values.type === "Decision" ? true : false

    if (taskId) {
      objToSubmit.comments[0].activityId = taskId
    }

    await mutationAddComment.mutateAsync(objToSubmit, {
      onSuccess: (response) => {
        if (response) {
        } else {
          //handleError(true)
        }
      },
      onError: () => {
        //handleError(false)
      },
    })
  }

  return (
    <>
      {!isLoading && isGovernanceStatusModalOpen === true && (
        <Modal
          title={t('governanceStatusModal.teamLeaderObjectives')}
          closeModal={closeModal}
          width={'70rem'}
        >
          <h3>
            {t('governanceStatusModal.teamLeader')}: {teamLeader?.name}
          </h3>

          <select
            style={{ marginBottom: '2rem', display: 'flex' }}
            className="governance select"
            onChange={(e) => {
              setObjectiveToDisplay(data.objectives[parseInt(e.target.value)])
            }}
          >
            {data.objectives.map((obj, objIndex) => (
              <option value={objIndex} key={`objective-${objIndex}`} id={obj.id}>
                {obj.statement}
              </option>
            ))}
          </select>
          <ModalContent>
            <ObjectiveDetails linkageData={linkageData?.linkageData} obj={objectiveToDisplay} />

            <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '30rem' }}>
              <b style={{ fontSize: '18px' }}>{t('governanceStatusModal.comments')}</b>
              <CommentForm
                filteredComments={filteredComments}
                isCommentLoading={isCommentLoading}
                objectiveId={parseInt(objectiveToDisplay?.id)}
                handleSubmit={handleCommentsSubmit}
                isLoading={mutationAddComment.isLoading || mutationAddComment.isFetching}
              />
            </div>
          </ModalContent>
          <Button
            onClick={() => {
              setIsGovernanceStatusModalOpen(false)
            }}
            color={colors.grey}
            borderRadius={'2.5px'}
            width={'10vw'}
            fontSize={'14px'}
          >
            {t('Common:commonButtons.close')}
          </Button>
        </Modal>
      )}
    </>
  )
}

export default GovernanceStatusModal

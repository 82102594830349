import './styles.css'
import DefinedTable from './components/DefinedTable'
import CustomTable from './components/CustomTable'
import { useSetupWizardContext } from '../../../../context/setupWizardContext'

const ImpactMeasureTableContainer = () => {
  const { state } = useSetupWizardContext()
  return (
    <div className="impact-measure-table-container-component">
      <DefinedTable />
      <CustomTable />
    </div>
  )
}

export default ImpactMeasureTableContainer

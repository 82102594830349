import * as Yup from 'yup'

const ObjectiveFormSchema = Yup.object().shape({
  // verb: Yup.string().required('Required'),

  // descriptor: Yup.string().required('Required'),
  statement: Yup.string().required('Required'),
})

export default ObjectiveFormSchema

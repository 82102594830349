import styled from 'styled-components'

const DashboardContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: row;
  flex-grow: 1;
  margin: 1rem 5rem;
  padding-bottom: 1rem;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
`
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  min-width: 50%;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 992px) {
    width: 80%;
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-grow: ${(props) => props.grow || 1};
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 90%;
  padding: 1rem 2rem;
  box-shadow: 0 5px 15px #ccc;
`

const CardHeader = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify || 'flex-start'};
  font-size: 1.25rem;
  white-space: nowrap;
  font-weight: bold;
  gap: 1rem;
  padding-bottom: 0.5rem;
`

const CardHeaderCaption = styled.div`
  font-style: italic;
  color: #808080;
  font-size: 0.85rem;
`

export { DashboardContainer, ColumnContainer, CardContainer, CardHeader, CardHeaderCaption }

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getSuggestedActivitiesData = async (objId) => {
  const queryString = `/autogen_activities/${objId}`

  const { data } = await axios.get(`${BASE_URL}${queryString}`, { timeout: 10000 })
  return data
}

export default function useSuggestedActivitiesData(objId) {
  return useQuery(['suggested-activities', objId], () => getSuggestedActivitiesData(objId), {
    refetchOnWindowFocus: false,
    enabled: objId !== undefined && objId !== null,
    retry: 1,
    onSuccess: (response) => {
      return response
    },
    onError: (response) => {
      return response
    },
  })
}

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getAreaEmployeesMinusSelfAndCopyOwners = async (objId) => {
  const { data } = await axios.get(`${BASE_URL}/area_employees_minus_self_and_copy_owners/${objId}`)
  return data
}

export default function useGetAreaEmployeesMinusSelfAndCopyOwners(objId) {
  return useQuery(
    ['employees-list-transfer-ownership', objId],
    () => getAreaEmployeesMinusSelfAndCopyOwners(objId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(objId),
    },
  )
}

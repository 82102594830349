import React from 'react'
import WizardButton from '../../WizardButton'

const SubmitWizard = ({ currentStep, setErrorMessage, handleSubmit, isLoading }) => {
  return (
    <WizardButton
      isLoading={isLoading}
      onClick={() => {
        handleSubmit()
      }}
    />
  )
}

export default SubmitWizard

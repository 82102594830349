import { React, Component } from 'react'
import '../../styles.css'
import DeleteButton from '../DeleteButton'
class IndividualContributorTable extends Component {
  render() {
    const { individualContributorArray, teamLeaderEmail } = this.props

    if (individualContributorArray.length > 0) {
      let filteredIndividualContributors = this.props.individualContributorArray.filter(
        function (individualContributor) {
          return individualContributor.managerEmail === teamLeaderEmail
        },
      )
      return (
        <div>
          <table>
            <tbody>
              <tr className="header-row">
                <th>
                  <span>Name</span>
                </th>
                <th>
                  <span>Title</span>
                </th>
                <th class="email-table-field">
                  <span>Email</span>
                </th>
              </tr>

              {filteredIndividualContributors?.map((individualContributor, index) => (
                <tr id={individualContributor.name} key={`employee-${index}`}>
                  <td>{individualContributor.name}</td>
                  <td>{individualContributor.title}</td>
                  <td class="email-table-field">{individualContributor.email}</td>
                  <td className="fragment-td">
                    <DeleteButton index={index} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    } else {
      return (
        <table>
          <tbody>
            <tr className="header-row">
              <th>
                <span>Name</span>
              </th>
              <th>
                <span>Title</span>
              </th>
              <th>
                <span>Email</span>
              </th>
            </tr>
          </tbody>
        </table>
      )
    }
  }
}
export default IndividualContributorTable

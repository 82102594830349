import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

import { getHttpRequest, postHttpRequest } from '../query/dynamicAPI'
import {
  API_SPREADHSHEET_SAVE_SUCCESS_MESSAGE,
  API_ERROR_MESSAGE,
  SEPREADSHEETS,
  MILESTONE_BY_FILE,
  IMPORTED_SPREADSHEET,
  SAVE_IMPORTED_SPREADSHEET,
  EXPORT_SPREADSHEET,
} from '../../api/constant/route'
import Toast from '../../common/toast'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const postSaveSpreadsheetData = async (formData) => {
  const { data } = await axios.post(`${BASE_URL}${SAVE_IMPORTED_SPREADSHEET}`, formData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export default function SAVE_IMPORTED_SPREADSHEET_DATA() {
  const queryClient = useQueryClient()

  return useMutation((formData) => postSaveSpreadsheetData(formData), {
    onSuccess: (response) => {
      void queryClient.invalidateQueries('spreadsheet-data')
      return response
    },
    onError: (response) => {
      return response
    },
  })
}

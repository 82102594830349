const subActivityStatus = [
  { value: 'NOT_YET_STARTED', label: 'Not Started' },
  { value: 'IN_PROGRESS', label: 'In Progress' },
  { value: 'COMPLETE', label: 'Complete' },
]

const activityStatus = [
  { value: 'NOT_STARTED', label: 'Not Started' },
  { value: 'ON_TRACK', label: 'On Track' },
  { value: 'FOR_REVIEW', label: 'For Review' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'DEPRIORITIZED', label: 'Deprioritized' },
]

const customFieldList = [
  { type: 1, value: 'text', name: 'Free Text' },
  { type: 2, value: 'number', name: 'Number' },
  { type: 3, value: 'date', name: 'Date' },
  { type: 5, value: 'checkBox', name: 'Yes/No' },
  { type: 4, value: 'list', name: 'List' },
  { type: 6, value: 'list', name: 'Pre-built List' },
]

const dynamicLists = [{ type: 6, value: 1, name: 'Employees' }]

export { subActivityStatus, activityStatus, customFieldList, dynamicLists }

import {
  API_ERROR_MESSAGE,
  LIST_MEETING_LOGS,
  MEETING_COMMENT_ADD,
  MEETING_MILESTONE_ADD,
  UPDATE_MEETING_LOGS,
  COMMENT_UPDATE,
  MILESTONE_UPDATE,
  GET_SINGLE_MEETING_TOPIC_DETAILS,
  UPLOAD_MEETING_DECK,
  GET_RECENT_OBJECTIVE_ID,
} from '../constant/route'
import { getHttpRequest, postHttpRequest } from '../query/dynamicAPI'
import Toast from '../../common/toast'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

export async function getMeetingTopic(
  resolved,
  viewHigherObjectives,
  selectedLeaderToLoadMH,
  evaluatedViewMode,
  selectedMeetingId,
  selectedTagId,
  onlyTopics = false,
) {
  try {
    let param = {
      is_resolved: resolved,
      view_higher_objectives: viewHigherObjectives,
      selected_leader_to_load_mh: selectedLeaderToLoadMH,
      view_mode: evaluatedViewMode,
      selected_meeting_id: selectedMeetingId,
      only_topics: onlyTopics,
      selected_tag_id: selectedTagId,
    }
    const data = await getHttpRequest(`/list_meeting_logs`, { params: { ...param } })

    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

const updateMeetingLogsPost = async (updateData) => {
  const { data } = await axios.post(`${BASE_URL}${UPDATE_MEETING_LOGS}`, updateData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export function useUpdateMeetingLogs() {
  const queryClient = useQueryClient()

  return useMutation((formData) => updateMeetingLogsPost(formData), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      queryClient.invalidateQueries('uw_meeting_topics')
    },
  })
}

export async function meetingMilestonAdd(formData) {
  try {
    const data = await postHttpRequest(`${MEETING_MILESTONE_ADD}`, formData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function meetingCommentAdd(formData) {
  try {
    const data = await postHttpRequest(`${MEETING_COMMENT_ADD}`, formData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function meetingCommentUpdate(id, formData) {
  try {
    const data = await postHttpRequest(`${COMMENT_UPDATE}/${id}`, formData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function meetingMilestoneUpdate(id, formData) {
  try {
    const data = await postHttpRequest(`${MILESTONE_UPDATE}/${id}`, formData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function uploadPptFile(formData) {
  try {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }

    const data = await postHttpRequest(`${UPLOAD_MEETING_DECK}`, formData, config)

    return data
  } catch (e) {
    return e.response
  }
}

export async function getSingleMeetingTopicDetails(formData) {
  try {
    const data = await postHttpRequest(`${GET_SINGLE_MEETING_TOPIC_DETAILS}`, formData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getRecentMeeting(id) {
  try {
    const data = await postHttpRequest(`${GET_RECENT_OBJECTIVE_ID}/${id}/3`)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getMeetingTopicForBGU(eId, show_l3_objectives_for_al_and_juniot_tls = false) {
  try {
    let url =
      eId !== null ? `/get_all_meeting_topics_by_obj/${eId}` : `/get_all_meeting_topics_by_obj`
    const data = await getHttpRequest(url, {
      params: {
        show_l3_objectives_for_al_and_juniot_tls: show_l3_objectives_for_al_and_juniot_tls,
      },
    })

    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function manageTopicUW(topicType, formData) {
  let url = ''
  if (topicType === 1) {
    url = '/uw_manage_blocker'
  } else if (topicType === 2) {
    url = '/uw_manage_decision'
  } else if (topicType === 3) {
    url = '/uw_manage_update'
  } else {
    throw new Error('Invalid topic type')
  }

  try {
    const data = await postHttpRequest(url, formData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function postCloseMeetingTopics(formData) {
  try {
    const data = await postHttpRequest('/close_meeting_topics', formData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function linkActionToMeetingTopic(formData) {
  try {
    const data = await postHttpRequest(`/link_action_to_meeting_topic`, formData, {})

    return data
  } catch (e) {
    return e.response
  }
}

export async function unLinkMeetingTopic(formData) {
  try {
    const data = await postHttpRequest(`/unlink_action_to_meeting_topic`, formData, {})

    return data
  } catch (e) {
    return e.response
  }
}

export async function generateMultipleMeetingTopics(meetingTopicText) {
  try {
    const postData = {
      meetingTopicText: meetingTopicText,
    }
    const data = await postHttpRequest(`/generate_multiple_meeting_topics_using_text`, postData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function addMultipleMeetingTopics(topics) {
  try {
    const postData = {
      topics: topics,
    }
    const data = await postHttpRequest(`/add_multiple_meeting_topics`, postData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function addMultipleCommentsDecisionsActionsToMeetingTopic(items, topicId, objId) {
  try {
    const postData = {
      commentsDecisionsActions: items,
      topicId: topicId,
      objId: objId,
    }
    const data = await postHttpRequest(
      `/add_multiple_comments_decisions_actions_to_meeting_topic`,
      postData,
    )
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getAiPresentationData(umtId) {
  try {
    const postData = {
      umtId: umtId,
    }
    const data = await postHttpRequest(`/get_ai_slides`, postData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function deleteAiPresentationData(umtId) {
  try {
    const postData = {
      umtId: umtId,
    }
    const data = await postHttpRequest(`/delete_ai_slides`, postData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function applyAiPresentationSuggestEdit(umtId, suggest, editSlides = false) {
  try {
    const postData = {
      umtId: umtId,
      suggest: suggest,
      editSlides: editSlides,
    }
    const data = await postHttpRequest(`/suggest_edit_ai_presentation_script`, postData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function applyInLineEditSuggestion(umtId, suggest) {
  try {
    const postData = {
      umtId: umtId,
      suggest: suggest,
    }
    const data = await postHttpRequest(`/inline_edit_suggest`, postData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getCompletedMeetingTopics(ownerIds, self = false) {
  try {
    const data = await getHttpRequest(`/get_completed_meeting_topics`, {
      params: { ownerIds: ownerIds.join(','), self: self },
    })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function setEmailReminder(formData) {
  try {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    const data = await postHttpRequest(`/set_email_reminder`, formData, config)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getEmailReminder() {
  try {
    const data = await getHttpRequest(`/get_email_reminder`)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function updateEmailReminder(formData) {
  try {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    const data = await postHttpRequest(`/update_email_reminder`, formData, config)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function setEmailFollowUps(formData) {
  try {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    const data = await postHttpRequest(`/set_email_followup`, formData, config)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getEmailFollowUps() {
  try {
    const data = await getHttpRequest(`/get_email_follow_up`)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function updateFolowUps(formData) {
  try {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    const data = await postHttpRequest(`/update_follow_ups`, formData, config)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function unlinkReminderTopic(reminderId) {
  try {
    const data = await postHttpRequest(`/unlink_reminder`, { reminderId })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function unlinkFollowupTopic(reminderId) {
  try {
    const data = await postHttpRequest(`/unlink_follow_up`, { reminderId })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getMeetingTagsListForUser() {
  try {
    const data = await getHttpRequest(`/get_meeting_tags_list_for_user`)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function updateMeetingInstanceForMeeting(meetingId, meetingInstanceId) {
  try {
    const data = await postHttpRequest(`/update_meeting_instance_for_meeting`, {
      meetingId,
      meetingInstanceId,
    })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getDecisionLogByMeetingInstanceId(meetingInstanceId) {
  try {
    const data = await postHttpRequest(`/get_decision_log_by_meeting_instance_id`, {
      meetingInstanceId,
    })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

import next from '../../../../assets/next-white.svg'
import { useNavigate } from 'react-router-dom'
import SubmitDemandIntakeWizard from './components/SubmitDemandIntakeWizard'
import { demandIntakeSubRoutes, routes } from '../../../../utils/routes'
import { useDemandIntakeContext } from '../../context/demandIntakeContext'
import { useTranslation } from 'react-i18next'
import { hasRole } from '../../../../utils/auth'
import { redirectToHome } from '../../../../utils/auth'

const NextResetActions = ({
  isLastStep,
  currentStep,
  handleNextFunction,
  handlePrevious,
  setCurrentStepIndex,
  setObjectiveType,
  errorMessage,
  setErrorMessage,
  handleSubmitObjective,
  handleSubmitAllocStuff,
}) => {
  const { t } = useTranslation(['Common'])
  const navigate = useNavigate()
  const { state, dispatch } = useDemandIntakeContext()

  const resetErrorMessage = () => {
    setErrorMessage(null)
  }

  const resetObjectives = () => {
    dispatch({
      type: 'UPDATE_OBJECTIVES',
      objectives: JSON.parse(JSON.stringify(state?.data?.objectivesCopy)),
    })
  }

  return (
    <div className="buttons-area-container">
      <div className="error-container">
        {errorMessage ? <div className="validation-error">{errorMessage}</div> : null}
      </div>
      <div className="dual-button-container">
        {currentStep === 0 ? (
          <>
            <button
              onClick={() => {
                let activityOnly = true
                handleSubmitObjective(null, activityOnly)
              }}
              className="dual-back-btn dual-btn"
            >
              {t('commonButtons.no')}
            </button>
            <button
              onClick={() => {
                setObjectiveType('General')
                dispatch({ type: 'OPEN_OBJ_FORM_MODAL' })
              }}
              className="dual-back-btn dual-btn"
            >
              {t('commonButtons.yes')}
            </button>
          </>
        ) : (
          <>
            <button
              className="dual-back-btn dual-btn"
              style={{
                backgroundColor: 'transparent',
                border: '1px solid #ED522E',
                color: 'black',
              }}
              onClick={() => {
                // resetErrorMessage()
                // resetObjectives()
                // dispatch({ type: 'SET_OBJ_ASSOCIATION', objectiveAssociation: null })
                // dispatch({ type: 'SET_LINKED_OBJECTIVE_ID', linkedObjectiveId: null })
                // setCurrentStepIndex(0)
                // navigate(`/${routes.demandintake}/${demandIntakeSubRoutes.intro}`)
                if (hasRole('area')) {
                  window.location.href = '/Objectives'
                } else {
                  redirectToHome()
                }
              }}
            >
              {/* <img
                className="nav-arrow previous"
                src={next}
                alt="Arrow indication a navigation backwards."
              /> */}
              <span> {t('commonButtons.completeAllocationLater')}</span>
            </button>
            {isLastStep ? (
              <SubmitDemandIntakeWizard
                currentStep={currentStep}
                handleSubmit={handleSubmitAllocStuff}
              />
            ) : (
              <button className="dual-next-btn dual-btn" onClick={() => handleNextFunction()}>
                <span>{t('commonButtons.next')}</span>
                <img
                  className="nav-arrow next"
                  src={next}
                  alt="Arrow indication a navigation forwards."
                />
              </button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default NextResetActions

// StreamAudioRecorder.js
import React, { useEffect } from 'react'
import { useMeetingRecording } from '../../utils/meetingRecordingContext/meetingRecordingContext'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { hasRole } from '../../utils/auth'
import { routes, meetingMultiViewSubRoutes } from '../../utils/routes'
import { getConsolidatedConfigSettings } from '../../utils/auth'
import ActivateSettingsTutorialModal from '../ActivateSettingsTutorialModal'
import { getMeetingTagsListForUser } from '../../api/services/meetingTopic.services'
import SelectMeetingTagsModal from '../../pages/MeetingMultiView/components/MeetingTopics/common/SelectMeetingTagsModal'

const StreamAudioRecorder = ({}) => {
  const { state, startRecording, stopRecording } = useMeetingRecording()

  const { t } = useTranslation(['Common'])
  const navigate = useNavigate()
  const isDelegateTl = parseInt(localStorage.getItem('dType')) === 5 ? true : false
  const isRecordingStop = React.useRef(state.isRecordingStop)
  const [isStartModalOpen, setIsStartModalOpen] = React.useState(false)
  const brainstormingTutorialOff = getConsolidatedConfigSettings('brainstorming_tutorial_off')
  const [meetings, setMeetings] = React.useState([])
  const [isSelectMeetingTagModalOpen, setIsSelectMeetingTagModalOpen] = React.useState(false)
  const [meetingInstanceId, setMeetingInstanceId] = React.useState(null)

  // this useEffect will be triggered everytime you go to different page
  // because it's part of the navigation, and it will be re-initialized,
  // since <Navigation /> is part of the ApplicationLayout/>...
  React.useEffect(() => {
    ;(async () => {
      if (state.isRecordingStop && !isRecordingStop.current) {
        const result = await Swal.fire({
          title: t('Common:transcription.doYouWantToReviewActionDecision'),
          showCancelButton: true,
          confirmButtonText: t('Common:transcription.yes'),
          cancelButtonText: t('Common:transcription.no'),
          reverseButtons: true,
        })
        // dispatch({ type: 'STOP_RECORDING' })
        if (result.isConfirmed) {
          let url = `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.meetingSummaries}`
          navigate(url)
          navigate(0)
        }
      }
      isRecordingStop.current = state.isRecordingStop
    })()
  }, [state.isRecordingStop])

  React.useEffect(() => {
    getMeetingTags()
  }, [])

  const getMeetingTags = async () => {
    let res = await getMeetingTagsListForUser()
    setMeetings(res?.meetings)
  }

  return (
    <>
      {isStartModalOpen && (
        <ActivateSettingsTutorialModal
          isModalOpen={isStartModalOpen}
          closeModal={() => setIsStartModalOpen(false)}
          startRecording={startRecording}
          setMeetings={setMeetings}
          setMeetingInstanceId={setMeetingInstanceId}
          setIsSelectMeetingTagModalOpen={setIsSelectMeetingTagModalOpen}
          fromAiMeeting={true}
        />
      )}
      {isSelectMeetingTagModalOpen && (
        <SelectMeetingTagsModal
          isModalOpen={isSelectMeetingTagModalOpen}
          setIsModalOpen={setIsSelectMeetingTagModalOpen}
          meetings={meetings}
          meetingInstanceId={meetingInstanceId}
        />
      )}
      <div style={{ display: 'flex', flexDirection: 'row', marginRight: '1rem' }}>
        {state.hasSummarizationStarted ? (
          <div
            className="fitted-button blue"
            onClick={async () => {
              await stopRecording()
            }}
            style={{ pointerEvents: 'auto', cursor: 'pointer' }}
          >
            {state.isStoppingRecording ? (
              <span
                className="loading-spinner"
                style={{ width: '1rem', height: '1rem', marginRight: '0.5rem' }}
              ></span>
            ) : (
              <span
                className="material-symbols-outlined icon-size"
                style={{ marginRight: '0.1rem' }}
              >
                stop_circle
              </span>
            )}
            <span
              style={{
                display: 'flex',
                fontSize: '16px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {' '}
              {state.isStoppingRecording
                ? t('Common:transcription.processingEnd')
                : t('Common:transcription.summarizing')}
            </span>
          </div>
        ) : (
          <div
            className="fitted-button blue"
            onClick={async () => {
              if (brainstormingTutorialOff !== true) {
                setIsStartModalOpen(true)
              } else {
                let { response, previousMeetings } = await startRecording()
                setMeetings(previousMeetings?.meetings)
                setMeetingInstanceId(response?.meetingInstanceId)
                if (previousMeetings?.meetings?.length > 0) {
                  setIsSelectMeetingTagModalOpen(true)
                }
              }
            }}
            style={{ pointerEvents: 'auto', cursor: 'pointer' }}
          >
            <span className="material-symbols-outlined icon-size" style={{ marginRight: '0.1rem' }}>
              play_arrow
            </span>
            <span
              style={{
                display: 'flex',
                fontSize: '16px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {' '}
              {t('Common:transcription.startRecordingMeeting')}
            </span>
          </div>
        )}
      </div>
    </>
  )
}

export default StreamAudioRecorder

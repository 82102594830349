import _ from 'lodash'
import useTeamAllocationData from '../../../api/query/useTeamAllocationData'
import useLinkageData from '../../../api/query/useLinkageData'
import { GET_TL_WIZARD_STATE } from '../../../api/constant/route'
import { getHttpRequest } from '../../../api/query/dynamicAPI'
import useTLWizardStateData from '../../../api/query/getTLWizardStateData'

export default function useTeamLeaderData(tlId) {
  //TBD Swetha : Remove local-storage when not required
  //const existingData = localStorage.getItem('tlw-data')
  const {
    data: existingData,
    isLoading: isExistingDataLoading,
    isFetching: isExistingDataFetching,
  } = useTLWizardStateData()
  const {
    data: employeeData,
    isLoading: isEmployeeLoading,
    error: employeeError,
  } = useTeamAllocationData(tlId)

  const { data: linkageData, isLoading: isLinkageLoading, error: linkageError } = useLinkageData()

  const isLoading =
    isEmployeeLoading || isLinkageLoading || isExistingDataFetching || isExistingDataLoading
  const error = employeeError || linkageError

  if (!isLoading && existingData && existingData?.tl_wizard_state.length > 0) {
    const tlWizData = existingData?.tl_wizard_state
    const parsedExistingData = tlWizData[0][1]
    const existingStep = tlWizData[0][0]
    //TBD Swetha : Remove local-storage when not required
    //const parsedExistingData = JSON.parse(tlWizardData)
    const employees = _.uniqBy(parsedExistingData.employees.concat(employeeData.employees), 'email')

    return {
      isLoading: isLoading,
      error: error,
      data: {
        existingStep: existingStep,
        employees: employees ?? [],
        objectives: parsedExistingData.objectives ?? [],
        linkageData: linkageData?.linkageData ?? [],
        stratDescData: linkageData?.companyStratDescriptions ?? [],
        externalEmpAlocs: {},
        nonReportingEmpsRealIndexes: employees
          ? employees.filter((emp, index) => {
              return emp.isDirectReport !== true
            })
          : [],
      },
    }
  }

  return {
    isLoading: isLoading,
    error: error,
    data: {
      employees: employeeData?.employees ?? [],
      objectives: [],
      linkageData: linkageData?.linkageData ?? [],
      stratDescData: linkageData?.companyStratDescriptions ?? [],
      existingStep: 0, //default step
    },
  }
}

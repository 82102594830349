import { getHttpRequest, postHttpRequest } from '../query/dynamicAPI'
import {
  TASK_COLUMN_UPDATE,
  CREATE_TASK,
  GET_SINGLE_ACTIVITY,
  API_ERROR_MESSAGE,
  GET_OBJECTIVES_ACTIVITY,
  ACTIVITIES_PER_OBJECTIVE,
  TRANSFER_ACTIVITY,
} from '../../api/constant/route'
import Toast from '../../common/toast'

export async function getSingleActivityById(activityId) {
  try {
    const data = await getHttpRequest(`${GET_SINGLE_ACTIVITY}/${activityId}`)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getActivitesPerObjectives(objectiveId) {
  try {
    const data = await getHttpRequest(`${ACTIVITIES_PER_OBJECTIVE}/${objectiveId}`)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function createorUpdateActivity(postData) {
  try {
    const data = await postHttpRequest(`${CREATE_TASK}`, postData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getActivitesByObjectiveId(objectiveId) {
  try {
    const data = await getHttpRequest(`${GET_OBJECTIVES_ACTIVITY}/${objectiveId}`)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

//KANBAN VIEW
export async function shuffleActivityBetweenColumn(objectiveId) {
  try {
    const data = await getHttpRequest(`${TASK_COLUMN_UPDATE}/${objectiveId}`)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function transferActivity(postData) {
  try {
    const data = await postHttpRequest(`${TRANSFER_ACTIVITY}`, postData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

import React from 'react'
import Modal from '../../../../../../components/Modal'

const SetupSuccessModal = ({ passwordResetLink }) => {
  return (
    <>
      <Modal title={'Setup Completed Successfully!'} includeCloseIcon={false}>
        <div className="bullet-container">
          <div className="divider"></div>
          {passwordResetLink && (
            <>
              <div>
                You may now set your account password by
                <a href={passwordResetLink} style={{ color: '#00f' }} target="_blank">
                  {' '}
                  clicking here
                </a>
                , and log in with your corporate email.
                <br></br>
                <br></br>
                <br></br>
                <br></br>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  )
}

export default SetupSuccessModal

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getSankeyData = async (companyWide, timeFilter) => {
  const { data } = await axios.get(`${BASE_URL}/get_sankey_data`, {
    params: { companyWide: companyWide, ...timeFilter },
  })
  return data
}

export default function useGetSankeyData(companyWide = true, timeFilter = {}) {
  return useQuery(
    ['sankeyData', companyWide, timeFilter],
    () => getSankeyData(companyWide, timeFilter),
    {
      refetchOnWindowFocus: false,
    },
  )
}

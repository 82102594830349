import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const autogenCapabilityTasks = async (dataSend) => {
  const { data } = await axios.post(`${BASE_URL}/autogen_capability_tasks`, dataSend, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return data
}

export default function useAutogenCapabilityTasks(data) {
  return useQuery(['autogenCapabilityTasks', data], () => autogenCapabilityTasks(data), {
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess: (result) => {
      return result
    },
    onError: (error) => {
      return error
    },
  })
}

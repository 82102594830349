import React from 'react'
import Modal from '../../../../../components/Modal'
import { useTranslation } from 'react-i18next'

const EditMenuModal = ({
  isOpen,
  handleClose,
  objective,
  setObjective,
  setIsEdit,
  handleOpenObjectiveModal,
  index,
}) => {
  const { t } = useTranslation(['Dashboard'])
  return (
    <>
      {isOpen === true && (
        <Modal title={'Edit Objective'} closeModal={handleClose}>
          <div>
            <div className="bullet-container" style={{ maxWidth: '30rem', textAlign: 'left' }}>
              <span>{t('adminDashboard.editWarning')}</span>
              <br />
              <span>
                <div style={{ paddingTop: '2rem' }}> {t('adminDashboard.languageWarning')}</div>
              </span>
            </div>
            <div
              className="modal-dual-btn"
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5rem',
                paddingTop: '1rem',
                width: '100%',
              }}
            >
              <button
                className="dual-btn negative-btn"
                onClick={() => {
                  setIsEdit(false)
                  setObjective(null)
                  handleOpenObjectiveModal(null, null)
                  handleClose()
                }}
              >
                <span>{t('adminDashboard.no')}</span>
              </button>
              <button
                className="dual-btn positive-btn"
                onClick={() => {
                  setIsEdit(true)
                  handleOpenObjectiveModal(objective, index)
                  handleClose()
                }}
              >
                <span>{t('adminDashboard.yes')}</span>
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default EditMenuModal

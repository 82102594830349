import React from 'react'
import ApplicationLayout from '../../components/ApplicationLayout'
import {
  getNotifications,
  clearNotificationsRequest,
} from '../../api/services/notifications.services'
import { useTranslation } from 'react-i18next'
import InPageLoader from '../../components/InPageLoader'
import './styles.scss'
import CardModal from '../../components/CardModal'
import CommentsModal2 from '../../components/CommentsModal2'
import AddEditActivityModal from '../../components/AddEditActivityModal'
import Swal from 'sweetalert2'
import NotificationRow from './NotificationRow'
import { useNavigate } from 'react-router-dom'

const Notifications = () => {
  const { t } = useTranslation(['Notifications', 'Common'])
  const [isLoading, setIsLoading] = React.useState(false)
  const [notificationsList, setNotificationsList] = React.useState([])
  const [fullNotificationsList, setFullNotificationsList] = React.useState([])
  const [clearDictionary, setClearDictionary] = React.useState({})
  const [isDirectMentionOnly, setIsDirectMentionOnly] = React.useState(false)

  const [isOpenCardModal, setIsOpenCardModal] = React.useState(false)
  const [cardModalTopicId, setCardModalTopicId] = React.useState(null)
  const [cardModalTopicType, setCardModalTopicType] = React.useState(null)

  const [isOpenCommentsModal, setIsOpenCommentsModal] = React.useState(false)
  const [commentsModalTaskId, setCommentsModalTaskId] = React.useState(null)
  const [commentsModalObjective, setCommentsModalObjective] = React.useState(null)
  const [commentsModalDisplayAllComments, setCommentsModalDisplayAllComments] =
    React.useState(false)

  const [isOpenActivityModal, setIsActivityModalOpen] = React.useState(false)
  const [activityModalObjectiveId, setActivityModalObjectiveId] = React.useState(null)
  const [activityModalActivityId, setActivityModalActivityId] = React.useState(null)

  const loggedInName = localStorage.getItem('tfmnm')
  const navigate = useNavigate()

  React.useEffect(() => {
    setIsLoading(true)
    loadNotifications()
    setIsLoading(false)
  }, [])

  React.useEffect(() => {
    if (isDirectMentionOnly) {
      let tempNotificationsList = []
      for (let record of notificationsList) {
        if (
          record?.action?.commentNotification?.notificationText.includes('@[' + loggedInName + ']')
        ) {
          tempNotificationsList.push(record)
        }
      }
      setNotificationsList(tempNotificationsList)
    } else {
      setNotificationsList(fullNotificationsList)
    }
  }, [isDirectMentionOnly])

  async function loadNotifications() {
    let result = await getNotifications()
    if (result && result.actions) {
      setNotificationsList(result.actions)
      setFullNotificationsList(result.actions)
      localStorage.setItem('notificationCount', result.actions.length)

      let tempClearDictionary = {}
      for (let record of result.actions) {
        tempClearDictionary[record.action.actionId] = false
      }
      setClearDictionary(tempClearDictionary)

      window.dispatchEvent(new Event('clearNotifications'))
    }
  }

  const handleChangeCheckbox = (actionId) => {
    let tempClearDictionary = { ...clearDictionary }
    tempClearDictionary[actionId] = !clearDictionary[actionId]
    setClearDictionary(tempClearDictionary)
  }

  const handleSeeMore = (notification) => {
    if (notification?.commentOn === 'COMMENT_ON_OBJECTIVE') {
      setCommentsModalObjective({ id: notification.objId, title: notification.objectiveText })
      setCommentsModalTaskId(null)
      setCommentsModalDisplayAllComments(true)
      setIsOpenCommentsModal(true)
    } else if (
      notification?.commentOn === 'COMMENT_ON_MEETING_TOPIC' ||
      notification?.commentOn === 'DECISION_ON_MEETING_TOPIC' ||
      notification?.milestoneActionType === 'CREATED_MILESTONE_MEETING_ACTION' ||
      notification?.milestoneActionType === 'ASSIGNED_MILESTONE_MEETING_ACTION'
    ) {
      setCardModalTopicId(notification.umtId)
      setIsOpenCardModal(true)
      setCardModalTopicType(notification.umtType)
    } else if (notification?.commentOn === 'COMMENT_ON_MILESTONE_ACTION') {
      setCommentsModalObjective({ id: notification.objId, title: notification.objectiveText })
      setCommentsModalTaskId(notification.activityId)
      setCommentsModalDisplayAllComments(false)
      setIsOpenCommentsModal(true)
    } else if (
      notification?.milestoneActionType === 'CREATED_MILESTONE_OBJ_ACTION' ||
      notification?.milestoneActionType === 'ASSIGNED_MILESTONE_OBJ_ACTION'
    ) {
      setActivityModalObjectiveId(notification.objId)
      setActivityModalActivityId(notification.milestoneId)
      setIsActivityModalOpen(true)
    } else if (notification?.topicType) {
      setCardModalTopicId(notification.topicId)
      setIsOpenCardModal(true)
      setCardModalTopicType(notification.topicType)
    }
  }

  async function clearNotifications(type) {
    const confirmationResult = await Swal.fire({
      title:
        type === 'all'
          ? t('Common:modalMessages.areYouSureYouWantToClearAllNotifications')
          : t('Common:modalMessages.areYouSureYouWantToClearSelectedNotifications'),
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })

    if (confirmationResult.isConfirmed) {
      let postData = {}
      if (type === 'all') {
        postData = {
          clearIds: Object.keys(clearDictionary).map((key) => parseInt(key, 10)),
        }
      } else {
        postData = {
          clearIds: Object.keys(clearDictionary)
            .filter((key) => clearDictionary[key] === true)
            .map((key) => parseInt(key, 10)),
        }
      }
      if (postData?.clearIds?.length > 0) {
        let result = await clearNotificationsRequest(postData)

        if (result) {
          loadNotifications()
        }
      }
    }
  }

  if (isLoading) {
    return (
      <>
        {' '}
        <InPageLoader />{' '}
      </>
    )
  }

  const generateHeader = (notification) => {
    //for comments
    if (notification?.commentOn) {
      if (notification?.commentOn === 'COMMENT_ON_OBJECTIVE') {
        return t('Notifications:commentOnObjective') + ' ' + '"' + notification.objectiveText + '"'
      } else if (notification?.commentOn === 'COMMENT_ON_MEETING_TOPIC') {
        return t('Notifications:commentOnTopic') + ' ' + '"' + notification.umtText + '"'
      } else if (notification?.commentOn === 'DECISION_ON_MEETING_TOPIC') {
        return t('Notifications:decisionOnTopic') + ' ' + '"' + notification.umtText + '"'
      } else if (notification?.commentOn === 'COMMENT_ON_MILESTONE_ACTION') {
        return t('Notifications:commentOnAction') + ' ' + '"' + notification.activityText + '"'
      }
    }
    // milestone actions and milestone action assignments
    else if (notification?.milestoneActionType) {
      if (notification?.milestoneActionType === 'CREATED_MILESTONE_MEETING_ACTION') {
        return (
          t('Notifications:createdMilestoneMeetingAction') + ' ' + '"' + notification.umtText + '"'
        )
      } else if (notification?.milestoneActionType === 'ASSIGNED_MILESTONE_MEETING_ACTION') {
        return (
          t('Notifications:assignedMilestoneMeetingAction') + ' ' + '"' + notification.umtText + '"'
        )
      } else if (notification?.milestoneActionType === 'CREATED_MILESTONE_OBJ_ACTION') {
        return (
          t('Notifications:createMilestoneObjAction') + ' ' + '"' + notification.objectiveText + '"'
        )
      } else if (notification?.milestoneActionType === 'ASSIGNED_MILESTONE_OBJ_ACTION') {
        return (
          t('Notifications:assignedMilestoneObjAction') +
          ' ' +
          '"' +
          notification.objectiveText +
          '"'
        )
      }
    } else if (notification?.topicType) {
      if (notification?.topicType === 1) {
        return t('Notifications:blockerAssignedFor') + ' ' + notification.objDescription
      }
      if (notification?.topicType === 2) {
        return t('Notifications:guidanceAssignFor') + ' ' + notification.objDescription
      }
      if (notification?.topicType === 3) {
        return t('Notifications:updateAssignFor') + ' ' + notification.objDescription
      }
      if (notification?.topicType === 4) {
        return t('Notifications:adminAssignFor') + ' ' + notification.objDescription
      }
    }
  }

  return (
    <ApplicationLayout>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '4rem',
          marginRight: '4rem',
          marginBottom: '2rem',
          marginTop: '2rem',
          display: 'flex',
          alignSelf: 'center',
          rowGap: '3rem',
          height: '770px',
          alignContent: 'center',
          paddingTop: '5rem',
        }}
      >
        <div
          style={{ width: '40rem', display: 'flex', flexDirection: 'column', alignSelf: 'center' }}
        >
          <span style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '3rem' }}>
            {t('Notifications:notifications')}
          </span>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ marginBottom: '0.5rem', alignItems: 'center', display: 'flex' }}>
              <span
                className="notification-link"
                onClick={() => {
                  clearNotifications('some')
                }}
              >
                {t('Notifications:clear')}
              </span>
              <span> | </span>
              <span
                className="notification-link"
                onClick={() => {
                  clearNotifications('all')
                }}
              >
                {t('Notifications:clearAll')}
              </span>
            </div>
            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: '0.5rem',
                gap: '0.5rem',
              }}
              onClick={() => {
                setIsDirectMentionOnly(!isDirectMentionOnly)
              }}
            >
              {!isDirectMentionOnly && (
                <span
                  style={{ fontSize: '35px', color: 'rgb(176, 173, 173)' }}
                  class="material-symbols-outlined"
                >
                  toggle_off
                </span>
              )}

              {isDirectMentionOnly && (
                <span
                  style={{ fontSize: '35px', color: '#4472c4' }}
                  class="material-symbols-outlined"
                >
                  toggle_on
                </span>
              )}
              <span>{t('Notifications:onlyMyDirectMentions')}</span>
            </div>
          </div>
          <div class="row-border"></div>
          {notificationsList.length === 0 && (
            <span style={{ marginTop: '2rem' }}> {t('Notifications:youHaveNoNotifications')}</span>
          )}
          {console.log(notificationsList)}
          {notificationsList.length > 0 &&
            notificationsList.map((notif, index) => (
              <div key={index}>
                {notif?.action?.commentNotification &&
                  notif?.action?.commentNotification?.commentOn !== 'UNKNOWN_COMMENT_TYPE' && (
                    <NotificationRow
                      description={generateHeader(notif?.action?.commentNotification)}
                      empName={notif?.action?.commentNotification?.commenterName}
                      notificationText={notif?.action?.commentNotification?.notificationText}
                      createdDate={notif?.action?.creationTime}
                      seeMoreClickHandler={() => {
                        handleSeeMore(notif?.action?.commentNotification)
                      }}
                      actionId={notif?.action?.actionId}
                      clearDictionary={clearDictionary}
                      handleChangeCheckbox={() => {
                        handleChangeCheckbox(notif?.action?.actionId)
                      }}
                      index={index}
                    />
                  )}

                {notif?.action?.milestoneActionNotification &&
                  notif?.action?.milestoneActionNotification?.milestoneActionType !==
                    'UNKNOWN_MILESTONE_ACTION_TYPE' && (
                    <NotificationRow
                      description={generateHeader(notif?.action?.milestoneActionNotification)}
                      empName={notif?.action?.milestoneActionNotification?.createdByName}
                      notificationText={notif?.action?.milestoneActionNotification?.milestoneText}
                      createdDate={notif?.action?.creationTime}
                      seeMoreClickHandler={() => {
                        handleSeeMore(notif?.action?.milestoneActionNotification)
                      }}
                      actionId={notif?.action?.actionId}
                      clearDictionary={clearDictionary}
                      handleChangeCheckbox={() => {
                        handleChangeCheckbox(notif?.action?.actionId)
                      }}
                      index={index}
                    />
                  )}

                {notif?.action?.milestoneActionAssignmentNotification &&
                  notif?.action?.milestoneActionAssignmentNotification?.milestoneActionType !==
                    'UNKNOWN_MILESTONE_ACTION_TYPE' && (
                    <NotificationRow
                      description={generateHeader(
                        notif?.action?.milestoneActionAssignmentNotification,
                      )}
                      empName={
                        notif?.action?.milestoneActionAssignmentNotification?.assignmentMakerName
                      }
                      notificationText={
                        notif?.action?.milestoneActionAssignmentNotification?.milestoneText
                      }
                      createdDate={notif?.action?.creationTime}
                      seeMoreClickHandler={() => {
                        handleSeeMore(notif?.action?.milestoneActionAssignmentNotification)
                      }}
                      actionId={notif?.action?.actionId}
                      clearDictionary={clearDictionary}
                      handleChangeCheckbox={() => {
                        handleChangeCheckbox(notif?.action?.actionId)
                      }}
                      index={index}
                    />
                  )}

                {notif?.action?.meetingTopicNotification && (
                  <NotificationRow
                    description={generateHeader(notif?.action?.meetingTopicNotification)}
                    empName={notif?.action?.meetingTopicNotification?.createdByName}
                    notificationText={notif?.action?.meetingTopicNotification?.topicDescription}
                    createdDate={notif?.action?.creationTime}
                    seeMoreClickHandler={() => {
                      handleSeeMore(notif?.action?.meetingTopicNotification)
                    }}
                    actionId={notif?.action?.actionId}
                    clearDictionary={clearDictionary}
                    handleChangeCheckbox={() => {
                      handleChangeCheckbox(notif?.action?.actionId)
                    }}
                    index={index}
                  />
                )}
              </div>
            ))}
        </div>
      </div>
      {isOpenCardModal && (
        <CardModal
          isModalOpen={isOpenCardModal}
          closeModal={() => setIsOpenCardModal(false)}
          umtId={cardModalTopicId}
          topicType={cardModalTopicType}
          hasTopicType={false}
        />
      )}

      {isOpenCommentsModal && (
        <CommentsModal2
          objective={commentsModalObjective}
          taskId={commentsModalTaskId}
          handleCancel={() => {
            setIsOpenCommentsModal(false)
          }}
          setIsRefreshObjective={() => {
            loadNotifications()
          }}
          displayAllComments={commentsModalDisplayAllComments}
        />
      )}

      {isOpenActivityModal && (
        <AddEditActivityModal
          isModalOpen={isOpenActivityModal}
          closeModal={() => {
            setIsActivityModalOpen(false)
          }}
          objectiveId={activityModalObjectiveId}
          activityId={activityModalActivityId}
          setIsRefreshObjective={() => {
            //setRefreshIndex(refreshIndex + 1)
          }}
          //refreshBGUScorecardFn={refreshBGUScorecardFn}
        />
      )}
    </ApplicationLayout>
  )
}

export default Notifications

import React from 'react'
import Routing from './Routing'
import axios from 'axios'
import Swal from 'sweetalert2'

import './App.scss'
import './responsive-styles/mobile-portrait.css'
import './responsive-styles/mobile-landscape.css'
import './responsive-styles/tablet.css'
import './responsive-styles/small-landscape.css'

import { QueryClient, QueryCache, QueryClientProvider } from '@tanstack/react-query'
import { FeatureFlagsProvider } from './utils/featureFlagsContext'
import { getHttpRequest } from './api/query/dynamicAPI'
import { NavbarProvider } from './utils/navBarContext'
import { clearLocalStorage } from './utils/auth'
import { MeetingRecordingProvider } from './utils/meetingRecordingContext/meetingRecordingContext'
import { KanbanVoiceProvider } from './pages/kanban/kanbanContext/KanbanContext'

axios.defaults.withCredentials = process.env.REACT_APP_DEVELOPER_ENV !== undefined
const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

let is_first_error = true

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      if (error.response.status === 401 || error.response.status === 403) {
        // alert("We apologize, an error has occurred. Please let your TransforML partner know of error code 0x1A. Please log in again.")
        localStorage.setItem('logoutReasonJan_1A', '0x1A')
        let userEmail = localStorage.getItem('tfmu') || 'none'

        let userEmailForLog = ''
        if (userEmail === 'none') {
          userEmailForLog = localStorage.getItem('tempLoginCode') || 'none2'
        } else {
          localStorage.setItem('tempLoginCode', userEmail)
          userEmailForLog = userEmail
        }

        clearLocalStorage()

        // We logout only once on the first error
        if (is_first_error) {
          is_first_error = false
          const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)
          window.location.href = `${BASE_URL}/f/logout?reason=0x1A&user=${userEmailForLog}&resp=${error.response.status}`
        }
      }
    },
  }),
})

const AppNew = () => {
  const [lastPing, setLastPing] = React.useState(0)
  const lastPingRef = React.useRef(lastPing) // Create a ref to hold the current value of lastPing
  const pingUrl = `/ping`

  const logout = (reason = 'unknown') => {
    let userEmail = localStorage.getItem('tfmu') || 'none'
    // Do a deep copy before we clear local storage.
    let userEmailForLog = ''
    if (userEmail === 'none') {
      userEmailForLog = localStorage.getItem('tempLoginCode') || 'none2'
    } else {
      localStorage.setItem('tempLoginCode', userEmail)
      userEmailForLog = userEmail
    }

    clearLocalStorage()
    localStorage.setItem('logoutReason', 'timeout')
    localStorage.setItem('logoutReasonXR', 'logoutFn')

    window.location.href = `${BASE_URL}/f/logout?reason=${reason}&user=${userEmailForLog}&sub=timeout`
  }

  const extendSessionModal = async () => {
    const result = await Swal.fire({
      title: 'Your session is about to end',
      text: 'Do you want to continue?',
      showCancelButton: true,
      confirmButtonText: 'Yes, keep me logged in',
      cancelButtonText: 'No, log me out',
    })

    if (result.isConfirmed) {
      const response = await getHttpRequest(pingUrl + '?pong')
    } else {
      // alert("Your session will end now. Please let your TransforML partner know of status code 0x1B. Please log in again.")
      localStorage.setItem('logoutReasonJan_1B')

      logout('0x1B')
    }
  }

  React.useEffect(() => {
    const timeoutDelaySecs = 5 * 60 * 1000 // 5 minutes

    if (localStorage.getItem('alive') === 'true') {
      // Check every one second if we need to ping the server. This is because setInterals get
      // paused when the computer is asleep.
      const interval = setInterval(() => {
        if (Date.now() - lastPingRef.current > timeoutDelaySecs) {
          const fetchData = async () => {
            try {
              const response = await getHttpRequest(pingUrl)

              if (response === 'EXPIRING') {
                // No need to clear out the interval, because we want to log the user out after the
                // next interval.
                localStorage.setItem('sessionNearExpry', 'True')
                extendSessionModal()
              } else if (response !== 'ALIVE') {
                // alert("Your session will end now. Please let your TransforML partner know of error code 0x1C. Please log in again.")
                localStorage.setItem('logoutReasonJan_1C', '0x1C')

                logout('0x1C')
              }
            } catch (e) {
              // alert("We apologize, an error has occurred. Please let your TransforML partner know of error code 0x1D. Please log in again.")
              localStorage.setItem('logoutReasonJan_1D', '0x1D')
              localStorage.setItem('logoutReasonJanAdditional', e)

              // logout('0x1D')
            }
          }
          fetchData()
          lastPingRef.current = Date.now()
        }
      }, 1000) // 1 second
      return () => clearInterval(interval)
    } else {
      localStorage.setItem('PingNotAlive', 'true')
      // const userEmail = localStorage.getItem('tfmu') || ''
      // window.location.href = `${BASE_URL}/f/logout?reason=${reason}&user=${userEmail}&sub=timeout`
    }
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <FeatureFlagsProvider>
        <MeetingRecordingProvider>
          <KanbanVoiceProvider>
            <NavbarProvider>
              <Routing />
            </NavbarProvider>
          </KanbanVoiceProvider>
        </MeetingRecordingProvider>
      </FeatureFlagsProvider>
    </QueryClientProvider>
  )
}

export default AppNew

import { useState, useEffect } from 'react'
import React, { useCallback } from 'react'
import ReactFlow, { addEdge, Controls, Background, useNodesState, useEdgesState } from 'reactflow'
import Flow from './Flow'
import { getListOfObjectivesFlow } from '../../api/services/objectives.services'
import InPageLoader from '../../components/InPageLoader/index'
import './style.scss'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import { postHttpRequest, getHttpRequest } from '../../api/query/dynamicAPI'
import ObjMapSavedObjsModal from '../../components/ObjMapSavedObjsModal'
import Toast from '../../common/toast'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../utils/routes'

export default function ObjectiveMapByObjective({
  objectiveList,
  activitiesModalClickHander,
  setIsObjHistoryOpen,
  setObjId,
  setIsAddEditObjModalOpen,
  setIsL1L2ModalOpen,
  setLastClickedLevel,
  editedObj,
  lastClickedLevel,
  setObjModalMode,
  setObjData,
  setActivitiesModalMode,
  setIsNotificationModalOpen,
  setIsRequestModalOpen,
  singleObjId,
  setSingleObjId = () => {},
  setIsViewFilesModalOpen,
  setIsKPIModalOpen,
  setOpenOnSpecificKpi,
  ShowDeleteObjectiveModal,
  ShowCompleteObjectiveModal,
  setIsGanttChartModalOpen,
  expandedMode = false,
  fromSetUpAgent = false,
  setIsAddEditMeetingTopicModalOpen,
  setIsCardModalOpen,
  isReviewMode = false,
  setSelectedTopicId,
  setSelectedTopicType,
  deleteMeetingTopic,
  setSelectedMeetingTopic,
  setIsAddL1Objective,
  setIsRecommendedObjStatementModalOpen,
  HandleCardFocus,
}) {
  const { t } = useTranslation(['ObjectiveMap', 'Common'])
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])

  const [isLoading, setIsLoading] = useState(false)

  const [teamLeader, setTeamLeader] = useState([])

  const [objectiveData, setObjectiveData] = useState([])

  const [cardLevel10, setCardLevel10] = useState([])
  const [cardLevel1, setCardLevel1] = useState([])
  const [cardLevel2, setCardLevel2] = useState([])
  const [cardLevel3, setCardLevel3] = useState([])

  const [selectLevel10, setSelectLevel10] = useState([])
  const [selectLevel1, setSelectLevel1] = useState([])
  const [selectLevel2, setSelectLevel2] = useState([])
  const [selectLevel3, setSelectLevel3] = useState([])

  const [level10Value, setLevel10Value] = useState('')
  const [level1Value, setLevel1Value] = useState('')
  const [level2Value, setLevel2Value] = useState('')
  const [level3Value, setLevel3Value] = useState('')

  const [totalCardDisplay, setTotalCardDisplay] = useState([])
  const [main, setMain] = useState(0)

  const [zoomIndexLevel1, setZoomIndexLevel1] = useState([])
  const [zoomIndexLevel2, setZoomIndexLevel2] = useState([])

  const [childrenCount, setChildrenCount] = useState({})
  const [searchedObjectives, setSearchedObjectives] = useState([])
  const [isInSearchMode, setIsInSearchMode] = useState(false)
  const [searchedNodeAncestors, setSearchedNodeAncestors] = useState([])
  const [displayedTree, setDisplayedTree] = useState([])

  const [isPrevSearchedOpen, setIsPrevSearchedOpen] = useState(false)
  // groups of saved searched objectives in the modal
  const [savedSearchedObjectives, setSavedSearchedObjectives] = useState([])
  const [isSavedResultsClicked, setIsSavedResultsClicked] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    getObjectiveFlow()
  }, [objectiveList])

  useEffect(() => {
    if (expandedMode) {
      initializeZoomLevels()
    }
  }, [cardLevel1, cardLevel2])

  useEffect(() => {
    if (singleObjId && totalCardDisplay.length > 0) {
      setSearchedObjectives(totalCardDisplay.filter((card) => singleObjId == card.id))
    }
  }, [totalCardDisplay])

  useEffect(() => {
    if (editedObj) {
      let tempCardLevel = []
      if (lastClickedLevel === 1) {
        tempCardLevel = [...cardLevel1]
      } else if (lastClickedLevel === 2) {
        tempCardLevel = [...cardLevel2]
      } else if (lastClickedLevel === 3) {
        tempCardLevel = [...cardLevel3]
      }

      for (let i = 0; i < tempCardLevel.length; i++) {
        if (parseInt(tempCardLevel[i]?.id) === editedObj?.objId) {
          tempCardLevel[i].goal = editedObj.statement
        }
      }
      if (lastClickedLevel === 1) {
        setCardLevel1(tempCardLevel)
      } else if (lastClickedLevel === 2) {
        setCardLevel2(tempCardLevel)
      } else if (lastClickedLevel === 3) {
        setCardLevel3(tempCardLevel)
      }

      setFlowData()
    }
  }, [editedObj])

  async function getObjectiveFlow() {
    const result = objectiveList

    let finalCard = []
    let childrenCountTmp = {}

    if (result && result.levelCards) {
      setObjectiveData(result.levelCards)
      for (let x = 0; result.levelCards.length > x; x++) {
        const obj = result.levelCards[x]
        // set drop down data point
        if (obj.level == 1)
          setLevelDropValue(obj.thisLevelSegementDisplayNames, obj.thisLevelSegementIds, obj.level)
        // TODO: If we decide to show level 10 conditionally (not send from backend in some cases),
        // this needs to be made conditional.
        if (obj.level == 10) setLevel10Value(obj.thisLevelSegementIds[0])
        // set cards array.
        if (
          obj.cardDisplayGroups &&
          obj.cardDisplayGroups.length > 0 &&
          obj.cardDisplayGroups[0].cards &&
          obj.cardDisplayGroups[0].cards.length > 0
        ) {
          const cards = obj.cardDisplayGroups
          let temp = []
          for (let y = 0; cards.length > y; y++) {
            temp = temp.concat(cards[y].cards)
            finalCard = finalCard.concat(cards[y].cards)
          }

          if (obj.level == 10) {
            setCardLevel10(temp)
          } else if (obj.level == 1) {
            setCardLevel1(temp)
          } else if (obj.level == 2) {
            setCardLevel2(temp)
          } else if (obj.level == 3) {
            setCardLevel3(temp)
          }
        }
      }
    }
    setTotalCardDisplay(finalCard)

    const finalEdges = []
    // Create Edges
    for (let y = 0; finalCard.length > y; y++) {
      let mainObj = finalCard[y]
      mainObj.ignoreOwnerMatch = true
      if (mainObj.parentId != 0) {
        const dataEdges = {
          id: `e2-${mainObj.parentId}-${mainObj.id}`,
          source: `${mainObj.parentId}`,
          target: `${mainObj.id}`,
          // type: "step",
          style: {
            stroke: '#737373',
            strokeWidth: 4,
          },
        }
        finalEdges.push(dataEdges)

        // Add count to its parent
        if (mainObj.parentId in childrenCountTmp) {
          childrenCountTmp[mainObj.parentId] += 1
        } else {
          childrenCountTmp[mainObj.parentId] = 1
        }
      }
    }
    setEdges(finalEdges)
    setChildrenCount(childrenCountTmp)
  }

  function initializeZoomLevels() {
    // Set Level 1 to first card ownerId
    setLevel1Value(cardLevel1.map((card) => card.ownerId)[0])

    // const level1ZoomIndex = cardLevel1.map(card => parseInt(card.id));
    const level1ZoomIndex = [0]
    const level2ZoomIndex = cardLevel2.map((card) => parseInt(card.id))

    setZoomIndexLevel1(level1ZoomIndex)
    setZoomIndexLevel2(level2ZoomIndex)

    setDisplayedTree([...totalCardDisplay]) // Display all cards initially
  }

  function setLevelDropValue(titleArray, numberArray, levelType) {
    const finalArray = []
    for (let x = 0; titleArray.length > x; x++) {
      const value = titleArray[x]
      const id = numberArray[x]

      finalArray.push({ value, id })
    }

    if (levelType === 1) {
      setSelectLevel1(finalArray)
    }
    if (levelType === 2) {
      setSelectLevel2(finalArray)
    }
    if (levelType === 3) {
      setSelectLevel3(finalArray)
    }
  }

  useEffect(() => {
    setFlowData()
  }, [
    level1Value,
    level2Value,
    level3Value,
    main,
    totalCardDisplay,
    zoomIndexLevel1,
    zoomIndexLevel2,
  ])

  function setFlowData() {
    // In search mode, we only show the cards that are in the searchedObjectives list and their
    // parents. Their parents are added in the Zoom Index list.

    // If there is level 10, others will move down.
    let levelPositionsY =
      cardLevel10.length > 0
        ? {
            10: 10,
            1: 250,
            2: 500,
            3: 750,
          }
        : {
            1: 10,
            2: 250,
            3: 500,
          }

    let finalNode = []

    let nodesAdded10 = []
    let nodesAdded1 = []
    let nodesAdded2 = []
    let nodesAdded3 = []

    const cards = [...totalCardDisplay]

    // Note: Always create a new object when adding to the list nodes, so that we can trigger a
    // re-render in ReactFlow. We don't always need a re-render for each card, but there's no easy
    // way to identify which cards need to be re-rendered  (e.g. when the zoom icon changes).

    let count = 0

    if (level10Value !== '') {
      // Level 10
      for (let x = 0; cardLevel10.length > x; x++) {
        const mainObj = cardLevel10[x]

        if (mainObj.ownerId == level10Value) {
          mainObj.main = 10
          mainObj.value = level10Value
          mainObj.hasChildren = true
          mainObj.ShowActivitesModal = ShowActivitesModal
          mainObj.ShowHistoryModal = ShowHistoryModal
          mainObj.ShowOverdueModal = ShowOverdueModal
          mainObj.ShowNotificationsModal = ShowNotificationsModal
          mainObj.isSearchedObjective = findCardIndexWithId(searchedObjectives, mainObj?.id) >= 0
          mainObj.ShowEditModal = ShowEditModal
          mainObj.ShowAddModal = ShowAddModal
          mainObj.ShowRequestUpdateModal = ShowRequestUpdateModal
          mainObj.ShowFilesModal = ShowFilesModal
          mainObj.ShowKPIModal = ShowKPIModal
          mainObj.ShowKPIModalSpecificKpi = ShowKPIModalSpecificKpi
          mainObj.ShowChangelogPage = ShowChangelogPage
          mainObj.expandedMode = expandedMode
          mainObj.ShowGanttChartModal = ShowGanttChartModal
          mainObj.ShowAddEditMeetingTopicModal = ShowAddEditMeetingTopicModal
          mainObj.isReviewMode = isReviewMode
          mainObj.ShowRecommendedObjStatementModalOpen = ShowRecommendedObjStatementModalOpen

          const dataNode = {
            id: `${mainObj?.id}`,
            type: 'customNodeObjectiveMode',
            data: mainObj,
            position: { x: 220 * (count + 1), y: levelPositionsY[10] },
          }
          ++count
          nodesAdded10.push(dataNode)
        }
      }
    }

    let level1CardsPositions = {}
    if (level1Value !== '') {
      let count = 0
      for (let x = 0; cardLevel1.length > x; x++) {
        // If search mode is enabled, then we should only show cards which are in zoomIndexLevel1
        // (ancestor of searched cards) or searchedObjectives. OTOH if search mode is disabled,
        // then we should show all cards for the selected owner.
        if (
          isInSearchMode &&
          findCardIndexWithId(searchedNodeAncestors, cardLevel1[x].id) === -1 &&
          findCardIndexWithId(zoomIndexLevel1, cardLevel1[x].id) === -1
        ) {
          continue
        } else if (!isInSearchMode && cardLevel1[x].ownerId !== parseInt(level1Value)) {
          continue
        }

        const mainObj = { ...cardLevel1[x] }
        mainObj.main = 1
        mainObj.value = level1Value

        mainObj.setZoomIndex = setZoomIndex
        mainObj.zoomIndexLevel = zoomIndexLevel1
        mainObj.isInSearchMode = isInSearchMode
        mainObj.hasChildren = childrenCount[mainObj.id] > 0
        mainObj.ShowActivitesModal = ShowActivitesModal
        mainObj.ShowHistoryModal = ShowHistoryModal
        mainObj.ShowOverdueModal = ShowOverdueModal
        mainObj.ShowNotificationsModal = ShowNotificationsModal
        mainObj.isSearchedObjective = findCardIndexWithId(searchedObjectives, mainObj?.id) >= 0
        mainObj.ShowEditModal = ShowEditModal
        mainObj.ShowAddModal = ShowAddModal
        mainObj.ShowRequestUpdateModal = ShowRequestUpdateModal
        mainObj.ShowFilesModal = ShowFilesModal
        mainObj.ShowKPIModal = ShowKPIModal
        mainObj.ShowKPIModalSpecificKpi = ShowKPIModalSpecificKpi
        mainObj.ShowChangelogPage = ShowChangelogPage
        mainObj.ShowGanttChartModal = ShowGanttChartModal
        mainObj.expandedMode = expandedMode
        mainObj.ShowAddEditMeetingTopicModal = ShowAddEditMeetingTopicModal
        mainObj.isReviewMode = isReviewMode
        mainObj.ShowRecommendedObjStatementModalOpen = ShowRecommendedObjStatementModalOpen

        const dataNode = {
          id: `${mainObj?.id}`,
          type: 'customNodeObjectiveMode',
          data: mainObj,
          position: fromSetUpAgent
            ? { x: 220 * count + 10, y: levelPositionsY[1] }
            : { x: 220 * (count + 1), y: levelPositionsY[1] },
        }

        ++count
        nodesAdded1.push(dataNode)
        level1CardsPositions[mainObj.id] = dataNode.position

        if (fromSetUpAgent && mainObj?.recommendations?.length > 0) {
          for (let recommendation of mainObj.recommendations) {
            const recNode = {
              id: `${mainObj?.id}-rec-${recommendation.length}`,
              type: 'recNode',
              data: recommendation,
              position: {
                x: 220 * count + 10,
                y: levelPositionsY[1],
              },
            }
            count++
            nodesAdded1.push(recNode)
          }
        }
      }
    }

    // Level 2, rendered only if we have zoomed in on a card in Level 1.
    //set meetingtopic node here
    let level2CardsPositions = {}
    if (zoomIndexLevel1.length > 0) {
      let unsortedLevel2CardsMeta = []
      let level2Topics = []
      count = 0
      for (let x = 0; cardLevel2.length > x; x++) {
        const mainObj = { ...cardLevel2[x] }

        if (findCardIndexWithId(displayedTree, mainObj.id) === -1) {
          continue
        }

        mainObj.main = 2
        mainObj.value = level2Value

        mainObj.setZoomIndex = setZoomIndex
        mainObj.zoomIndexLevel = zoomIndexLevel2
        mainObj.isInSearchMode = isInSearchMode

        mainObj.hasChildren = childrenCount[mainObj.id] > 0
        mainObj.ShowActivitesModal = ShowActivitesModal
        mainObj.ShowHistoryModal = ShowHistoryModal
        mainObj.ShowOverdueModal = ShowOverdueModal
        mainObj.ShowNotificationsModal = ShowNotificationsModal
        mainObj.isSearchedObjective = findCardIndexWithId(searchedObjectives, mainObj?.id) >= 0
        mainObj.ShowEditModal = ShowEditModal
        mainObj.ShowAddModal = ShowAddModal
        mainObj.ShowRequestUpdateModal = ShowRequestUpdateModal
        mainObj.ShowFilesModal = ShowFilesModal
        mainObj.ShowKPIModal = ShowKPIModal
        mainObj.ShowKPIModalSpecificKpi = ShowKPIModalSpecificKpi
        mainObj.ShowChangelogPage = ShowChangelogPage
        mainObj.ShowDeleteObjectiveModal = ShowDeleteObjectiveModal
        mainObj.ShowCompleteObjectiveModal = ShowCompleteObjectiveModal
        mainObj.ShowGanttChartModal = ShowGanttChartModal
        mainObj.expandedMode = expandedMode
        mainObj.ShowAddEditMeetingTopicModal = ShowAddEditMeetingTopicModal
        mainObj.isReviewMode = isReviewMode
        mainObj.ShowRecommendedObjStatementModalOpen = ShowRecommendedObjStatementModalOpen

        unsortedLevel2CardsMeta.push(mainObj)
      }

      // Sort unsortedLevel2CardsMeta by parent id. The assumption is that the parents are sorted by
      // IDs.
      unsortedLevel2CardsMeta.sort((a, b) => {
        return sortHelper(a, b, level1CardsPositions)
        // return level1CardsPositions[a.parentId].x - level1CardsPositions[b.parentId].x
      })
      for (let mainObj of unsortedLevel2CardsMeta) {
        let topicCount = 0
        const dataNode = {
          id: `${mainObj?.id}`,
          type: 'customNodeObjectiveMode',
          data: mainObj,
          position: fromSetUpAgent
            ? { x: 220 * count + 10, y: levelPositionsY[2] }
            : { x: 220 * (count + 1), y: levelPositionsY[2] },
        }
        if ((fromSetUpAgent || isReviewMode) && mainObj.topicList?.length > 0) {
          for (let topic of mainObj.topicList) {
            topic.ShowEditTopicModal = ShowEditTopicModal
            topic.ShowCardTopicModal = ShowCardTopicModal
            topic.ShowDeleteTopic = ShowDeleteTopic
            topic.HandleCardFocus = HandleCardFocus
            topic.isReviewMode = isReviewMode
            const topicNode = {
              id: `${mainObj?.id}-topic-${topicCount}`,
              type: 'topicNode',
              data: topic,
              position: {
                x: 220 * (count + 1),
                y: levelPositionsY[2] + 100 + 150 * (topicCount + 1),
              },
            }
            nodesAdded2.push(topicNode)
            ++topicCount
          }
        }
        ++count
        nodesAdded2.push(dataNode)
        level2CardsPositions[mainObj.id] = dataNode.position
      }
    }

    // Level 3, rendered only if we have zoomed in on a card in Level 2 and (by extension, level 1).
    count = 0
    nodesAdded3 = []
    let unsortedLevel3CardsMeta = []

    for (let x = 0; cardLevel3.length > x; x++) {
      const mainObj = { ...cardLevel3[x] }

      if (findCardIndexWithId(displayedTree, mainObj.id) === -1) continue

      mainObj.main = 3
      mainObj.value = level3Value

      mainObj.hasChildren = childrenCount[mainObj.id] > 0
      mainObj.isInSearchMode = null
      mainObj.ShowActivitesModal = ShowActivitesModal
      mainObj.ShowHistoryModal = ShowHistoryModal
      mainObj.ShowOverdueModal = ShowOverdueModal
      mainObj.ShowNotificationsModal = ShowNotificationsModal
      mainObj.isSearchedObjective = findCardIndexWithId(searchedObjectives, mainObj?.id) >= 0
      mainObj.ShowEditModal = ShowEditModal
      mainObj.ShowAddModal = ShowAddModal
      mainObj.ShowRequestUpdateModal = ShowRequestUpdateModal
      mainObj.ShowFilesModal = ShowFilesModal
      mainObj.ShowKPIModal = ShowKPIModal
      mainObj.ShowKPIModalSpecificKpi = ShowKPIModalSpecificKpi
      mainObj.ShowChangelogPage = ShowChangelogPage
      mainObj.ShowDeleteObjectiveModal = ShowDeleteObjectiveModal
      mainObj.ShowCompleteObjectiveModal = ShowCompleteObjectiveModal
      mainObj.fromSetUpAgent = fromSetUpAgent
      mainObj.ShowGanttChartModal = ShowGanttChartModal
      mainObj.ShowAddEditMeetingTopicModal = ShowAddEditMeetingTopicModal
      mainObj.isReviewMode = isReviewMode
      mainObj.ShowRecommendedObjStatementModalOpen = ShowRecommendedObjStatementModalOpen

      unsortedLevel3CardsMeta.push(mainObj)
    }

    // Sort unsortedLevel3CardsMeta by parent id. Once again, the assumption is that the parents are
    // sorted by IDs.
    unsortedLevel3CardsMeta.sort((a, b) => {
      return sortHelper(a, b, level2CardsPositions)
      // return level2CardsPositions[a.parentId].x - level2CardsPositions[b.parentId].x
    })

    for (let mainObj of unsortedLevel3CardsMeta) {
      let topicCount = 0
      const dataNode = {
        id: `${mainObj?.id}`,
        type: 'customNodeObjectiveMode',
        data: mainObj,
        position: { x: 220 * (count + 1), y: levelPositionsY[3] },
      }
      if ((fromSetUpAgent || isReviewMode) && mainObj.topicList.length > 0) {
        for (let topic of mainObj.topicList) {
          topic.ShowEditTopicModal = ShowEditTopicModal
          topic.ShowCardTopicModal = ShowCardTopicModal
          topic.ShowDeleteTopic = ShowDeleteTopic
          topic.HandleCardFocus = HandleCardFocus
          topic.isReviewMode = isReviewMode
          const topicNode = {
            id: `${mainObj?.id}-topic-${topicCount}`,
            type: 'topicNode',
            data: topic,
            position: {
              x: 220 * (count + 1),
              y: levelPositionsY[3] + 100 + 150 * (topicCount + 1),
            },
          }
          nodesAdded2.push(topicNode)
          ++topicCount
        }
      }
      ++count
      nodesAdded3.push(dataNode)
    }

    // Show level 1 dropdown. hide dropdown
    let dataNode = {
      id: `main-1`,
      type: 'dropDownEdge',
      data: {
        label: 'objective',
        view: 'objective',
        option: selectLevel1,
        value: level1Value,
        dropDownChangedHandler: dropDownChangedHandler,
        dropDown: true,
        main: 1,
      },
      position: { x: 10, y: levelPositionsY[1] },
    }
    if (!fromSetUpAgent) {
      finalNode.push(dataNode)
    }

    // Add Empty Node Index
    if (nodesAdded2.length === 0 && level2Value != '') {
      const dataNode = {
        id: `e2-1`,
        type: 'emptyNode',
        position: { x: 220 * (0 + 1), y: levelPositionsY[2] },
      }
      nodesAdded2.push(dataNode)
    }

    if (nodesAdded3.length === 0 && level3Value != '') {
      const dataNode = {
        id: `e2-2`,
        type: 'emptyNode',
        position: { x: 220 * (0 + 1), y: levelPositionsY[3] },
      }
      nodesAdded3.push(dataNode)
    }

    finalNode = finalNode.concat(nodesAdded1)
    finalNode = finalNode.concat(nodesAdded2)
    finalNode = finalNode.concat(nodesAdded3)
    finalNode = finalNode.concat(nodesAdded10)

    setNodes(finalNode)
  }

  function addLevel1ObjButtonHandler() {
    let ownerId = level1Value
    setObjData({ ownerId: ownerId })
    setObjModalMode('add')
    setObjData({ ownerId: ownerId })
    setIsAddL1Objective(true)
    setObjId(null)
    setIsL1L2ModalOpen(true)
  }

  function dropDownChangedHandler(event, level, nextLevel) {
    setZoomIndexLevel1([])
    setZoomIndexLevel2([])
    setIsInSearchMode(false)
    setSearchedObjectives([])
    setSearchedNodeAncestors([])
    setDisplayedTree([])

    if (level === 1) {
      setLevel1Value(event.target.value)
      setLevel2Value('')
      setLevel3Value('')

      if (objectiveData.length > 1) {
        const value = objectiveData.findIndex((x) => x.level == level)

        const downMappings = objectiveData[value].dropDownMappings
        for (let x = 0; downMappings.length > x; x++) {
          const obj = downMappings[x]
          if (obj.upperLevelPersonId == event.target.value) {
            setLevelDropValue(obj.lowerLevelPersonsTitles, obj.lowerLevelPersonsIds, 2)
            break
          } else {
            setLevelDropValue([], [], 2)
          }
        }
      }
    }

    setMain(nextLevel)
  }

  function setZoomIndex(data, level, isZoomIn) {
    // Disabling this because we will not end the search mode when one zooms in.
    // setIsInSearchMode(false)
    // setSearchedObjectives([])

    let displayedTreeTmp = [...displayedTree]
    if (isZoomIn) {
      // If we are zooming, add the immediate children of this card to the displayTree.
      // We will use this to determine if a card is a child of a searched card.
      for (let obj of totalCardDisplay) {
        if (obj.parentId == data.id) {
          displayedTreeTmp.push(obj)
        }
      }
    } else {
      // If we are zooming out, delete all children and grandchildren of this card from the
      // displayedTree.
      let indicesToDelete = []
      for (let i = 0; i < displayedTreeTmp.length; i++) {
        if (displayedTreeTmp[i].parentId == data.id) {
          // Delete children of displayedTreeTmp[i] too.
          for (let j = 0; j < displayedTreeTmp.length; j++) {
            if (displayedTreeTmp[j].parentId == displayedTreeTmp[i].id) {
              // displayedTreeTmp.splice(j, 1)
              indicesToDelete.push(j)
            }
          }
          // displayedTreeTmp.splice(i, 1)
          // --i
          indicesToDelete.push(i)
        }
      }

      // Delete in reverse order so that the indices don't change.
      indicesToDelete.sort((a, b) => b - a)
      for (let idx of indicesToDelete) {
        displayedTreeTmp.splice(idx, 1)
      }
    }

    setDisplayedTree(displayedTreeTmp)

    if (level == 1) {
      let idx1 = findCardIndexWithId(zoomIndexLevel1, data.id)
      let zoomIndexLevel1Tmp = [...zoomIndexLevel1]

      if (idx1 >= 0) {
        zoomIndexLevel1Tmp.splice(idx1, 1)
        setZoomIndexLevel1(zoomIndexLevel1Tmp)

        // We need to turn off level 2 for the children of this card.
        let zoomIndexLevel2Tmp = [...zoomIndexLevel2]
        for (let i = 0; i < zoomIndexLevel2Tmp.length; i++) {
          if (zoomIndexLevel2Tmp[i].parentId == data.id) {
            zoomIndexLevel2Tmp.splice(i, 1)
            --i
          }
        }
        setZoomIndexLevel2(zoomIndexLevel2Tmp)
      } else {
        setZoomIndexLevel1([...zoomIndexLevel1Tmp, data])
      }
    } else if (level == 2) {
      let idx2 = findCardIndexWithId(zoomIndexLevel2, data.id)
      let zoomIndexLevel2Tmp = [...zoomIndexLevel2]

      if (idx2 >= 0) {
        zoomIndexLevel2Tmp.splice(idx2, 1)
        setZoomIndexLevel2(zoomIndexLevel2Tmp)
      } else {
        setZoomIndexLevel2([...zoomIndexLevel2Tmp, data])
      }
    }
  }

  // async function ShowCommentModel(id) {
  //   // setIsShowComment(true)
  //   // setObjectiveId(id)
  // }
  async function ShowActivitesModal(id) {
    setActivitiesModalMode('activities')
    activitiesModalClickHander(true)
    setObjId(id)
  }

  async function ShowHistoryModal(id) {
    setIsObjHistoryOpen(true)
    setObjId(id)
  }

  async function ShowEditModal(id, level) {
    setObjId(id)
    setLastClickedLevel(level)
    setObjModalMode('edit')

    if (level === 1 || level === 2) {
      setIsL1L2ModalOpen(true)
    } else {
      setIsAddEditObjModalOpen(true)
    }
  }

  async function ShowAddModal(data, level) {
    let ownerId = level === 1 ? level2Value : level3Value
    setObjData({ parentId: data.id, topLevelObjectiveId: data.parentId, ownerId: ownerId })

    setLastClickedLevel(level - 1)
    setObjModalMode('add')

    if (level === 1) {
      setIsL1L2ModalOpen(true)
    } else if (level === 2) {
      setIsAddEditObjModalOpen(true)
    }
  }

  async function ShowOverdueModal(id) {
    setActivitiesModalMode('overdue')
    activitiesModalClickHander(true)
    setObjId(id)
  }

  async function ShowNotificationsModal(id) {
    setObjId(id)
    setIsNotificationModalOpen(true)
  }

  async function ShowRequestUpdateModal(id) {
    setObjId(id)
    setIsRequestModalOpen(true)
  }

  async function ShowFilesModal(id) {
    setObjId(id)
    setIsViewFilesModalOpen(true)
  }

  async function ShowKPIModal(data) {
    setObjId(data.id)
    setObjData({ ownerId: data.ownerId, level: data.main })
    setIsKPIModalOpen(true)
  }

  async function ShowKPIModalSpecificKpi(data) {
    setObjId(data.id)
    setObjData({ ownerId: data.ownerId, level: data.main })
    setIsKPIModalOpen(true)
    setOpenOnSpecificKpi(true)
  }

  async function ShowChangelogPage(id) {
    navigate(`/${routes.changeLog}`, { state: { objId: id } })
  }

  async function ShowGanttChartModal(id) {
    setObjId(id)
    setIsGanttChartModalOpen(true)
  }

  async function ShowAddEditMeetingTopicModal(data) {
    //called from objective node, data is objective data
    setSelectedMeetingTopic({
      topicId: null,
      topicType: null,
      topicText: null,
      topicPriority: null,
      objectiveId: null,
      ownerId: data.ownerId,
      ownerName: data.owner,
      leaderId: data.ownerId,
    })
    setObjId(data.id)
    setIsAddEditMeetingTopicModalOpen(true)
  }

  async function ShowEditTopicModal(data) {
    //called from topic node, data is topic data
    let leaderId = level3Value !== '' ? level3Value : level2Value
    setSelectedMeetingTopic({
      topicId: data.umtId,
      topicType: data.topicType,
      topicText: data.description,
      topicPriority: data.isPriority,
      objectiveId: data.objId,
      ownerId: data.topicOwnerId,
      ownerName: data.topicOwnerName,
      leaderId: leaderId,
    })
    setObjId(data.objId)
    setIsAddEditMeetingTopicModalOpen(true)
  }

  async function ShowCardTopicModal(topicId, topicType) {
    setSelectedTopicId(topicId)
    setSelectedTopicType(topicType)
    setIsCardModalOpen(true)
  }

  async function ShowDeleteTopic(topicId) {
    deleteMeetingTopic(topicId)
  }

  async function ShowRecommendedObjStatementModalOpen(data) {
    setObjData({
      ownerId: data.ownerId,
      level: data.main,
      objStatement: data.goal,
      parentId: data.parentId,
    })
    setObjId(data.id)
    setIsRecommendedObjStatementModalOpen(true)
  }

  // we only want to populate the search results AUTOMATICALLY when the user clicks on the select button
  // we don;t want to show result when the user is typing in the search box
  // OR singleObjId is not null which means it's coming from the chat bot search, we should also show the result
  useEffect(() => {
    if (isSavedResultsClicked && searchedObjectives.length > 0) {
      showSearchObjective()
      setIsSavedResultsClicked(false)
    } else if (singleObjId && searchedObjectives.length > 0) {
      showSearchObjective()
      setSingleObjId(null)
    }
  }, [isSavedResultsClicked, searchedObjectives, singleObjId])

  if (isLoading == true) {
    return <InPageLoader />
  }

  const handleChangeObjectiveListFilter = (value) => {
    setSearchedObjectives(value)
  }

  const clearSearchedObjectives = () => {
    setSearchedObjectives([])
    setIsInSearchMode(false)
    setZoomIndexLevel1([])
    setZoomIndexLevel2([])
    setSearchedNodeAncestors([])
    setDisplayedTree([])
  }

  const saveSearchedObjectives = async () => {
    if (searchedObjectives.length === 0) {
      return
    }

    try {
      let postBody = {
        searchedObjectives: searchedObjectives,
      }
      postHttpRequest('/save_obj_map_searched_results', postBody).then((res) => {
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.updatedSuccessfully'),
        })
      })
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const seePrevSavedObjectives = async () => {
    try {
      let response = await getHttpRequest('/get_saved_obj_map_searched_results')
      setSavedSearchedObjectives(response.savedObjectiveGroups)
      setIsPrevSearchedOpen(true)
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const showSearchObjective = () => {
    setIsInSearchMode(true)

    // !!! L10 is not handled !!!
    setLevel1Value('')

    let zoomIndexLevel1Tmp = []
    let zoomIndexLevel2Tmp = []
    let ancestorsIncSelf = []
    let displayedTreeTmp = []

    for (let searchedObjective of searchedObjectives) {
      // Go through all cards and find the one that matches the searched objective.
      // Then open it's parent. If the parent is level 2, open it's parent as well.
      // But first we have to find the L1 card's owner, because it needs to be selected in order to
      // show anything.

      let linkage = [] // This card, parent if exists, and grandparent if exists.
      for (let card of totalCardDisplay) {
        if (card.id == searchedObjective.id) {
          linkage.push({ ...card })
          break
        }
      }

      findAndPushParent(0, linkage)
      findAndPushParent(1, linkage)

      if (linkage.length === 3) {
        // This is an L3 card, expand both levels.
        setLevel1Value(linkage[2].ownerId)
        addToListIfIdNotExists(zoomIndexLevel1Tmp, { id: linkage[2].id })
        addToListIfIdNotExists(zoomIndexLevel2Tmp, { id: linkage[1].id })
      } else if (linkage.length === 2) {
        // This is an L2 card, only expand the L1 card.
        setLevel1Value(linkage[1].ownerId)
        addToListIfIdNotExists(zoomIndexLevel1Tmp, { id: linkage[1].id })
      } else if (linkage.length == 1) {
        // This is an L1 card, don't expand anything.
        setLevel1Value(linkage[0].ownerId)
      } else if (linkage.length == 0) {
        // BAU card, don't expand anything.
        // setLevel1Value(linkage[0].ownerId)
      }

      for (let card of linkage) {
        addToListIfIdNotExists(ancestorsIncSelf, card)
        addToListIfIdNotExists(displayedTreeTmp, card)
      }
    }

    setZoomIndexLevel1(zoomIndexLevel1Tmp)
    setZoomIndexLevel2(zoomIndexLevel2Tmp)

    // let ancestorsIncSelf = zoomIndexLevel1Tmp.concat(zoomIndexLevel2Tmp)
    // addToListIfIdNotExists(ancestorsIncSelf, )
    setSearchedNodeAncestors(ancestorsIncSelf)
    setDisplayedTree(displayedTreeTmp)
  }

  // IN search mode, we should only allow expanding the cards that are in the searchedObjectives
  // list.
  // When we first search for something, we should show exactly paths from search objective to root.
  // When we expand a card, we should show all its children.

  function findAndPushParent(idx, linkage) {
    if (linkage.length > idx && linkage[idx].parentId != 0) {
      for (let card of totalCardDisplay) {
        if (card.id == linkage[idx].parentId) {
          linkage.push({ ...card })
          break
        }
      }
    }
  }

  function findCardIndexWithId(list, id) {
    if (!list) return -1

    for (let i = 0; i < list.length; i++) {
      if (list[i].id == id) {
        return i
      }
    }
    return -1
  }

  function addToListIfIdNotExists(list, item) {
    if (findCardIndexWithId(list, item.id) < 0) {
      list.push(item)
    }
  }

  function sortHelper(a, b, parentCardsPositions) {
    let mx = (parentCardsPositions ? parentCardsPositions.length : 1) * 1000
    let aX = a.parentId in parentCardsPositions ? parentCardsPositions[a.parentId].x : mx
    let bX = b.parentId in parentCardsPositions ? parentCardsPositions[b.parentId].x : mx
    return aX - bX
  }

  function ancestorWasSearched(obj) {
    // Get the parent of the parent of obj, and see if it was searched.
    if (obj.parentId == 0) return false

    for (let possibleParent of totalCardDisplay) {
      if (obj.parentId == possibleParent.id) {
        for (let possibleGrandParent of totalCardDisplay) {
          if (possibleParent.parentId == possibleGrandParent.id) {
            if (findCardIndexWithId(searchedObjectives, possibleGrandParent.id) >= 0) {
              return true
            }
          }
        }
      }
    }

    return false
  }

  return (
    <>
      {isPrevSearchedOpen === true && (
        <ObjMapSavedObjsModal
          isModalOpen={isPrevSearchedOpen}
          closeModal={() => {
            setIsPrevSearchedOpen(false)
            setSavedSearchedObjectives([])
          }}
          savedSearchedObjectives={savedSearchedObjectives}
          setSearchedObjectives={setSearchedObjectives}
          setSavedSearchedObjectives={setSavedSearchedObjectives}
          totalCardDisplay={totalCardDisplay}
          setIsSavedResultsClicked={setIsSavedResultsClicked}
        />
      )}
      <div className="">
        {!fromSetUpAgent && (
          <div>
            <div style={{ width: '50%', float: 'left', padding: '5px' }}>
              <Select
                onChange={handleChangeObjectiveListFilter}
                maxMenuHeight={300}
                getOptionLabel={(option) => option.goal}
                getOptionValue={(option) => option.id}
                options={totalCardDisplay.sort((a, b) => a.goal.localeCompare(b.goal))}
                value={searchedObjectives}
                isMulti
              />
            </div>
            <div style={{ width: '30%', float: 'left', padding: '5px' }}>
              <div
                style={{ float: 'left', marginRight: '5px' }}
                class="fitted-button blue"
                onClick={() => {
                  showSearchObjective()
                }}
                onKeyDown={() => {
                  showSearchObjective()
                }}
              >
                <span style={{ margin: '0px ', height: '24px' }}>{t('menu.searchObjective')}</span>
              </div>

              <div
                style={{ float: 'left', marginRight: '5px' }}
                class="fitted-button blue"
                onClick={() => {
                  seePrevSavedObjectives()
                }}
                onKeyDown={() => {
                  seePrevSavedObjectives()
                }}
              >
                <span style={{ margin: '0px ', height: '24px' }}>{t('menu.showPrevSearch')}</span>
              </div>

              <div
                style={{ float: 'left', marginRight: '5px' }}
                class="fitted-button blue"
                onClick={() => {
                  saveSearchedObjectives()
                }}
                onKeyDown={() => {
                  saveSearchedObjectives()
                }}
              >
                <span style={{ margin: '0px ', height: '24px' }}>{t('menu.saveObjective')}</span>
              </div>

              <div
                style={{ float: 'left' }}
                class="fitted-button blue"
                onClick={() => {
                  clearSearchedObjectives()
                }}
                onKeyDown={() => {
                  clearSearchedObjectives()
                }}
              >
                <span style={{ margin: '0px ', height: '24px' }}>Clear</span>
              </div>
            </div>
          </div>
        )}
        <div className="add-objective">
          {nodes.length > 0 ? (
            <Flow
              initialNodes={nodes}
              initialEdges={edges}
              teamLeader={teamLeader}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  )
}

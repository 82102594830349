import React from 'react'
import './style.css'

const ChatInput = ({ sendText = () => {}, setMessageBubbles }) => {
  const [inputValue, setInputValue] = React.useState('')

  const handleChange = (e) => {
    setInputValue(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (inputValue.trim() !== '') {
      // everytime user enters something, reset the message bubbles
      setMessageBubbles([
        {
          message: `
                <div>
                    <span>Hi! I'm Odin, your AI chief-of-staff. I'm constantly learning and improving. Right now, I can help you with the following:</span>
                    <ul>
                        <li>Finding objectives, meeting topics, or actions that are related to something you are interested in. For example, you can say “what is going on with cyber security projects?”</li>
                        <li>Explore what your team has been working on. For example, you can say “what is my team doing?”</li>
                        <li>See what others are doing related to a particular topic. For example you can say “Find me everything related to X that others are working on”</li>
                        <li>See what a particular person is working on. For example you can ask “What has Jo worked on recently” or “what topics has Jo added recently?”</li>
                        <li>Doing all of the above for a particular date range. For example you can ask "What has my team done in the last 5 days"</li>
                    </ul>
                    <span>Give it a try. I'm happy to help.</span>
                </div>
            `,
          isUser: false,
          isFetched: false,
        },
        { message: inputValue, isUser: true },
        { message: '........', isUser: false, isFetched: false },
      ])
      sendText(inputValue)
      setInputValue('')
    }
  }

  return (
    <div className="input">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={inputValue}
          onChange={handleChange}
          placeholder="Type your message here"
        />
        <button class="send-button">
          <span class="material-symbols-outlined">arrow_upward</span>
        </button>
      </form>
    </div>
  )
}

export default ChatInput

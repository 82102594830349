export default function validate(form, RequiredData) {
  const errors = {}

  if ('status' in form && form.status == '') {
    errors.status = 'Status is required'
  }

  for (let x = 0; RequiredData.length > x; x++) {
    const obj = RequiredData[x]

    if (obj.fieldId == 1) {
      if (form.dueDate == '') {
        // errors.dueDate = `${obj.name} is required !`;
        errors.dueDate = 'Required'
      }
    } else if (obj.fieldId == 2) {
      if (form.assign == '') {
        // errors.assign = `${obj.name} is required !`;
        errors.assign = 'Required'
      }
    } else if (!form[obj.fieldId]) {
      // errors[obj.fieldId] = `${obj.name} is required !`;
      errors[obj.fieldId] = 'Required'
    } else if (obj.fieldId in form && form[obj.fieldId]?.value == '') {
      // errors[obj.fieldId] = `${obj.name} is required !`;
      errors[obj.fieldId] = 'Required'
    }
  }

  return errors
}

import React from 'react'
import Modal from '../../Modal'

import { useTranslation } from 'react-i18next'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'
import InPageLoader from '../../InPageLoader'
import { getCompanyConfigSettings } from '../../../utils/auth'
import { linkifyString } from '../../../utils/general'

const ActionDurationDetailsModal = ({ isModalOpen, closeModal, data, dataIsLoading = false }) => {
  const { t } = useTranslation(['Sankey'])
  const [dataToShow, setDataToShow] = React.useState(null)
  const [showMore, setShowMore] = React.useState(false)
  const [chartsData, setChartsData] = React.useState([])
  const productSettings = getCompanyConfigSettings('productSettings')

  React.useEffect(() => {
    const milestones = data?.milestones

    const minDuration = Math.min(...milestones.map((m) => m.duration))
    const maxDuration = Math.max(...milestones.map((m) => m.duration))

    const bucketSize = 5
    let numBuckets = Math.ceil((maxDuration - minDuration) / bucketSize)
    numBuckets = numBuckets == 0 ? 1 : numBuckets
    const buckets = Array.from({ length: numBuckets }, (_, index) => ({
      range: `${index * bucketSize + minDuration}-${(index + 1) * bucketSize + minDuration}`,
      count: 0,
    }))

    milestones.forEach((milestone) => {
      const bucketIndex = Math.floor((milestone.duration - minDuration) / bucketSize)
      buckets[bucketIndex >= numBuckets ? numBuckets - 1 : bucketIndex].count += 1
    })

    setChartsData(buckets)
    setDataToShow(data?.milestones)
  }, [data])

  function returnDataList(meeting) {
    if (meeting == null) return
    let finalArray = []
    if (meeting?.length > 5) {
      if (showMore) {
        finalArray = meeting
      } else {
        finalArray = meeting.slice(0, 5)
      }
    } else {
      finalArray = meeting
    }
    return (
      <>
        {finalArray?.length > 0 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ width: '950px', marginBottom: '3rem', paddingTop: '2rem' }}>
              <div className="chart-duration-action-table">
                <div className="chart-log-meeting-header"></div>
                <div
                  className="chart-log-meeting-header chart-duration-action-small-header"
                  style={{ textAlign: 'center' }}
                >
                  {t('actionsDurationDetailsModal.objectiveTitle')}
                </div>
                <div
                  className="chart-log-meeting-header chart-duration-action-large-header"
                  style={{ textAlign: 'center' }}
                >
                  {t('actionsDurationDetailsModal.topicTitle')}
                </div>
                <div
                  className="chart-log-meeting-header chart-duration-action-large-header"
                  style={{ textAlign: 'center' }}
                >
                  {t('actionsDurationDetailsModal.actionStatement')}
                </div>
                <div
                  className="chart-log-meeting-header chart-duration-action-small-header"
                  style={{ textAlign: 'center' }}
                >
                  {t('actionsDurationDetailsModal.duration')}
                </div>

                {finalArray?.map((action) => {
                  return (
                    <>
                      <React.Fragment key={`objective-${action.milestoneId}`}>
                        <div></div>
                        <div className="chart-log-meeting-description"></div>
                        <>
                          <div></div>
                          <>
                            <div
                              style={{
                                textAlign: 'left',
                                marginLeft: '0.2rem',
                                wordBreak: 'break-all',
                              }}
                            >
                              {productSettings &&
                                action?.initiativeId?.toString().padStart(5, '0') +
                                  ' - ' +
                                  action?.objDescription}
                              {!productSettings && action?.objDescription}
                            </div>
                            <div
                              style={{
                                textAlign: 'left',
                                marginLeft: '1rem',
                                wordBreak: 'break-all',
                              }}
                            >
                              {linkifyString(action.meetingTopicDescription)}
                            </div>
                            <div
                              style={{
                                textAlign: 'left',
                                marginLeft: '1rem',
                                wordBreak: 'break-all',
                              }}
                            >
                              {linkifyString(action.description)}
                            </div>
                            <div style={{ textAlign: 'left', marginLeft: '1rem' }}>
                              {action.duration}
                            </div>
                            <div></div>
                          </>
                        </>
                      </React.Fragment>
                    </>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          <div style={{ padding: '2rem' }}></div>
        )}
      </>
    )
  }

  return (
    <>
      <Modal
        isModalOpen={isModalOpen === true}
        closeModal={() => closeModal()}
        width={'75%'}
        style={{ padding: '0rem 0rem 1rem 0rem' }}
        title={t('actionsDurationDetailsModal.sankeyTitle')}
      >
        <>
          {dataIsLoading === true && <InPageLoader inComponent={true} />}
          <BarChart width={900} height={300} data={chartsData}>
            <CartesianGrid stroke="none" /> {/* Set stroke to "none" to remove grid lines */}
            <XAxis dataKey="range" />
            <YAxis />
            <Tooltip
              contentStyle={{ background: 'transparent', border: 'none', padding: '0' }}
              cursor={{ fill: 'transparent' }}
            />
            <Bar dataKey="count" barSize={50} fill="#F8BAAB" />
          </BarChart>
          <div
            className="chart-log-meeting-header"
            style={{ textAlign: 'center', fontSize: '28px' }}
          >
            {t('actionsDurationDetailsModal.tableTitle')}
          </div>
          {returnDataList(dataToShow)}
          {dataToShow?.length > 5 && (
            <span className="see_more" onClick={() => setShowMore(!showMore)}>
              {showMore
                ? t('actionsDurationDetailsModal.showLess')
                : t('actionsDurationDetailsModal.showMore')}
            </span>
          )}
        </>
      </Modal>
    </>
  )
}

export default ActionDurationDetailsModal

import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '../Modal'
import { getHttpRequest, postHttpRequest } from '../../api/query/dynamicAPI'
import SelectSingleField from '../CustomMultipleSelect/singleOption'
import InPageLoader from '../InPageLoader'
import Swal from 'sweetalert2'
import ModalSaveButton from '../ModalSubmitButton'
import Toast from '../../common/toast'
import { getAllEmployeeList } from '../../api/services/employee.services'

const ReportingLineModal = ({
  isModalOpen,
  closeModal,
  allTeamLeadersList,
  isFromAl = false,
  allAreaLeadersList,
}) => {
  const { t } = useTranslation(['Common'])
  const [selectedTLId, setSelectedTLID] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [isSaving, setIsSaving] = React.useState(false)

  const [delegations, setDelegations] = React.useState([])
  const [newDelegateId, setNewDelegateId] = React.useState(null)
  const [potentialDelegations, setPotentialDelegations] = React.useState([])
  const [employeesList, setEmployeesList] = React.useState([])
  const [teamLeadersList, setTeamLeadersList] = React.useState([])
  const [areaLeadersList, setAreaLeadersList] = React.useState([])
  console.log('isFromAl', isFromAl)

  React.useEffect(() => {
    if (selectedTLId) {
      fetchDelegations()
    }
  }, [selectedTLId])

  React.useEffect(() => {
    getAllEmployees()
  }, [])

  React.useEffect(() => {
    let ic = employeesList?.filter((person) => [0].includes(person.level) && person.eLeaderId !== 0)
    allTeamLeadersList = [...allTeamLeadersList, ...ic]
    setTeamLeadersList(allTeamLeadersList)
    console.log('allAreaLeadersList', allAreaLeadersList)
    setAreaLeadersList(allAreaLeadersList)
  }, [employeesList])

  React.useEffect(() => {
    setPotentialDelegations(
      employeesList?.filter(
        (person) =>
          !delegations.some((delegation) => delegation.eId === person.eId) &&
          person.eId !== selectedTLId &&
          [1, 2, 3, 0].includes(person.level) &&
          person.eLeaderId !== 0,
      ),
    )
  }, [delegations])

  async function getAllEmployees() {
    const result = await getAllEmployeeList()
    if (result?.employees) setEmployeesList(result.employees)
  }

  const fetchDelegations = async () => {
    try {
      setLoading(true)
      const response = await getHttpRequest(`/get_delegations_under/${selectedTLId}`)
      setLoading(false)
      setDelegations(response.employees ?? [])
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }

  const handleLeaderChange = (e) => {
    setSelectedTLID(e?.eId)
  }

  const handleDelegationChange = (e) => {
    setNewDelegateId(e.eId)
  }

  const deleteDelegation = async (eId) => {
    const result = await Swal.fire({
      title: t('Common:modalMessages.areYouSureDeleteThis'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })

    if (!result.isConfirmed) {
      return
    }
    setDelegations((prev) => prev.filter((delegation) => delegation.eId !== eId))
  }

  const addDelegation = () => {
    let newDelegate = potentialDelegations.find((person) => person.eId === newDelegateId)

    if (newDelegate) {
      setDelegations((prev) => [...prev, newDelegate])
    }
    setNewDelegateId(null)
  }

  const saveNewDelegation = async () => {
    if (!selectedTLId) {
      return
    }
    try {
      setIsSaving(true)
      await postHttpRequest(`/save_new_tl_connections/${selectedTLId}`, {
        newTlConnections: delegations.map((delegation) => delegation.eId),
      })
      setIsSaving(false)
      Toast.fire({
        icon: 'success',
        title: 'Successfully saved',
      })
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }

  return (
    <>
      <Modal isModalOpen={isModalOpen} closeModal={closeModal} width={'40rem'}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1rem',
            marginLeft: '1rem',
            width: '100%',
          }}
        >
          <div style={{ textAlign: 'left' }}>
            <span>
              <b>{t('reportingLineModal.selectTeamLeader')}:</b>
            </span>
          </div>
          <div style={{ display: 'flex' }}>
            <div>
              <SelectSingleField
                name="selectTeamLeader"
                value={
                  isFromAl
                    ? areaLeadersList.filter(
                        (person) => parseInt(person.eId) == parseInt(selectedTLId),
                      )
                    : teamLeadersList.filter(
                        (person) => parseInt(person.eId) == parseInt(selectedTLId),
                      )
                }
                options={isFromAl ? areaLeadersList : teamLeadersList}
                onChange={handleLeaderChange}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.eId}
                width={'80%'}
                menuShouldScrollIntoView={false}
                menuPosition="fixed"
              />
            </div>
          </div>
          <div style={{ textAlign: 'left' }}>
            <b>{t('reportingLineModal.hasRights')}</b>
          </div>
          {loading && <InPageLoader inComponent={true} />}
          {!loading && selectedTLId && (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', rowGap: '8px' }}>
              <>
                {delegations.map((delegation) => {
                  return (
                    <div style={{ display: 'flex', flexDirection: 'row' }} key={delegation.eId}>
                      <div>{delegation.name}</div>
                      <div
                        class="fitted-button red"
                        style={{ marginLeft: 'auto' }}
                        onClick={() => deleteDelegation(delegation.eId)}
                      >
                        <span class="material-symbols-outlined icon-size">close</span>
                      </div>
                    </div>
                  )
                })}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <SelectSingleField
                    name="selectDelegate"
                    value={potentialDelegations.filter(
                      (person) => parseInt(person.eId) == parseInt(newDelegateId),
                    )}
                    options={potentialDelegations}
                    onChange={handleDelegationChange}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.eId}
                    width={'80%'}
                    menuShouldScrollIntoView={false}
                    menuPosition="fixed"
                  />
                  <div
                    class="fitted-button blue"
                    style={{ marginLeft: 'auto' }}
                    onClick={() => {
                      addDelegation()
                    }}
                  >
                    <span class="material-symbols-outlined" style={{ fontSize: '20px' }}>
                      add
                    </span>
                  </div>
                </div>
              </>
            </div>
          )}
        </div>
        <div className="modal-dual-btn" style={{ marginTop: '5rem' }}>
          <button
            className="dual-btn negative-btn"
            onClick={() => {
              closeModal()
            }}
            type="button"
          >
            {t('commonButtons.cancel')}
          </button>
          <ModalSaveButton onClick={saveNewDelegation} isLoading={isSaving} />
        </div>
      </Modal>
    </>
  )
}

export default ReportingLineModal

import React, { useEffect, useRef } from 'react'
import './styles.css'
import { useTranslation } from 'react-i18next'
import Modal from '../Modal'
import {
  getAllEmployeeList,
  getTeamLeadersForAreaLeaders,
  saveOdinAgentConfig,
  getOdinAgentConfig,
} from '../../api/services/employee.services'
import Select from 'react-select'
import Toast from '../../common/toast'

const OdimEmailConfigModal = ({ isModalOpen, closeModal, selectedAreaLeader }) => {
  const areaLeaderId = selectedAreaLeader?.eId ?? '0000'
  const [empFlagValueMapping, setEmpFlagValueMapping] = React.useState(null)
  const [usersTouched, setUsersTouched] = React.useState([])

  const { t } = useTranslation(['Common'])

  const [tls, setTLUsers] = React.useState()
  const [allEmployees, setAllEmployees] = React.useState()
  const [error, setError] = React.useState(null)

  const handleTLCheckboxChange = (e, eId, flag) => {
    console.log('e', e)
    let tmpEmpFlagValueMapping = [...empFlagValueMapping]
    console.log('tmpEmpFlagValueMapping', tmpEmpFlagValueMapping)
    let newUsersTouched = [...usersTouched]
    //find the employee in the mapping
    let emp = tmpEmpFlagValueMapping.find((emp) => emp.eId === eId)
    if (emp) {
      if (flag === 'override') {
        emp[flag] = e.target.checked
      } else {
        emp[flag] = e.map((e) => e.eId)
      }
    }
    if (!newUsersTouched.includes(eId)) {
      newUsersTouched.push(eId)
    }
    console.log('newUsersTouched', newUsersTouched)
    console.log('tmpEmpFlagValueMapping', tmpEmpFlagValueMapping)

    setEmpFlagValueMapping(tmpEmpFlagValueMapping)
    setUsersTouched(newUsersTouched)
    setError(null)
  }

  const getTlsForAl = async () => {
    const result = await getTeamLeadersForAreaLeaders(areaLeaderId)
    console.log('result', result)
    setTLUsers(result?.employees)
    const tmpEmpFlagValueMapping = result?.employees.map((emp) => ({
      eId: emp.eId,
      override: false,
      escalate: [],
      cc: [],
    }))
    // setEmpFlagValueMapping(tmpEmpFlagValueMapping)
    const allEmployees = await getAllEmployeeList(false)
    setAllEmployees(allEmployees?.employees)

    let res = await getOdinAgentConfig(selectedAreaLeader.eId)
    for (let emp of res.employees) {
      let index = tmpEmpFlagValueMapping.findIndex((e) => e.eId === emp.eId)
      if (index !== -1 && emp.agentConfig) {
        tmpEmpFlagValueMapping[index].override = emp.agentConfig.isOverride
        tmpEmpFlagValueMapping[index].escalate = emp.agentConfig.escalationList
        tmpEmpFlagValueMapping[index].cc = emp.agentConfig.ccList
      }
    }
    setEmpFlagValueMapping(tmpEmpFlagValueMapping)
  }

  const saveData = async () => {
    let verified = verifyData()
    console.log('verified', verified)
    if (verified) {
      //save data
      let data = []
      for (let emp of empFlagValueMapping) {
        if (usersTouched.includes(emp.eId)) {
          data.push(emp)
        }
      }
      console.log('data', data)
      let response = await saveOdinAgentConfig(data)
      if (response?.success) {
        Toast.fire({
          icon: 'success',
          title: t('odinConfigModal.saveSuccess'),
        })
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Error saving data',
        })
      }
    }
  }

  const verifyData = () => {
    for (let emp of empFlagValueMapping) {
      if (emp.override && !(emp.escalate.length > 0 && emp.cc.length > 0)) {
        let error = {}
        if (emp.escalate.length === 0) {
          error.field = 'escalate'
        } else {
          error.field = 'cc'
        }
        error.eId = emp.eId
        console.log('error', error)
        setError(error)
        return false
      }
    }
    return true
  }

  const checkShowError = (eId, field) => {
    if (error && error.eId === eId && error.field === field) {
      return true
    }
    return false
  }

  useEffect(() => {
    getTlsForAl()
  }, [])

  return (
    <div className="tlconfig-modal">
      <Modal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        style={{ padding: '0rem 0rem 1rem 0rem' }}
        fixHorizontalScroll={true}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '2rem',
              width: '65%',
              // overflowX:'auto'
            }}
          >
            <div style={{ alignSelf: 'start', marginLeft: '3rem' }}>
              Team Leaders for
              <select className="accountable-select" defaultValue={areaLeaderId}>
                <option value={selectedAreaLeader?.eId} key={`${selectedAreaLeader?.eId}`}>
                  {selectedAreaLeader?.name}
                </option>
              </select>
            </div>
            {empFlagValueMapping && tls?.length > 0 && (
              <>
                <div style={{ width: '90vw', overflowX: 'scroll' }}>
                  <table style={{ marginBottom: '1rem', paddingTop: '1rem' }}>
                    <thead style={{ width: '100%' }}>
                      <tr>
                        <th style={{ width: '10rem' }}>Name</th>
                        <th key={'override'} style={{ width: '12rem' }}>
                          <span
                            className="tooltip"
                            style={{
                              display: 'inline-flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                          >
                            {t('odinConfigModal.overried')}
                          </span>
                        </th>
                        <th key={'escalate'} style={{ width: '20rem' }}>
                          <span
                            className="tooltip"
                            style={{
                              display: 'inline-flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                          >
                            {t('odinConfigModal.escalateTo')}
                          </span>
                        </th>
                        <th key={'copy'} style={{ width: '20rem' }}>
                          <span
                            className="tooltip"
                            style={{
                              display: 'inline-flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                          >
                            {t('odinConfigModal.copy')}
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ width: '100%' }}>
                      {tls?.map((user, userIndex) => (
                        <tr key={user.e_id}>
                          <td style={{ width: '10rem' }}>{user.name}</td>

                          <td key={'override'} style={{ width: '12rem' }}>
                            <div>
                              <input
                                id={`${user.e_id}-override`}
                                type="checkbox"
                                onChange={(e) => handleTLCheckboxChange(e, user.eId, 'override')}
                                checked={empFlagValueMapping[userIndex]?.override}
                              />
                            </div>
                          </td>
                          <td key={'escalate'} style={{ width: '20rem' }}>
                            <div>
                              <Select
                                options={allEmployees}
                                getOptionLabel={(option) => option.email}
                                getOptionValue={(option) => option.eId}
                                onChange={(e) => handleTLCheckboxChange(e, user.eId, 'escalate')}
                                value={allEmployees?.filter((emp) =>
                                  empFlagValueMapping[userIndex]?.escalate?.includes(emp.eId),
                                )}
                                isMulti
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    width: '20rem',
                                    height: '2rem',
                                    overflowX: 'auto',
                                    display: 'flex',
                                  }),
                                  option: (provided) => ({
                                    ...provided,
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                  }),
                                  menuPortal: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                  }),
                                }}
                                menuPortalTarget={document.body}
                              />
                            </div>
                            {checkShowError(user.eId, 'escalate') && (
                              <div style={{ fontSize: '0.8rem', color: 'red' }}>
                                {t('odinConfigModal.escalationError')}
                              </div>
                            )}
                          </td>
                          <td key={'cc'} style={{ width: '12rem' }}>
                            <div>
                              <Select
                                options={allEmployees}
                                getOptionLabel={(option) => option.email}
                                getOptionValue={(option) => option.eId}
                                onChange={(e) => handleTLCheckboxChange(e, user.eId, 'cc')}
                                isMulti
                                value={allEmployees?.filter((emp) =>
                                  empFlagValueMapping[userIndex]?.cc?.includes(emp.eId),
                                )}
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    width: '20rem',
                                    height: '2rem',
                                    overflowX: 'auto',
                                    display: 'flex',
                                  }),
                                  option: (provided) => ({
                                    ...provided,
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                  }),
                                  menuPortal: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                  }),
                                }}
                                menuPortalTarget={document.body}
                              />
                              {checkShowError(user.eId, 'cc') && (
                                <div style={{ fontSize: '0.8rem', color: 'red' }}>
                                  {t('odinConfigModal.ccError')}
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
        {
          <div
            style={{ marginTop: '1rem', display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            <div className="modal-dual-btn" style={{ marginTop: '0rem' }}>
              <button
                className="fitted-button blue"
                style={{
                  background: 'white',
                  padding: '0.4rem',
                  height: '35px',
                  fontWeight: 'bold',
                  marginRight: '1rem',
                }}
                onClick={() => {
                  closeModal()
                }}
                type="button"
              >
                {t('commonButtons.cancelSmall')}
              </button>

              <button
                type="button"
                className="fitted-button blue"
                style={{
                  background: 'white',
                  padding: '0.4rem',
                  height: '35px',
                  fontWeight: 'bold',
                }}
                onClick={saveData}
              >
                {t('commonButtons.save')}
              </button>
            </div>
          </div>
        }
      </Modal>
    </div>
  )
}

export default OdimEmailConfigModal

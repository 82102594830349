import useAllLeadersData from '../../../api/query/useAreaLeaderData'
import useCSuiteData from '../../../api/query/useCSuiteData'
import useCeoData from '../../../api/query/useCEOData'
import useTeamLeadersList from '../../../api/query/useTeamLeadersList'

export default function useGovernanceTabData() {
  const { data: csuiteData, isLoading: isCSuiteLoading, error: csuiteError } = useCSuiteData()

  const { data: ceoData, isLoading: isCeoLoading, error: ceoError } = useCeoData()

  const {
    data: addAreaLeaderData,
    isLoading: isAreaLeaderLoading,
    error: areaLeaderError,
  } = useAllLeadersData()

  const {
    data: teamLeaderData,
    isLoading: isTeamLeaderLoading,
    error: teamLeaderError,
  } = useTeamLeadersList()

  return {
    isLoading: isAreaLeaderLoading || isTeamLeaderLoading || isCSuiteLoading || isCeoLoading,
    error: areaLeaderError || teamLeaderError || csuiteError || ceoError,
    data: {
      allAreaLeadersList: addAreaLeaderData?.employees ?? [],
      allCsuiteLeadersList: csuiteData?.employees ?? [],
      allTeamLeadersList: teamLeaderData?.employees ?? [],
      teamLeadersList: [],
      csuiteLeadersList: [],
      ceoLeadersList: ceoData?.employees ?? [],
      areaLeadersList: [],
    },
  }
}

import React from 'react'
import useSubmitAreaLeaderWizard from '../../../../../../api/mutation/useSubmitAreaLeaderWizard'
import { useAreaLeaderContext } from '../../../../context/areaLeaderContext'
import '../../styles.css'
import WizardButton from '../../../../../../components/WizardButton'
import { redirectToHome } from '../../../../../../utils/auth'

const SubmitAreaLeaderWizard = () => {
  const { state } = useAreaLeaderContext()
  const mutationSubmitALWizard = useSubmitAreaLeaderWizard()

  const [submitClicked, setSubmitClicked] = React.useState(false)

  const handleSubmit = async () => {
    if (submitClicked === true) {
      return
    }
    let formData = {
      objectives: state.data.objectives,
    }

    await mutationSubmitALWizard.mutateAsync(formData, {
      onSuccess: () => {
        //TBD Swetha : Remove local-storage when not required
        // localStorage.removeItem('tlw-data')
        // localStorage.removeItem('tlw-step')
        localStorage.setItem('hrefReason_4E', '0x4E')
        localStorage.setItem('tfwert', '1')
        redirectToHome()
      },
      onError: () => {},
    })
  }

  return (
    <WizardButton
      isLoading={mutationSubmitALWizard.isLoading}
      onClick={() => {
        setSubmitClicked(true)
        handleSubmit()
      }}
    />
  )
}

export default SubmitAreaLeaderWizard

import next from '../../assets/next-white.svg'
import SubmitWizard from './components/index'
import './styles.scss'
import { useTranslation } from 'react-i18next'

const NextPreviousActions = ({
  hasIntro,
  isLastStep,
  currentStep,
  handleNext,
  handlePrevious,
  errorMessage,
  setErrorMessage,
  handleSubmit,
  isLoading,
}) => {
  const resetErrorMessage = () => {
    setErrorMessage(null)
  }
  const { t } = useTranslation(['Common'])
  return (
    <div className="buttons-area-container">
      <div className="error-container">
        {errorMessage ? <div className="validation-error">{errorMessage}</div> : null}
      </div>

      <div className="dual-button-container">
        {currentStep === 0 && hasIntro === true ? (
          <button onClick={() => handleNext()} className="start-button big-btn">
            {t('commonButtons.clickToBegin')}
          </button>
        ) : (
          <>
            <button
              className="dual-back-btn dual-btn"
              style={{ visibility: currentStep > 0 ? 'visible' : 'hidden' }}
              onClick={() => {
                handlePrevious()
                resetErrorMessage()
              }}
            >
              <img
                className="nav-arrow previous"
                src={next}
                alt="Arrow indication a navigation backwards."
              />
              <span>{t('commonButtons.back')}</span>
            </button>

            {isLastStep ? (
              <SubmitWizard
                currentStep={currentStep}
                setErrorMessage={setErrorMessage}
                handleSubmit={handleSubmit}
                isLoading={isLoading}
              />
            ) : (
              <button
                className="dual-next-btn dual-btn"
                onClick={() => {
                  handleNext()
                }}
              >
                <span>{t('commonButtons.next')}</span>
                <img
                  className="nav-arrow next"
                  src={next}
                  alt="Arrow indication a navigation forwards."
                />
              </button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default NextPreviousActions

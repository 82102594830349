import React from 'react'
import ApplicationLayout from '../../components/ApplicationLayout'
import SankeyPage from '../Sankey'
import { useLocation } from 'react-router-dom'

const SankeyPageOnly = () => {
  const [timeFilters, setTimeFilters] = React.useState({})

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const isAdmin = queryParams.get('isAdmin') === 'true'

  return (
    <ApplicationLayout>
      {isAdmin && (
        <>
          <div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            Topic Open Lowerbound <input type="date" id="datePicker1" /> <br />
            Topic Open Upperbound <input type="date" id="datePicker2" /> <br />
            Topic Close Lowerbound <input type="date" id="datePicker3" /> <br />
            Topic Close Upperbound <input type="date" id="datePicker4" /> <br />
            <button
              onClick={() => {
                let v1 = document.getElementById('datePicker1').value
                let v2 = document.getElementById('datePicker2').value
                let v3 = document.getElementById('datePicker3').value
                let v4 = document.getElementById('datePicker4').value

                let timeFilters_ = {
                  topic_open_ts_lb: v1 ? new Date(v1).getTime() / 1000 : null,
                  topic_open_ts_ub: v2 ? new Date(v2).getTime() / 1000 : null,
                  topic_close_ts_lb: v3 ? new Date(v3).getTime() / 1000 : null,
                  topic_close_ts_ub: v4 ? new Date(v4).getTime() / 1000 : null,
                }

                console.log('time filter =', timeFilters_)
                setTimeFilters(timeFilters_)
              }}
            >
              Apply time filter
            </button>
          </div>
        </>
      )}

      <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '4rem' }}>
        <SankeyPage timeFilters={timeFilters} />
      </div>
    </ApplicationLayout>
  )
}

export default SankeyPageOnly

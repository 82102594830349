import React, { useState } from 'react'
import { useCapacityContext } from '../../context/capacityContext'
import { Outlet } from 'react-router-dom'
import ProgressBar from '../../../../components/ProgressBar'
import NextPreviousActions from '../../../../components/NextPreviousActions'
import useCapacityWizardSteps from '../../hooks/useCapacityWizardSteps'
import useEditTeamLeaderAllocation from '../../../../api/mutation/editTeamLeaderAllocation'
import ErrorModal from '../../../../components/ErrorModal'
import { redirectToHome } from '../../../../utils/auth'

const CapacityWizard = ({ existingStep, isF2wTl, teamLeaderId = null }) => {
  const { state, dispatch } = useCapacityContext()
  const {
    currentStepIndex,
    steps,
    handleNext,
    handlePrevious,
    handleNextSkipOneStep,
    handlePreviousSkipOneStep,
    setCurrentStepIndex,
  } = useCapacityWizardSteps(existingStep, isF2wTl, teamLeaderId)

  const [errorMessage, setErrorMessage] = useState(null)

  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)

  const openErrorModal = () => {
    setIsErrorModalOpen(true)
  }

  const closeErrorModal = () => {
    setIsErrorModalOpen(false)
  }

  const mutationEditTeamLeaderAllocation = useEditTeamLeaderAllocation(teamLeaderId)

  const handleSubmit = async (activityOnly = null) => {
    //We don't want to submit if there are errors

    let hasErrorInCapacityWiz = false
    if (state.allocSubWiz.errorTeamCapAllocTable === true) {
      hasErrorInCapacityWiz = true
    }

    const hasTotalCapacityExceeding100Error = state?.data?.employees?.some((emp) => {
      return emp.currentAlloc + emp.sharedAlloc > 100
    })

    if (hasErrorInCapacityWiz || hasTotalCapacityExceeding100Error) {
      dispatch({ type: 'ALLOC_SUB_WIZ_SET_SELECT_ALL_EMP', value: true })
      setErrorMessage('Please address the errors above before continuing.')
      return
    }

    let employeesObjAlloc = []
    for (let obj of state.data.objectives) {
      if (!obj.team) {
        continue
      }

      for (let member of obj.team) {
        if (!isNaN(member.value)) {
          employeesObjAlloc.push({
            eId: member.eId,
            objId: parseInt(obj.id),
            allocPct: member.value,
          })
        }
      }
    }

    let responseObj = {
      isAddCoOwnersEnabled: true,
      objectives: [],
      employees: state.data.employees,
      employeesObjAlloc: employeesObjAlloc,
    }
    const objectives = state.data.objectives
    for (let i = 0; i < objectives.length; i++) {
      responseObj.objectives.push({ id: objectives[i].id })
      if (objectives[i].missingFte) {
        // if (objectives[i].missingFte > 0 && objectives[i].pendingTransferList.length <= 0 && getCompanyConfigSettings('productSettings')) {
        //   setErrorMessage('For objectives that you own, please select where the capacity should come from. You do not need to do this where you are a co-owner')
        //   return
        // }

        let pendingTransferList = !objectives[i].isExistingPendingTransferList
          ? objectives[i].pendingTransferList
          : []

        responseObj.objectives[i] = {
          ...responseObj.objectives[i],
          missingFte: objectives[i].missingFte,
          pendingTransferList: pendingTransferList,
          isCopy: objectives[i].isCopy,
        }
      }
      if (objectives[i].team) {
        let teamArray = []
        for (let p = 0; p < objectives[i].team.length; p++) {
          teamArray.push({
            eId: objectives[i].team[p].eId,
            isDirectReport: objectives[i].team[p].isDirectReport,
            value: objectives[i].team[p].value,
          })
        }
        responseObj.objectives[i] = { ...responseObj.objectives[i], team: teamArray }
      }
    }

    await mutationEditTeamLeaderAllocation.mutateAsync(responseObj, {
      onSuccess: () => {
        if (state && state?.locationState && state?.locationState?.isObjectivesTab) {
          window.location.href = '/Objectives'
        } else {
          redirectToHome()
        }
      },
      onError: () => {
        openErrorModal()
      },
    })
  }

  return (
    <>
      <ErrorModal isOpen={isErrorModalOpen} handleClose={closeErrorModal} errorMessage={null} />
      <ProgressBar currentStep={currentStepIndex} steps={steps} />
      <Outlet />
      <NextPreviousActions
        hasIntro={false}
        isLastStep={currentStepIndex === steps.length - 1}
        currentStep={currentStepIndex}
        handleNext={() => {
          // if ((isF2wTl === true && currentStepIndex === 2 )|| (isF2wTl === false && currentStepIndex === 1)) {//TODO: Commented until alloc logic is handled
          // if (state.allocSubWiz.errorTeamCapAllocTable === true) {
          //   dispatch({ type: "ALLOC_SUB_WIZ_SET_SELECT_ALL_EMP", value: true })
          //   //setErrorMessage("Please address the errors above before continuing.")
          //   return
          // }
          //   }
          setErrorMessage(null)
          handleNext()
        }}
        handlePrevious={() => {
          handlePrevious()
        }}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        handleSubmit={handleSubmit}
        isLoading={mutationEditTeamLeaderAllocation.isLoading}
      />
    </>
  )
}

export default CapacityWizard

import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const editObjectiveUnComplete = async (objId) => {
  const queryString = `/objectives/mark_as_uncomplete/${objId}`
  const { data } = await axios.post(`${BASE_URL}${queryString}`)

  return data
}

export default function useEditObjectiveUnComplete() {
  const client = useQueryClient()

  return useMutation((objId) => editObjectiveUnComplete(objId), {
    onSuccess: () => {
      void client.invalidateQueries('objectives')
    },
  })
}

import React from 'react'
import { CardContainer, StepHeader } from '../../../Update/styles'
import ResolveMeetingTopicModal from './ResolveMeetingTopicModal'
import EditMeetingTopicModal from './EditMeetingTopicModal'
import { useAreaLeaderContext } from '../../context/areaLeaderContext'
import { useTranslation } from 'react-i18next'
import ReviewGeneratedTopicsModal from '../../../../components/ReviewGeneratedTopicsModal'
import MultiAddUsingAudioModal from '../../../../components/MultiAddUsingAudioModal'

const MeetingTopicsStepAreaLeader = () => {
  const { t } = useTranslation(['Common'])
  const { state, dispatch } = useAreaLeaderContext()
  const [isEditMeetingTopicsModalOpen, setIsEditMeetingTopicsModalOpen] = React.useState(false)
  const [isResolveMeetingTopicsModalOpen, setIsResolveMeetingTopicsModalOpen] =
    React.useState(false)

  const [invalidateButtons, setInvalidateButtons] = React.useState(false)
  const [textInput, setTextInput] = React.useState(
    Array.from({ length: state.data.objectives.length }, (v, i) => ''),
  )
  const [selectedMeetingTopic, setSelectedMeetingTopic] = React.useState(null)

  const [isMultiAddTopicsUsingAudioModalOpen, setisMultiAddTopicsUsingAudioModalOpen] =
    React.useState(false)
  const [generatedTopics, setGeneratedTopics] = React.useState([])
  const [isReviewGeneratedTopicsModalOpen, setIsReviewGeneratedTopicsModalOpen] =
    React.useState(false)
  const [objId, setObjId] = React.useState(null)
  const [selectedTopics, setSelectedTopics] = React.useState([])

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
  }, [])

  React.useEffect(() => {
    if (generatedTopics && generatedTopics.length > 0) {
      setIsReviewGeneratedTopicsModalOpen(true)
    }
  }, [generatedTopics])

  React.useEffect(() => {
    if (selectedTopics.length > 0) saveMultiAddTopics()
  }, [selectedTopics])

  const textInputChangeHandler = (event, objIndex) => {
    let tempobjIndex = [...textInput]
    tempobjIndex[objIndex] = event.target.value
    setTextInput(tempobjIndex)
  }

  const setGeneratedTopicsWithIncludedTrue = (arg) => {
    let updatedTopics = [...arg]
    updatedTopics = updatedTopics.map((topic) => {
      topic.include = true
      return topic
    })
    setGeneratedTopics(updatedTopics)
  }

  const saveMultiAddTopics = () => {
    setInvalidateButtons(true)
    let lastMeetingTopicId = state.lastMeetingTopicLocaleId

    const multipleMeetingTopics = selectedTopics.map((topic) => {
      const topicObj = {
        description: topic.description,
        topicType: topic.topicType.toLowerCase(),
        localeId: lastMeetingTopicId + 1,
        localObjId: objId,
      }
      return topicObj
    })

    dispatch({ type: 'ADD_MULTIPLE_MEETING_TOPICS', meetingTopics: multipleMeetingTopics })
    setInvalidateButtons(false)
  }

  return (
    <>
      {isResolveMeetingTopicsModalOpen && selectedMeetingTopic && (
        <ResolveMeetingTopicModal
          isModalOpen={isResolveMeetingTopicsModalOpen}
          closeModal={() => {
            setIsResolveMeetingTopicsModalOpen(false)
          }}
          meetingTopic={selectedMeetingTopic}
        />
      )}
      {isEditMeetingTopicsModalOpen && selectedMeetingTopic && (
        <EditMeetingTopicModal
          isModalOpen={isEditMeetingTopicsModalOpen}
          closeModal={() => {
            setIsEditMeetingTopicsModalOpen(false)
          }}
          meetingTopic={selectedMeetingTopic}
        />
      )}

      {isMultiAddTopicsUsingAudioModalOpen && (
        <MultiAddUsingAudioModal
          isModalOpen={isMultiAddTopicsUsingAudioModalOpen}
          type="meetingTopic"
          setGeneratedText={setGeneratedTopicsWithIncludedTrue}
          closeModal={() => {
            setisMultiAddTopicsUsingAudioModalOpen(false)
          }}
        />
      )}

      {isReviewGeneratedTopicsModalOpen && (
        <ReviewGeneratedTopicsModal
          isModalOpen={isReviewGeneratedTopicsModalOpen}
          closeModal={() => {
            setIsReviewGeneratedTopicsModalOpen(false)
            // saveMultiAddTopics()
          }}
          handleTryAgain={() => {
            setGeneratedTopics(null)
            setIsReviewGeneratedTopicsModalOpen(false)
            setisMultiAddTopicsUsingAudioModalOpen(true)
          }}
          generatedTopics={generatedTopics}
          setSelectedTopics={setSelectedTopics}
        />
      )}

      <StepHeader>{t('meetingTopicWizStep.title')}</StepHeader>
      <CardContainer>
        <div style={{ display: 'flex', flexDirection: 'column', margin: '0 1rem' }}>
          <b>{t('meetingTopicWizStep.objectives')}</b>
        </div>
        {state.data.objectives.map((obj, objIndex) => (
          <div
            key={`obj-${obj.id}`}
            style={{ display: 'flex', flexDirection: 'row', columnGap: '3rem' }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '16rem',
                margin: '3rem 1rem',
              }}
            >
              <span>{obj.statement}</span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '1rem',
                  justifyItems: 'flex-end',
                }}
              >
                <div
                  class="fitted-button blue"
                  onClick={() => {
                    setObjId(obj.id)
                    setisMultiAddTopicsUsingAudioModalOpen(true)
                  }}
                  // style={{ display: 'flex', alignItems: 'center' }}
                  style={{ marginTop: '0.3rem' }}
                  id="voice-button"
                >
                  <span class="material-symbols-outlined icon-size">mic</span>
                </div>
              </div>
              <textarea
                data-testid={`multiSubActivityText-${objIndex}`}
                rows="5"
                id="multiSubActivityText"
                name="multiSubActivityText"
                value={textInput[objIndex]}
                onChange={(e) => {
                  textInputChangeHandler(e, objIndex)
                }}
                style={{
                  resize: 'none',
                  marginTop: '1rem',
                  border: 'none',
                  borderRadius: '10px',
                  padding: '0.5rem',
                  backgroundColor: 'rgb(242, 242, 242)',
                  fontSize: '14px',
                }}
              />
              <div
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
              >
                <div
                  style={{
                    width: 'fit-content',
                    height: '0.6rem',
                    fontSize: '12px',
                    alignSelf: 'flex-end',
                    marginBottom: '8px',
                    marginTop: '1rem',
                    backgroundColor: '#ED522E',
                  }}
                  className="add-btn"
                  data-testid={`blocker-${objIndex}`}
                  onClick={() => {
                    if (invalidateButtons === false && /\S/.test(textInput[objIndex])) {
                      setInvalidateButtons(true)
                      dispatch({
                        type: 'ADD_MEETING_TOPIC',
                        meetingTopic: {
                          description: textInput[objIndex],
                          topicType: 1,
                          localeId: state.lastMeetingTopicLocaleId + 1,
                          localObjId: obj.id,
                        },
                      })
                      let tempobjIndex = [...textInput]
                      tempobjIndex[objIndex] = ''
                      setTextInput(tempobjIndex)
                      setInvalidateButtons(false)
                    }
                  }}
                >
                  <span>{t('meetingTopicWizStep.blocker')}</span>
                </div>
                <div
                  data-testid={`guidance-${objIndex}`}
                  style={{
                    width: 'fit-content',
                    height: '0.6rem',
                    fontSize: '12px',
                    alignSelf: 'flex-end',
                    marginBottom: '8px',
                    marginTop: '1rem',
                  }}
                  className="add-btn"
                  onClick={() => {
                    if (invalidateButtons === false && /\S/.test(textInput[objIndex])) {
                      setInvalidateButtons(true)
                      dispatch({
                        type: 'ADD_MEETING_TOPIC',
                        meetingTopic: {
                          description: textInput[objIndex],
                          topicType: 2,
                          localeId: state.lastMeetingTopicLocaleId + 1,
                          localObjId: obj.id,
                        },
                      })
                      let tempobjIndex = [...textInput]
                      tempobjIndex[objIndex] = ''
                      setTextInput(tempobjIndex)
                      setInvalidateButtons(false)
                    }
                  }}
                >
                  <span>{t('meetingTopicWizStep.guidance')}</span>
                </div>
                <div
                  data-testid={`update-${objIndex}`}
                  style={{
                    width: 'fit-content',
                    height: '0.6rem',
                    fontSize: '12px',
                    alignSelf: 'flex-end',
                    marginBottom: '8px',
                    marginTop: '1rem',
                    backgroundColor: '#44546A',
                  }}
                  className="add-btn"
                  onClick={() => {
                    if (invalidateButtons === false && /\S/.test(textInput[objIndex])) {
                      setInvalidateButtons(true)
                      dispatch({
                        type: 'ADD_MEETING_TOPIC',
                        meetingTopic: {
                          description: textInput[objIndex],
                          topicType: 3,
                          localeId: state.lastMeetingTopicLocaleId + 1,
                          localObjId: obj.id,
                        },
                      })
                      let tempobjIndex = [...textInput]
                      tempobjIndex[objIndex] = ''
                      setTextInput(tempobjIndex)
                      setInvalidateButtons(false)
                    }
                  }}
                >
                  <span>{t('meetingTopicWizStep.update')}</span>
                </div>
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', width: '12rem' }}>
              <span>
                <b>{t('meetingTopicWizStep.blockerTitle')}</b>
              </span>
              <hr width="100%" color="#ED522E" style={{ height: '2px' }} />
              {/* <hr style={{ height: "2rem", borderWidth: "0", color: "red", backgroundColor: "gray" }}/ ></hr> */}

              {obj.blockers.map(
                (blk, blkIndex) =>
                  blk.isOpen !== false && (
                    <div
                      key={`obj-${blk.localObjId}`}
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <span>{blk.description}</span>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          marginTop: '1rem',
                          columnGap: '0.5rem',
                        }}
                      >
                        <div
                          data-testid={`editBlocker-${blk.mtId}-${blkIndex}`}
                          class="fitted-button blue spacing-bottom"
                        >
                          <span
                            class="material-symbols-outlined"
                            onClick={() => {
                              setSelectedMeetingTopic(blk)
                              setIsEditMeetingTopicsModalOpen(true)
                            }}
                          >
                            edit
                          </span>
                        </div>

                        <div
                          data-testid={`deleteBlocker-${blk.mtId}-${blkIndex}`}
                          class="fitted-button red spacing-bottom"
                        >
                          <span
                            class="material-symbols-outlined"
                            onClick={() => {
                              setSelectedMeetingTopic(blk)
                              dispatch({ type: 'DELETE_MEETING_TOPIC', meetingTopic: { ...blk } })
                              //setIsResolveMeetingTopicsModalOpen(true)
                            }}
                          >
                            close
                          </span>
                        </div>
                      </div>
                    </div>
                  ),
              )}
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', width: '12rem' }}>
              <span>
                <b>{t('meetingTopicWizStep.guidanceTitle')}</b>
              </span>
              <hr width="100%" color="#0926D5" style={{ height: '2px' }} />
              {obj.decisions.map(
                (dcs, dcsIndex) =>
                  dcs.isOpen !== false && (
                    <div
                      key={`obj-${dcs.localObjId}`}
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <span>{dcs.description}</span>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          marginTop: '1rem',
                          columnGap: '0.5rem',
                        }}
                      >
                        <div
                          data-testid={`editGuidance-${dcs.mtId}-${dcsIndex}`}
                          class="fitted-button blue spacing-bottom"
                        >
                          <span
                            class="material-symbols-outlined"
                            onClick={() => {
                              setSelectedMeetingTopic(dcs)
                              setIsEditMeetingTopicsModalOpen(true)
                            }}
                          >
                            edit
                          </span>
                        </div>
                        <div
                          data-testid={`deleteGuidance-${dcs.mtId}-${dcsIndex}`}
                          class="fitted-button red spacing-bottom"
                        >
                          <span
                            class="material-symbols-outlined"
                            onClick={() => {
                              setSelectedMeetingTopic(dcs)
                              //setIsResolveMeetingTopicsModalOpen(true)
                              dispatch({ type: 'DELETE_MEETING_TOPIC', meetingTopic: { ...dcs } })
                            }}
                          >
                            close
                          </span>
                        </div>
                      </div>
                    </div>
                  ),
              )}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '12rem' }}>
              <span>
                <b>{t('meetingTopicWizStep.updateTitle')}</b>
              </span>
              <hr width="100%" color="#44546A" style={{ height: '2px' }} />
              {obj.updates.map(
                (upd, updIndex) =>
                  upd.isOpen !== false && (
                    <div
                      key={`obj-${upd.localObjId}`}
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <span>{upd.description}</span>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          marginTop: '1rem',
                          columnGap: '0.5rem',
                        }}
                      >
                        <div
                          data-testid={`editUpdate-${upd.mtId}-${updIndex}`}
                          class="fitted-button blue spacing-bottom"
                        >
                          <span
                            class="material-symbols-outlined"
                            onClick={() => {
                              setSelectedMeetingTopic(upd)
                              setIsEditMeetingTopicsModalOpen(true)
                            }}
                          >
                            edit
                          </span>
                        </div>

                        <div
                          data-testid={`deleteUpdate-${upd.mtId}-${updIndex}`}
                          class="fitted-button red spacing-bottom"
                        >
                          <span
                            class="material-symbols-outlined"
                            onClick={() => {
                              setSelectedMeetingTopic(upd)
                              //setIsResolveMeetingTopicsModalOpen(true)
                              dispatch({ type: 'DELETE_MEETING_TOPIC', meetingTopic: { ...upd } })
                            }}
                          >
                            close
                          </span>
                        </div>
                      </div>
                    </div>
                  ),
              )}
            </div>
          </div>
        ))}
      </CardContainer>
    </>
  )
}

export default MeetingTopicsStepAreaLeader

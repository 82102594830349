import React from 'react'
import './styles.css'
import { useTranslation } from 'react-i18next'

const InfoModal = ({ isOpen, handleClose, stratDescData }) => {
  const { t } = useTranslation(['Common'])
  return (
    <div onClick={() => handleClose()} className={`info-modal ${isOpen ? '' : ' hidden'}`}>
      <div className="modal-tf">
        <table>
          <tr className="infoTableHeader">
            <td className="objCol"> {t('infoModal.objective')}</td>
            <td>{t('infoModal.what')}</td>
            <td>{t('infoModal.why')}</td>
            <td>{t('infoModal.how')}</td>
          </tr>
          {stratDescData?.map((d, index) => (
            <tr key={`d-${index}`}>
              <td className="objCol">{d.objective}</td>
              <td>{d.what}</td>
              <td>{d.why}</td>
              <td>{d.how}</td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  )
}

export default InfoModal

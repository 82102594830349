import React from 'react'
import areaLeaderReducer from './areaLeaderReducer'

export const DEFAULT_GOAL_DATA = {
  type: 2,
  level1Id: null,
  level: 2,
  blockers: [],
  decisions: [],
  updates: [],
}

export const defaultState = {
  lastEmpLocaleId: -1,
  lastObjLocaleId: -1,
  lastMeetingTopicLocaleId: -1,
  isFlowToWorkLeader: false,
  isEmployeeModalOpen: false,
  isObjHelpModalOpen: false,
  isObjFormModalOpen: false,
  isEnterpriseHelpModalOpen: false,
  isEnterpriseMeasureModalOpen: false,
  isCapacityConfirmModalOpen: false,
  isObjectivesFlowToWorkModalOpen: false,
  editObjectiveIndex: null,
  isOverCapacity: false,
  totalTeamAvailableFTEs: 0,
  totalObjRequiredFTEs: 0,
  addGoal: true,
  isMultipleObjModalOpen: false,
  data: {
    employees: [],
    objectives: [],
    linkageData: {},
    stratDescData: [],
    goalValidity: [],
    externalEmpAlocs: {},
    nonReportingEmpsRealIndexes: [],
  },
  allocSubWiz: {
    updateObjectiveId: null,
    errorTeamCapAllocTable: false,
    triggerSelectAllEmp: false,
  },
}

const AreaLeaderContext = React.createContext({
  state: defaultState,
  dispatch: () => {},
})

export const AreaLeaderProvider = ({ children, initialState }) => {
  const [state, dispatch] = React.useReducer(areaLeaderReducer, initialState)

  const context = {
    state,
    dispatch,
  }

  return <AreaLeaderContext.Provider value={context}>{children}</AreaLeaderContext.Provider>
}

const useAreaLeaderContext = () => {
  const context = React.useContext(AreaLeaderContext)
  if (!context) {
    throw new Error(
      'useTeamLeaderContext must be used within a AreaLeaderProvider. Wrap a parent component in <AreaLeaderProvider> to fix this error',
    )
  }

  return context
}

export { useAreaLeaderContext }

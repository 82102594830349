import React from 'react'
import ApplicationLayout from '../../components/ApplicationLayout'
import MeetingMinutesBody from './Component/MeetingMinutesBody'

const MeetingMinutes = () => {
  const hideNavigation = localStorage.getItem('tfex') === 'true'

  return (
    <ApplicationLayout fromSetUpAgent={hideNavigation}>
      <MeetingMinutesBody />
    </ApplicationLayout>
  )
}

export default MeetingMinutes

import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import Select from 'react-select'
import { API_SPREADHSHEET_SAVE_SUCCESS_MESSAGE, API_ERROR_MESSAGE } from '../../api/constant/route'
import Toast from '../../common/toast'

import 'react-datasheet-grid/dist/style.css'
import ApplicationLayout from '../../components/ApplicationLayout'
import useObjectiveDataList from '../../api/query/getListObjectiveData'
import InPageLoader from '../../components/InPageLoader/index'
import { KanbanTitle } from '../kanban/style'
import { activityStatus } from '../../utils/activityStatus'

import useGetListOfSepreadsheet, {
  check_spreadhsheet_updates,
} from '../../api/services/spreadsheet.srvices'
import useSaveSpreadsheetData from '../../api/mutation/useSaveSpreadsheetData'

// import Toast from '../../common/Toast';
import { getExportSepreadsheet, importSepreadsheet } from '../../api/services/spreadsheet.srvices'

import useGetAreaEmployees from '../../api/query/useGetAreaEmployees'

import fileDownload from 'js-file-download'

import MainSpreadSheet from './mainSpreadSheet'
import ObjectiveActivityChartsModal from '../../components/ObjectiveActivityChartsModal'

const Spreadsheet = () => {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const { data } = useObjectiveDataList()

  const [objectives, setObjectives] = useState([])

  const [statusFilterValue, setStatusFilterValue] = React.useState([])
  const [error, setError] = useState([])
  const [objectiveId, setObjectiveId] = useState('')
  const [title, setTitle] = useState('')
  const [showReload, setShowReload] = useState(false)

  const location = useLocation()

  const [filterObj, setFilterObj] = React.useState({
    dueDateFilter: [null, null],
    peopleFilter: [],
    progressFilter: [],
  })
  const [dueDateFilterValue, setDueDateFilterValue] = React.useState([null, null])

  const {
    data: result,
    isLoading: resultIsLoading,
    isFetching: resultIsFetching,
  } = useGetListOfSepreadsheet(objectiveId, filterObj)

  const queryParams = new URLSearchParams(location.search)
  const [loadingError, setLoadingError] = useState(false)

  const [mainResult, setMainResult] = useState(null)

  const [errorMessage, setErrorMessage] = useState(null)
  const [lastSavedTs, setLastSavedTs] = useState(null)
  const [isImport, setIsImport] = useState(false)
  const [intervalId, setIntervalId] = useState(null)
  const [isObjectiveActivityChartsModalOpen, setIsObjectiveActivityChartsModalOpen] =
    useState(false)

  const counter = useRef(0)
  const genId = () => counter.current++

  const mutationSaveSpreadsheetData = useSaveSpreadsheetData()

  const {
    data: areaEmployeesData,
    isLoading: isTeamLeaderLoading,
    error: teamLeaderError,
  } = useGetAreaEmployees()

  const [peopleFilterValue, setPeopleFilterValue] = React.useState([])
  const [milestonesPeopleList, setMilestonesPeopleList] = React.useState([])

  useEffect(() => {
    if (areaEmployeesData?.employees) {
      let tempAreaEmployeeData = [...(areaEmployeesData?.employees ?? [])]
      tempAreaEmployeeData.unshift({ name: 'Unassigned', eId: null })
      setMilestonesPeopleList(tempAreaEmployeeData)
    }
  }, [areaEmployeesData])

  const handleChangePeopleFilter = (value) => {
    setPeopleFilterValue(value)
  }

  useEffect(() => {
    setObjectives(data?.objectives ? data.objectives : [])
  }, [data?.objectives])

  useEffect(() => {
    getObjectiveTask()
  }, [filterObj])

  useEffect(() => {
    const objId = queryParams.get('objId')
    setObjectiveId(objId)
    setTitle(queryParams.get('title'))
    getObjectiveTask()
  }, [result])

  useEffect(() => {
    if (objectiveId != null) {
      checkForSpreadsheetUpdates()
    }
  }, [lastSavedTs, isImport])

  const checkForSpreadsheetUpdates = async () => {
    const objId = parseInt(queryParams.get('objId'))
    const checkUpdates = async () => {
      if (isImport && showReload) {
        clearInterval(intervalId)
        setShowReload(false)
        return
      }

      const updatesResult = await check_spreadhsheet_updates(objId)
      const savedTs = localStorage.getItem('tfst')

      if (updatesResult !== 'None' && savedTs !== null && Math.abs(updatesResult - savedTs) > 10) {
        setShowReload(true)
        clearInterval(intervalId)
        setIntervalId(null)
        return
      } else {
        setShowReload(false)
      }
    }

    const newIntervalId = setInterval(checkUpdates, 30000)
    setIntervalId(newIntervalId)
    setShowReload(false)
  }

  useEffect(() => {
    if (isImport) {
      clearInterval(intervalId)
      setShowReload(false)
    }
  }, [isImport])

  // Additional cleanup for the interval when the component is unmounted
  useEffect(() => {
    return () => {
      clearInterval(intervalId)
    }
  }, [intervalId])

  async function getObjectiveTask() {
    if (result) {
      setMainResult(result)
    }
  }

  const saveData = async (
    createdRowIds,
    updatedRowIds,
    deletedRowIds,
    idMapping,
    actdata,
    columnMetadata,
  ) => {
    let setChangesObj = {
      createdRowIds: Array.from(createdRowIds),
      updatedRowIds: Array.from(updatedRowIds),
      deletedRowIds: Array.from(deletedRowIds),
      activityData: actdata,
      idMapping: idMapping,
      columnMetadata: columnMetadata,
    }

    await mutationSaveSpreadsheetData.mutateAsync([parseInt(objectiveId), setChangesObj], {
      onSuccess: (response) => {
        Toast.fire({
          icon: 'success',
          title: API_SPREADHSHEET_SAVE_SUCCESS_MESSAGE,
        })

        //window.location.reload()
        localStorage.setItem('tfst', Date.now() / 1000)
        setLastSavedTs(Date.now() / 1000)
      },
      onError: (e) => {
        Toast.fire({
          icon: 'error',
          title: API_ERROR_MESSAGE,
        })
      },
    })
  }

  const handleChangeDueDateFilter = (event, type) => {
    let tempArrayDate = [...dueDateFilterValue]
    if (type === 'FROM') {
      tempArrayDate[0] = event.target.value
      if (tempArrayDate[1] == 'yyyy-mm-dd') {
        tempArrayDate[1] = null
      }
    } else if (type === 'TO') {
      tempArrayDate[1] = event.target.value
      if (tempArrayDate[0] == 'yyyy-mm-dd') {
        tempArrayDate[0] = null
      }
    }
    setDueDateFilterValue(tempArrayDate)
  }

  const applyFilters = () => {
    let filterObj = {
      dueDateFilter: dueDateFilterValue,
      peopleFilter: peopleFilterValue, // use to filter milestones only
      progressFilter: statusFilterValue,
    }
    setFilterObj(filterObj)
  }

  function onUploadButtonClick() {
    const inputElement = document.getElementById('myfile')
    inputElement.click()
    setIsImport(true)
  }

  async function onUploadCSV(e) {
    const files = e.target.files
    if (files.length !== 0) {
      var formData = new FormData()
      formData.append('file', files[0], files[0].name)
      setIsLoading(true)
      const result = await importSepreadsheet(objectiveId, formData)
      setIsLoading(false)
      if (result.success) {
        navigate(`/import-spreadsheets?importId=${result.importId}`)

        // setError(result.error.split(":"));
        // setSuccess(result.success.split(":"));
        // setIsCustomModel(true);

        // Toast.fire({
        //   icon: 'success',
        //   title: result.message,
        // })
      } else {
        // Toast.fire({
        //   icon: 'error',
        //   title: result.message,
        // })
        alert('something went wrong ')
      }
    }
  }

  async function onExportCSV() {
    setIsLoading(true)
    const postData = { file: true }
    const result = await getExportSepreadsheet(objectiveId, postData)
    setIsLoading(false)
    if (result) {
      fileDownload(result, `activities-${objectiveId}.xlsx`)
      // Toast.fire({
      //   icon: 'success',
      //   title: "File Download successfully!",
      // })
    } else {
      // Toast.fire({
      //   icon: 'error',
      //   title: result.message,
      // })
      alert('There was an error downloading the file.')
    }
  }

  const handleChangeStatusFilter = (value) => {
    setStatusFilterValue(value)
  }

  const resetFilters = () => {
    setDueDateFilterValue(['yyyy-mm-dd', 'yyyy-mm-dd'])
    setPeopleFilterValue([])
    setStatusFilterValue([])
    setFilterObj({ dueDateFilter: [null, null], peopleFilter: [], progressFilter: [] })
  }

  return (
    <>
      <ApplicationLayout />
      {isObjectiveActivityChartsModalOpen === true && (
        <ObjectiveActivityChartsModal
          isModalOpen={isObjectiveActivityChartsModalOpen}
          closeModal={() => {
            setIsObjectiveActivityChartsModalOpen(false)
          }}
          objective={{ id: objectiveId, statement: result?.objTitle }}
        />
      )}

      {loadingError && <span> There was an error loading the data. </span>}
      {!loadingError && (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginRight: '30px',
              marginLeft: '228px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                margin: '1rem 30px 2rem 0.5rem',
              }}
            >
              <KanbanTitle> Objective: {result?.objTitle}</KanbanTitle>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginLeft: '2rem',
                columnGap: '2rem',
                marginBottom: '1rem',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  // marginLeft: '2rem',
                  columnGap: '2rem',
                  // marginBottom: '1rem',
                  alignItems: 'flex-start',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                  <label className="label-custom">Assigned to activities:</label>
                  <div data-testid="multi-options-1" style={{ maxWidth: '16vw' }}>
                    <Select
                      key={`super-cool-private-key-76543245678`}
                      value={peopleFilterValue}
                      isMulti
                      onChange={handleChangePeopleFilter}
                      maxMenuHeight={100}
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.eId}
                      options={milestonesPeopleList}
                    />
                  </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                  <label className="label-custom">From due date:</label>
                  <input
                    type="date"
                    placeholder="yyyy-mm-dd"
                    id="dueDate"
                    name="dueDate"
                    autoComplete="off"
                    value={dueDateFilterValue[0]}
                    onChange={(e) => {
                      handleChangeDueDateFilter(e, 'FROM')
                    }}
                    style={{ height: '38px' }}
                  />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                  <label className="label-custom">To due date:</label>
                  <input
                    type="date"
                    id="dueDate"
                    name="dueDate"
                    autoComplete="off"
                    value={dueDateFilterValue[1]}
                    onChange={(e) => {
                      handleChangeDueDateFilter(e, 'TO')
                    }}
                    style={{ height: '38px' }}
                  />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                  <label for="label-custom">Status:</label>
                  <div data-testid="multi-options-1">
                    <Select
                      key={`super-cool-private-key-23463544`}
                      value={statusFilterValue}
                      isMulti
                      onChange={handleChangeStatusFilter}
                      maxMenuHeight={100}
                      getOptionLabel={(option) => option?.label}
                      getOptionValue={(option) => option?.value}
                      options={activityStatus}
                    />
                  </div>
                </div>

                <div
                  style={{
                    width: 'fit-content',
                    height: '1rem',
                    fontSize: '12px',
                    alignSelf: 'flex-end',
                    marginBottom: '8px',
                  }}
                  className="add-btn"
                  onClick={() => {
                    resetFilters()
                  }}
                >
                  <span>Reset Filter</span>
                </div>
                <div
                  style={{
                    width: 'fit-content',
                    // fontSize: 'initial',
                    height: '1rem',
                    fontSize: '12px',
                    alignSelf: 'flex-end',
                    marginBottom: '8px',
                  }}
                  className="add-btn"
                  onClick={() => {
                    applyFilters()
                  }}
                >
                  <span>Apply Filter</span>
                </div>
              </div>
              {objectiveId ? (
                <div className="spreadsheet-action-wrapper" style={{ marginTop: '1vw' }}>
                  <button
                    className="icon-button comment-icon-button"
                    onClick={() => {
                      setIsObjectiveActivityChartsModalOpen(true)
                    }}
                  >
                    <span className="material-symbols-outlined icon-size">insert_chart</span>
                  </button>

                  <div>
                    <button
                      onClick={onExportCSV}
                      className="move-btn-table import_export_btn btn_cmn"
                    >
                      Export
                    </button>
                  </div>

                  <div className="spreadsheet-action-wrapper">
                    <button
                      onClick={onUploadButtonClick}
                      className="move-btn-table import_export_btn btn_cmn"
                    >
                      Import
                    </button>

                    <input
                      style={{ display: 'none' }}
                      type="file"
                      id="myfile"
                      onChange={onUploadCSV}
                      name="myfile"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
            {errorMessage && <div className="validation-error">{errorMessage}</div>}

            {resultIsLoading || resultIsFetching || (isLoading && <InPageLoader />)}
            {!resultIsLoading && !resultIsFetching && !isLoading && mainResult && (
              <MainSpreadSheet
                mainResult={mainResult}
                saveData={saveData}
                showReload={showReload}
              />
            )}
          </div>
        </>
      )}
    </>
  )
}

export default Spreadsheet

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

import { getHttpRequest, postHttpRequest, getHttpRequestBinary } from '../query/dynamicAPI'
import {
  API_ERROR_MESSAGE,
  SEPREADSHEETS,
  MILESTONE_BY_FILE,
  IMPORTED_SPREADSHEET,
  SAVE_IMPORTED_SPREADSHEET,
  EXPORT_SPREADSHEET,
  CHECK_SPREADSHEET_UPDATES,
} from '../../api/constant/route'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getListOfSepreadsheet = async (objectiveId, filterObj) => {
  const queryString = `/spread_sheet/${objectiveId}`

  const { data } = await axios.post(`${BASE_URL}${queryString}`, {
    params: filterObj,
  })
  return data
}

export default function useGetListOfSepreadsheet(objectiveId, filterObj) {
  return useQuery(
    ['spreadsheet-data', objectiveId, filterObj],
    () => getListOfSepreadsheet(objectiveId, filterObj),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(objectiveId),
    },
  )
}

const getSingleActivity = async (objectiveId, activityId, backlogActivity = false) => {
  const queryString = `/activity_data`
  const { data } = await axios.get(`${BASE_URL}${queryString}`, {
    params: {
      objectiveId: objectiveId,
      activityId: activityId,
      backlogActivity: backlogActivity,
    },
  })
  return data
}

export function useGetSingleActivity(objectiveId, activityId = null, backlogActivity = false) {
  return useQuery(
    ['single_activity', objectiveId, activityId, backlogActivity],
    () => getSingleActivity(objectiveId, activityId, backlogActivity),
    {
      refetchOnWindowFocus: false,
      enabled: objectiveId !== null || objectiveId !== undefined,
    },
  )
}

export async function getExportSepreadsheet(objectiveId, postData) {
  try {
    const data = await getHttpRequestBinary(
      `${EXPORT_SPREADSHEET}/${objectiveId}?file=${postData.file}`,
    )
    return data
  } catch (e) {
    // Toast.fire({
    //     icon: 'error',
    //     title: API_ERROR_MESSAGE,
    // });
  }
  alert('Error exporting spreadsheet')
}

export async function importSepreadsheet(objectiveId, formData) {
  try {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    const data = await postHttpRequest(`${MILESTONE_BY_FILE}/${objectiveId}`, formData, config)
    return data
  } catch (e) {
    // Toast.fire({
    //     icon: 'error',
    //     title: API_ERROR_MESSAGE,
    // });
    alert('Error2The document is in the wrong format')
  }
}

export async function getNewImportedSpreadsheetData(objectiveId) {
  try {
    const data = await getHttpRequest(`${IMPORTED_SPREADSHEET}/${objectiveId}`)
    return data
  } catch (e) {
    // Toast.fire({
    //     icon: 'error',
    //     title: API_ERROR_MESSAGE,
    // });
    alert('Error importing spreadsheet')
  }
}

export async function check_spreadhsheet_updates(objectiveId) {
  try {
    const data = await getHttpRequest(`${CHECK_SPREADSHEET_UPDATES}/${objectiveId}`)
    return data
  } catch (e) {}
}

export async function getObjectivesSpreadSheet(
  tlId = null,
  objId = null,
  isShowCompleted,
  showFullList = false,
  showAll = false,
) {
  const postData = {
    file: true,
    isCompleted: isShowCompleted,
    tlId: tlId,
    showFullList: showFullList,
    objId: objId,
    showAll: showAll,
  }

  try {
    const data = await getHttpRequestBinary(`/export_obj_spread_sheet`, postData)
    return data
  } catch (e) {
    alert('Error exporting spreadsheet')
  }
}

import Modal from '../Modal'
import { useTranslation } from 'react-i18next'
import './style.css'
import CreatableSelect from 'react-select/creatable'
import { useEffect, useState } from 'react'
import { getHttpRequest, postHttpRequest } from '../../api/query/dynamicAPI'
import Toast from '../../common/toast'

const MilestoneLabelsManagementModal = ({ milestoneId, closeModal, refresh = () => {} }) => {
  const { t } = useTranslation(['Kanban'])
  const [isLoading, setIsLoading] = useState(false)

  const [milestoneLabelsList, setMilestoneLabelsList] = useState([])
  const [passedTags, setPassedTags] = useState([])

  const [selectedOption, setSelectedOption] = useState([])
  const [id, setId] = useState(0)

  useEffect(() => {
    getMilestoneLabels()
  }, [])

  const getMilestoneLabels = async () => {
    let result
    try {
      result = await getHttpRequest(
        `/get_milestone_labels_for_milestone?milestone_id=${milestoneId}`,
      )
      let existingtagsList = result.success.map((item) => ({
        id: item[0],
        name: item[1],
      }))
      setPassedTags(existingtagsList)
      setSelectedOption(result.success.map((item) => ({ value: item[0], label: item[1] })))
      let companyMilestoneLabels = await getHttpRequest(`/get_all_milestones_labels_for_company`)

      if (companyMilestoneLabels.success.length > 0) {
        const comLabels = companyMilestoneLabels.success.map((item) => ({
          id: item[0],
          name: item[1],
        }))
        setMilestoneLabelsList(comLabels)
      }
    } catch (err) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }

  const handleSelectChange = (newValue, actionMeta) => {
    if (!Array.isArray(newValue) || newValue.length <= 5) {
      setSelectedOption(newValue)
    }
  }

  const createOption = (label) => {
    setId(id - 1)
    return {
      id: id,
      name: label,
      milestoneId: id,
    }
  }

  const handleCreate = (inputValue) => {
    setIsLoading(true)
    setTimeout(() => {
      const newOption = createOption(inputValue)
      setIsLoading(false)
      setMilestoneLabelsList((milestonesLabelsList) => [...milestonesLabelsList, newOption])
      setSelectedOption((selectedOption) => [
        ...selectedOption,
        { value: newOption.id, label: newOption.name },
      ])
    }, 1000)
  }

  const handleChangeMilestoneLabels = async () => {
    try {
      let postData = {}
      //set deleted labels
      let delete_list = passedTags.filter(
        (item) => !selectedOption.some((option) => option.label === item.name),
      )
      delete_list.forEach((item) => {
        item['milestoneLabelId'] = item.id
        item['isDeleted'] = true
      })
      postData['milestoneLabels'] = delete_list
      postData['milestoneId'] = milestoneId
      //set added labels
      let notInPassedLabels = selectedOption.filter(
        (item) => !passedTags.some((option) => option.name === item.label),
      )
      let add_list = notInPassedLabels
        .filter((item) => !item.hasOwnProperty('isDeleted'))
        .map((item) => ({ labelMilestoneName: item.label }))
      postData['milestoneLabels'] = postData['milestoneLabels'].concat(add_list)
      let result = await postHttpRequest(`/save_milestone_labels`, postData)
      setTimeout(() => {
        refresh()
      }, 1000)
      closeModal()
    } catch (err) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }

  return (
    <div className="tags-management-modal">
      <Modal
        width={'50%'}
        // height={'20rem'}
        closeModal={closeModal}
      >
        <div className="modal-gray-box" style={{ width: '100%' }}>
          <div className="modal-inner-content">
            <div className="tutorial-management-modal-title" style={{ marginBottom: '10px' }}>
              {t('Kanban:manageLabels')}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '0.5rem',
                width: '18rem',
              }}
            >
              <label>{t('Kanban:labels')}</label>
              <CreatableSelect
                id="selectOption"
                value={selectedOption}
                onChange={handleSelectChange}
                options={milestoneLabelsList
                  .filter((item) => !selectedOption.some((option) => option.label === item.name))
                  .map(({ id, name }) => ({ value: id, label: name }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
                isLoading={isLoading}
                onCreateOption={handleCreate}
                isMulti
                isClearable
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 999999 }),
                }}
              />
              <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}></div>
            </div>

            <div
              className="sleek-button sleek-blue"
              onClick={handleChangeMilestoneLabels}
              style={{ marginLeft: 'auto' }}
            >
              <span>{t('Common:commonButtons.saveSmall')}</span>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default MilestoneLabelsManagementModal

import React from 'react'
import Modal from '../Modal'
import { Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ComposedChart } from 'recharts'

import { getHttpRequest } from '../../api/query/dynamicAPI'
import { useTranslation } from 'react-i18next'

const CountObjectivieChartsModal = ({ isModalOpen, closeModal, productSettings }) => {
  const { t } = useTranslation(['MeetingHub'])
  const [graphData, setGraphData] = React.useState(null)
  const [sortedFilterValue, setSortedValue] = React.useState('Owner')
  const [categoryFilterValue, setCategoryFilterValue] = React.useState('Status')
  const [isColor, setIsColor] = React.useState(false)
  const [remainingCategoriesOptions, setRemainingCategoriesOptions] = React.useState([
    { label: 'Status', value: 'Status' },
    { label: 'Color', value: 'Color' },
  ])

  let allDynamicKeysNames = []
  if (productSettings) {
    allDynamicKeysNames =
      graphData?.length > 0
        ? Array.from(new Set(graphData?.flatMap((item) => Object.keys(item))))
            .filter((key, keyIndex) => key !== 'label' && key !== 'count')
            .sort((a, b) => {
              if (a === 'Pre-PD0') return -1
              else if (b === 'Pre-PD0') return 1
              else return a < b ? -1 : a > b ? 1 : 0
            })
        : []
  } else {
    allDynamicKeysNames =
      graphData?.length > 0
        ? Array.from(new Set(graphData?.flatMap((item) => Object.keys(item)))).filter(
            (key, keyIndex) => key !== 'label',
          )
        : []
  }

  if (productSettings && graphData?.length > 0 && sortedFilterValue == 'Owner') {
    const removedZeroPersons = graphData.filter((person) => person.count === 0)
    if (removedZeroPersons && removedZeroPersons?.length > 0) {
      setGraphData(graphData.filter((person) => person.count !== 0))
    }
  }

  let colorArray = [
    '#4472c4',
    '#ed7d31',
    '#a5a5a5',
    '#ffc100',
    '#5b9bd5',
    '#71ad47',
    '#0563c1',
    '#954f72',
  ]
  let rgbArray = ['#008000', '#FF0000', '#FFBF00']
  const allOptions = [
    { label: 'Owner', value: 'Owner' },
    { label: 'Status', value: 'Status' },
    { label: 'Color', value: 'Color' },
  ]
  React.useEffect(() => {
    setGraphData(null)
    if (productSettings) {
      applyFilters()
    } else {
      getChartData({})
    }
  }, [])

  const applyFilters = () => {
    let filterObj = {
      sortedValue: sortedFilterValue,
      categoryValue: categoryFilterValue,
    }
    getChartData(filterObj)
    setIsColor(categoryFilterValue == 'Color')
  }

  const getChartData = async (filterObj) => {
    const data = await getHttpRequest('/get_count_objective_chart', {
      params: {
        filter: filterObj,
        isPublicChart: !productSettings,
      },
    })
    setGraphData(data)
  }

  const handleChangeChartFilterValue = (e) => {
    setSortedValue(e.target.value)
    let remainingCategories = allOptions.filter((op) => op.value !== e.target.value)
    setRemainingCategoriesOptions(remainingCategories)
  }

  const handleChangeCategoryFilterValue = (e) => {
    setCategoryFilterValue(e.target.value)
  }
  React.useEffect(() => {
    // in case user chooses category first then sortedvalue.
    if (remainingCategoriesOptions.find((op) => op.value === categoryFilterValue) === undefined) {
      setCategoryFilterValue(remainingCategoriesOptions[0].value)
      return
    }
  }, [remainingCategoriesOptions])

  const chartBars = (element, elementIndex) => {
    if (!productSettings) {
      return <Bar barSize={70} dataKey={element} yAxisId="right" fill="#F8BAAB"></Bar>
    }
    return (
      <Bar
        barSize={70}
        dataKey={element}
        stackId="a"
        yAxisId="right"
        fill={
          isColor
            ? rgbArray[((elementIndex % colorArray.length) + colorArray.length) % colorArray.length]
            : colorArray[
                ((elementIndex % colorArray.length) + colorArray.length) % colorArray.length
              ]
        }
      ></Bar>
    )
  }

  return (
    <>
      <Modal isModalOpen={isModalOpen} closeModal={closeModal} width={'70%'}>
        <>
          <h3>
            {productSettings
              ? t('Common:countObjectiveChart.initCount')
              : t('Common:countObjectiveChart.objCount')}
          </h3>
          {/* need to keep the first div to keep the width... */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '2rem',
              height: productSettings ? '10rem' : '0rem',
              width: '600px',
            }}
          >
            {productSettings && (
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '0.5rem',
                    minWidth: '7rem',
                    alignItems: 'flex-start',
                  }}
                >
                  <label for="sortfilter">
                    <b>{t('Common:countObjectiveChart.sortBy')}:</b>
                  </label>
                  <select
                    value={sortedFilterValue}
                    onChange={handleChangeChartFilterValue}
                    style={{ height: '38px' }}
                  >
                    {allOptions?.map((entry, entryIndex) => (
                      <option value={entry.value}>{entry.label}</option>
                    ))}
                  </select>
                </div>
                <div
                  style={{
                    marginLeft: '3rem',
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '0.5rem',
                    minWidth: '7rem',
                    alignItems: 'flex-start',
                  }}
                >
                  <label for="categoryfilter">
                    <b>{t('Common:countObjectiveChart.categoriedBy')}:</b>
                  </label>
                  <select
                    value={categoryFilterValue}
                    onChange={handleChangeCategoryFilterValue}
                    style={{ height: '38px' }}
                  >
                    {remainingCategoriesOptions?.map((op) => (
                      <option value={op.value}>{op.label}</option>
                    ))}
                  </select>
                </div>
                <div style={{ marginLeft: '3rem', display: 'flex', flexDirection: 'column' }}>
                  <div
                    style={{
                      width: 'fit-content',
                      // fontSize: 'initial',
                      height: '1rem',
                      fontSize: '12px',
                    }}
                    className="add-btn"
                    onClick={() => {
                      applyFilters()
                    }}
                  >
                    <span>{t('Common:countObjectiveChart.applyFilter')}</span>
                  </div>
                </div>
              </div>
            )}
          </div>

          {graphData && graphData.length > 0 ? (
            <>
              <ResponsiveContainer height={graphData.length * 130} width={'100%'}>
                <ComposedChart layout="vertical" data={graphData}>
                  <Legend
                    layout="horizontal"
                    verticalAlign="bottom"
                    align="left"
                    formatter={
                      productSettings
                        ? (value, entry, index) => (
                            <span style={{ color: 'black', fontSize: '0.8rem' }}>{value}</span>
                          )
                        : (value, entry, index) => (
                            <span style={{ color: 'black', fontSize: '0.8rem' }}>
                              Number of Objectives
                            </span>
                          )
                    }
                  />
                  <XAxis
                    hide
                    axisLine={false}
                    tickLine={false}
                    ticks={productSettings ? ['dataMin', 'dataMax'] : undefined}
                    tickFormatter={(tick) => `${tick}%`}
                    type="number"
                    domain={productSettings ? ['dataMin', 'dataMax'] : undefined}
                  />
                  <YAxis
                    type="category"
                    dataKey="label"
                    stroke="#44444"
                    width={60}
                    style={{
                      fontSize: '0.8rem',
                    }}
                  />
                  <YAxis
                    orientation="right"
                    yAxisId="right"
                    dataKey="count"
                    type="category"
                    axisLine={false}
                    tickLine={false}
                  />
                  <Tooltip />
                  {allDynamicKeysNames.map((element, elementIndex) => (
                    <>{chartBars(element, elementIndex)}</>
                  ))}
                </ComposedChart>
              </ResponsiveContainer>
            </>
          ) : (
            <span> {productSettings && t('Common:countObjectiveChart.noValue')} </span>
          )}
        </>
      </Modal>
    </>
  )
}

export default CountObjectivieChartsModal

import React from 'react'
import { useExcoExerciseContext } from '../context/ExcoExerciseContext'

export default function useExcoExerciseData() {
  const { state, dispatch } = useExcoExerciseContext()

  return {
    state,
  }
}

import { useTranslation } from 'react-i18next'

const ObjectiveExampleText = () => {
  const { t } = useTranslation(['Common'])
  return (
    <>
      <div style={{ width: '100%' }}>
        <div className="bullet-container">
          <div className="divider"></div>
          <b>{t('objectiveExampleText.line1')}</b>
          <div className="bullet-container">
            <ul style={{ paddingLeft: '1rem', margin: '0' }} className="diamond-yellow-list">
              <li style={{ margin: '0 0 1rem 0' }} className="diamond-yellow">
                <span>{t('objectiveExampleText.line2')}</span>
              </li>

              <li style={{ margin: '0 0 1rem 0' }} className="diamond-yellow">
                <span>{t('objectiveExampleText.line3')}</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="bullet-container">
          <b>{t('objectiveExampleText.line4')}</b>
          <ul style={{ paddingLeft: '1rem', margin: '0' }} className="diamond-yellow-list">
            <li style={{ margin: '0 0 1rem 0' }} className="diamond-yellow">
              {t('objectiveExampleText.line5.1')} <i>{t('objectiveExampleText.line5.2')}</i>
            </li>
            {/* <li style={{margin:"0 0 1rem 0"}} className="diamond-yellow">
            Hire <i>a design team</i>
          </li> */}
          </ul>
          <div className="divider"></div>
        </div>
      </div>
    </>
  )
}

export default ObjectiveExampleText

import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const setCommentsPinUnpin = async (commentData) => {
  const { data } = await axios.post(`${BASE_URL}/set_comments_pin_unpin`, commentData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export default function useSetCommentsPinUnpin() {
  const client = useQueryClient()

  return useMutation((data) => setCommentsPinUnpin(data), {
    onSuccess: () => {
      void client.invalidateQueries('comments')
    },
  })
}

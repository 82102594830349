import React from 'react'
import '../styles.css'
import { useGovernanceContext } from '../../../context/governanceContext'
import useTeamLeadersForAreaLeader from '../../../../../api/query/useTeamLeadersForAreaLeaderData'
import InPageLoader from '../../../../../components/InPageLoader'
import { AdminDropdownMenu } from '../../../../../components/SooDropdownMenu'
import { useTranslation } from 'react-i18next'
import { postHttpRequest } from '../../../../../api/query/dynamicAPI'
import Swal from 'sweetalert2'
import Toast from '../../../../../common/toast'

const TeamLeaders = ({ setRefetchData }) => {
  let { state, dispatch } = useGovernanceContext()
  const { t } = useTranslation(['Dashboard', 'Common'])
  const { data, isLoading, isFetching } = useTeamLeadersForAreaLeader(
    state.selectedAreaLeaderEId,
    state?.data?.allTeamLeadersList?.length ?? 0,
  )
  //Swetha TODO: This is a temporary hack. Changes need to be made so the state is updated automatically and not manually as below
  if (data !== undefined && !state.isTeamLeaderListUpdated) {
    state = {
      ...state,
      data: {
        ...state.data,
        teamLeadersList: data == null ? [] : [...data.employees.filter((e) => !e.isDeleted)],
      },
    }
  }

  return (
    <div className="table-wrapper" style={{ justifyContent: 'center', alignItems: 'center' }}>
      {isLoading && isFetching && (
        <div>
          <InPageLoader inComponent={true} />
        </div>
      )}
      {!(isLoading && isFetching) && (
        <div className="widget-grid-5" style={{ width: '100%', marginLeft: '5rem' }}>
          <div
            style={{
              margin: '0.5rem 0.5rem',
              textAlign: 'left',
              alignItems: 'normal',
              justifyContent: 'normal',
              width: '100%',
            }}
            className="widget-grid-title"
          >
            {t('adminDashboard.name')}
          </div>
          <div
            style={{
              margin: '0.5rem 0.5rem',
              textAlign: 'left',
              alignItems: 'normal',
              justifyContent: 'normal',
              width: '100%',
            }}
            className="widget-grid-title"
          >
            {t('adminDashboard.title')}
          </div>
          <div
            style={{
              margin: '0.5rem 0.5rem',
              textAlign: 'left',
              alignItems: 'normal',
              justifyContent: 'normal',
              width: '100%',
            }}
            className="widget-grid-title"
          >
            {t('adminDashboard.email')}
          </div>
          <div
            style={{
              margin: '0.5rem 0.5rem',
              textAlign: 'left',
              alignItems: 'normal',
              justifyContent: 'normal',
              width: '100%',
            }}
            className="widget-grid-title"
          >
            {t('adminDashboard.function')}
          </div>
          <div
            style={{ margin: '0.5rem 0.5rem', width: '8rem' }}
            className="widget-grid-title"
          ></div>
          {state?.data?.teamLeadersList?.map((teamLeader, index) => (
            <React.Fragment key={`TL-${teamLeader?.eId}-${teamLeader.email}`}>
              <div
                style={{
                  color: teamLeader?.isDeactivated ? '#BBBCBC' : 'black',
                  margin: '0.5rem 0.5rem',
                  textAlign: 'left',
                  alignItems: 'normal',
                  justifyContent: 'normal',
                  width: '90%',
                  wordBreak: 'break-word',
                  wordWrap: 'break-word',
                }}
                className="widget-grid-item"
              >
                {teamLeader?.name}
              </div>
              <div
                style={{
                  color: teamLeader?.isDeactivated ? '#BBBCBC' : 'black',
                  margin: '0.5rem 0.5rem',
                  textAlign: 'left',
                  alignItems: 'normal',
                  justifyContent: 'normal',
                  width: '90%',
                  wordBreak: 'break-word',
                  wordWrap: 'break-word',
                }}
                className="widget-grid-item"
              >
                {teamLeader?.title}
              </div>
              <div
                style={{
                  color: teamLeader?.isDeactivated ? '#BBBCBC' : 'black',
                  margin: '0.5rem 0.5rem',
                  textAlign: 'left',
                  alignItems: 'normal',
                  justifyContent: 'normal',
                  width: '90%',
                  wordBreak: 'break-word',
                  wordWrap: 'break-word',
                }}
                className="widget-grid-item"
              >
                {teamLeader?.email}
              </div>
              <div
                style={{
                  color: teamLeader?.isDeactivated ? '#BBBCBC' : 'black',
                  margin: '0.5rem 0.5rem',
                  textAlign: 'left',
                  alignItems: 'normal',
                  justifyContent: 'normal',
                  width: '90%',
                  wordBreak: 'break-word',
                  wordWrap: 'break-word',
                }}
                className="widget-grid-item"
              >
                {teamLeader?.function}
              </div>
              <div
                style={{
                  margin: '0.5rem 0.5rem',
                  textAlign: 'left',
                  alignItems: 'normal',
                  justifyContent: 'normal',
                }}
                className="widget-grid-item"
              >
                <AdminDropdownMenu
                  hasDeactivate={!teamLeader?.isDeactivated}
                  hasDelete={true}
                  onClickEdit={() => {
                    dispatch({
                      type: 'OPEN_TEAM_LEADER_MODAL',
                      editTeamLeaderIndex: index,
                      teamLeadersList: state.data.teamLeadersList,
                      editAreaLeaderIndex: null,
                      editCsuiteLeaderIndex: null,
                      editCeoLeaderIndex: null,
                      editTeamMemberIndex: null,
                    })
                  }}
                  onClickDelete={async () => {
                    const result = await Swal.fire({
                      title: t('Common:modalMessages.areYouSureDeleteThis'),
                      icon: 'question',
                      showCancelButton: true,
                      confirmButtonText: t('Common:commonButtons.yes'),
                      cancelButtonText: t('Common:commonButtons.no'),
                    })
                    if (result.isConfirmed) {
                      try {
                        let res = await postHttpRequest(`/delete_employee/${teamLeader.eId}`)
                        if (res.success) {
                          setRefetchData((prev) => !prev)
                          Toast.fire({
                            icon: 'success',
                            title: t('Common:modalMessages.deletedSuccessfully'),
                          })
                        } else {
                          Toast.fire({
                            icon: 'error',
                            title: t('Common:modalMessages.youCannotDeleteThis'),
                          })
                        }
                      } catch (error) {
                        Toast.fire({
                          icon: 'error',
                          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
                        })
                      }
                    }
                  }}
                  onClickChangeEmployeeState={async () => {
                    const result = await Swal.fire({
                      title: teamLeader?.isDeactivated
                        ? t('Common:modalMessages.areYouSureReactivate')
                        : t('Common:modalMessages.areYouSureDeactivate'),
                      icon: 'question',
                      showCancelButton: true,
                      confirmButtonText: t('Common:commonButtons.yes'),
                      cancelButtonText: t('Common:commonButtons.no'),
                    })
                    if (result.isConfirmed) {
                      try {
                        await postHttpRequest(`/edit_employee_state/${teamLeader.eId}`, {
                          isDeactivated: teamLeader.isDeactivated,
                        })
                        setRefetchData((prev) => !prev)
                        Toast.fire({
                          icon: 'success',
                          title: t('Common:modalMessages.updatedSuccessfully'),
                        })
                      } catch (error) {
                        Toast.fire({
                          icon: 'error',
                          title: t('Common:modalMessages.error'),
                        })
                      }
                    }
                  }}
                  leaderData={teamLeader}
                  reload={() => {
                    setRefetchData((prev) => !prev)
                  }}
                />
                {/* <div
                  class="fitted-button blue"
                  onClick={() => {
                    dispatch({
                      type: 'OPEN_TEAM_LEADER_MODAL',
                      editTeamLeaderIndex: index,
                      teamLeadersList: state.data.teamLeadersList,
                      editAreaLeaderIndex: null,
                      editCsuiteLeaderIndex: null,
                      editCeoLeaderIndex: null
                    })
                  }}
                >
                  <span class="material-symbols-outlined icon-size" >edit</span>
                  </div> */}
                {/* <div>
                  <img
                    className="delete-btn"
                    src={deleteImg}
                    alt="Click to Delete symbol"
                    onClick={() => {
                      dispatch({
                        type: 'DELETE_TEAM_LEADER',
                        editTeamLeaderIndex: index,
                        teamLeadersList: state.data.teamLeadersList,
                        isTeamLeaderListUpdated: true,
                      })
                    }}
                  />
                </div> */}
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
      {/* refresh => selectedAreaLeaderEId = 0 since default value = 0 in context; when select 'select AL' from the dropdown, selectedAreaLeaderEId = 0000*/}
    </div>
  )
}
export default TeamLeaders

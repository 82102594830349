import React from 'react'
import { Formik, Field, Form } from 'formik'
import Modal from '../../../../components/Modal'
import * as Yup from 'yup'
import useUwManageBlocker from '../../../../api/mutation/useUwManageBlocker'
import useUwManageDecision from '../../../../api/mutation/useUwManageDecision'
import useUwManageUpdate from '../../../../api/mutation/useUwManageUpdate'
import { useTranslation } from 'react-i18next'
import Textarea from 'rc-textarea'

const EditMeetingTopicModal = ({ isModalOpen, closeModal, meetingTopic }) => {
  const { t } = useTranslation(['Common'])

  const [isPriority, setIsPriority] = React.useState(meetingTopic?.isPriority)

  const mutationBlocker = useUwManageBlocker()
  const mutationDecision = useUwManageDecision()
  const mutationUpdate = useUwManageUpdate()
  const [charCount, setCharCount] = React.useState(meetingTopic?.description?.length ?? 0)

  const handleSubmit = async (values) => {
    let data = {}
    data.action = 'edit'

    let obj = {}
    obj.mtId = meetingTopic.mtId
    obj.description = values.description
    obj.isPriority = isPriority

    if (meetingTopic.topicType === 1) {
      data.blocker = obj
    } else if (meetingTopic.topicType === 2) {
      data.decision = obj
    } else if (meetingTopic.topicType === 3) {
      data.update = obj
    }

    if (meetingTopic.topicType === 1) {
      await mutationBlocker.mutateAsync(data, {
        onSuccess: () => {},
        onError: () => {},
      })
    } else if (meetingTopic.topicType === 2) {
      await mutationDecision.mutateAsync(data, {
        onSuccess: () => {},
        onError: () => {},
      })
    } else if (meetingTopic.topicType === 3) {
      await mutationUpdate.mutateAsync(data, {
        onSuccess: () => {},
        onError: () => {},
      })
    }
  }
  return (
    <>
      <Modal
        title={t('editMeetingTopicsModal.title')}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
      >
        <Formik
          enableReinitialize
          initialValues={{
            description: meetingTopic?.description ?? '',
          }}
          validationSchema={Yup.object().shape({
            description: Yup.string().required(t('required')),
          })}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values)
            closeModal(true)
            resetForm()
          }}
        >
          {({ errors, touched, resetForm, setFieldValue, values }) => (
            <Form className="objective-form">
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="form-field" style={{ width: '100%' }}>
                    <label>{t('editMeetingTopicsModal.description')}</label>
                    <Textarea
                      autoSize
                      className="form-field input"
                      type="text"
                      maxLength="300"
                      id="description"
                      name="description"
                      autoComplete="off"
                      style={{ width: '30rem', minHeight: '2rem', resize: 'none' }}
                      onChange={(e) => {
                        if (e.target.value.length <= 300) {
                          setCharCount([e.target.value.length])
                          setFieldValue('description', e.target.value)
                        }
                      }}
                      value={values.description}
                    />
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: 'auto' }}
                    >
                      <div
                        style={{
                          textAlign: 'right',
                          marginTop: '0.5rem',
                          fontSize: '12px',
                          color: charCount == 300 ? 'red' : 'gray',
                        }}
                      >
                        {charCount}/300
                      </div>
                    </div>
                    {errors.description && touched.description && (
                      <div className="validation-error">{errors.description}</div>
                    )}
                  </div>
                  {!isPriority && (
                    <button class="icon-button-no-border" style={{ margin: '1.5rem' }}>
                      <span
                        class="material-symbols-outlined icon-size"
                        onClick={() => {
                          setIsPriority(true)
                        }}
                      >
                        flag
                      </span>
                    </button>
                  )}
                  {isPriority && (
                    <button
                      class="icon-button-no-border"
                      style={{ display: 'block', margin: '1.5rem' }}
                    >
                      <span
                        class="material-symbols-outlined icon-size"
                        style={{ color: 'red' }}
                        onClick={() => {
                          setIsPriority(false)
                        }}
                      >
                        flag
                      </span>
                    </button>
                  )}
                </div>
              </div>
              <div
                className="modal-dual-btn"
                style={{
                  display: 'flex',

                  gap: '1rem',
                }}
              >
                <button
                  className="fitted-button modal-save-button blue"
                  onClick={() => {
                    resetForm()
                    closeModal()
                  }}
                  type="button"
                >
                  {t('commonButtons.cancel')}
                </button>
                <button className="fitted-button modal-save-button blue" type="submit">
                  {t('commonButtons.save')}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default EditMeetingTopicModal

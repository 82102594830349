import React from 'react'
import Modal from '../../../../../components/Modal'
import { useTranslation } from 'react-i18next'
import SelectField from '../../../../../components/CustomMultipleSelect/singleOption'

const UpdateMeetingTopicModal = ({
  closeModal,
  selectedItem,
  currentTopic,
  availableTopics = [],
  transferItemToTopic = () => {},
}) => {
  const { t } = useTranslation(['Common'])
  const [newSelectedTopic, setNewSelectedTopic] = React.useState(currentTopic)

  const updateTopic = (newSelectedTopic) => {
    setNewSelectedTopic(newSelectedTopic)
  }

  return (
    <Modal
      closeModal={closeModal}
      includeCloseIcon={true}
      width="60%"
      modalContainerStyle={{ width: '90%' }}
      showCard={true}
      title={t('topicSelectionModal.title')}
      titleStyle={{ color: 'rgb(91,132,203)', textAlign: 'left' }}
      modalTitleContainerStyle={{ justifyContent: 'flex-start' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '0.5rem',
          width: '100%',
        }}
      >
        <SelectField
          name="selectTopic"
          value={newSelectedTopic}
          options={availableTopics}
          onChange={updateTopic}
          getOptionLabel={(option) => option.umtDescription}
          getOptionValue={(option) => option.umtId}
          width={'45%'}
          menuPosition="fixed"
        />
        <div
          className="sleek-button sleek-blue"
          onClick={async () => {
            if (newSelectedTopic.umtId !== currentTopic.umtId) {
              transferItemToTopic(selectedItem, newSelectedTopic, currentTopic)
            }

            closeModal(true)
          }}
          style={{ marginLeft: 'auto' }}
        >
          <span>{t('Common:commonButtons.saveSmall')}</span>
        </div>
      </div>
    </Modal>
  )
}

export default UpdateMeetingTopicModal

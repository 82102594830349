import { React, Component } from 'react'
import '../../styles.css'
import DeleteButton from '../DeleteButton'
class TeamLeaderTable extends Component {
  render() {
    const { teamLeaderArray, areaLeaderEmail } = this.props
    if (teamLeaderArray.length > 0) {
      let filteredTeamLeaders = this.props.teamLeaderArray.filter(function (teamLeader) {
        return teamLeader.managerEmail === areaLeaderEmail
      })
      return (
        <div>
          <table>
            <tbody>
              <tr className="header-row">
                <th>
                  <span>Name</span>
                </th>
                <th>
                  <span>Title</span>
                </th>
                <th class="email-table-field">
                  <span>Email</span>
                </th>
              </tr>

              {filteredTeamLeaders?.map((teamLeader, index) => (
                <tr id={teamLeader.name} key={`employee-${index}`}>
                  <td>{teamLeader.name}</td>
                  <td>{teamLeader.title}</td>
                  <td class="email-table-field">{teamLeader.email}</td>
                  <td className="fragment-td">
                    <DeleteButton index={index} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    } else {
      return (
        <table>
          <tbody>
            <tr className="header-row">
              <th>
                <span>Name</span>
              </th>
              <th>
                <span>Title</span>
              </th>
              <th>
                <span>Email</span>
              </th>
            </tr>
          </tbody>
        </table>
      )
    }
  }
}
export default TeamLeaderTable

import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { myNotebookSubRoutes, routes } from '../../../utils/routes'
import { useTranslation } from 'react-i18next'

const MyNotebookHeaders = ({}) => {
  const { t } = useTranslation(['MyNotebook'])
  const location = useLocation()

  return (
    <div className="my-notebook-nav-container">
      <Link
        to={`/${routes.myNotebook}/${myNotebookSubRoutes.tasks}`}
        className={`nav-option ${
          location.pathname.endsWith(myNotebookSubRoutes.tasks) ? 'active' : ''
        }`}
      >
        <span className="nav-option-text">{t('tasks')}</span>
      </Link>
      <Link
        to={`/${routes.myNotebook}/${myNotebookSubRoutes.notes}`}
        className={`nav-option ${
          location.pathname.endsWith(myNotebookSubRoutes.notes) ? 'active' : ''
        }`}
      >
        <span className="nav-option-text">{t('notes')}</span>
      </Link>
    </div>
  )
}
export default MyNotebookHeaders

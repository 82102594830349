import { Field, Form, Formik } from 'formik'
import React from 'react'
import styled from 'styled-components'
import Button from '../../../../components/Button'
import { colors } from '../../../../utils/colors'
import { ButtonContainer } from '../../styles'
import * as Yup from 'yup'
import ModalSaveButton from '../../../../components/ModalSubmitButton'

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const validationSchema = Yup.object().shape({
  actionNeeded: Yup.string().required('Required'),
})

const BlockerForm = ({ initialValues, handleClose, handleSubmit }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        handleSubmit(values)
        resetForm()
        handleClose()
      }}
      validationSchema={validationSchema}
    >
      {({ errors, touched, resetForm }) => (
        <FormContainer>
          <Form>
            <div className="form-field">
              <label>Describe the blocker clearly in one sentence</label>
              <Field
                type="text"
                maxLength="200"
                id="actionNeeded"
                name="actionNeeded"
                autoComplete="off"
                style={{ width: '100%' }}
              />
              {errors.actionNeeded && touched.actionNeeded && (
                <div className="validation-error">{errors.actionNeeded}</div>
              )}
            </div>
            <div className="modal-dual-btn">
              <button
                className="dual-btn negative-btn"
                onClick={() => {
                  resetForm()
                  handleClose()
                }}
                type="button"
                color={colors.grey}
              >
                CLOSE
              </button>
              <ModalSaveButton />
            </div>
          </Form>
        </FormContainer>
      )}
    </Formik>
  )
}

export default BlockerForm

import React from 'react'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'

const MySelect = ({
  form,
  options,
  name,
  isMulti,
  dataTestId = null,
  isClearable = true,
  isDisabled = false,
  isAssignee = false,
}) => {
  const selectOptions = isAssignee
    ? options.filter?.((option) => option.is_deactivated === false)
    : options
  const { t } = useTranslation(['Common'])
  const handleChange = (selectedOption) => {
    if (isMulti === true) {
      // Multi-select
      const selectedValues = selectedOption.map((option) => option.value)
      form.setFieldValue(name, selectedValues)
    } else {
      // Single-select
      form.setFieldValue(name, selectedOption.value)
    }
  }

  const getValue = () => {
    let val = form.values[name]
    if (val) {
      if (isMulti === true) {
        // Multi-select
        return options.filter((option) => val.includes(option.value))
      } else {
        // Single-select
        return options?.find((option) => val === option.value)
      }
    }
  }

  return (
    <div style={{ textAlign: 'left', width: '100%' }} data-testid={dataTestId}>
      <Select
        options={selectOptions}
        isMulti={isMulti}
        value={getValue()}
        onChange={handleChange}
        isClearable={isClearable}
        isDisabled={isDisabled}
        placeholder={t('Common:filter.select') + '...'}
      />
    </div>
  )
}

export default MySelect

const VerbDataList = [
  'Analyze',
  'Assess',
  'Become',
  'Build',
  'Capture',
  'Conduct',
  'Create',
  'Define',
  'Deploy',
  'Design',
  'Develop',
  'Digitize',
  'Discover',
  'Drive',
  'Enable',
  'Enhance',
  'Evaluate',
  'Execute',
  'Forecast',
  'Handle',
  'Ideate',
  'Identify',
  'Increase',
  'Launch',
  'Maintain',
  'Monitor',
  'Negotiate',
  'Onboard',
  'Operate',
  'Pilot',
  'Plan',
  'Procure',
  'Provide',
  'Re-engineer',
  'Research',
  'Resolve',
  'Select',
  'Source',
  'Test',
  'Train',
  'Transform',
  'Troubleshoot',
  'Update',
  'Upgrade',
]

export default VerbDataList

import React from 'react'
import Modal from '../../../../components/Modal'
import { getHttpRequest, postHttpRequest } from '../../../../api/query/dynamicAPI'
import Toast from '../../../../common/toast'
import { useTranslation } from 'react-i18next'
import InPageLoader from '../../../../components/InPageLoader'
import useEditKanbanBoardChangeActivityStatus from '../../../../api/mutation/useEditKanbanBoardChangeActivityStatus'
import {
  meetingCommentUpdate,
  meetingMilestoneUpdate,
} from '../../../../api/services/meetingTopic.services'
import Swal from 'sweetalert2'
import { useUpdateMeetingLogs } from '../../../../api/services/meetingTopic.services'
import styled from 'styled-components'
import { MentionsInput, Mention } from 'react-mentions'
import { removeMentionMarkupFromText } from '../../../../utils/parseMarkup'
import useOdinEmailDetailData from '../../../../api/query/getOdinEmailDetail'
import useAddComment from '../../../../api/mutation/addCommentHome'

const Wrapper = styled.div`
  div > textarea {
    border-color: transparent !important;
  }
  width: 100%;
  font-family: 'Open Sans', 'Arial', 'Helvetica', 'system-ui', sans-serif;
`

const OdinLandingModal = ({ selectedTrackingId, isModalOpen, closeModal, userList = [] }) => {
  const { t } = useTranslation(['Common'])

  const [detailList, setDetailList] = React.useState([])
  const [data, setData] = React.useState(null)
  // const [isLoading, setIsLoading] = React.useState(false)
  const [reload, setReload] = React.useState(false)

  const topicType = ['Blocker', 'Guidance', 'Update', 'Admin']
  const topicColors = ['#ED522E', '#0926D5', 'rgb(127, 131, 155)', '#9933FF']
  const commentsType = ['COMMENT_ON_ACTION', 'COMMENT_ON_TOPIC']
  const changeActivityStatusMutation = useEditKanbanBoardChangeActivityStatus()
  const mutationUpdateMeetingLogs = useUpdateMeetingLogs()
  const mutationAddComment = useAddComment()

  const [mentionState, setMentionState] = React.useState([])

  // const textTypes = [
  //     "TOPIC_RESOLVED",
  //     "COMMENT_ON_TOPIC",
  //     "MILESTONE_ON_TOPIC",
  //     "COMMENT_ON_ACTION",
  //     "ACTION_COMPLETION",
  // ]

  const {
    data: response,
    isLoading: isDataLoading,
    error: dataError,
  } = useOdinEmailDetailData(selectedTrackingId, reload)

  React.useEffect(() => {
    if (response) {
      setData(response)
      setDetailList(response?.odinReplyEmailDetails ?? [])
    }
  }, [response])

  const buildWhatWeDidTitle = (types) => {
    // when there is a comment added to an action and that action is completed by Odin
    // we still use 'COMMENT_ON_ACTION' => ['COMMENT_ON_ACTION', 'ACTION_UPDATE']
    // or no comment for a pending actions during reply:  [ "NO_COMMENT_ON_PENDING_ACTION", "ACTION_UPDATE"]
    let text = '--'
    for (let type of types) {
      switch (type) {
        case 'TOPIC_RESOLVED':
          return t('odinInbox.resolvedTOpic')
        case 'COMMENT_ON_TOPIC':
          return t('odinInbox.addComment')
        case 'MILESTONE_ON_TOPIC':
          return t('odinInbox.addAction')
        case 'COMMENT_ON_ACTION':
          return t('odinInbox.updateAction')
        case 'NO_COMMENT_ON_PENDING_ACTION':
          return t('odinInbox.noComment')
        default:
          text = '--'
      }
    }
    return text
  }

  const handleCompleteClick = (objectiveId, milestoneId, index, isCurrentlyClosed) => async (e) => {
    if (isCurrentlyClosed) {
      await changeActivityStatus(objectiveId, milestoneId, 'ON_TRACK', index, true)
    } else {
      await changeActivityStatus(objectiveId, milestoneId, 'COMPLETED', index, false)
    }
  }

  const changeActivityStatus = async (objectiveId, milestoneId, newStatus, index, isOpen) => {
    const postObject = {
      objectiveId,
      milestoneId,
      newStatus,
      trackingId: selectedTrackingId,
    }

    await changeActivityStatusMutation.mutateAsync(postObject, {
      onSuccess: (response) => {
        let newDetailList = [...detailList]
        // obj is still referencing to old state, but it's ok in this case
        newDetailList[index].actionUpdatedByOdin.isCompleted = !isOpen
        setDetailList(newDetailList)
      },
      onError: () => {
        Toast.fire({
          icon: 'error',
          title: t('modalMessages.somethingWentWrongTryAgainLater'),
        })
      },
    })
  }

  async function deleteText(detail) {
    const result = await Swal.fire({
      title: t('Common:modalMessages.areYouSureRevertThis'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })

    if (result.isConfirmed && detail.types.includes('TOPIC_RESOLVED')) {
      const formData = {}
      formData.status = true

      formData.umtId = data?.umtId

      await mutationUpdateMeetingLogs.mutateAsync(formData, {
        onSuccess: () => {
          setReload((prev) => !prev)
        },
        onError: () => {
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
          })
        },
      })
      return
    }

    if (result.isConfirmed && detail.types.includes('MILESTONE_ON_TOPIC')) {
      const result = await meetingMilestoneUpdate(detail.id, {
        isDeleted: 'true',
        isDeletedByUserDuringReview: 'true',
        trackingId: selectedTrackingId,
      })
      if (result) {
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.deletedSuccessfully'),
        })
        setReload((prev) => !prev)
      } else {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
      return
    }

    if (result.isConfirmed && detail.types.some((t) => commentsType.includes(t))) {
      const result = await meetingCommentUpdate(detail.id, {
        isDeleted: 'true',
        isDeletedByUserDuringReview: 'true',
        trackingId: selectedTrackingId,
      })
      if (result) {
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.deletedSuccessfully'),
        })
        setReload((prev) => !prev)
      } else {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
    }
  }

  async function saveText(value, index) {
    const dataArray = [...detailList]
    const detail = dataArray[index]

    detail.odinSummaryText = value

    dataArray[index] = detail
    setDetailList(dataArray)
  }

  const updateText = async (detail) => {
    if (detail.odinSummaryText === '') {
      return
    }
    let result
    // anything related to comments
    if (detail.types.some((t) => commentsType.includes(t))) {
      let postData = {
        commentText: detail.odinSummaryText,
        umtId: data.umtId,
        isDecision: false,
      }
      let mentions =
        mentionState
          .find((mention) => mention.id === detail.id && mention.type === 'comment')
          ?.mentions.map((m) => m.id) ?? null
      if (mentions) {
        postData.mentions = mentions
      }
      result = await meetingCommentUpdate(detail.id, postData)
    } else if (detail.types.includes('MILESTONE_ON_TOPIC')) {
      let postData = {
        milestoneDesc: detail.odinSummaryText,
      }
      let mentions =
        mentionState
          .find((mention) => mention.id === detail.id && mention.type === 'action')
          ?.mentions.map((m) => m.id) ?? null
      // it means that user has triggered the onChange event of the textarea..
      // this is to prevent user click on the text then click outside of the textarea immediately
      // in which case mentions are not recorded yet
      if (mentions) {
        postData.mentions = mentions
      }
      result = await meetingMilestoneUpdate(detail.id, postData)
    } else if (detail.types.includes('NO_COMMENT_ON_PENDING_ACTION')) {
      // we are adding comments for an action without any comments during reply
      // this is a special case
      let objToSubmit = {
        comments: [
          {
            text: detail.odinSummaryText,
            objId: detail.actionUpdatedByOdin.goalId,
            activityId: detail.id,
            isAddedDuringReview: true,
            trackingId: selectedTrackingId,
          },
        ],
      }
      let mentions =
        mentionState
          .find((mention) => mention.id === detail.id && mention.type === 'comment')
          ?.mentions.map((m) => m.id) ?? null
      if (mentions) {
        objToSubmit.comments[0].mentions = mentions
      }

      await mutationAddComment.mutateAsync(objToSubmit, {
        onSuccess: (response) => {
          result = true
        },
      })
    }

    if (result) {
      Toast.fire({
        icon: 'success',
        title: t('Common:modalMessages.updatedSuccessfully'),
      })
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      width={'75%'}
      modalContainerStyle={{ width: '90%' }}
    >
      {isDataLoading && <InPageLoader inComponent={true} />}
      {data && !isDataLoading && (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', rowGap: '0.3rem' }}>
          <div style={{ display: 'flex', marginLeft: 'auto', color: 'lightgray' }}>
            {data.trackingId}
          </div>
          <div className="odin-email-inbox-list">
            <div
              className="odin-email-inbox-list-table-cell"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <div
                className="action-box"
                style={{ backgroundColor: topicColors[data.topicType - 1] }}
              >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {topicType[data.topicType - 1]}
                </div>
              </div>
              <div style={{ display: 'flex' }}>{data.topicDescription}</div>
            </div>
            <div className="odin-email-detail-row">
              <div className="odin-email-inbox-list-header">{t('odinInbox.whatdid')}</div>
              <div className="odin-email-inbox-list-header">{t('odinInbox.relatedTo')}</div>
              <div className="odin-email-inbox-list-header" style={{ marginLeft: '1.5%' }}>
                {t('odinInbox.summary')}
              </div>
              <div className="odin-email-inbox-list-header"></div>
              <div class="row-border" style={{ marginTop: '0.5rem' }}></div>
            </div>
            {detailList
              .sort((a, b) => a.sortOrder - b.sortOrder)
              .map((detail, index) => {
                if (detail.types.length > 1) {
                } else {
                }
                return (
                  <>
                    <div className="odin-email-detail-row" key={index}>
                      <div className="odin-email-detail-text">
                        {buildWhatWeDidTitle(detail.types)}
                      </div>
                      <div className="odin-email-detail-text">
                        {detail?.actionUpdatedByOdin
                          ? removeMentionMarkupFromText(detail?.actionUpdatedByOdin?.milestoneDesc)
                          : '--'}
                      </div>
                      <div className="odin-email-detail-text">
                        {/* only actions/commetns have odinSummaryText */}
                        {detail.id !== 0 ? (
                          <div className="odin-mention-input-box" style={{ marginLeft: '0.5rem' }}>
                            <Wrapper>
                              <MentionsInput
                                className="odin-mention-input-box-body_bottom_text"
                                value={detail.odinSummaryText}
                                onChange={(e, v, nv, mentions) => {
                                  let text_type = 'action'
                                  if (
                                    detail.types.some((t) => commentsType.includes(t)) ||
                                    detail.types.includes('NO_COMMENT_ON_PENDING_ACTION')
                                  ) {
                                    text_type = 'comment'
                                  }

                                  let mention = mentionState.find(
                                    (mention) =>
                                      mention.id === detail.id && mention.type === text_type,
                                  )
                                  if (mention) {
                                    setMentionState((prev) =>
                                      prev.map((item) =>
                                        item.id === detail.id && item.type === text_type
                                          ? { ...item, mentions: mentions }
                                          : item,
                                      ),
                                    )
                                  } else {
                                    setMentionState([
                                      ...mentionState,
                                      { id: detail.id, type: text_type, mentions: mentions },
                                    ])
                                  }
                                  saveText(e.target.value, index)
                                }}
                                onBlur={(e, clickedSuggestion) => {
                                  if (!clickedSuggestion) updateText(detail)
                                }}
                                allowSuggestionsAboveCursor={true}
                                style={{
                                  padding: '0',
                                  suggestions: {
                                    list: {
                                      maxHeight: 80,
                                      overflowY: 'auto',
                                    },
                                    item: {
                                      '&focused': {
                                        backgroundColor: '#d3d3d3',
                                      },
                                    },
                                  },
                                  highlighter: {
                                    border: '4px solid transparent',
                                  },
                                }}
                                spellCheck={false}
                              >
                                <Mention
                                  trigger="@"
                                  data={userList}
                                  // renderSuggestion={this.renderUserSuggestion}
                                  markup="@[__display__](user:__id__)"
                                  displayTransform={(id, display) => `@${display}`}
                                  renderSuggestion={(suggestion, search, highlightedDisplay) => (
                                    <div className="user">{highlightedDisplay}</div>
                                  )}
                                  appendSpaceOnAdd={true}
                                  // style={{backgroundColor:'rgb(206, 228, 229)'}}
                                />
                              </MentionsInput>
                            </Wrapper>
                          </div>
                        ) : (
                          <div style={{ marginLeft: '0.5rem' }}>--</div>
                        )}
                      </div>
                      <div className="odin-email-inbox-list-table-cell">
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          {(detail?.actionUpdatedByOdin?.milestoneId ?? 0) !== 0 && (
                            <div
                              className="fitted-button checklist"
                              onClick={(e) => {
                                handleCompleteClick(
                                  detail?.actionUpdatedByOdin?.goalId,
                                  detail?.actionUpdatedByOdin?.milestoneId,
                                  index,
                                  detail?.actionUpdatedByOdin?.isCompleted,
                                )(e)
                              }}
                              style={{ marginLeft: 'auto' }}
                            >
                              <span
                                className={`material-symbols-outlined ${
                                  detail?.actionUpdatedByOdin?.isCompleted
                                    ? 'green-check'
                                    : 'grey-check'
                                }`}
                                style={{ fontSize: '18px' }}
                              >
                                done
                              </span>
                            </div>
                          )}
                          {detail.sortOrder !== 5 && (
                            <div
                              class="fitted-button red icon-size"
                              onClick={(e) => deleteText(detail)}
                              style={{ marginLeft: 'auto' }}
                            >
                              <span
                                class="material-symbols-outlined icon-size"
                                style={{ fontSize: '18px' }}
                              >
                                close
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div class="row-border" style={{ marginTop: '0.5rem' }}></div>
                    </div>
                  </>
                )
              })}
          </div>
          <div style={{ textAlign: 'left' }}>{t('odinInbox.warning')}</div>
          <div
            className="sleek-button sleek-blue"
            style={{ cursor: 'pointer', marginLeft: 'auto', marginTop: '0.7rem' }}
            onClick={async () => {
              try {
                const confirmResult = await Swal.fire({
                  title: t('Common:modalMessages.areYouSureAcceptAll'),
                  icon: 'question',
                  showCancelButton: true,
                  confirmButtonText: t('Common:commonButtons.yes'),
                  cancelButtonText: t('Common:commonButtons.no'),
                })
                if (confirmResult.isConfirmed) {
                  let result = await postHttpRequest(`/accept_odin_email/${selectedTrackingId}`, {
                    isAccepted: true,
                  })
                  if (result.success) {
                    Toast.fire({
                      icon: 'success',
                      title: t('modalMessages.emailAccpetedSuccessfully'),
                    })
                    closeModal()
                  } else {
                    Toast.fire({
                      icon: 'error',
                      title: t('modalMessages.somethingWentWrongTryAgainLater'),
                    })
                  }
                }
              } catch (error) {
                Toast.fire({
                  icon: 'error',
                  title: t('modalMessages.somethingWentWrongTryAgainLater'),
                })
              }
            }}
          >
            {t('odinInbox.acceptAll')}
          </div>
        </div>
      )}
    </Modal>
  )
}

export default OdinLandingModal

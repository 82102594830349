import React from 'react'
import { useAreaLeaderContext } from '../../../../context/areaLeaderContext'
import ObjectiveForm from './ObjectiveForm'
import Modal from '../../../../../../components/Modal'
import { useTranslation } from 'react-i18next'

import './styles.css'

const AddEditObjectiveModal = () => {
  const { t } = useTranslation(['AreaLeaderWizard'])
  const { state, dispatch } = useAreaLeaderContext()

  const [resetForm, setResetForm] = React.useState(null)

  const closeModal = () => {
    dispatch({ type: 'CLOSE_OBJ_FORM_MODAL' })
    resetForm()
  }

  return (
    <>
      {state.isObjFormModalOpen === true && (
        <Modal
          title={t('addEditObjectiveTLWModal.addANewObjective')}
          closeModal={closeModal}
          width={'40rem'}
        >
          <div style={{ textAlign: 'left' }}>
            <span>{t('addEditObjectiveTLWModal.helpText')}</span>

            <ObjectiveForm setResetForm={setResetForm} />
          </div>
        </Modal>
      )}
    </>
  )
}

export default AddEditObjectiveModal

import React from 'react'
import { Formik, Field, Form } from 'formik'
import AddEmployeeSchema from './formSchema'

import { useSetupWizardContext } from '../../../../context/setupWizardContext'

const AddEmployeeForm = ({
  teamLeaderEmail,
  teamLeaderName,
  handleSubmit,
  handleCancel,
  setResetForm,
}) => {
  const { state } = useSetupWizardContext()

  const takenEmails = [
    ...state.data.areaLeader,
    ...state.data.teamLeader,
    ...state.data.individualContributor,
  ].map((leader) => leader.email)

  // will run after Yup ValidationSchema
  const validateEmail = (value) => {
    let error
    if (takenEmails.includes(value.toLowerCase())) {
      error = 'Email is already assigned.'
    }

    return error
  }

  const formikRef = React.useRef()

  React.useEffect(() => {
    if (formikRef.current !== undefined) {
      setResetForm(() => formikRef.current.resetForm)
    }
  }, [formikRef])

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        // NOTE: set initial values back to empty strings post demo (AM-RAVL)
        name: '',
        email: '',
        title: '',
        managerEmail: teamLeaderEmail,
        managerName: teamLeaderName,

        // NOTE: Review how these are retrieved/set, and set in context if same for all employees
      }}
      validationSchema={AddEmployeeSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, resetForm }) => (
        <Form className="form">
          <Field id="managerEmail" name="managerEmail" type="hidden" />
          <Field id="managerName" name="managerName" type="hidden" />

          <div className="form-field">
            <label htmlFor="name">Name</label>
            <Field id="name" name="name" />
            {errors.name && touched.name && <div className="validation-error">{errors.name}</div>}
          </div>
          <div className="form-field">
            <label htmlFor="title">Title</label>
            <Field id="title" name="title" />
            {errors.title && touched.title && (
              <div className="validation-error">{errors.title}</div>
            )}
          </div>
          <div className="form-field">
            <label htmlFor="email">Email</label>
            <Field id="email" name="email" validate={validateEmail} />
            {errors.email && touched.email && (
              <div className="validation-error">{errors.email}</div>
            )}
          </div>

          <div className="modal-dual-btn">
            <button
              className="dual-btn negative-btn"
              onClick={() => {
                resetForm()
                handleCancel()
              }}
              type="button"
            >
              CANCEL
            </button>
            <button className="dual-btn positive-btn" type="submit">
              SAVE
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddEmployeeForm

const demandIntakeReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_OBJ_FORM_MODAL':
      return {
        ...state,
        isObjFormModalOpen: true,
      }
    case 'CLOSE_OBJ_FORM_MODAL':
      return {
        ...state,
        isObjFormModalOpen: false,
      }
    case 'OPEN_CAPACITY_CONFIRM_MODAL':
      return {
        ...state,
        isCapacityConfirmModalOpen: true,
      }
    case 'CLOSE_CAPACITY_CONFIRM_MODAL':
      return {
        ...state,
        isCapacityConfirmModalOpen: false,
      }
    case 'SET_OBJ_ASSOCIATION':
      return {
        ...state,
        objectiveAssociation: action.objectiveAssociation,
      }
    case 'SET_LINKED_OBJECTIVE_ID':
      return {
        ...state,
        linkedObjectiveId: action.linkedObjectiveId,
      }
    case 'UPDATE_CAPACITY_CHECK':
      return {
        ...state,
        isOverCapacity: action.isOverCapacity,
      }
    case 'UPDATE_OBJECTIVES':
      return {
        ...state,
        data: {
          ...state.data,
          objectives: action.objectives,
        },
      }
    case 'UPDATE_EXTERNALEMPALOCS':
      return {
        ...state,
        data: {
          ...state.data,
          externalEmpAlocs: action.externalEmpAlocs,
        },
      }
    case 'UPDATE_DEMAND_INTAKE_ACTION':
      return {
        ...state,
        demandIntakeAction: action.demandIntakeAction,
      }
    case 'UPDATE_DEMAND_INTAKE_ACTION_OBJ':
      return {
        ...state,
        demandIntakeActionObj: action.demandIntakeActionObj,
      }
    case 'SET_NEW_REQUIRED_FTE':
      return {
        ...state,
        newRequiredFte: action.newRequiredFte,
      }
    case 'SET_NEW_OR_UPDATED_OBJ_ID':
      return {
        ...state,
        newOrEditedObjId: action.newOrEditedObjId,
      }
    case 'ALLOC_SUB_WIZ__OPEN_TEAMMEMBER_FORM_MODAL':
      return {
        ...state,
        isTeamMemberFormOpen: true,
        allocSubWiz: {
          ...state.allocSubWiz,
          updateObjectiveId: action.id,
        },
      }
    case 'ALLOC_SUB_WIZ__CLOSE_TEAMMEMBER_FORM_MODAL':
      return {
        ...state,
        isTeamMemberFormOpen: false,
        allocSubWiz: {
          ...state.allocSubWiz,
          updateObjectiveId: null,
        },
      }

    case 'ALLOC_SUB_WIZ__MOUNT_ALL_EMP':
      return {
        ...state,
        data: {
          ...state.data,
          employees: action.employees,
        },
      }

    case 'ALLOC_SUB_WIZ__ADD_MEMBERS_TO_OBJECTIVE': {
      return {
        ...state,
        data: {
          ...state.data,
          objectives: action.objectives,
        },
      }
    }
    case 'ALLOC_SUB_WIZ__SET_ALLOCATED_CAPACITY': {
      return {
        ...state,
        data: {
          ...state.data,
          objectives: action.objectives,
        },
      }
    }
    case 'ALLOC_SUB_WIZ__SET_ALLOCATED_CAPACITY_ERROR': {
      return {
        ...state,
        data: {
          ...state.data,
          objectives: action.objectives,
        },
      }
    }
    case 'ALLOC_SUB_WIZ__DISABLE_NEXT': {
      return {
        ...state,
        disableNext: true,
      }
    }
    case 'ALLOC_SUB_WIZ__ENABLE_NEXT': {
      return {
        ...state,
        disableNext: false,
      }
    }

    case 'ALLOC_SUB_WIZ__HAS_ERROR__TEAM_CAP_ALLOC_TABLE': {
      return {
        ...state,
        allocSubWiz: {
          ...state.allocSubWiz,
          errorTeamCapAllocTable: true,
        },
      }
    }

    case 'ALLOC_SUB_WIZ__NOT_HAS_ERROR__TEAM_CAP_ALLOC_TABLE': {
      return {
        ...state,
        allocSubWiz: {
          ...state.allocSubWiz,
          errorTeamCapAllocTable: false,
        },
      }
    }

    case 'ALLOC_SUB_WIZ__HAS_ERROR__ADDITIONAL_CAP_ALLOC_TABLE': {
      return {
        ...state,
        allocSubWiz: {
          ...state.allocSubWiz,
          errorAdditionalCapTable: true,
        },
      }
    }

    case 'ALLOC_SUB_WIZ__NOT_HAS_ERROR__ADDITIONAL_CAP_ALLOC_TABLE': {
      return {
        ...state,
        allocSubWiz: {
          ...state.allocSubWiz,
          errorAdditionalCapTable: false,
        },
      }
    }

    case 'ALLOC_SUB_WIZ_SET_SELECT_ALL_EMP': {
      return {
        ...state,
        allocSubWiz: {
          ...state.allocSubWiz,
          triggerSelectAllEmp: action.value,
        },
      }
    }
    case 'ALLOC_SUB_WIZ_OPEN_COOWNER_MODAL':
      return {
        ...state,
        isCoOwnerModalOpen: true,
        allocSubWiz: {
          ...state.allocSubWiz,
          updateObjectiveId: action.id,
        },
      }
    case 'ALLOC_SUB_WIZ_CLOSE_COOWNER_MODAL':
      return {
        ...state,
        isCoOwnerModalOpen: false,
        isNewPendingList: true,
        allocSubWiz: {
          ...state.allocSubWiz,
          updateObjectiveId: null,
        },
      }

    default:
      throw new Error(`Action: ${action.type} not implemented in Demand Intake reducer.`)
  }
}
export default demandIntakeReducer

import React from 'react'
import excoExerciseReducer from './ExcoExerciseReducer'

let localStorageState = localStorage.getItem('AIWorkshopExerciseState')
let tempState = localStorageState ? JSON.parse(localStorageState) : null
if (tempState === null) {
  tempState = {
    timerQuestion1: 0,
    gptVersionQuestion1: null,
    timerQuestion2: 0,
    gptVersionQuestion2: null,
    timerQuestion3: 0,
    gptVersionQuestion3: null,
    teamName: null,
    lastPageVisited: 0,
  }
}

export const defaultState = {
  ...tempState,
}

const ExcoExerciseContext = React.createContext({
  state: defaultState,
  dispatch: () => {},
})

export const ExcoExerciseProvider = ({ children, initialState }) => {
  const [state, dispatch] = React.useReducer(excoExerciseReducer, initialState)

  const context = {
    state,
    dispatch,
  }

  return <ExcoExerciseContext.Provider value={context}>{children}</ExcoExerciseContext.Provider>
}

const useExcoExerciseContext = () => {
  const context = React.useContext(ExcoExerciseContext)
  if (!context) {
    throw new Error(
      'useUpdateContext must be used within a ExcoExerciseProvider. Wrap a parent component in <ExcoExerciseProvider> to fix this error',
    )
  }

  return context
}

export { useExcoExerciseContext }

import React, { useState, useEffect, useRef } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Toast from '../../../common/toast'
import * as Yup from 'yup'
import { useGetSingleActivity } from '../../../api/services/spreadsheet.srvices'
import Modal from '../../../components/Modal'
import MySelect from '../../../components/MySelect'
import useSaveSpreadsheetData from '../../../api/mutation/useSaveSpreadsheetData'
import {
  API_SPREADHSHEET_SAVE_SUCCESS_MESSAGE,
  API_ERROR_MESSAGE,
} from '../../../api/constant/route'
import InPageLoader from '../../../components/InPageLoader'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import { ModalSaveButtonFitted } from '../../../components/ModalSubmitButton'
import { MentionsInput, Mention } from 'react-mentions'
import { getConsolidatedConfigSettings, hasRole } from '../../../utils/auth'
import { getObjectivUserList } from '../../../api/services/employee.services'
import { getHttpRequest, postHttpRequest } from '../../../api/query/dynamicAPI'
import useGetAllActiveCompanyObj from '../../../api/query/useGetAllActiveCompanyObj'
import { getCompanyConfigSettings } from '../../../utils/auth'
import SelectSingleField from '../../../components/CustomMultipleSelect/customSelect'
import { getAllEmployeeList } from '../../../api/services/employee.services'
import SelectField from '../../../components/CustomMultipleSelect/customSelect'
import Select from 'react-select'
// const FormikMentionsInput = ({
//   field,
//   form,
//   userList,
//   setMentionState,
//   activityId,
//   mentionState,
//   ...props
// }) => {
//   const handleChange = (event, newValue, newPlainTextValue, mentions) => {
//     let mention = mentionState.find((mention) => mention.id === activityId)
//     if (mention) {
//       setMentionState((prev) =>
//         prev.map((item) => (item.id === activityId ? { ...item, mentions: mentions } : item))
//       )
//     } else {
//       setMentionState([...mentionState, { id: activityId, mentions: mentions }])
//     }
//     // setMentionsList(mentions)
//     form.setFieldValue(field.name, newValue)
//   }
//   return (
//     // <Wrapper>
//     <MentionsInput
//       // className='body_bottom_box'
//       {...field}
//       {...props}
//       value={field.value}
//       onChange={handleChange}
//       allowSuggestionsAboveCursor={true}
//       style={{
//         maxWidth: '317px',
//         minWidth: '317px',
//         minHeight: '5.5rem',
//         border: '0.5px gray',
//         borderRadius: '10px',
//         backgroundColor: 'rgb(242, 242, 242)',
//         padding: '3px',
//         suggestions: {
//           list: {
//             maxHeight: 80,
//             overflowY: 'auto',
//           },
//           item: {
//             padding: '5px 15px',
//             borderBottom: '1px solid rgba(0,0,0,0.15)',
//             '&focused': {
//               backgroundColor: '#d3d3d3',
//             },
//           },
//         },
//         input: {
//           overflow: 'auto',
//         },
//         highlighter: {
//           boxSizing: 'border-box',
//           overflow: 'hidden',
//           height: 70,
//           border: '4px solid transparent',
//         },
//       }}
//       spellCheck={false}
//     >
//       <Mention
//         trigger="@"
//         data={userList}

//         markup="@[__display__](user:__id__)"
//         displayTransform={(id, display) => `@${display}`}
//         renderSuggestion={(suggestion, search, highlightedDisplay) => (
//           <div className="user">{highlightedDisplay}</div>
//         )}
//         appendSpaceOnAdd={true}
//       />
//     </MentionsInput>

//   )
// }

const AddEditActivityModal = ({
  isModalOpen,
  closeModal,
  activityId,
  selectedLeaderToLoadMH = null,
  isFromDashBoardAndIsRegularTl = false,
  selectedAction = null,
  actionText = null,
  loadAgain = () => {},
  brainstormSessionId = null,
}) => {
  const { t } = useTranslation(['Common', 'MyNotebook'])
  const [showL3Objectives, setShowL3Objectives] = useState(false)
  const isProductSettingsEnabled = getCompanyConfigSettings('productSettings')
  const mutationSaveSpreadsheetData = useSaveSpreadsheetData()
  const { data: objList } = useGetAllActiveCompanyObj({
    isFromDashBoardAndIsRegularTl,
    owner: undefined,
    selectedLeaderToLoadMH: selectedLeaderToLoadMH,
    showL3ObjsForAlAndJuniorTls: showL3Objectives,
  })
  const [employeesList, setEmployeesList] = useState([])
  const [objId, setObjId] = useState(0)

  const languageSettings = JSON.parse(localStorage.getItem('tfmc'))?.languageSettings

  useEffect(() => {
    getAllEmployees()
  }, [])

  async function getAllEmployees() {
    const result = await getAllEmployeeList()
    if (result?.employees) setEmployeesList(result.employees)
  }

  const submitForm = async (values, { setSubmitting }) => {
    console.log('values', values)

    try {
      let params = {
        objectiveId: values.objectiveId,
        milestoneDescription: values.actionDescription,
        milestoneAssignees: values.actionAssignees,
        brainstormSessionId: brainstormSessionId,
      }
      const data = await postHttpRequest('/add_edit_milestone', params)
      if (data.success) {
        Toast.fire({
          icon: 'success',
          title: API_SPREADHSHEET_SAVE_SUCCESS_MESSAGE,
        })
        loadAgain()
        closeModal()
      } else {
        Toast.fire({
          icon: 'error',
          title: API_ERROR_MESSAGE,
        })
      }
    } catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const getObjectiveLabel = (option) => {
    if (isProductSettingsEnabled && !option?.isGeneralObjective) {
      return `${option?.initiativeId?.toString()?.padStart(5, '0')} - ${!option?.isOriginal ? 'SHARED - ' : ''}${option.statement}`
    }
    return `${!option?.isOriginal ? 'SHARED - ' : ''}${option.statement}`
  }

  const validationSchema = Yup.object().shape({
    objectiveId: Yup.number().required('Required'),
    actionDescription: Yup.string().required('Required'),
    actionAssignees: Yup.array().min(1, 'At least one assignee is required').required('Required'),
  })

  return (
    <Modal
      title={
        activityId ? t('addEditActivityModal.editActivity') : t('addEditActivityModal.addActivity')
      }
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      width="80%"
    >
      <Formik
        initialValues={{
          objectiveId: objId,
          actionDescription: actionText || '',
          actionAssignees: [],
        }}
        validationSchema={validationSchema}
        onSubmit={submitForm}
      >
        {({ errors, touched, resetForm, setFieldValue, values }) => (
          <Form
            className="objective-form"
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="form-field action-field form-field action-field--kanban">
                <label>{t('MyNotebook:objective') + '*'}</label>
                <div style={{ width: '40rem' }}>
                  <Select
                    placeholder={t('formPlaceHolder.selectObjective')}
                    name="objectiveId"
                    value={objList?.objectives?.find((item) => item.id === values.objectiveId)}
                    options={objList?.objectives}
                    onChange={(selectedOption) => {
                      setFieldValue('objectiveId', selectedOption ? selectedOption.id : null)
                    }}
                    getOptionLabel={getObjectiveLabel}
                    getOptionValue={(option) => option.id}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: '100%',
                      }),
                    }}
                  />
                </div>
                <ErrorMessage name="objectiveId" component="div" className="validation-error" />
              </div>

              <div className="form-field action-field form-field action-field--kanban">
                <label>{t('MyNotebook:description') + '*'}</label>
                <Field
                  name="actionDescription"
                  as="textarea"
                  placeholder={languageSettings === 'en' ? 'Type here...' : 'Escribe aquí...'}
                  style={{ width: '39rem' }}
                />
                <ErrorMessage
                  name="actionDescription"
                  component="div"
                  className="validation-error"
                />
              </div>

              <div className="form-field action-field form-field action-field--kanban">
                <label>{t('MyNotebook:assignees') + '*'}</label>
                <Field name="actionAssignees">
                  {({ field, form }) => (
                    <div style={{ width: '40rem' }}>
                      <Select
                        {...field}
                        isMulti
                        options={employeesList.map((employee) => ({
                          value: employee.eId,
                          label: employee.name,
                        }))}
                        onChange={(selectedOptions) =>
                          form.setFieldValue('actionAssignees', selectedOptions)
                        }
                        onBlur={field.onBlur}
                        value={field.value}
                        placeholder={t('formPlaceHolder.selectAssignees')}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '100%',
                          }),
                        }}
                      />
                    </div>
                  )}
                </Field>
                <ErrorMessage name="actionAssignees" component="div" className="validation-error" />
              </div>
            </div>

            <div className="modal-dual-btn" style={{ columnGap: '4rem' }}>
              <div
                class="fitted-button blue"
                onClick={() => {
                  closeModal()
                  resetForm()
                }}
                type="button"
              >
                {t('commonButtons.cancel')}
              </div>

              <ModalSaveButtonFitted isLoading={mutationSaveSpreadsheetData.isLoading} />
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default AddEditActivityModal

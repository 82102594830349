import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const submitF2wTLWizCompletion = async () => {
  const { data } = await axios.post(
    `${BASE_URL}/f2w_no_obj`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )

  return data
}

export default function useSubmitF2wTLWizCompletion() {
  return useMutation((data) => submitF2wTLWizCompletion())
}

import './styles.css'
import AddImpactMeasureModal from './components/AddImpactMeasureModal'
import ImpactMeasuresIntro from './components/ImpactMeasuresIntro'
import ImpactMeasureTableContainer from './components/ImpactMesureTableContainer'

const ImpactMeasures = () => {
  return (
    <div className="impact-measures-component">
      <AddImpactMeasureModal />
      <ImpactMeasuresIntro />
      <ImpactMeasureTableContainer />
    </div>
  )
}

export default ImpactMeasures

import React from 'react'
import useSubmitSetupWizard from '../../../../../../api/mutation/useSubmitSetupWizard'
import { useSetupWizardContext } from '../../../../context/setupWizardContext'
import '../../styles.css'
import { useNavigate } from 'react-router-dom'
import ErrorModal from '../../../../../../components/ErrorModal'
import SetupSucessModal from '../SetupSuccessModal'
import WizardButton from '../../../../../../components/WizardButton'

const SubmitSetupWizard = ({ currentStep, setErrorMessage }) => {
  const { state, dispatch } = useSetupWizardContext()
  const mutationSubmitSetupWizard = useSubmitSetupWizard()
  const [isLoading, setIsLoading] = React.useState(false)
  const navigate = useNavigate()
  const [successModalOpen, setSuccessModalOpen] = React.useState(false)
  const [passwordResetLink, setPasswordResetLink] = React.useState(null)

  let submitDisabled = true

  const max10Condition = state.data.selectedMeasures.length <= 10
  const oneTopLevelCondition = state.data.selectedMeasures.find(
    (measure) => measure.topLine === true,
  )
  const oneBottomLevelCondtion = state.data.selectedMeasures.find(
    (measure) => measure.topLine === false,
  )

  if (max10Condition && oneTopLevelCondition && oneBottomLevelCondtion) {
    submitDisabled = false
  }

  const handleSubmit = async () => {
    if (submitDisabled) {
      return
    }

    if (currentStep === 6) {
      if (state.data.areaLevelObjectives.length === 0) {
        setErrorMessage('At least one valid objective is needed to continue')
        return
      } else if (state.data.areaLevelObjectives.length >= 1) {
        for (var i = 0; i < state.data.areaLevelObjectives.length; i++) {
          if (state.data.areaLevelObjectives[i].isValid === false) {
            // Temporarily bypass this because we run the Setup Wizard internally. The reason
            // this is causing a failure right now is that the check_objective_syntax stuff crashes
            // when there is no company config.
            //
            // setErrorMessage('All of the objectives need to be valid to continue')
            // return
          }
        }
      }
    }

    const modifiedObjectives =
      state?.data?.objectives?.map((obj, objIndex) =>
        obj.type ? 3 : parseInt(obj.level1Id) === 0 || parseInt(obj.level1Id) === null ? 2 : 1,
      ) ?? []

    state.data.objectives = modifiedObjectives

    setIsLoading(true)
    await mutationSubmitSetupWizard.mutateAsync(state.data, {
      onSuccess: (response) => {
        setIsLoading(false)
        setSuccessModalOpen(true)
        setPasswordResetLink(response.password_reset_link.ticket)
      },
      onError: () => {
        openErrorModal()
        setIsLoading(false)
      },
    })
  }

  const openErrorModal = () => {
    dispatch({ type: 'OPEN_ERROR_MODAL' })
  }

  const closeErrorModal = () => {
    dispatch({ type: 'CLOSE_ERROR_MODAL' })
  }

  return (
    <>
      {successModalOpen === true && (
        <SetupSucessModal passwordResetLink={passwordResetLink}></SetupSucessModal>
      )}

      <div className="submit-btn-container">
        <div className="submit-error-message-container hidden">
          <span>{!max10Condition && 'You can only select a maximum of 10 impact measures'}</span>
          <span>
            {!oneTopLevelCondition && 'You must select at least one top-level impact measure'}
          </span>
          <span>
            {!oneBottomLevelCondtion && 'You must select at least one bottom-level impact measure'}
          </span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {/* <button
            className={`final-submit-btn dual-next-btn dual-btn ${
              submitDisabled ? 'disabled-submit-btn' : ''
            }`}
            onClick={() => {
              handleSubmit()
            }}
          >
            {isLoading ? (
              <div style={{ width: '1rem', height: '1rem' }} className="loading-spinner"></div>
            ) : (
              <span>COMPLETE </span>
            )}
          </button> */}
          <WizardButton
            isLoading={mutationSubmitSetupWizard.isLoading}
            onClick={() => {
              handleSubmit()
            }}
          />
        </div>
      </div>
    </>
  )
}

export default SubmitSetupWizard

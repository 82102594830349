import { React, Component } from 'react'
import AddEmployeeModal from '../AddEmployeeModal'
import TeamLeaderTable from '../TeamLeaderTable'

import plus from '../../../../../../assets/plus.svg'
import next from '../../../../../../assets/next-white.svg'

import './styles.css'

class AreaLeaderCarousel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      nowIndex: 0,
      showAddEmployeeModal: false,
    }
  }

  render() {
    const { areaLeaderArray, teamLeaderArray } = this.props

    return (
      <div className="area-leader-carousel-component">
        <table>
          <tbody>
            <tr>
              <div
                onClick={() => {
                  if (this.state.nowIndex > 0) {
                    this.setState({
                      nowIndex: this.state.nowIndex - 1,
                    })
                  }
                }}
                className={`nextprev-button previous-area-leader-btn${
                  this.state.nowIndex === 0 ? 'disabled-carousel-btn hidden' : ''
                }`}
              >
                <span class="material-symbols-outlined">arrow_back_ios</span>
              </div>
              <div className="table-wrapper shadow">
                {areaLeaderArray.map((areaLeader, index) => {
                  if (this.state.nowIndex === index) {
                    return (
                      <div className="subtitle-teamleader" key={`arealeader-${index}`}>
                        <h4>
                          {index + 1} of {areaLeaderArray.length}
                        </h4>
                        <h3>
                          Who are the Team Leaders that report to Area Leader:{' '}
                          <span className="subtitle-arealeader">{areaLeader.name}? </span>
                        </h3>
                        <h3>
                          Area : <span className="subtitle-arealeader">{areaLeader.area}</span>
                        </h3>
                        <AddEmployeeModal
                          areaLeaderEmail={areaLeader.email}
                          areaLeaderName={areaLeader.name}
                          onClose={() => this.setState({ showAddEmployeeModal: false })}
                          show={this.state.showAddEmployeeModal}
                        />

                        <TeamLeaderTable
                          teamLeaderArray={teamLeaderArray}
                          areaLeaderEmail={areaLeader.email}
                        />
                      </div>
                    )
                  } else {
                    return []
                  }
                })}
                <button
                  onClick={() => this.setState({ showAddEmployeeModal: true })}
                  className="add-btn teamleader-btn"
                >
                  <img className="plus" src={plus} alt="Graphic of plus sign" />
                  ADD TEAM LEADER
                </button>
              </div>
              <div
                onClick={() => {
                  if (this.state.nowIndex < this.props.areaLeaderArray.length - 1) {
                    this.setState({
                      nowIndex: this.state.nowIndex + 1,
                    })
                  }
                }}
                className={`nextprev-button next-area-leader-btn${
                  this.state.nowIndex >= this.props.areaLeaderArray.length - 1
                    ? 'disabled-carousel-btn hidden'
                    : ''
                }`}
              >
                <span class="material-symbols-outlined">arrow_forward_ios</span>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}
export default AreaLeaderCarousel

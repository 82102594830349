import React, { useState, useEffect } from 'react'
import { useTeamLeaderContext } from '../../context/teamLeaderContext'
import { Outlet } from 'react-router-dom'
import ProgressBar from '../ProgressBar'

import NextPreviousActions from '../NextPreviousActions'
import useTeamLeaderWizardSteps, {
  stepsLabelsGenerator,
} from '../../hooks/useTeamLeaderWizardSteps'
import { useTranslation } from 'react-i18next'

import { POST_TL_WIZARD_STATE } from '../../../../api/constant/route'
import { postHttpRequest } from '../../../../api/query/dynamicAPI'

const TeamLeaderWizard = ({ existingStep }) => {
  const { t } = useTranslation(['TeamLeaderWizard'])
  const [errorMessage, setErrorMessage] = useState(null)
  const { state, dispatch } = useTeamLeaderContext()
  const { currentStepIndex, steps, handleNext, handlePrevious } =
    useTeamLeaderWizardSteps(existingStep)
  const [externalMemberAllocValid, setExternalMemberAllocValid] = useState(true)
  const [externaMemberAllocErrorArray, setExternaMemberAllocErrorArray] = useState(null)
  const stepsLabels = stepsLabelsGenerator()
  //TODO: Remove local storage
  // React.useEffect(() => {
  //   return () => {
  //     if (currentStepIndex > 0) {
  //       // localStorage.setItem('tlw-data', JSON.stringify(state.data))
  //       saveTLWizardState(state.data, currentStepIndex)
  //     }
  //   }
  // }, [currentStepIndex, state.data])

  return (
    <>
      <ProgressBar currentStep={currentStepIndex} steps={steps} />
      <Outlet
        context={{
          setErrorMessage,
          handlePrevious,
          setExternalMemberAllocValid,
          setExternaMemberAllocErrorArray,
        }}
      />
      <NextPreviousActions
        stepsLabels={stepsLabels}
        isLastStep={currentStepIndex === steps.length - 1}
        currentStep={currentStepIndex}
        handleNext={() => {
          if (currentStepIndex === 0 && state.isFlowToWorkLeader === true) {
            dispatch({ type: 'OPEN_OBJECTIVES_FLOWTOWORK_MODAL' })
            return
          } else if (currentStepIndex === stepsLabels.OBJECTIVES) {
            if (state.data.objectives.length === 0) {
              setErrorMessage(t('stepsErrorMsg.oneValidObj'))
              return
            } else if (state.data.objectives.length >= 1) {
              for (var i = 0; i < state.data.objectives.length; i++) {
                if (state.data.objectives[i].isValid === false) {
                  setErrorMessage(t('stepsErrorMsg.allObjNeedToBeValid'))
                  return
                }
              }
              setErrorMessage(null)
              handleNext()
            }
          } else if (currentStepIndex === stepsLabels.LINKAGE) {
            for (var i = 0; i < state.data.objectives.length; i++) {
              if (state.data.objectives[i].type === 3) {
                continue
              }
              if (
                state.data.objectives[i].level1Id === null ||
                state.data.objectives[i].level1Id === undefined ||
                state.data.objectives[i].level2Id === null ||
                state.data.objectives[i].level2Id === undefined
              ) {
                setErrorMessage(t('stepsErrorMsg.allFieldsNeedToBeCompleted'))
                return
              }
            }
            setErrorMessage(null)
            handleNext()
          } else if (currentStepIndex === stepsLabels.OBJECTIVE_TYPE) {
            const bauObjectives =
              state.data.objectives.filter((obj, objIndex) => obj.type === 3) ?? []
            if (bauObjectives.length === state.data.objectives.length) {
              handleNext()
              return
            }
            setErrorMessage(null)
            handleNext()
          } else if (currentStepIndex === stepsLabels.TEAM_CAPACITY_ALLOC) {
            if (state.allocSubWiz.errorTeamCapAllocTable === true) {
              dispatch({ type: 'ALLOC_SUB_WIZ_SET_SELECT_ALL_EMP', value: true })
              return
            }
            setErrorMessage(null)
            handleNext()
          } else {
            setExternaMemberAllocErrorArray(null)
            setErrorMessage(null)
            handleNext()
          }
        }}
        handlePrevious={() => {
          if (currentStepIndex === stepsLabels.OBJ_TEAM_MAPPING) {
            const bauObjectives =
              state.data.objectives.filter((obj, objIndex) => obj.type === 3) ?? []
            if (bauObjectives.length === state.data.objectives.length) {
              handlePrevious()
              return
            }
            setErrorMessage(null)
            handlePrevious()
          } else if (currentStepIndex === stepsLabels.MEETING_TOPICS) {
            const noDirectReportEmployees =
              state.data.employees.filter((emp) => emp.isDirectReport !== true) ?? []
            if (noDirectReportEmployees.length === 0) {
              handlePrevious()
              return
            }
          }

          handlePrevious()
        }}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        externaMemberAllocErrorArray={externaMemberAllocErrorArray}
        setExternaMemberAllocErrorArray={setExternaMemberAllocErrorArray}
      />
    </>
  )
}

export default TeamLeaderWizard

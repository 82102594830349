import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getTestUserLoginData = async () => {
  const { data } = await axios.get(`${BASE_URL}/test_users_list`)
  return data
}

export default function useTestUserLoginData(show) {
  return useQuery(['test_users_list'], () => getTestUserLoginData(), {
    refetchOnWindowFocus: false,
    enabled: show,
  })
}

import React, { useEffect, useState } from 'react'
import useEditCompanyConfig from '../../../../api/mutation/useEditCompanyConfig'
import useGetCompanyConfig from '../../../../api/query/useGetCompanyConfig'
import InPageLoader from '../../../../components/InPageLoader'
import Toast from '../../../../common/toast'
import { companyConfigEnums } from '../../../../utils/enumValues'

const CompanyConfigOptions = () => {
  const { data: configData, isLoading: isConfigDataLoading } = useGetCompanyConfig()
  const [checkboxes, setCheckboxes] = useState({})

  useEffect(() => {
    if (configData) {
      const values = {}
      const keys = Object.keys(configData)

      for (let key of keys) {
        if (key !== 'languageSettings') {
          values[key] = configData[key]
        } else if (key === 'languageSettings') {
          values['setLanguageToES'] = configData[key] === 'es'
        }
      }

      setCheckboxes(values)
    }
  }, [configData])

  const handleCheckboxChange = (field) => {
    setCheckboxes((prev) => ({
      ...prev,
      [field]: !prev[field],
    }))
  }

  const editUserConfigsMutation = useEditCompanyConfig()

  const saveData = async (data) => {
    await editUserConfigsMutation.mutateAsync(data, {
      onSuccess: () => {
        Toast.fire({
          icon: 'success',
          title: 'Data saved successfully',
        })
      },
      onError: () => {
        Toast.fire({
          icon: 'error',
          title: 'Something went wrong',
        })
      },
    })
  }

  return (
    <div className="tlconfig-modal">
      <div>
        {isConfigDataLoading && (
          <div>
            <InPageLoader inComponent={true} />
          </div>
        )}
        {!isConfigDataLoading && configData && (
          <>
            <table>
              <thead>
                <tr>
                  <th style={{ width: '20rem' }}>Setting</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(companyConfigEnums).map((field) => (
                  <tr key={field}>
                    <td style={{ width: '20rem' }}>{companyConfigEnums[field]}</td>
                    <td>
                      <input
                        id={field}
                        type="checkbox"
                        checked={checkboxes[field]}
                        onChange={() => handleCheckboxChange(field)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <button
                type="button"
                className="fitted-button blue"
                style={{
                  background: 'white',
                  padding: '0.4rem',
                  height: '35px',
                  fontWeight: 'bold',
                }}
                onClick={() => saveData(checkboxes)}
              >
                Save Data
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default CompanyConfigOptions

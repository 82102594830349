import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const generateMultipleSubActivities = async (request) => {
  const { data } = await axios.post(
    `${BASE_URL}/generate_multiple_subactivities_using_text`,
    request,
    { timeout: 15000 },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )

  return data
}

export default function useGenerateMultipleSubActivities() {
  return useMutation(
    ['generate-subactivities'],
    (request) => generateMultipleSubActivities(request),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      onSuccess: (response) => {
        return response
      },
      onError: (response) => {
        return response
      },
    },
  )
}

import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const createUserAddedList = async (list) => {
  const { data } = await axios.post(`${BASE_URL}/create_user_defined_list`, list, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export default function useCreateUserAddedList() {
  const queryClient = useQueryClient()

  return useMutation((list) => createUserAddedList(list), {
    onSuccess: () => {
      void queryClient.invalidateQueries('custom-template-lists')
    },
  })
}

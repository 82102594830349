import React from 'react'
import { useBrainstorm } from '../Recorder/BrainstormContext'
import { useTranslation } from 'react-i18next'

const RefineProblemButton = ({ setIsThinking }) => {
  const { state, generateClarifications, generateProblems } = useBrainstorm()
  const { t } = useTranslation(['Common'])

  return (
    <div
      onClick={async () => {
        setIsThinking(true)
        if (state.buttonTextState === 0) {
          await generateClarifications()
        } else {
          await generateProblems(state.meetingInstanceId)
        }
        // setIsThinking(false)
      }}
      className={`sleek-button play-circle`}
      style={{
        display: 'flex',
        width: '6rem',
        fontSize: '1rem',
        height: '2rem',
        //height: '1.5rem',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        color: 'rgb(255, 255, 255)',
        backgroundColor: 'rgb(68, 114, 196)',
        border: '1px solid rgb(255, 255, 255)',
      }}
    >
      <div
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}
      >
        <span style={{ textAlign: 'center' }}>
          {state.buttonTextState === 0
            ? t('Common:brainstorming.refineText')
            : t('Common:brainstorming.draftAgainText')}
        </span>
      </div>
    </div>
  )
}

export default RefineProblemButton

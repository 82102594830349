import React from 'react'
import { useSetupWizardContext } from '../../context/setupWizardContext'

import checkmark from '../../../../assets/checkmark.svg'
import './styles.css'

import TeamLeaderCarousel from './components/GovernanceCadencesTable'
import GovernanceCadencesTable from './components/GovernanceCadencesTable'

const GovernanceConfigStep = () => {
  const { state } = useSetupWizardContext()

  return (
    <div className="step-section governance-config-step">
      <h2>How often will the governance cycle run?</h2>

      <div className="step-details-intro-points-container">
        <ul style={{ width: '45vw' }} className="diamond-yellow-list">
          <li className="diamond-yellow">
            A governance cycle will result in every Team Leader receiving a wizard to complete,
            updating their team members capacity, objectives, etc. These are called Team Wizards.
          </li>
          <li className="diamond-yellow">
            We recommend 12 weeks (approx. 1 quarter) as it is the best balance between planning
            frequency and planning overhead.
          </li>
          <li className="diamond-yellow">
            In between Team Wizards, the Team Leaders will receive Update Wizards to give updates on
            progress and raise blockers.
          </li>
        </ul>
      </div>

      <GovernanceCadencesTable />
    </div>
  )
}

export default GovernanceConfigStep

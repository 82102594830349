import React, { useState } from 'react'

import { Handle, Position } from '@xyflow/react'

function MindMapProblemNode({ data, isConnectable }) {
  return (
    <div className="nodrag text-updater-node">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          border: '2px solid #aaaaaa',
          textAlign: 'left',
          borderRadius: '10px',
          width: '500px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '90%',
            textAlign: 'left',
            borderRadius: '10px',
            padding: '10px',
            color: 'black',
            height: '90px',
            fontSize: '15px',
            overflowY: 'auto',
          }}
        >
          {data.label}
        </div>
        <div
          class="fitted-button"
          style={{
            width: '20px',
            color: 'black',
            padding: '2px',
            fontSize: '10px',
            position: 'relative',
            bottom: '42px',
            height: '20px',
            left: '29.9px',
            border: '3px solid #aaaaaa',
            fontWeight: 'bold',
            alignSelf: 'flex-end',
          }}
          onClick={() => {
            data.addNewTopic()
          }}
        >
          <span class="material-symbols-outlined">add</span>
        </div>
      </div>
      <>
        <Handle type="source" position={Position.Bottom} id="b" isConnectable={isConnectable} />
      </>
    </div>
  )
}

export default MindMapProblemNode

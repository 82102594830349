import React from 'react'
import { CardContainer, CardContent, StepHeader, StepCaption, ButtonContainer } from '../../styles'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const CardsContainer = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
`

const KPIIntro = () => {
  const { t } = useTranslation(['KPIWizard', 'Common'])

  return (
    <>
      <div>
        <StepHeader>{t('kpiIntroPageTitle')}</StepHeader>
        <StepCaption></StepCaption>
      </div>
      <CardsContainer>
        <CardContainer>
          <CardContent>
            <div>{t('kpiIntroPageDescription')}</div>
          </CardContent>
        </CardContainer>
      </CardsContainer>
    </>
  )
}

export default KPIIntro

import React from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { dateFilter, dayMonthFilter } from '../../utils/time'
import InPageLoader from '../InPageLoader'
import './styles.css'
import useSetCommentsPinUnpin from '../../api/mutation/setCommentsPinUnpin'
import { useTranslation } from 'react-i18next'
import { shortenName, linkifyString } from '../../utils/general'
import CommentsDropdownMenu from '../CommentsModal2/CommentsDropdownMenu'
import { removeMentionMarkupFromText } from '../../utils/parseMarkup'
import { MentionsInput, Mention } from 'react-mentions'

const FormikMentionsInput = ({ field, form, userList, setMentionsList, ...props }) => {
  const handleChange = (event, newValue, newPlainTextValue, mentions) => {
    setMentionsList(mentions)
    form.setFieldValue(field.name, newValue)
  }
  return (
    // <Wrapper>
    <MentionsInput
      // className='body_bottom_box'
      {...field}
      {...props}
      value={field.value}
      onChange={handleChange}
      allowSuggestionsAboveCursor={true}
      style={{
        width: '100%',
        minHeight: '5rem',
        border: '0.5px solid gray',
        borderRadius: '10px',
        height: '100%',
        backgroundColor: 'rgba(238, 238, 238, 0.2)',
        padding: '5px',
        suggestions: {
          top: '30px',
          right: '50px',
          list: {
            maxHeight: 80,
            overflowY: 'auto',
          },
          item: {
            padding: '5px 15px',
            borderBottom: '1px solid rgba(0,0,0,0.15)',
            '&focused': {
              backgroundColor: '#d3d3d3',
            },
          },
        },
        input: {
          overflow: 'auto',
        },
        highlighter: {
          boxSizing: 'border-box',
          overflow: 'hidden',
          height: 70,
          border: '4px solid transparent',
        },
      }}
      spellCheck={false}
    >
      <Mention
        trigger="@"
        data={userList}
        // renderSuggestion={this.renderUserSuggestion}
        markup="@[__display__](user:__id__)"
        displayTransform={(id, display) => `@${display}`}
        renderSuggestion={(suggestion, search, highlightedDisplay) => (
          <div className="user">{highlightedDisplay}</div>
        )}
        appendSpaceOnAdd={true}
        // style={{backgroundColor:'rgb(206, 228, 229)'}}
      />
    </MentionsInput>
    // </Wrapper>
  )
}

const ActivityActionForm = ({
  filteredComments = [],
  objectiveId,
  isCommentLoading,
  handleSubmit,
  isLoading,
  taskId = null,
  topicId = null,
  displayAllComments = true,
  reloadFunction,
  userList = [],
  setMentionsList = () => {},
}) => {
  const { t } = useTranslation(['Common'])
  const setCommentPinUnpin = useSetCommentsPinUnpin()

  const updateCommentsPin = async (commentId, value) => {
    let postObj = { commentId: commentId, newValue: value }
    await setCommentPinUnpin.mutateAsync(postObj, {
      onSuccess: (response) => {
        for (let i = 0; i < filteredComments.length; i++) {
          if (filteredComments[i].commentId === commentId) {
            filteredComments[i].isPinned = value
          }
        }
      },
      onError: (response) => {},
    })
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Formik
          initialValues={{
            text: '',
          }}
          validationSchema={Yup.object().shape({
            text: Yup.string().required(t('required')),
          })}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values, objectiveId, taskId, topicId)
            resetForm()
          }}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form className="form" style={{ paddingTop: '0' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  gap: '0.5rem',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <div
                    className="form-field"
                    style={{ margin: '0', alignSelf: 'flex-start', height: '5rem', width: '98%' }}
                  >
                    <Field
                      name="text"
                      component={FormikMentionsInput}
                      userList={userList}
                      setMentionsList={setMentionsList}
                    />
                    {errors.text && touched.text && (
                      <div className="validation-error">{errors.text}</div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                  }}
                >
                  <button
                    className="sleek-button sleek-full-blue"
                    disabled={isLoading}
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 'fit-content',
                      height: 'fit-content',
                      borderRadius: '10px',
                      color: 'white',
                      padding: '0.3rem 1rem 0.3rem 1rem',
                      height: '2.2rem',
                      gap: '0.5rem',
                      fontSize: '14px',
                      backgroundColor: '#4472c4',
                      color: '#fff',
                      border: '1px solid #4472c4',
                      marginTop: '0.5rem',
                    }}
                    type="submit"
                  >
                    <span>
                      {isLoading === true ? (
                        <div
                          style={{ width: '0.5rem', height: '0.5rem', margin: '0' }}
                          className="loading-spinner"
                        ></div>
                      ) : null}
                      {t('commonButtons.comment')}
                    </span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {filteredComments.length === 0 && (
        <span style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          {' '}
          {t('commentsModal.commentsType.noComments')}
        </span>
      )}
      {filteredComments.length > 0 && (
        <div className="action-comment">
          {filteredComments.map((comm, index) => (
            <div key={index} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div className="divider" style={{ marginBottom: '1rem' }}></div>
              <div className="action-comment-column" style={{ width: '100%', gap: '0.5rem' }}>
                <div
                  className="action-comment-row"
                  style={{ color: 'grey', fontSize: '14px', justifyContent: 'space-between' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '1rem',
                      alignItems: 'center',
                    }}
                  >
                    <div className="action-comment-column" style={{ width: '2.5rem' }}>
                      {!comm?.isPinned && (
                        <button class="icon-button-no-border" style={{ rotate: '-40deg' }}>
                          <span
                            style={{ fontSize: '24px' }}
                            class="material-symbols-outlined icon-size"
                            onClick={() => {
                              updateCommentsPin(comm?.commentId, true)
                            }}
                          >
                            push_pin
                          </span>
                        </button>
                      )}
                      {comm?.isPinned && (
                        <button class="icon-button-no-border" style={{ rotate: '-40deg' }}>
                          <span
                            style={{ fontSize: '24px', color: 'red' }}
                            class="material-symbols-outlined icon-size"
                            onClick={() => {
                              updateCommentsPin(comm?.commentId, false)
                            }}
                          >
                            push_pin
                          </span>
                        </button>
                      )}
                    </div>

                    <span
                      style={{
                        backgroundColor: '#ED522E',
                        marginLeft: '-10px',
                      }}
                      className="tooltip circle-small-medium user-initials-circle"
                    >
                      {(comm.eName.match(/\b(\w)/g) || []).slice(0, 2).join('')}
                      <span className="tooltiptext" style={{ fontSize: '16px' }}>
                        {comm.eName}
                      </span>
                    </span>

                    <span>{shortenName(comm.eName)}</span>
                    <span>{dayMonthFilter(comm.timestamp)}</span>
                  </div>
                  <div style={{ alignSelf: 'end' }}>
                    <CommentsDropdownMenu
                      commentId={comm?.commentId}
                      reloadFunction={reloadFunction}
                      commentText={comm.text}
                      isCommentLoading={isCommentLoading}
                      userList={userList}
                    />
                  </div>
                </div>
                <div className="action-comment-row">
                  <span>{linkifyString(removeMentionMarkupFromText(comm.text))}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default ActivityActionForm

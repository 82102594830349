import styled from 'styled-components'

const ContainerObjective = styled.div`
  margin: 10px 20px;
`

const Container = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: nowrap;
  overflow-x: hidden;
  padding: 40px 8px;
`

const KanbanHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.4rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  overflow-x: hidden;
`

const KanbanTitle = styled.div`
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  max-width: 50rem;
  word-break: break-word;
`
const CreateTaskButton = styled.div`
  padding: 0.5rem;
  white-space: nowrap;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: rgb(237, 82, 46);
  color: #fff;
`
export { Container, KanbanHeader, KanbanTitle, CreateTaskButton, ContainerObjective }

import React, { useState, useEffect } from 'react'
import WizardQuestion from '../../../../components/WizardQuestion'
import { useTranslation } from 'react-i18next'
import { getCompanyOverview, setCompanyOverview } from '../../../../api/services/kpi.services'

const CompanyInfo = () => {
  const { t } = useTranslation(['KPIWizard', 'Common'])

  const [companyOverviewAnswer, setCompanyOverviewAnswer] = useState(null)
  const [args, setArgs] = useState({})
  const [isDemo, setIsDemo] = useState(false)

  React.useEffect(() => {
    setArgs({
      companyOverview: companyOverviewAnswer,
      isDemo: isDemo,
    })
  }, [companyOverviewAnswer, isDemo])

  return (
    <>
      <WizardQuestion
        question={t('companyOverview')}
        answer={companyOverviewAnswer}
        setAnswer={setCompanyOverviewAnswer}
        saveSetFunction={() => setCompanyOverview(args)}
        loadGetFunction={getCompanyOverview}
        hasSaveButton={true}
        fromCompanyInfo={true}
        isDemo={isDemo}
        setIsDemo={setIsDemo}
      />
    </>
  )
}
export default CompanyInfo

import React, { Fragment, useState } from 'react'
import Modal from '../Modal'
import InPageLoader from '../InPageLoader'
import useObjectiveTask from '../../api/query/getObjectiveTask'
import TaskStatic from '../../pages/kanban/common/TaskStatic'

const SeeMoreActivitiesModal = ({
  isModalOpen,
  closeModal,
  statement,
  objectiveId,
  activityType,
  setIsRefreshObjective,
  employeesWithColors,
}) => {
  const {
    data: activityData,
    isLoading: activityDataIsLoading,
    isFetching: activityDataIsFetching,
  } = useObjectiveTask(objectiveId)
  const [activitiesList, setActivitiesList] = React.useState(null)

  React.useEffect(() => {
    if (activityData?.milestones) {
      let arr = []
      for (let key in activityData?.milestones) {
        arr.push(activityData?.milestones[key])
      }
      arr.sort((a, b) => {
        if (a.dueDate === 0 && b.dueDate === 0) {
          if (a.milestoneId < b.milestoneId) return -1
          if (a.milestoneId > b.milestoneId) return 1
          return 0
        }
        if (a.dueDate === 0) {
          return 1
        }
        if (b.dueDate === 0) {
          return -1
        }
        return a.dueDate - b.dueDate
      })
      setActivitiesList([...arr])
    }
  }, [activityData])

  return (
    <>
      <Modal
        title={activityType + ' Actions'}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        width={'70%'}
        modalContainerStyle={{ width: '80%', padding: '0px' }}
      >
        <>
          <h3>Objective : {statement}</h3>
          {activitiesList && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                textAlign: 'left',
              }}
            >
              {activitiesList
                ?.sort((a, b) => {
                  if (a.completedTs === 0) {
                    return 1
                  }
                  if (b.completedTs === 0) {
                    return -1
                  }
                  return b.completedTs - a.completedTs
                })
                ?.map((act, actIndex) => {
                  return (
                    <>
                      {act?.progress === 'COMPLETED' && (
                        <TaskStatic
                          key={activityData.id}
                          task={act}
                          index={actIndex}
                          objectiveId={objectiveId}
                          setIsRefreshObjective={setIsRefreshObjective}
                          employeesWithColors={employeesWithColors}
                        />
                      )}
                    </>
                  )
                })}
            </div>
          )}
          {activitiesList && activitiesList?.length === 0 && (
            <span>There are no {activityType} activities.</span>
          )}

          {(activityDataIsLoading || activityDataIsFetching) && <InPageLoader inComponent={true} />}
        </>
      </Modal>
    </>
  )
}

export default SeeMoreActivitiesModal

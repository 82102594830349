import React from 'react'
import CardModal from '../../CardModal'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../utils/routes'
import ObjMapActivitiesModal from '../../../pages/objectiveFlow/modelComponent/activitiesModal'
import CommentsModal2 from '../../CommentsModal2'
import Toast from '../../../common/toast'
import { dayMonthFilter } from '../../../utils/time'
import { shortenName } from '../../../utils/general'
import { getCompanyConfigSettings } from '../../../utils/auth'
import { hasRole } from '../../../utils/auth'
import { removeMentionMarkupFromText } from '../../../utils/parseMarkup'

const BotMessage = ({
  message,
  primaryDateMode = null,
  lastUpdated = null,
  searchEmpName = null,
}) => {
  const [topicId, setTopicId] = React.useState(0)
  const [topicType, setTopicType] = React.useState(0)
  const [isTopicModalOpen, setIsTopicModalOpen] = React.useState(false)
  const [isActivitiesModalOpen, setIsActivitiesModalOpen] = React.useState(false)
  const [objId, setObjId] = React.useState(0)
  const [activityId, setActivityId] = React.useState(0)

  const [isCommentsModalOpen, setIsCommentsModalOpen] = React.useState(false)
  const [objectiveSelected, setObjectiveSelected] = React.useState(null)
  const [commentId, setCommentId] = React.useState(0)

  const navigate = useNavigate()
  const productSettings = getCompanyConfigSettings('productSettings')
  const isDelegateTl = parseInt(localStorage.getItem('dType')) === 5 ? true : false
  const topicTypeMap = {
    1: 'Blocker',
    2: 'Guidance',
    3: 'Update',
  }
  const openModal = async () => {
    try {
      // objective
      if (message.textType === 'OBJECTIVE_TYPE') {
        const isMultiView =
          hasRole('area') ||
          hasRole('cxo') ||
          hasRole('ceo') ||
          hasRole('cos') ||
          (hasRole('team') && isDelegateTl)

        let url = productSettings ? `/${routes.summaryOfObjectives}` : `/${routes.objectivemap}`
        navigate(url, {
          state: {
            objId: message.id,
          },
        })
        // This is needed to trigger a reload of ObjectiveMap if we are already on it.
        // Otherwise, the state change (objId: message.id) will not trigger a reload.
        navigate(0)
      } else if (message.textType === 'MILESTONE_TYPE') {
        if (message.umtId !== 0) {
          setTopicType(message.topicType)
          setTopicId(message.umtId)
          setIsTopicModalOpen(true)
        } else {
          // regular activity
          setObjId(message.goalId)
          setIsActivitiesModalOpen(true)
          setActivityId(message.id)
        }
      } else if (message.textType === 'COMMENT_TYPE') {
        if (message.umtId !== 0) {
          setTopicType(message.topicType)
          setTopicId(message.umtId)
          setIsTopicModalOpen(true)
        } else {
          setObjectiveSelected({
            statement: message.goalDesc,
            id: message.goalId,
          })
          setIsCommentsModalOpen(true)
          setCommentId(message.id)
        }
      } else {
        // topic
        setTopicType(message.topicType)
        setTopicId(message.id)
        setIsTopicModalOpen(true)
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }

  const closeCommentsModal = () => {
    setIsCommentsModalOpen(false)
    setObjectiveSelected(null)
    setCommentId(0)
  }

  const truncateText = (text) => {
    if (text.length <= 60) {
      return text
    } else {
      return text.slice(0, 60) + '...'
    }
  }

  const buildTextByType = () => {
    if (message.textType === 'OBJECTIVE_TYPE') {
      //   let actionText = "Objective '" + truncateText(message.description) + "' was recently updated"
      let actionText = (
        <p>
          <strong>{shortenName(message.name)}</strong> owns the objective '
          {truncateText(message.description)}'
          {message.isRecentlyUpdated
            ? ', which has made progress in the last ' + lastUpdated
            : ', which does not seem to have any activity for the last ' + lastUpdated}
        </p>
      )

      return actionText
    } else if (message.textType === 'MILESTONE_TYPE') {
      const actionTime = message.closedTime !== 0 ? message.closedTime : message.openedTime
      const statusMap = {
        new: 'added',
        closed: 'completed',
        updated: 'updated',
      }

      const status = statusMap[message.milestoneStatus] || 'added'
      let associatedDescription = ''
      let associatedType = ''
      if (message.umtId) {
        associatedDescription = message.topicDesc
        associatedType = topicTypeMap[message.topicType] || 'Topic'
      } else {
        associatedDescription = message.goalDesc
        associatedType = 'Objective'
      }

      let searchEmpNameLower = searchEmpName.toLowerCase().trim()
      let isSearchEmpNameValid =
        searchEmpNameLower !== '' &&
        searchEmpNameLower !== 'me' &&
        searchEmpNameLower !== 'my_team' &&
        searchEmpNameLower !== 'other_team'
          ? true
          : false

      let assignedToMsg = isSearchEmpNameValid ? `assigned to` : ''
      let assignedToValue = isSearchEmpNameValid ? `${searchEmpName}` : ''

      let actionText = (
        <p>
          On {dayMonthFilter(actionTime)}, <strong>{shortenName(message.name)}</strong> {status} the
          action {removeMentionMarkupFromText(truncateText(message.description))} {assignedToMsg}{' '}
          <strong>{assignedToValue}</strong> <strong>on</strong> {associatedType}
          {truncateText(associatedDescription)}.
        </p>
      )
      return actionText
    } else if (message.textType === 'COMMENT_TYPE') {
      // if (primaryDateMode === 'UPDATED_DATE') {

      // }
      let associatedDescription = ''
      let associatedType = ''
      let commentType = message.isCommentDecision ? 'decision' : 'comment'
      if (message.milestoneId) {
        associatedDescription = message.milestoneDesc
        associatedType = 'Action'
      } else if (message.umtId) {
        associatedDescription = message.topicDesc
        associatedType = topicTypeMap[message.topicType] || 'Topic'
      } else {
        associatedDescription = message.goalDesc
        associatedType = 'Objective'
      }

      let actionText = (
        <p>
          On {dayMonthFilter(message.openedTime)},<strong>{shortenName(message.name)}</strong> added
          a {commentType} {truncateText(message.description)} <strong>on</strong> {associatedType}{' '}
          {truncateText(associatedDescription)}
        </p>
      )
      return actionText
    } else {
      // topics
      const topicType = topicTypeMap[message.topicType] || 'topic'

      const actionTime = message.closedTime !== 0 ? message.closedTime : message.openedTime
      const actionVerb = message.closedTime !== 0 ? 'resolved' : 'added'
      let actionText = (
        <p>
          On {dayMonthFilter(actionTime)}, <strong>{shortenName(message.name)}</strong> {actionVerb}{' '}
          the {topicType} {truncateText(message.description)}
          <strong> on </strong> {truncateText(message.goalDesc)}
          {message.closedTime == 0 && (
            <>
              {message.isRecentlyUpdated
                ? ', which has made progress in the last ' + lastUpdated
                : ', which does not seem to have any activity for the last ' + lastUpdated}
            </>
          )}
        </p>
      )
      return actionText
    }
  }

  return (
    <>
      {isTopicModalOpen === true && topicId !== 0 && topicType !== 0 && (
        <CardModal
          isModalOpen={isTopicModalOpen}
          closeModal={() => {
            setIsTopicModalOpen(false)
            setTopicId(0)
            setTopicType(0)
            // refreshBGUScorecardFn()
          }}
          umtId={topicId}
          topicType={topicType}
        />
      )}
      {isActivitiesModalOpen && objId !== 0 && activityId !== 0 && (
        <ObjMapActivitiesModal
          closeModal={() => {
            setIsActivitiesModalOpen(false)
            setObjId(0)
            setActivityId(0)
          }}
          objectiveId={objId}
          mode={'activities'}
          activityId={activityId}
        />
      )}
      {isCommentsModalOpen === true && objectiveSelected && commentId !== 0 && (
        <CommentsModal2
          objective={{ id: objectiveSelected.id, statement: objectiveSelected?.statement }}
          handleCancel={closeCommentsModal}
          displayAllComments={true}
          commentId={commentId}
        />
      )}
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <span>{buildTextByType()}</span>
        <div onClick={() => openModal()} style={{ width: 'fit-content', height: 'fit-content' }}>
          {/* external link icon */}
          <svg width="24px" height="24px" viewBox="0 0 24 24" style={{ cursor: 'pointer' }}>
            <g
              strokeWidth="2.1"
              stroke="#666"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="17 13.5 17 19.5 5 19.5 5 7.5 11 7.5"></polyline>
              <path d="M14,4.5 L20,4.5 L20,10.5 M20,4.5 L11,13.5"></path>
            </g>
          </svg>
        </div>
      </div>
    </>
  )
}

export default BotMessage

import React from 'react'
import Modal from '../Modal'
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  LabelList,
  ComposedChart,
  ReferenceLine,
} from 'recharts'
import Select from 'react-select'
import GridLayout from 'react-grid-layout'
import { activityStatus } from '../../utils/activityStatus'
import useGetActivitiesChartOptions from '../../api/query/useGetActivitiesChartOptions'
import useGetActivitiesChartData from '../../api/query/useGetActivitiesChartData'
import InPageLoader from '../InPageLoader'
import styled from 'styled-components'

const StyledHorizontalBarYAxisResponsiveContainer = styled(ResponsiveContainer)`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;

  .recharts-bar {
    display: none;
  }
  .recharts-xAxis {
    display: none;
  }
`
const StyledHorizontalBarDataResponsiveContainer = styled(ResponsiveContainer)`
  .recharts-yAxis {
    display: none;
  }
`
const StyledToolTip = styled.div`
  margin: 0;
  line-height: 5px;
  border: 1px solid #f5f5f5;
  background-color: rgba(255, 255, 255, 255);
  padding-bottom: 0%;
  opacity: 0.8;
`

const layout = {
  pc: {
    breakpoint: 668,
    col: 3,
  },
  sp: {
    breakpoint: 0,
    col: 1,
  },
}

const CustomTooltip = ({ active, payload, whichBar }) => {
  if (active && payload && payload.length) {
    return (
      <>
        {payload
          ?.filter((ele) => {
            let vals = ele?.payload[ele?.name]

            let keys = Object.keys(vals).filter((val) => val != 'count')
            return keys.length > 0 ? ele.dataKey == whichBar : true
          })
          ?.map((ele) => {
            let vals = ele?.payload[ele?.name]

            let keys = Object.keys(vals).filter((val) => val != 'count')
            return (
              <StyledToolTip key={ele.dataKey + ele.value}>
                <p className="label" style={{ color: ele.fill }}>{`${ele.name} : ${
                  ele?.payload[ele?.name]['count']
                }`}</p>
                <>
                  {keys.length > 0 ? (
                    <div>
                      {keys.map((key) => (
                        <p
                          key={key}
                          className="desc"
                          style={{ marginLeft: '1rem' }}
                        >{`${key}: ${vals[key]}`}</p>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              </StyledToolTip>
            )
          })}
      </>
    )
  }
}

const ObjectiveActivityChartsModal = ({ isModalOpen, closeModal, objective }) => {
  const layouts = {
    pc: [
      { i: 'a', x: 0, y: 0, w: 3, h: 2 },
      { i: 'b', x: 0, y: 2, w: 3, h: 2 },
    ],
  }

  const [graphData, setGraphData] = React.useState(null)
  const [toggle, setToggle] = React.useState(false)
  const [whichBar, setWhichBar] = React.useState('')

  React.useEffect(() => {
    setGraphData(null)
    applyFilters(toggle)
  }, [toggle])

  React.useEffect(() => {}, [graphData])

  const {
    data: optionsData,
    isLoading: optionsIsLoading,
    isFetching: optionsIsFetching,
  } = useGetActivitiesChartOptions(parseInt(objective.id))

  const [statusFilterValue, setStatusFilterValue] = React.useState([
    { value: 'NOT_STARTED', label: 'Not Started' },
    { value: 'ON_TRACK', label: 'On Track' },
    { value: 'FOR_REVIEW', label: 'For Review' },
  ])
  const [chartFilterValue, setChartFilterValue] = React.useState('0')
  const [categoryFilterValue, setCategoryFilterValue] = React.useState('0')
  const [dueDateFilterValue, setDueDateFilterValue] = React.useState([null, null])
  const [filter, setFilter] = React.useState(null)

  const handleChangeStatusFilter = (value) => {
    setStatusFilterValue(value)
  }

  const handleChangeChartFilterValue = (event) => {
    setChartFilterValue(event.target.value)
  }

  const handleChangeCategoryFilterValue = (event) => {
    setCategoryFilterValue(event.target.value)
  }

  const applyFilters = (toggle) => {
    let filterObj = {
      statusValue: statusFilterValue,
      chartValue: chartFilterValue,
      categoryValue: categoryFilterValue,
      dateValue: dueDateFilterValue,
    }
    setFilter(filterObj)
    getChartData(filterObj, toggle)
  }

  let colorArray = ['#D85959', '#fe7f2d', '#fcca46', '#E38888', '#FEA56C', '#FFF7BB']
  let colors = [
    '#0052CC',
    '#00a3bf',
    '#00875A',
    '#FF991F',
    '#DE350B',
    '#5243AA',
    '#172B4D',
    '#808000',
    '#BF1585',
  ]

  const renderCustomizedLabel = (props) => {
    const { content, ...rest } = props
    return <Label {...rest} fontSize="12" fontWeight="Bold" fill={'black'} />
  }
  const avgLabelStyle = { fontSize: 12, fill: '#0926D5' }

  const mutationGetActivitiesChartData = useGetActivitiesChartData()

  const getChartData = async (filter, toggle) => {
    await mutationGetActivitiesChartData.mutateAsync([parseInt(objective.id), filter, toggle], {
      onSuccess: (response) => {
        setGraphData([...response])
      },
      onError: (e) => {
        setGraphData([])
      },
    })
  }

  const allDynamicKeysNames =
    graphData?.length > 0
      ? Array.from(new Set(graphData?.flatMap((item) => Object.keys(item)))).filter(
          (key, keyIndex) => key !== '$_eId' && key !== '$_name',
        )
      : []
  const keysToExclude = ['$_eId', '$_name']
  const sum = graphData?.reduce((sum, item) => {
    const filteredKeys = Object.keys(item).filter((key) => !keysToExclude?.includes(key))
    const itemSum = filteredKeys?.reduce((itemSum, key) => itemSum + item[key], 0)
    return sum + itemSum
  }, 0)

  const averageValue = graphData?.length > 0 ? sum / graphData?.length : 0

  const handleChangeDueDateFilter = (event, type) => {
    let tempArrayDate = [...dueDateFilterValue]
    if (type === 'FROM') {
      tempArrayDate[0] = event.target.value
    } else if (type === 'TO') {
      tempArrayDate[1] = event.target.value
    }
    setDueDateFilterValue(tempArrayDate)
  }

  const populateBars = (dataList) => {
    dataList.sort((a, b) => new Date(a._due) - new Date(b._due))
    let names = new Set()
    dataList.map((obj, i) => {
      return Object.keys(obj)
        .filter((k) => k != '_due')
        .forEach((name, ind) => {
          names.add(name)
        })
    })

    let i = 0
    let bars = []
    names.forEach((name) => {
      bars.push(
        <Bar
          dataKey={`${name}.count`}
          fill={colors[i]}
          maxBarSize={150}
          // barSize={20}
          name={name}
          onMouseOver={() => setWhichBar(`${name}.count`)}
        />,
      )
      i += 1
    })
    return bars
  }

  return (
    <>
      <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
        <>
          <h3>Objective : {objective.statement}</h3>
          <div
            style={{ display: 'flex', flexDirection: 'row', columnGap: '2rem', height: '10rem' }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '0.5rem',
                alignItems: 'flex-start',
              }}
            >
              <label for="teamLeadersFilter">
                <b>Status:</b>
              </label>
              <div data-testid="multi-options-1" style={{ maxWidth: '16vw' }}>
                <Select
                  value={statusFilterValue}
                  isMulti
                  onChange={handleChangeStatusFilter}
                  maxMenuHeight={100}
                  getOptionLabel={(option) => option?.label}
                  getOptionValue={(option) => option?.value}
                  options={activityStatus}
                />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '0.5rem',
                  minWidth: '7rem',
                  alignItems: 'flex-start',
                }}
              >
                <label for="timeframeFilter">
                  <b>Chart Value:</b>
                </label>
                <select
                  value={chartFilterValue}
                  onChange={handleChangeChartFilterValue}
                  style={{ height: '38px' }}
                >
                  <option value="0">Activity Count</option>
                  {optionsData?.chartValueOptions?.map((entry, entryIndex) => (
                    <option value={entry.value}>{entry.label}</option>
                  ))}
                </select>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '0.5rem',
                  minWidth: '7rem',
                  alignItems: 'flex-start',
                }}
              >
                <label for="timeframeFilter">
                  <b>Categorized By:</b>
                </label>
                <select
                  value={categoryFilterValue}
                  onChange={handleChangeCategoryFilterValue}
                  style={{ height: '38px' }}
                >
                  <option value="0">None</option>
                  {optionsData?.categoryOptions?.map((entry, entryIndex) => (
                    <option value={entry.value}>{entry.label}</option>
                  ))}
                </select>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                <label className="label-custom">
                  <b>From due date:</b>
                </label>
                <input
                  type="date"
                  id="dueDate"
                  name="dueDate"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChangeDueDateFilter(e, 'FROM')
                  }}
                  style={{ height: '38px' }}
                />
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                <label className="label-custom">
                  <b>To due date:</b>
                </label>
                <input
                  type="date"
                  id="dueDate"
                  name="dueDate"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChangeDueDateFilter(e, 'TO')
                  }}
                  style={{ height: '38px' }}
                />
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
                <div
                  class="fitted-button blue"
                  onClick={() => {
                    applyFilters(toggle)
                  }}
                >
                  <span class="extra-lateral-spacing">Apply</span>
                </div>

                <div
                  class="fitted-button blue"
                  onClick={() => {
                    setToggle((prev) => !prev)
                  }}
                >
                  <span class="extra-lateral-spacing">
                    {!toggle ? 'View Spread' : 'View Regular Charts'}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', width: '100%' }}>
            {optionsIsLoading || optionsIsFetching || graphData === null ? (
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <InPageLoader inComponent={true} />
              </div>
            ) : graphData?.length > 0 ? (
              !toggle ? (
                <>
                  <ResponsiveContainer height={graphData.length * 130} width={'100%'}>
                    <ComposedChart
                      layout="vertical"
                      data={graphData}
                      margin={{ left: 5, right: 10 }}
                    >
                      <Legend
                        layout="horizontal"
                        verticalAlign="bottom"
                        align="left"
                        formatter={(value, entry, index) => (
                          <span style={{ color: 'black', fontSize: '0.8rem' }}>{value}</span>
                        )}
                      />
                      <XAxis
                        hide
                        axisLine={false}
                        tickLine={false}
                        ticks={['dataMin', 'dataMax']}
                        tickFormatter={(tick) => `${tick}%`}
                        type="number"
                        domain={['dataMin', 'dataMax']}
                      />
                      <YAxis
                        type="category"
                        dataKey="$_name"
                        stroke="#44444"
                        style={{
                          fontSize: '0.8rem',
                        }}
                      />
                      <Tooltip />
                      {allDynamicKeysNames.map((element, elementIndex) => (
                        <>
                          <Bar
                            barSize={70}
                            dataKey={element}
                            stackId="a"
                            fill={
                              colorArray[
                                ((elementIndex % colorArray.length) + colorArray.length) %
                                  colorArray.length
                              ]
                            }
                          >
                            <LabelList
                              dataKey={element}
                              position="center"
                              content={renderCustomizedLabel}
                            />
                          </Bar>
                          <ReferenceLine
                            x={averageValue}
                            stroke="#0926D5"
                            label={{
                              position: 'insideTopRight',
                              value: `Avg: ${averageValue.toFixed(2)}`,
                              style: avgLabelStyle,
                            }}
                            ifOverflow="extendDomain"
                          />
                        </>
                      ))}
                    </ComposedChart>
                  </ResponsiveContainer>
                </>
              ) : (
                <>
                  <GridLayout.Responsive
                    className="layout"
                    layouts={layouts}
                    breakpoints={{ pc: layout.pc.breakpoint, sp: layout.sp.breakpoint }}
                    cols={{ pc: layout.pc.col, sp: layout.sp.col }}
                    width={900}
                    // height={300}
                    isDraggable={false}
                  >
                    <div key="b">
                      <div
                        style={{
                          height: '100%',
                          overflowX: 'scroll',
                          width: 'calc(100% - 60px)',
                          float: 'right',
                          overflowY: 'hidden',
                        }}
                      >
                        <StyledHorizontalBarDataResponsiveContainer
                          width={`${Math.ceil(graphData?.length / 5)}00%`}
                        >
                          <BarChart
                            width={800}
                            height={300}
                            data={graphData}
                            margin={{
                              top: 0,
                              right: 12,
                              left: 0,
                              bottom: 0,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="_due" interval={0} tickLine={false} />
                            <Tooltip
                              content={<CustomTooltip whichBar={whichBar} />}
                              cursor={{ stroke: '#f1f3f4', strokeWidth: 2 }}
                            />
                            <Legend align="left" />
                            {populateBars(graphData)}
                          </BarChart>
                        </StyledHorizontalBarDataResponsiveContainer>
                      </div>

                      <StyledHorizontalBarYAxisResponsiveContainer width="100%">
                        <BarChart
                          data={graphData}
                          margin={{ top: 0, right: 12, bottom: 38, left: 0 }}
                        >
                          <XAxis />
                          <YAxis type="number" tickLine={false} />
                          {populateBars(graphData)}
                        </BarChart>
                      </StyledHorizontalBarYAxisResponsiveContainer>
                    </div>
                  </GridLayout.Responsive>
                </>
              )
            ) : (
              <span> No values fit the filter criteria </span>
            )}
          </div>
        </>
      </Modal>
    </>
  )
}

export default ObjectiveActivityChartsModal

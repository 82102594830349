import React from 'react'
import { Formik, Field, Form } from 'formik'
import Modal from '../../../../../components/Modal'
import ModalSaveButton from '../../../../../components/ModalSubmitButton'
import CaseMappingSchema from '../../../../../utils/validationSchemas/CaseMappingSchema'

const CaseMappingModal = ({ mode, closeModal, handleSubmit, teamLeaders, rule }) => {
  const teamLeadersOriginalCopy = JSON.parse(JSON.stringify(teamLeaders))

  const filterTeamLeaders = (empoweredEmail) => {
    teamLeaders = teamLeadersOriginalCopy?.filter((member) => member.email !== empoweredEmail)
  }
  return (
    <>
      <Modal
        title={mode === 'ADD' ? 'Add Reporting Line' : 'Edit Reporting Line'}
        closeModal={() => {
          closeModal()
        }}
        width={'30rem'}
      >
        <Formik
          enableReinitialize
          initialValues={{
            tlEmpowerment: mode === 'ADD' ? '' : rule?.tlEmpowerment,
            mappedPeople: mode === 'ADD' ? '' : rule?.mappedPeople,
          }}
          validationSchema={CaseMappingSchema}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values.tlEmpowerment, values.mappedPeople)
            resetForm()
          }}
        >
          {({ errors, touched, resetForm, values, setFieldValue, validateForm }) => (
            <Form className="form" style={{ alignItems: 'flex-start' }}>
              <div className="form-field">
                <label>Managing Team Leader</label>

                <Field
                  as="select"
                  id="tlEmpowerment"
                  name="tlEmpowerment"
                  onChange={(e) => {
                    setFieldValue('tlEmpowerment', e.target.value)
                    setFieldValue('mappedPeople', '')
                    filterTeamLeaders(e.target.value)
                  }}
                >
                  <option value="">Select Team Leader</option>

                  {teamLeadersOriginalCopy?.map((emp, empIndex) => (
                    <option key={`tl-${empIndex}`} id={emp.email} value={emp.email}>
                      {emp.name}
                    </option>
                  ))}
                </Field>

                {errors?.tlEmpowerment && touched?.tlEmpowerment && (
                  <div
                    className="validation-error"
                    style={{ textAlign: 'left', maxWidth: '16rem' }}
                  >
                    {errors?.tlEmpowerment}
                  </div>
                )}
              </div>

              <div className="form-field">
                <label>Reporting Team Leaders</label>

                {teamLeaders?.map((emp, empIndex) => (
                  <>
                    {values?.tlEmpowerment?.email !== emp?.email && (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Field
                          type="checkbox"
                          name="mappedPeople"
                          value={emp?.email}
                          disabled={values.tlEmpowerment === ''}
                        />
                        {emp?.name}
                      </div>
                    )}
                  </>
                ))}

                {errors?.mappedPeople && touched?.mappedPeople && (
                  <div
                    className="validation-error"
                    style={{ textAlign: 'left', maxWidth: '16rem' }}
                  >
                    {errors?.mappedPeople}
                  </div>
                )}
              </div>

              <div className="modal-dual-btn">
                <button
                  className="dual-btn negative-btn"
                  onClick={() => {
                    closeModal()
                    resetForm()
                  }}
                  type="button"
                >
                  CANCEL
                </button>
                <ModalSaveButton></ModalSaveButton>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}
export default CaseMappingModal

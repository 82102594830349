import React from 'react'
import '../styles.css'
import { useGovernanceContext } from '../../../context/governanceContext'
import useCsuiteLeadersForCeo from '../../../../../api/query/useCsuiteLeadersForCEO'
import InPageLoader from '../../../../../components/InPageLoader'
import { AdminDropdownMenu } from '../../../../../components/SooDropdownMenu'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import Toast from '../../../../../common/toast'
import { postHttpRequest } from '../../../../../api/query/dynamicAPI'

const CsuiteLeaders = ({ setRefetchData }) => {
  let { state, dispatch } = useGovernanceContext()
  const { t } = useTranslation(['Dashboard'])
  //   temp hack to force update the leader list...
  const {
    data: csuites,
    isLoading,
    isFetching,
  } = useCsuiteLeadersForCeo(
    state.selectedCeoLeaderEId,
    state?.data?.allCsuiteLeadersList?.length ?? 0,
  )

  //Swetha TODO: This is a temporary hack. Changes need to be made so the state is updated automatically and not manually as below
  if (!state.isCsuiteLeaderListUpdated) {
    state = {
      ...state,
      data: {
        ...state.data,
        // data = undefined || null is when users select 'select c-suite leader' option or..
        // if thereis no ceo in the company, we show all c-suite leaders
        csuiteLeadersList:
          (state?.data?.ceoLeadersList?.length ?? 0) === 0
            ? state.data.allCsuiteLeadersList
            : !csuites
              ? []
              : [...csuites.employees.filter((e) => !e.isDeleted)],
      },
    }
  }

  return (
    <div className="table-wrapper" style={{ justifyContent: 'center', alignItems: 'center' }}>
      {isLoading && isFetching && (
        <div>
          <InPageLoader inComponent={true} />
        </div>
      )}
      {!(isLoading && isFetching) && (
        <div className="widget-grid-5" style={{ width: '100%', marginLeft: '5rem' }}>
          <div
            style={{
              margin: '0.5rem 0.5rem',
              textAlign: 'left',
              alignItems: 'normal',
              justifyContent: 'normal',
              width: '100%',
            }}
            className="widget-grid-title"
          >
            {t('adminDashboard.name')}
          </div>
          <div
            style={{
              margin: '0.5rem 0.5rem',
              textAlign: 'left',
              alignItems: 'normal',
              justifyContent: 'normal',
              width: '100%',
            }}
            className="widget-grid-title"
          >
            {t('adminDashboard.title')}
          </div>
          <div
            style={{
              margin: '0.5rem 0.5rem',
              textAlign: 'left',
              alignItems: 'normal',
              justifyContent: 'normal',
              width: '100%',
            }}
            className="widget-grid-title"
          >
            {t('adminDashboard.email')}
          </div>
          <div
            style={{
              margin: '0.5rem 0.5rem',
              textAlign: 'left',
              alignItems: 'normal',
              justifyContent: 'normal',
              width: '100%',
            }}
            className="widget-grid-title"
          >
            {t('adminDashboard.function')}
          </div>
          <div
            style={{ margin: '0.5rem 0.5rem', width: '8rem' }}
            className="widget-grid-title"
          ></div>

          {state?.data?.csuiteLeadersList?.map((csuite, index) => (
            <React.Fragment key={`AL-${csuite?.eId}-${csuite?.email}`}>
              <div
                style={{
                  color: csuite?.isDeactivated ? '#BBBCBC' : 'black',
                  margin: '0.5rem 0.5rem',
                  textAlign: 'left',
                  alignItems: 'normal',
                  justifyContent: 'normal',
                  width: '90%',
                  wordBreak: 'break-word',
                  wordWrap: 'break-word',
                }}
                className="widget-grid-item"
              >
                {csuite?.name}
              </div>
              <div
                style={{
                  color: csuite?.isDeactivated ? '#BBBCBC' : 'black',
                  margin: '0.5rem 0.5rem',
                  textAlign: 'left',
                  alignItems: 'normal',
                  justifyContent: 'normal',
                  width: '90%',
                  wordBreak: 'break-word',
                  wordWrap: 'break-word',
                }}
                className="widget-grid-item"
              >
                {csuite?.title}
              </div>
              <div
                style={{
                  color: csuite?.isDeactivated ? '#BBBCBC' : 'black',
                  margin: '0.5rem 0.5rem',
                  textAlign: 'left',
                  alignItems: 'normal',
                  justifyContent: 'normal',
                  width: '90%',
                  wordBreak: 'break-word',
                  wordWrap: 'break-word',
                }}
                className="widget-grid-item"
              >
                {csuite?.email}
              </div>
              <div
                style={{
                  color: csuite?.isDeactivated ? '#BBBCBC' : 'black',
                  margin: '0.5rem 0.5rem',
                  textAlign: 'left',
                  alignItems: 'normal',
                  justifyContent: 'normal',
                  width: '90%',
                  wordBreak: 'break-word',
                  wordWrap: 'break-word',
                }}
                className="widget-grid-item"
              >
                {csuite?.function}
              </div>

              <div
                style={{
                  margin: '0.5rem 0.5rem',
                  textAlign: 'left',
                  alignItems: 'normal',
                  justifyContent: 'normal',
                }}
                className="widget-grid-item"
              >
                <AdminDropdownMenu
                  hasDeactivate={!csuite?.isDeactivated}
                  hasDelete={true}
                  onClickEdit={() => {
                    dispatch({
                      type: 'OPEN_C_SUITE_LEADER_MODAL',
                      editCeoLeaderIndex: null,
                      editCsuiteLeaderIndex: index,
                      editAreaLeaderIndex: null,
                      editTeamLeaderIndex: null,
                      editTeamMemberIndex: null,
                      csuiteLeadersList: state.data.csuiteLeadersList,
                    })
                  }}
                  onClickDelete={async () => {
                    const result = await Swal.fire({
                      title: t('Common:modalMessages.areYouSureDeleteThis'),
                      icon: 'question',
                      showCancelButton: true,
                      confirmButtonText: t('Common:commonButtons.yes'),
                      cancelButtonText: t('Common:commonButtons.no'),
                    })
                    if (result.isConfirmed) {
                      try {
                        let res = await postHttpRequest(`/delete_employee/${csuite.eId}`)
                        if (res.success) {
                          setRefetchData((prev) => !prev)
                          Toast.fire({
                            icon: 'success',
                            title: t('Common:modalMessages.deletedSuccessfully'),
                          })
                        } else {
                          Toast.fire({
                            icon: 'error',
                            title: t('Common:modalMessages.youCannotDeleteThis'),
                          })
                        }
                      } catch (error) {
                        Toast.fire({
                          icon: 'error',
                          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
                        })
                      }
                    }
                  }}
                  onClickChangeEmployeeState={async () => {
                    const result = await Swal.fire({
                      title: csuite?.isDeactivated
                        ? t('Common:modalMessages.areYouSureReactivate')
                        : t('Common:modalMessages.areYouSureDeactivate'),
                      icon: 'question',
                      showCancelButton: true,
                      confirmButtonText: t('Common:commonButtons.yes'),
                      cancelButtonText: t('Common:commonButtons.no'),
                    })
                    if (result.isConfirmed) {
                      try {
                        await postHttpRequest(`/edit_employee_state/${csuite.eId}`, {
                          isDeactivated: csuite.isDeactivated,
                        })
                        setRefetchData((prev) => !prev)
                        Toast.fire({
                          icon: 'success',
                          title: t('Common:modalMessages.updatedSuccessfully'),
                        })
                      } catch (error) {
                        Toast.fire({
                          icon: 'error',
                          title: t('Common:modalMessages.error'),
                        })
                      }
                    }
                  }}
                  leaderData={csuite}
                  reload={() => {
                    setRefetchData((prev) => !prev)
                  }}
                />
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  )
}
export default CsuiteLeaders

import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import InPageLoader from '../InPageLoader'
import Toast from '../../common/toast'
import AudioToTextModal from '../AudioToTextModal/AudioToTextModal'

const WizardQuestion = ({
  objStatement = null,
  question,
  answer,
  setAnswer,
  placeholder = null,
  hasSaveButton = false,
  saveSetFunction = () => {},
  loadGetFunction = () => {},
  fromCompanyInfo = false,
  isDemo = false,
  setIsDemo = () => {},
}) => {
  const { t } = useTranslation(['Common'])
  const [isLoading, setIsLoading] = useState(false)
  const [isMultiLine, setIsMultiLine] = useState(false)
  const containerRef = useRef(null)
  const textRef = useRef(null)

  useEffect(() => {
    load()
  }, [])
  const [isAudioToTextModalOpen, setIsAudioToTextModalOpen] = React.useState(false)

  useEffect(() => {
    const containerElement = containerRef.current
    const textElement = textRef.current

    if (containerElement && textElement) {
      const resizeObserver = new ResizeObserver(() => {
        const containerWidth = containerElement.clientWidth
        const textHeight = textElement.clientHeight
        const lineHeight = parseInt(window.getComputedStyle(textElement).lineHeight, 10)
        const estimatedLines = textHeight / lineHeight

        console.log('textHeight:', textHeight)
        console.log('lineHeight:', lineHeight)
        console.log('estimatedLines:', estimatedLines)

        setIsMultiLine(estimatedLines > 1)
      })

      resizeObserver.observe(containerElement)
      resizeObserver.observe(textElement)

      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [question])

  const answerTextChangeHandler = (e) => {
    setAnswer(e.target.value)
  }

  async function save() {
    setIsLoading(true)
    let result = await saveSetFunction()
    setIsLoading(false)
    if (result?.success) {
      Toast.fire({
        icon: 'success',
        title: t('Common:modalMessages.updatedSuccessfully'),
      })
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  async function load() {
    setIsLoading(true)
    let result = await loadGetFunction()
    setIsLoading(false)
    if (result) {
      setAnswer(result.companyOverview)
      setIsDemo(result.isDemo)
    }
  }

  const handleIsDemoAccountChange = (e) => {
    setIsDemo(e.target.checked)
  }

  return (
    <div>
      {isAudioToTextModalOpen && (
        <AudioToTextModal
          startRecording={true}
          isModalOpen={isAudioToTextModalOpen}
          type="meetingHubCommentsActions"
          closeModal={() => {
            setIsAudioToTextModalOpen(false)
          }}
          setAudioText={setAnswer}
          answer={answer}
          displayText={question}
        />
      )}
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          className="left-aligned-wizard-step"
          style={{ width: fromCompanyInfo ? '50%' : '100%' }}
        >
          {objStatement && (
            <h2
              className="wizard-step__title"
              style={{
                margin: '1rem 0 1rem 0',
                textAlign: 'left',
                color: 'rgb(91,132,203)',
                maxWidth: '100%',
              }}
            >
              {objStatement}
            </h2>
          )}
          <h2
            className="wizard-step__title"
            ref={containerRef}
            style={{
              margin: '1rem 0',
              textAlign: 'left',
              maxWidth: '100%',
              fontSize: objStatement ? '24px' : undefined,
            }}
          >
            {/* {objStatement && <span>{objStatement}</span>} */}
            <span ref={textRef}>{question}</span>
          </h2>
          <div className="wizard-step__single-answer-container" style={{ padding: '0rem' }}>
            {isLoading ? (
              <InPageLoader inComponent={true} />
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%' }}>
                <textarea
                  rows="10"
                  id="questionAnswer"
                  name="questionAnswer"
                  value={answer}
                  placeholder={
                    placeholder === null ? t('Common:formPlaceHolder.typeHere') : placeholder
                  }
                  onChange={(e) => {
                    answerTextChangeHandler(e)
                  }}
                  style={{
                    resize: 'none',
                    borderRadius: '10px',
                    backgroundColor: '#f1f0f0',
                    fontSize: '14px',
                    width: '80%',
                    borderColor: 'grey',
                    fontFamily: "'Open Sans', 'Arial', 'Helvetica', 'system-ui', sans-serif",
                    padding: '0.5rem',
                    height: '5rem',
                  }}
                />
                <div style={{ width: '85%', marginLeft: '78%' }}>
                  <button
                    type="button"
                    className="btn-card tonedown-blue-btn"
                    style={{ width: '2.5rem' }}
                    onClick={(e) => {
                      setIsAudioToTextModalOpen(true)
                    }}
                  >
                    <span class="material-symbols-outlined icon-size">mic</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {fromCompanyInfo && (
          <div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '6.5rem' }}>
              <input
                type="checkbox"
                id="checkbox1"
                name="checkbox1"
                checked={isDemo}
                onChange={handleIsDemoAccountChange}
              />
              <label htmlFor="checkbox1" style={{ marginLeft: '0.5rem' }}>
                This is a free, demo account
              </label>
            </div>
          </div>
        )}
      </div>
      {hasSaveButton && (
        <div className="sleek-button sleek-full-blue" onClick={save}>
          <span>{t('Common:commonButtons.save')}</span>
        </div>
      )}
    </div>
  )
}

export default WizardQuestion

import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const valildateGoal = async (newGoalStatement) => {
  const { data } = await axios.post(
    `${BASE_URL}/validate_goal?text=${encodeURIComponent(newGoalStatement)}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  )

  return data
}

export default function useValidateGoal() {
  return useMutation((newGoalStatement) => valildateGoal(newGoalStatement))
}

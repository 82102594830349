import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getObjectivesMeetingTopics = async (objectiveIds) => {
  const { data } = await axios.post(
    `${BASE_URL}/get_meeting_topics_for_objectives`,
    { obj_ids: objectiveIds },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  )

  return JSON.stringify(data) === '{}' ? [] : data
}

export default function useGetObjectivesMeetingTopics(objectiveIds = []) {
  return useQuery(
    ['objective-blockers', objectiveIds],
    () => getObjectivesMeetingTopics(objectiveIds),
    {
      refetchOnWindowFocus: false,
      enabled: objectiveIds.length > 0,
    },
  )
}

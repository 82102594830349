import React, { useEffect, useState } from 'react'
import Modal from '../Modal'
import 'gantt-task-react/dist/index.css'
import { getGanttChartDataV2, getCustomGantt } from '../../api/services/objectives.services'
import './style.css'
import { useTranslation } from 'react-i18next'
import GanttChart from './ganttv2'
import InPageLoader from '../InPageLoader'

const GanttChartModal = ({
  isModalOpen,
  closeModal,
  objId = null,
  eId = null,
  l2ObjId = null,
  isCustomGantt = false,
  isFromSummaryOfObjectives = false, // This is a flag to determine if the Gantt is
  //being used in the Summary of Objectives page so for productSetting company for
  //levels 1 and 2 people we show any l3 objectives they own + their reportees' objectives
}) => {
  const [ganttChartData, setGanttChartData] = useState(null)

  const [objectivesList, setObjectivesList] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [showGanttChart, setShowGanttChart] = useState(false)
  const { t } = useTranslation(['Common'])

  useEffect(() => {
    if (isCustomGantt === false) {
      getGanttData()
    } else if (isCustomGantt === true) {
      getCustomGanttData()
    }
  }, [])

  async function getGanttData() {
    setIsLoading(true)
    const result = await getGanttChartDataV2(objId, eId, l2ObjId, isFromSummaryOfObjectives)

    setIsLoading(false)
    setShowGanttChart(true)
    setGanttChartData(result?.ganttList)
  }

  async function getCustomGanttData() {
    setIsLoading(true)
    const result = await getCustomGantt(objId)

    setIsLoading(false)
    setShowGanttChart(true)
    setGanttChartData(result?.ganttList)
  }

  return (
    <div className="gantt-modal">
      <Modal
        title={t('ganttChartModal.timelineView')}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        width={'100%'}
        height={'300rem'}
        titleStyle={{ marginRight: 'auto', color: '#5780ca' }}
      >
        {isLoading && <InPageLoader inComponent={true} />}

        {!isLoading && (
          <GanttChart data={ganttChartData} isFromSummaryOfObjectives={isFromSummaryOfObjectives} />
        )}
      </Modal>
    </div>
  )
}
export default GanttChartModal

import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const generateMultipleObjectives = async (request) => {
  const { data } = await axios.post(
    `${BASE_URL}/generate_multiple_objectives`,
    request,
    { timeout: 15000 },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )

  return data
}

export default function useGenerateMultipleObjectives() {
  return useMutation(['suggested-objectives'], (request) => generateMultipleObjectives(request), {
    refetchOnWindowFocus: false,
    retry: 1,
    onSuccess: (response) => {
      return response
    },
    onError: (response) => {
      return response
    },
  })
}

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getTeamLeadersList = async (excludeSelf) => {
  const queryString = excludeSelf ? '/all_team_leaders?mode=1' : '/all_team_leaders'
  const { data } = await axios.get(`${BASE_URL}${queryString}`)
  return data
}

export default function useTeamLeadersList(excludeSelf = false) {
  return useQuery(['team-leaders-list'], () => getTeamLeadersList(excludeSelf), {
    refetchOnWindowFocus: false,
  })
}

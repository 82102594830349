import React, { useEffect } from 'react'
import '../../../MeetingView/components/ScoreCard/styles.css'
import { BarChart, Bar, Cell, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts'
import { useTranslation } from 'react-i18next'
import { PendingActivitiesModal } from '../../../EmailReminderTab'

const BGUScoreCard = ({
  getLoadingStateFromChildComponent,
  actionChartData,
  setIsActionModalOpen,
  setIsActivityModalOpen,
  setIsTopicModalOpen,
  setSelectedBar,
  refreshBGUScorecardFn = () => {},
  setIsOpen = () => {},
  setActivitiesDuesCategory = () => {},
  visibility,
  loadAgain = () => {},
}) => {
  const { t } = useTranslation(['MeetingHub'])
  const [actionOpens, setActionOpens] = React.useState([])
  const [activitiesDue, setActivitiesDue] = React.useState([])
  const [topicAge, setTopicAge] = React.useState([])
  const [isEmailReminderModalOpen, setIsEmailReminderModalOpen] = React.useState(false)
  // const [isListModalOpen, setIsListModalOpen] = React.useState(false)
  // const [selectedData, setSelectedData] = React.useState(null)

  const colors = ['#ED522E', '#FFD700', '#0926D5', '#999999']
  const topicColors = ['#ED522E', '#0926D5', '#999999', '#9933FF']
  const actionsColors = ['#ED522E', '#0926D5']

  const maps = {
    1: t('scorecard.thisWeek'),
    2: t('scorecard.lastWeek'),
    3: t('scorecard.2weeks'),
    4: t('scorecard.3weeks'),
    5: t('scorecard.3weeks') + '+',
  }

  React.useEffect(() => {
    if (actionChartData) {
      let openActions = actionChartData?.actions?.filter((action) => action.isOpen == 1)
      let closedActions = actionChartData?.actions?.filter((action) => action.isOpen == 0)

      setActionOpens([
        {
          actions: openActions?.length ?? 0,
          name: t('scorecard.open'),
          opencloses: openActions,
          isOpen: true,
        },
        {
          actions: closedActions?.length ?? 0,
          name: t('scorecard.closed'),
          opencloses: closedActions,
          isOpen: false,
        },
      ])

      let lessToday = actionChartData?.milestoneObj?.filter((milestone) => milestone.category == 0)
      let lessTodayAWeek = actionChartData?.milestoneObj?.filter(
        (milestone) => milestone.category == 1,
      )
      let moreThanAWeek = actionChartData?.milestoneObj?.filter(
        (milestone) => milestone.category == 3,
      )
      let nullDue = actionChartData?.milestoneObj?.filter((milestone) => milestone.category == 2)

      setActivitiesDue([
        {
          numDue: lessToday?.length ?? 0,
          name: t('scorecard.overdue'),
          dues: lessToday,
          category: 0,
        },
        {
          numDue: lessTodayAWeek?.length ?? 0,
          name: t('scorecard.dueNextWeek'),
          dues: lessTodayAWeek,
          category: 1,
        },
        {
          numDue: moreThanAWeek?.length ?? 0,
          name: t('scorecard.dueLater'),
          dues: moreThanAWeek,
          category: 3,
        },
        {
          numDue: nullDue?.length ?? 0,
          name: t('scorecard.noDueDate'),
          dues: nullDue,
          category: 2,
        },
      ])
      const res = actionChartData?.topicsWeek

      setTopicAge(
        res
          .filter((r) => sumValues(r) > 0)
          ?.map((r) => ({
            value: r,
            name: maps[r.category],
            total: sumValues(r),
            category: r.category,
          })),
      )
    }
  }, [actionChartData])

  const sumValues = (obj) =>
    Object.keys(obj).reduce(
      (sum, key) =>
        key !== 'category' &&
        key !== 'blockedObjectives' &&
        key !== 'decisionObjectives' &&
        key !== 'updateObjectives' &&
        key !== 'adminTopicObjectives'
          ? sum + obj[key]
          : sum,
      0,
    )

  const handleTopicAgeClick = (data) => {
    setIsTopicModalOpen(true)
    setSelectedBar(data?.payload)
  }

  const handleOpenCloseActionsClick = (data, isOpen) => {
    setIsActionModalOpen(true)
    setSelectedBar(data)
    setIsOpen(isOpen)
  }

  const handleActivitiesDuesClick = (data, category) => {
    setIsActivityModalOpen(true)
    setSelectedBar(data)
    setActivitiesDuesCategory(category)
  }

  return (
    <>
      {isEmailReminderModalOpen && (
        <PendingActivitiesModal
          isEmailReminderModalOpen={isEmailReminderModalOpen}
          closeModal={() => {
            setIsEmailReminderModalOpen(false)
          }}
          refreshBGUScorecardFn={refreshBGUScorecardFn}
          loadAgain={loadAgain}
        />
      )}
      <div className="scorecard-main-container">
        <div className="scorecard-container">
          <h3 className="scorecard-title" style={{ visibility: visibility }}>
            {t('scorecard.titleBGU')}
          </h3>

          <h3
            style={{
              fontSize: '14px',
              width: 'fit-content',
              paddingbottom: '0.5rem',
              textAlign: 'left',
              visibility: visibility,
            }}
          >
            {t('scorecard.topicAge')}
          </h3>
          {topicAge.length > 0 ? (
            <>
              <ResponsiveContainer height={200} width={'100%'}>
                <BarChart data={topicAge} layout="vertical" margin={{ left: 15 }}>
                  <XAxis hide axisLine={false} type="number" domain={100} />
                  <YAxis
                    orientation="left"
                    yAxisId="left"
                    dataKey="name"
                    type="category"
                    axisLine={false}
                    tickLine={false}
                    width={70}
                    tick={{ fontSize: 15 }}
                  />
                  <YAxis
                    orientation="right"
                    yAxisId="right"
                    dataKey="total"
                    type="category"
                    axisLine={false}
                    tickLine={false}
                  />

                  <Tooltip cursor={{ fill: 'transparent' }} />
                  <Bar
                    yAxisId="left"
                    maxBarSize={30}
                    name="Blockers"
                    dataKey="value.numberBlockers"
                    onClick={(data) => handleTopicAgeClick(data)}
                    fill={topicColors[0]}
                    stackId={0}
                  />
                  <Bar
                    yAxisId="left"
                    maxBarSize={30}
                    name="Guidances"
                    dataKey="value.numberGuidances"
                    onClick={(data) => handleTopicAgeClick(data)}
                    fill={topicColors[1]}
                    stackId={0}
                  />
                  <Bar
                    yAxisId="left"
                    maxBarSize={30}
                    name="Updates"
                    dataKey="value.numberUpdates"
                    onClick={(data) => handleTopicAgeClick(data)}
                    fill={topicColors[2]}
                    stackId={0}
                  />
                  <Bar
                    yAxisId="left"
                    maxBarSize={30}
                    name="Admin"
                    dataKey="value.numberAdminTopics"
                    onClick={(data) => handleTopicAgeClick(data)}
                    fill={topicColors[3]}
                    stackId={0}
                  />
                </BarChart>
              </ResponsiveContainer>
            </>
          ) : (
            <span> {t('scorecard.noTopicsMessage')} </span>
          )}
          <div className="divider" style={{ width: '92%' }}></div>

          <div style={{ visibility: visibility }}>
            <div
              style={{
                color: '#0926D5',
                textDecoration: 'underline',
              }}
            >
              <span
                style={{ cursor: 'pointer', fontSize: '14px' }}
                onClick={() => {
                  setIsEmailReminderModalOpen(true)
                }}
                onKeyDown={() => {
                  setIsEmailReminderModalOpen(true)
                }}
              >
                {t('scorecard.seeDetailsHere')}
              </span>
            </div>
            <h3
              style={{
                fontSize: '14px',
                width: 'fit-content',
                paddingbottom: '0.5rem',
                textAlign: 'left',
              }}
            >
              {t('scorecard.topicAction')}
            </h3>
          </div>

          {actionOpens.length > 0 ? (
            <>
              <ResponsiveContainer height={110} width={'100%'}>
                <BarChart data={actionOpens} layout="vertical" margin={{ left: 15 }}>
                  <XAxis hide axisLine={false} type="number" />
                  <YAxis
                    orientation="left"
                    yAxisId="left"
                    dataKey="name"
                    type="category"
                    axisLine={false}
                    tickLine={false}
                    tick={{ fontSize: 15 }}
                  />
                  <YAxis
                    orientation="right"
                    yAxisId="right"
                    dataKey="actions"
                    type="category"
                    axisLine={false}
                    tickLine={false}
                  />
                  <Tooltip cursor={{ fill: 'transparent' }} />
                  <Bar name="Actions" dataKey="actions" maxBarSize={30} yAxisId="left">
                    {actionOpens.map((d, idx) => {
                      return (
                        <Cell
                          key={d.name}
                          fill={actionsColors[idx]}
                          onClick={() => handleOpenCloseActionsClick(d.opencloses, d.isOpen)}
                        />
                      )
                    })}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </>
          ) : (
            <span> {t('scorecard.noTopicsMessage')} </span>
          )}
          <div
            style={{ width: '95%', textAlign: 'left', marginTop: '2rem', wordBreak: 'break-word' }}
          >
            <div style={{ fontSize: '12px', width: 'fit-content', paddingbottom: '0.5rem' }}>
              {t('scorecard.secondChartNote')}
            </div>
          </div>
          {/* <div className="divider" style={{marginTop:'0.3rem', width: '92%'}}></div>

            <h3 style={{ fontSize: '14px', width: 'fit-content', paddingbottom: '0.5rem', textAlign:'left' }}>
            {t('scorecard.openActivities')}
            </h3>

            {activitiesDue.length>0?
                <>
                    <ResponsiveContainer height={150} width={'100%'}>
                    <BarChart
                            data={activitiesDue}
                            layout="vertical"
                            margin={{ left: 15}}
                        >
                            <XAxis hide axisLine={false} type="number" />
                            <YAxis
                            orientation='left'
                            yAxisId="left"
                            dataKey="name"
                            type="category"
                            axisLine={false}
                            tickLine={false}
                            tick={{fontSize: 15}}
                            />
                            <YAxis
                            orientation="right"
                            yAxisId="right"
                            dataKey="numDue"
                            type="category"
                            axisLine={false}
                            tickLine={false}
                            />
                            <Tooltip cursor={{fill: 'transparent'}}/>
                            <Bar name='Activities' dataKey="numDue" maxBarSize={25} yAxisId="left">
                                {activitiesDue.map((d, idx) => {
                                    return <Cell key={d.name} fill={colors[idx]} onClick={() => handleActivitiesDuesClick(d.dues, d.category)}/>;
                                })}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </> :  <span> {t('scorecard.noTopicsMessage')} </span>
            }
            <div style={{width:'95%',  textAlign:'left', marginTop:'2rem', wordBreak:'break-word'}}>
                <div style={{ fontSize: '12px', width: 'fit-content', paddingbottom: '0.5rem' }}>
                {t('scorecard.thirdChartNote')}
                </div>
            </div> */}
        </div>
      </div>
    </>
  )
}

export default BGUScoreCard

import { useQuery } from '@tanstack/react-query'
import { getHttpRequest } from './dynamicAPI'
import { GET_KANBAN_BOARD_OBJECTIVE_DATA } from '../../api/constant/route'

const getObjectiveData = async (teamLeaderId) => {
  const queryString =
    teamLeaderId === null
      ? GET_KANBAN_BOARD_OBJECTIVE_DATA
      : `${GET_KANBAN_BOARD_OBJECTIVE_DATA}/${teamLeaderId}`

  const data = await getHttpRequest(queryString)
  return data
}

export default function useObjectiveDataList(teamLeaderId = null) {
  return useQuery(['objectives', teamLeaderId], () => getObjectiveData(teamLeaderId), {
    refetchOnWindowFocus: false,
  })
}

import React from 'react'
import next from '../../../../assets/next-white.svg'
import SubmitSetupWizard from './components/SubmitTeamLeaderWizard'
import './styles.css'
import { useSetupWizardContext } from '../../context/setupWizardContext'

const NextPreviousActions = ({
  isLastStep,
  currentStep,
  handleNext,
  handlePrevious,
  errorMessage,
  setErrorMessage,
}) => {
  const { state, dispatch } = useSetupWizardContext()

  const areaLeads = state.data.areaLeader
  const teamLeads = state.data.teamLeader
  const invidualContributors = state.data.individualContributor

  let lackingAreaLeader
  let missingTeamLeaders
  //let missingContributors

  if (window.location.pathname === '/SetupWizard/AreaLeader') {
    if (areaLeads && areaLeads.length === 0) {
      lackingAreaLeader = true
    }
  }

  if (window.location.pathname === '/SetupWizard/TeamLeader' && areaLeads && teamLeads) {
    if (areaLeads.length === 0) {
      missingTeamLeaders = false
    } else if (teamLeads.length === 0) {
      missingTeamLeaders = true
    } else {
      for (const areaLead of areaLeads) {
        if (!teamLeads.find((teamLead) => teamLead.managerEmail === areaLead.email)) {
          missingTeamLeaders = true
          break
        }
      }
    }
  } else if (
    window.location.pathname === '/SetupWizard/IndividualContributor' &&
    areaLeads &&
    teamLeads &&
    invidualContributors
  ) {
    if (areaLeads.length === 0 || teamLeads.length === 0) {
      // missingContributors = false
    } else if (invidualContributors.length === 0) {
      //missingContributors = true
    } else {
      for (const teamLead of teamLeads) {
        if (
          !invidualContributors.find((contributor) => contributor.managerEmail === teamLead.email)
        ) {
          // missingContributors = true
          break
        }
      }
    }
  }

  return (
    <div className="buttons-area-container">
      <div className="error-container">
        {errorMessage ? <div className="validation-error">{errorMessage}</div> : null}
      </div>
      <div className="dual-button-container">
        {currentStep === 0 ? (
          <button onClick={() => handleNext()} className="start-button big-btn">
            Click To Begin
          </button>
        ) : (
          <>
            <button
              className="dual-previous-btn dual-btn"
              onClick={() => {
                handlePrevious()
              }}
            >
              <img
                className="nav-arrow previous"
                src={next}
                alt="Arrow indication a navigation backwards."
              />
              <span>BACK</span>
            </button>
            {isLastStep ? (
              <SubmitSetupWizard currentStep={currentStep} setErrorMessage={setErrorMessage} />
            ) : (
              <button
                className={`dual-next-btn dual-btn${
                  state.data.enterpriseLeader.length === 0 ||
                  lackingAreaLeader ||
                  missingTeamLeaders
                    ? // missingContributors
                      ' disabled-next-btn'
                    : ''
                }`}
                onClick={() => {
                  if (
                    state.data.enterpriseLeader.length === 0 ||
                    lackingAreaLeader ||
                    missingTeamLeaders
                    // missingContributors
                  ) {
                    return
                  }
                  handleNext()
                }}
              >
                <span>NEXT</span>
                {state.data.enterpriseLeader.length === 0 ? (
                  <div id="enterprise-leader-warning-message" className="dual-btn-warning-message">
                    <b>You must have one Enterprise Leader to proceed</b>
                  </div>
                ) : (
                  ''
                )}

                {lackingAreaLeader ? (
                  <div id="area-leader-warning-message" className="dual-btn-warning-message">
                    <b>You must have one Area Leader to proceed</b>
                  </div>
                ) : (
                  ''
                )}

                {missingTeamLeaders ? (
                  <div id="team-leader-warning-message" className="dual-btn-warning-message">
                    <b>Each Area Leader must have a Team Leader assigned to proceed</b>
                  </div>
                ) : (
                  ''
                )}

                {/* {missingContributors ? (
                  <div id="contributor-warning-message" className="dual-btn-warning-message">
                    <b>Each Team Leader must have a Contributor assigned to proceed</b>
                  </div>
                ) : (
                  ''
                )} */}

                <img
                  className="nav-arrow next"
                  src={next}
                  alt="Arrow indication a navigation forwards."
                />
              </button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default NextPreviousActions

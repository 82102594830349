import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getEmployeesObjective = async (objId) => {
  const queryString = `/get_employees_obj/${objId}`

  const { data } = await axios.post(`${BASE_URL}${queryString}`, {})
  return data
}

export default function useGetEmployeesObjective(objId) {
  return useQuery(['employees_objective', objId], () => getEmployeesObjective(objId), {
    refetchOnWindowFocus: false,
    enabled: Boolean(objId),
  })
}

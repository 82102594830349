import React from 'react'
import useSubmitTeamLeaderWizard from '../../../../../../api/mutation/useSubmitTeamLeaderWizard'
import { useTeamLeaderContext } from '../../../../context/teamLeaderContext'
import '../../styles.css'
import { useNavigate } from 'react-router-dom'
import WizardButton from '../../../../../../components/WizardButton'
import { redirectToHome } from '../../../../../../utils/auth'

const SubmitTeamLeaderWizard = ({ currentStep, setErrorMessage }) => {
  const { state } = useTeamLeaderContext()
  const mutationSubmitTLWizard = useSubmitTeamLeaderWizard()
  const navigate = useNavigate()

  const [submitClicked, setSubmitClicked] = React.useState(false)

  const handleSubmit = async () => {
    if (submitClicked === true) {
      return
    }

    const employeeIndexLocalId = state.data.employees
      .filter((emp) => emp.isDirectReport !== true)
      .map((e, i) => e.localeId ?? i)
    const objIndexLocalId = state.data.objectives.map((o, i) => o.localeId ?? i)

    let employeesObjAlloc = []
    for (let obj of state.data.objectives) {
      if (!obj.team) {
        continue
      }

      for (let member of obj.team) {
        employeesObjAlloc.push({
          eId: member.eId,
          objId: obj.id,
          allocPct: member.value,
        })
      }
    }

    let formData = {
      employees: state.data.employees,
      externalEmpAlocs: state.data.externalEmpAlocs,
      objectives: state.data.objectives,
      employeeIndexLocalId: employeeIndexLocalId,
      objIndexLocalId: objIndexLocalId,
      employeesObjAlloc: employeesObjAlloc,
    }

    await mutationSubmitTLWizard.mutateAsync(formData, {
      onSuccess: () => {
        //TBD Swetha : Remove local-storage when not required
        // localStorage.removeItem('tlw-data')
        // localStorage.removeItem('tlw-step')
        localStorage.setItem('tfwert', '1')
        redirectToHome()
      },
      onError: () => {
        // TODO(ali): see why we get a javascript error even when the 500 error
        // is caught here.
        // TODO(cecilia): Replace this with in-app error.
        alert('There was an error submitting the wizard.')
      },
    })
  }

  return (
    <WizardButton
      isLoading={mutationSubmitTLWizard.isLoading}
      onClick={() => {
        setSubmitClicked(true)
        handleSubmit()
      }}
    />
  )
}

export default SubmitTeamLeaderWizard

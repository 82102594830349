import React from 'react'
import { colors } from '../../utils/colors'
import { StyledButton } from './styles'

const Button = ({
  children,
  onClick,
  disabled,
  color = colors.orange, // default orange
  type = 'button',
  borderRadius = '0.5rem',
  width = 'fit-content',
  fontSize = '1rem',
  padding = '0.5rem',
}) => {
  const whiteTextColors = [colors.blue, colors.red, colors.orange, colors.green]

  return (
    <StyledButton
      onClick={onClick}
      type={type}
      backgroundColor={color}
      borderRadius={borderRadius}
      width={width}
      fontSize={fontSize}
      padding={padding}
      disabled={disabled}
      textColor={whiteTextColors.includes(color) ? colors.white : colors.black}
    >
      {children}
    </StyledButton>
  )
}

export default Button

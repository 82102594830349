import { useState } from 'react'
import { Container } from '../task/style'
import { Draggable } from 'react-beautiful-dnd'
import { colors } from '../../../../utils/colors'

import EditTask from '../../../createTask/index'

import SubActivity from '../../../SubActivities/index'

import '../task/style.scss'
import { dayMonthFilter } from '../../../../utils/time'

import CommentsModal2 from '../../../../components/CommentsModal2'
import { postHttpRequest } from '../../../../api/query/dynamicAPI'
import {
  POST_KANBAN_MARK_ACTIVITY_AS_PRIORITY,
  POST_KANBAN_BOARD_CHANGE_ACTIVITY_STATUS,
} from '../../../../api/constant/route'
import { useTranslation } from 'react-i18next'

import useEditKanbanBoardChangeActivityStatus from '../../../../api/mutation/useEditKanbanBoardChangeActivityStatus'
import CardModal from '../../../../components/CardModal'

function TaskStatic(props) {
  const { t } = useTranslation(['Kanban'])
  const [isCreateModel, setIsCreateModel] = useState(false)
  const [subActivites, setSubActivites] = useState(false)
  const [isCardModalOpen, setIsCardModalOpen] = useState(false)

  let assigneeList = props?.task?.assigneeList
  let displayAssigneeList = ''
  if (assigneeList != undefined && assigneeList.length > 0) {
    displayAssigneeList = assigneeList
      .map((e) => {
        return e.name.match(/\b(\w)/g).join('')
      })
      .join(',')
  }

  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false)

  const openCommentsModal = () => {
    setIsCommentsModalOpen(true)
  }

  const closeCommentsModal = () => {
    setIsCommentsModalOpen(false)
  }

  async function markActicityAsPriority(value, milestoneId) {
    const postObject = {
      milestoneId: milestoneId,
      newStatus: value,
    }
    postHttpRequest(POST_KANBAN_MARK_ACTIVITY_AS_PRIORITY, postObject)
      .then((response) => {
        if (response) {
          props.setIsRefreshObjective((old) => !old)
          return
        } else {
        }
      })
      .catch((error) => {})
  }

  const changeActivityStatusMutation = useEditKanbanBoardChangeActivityStatus()

  const changeActivityStatus = async (new_status) => {
    const postObject = {
      objectiveId: props.task.objectiveId,
      milestoneId: props.task.milestoneId,
      newStatus: new_status,
    }
    await changeActivityStatusMutation.mutateAsync(postObject, {
      onSuccess: (response) => {
        props.setIsRefreshObjective((old) => !old)
      },
      onError: () => {},
    })
  }

  const canBeDisabledNoSubactivity = props.task.totalSubactivityCount > 0 ? '' : 'inactive-blue'

  const canBeDisabledNoComments = props.task.totalCommentsCount > 0 ? '' : 'inactive-blue'

  const shouldApplyStyle = props.task.umtId !== 0

  const topicColors = ['#ED522E', '#0926D5', 'rgb(127, 131, 155)', '#9933FF']
  const topicType = ['Blocked', 'Guidance Needed', 'Update', 'Admin']
  const containerStyle = shouldApplyStyle
    ? { backgroundColor: topicColors[props.task.topicType - 1] }
    : {}

  return (
    <>
      <Container>
        <div className="task-inner-container">
          <div>
            {/* {!props.task.isPriority && (
              <button
                class="icon-button-no-border"
                style={{ display: 'block', marginLeft: 'auto' }}
              >
                <span
                  class="material-symbols-outlined icon-size"
                  onClick={() => {
                    markActicityAsPriority(true, props.task.milestoneId)
                  }}
                >
                  flag
                </span>
              </button>
            )} */}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              {props.task.umtId !== 0 ? (
                <div
                  className="action-box tooltip"
                  style={containerStyle}
                  onClick={() => {
                    setIsCardModalOpen(true)
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {topicType[props.task.topicType - 1]}
                    <span class="tooltiptext bgu_kanban_desc" style={{ left: '90%' }}>
                      {' '}
                      {props.task.meetingTopicDescription}{' '}
                    </span>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            {props.task.isPriority && (
              <button
                class="icon-button-no-border"
                style={{ display: 'block', marginLeft: 'auto' }}
              >
                <span
                  class="material-symbols-outlined icon-size"
                  style={{ color: 'red' }}
                  onClick={() => {
                    markActicityAsPriority(false, props.task.milestoneId)
                  }}
                >
                  flag
                </span>
              </button>
            )}
            <p style={{ marginBottom: '10px', color: 'black' }}>{props.task.description}</p>
          </div>
          <div
            className="task-action-container"
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: '0.5rem',
              columnGap: '0.5rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                columnGap: '0.5rem',
                justifyContent: 'space-between',
              }}
            >
              <span
                style={{
                  textAlign: 'center',
                  color: colors.blue,
                  cursor: 'pointer',
                  alignSelf: 'end',
                  fontSize: '14px',
                }}
                onClick={() => {
                  changeActivityStatus('ON_TRACK')
                }}
              >
                {t('reopen')}
              </span>
              <div style={{ display: 'flex', flexDirection: 'row', columnGap: '0.5rem' }}>
                <span>
                  {props.task.dueDate === 0
                    ? ''
                    : `${t('due')}: ${dayMonthFilter(props.task.dueDate)}`}
                </span>
                <span>{`${t('closed')}: ${dayMonthFilter(props.task.completedTs)}`}</span>
              </div>
            </div>
          </div>
        </div>
        {isCreateModel && (
          <EditTask
            objectiveId={props.objectiveId}
            view={isCreateModel}
            setModelView={setIsCreateModel}
            setIsRefreshObjective={props.setIsRefreshObjective}
            taskId={props.task.milestoneId.toString()}
          />
        )}

        {subActivites && (
          <SubActivity
            objectiveId={props.objectiveId}
            task={props.task}
            view={subActivites}
            setModelView={setSubActivites}
            setIsRefreshObjective={props.setIsRefreshObjective}
            taskId={props.task.milestoneId.toString()}
          />
        )}

        {isCommentsModalOpen && (
          <CommentsModal2
            objective={{ id: props.objectiveId }}
            taskId={props.task.milestoneId}
            handleCancel={closeCommentsModal}
            setIsRefreshObjective={props.setIsRefreshObjective}
          />
        )}

        {isCardModalOpen && (
          <CardModal
            isModalOpen={isCardModalOpen}
            closeModal={() => {
              setIsCardModalOpen(false)
            }}
            objectiveId={props.objectiveId}
            topicType={props.task.topicType}
            umtId={props.task.umtId}
          />
        )}
      </Container>
    </>
  )
}

export default TaskStatic

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

// reportingTo (optional): the ID of the team leader. If null, the signed in user's ID is used.
const getTeamAllocationData = async (tlId) => {
  const queryString = tlId === null ? '/employees_alloc_t' : `/employees_alloc_t/${tlId}`

  const { data } = await axios.get(`${BASE_URL}${queryString}`)
  return data
}

export default function useTeamAllocationData(tlId = null) {
  return useQuery(['team-allocation', tlId], () => getTeamAllocationData(tlId), {
    refetchOnWindowFocus: false,
  })
}

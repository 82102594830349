import React from 'react'
import './styles.css'
import { useSetupWizardContext } from '../../../../context/setupWizardContext'
import AddEnterpriseLeaderModalForm from './components/AddEnterpriseLeaderModalForm'
import Modal from '../../../../../../components/Modal'

const AddEnterpriseLeaderModal = () => {
  const { state, dispatch } = useSetupWizardContext()

  const handleCancel = () => {
    dispatch({ type: 'CLOSE_EMPLOYEE_MODAL' })
    resetForm()
  }

  const [resetForm, setResetForm] = React.useState(null)

  return (
    <>
      {state.isEmployeeModalOpen === true && (
        <Modal title={'Add Top Team Member'} closeModal={handleCancel}>
          <AddEnterpriseLeaderModalForm handleCancel={handleCancel} setResetForm={setResetForm} />
        </Modal>
      )}
    </>
  )
}

export default AddEnterpriseLeaderModal

import { getHttpRequest, postHttpRequest } from '../query/dynamicAPI'
import Toast from '../../common/toast'

export async function uploadFileFilingCabinet(formData) {
  try {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }

    const data = await postHttpRequest(`/upload_file_filing_cabinet`, formData, config)

    return data
  } catch (e) {
    return e.response
  }
}

export async function getFilesFilingCabinetByObjId(formData) {
  try {
    const data = await postHttpRequest('/get_filing_cabinet_obj_id', formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      //title: API_ERROR_MESSAGE,
    })
  }
}

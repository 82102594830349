import React, { useState, useEffect, useRef } from 'react'

const AudioWaveVisualizer = ({ audioStream }) => {
  const [audioData, setAudioData] = useState(new Array(32).fill(0))
  const audioContextRef = useRef(null)
  const analyzerRef = useRef(null)
  const rafRef = useRef(null)
  const previousDataRef = useRef(new Array(32).fill(0))

  useEffect(() => {
    if (!audioStream) return

    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)()
    analyzerRef.current = audioContextRef.current.createAnalyser()
    analyzerRef.current.fftSize = 2048 // Increased for even better frequency resolution

    const source = audioContextRef.current.createMediaStreamSource(audioStream)
    source.connect(analyzerRef.current)

    const bufferLength = analyzerRef.current.frequencyBinCount
    const dataArray = new Uint8Array(bufferLength)

    let lastUpdateTime = 0
    const updateInterval = 50 // Update every 50ms

    const updateAudioData = (timestamp) => {
      if (timestamp - lastUpdateTime > updateInterval) {
        analyzerRef.current.getByteFrequencyData(dataArray)

        const normalizedData = normalizeData(dataArray)
        const compressedData = compressData(normalizedData)
        const smoothedData = smoothData(compressedData, previousDataRef.current)

        setAudioData(smoothedData)
        previousDataRef.current = smoothedData
        lastUpdateTime = timestamp
      }
      rafRef.current = requestAnimationFrame(updateAudioData)
    }

    rafRef.current = requestAnimationFrame(updateAudioData)

    return () => {
      cancelAnimationFrame(rafRef.current)
      if (audioContextRef.current.state !== 'closed') {
        audioContextRef.current.close()
      }
    }
  }, [audioStream])

  // Normalize the frequency data with frequency-dependent scaling
  const normalizeData = (data) => {
    const numberOfBars = 32
    const normalizedData = new Array(numberOfBars).fill(0)
    const bucketSize = Math.floor(data.length / numberOfBars)

    for (let i = 0; i < numberOfBars; i++) {
      let sum = 0
      for (let j = 0; j < bucketSize; j++) {
        const index = i * bucketSize + j
        // Apply frequency-dependent scaling
        const scaleFactor = 1 + (index / data.length) * 2 // Higher frequencies get boosted more
        sum += data[index] * scaleFactor
      }
      // Apply a more aggressive logarithmic scale
      normalizedData[i] = (Math.log(sum / bucketSize + 1) / Math.log(50)) * 255
    }

    return normalizedData
  }

  // Apply dynamic range compression
  const compressData = (data) => {
    const threshold = 100 // Compression threshold
    const ratio = 4 // Compression ratio

    return data.map((value) => {
      if (value > threshold) {
        return threshold + (value - threshold) / ratio
      }
      return value
    })
  }

  // Smooth the transitions between frames
  const smoothData = (newData, oldData) => {
    return newData.map((value, index) => {
      return oldData[index] * 0.6 + value * 0.4
    })
  }

  return (
    <div className="visualizer-container">
      {audioData.map((value, index) => (
        <div
          key={index}
          className="visualizer-bar"
          style={{ transform: `scaleY(${value / 255})` }}
        ></div>
      ))}
      <style jsx>{`
        .visualizer-container {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          height: 50px;
          width: 24rem;
          //background-color: #f0f0f0;
          background-color: white;
          border: 1px solid grey;
          padding: 0;
          //border-radius: 25px;
          overflow: hidden;
          height: 5rem;
        }
        .visualizer-bar {
          flex: 1;
          height: 100%;
          margin: 0 1px;
          background-color: #3b82f6;
          transform-origin: bottom;
          transition: transform 0.1s ease;
        }
      `}</style>
    </div>
  )
}

export default AudioWaveVisualizer

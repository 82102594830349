import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const updateWizardDeleteActivity = async (activityData) => {
  const { data } = await axios.post(`${BASE_URL}/update_wizard_delete_activity`, activityData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export default function useUpdateWizardDeleteActivity() {
  const queryClient = useQueryClient()

  return useMutation((data) => updateWizardDeleteActivity(data), {
    onSuccess: () => {
      void queryClient.invalidateQueries('activities-spreadsheet-data')
    },
  })
}

import React, { useState } from 'react'
import { useExcoExerciseContext } from '../../context/ExcoExerciseContext'
import { Outlet } from 'react-router-dom'
import ProgressBar from '../../../../components/ProgressBar'
import NextPreviousActions from '../../../../components/NextPreviousActions'
import useExcoExerciseWizardSteps, {
  stepsLabelsGenerator,
} from '../../hooks/useExcoExerciseWizardSteps'
import { postHttpRequest, getHttpRequest } from '../../../../api/query/dynamicAPI'

const AIExerciseWizard = ({ existingStep }) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const { state, dispatch } = useExcoExerciseContext()
  const { currentStepIndex, steps, handleNext, handlePrevious, wArray, isLastStep } =
    useExcoExerciseWizardSteps(existingStep)

  const stepsLabels = stepsLabelsGenerator()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async () => {
    // await mutationUpdateWizardSubmit.mutateAsync(responseObj, {
    //   onSuccess: () => {
    //     localStorage.setItem('tfuwtl', '1');
    //     redirectToHome()
    //   },
    //   onError: () => {
    //     alert('There was an error submitting the wizard.')
    //     redirectToHome()
    //   },
    // })
  }

  const submitAnswer = async (
    questionNumber,
    gptVersion,
    userMessage,
    setResultPasses,
    setResultText,
  ) => {
    setIsLoading(true)
    let params = {
      questionNumber: questionNumber,
      gptVersion: gptVersion,
      userMessage: userMessage,
    }
    const response = await getHttpRequest('/ai_workshop_submit_answer', { params: params })
    setIsLoading(false)
    if (response) {
      if (response === false) {
      } else {
        setResultPasses(response.answerPass)
        setResultText(response.answer)
        dispatch({
          type: 'SAVE_GPT_VERSION',
          questionNumber: questionNumber,
          gptVersion: gptVersion,
        })
      }
    }
    setIsLoading(false)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '2rem' }}>
      <ProgressBar
        currentStep={currentStepIndex}
        steps={wArray.filter((value) => value === true)}
      />
      <Outlet
        context={{
          handleNext,
          handleSubmit,
          isLastStep,
          submitAnswer,
          isLoading,
        }}
      />
    </div>
  )
}

export default AIExerciseWizard

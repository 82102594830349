import React from 'react'
import './styles.css'
import { useSetupWizardContext } from '../../../../../../context/setupWizardContext'

const DefinedTable = () => {
  const { state, dispatch } = useSetupWizardContext()

  const industryWideMeasures = state.data.industryWideMeasures

  //   top-level objectives at top
  const sortedMeasures = [
    ...industryWideMeasures.filter((m) => m.topLine === true),
    ...industryWideMeasures.filter((m) => m.topLine === false),
  ]

  const toggleCheckedHandler = (e, index) => {
    if (e.target.checked) {
      dispatch({
        type: 'ADD_SELECTED_MEASURE',
        selectedMeasure: industryWideMeasures.find(
          (measure) => measure.id === Number(e.target.value),
        ),
      })
    } else {
      dispatch({
        type: 'REMOVE_SELECTED_MEASURE',
        measureId: Number(e.target.value),
      })
    }
  }

  return (
    <div className="impact-measure-defined-table-component" style={{ width: '40%' }}>
      <h2 style={{ whiteSpace: 'nowrap' }}>Pre-defined measures</h2>
      <div
        className="table-wrapper shadow"
        style={{
          minHeight: '25vh',
          minWidth: '50%',
          maxWidth: '70%',
          display: 'flex',
          justifyContent: 'flex-start',
          paddingTop: '2rem',
        }}
      >
        <div className="widget-grid-4">
          <div
            className="widget-grid-title"
            style={{ padding: '0.5rem', justifyContent: 'flex-start', width: '5rem' }}
          >
            Measure
          </div>
          <div
            className="widget-grid-title"
            style={{ padding: '0.5rem', justifyContent: 'flex-start', width: '5rem' }}
          >
            Unit
          </div>
          <div
            className="widget-grid-title"
            style={{ padding: '0.5rem', justifyContent: 'flex-start', width: '5rem' }}
          >
            Type
          </div>
          <div
            className="widget-grid-title"
            style={{ padding: '0.5rem', justifyContent: 'flex-start', width: '5rem' }}
          >
            Selected
          </div>

          {sortedMeasures?.map((measure, index) => (
            <React.Fragment key={`governanceStatus-${index}`}>
              <div
                className="widget-grid-item"
                style={{ padding: '0.5rem', justifyContent: 'flex-start', width: '5rem' }}
              >
                {measure.name}
              </div>
              <div
                className="widget-grid-item"
                style={{ padding: '0.5rem', justifyContent: 'flex-start', width: '5rem' }}
              >
                {measure.unit}
              </div>
              <div
                className="widget-grid-item"
                style={{ padding: '0.5rem', justifyContent: 'flex-start', width: '5rem' }}
              >
                {measure.topLine ? 'Top-line' : 'Bottom-line'}
              </div>
              <div
                className="widget-grid-item"
                style={{ padding: '0.5rem', justifyContent: 'flex-start', width: '5rem' }}
              >
                <input
                  value={measure.id}
                  type="checkbox"
                  onChange={(e) => toggleCheckedHandler(e)}
                  checked={state.data.selectedMeasures.find((el) => measure.id === el.id)}
                />
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default DefinedTable

import React from 'react'
import updateReducer from './updateReducer'

export const defaultState = {
  isActivityModalOpen: false,
  suggestedActivitiesModalOpen: false,
  isBlockerModalOpen: false,
  isResolveBlockerModalOpen: false,
  isTeamMemberFormOpen: false,
  disableNext: false,
  isErrorModalOpen: false,
  errorModalMessage: null,
  blockerErrors: {},
  isMultiActivitiesModalOpen: false,

  joyRide: {
    isToursActive: [true, true, true],
    stepName: 'allMyActions',
    tourInnerStepIndex: 0,
    tourIndex: 0,
    isTutorialOff: true,
    isInTutorial: false,
    customJoyRideCallBackFn: () => {},
    joyRideKey: 0,
    steps: {},
  },

  data: {
    employees: [],
    objectives: [],
    blockers: [],
    milestones: [],
  },
  allocSubWiz: {
    updateObjectiveId: null,
    errorTeamCapAllocTable: false,
    triggerSelectAllEmp: false,
  },
  error: {
    milestoneStep: false,
  },
}

const UpdateContext = React.createContext({
  state: defaultState,
  dispatch: () => {},
})

export const UpdateProvider = ({ children, initialState }) => {
  const [state, dispatch] = React.useReducer(updateReducer, initialState)

  const context = {
    state,
    dispatch,
  }

  return <UpdateContext.Provider value={context}>{children}</UpdateContext.Provider>
}

const useUpdateContext = () => {
  const context = React.useContext(UpdateContext)
  if (!context) {
    throw new Error(
      'useUpdateContext must be used within a UpdateProvider. Wrap a parent component in <UpdateProvider> to fix this error',
    )
  }

  return context
}

export { useUpdateContext }

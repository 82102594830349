import { getHttpRequest, postHttpRequest } from '../query/dynamicAPI'
import Toast from '../../common/toast'

export async function addEditFilterView(formData) {
  try {
    let config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const data = await postHttpRequest(`/add_edit_filter_view`, formData, config)

    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
    })
  }
}

export async function getFilterViews() {
  try {
    const data = await getHttpRequest(`/get_filter_views`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
    })
  }
}

export async function deleteFilterView(formData) {
  try {
    let config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const data = await postHttpRequest(`/delete_filter_view`, formData, config)

    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
    })
  }
}

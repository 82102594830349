import * as yup from 'yup'

export const AddSchema = yup.object().shape({
  title: yup.string().required('Title is Required !'),
  firstName: yup.string().required('First Name is Required !'),
  lastName: yup.string().required('Last Name is Required !'),
  email: yup.string().email('Must be a valid email').max(255).required('Email is required'),
})

export const MoveSchema = yup.object().shape({
  title: yup.string().required('Title is Required !'),
  assign: yup.object().shape({
    eId: yup.string().required('Select Team Leader is Required !'),
    name: yup.string().required('Select Team Leader is Required !'),
  }),
})

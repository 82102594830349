import React from 'react'
import { Link } from 'react-router-dom'
import { governanceSubRoutes, routes } from '../../../../utils/routes'
import '../../styles.css'
import { useLocation } from 'react-router-dom'
import { hasRole } from '../../../../utils/auth'
import { getCompanyConfigSettings } from '../../../../utils/auth'

const GovernanceHeaders = () => {
  const location = useLocation()
  const isDevMode = localStorage.getItem('dMd') === 'true'
  const productSettings = getCompanyConfigSettings('productSettings')

  return (
    <div className="governance-nav-container">
      <Link
        to={`/${routes.governance}/${governanceSubRoutes.manageTeamLeader}`}
        className={`nav-option ${
          location.pathname.endsWith(governanceSubRoutes.manageTeamLeader) ||
          location.pathname.endsWith(routes.governance)
            ? 'active'
            : ''
        }`}
      >
        <span className="nav-option-text">People</span>
      </Link>
      <Link
        to={`/${routes.governance}/${governanceSubRoutes.manageCadences}`}
        className={`nav-option ${
          location.pathname.endsWith(governanceSubRoutes.manageCadences) ? 'active' : ''
        }`}
      >
        <span className="nav-option-text">Governance</span>
      </Link>
      <Link
        to={`/${routes.governance}/${governanceSubRoutes.manageObjectives}`}
        className={`nav-option ${
          location.pathname.endsWith(governanceSubRoutes.manageObjectives) ? 'active' : ''
        }`}
      >
        <span className="nav-option-text">Objectives</span>
      </Link>
      {/* <Link
        to={`/${routes.governance}/${governanceSubRoutes.governanceStatus}`}
        className={`nav-option ${location.pathname.endsWith(governanceSubRoutes.governanceStatus) ? 'active' : ''
          }`}
      >
        <span className="nav-option-text">Current Governance Cycle Status</span>
      </Link> */}

      {/* <Link
        to={`/${routes.governance}/${governanceSubRoutes.enterpriseMetrics}`}
        className={`nav-option ${location.pathname.endsWith(governanceSubRoutes.enterpriseMetrics) ? 'active' : ''
          }`}
      >
        <span className="nav-option-text">View Enterprise metrics</span>
      </Link> */}
      {/* <Link
        to={`/${routes.governance}/${governanceSubRoutes.peopleManagment}`}
        className={`nav-option ${location.pathname.endsWith(governanceSubRoutes.peopleManagment) ? 'active' : ''
          }`}
      >
        <span className="nav-option-text">People Management</span>
      </Link> */}
      {/* <Link
        to={`/${routes.governance}/${governanceSubRoutes.accessConfig}`}
        className={`nav-option ${location.pathname.endsWith(governanceSubRoutes.accessConfig) ? 'active' : ''
          }`}
      >
        <span className="nav-option-text">User Configurations</span>
      </Link> */}

      <Link to={`/${governanceSubRoutes.manageTeamConfiguration}`}></Link>
      {/* <Link
        to={`/${routes.governance}/${governanceSubRoutes.initiativeMangement}`}
        className={`nav-option ${location.pathname.endsWith(governanceSubRoutes.initiativeMangement) ? 'active' : ''
          }`}
      >
        <span className="nav-option-text">Manage Initiatives</span>
      </Link> */}
      {hasRole('enterprise') && (
        <Link
          to={`/${routes.governance}/${governanceSubRoutes.classifyMeetingTopics}`}
          className={`nav-option ${
            location.pathname.endsWith(governanceSubRoutes.classifyMeetingTopics) ? 'active' : ''
          }`}
        >
          <span className="nav-option-text">Machine Learning</span>
        </Link>
      )}
      {hasRole('enterprise') && (
        <Link
          to={`/${routes.governance}/${governanceSubRoutes.logMei}`}
          className={`nav-option ${
            location.pathname.endsWith(governanceSubRoutes.logMei) ? 'active' : ''
          }`}
        >
          <span className="nav-option-text">Log MEI</span>
        </Link>
      )}

      {(hasRole('enterprise') || hasRole('admin')) && (
        <Link
          to={`/${routes.governance}/${governanceSubRoutes.seeOthersProfile}`}
          className={`nav-option ${
            location.pathname.endsWith(governanceSubRoutes.seeOthersProfile) ? 'active' : ''
          }`}
        >
          <span className="nav-option-text">See Other's Profile</span>
        </Link>
      )}

      {hasRole('enterprise') && (
        <Link
          to={`/${routes.governance}/${governanceSubRoutes.support}`}
          className={`nav-option ${
            location.pathname.endsWith(governanceSubRoutes.support) ? 'active' : ''
          }`}
        >
          <span className="nav-option-text">Support</span>
        </Link>
      )}
      {hasRole('enterprise') && isDevMode && (
        <Link
          to={`/${routes.governance}/${governanceSubRoutes.companyConfig}`}
          className={`nav-option ${
            location.pathname.endsWith(governanceSubRoutes.companyConfig) ? 'active' : ''
          }`}
        >
          <span className="nav-option-text">Company Config Options</span>
        </Link>
      )}
      {(hasRole('enterprise') || !productSettings) && (
        <Link
          to={`/${routes.governance}/${governanceSubRoutes.meiReports}`}
          className={`nav-option ${
            location.pathname.endsWith(governanceSubRoutes.meiReports) ? 'active' : ''
          }`}
        >
          <span className="nav-option-text">MEI Reports</span>
        </Link>
      )}
      {hasRole('enterprise') && (
        <Link
          to={`/${routes.governance}/${governanceSubRoutes.companyInfo}`}
          className={`nav-option ${
            location.pathname.endsWith(governanceSubRoutes.companyInfo) ? 'active' : ''
          }`}
        >
          <span className="nav-option-text">Company Info</span>
        </Link>
      )}
      {hasRole('enterprise') && (
        <Link
          to={`/${routes.governance}/${governanceSubRoutes.agentSession}`}
          className={`nav-option ${
            location.pathname.endsWith(governanceSubRoutes.agentSession) ? 'active' : ''
          }`}
        >
          <span className="nav-option-text">Agent Sessions</span>
        </Link>
      )}
    </div>
  )
}

export default GovernanceHeaders

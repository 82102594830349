import React from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import ErrorModal from '../../../../components/ErrorModal'
import { CardContainer, CardHeader, ObjectiveGrid } from '../../styles'
import ObjectiveRow from './ObjectiveRow'
import { useUpdateContext } from '../../context/updateContext'
import SuggestedActivitiesModal from '../../../../components/SuggestedActivitiesModal'
import { postHttpRequest } from '../../../../api/query/dynamicAPI'
import { UPDATE_WIZARD_ADD_ACTIVITY } from '../../../../api/constant/route'
import AddMultipleActivitiesModal from '../../../../components/AddMultipleActivitiesModal'
import { dateFilter } from '../../../../utils/time'
import AddEditActivityModal from '../../../../components/AddEditActivityModal'
import useGetObjectiveMilestones from '../../../../api/query/useGetObjectiveMilestones'
import useUpdateWizardAddActivity from '../../../../api/mutation/useUpdateWizardAddActivity'
import useUpdateWizardDeleteActivity from '../../../../api/mutation/useUpdateWizardDeleteActivity'
import {
  API_SPREADHSHEET_SAVE_SUCCESS_MESSAGE,
  API_ERROR_MESSAGE,
} from '../../../../api/constant/route'
import Toast from '../../../../common/toast'
import { useTranslation } from 'react-i18next'
import { isAreaLeader } from '../../../../utils/auth'
const UpdateActivities = () => {
  const { t } = useTranslation(['UpdateWizard'])

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
  }, [])

  const { state, dispatch } = useUpdateContext()
  const [objective, setObjective] = React.useState(null)
  const [activity, setActivity] = React.useState(null)
  const [milestoneIndexToEdit, setMilestoneIndexToEdit] = React.useState(null)
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const [isAddEditActivityModalOpen, setIsAddEditActivityModalOpen] = React.useState(false)

  const mutationAddActivity = useUpdateWizardAddActivity()
  const mutationDeleteActivity = useUpdateWizardDeleteActivity()
  const {
    data: milestoneData,
    isLoading: isMilestonesLoading,
    error: milestonesError,
  } = useGetObjectiveMilestones(state?.data?.objectives?.map((obj) => parseInt(obj.id)) ?? [])

  const handleOpenActivityModal = (objective, milestoneIndex = null) => {
    if (milestoneIndex !== null) {
      setMilestoneIndexToEdit(milestoneIndex)
    }

    setObjective(objective)
    //dispatch({ type: 'OPEN_ACTIVITY_MODAL' })
    setIsAddEditActivityModalOpen(true)
  }

  const handleCloseActivityModal = () => {
    setMilestoneIndexToEdit(null)
    setObjective(null)
    dispatch({ type: 'CLOSE_ACTIVITY_MODAL' })
  }

  const handleAddActivity = (activity) => {
    async function submitNewActivityInBackground() {
      let postObject = {
        milestones: [activity],
      }
      postHttpRequest(UPDATE_WIZARD_ADD_ACTIVITY, postObject)
        .then((response) => {
          let milestoneIds = response.activities

          if (milestoneIds !== undefined && milestoneIds !== null && milestoneIds.length > 0) {
            activity.milestoneId = milestoneIds[0]
            dispatch({ type: 'ADD_ACTIVITY', activity: activity })
          }
        })
        .catch((error) => {})
    }

    submitNewActivityInBackground()
  }

  const handleAddManyActivities = (activities) => {
    async function submitNewActivityInBackground() {
      let postObject = {
        milestones: activities,
      }

      await mutationAddActivity.mutateAsync(postObject, {
        onSuccess: (response) => {
          Toast.fire({
            icon: 'success',
            title: API_SPREADHSHEET_SAVE_SUCCESS_MESSAGE,
          })
        },
        onError: (e) => {
          Toast.fire({
            icon: 'error',
            title: API_ERROR_MESSAGE,
          })
        },
      })
    }

    submitNewActivityInBackground()
  }

  const handleDeleteActivity = (activityIndex) => {
    async function submitDeleteActivityInBackground() {
      let deltActivity = {
        ...milestoneData?.milestones[activityIndex],
        mode: 'deleted',
      }
      let postObject = {
        milestones: [deltActivity],
      }
      await mutationDeleteActivity.mutateAsync(postObject, {
        onSuccess: (response) => {
          Toast.fire({
            icon: 'success',
            title: API_SPREADHSHEET_SAVE_SUCCESS_MESSAGE,
          })
        },
        onError: (e) => {
          Toast.fire({
            icon: 'error',
            title: API_ERROR_MESSAGE,
          })
        },
      })
    }
    submitDeleteActivityInBackground()
  }

  const handleOpenSuggestedActivityModal = (objective, milestoneIndex = null) => {
    if (milestoneIndex !== null) {
      setMilestoneIndexToEdit(milestoneIndex)
    }

    setObjective(objective)
    dispatch({ type: 'OPEN_SUGGESTED_ACTIVITY_MODAL' })
  }

  const handleCloseSuggestedActivityModal = () => {
    setMilestoneIndexToEdit(null)
    setObjective(null)
    dispatch({ type: 'CLOSE_SUGGESTED_ACTIVITY_MODAL' })
  }

  const handleOpenMultiActivitiesModal = (objective) => {
    setObjective(objective)
    dispatch({ type: 'OPEN_MULTI_ACTIVITIES_MODAL' })
  }

  const handleCloseMultiActivitiesModal = () => {
    setObjective(null)
    dispatch({ type: 'CLOSE_MULTI_ACTIVITIES_MODAL' })
  }

  const closeErrorModal = () => {
    setIsErrorModalOpen(false)
  }

  const handleAddMultipleActivities = (activities) => {
    let activitiesToShow = []

    for (let activity of activities) {
      activitiesToShow.push({
        description: activity.title,
        progress: 'NOT_STARTED',
        objectiveId: activity.objId,
        milestoneId: activity.activityId,
        dueDate: activity.dueDate === 0 ? null : dateFilter(activity.dueDate),
      })
    }
  }

  const [filteredObjectives, setFilteredObjectives] = React.useState([])
  const [sharedObjectives, setSharedObjectives] = React.useState([])

  const handleOpenErrorModalMessage = (message) => {
    dispatch({ type: 'OPEN_ERROR_MODAL_WITH_MESSAGE', message: message })
  }

  React.useEffect(() => {
    // Filter out objectives that are associated with templates, or don't have any milestones.
    if (state?.data?.objectives) {
      let tempFilteredObjectives = state?.data?.objectives.filter(
        (obj) => obj.hasTemplate === false && obj.originalOwnerId === 0 && obj.isCopy === false,
      )

      let tempSharedObjectives = state?.data?.objectives.filter(
        (obj) => obj.hasTemplate === false && obj.originalOwnerId > 0,
      )

      setSharedObjectives(tempSharedObjectives)

      if (isAreaLeader()) {
        let objWithAct = []
        for (let obj of tempFilteredObjectives) {
          for (let mil of milestoneData?.milestones ?? []) {
            if (parseInt(obj.id) === parseInt(mil.objectiveId)) {
              objWithAct.push(obj)
              break
            }
          }
        }
        setFilteredObjectives([...objWithAct])
        return
      }
      setFilteredObjectives([...tempFilteredObjectives])
    }
  }, [state?.data?.objectives])

  return (
    <>
      <ErrorModal isOpen={isErrorModalOpen} handleClose={closeErrorModal} errorMessage={null} />

      {isAddEditActivityModalOpen && objective && (
        <AddEditActivityModal
          isModalOpen={isAddEditActivityModalOpen}
          closeModal={() => {
            setIsAddEditActivityModalOpen(false)
            setActivity(null)
          }}
          objectiveId={parseInt(objective.id)}
          activityId={activity?.milestoneId ?? null}
        />
      )}

      {state.suggestedActivitiesModalOpen && (
        <SuggestedActivitiesModal
          objective={objective}
          handleClose={handleCloseSuggestedActivityModal}
          addActivities={handleAddManyActivities}
          handleOpenErrorModalMessage={handleOpenErrorModalMessage}
        />
      )}

      {state.isMultiActivitiesModalOpen === true ? (
        <AddMultipleActivitiesModal
          objectiveId={objective.id}
          handleClose={handleCloseMultiActivitiesModal}
          setIsRefreshObjective={() => {}}
          handleAddActivities={handleAddMultipleActivities}
        />
      ) : null}

      <div className="wizard-step">
        <h2 className="wizard-step__title" style={{ margin: '2rem 0 0.5rem 0' }}>
          {t('activitiesStep.header')}
        </h2>
        <div className="wizard-step__overview" style={{ alignSelf: 'start' }}>
          {filteredObjectives.length > 0 ||
            (sharedObjectives.length > 0 && (
              <div className="wizard-step__overview__paragraph">
                <span>
                  <span className="icon-size" style={{ color: '#BC7063' }}>
                    A
                  </span>{' '}
                  = Actions &nbsp;
                  {/* Commented out for now to see if we really want to keep or not the M */}
                  {/* <span class="icon-size" style={{ color: '#7A9980' }}>
                M
              </span> = Milestones */}
                </span>
              </div>
            ))}
        </div>
        {filteredObjectives.length > 0 && (
          <CardContainer>
            <ObjectiveGrid>
              <CardHeader>{t('activitiesStep.objective')}</CardHeader>
              <CardHeader>
                <div
                  style={{
                    display: 'grid',
                    columnGap: '1rem',
                    gridTemplateColumns: '2fr 1.8fr 1fr 1fr 1fr 1fr 1fr',
                    width: '100%',
                  }}
                >
                  <div> {t('activitiesStep.meetingTopic')}</div>
                  <div> {t('activitiesStep.activities')}</div>
                  <div>{t('activitiesStep.assignee')}</div>
                  <div> {t('activitiesStep.startDate')}</div>
                  <div> {t('activitiesStep.dueDate')}</div>
                  <div> {t('activitiesStep.status')}</div>
                  <div> </div>
                </div>
              </CardHeader>
              {filteredObjectives?.map((obj, index) => (
                <ObjectiveRow
                  objective={obj}
                  milestones={milestoneData?.milestones ?? []}
                  key={`ms-obj-${index}`}
                  openModal={handleOpenActivityModal}
                  addActivity={handleAddActivity}
                  deleteActivity={handleDeleteActivity}
                  handleOpenSuggestedActivityModal={handleOpenSuggestedActivityModal}
                  handleOpenMultiActivitiesModal={handleOpenMultiActivitiesModal}
                  setActivity={setActivity}
                  setIsAddEditActivityModalOpen={setIsAddEditActivityModalOpen}
                  setObjective={setObjective}
                />
              ))}
            </ObjectiveGrid>
          </CardContainer>
        )}
        {sharedObjectives.length > 0 && (
          <>
            <br />
            <CardHeader>{t('activitiesStep.objsSharedWithMe')}</CardHeader>
            <CardContainer>
              <ObjectiveGrid>
                <CardHeader>{t('activitiesStep.objective')}</CardHeader>
                <CardHeader>
                  <div
                    style={{
                      display: 'grid',
                      columnGap: '1rem',
                      gridTemplateColumns: '2fr 1.8fr 1fr 1fr 1fr 1fr 1fr',
                      width: '100%',
                    }}
                  >
                    <div> {t('activitiesStep.meetingTopic')}</div>
                    <div> {t('activitiesStep.activities')}</div>
                    <div>{t('activitiesStep.assignee')}</div>
                    <div> {t('activitiesStep.startDate')}</div>
                    <div> {t('activitiesStep.dueDate')}</div>
                    <div> {t('activitiesStep.status')}</div>
                    <div> </div>
                  </div>
                </CardHeader>

                {sharedObjectives?.map((obj, index) => (
                  <ObjectiveRow
                    objective={obj}
                    milestones={milestoneData?.milestones ?? []}
                    key={`ms-obj-shared-${index}`}
                    openModal={handleOpenActivityModal}
                    addActivity={handleAddActivity}
                    deleteActivity={handleDeleteActivity}
                    handleOpenSuggestedActivityModal={handleOpenSuggestedActivityModal}
                    handleOpenMultiActivitiesModal={handleOpenMultiActivitiesModal}
                    setActivity={setActivity}
                    setIsAddEditActivityModalOpen={setIsAddEditActivityModalOpen}
                    setObjective={setObjective}
                  />
                ))}
              </ObjectiveGrid>
            </CardContainer>
          </>
        )}
        {filteredObjectives.length === 0 && sharedObjectives.length === 0 && (
          <div>{t('activitiesStep.noObjectivesMsg')}</div>
        )}
      </div>
    </>
  )
}

export default UpdateActivities

import React from 'react'
import './styles.css'

const ScoreCard = ({ scoreCardData }) => {
  const [strategyBreakdown, setStrategyBreakdown] = React.useState([])
  const [workObjectives, setWorkObjectives] = React.useState(scoreCardData.stratWorkObjectives)
  const [strategicMeasures, setStrategicMeasures] = React.useState(scoreCardData.stratWorkMeasures)

  const roundTo100 = (objArray) => {
    let truncStratSum = 0
    for (let obj of objArray) {
      truncStratSum += obj.realPercent
    }
    let remainder = 100 - truncStratSum
    for (let obj of objArray) {
      if (remainder === 0) break
      if (obj.realPercent == 0) continue
      obj.realPercent += 1
      remainder--
    }
  }

  React.useEffect(() => {
    // Percentages for type of work.
    const tempBreakdown = [
      {
        measureName: 'Strategic Work',
        fte: scoreCardData.strategicWorkFte,
      },
      {
        measureName: 'Non-Strategic Work',
        fte: scoreCardData.nonStrategicWorkFte,
      },
      {
        measureName: 'Day-To-Day Operations',
        fte: scoreCardData.dayToDayWorkFte,
      },
    ]

    const tempWorkObj = [...scoreCardData.stratWorkObjectives]
    const tempMeasures = [...scoreCardData.stratWorkMeasures]

    let stratSum = 0
    for (let strat of tempBreakdown) {
      if (strat.fte === undefined) strat.fte = 0
      stratSum += strat.fte
    }
    for (let strat of tempBreakdown) {
      if (stratSum > 0) strat.realPercent = Math.trunc((strat.fte / stratSum) * 100, 0)
      else strat.realPercent = 0
    }

    roundTo100(tempBreakdown)

    // Percentages for objectives.
    let topObjSum = 0
    for (let obj of tempWorkObj) {
      topObjSum += obj.fte
    }
    for (let obj of tempWorkObj) {
      if (topObjSum > 0) obj.realPercent = Math.trunc((obj.fte / topObjSum) * 100, 0)
      else obj.realPercent = 0
    }

    roundTo100(tempWorkObj)

    // Percentages for measures.
    let measureSum = 0
    for (let measure of tempMeasures) {
      measureSum += measure.fte
    }
    for (let measure of tempMeasures) {
      if (measureSum > 0) measure.realPercent = Math.trunc((measure.fte / measureSum) * 100, 0)
      else measure.realPercent = 0
    }

    roundTo100(tempMeasures)

    setStrategyBreakdown(tempBreakdown)
    setWorkObjectives(tempWorkObj)
    setStrategicMeasures(tempMeasures)
  }, [
    scoreCardData.dayToDayWorkFte,
    scoreCardData.nonStrategicWorkFte,
    scoreCardData.strategicWorkFte,
    scoreCardData.stratWorkMeasures,
    scoreCardData.stratWorkObjectives,
  ])

  // - Work by type
  // - Work by strategic objective
  // - Work by impact measure

  return (
    <div className="scorecard-main-container">
      <div className="scorecard-container shadow">
        <h3 className="scorecard-title">What are we working on?</h3>
        <div className="scorecard-header-container">
          <b>Dimension</b>
          <b style={{ textAlign: 'center' }}>Percentage</b>
        </div>

        <h3 className="scorecard-subtitle">Work by type</h3>
        <div className="scorecard">
          {strategyBreakdown.map((strat, index) => (
            <React.Fragment key={`strat-${index}`}>
              <div>{strat.measureName}</div>
              <div style={{ textAlign: 'center' }}>
                <b>{strat.realPercent}</b>
              </div>
            </React.Fragment>
          ))}
        </div>

        <div className="divider"></div>

        <h3 className="scorecard-subtitle">Work by strategic objective</h3>
        <div className="scorecard">
          {workObjectives.map((workObj, index) => (
            <React.Fragment key={`workObj-${index}`}>
              <div>{workObj.statement}</div>
              <div style={{ textAlign: 'center' }}>
                <b>{workObj.realPercent}</b>
              </div>
            </React.Fragment>
          ))}
        </div>

        <div className="divider"></div>

        <h3 className="scorecard-subtitle">Work by impact measure</h3>
        <div className="scorecard">
          {strategicMeasures.map((measure, index) => (
            <React.Fragment key={`measure-${index}`}>
              <div>{measure.measureName}</div>
              {measure.realPercent !== undefined && (
                <div style={{ textAlign: 'center' }}>
                  <b>{measure.realPercent}</b>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ScoreCard

import React from 'react'
import { Handle, Position, useStore } from 'reactflow'
import './customNode.scss'

const connectionNodeIdSelector = (state) => state.connectionNodeId

function UniqueNode({ data, isConnectable = true }) {
  const connectionNodeId = useStore(connectionNodeIdSelector)
  const isConnecting = !!connectionNodeId
  // add-blue
  return (
    <div
      className={`customNode node-team-flow custom-node-team-flow ${
        !data.isRecivedWizard ? 'without-blue' : 'add-blue'
      }`}
    >
      <Handle
        className="customHandle"
        type="target"
        style={{ visibility: 'visible' }}
        position={Position.Top}
        isConnectable={isConnectable}
      />

      <p className="active-wizard-title"> {data.label} </p>

      {!isConnecting && (
        <Handle
          className="customHandle"
          type="source"
          style={{ visibility: 'visible' }}
          position={Position.Bottom}
          isConnectable={isConnectable}
        />
      )}
    </div>
  )
}

export default UniqueNode

import { useState, React } from 'react'
import { Formik, Field, Form } from 'formik'
import Modal from '../Modal'
import * as Yup from 'yup'
import InPageLoader from '../InPageLoader'
import { useTranslation } from 'react-i18next'
import { generateMultipleMeetingTopics } from '../../api/services/meetingTopic.services'

const MultiAddTextModal = ({ isModalOpen, closeModal, type, setGeneratedText }) => {
  const { t } = useTranslation(['Common'])

  const [isLoading, setIsLoading] = useState(false)
  const [displayError, setDisplayError] = useState(false)

  const generateMultipleItems = async (values, resetForm) => {
    setIsLoading(true)

    if (type === 'meetingTopic') {
      generateMultipleMeetingTopics(values.inputText)
        .then((response) => {
          const data = response
          if (data) {
            setGeneratedText(data)
            setIsLoading(false)
            resetForm()
            closeModal()
          }
        })
        .catch((error) => {
          setDisplayError(true)
          setIsLoading(false)
          resetForm()
        })
    }
  }

  return (
    <>
      {isModalOpen === true && (
        <Modal closeModal={closeModal} width={'60vw'}>
          {isLoading ? (
            <InPageLoader displayErrorText={true} />
          ) : (
            <div className="modal-gray-box">
              <div className="modal-inner-content">
                <div className="modal-inner-title">{t('multiAddTextModal.addMeetingTopics')}</div>
                <Formik
                  enableReinitialize
                  initialValues={{
                    inputText: '',
                  }}
                  validationSchema={Yup.object().shape({
                    inputText: Yup.string().required(t('Common:formError.required')),
                  })}
                  onSubmit={(values, { resetForm }) => {
                    generateMultipleItems(values, resetForm)
                  }}
                >
                  {({ errors, touched, resetForm, setFieldValue }) => (
                    <div style={{ textAlign: 'left' }}>
                      <span>{t('multiAddTextModal.topicsTitle')}</span>

                      <Form className="objective-form">
                        <div style={{ width: '100%' }}>
                          <div className="form-field" style={{ justifyContent: 'center' }}>
                            <Field
                              component="textarea"
                              rows="8"
                              id="inputText"
                              name="inputText"
                              style={{
                                width: '90%',
                                height: '100%',
                                resize: 'none',
                                border: '1px solid rgb(238, 238, 238)',
                                borderRadius: '2.5px',
                                wordBreak: 'break-word',
                                backgroundColor: 'white',
                                fontFamily:
                                  '"Open Sans", Arial, Helvetica, "system-ui", sans-serif',
                              }}
                              maxLength="512"
                              onFocus={() => {
                                setDisplayError(false)
                              }}
                            />
                            {errors.inputText && touched.inputText && (
                              <div className="validation-error">{errors.inputText}</div>
                            )}
                            {displayError && (
                              <span style={{ color: 'red' }}>
                                {t('Common:modalMessages.somethingWentWrongPleaseTryAgain')}
                              </span>
                            )}
                            <div className="modal-dual-btn" style={{ gap: '1rem' }}>
                              <div
                                className="sleek-button sleek-blue"
                                style={{ cursor: 'pointer', height: '1.5rem' }}
                                onClick={() => {
                                  closeModal()
                                }}
                              >
                                {t('commonButtons.cancelLowercase')}
                              </div>
                              <button
                                className="sleek-button sleek-blue"
                                style={{
                                  cursor: 'pointer',
                                  height: '2.2rem',
                                  backgroundColor: 'white',
                                }}
                                type="submit"
                              >
                                {t('commonButtons.generateLowerCase')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          )}
        </Modal>
      )}
    </>
  )
}

export default MultiAddTextModal

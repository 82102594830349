import React from 'react'
import Loading from '../../components/Loading'
import ApplicationLayout from '../../components/ApplicationLayout'
import { defaultState, DemandIntakeProvider } from './context/demandIntakeContext'
import DemandIntakeWizard from './components/DemandIntakeWizard'
import useDemandIntakeData from './hooks/useDemandIntakeData'
import { useLocation } from 'react-router-dom'

const DemandIntakePage = () => {
  const { data, isLoading, error } = useDemandIntakeData()
  const location = useLocation()

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    // TODO: Error Component
    return 'Error'
  }

  // concat existing data / api data

  const initialState = {
    ...defaultState,
    data,
    locationState: location.state,
  }

  for (let i = 0; i < initialState.data.employees.length; i++) {
    if (initialState.data.employees[i].isDirectReport !== true) {
      initialState.data.nonReportingEmpsRealIndexes.push(i)
    }
  }

  const isF2wTl = parseInt(localStorage.getItem('mType')) === 4 ? true : false

  return (
    <ApplicationLayout>
      <DemandIntakeProvider initialState={initialState}>
        <div
          className="wizard-container"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <DemandIntakeWizard existingStep={0} isF2wTl={isF2wTl} />
        </div>
      </DemandIntakeProvider>
    </ApplicationLayout>
  )
}

export default DemandIntakePage

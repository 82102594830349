import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const addSuggestedObjective = async (suggestedObjectiveData) => {
  const { data } = await axios.post(`${BASE_URL}/suggest_objective`, suggestedObjectiveData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export default function useAddSuggestedObjective() {
  return useMutation((data) => addSuggestedObjective(data), {})
}

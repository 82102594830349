import './styles.css'
import checkmark from '../../../../../../assets/checkmark.svg'

const ImpactMeasuresIntro = () => {
  return (
    <div className="impact-measures-intro-component">
      <h2>Finally, let's define a few impact measures for your organization</h2>
      <div className="step-details-intro-points-container">
        <ul className="diamond-yellow-list">
          <li className="diamond-yellow">
            An impact measure is a metric which is directly connected to either top-line or
            bottom-line impact.
          </li>
          <li className="diamond-yellow">
            For example, if you run a business that has an app that generates revenue for the
            company, then impact measure could be:
          </li>
          <ul>
            <li className="diamond-yellow">
              <b>Top-line:</b> Monthly Average Users, Price per subscription.
            </li>
            <li className="diamond-yellow">
              <b>Bottom-line:</b> SG&A, Amount spent on R&D.
            </li>
          </ul>
          <li className="diamond-yellow">
            Every Enterprise objective must include at least one impact measure.
          </li>
          <li className="diamond-yellow">
            The predefined impact measures below are specific to your industry, however you can add
            additional custom measures on the right side.
          </li>
          <li className="diamond-yellow">
            There should be no more than 10 impact measures selected so that it is easy for teams to
            map their work.
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ImpactMeasuresIntro

import React from 'react'
import Modal from '../../Modal'

import { useTranslation } from 'react-i18next'
import InPageLoader from '../../InPageLoader'
import { getCompanyConfigSettings } from '../../../utils/auth'
import { linkifyString } from '../../../utils/general'

const TopicsInformationModal = ({
  isModalOpen,
  closeModal,
  data,
  dataIsLoading = false,
  topicTitle = [],
}) => {
  const { t } = useTranslation(['MeetingHub', 'Sankey', 'Dashboard'])
  const [dataToShow, setDataToShow] = React.useState(null)
  const [showMore, setShowMore] = React.useState(false)
  const productSettings = getCompanyConfigSettings('productSettings')
  const titleMap = {
    PRODUCT: t('Sankey:actionsDurationDetailsModal.productTopics'),
    RESOURCE: t('Sankey:actionsDurationDetailsModal.resourceTopics'),
    PROCESS: t('Sankey:actionsDurationDetailsModal.processTopics'),
    DIRECTION: t('Sankey:actionsDurationDetailsModal.directionTopics'),
    APPROVAL: t('Sankey:actionsDurationDetailsModal.approvalTopics'),
    PRIORITIZATION: t('Sankey:actionsDurationDetailsModal.prioritizationTopics'),
  }

  React.useEffect(() => {
    setDataToShow(data?.objectives)
  }, [data])

  function returnDataList(meeting) {
    if (meeting == null) return
    let finalArray = []
    if (meeting?.length > 5) {
      if (showMore) {
        finalArray = meeting
      } else {
        finalArray = meeting.slice(0, 5)
      }
    } else {
      finalArray = meeting
    }
    return (
      <>
        {finalArray?.length > 0 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ width: '100%', marginBottom: '3rem', padding: '2rem' }}>
              <div className="chart-log-topics-table">
                <div className="chart-log-meeting-header"></div>
                <div
                  className="chart-log-meeting-header chart-topics-objective-header"
                  style={{ textAlign: 'center' }}
                >
                  {t('scorecard.objectiveTitle')}
                </div>
                <div
                  className="chart-log-meeting-header chart-topics-topics-header"
                  style={{ textAlign: 'center' }}
                >
                  {t('scorecard.topicTitle')}
                </div>
                <div
                  className="chart-log-meeting-header chart-topics-small-header"
                  style={{ textAlign: 'center' }}
                >
                  {t('scorecard.ownerName')}
                </div>

                {finalArray?.map((objective) => {
                  return (
                    <>
                      <React.Fragment key={`objective-${objective.objId}`}>
                        <div></div>
                        <div className="chart-log-meeting-description"></div>
                        <>
                          <div></div>
                          <>
                            <div
                              style={{
                                textAlign: 'left',
                                paddingLeft: '8px',
                                wordBreak: 'break-all',
                              }}
                            >
                              {productSettings &&
                                objective?.initiativeId?.toString().padStart(5, '0') +
                                  ' - ' +
                                  objective?.objDescription}

                              {!productSettings && objective?.objDescription}
                            </div>
                            <div style={{ textAlign: 'left', wordBreak: 'break-all' }}>
                              {linkifyString(objective.description)}
                            </div>
                            <div style={{ textAlign: 'left' }}>{objective.objOwner}</div>
                            <div></div>
                          </>
                        </>
                      </React.Fragment>
                    </>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          <div style={{ padding: '2rem' }}></div>
        )}
      </>
    )
  }

  return (
    <>
      <Modal
        isModalOpen={isModalOpen === true}
        closeModal={() => closeModal()}
        width={'75%'}
        style={{ padding: '0rem 0rem 1rem 0rem' }}
        title={
          topicTitle.length > 0
            ? titleMap[topicTitle[0]] +
              ' - ' +
              (topicTitle[1]
                ? t('Sankey:actionsDurationDetailsModal.notResolved2weeks')
                : t('Sankey:actionsDurationDetailsModal.resolved2weeks'))
            : ''
        }
      >
        <>
          {dataIsLoading === true && <InPageLoader inComponent={true} />}
          {returnDataList(dataToShow)}
          {dataToShow?.length > 5 && (
            <span className="see_more" onClick={() => setShowMore(!showMore)}>
              {showMore
                ? t('Dashboard:lastMeetingTopic.showLess')
                : t('Dashboard:lastMeetingTopic.showMore')}
            </span>
          )}
        </>
      </Modal>
    </>
  )
}

export default TopicsInformationModal

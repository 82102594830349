import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const objectiveStatusUpdate = async (objData) => {
  const { data } = await axios.post(`${BASE_URL}/update_objective_status`, objData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export default function useObjectiveStatusUpdate() {
  const queryClient = useQueryClient()

  return useMutation((data) => objectiveStatusUpdate(data), {
    onSuccess: () => {
      void queryClient.invalidateQueries('objective-info')
    },
  })
}

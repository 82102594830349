import { useState, useRef, React, useEffect } from 'react'
import './styles.css'
import { useTranslation } from 'react-i18next'
import logo from '../../assets/transforml-icon.png'

const AudioRecorderV2 = ({ handleSubmit, isNewDesign = false, start = true, displayText = '' }) => {
  const [recording, setRecording] = useState(false)
  const mediaRecorder = useRef(null)
  const [seconds, setSeconds] = useState(0)

  const { t } = useTranslation(['Common'])

  const startRecording = () => {
    setSeconds(0)
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const options = {
        audioBitsPerSecond: 64000,
        mimeType: 'audio/webm',
      }
      mediaRecorder.current = new MediaRecorder(stream, options)
      mediaRecorder.current.start()
      setRecording(true)
    })
  }

  const stopRecording = async () => {
    mediaRecorder.current.stop()
    setRecording(false)
    mediaRecorder.current.ondataavailable = (e) => {
      const audioBlob = new Blob([e.data], { type: 'audio/webm' })
      handleSubmit(audioBlob)
    }
    setSeconds(0)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds < 60) {
        setSeconds(seconds + 1)
      } else {
        clearInterval(interval)
        stopRecording()
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  })

  useEffect(() => {
    if (start) {
      startRecording()
    }
  }, [])

  const formatTime = (secs) => {
    let hours = Math.floor(secs / 3600)
    let minutes = Math.floor(secs / 60) % 60
    let seconds = secs % 60
    return [hours, minutes, seconds]
      .map((v) => ('' + v).padStart(2, '0'))
      .filter((v, i) => v !== '00' || i > 0)
      .join(':')
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {/* <div class="sleek-button sleek-blue recording"></div> */}
        <div style={{ width: '100%', marginBottom: '2rem' }}>
          <img className="logo-large" src={logo} alt="TransforML Logo" />
          <div style={{ marginTop: '1rem' }}>
            <span style={{ fontSize: '16px' }}>
              <b>{displayText}</b>
            </span>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '1rem' }}>
          <div class="audio-recorder-timer-v2" style={{ width: '20%', fontSize: '18px' }}>
            {formatTime(seconds)}
          </div>
          <div
            class="audio-recorder-timer-v2"
            style={{ width: '20%', marginLeft: 'auto', fontSize: '18px' }}
          >
            0:60
          </div>
        </div>
        <div
          onClick={stopRecording}
          class="sleek-button sleek-full-blue"
          style={{ width: '80%', fontSize: '18px' }}
        >
          {t('audioRecorder.stop')}
        </div>
      </div>
    </>
  )
}
export default AudioRecorderV2

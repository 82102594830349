const kpiReducer = (state, action) => {
  switch (action.type) {
    case 'SET_OBJ':
      return {
        ...state,
        obj: action.obj,
      }

    case 'SET_TEAM_OVERVIEW':
      return {
        ...state,
        answers: {
          ...state.answers,
          teamOverview: action.value,
        },
      }

    case 'SET_TIMEFRAME':
      return {
        ...state,
        answers: {
          ...state.answers,
          timeframe: action.value,
        },
      }

    case 'SET_LONGTERM_ANSWER':
      return {
        ...state,
        answers: {
          ...state.answers,
          longtermAnswer: action.value,
        },
      }
    case 'SET_SHORTTERM_ANSWER':
      return {
        ...state,
        answers: {
          ...state.answers,
          shorttermAnswer: action.value,
        },
      }
    case 'SET_SUGGESTED_KPIS_TO_SAVE':
      return {
        ...state,
        suggestedKpisToSave: action.value,
      }
    case 'SET_SUGGESTED_KPIS_TO_SAVE_WITH_ERROR':
      return {
        ...state,
        suggestedKpisToSaveWithError: action.value,
      }

    default:
      throw new Error(`Action: ${action.type} not implemented in Update reducer.`)
  }
}

export default kpiReducer

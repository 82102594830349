import React from 'react'
import Modal from '../Modal'
import { useTranslation } from 'react-i18next'
import InPageLoader from '../InPageLoader'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import SelectField from '../CustomMultipleSelect/singleOption'

const MeetingSelectionModal = ({ closeModal, setSelectedMeetingId, selectedMeetingId }) => {
  console.log('selectedMeetingId: ', selectedMeetingId)
  const { t } = useTranslation(['Common'])
  const [isLoading, setIsLoading] = React.useState(false)
  const [meetings, setMeetings] = React.useState([])
  const [selectedMeeting, setSelectedMeeting] = React.useState(null)

  React.useEffect(() => {
    setIsLoading(true)
    getMeetingForUser()
  }, [])

  const getMeetingForUser = async () => {
    try {
      let response = await getHttpRequest('/get_meetings_by_user')
      setMeetings(response.meetings)
    } catch (error) {}
    setIsLoading(false)
  }

  React.useEffect(() => {
    setSelectedMeeting(meetings.find((meeting) => meeting.meetingId === selectedMeetingId) ?? null)
  }, [meetings])

  const updateTopicMeetings = (selectedMeeting) => {
    setSelectedMeeting(selectedMeeting)
  }

  return (
    <Modal
      title={t('meetingSelectionModal.title')}
      closeModal={closeModal}
      width={'60%'}
      titleStyle={{ marginRight: 'auto', color: '#5780ca' }}
      modalContainerStyle={{ width: '99%', padding: '0px' }}
    >
      {isLoading && <InPageLoader inComponent={true} />}
      {!isLoading && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '0.5rem',
            width: '100%',
          }}
        >
          <SelectField
            // placeholder={t("formPlaceHolder.owner")}
            name="selectMeeting"
            value={selectedMeeting}
            options={meetings}
            onChange={updateTopicMeetings}
            getOptionLabel={(option) => option.meetingName}
            getOptionValue={(option) => option.meetingId}
            width={'45%'}
            menuPosition="fixed"
          />
          <div
            className="sleek-button sleek-blue"
            onClick={() => {
              if (selectedMeeting === null) {
                return
              }
              setSelectedMeetingId(selectedMeeting.meetingId)
              closeModal(true)
            }}
            style={{ marginLeft: 'auto' }}
          >
            <span>{t('Common:commonButtons.saveSmall')}</span>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default MeetingSelectionModal

import Modal from '../Modal'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { getEmailFromName } from '../../utils/general'
import { savePicassoObjectives } from '../../api/services/objectives.services'
import TextArea from 'rc-textarea'

const ConfirmEmailModal = ({
  isModalOpen,
  closeModal,
  newEmployee,
  objectiveList,
  setIsSendEmailModalOpen,
  newEmployeeWithId,
  setEmailInfo,
}) => {
  const { t } = useTranslation(['ObjectiveMap', 'commonButtons'])
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [newEmployeeInfo, setNewEmployeeInfo] = useState(
    newEmployee?.map((employee) => {
      return {
        name: employee,
        email: '',
      }
    }),
  )
  const [newEmployeeWithIdInfo, setNewEmployeeWithIdInfo] = useState(
    newEmployeeWithId?.map((employee) => {
      return {
        name: employee.name,
        email: getEmailFromName(employee.name),
        id: employee.id,
      }
    }),
  )

  const handleNameChange = (index, event) => {
    const newEmployees = [...newEmployeeInfo]
    newEmployees[index].name = event.target.value
    setNewEmployeeInfo(newEmployees)
    if (newEmployeeWithId.length > 0) {
      const newEmployeesWithId = [...newEmployeeWithIdInfo]
      newEmployeesWithId[index].name = event.target.value
      setNewEmployeeWithIdInfo(newEmployeesWithId)
    }
  }

  const handleEmailChange = (index, event) => {
    if (showErrorMessage) {
      setShowErrorMessage(false)
    }
    const newEmployees = [...newEmployeeInfo]
    newEmployees[index].email = event.target.value
    setNewEmployeeInfo(newEmployees)
    if (newEmployeeWithId.length > 0) {
      const newEmployeesWithId = [...newEmployeeWithIdInfo]
      newEmployeesWithId[index].email = event.target.value
      setNewEmployeeWithIdInfo(newEmployeesWithId)
    }
  }

  const saveObjectives = async () => {
    const response = await savePicassoObjectives(
      objectiveList.levelCards,
      newEmployeeInfo,
      newEmployeeWithIdInfo,
    )
    if (response.success) {
      setEmailInfo(newEmployeeWithIdInfo)
      closeModal()
      setIsSendEmailModalOpen(true)
    } else {
      console.log(response.error_message)
      setShowErrorMessage(true)
    }
  }

  useEffect(() => {}, [newEmployeeInfo])

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      width="70rem"
      modalContainerStyle={{ width: '93%' }}
      showCard={true}
      title={t('ObjectiveMap:modalTitle.comfirmEmailTitle')}
      titleStyle={{ color: 'rgb(91,132,203)' }}
      modalTitleContainerStyle={{ marginBottom: '0rem', justifyContent: 'flex-start' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ textAlign: 'left', marginTop: '2rem' }}>
          <span style={{ fontSize: '1.7rem' }}>
            {t('ObjectiveMap:setUpMessages.emailAndNameConfirmation')}
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: '20rem',
            marginTop: '1rem',
          }}
        >
          <div style={{ flexDirection: 'column' }}>
            <div style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              {t('ObjectiveMap:setUpMessages.name')}
            </div>
            {newEmployee?.map((employee, index) => (
              <div key={index} style={{ marginTop: '1rem' }}>
                {/* <input
                                    type="text"
                                    defaultValue={employee}
                                    onChange={(e) => handleNameChange(index, e)}
                                /> */}
                <div className="review-generated-topics-list-item">
                  <TextArea
                    type="text"
                    onChange={(e) => {
                      handleNameChange(index, e)
                    }}
                    value={newEmployeeInfo[index].name}
                    style={{
                      width: '10rem',
                      height: '1rem',
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '1.3rem',
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
          <div style={{ flexDirection: 'column' }}>
            <div style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              {t('ObjectiveMap:setUpMessages.email')}
            </div>
            {newEmployee?.map((employee, index) => (
              <div key={index} style={{ marginTop: '1rem' }}>
                {/* <input
                                    type="text"
                                    defaultValue={getEmailFromName(employee)}
                                    onChange={(e) => handleEmailChange(index, e)}
                                /> */}
                <div
                  className="review-generated-topics-list-item"
                  style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}
                >
                  <TextArea
                    type="text"
                    onChange={(e) => {
                      handleEmailChange(index, e)
                    }}
                    value={newEmployeeInfo[index].email}
                    style={{
                      width: '20rem',
                      height: '1rem',
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '1.3rem',
                    }}
                  />
                </div>
              </div>
            ))}
            {showErrorMessage && (
              <div style={{ color: 'red', fontSize: '1rem', marginTop: '1rem' }}>
                {t('ObjectiveMap:setUpMessages.emailError')}
              </div>
            )}
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
          <div
            onClick={saveObjectives}
            className="sleek-button sleek-full-blue"
            style={{ fontSize: '1rem', height: '2rem', cursor: 'pointer' }}
          >
            {t('Common:commonButtons.next')}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmEmailModal

import { useEffect } from 'react'
import { useLocation, useNavigationType, useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'

const useSentryRouting = () => {
  const location = useLocation()
  const navigationType = useNavigationType()

  useEffect(() => {
    // Set user and session information from localStorage when the app starts
    const userId = localStorage.getItem('tfei')
    if (userId) {
      Sentry.getGlobalScope().setTag('user_corr_id', userId)
      Sentry.setUser({ id: userId })
    }

    // Update Sentry context with the new route
    Sentry.setContext('navigation happended', { route: location.pathname, type: navigationType })
  }, [location, navigationType])
}

export default useSentryRouting

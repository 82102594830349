import React from 'react'
import { getSmoothStepPath } from 'reactflow'

import Swal from 'sweetalert2'

import { deleteConnection } from '../../../api/services/employee.services'

import $ from 'jquery'

import '../style.css'
import Toast from '../../../common/toast'

const foreignObjectSize = 40

const onEdgeClick = (evt, id) => {
  evt.stopPropagation()
  const split_Id = id.split('-')
  let value = 'rf__edge-' + id

  Swal.fire({
    title: `Are you sure want remove connection?`,
    text: 'You want to remove connection!',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    // reverseButtons: true
  }).then(async (result) => {
    if (result.isConfirmed) {
      const postData = {
        delegateTlId: split_Id[2],
        juniorTlId: split_Id[1],
      }
      try {
        const result = await deleteConnection(postData)

        if (result) {
          $(`[data-testid=${value}`).empty()
        }
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Something went wrong!',
        })
      }
    }
  })
}

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {
    stroke: '#f6ab6c',
  },
  markerEnd,
}) {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={labelX - foreignObjectSize / 2}
        y={labelY - foreignObjectSize / 2}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <div>
          <button className="edgebutton" onClick={(event) => onEdgeClick(event, id)}>
            ×
          </button>
        </div>
      </foreignObject>
    </>
  )
}

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getSetupInitData = async () => {
  const { data } = await axios.get(`${BASE_URL}/setupwiz_init`)

  return data
}

export default function useSetupInitData() {
  return useQuery(['setupInitData'], () => getSetupInitData(), {
    refetchOnWindowFocus: false,
  })
}

import React from 'react'
import { Formik, Field, Form } from 'formik'
import AddEnterpriseLeaderSchema from './formSchema'
import { useSetupWizardContext } from '../../../../../../context/setupWizardContext'

const AddEnterpriseLeaderModalForm = ({ handleSubmit, handleCancel, setResetForm }) => {
  const { state, dispatch } = useSetupWizardContext()

  const formikRef = React.useRef()

  React.useEffect(() => {
    if (formikRef.current !== undefined) {
      setResetForm(() => formikRef.current.resetForm)
    }
  }, [formikRef])

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={{
          // NOTE: set initial values back to empty strings post demo (AM-RAVL)
          name: '',
          title: '',
          // NOTE: Review how these are retrieved/set, and set in context if same for all employees
        }}
        validationSchema={AddEnterpriseLeaderSchema}
        onSubmit={(values, { resetForm }) => {
          dispatch({ type: 'ADD_ENTERPRISE_LEADER', enterpriseLeader: { ...values } })
          resetForm()
        }}
      >
        {({ errors, touched, resetForm }) => (
          <Form className="form" id="area-leader-modal-form">
            <div className="form-field">
              <label htmlFor="name">Name</label>
              <Field id="name" name="name" />
              {errors.name && touched.name && <div className="validation-error">{errors.name}</div>}
            </div>

            <div className="form-field">
              <label htmlFor="title">Title</label>
              <Field id="title" name="title" />
              {errors.title && touched.title && (
                <div className="validation-error">{errors.title}</div>
              )}
            </div>

            <div className="modal-dual-btn">
              <button
                className="dual-btn negative-btn"
                onClick={() => {
                  handleCancel()
                }}
                type="button"
              >
                CANCEL
              </button>
              <button type="submit" className="dual-btn positive-btn">
                SAVE
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default AddEnterpriseLeaderModalForm

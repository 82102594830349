import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const saveSpreadsheetData = async (objectiveId, changes_activities) => {
  const queryString = `/spread_sheet_save/${objectiveId}`

  const { data } = await axios.post(`${BASE_URL}${queryString}`, {
    changes_activities: changes_activities,
  })
  return data
}

export default function useSaveSpreadsheetData() {
  const queryClient = useQueryClient()

  return useMutation((data) => saveSpreadsheetData(data[0], data[1]), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      void queryClient.invalidateQueries('activities-spreadsheet-data')
    },
  })
}

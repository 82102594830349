import React, { useCallback, useEffect } from 'react'
import {
  ReactFlow,
  addEdge,
  Background,
  useNodesState,
  useEdgesState,
  MarkerType,
  MiniMap,
  Controls,
  ControlButton,
} from '@xyflow/react'
import '@xyflow/react/dist/style.css'

import FloatingEdge from './FloatingEdge'
import FloatingConnectionLine from './FloatingConnectionLine'
import { createNodesAndEdges } from './utils'
import './style.scss'
import { useBrainstorm } from '../../pages/Brainstorm/Recorder/BrainstormContext'
import MindMapItemNode from './MindMapItemNode'
import MindMapTopicNode from './MindMapTopicNode'
import MindMapProblemNode from './MindMapProblemNode'
import { postHttpRequest } from '../../api/query/dynamicAPI'
import { similarity } from '../../utils/general'
import SelectionModal from './Component/SelectionModal'
import AddEditMeetingTopicModal from '../AddEditMeetingTopicModal'
import { getCompanyConfigSettings } from '../../utils/auth'
import BrainstormAddActionDecisionModal from './Component/BrainstormAddActionDecisionModal'
import AddEditActivityModal from '../../pages/MyNotebook/AddEditActivityModal'
import AddEditObjectiveModal from '../AddEditObjectiveModal'
import { v4 as uuidv4 } from 'uuid'

const sortUserChanges = (a, b) => {
  // topic comes first
  if (a.id === undefined && b.id !== undefined) {
    // a comes first
    return -1
  }
  if (b.id === undefined && a.id !== undefined) {
    // b comes first
    return 1
  }

  return 0
}

const edgeTypes = {
  // floating: FloatingEdge,
}
const nodeTypes = {
  mindmapItemNode: MindMapItemNode,
  mindmapTopicNode: MindMapTopicNode,
  mindmapProblemNode: MindMapProblemNode,
}

const ENUMSELECTEDTYPE = Object.freeze({
  OBJECTIVE: 0,
  TOPIC: 1,
  ACTION: 2,
  DECISION: 3,
})

const MindMap = ({ jsonData, readOnly, brainstormId = null }) => {
  const [itemIndices, setItemIndex] = React.useState(0)
  const [initialNodes, setInitialNodes] = React.useState([])
  const [initialEdges, setInitialEdges] = React.useState([])
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges)
  const { state, dispatch } = useBrainstorm()

  const [mindmap, setMindmap] = React.useState(jsonData)
  const [triggerApiCall, setTriggerApiCall] = React.useState(false)
  const newTopicTemplate = {
    topic: 'New: click to edit',
    user_added: true,
    decisions: [],
    comments: [],
    actions: [],
    newTopic: true,
  }

  // const [userJustAddedNewTopic, setUserJustAddedNewTopic] = React.useState(false)
  const [userChanges, setUserChanges] = React.useState([])
  const [isSelectionModalOpen, setIsSelectionModalOpen] = React.useState(false)
  const [selectedNode, setSelectedNode] = React.useState({})
  const [selectedType, setSelectedType] = React.useState(null)
  const [isAddMeetingTopicOpen, setIsAddMeetingTopicOpen] = React.useState(false)
  const [isAddCommentModalOpen, setIsAddCommentModalOpen] = React.useState(false)
  const [isAddActionModalOpen, setIsAddActionModalOpen] = React.useState(false)

  const isProductSettingsEnabled = getCompanyConfigSettings('productSettings')
  const [isAddEditObjModalOpen, setIsAddEditObjModalOpen] = React.useState(false)

  const userId = localStorage.getItem('tfei')

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            // type: 'floating',
            markerEnd: { type: MarkerType.Arrow },
          },
          eds,
        ),
      ),
    [setEdges, itemIndices],
  )

  const onRemoveItem = (description, topicIndex, itemIndex, itemId) => {
    console.log('remove item', description)
    dispatch({ type: 'ADD_TO_DISCARDED_LIST', description: description })
    // add to discarded list
    // and remove corresponding item from userChanges
    if (!readOnly) {
      console.log('removed topicIndex', topicIndex)
      console.log('removed itemIndex', itemIndex)
      console.log('removed itemId', itemId)

      if (itemIndex === undefined) {
        // if we are removing a topic, we need to remove all actions and decisions from user changes too
        setUserChanges((userChanges) =>
          userChanges
            .filter((change) => change.topicIndex !== topicIndex)
            .map((change) => {
              return {
                ...change,
                topicIndex: change.topicIndex - (change.topicIndex > topicIndex ? 1 : 0),
              }
            }),
        )
      } else {
        // otherwise, we should remove the specific action or decision whose topicIndex and id matches
        setUserChanges((userChanges) =>
          userChanges.filter(
            (change) => !(change.topicIndex === topicIndex && change.id == itemId),
          ),
        )
      }
    }
  }

  React.useEffect(() => {
    console.log('new userChanges', userChanges)
  }, [userChanges])

  // const calculateIndexInDecisionsOrActionsFromOverallIndex = (topic, actionDecisionIndex, includeRecommended) => {
  //   // actionDecisionIndex is the index of the action or decision in the overall list of actions and decisions,
  //   // it's calculated from utils.js createNodesAndEdges function
  //   // since actionDecisionIndex is based on the total number of actions and decisions in the topic,
  //   // we need to calculate the index in the actions or decisions array, given decisions array comes first
  //   console.log('topic', JSON.parse(JSON.stringify(topic)))
  //   console.log('actionDecisionIndex', actionDecisionIndex)
  //   let decisionsCount = topic.decisions.length
  //   let commentsCount = topic.comments.length
  //   let actionsCount = topic.actions.length

  //   if(actionDecisionIndex < decisionsCount){
  //     return actionDecisionIndex
  //   }
  //   if(actionDecisionIndex < decisionsCount + commentsCount){
  //     return actionDecisionIndex - decisionsCount
  //   }

  //   if(!includeRecommended){
  //     return actionDecisionIndex - decisionsCount - commentsCount
  //   }else{
  //     if(actionDecisionIndex < decisionsCount + commentsCount + actionsCount){
  //       return actionDecisionIndex - decisionsCount - commentsCount
  //     }
  //     return actionDecisionIndex - decisionsCount - commentsCount - actionsCount
  //   }

  //   // if(!includeRecommended){
  //   //   return actionDecisionIndex - decisionsCount
  //   // }
  //   // else{
  //   //   let actionsCount = topic.actions.length

  //   //   if(actionDecisionIndex < decisionsCount + actionsCount){
  //   //     return actionDecisionIndex - decisionsCount
  //   //   }
  //   //   return actionDecisionIndex - decisionsCount - actionsCount
  //   // }
  // }

  const updateMindMapText = async (
    topicText,
    previousText,
    text,
    topicIndex,
    actionDecisionId,
    actionDecisionType,
  ) => {
    console.log('previousText, text: ', previousText, text)
    if (text.trim() === '') {
      return
    }
    // NEW CHANGES FROM THIS POINT ONWARDS
    // {
    //   "decisions": [],
    //   "comments": [
    //     {
    //       "id": "a31a4061-d819-489f-b4a1-0a88a9600e5c",
    //       "is_converted": false,
    //       "text": "The current messaging and positioning needs to be rethought as part of the golden pitch certification."
    //     }
    //   ]
    // }
    console.log('mindmap ooo', mindmap)
    setMindmap((prevMindmap) => {
      console.log('prevMindmap', prevMindmap)
      console.log('updateText, index', text, topicIndex, actionDecisionId, actionDecisionType)
      const data = structuredClone(prevMindmap)
      console.log('old mm:', JSON.parse(JSON.stringify(data)))
      if (actionDecisionId !== undefined) {
        let topicNode = data.action_plans[topicIndex]
        // let correctIndexInActionsOrDecisions = calculateIndexInDecisionsOrActionsFromOverallIndex(topicNode, actionDecisionIndex)
        console.log('actionDecisionId type: ', typeof actionDecisionId)
        let itemType =
          actionDecisionType === 'action'
            ? 'actions'
            : actionDecisionType === 'comment'
              ? 'comments'
              : 'decisions'
        let itemIndex = topicNode[itemType].findIndex((item) => item.id == actionDecisionId)
        console.log('itemIndex', itemIndex)
        if (itemIndex === -1) {
          console.log('itemIndex === -1')
          return data
        } else {
          console.log('itemIndex !== -1')
          topicNode[itemType][itemIndex].text = text
        }

        // topicNode[itemType][actionDecisionIndex].text = text
      } else {
        data.action_plans[topicIndex]['topic'] = text
        // to handle case:
        // user creates a new topic, then MM request is sent, then user edits the new topic
        // ideally it should be a new topic, but we remove all 'new' flag as soon as MM request is sent
        if (previousText && previousText.trim() === 'New: click to edit') {
          data.action_plans[topicIndex]['newTopic'] = true
        }
      }

      console.log('new mm: ', data)
      return data
    })
    setTriggerApiCall(true)
    setUserChanges((prevChanges) => {
      let newUserChanges = [
        // ...deDupedChanges,
        ...prevChanges,
        {
          type: 'text',
          topicIndex: topicIndex,
          // actionDecisionIndex: actionDecisionIndex,
          dataType: actionDecisionType,
          id: actionDecisionId,
          value: text,
          topicText: topicText,
        },
      ]
      // if it's updating a topic, then we should update all changes topicText, whose topicIndex is the same
      // otherwise, leave them as is.
      if (actionDecisionId === undefined) {
        newUserChanges = newUserChanges.map((change) => {
          if (change.topicIndex === topicIndex) {
            return {
              ...change,
              topicText: topicText,
            }
          }
          return change
        })
      }
      newUserChanges.sort(sortUserChanges)

      return newUserChanges
    })
  }

  React.useEffect(() => {
    if (triggerApiCall && mindmap) {
      ;(async () => {
        // update brainstorm map in context with latest mindmap
        dispatch({
          type: 'SET_BRAINSTORM_MAP',
          brainstormMap: {
            // 'problem' is probably not needed here because mindmap is already with problem
            problem: state.primaryProblem,
            action_plans: mindmap.action_plans,
          },
        })
        await postHttpRequest(`/brainstorm/update_brainstorm_instance/${state.meetingInstanceId}`, {
          brainstormMap: {
            action_plans: mindmap.action_plans.filter(
              (topic) =>
                topic.topic && topic.topic !== '' && !state.discardedList.includes(topic.topic),
            ),
          },
        })
        if (readOnly) {
          try {
            await postHttpRequest(`/brainstorm/save_brainstorm_session`, {
              meetingInstanceId: state.meetingInstanceId,
              discardedList: state.discardedList,
              highlightedSubProblems: state.highlightedQuestions,
            })
          } catch (error) {
            console.log('error saving brainstorm session', error)
          }
        }

        releaseMindMap()
      })()
    }
    setTriggerApiCall(false)
  }, [mindmap, triggerApiCall])

  const lockMindMap = () => {
    dispatch({ type: 'SET_MINDMAP_LOCKED', isMindMapLocked: true })
  }

  const releaseMindMap = () => {
    dispatch({ type: 'SET_MINDMAP_LOCKED', isMindMapLocked: false })
  }

  const addNewItem = (topic) => {
    console.log('add new item', topic)
    setMindmap((prevMindmap) => {
      const data = structuredClone(prevMindmap)
      data.action_plans[topic.index].actions.push({
        text: 'New Action: click to edit',
        is_converted: false,
        id: uuidv4(),
      })

      return data
    })
  }

  const addNewTopic = () => {
    setMindmap((prevMindmap) => {
      const data = structuredClone(prevMindmap)
      data.action_plans.push(newTopicTemplate)
      return data
    })
  }

  const switchActionDecision = (topicText, topicIndex, actionDecisionId, dataType, text) => {
    // be careful with null value from item[0], especially when the item is recommended.,,
    // action -> decision -> comment -> action
    let nextValue
    if (dataType === 'action') {
      nextValue = 'decisions'
    } else if (dataType === 'decision') {
      nextValue = 'comments'
    } else if (dataType === 'comment') {
      nextValue = 'actions'
    }
    console.log('nextValue', nextValue)

    setMindmap((prevMindmap) => {
      const data = structuredClone(prevMindmap)
      let topicNode = data.action_plans[topicIndex]
      console.log('topicNode switchActionDecision', topicNode)
      // let correctIndexInActionsOrDecisions = calculateIndexInDecisionsOrActionsFromOverallIndex(topicNode, actionDecisionIndex)

      let itemType =
        dataType === 'action' ? 'actions' : dataType === 'comment' ? 'comments' : 'decisions'
      let itemIndex = topicNode[itemType].findIndex((item) => item.id == actionDecisionId)
      console.log('itemIndex', itemIndex)
      if (itemIndex === -1) {
        console.log('itemIndex === -1')
        return data
      } else {
        console.log('itemIndex !== -1')
        let item = topicNode[itemType].splice(itemIndex, 1)
        console.log('item switchActionDecision', item)
        topicNode[nextValue].push(item[0])
      }

      return data
    })
    setUserChanges((prevChanges) => {
      return [
        ...prevChanges,
        {
          type: 'switch',
          topicIndex: topicIndex,
          // actionDecisionIndex: actionDecisionIndex,
          dataType: dataType,
          id: actionDecisionId,
          value: nextValue,
          text: text,
          topicText: topicText,
        },
      ]
    })
    setTriggerApiCall(true)
  }

  const removeDiscardedItems = React.useCallback(
    (topic, index) => {
      // Filter decisions
      topic.decisions = topic.decisions.filter(
        (decision) => !state.discardedList.includes(decision.text),
      )

      // Filter actions
      topic.actions = topic.actions.filter((action) => !state.discardedList.includes(action.text))
      // Filter comments
      topic.comments =
        topic?.comments?.filter((comment) => !state.discardedList.includes(comment.text)) ?? []

      // Filter recommended actions
      if (topic.recommended_actions) {
        topic.recommended_actions = topic.recommended_actions.filter(
          (recommendedAction) => !state.discardedList.includes(recommendedAction.text),
        )
      }
    },
    [state.discardedList],
  )

  const forwardOpenModal = (topicIndex, actionDecisionId, dataType, isRecommended) => {
    setSelectedNode({ topicIndex, actionDecisionId, dataType, isRecommended })
    setIsSelectionModalOpen(true)
  }

  React.useEffect(() => {
    if (mindmap) {
      console.log('mm', mindmap)
      let data = structuredClone(mindmap)
      if (data?.problem && data?.action_plans) {
        console.log('ooooooooooooooo')

        data.action_plans = data.action_plans.filter(
          (topic) =>
            topic.topic && topic.topic !== '' && !state.discardedList.includes(topic.topic),
        )

        data.action_plans.forEach(removeDiscardedItems)
        const { nodes: newNodes, edges: newEdges } = createNodesAndEdges(
          mindmap,
          onRemoveItem,
          updateMindMapText,
          lockMindMap,
          state.readOnly,
          addNewItem,
          switchActionDecision,
          forwardOpenModal,
          addNewTopic,
        )
        console.log('newNodes', newNodes)
        setNodes(newNodes)
        setEdges(newEdges)
      }
    }
  }, [mindmap])

  const updateDiscardedList = async () => {
    try {
      await postHttpRequest(`/brainstorm/update_discarded_list/${state.meetingInstanceId}`, {
        discardedList: state.discardedList,
      })
    } catch (error) {
      console.log('error update_discarded_list: ', error)
    }
  }

  React.useEffect(() => {
    if (mindmap) {
      setMindmap((prevMindmap) => {
        let data = structuredClone(prevMindmap)
        console.log('kkkkkkkkkk')

        data.action_plans = data.action_plans.filter(
          (topic) =>
            topic.topic && topic.topic !== '' && !state.discardedList.includes(topic.topic),
        )

        data.action_plans.forEach(removeDiscardedItems)

        return data
      })

      setUserChanges((userChanges) =>
        userChanges.filter(
          (change) => change.type === 'switch' || !state.discardedList.includes(change.value),
        ),
      )
    }
    if (readOnly) {
      updateDiscardedList()
    }
  }, [state.discardedList])

  React.useEffect(() => {
    let data = structuredClone(jsonData)
    console.log('jsonData mm: ', jsonData)
    if (data?.problem && data?.action_plans) {
      data.action_plans = data.action_plans.filter(
        (topic) => topic.topic && topic.topic !== '' && !state.discardedList.includes(topic.topic),
      )
      data.action_plans.forEach(removeDiscardedItems)

      // update mindmap, with new values from jsonData, while keeping the user changes
      // ....
      console.log('userChanges mm', userChanges)
      setMindmap((prevMindmap) => {
        console.log('prevMindmap', JSON.parse(JSON.stringify(prevMindmap)))
        let updatedMindmap = structuredClone(data)
        console.log('updatedMindmap', JSON.parse(JSON.stringify(updatedMindmap)))
        let newTopicIndexMapping = {}
        let newTopicSet = new Set()
        userChanges.forEach((change) => {
          // there will be only one TYPE for every (topicIndex, actionDecisionIndex) pair
          console.log('newTopicIndexMapping', newTopicIndexMapping)
          // ~example: change.topicIndex = 10, there were 5 topics removed, so we can't use 10 anymore~
          // I think we handle this via constanly updating topicIndex
          let topicIndex = newTopicIndexMapping.hasOwnProperty(change.topicIndex)
            ? newTopicIndexMapping[change.topicIndex]
            : change.topicIndex
          console.log('topicIndex: ', topicIndex)
          // it means this topic has been removed so no actions/decisions too..
          // another case to consider is:
          // after MM request is sent, createa a new topic, then add a new action/decision, etc (optional then remove some topics before it)
          // userChanges will be [{'text', 'new topic text'}, {'text', 'new action text'} etc]
          // after the new topic is updated, usually newTopicIndexMapping = {change.topicIndex : change.topicIndex + optional ? 0 : 1 (maybe)}
          // so the 'new action text' will be skipped. since we
          // 1. sort userChanges, so every topic will be updated first
          // 2. if the topic is new, we add 'existingTopic' to the mindmap, which includes actions/decisions
          if (topicIndex >= prevMindmap.action_plans.length || newTopicSet.has(topicIndex)) {
            console.log('topicIndex >= updatedMindmap.action_plans.length')

            // find the topic index based on the topic text from mindmap (before response comes back)
            topicIndex = prevMindmap.action_plans.findIndex(
              (topic) => topic.topic === change.topicText,
            )

            // if topic is already been removed or the topic has been added as a new topic (see 'another case' above) skip
            // something to consider -> topic text can change???
            if (state.discardedList.includes(change.topicText) || newTopicSet.has(topicIndex))
              return
          }
          let existingTopic = prevMindmap.action_plans[topicIndex]
          if (existingTopic === undefined) {
            console.log('existingTopic undefined')
            return
          }
          console.log('existingTopic', JSON.parse(JSON.stringify(existingTopic)))
          if (change.type === 'text') {
            console.log('text change mm', change)
            if (change.id !== undefined) {
              let itemType

              if (change.dataType === 'action') {
                itemType = 'actions'
              } else if (change.dataType === 'decision') {
                itemType = 'decisions'
              } else if (change.dataType === 'comment') {
                itemType = 'comments'
              }

              let topicNodeUpdatedMindmap = updatedMindmap.action_plans[topicIndex]
              if (topicNodeUpdatedMindmap === undefined) {
                console.log('topicNodeUpdatedMindmap undefined')
                return
              }

              let itemIndex = -1
              itemIndex = topicNodeUpdatedMindmap[itemType].findIndex(
                (item) => item.id == change.id,
              )

              // if itemIndex is -1, it means the item is not in the updatedMindmap, so we need to add it
              if (itemIndex === -1) {
                console.log('bigger than length')
                updatedMindmap.action_plans[topicIndex][itemType].push({
                  text: 'New Action: click to edit',
                  is_converted: false,
                  id: change.id,
                })
                let lastIndex = updatedMindmap.action_plans[topicIndex][itemType].length - 1
                updatedMindmap.action_plans[topicIndex][itemType][lastIndex].text = change.value
              } else {
                console.log('smaller than length')
                updatedMindmap.action_plans[topicIndex][itemType][itemIndex].text = change.value
              }
            } else {
              console.log('existingTopic again', JSON.parse(JSON.stringify(existingTopic)))
              console.log('existingTopic.newTopic', existingTopic.newTopic)
              // one edge case:
              // user add a new topic without editing it, then MM request is sent, then editing it
              // then this topic is not a new Topic.... -> handle it from updateMindMap()
              if (existingTopic.newTopic) {
                console.log('new topic')
                updatedMindmap.action_plans.push(existingTopic)
                newTopicIndexMapping[topicIndex] = updatedMindmap.action_plans.length - 1
                topicIndex = updatedMindmap.action_plans.length - 1
                newTopicSet.add(topicIndex)
              } else {
                console.log('existing topic')
                if (topicIndex >= updatedMindmap.action_plans.length) {
                  console.log('topicIndex >= updatedMindmap.action_plans.length')
                  return
                }
                updatedMindmap.action_plans[topicIndex].topic = change.value
              }
            }
            console.log('topicIndex xxx', topicIndex)
            console.log(
              'updatedMindmap.action_plans[topicIndex]: ',
              updatedMindmap.action_plans[topicIndex],
            )
          } else if (change.type === 'switch') {
            console.log('switch change', change)
            // I don;t think the if is needed nor can be reached
            if (
              existingTopic?.newTopic &&
              updatedMindmap.action_plans.length < prevMindmap.action_plans.length
            ) {
              console.log('12345678')
              updatedMindmap.action_plans.push(existingTopic)
              newTopicIndexMapping[topicIndex] = updatedMindmap.action_plans.length - 1
              topicIndex = updatedMindmap.action_plans.length - 1
              newTopicSet.add(topicIndex)
            }
            let topicNode = updatedMindmap.action_plans[topicIndex]
            if (topicNode === undefined) {
              console.log('topicNode undefined')
              return
            }
            console.log('topicNode', JSON.parse(JSON.stringify(topicNode)))
            // let correctIndexInActionsOrDecisions = calculateIndexInDecisionsOrActionsFromOverallIndex(topicNode, change.actionDecisionIndex)
            // console.log('correctIndexInActionsOrDecisions', correctIndexInActionsOrDecisions)

            let itemType

            if (change.dataType === 'action') {
              itemType = 'actions'
            } else if (change.dataType === 'decision') {
              itemType = 'decisions'
            } else if (change.dataType === 'comment') {
              itemType = 'comments'
            }

            let itemIndex = topicNode[itemType].findIndex((item) => item.id == change.id)
            console.log('itemIndex', itemIndex)
            if (itemIndex === -1) {
              console.log('itemIndex === -1')
              return
            }

            let item = topicNode[itemType].splice(itemIndex, 1)
            console.log('item', item)
            // this action or decision was removed or, so no need to switch
            // if(!item || item.length === 0) return
            // try to minimize a bug as much as possible ->
            // switch an item, it could possibly remove a different item.. since we use splice()...
            // if(similarity(item[0].text, change.text) < 80){
            //   console.log('similarity < 80')
            //   topicNode[itemType].splice(itemIndex, 0, item[0])
            // }

            topicNode[change.value].push({
              ...item[0],
              text: change.text,
            })
          }
        })

        updatedMindmap.action_plans.forEach((topic) => {
          if (topic.newTopic) {
            topic.newTopic = false
          }
        })
        return updatedMindmap
      })
      setTriggerApiCall(true)
    }

    setUserChanges([])
  }, [state.isUpdatingMMCount])

  React.useEffect(() => {
    if (state.isUpdatingMM) {
      console.log('reset all new topic to false')
      setMindmap((prevMindmap) => {
        let updatedMindmap = structuredClone(prevMindmap)
        updatedMindmap.action_plans.forEach((topic) => {
          if (topic.newTopic) {
            topic.newTopic = false
          }
        })
        return updatedMindmap
      })
      setUserChanges([])
    }
  }, [state.isUpdatingMM])

  const setTypeSelection = (selection) => {
    console.log('selection', selection)
    setSelectedType(selection)
  }

  const retrieveItem = (topicNode) => {
    // let itemIndex = calculateIndexInDecisionsOrActionsFromOverallIndex(topicNode, selectedNode.actionDecisionIndex, true)
    let itemType =
      selectedNode.dataType === 'action'
        ? 'actions'
        : selectedNode.dataType === 'decision'
          ? 'decisions'
          : 'comments'
    let itemIndex = topicNode[itemType].findIndex(
      (item) => item.id == selectedNode.actionDecisionId,
    )

    let item
    if (selectedNode.isRecommended) {
      item = topicNode['recommended_actions'][itemIndex]
    } else {
      let value
      if (selectedNode.dataType === 'action') {
        value = 'actions'
      } else if (selectedNode.dataType === 'decision') {
        value = 'decisions'
      } else if (selectedNode.dataType === 'comment') {
        value = 'comments'
      }

      item = topicNode[value][itemIndex]
    }

    return item
  }

  React.useEffect(() => {
    console.log('selectedType', selectedType)
    if (selectedType !== null) {
      switch (selectedType) {
        case ENUMSELECTEDTYPE.OBJECTIVE:
          console.log('objective')
          setIsAddEditObjModalOpen(true)
          break
        case ENUMSELECTEDTYPE.TOPIC:
          console.log('topic')
          setIsAddMeetingTopicOpen(true)
          break
        case ENUMSELECTEDTYPE.ACTION:
          console.log('action')
          setIsAddActionModalOpen(true)
          break
        case ENUMSELECTEDTYPE.DECISION:
          console.log('decision')
          setIsAddCommentModalOpen(true)
          break
        default:
          console.log('default')
          break
      }
    }
  }, [selectedType])

  const loadAgain = () => {
    console.log('load again', selectedNode)
    // add a new field to the item
    setMindmap((prevMindmap) => {
      const data = structuredClone(prevMindmap)
      let topicNode = data.action_plans[selectedNode.topicIndex]
      let item = retrieveItem(topicNode)

      item.is_converted = true

      return data
    })
    setSelectedNode({})
    setTriggerApiCall(true)
  }

  const calculateTextInputIn = React.useMemo(() => {
    if (!(Object.keys(selectedNode).length > 0 && mindmap)) return ''

    console.log('calculateTextInputIn', selectedNode)
    let topicNode = mindmap.action_plans[selectedNode.topicIndex]
    let item = retrieveItem(topicNode)
    return item.text
  }, [selectedNode, mindmap])

  return (
    <>
      {isSelectionModalOpen && Object.keys(selectedNode).length > 0 && (
        <SelectionModal
          closeModal={() => {
            // setSelectedNode({})
            setIsSelectionModalOpen(false)
          }}
          setTypeSelection={setTypeSelection}
        />
      )}
      {isAddMeetingTopicOpen && (
        <AddEditMeetingTopicModal
          isModalOpen={isAddMeetingTopicOpen}
          closeModal={() => {
            setIsAddMeetingTopicOpen(false)
            setSelectedType(null)
          }}
          loadAgain={loadAgain}
          isProductSettingsEnabled={isProductSettingsEnabled}
          refreshBGUScorecardFn={() => {}}
          showMineButton={false}
          textInputIn={calculateTextInputIn}
          brainstormSessionId={brainstormId}
        />
      )}
      {isAddActionModalOpen && (
        <AddEditActivityModal
          isModalOpen={isAddActionModalOpen}
          closeModal={() => {
            setIsAddActionModalOpen(false)
            setSelectedType(null)
            // loadAgain()
          }}
          actionText={calculateTextInputIn}
          loadAgain={loadAgain}
          brainstormSessionId={brainstormId}
        />
      )}
      {isAddCommentModalOpen && (
        <BrainstormAddActionDecisionModal
          closeModal={() => {
            setIsAddCommentModalOpen(false)
            setSelectedType(null)
          }}
          text={calculateTextInputIn}
          loadAgain={loadAgain}
        />
      )}
      {isAddEditObjModalOpen && (
        <AddEditObjectiveModal
          closeModal={() => {
            setIsAddEditObjModalOpen(false)
            setSelectedType(null)
          }}
          isAddEditObjectiveModalOpen={isAddEditObjModalOpen}
          mode={'add'}
          setEditedObj={() => {}}
          objData={{
            statement: calculateTextInputIn,
            ownerId: userId,
          }}
          setObjData={() => {}}
          loadAgain={loadAgain}
          isFromObjectiveMap={true}
        />
      )}
      <br />
      <br />
      <br />
      {/* <button onClick={() => setItemIndex(itemIndex + 1)}>Next</button> */}
      <div className="floatingedges" style={{ border: '0px solid #000000' }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          // fitView
          edgeTypes={edgeTypes}
          nodeTypes={nodeTypes}
          // connectionLineComponent={FloatingConnectionLine}
          //defaultViewport={ x: 0, y: 0, zoom: 1 }
        >
          <Controls orientation={'horizontal'} showInteractive={false}>
            {/* {!state.readOnly && <ControlButton onClick={addNewTopic}>
          New Topic
        </ControlButton>} */}
          </Controls>
        </ReactFlow>
      </div>
    </>
  )
}

export default MindMap

import styled from 'styled-components'

const ColumnContainer = styled.div`
  border: 1px solid rgb(242, 242, 242);
  border-radius: 2px;
  width: 20%;
  display: flex;
  flex-direction: column;
  // box-shadow: 0 5px 15px #ccc;
`

const Title = styled.div`
  padding: 8px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  background-color: rgb(242, 242, 242);
  display: flex;
  align-items: center;
  justify-content: center;
`

const TaskList = styled.div`
  padding: 8px;
  background-color: ${(props) => (props.isDraggingOver ? 'rgb(242,242,242)' : 'white')};
  flex-grow: 1;
  min-height: 100px;
`

export { ColumnContainer, Title, TaskList }

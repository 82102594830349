import React from 'react'
import { useDemandIntakeContext } from '../../context/demandIntakeContext'
import { useOutletContext } from 'react-router-dom'

const ChooseBauObjective = () => {
  const { state, dispatch } = useDemandIntakeContext()
  const { setErrorMessage } = useOutletContext()
  const [bauValue, setBauValue] = React.useState('none')

  const handleChange = (value) => {
    setErrorMessage(null)
    setBauValue(value)

    if (value === 'none') {
      dispatch({ type: 'SET_OBJ_ASSOCIATION', objectiveAssociation: null })
      dispatch({ type: 'SET_LINKED_OBJECTIVE_ID', linkedObjectiveId: null })
      setBauValue(null)
      return
    } else if (parseInt(value) === 0) {
      dispatch({ type: 'SET_OBJ_ASSOCIATION', objectiveAssociation: parseInt(value) })
      dispatch({ type: 'SET_LINKED_OBJECTIVE_ID', linkedObjectiveId: null })
    }
    let objectives = state?.data?.objectives ?? []
    for (let obj of objectives) {
      if (parseInt(obj.id) === parseInt(value)) {
        dispatch({ type: 'SET_OBJ_ASSOCIATION', objectiveAssociation: null })
        dispatch({ type: 'SET_LINKED_OBJECTIVE_ID', linkedObjectiveId: parseInt(value) })
      }
    }
  }

  return (
    <>
      <div className="step-section">
        <h2>Select a BAU objective to associate the suggestion with </h2>
        <div className="blocking-instructions">
          <div className="instruction">
            <span>
              Given the relatively small effort required, this item will be classified as a
              milestone under an existing BAU objective. Small efforts like these tend to be part of
              BAU work in typical organizations.
            </span>
          </div>
        </div>
        <table className="blocking-table shadow">
          <tbody>
            <select
              className="exec-goal-select"
              onChange={(e) => handleChange(e.target.value)}
              value={bauValue}
            >
              <option value="none">Select BAU Objective</option>

              {state?.data?.objectives
                .filter((obj, objIndex) => obj.typeId === 3)
                .map((objective, objectiveIndex) => (
                  <option
                    value={objective.id}
                    key={`${objectiveIndex}-objective-${objectiveIndex}`}
                    id={objective.id}
                  >
                    {objective.statement}
                  </option>
                ))}
              <option value="0">Create new BAU objective</option>
            </select>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default ChooseBauObjective

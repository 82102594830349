import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getActivitiesChartData = async (objectiveId, filter, isSplitByDueDate) => {
  const queryString = `/activities_chart_data/${objectiveId}/${isSplitByDueDate}`
  const { data } = await axios.get(`${BASE_URL}${queryString}`, {
    params: {
      filter: filter,
    },
  })
  return data
}

export default function useGetActivitiesChartData() {
  const queryClient = useQueryClient()

  return useMutation((data) => getActivitiesChartData(data[0], data[1], data[2]), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      void queryClient.invalidateQueries('activities-chart-data')
    },
  })
}

import React, { useState, useEffect } from 'react'
import { Formik, Field, Form } from 'formik'
import Modal from '../Modal'
import ModalSaveButton from '../ModalSubmitButton'
import { useTranslation } from 'react-i18next'
import SelectField from '../CustomMultipleSelect/customSelect'

const SelectLeaderModal = ({ isModalOpen, closeModal, handleSubmit, employees }) => {
  const { t } = useTranslation(['Common'])
  const filteredEmployees = employees?.employees?.filter(
    (employee) =>
      employee.name !== localStorage.getItem('tfmnm') &&
      employee.name !== localStorage.getItem('tfin'),
  )

  const validate = (values) => {
    const errors = {}
    if (!values.selectedTeamLeader) {
      errors.selectedTeamLeader = t('formError.required')
    }
    return errors
  }

  const [resetForm, setResetForm] = useState(null)

  const formikRef = React.useRef()

  useEffect(() => {
    if (formikRef.current !== undefined) {
      setResetForm(() => formikRef.current.resetForm)
    }
  }, [formikRef])

  return (
    <>
      <Modal title={t('selectLeaderModal.selectLeader')} closeModal={closeModal}>
        <Formik
          enableReinitialize
          initialValues={{
            selectedTeamLeader: null,
          }}
          validate={validate}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values.selectedTeamLeader)
            resetForm()
            closeModal()
          }}
        >
          {({ errors, touched, resetForm }) => (
            <Form
              className="objective-form"
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <div>
                <div className="form-field action-field">
                  <Field {...SelectField} name="selectedTeamLeader">
                    {({ field }) => (
                      <SelectField
                        field={field}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.eId}
                        options={filteredEmployees}
                        isMulti={false}
                        isClearable={false}
                        menuShouldScrollIntoView={false}
                        menuPosition="fixed"
                      />
                    )}
                  </Field>
                </div>
                {errors.selectedTeamLeader && touched.selectedTeamLeader && (
                  <div className="validation-error" style={{ textAlign: 'left' }}>
                    {errors.selectedTeamLeader}
                  </div>
                )}
              </div>
              <div className="modal-dual-btn">
                <button
                  className="fitted-button blue spacing-bottom"
                  style={{ background: 'white', padding: '0.4rem' }}
                  onClick={() => {
                    resetForm()
                    closeModal()
                  }}
                  type="button"
                >
                  {t('selectLeaderModal.cancel')}
                </button>
                <button
                  className="fitted-button blue spacing-bottom"
                  style={{ background: 'white', padding: '0.4rem', marginLeft: '1rem' }}
                  type="submit"
                  data-testid={'submit'}
                >
                  {t('selectLeaderModal.loadMeetingTopics')}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default SelectLeaderModal

import React from 'react'
import { useTranslation } from 'react-i18next'
import { getHttpRequest } from '../../../api/query/dynamicAPI'
import MeetingActions from './MeetingActions'
import OtherActivities from './OtherActivities'
import InPageLoader from '../../../components/InPageLoader'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../utils/routes'

const PendingActivitiesList = ({
  refreshBGUScorecardFn = () => {},
  eIdFilter = null,
  fromHomePage = false,
  printMode = false,
  showFlaggedOnly = false,
  searchFilterInput = '',
}) => {
  const [meetingActionsData, setMeetingActionsData] = React.useState([])
  const [otherActivities, setOtherActivities] = React.useState([])
  const { t } = useTranslation(['Common'])
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const [refreshActivitiesData, setRefreshActivitiesData] = React.useState(false)

  React.useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        let url =
          eIdFilter === null
            ? '/get_reminder_email_actions'
            : `/get_reminder_email_actions/${eIdFilter}`
        let response = await getHttpRequest(url)
        setMeetingActionsData(response)
        setOtherActivities(response)
        setLoading(false)
      } catch (error) {}
    })()
  }, [refreshActivitiesData, eIdFilter])

  const refreshPendingListData = () => {
    setRefreshActivitiesData((prev) => !prev)
  }

  if (loading) {
    return <InPageLoader inComponent={true} />
  }

  return (
    <>
      <div style={{ paddingBottom: '10px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '1.5rem',
            fontWeight: 'bold',
            columnGap: '1rem',
          }}
        >
          <div>{t('emailReminder.title')}</div>
          {printMode === false && (
            <div
              class="fitted-button blue"
              onClick={() => {
                window.open(`/${routes.printMode}?page=EmailRemainder`, '_blank')
              }}
            >
              <span class="material-symbols-outlined">print</span>
            </div>
          )}
        </div>
        <MeetingActions
          data={meetingActionsData}
          refreshPendingListData={refreshPendingListData}
          refreshBGUScorecardFn={refreshBGUScorecardFn}
          fromHomePage={fromHomePage}
          printMode={printMode}
          showFlaggedOnly={showFlaggedOnly}
          searchFilterInput={searchFilterInput}
        />
        <OtherActivities
          data={otherActivities}
          refreshPendingListData={refreshPendingListData}
          refreshBGUScorecardFn={refreshBGUScorecardFn}
          printMode={printMode}
          showFlaggedOnly={showFlaggedOnly}
          searchFilterInput={searchFilterInput}
        />
      </div>
    </>
  )
}

export default PendingActivitiesList

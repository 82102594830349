// StreamAudioRecorder.js
import React, { useEffect } from 'react'
import { useBrainstorm } from '../../pages/Brainstorm/Recorder/BrainstormContext'
import { useTranslation } from 'react-i18next'
import ActivateSettingsTutorialModal from '../../components/ActivateSettingsTutorialModal'
import { detectBrowser } from '../../utils/general'
import { getConsolidatedConfigSettings } from '../../utils/auth'

const BrainstormAudioButton = ({}) => {
  const { state, startRecording } = useBrainstorm()
  const [isStartModalOpen, setIsStartModalOpen] = React.useState(false)
  const brainstormingTutorialOff = getConsolidatedConfigSettings('brainstorming_tutorial_off')
  const { t } = useTranslation(['Common'])

  return (
    <>
      {isStartModalOpen && (
        <ActivateSettingsTutorialModal
          isModalOpen={isStartModalOpen}
          closeModal={() => setIsStartModalOpen(false)}
          startRecording={startRecording}
        />
      )}

      <div
        style={{
          display: `${state.isBrainstormingStarted ? 'none' : 'flex'}`,
          flexDirection: 'row',
          marginRight: '1rem',
        }}
      >
        <div
          onClick={() => {
            if (brainstormingTutorialOff !== true && state.useMicOnly === false) {
              setIsStartModalOpen(true)
            } else {
              startRecording()
            }
          }}
          className="sleek-button play-circle"
          style={{
            display: 'flex',
            width: '10rem',
            fontSize: '1rem',
            height: '2rem',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            color: 'rgb(255, 255, 255)',
            backgroundColor: 'rgb(68, 114, 196)',
            border: '1px solid rgb(255, 255, 255)',
          }}
        >
          <span class="material-symbols-outlined">not_started</span>
          {state.language === 'es' ? 'Haga clic para comenzar' : 'Click to Start'}
        </div>
      </div>
    </>
  )
}

export default BrainstormAudioButton

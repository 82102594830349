import React from 'react'

import { useSetupWizardContext } from '../../context/setupWizardContext'
import './styles.css'
import AreaLeaderCarousel from './components/AreaLeaderCarousel'

const TeamStep = () => {
  const { state } = useSetupWizardContext()

  return (
    <>
      <div className="step-section team-step">
        <h2>Let’s add the team leaders that report into each Area</h2>

        <p style={{ textAlign: 'left' }} className="step-details-p">
          A Team Leader is generally someone that only has individual contributors that report to
          them. In some cases, they may have small team managers (e.g., a new manager with an
          analyst and an intern as direct reports), but these managers typically don’t have their
          own unique objectives.
          <br /> <br />
          You can use the backwards and forwards buttons to move between Area Leaders.
        </p>

        <AreaLeaderCarousel
          areaLeaderArray={state.data.areaLeader}
          teamLeaderArray={state.data.teamLeader}
        />
      </div>
    </>
  )
}

export default TeamStep

import React from 'react'
import * as d3 from 'd3' // we will need d3.js
import { scaleOrdinal, forceSimulation, forceCollide } from 'd3'
import { sankey, sankeyJustify, sankeyLinkHorizontal } from 'd3-sankey'
import { useTranslation } from 'react-i18next'
import ActionDurationDetailsModal from './ActionDurationDetailsModal'
import useGetCategoryActionDurationData from '../../api/query/getCategoryActionDurationData'
import Toast from '../../common/toast'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import TopicsInformationModal from './TopicsInformationModal'

const MARGIN_Y = 25
const MARGIN_X = 5
const arrowContainerHeight = 40
const bubbleContainerWidth = 250
const nodePadding = 35
const arrowHeight = 10

const D3ExecSankey = ({ width, height, data, insightsData, companyWide = true }) => {
  const bubbleContainerHeight = parseInt(height, 10) + parseInt(arrowContainerHeight, 10)
  const { t } = useTranslation(['Sankey', 'Common'])

  const sankeyContainer = React.useRef()
  const arrowContainer = React.useRef()
  const bubbleContainer = React.useRef()

  const [isActionDurationDetailsModalOpen, setIsActionDurationDetailsModalOpen] =
    React.useState(false)
  const [isTopicsInformationModalOpen, setIsTopicsInformationModalOpen] = React.useState(false)
  const [modalData, setModalData] = React.useState(null)
  const [topcsInfoData, setTopcsInfoData] = React.useState(null)
  const [topicTitle, setTopicTitle] = React.useState([])

  const handleCloseModal = () => {
    setModalData(null)
    setTopcsInfoData(null)
    setIsActionDurationDetailsModalOpen(false)
    setIsTopicsInformationModalOpen(false)
  }

  const getFirstWordUntilCapitalization = (inputString) => {
    // Loop through each character in the input string
    for (let i = 0; i < inputString.length; i++) {
      // Check if the current character is a capital letter
      if (inputString[i] === inputString[i].toUpperCase()) {
        // Return the substring from the beginning of the string up to this capital letter
        return inputString.substring(0, i)
      }
    }
    // If no capital letter is found, return the entire input string
    return inputString
  }

  async function getModalData(category) {
    try {
      const data = await getHttpRequest(
        `/get_blockers_guidances_category_action_duration_details/${category}`,
        {
          params: {
            companyWide: companyWide,
          },
        },
      )
      return data
    } catch (e) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  async function prepareModalData(category) {
    const result = await getModalData(category)
    if (result) setModalData(result)
  }

  const getCoordinates = () => {
    // Select all the rectangles representing nodes
    const nodesSelection = d3.selectAll('rect')
    let allNodes = []

    nodesSelection.each(function (d, i) {
      const node = d3.select(this)
      const x = +node.attr('x') // X-coordinate of the rectangle
      const y = +node.attr('y') // Y-coordinate of the rectangle
      const width = +node.attr('width') // Width of the rectangle
      const height = +node.attr('height') // Height of the rectangle
      const id = node.attr('id') //id of the rectangle
      allNodes.push({ x: x, y: y, width: width, height: height, id: id })
    })

    // Find objects where x appears exactly 6 times
    const result = []
    for (const obj of allNodes) {
      if (
        obj.id === 'process' ||
        obj.id === 'product' ||
        obj.id === 'resourceLimitations' ||
        obj.id === 'direction' ||
        obj.id === 'approval' ||
        obj.id === 'prioritization'
      ) {
        let newObj = JSON.parse(JSON.stringify(obj))
        let endNodes = []
        for (let node of data.nodes) {
          if (newObj.id === node.id) {
            //if stat to assign bubble value
            newObj.value = node?.data?.avgTimeCompActions
          }
          if (
            node?.id?.includes('Within2Weeks') &&
            node?.id?.toLowerCase().includes(newObj?.id?.toLowerCase())
          ) {
            //if stat to find ending nodes to align with bubble
            endNodes.push({ ...node })
          }
        }
        newObj.lastNodes = endNodes
        newObj.categoryName = obj.id
        endNodes = []
        result.push(newObj)
      }
    }

    for (let r of result) {
      let cy = null
      if (r.lastNodes.length === 1) {
        let node1 = allNodes.find((node) => node.id === r.lastNodes[0].id)
        cy = node1.y + node1.height / 2
      } else if (r.lastNodes.length === 2) {
        let node1 = allNodes.find((node) => node.id === r.lastNodes[0].id)
        let node2 = allNodes.find((node) => node.id === r.lastNodes[1].id)
        if (node1.y < node2.y) {
          cy = (node2.y + node1.y + node1.height) / 2
        } else if (node1.y > node2.y) {
          cy = (node1.y + node2.y + node2.height) / 2
        }
      } else {
        cy = r.y + r.height / 2
      }
      r.cy = cy
    }

    return [result, allNodes]
  }

  React.useEffect(() => {
    let result = getCoordinates()
    let firstNode = result[1][0]
    let lastNode = result[1][result[1].length - 1]

    // Start ***************** bubbleContainer *************************
    const bubbleContainerTitleCX = 50
    d3.select(bubbleContainer.current).selectAll('*').remove()
    for (let node of result[0]) {
      d3.select(bubbleContainer.current)
        .append('g')
        .append('ellipse')
        .attr('cx', bubbleContainerWidth / 2)
        .attr('cy', node.cy + arrowContainerHeight)
        .attr('rx', 45) // Adjust the horizontal radius (half of the desired width)
        .attr('ry', 20) // Adjust the vertical radius (half of the desired height)
        .attr('r', 15)
        .attr('stroke', '#70777F')
        .attr('fill', '#70777F')
        .style('cursor', 'pointer')
        .on('click', function () {
          if (node.value.toFixed(0) != 0) {
            prepareModalData(getFirstWordUntilCapitalization(node.categoryName))
            setIsActionDurationDetailsModalOpen(true)
          }
        })

      d3.select(bubbleContainer.current)
        .append('text')
        .attr('x', bubbleContainerWidth / 2)
        .attr('y', node.cy + arrowContainerHeight)
        .attr('dy', '.35em')
        .attr('text-anchor', 'middle')
        .style('fill', 'white')
        .style('cursor', 'pointer')
        .text(node.value.toFixed(0) == 0 ? '--' : node.value.toFixed(1))
        .on('click', function () {
          if (node.value.toFixed(0) != 0) {
            prepareModalData(getFirstWordUntilCapitalization(node.categoryName))
            setIsActionDurationDetailsModalOpen(true)
          }
        })
    }

    // Define bubbles top title
    d3.select(bubbleContainer.current)
      .append('foreignObject')
      .attr('cx', bubbleContainerTitleCX)
      .attr('cy', firstNode.y - 50)
      .attr('width', '100%')
      .attr('height', 65)
      .attr('id', (d, i) => `text-bubble-title`)
      .attr('dy', '.35em')
      .style('fill', '#70777F')
      .append('xhtml:span')
      .html(
        t('medianTimeToCompleteAnAction') +
          ' : ' +
          data?.avgActionCompletionTime.toFixed(1) +
          ' ' +
          t('days'),
      )
      .style('width', '100%')
      .style('display', 'flex')
      .style('justify-content', 'center')
      .style('align-items', 'center')
      .style('font-weight', 'bold')
      .style('text-align', 'center')

    // End ***************** bubbleContainer *************************

    // Start ***************** arrowContainer *************************
    // reset arrowContainer.current in d3.
    d3.select(arrowContainer.current).selectAll('*').remove()
    // (re-)Define arrow top title
    d3.select(arrowContainer.current)
      .append('foreignObject')
      .attr('cx', bubbleContainerTitleCX)
      .attr('cy', firstNode.y - arrowContainerHeight)
      .attr('width', '100%')
      .attr('height', 40)
      .attr('id', (d, i) => `text-bubble-title`)
      .attr('dy', '.35em')
      .style('fill', '#70777F')
      .append('xhtml:span')
      .html(
        t('avgTimeToFirstActionOrDecision') +
          ' : ' +
          data?.avgTimeFirstActionComment.toFixed(1) +
          ' ' +
          t('days'),
      )
      .style('width', '100%')
      .style('display', 'flex')
      .style('justify-content', 'center')
      .style('align-items', 'center')
      .style('font-weight', 'bold')
      .style('text-align', 'center')

    // Define the arrowhead markers
    d3.select(arrowContainer.current)
      .append('defs')
      .append('marker')
      .attr('id', 'start-arrowhead')
      .attr('markerWidth', 10)
      .attr('markerHeight', arrowHeight)
      .attr('refX', 5)
      .attr('refY', 5)
      .append('path')
      .attr('d', 'M0,0 L10,5 L0,10')
      .attr('fill', '#70777F')
      .attr('transform', 'rotate(180 5 5)')

    d3.select(arrowContainer.current)
      .append('defs')
      .append('marker')
      .attr('id', 'end-arrowhead')
      .attr('markerWidth', 10)
      .attr('markerHeight', arrowHeight)
      .attr('refX', 5)
      .attr('refY', 5)
      .append('path')
      .attr('d', 'M0,0 L10,5 L0,10')
      .attr('fill', '#70777F')

    // Append the line with arrowhead markers
    d3.select(arrowContainer.current)
      .append('line')
      .attr('x1', firstNode.x)
      .attr('y1', arrowContainerHeight - arrowHeight + 5)
      .attr('x2', lastNode.x + lastNode.width)
      .attr('y2', arrowContainerHeight - arrowHeight + 5)
      .attr('stroke', '#70777F')
      .attr('fill', '#70777F')
      .attr('marker-start', 'url(#start-arrowhead)')
      .attr('marker-end', 'url(#end-arrowhead)')

    // End ***************** arrowContainer *************************
  }, [data])

  // Set the sankey diagram properties
  const sankeyGenerator = sankey()
    .nodeWidth(20)
    .nodePadding(nodePadding)
    .extent([
      [MARGIN_X, MARGIN_Y],
      [width - MARGIN_X - 90, height - MARGIN_Y],
    ])
    .nodeId((node) => node.name) // Accessor function: how to retrieve the id that defines each node. This id is then used for the source and target props of links
    .nodeAlign(sankeyJustify) // Algorithm used to decide node position
    .nodeSort(null)

  // Compute nodes and links positions
  const { nodes, links } = sankeyGenerator(data)

  const [hoverNodeState, setHoverNodeState] = React.useState(
    data.nodes.map((node) => ({ id: node.id, state: false })),
  )

  //
  // Draw the nodes
  //
  const allNodes = nodes.map((node) => {
    return (
      <g key={node.index}>
        <rect
          id={node.id}
          height={node.y1 - node.y0}
          width={sankeyGenerator.nodeWidth()}
          x={node.x0}
          y={node.y0}
          stroke={node.nodeColor}
          fill={node.nodeColor}
          fillOpacity={hoverNodeState.find((obj) => obj.id === node.id)?.state ? 0.2 : 1}
          strokeOpacity={hoverNodeState.find((obj) => obj.id === node.id)?.state ? 0.2 : 1}
          rx={5}
          ry={5}
          style={{ cursor: node.id.includes('esolvedWithin2Weeks') ? 'pointer' : 'auto' }}
          onClick={async () => {
            // "resolvedWithin2WeeksProcess" or "notResolvedWithin2WeeksProcess"
            if (node.id.includes('esolvedWithin2Weeks')) {
              let category = node.targetLinks[0].source.id
              if (category.toUpperCase().includes('RESOURCE')) {
                category = 'RESOURCE'
              }
              let isNotResolvedInTwoWeeks = node.id.includes('not')
              try {
                let data = await getHttpRequest(`/get_topics_information`, {
                  params: {
                    category: category,
                    isNotResolvedInTwoWeeks: isNotResolvedInTwoWeeks,
                    companyWide: companyWide,
                  },
                })
                setTopicTitle([category.toUpperCase(), isNotResolvedInTwoWeeks])
                setTopcsInfoData(data)
                setIsTopicsInformationModalOpen(true)
              } catch (error) {
                Toast.fire({
                  icon: 'error',
                  title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
                })
              }
            }
          }}
        />
      </g>
    )
  })

  const [hoverLinkState, setHoverLinkState] = React.useState(
    data.links.map((link) => ({ id: link.source.id + '-' + link.target.id, state: false })),
  )

  const handleMouseOverLink = (linkId) => {
    let tempHoverLinkState = []
    for (let state of hoverLinkState) {
      let stateCopy = JSON.parse(JSON.stringify(state))
      if (stateCopy.id !== linkId) {
        stateCopy.state = true
      }
      tempHoverLinkState.push(stateCopy)
    }
    setHoverLinkState(tempHoverLinkState)

    const parts = linkId.split('-')
    let tempHoverNodeState = []
    for (let state of hoverNodeState) {
      let stateCopy = JSON.parse(JSON.stringify(state))
      if (parts[0] === state.id || parts[1] === state.id) {
        stateCopy.state = false
      } else {
        stateCopy.state = true
      }
      tempHoverNodeState.push(stateCopy)
    }
    setHoverNodeState(tempHoverNodeState)
  }

  const handleMouseOutLink = () => {
    setHoverLinkState(hoverLinkState.map((obj) => ({ ...obj, state: false })))
    setHoverNodeState(hoverNodeState.map((obj) => ({ ...obj, state: false })))
  }

  //
  // Draw the links
  //
  const allLinks = links.map((link, i) => {
    const linkGenerator = sankeyLinkHorizontal()
    const path = linkGenerator(link)
    const isClickableLink =
      link.source.id === 'process' ||
      link.source.id === 'product' ||
      link.source.id === 'resourceLimitations' ||
      link.source.id === 'approval' ||
      link.source.id === 'direction' ||
      link.source.id === 'prioritization'

    return (
      <path
        id={link.source.id + '-' + link.target.id}
        key={i}
        d={path}
        stroke={link.source.nodeColor}
        fill="none"
        strokeOpacity={
          hoverLinkState.find((obj) => obj.id === link.source.id + '-' + link.target.id)?.state
            ? 0.1
            : 0.4
        }
        strokeWidth={link.width}
        onMouseOver={() => handleMouseOverLink(link.source.id + '-' + link.target.id)}
        onMouseOut={() => {
          handleMouseOutLink()
        }}
        style={{ cursor: isClickableLink ? 'pointer' : 'auto' }}
        onClick={async () => {
          // only allow clicking on 'process', 'product'..... level links, for now...
          if (isClickableLink) {
            // let category = link.
            let category = link.source.id
            if (category.toUpperCase().includes('RESOURCE')) {
              category = 'RESOURCE'
            }
            let isNotResolvedInTwoWeeks = link.target.id.includes('not')
            try {
              let data = await getHttpRequest(`/get_topics_information`, {
                params: {
                  category: category,
                  isNotResolvedInTwoWeeks: isNotResolvedInTwoWeeks,
                  companyWide: companyWide,
                },
              })
              setTopicTitle([category.toUpperCase(), isNotResolvedInTwoWeeks])
              setTopcsInfoData(data)
              setIsTopicsInformationModalOpen(true)
            } catch (error) {
              Toast.fire({
                icon: 'error',
                title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
              })
            }
          }
        }}
      />
    )
  })

  //
  // Draw the Labels
  //
  const allLabels = nodes
    .filter((node) => !node.id.includes('Within2Weeks'))
    .map((node, i) => {
      return (
        <text
          key={i}
          x={node.x1 + 6}
          y={node.y0}
          dy="0.35rem"
          textAnchor={node.x0 < width / 2 ? 'start' : 'start'}
          fontSize={12}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <tspan style={{ fontWeight: 'bold' }} x={node.x1 + 6} dy="1.2em">
            {node.name}
          </tspan>

          <tspan x={node.x1 + 6} dy="1.2em">
            {t('numberOfTopics')}:&nbsp;
            {node?.data?.total}
          </tspan>

          <tspan x={node.x1 + 6} dy="1.2em">
            {node?.data?.percentageRelative.toFixed(1)}%
          </tspan>
        </text>
      )
    })

  const allLabelsEnd = nodes
    .filter((node) => node.id.includes('Within2Weeks'))
    .map((node, i) => {
      return (
        <text
          key={i}
          x={node.x1 + 6}
          y={node.y0}
          dy="0.35rem"
          textAnchor={node.x0 < width / 2 ? 'start' : 'start'}
          fontSize={12}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <tspan x={node.x1 + 6} dy="1.2em">
            {node?.data?.percentageRelative.toFixed(1)}%
          </tspan>
        </text>
      )
    })

  return (
    <>
      {isActionDurationDetailsModalOpen && modalData && (
        <ActionDurationDetailsModal
          isModalOpen={isActionDurationDetailsModalOpen === true}
          closeModal={handleCloseModal}
          data={modalData}
          //dataIsLoading={modalDataIsLoading}
        />
      )}

      {isTopicsInformationModalOpen && topcsInfoData && (
        <TopicsInformationModal
          isModalOpen={isTopicsInformationModalOpen === true}
          closeModal={handleCloseModal}
          data={topcsInfoData}
          //dataIsLoading={modalDataIsLoading}
          topicTitle={topicTitle}
        />
      )}

      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ width: '20px', backgroundColor: 'hsl(126,18.2%,56.9%)', marginRight: '5px' }}>
          &nbsp;
        </div>
        <div class="cell">{t('resolvedWithin2Weeks')}</div>
        <div class="cell" style={{ width: '20px' }}>
          &nbsp;
        </div>
        <div style={{ width: '20px', backgroundColor: '#E79B9B', marginRight: '5px' }}>&nbsp;</div>
        <div class="cell">{t('notResolvedWithin2Weeks')}</div>
      </div>
      <br />
      <div style={{ display: 'flex', flexDirection: 'row' }} height={height}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <svg width={width} height={arrowContainerHeight} ref={arrowContainer}></svg>
          <svg width={width} height={height} ref={sankeyContainer}>
            {allNodes}
            {allLabels}
            {allLabelsEnd}
            {allLinks}
          </svg>
        </div>
        <svg
          width={bubbleContainerWidth}
          height={bubbleContainerHeight}
          ref={bubbleContainer}
        ></svg>
        <div
          height={bubbleContainerHeight}
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '2rem',
            width: bubbleContainerWidth,
            height: bubbleContainerHeight,
            marginLeft: '0.5rem',
          }}
        >
          {insightsData?.strengthsList.length > 0 && (
            <div>
              <div style={{ textAlign: 'center' }}>
                <b>{t('strengths')} </b>
              </div>
              <ul>
                {insightsData?.strengthsList.map((item) => {
                  if (item.includes('Resolution rate')) {
                    const parts = item.split(
                      /(Resource Limitations|Direction|Approval|Prioritization|Product|Process)/g,
                    )
                    return (
                      <li style={{ fontSize: '12px', margin: '10px 0' }}>
                        {parts.map((part, ind) => {
                          if (
                            part === 'Resource Limitations' ||
                            part === 'Direction' ||
                            part === 'Approval' ||
                            part === 'Prioritization' ||
                            part === 'Product' ||
                            part === 'Process'
                          ) {
                            return (
                              <span key={ind} style={{ fontWeight: 'bold' }}>
                                {' '}
                                {part}{' '}
                              </span>
                            )
                          }
                          return part
                        })}
                      </li>
                    )
                  }
                  return <li style={{ fontSize: '12px', margin: '10px 0' }}> {item} </li>
                })}
              </ul>
            </div>
          )}

          {insightsData?.opportunitiesList.length > 0 && (
            <div>
              <div style={{ textAlign: 'center' }}>
                <b>{t('opportunities')} </b>
              </div>
              <ul>
                {insightsData?.opportunitiesList.map((item) => {
                  if (item.includes('Resolution rate')) {
                    const parts = item.split(
                      /(Resource Limitations|Direction|Approval|Prioritization|Product|Process)/g,
                    )
                    return (
                      <li style={{ fontSize: '12px', margin: '10px 0' }}>
                        {parts.map((part, ind) => {
                          if (
                            part === 'Resource Limitations' ||
                            part === 'Direction' ||
                            part === 'Approval' ||
                            part === 'Prioritization' ||
                            part === 'Product' ||
                            part === 'Process'
                          ) {
                            return (
                              <span key={ind} style={{ fontWeight: 'bold' }}>
                                {' '}
                                {part}{' '}
                              </span>
                            )
                          }
                          return part
                        })}
                      </li>
                    )
                  }
                  return <li style={{ fontSize: '12px', margin: '10px 0' }}> {item} </li>
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default D3ExecSankey

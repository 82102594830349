import React from 'react'
import ApplicationLayout from '../../components/ApplicationLayout'
import D3ExecSankey from '../../components/D3ExecSankey'
import useGetSankeyData from '../../api/query/useGetSankeyData'
import { useTranslation } from 'react-i18next'
import { getCompanyConfigSettings, hasRole, getInheritedLevel } from '../../utils/auth'
import InPageLoader from '../../components/InPageLoader'
import { useNavbarContext } from '../../utils/navBarContext'

const SankeyPage = ({ timeFilters }) => {
  const { t } = useTranslation(['Sankey'])
  const [companyWide, setCompanyWide] = React.useState(true)
  const {
    data: data,
    isLoading: isSankeyLoading,
    error: sankeyError,
  } = useGetSankeyData(companyWide, timeFilters)
  const [sankeyData, setSankeyData] = React.useState(null)
  const [insightsData, setInsightsData] = React.useState(null)
  const { isNavbarCollapsed, handleNavbarStateChange } = useNavbarContext()

  React.useEffect(() => {
    if (data) {
      setSankeyDataJson(data)
      setSankeyInsightsDataJson(data)
    }
  }, [data])

  let minusValue = isNavbarCollapsed ? 200 : 300
  const [graphWidth, setGraphWidth] = React.useState(window.innerWidth - minusValue)

  React.useEffect(() => {
    let minusValue = isNavbarCollapsed ? 200 : 300
    const handleResize = () => {
      setGraphWidth(window.innerWidth - minusValue)
    }

    window.addEventListener('resize', handleResize)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []) // Empty dependency array ensures this effect runs only once after initial render

  React.useEffect(() => {
    let minusValue = isNavbarCollapsed ? 200 : 300

    setGraphWidth(window.innerWidth - minusValue)
  }, [isNavbarCollapsed])

  const setSankeyInsightsDataJson = (data) => {
    let insights = data?.insights
    let result = {}
    let strengthsList = []
    let opportunitiesList = []

    let avgTimeFirstActionComment = data.sankey.avg_time_first_action_comment.toFixed(1)
    let addendumAttfa = ''
    if (insights.attfa > insights.attfaUpperQuartileBenchmark) {
      addendumAttfa = t('topQuartileAddendum', { X: insights.attfaUpperQuartileBenchmark })
    }
    if (insights.attfa <= insights.attfaBenchmark) {
      let message = t('strengthAttfaMessage', {
        X: avgTimeFirstActionComment,
        Y: insights.attfaBenchmark,
      })
      if (addendumAttfa.length > 0) {
        message += ' ' + addendumAttfa
      }
      strengthsList.push(message)
    } else if (insights.attfa > insights.attfaBenchmark) {
      opportunitiesList.push(
        t('opportunityAttfaMessage', { X: avgTimeFirstActionComment, Y: insights.attfaBenchmark }),
      )
    }

    let addendumAttca = ''
    if (insights.attca > insights.attcaUpperQuartileBenchmark) {
      addendumAttca = t('topQuartileAddendum', { X: insights.attcaUpperQuartileBenchmark })
    }
    let avgActionCompletionTime = data.sankey.avg_action_completion_time.toFixed(1)
    if (insights.attca <= insights.attcaBenchmark) {
      let message = t('strengthAttcaMessage', {
        X: avgActionCompletionTime,
        Y: insights.attcaBenchmark,
      })
      if (addendumAttca.length > 0) {
        message += ' ' + addendumAttca
      }
      strengthsList.push(message)
    } else if (insights.attca > insights.attcaBenchmark) {
      opportunitiesList.push(
        t('opportunityAttcaMessage', { X: avgActionCompletionTime, Y: insights.attcaBenchmark }),
      )
    }

    let strengthsCategoryList = []
    let opportunitiesCategoryList = []
    for (let category in data?.insights?.categoryData) {
      if (data?.sankey?.data?.nodes.some((item) => item.id === category)) {
        if (
          data?.insights?.categoryData[category][0] >= data?.insights?.categoryData[category][1]
        ) {
          strengthsCategoryList.push(t(category))
        } else if (
          data?.insights?.categoryData[category][0] < data?.insights?.categoryData[category][1]
        ) {
          opportunitiesCategoryList.push(t(category))
        }
      }
    }

    let formattedStrengthsString = ''
    if (strengthsCategoryList.length === 1) {
      formattedStrengthsString = strengthsCategoryList[0]
    } else if (strengthsCategoryList.length > 1) {
      formattedStrengthsString =
        strengthsCategoryList.slice(0, -1).join(', ') + ' and ' + strengthsCategoryList.slice(-1)
    }

    let formattedOpportunitiesString = ''
    if (opportunitiesCategoryList.length === 1) {
      formattedOpportunitiesString = opportunitiesCategoryList[0]
    } else if (opportunitiesCategoryList.length > 1) {
      formattedOpportunitiesString =
        opportunitiesCategoryList.slice(0, -1).join(', ') +
        ' and ' +
        opportunitiesCategoryList.slice(-1)
    }

    if (strengthsCategoryList.length > 0) {
      strengthsList.push(t('strengthCategoryMessage') + ': ' + formattedStrengthsString)
    }

    if (opportunitiesCategoryList.length > 0) {
      opportunitiesList.push(t('opportunityCategoryMessage') + ': ' + formattedOpportunitiesString)
    }

    if (insights.prioritizationPercentage === 0) {
      opportunitiesList.push(t('opportunityPrioritizationIsZeroMessage'))
    }

    if (
      insights.prioritizationPercentage > 0 &&
      insights.prioritizationPercentage < insights.prioritizationPercentageBenchmark
    ) {
      opportunitiesList.push(
        t('opportunityPriotizationBenchmark', { X: insights.prioritizationPercentageBenchmark }),
      )
    }

    result.strengthsList = strengthsList
    result.opportunitiesList = opportunitiesList
    setInsightsData(result)
  }

  const setSankeyDataJson = (data) => {
    let sankeyData = { nodes: [], links: [] }
    for (let node of data?.sankey?.data?.nodes) {
      let obj = {}
      obj = JSON.parse(JSON.stringify(node))
      switch (node['id']) {
        case 'meetingTopics':
          obj['nodeColor'] = 'hsl(0,0%,60%)'
          obj['percentage'] = '20%'
          break
        case 'blockers':
          obj['nodeColor'] = 'hsl(28,100%,52.9%)'
          obj['percentage'] = '20%'
          break
        case 'guidance':
          obj['nodeColor'] = 'hsl(231.4,91.9%,43.3%)'
          obj['percentage'] = '20%'
          break
        case 'process':
          obj['nodeColor'] = 'hsl(28,100%,76.5%)'
          obj['percentage'] = '20%'
          break
        case 'product':
          obj['nodeColor'] = 'hsl(28,100%,76.5%)'
          obj['percentage'] = '20%'
          break
        case 'resourceLimitations':
          obj['nodeColor'] = 'hsl(28,100%,76.5%)'
          obj['percentage'] = '20%'
          break
        case 'direction':
          obj['nodeColor'] = 'hsl(231.6,69.9%,62.2%)'
          obj['percentage'] = '20%'
          break
        case 'approval':
          obj['nodeColor'] = 'hsl(231.6,69.9%,62.2%)'
          obj['percentage'] = '20%'
          break
        case 'prioritization':
          obj['nodeColor'] = 'hsl(231.6,69.9%,62.2%)'
          obj['percentage'] = '20%'
          break
        case 'resolvedWithin2WeeksProcess':
          obj['nodeColor'] = 'hsl(126,18.2%,56.9%)'
          obj['percentage'] = '20%'
          break
        case 'notResolvedWithin2WeeksProcess':
          obj['nodeColor'] = '#E79B9B'
          obj['percentage'] = '20%'
          break
        case 'resolvedWithin2WeeksProduct':
          obj['nodeColor'] = 'hsl(126,18.2%,56.9%)'
          obj['percentage'] = '20%'
          break
        case 'notResolvedWithin2WeeksProduct':
          obj['nodeColor'] = '#E79B9B'
          obj['percentage'] = '20%'
          break
        case 'resolvedWithin2WeeksDirection':
          obj['nodeColor'] = 'hsl(126,18.2%,56.9%)'
          obj['percentage'] = '20%'
          break
        case 'notResolvedWithin2WeeksDirection':
          obj['nodeColor'] = '#E79B9B'
          obj['percentage'] = '20%'
          break
        case 'resolvedWithin2WeeksApproval':
          obj['nodeColor'] = 'hsl(126,18.2%,56.9%)'
          obj['percentage'] = '20%'
          break
        case 'notResolvedWithin2WeeksApproval':
          obj['nodeColor'] = '#E79B9B'
          obj['percentage'] = '20%'
          break
        case 'resolvedWithin2WeeksPrioritization':
          obj['nodeColor'] = 'hsl(126,18.2%,56.9%)'
          obj['percentage'] = '20%'
          break
        case 'notResolvedWithin2WeeksPrioritization':
          obj['nodeColor'] = '#E79B9B'
          obj['percentage'] = '20%'
          break
        case 'resolvedWithin2WeeksResourceLimitations':
          obj['nodeColor'] = 'hsl(126,18.2%,56.9%)'
          obj['percentage'] = '20%'
          break
        case 'notResolvedWithin2WeeksResourceLimitations':
          obj['nodeColor'] = '#E79B9B'
          obj['percentage'] = '20%'
          break
        default:
      }
      obj['name'] = t(`${obj['id']}`)
      sankeyData['nodes'].push(obj)
    }

    for (let link of data?.sankey?.data?.links) {
      let obj = {}
      obj = JSON.parse(JSON.stringify(link))
      obj['source'] = t(`${obj['source']}`)
      obj['target'] = t(`${obj['target']}`)
      sankeyData['links'].push(obj)
    }
    sankeyData.avgActionCompletionTime = data.sankey.avg_action_completion_time
    sankeyData.avgTimeFirstActionComment = data.sankey.avg_time_first_action_comment
    setSankeyData(sankeyData)
  }
  if (isSankeyLoading) {
    return (
      <>
        {' '}
        <InPageLoader />{' '}
      </>
    )
  }

  return (
    <div style={{ width: '100%', paddingLeft: '1rem' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          display: 'flex',
          alignSelf: 'center',
          rowGap: '3rem',
          height: '770px',
        }}
      >
        {(getInheritedLevel() == 1 || hasRole('cxo') || hasRole('area')) && (
          <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem' }}>
            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                width: 'fitContent',
                height: 'fitContent',
                borderRadius: '4px',
                color: 'white',
                padding: '0.3rem 1rem 0.3rem 1rem',
                backgroundColor: companyWide ? '#4472c4' : '#a6a6a6',
              }}
              onClick={() => setCompanyWide(true)}
            >
              {t('companyWide')}
            </div>
            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                width: 'fitContent',
                height: 'fitContent',
                borderRadius: '4px',
                color: 'white',
                padding: '0.3rem 1rem 0.3rem 1rem',
                backgroundColor: companyWide ? '#a6a6a6' : '#4472c4',
              }}
              onClick={() => setCompanyWide(false)}
            >
              {t('myArea')}
            </div>
          </div>
        )}

        {sankeyData &&
        sankeyData?.nodes?.length > 0 &&
        sankeyData?.links?.length > 0 &&
        insightsData ? (
          <div className="shadow" style={{ padding: '2rem', height: 'fit-content' }}>
            <div style={{ width: graphWidth, overflowX: 'scroll' }}>
              <div style={{ width: '1400px' }}>
                <D3ExecSankey
                  data={sankeyData}
                  insightsData={insightsData}
                  width={'800'}
                  height={'600'}
                  style={{ overflow: 'visible' }}
                  companyWide={companyWide}
                />
              </div>
            </div>
          </div>
        ) : (
          <div style={{ marginLeft: '2rem' }}>{t('noSankey')}</div>
        )}
      </div>
    </div>
  )
}

export default SankeyPage

import React, { useState, useEffect } from 'react'
import './styles.css'

const CapacityConfirmModal = ({
  objectives,
  isCapacityConfirmModalOpen,
  handleClose,
  handleNext,
  totalObjRequiredFTEs,
  totalTeamAvailableFTEs,
  objectiveAssociation = null,
  assumeNoAllocatedFte,
}) => {
  //Just display and modify objectives with required FTE greater than 0
  objectives = objectives.filter((obj, index) => {
    return obj.requiredFte > 0
  })

  const [checkboxErrorMessage, setCheckboxErrorMessage] = useState(null)
  const [displayMissingInputFTE, setDisplayMissingInputFTE] = useState(false)

  const [lackingCapacityArray, setLackingCapacityArray] = React.useState(
    objectives?.map((obj, index) => obj.lackingCapacity === true),
  )
  const [lackingCapacityObjAssociation, setLackingCapacityObjAssociation] = React.useState(
    objectiveAssociation?.lackingCapacity,
  )

  const [missingFteArray, setMissingFTEArray] = React.useState(
    objectives?.map((obj, index) =>
      assumeNoAllocatedFte === true ? (obj.missingFte ?? 0) : obj.requiredFte - obj.allocatedFte,
    ),
  )

  const [errorMissingFteArray, setErrorMissingFTEArray] = React.useState(
    objectives?.map((obj, index) => null),
  )

  const [missingFteObjAssociation, setMissingFTEObjAssociation] = React.useState(
    objectiveAssociation?.missingFte ? objectiveAssociation?.missingFte : 0,
  )

  const [errorMissingFteObjAssociation, setErrorMissingFteObjAssociation] = React.useState(null)

  const [totalMissingCapacity, setTotalMissingCapacity] = React.useState(
    totalObjRequiredFTEs - totalTeamAvailableFTEs,
  )

  React.useEffect(() => {
    objectives = objectives.filter((obj, index) => {
      return obj.requiredFte > 0
    })
    setCheckboxErrorMessage(null)
    setDisplayMissingInputFTE(false)

    setLackingCapacityArray(
      assumeNoAllocatedFte === true
        ? objectives?.map((obj, index) => obj.lackingCapacity === true)
        : objectives?.map((obj, index) => (obj.requiredFte - obj.allocatedFte > 0 ? true : false)),
    )
    setLackingCapacityObjAssociation(objectiveAssociation?.lackingCapacity)

    setMissingFTEArray(
      objectives?.map((obj, index) =>
        assumeNoAllocatedFte === true ? (obj.missingFte ?? 0) : obj.requiredFte - obj.allocatedFte,
      ),
    )
    setErrorMissingFTEArray(objectives?.map((obj, index) => null))

    setMissingFTEObjAssociation(
      objectiveAssociation?.missingFte ? objectiveAssociation?.missingFte : 0,
    )
    setErrorMissingFteObjAssociation(null)

    setTotalMissingCapacity(totalObjRequiredFTEs - totalTeamAvailableFTEs)
  }, [isCapacityConfirmModalOpen])

  // Display missing capacity input column only when more than one objective is checked
  React.useEffect(() => {
    let counterLackingCapacityObj = 0
    for (let value of lackingCapacityArray) {
      if (value === true) {
        counterLackingCapacityObj++
      }
    }
    if (objectiveAssociation) {
      if (lackingCapacityObjAssociation === true) {
        counterLackingCapacityObj++
      }
    }
    if (counterLackingCapacityObj > 1) {
      setDisplayMissingInputFTE(true)
    } else {
      setDisplayMissingInputFTE(false)
    }
  }, [lackingCapacityArray, lackingCapacityObjAssociation])

  const resetModal = () => {
    setLackingCapacityArray(objectives?.map((obj, index) => obj.lackingCapacity === true))
    setMissingFTEArray(objectives?.map((obj, index) => (obj.missingFte ? obj.missingFte : 0)))
    setErrorMissingFTEArray(objectives?.map((obj, index) => null))
    if (objectiveAssociation) {
      setLackingCapacityObjAssociation(objectiveAssociation?.lackingCapacity)
      setMissingFTEObjAssociation(
        objectiveAssociation?.missingFte ? objectiveAssociation?.missingFte : 0,
      )
      setErrorMissingFteObjAssociation(null)
    }
  }

  /* Does the excess capacity box work in situations where even after selecting the excess capacity 
  objectives, you're still over capacity? E.g. if my team only has 2 people, and I was to enter 3 
  objectives, each requiring 3 people each, then it should not let me proceed until all 3 are 
  checked as exceeding capacity. Basically, the proceeding criteria is that the (sum of required 
  FTE of not exceeding capacity, i.e. non-ticked ones) <= team allocation */

  const haveEnoughCapacityForUnchecked = () => {
    //change this
    let totalCapacityOfUncheckedObj = 0
    for (let i = 0; i < objectives?.length; i++) {
      totalCapacityOfUncheckedObj += !lackingCapacityArray[i] ? objectives[i].requiredFte : 0
    }

    if (objectiveAssociation) {
      totalCapacityOfUncheckedObj += !lackingCapacityObjAssociation
        ? objectiveAssociation?.requiredFte
        : 0
    }

    return totalCapacityOfUncheckedObj <= totalTeamAvailableFTEs
  }

  const handleLackingCapacityCheckBoxClick = (position, checked) => {
    const updatedCheckedState = lackingCapacityArray.map((item, index) =>
      index === position ? !item : item,
    )
    setLackingCapacityArray(updatedCheckedState)

    // If a checkbox becomes unchecked, its associated missingFTE array element should be set to 0.
    let val = 0

    let tempMissingFTEArray = [...missingFteArray]
    tempMissingFTEArray[position] = val
    setMissingFTEArray(tempMissingFTEArray)

    let tempErrorMissingFteArray = [...errorMissingFteArray]
    tempErrorMissingFteArray[position] = null
    setErrorMissingFTEArray(tempErrorMissingFteArray)
  }

  const handleCheckBoxClickObjAssociation = (isChecked) => {
    setLackingCapacityObjAssociation(isChecked)

    // If a checkbox becomes unchecked, its associated missingFTE array element should be set to 0.
    if (isChecked === true) {
      setMissingFTEObjAssociation(1)
      setErrorMissingFteObjAssociation(null)
    } else {
      setMissingFTEObjAssociation(0)
      setErrorMissingFteObjAssociation(null)
    }
  }

  const handleMissingFTEInputChange = (value, position) => {
    if (value > objectives[position].requiredFte) {
      let tempErrorMissingFteArray = [...errorMissingFteArray]
      tempErrorMissingFteArray[position] =
        'The input cannot be greater than the required FTE of ' + objectives[position].requiredFte

      setErrorMissingFTEArray(tempErrorMissingFteArray)
    } else if (value === null || value === undefined || isNaN(value) === true) {
      let tempErrorMissingFteArray = [...errorMissingFteArray]
      tempErrorMissingFteArray[position] = 'The input cannot be less than 0'

      setErrorMissingFTEArray(tempErrorMissingFteArray)
    } else if (value <= 0) {
      let tempErrorMissingFteArray = [...errorMissingFteArray]
      tempErrorMissingFteArray[position] =
        'If an objective is lacking capacity, you need to enter a number greater than 0'

      setErrorMissingFTEArray(tempErrorMissingFteArray)
    } else {
      let tempErrorMissingFteArray = [...errorMissingFteArray]
      tempErrorMissingFteArray[position] = null

      setErrorMissingFTEArray(tempErrorMissingFteArray)
    }
    let tempMissingFTEArray = [...missingFteArray]
    tempMissingFTEArray[position] = value
    setMissingFTEArray(tempMissingFTEArray)
  }

  const handleMissingFTEObjAssociationChange = (value) => {
    if (value > objectiveAssociation.requiredFte) {
      setErrorMissingFteObjAssociation(
        'The input cannot be greater than the required FTE of ' + objectiveAssociation.requiredFte,
      )
    } else if (value === null || value === undefined || isNaN(value) === true) {
      setErrorMissingFteObjAssociation('The input cannot be less than 0')
    } else if (value <= 0) {
      setErrorMissingFteObjAssociation(
        'If an objective is lacking capacity, you need to enter a number greater than 0',
      )
    } else {
      setErrorMissingFteObjAssociation(null)
    }
    setMissingFTEObjAssociation(value)
  }

  const saveValuesToState = () => {
    let missingFteObjAssociation_ = missingFteObjAssociation // setMissingFTEObjAssociation
    // Dispatch is not used in this function due to state not being synchronously updated before
    // being submitted.
    // Find out how many isLacking capacity there are, and if there is only one, set it's
    // missingFte to the total missingFte.
    let lastLackingCapIndex = -1
    let numLackingCap = 0
    for (let i = 0; i < objectives?.length; i++) {
      if (lackingCapacityArray[i] === true) {
        lastLackingCapIndex = i
        numLackingCap++
      }
    }

    if (objectiveAssociation) {
      if (lackingCapacityObjAssociation === true) {
        numLackingCap++
      }
    }

    if (numLackingCap === 1) {
      if (objectiveAssociation && lackingCapacityObjAssociation === true) {
        missingFteObjAssociation_ = totalMissingCapacity
      } else {
        missingFteArray[lastLackingCapIndex] = totalMissingCapacity
      }
    }

    for (let i = 0; i < objectives?.length; i++) {
      let objective = objectives[i]
      objective.lackingCapacity = lackingCapacityArray[i]
      objectives[i].isModified = true

      if (objective.lackingCapacity === false) {
        objective.missingFte = 0
        objective.allocatedFte = objective.requiredFte
      } else {
        // If the user checked the box and left the value as 0, then we should save
        // `lackingCapacity` as false.
        if (almostEquals(missingFteArray[i], 0)) {
          objective.lackingCapacity = false
        }
        objective.missingFte = missingFteArray[i]
        objective.allocatedFte = objective.requiredFte - missingFteArray[i]
      }
    }

    if (objectiveAssociation) {
      objectiveAssociation.lackingCapacity = lackingCapacityObjAssociation
      if (objectiveAssociation.lackingCapacity === false) {
        objectiveAssociation.missingFte = 0
        objectiveAssociation.allocatedFte = objectiveAssociation.requiredFte
      } else {
        // If the user checked the box and left the value as 0, then we should save
        // `lackingCapacity` as false.
        if (almostEquals(objectiveAssociation.missingFte, 0)) {
          objectiveAssociation.lackingCapacity = false
        }

        objectiveAssociation.missingFte = missingFteObjAssociation_
        objectiveAssociation.allocatedFte =
          objectiveAssociation.requiredFte - missingFteObjAssociation_
      }
    }

    setMissingFTEObjAssociation(missingFteObjAssociation_)
  }

  const almostEquals = (val1, val2) => {
    const errorBound = 0.0001
    return Math.abs(val1 - val2) < errorBound
  }

  return (
    <div className={`fte-warning-modal ${isCapacityConfirmModalOpen ? '' : 'hidden'}`}>
      <div className="modal-tf">
        <p>
          <b>Attention: </b> The total capacity allocated exceeds your team's capacity. If this is
          accurate DO NOT change it - it is essential for leadership to be able to make tradeoffs.
          If this was an error please click back to fix it.
        </p>

        <h2>Which objectives are lacking capacity?</h2>

        <div style={{ width: '80%', display: 'flex', justifyContent: 'flex-start' }}>
          {/* Total lacking capacity: {totalObjRequiredFTEs - totalTeamAvailableFTEs} */}
        </div>

        <table>
          <tbody>
            <tr>
              <th className="wide-cell">Goal</th>
              <th>Lacking Capacity?</th>
              {displayMissingInputFTE === true && (
                <th>In FTEs, what is the capacity missing for each objective?</th>
              )}
              {/* This logic (and column) should only be triggered if more than one objectives are marked as
              exceeding capacity.
              Here X = total exceeded. Show textboxes against all objectives marked as exceeding 
              capactiy.
              Recompute logic whenever a checkbox is unchecked.
              Return these values in the request to backend (see DB logc in the Capacity allocation excel sheet*/}
            </tr>

            {objectives?.map((objective, index) => (
              <>
                <tr key={`objective-${index}`}>
                  <td className="wide-cell">{objective.statement}</td>
                  <td>
                    <input
                      className="capacity-checkbox"
                      type="checkbox"
                      checked={lackingCapacityArray[index]}
                      onChange={(e) => handleLackingCapacityCheckBoxClick(index, e.target.checked)}
                    />
                  </td>

                  {displayMissingInputFTE === true && (
                    <td>
                      {lackingCapacityArray[index] === true && (
                        <input
                          style={{ width: '5vw' }}
                          className="fte-input"
                          defaultValue={missingFteArray[index]}
                          name="missingFte"
                          type="text"
                          min="0"
                          onChange={(e) => {
                            handleMissingFTEInputChange(parseFloat(e.target.value), index)
                          }}
                        />
                      )}
                    </td>
                  )}
                </tr>
                <tr key={`objective-error-${index}`}>
                  <td></td>
                  <td></td>
                  <td>
                    {' '}
                    <div className="validation-error">{errorMissingFteArray[index]}</div>
                  </td>
                </tr>
              </>
            ))}

            {objectiveAssociation !== null && objectiveAssociation.requiredFte > 0 && (
              <>
                <tr key={`objectiveAssociation-${0}`}>
                  <td className="wide-cell">{objectiveAssociation?.statement}</td>
                  <td>
                    <input
                      className="capacity-checkbox"
                      type="checkbox"
                      checked={lackingCapacityObjAssociation}
                      onChange={(e) => handleCheckBoxClickObjAssociation(e.target.checked)}
                    />
                  </td>

                  {displayMissingInputFTE === true && (
                    <td>
                      {lackingCapacityObjAssociation === true && (
                        <input
                          style={{ width: '5vw' }}
                          className="fte-input"
                          defaultValue={missingFteObjAssociation}
                          name="missingFte"
                          type="text"
                          min="0"
                          onChange={(e) => {
                            handleMissingFTEObjAssociationChange(parseFloat(e.target.value))
                          }}
                        />
                      )}
                    </td>
                  )}
                </tr>
                <tr key={`objectiveAssociation-error`}>
                  <td></td>
                  <td></td>
                  <td>
                    {' '}
                    <div className="validation-error">{errorMissingFteObjAssociation}</div>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
        {/* {displayMissingInputFTE === true && ( */}
        <div style={{ display: 'flex', width: '80%', alignItems: 'center' }}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <b>Total Missing Capacity: {totalMissingCapacity.toFixed(2)}</b>
        </div>
        {/* )} */}
        <div className="error-container" style={{ width: '40rem' }}>
          {checkboxErrorMessage ? (
            <div className="validation-error">{checkboxErrorMessage}</div>
          ) : null}
        </div>
        <br />

        <div className="modal-dual-btn" style={{ marginTop: '4rem' }}>
          <button
            className="dual-btn positive-btn"
            onClick={() => {
              resetModal()
              handleClose()
            }}
          >
            <span>BACK</span>
          </button>
          <button
            className="dual-btn positive-btn"
            onClick={() => {
              setCheckboxErrorMessage(null)

              const errorMissingFteNumber = errorMissingFteArray.filter((error) => {
                return error !== null
              })
              if (errorMissingFteNumber.length > 0) {
                return
              }
              if (objectiveAssociation && errorMissingFteObjAssociation !== null) {
                return
              }

              if (!haveEnoughCapacityForUnchecked()) {
                setCheckboxErrorMessage(
                  'The selected objectives do not sufficiently cover the capacity gap for your team, please select additional objectives that are lacking capacity.',
                )
                return
              }
              let sumMissingFTE = missingFteArray.reduce((a, b) => (a = a + b), 0)
              if (objectiveAssociation) {
                sumMissingFTE += missingFteObjAssociation
              }
              const diff = totalMissingCapacity
              if (
                displayMissingInputFTE === true &&
                !almostEquals(sumMissingFTE, totalMissingCapacity)
              ) {
                setCheckboxErrorMessage(
                  // `The sum of your overcapacity objectives missing FTEs should be equal to your curent required capacity of ${totalObjRequiredFTEs} `
                  `You are lacking capacity of ${diff.toFixed(2)} FTE(s). ` +
                    `However, you have specified ${sumMissingFTE.toFixed(
                      2,
                    )} FTE(s) across the selected ` +
                    `objectives that are lacking capacity. Please recheck the distribution to ` +
                    `ensure you are fully accounting for all lacked capacity across objectives.`,
                )
                return
              }

              setCheckboxErrorMessage(null)
              saveValuesToState()
              handleNext()
              handleClose()
            }}
          >
            <span>PROCEED</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default CapacityConfirmModal

import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import ApplicationLayout from '../../components/ApplicationLayout'
import Loading from '../../components/Loading'
import ChangesReportHeaders from './Headers'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const ChangesReportPage = () => {
  const { t } = useTranslation(['ChangesReport'])
  const [isLoading, setIsLoading] = useState(true)
  const [reload, setReload] = React.useState(0)

  const changesReportContainerRef = React.useRef(null)

  const loadAgain = () => {
    setReload(reload + 1)
  }

  return (
    <ApplicationLayout>
      <div
        ref={changesReportContainerRef}
        className="changes-report-container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
        }}
      >
        <div className="changes-report" style={{ paddingTop: '3.5rem' }}>
          <div className="page_title">{t('importantChanges')}</div>
          <ChangesReportHeaders />
          <div className="changes-report-body">
            <div className={`changes-report-container`}>
              <Outlet
                context={{
                  setIsLoading,
                  isLoading,
                  loadAgain,
                  reload,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </ApplicationLayout>
  )
}

export default ChangesReportPage

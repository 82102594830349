import { useQuery } from '@tanstack/react-query'
import { getHttpRequest } from './dynamicAPI'

import { GET_LIST_OBJECTIVES } from '../../api/constant/route'

const getObjectiveData = async (tlId, show_owned_only) => {
  const queryString = `${GET_LIST_OBJECTIVES}/${tlId}/${show_owned_only}`

  const data = await getHttpRequest(queryString)
  return data
}

export default function useObjectiveData(tlId = null, show_owned_only = false) {
  return useQuery(['objectives'], () => getObjectiveData(tlId, show_owned_only), {
    refetchOnWindowFocus: false,
  })
}

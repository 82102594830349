import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getTeamLeadersForAreaLeaderData = async (arealLeaderEmpId) => {
  if (arealLeaderEmpId && arealLeaderEmpId !== '0000') {
    const queryString = `/team_leaders_for_area_leader/${arealLeaderEmpId}`
    const { data } = await axios.get(`${BASE_URL}${queryString}`)
    return data
  }
  return null
}

export default function useTeamLeadersForAreaLeader(arealLeaderEmpId = null, newLength = 0) {
  return useQuery(
    ['employees', arealLeaderEmpId, newLength],
    () => getTeamLeadersForAreaLeaderData(arealLeaderEmpId),
    {
      refetchOnWindowFocus: false,
    },
  )
}

import Select from 'react-select'
import { useField } from 'formik'
import React from 'react'
import './styles.css'

export default function SelectField(props) {
  const [field, state, { setValue, setTouched }] = useField(props.field?.name)

  // value is an array now
  const onChange = (value) => {
    setValue(value)
  }

  // use value to make this a  controlled component
  // now when the form receives a value for 'campfeatures' it will populate as expected

  return (
    <div className={`select_field`} style={{ textAlign: 'left' }} data-testid="multi-options">
      <Select
        {...props}
        value={state?.value}
        isMulti={props.isMulti}
        isClearable={props.isClearable}
        onChange={onChange}
        onBlur={setTouched}
      />
    </div>
  )
}

import React from 'react'
import Joyride from 'react-joyride'
import { useUpdateContext } from '../../context/updateContext'
import { useTranslation } from 'react-i18next'

const JoyrideComponent = ({
  // stepName = 'allMyActions',
  handleJoyrideCallback = () => {},
  // uwKpi = false,
  // index = 0
}) => {
  const { state, dispatch } = useUpdateContext()
  const { t } = useTranslation(['UpdateWizard'])
  return (
    <Joyride
      // {...conditionalProps}
      // key={state.joyRide.joyRideKey}
      callback={state.joyRide.customJoyRideCallBackFn}
      continuous
      run={state.joyRide.isToursActive[state.joyRide.tourIndex]}
      showProgress
      showSkipButton
      hideCloseButton
      disableOverlayClose
      steps={state.joyRide.steps[state.joyRide.stepName]}
      stepIndex={state.joyRide.tourInnerStepIndex}
      spotlightPadding={5}
      scrollToFirstStep
      disableScrollParentFix
      disableCloseOnEsc
      locale={{
        back: t('joyRideSteps.back'),
        next: t('joyRideSteps.next'),
        skip: t('joyRideSteps.skip'),
        last: t('joyRideSteps.last'),
      }}
      styles={{
        options: {
          zIndex: 300,
          spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
          textColor: '#333',
          primaryColor: '#ED522E',
        },
      }}
    />
  )
}

export default JoyrideComponent

import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const editObjective = async (objectiveData, teamLeadId) => {
  if (teamLeadId !== undefined) objectiveData['teamLeaderId'] = teamLeadId
  const { data } = await axios.post(`${BASE_URL}/edit_objective_no_allocation`, objectiveData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export default function useEditObjective(teamLeadId) {
  const client = useQueryClient()

  return useMutation((data) => editObjective(data, teamLeadId), {
    onSuccess: (response) => {
      if (response.ok) {
        void client.invalidateQueries('objectives')
      }
    },
  })
}

import React from 'react'
import { useTranslation } from 'react-i18next'

const WizardButton = ({ isLoading = false, onClick, disabled, text }) => {
  const { t } = useTranslation(['Common'])

  return (
    <button
      className={'dual-next-btn dual-btn ' + (isLoading === true ? 'disabled-button' : '')}
      type="submit"
      disabled={disabled === true || isLoading}
      onClick={onClick}
    >
      <div
        className="submit-update-wizard-button-inner"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading === true ? (
          <div
            style={{ width: '0.5rem', height: '0.5rem', margin: '0' }}
            className="loading-spinner"
          ></div>
        ) : null}
        {text ? <span>{text}</span> : <span>{t('commonButtons.complete')}</span>}
      </div>
    </button>
  )
}

export default WizardButton

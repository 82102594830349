import { useTranslation } from 'react-i18next'
import Modal from '../Modal'

import './style.css'
import React, { useEffect } from 'react'
import {
  getCompletedMeetingTopics,
  getMeetingTopic,
  useUpdateMeetingLogs,
} from '../../api/services/meetingTopic.services'
import Toast from '../../common/toast'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import { API_ERROR_MESSAGE } from '../../api/constant/route'
import Select from 'react-select'
import { convertTimeStamToMMDDYYYY } from '../../utils/time'
import TopicsReportDropdownMenu from '../MeetingTopicDropDownMenu'
import CardModal from '../CardModal'

const TopicsReportModal = ({ isEmailReminderModalOpen, closeModal, loadAgain = {} }) => {
  const { t } = useTranslation(['MeetingHub'])
  const [resolvedMeetingTopic, setResolvedMeetingTopic] = React.useState([])
  const [teamLeadersList, setTeamLeadersList] = React.useState([])
  const [selectedTeamleader, setSelectedTeamLeader] = React.useState(null)
  const [selectedAll, setSelectedAll] = React.useState(true)
  const [allEmployees, setAllEmployees] = React.useState([])
  const [selectedDays, setSelectedDays] = React.useState(7)
  const [filteredMeetingTopics, setFilteredMeetingTopics] = React.useState([])
  const [isCardModalOpen, setIsCardModalOpen] = React.useState(false)
  const [topicId, setTopicId] = React.useState(0)
  const [topicType, setTopicType] = React.useState(0)
  const mutationUpdateMeetingLogs = useUpdateMeetingLogs()
  const [refresh, setRefresh] = React.useState(false)
  const isDelegate = parseInt(localStorage.getItem('dType')) === 5 ? true : false
  useEffect(() => {
    getEmployeeList()
  }, [])

  useEffect(() => {
    if ((teamLeadersList.length > 0 && selectedTeamleader) || refresh) {
      getResolvedMeetingTopics()
    }
  }, [selectedTeamleader, refresh])

  const getResolvedMeetingTopics = async () => {
    let eIds = []
    if (Array.isArray(selectedTeamleader)) {
      selectedTeamleader.forEach((leader) => {
        if (leader?.eId) {
          eIds.push(leader.eId)
        }
      })
    } else {
      if (Array.isArray(selectedTeamleader.eId)) {
        selectedTeamleader.eId.forEach((id) => eIds.push(id))
      } else {
        eIds.push(selectedTeamleader.eId)
      }
    }
    const result = await getCompletedMeetingTopics(eIds)
    if (result) {
      const combinedObjectives = result?.adminTopicObjectives.concat(
        result?.blockedObjectives,
        result?.decisionObjectives,
        result?.updateObjectives,
      )
      setResolvedMeetingTopic(combinedObjectives)
      filterMeetingTopics(selectedDays, combinedObjectives)
      setRefresh(false)
    }
  }

  async function getEmployeeList() {
    try {
      let params = {
        includeDelegate: isDelegate,
      }
      const employeeList = await getHttpRequest('/get_reporting_employees', { params: params })
      if (employeeList) {
        let employees = employeeList.employees
        let eIds = employees.map((employee) => employee.eId)

        let newEmployee = {
          name: t('Common:filter.all'),
          eId: eIds,
        }

        employees.unshift(newEmployee)
        setAllEmployees(newEmployee)

        setTeamLeadersList(employeeList.employees)
        setSelectedTeamLeader(newEmployee)
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: API_ERROR_MESSAGE,
      })
    }
  }

  const updateSelectedTeamLeader = (selectedOption) => {
    let containsAll = false
    if (selectedOption.length > 0) {
      containsAll = selectedOption.some((leader) => leader.name.includes(t('Common:filter.all')))
    }

    if (containsAll) {
      setSelectedTeamLeader(allEmployees)
      setSelectedAll(true)
    } else {
      setSelectedAll(false)
      setSelectedTeamLeader(selectedOption)
    }
  }

  const generateMeetingTopic = (topics) => {
    const sortedTopics = [...topics].sort((a, b) => {
      return new Date(b.closedTime) - new Date(a.closedTime)
    })
    return sortedTopics.map((topic, index) => {
      return (
        <div className="meeting-topic-inner-grid" key={topic?.umtId}>
          <div
            className="my-meeting-topics-cell"
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            {getActionBox(topic.topicType)}
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => onClikcViewDetails(topic.umtId, topic.topicType)}
            >
              {topic.actionNeeded}
            </span>
          </div>
          <div className="my-meeting-topics-cell">{topic.statement}</div>
          <div className="my-meeting-topics-cell">
            {convertTimeStamToMMDDYYYY(topic.closedTime)}
          </div>
          <div className="my-meeting-topics-cell">{topic.topicOwnerName}</div>
          <div className="my-meeting-topics-cell">{topic.closedByName}</div>
          <div className="my-meeting-topics-cell">
            <div style={{ alignSelf: 'end' }}>
              <TopicsReportDropdownMenu
                onClikcViewDetails={() => onClikcViewDetails(topic.umtId, topic.topicType)}
                onClickUnresolve={() => onClickUnresolve(topic.umtId)}
              />
            </div>
          </div>
        </div>
      )
    })
  }

  const getActionBox = (topicType) => {
    let type
    let color
    if (topicType === 1) {
      type = 'blocker'
      color = 'rgb(237, 82, 46)'
    }
    if (topicType === 2) {
      type = 'guidance'
      color = 'blue'
    }
    if (topicType === 3) {
      type = 'update'
      color = 'gray'
    }
    if (topicType === 4) {
      type = 'admin'
      color = '#9933FF'
    }
    return (
      <div className="meeting-topic-tagbox" style={{ backgroundColor: color }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '0.8rem',
          }}
        >
          {t(`meetingTopicReport.${type}`)}
        </div>
      </div>
    )
  }

  const handleFilter = (days) => {
    setSelectedDays(days)
    filterMeetingTopics(days)
  }

  const filterMeetingTopics = (days, topicsList = null) => {
    let originialTopics = []
    if (topicsList) {
      originialTopics = topicsList
    } else {
      originialTopics = resolvedMeetingTopic
    }
    let filteredTopics = originialTopics
    if (days) {
      const today = new Date()
      const pastDate = new Date(today)
      pastDate.setDate(today.getDate() - days)
      let filterDate = pastDate.getTime() / 1000
      filteredTopics = originialTopics.filter((topic) => {
        return topic.closedTime >= filterDate
      })
    }

    setFilteredMeetingTopics(filteredTopics)
  }

  const onClikcViewDetails = (umtId, topicType) => {
    setIsCardModalOpen(true)
    setTopicId(umtId)
    setTopicType(topicType)
  }

  const onClickUnresolve = (umtId) => {
    unResolveMeetingTopic(umtId)
  }

  async function unResolveMeetingTopic(umtId) {
    const formData = {}
    formData.status = true

    formData.umtId = umtId

    await mutationUpdateMeetingLogs.mutateAsync(formData, {
      onSuccess: () => {
        setRefresh(true)
        loadAgain()
      },
      onError: () => {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      },
    })
  }

  return (
    <div className="obj-report-modal">
      <Modal
        isModalOpen={isEmailReminderModalOpen}
        closeModal={() => {
          closeModal()
        }}
        width={'90%'}
        height={'50rem'}
      >
        <div className="modal-inner-content">
          <div className="obj-report-modal-title">{t('meetingTopicReport.title')}</div>
          <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
            <div>
              <div style={{ fontWeight: 'bold', fontSize: '20px', marginBottom: '1rem' }}>
                {t('meetingTopicReport.showReportFor')}
              </div>
              <Select
                placeholder={t('meetingTopicReport.selectLeader')}
                name="selectOwner"
                value={selectedTeamleader}
                options={teamLeadersList}
                onChange={updateSelectedTeamLeader}
                getOptionLabel={(option) => option.name || ''}
                getOptionValue={(option) => option.eId || ''}
                isMulti={selectedAll ? false : true}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '15rem',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    width: '15rem',
                  }),
                }}
              />
            </div>
          </div>
          <div className="button-container">
            <div
              className="button"
              onClick={() => handleFilter(7)}
              style={{ backgroundColor: selectedDays == 7 ? '#4472c4' : '#a5a5a5' }}
            >
              {t('meetingTopicReport.past7Days')}
            </div>
            <div
              className="button"
              onClick={() => handleFilter(14)}
              style={{ backgroundColor: selectedDays == 14 ? '#4472c4' : '#a5a5a5' }}
            >
              {t('meetingTopicReport.past14Days')}
            </div>
            <div
              className="button"
              onClick={() => handleFilter(30)}
              style={{ backgroundColor: selectedDays == 30 ? '#4472c4' : '#a5a5a5' }}
            >
              {t('meetingTopicReport.past30Days')}
            </div>
            {/* <div
              className="button"
              onClick={() => handleFilter(60)}
              style={{ backgroundColor: selectedDays == 60 ? '#4472c4' : '#a5a5a5' }}
            >
              {t('meetingTopicReport.past60Days')}
            </div> */}
            {/* <div
              className="button"
              onClick={() => handleFilter(90)}
              style={{ backgroundColor: selectedDays == 90 ? '#4472c4' : '#a5a5a5' }}
            >
              {t('meetingTopicReport.past90Days')}
            </div> */}
          </div>
          {filteredMeetingTopics?.length > 0 ? (
            <div
              className="modal-gray-box"
              style={{ width: '100%', borderRadius: '10px', padding: '1rem' }}
            >
              <div style={{ marginBottom: '1rem' }}>
                <div
                  className="meeting-topic-container"
                  style={{ width: '100%', marginBottom: '1rem' }}
                >
                  <div className="meeting-topic-table">
                    <div className="meeting-topic-inner-grid">
                      <div className="my-meeting-topic-header" style={{ textAlign: 'left' }}>
                        {t('meetingTopicReport.topic')}
                      </div>
                      <div
                        className="my-meeting-topic-header"
                        style={{ paddingLeft: '0rem', textAlign: 'left' }}
                      >
                        {t('meetingTopicReport.objective')}
                      </div>
                      <div
                        className="my-meeting-topic-header"
                        style={{ paddingLeft: '0rem', textAlign: 'left' }}
                      >
                        {t('meetingTopicReport.dateClosed')}
                      </div>
                      <div
                        className="my-meeting-topic-header"
                        style={{ paddingLeft: '0rem', textAlign: 'left' }}
                      >
                        {t('meetingTopicReport.topicOwner')}
                      </div>
                      <div
                        className="my-meeting-topic-header"
                        style={{ paddingLeft: '0rem', textAlign: 'left' }}
                      >
                        {t('meetingTopicReport.closedBy')}
                      </div>
                    </div>
                    <React.Fragment>{generateMeetingTopic(filteredMeetingTopics)}</React.Fragment>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ marginTop: '1rem' }}>{t('meetingTopicReport.noTopics')}</div>
          )}
        </div>
      </Modal>
      {isCardModalOpen && topicId !== 0 && topicType !== 0 && (
        <CardModal
          isModalOpen={isCardModalOpen}
          closeModal={() => {
            setIsCardModalOpen(false)
            setTopicId(0)
            setTopicType(0)
          }}
          umtId={topicId}
          topicType={topicType}
        />
      )}
    </div>
  )
}

export default TopicsReportModal

import styled from 'styled-components'

const Container = styled.div`
  padding: 10px;
  // border: 1px solid lightgrey;
  box-shadow: ${(props) =>
    props.canShowGlowing
      ? `
    inset 0 0 2px #fff, 
    inset 1px 0 4px #ffff00, 
    inset -1px 0 4px #ff4500, 
    inset 1px 0 14px #ffff00, 
    inset -1px 0 14px #ff4500, 
    0 0 2px #fff, 
    0 0 4px #ffff00, 
    0 0 4px #ff4500, 
    0 0 6px #ff6347
    `
      : '0 0px 6px #ccc'};
  margin-bottom: 8px;
  border-radius: 4px;
  background-color: ${(props) => props.bgColor};
`

const TaskInnerContainer = styled.div``

export { Container, TaskInnerContainer }

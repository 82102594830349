import { useEffect, useState } from 'react'
import Modal from '../../../../../../components/Modal'
import { getCompanyConfigSettings } from '../../../../../../utils/auth'
import { postHttpRequest } from '../../../../../../api/query/dynamicAPI'
import Toast from '../../../../../../common/toast'
import TextArea from 'rc-textarea'
import { useTranslation } from 'react-i18next'
import InPageLoader from '../../../../../../components/InPageLoader'
import AudioToTextModal from '../../../../../../components/AudioToTextModal/AudioToTextModal'
import { ModalSaveButtonFitted } from '../../../../../../components/ModalSubmitButton'
import {
  applyAiPresentationSuggestEdit,
  applyInLineEditSuggestion,
  deleteAiPresentationData,
} from '../../../../../../api/services/meetingTopic.services'
import { addDotAfterSentence } from '../../../../../../utils/general'

const AiPresentationModal = ({
  isModalOpen,
  closeModal,
  meetingTopic,
  presentationMateirial,
  showEng,
  setIsAiPresentationQuestionModalOpen,
  umtId,
  setIsAiPresentationSlidesModalOpen,
  setSlidesMaterial,
  existingSuggestions,
  setExistingSuggestions,
  loadAgain,
}) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [isEnglish, setIsEnglish] = useState(showEng)
  const productSettings = getCompanyConfigSettings('productSettings')
  const [suggestions, setSuggestions] = useState([])
  const [suggestionString, setSuggestionString] = useState('')
  const { t } = useTranslation(['Common'])
  const [isAudioToTextModalOpen, setIsAudioToTextModalOpen] = useState(false)
  const [audioText, setAudioText] = useState('')
  const [logId, setLogId] = useState()
  const [suggestEdit, setSuggesEdit] = useState('')

  useEffect(() => {
    setIsProcessing(true)
    generateNewPresentationSuggestion()
  }, [presentationMateirial, existingSuggestions])

  useEffect(() => {
    setSuggesEdit((prev) => prev + addDotAfterSentence(prev) + audioText)
  }, [audioText])

  function formatSlides(slides) {
    return slides
      .map((slide, index) => {
        const title = `  Slide${index + 1}: ${slide.Title}`
        const content = slide.content.map((point) => `      • ${point}`).join('\n')
        return `${title}\n${content}`
      })
      .join('\n\n')
  }

  const handleSuugestionChange = (e) => {
    setSuggestionString(e.target.value)
  }

  const generateNewPresentationSuggestion = async () => {
    try {
      setIsAiPresentationQuestionModalOpen(false)
      if (existingSuggestions && existingSuggestions?.Slides.length > 0) {
        setSuggestions(existingSuggestions ?? [])
        let slideString = formatSlides(existingSuggestions.Slides)
        setSuggestionString(slideString)
        setIsProcessing(false)
        return
      }
      presentationMateirial.language = productSettings
        ? 'ENGLISH'
        : isEnglish
          ? 'ENGLISH'
          : 'SPANISH'
      presentationMateirial.umtId = umtId
      let response = await postHttpRequest(
        '/generate_ai_presentation_suggestion',
        presentationMateirial,
      )
      setSuggestions(response?.suggestions ?? [])
      setExistingSuggestions(response?.suggestions)
      setLogId(response?.logId)
      let slideString = formatSlides(response?.suggestions.Slides)
      setSuggestionString(slideString)
    } catch (error) {
      if (error?.response?.status === 400) {
        Toast.fire({
          icon: 'error',
          title: error.response.data,
        })
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Something went wrong',
        })
      }
      closeModal(true)
    }
    setIsProcessing(false)
  }

  const generateSlides = async () => {
    setSlidesMaterial({
      umtId: umtId,
      language: isEnglish ? 'ENGLISH' : 'SPANISH',
      suggestions: suggestions,
      logId: logId,
    })
    setIsAiPresentationSlidesModalOpen(true)
  }

  const handleDelete = async () => {
    await deleteAiPresentationData(umtId)
    setExistingSuggestions(null)
    loadAgain()
  }

  const handleChangeSuggestEdit = (e) => {
    setSuggesEdit(e.target.value)
  }

  const handleApllySuggestEdit = async (umtId, suggestEdit) => {
    if (suggestEdit.trim() !== '') {
      setIsProcessing(true)
      let result = await applyAiPresentationSuggestEdit(umtId, suggestEdit)
      setSuggestions(result ?? [])
      setExistingSuggestions(result)
      let slideString = formatSlides(existingSuggestions.Slides)
      setSuggestionString(slideString)
      setIsProcessing(false)
    }
  }

  const handleSvaeNewScript = async (umtId, suggestionString) => {
    setIsProcessing(true)
    let response = await applyInLineEditSuggestion(umtId, suggestionString)
    setIsProcessing(false)
    setSuggestions(response ?? [])
    setExistingSuggestions(response)
    let slideString = formatSlides(response?.Slides)
    setSuggestionString(slideString)
  }

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      width="95%"
      height={isProcessing ? '18rem' : '55rem'}
      modalContainerStyle={{ width: '93%' }}
      showCard={true}
      title={meetingTopic?.actionNeeded}
      titleStyle={{ color: 'rgb(91,132,203)' }}
      modalTitleContainerStyle={{ marginBottom: '0rem' }}
    >
      {isProcessing && (
        <>
          <div style={{ fontSize: '1.5rem', marginBottom: '2rem' }}>
            <b>{t('aiPresentationModal.generateSlidesSuggestions')}</b>
          </div>
          <InPageLoader inComponent={true} />
        </>
      )}
      {isAudioToTextModalOpen && (
        <AudioToTextModal
          startRecording={true}
          isModalOpen={isAudioToTextModalOpen}
          type="meetingHubCommentsActions"
          closeModal={() => {
            setIsAudioToTextModalOpen(false)
          }}
          setAudioText={setAudioText}
        />
      )}
      {!isProcessing && (
        <div>
          <div
            className="review-generated-topics-list-item"
            style={{ width: '95%', marginTop: '2rem', marginLeft: 'auto', marginRight: 'auto' }}
          >
            <TextArea
              type="text"
              onChange={(e) => {
                handleSuugestionChange(e)
              }}
              value={suggestionString}
              style={{
                height: '30rem',
                backgroundColor: 'white',
                width: '100%',
                border: '1px solid grey',
              }}
              spellCheck={false}
            />
          </div>
          <div
            style={{
              width: '95%',
              marginTop: '1.5rem',
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <div>{t('Common:aiPresentationModal.suggestEdit')}</div>
          </div>
          <div
            className="review-generated-topics-list-item"
            style={{
              width: '95%',
              marginTop: '0.5rem',
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <TextArea
              type="text"
              onChange={(e) => {
                handleChangeSuggestEdit(e)
              }}
              value={suggestEdit}
              style={{
                height: '5rem',
                backgroundColor: 'white',
                width: '100%',
                border: '1px solid grey',
              }}
              spellCheck={false}
            />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <button
                type="button"
                className="btn-card tonedown-blue-btn"
                style={{ width: '2.5rem', marginTop: '0.5rem' }}
                onClick={(e) => {
                  setIsAudioToTextModalOpen(true)
                }}
              >
                <span className="material-symbols-outlined icon-size">mic</span>
              </button>
              <div
                onClick={(e) => {
                  handleApllySuggestEdit(umtId, suggestEdit)
                }}
                style={{
                  cursor: 'pointer',
                  color: 'rgb(91,132,203)',
                  fontSize: '1.1rem',
                  marginTop: '0.5rem',
                  alignItems: 'center',
                  marginLeft: '1rem',
                }}
              >
                <span>Apply</span>
              </div>
            </div>
          </div>
          <div
            className="modal-dual-btn"
            style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}
          >
            <ModalSaveButtonFitted
              tag={t('Common:commonButtons.delete')}
              onClick={() => {
                handleDelete()
                closeModal()
              }}
            />

            <ModalSaveButtonFitted
              tag={t('Common:commonButtons.save')}
              onClick={() => {
                handleSvaeNewScript(umtId, suggestionString)
              }}
            />

            <ModalSaveButtonFitted
              tag={t('Common:commonButtons.buildSlides')}
              onClick={() => {
                generateSlides()
              }}
            />
          </div>
        </div>
      )}
    </Modal>
  )
}

export default AiPresentationModal

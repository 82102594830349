import React from 'react'
import VerbDataList from './VerbDataList'

export default function useVerbSuggestions(resetInterval = null) {
  const [verbOptions, setVerbOptions] = React.useState([])

  function handleVerbSuggestions(target) {
    if (target.value) {
      const filteredOptions = VerbDataList.filter((option) =>
        option.toLowerCase().startsWith(target.value.toLowerCase()),
      )
      setVerbOptions(filteredOptions)
    } else {
      setVerbOptions([])
    }
  }

  return [verbOptions, handleVerbSuggestions]
}

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getObjectiveDataWithTeamLeaderId = async (teamLeaderId) => {
  const { data } = await axios.get(`${BASE_URL}${`/team_leader_objectives/${teamLeaderId}`}`)
  return data
}

export default function useObjectiveDataWithTeamLeaderId(teamLeaderId) {
  return useQuery(
    ['objectives', teamLeaderId],
    () => getObjectiveDataWithTeamLeaderId(teamLeaderId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(teamLeaderId),
    },
  )
}

import React, { useState, useEffect } from 'react'
import { useExcoExerciseContext } from '../../context/ExcoExerciseContext'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import InPageLoader from '../../../../components/InPageLoader'
import '../../styles.scss'

const AIExerciseQuestion1 = () => {
  const { handleNext, isLastStep, submitAnswer, isLoading } = useOutletContext()
  const { state, dispatch } = useExcoExerciseContext()
  const { t } = useTranslation(['UpdateWizard'])
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1)
    }, 1000)

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId)
  }, []) // Empty dependency array ensures the effect runs only once on mount

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
    const remainingSeconds = time % 60
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`
  }

  const [textInput, setTextInput] = useState('')
  const textInputChangeHandler = (e) => {
    setTextInput(e.target.value)
  }
  const [resultText, setResultText] = useState('')
  const resultTextChangeHandler = (e) => {
    setResultText(e.target.value)
  }

  const [gptVersion, setGptVersion] = useState('gpt-35')
  const gptVersionChangeHandler = (e) => {
    setGptVersion(e.target.value)
  }

  const [resultPasses, setResultPasses] = useState(null)

  return (
    <>
      <div className="wizard-step">
        <h2 className="wizard-step__title">Exercise 1: Perceive</h2>
        <div className="wizard-step__main-content-container">
          <div
            className="ai-workshop"
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <div className="score-container">
              <span>Timer: {formatTime(seconds)}</span>
            </div>

            <div className="content-container">
              <div className="prompt-container">
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                  <span>
                    <b>
                      Maria asks the chatbot
                      <br /> (automatically added to prompt to GPT):
                    </b>
                    <br />
                    <br />
                    “I want a macbook”
                  </span>
                  <span>
                    <b>Goal of this exercise: </b>
                    <br />
                    Write a prompt to interpret Maria's ask and return the right parameters so that
                    we can perform a search for her.
                    <br />
                    <br />
                    The format that our system expects is:
                    <br />
                    <br />
                    (Item_type = "", brand = "", Model_year = "", User_rating = "")
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '1rem',
                  }}
                >
                  <span>Settings:</span>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '1rem',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (gptVersion === 'gpt-35') {
                        setGptVersion('gpt-4')
                      } else {
                        setGptVersion('gpt-35')
                      }
                    }}
                  >
                    <span>GPT 3.5</span>
                    {gptVersion === 'gpt-35' && (
                      <span
                        style={{ fontSize: '35px', color: 'rgb(176, 173, 173)' }}
                        class="material-symbols-outlined"
                      >
                        toggle_off
                      </span>
                    )}

                    {gptVersion === 'gpt-4' && (
                      <span
                        style={{ fontSize: '35px', color: '#4472c4' }}
                        class="material-symbols-outlined"
                      >
                        toggle_on
                      </span>
                    )}
                    <span>GPT 4.0</span>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <div
                  className="sleek-button sleek-full-grey"
                  style={{
                    width: '100%',
                    padding: 'unset',
                    height: 'unset',
                    borderRadius: '5px',
                  }}
                  onClick={() => {
                    if (window.confirm('Are you sure?')) {
                      //update timer for question1
                      dispatch({
                        type: 'SAVE_QUESTION_TIMER',
                        questionNumber: 1,
                        time: seconds,
                      })
                      dispatch({
                        type: 'SAVE_LAST_PAGE_VISITED',
                        lastPageVisited: 2,
                      })
                      handleNext()
                    }
                  }}
                >
                  {' '}
                  For facilitator user only{' '}
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <span>Write your prompt here:</span>
                <textarea
                  rows="20"
                  id="textInput"
                  name="textInput"
                  value={textInput}
                  onChange={(e) => {
                    textInputChangeHandler(e)
                  }}
                  style={{
                    resize: 'none',
                    borderRadius: '10px',
                    backgroundColor: 'white',
                    fontSize: '14px',
                    width: '20rem',
                    padding: '5px',
                  }}
                />
                <div
                  className="sleek-button sleek-full-blue"
                  style={{ width: '100%', padding: 'unset', height: 'unset', borderRadius: '5px' }}
                  onClick={() => {
                    if (isLoading === false) {
                      if (textInput != null && textInput.trim() !== '') {
                        submitAnswer(1, gptVersion, textInput, setResultPasses, setResultText)
                      }
                    }
                  }}
                >
                  Execute My Prompt{' '}
                  {isLoading === true ? (
                    <div
                      style={{ width: '0.5rem', height: '0.5rem', margin: '0' }}
                      className="loading-spinner"
                    ></div>
                  ) : null}
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <span>Result:</span>

                <textarea
                  rows="10"
                  id="resultText"
                  name="resultText"
                  value={resultText}
                  disabled={true}
                  onChange={(e) => {
                    resultTextChangeHandler(e)
                  }}
                  style={{
                    resize: 'none',
                    borderRadius: '10px',
                    backgroundColor: 'white',
                    fontSize: '14px',
                    width: '20rem',
                  }}
                />
                <b>Expected Answer:</b>

                <span style={{ width: '20rem', paddingBottom: '0.3rem' }}>
                  &#40;Item_type = "laptop", brand = "Apple", Model_year = "", User_rating = ""&#41;
                </span>
                {resultPasses === true && (
                  <div
                    className="sleek-button sleek-full-green"
                    style={{
                      width: '100%',
                      padding: 'unset',
                      height: 'unset',
                      borderRadius: '5px',
                    }}
                    onClick={() => {
                      //update timer for question1
                      dispatch({
                        type: 'SAVE_QUESTION_TIMER',
                        questionNumber: 1,
                        time: seconds,
                      })
                      dispatch({
                        type: 'SAVE_LAST_PAGE_VISITED',
                        lastPageVisited: 2,
                      })
                      handleNext()
                    }}
                  >
                    {' '}
                    Good Job! Next{' '}
                  </div>
                )}
                {resultPasses === false && (
                  <div
                    className="sleek-button sleek-full-red"
                    style={{
                      width: '100%',
                      padding: 'unset',
                      height: 'unset',
                      borderRadius: '5px',
                    }}
                  >
                    {' '}
                    Wrong answer, try again{' '}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AIExerciseQuestion1

import { React, useEffect, useState, Fragment } from 'react'
import { CardContainer, CardHeader } from './styles'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { getTeamLeaderList } from '../../../../api/services/employee.services'
import { getListOfObjectives } from '../../../../api/services/objectives.services.jsx'
import Button from '../../../../components/Button'
import { routes } from '../../../../utils/routes'
import useDeleteObjective_v2 from '../../../../api/mutation/deleteObjectiveHome_v2'
import { useTranslation } from 'react-i18next'
// import ObjectiveRow from '../../../Home/components/Objectives/components/ObjectiveRow'

const Grid = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 0.8fr 0.3fr;
  row-gap: 0.5em;
  column-gap: 0.5rem;
  padding-top: 1rem;
  width: 100%;
`

const GridHeader = styled.div`
  font-weight: bold;
  justify-content: center;
  padding: 0.2rem 0.5rem;
`

const GridContent = styled.div`
  justify-content: center;
  padding: 0.2rem 0.6rem;
  word-break: break-word;
`

const TeamObjectiveCard = ({ setInitModalOpen = () => {} }) => {
  const [teamLeaderList, setTeamLeaderList] = useState([])
  const [objectivesByTeamId, setObjectives] = useState([])
  const [teamLeadId, setTeamLeadId] = useState(0)
  const deleteObjective_v2 = useDeleteObjective_v2()
  const navigate = useNavigate()
  const { t } = useTranslation(['Dashboard'])
  useEffect(() => {
    getLeaderList()
  }, [])

  async function getLeaderList() {
    const result = await getTeamLeaderList('?level=3')
    if (result && result?.employees) setTeamLeaderList(result?.employees)
  }

  useEffect(() => {
    ;(async () => {
      if (teamLeaderList && teamLeaderList.length > 0) {
        setTeamLeadId(teamLeaderList[0].eId)
        let objectives = await getListOfObjectives(teamLeaderList[0].eId)
        setObjectives(objectives.objectives)
      }
    })()
  }, [teamLeaderList])

  const displayObjectives = async (e) => {
    setTeamLeadId(e.target.value)
    let objectives = await getListOfObjectives(e.target.value)
    setObjectives(objectives.objectives)
  }

  const handleEdit = async (objective) => {
    navigate(`/${routes.demandintake}`, {
      state: { editObj: true, objective: objective, tid: teamLeadId },
    })
  }

  return (
    <>
      <CardContainer basis="100%">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1rem',
            width: '100%',
            position: 'relative',
          }}
        >
          <div className="underline-admin"></div>
        </div>
        <Grid>
          <GridHeader
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '0.5rem',
              justifyContent: 'flex-start',
              paddingLeft: '0.1rem',
            }}
          >
            <GridHeader style={{ whiteSpace: 'nowrap' }}>Team objectives for </GridHeader>
            <select
              style={{ marginBottom: '1rem' }}
              className="governance select"
              onChange={displayObjectives}
            >
              {teamLeaderList?.map((leader, leaderIndex) => (
                <option value={leader.eId} key={`leader-${leaderIndex}`} id={leader.eId}>
                  {leader.name}
                </option>
              ))}
            </select>
          </GridHeader>
          <GridHeader></GridHeader>
          <GridHeader></GridHeader>
          {objectivesByTeamId?.map((obj, ind) => (
            <Fragment key={obj.id}>
              <GridContent>{obj.statement}</GridContent>
              <GridContent>
                <></>
              </GridContent>
              <GridContent style={{ display: 'flex', flexDirection: 'row', columnGap: '0.5rem' }}>
                {!obj.isGeneralObjective && (
                  <div class="fitted-button blue" onClick={(e) => handleEdit(obj)}>
                    <span class="material-symbols-outlined icon-size">edit</span>
                  </div>
                )}
              </GridContent>
            </Fragment>
          ))}
        </Grid>
        <h4 style={{ marginTop: '3rem', textAlign: 'center' }}>
          {t('adminDashboard.teamObjNote')}
        </h4>
      </CardContainer>
    </>
  )
}

export default TeamObjectiveCard

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { removeMentionMarkupFromText } from '../../utils/parseMarkup'
import { useTranslation } from 'react-i18next'
import UpdateMeetingTopicModal from '../../pages/MeetingMultiView/components/MeetingSummary/sub-components/UpdateMeetingTopicModal'
import Toast from '../../common/toast'
import Swal from 'sweetalert2'
import TextArea from 'rc-textarea'
import AssigneeActivity from '../../../../react_client/src/pages/MeetingMultiView/components/MeetingTopics/common/assigneeActivity'
import { getHttpRequest, postHttpRequest } from '../../api/query/dynamicAPI'
import {
  isAreaLeader,
  isTeamLeader,
  getCompanyConfigSettings,
  isDelegate,
} from '../../../../react_client/src/utils/auth'
import { getDecisionLogByMeetingInstanceId } from '../../api/services/meetingTopic.services'
import Modal from '../../components/Modal'
import logoWithoutText from '../../../../react_client/src/assets/transforml_image_no_padding.png'

const ObjectiveStatement = styled.div`
  font-size: 16px;
  width: -moz-fit-content;
  width: fit-content;
  word-break: break-word;
`

const DecisionLogModal = ({
  // meetingGeneratedItems = [],
  reload = () => {},
  isFinalized = false,
  meetingNoteName = '',
  isViewOpen,
  closeView,
  meetingInstanceId,
  meetingName,
}) => {
  const { t } = useTranslation(['Common'])
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [selectedItem, setSelectedItem] = React.useState(null)
  const [selectedTopic, setSelectedTopic] = React.useState(null)
  const [isAssignmentModalOpen, setIsAssignmentModalOpen] = React.useState(false)
  const [selectedItemId, setSelectedItemId] = React.useState(null)
  const [selectedTopicId, setSelectedTopicId] = React.useState(null)
  const [meetingGeneratedItems, setMeetingGeneratedItems] = React.useState([])
  const [availableTopics, setAvailableTopics] = React.useState([])

  const isProductSettingsEnabled = getCompanyConfigSettings('productSettings')

  const enableDecisionLog = getCompanyConfigSettings('enableDecisionLog') === true

  const handleItemEdit = (item, topic) => {
    setSelectedItem(item)
    setSelectedTopic(topic)
  }
  const transferItemToTopic = (selectedItem, newSelectedTopic, currentTopic) => {
    const tempmMeetingGeneratedItems = [...meetingGeneratedItems]

    //find the destination topic
    let destTopic = tempmMeetingGeneratedItems.flatMap((obj) =>
      obj.meetingTranscriptGeneratedItemWrappers.filter(
        (topic) => topic.umtId === newSelectedTopic.umtId,
      ),
    )[0]

    //find the source topic
    let sourceTopic = tempmMeetingGeneratedItems.flatMap((obj) =>
      obj.meetingTranscriptGeneratedItemWrappers.filter(
        (topic) => topic.umtId === currentTopic.umtId,
      ),
    )[0]

    if (!sourceTopic) return

    if (!destTopic) {
      // if we don't have a destination topic if means it is not currently on display so we need to add it first
      const newTopic = {
        umtId: newSelectedTopic.umtId,
        umtDescription: newSelectedTopic.umtDescription,
        meetingTranscriptGeneratedItems: [],
        goalId: newSelectedTopic.goalId,
        umtType: newSelectedTopic.umtType,
      }
      destTopic = newTopic

      let goalToInsert = tempmMeetingGeneratedItems.find(
        (obj) => obj.goalId === newSelectedTopic.goalId,
      ) //if we can't find the goal we need to create it

      if (goalToInsert === undefined) {
        goalToInsert = {
          goalId: newSelectedTopic.goalId,
          goalDescription: newSelectedTopic.goalDescription,
          meetingTranscriptGeneratedItemWrappers: [],
          initiativeId: newSelectedTopic.initiativeId,
          isGeneralObjective: newSelectedTopic.isGeneralObjective,
        }

        tempmMeetingGeneratedItems.push(goalToInsert)
      }

      //add the new topic to the goal
      goalToInsert.meetingTranscriptGeneratedItemWrappers.push(newTopic)
    }

    if (destTopic) {
      //add item to the destination topic
      destTopic.meetingTranscriptGeneratedItems.push(selectedItem)

      //remove item from the source topic
      sourceTopic.meetingTranscriptGeneratedItems =
        sourceTopic.meetingTranscriptGeneratedItems.filter((item) => item.id !== selectedItem.id)

      function filterMeetingItems(tempmMeetingGeneratedItems) {
        return tempmMeetingGeneratedItems
          .map((topic) => ({
            ...topic,
            meetingTranscriptGeneratedItemWrappers:
              topic.meetingTranscriptGeneratedItemWrappers.filter(
                (item) =>
                  (item.meetingTranscriptGeneratedItems &&
                    item.meetingTranscriptGeneratedItems.length > 0) ||
                  item.isAddedCurrentMeeting,
              ),
          }))
          .filter(
            (item) =>
              item.meetingTranscriptGeneratedItemWrappers.length > 0 || item.isAddedCurrentMeeting,
          )
      }

      function removeEmptyGoals(items) {
        return items.filter((item) => item.meetingTranscriptGeneratedItemWrappers.length > 0)
      }

      const filteredMeetings = filterMeetingItems(tempmMeetingGeneratedItems)
      const filteredGoals = removeEmptyGoals(filteredMeetings)
      // Sort the tempmMeetingGeneratedItems
      const sortedItems = sortMeetingGeneratedItems(filteredGoals)

      setMeetingGeneratedItems([...sortedItems])
    }
  }

  function sortMeetingGeneratedItems(items) {
    return items
      .sort((a, b) => {
        // Sort by goalDescription
        if (a.goalDescription < b.goalDescription) return -1
        if (a.goalDescription > b.goalDescription) return 1

        return 0 // If goalDescriptions are equal, maintain original order
      })
      .map((goal) => ({
        ...goal,
        meetingTranscriptGeneratedItemWrappers: sortMeetingTranscriptGeneratedItemWrappers(
          goal.meetingTranscriptGeneratedItemWrappers,
        ),
      }))
  }

  function sortMeetingTranscriptGeneratedItemWrappers(wrappers) {
    return wrappers
      .sort((a, b) => {
        // Sort by umtDescription
        if (a.umtDescription < b.umtDescription) return -1
        if (a.umtDescription > b.umtDescription) return 1

        return 0 // If umtDescriptions are equal, maintain original order
      })
      .map((wrapper) => ({
        ...wrapper,
        meetingTranscriptGeneratedItems: sortMeetingTranscriptGeneratedItems(
          wrapper.meetingTranscriptGeneratedItems,
        ),
      }))
  }

  function sortMeetingTranscriptGeneratedItems(items) {
    return items.sort((a, b) => {
      // Sort by description
      if (a.description < b.description) return -1
      if (a.description > b.description) return 1

      return 0 // If descriptions are equal, maintain original order
    })
  }

  const handleItemUpdate = (
    selectedItemId,
    selectedTopicId,
    isAccepted = false,
    value,
    isTextUpdate = false,
    isAssigneeUpdate = false,
  ) => {
    console.log('selectedItemId:', selectedItemId)

    const updatedMeetingGeneratedItems = meetingGeneratedItems.map((goal) => ({
      ...goal,
      meetingTranscriptGeneratedItemWrappers: goal.meetingTranscriptGeneratedItemWrappers.map(
        (topic) => ({
          ...topic,
          meetingTranscriptGeneratedItems: topic.meetingTranscriptGeneratedItems.map((item) => ({
            ...item,
          })),
        }),
      ),
    }))

    let updatedItem = null
    let updatedTopic = null
    let updatedGoal = null

    for (const goal of updatedMeetingGeneratedItems) {
      for (const topic of goal.meetingTranscriptGeneratedItemWrappers) {
        const foundItem = topic.meetingTranscriptGeneratedItems.find(
          (item) => item.id === selectedItemId,
        )
        if (foundItem) {
          updatedItem = foundItem
          updatedTopic = topic
          updatedGoal = goal
          break
        }
      }
      if (updatedItem) break
    }

    if (!updatedItem) {
      return
    }

    if (isAssigneeUpdate) {
      updatedItem.assigneeList = value
    } else if (!isTextUpdate) {
      updatedItem.isAccepted = isAccepted
    } else {
      updatedItem.description = value
    }

    setMeetingGeneratedItems(updatedMeetingGeneratedItems)
  }

  const handleFinalizeMeeting = async () => {
    const filteredMeetingGeneratedItems = meetingGeneratedItems.map((goal) => ({
      ...goal,
      meetingTranscriptGeneratedItemWrappers: goal.meetingTranscriptGeneratedItemWrappers.filter(
        (topic) => topic.umtId !== -1,
      ),
    }))
    console.log('filteredMeetingGeneratedItems:', filteredMeetingGeneratedItems)

    const actionRequiredUpdates = []
    const commentRequiredUpdates = []
    const actionNew = []
    const commentNew = []
    const topicsShouldBeDeleted = []

    filteredMeetingGeneratedItems.forEach((goal) => {
      goal.meetingTranscriptGeneratedItemWrappers.forEach((topic) => {
        if (
          (topic.isAddedCurrentMeeting && topic.meetingTranscriptGeneratedItems.length === 0) ||
          topic.isDeleted
        ) {
          topicsShouldBeDeleted.push(topic)
        }

        topic.meetingTranscriptGeneratedItems.forEach((item) => {
          item.umtId = topic.umtId
          item.goalId = goal.goalId

          if (item.id === 0) {
            if (item.isComment) {
              commentNew.push(item)
            } else {
              actionNew.push(item)
            }
          } else {
            if (item.isComment) {
              commentRequiredUpdates.push(item)
            } else {
              actionRequiredUpdates.push(item)
            }
          }
        })
      })
    })

    // console.log('Action Required Updates:', actionRequiredUpdates);
    // console.log('Comment Required Updates:', commentRequiredUpdates);
    // console.log('New Actions:', actionNew);
    // console.log('New Comments:', commentNew);
    // console.log('Topics to be deleted:', topicsShouldBeDeleted);

    let result = await Swal.fire({
      title: t('Common:modalMessages.areYouSureFinalizeMeeting'),
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })

    if (result.isConfirmed) {
      try {
        await postHttpRequest('/meeting/finalize_meeting', {
          meetingInstanceId: meetingInstanceId,
          actionRequiredUpdates,
          commentRequiredUpdates,
          actionNew,
          commentNew,
          topicsShouldBeDeleted,
          meetingNoteName,
        })

        if (enableDecisionLog && (isAreaLeader() || isDelegate())) {
          let result = await Swal.fire({
            title: t('Common:modalMessages.doYouWantToSendOutADecisionLog'),
            showCancelButton: true,
            confirmButtonText: t('Common:commonButtons.yes'),
            cancelButtonText: t('Common:commonButtons.no'),
          })

          if (result.isConfirmed) {
            window.location.href = '/MeetingMultiView/DecisionLog'
          } else {
            window.location.href = '/MeetingMultiView/'
          }
        }
        reload()
        closeView()
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
    }
  }

  const deleteTopic = async (value) => {
    const updatedMeetingGeneratedItems = meetingGeneratedItems.map((goal) => ({
      ...goal,
      meetingTranscriptGeneratedItemWrappers: goal.meetingTranscriptGeneratedItemWrappers.map(
        (topic) => ({
          ...topic,
          meetingTranscriptGeneratedItems: topic.meetingTranscriptGeneratedItems.map((item) => ({
            ...item,
          })),
        }),
      ),
    }))

    let deletedTopicState = null
    let deletedTopicGoal = null

    for (const goal of updatedMeetingGeneratedItems) {
      const foundTopic = goal.meetingTranscriptGeneratedItemWrappers.find(
        (topic) => topic.umtId === value.umtId,
      )
      if (foundTopic) {
        deletedTopicState = foundTopic
        deletedTopicGoal = goal
        break
      }
    }

    if (!deletedTopicState) return

    const result = await Swal.fire({
      title: t('Common:modalMessages.areYouSureDeleteThis'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })

    if (result.isConfirmed) {
      deletedTopicState.isDeleted = true
      deletedTopicState.meetingTranscriptGeneratedItems.forEach((item) => (item.isAccepted = false))

      // Update the state
      setMeetingGeneratedItems(updatedMeetingGeneratedItems)

      // Update availableTopics
      setAvailableTopics((prev) => prev.filter((topic) => topic.umtId !== value.umtId))
    }
  }

  const addBackTopic = async (value) => {
    const updatedMeetingGeneratedItems = meetingGeneratedItems.map((goal) => ({
      ...goal,
      meetingTranscriptGeneratedItemWrappers: goal.meetingTranscriptGeneratedItemWrappers.map(
        (topic) => ({
          ...topic,
          meetingTranscriptGeneratedItems: topic.meetingTranscriptGeneratedItems.map((item) => ({
            ...item,
          })),
        }),
      ),
    }))

    let deletedTopic = null
    let parentGoal = null

    for (const goal of updatedMeetingGeneratedItems) {
      deletedTopic = goal.meetingTranscriptGeneratedItemWrappers.find(
        (topic) => topic.umtId === value.umtId,
      )
      if (deletedTopic) {
        parentGoal = goal
        break
      }
    }

    if (!deletedTopic) return

    deletedTopic.isDeleted = false

    // Update the state
    setMeetingGeneratedItems(updatedMeetingGeneratedItems)

    // Update availableTopics
    setAvailableTopics((prev) => [
      ...prev,
      {
        umtId: value.umtId,
        umtDescription: value.umtDescription,
        goalId: parentGoal.goalId,
        goalDescription: parentGoal.goalDescription,
        umtType: value.umtType || deletedTopic.umtType,
      },
    ])
  }

  const getDecisionLogsByMeetingInstanceId = async () => {
    let res = await getDecisionLogByMeetingInstanceId(meetingInstanceId)
    setMeetingGeneratedItems(res?.meetingTranscriptObjLevel)
    let trackedTopics =
      res?.meetingTranscriptGeneratedItemWrappers
        ?.filter((topic) => topic.umtId !== -1)
        ?.map((topic) => ({
          umtDescription: topic.umtDescription,
          umtId: topic.umtId,
          goalId: topic.goalId,
        })) ?? []

    let response = await getHttpRequest('/meeting/get_all_meeting_transcripts')
    let topicsOwnedByUser = response.topicsOwned

    let newAvailableTopics = [...trackedTopics, ...topicsOwnedByUser]

    // remove duplidate topics
    setAvailableTopics(
      newAvailableTopics?.filter(
        (topic, index, arr) => arr.findIndex((val) => val.umtId === topic.umtId) === index,
      ),
    )
  }

  useEffect(() => {
    getDecisionLogsByMeetingInstanceId()
  }, [])

  return (
    <Modal
      title={meetingName}
      isModalOpen={isViewOpen}
      data-testid="Assigne"
      closeModal={closeView}
      width={'60rem'}
      titleStyle={{ color: 'rgb(91,132,203)', textAlign: 'left' }}
      modalTitleContainerStyle={{ marginBottom: '0rem', justifyContent: 'flex-start' }}
    >
      <>
        {isModalOpen && selectedItem && selectedTopic && (
          <UpdateMeetingTopicModal
            selectedItem={selectedItem}
            currentTopic={selectedTopic}
            closeModal={() => {
              setSelectedItem(null)
              setSelectedTopic(null)
              setIsModalOpen(false)
            }}
            availableTopics={availableTopics}
            transferItemToTopic={transferItemToTopic}
          />
        )}
        {isAssignmentModalOpen &&
          selectedItem &&
          selectedItemId !== null &&
          selectedTopicId !== null && (
            <AssigneeActivity
              objectiveId={-1}
              setModelView={setIsAssignmentModalOpen}
              assigneeList={selectedItem.assigneeList}
              view={isAssignmentModalOpen}
              placeHolderOnly={true}
              cleanup={(assignees) =>
                handleItemUpdate(
                  selectedItemId,
                  selectedTopicId,
                  selectedItem.isAccepted,
                  assignees,
                  false,
                  true,
                )
              }
            />
          )}
        {meetingGeneratedItems?.length > 0 ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {meetingGeneratedItems
              .sort((a, b) => b.goalDescription - a.goalDescription)
              .map((obj, objIndex) => (
                <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '2rem' }}>
                  <ObjectiveStatement>
                    <span>
                      <b>
                        {isProductSettingsEnabled &&
                        !obj?.isGeneralObjective &&
                        obj?.initiativeId &&
                        obj.isGeneralObjective === false ? (
                          <span>
                            {obj?.initiativeId.toString()?.padStart(5, '0')} - {obj.goalDescription}
                          </span>
                        ) : (
                          <span>{obj.goalDescription}</span>
                        )}
                      </b>
                    </span>
                  </ObjectiveStatement>

                  {obj.meetingTranscriptGeneratedItemWrappers
                    .sort((a, b) => {
                      if (a.umtId === -1) return 1
                      else if (b.umtId === -1) return -1
                      else if (a.umtType < b.umtType) return -1
                      else if (a.umtType > b.umtType) return 1
                      else return 0
                    })
                    .map((topic, index) => {
                      return (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              columnGap: '1rem',
                              justifyContent: 'space-between',
                              paddingRight: '0rem',
                              paddingTop: '0.3rem',
                              paddingBottom: '0.3rem',
                              marginTop: '1rem',
                              marginLeft: '0.5rem',
                              // marginLeft:'1%',
                            }}
                            key={topic.umtId}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  width: '100%',
                                  flexDirection: 'column',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    width: '100%',
                                    flexDirection: 'column',
                                    rowGap: '0.5rem',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      //width: '97%',
                                      flexDirection: 'row',
                                      gap: '1rem',
                                      //justifyContent: 'space-between',
                                    }}
                                  >
                                    <div
                                      style={{
                                        backgroundColor:
                                          topic.umtType === 1
                                            ? 'rgb(237, 82, 46)'
                                            : topic.umtType === 2
                                              ? 'rgb(9, 38, 213)'
                                              : topic.umtType === 3
                                                ? 'rgb(127, 131, 155)'
                                                : '#9933ff',
                                        //item.isComment || item.isDecision ? '#EF967A' : '#c6d4ed',
                                        color: 'white',
                                        padding: '0.1rem 0.2rem',
                                        borderRadius: '0.5rem',
                                        // marginLeft: '0.5rem',
                                        // marginBottom: '0.3rem'
                                        //margin: 'auto auto',
                                        width: '5rem',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          fontSize: '13.33px',
                                        }}
                                      >
                                        {topic.umtType === 1
                                          ? t('filter.blocker')
                                          : topic.umtType === 2
                                            ? t('filter.guidance')
                                            : topic.umtType === 3
                                              ? t('filter.update')
                                              : t('filter.admin')}
                                      </div>
                                    </div>
                                    <ObjectiveStatement>
                                      <span
                                        style={{
                                          textDecoration: topic.isDeleted ? 'line-through' : 'none',
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        <span>
                                          {topic.umtId !== -1
                                            ? topic.umtDescription
                                            : t('transcription.notyetOrganized')}
                                        </span>
                                      </span>
                                    </ObjectiveStatement>
                                    {!isFinalized && topic.isAddedCurrentMeeting && (
                                      <>
                                        {!topic.isDeleted ? (
                                          <div
                                            class="fitted-button icon-size"
                                            onClick={() => deleteTopic(topic)}
                                          >
                                            <span
                                              class="material-symbols-outlined icon-size"
                                              style={{ fontSize: '16px' }}
                                            >
                                              delete
                                            </span>
                                          </div>
                                        ) : (
                                          <div
                                            class="fitted-button icon-size"
                                            onClick={() => addBackTopic(topic)}
                                          >
                                            <span class="material-symbols-outlined icon-size-l">
                                              add_circle
                                            </span>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <div className="underline-meeting"></div>
                                </div>
                                <>
                                  <div
                                    style={{ marginBottom: '1rem', padding: '0rem 2rem 1rem 2rem' }}
                                  >
                                    {topic.meetingTranscriptGeneratedItems.map(
                                      (item, itemIndex) => {
                                        return (
                                          <>
                                            <div
                                              key={item.id}
                                              className={`${
                                                isFinalized
                                                  ? 'meeting-action-comments-view-finalized'
                                                  : 'meeting-action-comments-view'
                                              }`}
                                              style={{
                                                alignItems: 'center',
                                              }}
                                            >
                                              {!isFinalized && (
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                  }}
                                                  className="tooltip"
                                                >
                                                  {topic.umtId !== -1 && (
                                                    <>
                                                      <input
                                                        type="checkbox"
                                                        id={item.id}
                                                        name={item.id}
                                                        checked={item.isAccepted}
                                                        onChange={() =>
                                                          handleItemUpdate(
                                                            item.id,
                                                            topic.umtId,
                                                            !item.isAccepted,
                                                          )
                                                        }
                                                        disabled={topic.isDeleted}
                                                      />
                                                      <span
                                                        class="tooltiptext meeting_notes"
                                                        style={{
                                                          width: 'fit-content',
                                                          marginLeft: '-40px',
                                                          paddingRight: '1rem',
                                                          bottom: '100%',
                                                        }}
                                                      >
                                                        {' '}
                                                        {item.isAccepted
                                                          ? t('Common:tooltip.accept')
                                                          : t('Common:tooltip.reject')}{' '}
                                                      </span>
                                                    </>
                                                  )}
                                                </div>
                                              )}
                                              <div>
                                                {item.isGeneratedByAi && (
                                                  <React.Fragment>
                                                    <img
                                                      className="logo-new-folded"
                                                      style={{
                                                        width: '1.2rem',
                                                        height: '1.2rem',
                                                        paddingRight: '0.3rem',
                                                      }}
                                                      src={logoWithoutText}
                                                      alt="TransforML Logo"
                                                    />
                                                    <span
                                                      style={{
                                                        fontStyle: 'italic',
                                                        fontWeight: 'bold',
                                                        fontSize: '1rem',
                                                        color: 'rgb(91, 132, 203)',
                                                      }}
                                                    >
                                                      {t('decisionLogModal.ai')}
                                                    </span>
                                                  </React.Fragment>
                                                )}
                                                <div
                                                  style={{
                                                    backgroundColor:
                                                      item.isComment || item.isDecision
                                                        ? 'white'
                                                        : '#A9A9A9',
                                                    color:
                                                      item.isComment || item.isDecision
                                                        ? '#A9A9A9'
                                                        : 'white',
                                                    border: `1px solid ${
                                                      item.isComment || item.isDecision
                                                        ? '#A9A9A9'
                                                        : 'white'
                                                    }`,

                                                    borderRadius: '0.5rem',
                                                    // marginLeft: '0.5rem',
                                                    // marginBottom: '0.3rem'
                                                    margin: 'auto auto',
                                                    width: '4.5rem',
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: 'center',
                                                      fontSize: '13.33px',
                                                    }}
                                                  >
                                                    {item.isDecision
                                                      ? t('commentsModal.commentsType.decision')
                                                      : item.isComment
                                                        ? t('commentsModal.commentsType.comment')
                                                        : t('commentsModal.commentsType.action')}
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  width: '100%',
                                                  margin: 'auto 0',
                                                }}
                                              >
                                                <div
                                                  className="review-generated-topics-list-item"
                                                  style={{ width: '100%' }}
                                                >
                                                  <TextArea
                                                    type="text"
                                                    onChange={(e) => {
                                                      handleItemUpdate(
                                                        item.id,
                                                        topic.umtId,
                                                        item.isAccepted,
                                                        e.target.value,
                                                        true,
                                                      )
                                                    }}
                                                    value={removeMentionMarkupFromText(
                                                      item.description,
                                                    )}
                                                    style={{
                                                      height: '2rem',
                                                      backgroundColor: 'white',
                                                      width: '100%',
                                                      border: `1px solid ${
                                                        isFinalized ? 'transparent' : 'lightgray'
                                                      }`,
                                                    }}
                                                    disabled={isFinalized}
                                                    spellCheck={false}
                                                  />
                                                </div>
                                              </div>
                                              {!isFinalized && (
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    columnGap: '0.5rem',
                                                    marginLeft: 'auto',
                                                    marginTop: 'auto',
                                                    marginBottom: 'auto',
                                                  }}
                                                >
                                                  {!(item.isComment || item.isDecision) && (
                                                    <div
                                                      style={{
                                                        marginTop: 'auto',
                                                        marginBottom: 'auto',
                                                      }}
                                                      class="fitted-button blue tooltip"
                                                      onClick={(e) => {
                                                        handleItemEdit(item, topic)
                                                        setSelectedItemId(item.id)
                                                        setSelectedTopicId(topic.umtId)
                                                        setIsAssignmentModalOpen(true)
                                                      }}
                                                    >
                                                      <span class="material-symbols-outlined icon-size">
                                                        person_add
                                                      </span>
                                                      <span
                                                        class="tooltiptext"
                                                        style={{ fontSize: '16px' }}
                                                      >
                                                        {' '}
                                                        {t('Common:tooltip.assignToOthers')}{' '}
                                                      </span>
                                                    </div>
                                                  )}
                                                  <div
                                                    className="fitted-button blue tooltip"
                                                    onClick={() => {
                                                      handleItemEdit(item, topic)
                                                      setIsModalOpen(true)
                                                    }}
                                                    style={{
                                                      marginTop: 'auto',
                                                      marginBottom: 'auto',
                                                    }}
                                                  >
                                                    <span className="material-symbols-outlined icon-size">
                                                      swap_vert
                                                    </span>
                                                    <span
                                                      class="tooltiptext meeting_notes"
                                                      style={{
                                                        width: 'fit-content',
                                                        marginLeft: '-30px',
                                                        paddingRight: '1rem',
                                                      }}
                                                    >
                                                      {' '}
                                                      {t('Common:tooltip.edit')}{' '}
                                                    </span>
                                                  </div>
                                                </div>
                                              )}

                                              <div></div>
                                            </div>
                                            <div className="divider"></div>
                                          </>
                                        )
                                      },
                                    )}
                                  </div>
                                </>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    })}
                </div>
              ))}
            {/* if thre is no ai generated items, we do not show the finalize button */}

            {!isFinalized && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{
                    display: 'flex',
                    gap: '1rem',
                    justifyContent: 'center',
                    marginTop: '1rem',
                  }}
                >
                  <button
                    className="sleek-button sleek-blue"
                    style={{
                      cursor: 'pointer',
                      height: '2.2rem',
                      backgroundColor: 'white',
                      float: 'right',
                      width: '10rem',
                    }}
                    onClick={() => handleFinalizeMeeting()}
                  >
                    <span>{t('transcription.finalize')}</span>
                  </button>
                </div>
                <p>&nbsp;</p>
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '3rem',
            }}
          >
            {t('transcription.noMeetingItems')}
          </div>
        )}
      </>
    </Modal>
  )
}

export default DecisionLogModal

import React from 'react'
import Modal from '../../../../../../components/Modal'
import AudioToTextModal from '../../../../../../components/AudioToTextModal/AudioToTextModal'
import TextArea from 'rc-textarea'
import { getHttpRequest, postHttpRequest } from '../../../../../../api/query/dynamicAPI'
import Toast from '../../../../../../common/toast'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { ModalSaveButtonFitted } from '../../../../../../components/ModalSubmitButton'
import { removeMentionMarkupFromText } from '../../../../../../utils/parseMarkup'
import { getCompanyConfigSettings } from '../../../../../../utils/auth'
import { addDotAfterSentence } from '../../../../../../utils/general'

const AiPresentationQuestionModal = ({
  isModalOpen,
  closeModal,
  setPresentationMaterial = () => {},
  setIsAiPresentationDraftModalOpen = () => {},
  setShowEng = () => {},
  showEng,
  topic,
}) => {
  const { t } = useTranslation(['Common'])
  const [isAudioToTextModalOpen, setIsAudioToTextModalOpen] = React.useState(false)
  const [audioText, setAudioText] = React.useState('')

  const [isFetchingResponse, setIsFetchingResponse] = React.useState(false)
  const [memoQuestions, setMemoQuestions] = React.useState([])
  const [gptGeneratedQuestions, setGptGeneratedQuestions] = React.useState([])

  const [textAreas, setTextAreas] = React.useState({})
  const [selectedQuestionIndex, setSelectedQuestionIndex] = React.useState(-1)
  const [log_id, setLogId] = React.useState('')
  const productSettings = getCompanyConfigSettings('productSettings')
  const [question1Sentence, setQuestion1Sentence] = React.useState('')
  const [question2Sentence, setQuestion2Sentence] = React.useState('')
  const [question3Sentence, setQuestion3Sentence] = React.useState('')

  const Question1Eng = ' What is the context of the meeting and how much time will you have?'
  const Question1Esp = '¿Cuál es el propósito de este memorando? ¿A quién está destinado?'
  const Question2Eng = 'Who is the audience and what do they care about most?'
  const Question2Esp = '¿Quién es la audiencia y qué es lo que más les importa?'
  const Question3Eng =
    'How are you planning to structure and approach this meeting? What would success look like at the end of this meeting?'
  const Question3Esp =
    '¿Cómo planeas estructurar y abordar esta reunión? ¿Cómo se vería el éxito al final de esta reunión?'

  const [hideToggle, setHideToggle] = React.useState(false)
  const [questionAudioIndex, setQuestionAudioIndex] = React.useState(0)
  const [question3Disabled, setQuestion3Disabled] = React.useState(false)

  React.useEffect(() => {
    if (selectedQuestionIndex === -1) {
      if (questionAudioIndex == 1) {
        setQuestion1Sentence((prev) => prev + addDotAfterSentence(prev) + audioText)
      } else if (questionAudioIndex == 2) {
        setQuestion2Sentence((prev) => prev + addDotAfterSentence(prev) + audioText)
      } else if (questionAudioIndex == 3) {
        setQuestion3Sentence((prev) => prev + addDotAfterSentence(prev) + audioText)
      }
    } else {
      setTextAreas((textArea) => ({
        ...textAreas,
        [[`question${selectedQuestionIndex}`]]:
          textArea[`question${selectedQuestionIndex}`] +
          addDotAfterSentence(textArea[`question${selectedQuestionIndex}`]) +
          audioText,
      }))
      setSelectedQuestionIndex(-1)
    }
  }, [audioText])

  const handleQuestion1Change = (e) => {
    setQuestion1Sentence(e.target.value)
  }

  const handleQuestion2Change = (e) => {
    setQuestion2Sentence(e.target.value)
  }

  const handleQuestion3Change = (e) => {
    setQuestion3Sentence(e.target.value)
  }

  const handleQuestionTextChange = (e, setFieldValue) => {
    const { name, value, selectionStart } = e.target
    setFieldValue(name, value)
    setTextAreas({ ...textAreas, [name]: value })
    const textarea = document.getElementById(name)
    if (textarea) {
      requestAnimationFrame(() => {
        textarea.selectionStart = selectionStart
        textarea.selectionEnd = selectionStart
      })
    }
  }

  React.useEffect(() => {
    if (memoQuestions.length > 0 && gptGeneratedQuestions.length > 0) {
      setTextAreas(
        memoQuestions.reduce((acc, _, index) => ({ ...acc, [`question${index}`]: '' }), {}),
      )
    }
  }, [memoQuestions])

  // React.useEffect(() => {
  // }, [textAreas])

  const populateAiPresentationQuestions = async () => {
    setIsFetchingResponse(true)
    let postData = {}
    postData.question1Answer = question1Sentence
    postData.question2Answer = question2Sentence
    postData.question3Answer = question3Disabled ? 'I do not know' : question3Sentence
    postData.language = showEng ? 'ENGLISH' : 'SPANISH'
    postData.umtId = topic?.umtId
    try {
      let response = await postHttpRequest('/get_ai_presentation_questions', postData)
      let allQuestions = response.questions
      setGptGeneratedQuestions(allQuestions)
      setMemoQuestions(allQuestions)
      setLogId(response.log_id)
      setHideToggle(true)
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
    setIsFetchingResponse(false)
  }

  const buildQuestionAnswerPair = () => {
    return memoQuestions
      .filter((_, index) => {
        return (textAreas?.[`question${index}`] ?? '') !== ''
      })
      .map((question, index) => {
        return {
          question: question,
          answer: textAreas?.[`question${index}`] ?? '',
        }
      })
  }

  const preparePresentationMaterial = () => {
    setPresentationMaterial({
      question1: question1Sentence,
      question2: question2Sentence,
      question3: question3Disabled ? 'I do not know' : question3Sentence,
      questions: buildQuestionAnswerPair(),
      log_id: log_id,
    })
    setIsAiPresentationDraftModalOpen(true)
  }

  const validationSchema = Yup.object().shape({
    question0: Yup.string().required(t('Common:required')),
    question1: Yup.string().required(t('Common:required')),
  })

  const getAudioQuestionText = () => {
    switch (questionAudioIndex) {
      case 1:
        return showEng ? Question1Eng : Question1Esp
      case 2:
        return showEng ? Question2Eng : Question2Esp
      case 3:
        return showEng ? Question3Eng : Question3Esp
      default:
        return
    }
  }

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      width="85%"
      modalContainerStyle={{ width: '90%' }}
      title={removeMentionMarkupFromText(topic?.actionNeeded)}
      titleStyle={{ color: 'rgb(91,132,203)' }}
      modalTitleContainerStyle={{ marginBottom: '0rem' }}
    >
      {isAudioToTextModalOpen && (
        <AudioToTextModal
          startRecording={true}
          isModalOpen={isAudioToTextModalOpen}
          type="meetingHubCommentsActions"
          closeModal={() => {
            setIsAudioToTextModalOpen(false)
          }}
          setAudioText={setAudioText}
          displayText={
            selectedQuestionIndex === -1
              ? getAudioQuestionText()
              : memoQuestions[selectedQuestionIndex]
          }
        />
      )}
      <div style={{ width: '90%' }}>
        {!hideToggle && !productSettings && (
          <div
            style={{
              marginLeft: 'auto',
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '0.5rem',
              width: '9rem',
            }}
          >
            <div class="tooltip">
              <span class="material-symbols-outlined">info</span>
              <span class="tooltiptext" style={{ fontSize: '15px', width: '20rem' }}>
                {t('aiMemo.info')}{' '}
              </span>
            </div>
            <span>{showEng ? t('aiMemo.eng') : t('aiMemo.esp')}</span>
            <div
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
              onClick={() => {
                let allQuestions = [...gptGeneratedQuestions]
                setMemoQuestions(allQuestions)
                setShowEng((prev) => !prev)
              }}
            >
              {!showEng && (
                <span
                  style={{ fontSize: '35px', color: 'rgb(176, 173, 173)' }}
                  class="material-symbols-outlined"
                >
                  toggle_off
                </span>
              )}

              {showEng && (
                <span
                  style={{ fontSize: '35px', color: 'rgb(176, 173, 173)' }}
                  class="material-symbols-outlined"
                >
                  toggle_on
                </span>
              )}
            </div>
          </div>
        )}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {memoQuestions.length == 0 && gptGeneratedQuestions.length == 0 && (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div
                style={{
                  textAlign: 'left',
                  marginBottom: '1rem',
                  marginTop: !hideToggle && !productSettings ? '0rem' : '2rem',
                }}
              >
                <b>{showEng ? Question1Eng : Question1Esp}</b>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div className="review-generated-topics-list-item" style={{ width: '100%' }}>
                  <TextArea
                    rows={3}
                    type="text"
                    onChange={(e) => {
                      handleQuestion1Change(e)
                    }}
                    value={question1Sentence}
                    style={{ height: '5rem', backgroundColor: '#f1f0f0', width: '100%' }}
                    disabled={isFetchingResponse || gptGeneratedQuestions.length > 0}
                    spellCheck={false}
                  />
                </div>
                {gptGeneratedQuestions.length === 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: 'auto',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-card tonedown-blue-btn"
                      style={{ width: '2.5rem' }}
                      onClick={(e) => {
                        setQuestionAudioIndex(1)
                        setIsAudioToTextModalOpen(true)
                      }}
                    >
                      <span class="material-symbols-outlined icon-size">mic</span>
                    </button>
                  </div>
                )}
              </div>
              <div
                style={{
                  textAlign: 'left',
                  marginBottom: '1rem',
                  marginTop: !hideToggle && !productSettings ? '0rem' : '2rem',
                }}
              >
                <b>{showEng ? Question2Eng : Question2Esp}</b>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div className="review-generated-topics-list-item" style={{ width: '100%' }}>
                  <TextArea
                    rows={3}
                    type="text"
                    onChange={(e) => {
                      handleQuestion2Change(e)
                    }}
                    value={question2Sentence}
                    style={{ height: '5rem', backgroundColor: '#f1f0f0', width: '100%' }}
                    disabled={isFetchingResponse || gptGeneratedQuestions.length > 0}
                    spellCheck={false}
                  />
                </div>
                {gptGeneratedQuestions.length === 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: 'auto',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-card tonedown-blue-btn"
                      style={{ width: '2.5rem' }}
                      onClick={(e) => {
                        setQuestionAudioIndex(2)
                        setIsAudioToTextModalOpen(true)
                      }}
                    >
                      <span class="material-symbols-outlined icon-size">mic</span>
                    </button>
                  </div>
                )}
              </div>
              <div
                style={{
                  textAlign: 'left',
                  marginBottom: '1rem',
                  marginTop: !hideToggle && !productSettings ? '0rem' : '2rem',
                }}
              >
                <b>{showEng ? Question3Eng : Question3Esp}</b>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div className="review-generated-topics-list-item" style={{ width: '100%' }}>
                  <TextArea
                    rows={3}
                    type="text"
                    onChange={(e) => {
                      handleQuestion3Change(e)
                    }}
                    value={question3Disabled ? '' : question3Sentence}
                    style={{ height: '5rem', backgroundColor: '#f1f0f0', width: '100%' }}
                    disabled={
                      isFetchingResponse || gptGeneratedQuestions.length > 0 || question3Disabled
                    }
                    spellCheck={false}
                  />
                </div>
                {gptGeneratedQuestions.length === 0 && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        type="checkbox"
                        checked={question3Disabled}
                        onClick={() => {
                          setQuestion3Disabled(!question3Disabled)
                        }}
                      />
                      <span style={{ marginLeft: '0.5rem' }}>
                        {showEng ? "I don't know" : 'No sé'}
                      </span>
                    </div>
                    <button
                      type="button"
                      className="btn-card tonedown-blue-btn"
                      style={{ width: '2.5rem' }}
                      onClick={(e) => {
                        setQuestionAudioIndex(3)
                        setIsAudioToTextModalOpen(true)
                      }}
                    >
                      <span className="material-symbols-outlined icon-size">mic</span>
                    </button>
                  </div>
                )}
              </div>
              {gptGeneratedQuestions.length === 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '0.75rem',
                    width: '100%',
                  }}
                >
                  {question1Sentence !== '' &&
                    question2Sentence !== '' &&
                    (question3Sentence !== '' || question3Disabled) && (
                      <div style={{ marginLeft: 'auto' }}>
                        <div
                          className="sleek-button sleek-full-blue"
                          style={{ padding: '0rem 0.6rem 0rem 0.6rem' }}
                          onClick={(e) => {
                            populateAiPresentationQuestions()
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {isFetchingResponse === true ? (
                              <div
                                style={{
                                  width: '0.3rem',
                                  height: '0.3rem',
                                  margin: '0',
                                  borderTopColor: 'white',
                                }}
                                className="loading-spinner icon-size-l"
                              ></div>
                            ) : null}
                            <span>{t('Common:commonButtons.nextSmall')}</span>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              )}
            </div>
          )}
          {memoQuestions.length > 0 && gptGeneratedQuestions.length > 0 && (
            <Formik
              enableReinitialize
              initialValues={textAreas}
              validationSchema={validationSchema}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={(values, { resetForm }) => {
                preparePresentationMaterial(values)
                resetForm()
              }}
            >
              {({ errors, touched, resetForm, setFieldValue, values }) => (
                <Form
                  className="objective-form"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    textAlign: 'left',
                  }}
                >
                  {memoQuestions.map((question, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        marginTop: '1rem',
                      }}
                    >
                      <div style={{ marginBottom: '1rem' }}>
                        <b>{`${index + 1}. ${question}`}</b>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div
                          className="review-generated-topics-list-item"
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'left',
                            width: '100%',
                          }}
                        >
                          <Field
                            id={`question${index}`}
                            name={`question${index}`}
                            onChange={(e) => {
                              handleQuestionTextChange(e, setFieldValue)
                            }}
                            autoComplete="off"
                            component="textarea"
                            style={{ height: '5rem', backgroundColor: '#f1f0f0', width: '100%' }}
                          />
                          {errors[`question${index}`] && touched[`question${index}`] && (
                            <div className="validation-error" style={{ width: '100%' }}>
                              {errors[`question${index}`]}
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: 'auto',
                          }}
                        >
                          <button
                            type="button"
                            className="btn-card tonedown-blue-btn"
                            style={{ width: '2.5rem' }}
                            onClick={(e) => {
                              setSelectedQuestionIndex(index)
                              setIsAudioToTextModalOpen(true)
                            }}
                          >
                            <span class="material-symbols-outlined icon-size">mic</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div style={{ display: 'flex', marginLeft: 'auto', marginTop: '1rem' }}>
                    <button
                      className="sleek-button sleek-full-blue"
                      style={{ padding: '0rem 0.6rem 0rem 0.6rem' }}
                      type="submit"
                    >
                      <span>{t('Common:commonButtons.next')}</span>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default AiPresentationQuestionModal

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getLoginData = async () => {
  const { data } = await axios.get(`${BASE_URL}/f/userlocal`, { withCredentials: true })
  return data
}

export default function useLoginData() {
  return useQuery(['login_info'], () => getLoginData(), {
    refetchOnWindowFocus: false,
  })
}

import React from 'react'
import Modal from '../../../../components/Modal'
import useExceedsCapacityObjectives from '../../../../api/query/useExceedsCapacityObjectives'
import useSubmitAreaMeetingCompletion from '../../../../api/mutation/useSubmitAreaMeetingCompletion'
import InPageLoader from '../../../../components/InPageLoader'
import styled from 'styled-components'
import { Formik, Field, Form } from 'formik'
import ModalSaveButton from '../../../../components/ModalSubmitButton'

const MeetingCompleteModal = ({ closeModal, updateRefreshIndex, openErrorModal }) => {
  const ObjGrid = styled.div`
    display: grid;
    justify-content: space-between;
    grid-template-columns: 5fr 1fr;
    row-gap: 0.5em;
    column-gap: 0.5rem;
    padding-top: 1rem;
  `

  const title =
    "Before we complete this meeting, let's decide what to do with decisions still pending"

  // Refetch all tradeoffs, because it's possible the screen right now is showing only
  const { data, isLoading, isFetching } = useExceedsCapacityObjectives()
  const completionMutation = useSubmitAreaMeetingCompletion()

  if (isFetching) {
    return (
      <Modal title={title} closeModal={closeModal} width={'50vw'}>
        <InPageLoader inComponent={true} />
      </Modal>
    )
  }

  const handleSubmit = async (values) => {
    await completionMutation.mutateAsync(
      {
        keepOpen: values.skippedObj.map((v, i) => parseInt(v)),
      },
      {
        onSuccess: (response) => {
          updateRefreshIndex()
          closeModal()
        },
        onError: (response) => {
          openErrorModal()
          closeModal()
        },
      },
    )
  }

  return (
    <>
      <Modal
        title={title}
        closeModal={() => {
          closeModal()
        }}
        width={'50rem'}
      >
        <div style={{ textAlign: 'left' }}>
          Do any of the decisions below still require further discussion? If so, select them. The
          selected decisions will remain open. Otherwise, we will close all unselected decisions.
          This means that if a decision has indicated a need for more resources, we will clear this
          ask.
          <br />
          <br />
          <Formik
            initialValues={{
              skippedObj: [],
            }}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values)
            }}
          >
            {({ values }) => (
              <Form>
                <ObjGrid>
                  {data?.tradeoffs?.map((tradeoff, index) => (
                    <>
                      <div
                        style={{ cursor: 'pointer' }}
                        title={
                          tradeoff.requiredFte +
                          ' required FTE will be reduced to ' +
                          tradeoff.allocatedFte +
                          ' FTE.'
                        }
                      >
                        <div style={{ paddingBottom: '1rem' }}>
                          <span style={{ fontWeight: 'bold' }}>{tradeoff.orginalStatement}</span>
                          <br />
                          {tradeoff.ownerName}
                          <br />
                          {/* {tradeoff.requiredFte} required FTE will be reduced to{' '}
                          {tradeoff.allocatedFte} FTE.
                          <br /> */}
                        </div>
                      </div>

                      <div>
                        <Field
                          type="checkbox"
                          name="skippedObj"
                          value={tradeoff.orginalObjId.toString()}
                        />
                      </div>
                    </>
                  ))}
                </ObjGrid>
                <div className="modal-dual-btn">
                  <button
                    className="dual-btn negative-btn"
                    onClick={() => {
                      closeModal()
                    }}
                    type="button"
                  >
                    CANCEL
                  </button>
                  <ModalSaveButton isLoading={isLoading || isFetching} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  )
}

export default MeetingCompleteModal

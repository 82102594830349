import React, { useState } from 'react'
import { useDemandIntakeContext } from '../../context/demandIntakeContext'
import { Outlet, useNavigate } from 'react-router-dom'
import ProgressBar from '../../../../components/ProgressBar'
import NextResetActions from '../NextResetActions'
import useDemandIntakeWizardSteps from '../../hooks/useDemandIntakeWizardSteps'
import AddObjectiveModal from '../AddObjectiveModal'
import useConvertSuggestedObjective from '../../../../api/mutation/editConvertSuggestedObjective'
import useAddObjective from '../../../../api/mutation/addObjectiveHome'
import useEditObjective from '../../../../api/mutation/editObjectiveHome'
import useEditTeamLeaderAllocation from '../../../../api/mutation/editTeamLeaderAllocation'
import ErrorModal from '../../../../components/ErrorModal'
import { getCompanyConfigSettings, hasRole } from '../../../../utils/auth'
import { redirectToHome } from '../../../../utils/auth'
import { routes } from '../../../../utils/routes'

const DemandIntakeWizard = ({ existingStep, isF2wTl }) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const { state, dispatch } = useDemandIntakeContext()
  const [objectiveType, setObjectiveType] = React.useState('General')
  const {
    currentStepIndex,
    steps,
    handleNext,
    handlePrevious,
    handleNextSkipOneStep,
    handlePreviousSkipOneStep,
    setCurrentStepIndex,
  } = useDemandIntakeWizardSteps(existingStep, isF2wTl)

  const [externalMemberAllocValid, setExternalMemberAllocValid] = useState(true)

  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const [objectiveWidgetErrorMessage, setObjectiveWidgetErrorMessage] = React.useState(null)

  const mutationSuggest = useConvertSuggestedObjective()
  const mutationAddObjective = useAddObjective()

  const navigate = useNavigate()

  let teamLeadId = undefined
  if (state.demandIntakeActionObj?.teamLeadId !== undefined) {
    teamLeadId = state.demandIntakeActionObj?.teamLeadId
  } else if (state.locationState?.ownerId !== undefined) {
    teamLeadId = state.locationState?.ownerId
  }
  const mutationEditObjective = useEditObjective(teamLeadId)
  const mutationEditTeamLeaderAllocation = useEditTeamLeaderAllocation()

  const handleSubmitObjective = async (objectiveToSubmit, activityOnly = false) => {
    let responseObj = {}

    if (activityOnly === true) {
      responseObj = {
        activityOnly: true,
        suggestionId: state.demandIntakeActionObj.suggestedObjId || null,
      }
    } else {
      if (state.demandIntakeAction === 'editObj') {
        objectiveToSubmit = {
          ...objectiveToSubmit,
          id: state.demandIntakeActionObj.objective.id,
        }
      }

      responseObj = {
        // newObjectiveData: state?.objectiveAssociation,
        newObjectiveData: objectiveToSubmit,
        // Id of the suggestion from the DB, if any.
        suggestionId: state.demandIntakeActionObj.suggestedObjId || null,
      }
    }

    let mutationApi = null
    if (state.demandIntakeAction === 'reviewSuggestedObjective') {
      mutationApi = mutationSuggest
    } else if (state.demandIntakeAction === 'editObj') {
      mutationApi = mutationEditObjective
    } else if (state.demandIntakeAction === 'createNewObj') {
      mutationApi = mutationAddObjective
    } else if (state.demandIntakeAction === 'activityBoardCreateNewObj') {
      mutationApi = mutationAddObjective
    }

    await mutationApi.mutateAsync(responseObj, {
      onSuccess: (response) => {
        dispatch({ type: 'SET_NEW_OR_UPDATED_OBJ_ID', newOrEditedObjId: response.obj_id })
        dispatch({ type: 'CLOSE_OBJ_FORM_MODAL' })

        if (state.demandIntakeAction === 'createNewObj') {
          if (state.locationState?.path !== undefined) {
            if (state.locationState?.path === '/KPI/ListOfObjectives') {
              navigate(`/${routes.kpiListOfObjectives}`, {
                state: { showObjOnly: true },
              })
            } else {
              window.location.href = state.locationState?.path
            }
          } else {
            window.location.href = '/Objectives'
          }
        }
        if (state.demandIntakeAction === 'editObj') {
          if (state.demandIntakeActionObj?.teamLeadId !== undefined) {
            window.location.href = '/Governance/ManageObjectives'
          } else if (state.locationState?.path !== undefined) {
            window.location.href = state.locationState?.path
          } else {
            window.location.href = '/Objectives'
          }
        }
        if (state.demandIntakeAction === 'activityBoardCreateNewObj') {
          window.location.href = '/Kanban'
        }
        if (activityOnly === true) {
          redirectToHome()
        } else {
          handleNextSkipOneStep()
        }
      },
      onError: () => {
        alert('There was an error submitting the wizard.')
        if (state.locationState?.path !== undefined) {
          window.location.href = state.locationState?.path
        } else {
          redirectToHome()
        }
      },
    })
  }

  const handleSubmitAllocStuff = async (activityOnly = null) => {
    if (externalMemberAllocValid === false) {
      return
    }

    let employeesObjAlloc = []
    for (let obj of state.data.objectives) {
      if (!obj.team) {
        continue
      }

      for (let member of obj.team) {
        employeesObjAlloc.push({
          eId: member.eId,
          objId: parseInt(obj.id),
          allocPct: member.value,
        })
      }
    }

    let responseObj = {
      isAddCoOwnersEnabled: true,
      objectives: [],
      employees: state.data.employees,
      employeesObjAlloc: employeesObjAlloc,
    }
    const objectives = state.data.objectives

    for (let i = 0; i < objectives.length; i++) {
      responseObj.objectives.push({ id: objectives[i].id })
      if (objectives[i].missingFte) {
        // if (objectives[i].missingFte > 0 && objectives[i].pendingTransferList.length <= 0 && getCompanyConfigSettings('productSettings')) {
        //   setErrorMessage('For objectives that you own, please select where the capacity should come from. You do not need to do this where you are a co-owner')
        //   return
        // }
        responseObj.objectives[i] = {
          ...responseObj.objectives[i],
          missingFte: objectives[i].missingFte,
          pendingTransferList: objectives[i].pendingTransferList,
          isCopy: objectives[i].isCopy,
        }
      }
      if (objectives[i].team) {
        let teamArray = []
        for (let p = 0; p < objectives[i].team.length; p++) {
          teamArray.push({
            eId: objectives[i].team[p].eId,
            isDirectReport: objectives[i].team[p].isDirectReport,
            value: objectives[i].team[p].value,
          })
        }
        responseObj.objectives[i] = { ...responseObj.objectives[i], team: teamArray }
      }
    }

    await mutationEditTeamLeaderAllocation.mutateAsync(responseObj, {
      onSuccess: () => {
        if (hasRole('area')) {
          window.location.href = '/Objectives'
        } else {
          redirectToHome()
        }
      },
      onError: () => {
        alert('There was an error submitting the wizard.')
      },
    })
  }

  const openErrorModal = () => {
    setIsErrorModalOpen(true)
  }

  const closeErrorModal = () => {
    setIsErrorModalOpen(false)
  }

  const setAllocEqualRequiredForAll = () => {
    for (let obj of state.data.objectives) {
      obj.allocatedFte = obj.requiredFte
    }
  }

  return (
    <>
      <ErrorModal
        isOpen={isErrorModalOpen}
        handleClose={closeErrorModal}
        errorMessage={objectiveWidgetErrorMessage}
      />
      <AddObjectiveModal
        objectiveType={objectiveType}
        handleNext={handleNext}
        handleSubmit={handleSubmitObjective}
        handleNextSkipOneStep={handleNextSkipOneStep}
      />
      <ProgressBar currentStep={currentStepIndex} steps={steps} />
      <Outlet
        context={{
          setErrorMessage,
          handleNext,
          setExternalMemberAllocValid,
        }}
      />
      <NextResetActions
        isLastStep={currentStepIndex === steps.length - 1}
        currentStep={currentStepIndex}
        setCurrentStepIndex={setCurrentStepIndex}
        setObjectiveType={setObjectiveType}
        handleSubmitObjective={handleSubmitObjective}
        handleSubmitAllocStuff={handleSubmitAllocStuff}
        handleNextFunction={() => {
          if (currentStepIndex === 1) {
            if (state?.objectiveAssociation === 0) {
              setObjectiveType('BAU')
              dispatch({ type: 'OPEN_OBJ_FORM_MODAL' })
              return
            } else if (state?.objectiveAssociation === null && state?.linkedObjectiveId === null) {
              setErrorMessage('Please select an option from the dropdown before proceeding')
              return
            }
          } else if (
            (currentStepIndex === 3 && isF2wTl === false) ||
            (currentStepIndex === 4 && isF2wTl === true)
          ) {
            if (state.allocSubWiz.errorTeamCapAllocTable === true) {
              dispatch({ type: 'ALLOC_SUB_WIZ_SET_SELECT_ALL_EMP', value: true })
              //setErrorMessage("A team member has a 0% capacity assigned against an objective. Please enter a value greater than 0%")
              return
            }
          }
          setErrorMessage(null)
          handleNext()
        }}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </>
  )
}

export default DemandIntakeWizard

import React from 'react'
import Modal from '../../../../../components/Modal'
import { useTranslation } from 'react-i18next'
import SelectSingleField from '../../../../../components/CustomMultipleSelect/singleOption'
import { getHttpRequest, postHttpRequest } from '../../../../../api/query/dynamicAPI'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import Toast from '../../../../../common/toast'

const MeiCriteriaModal = ({ isModalOpen, closeModal, cxoList = [] }) => {
  const { t } = useTranslation(['Dashboard', 'Common'])

  const [selectedCxoId, setSelectedCxoId] = React.useState(null)
  const [meiThresholds, setMeiThresholds] = React.useState(null)

  const handleOwnerChange = (e) => {
    setSelectedCxoId(e?.eId)
  }

  React.useEffect(() => {
    if (selectedCxoId) {
      // fetch data
      fetchMeiCriteria(selectedCxoId)
    }
  }, [selectedCxoId])

  const errorToast = () => {
    Toast.fire({
      icon: 'error',
      title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
    })
  }

  const fetchMeiCriteria = async (cxoId) => {
    try {
      let result = await getHttpRequest(`/get_mei_thresholds/${cxoId}`)
      setMeiThresholds(result['data'])
    } catch (error) {
      errorToast()
    }
  }

  const handleSubmit = async (prepared, coordinated, taskmaster) => {
    console.log(prepared, coordinated, taskmaster)
    try {
      let response = await postHttpRequest(`/upsert_mei_thresholds/${selectedCxoId}`, {
        prepared: prepared,
        coordinated: coordinated,
        taskmaster: taskmaster,
      })
      if (response.success) {
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.updatedSuccessfully'),
        })
      } else {
        errorToast()
      }
    } catch (error) {
      errorToast()
    }
  }

  return (
    <Modal
      title={t('adminDashboard.meiModalTitle')}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      width={'90%'}
      height={'60%'}
      titleStyle={{ marginRight: 'auto', color: '#5780ca' }}
      modalContainerStyle={{ width: '90%' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: '0.3rem' }}>
          <span>{t('adminDashboard.selectCxo')}</span>
        </div>
        <SelectSingleField
          placeholder={t('Common:formPlaceHolder.selectLeader')}
          name="selectOwner"
          value={cxoList?.filter((person) => parseInt(person.eId) === parseInt(selectedCxoId))}
          options={cxoList}
          onChange={handleOwnerChange}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.eId}
          width={'30%'}
        />
        {meiThresholds && (
          <div style={{ marginTop: '3rem' }}>
            <Formik
              key={JSON.stringify(meiThresholds)}
              enableReinitialize
              initialValues={{
                preparedThreshold: meiThresholds?.prepared_target ?? 1,
                coordinatedThreshold: meiThresholds?.coordinated_target ?? 1,
                taskmasterThreshold: meiThresholds?.taskmaster_target ?? 0.4,
              }}
              validationSchema={Yup.object().shape({
                preparedThreshold: Yup.number()
                  .required(t('Common:formError.required'))
                  .min(0.001, t('Common:formError.lessThanZero')),
                coordinatedThreshold: Yup.number()
                  .required(t('Common:formError.required'))
                  .min(0.001, t('Common:formError.lessThanZero')),
                taskmasterThreshold: Yup.number()
                  .required(t('Common:formError.required'))
                  .max(1, t('Common:formError.moreThanOne'))
                  .min(0.001, t('Common:formError.lessThanZero')),
              })}
              onSubmit={(values, { resetForm }) => {
                let preparedThreshold = values.preparedThreshold
                let coordinatedThreshold = values.coordinatedThreshold
                let taskmasterThreshold = values.taskmasterThreshold
                handleSubmit(preparedThreshold, coordinatedThreshold, taskmasterThreshold)
              }}
            >
              {({ errors, touched, resetForm, values, setFieldValue, validateForm }) => (
                <Form>
                  <div
                    className="form-field"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '70%',
                      flexDirection: 'row',
                    }}
                  >
                    <div style={{ textAlign: 'left' }}>
                      <label>{t('adminDashboard.prepared')}</label>
                    </div>
                    <div
                      style={{
                        textAlign: 'right',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Field
                        type="number"
                        id="preparedThreshold"
                        name="preparedThreshold"
                        autoComplete="off"
                      />
                      {errors?.preparedThreshold && (
                        <div
                          className="validation-error"
                          style={{ textAlign: 'left', maxWidth: '16rem' }}
                        >
                          {errors?.preparedThreshold}
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className="form-field"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '70%',
                      flexDirection: 'row',
                    }}
                  >
                    <div style={{ textAlign: 'left' }}>
                      <label>{t('adminDashboard.coordinated')}</label>
                    </div>
                    <div
                      style={{
                        textAlign: 'right',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Field
                        type="number"
                        id="coordinatedThreshold"
                        name="coordinatedThreshold"
                        autoComplete="off"
                      />
                      {errors?.coordinatedThreshold && (
                        <div
                          className="validation-error"
                          style={{ textAlign: 'left', maxWidth: '16rem' }}
                        >
                          {errors?.coordinatedThreshold}
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className="form-field"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '70%',
                      flexDirection: 'row',
                    }}
                  >
                    <div style={{ textAlign: 'left' }}>
                      <label>{t('adminDashboard.taskmaster')}</label>
                    </div>
                    <div
                      style={{
                        textAlign: 'right',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Field
                        type="number"
                        id="taskmasterThreshold"
                        name="taskmasterThreshold"
                        autoComplete="off"
                      />
                      {errors?.taskmasterThreshold && (
                        <div
                          className="validation-error"
                          style={{ textAlign: 'left', maxWidth: '16rem' }}
                        >
                          {errors?.taskmasterThreshold}
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="sleek-button sleek-blue"
                      style={{ cursor: 'pointer', height: '2.2rem', backgroundColor: 'white' }}
                    >
                      <span>{t('Common:commonButtons.save')}</span>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default MeiCriteriaModal

// NavbarContext.js

import React, { createContext, useState, useContext } from 'react'

const NavbarContext = createContext()

export const NavbarProvider = ({ children }) => {
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(false)

  const handleNavbarStateChange = (collapsed) => {
    setIsNavbarCollapsed(collapsed)
  }

  return (
    <NavbarContext.Provider value={{ isNavbarCollapsed, handleNavbarStateChange }}>
      {children}
    </NavbarContext.Provider>
  )
}

export const useNavbarContext = () => useContext(NavbarContext)

import { useTranslation } from 'react-i18next'

const IntroAreaLeader = () => {
  const { t } = useTranslation(['AreaLeaderWizard'])
  return (
    <>
      <div className="intro">
        <div className="intro-title">
          <h2>{t('introStep.title')}</h2>
          <p style={{ textAlign: 'justify' }}>{t('introStep.subtitle')}</p>
        </div>

        <div
          className="intro-container"
          style={{
            display: 'flex',
            flexDirection: 'row',
            columnGap: '1rem',
            marginTop: '2rem',
            justifyContent: 'center',
          }}
        >
          <div className="intro-summary-point shadow">
            {/* <div className="intro-summary-title">
            <b>{t("introStep.box1Title")}</b>
          </div> */}

            <p className="intro-summary-details">{t('introStep.box1Text')}</p>
          </div>

          <div className="intro-summary-point shadow">
            {/* <div className="intro-summary-title">
            <b>{t("introStep.box2Title")}</b>
          </div> */}

            <p className="intro-summary-details">{t('introStep.box2Text')} </p>
          </div>

          <div className="intro-summary-point shadow">
            {/* <div className="intro-summary-title">
            <b>{t("introStep.box3Title")}</b>
          </div> */}

            <p className="intro-summary-details">{t('introStep.box3Text')}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default IntroAreaLeader

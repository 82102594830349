import React from 'react'
import WizardQuestion from '../../../../components/WizardQuestion'
import { useTranslation } from 'react-i18next'
import { useKPIContext } from '../../context/kpiContext'

const KPILongTermQuestion = () => {
  const { t } = useTranslation(['KPIWizard', 'Common'])

  const [longTermAnswer, setLongTermAnswer] = React.useState(null)
  const { state, dispatch } = useKPIContext()

  React.useEffect(() => {
    if (state.answers.longtermAnswer !== null) {
      setLongTermAnswer(state.answers.longtermAnswer)
    }
  }, [])

  React.useEffect(() => {
    dispatch({ type: 'SET_LONGTERM_ANSWER', value: longTermAnswer })
  }, [longTermAnswer])

  return (
    <>
      <WizardQuestion
        question={t('longTermQuestion')}
        answer={longTermAnswer}
        setAnswer={setLongTermAnswer}
        loadGetFunction={() => {
          return ''
        }}
        objStatement={state.obj?.statement}
      />
    </>
  )
}
export default KPILongTermQuestion

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { kpiSubRoutes, routes } from '../../../utils/routes'

function kpiStepsGenerator() {
  return [
    kpiSubRoutes.intro,
    kpiSubRoutes.teamOverview,
    kpiSubRoutes.listOfObjectives,
    kpiSubRoutes.timeframe,
    kpiSubRoutes.longtermQuestion,
    kpiSubRoutes.shorttermQuestion,
    kpiSubRoutes.suggestedKpis,
  ]
}

export function stepsLabelsGenerator() {
  return {
    INTRO: 0,
    TEAM_OVERVIEW: 1,
    LIST_OF_OBJECTIVES: 2,
    TIMEFRAME: 3,
    LONGTERM_QUESTION: 4,
    SHORTTERM_QUESTION: 5,
    SUGGESTED_KPIS: 6,
  }
}

export default function useKPIWizardSteps(existingStep, state, dispatch) {
  const kpiSteps = kpiStepsGenerator()
  const navigate = useNavigate()
  const [currentStepIndex, setCurrentStepIndex] = React.useState(parseInt(existingStep))

  const [kpiwArray, setKpiwArray] = React.useState([
    true, //INTRO 0
    true, //TEAM_OVERVIEW 1
    true, //LIST_OF_OBJECTIVES 2
    true, //TIMEFRAME 3
    true, //LONGTERM_QUESTION 4
    true, //SHORTTERM_QUESTION 5
    true, //SUGGESTED_KPIS 6
  ])

  React.useEffect(() => {
    if (currentStepIndex >= 0) {
      navigate(`/${routes.kpi}/${kpiSteps[currentStepIndex]}`)
    }
  }, [currentStepIndex, existingStep, navigate])

  const redirectToIntroPage = () => {
    setCurrentStepIndex(0)
  }

  const redirectToListOfObjPage = () => {
    setCurrentStepIndex(2)
  }

  const handleNext = () => {
    let tempKpiwArray = [...kpiwArray]
    if (state.answers.timeframe === '1') {
      tempKpiwArray[4] = false
    } else if (state.answers.timeframe === '2' || state.answers.timeframe === '3') {
      tempKpiwArray[4] = true
    }

    if (currentStepIndex <= kpiSteps.length) {
      let stepIncrease = 1
      while (true) {
        if (currentStepIndex + stepIncrease < kpiSteps.length) {
          if (tempKpiwArray[currentStepIndex + stepIncrease] === true) {
            const nextStep = currentStepIndex + stepIncrease
            setCurrentStepIndex(nextStep)
            break
          }

          stepIncrease++
        }
        if (currentStepIndex + stepIncrease === kpiSteps.length) {
          break
        }
      }
      setKpiwArray([...tempKpiwArray])
    }
  }

  const handlePrevious = () => {
    //todo clear when going from timeframe question to obj list
    let tempKpiwArray = [...kpiwArray]
    if (state.answers.timeframe === '1') {
      tempKpiwArray[4] = false
    } else if (state.answers.timeframe === '2' || state.answers.timeframe === '3') {
      tempKpiwArray[4] = true
    }
    //step resets state data related to specific obj, if user goes back from timeframe question to obj list page
    // it should reset the state data
    if (currentStepIndex === 3) {
      //clean obj saved in state
      dispatch({ type: 'SET_OBJ', obj: null })

      //obj related answers stored
      dispatch({ type: 'SET_TIMEFRAME', value: null })
      dispatch({ type: 'SET_LONGTERM_ANSWER', value: null })
      dispatch({ type: 'SET_SHORTTERM_ANSWER', value: null })
    }
    if (currentStepIndex <= kpiSteps.length) {
      let stepDecrease = 1
      while (true) {
        if (tempKpiwArray[currentStepIndex - stepDecrease] === true) {
          const prevStep = currentStepIndex - stepDecrease
          setCurrentStepIndex(prevStep)
          break
        }

        stepDecrease++
        if (currentStepIndex - stepDecrease === -1) {
          break
        }
      }
      setKpiwArray([...tempKpiwArray])
    }
  }

  const isLastStep = () => {
    let lastIndex = -1

    for (let i = 0; i < kpiwArray.length; i++) {
      if (kpiwArray[i] === true) {
        lastIndex = i
      }
    }

    if (currentStepIndex === lastIndex) {
      return true
    } else {
      return false
    }
  }

  return {
    currentStepIndex,
    steps: kpiSteps,
    handleNext,
    handlePrevious,
    kpiwArray,
    isLastStep,
    redirectToIntroPage,
    redirectToListOfObjPage,
  }
}

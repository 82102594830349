import React, { useState } from 'react'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import Toast from '../../common/toast'
import ChatBot from '../../components/ChatBot'

const SearchTest = () => {
  const [searchText, setSearchText] = useState('')
  const [scope, setScope] = useState('')
  const [name, setName] = useState('')
  const [searchResults, setSearchResults] = useState(null)
  const [embeddingsServerSearchResults, setEmbeddingsServerSearchResults] = useState(null) // [id, score
  const [useLLM, setUseLLM] = useState(false)
  const search = async () => {
    try {
      let result = await getHttpRequest(
        `/search_v1?searchText=${searchText}&scope=${scope}&empName=${name}&useLLM=${useLLM}`,
      )
      setSearchResults(result)
    } catch (err) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }

  const generate = async () => {
    try {
      let result = await getHttpRequest('/generate_embeddings')
    } catch (err) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }

  const testEmbeddingsServer = async () => {
    try {
      let result = await getHttpRequest(`/embeddings_server_search?searchText=${searchText}`)
      setEmbeddingsServerSearchResults(result)
    } catch (err) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }

  return (
    <div>
      <h2>Search Component</h2>
      <div>
        <label>Search Text:</label>
        <input type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
      </div>
      <div>
        <label>Scope:</label>
        <input type="text" value={scope} onChange={(e) => setScope(e.target.value)} />
      </div>
      <div>
        <label>Name:</label>
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
      </div>
      <button onClick={search}>Get Similar</button>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span>Use LLM:</span>
        <input
          type="checkbox"
          value={useLLM}
          onChange={(e) => setUseLLM(e.target.checked)}
          name="useLLM"
        />
      </div>
      <button onClick={generate}>Generate Embeddings</button>
      <button onClick={testEmbeddingsServer}>Test Embeddings Server</button>
      {embeddingsServerSearchResults && (
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Similarity Score</th>
            </tr>
          </thead>
          <tbody>
            <tr>Objectives</tr>
            {embeddingsServerSearchResults.matchedObjIds &&
              embeddingsServerSearchResults.matchedObjIds.map((row, index) => (
                <tr key={index}>
                  <td>{row.id}</td>
                  <td>{row.score}</td>
                </tr>
              ))}
            <tr>Milestones</tr>
            {embeddingsServerSearchResults?.matchedMilestoneIds &&
              embeddingsServerSearchResults.matchedMilestoneIds.map((row, index) => (
                <tr key={index}>
                  <td>{row.id}</td>
                  <td>{row.score}</td>
                </tr>
              ))}
            <tr>Meeting Topics</tr>
            {embeddingsServerSearchResults?.matchedMeetingTopicIds &&
              embeddingsServerSearchResults.matchedMeetingTopicIds.map((row, index) => (
                <tr key={index}>
                  <td>{row.id}</td>
                  <td>{row.score}</td>
                </tr>
              ))}
            <tr>Comments</tr>
            {embeddingsServerSearchResults?.matchedCommentIds &&
              embeddingsServerSearchResults.matchedCommentIds.map((row, index) => (
                <tr key={index}>
                  <td>{row.id}</td>
                  <td>{row.score}</td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
      {searchResults && false && (
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Type</th>
              <th>Title</th>
              <th>Similarity Score</th>
            </tr>
          </thead>
          <tbody>
            {searchResults.objective.map((row, index) => (
              <tr key={index}>
                <td>{row[0]}</td>
                <td>Objective</td>
                <td>{row[1]}</td>
                <td>{row[2]}</td>
              </tr>
            ))}
            {searchResults.unified_meeting_topic.map((row, index) => (
              <tr key={index}>
                <td>{row[0]}</td>
                <td>Meeting Topic</td>
                <td>{row[1]}</td>
                <td>{row[2]}</td>
              </tr>
            ))}
            {searchResults.milestone.map((row, index) => (
              <tr key={index}>
                <td>{row[0]}</td>
                <td>Milestone</td>
                <td>{row[1]}</td>
                <td>{row[2]}</td>
              </tr>
            ))}
            {searchResults.comment.map((row, index) => (
              <tr key={index}>
                <td>{row[0]}</td>
                <td>Comment</td>
                <td>{row[1]}</td>
                <td>{row[2]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <div>
        <ChatBot />
      </div>
    </div>
  )
}

export default SearchTest

import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import styles from '../PieCharCustomizedLabel/Charts.module.scss'
import { useTranslation } from 'react-i18next'

const StackedBarCustomizedLabel = ({ data }) => {
  const { t } = useTranslation(['Common'])
  return (
    <ResponsiveContainer width="20%" height="100%" className={styles.customStyling}>
      <BarChart
        width={100}
        height={500}
        data={data}
        margin={{
          top: 70,
          right: 30,
          //   left: 20,
          bottom: 8,
        }}
      >
        <text
          x="50%"
          y="8%"
          fill="black"
          textAnchor="middle"
          dominantBaseline="central"
          fontSize="14"
          fontWeight="bold"
        >
          {t('kpi.kpiBarChartTitle')}
        </text>
        <XAxis dataKey="name" tick={{ fontSize: '12px' }} />
        <YAxis cx="10%" tick={{ fontSize: '12px' }} />
        <Tooltip />
        {/* <Legend /> */}
        <Bar name="Objective with KPIs" dataKey="objectiveWithKpi" stackId="a" fill="#4874c4" />
        <Bar
          name="Objective without KPIs"
          dataKey="objectiveWithoutKpi"
          stackId="a"
          fill="#787474"
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default StackedBarCustomizedLabel

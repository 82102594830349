const shortenName = (name) => {
  // when name = undefiend/null
  if (!name) return ''

  const words = name.trim().split(/\s+/)
  if (words.length === 1) {
    return name.trim()
  }
  const firstWord = words[0]
  const remainingWords = words.slice(1)
  const initials = remainingWords.map((word) => word[0].toUpperCase()).join('.')
  return firstWord + ' ' + initials + '.'
}

const truncateName = (name, limit = 10) => {
  return name.length > limit ? `${name.slice(0, limit)}...` : name
}

const sanitizeString = (str) => {
  var sanitizeUrl = require('@braintree/sanitize-url').sanitizeUrl
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
  const urls = str.match(urlRegex)
  if (!urls) {
    return str
  }
  const sanitizedUrls = urls.map((url) => {
    const sanitizedUrl = sanitizeUrl(url)
    return sanitizedUrl
  })
  let currentIndex = 0
  const sanitizedString = sanitizedUrls.reduce((acc, sanitizedUrl) => {
    const startIndex = str.indexOf(urls[currentIndex], currentIndex)
    const endIndex = startIndex + urls[currentIndex].length
    currentIndex = endIndex
    return acc + str.slice(acc.length, startIndex) + sanitizedUrl
  }, '')
  return sanitizedString + str.slice(currentIndex)
}

const linkifyString = (str) => {
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
  const urls = str.match(urlRegex)

  if (!urls) {
    return <span>{str}</span>
  }

  const elements = []
  let currentIndex = 0

  for (let i = 0; i < urls.length; i++) {
    const url = urls[i]
    const startIndex = str.indexOf(url, currentIndex)
    const endIndex = startIndex + url.length
    const textBeforeUrl = str.slice(currentIndex, startIndex)
    const linkifiedUrl = (
      <a
        href={url}
        key={i}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: 'blue', textDecoration: 'underline' }}
      >
        {url}
      </a>
    )
    elements.push(<span key={`before-${i}`}>{textBeforeUrl}</span>)
    elements.push(linkifiedUrl)
    currentIndex = endIndex
  }

  const textAfterLastUrl = str.slice(currentIndex)
  elements.push(<span key="after-last-url">{textAfterLastUrl}</span>)

  return <span style={{ width: '100%' }}>{elements}</span>
}

function convertToTitleCase(str) {
  if (typeof str !== 'string') {
    return ''
  }

  return str
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

function truncateText(text, expectedLength) {
  if (text.length <= expectedLength) {
    return text
  } else {
    return text.slice(0, expectedLength) + '...'
  }
}

function getCurrentMondayDate() {
  let current_date = new Date()
  // get the current day of the week; Sunday is 0, Monday is 1, Tuesday is 2, etc.
  let current_day = current_date.getDay()
  let diff = current_date.getDate() - current_day + (current_day === 0 ? -6 : 1)
  let monday = new Date(current_date.setDate(diff))

  let timezoneOffset = monday.getTimezoneOffset() * 60000 // Convert minutes to milliseconds
  let monday_formatted = new Date(monday - timezoneOffset).toISOString().split('T')[0]
  return monday_formatted
}

function isChromeBrowser() {
  const { userAgent } = navigator

  // Check if the browser is Chrome or Edge but not Opera (Chromium-based)
  const isChrome = /chrome|edge|edg/i.test(userAgent) && !/OPR/i.test(userAgent)
  console.log(userAgent)

  return isChrome
}

function getCurrentDate() {
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, '0') // Months are zero-based
  const day = String(currentDate.getDate()).padStart(2, '0')

  const formattedDate = `${year}-${month}-${day}`
  console.log(formattedDate)

  return formattedDate
}

const addDotAfterSentence = (sentence) => {
  if (!sentence) {
    return ''
  }
  const trimmedSentence = sentence.trim()
  if (
    trimmedSentence[trimmedSentence.length - 1] !== '.' &&
    trimmedSentence[trimmedSentence.length - 1] !== '?' &&
    trimmedSentence[trimmedSentence.length - 1] !== '!' &&
    trimmedSentence.length > 0
  ) {
    return '. '
  }
  return ' '
}

const getEmailFromName = (fullName) => {
  const nameParts = fullName.trim().split(' ')
  const lastName = nameParts[nameParts.length - 1]
  const email = `${lastName.toLowerCase()}@company.com`
  return email
}

const processMentions = (inputText) => {
  // Regular expression to match the pattern @[Name](user:X)
  const pattern = /@\[([^\]]+)\]\(user:\d+\)/g

  // Split the text into parts: mentions and non-mentions
  const parts = inputText.split(pattern)

  // Process each part
  return parts.map((part, index) => {
    if (index % 2 === 1) {
      // Odd indices are matches (mentions)
      return (
        <span key={index} className="mention">
          @{part}
        </span>
      )
    } else {
      // Even indices are non-matches (regular text)
      return part
    }
  })
}

// GPT generated code
function levenshteinDistance(s1, s2) {
  const len1 = s1.length
  const len2 = s2.length
  const dp = Array.from(Array(len1 + 1), () => Array(len2 + 1).fill(0))

  // Initialize the first row and column of the matrix
  for (let i = 0; i <= len1; i++) dp[i][0] = i
  for (let j = 0; j <= len2; j++) dp[0][j] = j

  // Compute the distance matrix
  for (let i = 1; i <= len1; i++) {
    for (let j = 1; j <= len2; j++) {
      const cost = s1[i - 1] === s2[j - 1] ? 0 : 1
      dp[i][j] = Math.min(
        dp[i - 1][j] + 1, // deletion
        dp[i][j - 1] + 1, // insertion
        dp[i - 1][j - 1] + cost, // substitution
      )
    }
  }

  return dp[len1][len2]
}

// GPT generated code
function similarity(s1, s2) {
  const len1 = s1.length
  const len2 = s2.length
  const maxLength = Math.max(len1, len2)

  if (maxLength === 0) return 100 // Both strings are empty

  const distance = levenshteinDistance(s1, s2)
  const similarity = ((1 - distance / maxLength) * 100).toFixed(2)

  return similarity
}
function detectBrowser() {
  const userAgent = navigator.userAgent

  if (userAgent.indexOf('Chrome') > -1) {
    return 'Chrome'
  } else if (userAgent.indexOf('Safari') > -1) {
    return 'Safari'
  } else if (userAgent.indexOf('Edg') > -1) {
    return 'Edge'
  }
  return 'Unknown'
}

function detectOS() {
  const userAgent = navigator.userAgent.toLowerCase()

  if (userAgent.includes('win')) {
    return 'Windows'
  } else if (userAgent.includes('mac')) {
    return 'MacOS'
  } else if (userAgent.includes('linux')) {
    return 'Linux'
  } else if (userAgent.includes('android')) {
    return 'Android'
  } else if (userAgent.includes('ios')) {
    return 'iOS'
  }

  return 'Unknown'
}

export {
  shortenName,
  sanitizeString,
  linkifyString,
  convertToTitleCase,
  truncateText,
  getCurrentMondayDate,
  isChromeBrowser,
  getCurrentDate,
  addDotAfterSentence,
  getEmailFromName,
  processMentions,
  similarity,
  truncateName,
  detectBrowser,
  detectOS,
}

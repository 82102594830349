import { getHttpRequest, postHttpRequest } from '../query/dynamicAPI'
import Toast from '../../common/toast'

export async function getNoteTypeAspirationNotes(params) {
  try {
    const data = await getHttpRequest(`/get_note_type_workshop_aspiration_notes`, {
      params: params,
    })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      //title: API_ERROR_MESSAGE,
    })
  }
}

export async function addWorkshopAspirationNote(params) {
  try {
    const data = await postHttpRequest(`/add_workshop_aspiration_note`, params)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      //title: API_ERROR_MESSAGE,
    })
  }
}

export async function deleteWorkshopAspirationNote(params) {
  try {
    const data = await postHttpRequest(`/delete_workshop_aspiration_note`, params)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      //title: API_ERROR_MESSAGE,
    })
  }
}

export async function editWorkshopAspirationNote(params) {
  try {
    const data = await postHttpRequest(`/edit_workshop_aspiration_note`, params)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      //title: API_ERROR_MESSAGE,
    })
  }
}

export async function generateWorkshopAspirationSummary(isTop5PerPersonEnabled = false) {
  try {
    const data = await getHttpRequest(`/generate_workshop_aspiration_summary`, {
      params: { isTop5PerPersonEnabled: isTop5PerPersonEnabled },
    })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      //title: API_ERROR_MESSAGE,
    })
  }
}

import React from 'react'

const RowSlide = ({ slideData }) => {
  const { slide_title, rows } = slideData

  return (
    <div
      style={{
        height: '100%',
        padding: '20px',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <div
        style={{
          fontSize: '2rem',
          fontWeight: 'bold',
          marginBottom: '20px',
          color: '#0000FF',
          textAlign: 'left',
        }}
      >
        {slide_title}
      </div>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
        {rows.map((row, index) => (
          <div key={index} style={{ display: 'flex', marginBottom: '10px' }}>
            <div
              style={{
                backgroundColor: '#FF6347',
                color: 'white',
                padding: '1rem',
                width: '30%',
                fontSize: '1.2rem',
                fontWeight: 'bold',
              }}
            >
              {row.box}
            </div>
            <div
              style={{
                display: 'flex',
                backgroundColor: '#F8F8F8',
                padding: '1rem',
                width: '70%',
                fontSize: '1rem',
                color: '#333',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {row.content}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default RowSlide

import React, { useState, useEffect } from 'react'
import { useTeamLeaderContext } from '../../context/teamLeaderContext'
import { useOutletContext } from 'react-router-dom'
import ObjTeamMappingTable from '../../../../components/AllocSubWizard/ObjTeamMappingTable'
import AddTeamMemberModal from '../../../../components/AllocSubWizard/AddTeamMemberModal'
import { useTranslation } from 'react-i18next'
import './styles.scss'

const ObjectivesTeamMapping = () => {
  const { t } = useTranslation(['Common'])
  const { state, dispatch } = useTeamLeaderContext()

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
  }, [])

  return (
    <>
      {state.isTeamMemberFormOpen === true ? (
        <AddTeamMemberModal contextHandler={useTeamLeaderContext} />
      ) : null}

      <div className="wizard-step">
        <h2 className="wizard-step__title">{t('capacityAllocation.capacityObjectiveTitle')}</h2>
        {state.data.employees.length > 0 ? (
          <>
            <div className="wizard-step__overview">
              <div className="wizard-step__overview__paragraph">
                {t('capacityAllocation.capacityObjectiveSubtitle')}
              </div>
            </div>

            <div className="wizard-step__main-content-container">
              <div className="objectivesTeamMapping">
                <ObjTeamMappingTable contextHandler={useTeamLeaderContext} />
              </div>
            </div>
          </>
        ) : (
          'You do not have any team members.'
        )}
      </div>
    </>
  )
}

export default ObjectivesTeamMapping

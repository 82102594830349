import next from '../../../../assets/next-white.svg'
import SubmitAreaLeaderWizard from './components/SubmitAreaLeaderWizard'
import './styles.css'
import { useTranslation } from 'react-i18next'

const NextPreviousActions = ({
  isLastStep,
  currentStep,
  handleNext,
  handlePrevious,
  errorMessage,
  setErrorMessage,
  stepsLabels,
}) => {
  const { t } = useTranslation(['Common'])

  const resetErrorMessage = () => {
    setErrorMessage(null)
  }

  return (
    <div className="buttons-area-container">
      <div className="error-container">
        {errorMessage ? <div className="validation-error">{errorMessage}</div> : null}
      </div>
      <div className="dual-button-container">
        {currentStep === stepsLabels.INTRO ? (
          <button onClick={() => handleNext()} className="start-button big-btn">
            {t('commonButtons.clickToBegin')}
          </button>
        ) : (
          <>
            <button
              className="dual-back-btn dual-btn"
              onClick={() => {
                handlePrevious()
                resetErrorMessage()
              }}
            >
              <img
                className="nav-arrow previous"
                src={next}
                alt="Arrow indication a navigation backwards."
              />
              <span>{t('commonButtons.back')}</span>
            </button>
            {isLastStep ? (
              <SubmitAreaLeaderWizard />
            ) : (
              <button
                className="dual-next-btn dual-btn"
                onClick={() => {
                  handleNext()
                }}
              >
                <span>{t('commonButtons.next')}</span>
                <img
                  className="nav-arrow next"
                  src={next}
                  alt="Arrow indication a navigation forwards."
                />
              </button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default NextPreviousActions

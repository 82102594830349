import React, { useCallback } from 'react'
import ReactFlow, { addEdge, Controls, Background, useNodesState, useEdgesState } from 'reactflow'
import 'reactflow/dist/style.css'
import { MarkerType } from 'reactflow'
import Swal from 'sweetalert2'

import { teamUpdateConnection } from '../../api/services/employee.services'

import ButtonEdge from './common/buttonEdge.jsx'

import CustomNode from './common/customNode'
import Toast from '../../common/toast'

const edgeTypes = { buttonedge: ButtonEdge }

const nodeTypes = { customNode: CustomNode }

const onInit = (reactFlowInstance) => {}

const OverviewFlow = (props) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(props.initialNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState(props.initialEdges)

  const onConnect = useCallback(
    (params) => {
      params.type = 'buttonedge'
      params.markerEnd = {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
        color: '#E91B51',
      }
      setEdges((eds) => addEdge(params, eds))
    },
    [setEdges],
  )

  const setData = (params) => {
    if (params.source === params.target) {
      return
    }
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want be Join both node!',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      // reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          updateConnection(params)
          onConnect(params)
        } catch (error) {
          Toast.fire({
            icon: 'error',
            title: 'Something went wrong!',
          })
        }
      }
    })
  }

  const updateConnection = async (params) => {
    const data = {
      delegateTlId: params.target,
      juniorTlId: params.source,
    }
    const result = await teamUpdateConnection(data)
    if (result) {
    }
  }

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={setData}
      onInit={onInit}
      fitView
      attributionPosition="top-right"
      edgeTypes={edgeTypes}
      nodeTypes={nodeTypes}
    >
      {/* <MiniMap
        nodeStrokeColor={(n) => {
          if (n.style?.background) return n.style.background;
          if (n.type === "input") return "#0041d0";
          if (n.type === "output") return "#ff0072";
          if (n.type === "default") return "#1a192b";

          return "#eee";
        }}
        nodeColor={(n) => {
          if (n.style?.background) return n.style.background;

          return "#fff";
        }}
        nodeBorderRadius={2}
      /> */}
      <Controls />
      <Background color="#ed522e" gap={16} />
    </ReactFlow>
  )
}

export default OverviewFlow

import React from 'react'
import Modal from '../Modal'
import { useTranslation } from 'react-i18next'
import './style.css'

const StaffingTableModal = ({ isModalOpen, closeModal, data }) => {
  const { t } = useTranslation(['MeetingHub', 'Common'])
  const [valueToDisplay, setValueToDisplay] = React.useState(data?.objectives)
  const [showUnstaffed, setShowUnstaffed] = React.useState(false)
  const handleClickUnstaffing = () => {
    setShowUnstaffed((prev) => !prev)
  }

  React.useEffect(() => {
    if (!showUnstaffed) {
      setValueToDisplay(data?.objectives)
    } else {
      setValueToDisplay(data?.objectives?.filter((val) => val?.staffing === undefined))
    }
  }, [showUnstaffed])

  return (
    <>
      <Modal
        title={t('summaryOfObjectives.staffingTitle')}
        isModalOpen={isModalOpen}
        closeModal={() => closeModal()}
      >
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'left',
            }}
          >
            <div
              type="button"
              className="fitted-button blue"
              style={{ marginLeft: 'auto', marginBottom: '2rem' }}
              onClick={handleClickUnstaffing}
            >
              {showUnstaffed
                ? t('summaryOfObjectives.showAll')
                : t('summaryOfObjectives.showUnstaffed')}
            </div>
            <>
              {valueToDisplay?.length > 0 ? (
                <div className="staffing-table">
                  <div className="staffing-table-header"></div>
                  <div className="staffing-table-header">
                    <b>{t('summaryOfObjectives.staffingInitiatives')}</b>
                  </div>
                  <div className="staffing-table-header">
                    <b>{t('summaryOfObjectives.staffing')}</b>
                  </div>

                  <>
                    {valueToDisplay?.map((obj) => (
                      <>
                        <div style={{ marginTop: '0.2rem' }}>
                          <button
                            className="icon-button group-icon-button"
                            style={{
                              width: '2rem',
                              fontSize: '12px',
                              marginRight: '0.2rem',
                            }}
                            disabled
                          >
                            <span style={{ width: '5rem' }}>{obj.priority}</span>
                          </button>
                        </div>
                        <div className="staffing-table-statement">
                          {obj.initiativeId.toString().padStart(5, '0')}-{obj.statement}
                        </div>
                        <div className="staffing-table-stuff">
                          {obj.staffing?.length > 0 ? (
                            obj.staffing?.map((person, ind) => (
                              <>
                                <span key={person.name}>
                                  {person.name.replace(/(\b\w+\b)\s(\b\w)\w*\b/, '$1 $2')}
                                </span>
                                {ind !== obj.staffing?.length - 1 && <span>, </span>}
                              </>
                            ))
                          ) : (
                            <span>--</span>
                          )}
                        </div>
                      </>
                    ))}
                  </>
                </div>
              ) : (
                <div style={{ padding: '2rem' }}>{t('summaryOfObjectives.noStuffingData')}</div>
              )}
            </>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default StaffingTableModal

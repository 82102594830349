import { useTranslation } from 'react-i18next'
import '../Loading/styles.css'

const InPageLoader = ({ inComponent = false, displayErrorText = false }) => {
  const { t } = useTranslation(['Common'])

  return displayErrorText === true ? (
    <div className="loading-container-with-error">
      <h2 style={{ maxWidth: '100%' }}>{t('inPageLoader.title')}</h2>
      <div className="loading-spinner"></div>
    </div>
  ) : inComponent === false ? (
    <div className="loading-container">
      <div className="loading-spinner"></div>
    </div>
  ) : (
    <div style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
      <div className="loading-spinner"></div>
    </div>
  )
}

export default InPageLoader

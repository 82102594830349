import React, { useState, useEffect } from 'react'
import Modal from '../../../../../components/Modal/index'
import { getObjectivUserList } from '../../../../../api/services/employee.services'
import Toast from '../../../../../common/toast'
import { meetingMilestoneUpdate } from '../../../../../api/services/meetingTopic.services'
import InPageLoader from '../../../../../components/InPageLoader/index'
import { useTranslation } from 'react-i18next'
import SelectField from '../../../../../components/CustomMultipleSelect/singleOption'
import { ModalSaveButtonFitted } from '../../../../../components/ModalSubmitButton'
import { postHttpRequest } from '../../../../../api/query/dynamicAPI'

const initialValues = {
  facilatator: null,
  frequency: null,
}

const EditMeetingModal = ({
  objectiveId,
  view,
  setModelView,
  loadAgain = () => {},
  refreshBGUScorecardFn = () => {},
  meetingId,
  meeting,
}) => {
  const { t } = useTranslation(['Common'])
  // const { state, dispatch } = useDemandIntakeContext()

  const [formValues, setFormValues] = useState(initialValues)

  const [userList, setUserList] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  const [error, setError] = useState('')

  const frequencyOptions = [
    { value: 'weekly', label: 'Weekly' },
    { value: 'bi-weekly', label: 'Bi-Weekly' },
    { value: 'monthly', label: 'Monthly' },
  ]

  useEffect(() => {
    if (objectiveId) {
      getUserList()
    }
  }, [objectiveId])

  useEffect(() => {
    if (userList.length > 0) {
      setFormValues((prevState) => {
        return {
          ...prevState,
          facilatator: userList[0],
        }
      })
      console.log('userList', userList)
      let defaultFacilatator = userList.find((user) => user.eId === meeting.facilitatorId)

      setFormValues({
        facilatator: defaultFacilatator,
        frequency: frequencyOptions[meeting.frequency - 1],
      })
    }
  }, [userList])

  useEffect(() => {
    console.log('formValues', formValues)
  }, [formValues])

  async function getUserList() {
    setIsLoading(true)
    const result = await getObjectivUserList(objectiveId)
    setIsLoading(false)
    if (result?.employees) setUserList(result.employees)
  }

  function inputChangedHandler(e, data) {
    console.log('e', e)
    console.log('data', data)
    // Get name of changed input, and its corresponding value
    let name
    let value
    if (data) {
      if (data.name == 'facilatator') {
        name = data.name
        value = e
      } else if (data.name == 'frequency') {
        name = data.name
        value = e
      }
    }
    updateFormHandler(name, value)
  }

  function updateFormHandler(name, value) {
    setFormValues((prevState) => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  async function onCustomSubmit(data, type) {
    if (!formValues.facilatator || !formValues.frequency) {
      setError('Assign Required !')
      return
    }

    setIsLoading(true)
    let result
    let response = await postHttpRequest(`/update_meeting_detail/${meetingId}`, {
      facilatator: formValues.facilatator.eId,
      frequency: formValues.frequency.value,
    })
    result = response.success

    setIsLoading(false)
    if (result) {
      setModelView(false)
      setUserList([])
      setFormValues(initialValues)
      loadAgain()
      refreshBGUScorecardFn()
      Toast.fire({
        icon: 'success',
        title: 'Assignee updated successfully',
      })
    } else {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong try again later!',
      })
    }
  }

  return (
    <div className="kanban-modal">
      <Modal
        title={t('editMeetingDetailModal.editMeetingDetial')}
        isModalOpen={view}
        data-testid="Assigne"
        closeModal={() => setModelView(false)}
        width={'35rem'}
      >
        {isLoading ? <InPageLoader /> : ''}

        {!isLoading > 0 && (
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div
              className="column-input-objective"
              style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
            >
              <div className="row-kanban-inputs">
                <div className="form-field action-field form-field action-field--kanban">
                  <label>{t('editMeetingDetailModal.facilatator')}</label>
                  <div style={{ width: '100%' }}>
                    <SelectField
                      placeholder={t('formPlaceHolder.select')}
                      name="facilatator"
                      value={formValues.facilatator}
                      options={userList}
                      onChange={inputChangedHandler}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.eId}
                      menuShouldScrollIntoView={false}
                      menuPosition="fixed"
                    />
                    {error ? <span className="error error-add-commnet">{error}</span> : null}
                  </div>
                </div>
              </div>

              <div className="row-kanban-inputs">
                <div className="form-field action-field form-field action-field--kanban">
                  <label>{t('editMeetingDetailModal.frequency')}</label>
                  <div style={{ width: '100%' }}>
                    <SelectField
                      placeholder={t('formPlaceHolder.select')}
                      name="frequency"
                      value={formValues.frequency}
                      options={frequencyOptions}
                      onChange={inputChangedHandler}
                      isClearable={true}
                      menuShouldScrollIntoView={false}
                      menuPosition="fixed"
                    />
                    {error ? <span className="error error-add-commnet">{error}</span> : null}
                  </div>
                </div>
              </div>

              <div className="modal-dual-btn">
                <ModalSaveButtonFitted
                  isLoading={isLoading}
                  tag={t('Common:commonButtons.updateUpperCase')}
                  onClick={onCustomSubmit}
                />
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}
export default EditMeetingModal

import './style.scss'
const ListComment = ({ comment }) => {
  return (
    <div className="list-wrapper-comment">
      <div className="comment-create-detail">
        <span> {comment.user}</span>
        <span> 2023-01-27</span>
      </div>
      <p> {comment.comment} </p>
    </div>
  )
}
export default ListComment

import React from 'react'
import Modal from '../Modal'
import { useTranslation } from 'react-i18next'
import './styles.scss'
import Toast from '../../common/toast'
import {
  uploadFileFilingCabinet,
  getFilesFilingCabinetByObjId,
} from '../../api/services/filingCabinet.services'
import InPageLoader from '../InPageLoader'
import { dateFilter } from '../../utils/time'

const FilingCabinetModal = ({ closeModal, objId, mtId, topicType }) => {
  const { t } = useTranslation(['Common'])
  const [isLoading, setIsLoading] = React.useState(true)
  const [fileList, setFileList] = React.useState([])
  const [title, setTitle] = React.useState('')
  const fileUploadRef = React.useRef(null)

  const handleUploadFileClick = () => {
    fileUploadRef.current.click()
  }

  async function loadFileList() {
    setIsLoading(true)
    let formData = new FormData()
    if (objId) {
      formData.append('objId', parseInt(objId))
    }
    if (mtId) {
      formData.append('mtId', parseInt(mtId))
      formData.append('topicType', topicType)
    }

    let result = await getFilesFilingCabinetByObjId(formData)
    setIsLoading(false)
    if (result && result.fileList) {
      setFileList(result.fileList)
    }

    if (result && result.objStatement) {
      setTitle(result.objStatement)
    }
  }

  React.useEffect(() => {
    loadFileList()
  }, [])

  async function onChangeFile(e) {
    const files = e.target.files

    if (files.length !== 0) {
      setIsLoading(true)
      let formData = new FormData()

      if (objId) {
        formData.append('objId', parseInt(objId))
      }
      if (mtId) {
        formData.append('umtId', parseInt(mtId))
        formData.append('topicType', topicType)
      }
      formData.append('file', files[0], files[0].name)

      let response = await uploadFileFilingCabinet(formData)
      setIsLoading(false)

      if (response.upload_status === 'success') {
        loadFileList()
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.fileUploadSuccess'),
        })
      } else {
        let errorResult = response.data.upload_status
        if (errorResult === 'timeout') {
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.fileUploadRequestTimeout'),
          })
        } else if (errorResult === 'malicious') {
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.fileScanningFailed'),
          })
        } else {
          // Default error handling for other cases
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
          })
        }
      }
    }
  }

  async function downloadFile(file) {
    const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)
    window.open(`${BASE_URL}/export_filing_cabinet_file/${file.fileId}`, '_blank')
  }

  return (
    <Modal
      isModalOpen={true}
      closeModal={() => {
        closeModal()
      }}
      width={'75%'}
      title={title}
    >
      {isLoading && <InPageLoader inComponent={true} />}
      {!isLoading && (fileList === null || fileList.length === 0) && (
        <div style={{ textAlign: 'left', width: '100%' }}>
          <span>{t('filingCabinet.noFiles')}</span>
        </div>
      )}

      <div>
        {!isLoading && fileList !== null && fileList.length !== 0 && (
          <div className="filing-cabinet-table">
            <div className="filing-cabinet-header title-border">
              {t('filingCabinet.meetingTopic')}
            </div>
            <div className="filing-cabinet-header title-border">{t('filingCabinet.fileName')}</div>
            <div className="filing-cabinet-header title-border">{t('filingCabinet.type')}</div>
            <div className="filing-cabinet-header title-border">{t('filingCabinet.date')}</div>
            <div className="filing-cabinet-header title-border">
              {t('filingCabinet.uploadedBy')}
            </div>
            <div className="filing-cabinet-header title-border">{t('filingCabinet.download')}</div>

            {fileList.map((file, index) => {
              return (
                <React.Fragment key={index}>
                  <div style={{ textAlign: 'left' }}>
                    {file?.meetingTopicStatement === '' || null
                      ? '---'
                      : file.meetingTopicStatement}
                  </div>
                  <div style={{ textAlign: 'left' }}>{file?.fileName}</div>
                  <div style={{ textAlign: 'left' }}>.{file?.fileTypeStr}</div>
                  <div style={{ textAlign: 'left' }}>{dateFilter(file?.uploadTimestamp)}</div>
                  <div style={{ textAlign: 'left' }}>{file?.fileUploaderName}</div>

                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="fitted-button blue tooltip" onClick={() => downloadFile(file)}>
                      <span className="material-symbols-outlined">download</span>
                    </div>
                  </div>
                </React.Fragment>
              )
            })}
          </div>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            columnGap: '1rem',
            paddingTop: '2rem',
            width: '100%',
          }}
        >
          <span>{t('filingCabinet.uploadNewFile')}</span>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              class="fitted-button blue tooltip"
              onClick={handleUploadFileClick}
              onKeyDown={handleUploadFileClick}
            >
              <input
                type="file"
                id="myFile"
                name="filename"
                ref={fileUploadRef}
                accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.png,.jpg,.jpeg,.svg,.txt,.csv"
                onChange={(e) => onChangeFile(e)}
                style={{ display: 'none' }}
              ></input>

              <span class="material-symbols-outlined">upload</span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default FilingCabinetModal

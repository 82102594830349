import React from 'react'
import ApplicationLayout from '../../components/ApplicationLayout'
import useLinkageData from '../../api/query/useLinkageData'
import styled from 'styled-components'
import './styles.css'
import InPageLoader from '../../components/InPageLoader'

const OrganizationStrategy = () => {
  const { data: linkageData, isLoading: isLinkageLoading, error: linkageError } = useLinkageData()

  const OrganizationStrategyContainer = styled.div`
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 1.5vw 1vw;
    margin-bottom: 3rem;
  `

  return (
    <ApplicationLayout>
      <div className="intro">
        <div className="intro-title">
          <h2>Our Strategy </h2>
        </div>

        <OrganizationStrategyContainer>
          <div className="shadow">
            <table className="strategy-table">
              <tr className="infoTableHeader">
                <td style={{ width: '15%' }}>Objective</td>
                <td>What?</td>
                <td>Why?</td>
                <td>How?</td>
              </tr>
              {isLinkageLoading === true ? (
                <InPageLoader />
              ) : (
                linkageData?.companyStratDescriptions.map((d, index) => (
                  <tr key={`d-${index}`}>
                    <td style={{ width: '15%' }}>{d.objective}</td>
                    <td>{d.what}</td>
                    <td>{d.why}</td>
                    <td>{d.how}</td>
                  </tr>
                ))
              )}
            </table>
          </div>
        </OrganizationStrategyContainer>
      </div>
    </ApplicationLayout>
  )
}

export default OrganizationStrategy

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getManyObjectivesComments = async (objIds, pinned, isDecision, showIsCompleted) => {
  const queryString = `/get_comments_for_many_objs/${objIds}/${pinned}/${isDecision}?is-completed=${showIsCompleted}`

  const { data } = await axios.get(`${BASE_URL}${queryString}`)
  return data
}

export default function useManyObjectivesComments({
  objIds,
  pinned = false,
  isDecision = false,
  showIsCompleted = false,
}) {
  return useQuery(
    ['comments', objIds, { pinned }],
    () => getManyObjectivesComments(objIds, pinned, isDecision, showIsCompleted),
    {
      refetchOnWindowFocus: false,
      enabled: objIds.length > 0,
    },
  )
}

import React from 'react'
import './styles.css'
import logo from '../../assets/transforml-logo-5-4.png'
import avatar from '../../assets/avatar.png'
import { Link } from 'react-router-dom'

const NavigationLoginless = ({ loginProtected = true }) => {
  return (
    <>
      <div className="navigation">
        <div className="nav-div">
          <div className="logo-container">
            <Link to={'/'}>
              <img className="logo" src={logo} alt="TransforML Logo" />
            </Link>
          </div>
          {/* <img
            onClick={showNavModal}
            className="show-navigation-button"
            src={nav}
            alt="Show navigation button."
          /> */}
        </div>
        {/* <div style={{ marginLeft: 'auto' }}>
          <img className="avatar" src={avatar} alt="TransforML Logo" />
        </div> */}
      </div>
    </>
  )
}

export default NavigationLoginless

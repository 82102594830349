import React from 'react'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/core.css'
import { useTranslation } from 'react-i18next'
import { getCompanyConfigSettings } from '../../utils/auth'
import Toast from '../../common/toast'
import { uploadPptFile } from '../../api/services/meetingTopic.services'
import { Link } from 'react-router-dom'

function MeetingTopicCardDropdownMenu(props) {
  const productSettings = getCompanyConfigSettings('productSettings')
  const { t } = useTranslation(['MeetingHub'])

  const meetingDeckUploadRef = React.useRef(null)

  const handleUploadMeetingDeckClick = () => {
    meetingDeckUploadRef.current.click()
  }

  const fileUploadRef = React.useRef(null)

  const handleUploadFileClick = () => {
    fileUploadRef.current.click()
  }

  async function onChangePpt(e, data) {
    const files = e.target.files

    if (files.length !== 0) {
      props.setIsLoading(true)
      let formData = new FormData()

      if (data.topicId) {
        formData.append('topic_id', data.topicId)
      } else if (data.blockerId) {
        formData.append('blockerId', data.blockerId)
      }

      formData.append('file', files[0], files[0].name)

      let response = await uploadPptFile(formData)
      props.setIsLoading(false)

      if (response.upload_status === 'success') {
        props.loadAgain()
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.fileUploadSuccess'),
        })
      } else {
        let errorResult = response.data.upload_status
        if (errorResult === 'timeout') {
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.fileUploadRequestTimeout'),
          })
        } else if (errorResult === 'malicious') {
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.fileScanningFailed'),
          })
        } else {
          // Default error handling for other cases
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
          })
        }
      }
    }
  }

  return (
    <div>
      {props.isLoading ? (
        <div style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
          <div
            className="loading-spinner"
            style={{ width: '1rem', height: '1rem', marginLeft: '1rem' }}
          ></div>
        </div>
      ) : (
        <Menu
          menuClassName="soo-menu"
          menuButton={
            <MenuButton style={{ backgroundColor: 'white', border: 'none', padding: '0' }}>
              <span style={{ alignSelf: 'center' }} class="material-symbols-outlined">
                more_vert
              </span>
            </MenuButton>
          }
        >
          {!props.viewOnlyMode && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                props.onClickEdit()
              }}
            >
              {t('summaryOfObjectives.edit')}
            </MenuItem>
          )}
          {/* Commenting out until button logic for card gets decided and we decide to maybe keep this, so we fix it  */}
          {/* {props.showMeetingDeck && (
            <>
              {!props.viewOnlyMode && (
                <>
                  <MenuItem
                    style={{ textAlign: 'left' }}
                    onClick={() => {
                      handleUploadMeetingDeckClick()
                    }}
                  >
                    {t('summaryOfObjectives.uploadPresentation')}
                    <input
                      type="file"
                      id="myFile"
                      name="filename"
                      ref={meetingDeckUploadRef}
                      accept=".ppt, .pptx, .pdf"
                      onChange={(e) => onChangePpt(e, props.data)}
                      style={{ display: 'none' }}
                      title={
                        props.data.hasMeetingDeck ? t('meetingTopics.fileAlreadyExistsTooltip') : ''
                      }
                    ></input>
                  </MenuItem>
                </>
              )}
            </>
          )} */}

          {props.showMeetingDeck && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                props.onClickViewFiles()
              }}
            >
              {t('summaryOfObjectives.viewOtherFiles')}
            </MenuItem>
          )}

          {props?.enableCustomGantt && (
            <MenuItem style={{ textAlign: 'left' }} onClick={handleUploadFileClick}>
              {t('summaryOfObjectives.importTimeline')}
              <input
                type="file"
                id="myFile"
                name="filename"
                ref={fileUploadRef}
                accept=".xlsx, .xls, .xlsm"
                onChange={props.onClickUploadCustomGanttData}
                style={{ display: 'none' }}
              />
            </MenuItem>
          )}

          {props.data?.objLevel != 1 && props?.enableCustomGantt && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                props.onClickOpenCustomGantt()
              }}
            >
              {t('summaryOfObjectives.viewTimeline')}
            </MenuItem>
          )}
          {!props.viewOnlyMode && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                props.onClickMerge()
              }}
            >
              {t('meetingTopics.mergeWithTopic')}
            </MenuItem>
          )}

          {/* {!props.viewOnlyMode && (
              <MenuItem
                style={{ textAlign: 'left' }}
                onClick={() => {
                  props.onClickSetReminder()
                }}
              >
                {t('meetingTopics.setReminder')}
              </MenuItem>
            )} */}

          {/* {!props.data.finalSlides && !props.fromPresentationDeck && (<MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                props.onClickAiPresentation()
              }}
            >
              {t('summaryOfObjectives.aiPresentation')}
            </MenuItem>)} */}

          {/* {props.data.finalSlides && !props.fromPresentationDeck && (<MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                props.onClickAiPresentation()
              }}
            >
              {t('summaryOfObjectives.generatedSlides')}
            </MenuItem>)} */}

          {/* {props.data.finalSlides && !props.fromPresentationDeck && (<MenuItem
              style={{ textAlign: 'left' }}
            >
              
              <Link
                to={`/Presentation`}
                state={{
                  redirectTo: 'meetingTopics',
                  finalSlides: props.data.finalSlides,
                  umtId: props.data.umtId,
                  topicType: props.data.topicType ? props.data.topicType : 1

                }}
              >
                {t('summaryOfObjectives.presentSlides')}
              </Link>
            </MenuItem>)} */}
        </Menu>
      )}
    </div>
  )
}

export default MeetingTopicCardDropdownMenu

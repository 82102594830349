import React from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import target from '../../../../../assets/target.svg'
import deleteCross from '../../../../../assets/delete-cross.svg'
import { useTeamLeaderContext } from '../../../context/teamLeaderContext'
import useSubmitF2wTLWizCompletion from '../../../../../api/mutation/useSubmitF2wTLWizCompletion'
import { redirectToHome } from '../../../../../utils/auth'

const ObjectivesFlowToWorkModal = () => {
  const { state, dispatch } = useTeamLeaderContext()
  const { setErrorMessage, handleNext, handlePrevious } = useOutletContext()
  const [hasObjectives, setHasObjectives] = React.useState(null)
  const mutationSubmitWiz = useSubmitF2wTLWizCompletion()

  const handleClose = () => {
    dispatch({ type: 'CLOSE_OBJECTIVES_FLOWTOWORK_MODAL' })
  }
  return (
    <div
      className={`goal-mod-modal add-goal-modal ${
        state.isObjectivesFlowToWorkModalOpen ? '' : 'hidden'
      }`}
    >
      {hasObjectives !== false && (
        <div className="modal-tf" style={{ display: 'flex', width: '60vw' }}>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            <div
              className="delete-button"
              onClick={() => {
                handleClose()
              }}
            >
              <img className="delete-btn" src={deleteCross} />
            </div>
          </div>

          <div className="modal-title-container">
            <img src={target} alt="graphic of a target" />
            <b className="modal-title">Let's learn a bit about your team</b>
          </div>
          <div
            className="modal-content"
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              className="bullet-container"
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5rem',
                paddingTop: '1rem',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginBottom: '1rem', width: '70%' }}>
                  We understand your team provides capacity for other teams to achieve their
                  objectives, and in the process team members are assigned to other teams. Apart
                  from this, does your team own objectives of their own to which capacity needs to
                  be allocated?
                </div>
              </div>
            </div>
            <div className="modal-dual-btn">
              <button
                className="dual-btn negative-btn"
                onClick={async () => {
                  setHasObjectives(false)

                  await mutationSubmitWiz.mutateAsync(
                    {},
                    {
                      onSuccess: (response) => {
                        handleClose()
                      },
                      onError: () => {},
                    },
                  )
                }}
              >
                <span>NO</span>
              </button>
              <button
                className="dual-btn positive-btn"
                onClick={() => {
                  handleNext()
                  handleClose()
                }}
              >
                <span>YES</span>
              </button>
            </div>
          </div>
        </div>
      )}

      {hasObjectives === false && (
        <div className="modal-tf" style={{ display: 'flex', width: '60vw' }}>
          {/* <div className="modal-title-container">
            <img src={target} alt="graphic of a target" />
            <b className="modal-title">Let's learn a bit about your team</b>
          </div> */}
          <div
            className="modal-content"
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              className="bullet-container"
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5rem',
                paddingTop: '1rem',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginBottom: '1rem', width: '70%' }}>
                  <h2 style={{ margin: '2rem' }}>
                    Thank you - you do not need to complete the wizard.
                  </h2>
                </div>
              </div>
            </div>
            <div className="modal-dual-btn">
              <button
                className="dual-btn positive-btn"
                onClick={() => {
                  redirectToHome()
                }}
              >
                <span>OK</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ObjectivesFlowToWorkModal

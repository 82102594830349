import { React, useState } from 'react'
import Modal from '../../Modal'
import { useTranslation } from 'react-i18next'
import Toast from '../../../common/toast'
import { ModalSaveButtonFitted } from '../../ModalSubmitButton'
import { addEditFilterView } from '../../../api/services/filterView.services'

const AddEditFilterViewModal = ({
  handleClose,
  timeframeFilterValue,
  peopleFilterValue,
  objectiveFilterValue,
  selectedFilterTopic,
  reloadBackground,
  tagsFilterValue,
}) => {
  const { t } = useTranslation(['Common'])

  const [isLoading, setIsLoading] = useState(false)
  const [viewName, setViewName] = useState('')
  const [error, setError] = useState('')

  const validateAndSubmit = () => {
    if (viewName === '') {
      setError(t('Common:modalMessages.viewNameIsRequired'))
      return
    } else {
      setError(null)
      submit()
    }
  }

  const submit = async () => {
    let formData = {}

    let timeframeString = timeframeFilterValue?.value ? timeframeFilterValue.value : 'All'

    let peopleFilterArray = []
    for (const people of peopleFilterValue) {
      peopleFilterArray.push(parseInt(people.eId))
    }

    let objectiveFilterArray = []
    for (const obj of objectiveFilterValue) {
      objectiveFilterArray.push(parseInt(obj.id))
    }

    let meetingTopicFilterArray = []
    for (const mt of selectedFilterTopic) {
      meetingTopicFilterArray.push(parseInt(mt.id))
    }

    let tagsFilterValueArray = []
    for (const tag of tagsFilterValue) {
      tagsFilterValueArray.push(parseInt(tag.id))
    }

    formData = {
      ruleName: viewName,
      ruleLocation: 'KANBAN_FILTER_LOCATION',
      filterValues: {
        timeframe: timeframeString,
        assignedToActions: peopleFilterArray,
        objectives: objectiveFilterArray,
        meetingTopics: meetingTopicFilterArray,
        tags: tagsFilterValueArray,
      },
    }

    setIsLoading(true)
    try {
      let result = await addEditFilterView(formData)
      if (result.success) {
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.newPresetAddedSuccessfully'),
        })
        reloadBackground()
        handleClose()
      } else {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
    setIsLoading(false)
    handleClose()
  }

  return (
    <>
      <Modal
        closeModal={() => {
          handleClose()
        }}
        width={'30rem'}
        title={t('Common:filter.addNewPreset')}
      >
        <div className="form-field action-field  form-field action-field--kanban ">
          <label>{t('Common:filter.presetName')}</label>
          <div className="form-field action-field action-field--kanban">
            <input
              type="test"
              id="listName"
              name="listName"
              value={viewName}
              onChange={(e) => setViewName(e.target.value)}
            />
          </div>
        </div>

        <div
          className="modal-dual-btn"
          style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}
        >
          <ModalSaveButtonFitted
            isLoading={isLoading}
            tag={t('Common:commonButtons.cancel')}
            onClick={() => {
              handleClose()
            }}
          />

          <ModalSaveButtonFitted
            isLoading={isLoading}
            tag={t('Common:commonButtons.save')}
            onClick={() => {
              validateAndSubmit()
            }}
          />
        </div>
      </Modal>
    </>
  )
}

export default AddEditFilterViewModal

export function formatName(name) {
  if (!name) {
    return ''
  }
  const parts = name.split(/\s+/)
  if (parts.length < 2) {
    return parts[0].length > 5 ? `${parts[0].substring(0, 5)}...` : parts[0]
  }
  const firstName = parts[0].length > 5 ? `${parts[0].substring(0, 5)}...` : parts[0]
  return `${firstName} ${parts[1].charAt(0)}.`
}

export function getFirstLetters(string1, string2) {
  const processString = (str) => {
    return str?.trim()?.[0]?.toUpperCase() || ''
  }
  return processString(string1) + processString(string2)
}

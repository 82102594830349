import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getEmployeesUserConfigs = async (eLeaderId) => {
  let url = `${BASE_URL}/employees_user_configs_under_leader/${eLeaderId}`
  const { data } = await axios.get(url)
  return data
}

export default function useGetEmployeesUserConfigs(eLeaderId = null) {
  return useQuery(['employees-user-configs', eLeaderId], () => getEmployeesUserConfigs(eLeaderId), {
    refetchOnWindowFocus: false,
    enabled: eLeaderId !== null && eLeaderId !== '0000' && eLeaderId !== '000',
  })
}

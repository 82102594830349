import React, { useState, useEffect } from 'react'
import { Formik, Field, Form } from 'formik'
import Modal from '../Modal'
import ModalSaveButton from '../ModalSubmitButton'
import { useTranslation } from 'react-i18next'
import SelectField from '../CustomMultipleSelect/customSelect'
import GovernanceStatus from '../GovernanceStatus'
import './style.css'

const ShowUwStatusesModal = ({ closeModal }) => {
  const { t } = useTranslation(['Dashboard'])

  return (
    <>
      <div className="uw-statuses-modal">
        <Modal
          title={t('governanceCard.modalTitle')}
          height={'50rem'}
          width={'25rem'}
          closeModal={closeModal}
        >
          <GovernanceStatus location={'uwStatusesModal'} />
        </Modal>
      </div>
    </>
  )
}

export default ShowUwStatusesModal

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getHigherObjectives = async () => {
  const { data } = await axios.get(`${BASE_URL}/manage_higher_objs`)
  return data
}

export default function useHigherObjectives() {
  return useQuery(['higherObjectives'], () => getHigherObjectives(), {
    refetchOnWindowFocus: false,
  })
}

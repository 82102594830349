import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getCompletedObj = async (objLevel) => {
  const { data } = await axios.get(`${BASE_URL}/completed_objectives`, {
    params: {
      objLevel: objLevel,
    },
  })
  return data
}

export default function useGetCompletedObj(objLevel) {
  return useQuery(['completed-obj'], () => getCompletedObj(objLevel), {
    refetchOnWindowFocus: false,
  })
}

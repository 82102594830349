import React, { useState, useEffect } from 'react'
import { useDemandIntakeContext } from '../../context/demandIntakeContext'
import TeamCapacityAllocationTable from '../../../../components/AllocSubWizard/TeamCapacityAllocationTable'
import { useOutletContext } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const DemandIntakeTeamCapacityAllocation = () => {
  const { t } = useTranslation(['Common'])
  return (
    <>
      <div className="wizard-step">
        <h2 className="wizard-step__title">
          {t('capacityAllocation.teamCapacityAllocationTitle')}
        </h2>
        <div className="wizard-step__overview">
          <div className="wizard-step__overview__paragraph">
            {t('capacityAllocation.teamCapacityAllocationSubtitle')}
          </div>
        </div>

        <div className="wizard-step__main-content-container">
          <TeamCapacityAllocationTable contextHandler={useDemandIntakeContext} />
        </div>
      </div>
    </>
  )
}

export default DemandIntakeTeamCapacityAllocation

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getNoteTypeAspirationNotes,
  addWorkshopAspirationNote,
  editWorkshopAspirationNote,
  deleteWorkshopAspirationNote,
} from '../../../../api/services/aspirationNotes.services'
import Toast from '../../../../common/toast'
import EditNoteModal from '../EditNoteModal'
import Swal from 'sweetalert2'
import ApplicationLayout from '../../../../components/ApplicationLayout'

const WorkshopAspirations = ({}) => {
  const { t } = useTranslation(['UpdateWizard'])
  const [leftInputText, setLeftInputText] = React.useState('')
  const [rightInputText, setRightInputText] = React.useState('')

  const leftInputTextChangeHandler = (e) => {
    setLeftInputText(e.target.value)
  }

  const rightInputTextChangeHandler = (e) => {
    setRightInputText(e.target.value)
  }

  React.useEffect(() => {
    loadCustomerAspirationList()
    loadAssociateAspirationList()
  }, [])

  const handleAdd = async (noteText, noteType) => {
    let noteData = {
      note: noteText,
      noteType: noteType,
    }
    let result = await addWorkshopAspirationNote(noteData)
    if (result) {
      Toast.fire({
        icon: 'success',
        title: 'Note added successfully',
        //title:mode==="add"? t('kpi.kpiAddSuccess') : t('kpi.kpiEditSuccess'),
      })
      if (noteType === 'Customer') {
        setLeftInputText('')
        loadCustomerAspirationList()
      } else {
        setRightInputText('')
        loadAssociateAspirationList()
      }
    } else {
      Toast.fire({
        icon: 'error',
        title: 'Error adding note. Please try again later.',
        //title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const handleEdit = async (noteId, noteTex, noteType) => {
    let noteData = {
      noteId: noteId,
      note: noteTex,
    }
    let result = await editWorkshopAspirationNote(noteData)
    if (result) {
      Toast.fire({
        icon: 'success',
        title: 'Note edited successfully',
        //title:mode==="add"? t('kpi.kpiAddSuccess') : t('kpi.kpiEditSuccess'),
      })

      if (noteType === 'Customer') {
        loadCustomerAspirationList()
      } else {
        loadAssociateAspirationList()
      }
    } else {
      Toast.fire({
        icon: 'error',
        title: 'Error editing note. Please try again later.',
        //title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const handleDelete = async (noteId, noteType) => {
    const result = await Swal.fire({
      title: 'Are you sure you want to delete this note?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    })
    if (result.isConfirmed) {
      let noteData = { noteId: noteId }
      let result = await deleteWorkshopAspirationNote(noteData)
      if (result) {
        Toast.fire({
          icon: 'success',
          title: 'Note deleted successfully',
          //title:mode==="add"? t('kpi.kpiAddSuccess') : t('kpi.kpiEditSuccess'),
        })
        if (noteType === 'Customer') {
          loadCustomerAspirationList()
        } else {
          loadAssociateAspirationList()
        }
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Error deleting note. Please try again later.',
          //title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
    }
  }

  async function loadAssociateAspirationList() {
    //setIsLoading(true)
    let result = await getNoteTypeAspirationNotes({ noteType: 'Associate', onlyForUser: true })
    //setIsLoading(false)
    if (result && result.aspirationNotesList) {
      setRightColumnList(result.aspirationNotesList)
    }

    if (result) {
      //setRightColumnList(result.objDescription)
    }
  }

  async function loadCustomerAspirationList() {
    //setIsLoading(true)
    let result = await getNoteTypeAspirationNotes({ noteType: 'Customer', onlyForUser: true })
    //setIsLoading(false)
    if (result && result.aspirationNotesList) {
      setLeftColumnList(result.aspirationNotesList)
    }

    if (result) {
      //setLeftColumnList(result.objDescription)
    }
  }

  const [leftColumnList, setLeftColumnList] = React.useState([])
  const [rightColumnList, setRightColumnList] = React.useState([])
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false)
  const [noteToEdit, setNoteToEdit] = React.useState(null)

  return (
    <ApplicationLayout>
      <>
        {isEditModalOpen && noteToEdit && (
          <EditNoteModal
            handleEdit={handleEdit}
            handleClose={() => {
              setIsEditModalOpen(false)
            }}
            noteId={noteToEdit?.noteId}
            noteText={noteToEdit?.note}
            noteType={noteToEdit?.noteType}
          />
        )}

        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '80%',
              gap: '2rem',
              paddingTop: '4rem',
            }}
          >
            <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <b style={{ textAlign: 'center' }}>Your Aspirations for AI</b>
            </span>
            <div style={{ display: 'flex' }}>
              <span style={{ display: 'flex', width: '70rem' }}>
                Through the day, please keep adding how you think Walmex should use AI vis-a-vis our
                Customers and Associates. We will use AI to create the first draft of a holistic
                aspiration based on everyone's input!
              </span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '40rem' }}>
                <span>
                  As Walmex we hope to leverage AI to better serve our <b>Customers</b> so that they
                  can:
                </span>
                <br />
                <div className="divider" />
                <br />
                <div
                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  <textarea
                    rows="5"
                    id="leftInputText"
                    name="leftInputText"
                    value={leftInputText}
                    onChange={(e) => {
                      leftInputTextChangeHandler(e)
                    }}
                    style={{
                      resize: 'none',
                      borderRadius: '10px',
                      backgroundColor: 'white',
                      fontSize: '14px',
                      width: '25rem',
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      width: '7rem',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                    }}
                  >
                    <div
                      className="fitted-button blue tooltip"
                      onClick={() => {
                        handleAdd(leftInputText, 'Customer')
                      }}
                    >
                      <span className="material-symbols-outlined">add</span>
                    </div>
                  </div>
                </div>
                <div>
                  <br />
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    {leftColumnList.map((item, index) => (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          gap: '1rem',
                        }}
                      >
                        <span>{item.note}</span>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                          <div
                            className="fitted-button red tooltip"
                            onClick={() => {
                              handleDelete(item.noteId, 'Customer')
                            }}
                          >
                            <span className="material-symbols-outlined">close</span>
                          </div>
                          <div
                            className="fitted-button blue tooltip"
                            onClick={() => {
                              setNoteToEdit(item)
                              setIsEditModalOpen(true)
                            }}
                          >
                            <span className="material-symbols-outlined">edit</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', width: '40rem' }}>
                <span>
                  As Walmex we hope to leverage AI to better serve our <b>Associates</b> so that
                  they can:
                </span>
                <br />
                <div className="divider" />
                <br />
                <div
                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  <textarea
                    rows="5"
                    id="rightInputText"
                    name="rightInputText"
                    value={rightInputText}
                    onChange={(e) => {
                      rightInputTextChangeHandler(e)
                    }}
                    style={{
                      resize: 'none',
                      borderRadius: '10px',
                      backgroundColor: 'white',
                      fontSize: '14px',
                      width: '25rem',
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      width: '7rem',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                    }}
                  >
                    <div
                      className="fitted-button blue tooltip"
                      onClick={() => {
                        handleAdd(rightInputText, 'Associate')
                      }}
                    >
                      <span className="material-symbols-outlined">add</span>
                    </div>
                  </div>
                </div>
                <div>
                  <br />
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    {rightColumnList.map((item, index) => (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          gap: '1rem',
                        }}
                      >
                        <span>{item.note}</span>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                          <div
                            className="fitted-button red tooltip"
                            onClick={() => {
                              handleDelete(item.noteId, 'Associate')
                            }}
                          >
                            <span className="material-symbols-outlined">close</span>
                          </div>
                          <div
                            className="fitted-button blue tooltip"
                            onClick={() => {
                              setNoteToEdit(item)
                              setIsEditModalOpen(true)
                            }}
                          >
                            <span className="material-symbols-outlined">edit</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </ApplicationLayout>
  )
}

export default WorkshopAspirations

import React, { useState, useEffect } from 'react'
import './styles.css'

function intOr(val) {
  let res = parseInt(val)
  if (res === undefined || isNaN(res)) res = 0
  return res
}

const ExternalMembersAlloc = ({
  state,
  // Array containing all employees, not just non-direct reports.
  employees,
  objectives,
  // Dicitionary (hook) where `externalEmpAlocsH[[i,j]]` = allocation of employee_i on obj_j.
  // Note that employee_i (and obj_i) refers to the employee (objective) with local ID = i, not
  // index i in the array.
  // We use localIds to make this component resilient to changes in the arrays.
  externalEmpAlocsH,
  // Setter for the above hook.
  setExternalEmpAlocs,
  // Setter to pass validation back to parent.
  setExternalMemberAllocValid,
  // If true, then pre-existing 0 values show up as N/A.
  disableEditingZero,
  // List of pre-existing 0 values.
  nonZeroPairsFromBackend = {},

  setExternaMemberAllocErrorArray,

  trigger,
}) => {
  // Temp variable to store the sum for each employee (row in the 2D array).
  let externalEmpAlocSumsTmp = {}

  var allowEditingZero = !disableEditingZero

  // A hook s.t. externalEmpAlocSumsH[i] = sum of employee_i's alloc across all objectives.
  const [externalEmpAlocSumsH, setExternalEmpAlocSums] = useState(externalEmpAlocSumsTmp)
  // A hook s.t. objectivesExternalEmpAlocSumsH[i] = sum of obj_i's alloc across all employees.
  const [objectivesExternalEmpAlocSumsH, setObjectivesExternalEmpAlocSums] = useState([])
  // Hook for error messages.
  const [errorMessages, setErrorMessages] = useState([])
  // Hook sotring the number of columns, used for styling
  const [columnsLength, setColumnsLength] = useState(0)

  //Hook used to keep track if a person has been touched or not to determine when to show error message and allow or not to continue to next page
  const [errorMessageArray, setErrorMessageArray] = useState(
    new Array(employees.length).fill(false),
  )

  const allocChangeHandler = (e, objLocalId, eNonDirectLocalId) => {
    // Update the allocation dictionary ownned by the parent.
    let externalEmpAlocsTmp = { ...externalEmpAlocsH }
    externalEmpAlocsTmp[[eNonDirectLocalId, objLocalId]] = intOr(e.target.value)
    setExternalEmpAlocs(externalEmpAlocsTmp)

    let tempErrorMessageArray = [...errorMessageArray]
    tempErrorMessageArray[eNonDirectLocalId] = true
    setErrorMessageArray(tempErrorMessageArray)
  }

  const numEmployees = employees?.filter((emp) => emp.isDirectReport !== true).length
  const numObjectives = objectives.length

  // For both employeesa and objectives, we need to map the indexes to localIds for efficient
  // processing.
  const employeeIndexLocalId = employees
    .filter((emp) => emp.isDirectReport !== true)
    .map((e, i) => e.localeId ?? i)
  const objIndexLocalId = objectives.map((o, i) => o.localeId ?? i)

  React.useEffect(() => {
    const employeesLength = employees?.filter((emp) => emp.isDirectReport !== true).length
    setColumnsLength(employeesLength + 2)
  }, [employees])

  React.useEffect(() => {
    let externalEmpAlocSumsTmp = {}
    let filteredEmployees = employees.filter((emp) => emp.isDirectReport !== true)
    for (let eIndex = 0; eIndex < filteredEmployees.length; eIndex++) {
      let empLocal = employeeIndexLocalId[eIndex]
      externalEmpAlocSumsTmp[empLocal] = 0
      for (let oIndex = 0; oIndex < objectives.length; oIndex++) {
        let objLocal = objIndexLocalId[oIndex]
        externalEmpAlocSumsTmp[empLocal] += externalEmpAlocsH[[empLocal, objLocal]] ?? 0
      }
    }

    setExternalEmpAlocSums(externalEmpAlocSumsTmp)

    // Compute the total alloc per objective.
    let objectivesExternalEmpAlocsSumsTmp = {}
    for (let col = 0; col < numObjectives; col++) {
      let objLocal = objIndexLocalId[col]
      if (objectives[col].requiredFte == 0) {
        continue
      }
      objectivesExternalEmpAlocsSumsTmp[objLocal] = 0
      for (let row = 0; row < numEmployees; row++) {
        let empLocal = employeeIndexLocalId[row]
        objectivesExternalEmpAlocsSumsTmp[objLocal] += externalEmpAlocsH[[empLocal, objLocal]] ?? 0
      }
    }
    setObjectivesExternalEmpAlocSums(objectivesExternalEmpAlocsSumsTmp)
    setErrorMessages([])
    setExternaMemberAllocErrorArray(null)

    //check if over capacity by person
    let personCapacityErrorArray = []
    for (let i = 0; i < employees.length; i++) {
      if (
        externalEmpAlocSumsTmp[i] > employees[i].teamAllocPct &&
        employees[i].isDirectReport !== true
      ) {
        let message =
          employees[i].name +
          `'s allocation is above their borrowed capacity of ${employees[i].teamAllocPct}%.`
        personCapacityErrorArray.push(message)
      } else if (externalEmpAlocSumsTmp[i] < employees[i].teamAllocPct) {
        let message =
          employees[i].name +
          `'s allocation is under their borrowed capacity of ${employees[i].teamAllocPct}%.`
        personCapacityErrorArray.push(message)
      }
    }

    //check if over capacity by objective
    let objCapacityErrorArray = []
    for (let i = 0; i < objectives.length; i++) {
      let localId = objIndexLocalId[i]
      if (objectivesExternalEmpAlocsSumsTmp[localId] > objectives[i].requiredFte * 100) {
        const externalSumFte = objectivesExternalEmpAlocsSumsTmp[localId] / 100.0
        let message = `There is more capacity allocated to "${objectives[i].statement}" than needed.`
        objCapacityErrorArray.push(message)
      }
    }

    let errorMessageArrayLength = errorMessageArray.filter((touched) => touched === true).length
    let isDirectReportFalseTotal = employees.filter((emp) => emp.isDirectReport !== true).length

    const checkAllValuesGreatherThanZero = (externalEmpAlocSumsH) => {
      if (Object.values(externalEmpAlocSumsH).length === 0) {
        return false
      }
      for (const val of Object.values(externalEmpAlocSumsH)) {
        if (val <= 0) {
          return false
        }
      }
      return true
    }

    if (personCapacityErrorArray.concat(objCapacityErrorArray).length > 0) {
      setExternalMemberAllocValid(false)
      setExternaMemberAllocErrorArray(personCapacityErrorArray.concat(objCapacityErrorArray))
    } else if (
      Object.keys(externalEmpAlocSumsH).length === isDirectReportFalseTotal &&
      checkAllValuesGreatherThanZero(externalEmpAlocSumsH)
    ) {
      setExternalMemberAllocValid(true)
    }
  }, [externalEmpAlocsH, trigger])

  var isObjectiveHidden = []
  let filteredEmployees = employees.filter((emp) => emp.isDirectReport !== true)
  for (let o = 0; o < objectives.length; o++) {
    let hideOrNot = allowEditingZero === false
    for (let e = 0; e < filteredEmployees.length; e++) {
      hideOrNot =
        hideOrNot && nonZeroPairsFromBackend[[employeeIndexLocalId[e], objIndexLocalId[o]]] !== true
    }
    isObjectiveHidden.push(hideOrNot)
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div className="table-wrapper external-member-alloc-table">
          <div
            style={{
              fontSize: '14px',
              color: '#555',
              fontStyle: 'italic',
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
            }}
          >
            Only objectives that have been assigned capacity are shown in this table
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: `repeat(${columnsLength}, 1fr)` }}>
            <div className="widget-grid-title" style={{ width: '10rem' }}></div>
            {employees
              ?.filter((emp) => emp.isDirectReport !== true)
              .map((employee, eIndex) => (
                <div className="widget-grid-title" style={{ fontWeight: 'bold' }}>
                  {employee.name}
                </div>
              ))}
            <div className="widget-grid-title" style={{ width: '8rem', fontWeight: 'bold' }}>
              Objective total
            </div>
          </div>
          <div>
            {objectives.map((objective, objIndex) => (
              <>
                {!isObjectiveHidden[objIndex] && objective.requiredFte > 0 && (
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: `repeat(${columnsLength}, 1fr)`,
                    }}
                  >
                    <div
                      className="widget-grid-title"
                      style={{ width: '10rem', justifyContent: 'flex-start', fontWeight: 'normal' }}
                    >
                      {' '}
                      {objective.statement}
                    </div>
                    {employees
                      ?.filter((emp) => emp.isDirectReport !== true)
                      .map((emp, eNonDirectIndex) => (
                        <div className="widget-grid-title">
                          {(allowEditingZero === true ||
                            nonZeroPairsFromBackend[
                              [employeeIndexLocalId[eNonDirectIndex], objIndexLocalId[objIndex]]
                            ] === true) && (
                            <>
                              <input
                                type="text"
                                style={{ width: '30px', textAlign: 'center', fontSize: '1rem' }}
                                key={`extern-obj-emp-${objIndex}-${eNonDirectIndex}`}
                                onChange={(e) =>
                                  allocChangeHandler(
                                    e,
                                    objIndexLocalId[objIndex],
                                    employeeIndexLocalId[eNonDirectIndex],
                                  )
                                }
                                defaultValue={
                                  externalEmpAlocsH[
                                    [
                                      employeeIndexLocalId[eNonDirectIndex],
                                      objIndexLocalId[objIndex],
                                    ]
                                  ] ?? 0
                                }
                              />
                              %
                              {objective.requiredFte === 0 && (
                                <span>
                                  {' '}
                                  <br />
                                  Return alloc to owner b/c obj fte is 0 (in update wizard only)
                                </span>
                              )}
                            </>
                          )}
                          {allowEditingZero === false &&
                            nonZeroPairsFromBackend[
                              [employeeIndexLocalId[eNonDirectIndex], objIndexLocalId[objIndex]]
                            ] !== true &&
                            'N/A'}
                        </div>
                      ))}
                    <div className="widget-grid-title" style={{ width: '8rem' }}>
                      {' '}
                      {objectivesExternalEmpAlocSumsH[objIndexLocalId[objIndex]]}%
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: `repeat(${columnsLength}, 1fr)` }}>
            <div
              className="widget-grid-title"
              style={{ width: '10rem', justifyContent: 'flex-start', fontWeight: 'bold' }}
            >
              Total
            </div>
            {employees
              ?.filter((emp) => emp.isDirectReport !== true)
              .map((emp, eNonDirectIndex) => (
                <div className="widget-grid-title">
                  {externalEmpAlocSumsH[employeeIndexLocalId[eNonDirectIndex]]}%
                </div>
              ))}
            <div className="widget-grid-title" style={{ width: '8rem' }}></div>
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: `repeat(${columnsLength}, 1fr)`,
              backgroundColor: '#dcdcdc',
            }}
          >
            <div
              className="widget-grid-title"
              style={{ width: '10rem', justifyContent: 'flex-start' }}
            >
              Capacity must add to
            </div>
            {employees
              ?.filter((emp) => emp.isDirectReport !== true)
              .map((emp, eNonDirectIndex) => (
                <div className="widget-grid-title">{emp.teamAllocPct}%</div>
              ))}
            <div className="widget-grid-title" style={{ width: '8rem' }}></div>
          </div>
        </div>
      </div>
      <div className="validation-error">
        {errorMessages &&
          errorMessages.map((msg, msgIndex) => (
            <>
              <div>{msg}</div>
              <br></br>
            </>
          ))}
      </div>
    </>
  )
}

export default ExternalMembersAlloc

import React from 'react'
import './styles.css'

const ProgressBar = ({ currentStep, steps }) => {
  return (
    <div className="navigation-new">
      <div className="nav-progress-container">
        <div className="progress-bar">
          {steps.map((step, index) => (
            <div className="step" key={index}>
              {index > 0 && (
                <div
                  className={`progress progress-rect${
                    currentStep >= index ? ' active-progress' : ''
                  }`}
                ></div>
              )}
              <div
                className={`progress progress-circle${
                  currentStep >= index ? ' active-progress' : ''
                }`}
              ></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProgressBar

import React, { useState, useEffect } from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import useVerbSuggestions from '../../../utils/useVerbSuggestions'
import deleteCross from '../../../assets/delete-cross.svg'
import target from '../../../assets/target.svg'
import useValidateGoal from '../../../api/mutation/useValidateGoal'
import useAddSuggestedObjective from '../../../api/mutation/addSuggestedObjective'
import ObjectiveExampleText from '../../ObjectiveExampleText'
import ErrorModal from '../../ErrorModal'
import Modal from '../../Modal'
import ModalSaveButton from '../../ModalSubmitButton'
import useTeamLeadersList from '../../../api/query/useTeamLeadersList'
import SelectField from '../../CustomMultipleSelect/customSelect'
import { useTranslation } from 'react-i18next'

const SuggestObjectiveModal = ({
  isSuggestObjectiveModalOpen,
  setIsSuggestObjectiveModalOpen,
  teamLeader = '',
}) => {
  const { t } = useTranslation(['Common'])
  const [errorMessage, setErrorMessage] = React.useState(null)
  const [verbOptions, handleVerbSuggestions] = useVerbSuggestions()
  const mutationValidateGoal = useValidateGoal()
  const mutationAddSuggestedObjective = useAddSuggestedObjective()
  const [suggestObjectiveWidgetErrorMessage, setSuggestObjectiveWidgetErrorMessage] =
    React.useState(null)
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const handleClose = () => {
    setIsSuggestObjectiveModalOpen(false)
  }
  const { data: teamLeaderData } = useTeamLeadersList(true)

  React.useEffect(() => {
    setErrorMessage(null)
    setSuggestObjectiveWidgetErrorMessage(null)
  }, [isSuggestObjectiveModalOpen === true])

  const handleSubmit = async (values, resetForm) => {
    values.statement = values.statement.charAt(0).toUpperCase() + values.statement.slice(1)
    await mutationAddSuggestedObjective.mutateAsync(
      {
        action: values.statement.replace(/ .*/, ''),
        details: values.statement.substring(values.statement.indexOf(' ') + 1),
        comment: values.comment,
        tlId:
          values.selectedTeamLeader.eId === undefined
            ? parseInt(values.selectedTeamLeader[0].userId)
            : values.selectedTeamLeader.eId,
      },
      {
        onSuccess: (response) => {
          resetForm()
          handleClose()
          if (response.success === false) {
            setSuggestObjectiveWidgetErrorMessage('There was an error, please try again.')
            openErrorModal()
          }
          setIsBeingSubmitted(false)
        },
        onError: () => {
          resetForm()
          setIsBeingSubmitted(false)
          handleClose()
          openErrorModal()
        },
      },
    )
  }

  const openErrorModal = () => {
    setIsErrorModalOpen(true)
  }

  const closeErrorModal = () => {
    setIsErrorModalOpen(false)
    resetForm()
  }

  const errorCheck = async (values, resetForm) => {
    setIsBeingSubmitted(true)
    await mutationValidateGoal.mutateAsync(`${values.statement}`, {
      onSuccess: (response) => {
        if (response.is_valid === true) {
          values.isValid = true
          setErrorMessage(null)
          handleSubmit(values, resetForm)
        } else {
          setIsBeingSubmitted(false)
          setErrorMessage('Action and/or Details inputs are not in the correct syntax')
        }
      },
      onError: () => {
        openErrorModal()
      },
    })
  }
  const validate = (values) => {
    const errors = {}
    if (!values.statement) {
      errors.statement = t('formError.required')
    }

    if (!values.selectedTeamLeader) {
      errors.selectedTeamLeader = t('formError.required')
    }

    return errors
  }

  const [resetForm, setResetForm] = useState(null)

  const formikRef = React.useRef()

  useEffect(() => {
    if (formikRef.current !== undefined) {
      setResetForm(() => formikRef.current.resetForm)
    }
  }, [formikRef])

  const [isBeingSubmitted, setIsBeingSubmitted] = React.useState(false)

  return (
    <>
      <ErrorModal
        isOpen={isErrorModalOpen}
        handleClose={closeErrorModal}
        errorMessage={suggestObjectiveWidgetErrorMessage}
      />

      {isSuggestObjectiveModalOpen === true && (
        <Modal title={t('suggestObjectiveModal.suggestObjective')} closeModal={handleClose}>
          <Formik
            enableReinitialize
            initialValues={{
              statement: '',
              comment: '',
              selectedTeamLeader: teamLeader ? [teamLeader] : null,
            }}
            validate={validate}
            // validationSchema={Yup.object().shape({
            //   statement: Yup.string().required('Required'),
            //   comment: Yup.string(),
            // })}
            onSubmit={(values, { resetForm }) => {
              errorCheck(values, resetForm)
            }}
          >
            {({ errors, touched, resetForm }) => (
              <Form
                className="objective-form"
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
              >
                <div>
                  <div className="form-field action-field">
                    <label>{t('suggestObjectiveModal.objectiveName')}</label>
                    <Field
                      list="obj_verb_list"
                      id="statement"
                      name="statement"
                      // placeholder="Start with a verb"
                      onKeyUp={handleVerbSuggestions}
                      onClick={handleVerbSuggestions}
                      autoComplete="off"
                    />
                    {errors.statement && touched.statement && (
                      <div className="validation-error">{errors.statement}</div>
                    )}
                    {errorMessage && <div className="validation-error">{errorMessage}</div>}
                    <datalist id="obj_verb_list">
                      {verbOptions.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </datalist>
                  </div>
                  <div className="form-field action-field">
                    <label>{t('suggestObjectiveModal.note')}</label>
                    <Field
                      type="text"
                      id="comment_input"
                      name="comment"
                      autoComplete="off"
                      as="textarea"
                      style={{ width: '100%' }}
                      rows="4"
                    />
                    {errors.comment && touched.comment && (
                      <div className="validation-error">{errors.comment}</div>
                    )}
                  </div>
                  <div className="form-field action-field">
                    <label>{t('suggestObjectiveModal.selectTeamLeader')}</label>
                    <Field {...SelectField} name="selectedTeamLeader">
                      {({ field }) => (
                        <SelectField
                          field={field}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.eId}
                          options={teamLeaderData?.employees}
                          isMulti={false}
                          isClearable={false}
                        />
                      )}
                    </Field>
                  </div>
                  {errors.selectedTeamLeader && touched.selectedTeamLeader && (
                    <div className="validation-error" style={{ textAlign: 'left' }}>
                      {errors.selectedTeamLeader}
                    </div>
                  )}
                </div>
                <div className="modal-dual-btn">
                  <button
                    className="dual-btn negative-btn"
                    onClick={() => {
                      resetForm()
                      handleClose()
                      setErrorMessage(null)
                    }}
                    type="button"
                  >
                    {t('commonButtons.cancel')}
                  </button>
                  <ModalSaveButton
                    isLoading={mutationValidateGoal.isLoading || mutationValidateGoal.isFetching}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  )
}

export default SuggestObjectiveModal

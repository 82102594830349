import React from 'react'
import ManageInitiative from '../../pages/Governance/components/ManageInitiative'
import Modal from '../Modal'
const InitNumberModal = ({ isModalOpen, closeModal }) => {
  return (
    <>
      <Modal
        title={'Switch Initiative Numbers'}
        isModalOpen={isModalOpen}
        closeModal={() => closeModal()}
        width={'60%'}
        height={'30rem'}
      >
        <ManageInitiative />
      </Modal>
    </>
  )
}

export default InitNumberModal

import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const createAreaLeaderUpdateCycle = async () => {
  const { data } = await axios.post(`${BASE_URL}/create_area_leader_update_cycle`)

  return data
}

export default function useCreateAreaLeaderUpdateCycle() {
  const queryClient = useQueryClient()

  return useMutation(() => createAreaLeaderUpdateCycle(), {
    onSuccess: () => {
      void queryClient.invalidateQueries('govalcycleinfo')
    },
  })
}

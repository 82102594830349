import React from 'react'
import './styles.css'
import { useTranslation } from 'react-i18next'

const ObjectiveTypeHelpModal = ({ isOpen, handleClose }) => {
  const { t } = useTranslation(['Common'])
  return (
    <div onClick={handleClose} className={`obj-type-modal ${isOpen ? '' : ' hidden'}`}>
      <div className="modal-tf">
        <div style={{ textAlign: 'left' }}>
          {t('objectiveTypeHelpModal.line1')}
          <ul style={{ textAlign: 'left' }}>
            <li>
              <b>{t('objectiveTypeHelpModal.line2.1')}</b> {t('objectiveTypeHelpModal.line2.2')}
            </li>
            <br />
            <li>
              <b>{t('objectiveTypeHelpModal.line3.1')}</b> {t('objectiveTypeHelpModal.line3.2')}
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ObjectiveTypeHelpModal

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getTeamCapacityData = async (teamLeaderId) => {
  const queryString = teamLeaderId === null ? '/team_capacity' : `/team_capacity/${teamLeaderId}`

  const { data } = await axios.get(`${BASE_URL}${queryString}`)

  return data
}

export default function useTeamCapacityData(teamLeaderId = null) {
  return useQuery(['team_capacity', teamLeaderId], () => getTeamCapacityData(teamLeaderId), {
    refetchOnWindowFocus: false,
  })
}

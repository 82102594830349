import React from 'react'
import { defaultState, TeamLeaderProvider } from './context/teamLeaderContext'
import TeamLeaderWizard from './components/TeamLeaderWizard'
import useTeamLeaderData from './hooks/useTeamLeaderData'
import Loading from '../../components/Loading'
import ApplicationLayout from '../../components/ApplicationLayout'

const TeamLeaderWizardPage = () => {
  const { data, isLoading, error } = useTeamLeaderData()
  //TBD Swetha : Remove local-storage when not required
  // const existingStep = localStorage.getItem('tlw-step')
  const existingStep = data?.existingStep

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    // TODO: Error Component
    return 'Error'
  }

  // concat existing data / api data

  const initialState = {
    ...defaultState,
    isFlowToWorkLeader: parseInt(localStorage.getItem('mType')) === 4,
    data,
  }

  //adding localeId to incoming team employees on load
  let counter = -1
  for (let emp of initialState.data.employees) {
    counter++
    emp.localeId = counter
    emp.eId = counter
  }
  initialState.lastEmpLocaleId = counter

  let objectiveCount = -1
  for (let obj of initialState.data.objectives) {
    objectiveCount++
    obj.localeId = objectiveCount
    obj.id = objectiveCount
  }
  initialState.lastObjLocaleId = objectiveCount

  return (
    <ApplicationLayout>
      <TeamLeaderProvider initialState={initialState}>
        <div
          className="wizard-container"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TeamLeaderWizard existingStep={existingStep ?? 0} />
        </div>
      </TeamLeaderProvider>
    </ApplicationLayout>
  )
}

export default TeamLeaderWizardPage

import React from 'react'
import './styles.css'
import { useTranslation } from 'react-i18next'

const MeasureHelpModal = ({ isOpen, handleClose }) => {
  const { t } = useTranslation(['Common'])
  return (
    <div onClick={handleClose} className={`info-modal2 ${isOpen ? '' : 'hidden'}`}>
      <div className="modal-tf">
        <ul>
          <li>{t('measureHelpModal.line1')}</li>
          <li>{t('measureHelpModal.line2')}</li>
          <li>{t('measureHelpModal.line3')}</li>
        </ul>
      </div>
    </div>
  )
}

export default MeasureHelpModal

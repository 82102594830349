import React from 'react'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/core.css'
import { useTranslation } from 'react-i18next'
import { getCompanyConfigSettings } from '../../utils/auth'

function KanbanDropdownMenu(props) {
  const { t } = useTranslation(['Kanban', 'MeetingHub'])

  return (
    <div style={{ margin: 'auto 0' }}>
      <Menu
        menuClassName="soo-menu"
        menuButton={
          <MenuButton
            style={{
              backgroundColor: props.backgroundColor ?? 'white',
              border: 'none',
              padding: '0',
            }}
          >
            <span style={{ alignSelf: 'center' }} class="material-symbols-outlined">
              more_vert
            </span>
          </MenuButton>
        }
      >
        <>
          <MenuItem style={{ textAlign: 'left' }} onClick={props.onClickEdit}>
            {t('dropdown.edit')}
          </MenuItem>

          {props.fileId !== '' && props.fileId !== undefined && (
            <MenuItem style={{ textAlign: 'left' }} onClick={props.onClickDownloadFile}>
              {t('dropdown.download')}
            </MenuItem>
          )}

          {props.showMeetingDeck && (props.fileId === '' || props.fileId === undefined) && (
            <MenuItem style={{ textAlign: 'left' }} onClick={props.onClickUploadFile}>
              {t('dropdown.upload')}

              <input
                key={props.milestoneId.toString()}
                type="file"
                id="newFile"
                name="filenameNew"
                ref={props.kanbanUploadRef}
                accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.png,.jpg,.jpeg,.svg,.txt,.csv"
                onChange={(e) => props.onChangeFile(e)}
                style={{ display: 'none' }}
              ></input>
            </MenuItem>
          )}
          {props.umtId && (
            <MenuItem style={{ textAlign: 'left' }} onClick={props.onCilckUnlink}>
              {t('dropdown.unLink')}
            </MenuItem>
          )}
          {props.aiMemoCount === 0 && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                props.onClickAiMemo()
              }}
            >
              {t('MeetingHub:summaryOfObjectives.aiMemo')}
            </MenuItem>
          )}
          {props.aiMemoCount > 0 && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                props.onClickDraftMemo()
              }}
            >
              {t('MeetingHub:summaryOfObjectives.aiMemoDraft')}
            </MenuItem>
          )}
        </>
      </Menu>
    </div>
  )
}

export default KanbanDropdownMenu

import React from 'react'
import { defaultState, AreaLeaderProvider } from './context/areaLeaderContext'
import AreaLeaderWizard from './components/AreaLeaderWizard'
import useAreaLeaderData from './hooks/useAreaLeaderData'
import Loading from '../../components/Loading'
import ApplicationLayout from '../../components/ApplicationLayout'

const AreaLeaderWizardPage = () => {
  const { data, isLoading, error } = useAreaLeaderData()
  //TBD Swetha : Remove local-storage when not required
  // const existingStep = localStorage.getItem('tlw-step')
  const existingStep = data?.existingStep

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    // TODO: Error Component
    return 'Error'
  }

  // concat existing data / api data

  const initialState = {
    ...defaultState,
    isFlowToWorkLeader: parseInt(localStorage.getItem('mType')) === 4,
    data,
  }

  let objectiveCount = -1
  for (let obj of initialState.data.objectives) {
    objectiveCount++
    obj.localeId = objectiveCount
    obj.id = objectiveCount
  }
  initialState.lastObjLocaleId = objectiveCount

  return (
    <ApplicationLayout>
      <AreaLeaderProvider initialState={initialState}>
        <div
          className="wizard-container"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <AreaLeaderWizard existingStep={existingStep ?? 0} />
        </div>
      </AreaLeaderProvider>
    </ApplicationLayout>
  )
}

export default AreaLeaderWizardPage

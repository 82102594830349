import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getCompanyConfig = async () => {
  let url = `${BASE_URL}/get_company_configs`
  const { data } = await axios.get(url)
  return data
}

export default function useGetCompanyConfig() {
  return useQuery(['company-config'], () => getCompanyConfig(), {
    refetchOnWindowFocus: false,
  })
}

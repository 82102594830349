import React from 'react'
import { useTranslation } from 'react-i18next'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import Toast from '../../common/toast'
import EmployeeMEIBoxesModal from '../EmployeeMEIBoxesModal'
import MeiModal from '../../pages/Home/components/AdminDashboard/components/TeamDoing/component/MeiModal'

const NavigationMEITeam = () => {
  const { t } = useTranslation(['Common'])

  const [metrics, setMetrics] = React.useState(null)
  const [isEmployeeMeiBoxesModalOpen, setIsEmployeeMeiBoxesModalOpen] = React.useState(false)
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [data, setData] = React.useState(null)
  //call this for ALs Team
  const refreshMei = async () => {
    try {
      let result = await getHttpRequest('/get_mei_data')
      setMetrics(result)
    } catch (err) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }

  return (
    <>
      {isEmployeeMeiBoxesModalOpen && metrics && (
        <EmployeeMEIBoxesModal
          metrics={metrics}
          closeModal={() => setIsEmployeeMeiBoxesModalOpen(false)}
          setIsEmployeeMeiBoxesModalOpen={setIsModalOpen}
          setData={setData}
        />
      )}

      {isModalOpen && data && (
        <MeiModal data={data} isModalOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} />
      )}
      <div>
        {
          <span
            style={{
              color: '#4472c4',
              textDecoration: 'underline',
              cursor: 'pointer',
              fontSize: '14px',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => {
              refreshMei()
              setIsEmployeeMeiBoxesModalOpen(true)
            }}
          >
            {t('navigation.howIsMyTeamDoing')}
          </span>
        }
      </div>
    </>
  )
}

export default NavigationMEITeam

import React from 'react'
import './styles.css'
import useGovernanceStatusData from './../../api/query/useGovernanceCycleStatusData'
import Button from '../Button'
import { governanceCycleEscalationStatus } from '../../utils/enumValues'
import GovernanceStatusModal from './GovernanceStatusModal'
import { CardContainer, CardHeader } from '../../pages/Home/styles'
import SuggestObjectiveModal from './SuggestObjectiveModal'
import InPageLoader from '../InPageLoader'
import { routes, capacitySubRoutes } from '../../utils/routes'
import { useNavigate } from 'react-router-dom'
// import BlockerModal from '../../pages/Home/components/Blockers/BlockerModal'
import { useTranslation } from 'react-i18next'

const GovernanceStatus = ({ location }) => {
  const { t } = useTranslation(['Dashboard'])
  const { data: governanceStatusData, isLoading: governanceStatusIsLoading } =
    useGovernanceStatusData()
  const [isGovernanceStatusModalOpen, setIsGovernanceStatusModalOpen] = React.useState(false)
  const [isSuggestObjectiveModalOpen, setIsSuggestObjectiveModalOpen] = React.useState(false)
  const [isBlockerFormModalOpen, setIsBlockerFormModalOpen] = React.useState(false)
  const [teamLeader, setTeamLeader] = React.useState(null)
  const navigate = useNavigate()
  const isF2wTl = parseInt(localStorage.getItem('mType')) === 4 ? true : false

  const statusMappings = {
    COMPLETED: t('governanceCard.completed'),
    NOT_COMPLETED: t('governanceCard.notCompleted'),
    UW_IN_PROGRESS: t('governanceCard.inProgress'),
    CUSTOMIZED_PENDING_REVIEW: t('governanceCard.pendingReview'),
    NOT_APPLICABLE: t('governanceCard.na'),
  }

  const classMappings = {
    COMPLETED: 'good-text',
    NOT_COMPLETED: 'bad-text',
    CUSTOMIZED_PENDING_REVIEW: 'bad-text',
    NOT_APPLICABLE: 'neutral-text',
    UW_IN_PROGRESS: 'neutral-text',
  }

  const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children

  if (governanceStatusIsLoading) {
    return (
      <ConditionalWrapper
        condition={location === 'Governance'}
        wrapper={(children) => <div style={{ width: '45%' }}>{children} </div>}
      >
        <div className="shadow-governance-box">
          {location === 'Home' && (
            <CardHeader style={{ color: '#44546A' }}>{t('governanceCard.title')}</CardHeader>
          )}
          <div className="table-wrapper">
            <InPageLoader inComponent={true} />
          </div>
        </div>
      </ConditionalWrapper>
    )
  }

  const closeBlockerModal = () => {
    setIsBlockerFormModalOpen(false)
  }

  return (
    <>
      {teamLeader && isGovernanceStatusModalOpen === true && (
        <GovernanceStatusModal
          isGovernanceStatusModalOpen={isGovernanceStatusModalOpen}
          setIsGovernanceStatusModalOpen={setIsGovernanceStatusModalOpen}
          teamLeader={teamLeader}
        />
      )}
      <ConditionalWrapper
        condition={location === 'Governance'}
        wrapper={(children) => <div style={{ width: '45%', overflow: 'auto' }}>{children} </div>}
      >
        {teamLeader && isSuggestObjectiveModalOpen === true && (
          <SuggestObjectiveModal
            isSuggestObjectiveModalOpen={isSuggestObjectiveModalOpen}
            setIsSuggestObjectiveModalOpen={setIsSuggestObjectiveModalOpen}
            teamLeader={teamLeader}
          ></SuggestObjectiveModal>
        )}
        {/* {isBlockerFormModalOpen === true && (
          <BlockerModal
            isModalOpen={isBlockerFormModalOpen}
            closeModal={closeBlockerModal}
            mode={'ADD1'}
            blocker={null}
            teamLeader={teamLeader?.userId}
          />
        )} */}
        {/* <CardContainer style={{color: '#44546A', height:'569px', marginTop:'13px', justifyContent:'normal', overflowY:'auto', paddingTop:'0rem' }}> */}
        <div className="shadow-governance-box">
          {location === 'Home' && <CardHeader>{t('governanceCard.title')}</CardHeader>}
          {governanceStatusData?.teamLeaderStatuses?.length > 0 && (
            <div
              className="table-wrapper"
              style={{
                paddingTop: '5px',
                justifyContent: 'left',
                alignItems: 'flex-start',
                marginLeft: '1rem',
              }}
            >
              <div
                className={location === 'Home' ? 'widget-grid-4' : 'widget-grid-2'}
                style={{ columnGap: '3rem', rowGap: '0.5rem' }}
              >
                <div
                  className="governance-table-title"
                  style={{ justifyContent: 'unset', padding: '.5rem 0.25rem' }}
                >
                  {t('governanceCard.teamLeader')}
                </div>
                <div
                  className="governance-table-title"
                  style={{ justifyContent: 'unset', padding: '.5rem 0.25rem' }}
                >
                  {t('governanceCard.status')}
                </div>
                {/* <div
                  className="widget-grid-title"
                  style={{ justifyContent: 'unset', padding: '.5rem 0.25rem' }}
                >
                  Escalated
                </div> */}
                {/* <div
                  className="widget-grid-title"
                  style={{ justifyContent: 'unset', padding: '.5rem 0.25rem' }}
                ></div> */}
                {location === 'Home' && (
                  <>
                    <div className="governance-table-title"></div>
                  </>
                )}
                {!governanceStatusIsLoading &&
                  governanceStatusData?.teamLeaderStatuses?.map((leader, index) => (
                    <React.Fragment key={`governanceStatus-${index}`}>
                      <div
                        className="governance-table-cell"
                        style={{
                          justifyContent: 'unset',
                          padding: '.5rem 0.25rem',
                          wordBreak: 'break-word',
                        }}
                      >
                        <span>{leader?.name}</span>
                      </div>
                      <div
                        className="governance-table-cell"
                        style={{ justifyContent: 'unset', padding: '.5rem 0.25rem' }}
                      >
                        <span className={classMappings[leader?.completionStatus]}>
                          {statusMappings[leader?.completionStatus]}
                        </span>
                      </div>
                      {/* <div
                        className="widget-grid-item"
                        style={{ justifyContent: 'unset', padding: '.5rem 0.25rem' }}
                      >
                        {governanceCycleEscalationStatus(leader?.escalationStatus)}
                      </div> */}
                      {/* <div
                        className="widget-grid-item"
                        style={{ padding: '0 0.5rem', justifyContent: 'unset' }}
                      >
                        {(leader?.completionStatus == 'COMPLETED' || leader?.completionStatus == 'NOT_APPLICABLE' || leader?.isUpdateWizStatus) && (
                          // <Button
                          //   onClick={() => {
                          //     setTeamLeader(leader)
                          //     setIsGovernanceStatusModalOpen(true)
                          //   }}
                          // >
                          //   {t("governanceCard.view")}
                          //   <br/>

                          //    {t("governanceCard.objectives")}
                          // </Button>
                          <span className='view_all' onClick={() => {
                            setTeamLeader(leader)
                            setIsGovernanceStatusModalOpen(true)
                          }}>
                            {t("governanceCard.view")} {t("governanceCard.objectives")}

                          </span>
                        )}
                      </div> */}

                      {/* {location === 'Home' && (
                        <div className="widget-grid-item" style={{ padding: '0px 0.5rem', justifyContent: 'unset' }}>
                          <Button
                            onClick={() => {
                              setTeamLeader(leader)
                              setIsSuggestObjectiveModalOpen(true)
                            }}
                            style={{ minWidth: '40rem' }}
                          >
                            {t("governanceCard.suggest")}
                            <br/>
                     
                            {t("governanceCard.objective")}
                          </Button>
                        </div>
                      )} */}
                      {/* {location === 'Home' && (
                        <div className="widget-grid-item" style={{ justifyContent: 'unset' }}>
                          <Button
                            onClick={() => {
                              setTeamLeader(leader)
                              setIsBlockerFormModalOpen(true)
                            }}
                            style={{ minWidth: '40rem' }}
                          >
                           {t("governanceCard.add")}
                           <br/>
                          
                           {t("governanceCard.blocker")}
                          </Button>
                        </div>
                      )} */}
                      {location === 'Home' && isF2wTl === false && (
                        <div className="widget-grid-item" style={{ justifyContent: 'unset' }}>
                          {/* <Button
                            onClick={() => {
                              setTeamLeader(leader)
                              const teamLeaderId = leader?.userId
                              navigate(`/${routes.capacity}/${capacitySubRoutes.objectivesTeamMapping}`, { state: { teamLeaderId } });
                            }}
                            style={{ minWidth: '40rem' }}
                          >

                            {t("governanceCard.edit")}
                            <br />

                            {t("governanceCard.capacity")}


                          </Button> */}
                          <span
                            className="view_all"
                            onClick={() => {
                              setTeamLeader(leader)
                              const teamLeaderId = leader?.userId
                              navigate(
                                `/${routes.capacity}/${capacitySubRoutes.objectivesTeamMapping}`,
                                { state: { teamLeaderId: teamLeaderId, isObjectivesTab: true } },
                              )
                            }}
                          >
                            {t('governanceCard.edit')} {t('governanceCard.capacity')}
                          </span>
                        </div>
                      )}
                      {location === 'Home' && isF2wTl === true && <div></div>}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          )}
          {governanceStatusData?.teamLeaderStatuses?.length === 0 && (
            <div>{t('adminDashboard.noTLWizard')}</div>
          )}
        </div>
      </ConditionalWrapper>
    </>
  )
}

export default GovernanceStatus

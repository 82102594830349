import React from 'react'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/core.css'
import { useTranslation } from 'react-i18next'

function SubactivityDropdownMenu(props) {
  const { t } = useTranslation(['Kanban'])

  return (
    <div style={{ textAlign: 'center' }}>
      <Menu
        menuClassName="obj-map-menu"
        menuButton={
          <MenuButton
            style={{
              backgroundColor: props.backgroundColor ?? 'white',
              border: 'none',
              padding: '0.1rem 0 0 0',
            }}
          >
            <span
              style={{ alignSelf: 'center', transform: 'rotate(90deg)' }}
              class="material-symbols-outlined icon-size"
            >
              more_vert
            </span>
          </MenuButton>
        }
        portal={true}
      >
        <div>
          {
            <MenuItem
              onClick={() => {
                props.deleteSubActivity()
              }}
            >
              {t('dropdown.delete')}
            </MenuItem>
          }

          {
            <MenuItem
              onClick={() => {
                props.editDatesSubactivity()
              }}
            >
              {t('dropdown.editDates')}
            </MenuItem>
          }
        </div>
      </Menu>
    </div>
  )
}

export default SubactivityDropdownMenu

import React, { useState, useEffect } from 'react'
import { Formik, Field, Form } from 'formik'

import BasicShema from './schemas/index'
import SelectField from '../../components/CustomMultipleSelect/customSelect'
import ApplicationLayout from '../../components/ApplicationLayout'
import Flow from './Flow'
import SearchIcon from '../../assets/header-search.svg'
import { MarkerType } from 'reactflow'

import {
  getTeamLeaderList,
  getTeamFlow,
  updateReceivedWizard,
} from '../../api/services/employee.services'
import InPageLoader from '../../components/InPageLoader/index'
import './style.css'

export default function App({ show, areaLeader, closeGTeamFlowkView }) {
  const [formValues, setFormValues] = useState({
    assign: areaLeader[0],
  })
  // const [teamLeaderList, setTeamLeaderList] = useState([])

  const [nodes, setNodes] = useState([])
  const [edges, setEdges] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getTeamLeadFlow(areaLeader[0].eId)
  }, [])

  const getTeamLeadFlow = async (areaLeaderId) => {
    getTeamFlowEmployee(areaLeaderId)
  }

  async function onClickRecivedWizard(data) {
    const postObj = {
      eId: data.eId,
      showWizards: !data.isRecivedWizard,
      eLeaderId: data?.eLeaderId,
    }
    setIsLoading(true)
    const result = await updateReceivedWizard(postObj)
    setIsLoading(false)
    if (result) {
      setFlowData(result.employees, result.delegates, data?.eLeaderId)
    }
  }

  async function getTeamFlowEmployee(eId) {
    setNodes([])
    setEdges([])
    setIsLoading(true)
    const result = await getTeamFlow(eId)
    setIsLoading(false)
    if (result) {
      setFlowData(result.employees, result.delegates, eId)
    }
  }

  function setFlowData(nodes, otherEdges, eLeaderId) {
    const finalNode = []
    const finalEdges = []
    const repoter = nodes

    const lenghtTotal = repoter.length
    let midLength = repoter.length / 2

    for (let x = 0; repoter.length > x; x++) {
      const mainObj = repoter[x]

      let positionX = 0
      let positionY = 0

      if (x < midLength) {
        positionX = 100 * x
        positionY = 100 * x
      } else {
        positionX = 150 * x - 100
        positionY = 100 * (lenghtTotal - x)
      }

      const dataNode = {
        id: `${mainObj?.eId}`,
        data: {
          label: mainObj.name,
          handleClick: onClickRecivedWizard,
          isRecivedWizard: mainObj?.showWizards,
          eId: mainObj?.eId,
          eLeaderId: eLeaderId,
        },
        type: 'customNode',
        position: { x: positionX, y: positionY },
      }

      finalNode.push(dataNode)
      // const dataEdges = {
      //   id: `e1-${x}`,
      //   source: `${nodes?.id}`,
      //   target: `${mainObj?.id}`,
      //   type: "step",
      //   markerEnd: {
      //     type: MarkerType.ArrowClosed,
      //     width: 20,
      //     height: 20,
      //     color: '#E91B51',
      //   },
      // };
      // // finalEdges.push(dataEdges);
    }

    if (otherEdges && otherEdges.length) {
      for (let y = 0; otherEdges.length > y; y++) {
        const mainObj = otherEdges[y]
        const dataEdges = {
          id: `e2-${mainObj.delegateTlId}-${mainObj.juniorTlId}`,
          source: `${mainObj.delegateTlId}`,
          target: `${mainObj.juniorTlId}`,
          type: 'buttonedge',
          // type: "step",

          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: '#E91B51',
          },
        }
        finalEdges.push(dataEdges)
      }
    }

    setNodes(finalNode)
    setEdges(finalEdges)
  }

  return (
    <>
      {show === false ? '' : <ApplicationLayout></ApplicationLayout>}
      <div>
        {isLoading ? (
          <InPageLoader />
        ) : (
          <>
            <Formik
              initialValues={formValues}
              validationSchema={BasicShema}
              onSubmit={getTeamLeadFlow}
              enableReinitialize
            >
              {({ values, errors, touched, resetForm, setFieldValue, validateForm }) => (
                <Form className="objective-form">
                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <div
                      className="column-input-objective"
                      style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                    >
                      <div className="row-kanban-inputs">
                        <div
                          className="form-field action-field form-field action-field--kanban"
                          style={{ display: 'flex', flexDirection: 'row' }}
                        >
                          <div
                            className="select-search"
                            style={{ marginRight: 'auto', marginLeft: '3rem' }}
                          >
                            <div className="custom-select-wrapper-team">
                              <Field {...SelectField} name="assign">
                                {({ field }) => (
                                  <SelectField
                                    value={formValues.assign}
                                    field={field}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.eId}
                                    options={areaLeader}
                                    isMulti={false}
                                    isClearable={false}
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                          <div
                            class="fitted-button red"
                            onClick={(e) => closeGTeamFlowkView()}
                            style={{ marginLeft: 'auto', marginRight: '5rem' }}
                          >
                            <span class="material-symbols-outlined icon-size">close</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="add" style={{ width: '100%' }}>
              {nodes.length > 0 ? <Flow initialNodes={nodes} initialEdges={edges} /> : ''}
            </div>
          </>
        )}
      </div>
    </>
  )
}

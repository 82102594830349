import React from 'react'
import edit from '../../../../assets/edit.svg'
import deleteCross from '../../../../assets/delete-cross.svg'
import plus from '../../../../assets/plus.svg'
import exclaim from '../../../../assets/exclaim.svg'
import { useAreaLeaderContext } from '../../context/areaLeaderContext'
import AddEditObjectiveModal from './components/AddEditObjectiveModal'
import { useTranslation } from 'react-i18next'

import './styles.css'
import AddMultipleObjectivesModal from './components/AddMultipleObjectivesModal'
import { getCompanyConfigSettings } from '../../../../utils/auth'

const ObjectivesStepAreaLeader = () => {
  const { state, dispatch } = useAreaLeaderContext()
  const { t } = useTranslation(['AreaLeaderWizard'])

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
  }, [])

  return (
    <>
      <AddEditObjectiveModal />
      <AddMultipleObjectivesModal></AddMultipleObjectivesModal>

      <div className="wizard-step">
        <h2 className="wizard-step__title">{t('objectiveStep.title')}</h2>
        <div className="wizard-step__overview">
          <div className="wizard-step__overview__paragraph">
            <span>{t('objectiveStep.subtitle1')}</span>
          </div>
        </div>

        <div className="wizard-step__main-content-container">
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem', width: '100%' }}>
            {state.data.objectives ? (
              state.data.objectives.map((objective, index) => (
                <div className="objective-row" key={`obj-${index}`} style={{ gap: '0.5rem' }}>
                  <div className="statement-wrapper" style={{ display: 'flex' }}>
                    {!objective.isValid ? (
                      <img src={exclaim} className="warning" alt="Warning" />
                    ) : (
                      <div className="warning"></div>
                    )}

                    <div
                      className={`statement-container ${
                        objective.isValid ? 'statement-valid' : 'statement--invalid'
                      }`}
                      style={{ overflowY: 'auto' }}
                    >
                      <span data-testid={`obj-statement-${index}`} className="objective-statement">
                        {objective.statement}
                      </span>
                    </div>
                  </div>

                  <div
                    className="edit-button"
                    onClick={() => {
                      dispatch({ type: 'OPEN_OBJ_FORM_MODAL', editObjectiveIndex: index })
                    }}
                  >
                    {/* <img className="edit-btn" src={edit} alt="Click to edit symbol" /> */}
                    <div class="fitted-button blue tooltip">
                      <span class="material-symbols-outlined">edit</span>
                      {/* <span class="tooltiptext"> {t('Common:tooltip.edit')} </span> */}
                    </div>
                  </div>
                  <div
                    className="delete-button"
                    // onClick={() => {
                    //   dispatch({ type: 'DELETE_OBJECTIVE', localeId: objective.localeId })
                    // }}
                  >
                    {/* <img className="delete-btn" src={deleteCross} alt="Click to delete symbol" /> */}
                    <div
                      class="fitted-button red tooltip"
                      onClick={() => {
                        dispatch({ type: 'DELETE_OBJECTIVE', localeId: objective.localeId })
                      }}
                    >
                      <span class="material-symbols-outlined">close</span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <b className="no-objectives-text">You don't currently have any objectives...</b>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', columnGap: '3rem' }}>
            <div
              className="sleek-button sleek-full-blue"
              onClick={() => {
                dispatch({ type: 'OPEN_OBJ_FORM_MODAL', editObjectiveIndex: null })
              }}
            >
              <span class="material-symbols-outlined icon-size-l">add_circle</span>
              <span>{t('objectiveStep.addObjective')}</span>
            </div>
            <div
              className="sleek-button sleek-full-blue"
              onClick={() => {
                dispatch({ type: 'OPEN_MULTIPLE_OBJ_FORM_MODAL' })
              }}
            >
              <span class="material-symbols-outlined icon-size-l">add_circle</span>
              <span>{t('objectiveStep.addMultipleObjectives')}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ObjectivesStepAreaLeader

import React from 'react'
import { useTeamLeaderContext } from '../../../../context/teamLeaderContext'
import target from '../../../../../../assets/target.svg'
import checkmark from '../../../../../../assets/target.svg'
import ObjectiveForm from './ObjectiveForm'
import ObjectiveExampleText from '../../../../../../components/ObjectiveExampleText'
import Modal from '../../../../../../components/Modal'
import { useTranslation } from 'react-i18next'

import './styles.css'

const AddEditObjectiveModal = () => {
  const { t } = useTranslation(['TeamLeaderWizard'])
  const { state, dispatch } = useTeamLeaderContext()

  const [resetForm, setResetForm] = React.useState(null)

  const closeModal = () => {
    dispatch({ type: 'CLOSE_OBJ_FORM_MODAL' })
    resetForm()
  }

  return (
    <>
      {state.isObjFormModalOpen === true && (
        <Modal title={t('addEditObjectiveTLWModal.addANewObjective')} closeModal={closeModal}>
          <ObjectiveExampleText />
          <ObjectiveForm setResetForm={setResetForm} />
        </Modal>
      )}
    </>
  )
}

export default AddEditObjectiveModal

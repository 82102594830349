import React, { useState } from 'react'
import { useBrainstorm } from '../Recorder/BrainstormContext'
//import odinImg from '../../../assets/odin2.png'
import troyImg from '../../../assets/troy_circle.png'

const CircleLoader = ({ isThinking }) => {
  const { state, dispatch } = useBrainstorm()

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '6rem',
          height: '6rem',
          position: 'relative',
        }}
      >
        <svg
          style={{
            animation: state.isOdinPendingSpeaking ? 'spin 1s linear infinite' : 'none',
            width: '100%',
            height: '100%',
          }}
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient id="golden-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#FFD700" />
              <stop offset="50%" stopColor="#FFA500" />
              <stop offset="100%" stopColor="#FFD700" />
            </linearGradient>
          </defs>
          <circle
            cx="50"
            cy="50"
            r="45"
            stroke={
              state.isOdinPendingSpeaking
                ? state.isMuted
                  ? 'red'
                  : 'url(#golden-gradient)'
                : 'white'
            }
            strokeWidth="8"
            fill="none"
            strokeLinecap="round"
          />
        </svg>
        <div
          style={{
            position: 'absolute',
            inset: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#D97706"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
            <polyline points="14 2 14 8 20 8" />
          </svg> */}

          <img
            src={troyImg}
            alt=""
            onClick={() => {
              if (isThinking || !state.isBotAudioPlaying) return
              dispatch({ type: 'SET_IS_MUTED', isMuted: true })
            }}
            style={{
              width: '75px',
              height: 'auto',
              cursor: 'pointer',
            }}
          />
        </div>
      </div>
      {/* <button 
        onClick={toggleCircle}
        style={{
          padding: '0.5rem 1rem',
          backgroundColor: '#4B5563',
          color: 'white',
          border: 'none',
          borderRadius: '0.25rem',
          cursor: 'pointer'
        }}
      >
        Toggle Circle
      </button> */}
      <style jsx>{`
        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
  )
}

export default CircleLoader

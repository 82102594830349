import React from 'react'
import Modal from '../Modal'
import { useTranslation } from 'react-i18next'
import { hasRole } from '../../utils/auth'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../utils/routes'

const ChangesReportModal = ({ closeModal, reports, buildMutatedText = () => {} }) => {
  const { t } = useTranslation(['Common'])
  const navigate = useNavigate()

  return (
    <Modal
      closeModal={closeModal}
      width="60%"
      modalContainerStyle={{ width: '90%' }}
      showCard={true}
      title={t('changesReportModal.title')}
      titleStyle={{ color: 'rgb(91,132,203)', textAlign: 'left' }}
      modalTitleContainerStyle={{ marginBottom: '0rem', justifyContent: 'flex-start' }}
    >
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <b>{t('changesReportModal.subTitle')}</b>
        </div>
        <div style={{ marginTop: '2rem' }}>
          {reports.map((report) => {
            const mutatedText = buildMutatedText(
              report.action,
              report.highlightStartIndex,
              report.highlightEndIndex,
              report.multiHighlightStartIndex,
              report.multiHighlightEndIndex,
            )
            return (
              <div
                key={report.actionId}
                style={{
                  backgroundColor: report.isNew ? '#c6d4ed' : 'rgb(242, 242, 242)',
                  color: 'black',
                  borderRadius: '0.3rem',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginTop: '0.5rem',
                  width: '90%',
                  padding: '0.5rem',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (!hasRole('team')) {
                    console.log(report.type)
                    let subroute =
                      report.type === 'ACTION_REPORT'
                        ? 'Actions'
                        : report.type === 'KPI_REPORT'
                          ? 'KPIs'
                          : 'MeetingTopics'
                    navigate(`/${routes.changesReport}/${subroute}`)
                  }
                  closeModal()
                }}
              >
                <span
                  style={{
                    textAlign: 'left',
                    display: 'block',
                  }}
                >
                  {mutatedText}
                </span>
                <span
                  style={{
                    textAlign: 'right',
                    display: 'block',
                  }}
                >
                  {report.date}
                </span>
              </div>
            )
          })}
        </div>
      </>
    </Modal>
  )
}

export default ChangesReportModal

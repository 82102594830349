import React from 'react'
import _ from 'lodash'
import useTeamCapacityData from '../../../api/query/getTeamCapacity'
import useGetObjectivesByOwnerId from '../../../api/query/getObjectivesByOwnerId'
import useGetObjectiveMilestones from '../../../api/query/useGetObjectiveMilestones'
import useEmpObjMapping from '../../../api/query/useEmpObjMapping'
import { useUpdateContext } from '../context/updateContext'

export default function useUpdateData(tlId) {
  const [objectiveInputKey, setObjectiveInputKey] = React.useState(0)
  const { state, dispatch } = useUpdateContext()
  const {
    data: employeeData,
    isLoading: isEmployeeLoading,
    error: employeeError,
  } = useTeamCapacityData()

  const {
    data: objectiveData,
    isLoading: isObjectivesLoading,
    error: objectivesError,
  } = useGetObjectivesByOwnerId()

  const {
    data: empObjMappingData,
    isLoading: isEmpObjMappingLoading,
    error: empObjMappingError,
  } = useEmpObjMapping()

  const {
    data: milestoneData,
    isLoading: isMilestonesLoading,
    error: milestonesError,
  } = useGetObjectiveMilestones(objectiveData?.objectives?.map((obj) => parseInt(obj.id)) ?? [])

  React.useEffect(() => {
    if (
      objectiveData === null ||
      objectiveData === undefined ||
      empObjMappingData == null ||
      empObjMappingData == undefined ||
      employeeData == null ||
      employeeData == undefined
    ) {
      return
    }

    // Explicitly update the textboxes because they use defaultValue and that is not updated on
    // state change. However, refreshing the key for the <tr> causes a rerender.
    setObjectiveInputKey(objectiveInputKey + 1)

    let objectives = [...objectiveData.objectives]

    for (let { eId, objId, allocation, eName } of empObjMappingData.objIcMappings) {
      for (let obj of objectives) {
        if (parseInt(obj.id) === objId) {
          const teamEntry = {
            eId: eId,
            value: allocation,
            name: eName,
            canDelete: employeeData?.employees?.some((emp) => emp.eId === eId),
          }

          if (obj.team) {
            obj.team.push(teamEntry)
          } else {
            obj.team = [teamEntry]
          }
        }
      }
    }

    dispatch({ type: 'UPDATE_OBJECTIVES', objectives: objectives })
  }, [objectiveData, empObjMappingData, employeeData])

  const isLoading =
    isEmployeeLoading || isObjectivesLoading || isMilestonesLoading || isEmpObjMappingLoading
  const error = employeeError || objectivesError || empObjMappingError

  // Comment this out because we no longer want to retrieve the state data from the db.

  // if (!isLoading && existingData && existingData?.tl_wizard_state.length >0) {
  //   const tlWizData = existingData?.tl_wizard_state
  //   const parsedExistingData = tlWizData[0][1]?.data
  //   const  existingStep = tlWizData[0][0]
  //   const employees = _.uniqBy(parsedExistingData?.employees?.concat(employeeData.employees), 'email')

  //   return {
  //     isLoading: isLoading,
  //     error: error,
  //     data: {
  //       employees: employees ?? employeeData.employees,
  //       existingStep: existingStep,
  //       objectives: parsedExistingData?.objectives ?? [],
  //       blockers: parsedExistingData?.blockers ?? [],
  //       milestones: parsedExistingData?.milestones ?? [],
  //       empObjMappingData : parsedExistingData?.objIcMappings ??[]
  //     },
  //   }
  // }
  return {
    isLoading: isLoading,
    error: error,
    data: {
      existingStep: 0, //default step
      employees: employeeData?.employees ?? [],
      objectives: objectiveData?.objectives ?? [],
      empObjMappingData: empObjMappingData?.objIcMappings ?? [],
    },
  }
}

import React, { useState } from 'react'
import { Formik, Field, Form } from 'formik'
import VerbDataList from '../../../../../../utils/VerbDataList'
import { useAreaLeaderContext } from '../../../../context/areaLeaderContext'
import './styles.css'
import ObjectiveFormSchema from './ObjectiveFormSchema'
import useValidateGoal from '../../../../../../api/mutation/useValidateGoal'
import ModalSaveButton, {
  ModalSaveButtonFitted,
} from '../../../../../../components/ModalSubmitButton'
import { useTranslation } from 'react-i18next'

const ObjectiveForm = ({ setResetForm }) => {
  const { t } = useTranslation(['AreaLeaderWizard'])
  const { state, dispatch } = useAreaLeaderContext()
  const { mutateAsync, isLoading } = useValidateGoal()

  const editObjective = React.useMemo(() => {
    if (state.editObjectiveIndex !== null) {
      return state.data.objectives[state.editObjectiveIndex]
    } else {
      return null
    }
  }, [state.editObjectiveIndex, state.data.objectives])

  const handleSubmit = async (values, resetForm) => {
    values.statement = values.statement.charAt(0).toUpperCase() + values.statement.slice(1)
    const result = await mutateAsync(`${values.statement}`)

    if (editObjective !== null) {
      const objectiveToEdit = {
        ...editObjective,
        statement: values.statement,
        isValid: result.is_valid,
      }

      dispatch({
        type: 'UPDATE_OBJECTIVE',
        objective: objectiveToEdit,
        objectiveIndex: state.editObjectiveIndex,
      })
    } else {
      state.lastObjLocaleId++
      dispatch({
        type: 'ADD_GOAL',
        statement: values.statement,
        isValid: result.is_valid,
        localeId: state.lastObjLocaleId,
      })
    }
    resetForm()
  }

  const [verbOptions, setVerbOptions] = useState([])

  const handleVerbSuggestions = ({ target }) => {
    if (target.value) {
      const filteredOptions = VerbDataList.filter((option) =>
        option.toLowerCase().startsWith(target.value.toLowerCase()),
      )
      setVerbOptions(filteredOptions)
    } else {
      setVerbOptions([])
    }
  }

  const formikRef = React.useRef()

  React.useEffect(() => {
    if (formikRef.current !== undefined) {
      setResetForm(() => formikRef.current.resetForm)
    }
  }, [formikRef])

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={{
        statement: editObjective?.statement ?? '',
      }}
      validationSchema={ObjectiveFormSchema}
      onSubmit={(values, { resetForm }) => {
        handleSubmit(values, resetForm)
      }}
    >
      {({ errors, touched, resetForm }) => (
        <Form className="objective-form">
          <div>
            <div className="form-field action-field">
              <label>{t('addEditObjectiveTLWModal.statement')}</label>
              <Field
                list="obj_verb_list"
                id="statement"
                name="statement"
                placeholder="Start typing..."
                onKeyUp={handleVerbSuggestions}
                onClick={handleVerbSuggestions}
                autoComplete="off"
              />
              {errors.statement && touched.statement && (
                <div className="validation-error">{errors.statement}</div>
              )}
              <datalist id="obj_verb_list">
                {verbOptions.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </datalist>
            </div>
          </div>
          <div
            className="modal-dual-btn"
            style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}
          >
            <ModalSaveButtonFitted
              onClick={() => {
                resetForm()
                dispatch({ type: 'CLOSE_OBJ_FORM_MODAL' })
              }}
              tag={t('Common:commonButtons.cancel')}
            />
            <ModalSaveButtonFitted isLoading={isLoading} />
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ObjectiveForm

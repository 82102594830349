import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getCsuiteLeaderForCeoData = async (ceoLeaderId) => {
  const queryString = `/csuite_leaders_for_ceo/${ceoLeaderId}`
  const { data } = await axios.get(`${BASE_URL}${queryString}`)
  return data
}

export default function useCsuiteLeadersForCeo(ceoLeaderId = null, newLength = 0) {
  if (ceoLeaderId === null || ceoLeaderId === 0 || ceoLeaderId === '000000') {
    ceoLeaderId = 0
  }

  return useQuery(
    ['ceo-employees', ceoLeaderId, newLength],
    () => getCsuiteLeaderForCeoData(ceoLeaderId),
    {
      refetchOnWindowFocus: false,
    },
  )
}

import { getHttpRequest, postHttpRequest } from '../query/dynamicAPI'
import {
  GOAL_CUSTOM_TEMPLATE_GET,
  GOAL_CUSTOM_TEMPLATE_ADD,
  API_ERROR_MESSAGE,
  LIST_ITEM_GET,
  GOAL_CUSTOM_TEMPLATE_EDIT,
} from '../../api/constant/route'
import Toast from '../../common/toast'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

export async function getGoalCustomTemplate(objectiveId) {
  try {
    const data = await getHttpRequest(`${GOAL_CUSTOM_TEMPLATE_GET}/${objectiveId}`)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

const getListItemsGet = async () => {
  const { data } = await axios.get(`${BASE_URL}/list_items_get`)
  return data
}

export default function useGetListItemsGet() {
  return useQuery(['custom-template-lists'], () => getListItemsGet(), {
    refetchOnWindowFocus: false,
  })
}

export async function addGoalCustomTemplate(postData) {
  try {
    const data = await postHttpRequest(`${GOAL_CUSTOM_TEMPLATE_ADD}`, postData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function editGoalCustomTemplate(postData) {
  try {
    const data = await postHttpRequest(`${GOAL_CUSTOM_TEMPLATE_EDIT}`, postData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

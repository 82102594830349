import React from 'react'

const SpeakingAnimationCircle = ({ size = 50, color = 'orange' }) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: '50%',
        backgroundColor: color,
        animation: 'pulse 2s infinite',
      }}
    >
      <style>{`
        @keyframes pulse {
          0%, 80% { transform: scale(1); }
          15% { transform: scale(1.5); }
          30% { transform: scale(1); }
          45% { transform: scale(1.25); }
          60% { transform: scale(1); }
          75% { transform: scale(1.4); }
        }
      `}</style>
    </div>
  )
}

export default SpeakingAnimationCircle

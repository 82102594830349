import React from 'react'
import WizardQuestion from '../../../../components/WizardQuestion'
import { useTranslation } from 'react-i18next'
import { getTeamOverview } from '../../../../api/services/kpi.services'
import { useKPIContext } from '../../context/kpiContext'

const KPITeamOverview = () => {
  const { t } = useTranslation(['KPIWizard', 'Common'])

  const [teamOverviewAnswer, setTeamOverviewAnswer] = React.useState(null)
  const { state, dispatch } = useKPIContext()

  React.useEffect(() => {
    if (state.answers.teamOverview !== null) {
      setTeamOverviewAnswer(state.answers.teamOverview)
    }
  }, [])

  React.useEffect(() => {
    dispatch({ type: 'SET_TEAM_OVERVIEW', value: teamOverviewAnswer })
  }, [teamOverviewAnswer])

  return (
    <>
      <WizardQuestion
        question={t('teamOverviewQuestion')}
        answer={teamOverviewAnswer}
        setAnswer={setTeamOverviewAnswer}
        loadGetFunction={getTeamOverview}
      />
    </>
  )
}
export default KPITeamOverview

import React from 'react'
import Modal from '../Modal'
import { getHttpRequest, postHttpRequest } from '../../api/query/dynamicAPI'
import InPageLoader from '../InPageLoader'
import { useTranslation } from 'react-i18next'
import TextArea from 'rc-textarea'
import AssigneeActivity from '../../pages/MeetingMultiView/components/MeetingTopics/common/assigneeActivity'
import Toast from '../../common/toast'
import { shortenName } from '../../utils/general'
import Swal from 'sweetalert2'
import EditMeetingModal from '../../pages/MeetingMultiView/components/MeetingTopics/common/EditMeeingDetailModal'

const ManageMeetingModal = ({ closeModal }) => {
  const [meetings, setMeetings] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [inputDescription, setInputDescription] = React.useState('')
  const [isAssignmentModalOpen, setIsAssignmentModalOpen] = React.useState(false)
  const [assigneeList, setAssigneeList] = React.useState([])
  const [selectedMeetingId, setSelectedMeetingId] = React.useState(null)
  const [editMeetingModalOpen, setEditMeetingModalOpen] = React.useState(false)
  const [selectedMeeting, setSelectedMeeting] = React.useState(null)

  const { t } = useTranslation(['MeetingHub', 'Common'])

  React.useEffect(() => {
    fetchMeetings()
  }, [])

  const fetchMeetings = async () => {
    setIsLoading(true)
    try {
      let response = await getHttpRequest('/get_meetings_for_owner')
      setMeetings(response.personalMeetings)
    } catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
    setIsLoading(false)
  }

  const addNewMeeting = async (meetingName) => {
    console.log(meetingName)
    try {
      await postHttpRequest('/add_new_meeting', {
        meetingName,
      })
      loadAgain()
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const loadAgain = () => {
    setSelectedMeetingId(null)
    fetchMeetings()
  }

  const deleteMeeting = async (meetingId) => {
    try {
      const result = await Swal.fire({
        title: t('Common:modalMessages.areYouSureDeleteThis'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: t('Common:commonButtons.yes'),
        cancelButtonText: t('Common:commonButtons.no'),
      })

      if (result.isConfirmed) {
        await postHttpRequest(`/edit_meeting/${meetingId}`, {
          isDelete: true,
        })
        loadAgain()
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  async function updateMeetingDescription(value, index) {
    const dataArray = [...meetings]
    const meeting = dataArray[index]

    meeting.meetingName = value

    dataArray[index] = meeting
    setMeetings(dataArray)
  }

  const saveMeeting = async (meeting) => {
    if (meeting.meetingName === '') {
      return
    }

    try {
      let response = await postHttpRequest(`/edit_meeting/${meeting.meetingId}`, {
        meetingName: meeting.meetingName,
        isDelete: false,
      })
      Toast.fire({
        icon: 'success',
        title: t('Common:modalMessages.updatedSuccessfully'),
      })
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  return (
    <Modal
      closeModal={closeModal}
      title={t('meetingManagement.title')}
      width={'45%'}
      height={'60%'}
      titleStyle={{ marginRight: 'auto', color: '#5780ca' }}
      modalContainerStyle={{ width: '80%' }}
    >
      {isAssignmentModalOpen && selectedMeetingId && (
        <AssigneeActivity
          objectiveId={-1}
          setModelView={setIsAssignmentModalOpen}
          view={isAssignmentModalOpen}
          assigneeList={assigneeList}
          loadAgain={loadAgain}
          meetingId={selectedMeetingId}
        />
      )}

      {editMeetingModalOpen && selectedMeetingId && (
        <EditMeetingModal
          objectiveId={-1}
          setModelView={setEditMeetingModalOpen}
          view={isAssignmentModalOpen}
          assigneeList={assigneeList}
          loadAgain={loadAgain}
          meetingId={selectedMeetingId}
          meeting={selectedMeeting}
        />
      )}

      {isLoading && <InPageLoader inComponent={true} />}
      {!isLoading && (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              // justifyContent: 'space-between',
              padding: '10px',
              boxShadow: 'rgb(204, 204, 204) 0px 0px 6px',
              borderRadius: '4px',
              backgroundColor: 'white',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <TextArea
                  rows={1}
                  type="text"
                  placeholder={t('Common:formPlaceHolder.typeToAdd')}
                  style={{
                    width: '97%',
                    resize: 'none',
                    border: '1px solid rgb(238, 238, 238)',
                    backgroundColor: '#f1f0f0',
                    borderRadius: '2.5px',
                    fontFamily: "'Open Sans', 'Arial', 'Helvetica', 'system-ui', sans-serif",
                  }}
                  onChange={(e) => {
                    setInputDescription(e.target.value)
                  }}
                  value={inputDescription}
                  spellCheck={false}
                />
              </div>
              <div
                class="fitted-button blue"
                style={{ marginTop: 'auto', marginBottom: 'auto', padding: '0rem' }}
                onClick={() => {
                  if (inputDescription !== '') {
                    addNewMeeting(inputDescription)
                    setInputDescription('')
                  }
                }}
              >
                <span class="material-symbols-outlined">add</span>
              </div>
            </div>
          </div>
          {meetings.length > 0 ? (
            <div style={{ marginTop: '2rem', width: '100%' }}>
              {meetings.map((meeting, index) => (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                    }}
                    key={index}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'left',
                        width: '80%',
                        wordBreak: 'break-word',
                      }}
                    >
                      <TextArea
                        style={{
                          border: 'none',
                          fontWeight: '600',
                          fontFamily: '"Open Sans", "Arial", "Helvetica", "system-ui", sans-serif',
                          height: '17px',
                          fontSize: '15px',
                        }}
                        autoSize
                        className="body_bottom_text"
                        value={meeting.meetingName}
                        onChange={(e) => updateMeetingDescription(e.target.value, index)}
                        onBlur={() => saveMeeting(meeting)}
                      ></TextArea>
                      {/* {meeting.meetingName} */}

                      <div
                        style={{
                          margin: 'auto 0',
                          color: 'grey',
                          fontWeight: 'normal',
                          fontSize: '14px',
                        }}
                      >
                        {meeting?.meetingManagers
                          ?.sort((a, b) => a.name.localeCompare(b.name))
                          .map((manager, managerIndex) => (
                            <React.Fragment key={managerIndex}>
                              <i>{shortenName(manager.name)}</i>
                              {managerIndex !== meeting?.meetingManagers.length - 1 && (
                                <span>, </span>
                              )}
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto' }}>
                      <div
                        style={{ marginTop: 'auto', marginBottom: 'auto' }}
                        class="fitted-button blue tooltip"
                        onClick={(e) => {
                          setAssigneeList(meeting.meetingManagers)
                          setIsAssignmentModalOpen(true)
                          console.log(meeting.meetingId)
                          setSelectedMeetingId(meeting.meetingId)
                        }}
                      >
                        <span
                          class="material-symbols-outlined icon-size"
                          style={{ fontSize: '15px' }}
                        >
                          person_add
                        </span>
                        <span class="tooltiptext" style={{ fontSize: '16px' }}>
                          {' '}
                          {t('Common:tooltip.assignToOthers')}{' '}
                        </span>
                      </div>
                      <div
                        style={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: '0.1rem' }}
                        class="fitted-button blue tooltip"
                        onClick={(e) => {
                          console.log(meeting)
                          setSelectedMeeting(meeting)
                          setSelectedMeetingId(meeting.meetingId)
                          setEditMeetingModalOpen(true)
                        }}
                      >
                        <span
                          class="material-symbols-outlined icon-size"
                          style={{ fontSize: '15px' }}
                        >
                          edit
                        </span>
                        <span class="tooltiptext" style={{ fontSize: '16px' }}>
                          {' '}
                          {t('Common:tooltip.editMeetingDetail')}{' '}
                        </span>
                      </div>
                      <div
                        class="fitted-button red icon-size"
                        style={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: '0.1rem' }}
                        onClick={(e) => deleteMeeting(meeting.meetingId)}
                      >
                        <span
                          class="material-symbols-outlined icon-size"
                          style={{ fontSize: '15px' }}
                        >
                          close
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          ) : (
            <div style={{ marginTop: '2rem' }}>{t('meetingManagement.noMeetings')}</div>
          )}
        </>
      )}
    </Modal>
  )
}

export default ManageMeetingModal

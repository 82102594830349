const errorKpiValidation = (tempSuggestedKpisToSave = []) => {
  let atLeastOneError = false
  let suggestedKpisToSaveWithErrors = []

  for (let kpi of tempSuggestedKpisToSave) {
    let kpiWithErrors = { ...kpi, errorFields: {} } // Object to store fields with errors

    if (kpi?.name) {
      kpiWithErrors.errorFields.name = false
    } else {
      kpiWithErrors.errorFields.name = true
      atLeastOneError = true
    }

    if (kpi?.kpiType != null) {
      kpiWithErrors.errorFields.kpiType = false
    } else {
      kpiWithErrors.errorFields.kpiType = true
      atLeastOneError = true
    }

    if (kpi?.kpiType !== 3) {
      if (kpi?.unit) {
        kpiWithErrors.errorFields.unit = false
      } else {
        kpiWithErrors.errorFields.unit = true
        atLeastOneError = true
      }
      if (kpi?.target) {
        kpiWithErrors.errorFields.targetDate = false
      } else {
        kpiWithErrors.errorFields.target = true
        atLeastOneError = true
      }
    }

    if (kpi?.frequency != null && kpi?.frequency !== 0) {
      kpiWithErrors.errorFields.frequency = false
    } else {
      kpiWithErrors.errorFields.frequency = true
      atLeastOneError = true
    }

    if (kpi?.targetDate) {
      kpiWithErrors.errorFields.targetDate = false
    } else {
      kpiWithErrors.errorFields.targetDate = true
      atLeastOneError = true
    }

    if (kpi?.startDate) {
      kpiWithErrors.errorFields.startDate = false
    } else {
      kpiWithErrors.errorFields.startDate = true
      atLeastOneError = true
    }

    if (kpi?.startDate && kpi?.targetDate) {
      if (new Date(kpi?.startDate) >= new Date(kpi?.targetDate)) {
        kpiWithErrors.errorFields.startDate = true
        atLeastOneError = true
      } else {
        kpiWithErrors.errorFields.startDate = false
      }
    }

    if (atLeastOneError) {
      suggestedKpisToSaveWithErrors.push(kpiWithErrors)
    }
  }
  return { atLeastOneError, suggestedKpisToSaveWithErrors }
}

export { errorKpiValidation }

import React from 'react'
import Button from '../../../../../../components/Button'
import { colors } from '../../../../../../utils/colors'
import { objectiveRowContainer, dataContainer, actionContainer } from './styles'
import edit from './../../../../../../assets/edit.svg'
import deleteCross from './../../../../../../assets/delete-cross.svg'
import CommentsModal2 from '../../../../../../components/CommentsModal2'
import CoOwnedObjectiveAllocModal from '../../../../../../components/CoOwnedObjectiveAllocModal'
import { useTranslation } from 'react-i18next'
import { getCompanyConfigSettings } from '../../../../../../utils/auth'

const ObjectiveRow = ({
  objective,
  markAsComplete,
  handleEdit,
  handleDelete,
  handleUncomplete,
  fromCompletedObjModal = false,
}) => {
  const productSettings = getCompanyConfigSettings('productSettings')
  const [commentsModalIsOpen, setCommentsModalIsOpen] = React.useState(false)
  const openCommentsModal = () => {
    setCommentsModalIsOpen(true)
  }
  const closeCommentsModal = () => {
    setCommentsModalIsOpen(false)
  }

  const [isCoOwnedObjectiveAllocModalOpen, setIsCoOwnedObjectiveAllocModalOpen] =
    React.useState(false)
  const { t } = useTranslation(['Dashboard'])

  return (
    <>
      {commentsModalIsOpen === true && objective && (
        <CommentsModal2
          objective={{ id: objective.id, statement: objective.statement }}
          handleCancel={closeCommentsModal}
          displayAllComments={true}
        />
      )}

      {isCoOwnedObjectiveAllocModalOpen === true && objective && (
        <CoOwnedObjectiveAllocModal
          objective={objective}
          isModalOpen={isCoOwnedObjectiveAllocModalOpen}
          closeModal={() => {
            setIsCoOwnedObjectiveAllocModalOpen(false)
          }}
          productSettings={productSettings}
        />
      )}

      <div style={objectiveRowContainer}>
        <div style={dataContainer}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              {productSettings &&
                objective?.initiativeId !== 0 &&
                !objective?.isGeneralObjective && (
                  <b>
                    <div style={{ display: 'flex', margin: '0.3rem' }}>
                      <div>{objective?.initiativeId.toString()?.padStart(5, '0')}</div>
                      <span style={{ paddingLeft: '0.1rem' }}> -</span>
                    </div>
                  </b>
                )}
              <b>{objective.statement}</b>
            </div>
            {objective.originalId > 0 && !objective.complete && (
              <button
                data-testid={`coOwnedObj-${objective.id}`}
                className="icon-button group-icon-button"
                style={{ width: '6rem', fontSize: '12px', marginLeft: '1rem' }}
                onClick={() => {
                  setIsCoOwnedObjectiveAllocModalOpen(true)
                }}
              >
                <span style={{ width: '6rem' }}>{t('objectiveRow.coowned')}</span>
              </button>
            )}
          </div>
          <div data-testid={`obj-${objective.statement}`}>
            {!fromCompletedObjModal && (
              <>
                {(objective.allocatedFte * 100).toFixed(0)}
                {t('objectiveRow.fTEAllocated')}
                {objective.lackingCapacity && (
                  <span>
                    {' '}
                    |{/* {parseInt(objective.missingFte*100)}% More Required */}
                    {(objective.missingFte * 100).toFixed(0)}
                    {t('objectiveRow.fTERequired')}
                  </span>
                )}
              </>
            )}
          </div>
        </div>
        <div style={actionContainer}>
          {/* Commented out until comment functionality is brought back */}
          {/* <div style={{color:'rgb(82 119 255)',cursor: 'pointer'}}  onClick={handleEdit}>
            View Comments&nbsp; 
            </div> */}
          {objective.complete && !objective.isCopy && (
            <>
              {handleUncomplete ? (
                <div
                  style={{ color: 'rgb(82 119 255)', cursor: 'pointer' }}
                  onClick={(e) => {
                    handleUncomplete()
                  }}
                >
                  {t('objectiveRow.markAsIncomplete')} &nbsp;
                </div>
              ) : (
                <div>
                  <i>{t('objectiveRow.completed')}</i>
                </div>
              )}
            </>
          )}

          {!objective.complete && (
            <>
              <div
                style={{ color: 'rgb(82 119 255)', cursor: 'pointer' }}
                onClick={(e) => {
                  openCommentsModal()
                }}
                className="hpObjEditBtn"
              >
                &nbsp;{t('common.comments')}&nbsp;
              </div>
              {!objective.isGeneralObjective && (
                <>
                  |
                  <div
                    data-testid={`objEdit-${objective.id}`}
                    style={{ color: 'rgb(82 119 255)', cursor: 'pointer' }}
                    onClick={(e) => {
                      handleEdit()
                    }}
                    className="hpObjEditBtn"
                  >
                    &nbsp;{t('common.edit')}&nbsp;
                  </div>
                  |
                  {!objective.isCopy && (
                    <>
                      <div
                        style={{ color: 'rgb(82 119 255)', cursor: 'pointer' }}
                        onClick={(e) => {
                          markAsComplete()
                        }}
                      >
                        {t('objectiveRow.markAsComplete')}&nbsp;
                      </div>
                      |
                    </>
                  )}
                  <div
                    style={{ color: '#a00', cursor: 'pointer' }}
                    onClick={(e) => {
                      handleDelete()
                    }}
                  >
                    &nbsp;{t('common.delete')}&nbsp;
                  </div>
                </>
              )}
              {objective.lackingCapacity ? (
                <div>
                  <i>{t('objectiveRow.lackingCapacityMsg')}</i>
                </div>
              ) : (
                ''
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ObjectiveRow

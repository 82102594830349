import React from 'react'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import Toast from '../../common/toast'
import { useTranslation } from 'react-i18next'
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Legend,
  Label,
} from 'recharts'
import ApplicationLayout from '../../components/ApplicationLayout'
import InPageLoader from '../../components/InPageLoader'

const AgedItemsRanking = () => {
  const { t } = useTranslation(['MeetingHub'])
  const [data, setData] = React.useState([])
  const [teamAverage, setTeamAverage] = React.useState(0)
  const [recommendedAverage, setRecommendedAverage] = React.useState(0)
  const [selectedViewMode, setSelectedViewMode] = React.useState(1)
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    getAgedItemsRanking(selectedViewMode)
  }, [selectedViewMode])

  const updateData = (response) => {
    if (response) {
      setRecommendedAverage(response.recommendedAverage)
      setTeamAverage(parseFloat((+response.teamAverage).toFixed(2)))
      setData(response.agedItems)
    }
  }

  const getAgedItemsRanking = async (selectedViewMode) => {
    setIsLoading(true)
    try {
      const response = await getHttpRequest('/get_aged_item_chart_data', {
        params: {
          onlyTopics: selectedViewMode === 1,
          onlyActions: selectedViewMode === 2,
        },
      })
      updateData(response)
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong.',
      })
    }
    setIsLoading(false)
  }

  const determinColor = (count) => {
    if (count > teamAverage && count > recommendedAverage) {
      return '#ff0000'
    } else if (count <= teamAverage && count > recommendedAverage) {
      return '#ffd700'
    } else if (count <= recommendedAverage) {
      return '#008000'
    }
  }

  const handleModeChange = (mode) => {
    setSelectedViewMode(mode)
  }

  return (
    <ApplicationLayout>
      <div style={{ width: '90%', paddingTop: '3rem', paddingLeft: '1rem' }}>
        <div className="scorecard-container">
          <h2 style={{ fontSize: '16px', width: 'fit-content', textAlign: 'left' }}>
            {t('ageChart.title')}
          </h2>
          {isLoading && <InPageLoader inComponent={true} />}
          {!isLoading &&
            (data.length > 0 ? (
              <div style={{ marginTop: '2rem' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    columnGap: '0.5rem',
                    marginBottom: '1rem',
                  }}
                  id="meetingHubTutorial0"
                >
                  <div
                    className="option-button"
                    style={{ backgroundColor: selectedViewMode === 1 ? '#4472c4' : '#a6a6a6' }}
                    onClick={(event) => handleModeChange(1)}
                  >
                    {t('ageChart.topics')}
                  </div>

                  <div
                    className="option-button"
                    style={{ backgroundColor: selectedViewMode === 2 ? '#4472c4' : '#a6a6a6' }}
                    onClick={(event) => handleModeChange(2)}
                  >
                    {t('ageChart.actions')}
                  </div>
                </div>

                <ResponsiveContainer height={data.length * 100} width={'100%'}>
                  <BarChart data={data} layout="vertical" margin={{ left: 15 }}>
                    <XAxis hide axisLine={false} type="number" />
                    <YAxis
                      orientation="left"
                      yAxisId="left"
                      dataKey="name"
                      type="category"
                      tickLine={false}
                      tick={{ fontSize: 15 }}
                    />
                    <Tooltip cursor={{ fill: 'transparent' }} />
                    <Legend
                      layout="vertical"
                      verticalAlign="top"
                      wrapperStyle={{
                        left: 0,
                        top: 0,
                        paddingBottom: 20,
                      }}
                      // dummy payload for reference line..
                      payload={[
                        {
                          id: 'rec',
                          value: `${t('ageChart.recommeded')}: ${recommendedAverage}`,
                          type: 'square',
                          color: 'blue',
                        },
                        {
                          id: 'youravg',
                          value: `${t('ageChart.teamAvg')}: ${teamAverage}`,
                          type: 'square',
                          color: '#7e9ed6',
                        },
                      ]}
                    />
                    <ReferenceLine
                      id="recommend"
                      yAxisId="left"
                      x={recommendedAverage}
                      stroke="blue"
                      strokeWidth={3}
                      ifOverflow="extendDomain"
                    >
                      {/* <Label value={recommendedAverage} position="top" fill='blue'/> */}
                    </ReferenceLine>
                    <ReferenceLine
                      id="teamavg"
                      yAxisId="left"
                      x={teamAverage}
                      stroke="#7e9ed6"
                      strokeWidth={3}
                      ifOverflow="extendDomain"
                    >
                      {/* <Label value={teamAverage} position="top" fill='orange'/> */}
                    </ReferenceLine>

                    <Bar
                      name="count"
                      dataKey="count"
                      maxBarSize={25}
                      yAxisId="left"
                      legendType="none"
                    >
                      {data.map((d, idx) => {
                        return (
                          <Cell
                            key={d.name}
                            fill={determinColor(d.count)}
                            onClick={() => console.log(d)}
                          />
                        )
                      })}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            ) : (
              <span> {t('scorecard.noTopicsMessage')} </span>
            ))}
        </div>
      </div>
    </ApplicationLayout>
  )
}

export default AgedItemsRanking

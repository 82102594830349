import React from 'react'

const TwoColumnSlide = ({ slideData }) => {
  const { panel_lhs, panel_rhs } = slideData

  return (
    <div
      style={{
        display: 'flex',
        fontFamily: 'Arial, sans-serif',
        padding: '20px',
        backgroundColor: 'white',
        height: '100%',
      }}
    >
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          margin: '0 2.2rem',
          width: '20rem',
        }}
      >
        <div
          style={{
            backgroundColor: '#FF6347',
            color: 'white',
            padding: '1.2rem',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            marginBottom: '10px',
          }}
        >
          {panel_lhs.title}
        </div>
        <ul style={{ listStyleType: 'disc', paddingLeft: '1rem', marginTop: '1rem' }}>
          {panel_lhs.items.map((item, index) => (
            <li key={index} style={{ marginBottom: '1.5rem' }}>
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          margin: '0 2.2rem',
          width: '20rem',
        }}
      >
        <div
          style={{
            backgroundColor: '#FF6347',
            color: 'white',
            padding: '1.2rem',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            marginBottom: '10px',
          }}
        >
          {panel_rhs.title}
        </div>
        <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginTop: '1rem' }}>
          {panel_rhs.items.map((item, index) => (
            <li key={index} style={{ marginBottom: '2.5rem' }}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default TwoColumnSlide

import { DEFAULT_GOAL_DATA } from './teamLeaderContext'
import _ from 'lodash'

const teamLeaderReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_EMPLOYEE_MODAL':
      return {
        ...state,
        isEmployeeModalOpen: true,
      }
    case 'CLOSE_EMPLOYEE_MODAL':
      return {
        ...state,
        isEmployeeModalOpen: false,
      }
    case 'ADD_EMPLOYEE':
      return {
        ...state,
        isEmployeeModalOpen: false,
        data: {
          ...state.data,
          employees: [...state.data.employees, action.employee],
        },
      }
    case 'UPDATE_EMPLOYEE_ALLOCATION':
      return {
        ...state,
        data: {
          ...state.data,
          employees: action.employees,
        },
      }
    case 'OPEN_OBJ_HELP_MODAL':
      return {
        ...state,
        isObjHelpModalOpen: true,
      }
    case 'CLOSE_OBJ_HELP_MODAL':
      return {
        ...state,
        isObjHelpModalOpen: false,
      }
    case 'OPEN_OBJ_FORM_MODAL':
      return {
        ...state,
        isObjFormModalOpen: true,
        editObjectiveIndex: action.editObjectiveIndex,
      }
    case 'OPEN_MULTIPLE_OBJ_FORM_MODAL':
      return {
        ...state,
        isMultipleObjModalOpen: true,
      }
    case 'CLOSE_OBJ_FORM_MODAL':
      return {
        ...state,
        isObjFormModalOpen: false,
      }
    case 'CLOSE_MULTIPLE_OBJ_FORM_MODAL':
      return {
        ...state,
        isMultipleObjModalOpen: false,
      }
    case 'ADD_GOAL':
      const newGoal = {
        ..._.cloneDeep(DEFAULT_GOAL_DATA),
        type: action.objType ?? null,
        statement: `${action.statement}`,
        isValid: Boolean(action.isValid),
        localeId: action.localeId,
        id: action.localeId,
      }

      if (action.level1Id === null || action.level2Id === null || action.possibleMeasure === null) {
        newGoal.level1Id = null
        newGoal.level2Id = null
        newGoal.possibleMeasure = null
      }

      return {
        ...state,
        isObjFormModalOpen: false,
        data: {
          ...state.data,
          objectives: [...state.data.objectives, newGoal],
        },
      }
    case 'UPDATE_LAST_OBJ_LOCALE_ID':
      return {
        ...state,
        lastObjLocaleId: action.lastObjLocaleId,
      }
    case 'UPDATE_OBJECTIVE': {
      //action.objective.statement = `${action.objective.statement}`

      const objs = state.data.objectives
      objs[action.objectiveIndex] = action.objective

      return {
        ...state,
        isObjFormModalOpen: false,
        data: {
          ...state.data,
          objectives: [...objs],
        },
      }
    }
    case 'OPEN_ENTERPRISE_INFO_MODAL':
      return {
        ...state,
        isEnterpriseHelpModalOpen: true,
      }
    case 'CLOSE_ENTERPRISE_INFO_MODAL':
      return {
        ...state,
        isEnterpriseHelpModalOpen: false,
      }
    case 'OPEN_ENTERPRISE_MEASURE_MODAL':
      return {
        ...state,
        isEnterpriseMeasureModalOpen: true,
      }
    case 'CLOSE_ENTERPRISE_MEASURE_MODAL':
      return {
        ...state,
        isEnterpriseMeasureModalOpen: false,
      }
    case 'OPEN_CAPACITY_CONFIRM_MODAL':
      return {
        ...state,
        isCapacityConfirmModalOpen: true,
      }
    case 'CLOSE_CAPACITY_CONFIRM_MODAL':
      return {
        ...state,
        isCapacityConfirmModalOpen: false,
      }
    case 'UPDATE_CAPACITY_CHECK':
      return {
        ...state,
        isOverCapacity: action.isOverCapacity,
      }
    case 'DELETE_OBJECTIVE': {
      const objs = state.data.objectives.filter((obj, index) => obj.localeId !== action.localeId)

      return {
        ...state,
        data: {
          ...state.data,
          objectives: [...objs],
        },
      }
    }
    case 'OPEN_OBJECTIVES_FLOWTOWORK_MODAL':
      return {
        ...state,
        isObjectivesFlowToWorkModalOpen: true,
      }
    case 'CLOSE_OBJECTIVES_FLOWTOWORK_MODAL':
      return {
        ...state,
        isObjectivesFlowToWorkModalOpen: false,
      }
    case 'SET_FLOWTOWORK_USER':
      return {
        ...state,
        isFlowToWorkLeader: action.value,
      }
    case 'DELETE_EMPLOYEE':
      const emps = state.data.employees.filter((emp, index) => emp.localeId !== action.localeId)

      return {
        ...state,
        data: {
          ...state.data,
          employees: [...emps],
        },
      }
    case 'UPDATE_EXTERNALEMPALOCS':
      return {
        ...state,
        data: {
          ...state.data,
          externalEmpAlocs: action.externalEmpAlocs,
        },
      }
    case 'UPDATE_NONREPORTINGEMPSREALINDEXES':
      return {
        ...state,
        data: {
          ...state.data,
          nonReportingEmpsRealIndexes: action.nonReportingEmpsRealIndexes,
        },
      }
    case 'UPDATE_OBJECTIVES':
      return {
        ...state,
        data: {
          ...state.data,
          objectives: action.objectives,
        },
      }
    case 'UPDATE_TOTAL_TEAM_AVAILABLE_FTES':
      return {
        ...state,
        totalTeamAvailableFTEs: action.totalTeamAvailableFTEs,
      }
    case 'UPDATE_TOTAL_OBJ_REQUIRED_FTES':
      return {
        ...state,
        totalObjRequiredFTEs: action.totalObjRequiredFTEs,
      }

    case 'ALLOC_SUB_WIZ__OPEN_TEAMMEMBER_FORM_MODAL':
      return {
        ...state,
        isTeamMemberFormOpen: true,
        allocSubWiz: {
          ...state.allocSubWiz,
          updateObjectiveId: action.id,
        },
      }
    case 'ALLOC_SUB_WIZ__CLOSE_TEAMMEMBER_FORM_MODAL':
      return {
        ...state,
        isTeamMemberFormOpen: false,
        allocSubWiz: {
          ...state.allocSubWiz,
          updateObjectiveId: null,
        },
      }

    case 'ALLOC_SUB_WIZ__MOUNT_ALL_EMP':
      return {
        ...state,
        data: {
          ...state.data,
          employees: action.employees,
        },
      }

    case 'ALLOC_SUB_WIZ__ADD_MEMBERS_TO_OBJECTIVE': {
      return {
        ...state,
        data: {
          ...state.data,
          objectives: action.objectives,
        },
      }
    }
    case 'ALLOC_SUB_WIZ__SET_ALLOCATED_CAPACITY': {
      return {
        ...state,
        data: {
          ...state.data,
          objectives: action.objectives,
        },
      }
    }
    case 'ALLOC_SUB_WIZ__SET_ALLOCATED_CAPACITY_ERROR': {
      return {
        ...state,
        data: {
          ...state.data,
          objectives: action.objectives,
        },
      }
    }
    case 'ALLOC_SUB_WIZ__DISABLE_NEXT': {
      return {
        ...state,
        disableNext: true,
      }
    }
    case 'ALLOC_SUB_WIZ__ENABLE_NEXT': {
      return {
        ...state,
        disableNext: false,
      }
    }

    case 'ALLOC_SUB_WIZ__HAS_ERROR__TEAM_CAP_ALLOC_TABLE': {
      return {
        ...state,
        allocSubWiz: {
          ...state.allocSubWiz,
          errorTeamCapAllocTable: true,
        },
      }
    }

    case 'ALLOC_SUB_WIZ__NOT_HAS_ERROR__TEAM_CAP_ALLOC_TABLE': {
      return {
        ...state,
        allocSubWiz: {
          ...state.allocSubWiz,
          errorTeamCapAllocTable: false,
        },
      }
    }

    case 'ALLOC_SUB_WIZ__HAS_ERROR__ADDITIONAL_CAP_ALLOC_TABLE': {
      return {
        ...state,
        allocSubWiz: {
          ...state.allocSubWiz,
          errorAdditionalCapTable: true,
        },
      }
    }

    case 'ALLOC_SUB_WIZ__NOT_HAS_ERROR__ADDITIONAL_CAP_ALLOC_TABLE': {
      return {
        ...state,
        allocSubWiz: {
          ...state.allocSubWiz,
          errorAdditionalCapTable: false,
        },
      }
    }

    case 'ALLOC_SUB_WIZ_SET_SELECT_ALL_EMP': {
      return {
        ...state,
        allocSubWiz: {
          ...state.allocSubWiz,
          triggerSelectAllEmp: action.value,
        },
      }
    }
    case 'ADD_MEETING_TOPIC': {
      const objs = state.data.objectives
      for (let i = 0; i < objs.length; i++) {
        if (objs[i].id === action.meetingTopic.localObjId) {
          if (action.meetingTopic.topicType === 1) {
            objs[i].blockers.push(action.meetingTopic)
          } else if (action.meetingTopic.topicType === 2) {
            objs[i].decisions.push(action.meetingTopic)
          } else if (action.meetingTopic.topicType === 3) {
            objs[i].updates.push(action.meetingTopic)
          }
        }
      }

      return {
        ...state,
        lastMeetingTopicLocaleId: state.lastMeetingTopicLocaleId + 1,
        data: {
          ...state.data,
          objectives: [...objs],
        },
      }
    }

    case 'ADD_MULTIPLE_MEETING_TOPICS': {
      const { meetingTopics } = action
      const updatedObjectives = state.data.objectives.map((obj) => {
        const matchingTopics = meetingTopics.filter((topic) => topic.localObjId === obj.id)
        matchingTopics.forEach((topic) => {
          if (topic.topicType === 'blocker') {
            obj.blockers.push(topic)
          } else if (topic.topicType === 'guidance') {
            obj.decisions.push(topic)
          } else if (topic.topicType === 'update') {
            obj.updates.push(topic)
          }
        })
        return obj
      })

      return {
        ...state,
        lastMeetingTopicLocaleId: state.lastMeetingTopicLocaleId + 1,
        data: {
          ...state.data,
          objectives: updatedObjectives,
        },
      }
    }

    case 'EDIT_MEETING_TOPIC': {
      const objs = state.data.objectives
      for (let i = 0; i < objs.length; i++) {
        if (objs[i].id === action.meetingTopic.localObjId) {
          if (action.meetingTopic.topicType === 1) {
            for (let j = 0; j < objs[i].blockers.length; j++) {
              if (objs[i].blockers[j].localeId === action.meetingTopic.localeId) {
                objs[i].blockers[j] = action.meetingTopic
              }
            }
          } else if (action.meetingTopic.topicType === 2) {
            for (let j = 0; j < objs[i].decisions.length; j++) {
              if (objs[i].decisions[j].localeId === action.meetingTopic.localeId) {
                objs[i].decisions[j] = action.meetingTopic
              }
            }
          } else if (action.meetingTopic.topicType === 3) {
            for (let j = 0; j < objs[i].updates.length; j++) {
              if (objs[i].updates[j].localeId === action.meetingTopic.localeId) {
                objs[i].updates[j] = action.meetingTopic
              }
            }
          }
        }
      }

      return {
        ...state,
        isObjFormModalOpen: false,
        data: {
          ...state.data,
          objectives: [...objs],
        },
      }
    }
    case 'DELETE_MEETING_TOPIC': {
      const objs = state.data.objectives
      for (let i = 0; i < objs.length; i++) {
        if (objs[i].id === action.meetingTopic.localObjId) {
          if (action.meetingTopic.topicType === 1) {
            for (let j = 0; j < objs[i].blockers.length; j++) {
              if (objs[i].blockers[j].localeId === action.meetingTopic.localeId) {
                objs[i].blockers.splice(j, 1)
              }
            }
          } else if (action.meetingTopic.topicType === 2) {
            for (let j = 0; j < objs[i].decisions.length; j++) {
              if (objs[i].decisions[j].localeId === action.meetingTopic.localeId) {
                objs[i].decisions.splice(j, 1)
              }
            }
          } else if (action.meetingTopic.topicType === 3) {
            for (let j = 0; j < objs[i].updates.length; j++) {
              if (objs[i].updates[j].localeId === action.meetingTopic.localeId) {
                objs[i].updates.splice(j, 1)
              }
            }
          }
        }
      }

      return {
        ...state,
        isObjFormModalOpen: false,
        data: {
          ...state.data,
          objectives: [...objs],
        },
      }
    }

    default:
      throw new Error(`Action: ${action.type} not implemented in Team Leader reducer.`)
  }
}

export default teamLeaderReducer

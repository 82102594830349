import React from 'react'
import InPageLoader from '../../components/InPageLoader'
import plus from '../../assets/plus.svg'
import ApplicationLayout from '../../components/ApplicationLayout'
import { Formik, Field, Form } from 'formik'
import useAutogenCapabilityTasks from '../../api/query/useAutogenCapabilityTasks'
import useEmployeeData_2_3 from '../../api/query/useEmployeeDataLevel2&3'

const AreaLevelFlow = () => {
  const [details, setDetails] = React.useState('')
  const [capability, setCapability] = React.useState('')

  const { data: dataEmpLevel23 } = useEmployeeData_2_3()

  const {
    data: capabilityData,
    isLoading: capabilityTasksIsLoading,
    isFetching: capabilityTasksIsFetching,
    refetch: capabilityTasksRefetch,
  } = useAutogenCapabilityTasks({ details: details, capability: capability })

  const [capabilityTasksData, setCapabilityTasksData] = React.useState()

  React.useEffect(() => {
    if (Array.isArray(capabilityData?.suggested_tasks)) {
      setCapabilityTasksData([...capabilityData.suggested_tasks])
    }
  }, [capabilityData])

  const testOmnichannel = () => {
    setCapabilityTasksData([
      'Evaluate current omnichannel capabilities and identify areas for improvement.',
      'Analyze customer preferences and behaviors across channels.',
      'Develop strategies for integrating and optimizing channels.',
      'Review and update current payment processes to ensure omnichannel compatibility.',
      'Implement a unified customer view across channels.',
      'Establish a system for tracking and monitoring customer engagement and loyalty.',
      'Explore and incorporate new technologies, such as artificial intelligence and machine learning.',
      'Integrate data from multiple sources to create a single view of the customer.',
      'Develop a comprehensive marketing and promotional plan to increase omnichannel penetration.',
      'Conduct regular training and education sessions for employees on omnichannel processes and technologies.',
    ])
  }

  return (
    <>
      <ApplicationLayout>
        <div
          className="wizard-container"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div className="wizard-step">
            <h2 className="wizard-step__title">Big Room Planning Canvas</h2>

            <div
              className="wizard-step__main-content-container"
              style={{ alignItems: 'flex-start' }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="form-field action-field">
                  <label>Capability</label>
                  <input
                    id="capability"
                    name="capability"
                    autoComplete="off"
                    type="text"
                    onChange={(e) => {
                      setCapability(e.target.value)
                    }}
                  />
                </div>
                <div style={{ color: 'grey', marginLeft: '3rem' }}>
                  <br />
                  Eg. Increase our omnichannel penetration
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="form-field action-field">
                  <label>Details</label>
                  <textarea
                    id="details"
                    name="details"
                    rows="4"
                    autoComplete="off"
                    onChange={(e) => {
                      setDetails(e.target.value)
                    }}
                    style={{ resize: 'none' }}
                  ></textarea>
                </div>
                <div style={{ color: 'grey', marginLeft: '3rem' }}>
                  <div>
                    <br />
                    Eg. We want to adapt processes and technology
                    <br />
                    to increase our omnichannel penetration
                  </div>
                  {/* <div>Eg. We want to adapt processes and technology {"\n"} to increase our omnichannel penetration</div> */}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  alignItems: 'center',
                  padding: '1rem 0 0 0',
                  gap: '1rem',
                }}
              >
                <div className="add-btn" onClick={() => {}} style={{ marginTop: '0' }}>
                  <img className="plus" src={plus} alt="TransforML Logo" />
                  <span>ADD OBJECTIVE</span>
                </div>

                <div
                  className="add-btn"
                  onClick={() => {
                    capabilityTasksRefetch()
                  }}
                  style={{ whiteSpace: 'nowrap', marginTop: '0', width: '12vw' }}
                >
                  <img className="plus" src={plus} alt="TransforML Logo" />
                  <span>SUGGEST OBJECTIVES</span>
                </div>

                <div
                  className="add-btn"
                  onClick={() => {
                    testOmnichannel()
                  }}
                  style={{ whiteSpace: 'nowrap', marginTop: '0', width: '12vw' }}
                >
                  <img className="plus" src={plus} alt="TransforML Logo" />
                  <span>TEST OMNICHANNEL</span>
                </div>
              </div>

              {capabilityTasksIsFetching && (
                <div style={{ width: '100%', margin: '5rem 0' }}>
                  <InPageLoader inComponent={true} />
                </div>
              )}
              {capabilityTasksData && (
                <div style={{ marginTop: '3rem' }}>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
                      columnGap: '2rem',
                      rowGap: '2rem',
                      marginBottom: '1rem',
                    }}
                  >
                    <b>Objective</b>

                    <b>Est duration</b>

                    <b>Due date</b>
                    <b>VP Level Owner</b>
                    <b>Team Leader needed</b>
                  </div>

                  {capabilityTasksData?.map((item, index) => (
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
                        columnGap: '2rem',
                        rowGap: '2rem',
                        marginBottom: '1rem',
                      }}
                    >
                      <div>{item}</div>

                      <div className="form-field action-field">
                        <input style={{ width: 'unset', height: 'unset' }} />
                      </div>

                      <div className="form-field action-field">
                        <input type="date" style={{ width: 'unset', height: 'unset' }} />
                      </div>

                      <div className="form-field action-field">
                        <select
                          className="accountable-select"
                          onChange={(e) => {
                            // handleChange(e.target.value, index, e.target)
                          }}
                          // defaultValue={objective && objective.assigneeEmpId}
                        >
                          <option key="123" value="">
                            Select VP Level
                          </option>
                          {dataEmpLevel23?.employees.map((employee, index) => (
                            <option key={`emp-${index}`} value={employee.eId}>
                              {employee.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-field action-field">
                        <select
                          className="accountable-select"
                          onChange={(e) => {
                            // handleChange(e.target.value, index, e.target)
                          }}
                          // defaultValue={objective && objective.assigneeEmpId}
                        >
                          <option key="123" value="">
                            Select Team Leader
                          </option>
                          {dataEmpLevel23?.employees.map((employee, index) => (
                            <option key={`emp-${index}`} value={employee.eId}>
                              {employee.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  ))}
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '1rem 0 0 0',
                      gap: '1rem',
                    }}
                  >
                    <div
                      className="edit-cap-btn"
                      onClick={() => {}}
                      style={{
                        whiteSpace: 'nowrap',
                        backgroundColor: 'rgb(200, 200, 200)',
                        color: 'black',
                      }}
                    >
                      <span>CANCEL</span>
                    </div>
                    <div
                      className="add-btn"
                      onClick={() => {}}
                      style={{ whiteSpace: 'nowrap', width: '12vw', marginTop: '0' }}
                    >
                      <span>FINALIZE ASSIGNMENT</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </ApplicationLayout>
    </>
  )
}

export default AreaLevelFlow

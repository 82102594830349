import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const deleteObjective = async (objData) => {
  const { data } = await axios.post(`${BASE_URL}/delete_objective_v2`, objData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export default function useDeleteObjective_v2() {
  const client = useQueryClient()
  return useMutation((data) => deleteObjective(data), {
    onSuccess: (response) => {
      if (response.ok) {
        void client.invalidateQueries('objectives')
      }
    },
  })
}

import React, { useState, useEffect, useRef } from 'react'
import TopCard from './TopCard'
import BottomCard from './BottomCard'

const CardExpanded = ({
  data,
  index,
  createFlagStatus,
  resolved,
  loadAgain,
  reload,
  showInitiativesId,
  createComment,
  createActivity,
  refreshBGUScorecardFn,
  teamLeadersList,
  viewOnlyMode = false,
  flagStatusLoading,
  fromHomePage = false,
  showDropDownMenu = true,
  userList = [],
  updateCardRecordingState = () => Promise.resolve(),
  isPresentationMode = false,
  fromPresentationDeck = false,
}) => {
  const [commentActivityData, setCommentActivityData] = useState(
    data?.activities
      ? data?.activities.sort((a, b) => {
          // 1 = true, 0 = false
          return b.isPriority - a.isPriority
        })
      : [],
  )

  const [cardButtonTypes, setCardButtonTypes] = useState([])

  let hasNoActivities = data?.activities?.length === 0 ? true : false

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '1rem',
        minHeight: '70vh',
        border: '2px solid rgba(128, 128, 128, 0.24)',
        padding: '1rem',
      }}
    >
      <TopCard
        data={data}
        index={index}
        createFlagStatus={createFlagStatus}
        resolved={resolved}
        loadAgain={loadAgain}
        reload={reload}
        showInitiativesId={showInitiativesId}
        createComment={createComment}
        createActivity={createActivity}
        refreshBGUScorecardFn={refreshBGUScorecardFn}
        teamLeadersList={teamLeadersList}
        viewOnlyMode={viewOnlyMode}
        flagStatusLoading={flagStatusLoading}
        fromHomePage={fromHomePage}
        showDropDownMenu={showDropDownMenu}
        userList={userList}
        updateCardRecordingState={updateCardRecordingState}
        isPresentationMode={isPresentationMode}
        fromPresentationDeck={fromPresentationDeck}
        setCommentActivityData={setCommentActivityData}
        cardButtonTypes={cardButtonTypes}
        setCardButtonTypes={setCardButtonTypes}
      />
      <div style={{ width: '1px', backgroundColor: '#E0E0E0' }} />
      <BottomCard
        commentActivityData={commentActivityData}
        setCommentActivityData={setCommentActivityData}
        loadAgain={loadAgain}
        reload={reload}
        refreshBGUScorecardFn={refreshBGUScorecardFn}
        viewOnlyMode={viewOnlyMode}
        index={index}
        cardButtonTypes={cardButtonTypes}
        userList={userList}
        umtId={data.umtId}
        isPresentationMode={isPresentationMode}
        setCardButtonTypes={setCardButtonTypes}
        hasNoActivities={hasNoActivities}
      />
    </div>
  )
}
export default CardExpanded

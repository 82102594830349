import React from 'react'
import { useNavigate } from 'react-router-dom'
import { capacitySubRoutes, routes } from '../../../utils/routes'

export default function useCapacityWizardSteps(existingStep, isF2wTl, teamLeaderId = null) {
  let capacitySteps = []

  if (isF2wTl) {
    capacitySteps = [
      capacitySubRoutes.f2wAvailability,
      capacitySubRoutes.objectivesTeamMapping,
      capacitySubRoutes.teamCapacityAllocation,
    ]
  } else {
    capacitySteps = [
      capacitySubRoutes.objectivesTeamMapping,
      capacitySubRoutes.teamCapacityAllocation,
    ]
  }

  const navigate = useNavigate()
  const [currentStepIndex, setCurrentStepIndex] = React.useState(parseInt(existingStep))

  React.useEffect(() => {
    if (currentStepIndex >= 0) {
      navigate(`/${routes.capacity}/${capacitySteps[currentStepIndex]}`, {
        state: { teamLeaderId },
      })
    }
  }, [currentStepIndex, existingStep, navigate])

  const handleNext = () => {
    if (currentStepIndex <= capacitySteps.length - 1) {
      const nextStep = currentStepIndex + 1
      setCurrentStepIndex(nextStep)
    }
  }

  const handleNextSkipOneStep = () => {
    if (currentStepIndex <= capacitySteps.length - 2) {
      const nextStep = currentStepIndex + 2
      setCurrentStepIndex(nextStep)
    }
  }

  const handlePrevious = () => {
    if (Boolean(currentStepIndex)) {
      const prevStep = currentStepIndex - 1
      setCurrentStepIndex(prevStep)
    }
  }

  const handlePreviousSkipOneStep = () => {
    if (Boolean(currentStepIndex)) {
      const prevStep = currentStepIndex - 2
      setCurrentStepIndex(prevStep)
    }
  }

  return {
    currentStepIndex,
    setCurrentStepIndex,
    steps: capacitySteps,
    handleNext,
    handleNextSkipOneStep,
    handlePrevious,
    handlePreviousSkipOneStep,
  }
}

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '../Modal'
import Textarea from 'rc-textarea'
import { postHttpRequest, getHttpRequest } from '../../api/query/dynamicAPI'
import SubactivityDropdownMenu from '../../pages/SubActivities/subactivityDropdownMenu'
import InPageLoader from '../InPageLoader'
import Toast from '../../common/toast'
import Swal from 'sweetalert2'
import {
  POST_KANBAN_MARK_ACTIVITY_AS_PRIORITY,
  CREATE_SUB_ACTIVITY,
  GET_SINGLE_SUB_ACTIVITY,
  API_SPREADHSHEET_SAVE_SUCCESS_MESSAGE,
  API_ERROR_MESSAGE,
  DELETE_SUB_ACTIVITY,
} from '../../api/constant/route'
import { getSubActivityByActivityId } from '../../api/services/subActivities.services'
import SingleSubactivityModal from '../../pages/kanban/common/singleSubactivityModal'

const SubactivityModal = ({ activity, closeModal, loadBackground }) => {
  const { t } = useTranslation(['Kanban'])
  const [newSubActivityDescription, setNewSubActivityDescription] = useState('')
  const [subActivityListData, setSubActivityListData] = useState([])
  const [selectedSubactivity, setSelectedSubactivity] = useState(null)
  const [isSingleSubactivityModalOpen, setIsSubactivityModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [refresh, setRefresh] = useState(0)

  // Fetch subactivities only once when component mounts or activity changes
  React.useEffect(() => {
    const fetchSubactivities = async () => {
      try {
        setIsLoading(true)
        const subactivitiesList = await getSubActivityByActivityId(activity?.milestoneId)
        setSubActivityListData(subactivitiesList.subActivitiesList || [])
      } catch (error) {
        console.error('Error fetching subactivities:', error)
        Toast.fire({
          icon: 'error',
          title: API_ERROR_MESSAGE,
        })
      } finally {
        setIsLoading(false)
      }
    }

    if (activity?.milestoneId) {
      fetchSubactivities()
    }
  }, [activity?.milestoneId, refresh])

  const handleTextChange = (e) => {
    setNewSubActivityDescription(e.target.value)
  }

  const createSubactivity = async () => {
    if (!newSubActivityDescription.trim()) return

    const postObj = {
      objectiveId: parseInt(activity?.objectiveId),
      activityId: parseInt(activity?.milestoneId),
      subActivities: [
        { subAcitivityDescription: newSubActivityDescription, status: 'NOT_YET_STARTED' },
      ],
    }

    try {
      const response = await postHttpRequest(CREATE_SUB_ACTIVITY, postObj)
      if (response) {
        setNewSubActivityDescription('')
        // Fetch updated list instead of modifying state directly
        const updatedList = await getSubActivityByActivityId(activity?.milestoneId)
        setSubActivityListData(updatedList.subActivitiesList || [])

        Toast.fire({
          icon: 'success',
          title: API_SPREADHSHEET_SAVE_SUCCESS_MESSAGE,
        })
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: API_ERROR_MESSAGE,
      })
    }
  }

  const updateSubActivityList = (value, index) => {
    setSubActivityListData((prev) => {
      const updated = [...prev]
      updated[index] = { ...updated[index], subAcitivityDescription: value }
      return updated
    })
  }

  const editSubactivity = async (index) => {
    const subActivity = subActivityListData[index]
    if (!subActivity) return

    const postObj = {
      objectiveId: parseInt(activity?.objectiveId),
      activityId: parseInt(activity?.milestoneId),
      subActivities: [{ ...subActivity }],
    }

    try {
      await postHttpRequest(CREATE_SUB_ACTIVITY, postObj)
      // Fetch fresh data after update
      const updatedList = await getSubActivityByActivityId(activity?.milestoneId)
      setSubActivityListData(updatedList.subActivitiesList || [])
    } catch (error) {
      console.error('Error updating subactivity:', error)
    }
  }

  const updateSubActivityListDescriptionOnBlur = (e, index) => {
    if (subActivityListData[index]) {
      subActivityListData[index][e.target.name] = e.target.value
    }
    editSubactivity(index)
  }

  const updateSubActivityListStatus = async (e, index) => {
    let tempSubActivityListData = [...subActivityListData]
    tempSubActivityListData[index].status =
      tempSubActivityListData[index].status === 'COMPLETE' ? 'NOT_YET_STARTED' : 'COMPLETE'
    setSubActivityListData(tempSubActivityListData)

    const postObj = {
      objectiveId: parseInt(activity?.objectiveId),
      activityId: parseInt(activity?.milestoneId),
      subActivities: [{ ...tempSubActivityListData[index] }],
    }

    try {
      await postHttpRequest(CREATE_SUB_ACTIVITY, postObj)
      // Fetch fresh data after update
      const updatedList = await getSubActivityByActivityId(activity?.milestoneId)
      setSubActivityListData(updatedList.subActivitiesList || [])
    } catch (error) {
      console.error('Error updating subactivity:', error)
    }
  }

  const deleteSubActivity = async (subActId) => {
    try {
      await postHttpRequest(DELETE_SUB_ACTIVITY, { subActivityId: subActId })
      // Fetch fresh data after deletion
      const updatedList = await getSubActivityByActivityId(activity?.milestoneId)
      setSubActivityListData(updatedList.subActivitiesList || [])
    } catch (error) {
      console.error('Error deleting subactivity:', error)
    }
  }

  const confirmDeleteSubactivity = (subActId, subActivityText) => {
    Swal.fire({
      title: t('Common:modalMessages.areYouSureDeleteX', { X: subActivityText }),
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        deleteSubActivity(subActId)
      }
    })
  }
  return (
    <>
      {isSingleSubactivityModalOpen && (
        <SingleSubactivityModal
          closeModal={() => {
            setIsSubactivityModalOpen(false)
            setSelectedSubactivity(null)
          }}
          subactivity={selectedSubactivity}
          setIsRefreshObjective={() => {
            setRefresh((old) => !old)
          }}
        />
      )}

      <Modal
        title={activity.description}
        closeModal={() => {
          closeModal(subActivityListData.length)
        }}
        width={'20rem'}
        titleStyle={{ color: 'rgb(91,132,203)', textAlign: 'left' }}
        modalTitleContainerStyle={{ justifyContent: 'flex-start' }}
      >
        {isLoading && <InPageLoader inComponent={true} />}
        {!isLoading && (
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
            {subActivityListData?.map((data, index) => (
              <div key={index} className="">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    columnGap: '0.8rem',
                    justifyContent: '1rem',
                    alignItems: 'center',
                  }}
                >
                  <Textarea
                    id="subAcitivityDescription"
                    name="subAcitivityDescription"
                    placeholder={t('Common:formPlaceHolder.typeToAdd')}
                    autoComplete="off"
                    autoSize
                    value={subActivityListData[index].subAcitivityDescription}
                    style={{
                      width: '80%',
                      resize: 'none',
                      border: '1px solid rgb(238, 238, 238)',
                      backgroundColor: 'rgba(238, 238, 238, 0.2)',
                      borderRadius: '2.5px',
                      fontFamily: "'Open Sans', 'Arial', 'Helvetica', 'system-ui', sans-serif",
                      minHeight: '3.5rem',
                      backgroundColor: 'white',
                    }}
                    onChange={(e) => {
                      updateSubActivityList(e.target.value, index)
                    }}
                    onBlur={(e) => {
                      updateSubActivityListDescriptionOnBlur(e, index)
                    }}
                  />
                  <div style={{ display: 'flex', flexDirection: 'column', justifyItems: 'center' }}>
                    {data.status === 'NOT_YET_STARTED' && (
                      <div
                        class="fitted-button checklist"
                        onClick={(e) => {
                          updateSubActivityListStatus(e, index)
                        }}
                        style={{ margin: '0' }}
                      >
                        <span class="material-symbols-outlined grey-check" style={{ margin: '0' }}>
                          done
                        </span>
                      </div>
                    )}

                    {data.status === 'COMPLETE' && (
                      <div
                        class="fitted-button checklist"
                        onClick={(e) => {
                          updateSubActivityListStatus(e, index)
                        }}
                        style={{ margin: '0' }}
                      >
                        <span class="material-symbols-outlined green-check" style={{ margin: '0' }}>
                          done
                        </span>
                      </div>
                    )}
                    <SubactivityDropdownMenu
                      deleteSubActivity={(e) => {
                        confirmDeleteSubactivity(
                          data.subActivityId,
                          subActivityListData[index].subAcitivityDescription,
                        )
                      }}
                      editDatesSubactivity={(e) => {
                        setSelectedSubactivity(data)
                        setIsSubactivityModalOpen(true)
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                columnGap: '0.4rem',
                justifyContent: '1rem',
                alignItems: 'center',
              }}
            >
              <Textarea
                style={{
                  minHeight: '2.5rem',
                  width: '80%',
                  resize: 'none',
                  border: '1px solid rgb(238, 238, 238)',
                  backgroundColor: 'rgba(238, 238, 238, 0.2)',
                  borderRadius: '2.5px',
                  fontFamily: "'Open Sans', 'Arial', 'Helvetica', 'system-ui', sans-serif",
                  backgroundColor: 'white',
                }}
                autoSize
                id="subActivityDescription"
                name="subActivityDescription"
                placeholder={t('Common:formPlaceHolder.typeToAdd')}
                autoComplete="off"
                className="custom-input"
                onChange={(e) => {
                  handleTextChange(e)
                }}
                value={newSubActivityDescription}
              />

              <div
                class="fitted-button blue"
                style={{ backgroundColor: 'white' }}
                onClick={() => {
                  createSubactivity()
                }}
              >
                <span class="material-symbols-outlined">add</span>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}
export default SubactivityModal

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { areaLeaderSubRoutes, routes } from '../../../utils/routes'
import { useAreaLeaderContext } from '../context/areaLeaderContext'

function alwStepsGenerator() {
  return [
    areaLeaderSubRoutes.intro,
    areaLeaderSubRoutes.objectives,
    areaLeaderSubRoutes.type,
    areaLeaderSubRoutes.linkage,
    areaLeaderSubRoutes.meetingTopics,
  ]
}

export function stepsLabelsGenerator() {
  return {
    INTRO: 0,
    OBJECTIVES: 1,
    OBJECTIVE_TYPE: 2,
    LINKAGE: 3,
    MEETING_TOPICS: 4,
  }
}

export default function useTeamLeaderWizardSteps(existingStep) {
  const areaLeaderSteps = alwStepsGenerator()
  let alArray = [
    true, //INTRO 0
    true, //OBJECTIVES 1
    true, //OBJECTIVE_TYPE 2
    true, //LINKAGE 3
    true, //MEETING_TOPICS 4
  ]
  const navigate = useNavigate()
  const [currentStepIndex, setCurrentStepIndex] = React.useState(parseInt(existingStep))
  const { state } = useAreaLeaderContext()

  React.useEffect(() => {
    if (currentStepIndex >= 0) {
      navigate(`/${routes.areaLeader}/${areaLeaderSteps[currentStepIndex]}`)
    }
  }, [currentStepIndex, existingStep, navigate])

  //TODO Swetha: Commenting the popup on browser close
  // window.addEventListener("beforeunload", (ev) => {
  //   if (window.location.href.indexOf('Blocking') === -1) {
  //     ev.preventDefault();
  //     navigate(`/${routes.teamleader}/${teamLeaderSteps[currentStepIndex]}`)
  //     return ev.returnValue = 'You have unsaved data. If you want to save progress, click cancel and navigate to the next page.';
  //   }
  //   else{
  //     return ''
  //   }
  // });

  const handleNext = () => {
    let steps = stepsLabelsGenerator()
    if (currentStepIndex <= areaLeaderSteps.length - 1) {
      const level3Objectives = state.data.objectives.filter((obj, objIndex) => obj.type === 3) ?? []

      if (level3Objectives.length === state.data.objectives.length) {
        alArray[steps.LINKAGE] = false
      }

      let stepIncrease = 1
      while (true) {
        if (alArray[currentStepIndex + stepIncrease] === true) {
          const nextStep = currentStepIndex + stepIncrease
          setCurrentStepIndex(nextStep)
          //saveTLWizardState(state.data, nextStep)
          break
        }
        stepIncrease++
      }

      //TBD Swetha : Remove local-storage when not required
      // localStorage.setItem('tlw-step', nextStep.toString())
    }
  }

  const handlePrevious = () => {
    let steps = stepsLabelsGenerator()
    if (Boolean(currentStepIndex)) {
      const level3Objectives = state.data.objectives.filter((obj, objIndex) => obj.type === 3) ?? []

      if (level3Objectives.length === state.data.objectives.length) {
        alArray[steps.LINKAGE] = false
      }

      let stepDecrease = 1
      while (true) {
        if (alArray[currentStepIndex - stepDecrease] === true) {
          const prevStep = currentStepIndex - stepDecrease
          setCurrentStepIndex(prevStep)
          //saveTLWizardState(state.data, prevStep)
          break
        }
        stepDecrease++
      }
    }
  }

  //TO-DO
  // async function saveALWizardState(tlWizardState, step) {
  //   const postObj = {}
  //   postObj.tlWizardState = tlWizardState
  //   postObj.step = step
  //   postHttpRequest(POST_TL_WIZARD_STATE, postObj)
  //     .then((response) => {
  //       if (response) {
  //         return
  //       } else {
  //       }
  //     })
  //     .catch((error) => {})
  // }

  return {
    currentStepIndex,
    steps: areaLeaderSteps,
    handleNext,
    handlePrevious,
  }
}

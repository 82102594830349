import React from 'react'
import '../../../MeetingView/components/MilestonesOverview/styles.css'
import SummaryOfObjectives from '../../../../components/SummaryOfObjectives'
import { routes } from '../../../../utils/routes'
import { useNavigate } from 'react-router-dom'

const MilestonesOverview = () => {
  let eIdsOfSelectedTls = []
  const navigate = useNavigate()

  React.useEffect(() => {
    // Redirect to SummaryOfObjectives as we don't have SoO in MH anymore
    navigate(`/${routes.summaryOfObjectives}`)
  }, [navigate])

  return (
    <>{/* <SummaryOfObjectives eIdsOfSelectedTls={eIdsOfSelectedTls}></SummaryOfObjectives> */}</>
  )
}

export default MilestonesOverview

import React from 'react'
import { useTranslation } from 'react-i18next'
import { dayMonthFilter } from '../../../../utils/time'

const NotesList = ({ notes, selectedIndex, setSelectedIndex, setTitle, setNoteId, setNote }) => {
  const { t } = useTranslation(['MyNotebook'])

  return (
    <div className="my-notebook-notes-left-subcontainer">
      <div className="note-box">
        {notes?.map((note, index) => (
          <React.Fragment key={index}>
            <div
              className={`my-notebook-notes-left-subcontainer-note ${selectedIndex === index ? 'selected-note' : ''}`}
              onClick={() => {
                setSelectedIndex(index)
                setTitle(note?.noteTitle)
                setNoteId(note?.noteId)
                setNote(note?.noteDescription)
              }}
            >
              <div className="my-notebook-notes-left-subcontainer-left">
                <div className="my-notebook-notes-left-subcontainer-note-title">
                  <span className="truncate">
                    {note?.noteTitle !== '' ? note?.noteTitle : t('MyNotebook:newNote')}
                  </span>
                </div>
                <div className="my-notebook-notes-left-subcontainer-note-description">
                  <span className="truncate">
                    {note?.noteDescription !== ''
                      ? note?.noteDescription
                      : t('MyNotebook:noAdditionalText')}
                  </span>
                </div>
              </div>
              <div className="my-notebook-notes-left-subcontainer-note-date">
                <span>{note?.lastUpdatedTs ? dayMonthFilter(note?.lastUpdatedTs) : ''}</span>
              </div>
            </div>
            <div className="divider"></div>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

export default NotesList

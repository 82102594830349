import React from 'react'
import ApplicationLayout from '../../components/ApplicationLayout'
import next from '../../assets/next-white.svg'

import plus from '../../assets/plus.svg'

import ObjectivesStep from '../TeamLeader/components/ObjectivesStep'

import { Component } from 'react'

import './styles.css'

class Leadership extends Component {
  constructor() {
    super()
  }

  componentDidMount() {
    const leadershipPage = document.querySelector('.leadership-page')

    const stepSections = leadershipPage.querySelectorAll('.leadership-step')

    const numberOfSteps = stepSections.length

    let stepCounter = 0

    const leadershipPrevious = leadershipPage.querySelector('.dual-back-btn')
    leadershipPrevious.addEventListener('click', leadershipPreviousStepHandler)

    const leadershipNext = leadershipPage.querySelector('.dual-next-btn ')
    leadershipNext.addEventListener('click', leadershipNextStepHandler)

    function leadershipPreviousStepHandler() {
      if (stepCounter <= 0) {
        return
      }

      stepCounter--

      for (const stepSection of stepSections) {
        stepSection.classList.add('hidden')
      }

      stepSections[stepCounter].classList.remove('hidden')
    }

    function leadershipNextStepHandler() {
      if (stepCounter >= numberOfSteps - 1) {
        window.location.href = '/'
      }

      stepCounter++

      for (const stepSection of stepSections) {
        stepSection.classList.add('hidden')
      }

      stepSections[stepCounter].classList.remove('hidden')
    }
  }

  render() {
    return (
      <ApplicationLayout>
        <div className="leadership-page">
          {/* TODO: Add dynamic progress bar when refactoring component into React style
          <div className="nav-progress-container">
            <div className="progress-bar">
              <div className="progress progress-circle active-progress"></div>

              <div className="progress progress-rect"></div>
              <div className="progress progress-circle"></div>

              <div className="progress progress-rect"></div>
              <div className="progress progress-circle"></div>

              <div className="progress progress-rect"></div>
              <div className="progress progress-circle"></div>

              <div className="progress progress-rect"></div>
              <div className="progress progress-circle"></div>
            </div>
          </div>  */}

          <section className="leadership-step leadership-intro-step">
            <div className="intro-title">
              <h2>Welcome to the S2A Leadership Wizard </h2>
              <p>
                This wizard will help you decompose your organization's strategic objectives into
                more <b>specific objectives that apply to your BU / Function</b>. In addition, you
                will also be able to add{' '}
                <b>additional objectives that only apply to your BU / Function</b>.
              </p>
            </div>
            <div className="intro-summary-container">
              <div className="intro-summary-point shadow">
                <div className="intro-summary-title">
                  <b>Overview of your Enterprise's Objecives</b>
                </div>

                <p className="intro-summary-details">
                  We fill first walk you through{' '}
                  <b>details of your Enterprise's Strategy, associated objectives</b> and the{' '}
                  <b>rationale behind them</b>.
                </p>
              </div>

              <div className="intro-summary-point shadow">
                <div className="intro-summary-title">
                  <b>Customization of Objectives</b>
                </div>

                <p className="intro-summary-details">
                  once you've understood these objectives, we will help you apply them to your BU /
                  Function. For this, we will ask you a <b>series of questions</b> which will be
                  used to customize the enterprise objectives based on the specific role that your
                  BU / Function plays.
                </p>
              </div>

              <div className="intro-summary-point shadow">
                <div className="intro-summary-title">
                  <b>Additional Objectives</b>
                </div>

                <p className="intro-summary-details">
                  You will have the ability to{' '}
                  <b>add objectives that might not be present in the Enterprise Strategy</b>, but
                  are important for your specific BU / Function (e.g. upgrading a specific core
                  system / process). These objectives will only be visible to Enterprise leaders and
                  Team leaders within your BU / Function.
                </p>
              </div>
            </div>
          </section>

          <section className="leadership-step enterprise-strategy-step hidden">
            <h2>Let's start by walking you through our Enterprise Strategy</h2>

            <table className="shadow">
              <tbody>
                <tr>
                  <th>What</th>
                  <th>Why</th>
                  <th>How</th>
                  <th>Description</th>
                </tr>

                <tr>
                  <td>Grow 3P business</td>
                  <td>
                    3P sales enable us to expand the long-tail of our product catalogue without
                    massive investment
                  </td>
                  <td>
                    Our 3P approach currently trails the competition across{' '}
                    <b>systems and processes</b>. Our objective is to rapidly modernize these.
                  </td>
                  <td className="button-cell">
                    <button className="big-btn">Watch Video</button>
                    <button className="big-btn">See Presentation</button>
                  </td>
                </tr>
                <tr>
                  <td>Improve price competitiveness</td>
                  <td>
                    <span>
                      Over the past 3 years, our price gap has consistently fallen vis-a-vis the
                      competition
                    </span>
                    <br />
                    <br />
                    <span>
                      This has had an adverse impact on our market share, whhich has fallen by 2%
                    </span>
                  </td>
                  <td>
                    We hope to increase our price gap by making{' '}
                    <b>smarter data-based decisions and leaning out our costs</b>
                  </td>
                  <td className="button-cell">
                    <button className="big-btn">Watch Video</button>
                    <button className="big-btn">See Presentation</button>
                  </td>
                </tr>
                <tr>
                  <td>Transform operating moel to be more Agile</td>
                  <td>
                    <span>
                      An analysis of our operating model rans us in the second quartile in terms of
                      agility vis-a-vis other organizations
                    </span>
                    <br />
                    <br />
                    <span>
                      This drastically reduces our ability to rapidly respond to changing market
                      conditions
                    </span>
                  </td>
                  <td>
                    We plan to deploy a
                    <b> set of cross-functional teams across critical customer-facing activities</b>
                    , that will be empowered to make rapid decisions without bureacracy
                  </td>
                  <td className="button-cell">
                    <button className="big-btn">Watch Video</button>
                    <button className="big-btn">See Presentation</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>

          <section className="leadership-step leadership-quiz hidden">
            <h2>Let's test your knowledge of our Enterprise Strategy</h2>

            <div className="quiz-container shadow">
              <span className="reminder">
                You need to score 80% to get certified in our strategy
              </span>

              <b className="question">Question 1/5: Why do we want to grow our 3P business?</b>

              <div className="answers">
                <div className="answer">
                  <div className="answer-circle"></div>
                  <span>To be able to increase our revenue</span>
                </div>
                <div className="answer">
                  <div className="answer-circle"></div>
                  <span>To simplify our supply chain</span>
                </div>
                <div className="answer">
                  <div className="answer-circle"></div>
                  <span>To deliver on market commitments</span>
                </div>
                <div className="answer">
                  <div className="answer-circle"></div>
                  <span>To expand the long tail of our Product assortment</span>
                </div>
              </div>
            </div>
          </section>

          <section className="leadership-step hidden">
            <h2>Let's help you customize objectives for your BU / Function</h2>

            <div className="option-select-container">
              <b className="option-select-title">
                Select the objectives that apply to your BU / Function
              </b>

              <div className="option-div-container">
                <div className="option-div shadow">
                  <input type="checkbox" />
                  <span>Grow 3P business</span>
                </div>
              </div>
              <div className="option-div-container">
                <div className="option-div shadow">
                  <input type="checkbox" />
                  <span>Improve price competitiveness</span>
                </div>
              </div>
              <div className="option-div-container">
                <div className="option-div shadow">
                  <input type="checkbox" />
                  <span>Transform operating model to be more agile</span>
                </div>
              </div>
            </div>
          </section>

          <section className="leadership-step hidden">
            <h2>Let's figure out what products your objectives apply to</h2>

            <table className="shadow">
              <tbody>
                <tr>
                  <th>Objective</th>
                  <th>Relevant Products</th>
                </tr>
                <tr>
                  <td>Improve price competitiveness</td>
                  <td>
                    <select>
                      <option value="1">Product 1</option>
                      <option value="2">Product 2</option>
                      <option value="3">Product 3</option>
                      <option value="4">Product 4</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Transform operating model to be more agile</td>
                  <td>
                    <select>
                      <option value="1">Product 1</option>
                      <option value="2">Product 2</option>
                      <option value="3">Product 3</option>
                      <option value="4">Product 4</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>

          {/* <section className="leadership-step hidden">
            <h2>Let's figure out what activities your objectives apply to</h2>
  
            <table className="shadow">
              <tbody>
                <tr>
                  <th>Objective</th>
                  <th>Relevant Activities</th>
                </tr>
  
                <tr>
                  <td>Transform operating model to be more agile</td>
                  <td>
                    <select>
                      <option value="1">Activity 1</option>
                      <option value="2">Activity 2</option>
                      <option value="3">Activity 3</option>
                      <option value="4">Activity 4</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </section> */}

          <section className="leadership-step objectives-apply-step hidden">
            <h2>Let's determine how your objectives apply</h2>

            <ObjectivesStep />

            {/* <div className="how-to-define-text-container">
              <b className="define-text-title">How to define an objective</b>

              <div className="text-section shadow">
                <b>Every objective has 3 components:</b>
                <br />
                <br />
                <span>
                  A <b className="verb">verb</b> that indicates an action that needs to be performed
                </span>
                <br />
                <br />
                <span>
                  An optional <b className="descriptor">descriptor</b> for the action being
                  performed
                </span>
                <br />
                <br />
                <span>
                  A <b className="subject">subject</b> on which the action is performed
                </span>
              </div>

              <div className="text-section shadow">
                <b>For Example:</b>
                <br />
                <br />

                <span>
                  <b className="verb">Discover </b>
                  <b className="descriptor">customer needs</b> for
                  <b className="subject"> online store</b>
                </span>
                <br />
                <br />

                <span>
                  <b className="verb">Source</b>
                  <b className="subject"> designers</b>
                </span>
              </div>

              <p>
                For the Product you've selected that the objective applies to, let's figure out{' '}
                <b>how it applies</b>
              </p>
            </div>

            <table className="shadow">
              <tbody>
                <tr>
                  <th>Product</th>
                  <th>Verb</th>
                  <th>Descriptor</th>
                  <th>Subject</th>
                </tr>

                <tr>
                  <td>Apparel</td>
                  <td>
                    <select defaultValue="1">
                      <option value="1">Increase</option>
                      <option value="2">Decrease</option>
                      <option value="3">Negotiatate</option>
                      <option value="4">Sell</option>
                    </select>
                  </td>
                  <td>
                    <input type="text" defaultValue="price gap for" />
                  </td>
                  <td>
                    <select defaultValue="1">
                      <option value="1">Apparel</option>
                      <option value="2">Bakery</option>
                    </select>
                  </td>
                </tr>

                <tr>
                  <td>Bakery</td>
                  <td>
                    <select defaultValue="3">
                      <option value="1">Increase</option>
                      <option value="2">Decrease</option>
                      <option value="3">Negotiatate</option>
                      <option value="4">Sell</option>
                    </select>
                  </td>
                  <td>
                    <input type="text" defaultValue="better raw material rates" />
                  </td>
                  <td>
                    <select defaultValue="2">
                      <option value="1">Apparel</option>
                      <option value="2">Bakery</option>
                    </select>
                  </td>
                </tr>
              </tbody>

              <button className="add-btn">
                <img className="plus" src={plus} alt="Graphic of plus sign" />
                <span>ADD OBJECTIVE</span>
              </button>
            </table> */}
          </section>

          <section className="leadership-step hidden">
            <h2>Let's determine what impact measures will be affected by your objectives</h2>

            <table className="shadow">
              <tbody>
                <tr>
                  <th>Objective</th>
                  <th>Impact Measure</th>
                </tr>

                <tr>
                  <td>Increase price gap for apparel</td>
                  <td>
                    <select>
                      <option value="1">Instore traffic</option>
                      <option value="2">Margin</option>
                      <option value="3">SG&A</option>
                    </select>
                  </td>
                </tr>

                <tr>
                  <td>Negotiate better raw material rates for Bakery</td>
                  <td>
                    <select>
                      <option value="2">Margin</option>
                      <option value="1">Instore traffic</option>
                      <option value="3">SG&A</option>
                    </select>
                  </td>
                </tr>

                <tr>
                  <td>Setup cross-functional promo planning teams</td>
                  <td>
                    <select>
                      <option value="3">SG&A</option>
                      <option value="1">Instore traffic</option>
                      <option value="2">Margin</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>

          {/* <section className="leadership-step objectives-apply-step hidden">
            <h2>Let's determine any objectives you might have for your Function</h2>

            <div className="how-to-define-text-container">
              <b className="define-text-title">How to define an objective</b>

              <div className="text-section shadow">
                <b>Every objective has 3 components:</b>
                <br />
                <br />
                <span>
                  A <b className="verb">verb</b> that indicates an action that needs to be performed
                </span>
                <br />
                <br />
                <span>
                  An optional <b className="descriptor">descriptor</b> for the action being
                  performed
                </span>
                <br />
                <br />
                <span>
                  A <b className="subject">subject</b> on which the action is performed
                </span>
              </div>

              <div className="text-section shadow">
                <b>For Example:</b>
                <br />
                <br />

                <span>
                  <b className="verb">Discover </b>
                  <b className="descriptor">customer needs</b> for
                  <b className="subject"> online store</b>
                </span>
                <br />
                <br />

                <span>
                  <b className="verb">Source</b>
                  <b className="subject"> designers</b>
                </span>
              </div>

              <p>
                For the Product you've selected that the objective applies to, let's figure out{' '}
                <b>how it applies</b>
              </p>
            </div>

            <table className="shadow">
              <tbody>
                <tr>
                  <th>Verb</th>
                  <th>Descriptor</th>
                  <th>Subject</th>
                </tr>

                <tr>
                  <td>
                    <select defaultValue="3">
                      <option value="1">Increase</option>
                      <option value="2">Decrease</option>
                      <option value="3">Negotiatate</option>
                      <option value="4">Sell</option>
                    </select>
                  </td>
                  <td>
                    <input type="text" defaultValue="better raw material rates" />
                  </td>
                  <td>
                    <select defaultValue="2">
                      <option value="1">Apparel</option>
                      <option value="2">Bakery</option>
                    </select>
                  </td>
                </tr>
              </tbody>

              <button className="add-btn">
                <img className="plus" src={plus} alt="Graphic of plus sign" />
                <span>ADD OBJECTIVE</span>
              </button>
            </table>
          </section>

          <section className="leadership-step hidden">
            <h2>Let's determine what impact measures will be affected by your custom objectives</h2>

            <table className="shadow">
              <tbody>
                <tr>
                  <th>Objective</th>
                  <th>Impact Measure</th>
                </tr>

                <tr>
                  <td>Increase market share for bakery</td>
                  <td>
                    <select>
                      <option value="1">Instore traffic</option>
                      <option value="2">Margin</option>
                      <option value="3">SG&A</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </section> */}

          <div className="dual-button-container">
            <button className="dual-back-btn dual-btn">
              <img
                className="nav-arrow previous"
                src={next}
                alt="Arrow indication a navigation backwards."
              />
              <span>BACK</span>
            </button>

            <button className="dual-next-btn dual-btn">
              <img
                className="nav-arrow next"
                src={next}
                alt="Arrow indication a navigation forward."
              />
              <span>NEXT</span>
            </button>
          </div>
        </div>
      </ApplicationLayout>
    )
  }
}

export default Leadership

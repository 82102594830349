import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getAreaEmployees = async () => {
  const { data } = await axios.get(`${BASE_URL}/area_employees`)
  return data
}

export default function useGetAreaEmployees() {
  return useQuery(['employees-list'], () => getAreaEmployees(), {
    refetchOnWindowFocus: false,
  })
}

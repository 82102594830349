import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const addEmployee = async (employeeData) => {
  const { data } = await axios.post(`${BASE_URL}/add_employee`, employeeData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export default function useAddEmployee() {
  return useMutation((data) => addEmployee(data))
}

import React from 'react'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/core.css'
import { useTranslation } from 'react-i18next'
import { meetingCommentUpdate } from '../../api/services/meetingTopic.services'
import UpdateTextCommentModal from './updateTextCommentModal'
import Swal from 'sweetalert2'
import './styles.scss'

function CommentsDropdownMenu(props) {
  const { t } = useTranslation(['Common'])
  const [isUpdateTextCommentModalOpen, setIsUpdateTextCommentModalOpen] = React.useState(false)
  // const [mentionsList, setMentionsList] = React.useState([])
  const [mentionState, setMentionState] = React.useState([])

  const deleteComment = async () => {
    const resultConfirm = await Swal.fire({
      title: t('Common:modalMessages.areYouSureYouWantToDeleteThisComment'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })
    if (resultConfirm.isConfirmed) {
      const result = await meetingCommentUpdate(props.commentId, { isDeleted: 'true' })
      if (result) {
        // Toast.fire({
        //   icon: 'success',
        //   title: t('Common:modalMessages.deletedSuccessfully'),
        // })
        if (props.reloadFunction != undefined && props.reloadFunction != null) {
          props.reloadFunction()
        }
      }
    }
  }

  const updateComment = async (commentText) => {
    let data = { commentText: commentText }
    let mentions =
      mentionState.find((mention) => mention.id === props.commentId)?.mentions.map((m) => m.id) ??
      null
    if (mentions) {
      data.mentions = mentions
    }
    const result = await meetingCommentUpdate(props.commentId, data)

    if (result) {
      if (props.reloadFunction != undefined && props.reloadFunction != null) {
        props.reloadFunction()
      }
      //   Toast.fire({
      //     icon: 'success',
      //     title: t('Common:modalMessages.updatedSuccessfully'),
      //   })
    } else {
      //   Toast.fire({
      //     icon: 'error',
      //     title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      //   })
    }
  }

  return (
    <div>
      {isUpdateTextCommentModalOpen && (
        <UpdateTextCommentModal
          closeModal={() => {
            setIsUpdateTextCommentModalOpen()
          }}
          updateComment={updateComment}
          originalCommentText={props.commentText}
          isLoading={props.isCommentLoading}
          userList={props.userList}
          setMentionState={setMentionState}
          commentId={props.commentId}
          mentionState={mentionState}
        />
      )}
      <Menu
        menuClassName="comments-menu"
        menuButton={
          <MenuButton style={{ backgroundColor: 'white', border: 'none', padding: '0' }}>
            <span style={{ alignSelf: 'center' }} class="material-symbols-outlined">
              more_vert
            </span>
          </MenuButton>
        }
      >
        <>
          {
            <MenuItem
              onClick={() => {
                setIsUpdateTextCommentModalOpen(true)
              }}
            >
              {t('commonButtons.edit')}
            </MenuItem>
          }

          {
            <MenuItem
              onClick={() => {
                deleteComment()
              }}
            >
              {t('commonButtons.deleteLowercase')}
            </MenuItem>
          }
        </>
      </Menu>
    </div>
  )
}

export default CommentsDropdownMenu

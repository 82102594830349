import React from 'react'
import { useDemandIntakeContext } from '../../context/demandIntakeContext'
import useSuggestedObjectiveData from '../../../../api/query/useSuggestedObjectiveData'
import InPageLoader from '../../../../components/InPageLoader'
import { useTranslation } from 'react-i18next'
import { redirectToHome } from '../../../../utils/auth'

// TODO(ali): Ideally much of this stuff should be happening in the parent component. The load
// times are very high right now because first the parent loads some data related to other
// objectives, and then after they have loaded, this component loads the suggested objective data.
const ReviewAssessObjective = () => {
  const { t } = useTranslation(['Dashboard'])
  const { state, dispatch } = useDemandIntakeContext()

  const [suggestedObjIdH, setSuggestedObjIdH] = React.useState(null)
  const [displayedSuggestion, setDisplayedSuggestion] = React.useState(null)

  const { data: suggestionData, isLoading: suggestionLoading } =
    useSuggestedObjectiveData(suggestedObjIdH)

  // Wrap all of this in a useEffect so that it triggers only once per component-mount.
  React.useEffect(() => {
    let demandIntakeActionObj = null

    if (state.locationState !== null && state.locationState !== undefined) {
      if (
        state.locationState.hasOwnProperty('createNewObj') &&
        state.locationState.createNewObj === true
      ) {
        demandIntakeActionObj = {
          createNewObj: true,
          action: null,
          details: null,
          suggestedObjId: null,
        }

        dispatch({ type: 'UPDATE_DEMAND_INTAKE_ACTION', demandIntakeAction: 'createNewObj' })
      } else if (state.locationState.hasOwnProperty('action')) {
        const suggestedObjectiveId =
          state.locationState.action.suggestedObjectiveAssignedAction.suggestedObjId
        demandIntakeActionObj = {
          reviewSuggestedObjective: true,
          suggestedObjId: suggestedObjectiveId,
        }

        dispatch({
          type: 'UPDATE_DEMAND_INTAKE_ACTION',
          demandIntakeAction: 'reviewSuggestedObjective',
        })
        setSuggestedObjIdH(demandIntakeActionObj?.suggestedObjId)
      } else if (
        state.locationState.hasOwnProperty('editObj') &&
        state.locationState.editObj === true
      ) {
        state.locationState.objective = {
          ...state.locationState.objective,
          copyAllocatedFte: state.locationState.objective.allocatedFte,
          copyRequiredFte: state.locationState.objective.requiredFte,
        }
        demandIntakeActionObj = {
          editObj: true,
          suggestedObjId: null,
          objective: state.locationState.objective,
          teamLeadId: state.locationState.tid,
        }

        dispatch({ type: 'UPDATE_DEMAND_INTAKE_ACTION', demandIntakeAction: 'editObj' })
        dispatch({
          type: 'SET_OBJ_ASSOCIATION',
          objectiveAssociation: state.locationState.objective,
        })
      } else if (
        state.locationState.hasOwnProperty('activityBoardCreateNewObj') &&
        state.locationState.activityBoardCreateNewObj === true
      ) {
        demandIntakeActionObj = {
          activityBoardCreateNewObj: true,
          action: null,
          details: null,
          suggestedObjId: null,
        }

        dispatch({
          type: 'UPDATE_DEMAND_INTAKE_ACTION',
          demandIntakeAction: 'activityBoardCreateNewObj',
        })
      }
    }

    dispatch({
      type: 'UPDATE_DEMAND_INTAKE_ACTION_OBJ',
      demandIntakeActionObj: demandIntakeActionObj,
    })

    if (
      demandIntakeActionObj.hasOwnProperty('createNewObj') ||
      demandIntakeActionObj.hasOwnProperty('editObj') ||
      demandIntakeActionObj.hasOwnProperty('activityBoardCreateNewObj')
    ) {
      dispatch({ type: 'OPEN_OBJ_FORM_MODAL' })
    }

    // If `demandIntakeActionObj` is still null, then there is a big problem and we should redirect
    // to the homepage after showing an error.
    if (demandIntakeActionObj === null) {
      redirectToHome()
    }
  }, [])

  React.useEffect(() => {
    if (suggestionData) {
      state.suggestedObjective = suggestionData.action + ' ' + suggestionData.details
      state.suggestionComment = suggestionData.comment

      setDisplayedSuggestion({
        suggestedObjective: state.suggestedObjective,
        suggestionComment: state.suggestionComment,
      })
    }
  }, [suggestionData])

  // TODO(ali): If we cannot load the data, show an error.
  if (suggestedObjIdH !== null && suggestionLoading) {
    return <InPageLoader />
  }

  return (
    <>
      {state.demandIntakeActionObj?.hasOwnProperty('reviewSuggestedObjective') && (
        <div className="step-section">
          <h2>{t('reviewAssessObjective.reviewSuggestedObjective')}</h2>
          <div className="blocking-instructions"></div>
          <table className="blocking-table shadow">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              <span>
                <b>{t('reviewAssessObjective.objective')}:</b>{' '}
                {displayedSuggestion?.suggestedObjective}
              </span>

              <br></br>
              <span>
                <b>{t('reviewAssessObjective.comment')}:</b>{' '}
                {displayedSuggestion?.suggestionComment}
              </span>
            </div>
          </table>
          <div style={{ margin: '3rem 0 0 0' }}>
            <b>{t('reviewAssessObjective.question')}</b>
          </div>
        </div>
      )}
    </>
  )
}

export default ReviewAssessObjective

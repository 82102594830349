import React, { useState, useRef } from 'react'
import { Formik, Field, Form } from 'formik'
import VerbDataList from '../../../../utils/VerbDataList'
import { useSetupWizardContext } from '../../context/setupWizardContext'
import './styles.css'
import ObjectiveFormSchema from './ObjectiveFormSchema'
import useValidateGoal from '../../../../api/mutation/useValidateGoal'
import { useOutletContext } from 'react-router-dom'
import { LeadersContainer } from '../../../Governance/components/ManageLeaders/styles'
import ModalSaveButton from '../../../../components/ModalSubmitButton'

const ObjectiveForm = ({ goalType, goalKey, setResetForm }) => {
  const { state, dispatch } = useSetupWizardContext()
  const { mutateAsync, isLoading } = useValidateGoal()

  const editObjective = React.useMemo(() => {
    if (state.editObjectiveIndex !== null) {
      return state.data[goalKey][state.editObjectiveIndex]
    } else {
      return null
    }
  }, [state.editObjectiveIndex, state.data[goalKey]])

  const [setErrorMessage] = useOutletContext()

  const resetErrorMessage = () => {
    setErrorMessage(null)
  }

  const handleSubmit = async (values, resetForm) => {
    values.statement = values.statement.charAt(0).toUpperCase() + values.statement.slice(1)
    const result = await mutateAsync(`${values.statement}`)

    if (editObjective !== null) {
      let objectiveToEdit = {
        ...editObjective,
        ownerName: values.ownerName,
        ownerTitle: values.ownerTitle,
        statement: values.statement,
        isValid: result.is_valid,
      }
      if (goalType === 'TOP_LEVEL') {
        objectiveToEdit = {
          ...objectiveToEdit,
          what: values.what,
          why: values.why,
          how: values.how,
        }
        dispatch({
          type: 'UPDATE_TOP_LEVEL_OBJECTIVE',
          objective: objectiveToEdit,
          objectiveIndex: state.editObjectiveIndex,
          statement: values.statement,
        })
      } else if (goalType === 'AREA_LEVEL') {
        objectiveToEdit = {
          ...objectiveToEdit,
          linkedTopLevelGoal: values.linkedTopLevelGoal,
        }
        dispatch({
          type: 'UPDATE_AREA_LEVEL_OBJECTIVE',
          objective: objectiveToEdit,
          objectiveIndex: state.editObjectiveIndex,
          statement: values.statement,
        })
      }
    } else {
      if (goalType === 'TOP_LEVEL') {
        dispatch({
          //chnage this
          type: `ADD_TOP_LEVEL_GOAL`,
          ownerName: values.ownerName,
          ownerTitle: values.ownerTitle,
          statement: values.statement,
          isValid: result.is_valid,
          what: values.what,
          why: values.why,
          how: values.how,
        })
      } else {
        dispatch({
          type: `ADD_AREA_LEVEL_GOAL`,
          ownerName: values.ownerName,
          ownerTitle: values.ownerTitle,
          statement: values.statement,
          isValid: result.is_valid,
          linkedTopLevelGoal: values.linkedTopLevelGoal,
        })
      }
    }
    dispatch({ type: 'CLOSE_OBJ_FORM_MODAL' })
    resetForm()
  }

  const [verbOptions, setVerbOptions] = useState([])

  const handleVerbSuggestions = ({ target }) => {
    if (target.value) {
      const filteredOptions = VerbDataList.filter((option) =>
        option.toLowerCase().startsWith(target.value.toLowerCase()),
      )
      setVerbOptions(filteredOptions)
    } else {
      setVerbOptions([])
    }
  }

  const formikRef = React.useRef()

  React.useEffect(() => {
    if (formikRef.current !== undefined) {
      setResetForm(() => formikRef.current.resetForm)
    }
  }, [formikRef])

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={{
        ownerName: editObjective?.ownerName ?? '',
        ownerTitle: editObjective?.ownerTitle ?? '',
        statement: editObjective?.statement ?? '',
        linkedTopLevelGoal:
          goalType === 'AREA_LEVEL'
            ? editObjective?.linkedTopLevelGoal
              ? editObjective?.linkedTopLevelGoal
              : ''
            : '   ',
        what: goalType === 'TOP_LEVEL' ? (editObjective?.what ? editObjective?.what : '') : '   ',
        why: goalType === 'TOP_LEVEL' ? (editObjective?.why ? editObjective?.why : '') : '   ',
        how: goalType === 'TOP_LEVEL' ? (editObjective?.how ? editObjective?.how : '') : '   ',
      }}
      validationSchema={ObjectiveFormSchema}
      onSubmit={(values, { resetForm }) => {
        handleSubmit(values, resetForm)
      }}
    >
      {({ errors, touched, resetForm }) => (
        <Form className="objective-form setup-wizard-objective-form">
          <div>
            {/* 
            <div className="form-field">
              <label>Owner Title</label>
              <Field type="text" id="ownerTitle" name="ownerTitle" autoComplete="off" />
              {errors.ownerTitle && touched.ownerTitle && (
                <div className="validation-error">{errors.ownerTitle}</div>
              )}
            </div> */}

            {goalType === 'AREA_LEVEL' ? (
              <>
                <div className="form-field">
                  <label>Owner Name</label>
                  <Field as="select" id="ownerName" name="ownerName" autoComplete="off">
                    <option value="">Select An Owner</option>
                    {state.data.areaLeader.map((leader, index) => (
                      <option value={leader.name} key={`leader-${index}`} id={leader.name}>
                        {leader.name}
                      </option>
                    ))}
                  </Field>
                  {errors.ownerName && touched.ownerName && (
                    <div className="validation-error">{errors.ownerName}</div>
                  )}
                </div>
                <div className="form-field">
                  <label>Enterprise Objective Linked to</label>
                  <Field as="select" id="linkedTopLevelGoal" name="linkedTopLevelGoal">
                    <option value="">Select Enterprise Objective</option>
                    <option value="0">Not linked to an enterprise objective</option>
                    {state.data.topLevelObjectives.map((objective, objectiveIndex) => {
                      return (
                        <option
                          key={`level1-${objectiveIndex}`}
                          id={objective.statement}
                          value={objective.statement}
                        >
                          {objective.statement}
                        </option>
                      )
                    })}
                  </Field>
                  {errors.linkedTopLevelGoal && touched.linkedTopLevelGoal && (
                    <div className="validation-error">{errors.linkedTopLevelGoal}</div>
                  )}
                </div>
              </>
            ) : (
              ''
            )}

            {goalType === 'TOP_LEVEL' ? (
              <>
                <div className="form-field">
                  <label>Owner Name</label>
                  <Field as="select" id="ownerName" name="ownerName" autoComplete="off">
                    <option value="">Select An Owner</option>
                    {state.data.enterpriseLeader.map((leader, index) => (
                      <option value={leader.name} key={`leader-${index}`} id={leader.name}>
                        {leader.name}
                      </option>
                    ))}
                  </Field>
                  {errors.ownerName && touched.ownerName && (
                    <div className="validation-error">{errors.ownerName}</div>
                  )}
                </div>
              </>
            ) : (
              ''
            )}

            <div className="form-field action-field">
              <label>Statement</label>
              <Field
                list="obj_verb_list"
                id="statement"
                name="statement"
                placeholder="Start typing..."
                onKeyUp={handleVerbSuggestions}
                onClick={handleVerbSuggestions}
                autoComplete="off"
              />
              {errors.statement && touched.statement && (
                <div className="validation-error">{errors.statement}</div>
              )}
              <datalist id="obj_verb_list">
                {verbOptions.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </datalist>
            </div>

            {/* <div className="form-field">
              <label>Description</label>
              <Field type="text" id="descriptor_input" name="descriptor" autoComplete="off" />
              {errors.descriptor && touched.descriptor && (
                <div className="validation-error">{errors.descriptor}</div>
              )}
            </div> */}
          </div>

          {goalType === 'TOP_LEVEL' && (
            <div>
              <div className="form-field">
                <label>What the objective means</label>
                <Field type="text" id="what" name="what" autoComplete="off" />
                {errors.what && touched.what && (
                  <div className="validation-error">{errors.what}</div>
                )}
              </div>

              <div className="form-field">
                <label>Why it matters</label>
                <Field type="text" id="why" name="why" autoComplete="off" />
                {errors.why && touched.why && <div className="validation-error">{errors.why}</div>}
              </div>

              <div className="form-field">
                <label>How we plan to accomplish it</label>
                <Field type="text" id="how" name="how" autoComplete="off" />
                {errors.how && touched.how && <div className="validation-error">{errors.how}</div>}
              </div>
            </div>
          )}

          <div className="modal-dual-btn">
            <button
              className="dual-btn negative-btn"
              onClick={() => {
                resetForm()
                dispatch({ type: 'CLOSE_OBJ_FORM_MODAL' })
                resetErrorMessage()
              }}
              type="button"
            >
              CANCEL
            </button>
            {/* <button
              className="dual-btn positive-btn"
              type="submit"
              disabled={isLoading}
              onClick={resetErrorMessage}
            >
              SAVE
            </button> */}
            <ModalSaveButton isLoading={isLoading} />
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ObjectiveForm

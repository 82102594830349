import React from 'react'
import Modal from '../Modal'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import './style.css'

const EmployeeAllocationModal = ({ isModalOpen, closeModal, employeeAlloc, teamMembers }) => {
  const { t } = useTranslation(['MeetingHub', 'Common'])
  const [employeeFilterValue, setEmployeeFilterValue] = React.useState(null)
  const [valueToDisplay, setValueToDisplay] = React.useState([])
  const [totalAllocation, setTotalAllocation] = React.useState(0)

  React.useEffect(() => {
    if (employeeFilterValue !== null && employeeAlloc[employeeFilterValue.name] !== undefined) {
      setValueToDisplay(employeeAlloc[employeeFilterValue.name])
    } else {
      setValueToDisplay([])
    }
  }, [employeeFilterValue])

  React.useEffect(() => {
    if (valueToDisplay.length > 0) {
      setTotalAllocation(valueToDisplay.reduce((acc, obj) => acc + obj.allocation, 0))
    } else {
      setTotalAllocation(0)
    }
  }, [valueToDisplay])

  const handleChangeEmployeeFilter = (option) => {
    setEmployeeFilterValue(option)
  }

  return (
    <>
      <Modal
        title={t('Common:peopleAssignmentTable.title')}
        isModalOpen={isModalOpen}
        closeModal={() => closeModal()}
        width={'60%'}
        height={'30rem'}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '0.5rem',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <label for="teamLeadersFilter">
            <b>{t('Common:filter.teamMemberFilter')}:</b>
          </label>
          <div data-testid="multi-options-1" style={{ width: '20vw', textAlign: 'left' }}>
            <Select
              value={employeeFilterValue}
              onChange={handleChangeEmployeeFilter}
              maxMenuHeight={100}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?.eId}
              options={teamMembers}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'left',
            }}
          >
            {valueToDisplay?.length > 0 ? (
              <>
                <div className="allocation-table">
                  <div className="allocation-table-header"></div>
                  <div className="allocation-table-header"></div>
                  <div className="allocation-table-header"></div>
                  {valueToDisplay?.map((allocation) => (
                    <>
                      <div></div>
                      <b>
                        <span className="allocation-display" style={{ textAlign: 'left' }}>
                          {allocation.objDesc}
                        </span>
                      </b>
                      <span className="allocation-display" style={{ marginLeft: '3rem' }}>
                        {allocation.allocation}%
                      </span>
                    </>
                  ))}
                  <>
                    <div></div>
                    <b>
                      <span className="allocation-display">Total</span>
                    </b>
                    <b>
                      <span
                        className="allocation-display"
                        style={{
                          marginLeft: '3rem',
                          color: totalAllocation > 100 ? 'red' : 'black',
                        }}
                      >
                        {totalAllocation}%
                      </span>
                    </b>
                  </>
                </div>
              </>
            ) : (
              <div style={{ marginTop: '3rem' }}>{t('Common:peopleAssignmentTable.noValue')}</div>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default EmployeeAllocationModal

import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const submitAreaMeetingCompletion = async (meetingData) => {
  const { data } = await axios.post(`${BASE_URL}/complete_area_meeting`, meetingData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export default function useSubmitAreaMeetingCompletion() {
  const queryClient = useQueryClient()
  return useMutation((data) => submitAreaMeetingCompletion(data), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      void queryClient.invalidateQueries([
        'multi-meeting',
        'f2w',
        'employees',
        'areaLeaderObjectives',
      ])
    },
  })
}

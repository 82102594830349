import React from 'react'
import './styles.css'
import employee from '../../../../../../assets/employee.svg'
import { useSetupWizardContext } from '../../../../context/setupWizardContext'
import AddAreaLeaderModalForm from './components/AddAreaLeaderModalForm'
import Modal from '../../../../../../components/Modal'

const AddAreaLeaderModal = () => {
  const { state, dispatch } = useSetupWizardContext()
  const [resetForm, setResetForm] = React.useState(null)

  const handleCancel = () => {
    dispatch({ type: 'CLOSE_EMPLOYEE_MODAL' })
    resetForm()
  }

  return (
    <>
      {state.isEmployeeModalOpen === true && (
        <Modal title={'Add an Area Leader'} closeModal={handleCancel}>
          <AddAreaLeaderModalForm handleCancel={handleCancel} setResetForm={setResetForm} />
        </Modal>
      )}
    </>
  )
}

export default AddAreaLeaderModal

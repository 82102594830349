import { useQueries } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getGovernanceData = async () => {
  const { data } = await axios.get(`${BASE_URL}/gov_status_data`)
  return data
}

// export default function useGovernanceData() {
//   return useQuery(['governance'], () => getGovernanceData(), {
//     refetchOnWindowFocus: false,
//   })
// }

const getEnterpriseMetricsData = async () => {
  const { data } = await axios.get(`${BASE_URL}/gov_enterprise_metrics`)
  return data
}

// export function useEnterpriseMetricsData() {
//   return useQuery(['enterpriseMetrics'], () => getEnterpriseMetricsData(), {
//     refetchOnWindowFocus: false,
//   })
// }

const getCompanyWideScorecard = async () => {
  const { data } = await axios.get(`${BASE_URL}/company_wide_scorecard`)
  return data
}

export default function useAllGovernanceData() {
  return useQueries({
    queries: [
      { queryKey: ['gov_status'], queryFn: () => getGovernanceData(), refetchOnWindowFocus: false },
      {
        queryKey: ['enterprise_metrics'],
        queryFn: () => getEnterpriseMetricsData(),
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['gov_scorecard'],
        queryFn: () => getCompanyWideScorecard(),
        refetchOnWindowFocus: false,
      },
    ],
  })
}

import Swal from 'sweetalert2'
import { isChromeBrowser } from '../utils/general'

export const checkForBrowser = async (t) => {
  if (!isChromeBrowser()) {
    console.log('Not Chrome Browser')
    await Swal.fire({
      title: t('Common:browserWarning'),
      icon: 'info',
      confirmButtonText: t('Common:commonButtons.close'),
      customClass: {
        title: 'swal-chrome-browser-check',
      },
    })
  }
  return true
}

import React from 'react'
import Modal from '../Modal'
import useUpdateDecisionLogOutcome from '../../api/mutation/useUpdateDecisionLogOutcome'
import Toast from '../../common/toast'
import { useTranslation } from 'react-i18next'
import { removeMentionMarkupFromText } from '../../utils/parseMarkup'
import { MentionsInput, Mention } from 'react-mentions'

const EditCommentActivityModal = ({
  isModalOpen,
  closeModal,
  commentId,
  activityId,
  textIn,
  userList = [],
}) => {
  const { t } = useTranslation(['Common'])
  const [textInput, setTextInput] = React.useState(textIn)
  const mutationUpdate = useUpdateDecisionLogOutcome()

  const textInputChangeHandler = (event) => {
    setTextInput(event.target.value)
  }

  const [errorMessageTextArea, setErrorMessageTextArea] = React.useState(null)
  const [mentionState, setMentionState] = React.useState([])

  const style = {
    input: {
      overflow: 'auto',
    },
    highlighter: {
      boxSizing: 'border-box',
      overflow: 'hidden',
      height: 70,
      border: '4px solid transparent',
    },
    textAlign: 'left',
    marginTop: '1rem',
    border: 'none',
    // padding: '0.5rem',
    minHeight: '8rem',
    backgroundColor: 'rgb(242, 242, 242)',
    fontSize: '16px',
    width: '20rem',
    suggestions: {
      list: {
        maxHeight: 75,
        overflowY: 'auto',
      },
      item: {
        padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        '&focused': {
          backgroundColor: '#d3d3d3',
        },
      },
    },
  }

  const updateOutcome = async (commentId, activityId, textInput) => {
    let data = {}
    data.commentId = commentId
    data.activityId = activityId
    data.outcome = textInput
    let id = activityId === 0 ? commentId : activityId

    let mentions =
      mentionState.find((mention) => mention.id === id)?.mentions.map((m) => m.id) ?? null
    if (mentions) {
      data.mentions = mentions
    }

    await mutationUpdate.mutateAsync(data, {
      onSuccess: () => {
        setTextInput('')
        closeModal()
        Toast.fire({
          icon: 'success',
          title:
            commentId != 0
              ? t('modalMessages.commentUpdatedSuccessfully')
              : t('modalMessages.actionUpdatedSuccessfully'),
        })
      },
      onError: () => {
        Toast.fire({
          icon: 'error',
          title: t('modalMessages.somethingWentWrongPleaseTryAgain'),
        })
      },
    })
  }

  const validate = () => {
    if (!/\S/.test(textInput)) {
      setErrorMessageTextArea(t('formError.required'))
    } else {
      updateOutcome(commentId, activityId, textInput)
    }
  }

  return (
    <>
      <Modal
        title={
          commentId != 0
            ? t('editCommentActivityModal.editComment')
            : t('editCommentActivityModal.editActivity')
        }
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        width={'30rem'}
      >
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
          <MentionsInput
            value={textInput}
            onChange={(e, v, nv, mentions) => {
              // setMentionsList(mentions)
              textInputChangeHandler(e)
              let id = activityId === 0 ? commentId : activityId
              let mention = mentionState.find((mention) => mention.id === id)
              if (mention) {
                setMentionState((prev) =>
                  prev.map((item) => (item.id === id ? { ...item, mentions: mentions } : item)),
                )
              } else {
                setMentionState([...mentionState, { id: id, mentions: mentions }])
              }
            }}
            allowSuggestionsAboveCursor={true}
            style={style}
            spellCheck={false}
          >
            <Mention
              trigger="@"
              data={userList}
              markup="@[__display__](user:__id__)"
              displayTransform={(id, display) => `@${display}`}
              // renderSuggestion={(suggestion, search, highlightedDisplay) => (
              //   <div className="user">{highlightedDisplay}</div>
              // )}
              appendSpaceOnAdd={true}
              // style={{backgroundColor:'rgb(206, 228, 229)'}}
            />
          </MentionsInput>
          <div style={{ textAlign: 'left' }} className="validation-error">
            {errorMessageTextArea}
          </div>
        </div>
        <div
          style={{
            width: 'fit-content',
            height: '1rem',
            fontSize: '12px',
            alignSelf: 'flex-end',
            marginBottom: '8px',
            marginTop: '1rem',
            backgroundColor: '#44546A',
          }}
          className="add-btn"
          onClick={() => {
            validate()
          }}
        >
          <span>{t('commonButtons.save')}</span>
        </div>
      </Modal>
    </>
  )
}

export default EditCommentActivityModal

import React from 'react'
import Joyride from 'react-joyride'
import { useOutletContext } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import { postHttpRequest } from '../../../../api/query/dynamicAPI'
import Toast from '../../../../common/toast'

const JoyrideComponent = ({ run, meetingTopicExist }) => {
  const { t } = useTranslation('MeetingHub')
  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data
    if (action === 'reset') {
      Swal.fire({
        title: t('joyRideSteps.popupTitle'),
        showCancelButton: true,
        confirmButtonText: t('joyRideSteps.yes'),
        cancelButtonText: t('joyRideSteps.no'),
        // reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          ;(async () => {
            try {
              await postHttpRequest('/edit_employees_user_preferences', {
                mhTutorialOff: true,
              })
              updateLocalStorage()
              Toast.fire({
                icon: 'success',
                title: t('Common:modalMessages.updatedSuccessfully'),
              })
            } catch (error) {
              Toast.fire({
                icon: 'error',
              })
            }
          })()
        }
      })
    }
  }
  const handleNoMeetingTopics = () => {
    if (!meetingTopicExist) {
      steps.splice(5, 12)
      steps.push(noMeetingTopic)
    }
  }

  const updateLocalStorage = () => {
    let cv = localStorage.getItem('cfg')
    let config = JSON.parse(cv)
    config.mh_tutorial_off = true
    let updatedConfig = JSON.stringify(config)
    localStorage.setItem('cfg', updatedConfig)
  }

  const noMeetingTopic = {
    content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.nomeetingtopics')}</div>,
    placement: 'right',
    target: '#noMeetingtopics',
    spotlightPadding: 1,
    scrollTo: false,
    disableBeacon: true,
    id: '1',
  }
  const steps = [
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.meetingHub0')}</div>,
      placement: 'right',
      target: '#meetingHubTutorial0',
      spotlightPadding: 1,
      scrollTo: false,
      disableBeacon: true,
      id: '0',
    },
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.meetingHub1')}</div>,
      placement: 'right',
      target: '#meetingHubTutorial1',
      spotlightPadding: 1,
      scrollTo: false,
      disableBeacon: true,
      id: '1',
    },
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.meetingHub2')}</div>,
      placement: 'right',
      target: '#meetingHubTutorial2',
      spotlightPadding: 1,
      scrollTo: false,
      disableBeacon: true,
      id: '2',
    },
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.meetingHub3')}</div>,
      placement: 'right',
      target: '#meetingHubTutorial3',
      spotlightPadding: 5,
      scrollTo: false,
      disableBeacon: true,
      id: '3',
    },
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.meetingHub4')}</div>,
      placement: 'right',
      target: '#meetingHubTutorial4',
      spotlightPadding: 1,
      scrollTo: false,
      disableBeacon: true,
      id: '4',
    },
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.meetingHub5')}</div>,
      placement: 'right',
      target: '#meetingHubTutorial5',
      spotlightPadding: 1,
      scrollTo: false,
      disableBeacon: true,
      id: '5',
    },
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.meetingHub6')}</div>,
      placement: 'right',
      target: '#meetingHubTutorial6',
      spotlightPadding: 1,
      scrollTo: false,
      disableBeacon: true,
      id: '6',
    },
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.meetingHub7')}</div>,
      placement: 'right',
      target: '#meetingTutorial7',
      spotlightPadding: 1,
      scrollTo: false,
      disableBeacon: true,
      id: '7',
    },
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.meetingHub8')}</div>,
      placement: 'right',
      target: '#meetingHubTutorial8',
      spotlightPadding: 1,
      scrollTo: false,
      disableBeacon: true,
      id: '8',
    },
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.meetingHub8a')}</div>,
      placement: 'right',
      target: '#meetingHubTutorial8a',
      spotlightPadding: 5,
      scrollTo: false,
      disableBeacon: true,
      id: '8a',
    },
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.meetingHub9')}</div>,
      placement: 'right',
      target: '#meetingHubTutorial9',
      spotlightPadding: 1,
      scrollTo: false,
      disableBeacon: true,
      id: '9',
    },
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.meetingHub10')}</div>,
      placement: 'right',
      target: '#meetingHubTutorial10',
      spotlightPadding: 1,
      scrollTo: false,
      disableBeacon: true,
      id: '10',
    },
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.meetingHub11')}</div>,
      placement: 'right',
      target: '#meetingHubTutorial11',
      spotlightPadding: 1,
      scrollTo: false,
      disableBeacon: true,
      id: '11',
    },
    {
      content: <div style={{ textAlign: 'left' }}>{t('joyRideSteps.meetingHub12')}</div>,
      placement: 'right',
      target: '#meetingHubTutorial12',
      spotlightPadding: 1,
      scrollTo: false,
      disableBeacon: true,
      id: '12',
    },
  ]
  handleNoMeetingTopics()
  return (
    <Joyride
      steps={steps}
      callback={handleJoyrideCallback}
      styles={{
        options: {
          zIndex: 300,
          spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
          textColor: '#333',
          primaryColor: '#ED522E',
        },
      }}
      debug={true}
      run={run}
      continuous
      hideCloseButton
      scrollToFirstStep
      disableScrollParentFix
      disableCloseOnEsc
      disableOverlayClose
      showSkipButton
      locale={{
        back: t('joyRideSteps.back'),
        next: t('joyRideSteps.next'),
        skip: t('joyRideSteps.skip'),
        last: t('joyRideSteps.last'),
      }}
    ></Joyride>
  )
}

export default JoyrideComponent

import React, { useState } from 'react'
import Modal from '../../../components/Modal'
import { getHttpRequest } from '../../../api/query/dynamicAPI'
import { useTranslation } from 'react-i18next'
import { dateFilter } from '../../../utils/time'
import InPageLoader from '../../../components/InPageLoader'
import Textarea from 'rc-textarea'
import useSaveSpreadsheetData from '../../../api/mutation/useSaveSpreadsheetData'
import AddEditActivityModal from '../../../components/AddEditActivityModal'
import { shortenName } from '../../../utils/general'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/core.css'
import { isDateInPast } from '../../../utils/time'
import { removeMentionMarkupFromText } from '../../../utils/parseMarkup'

const NotificationsModal = ({ closeModal, objectiveId }) => {
  const { t } = useTranslation(['ObjectiveMap'])

  const [isLoading, setIsLoading] = React.useState(false)
  const [notificationData, setNotificationData] = React.useState(null)
  const [inputDescription, setInputDescription] = useState('')
  const [isRefreshObjective, setIsRefreshObjective] = useState('')
  const [isSingleActivityModalOpen, setIsSingleActivityModalOpen] = useState(false)
  const [editActivityId, setEditActivityId] = useState(false)

  function getObjectiveTasks(objectiveId) {
    setIsLoading(true)
    getHttpRequest(`/get_objective_notification_data`, {
      params: {
        objId: objectiveId,
      },
    })
      .then((response) => {
        const data = response
        if (data && data.objectiveNotifications) {
          setNotificationData(data.objectiveNotifications)
        }
        setIsLoading(false)
      })
      .catch((error) => {})
  }

  function statusEnumStrToVal(status) {
    let d = {
      NOT_STARTED: 'Not Started',
      ON_TRACK: 'On track',
      AT_RISK: 'At risk',
      COMPLETED: 'Completed',
      DEPRIORITIZED: 'Deprioritized',
    }

    if (status in d) return d[status]
    else return ''
  }

  React.useEffect(() => {
    getObjectiveTasks(objectiveId)
  }, [objectiveId, isRefreshObjective])

  if (isLoading) {
    return (
      <Modal
        isModalOpen={true}
        closeModal={() => {
          closeModal()
        }}
        width={'90%'}
        style={{ padding: '0rem 0rem 1rem 0rem' }}
        title={t('modalTitle.notifications')}
        icon={'notifications'}
      >
        <InPageLoader inComponent={true} />
      </Modal>
    )
  }

  const generateAssigneesString = (list) => {
    const names = list.map((item) => shortenName(item.name))

    if (names?.length === 0) {
      return ''
    } else if (names?.length === 1) {
      return names[0]
    } else {
      const lastTwoNames = names.slice(-2).join(' & ')
      const namesExceptLastTwo = names.slice(0, -2).join(', ')
      return `${namesExceptLastTwo}${namesExceptLastTwo ? ', ' : ''}${lastTwoNames}`
    }
  }

  const notificationTypemessage = (obj) => {
    if (obj.notificationType === 'BLOCKERS') {
      return (
        t('notificationType.personAdded', { X: shortenName(obj.ownerName) }) +
        t('notificationType.blocker')
      )
    } else if (obj.notificationType === 'GUIDANCE') {
      return (
        t('notificationType.personAdded', { X: shortenName(obj.ownerName) }) +
        t('notificationType.guidance')
      )
    } else if (obj.notificationType === 'UPDATE') {
      return (
        t('notificationType.personAdded', { X: shortenName(obj.ownerName) }) +
        t('notificationType.update')
      )
    } else if (obj.notificationType === 'ADMIN_TOPIC') {
      return (
        t('notificationType.personAdded', { X: shortenName(obj.ownerName) }) +
        t('notificationType.update')
      )
    } else if (obj.notificationType === 'COMMENT') {
      return (
        t('notificationType.personAdded', { X: shortenName(obj.ownerName) }) +
        t('notificationType.comment')
      )
    } else if (obj.notificationType === 'ACTIVITY') {
      if (obj?.assignees?.length > 0) {
        if (obj?.ownerName) {
          return (
            t('notificationType.personAdded', { X: shortenName(obj.ownerName) }) +
            t('notificationType.activityCreated') +
            t('notificationType.activityAssigned') +
            generateAssigneesString(obj.assignees)
          )
        } else {
          return (
            t('notificationType.activityAssignedWithoutOwner') +
            generateAssigneesString(obj.assignees)
          )
        }
      } else {
        if (obj?.ownerName) {
          return (
            t('notificationType.personAdded', { X: shortenName(obj.ownerName) }) +
            t('notificationType.activityCreated')
          )
        } else {
          return t('notificationType.activityCreatedWithoutOwner')
        }
      }
    } else if (obj.notificationType === 'DECISION') {
      return (
        t('notificationType.personAdded', { X: shortenName(obj.ownerName) }) +
        t('notificationType.decision')
      )
    } else if (obj.notificationType === 'ACTION') {
      if (obj?.assignees?.length > 0) {
        if (obj?.ownerName) {
          return (
            t('notificationType.personAdded', { X: shortenName(obj.ownerName) }) +
            t('notificationType.actionCreated') +
            t('notificationType.actionAssigned') +
            generateAssigneesString(obj.assignees)
          )
        } else {
          return (
            t('notificationType.actionAssignedWithoutOwner') +
            generateAssigneesString(obj.assignees)
          )
        }
      } else {
        if (obj?.ownerName) {
          return (
            t('notificationType.personAdded', { X: shortenName(obj.ownerName) }) +
            t('notificationType.actionCreated')
          )
        } else {
          return t('notificationType.actionCreatedWithoutOwner')
        }
      }
    } else if (obj.notificationType === 'ACTIVITY_COMPLETED') {
      return t('notificationType.activityCompleted')
    } else if (obj.notificationType === 'ACTION_COMPLETED') {
      return t('notificationType.actionCompleted')
    }
  }

  return (
    <>
      <Modal
        isModalOpen={true}
        closeModal={() => {
          closeModal()
        }}
        width={'90%'}
        style={{ padding: '0rem 0rem 1rem 0rem' }}
        title={t('modalTitle.notifications')}
        icon={'notifications'}
      >
        <>
          {isSingleActivityModalOpen && (
            <AddEditActivityModal
              isModalOpen={isSingleActivityModalOpen}
              closeModal={() => {
                setIsSingleActivityModalOpen(false)
              }}
              objectiveId={objectiveId}
              activityId={editActivityId}
              setIsRefreshObjective={() => {
                setIsRefreshObjective((old) => !old)
              }}
            />
          )}
          <br />
          {isLoading && <InPageLoader inComponent={true} />}
          {isLoading === false && notificationData && notificationData?.length > 0 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                wordBreak: 'break-word',
              }}
            >
              <div style={{ width: '100%', marginBottom: '3rem' }}>
                <div className="notifications-table">
                  <div className="chart-log-meeting-header title-border">
                    {t('columnName.date')}
                  </div>
                  <div className="chart-log-meeting-header title-border">
                    {t('columnName.type')}
                  </div>
                  <div className="chart-log-meeting-header title-border">
                    {t('columnName.details')}
                  </div>
                  <div></div>

                  {notificationData.map((item, index) => {
                    return (
                      <React.Fragment key={`activity-${item?.milestoneId}`}>
                        <div className="chart-log-meeting-description"></div>
                        <>
                          <>
                            <div style={{ textAlign: 'left', paddingLeft: '0.5rem' }}>
                              {item?.createdDate == 0 ? '--' : dateFilter(item?.createdDate)}
                            </div>

                            <div style={{ textAlign: 'left' }}>{notificationTypemessage(item)}</div>
                            <div style={{ textAlign: 'left' }}>
                              {removeMentionMarkupFromText(item.description)}
                            </div>
                            <div style={{ textAlign: 'left' }}>
                              {item.topicDescription && (
                                <div class="fitted-button blue tooltip" type="button">
                                  <span class="material-symbols-outlined">info</span>
                                  <span class="tooltiptext">{item.topicDescription}</span>
                                </div>
                              )}
                            </div>

                            <div class="row-border" style={{ gridColumn: '1 / 4' }}></div>
                          </>
                        </>{' '}
                      </React.Fragment>
                    )
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div style={{ padding: '2rem' }}>There are no activities for this objective.</div>
          )}
        </>
      </Modal>
    </>
  )
}

export default NotificationsModal

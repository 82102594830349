import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import reportWebVitals from './reportWebVitals'
import AppNew from './AppNew'
import Moment from 'react-moment'
import 'moment-timezone'
import i18n from './i18n'
import { Suspense } from 'react'
import { I18nextProvider } from 'react-i18next'
import InPageLoader from './components/InPageLoader'
import * as Sentry from '@sentry/react'
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  // to be used inside integrations
  // Sentry.browserTracingIntegration(),
  integrations: [
    Sentry.replayIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['frontend.transforml.co', 'dplus.transforml.co'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <>
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<InPageLoader />}>
        {/* <React.StrictMode> */}
        <AppNew />
        {/* </React.StrictMode> */}
      </Suspense>
    </I18nextProvider>
  </>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import React, { useState, useEffect } from 'react'
import InPageLoader from '../InPageLoader'
import { useTranslation } from 'react-i18next'

const F2wAvailability = ({ employees }) => {
  const { t } = useTranslation(['Common'])

  return (
    <>
      <div className="wizard-step" style={{ margin: '0' }}>
        <h2 className="wizard-step__title">{t('f2wAvailabilityPage.title')}</h2>
        <div className="wizard-step__overview">
          <div className="wizard-step__overview__paragraph">
            {t('f2wAvailabilityPage.subtitle')}
          </div>
        </div>

        <div
          className="wizard-step__main-content-container"
          style={{ display: 'flex', minWidth: 'auto', padding: '4rem' }}
        >
          {/* Might need to re-add loader, but now the data is adding from before */}
          {/* {state.isLoading === false 
           ? ( */}
          <div className="table-wrapper-for-f2w-employees" style={{ display: 'flex' }}>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                rowGap: '1rem',
                columnGap: '2rem',
              }}
            >
              {employees?.map((emp, empIndex) => (
                <>
                  {emp?.teamAllocPct > 0 && (
                    <>
                      <div>
                        <b>{emp?.name}</b>
                      </div>
                      <div>{emp?.teamAllocPct} %</div>
                    </>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default F2wAvailability

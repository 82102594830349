import React from 'react'

const AgendaSlide = ({ agendaItems }) => {
  return (
    <div
      style={{
        height: '100%',
        padding: '20px',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      <div
        style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '20px', textAlign: 'left' }}
      >
        Agenda
      </div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        {agendaItems.map((item, index) => (
          <div
            key={index}
            style={{
              backgroundColor: 'red',
              color: 'white',
              padding: '1rem',
              marginBottom: '1.2rem',
              fontSize: '1.5rem',
              width: '100%',
              textAlign: 'left',
              flex: `0 0 ${100 / agendaItems.length}%`,
              maxWidth: '50rem',
            }}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  )
}
export default AgendaSlide

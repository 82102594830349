import React, { useState, useEffect } from 'react'
import { CardContainer, CardHeader } from './styles'
import './style.scss'
import AddMember from './common/AddMemeber'
import MoveMember from './common/MoveMember'
import Toast from '../../../../common/toast'

import {
  getTeamFlow,
  removeMemberPeopleManagment,
} from '../../../../api/services/employee.services'

import InPageLoader from '../../../../components/InPageLoader/index'
import { useGovernanceContext } from '../../context/governanceContext'
import { AdminDropdownMenu } from '../../../../components/SooDropdownMenu'

import Swal from 'sweetalert2'
import { postHttpRequest } from '../../../../api/query/dynamicAPI'
import { useTranslation } from 'react-i18next'

const ManageTeamMembers = ({ allTeamLeadersList, setRefetchData = () => {}, licenses = [] }) => {
  const [selectedTeamLeader, setSelectedTeamLeader] = useState('0')
  const { t } = useTranslation(['Common'])
  let { state, dispatch } = useGovernanceContext()

  const [moveShow, setIsMoveShow] = useState(false)
  const [addMemberShow, setAddMemberShow] = useState(false)

  // const [teamLeaderList, setTeamLeaderList] = useState([]);

  // const [teamLeaderList, setTeamLeaderList] = useState([]);

  const [teamMemerList, setTeamMemberList] = useState([])

  const [moveId, setMoveId] = useState('')

  const [isReset, setIsReset] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (selectedTeamLeader != '00000' && selectedTeamLeader != '0') {
      getTeamFlowEmployee(selectedTeamLeader)
    }
  }, [selectedTeamLeader, isReset])

  useEffect(() => {
    dispatch({
      type: 'UPDATE_TEAM_MEMBER_LIST',
      teamMembersList: teamMemerList,
    })
  }, [teamMemerList])

  async function getTeamFlowEmployee(eId) {
    setIsLoading(true)
    const result = await getTeamFlow(eId)
    setIsLoading(false)
    if (result?.employees) {
      setTeamMemberList(result.employees.filter((emp) => emp.isDeleted === false))
    }
  }

  // async function removeMember(eId, index) {
  //   if (window.confirm(`Are you sure you want to delete this team member?`)) {
  //     const temp_teamMemerList = [...teamMemerList];
  //     temp_teamMemerList.splice(index, 1)
  //     setTeamMemberList(temp_teamMemerList);
  //     setIsLoading(true)
  //     const result = await removeMemberPeopleManagment(eId);
  //     setIsLoading(false)
  //     if (result) {
  //       setIsReset((old) => !old);
  //       setRefetchData(prev => !prev)
  //     }
  //   }
  // }

  function moveMember(eId) {
    setMoveId(eId)
    setIsMoveShow(true)
  }

  function dropDownChangedHandler(event) {
    setSelectedTeamLeader(event.target.value)
  }

  // if (isLoading) {
  //   return <> <InPageLoader inComponent={true}/> </>
  // }

  return (
    <>
      <div
        style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem', marginBottom: '50px' }}
      >
        <CardContainer>
          <CardHeader>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '1rem',
                width: '100%',
                position: 'relative',
              }}
            >
              <div style={{ display: 'flex' }}>
                Team Members for
                <div style={{ marginLeft: 'auto' }}>
                  {selectedTeamLeader !== '00000' && selectedTeamLeader != '0' && (
                    <button
                      type="button"
                      className="fitted-button blue"
                      style={{ background: 'white', padding: '0.2rem', fontWeight: 'bold' }}
                      onClick={() => {
                        let license = licenses.find((license) => license.name === 'teamMembers')
                        if (license && license.value <= license.used) {
                          Toast.fire({
                            icon: 'error',
                            title:
                              'You do not currently have available licenses to add another user',
                          })
                          return
                        }
                        setAddMemberShow(true)
                      }}
                    >
                      <span className="material-symbols-outlined icon-size">
                        <span
                          class="material-symbols-outlined"
                          style={{ fontWeight: 'bold', fontSize: '20px' }}
                        >
                          add
                        </span>
                      </span>
                    </button>
                  )}
                </div>
              </div>
              <select
                className="accountable-select"
                onChange={dropDownChangedHandler}
                value={selectedTeamLeader}
                style={{ width: '30%', height: '2rem' }}
              >
                <option value="00000" key="00000">
                  Select Team Leader
                </option>
                {allTeamLeadersList?.map((employee, index) => (
                  <option value={employee?.eId} key={`${employee?.eId}`}>
                    {employee?.name}
                  </option>
                ))}
              </select>
              <div className="underline-admin"></div>
            </div>
          </CardHeader>
          <div className="table-wrapper" style={{ justifyContent: 'center', alignItems: 'center' }}>
            {isLoading && (
              <div>
                <InPageLoader inComponent={true} />
              </div>
            )}
            {!isLoading && (
              <div
                style={{
                  width: '100%',
                  marginLeft: '5rem',
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr 0.5fr',
                }}
              >
                <div
                  style={{
                    margin: '0.5rem 0.5rem',
                    textAlign: 'left',
                    alignItems: 'normal',
                    justifyContent: 'normal',
                    width: '100%',
                  }}
                  className="widget-grid-title"
                >
                  Name
                </div>
                <div
                  style={{
                    margin: '0.5rem 0.5rem',
                    textAlign: 'left',
                    alignItems: 'normal',
                    justifyContent: 'normal',
                    width: '100%',
                  }}
                  className="widget-grid-title"
                >
                  Title
                </div>
                <div
                  style={{
                    margin: '0.5rem 0.5rem',
                    textAlign: 'left',
                    alignItems: 'normal',
                    justifyContent: 'normal',
                    width: '100%',
                  }}
                  className="widget-grid-title"
                >
                  Email
                </div>
                <div
                  style={{ margin: '0.5rem 0.5rem', width: '8rem' }}
                  className="widget-grid-title"
                ></div>
                {selectedTeamLeader !== '00000' &&
                  selectedTeamLeader != '0' &&
                  teamMemerList &&
                  !isLoading &&
                  teamMemerList.map((teamMember, index) => (
                    <React.Fragment key={`TM-${teamMember?.eId}`}>
                      <div
                        style={{
                          color: teamMember?.isDeactivated ? '#BBBCBC' : 'black',
                          margin: '0.5rem 0.5rem',
                          textAlign: 'left',
                          alignItems: 'normal',
                          justifyContent: 'normal',
                          width: '90%',
                          wordBreak: 'break-word',
                          wordWrap: 'break-word',
                        }}
                        className="widget-grid-item"
                      >
                        {teamMember?.name}
                      </div>
                      <div
                        style={{
                          color: teamMember?.isDeactivated ? '#BBBCBC' : 'black',
                          margin: '0.5rem 0.5rem',
                          textAlign: 'left',
                          alignItems: 'normal',
                          justifyContent: 'normal',
                          width: '90%',
                          wordBreak: 'break-word',
                          wordWrap: 'break-word',
                        }}
                        className="widget-grid-item"
                      >
                        {teamMember?.title}
                      </div>
                      <div
                        style={{
                          color: teamMember?.isDeactivated ? '#BBBCBC' : 'black',
                          margin: '0.5rem 0.5rem',
                          textAlign: 'left',
                          alignItems: 'normal',
                          justifyContent: 'normal',
                          width: '90%',
                          wordBreak: 'break-word',
                          wordWrap: 'break-word',
                        }}
                        className="widget-grid-item"
                      >
                        {teamMember?.email}
                      </div>
                      <div
                        style={{
                          margin: '0.5rem 0.5rem',
                          textAlign: 'left',
                          alignItems: 'normal',
                          justifyContent: 'normal',
                        }}
                        className="widget-grid-item"
                      >
                        <AdminDropdownMenu
                          // hasEdit={false}
                          hasDelete={true}
                          hasDeactivate={!teamMember?.isDeactivated}
                          hasMove={true}
                          onClickEdit={() => {
                            dispatch({
                              type: 'OPEN_TEAM_MEMBER_MODAL',
                              editTeamMemberIndex: index,
                              teamMembersList: state.data.teamMembersList,
                              editAreaLeaderIndex: null,
                              editCsuiteLeaderIndex: null,
                              editCeoLeaderIndex: null,
                              editTeamLeaderIndex: null,
                            })
                          }}
                          onClickChangeEmployeeState={async () => {
                            const result = await Swal.fire({
                              title: teamMember?.isDeactivated
                                ? t('Common:modalMessages.areYouSureReactivate')
                                : t('Common:modalMessages.areYouSureDeactivate'),
                              icon: 'question',
                              showCancelButton: true,
                              confirmButtonText: t('Common:commonButtons.yes'),
                              cancelButtonText: t('Common:commonButtons.no'),
                            })
                            if (result.isConfirmed) {
                              try {
                                await postHttpRequest(`/edit_employee_state/${teamMember.eId}`, {
                                  isDeactivated: teamMember.isDeactivated,
                                })
                                setRefetchData((prev) => !prev)
                                Toast.fire({
                                  icon: 'success',
                                  title: t('Common:modalMessages.updatedSuccessfully'),
                                })
                              } catch (error) {
                                Toast.fire({
                                  icon: 'error',
                                  title: t('Common:modalMessages.error'),
                                })
                              }
                            }
                          }}
                          onClickDelete={async () => {
                            const result = await Swal.fire({
                              title: t('Common:modalMessages.areYouSureDeleteThis'),
                              icon: 'question',
                              showCancelButton: true,
                              confirmButtonText: t('Common:commonButtons.yes'),
                              cancelButtonText: t('Common:commonButtons.no'),
                            })
                            if (result.isConfirmed) {
                              try {
                                let res = await postHttpRequest(
                                  `/delete_employee/${teamMember.eId}`,
                                )
                                if (res.success) {
                                  setRefetchData((prev) => !prev)
                                  Toast.fire({
                                    icon: 'success',
                                    title: t('Common:modalMessages.deletedSuccessfully'),
                                  })
                                } else {
                                  Toast.fire({
                                    icon: 'error',
                                    title: t('Common:modalMessages.youCannotDeleteThis'),
                                  })
                                }
                              } catch (error) {
                                Toast.fire({
                                  icon: 'error',
                                  title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
                                })
                              }
                            }
                          }}
                          onClickMove={() => moveMember(teamMember.eId)}
                        />
                        {/* <div
                      class="fitted-button red"
                      onClick={(e) => removeMember(teamMember.eId, index)}
                    >
                      <span class="material-symbols-outlined icon-size">close</span>
                    </div>
                    <button type="button" className="fitted-button blue" 
                        style={{ background: 'white', padding: '0.4rem', height:'30px', fontWeight: 'bold', marginLeft:'1rem' }} 
                        onClick={()=>moveMember(teamMember.eId)}>
                          Move
                        </button> */}
                        {/* <div>
                      <img
                        className="delete-btn"
                        src={deleteImg}
                        alt="Click to Delete symbol"
                        onClick={() => {
                          dispatch({
                            type: 'DELETE_TEAM_LEADER',
                            editTeamLeaderIndex: index,
                            teamLeadersList: state.data.teamLeadersList,
                            isTeamLeaderListUpdated: true,
                          })
                        }}
                      />
                    </div> */}
                      </div>
                    </React.Fragment>
                  ))}
              </div>
            )}
          </div>
        </CardContainer>

        {moveShow && (
          <MoveMember
            view={moveShow}
            setIsMoveShow={setIsMoveShow}
            selectedTeamLeader={selectedTeamLeader}
            teamLeaderList={state.data.allTeamLeadersList.filter(
              (emp) => emp.isDeactivated === false && emp.isDeleted === false,
            )}
            moveId={moveId}
            setIsReset={setIsReset}
          />
        )}
        {addMemberShow && (
          <AddMember
            view={addMemberShow}
            setAddMemberShow={setAddMemberShow}
            selectedTeamLeader={selectedTeamLeader}
            teamLeaderList={state.data.allTeamLeadersList}
            setIsReset={setIsReset}
            setRefetchData={setRefetchData}
          />
        )}
      </div>
    </>
  )
}

export default ManageTeamMembers

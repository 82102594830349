import React, { useState, useEffect } from 'react'
import Modal from '../../../../components/Modal'
import InPageLoader from '../../../../components/InPageLoader'
import { useTranslation } from 'react-i18next'
import Toast from '../../../../common/toast'
import { getHttpRequest, postHttpRequest } from '../../../../api/query/dynamicAPI'
import { KanbanCardHexColors } from '../../../../utils/colors'
import { set } from 'lodash'
import { ModalSaveButtonFitted } from '../../../../components/ModalSubmitButton'

const CardColorModal = ({ milestoneId, closeModal }) => {
  const { t } = useTranslation(['Kanban', 'Common'])
  const [isLoading, setIsLoading] = useState(false)
  const [colorSelected, setColorSelected] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const containerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    padding: '16px',
  }

  const buttonStyle = (color) => ({
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    backgroundColor: `#${color}`,
    border: color === 'FFFFFF' ? '1px solid #CCCCCC' : 'none',
    cursor: 'pointer',
    transition: 'transform 0.2s',
    outline: 'none',
    boxShadow: colorSelected === color ? '0 0 0 2px black' : 'none',
  })

  React.useEffect(() => {
    getCardColor()
  }, [])

  const getCardColor = async () => {
    setIsLoading(true)
    try {
      let params = {
        milestoneId: milestoneId,
      }
      const data = await getHttpRequest('/get_milestone_color', { params })
      setColorSelected(data.color)
    } catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
    setIsLoading(false)
  }

  const validateAndSubmit = () => {
    if (colorSelected === null) {
      setErrorMessage(t('Kanban:pleaseSelectAColor'))
      return
    } else {
      setErrorMessage(null)
      setCardColor()
    }
  }

  const setCardColor = async () => {
    setIsLoading(true)

    try {
      // set card color
      let params = {
        milestoneId: milestoneId,
        hexColor: colorSelected,
      }
      const data = await postHttpRequest('/set_milestone_color', params)
    } catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
    setIsLoading(false)
    closeModal()
  }

  return (
    <>
      <Modal
        style={{ zIndex: 104 }}
        width={'40rem'}
        title={t('Kanban:chooseAColor')}
        closeModal={closeModal}
      >
        {isLoading && <InPageLoader isLoading={isLoading} inComponent={true} />}

        {!isLoading && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={containerStyle}>
              {KanbanCardHexColors.map((color, index) => (
                <button
                  key={index}
                  style={buttonStyle(color)}
                  onClick={() => setColorSelected(color)}
                  onMouseEnter={(e) => (e.target.style.transform = 'scale(1.1)')}
                  onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                />
              ))}
            </div>

            {errorMessage && (
              <div style={{ marginBottom: '1rem' }} className="validation-error">
                {errorMessage}
              </div>
            )}

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyItems: 'center',
                justifyContent: 'center',
                paddingTop: '2rem',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
                <ModalSaveButtonFitted
                  isLoading={isLoading}
                  tag={t('Common:commonButtons.cancel')}
                  onClick={() => {
                    closeModal()
                  }}
                />

                <ModalSaveButtonFitted
                  isLoading={isLoading}
                  tag={t('Common:commonButtons.save')}
                  onClick={() => {
                    validateAndSubmit()
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}

export default CardColorModal

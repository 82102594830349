import styled from 'styled-components'

const LeadersContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
  flex-grow: 1;
  margin: 1rem 5rem;
  padding-bottom: 1rem;
`

const CardContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  flex-basis: ${(props) => props.basis || '100%'};
  padding: 1rem 2rem;
  box-shadow: 0 5px 15px #ccc;
`

const CardHeader = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify || 'flex-start'};
  font-size: 1.25rem;
  white-space: nowrap;
  font-weight: bold;
  gap: 1rem;
  padding-bottom: 0.5rem;
`

const EmployeeGrid = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 0.5em;
  column-gap: 0.5rem;
  padding-top: 1rem;
`

export { CardHeader, CardContainer, LeadersContainer, EmployeeGrid }

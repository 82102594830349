import React from 'react'
import Modal from '../../../../../../../components/Modal'
import { useTranslation } from 'react-i18next'
import triangle_diagram from '../../../../../../../assets/triangle-diagram.png'
import triangle_diagram_es from '../../../../../../../assets/triangle-diagram-spanish.png'
import { getCompanyConfigSettings } from '../../../../../../../utils/auth'
import './styles.scss'
import { getHttpRequest } from '../../../../../../../api/query/dynamicAPI'
import InPageLoader from '../../../../../../../components/InPageLoader'

const MeiHelpModal = ({ isModalOpen, closeModal }) => {
  const { t } = useTranslation(['Dashboard'])
  const isSpanishLangEnabled = getCompanyConfigSettings('languageSettings') === 'es'
  const currentUser = localStorage.getItem('tfei')
  const showL2BetaMei = [1158, 1041, 1026].some((elem) => parseInt(currentUser) === elem)

  const [thresholds, setThresholds] = React.useState(null)
  React.useEffect(() => {
    ;(async () => {
      let response = await getHttpRequest('/get_mei_thresholds')
      setThresholds(response.data)
    })()
  }, [])

  return (
    <>
      <Modal
        isModalOpen={isModalOpen}
        closeModal={() => {
          closeModal()
        }}
        width={'80%'}
        style={{ padding: '0rem 0rem 1rem 0rem' }}
        title={t('managementExecIndex.helpIconTextTitle')}
      >
        {!thresholds && <InPageLoader inComponent={true} />}
        {thresholds && (
          <>
            {isSpanishLangEnabled ? (
              <img
                src={triangle_diagram_es}
                style={{ width: '60%', height: '60%' }}
                alt="methodology demostration"
              />
            ) : (
              <img
                src={triangle_diagram}
                style={{ width: '60%', height: '60%' }}
                alt="methodology demostration"
              />
            )}
            <div style={{ textAlign: 'left', marginBottom: '3rem' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  <span className="mei-modal-breakdown-category">Prepared</span>
                </div>
                {t('managementExecIndex.helpIconTextPrepared', {
                  X: thresholds.prepared_target.toFixed(1),
                })}
              </div>
              <br />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  <span className="mei-modal-breakdown-category">Coordinated</span>
                </div>
                {t('managementExecIndex.helpIconTextCoordinated', {
                  X: thresholds.coordinated_target.toFixed(1),
                })}
              </div>
              <br />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  <span className="mei-modal-breakdown-category">Task Master</span>
                </div>
                {showL2BetaMei
                  ? '40% de las acciones/actividades están completadas en las últimas 4 semanas.'
                  : t('managementExecIndex.helpIconTextTaskmaster', {
                      X: (thresholds.taskmaster_target * 100).toFixed(1),
                    })}
              </div>
              <br />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  <span className="mei-modal-breakdown-category">KPIs</span>
                </div>
                {t('managementExecIndex.helpIconTextKPIS')}
              </div>
            </div>
          </>
        )}
      </Modal>
    </>
  )
}

export default MeiHelpModal

import React from 'react'
import Modal from '../../../../../components/Modal'

import { useTranslation } from 'react-i18next'
import { dateFilter } from '../../../../../utils/time'
import { useUpdateMeetingLogs } from '../../../../../api/services/meetingTopic.services'
import Toast from '../../../../../common/toast'
import './style.css'
import { getCompanyConfigSettings } from '../../../../../utils/auth'
import Swal from 'sweetalert2'
import { getHttpRequest } from '../../../../../api/query/dynamicAPI'
import InPageLoader from '../../../../../components/InPageLoader'

const BGUTopicAgeTableModal = ({
  isModalOpen,
  closeModal,
  data,
  refreshBGUScorecardFn,
  loadAgain,
  selectedViewMode = null,
  selectedMeetingId = null,
  selectedTagId = null,
}) => {
  const { t } = useTranslation(['MeetingHub', 'Common'])
  const [topicForTheCategory, setTopicForTheCategory] = React.useState(null)
  const computeLength = () =>
    topicForTheCategory?.blockedObjectives?.length +
      topicForTheCategory?.decisionObjectives?.length +
      topicForTheCategory?.updateObjectives?.length +
      topicForTheCategory?.adminTopicObjectives?.length >
    0

  const [blockerResolveBtns, setBlockerResolveBtns] = React.useState({})
  const [decisionResolveBtns, setDecisionResolveBtns] = React.useState({})
  const [updateResolveBtns, setUpdateResolveBtns] = React.useState({})
  const [adminTopicResolveBtns, setAdminTopicResolveBtns] = React.useState({})

  const [isLoading, setIsLoading] = React.useState(false)
  const mutationUpdateMeetingLogs = useUpdateMeetingLogs()
  const productSettings = getCompanyConfigSettings('productSettings')

  React.useEffect(() => {
    setIsLoading(true)
    refreshBGUTopicAgeFn()
  }, [])

  const refreshBGUTopicAgeFn = async () => {
    try {
      const response = await getHttpRequest(`/bgu_topics_age_scorecard_response`, {
        params: {
          category: data?.category ?? 'all',
          viewMode: selectedViewMode,
          meetingId: selectedMeetingId,
          tagId: selectedTagId,
        },
      })
      setTopicForTheCategory(response?.topicsWeek?.[0])
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }

    setIsLoading(false)
  }

  const resolve = (id, type, index) => (e) => {
    Swal.fire({
      title: t('Common:modalMessages.areYouSureResolveCompleteMeetingTopic'),
      text: '',
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        setTopicResolve(id, type, index)
      }
    })
  }

  async function setTopicResolve(id, type, index) {
    const formData = {}
    formData.status = false
    formData.umtId = id

    await mutationUpdateMeetingLogs.mutateAsync(formData, {
      onSuccess: () => {
        if (type === 'blocker') {
          setBlockerResolveBtns((prev) => ({
            ...prev,
            [id]: true,
          }))
          topicForTheCategory?.blockedObjectives.splice(index, 1)
        } else if (type === 'decision') {
          setDecisionResolveBtns((prev) => ({
            ...prev,
            [id]: true,
          }))
          topicForTheCategory?.decisionObjectives.splice(index, 1)
        } else if (type === 'update') {
          setUpdateResolveBtns((prev) => ({
            ...prev,
            [id]: true,
          }))
          topicForTheCategory?.updateObjectives.splice(index, 1)
        } else if (type === 'admin') {
          setAdminTopicResolveBtns((prev) => ({
            ...prev,
            [id]: true,
          }))
          topicForTheCategory?.adminTopicObjectives.splice(index, 1)
        }
        if (refreshBGUScorecardFn !== undefined) {
          refreshBGUScorecardFn()
        }
        loadAgain()
      },
      onError: () => {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      },
    })
  }

  const sortTopicByOpenDate = (a, b) => {
    let date1 = a.openedTime
    let date2 = b.openedTime
    return date1 > date2 ? 1 : -1
  }

  return (
    <>
      <Modal
        isModalOpen={isModalOpen}
        closeModal={() => {
          closeModal()
        }}
        width={'90%'}
        style={{ padding: '0rem 0rem 1rem 0rem' }}
      >
        <>
          {isLoading && <InPageLoader inComponent={true} />}
          {!isLoading &&
            (topicForTheCategory && computeLength() ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '100%', marginBottom: '3rem', padding: '2rem' }}>
                  <div className="chart-log-meeting-table">
                    {/* <div className="chart-log-meeting-header"></div> */}
                    <div className="chart-log-meeting-header title-border">
                      {t('scorecard.objectiveTitle')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('scorecard.topicTitle')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('scorecard.ownerName')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('scorecard.createdOn')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('scorecard.resolved')}
                    </div>

                    {topicForTheCategory?.blockedObjectives
                      ?.sort(sortTopicByOpenDate)
                      ?.map((blocker, index) => {
                        return (
                          <React.Fragment key={`objective-${blocker.umtId}`}>
                            {/* <div></div> */}
                            <div className="chart-log-meeting-description"></div>
                            <>
                              {/* <div></div> */}
                              <>
                                <div style={{ textAlign: 'left' }}>
                                  {blocker.isObjCompleted ? (
                                    <div style={{ color: 'grey' }}>
                                      <b>{t('Common:completedCaps')}</b>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  <div>
                                    {productSettings &&
                                    blocker?.initiativeId !== 0 &&
                                    !blocker?.isGeneralObjective ? (
                                      <div>
                                        <span>
                                          {blocker?.initiativeId.toString()?.padStart(5, '0')}
                                        </span>
                                        <span style={{ padding: '0rem 0.2rem 0rem 0.2rem' }}>
                                          {' '}
                                          -
                                        </span>
                                        <span>{blocker.statement}</span>
                                      </div>
                                    ) : (
                                      <span>{blocker.statement}</span>
                                    )}
                                  </div>
                                </div>
                                <div className="chart-log-blocker">{blocker.actionNeeded}</div>
                                <div style={{ textAlign: 'left' }}>{blocker.objOwnerName}</div>
                                <div style={{ textAlign: 'left' }}>
                                  {dateFilter(blocker.openedTime)}
                                </div>
                                {blockerResolveBtns[blocker.umtId] === undefined && (
                                  <div
                                    class="fitted-button blue"
                                    onClick={(e) => resolve(blocker.umtId, 'blocker', index)(e)}
                                  >
                                    <span>{t('meetingTopics.resolve')}</span>
                                  </div>
                                )}
                                {/* <div></div> */}
                                <div class="row-border"></div>
                              </>
                            </>
                          </React.Fragment>
                        )
                      })}
                    {topicForTheCategory?.decisionObjectives
                      ?.sort(sortTopicByOpenDate)
                      ?.map((decision, index) => {
                        return (
                          <React.Fragment key={`objective-${decision.umtId}`}>
                            {/* <div></div> */}
                            <div className="chart-log-meeting-description"></div>
                            <>
                              {/* <div></div> */}
                              <>
                                <div style={{ textAlign: 'left' }}>
                                  {decision.isObjCompleted ? (
                                    <div style={{ color: 'grey' }}>
                                      <b>{t('Common:completedCaps')}</b>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  <div>
                                    {productSettings &&
                                    decision?.initiativeId !== 0 &&
                                    !decision?.isGeneralObjective ? (
                                      <div>
                                        <span>
                                          {decision?.initiativeId.toString()?.padStart(5, '0')}
                                        </span>
                                        <span style={{ padding: '0rem 0.2rem 0rem 0.2rem' }}>
                                          {' '}
                                          -
                                        </span>
                                        <span>{decision.statement}</span>
                                      </div>
                                    ) : (
                                      <span>{decision.statement}</span>
                                    )}
                                  </div>
                                </div>
                                <div className="chart-log-decision">{decision.actionNeeded}</div>
                                <div style={{ textAlign: 'left' }}>{decision.objOwnerName}</div>
                                <div style={{ textAlign: 'left' }}>
                                  {dateFilter(decision.openedTime)}
                                </div>
                                {decisionResolveBtns[decision.umtId] === undefined && (
                                  <div
                                    class="fitted-button blue"
                                    onClick={(e) => resolve(decision.umtId, 'decision', index)(e)}
                                  >
                                    {t('meetingTopics.resolve')}
                                  </div>
                                )}
                                {/* <div></div> */}
                                <div class="row-border"></div>
                              </>
                            </>
                          </React.Fragment>
                        )
                      })}
                    {topicForTheCategory?.updateObjectives
                      ?.sort(sortTopicByOpenDate)
                      ?.map((update, index) => {
                        return (
                          <React.Fragment key={`objective-${update.umtId}`}>
                            <div className="chart-log-meeting-description"></div>
                            <>
                              <div style={{ textAlign: 'left' }}>
                                {update.isObjCompleted ? (
                                  <div style={{ color: 'grey' }}>
                                    <b>{t('Common:completedCaps')}</b>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <div>
                                  {productSettings &&
                                  update?.initiativeId !== 0 &&
                                  !update?.isGeneralObjective ? (
                                    <div>
                                      <span>
                                        {update?.initiativeId.toString()?.padStart(5, '0')}
                                      </span>
                                      <span style={{ padding: '0rem 0.2rem 0rem 0.2rem' }}> -</span>
                                      <span>{update.statement}</span>
                                    </div>
                                  ) : (
                                    <span>{update.statement}</span>
                                  )}
                                </div>
                              </div>
                              <div className="chart-log-update">{update.actionNeeded}</div>
                              <div style={{ textAlign: 'left' }}>{update.objOwnerName}</div>
                              <div style={{ textAlign: 'left' }}>
                                {dateFilter(update.openedTime)}
                              </div>
                              {updateResolveBtns[update.umtId] === undefined && (
                                <div
                                  class="fitted-button blue"
                                  onClick={(e) => resolve(update.umtId, 'update', index)(e)}
                                >
                                  <span>{t('meetingTopics.resolve')}</span>
                                </div>
                              )}
                              <div class="row-border"></div>
                            </>
                          </React.Fragment>
                        )
                      })}

                    {topicForTheCategory?.adminTopicObjectives
                      ?.sort(sortTopicByOpenDate)
                      ?.map((update, index) => {
                        return (
                          <React.Fragment key={`objective-${update.umtId}`}>
                            <div className="chart-log-meeting-description"></div>
                            <>
                              <div style={{ textAlign: 'left' }}>
                                {update.isObjCompleted ? (
                                  <div style={{ color: 'grey' }}>
                                    <b>{t('Common:completedCaps')}</b>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <div>
                                  {productSettings &&
                                  update?.initiativeId !== 0 &&
                                  !update?.isGeneralObjective ? (
                                    <div>
                                      <span>
                                        {update?.initiativeId.toString()?.padStart(5, '0')}
                                      </span>
                                      <span style={{ padding: '0rem 0.2rem 0rem 0.2rem' }}> -</span>
                                      <span>{update.statement}</span>
                                    </div>
                                  ) : (
                                    <span>{update.statement}</span>
                                  )}
                                </div>
                              </div>
                              <div className="chart-log-admin-topic">{update.actionNeeded}</div>
                              <div style={{ textAlign: 'left' }}>{update.objOwnerName}</div>
                              <div style={{ textAlign: 'left' }}>
                                {dateFilter(update.openedTime)}
                              </div>
                              {adminTopicResolveBtns[update.umtId] === undefined && (
                                <div
                                  class="fitted-button blue"
                                  onClick={(e) => resolve(update.umtId, 'admin', index)(e)}
                                >
                                  <span>{t('meetingTopics.resolve')}</span>
                                </div>
                              )}
                              <div class="row-border"></div>
                            </>
                          </React.Fragment>
                        )
                      })}
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ padding: '2rem' }}>{t('decisionLog.thereAreNoLogsAtThisTime')}</div>
            ))}
        </>
      </Modal>
    </>
  )
}

export default BGUTopicAgeTableModal

import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const addObjective = async (objectiveData) => {
  const { data } = await axios.post(`${BASE_URL}/add_objective_no_alloc`, objectiveData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export default function useAddObjective() {
  const client = useQueryClient()

  return useMutation((data) => addObjective(data), {
    onSuccess: (response) => {
      // if (response.ok) {
      void client.invalidateQueries({ queryKey: ['objectives'] })
      // }
    },
  })
}

import React, { useState, useEffect } from 'react'
import Modal from '../Modal'
import InPageLoader from '../InPageLoader'
import { useTranslation } from 'react-i18next'
import SelectSingleField from '../CustomMultipleSelect/singleOption'
import { getMeetingTopic } from '../../api/services/meetingTopic.services'
import {
  linkActionToMeetingTopic,
  unLinkMeetingTopic,
} from '../../api/services/meetingTopic.services'
import Toast from '../../common/toast'
import './styles.scss'
import SelectField from '../CustomMultipleSelect/singleOption'
import { ModalSaveButtonFitted } from '../ModalSubmitButton'
import { getConsolidatedConfigSettings } from '../../utils/auth'
const LinkActionToMeetingTopicModal = ({
  closeModal,
  actionId,
  objId,
  umtId,
  reloadBackground,
}) => {
  const { t } = useTranslation(['Kanban', 'Common'])
  const [isLoading, setIsLoading] = useState(false)
  const [errorArray, setErrorArray] = useState(Array(3).fill(null))

  const [isCreatingNewTopic, setIsCreatingNewTopic] = useState(false)

  const [newMeetingTopicDescription, setNewMeetingTopicDescription] = useState(null)
  const [newMeetingTopicType, setNewMeetingTopicType] = useState(null)

  const [topicsList, setTopicsList] = useState([])
  const [existingMeetingTopicId, setExistingMeetingTopicId] = useState(null)

  const allowCxoUsersSeeL3Objs = getConsolidatedConfigSettings('allow_cxo_users_see_l3_objs')

  React.useEffect(() => {
    setIsLoading(true)
    getTopicsList()
    setIsLoading(false)
  }, [])

  React.useEffect(() => {
    setIsLoading(true)
    if (umtId) {
      if (topicsList.length > 0) {
        for (let i = 0; i < topicsList.length; i++) {
          if (topicsList[i].id === umtId) {
            setExistingMeetingTopicId(topicsList[i])
          }
        }
      }
    } else {
      setIsCreatingNewTopic(true)
    }
    setIsLoading(false)
  }, [topicsList])

  React.useEffect(() => {
    setExistingMeetingTopicId(null)
    setIsLoading(true)
    setNewMeetingTopicDescription(null)
    setNewMeetingTopicType(null)
    setIsLoading(false)
  }, [isCreatingNewTopic])

  const handleChangeMeetingTopicDescription = (e) => {
    setNewMeetingTopicDescription(e.target.value)
  }

  const handleRadioChange = (e) => {
    setNewMeetingTopicType(e.target.value)
  }

  const handleChangeExistingMeetingTopicId = (value) => {
    setExistingMeetingTopicId(value)
  }

  const validate = () => {
    let tempErrorArray = Array(3).fill(null)
    if (isCreatingNewTopic) {
      if (newMeetingTopicDescription === null) {
        tempErrorArray[0] = t('Common:required')
      }
      if (newMeetingTopicType === null) {
        tempErrorArray[1] = t('Common:required')
      }
    } else {
      if (existingMeetingTopicId === null) {
        tempErrorArray[2] = t('Common:required')
      }
    }
    setErrorArray(tempErrorArray)
    return tempErrorArray
  }

  const submit = async () => {
    let formData = {}
    if (isCreatingNewTopic === true) {
      // create new meeting topic and link to action
      formData = {
        description: newMeetingTopicDescription,
        type: newMeetingTopicType,
        objId: objId,
      }
    } else {
      // link to existing meeting topic
      formData = {
        meetingTopicId: existingMeetingTopicId?.id,
      }
    }
    formData['actionId'] = actionId
    setIsLoading(true)
    try {
      let result = await linkActionToMeetingTopic(formData)
      if (result.success) {
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.actionSuccessfullyLinkedToMeetingTopic'),
        })

        closeModal()
        reloadBackground()
      } else {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
    setIsLoading(false)
    closeModal()
  }

  const unLink = async () => {
    let formData = {}
    formData['actionId'] = actionId
    setIsLoading(true)
    try {
      let result = await unLinkMeetingTopic(formData)
      if (result.success) {
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.actionSuccessfullyUnlinkedToMeetingTopic'),
        })

        closeModal()
        reloadBackground()
      } else {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
    setIsLoading(false)
    closeModal()
  }

  const validateAndSubmit = () => {
    let tempErrorArray = validate()
    if (tempErrorArray.every((item) => item === null)) {
      submit()
    }
  }

  const getTopicsList = async () => {
    setIsLoading(true)
    const result = await getMeetingTopic(false, false, undefined)
    setIsLoading(false)
    if (result) {
      let finalArray = []

      finalArray = finalArray.concat(result.blockedObjectives)
      finalArray = finalArray.concat(result.decisionObjectives)
      finalArray = finalArray.concat(result.updateObjectives)
      finalArray = finalArray.concat(result.adminTopicObjectives)
      finalArray = finalArray.filter((item) => parseInt(item.objectiveId) === parseInt(objId))
      const tempTopicsList = (finalArray || []).map((item) => ({
        name: item.actionNeeded,
        id: item.umtId,
      }))
      setTopicsList(tempTopicsList)
    }
  }

  return (
    <>
      <Modal
        style={{ zIndex: 104 }}
        width={'40rem'}
        title={t('Kanban:linkActionToAMeetingTopic')}
        closeModal={closeModal}
      >
        {isLoading && <InPageLoader isLoading={isLoading} />}
        {!isLoading && (
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '2rem' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                columnGap: '1rem',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div
                className="option-button"
                style={{ backgroundColor: isCreatingNewTopic === true ? '#4472c4' : '#a6a6a6' }}
                onClick={() => {
                  setIsCreatingNewTopic(true)
                }}
              >
                {t('Common:commonButtons.new')}
              </div>
              <div
                className="option-button"
                style={{ backgroundColor: isCreatingNewTopic === false ? '#4472c4' : '#a6a6a6' }}
                onClick={() => {
                  setIsCreatingNewTopic(false)
                }}
              >
                {t('Common:commonButtons.existing')}
              </div>
            </div>
          </div>
        )}
        {!isLoading && isCreatingNewTopic && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <div className="form-field">
              <label>{t('Kanban:createNewMeetingTopic')}</label>

              <textarea
                rows="6"
                id="meetingTopicDescription"
                value={newMeetingTopicDescription}
                onChange={(value) => {
                  handleChangeMeetingTopicDescription(value)
                }}
                style={{
                  resize: 'none',
                  border: 'none',
                  borderRadius: '10px',
                  padding: '0.5rem',
                  backgroundColor: 'rgb(242, 242, 242)',
                  fontSize: '14px',
                  width: '20rem',
                }}
              />
              {errorArray[0] !== null && (
                <div style={{ textAlign: 'left' }} className="validation-error">
                  {errorArray[0]}
                </div>
              )}
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="dot-mt-type-line">
                  <span className="dot" style={{ backgroundColor: '#ED522E' }}></span>
                  <span>Blocker</span>
                </div>
                <input
                  type="radio"
                  value="1"
                  checked={newMeetingTopicType === '1'}
                  onChange={handleRadioChange}
                />
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="dot-mt-type-line">
                  <span className="dot" style={{ backgroundColor: '#0926D5' }}></span>
                  <span>Guidance</span>
                </div>
                <input
                  type="radio"
                  value="2"
                  checked={newMeetingTopicType === '2'}
                  onChange={handleRadioChange}
                />
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="dot-mt-type-line">
                  <span className="dot" style={{ backgroundColor: 'rgb(127, 131, 155)' }}></span>
                  <span>Update</span>
                </div>
                <input
                  type="radio"
                  value="3"
                  checked={newMeetingTopicType === '3'}
                  onChange={handleRadioChange}
                />
              </div>
              {allowCxoUsersSeeL3Objs === 'true' && (
                <>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="dot-mt-type-line">
                      <span className="dot" style={{ backgroundColor: '#9933FF' }}></span>
                      <span>Admin </span>
                    </div>
                    <input
                      type="radio"
                      value="4"
                      checked={newMeetingTopicType === '4'}
                      onChange={handleRadioChange}
                    />
                  </div>
                  {errorArray[1] !== null && (
                    <div className="validation-error" style={{ textAlign: 'left' }}>
                      {errorArray[1]}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        {!isLoading && !isCreatingNewTopic && (
          <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="form-field">
                <label>{t('Kanban:existingMeetingTopicsForThisObjective')}</label>
                <SelectField
                  isMulti={false}
                  placeholder={t('Kanban:selectMeetingTopic')}
                  name="selectMeetingTopic"
                  value={existingMeetingTopicId}
                  onChange={(option) => {
                    handleChangeExistingMeetingTopicId(option)
                  }}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  options={topicsList}
                  width={'20rem'}
                  isClearable={true}
                  menuShouldScrollIntoView={false}
                  menuPosition="fixed"
                />
              </div>
            </div>
            {errorArray[2] !== null && (
              <div className="validation-error" style={{ textAlign: 'left' }}>
                {errorArray[2]}
              </div>
            )}
          </div>
        )}
        <div
          className="modal-dual-btn"
          style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}
        >
          {/* Bring back these buttons when we decide to change buttons again all at once in a few days */}
          {/* <button
            className="dual-btn negative-btn"
            onClick={() => {
              closeModal()
            }}
            type="button"
          >
            {t('Common:commonButtons.cancel')}
          </button>
          <button
            className="dual-btn positive-btn"
            type="submit"
            onClick={() => {
              validateAndSubmit()
            }}
          >
            {t('Common:commonButtons.save')}
          </button> */}
          <ModalSaveButtonFitted
            isLoading={isLoading}
            tag={t('Common:commonButtons.cancel')}
            onClick={() => {
              closeModal()
            }}
          />
          {umtId && (
            <ModalSaveButtonFitted
              isLoading={isLoading}
              tag={t('Common:commonButtons.unLink')}
              onClick={() => {
                unLink()
              }}
            />
          )}

          <ModalSaveButtonFitted
            isLoading={isLoading}
            tag={t('Common:commonButtons.save')}
            onClick={() => {
              validateAndSubmit()
            }}
          />
        </div>
      </Modal>
    </>
  )
}

export default LinkActionToMeetingTopicModal

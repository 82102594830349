import React, { useEffect } from 'react'
import ApplicationLayout from '../../components/ApplicationLayout'
import './objective.scss'
import TeamsObjective from './components/ teamsObjective'
import TeamsMembers from './components/teamsMembers'
import { hasRole } from '../../utils/auth'
import AreaObjectives from './components/areaObjectives'
import GovernanceStatus from '../../components/GovernanceStatus'

const ObjectivesTab = () => {
  const isAreaLeader = hasRole('area') ? true : false
  const isCxo = hasRole('ceo') || hasRole('cxo') || hasRole('cos') ? true : false
  const isDelegateTl = parseInt(localStorage.getItem('dType')) === 5 ? true : false

  useEffect(() => {}, [])

  return (
    <>
      <ApplicationLayout>
        <div className="container">
          <div className="page_title">Objectives</div>
          <div className="objective_wrapper">
            {!isCxo && <TeamsObjective />}
            {isAreaLeader || isCxo ? <AreaObjectives /> : <TeamsMembers />}
          </div>
          {/* <br />
      <div className='objective_wrapper' style={{ width: '44%' }}>
        {(isAreaLeader || isDelegateTl) && (
          <GovernanceStatus location={'Home'} />
        )}
      </div> */}
        </div>
      </ApplicationLayout>
    </>
  )
}
export default ObjectivesTab

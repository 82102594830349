import React, { useState } from 'react'
import styled from 'styled-components'
import './styles.css'
import { postHttpRequest } from '../../../../api/query/dynamicAPI'
import 'sweetalert2/dist/sweetalert2.min.css'
import InPageLoader from '../../../../components/InPageLoader'
import Toast from '../../../../common/toast'
import { API_ERROR_MESSAGE } from '../../../../api/constant/route'
import { getCompanyConfigSettings, hasRole } from '../../../../utils/auth'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import SelectSingleField from '../../../../components/CustomMultipleSelect/singleOption.jsx'
import { getHttpRequest } from '../../../../api/query/dynamicAPI'
import { dayMonthFilter } from '../../../../utils/time'
import { Link } from 'react-router-dom'
import { linkifyString } from '../../../../utils/general'
const ObjectiveStatement = styled.div`
  font-size: 16px;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 1.5rem;
`

const MyDecisions = () => {
  const { t } = useTranslation(['MeetingHub'])
  let { state } = useLocation()
  const isProductSettingsEnabled = getCompanyConfigSettings('productSettings')
  const [decisionLogData, setDecisionLogData] = React.useState([])
  const [isLoadingDecisionLogData, setIsloadingDecisionLogData] = useState(false)
  const [dateFilterValue, setDateFilterValue] = React.useState([null, null])
  const [showValidationMsg, setShowValidationMsg] = React.useState(false)
  const [tlId, setTlId] = React.useState(null)
  const [teamLeadersData, setTeamLeadersData] = React.useState(null)
  // causing log out when team leader is loggined
  // const { data: teamLeadersData, isLoading: isTeamLeadersLoading } = useAreaLeaderTeamLeaders()

  React.useEffect(() => {
    const date = new Date()
    const endDate = date.toISOString().split('T')[0]
    date.setDate(date.getDate() - 15)
    const startDate = date.toISOString().split('T')[0]
    setDateFilterValue([startDate, endDate])
    if (!state) getDecisionLogData(tlId, [startDate, endDate])
    else {
      let url = ''
      let param = {}
      if (hasRole('ceo') || hasRole('cos')) {
        url = '/all_reporting_leaders'
      } else {
        url = '/team_leaders_for_area_leader'
        param = {
          includeSelf: true,
          notExcludeCos: true,
        }
      }

      getHttpRequest(url, { params: { ...param } }).then((response) => {
        setTeamLeadersData(response)
      })
    }
  }, [])

  React.useEffect(() => {
    ;(async () => {
      if (teamLeadersData && teamLeadersData?.employees.length > 0) {
        const date = new Date()
        const endDate = date.toISOString().split('T')[0]
        date.setDate(date.getDate() - 15)
        const startDate = date.toISOString().split('T')[0]
        let empName =
          localStorage.getItem('tfin') != 'null'
            ? localStorage.getItem('tfin')
            : localStorage.getItem('tfmnm')
        let leader = teamLeadersData.employees.filter((emp) => emp.name == empName)[0]
        setTlId(parseInt(leader.eId))
        getDecisionLogData(leader.eId, [startDate, endDate])
      }
    })()
  }, [teamLeadersData])

  function getDecisionLogData(tlId = null, dateFilter = []) {
    const postData = {}
    postData.dateFilter = dateFilter
    setIsloadingDecisionLogData(true)
    let baseURL = `/get_team_decision_log`
    //when TLID exists
    if (tlId !== null) {
      baseURL = baseURL + `/${tlId}`
    }
    postHttpRequest(baseURL, postData)
      .then((response) => {
        if (response) {
          setDecisionLogData(response)
        }
        if (!response) {
          return
        }
      })
      .catch((error) => {
        Toast.fire({
          icon: 'error',
          title: API_ERROR_MESSAGE,
        })
      })
    setIsloadingDecisionLogData(false)
  }

  const handleDateFilter = (event, type) => {
    let tempArrayDate = [...dateFilterValue]
    if (type === 'FROM') {
      tempArrayDate[0] = event.target.value
    } else if (type === 'TO') {
      tempArrayDate[1] = event.target.value
    }
    setDateFilterValue(tempArrayDate)
  }

  const applyFilters = () => {
    if (dateFilterValue[0] > dateFilterValue[1]) {
      setShowValidationMsg(true)
    } else {
      setShowValidationMsg(false)
      getDecisionLogData(tlId, dateFilterValue)
    }
  }
  if (isLoadingDecisionLogData) {
    return <InPageLoader />
  }

  const inputChangedHandler = (e) => {
    setTlId(parseInt(e.eId))
    getDecisionLogData(parseInt(e.eId), dateFilterValue)
  }

  return (
    <>
      <div className="date-validation-box">
        <div className="date-filter">
          {state ? (
            <SelectSingleField
              value={
                teamLeadersData?.employees?.filter((emp) => parseInt(emp.eId) === parseInt(tlId))[0]
              }
              options={teamLeadersData?.employees}
              onChange={inputChangedHandler}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.eId}
              width={'100%'}
              marginTop={'1.5rem'}
            />
          ) : (
            <></>
          )}
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
            <label className="label-custom">{t('Common:filter.fromDate')}:</label>
            <input
              type="date"
              id="dateFilter"
              name="dateFilter"
              autoComplete="off"
              onChange={(e) => {
                handleDateFilter(e, 'FROM')
              }}
              style={{ height: '38px' }}
              defaultValue={dateFilterValue[0]}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
            <label className="label-custom">{t('Common:filter.toDate')}:</label>
            <input
              type="date"
              id="dateFilter"
              name="dateFilter"
              autoComplete="off"
              onChange={(e) => {
                handleDateFilter(e, 'TO')
              }}
              style={{ height: '38px' }}
              defaultValue={dateFilterValue[1]}
            />
          </div>
          <div
            class="fitted-button blue"
            onClick={() => {
              applyFilters()
            }}
            style={{ alignSelf: 'end' }}
          >
            <span style={{ margin: '0px 0.5rem' }}>{t('Common:filter.applyFilter')}</span>
          </div>
        </div>
        {showValidationMsg && (
          <span className="validation-message">{t('Common:filter.fromToDateErrorMessage')}</span>
        )}
      </div>
      {decisionLogData?.objectives?.length > 0 ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className="shadow" style={{ width: '88%', marginBottom: '3rem', padding: '2rem' }}>
            <div className="my-decisions-meeting-table">
              <div className="my-decisions-meeting-header"></div>
              <div className="my-decisions-meeting-header">{t('myDecisions.topic')}</div>
              <div className="my-decisions-history-inner-grid">
                <div className="my-decisions-meeting-header" style={{ paddingLeft: '0rem' }}>
                  {t('myDecisions.outcome')}
                </div>
                <div className="my-decisions-meeting-header" style={{ paddingLeft: '0rem' }}>
                  {t('myDecisions.owner')}
                </div>
                <div className="my-decisions-meeting-header" style={{ paddingLeft: '0rem' }}>
                  {t('myDecisions.createdOn')}
                </div>
              </div>
              <div className="divider my-decisions-meeting-description"></div>

              {decisionLogData?.objectives?.map((objective, index) => {
                const { blockers, decisions, updates, decisionCommentsGoalOnly } = objective
                const totalRows =
                  (blockers?.length ?? 0) +
                  (decisions?.length ?? 0) +
                  (updates?.length ?? 0) +
                  (decisionCommentsGoalOnly?.length ?? 0)

                return (
                  <React.Fragment key={`objective-${index}`}>
                    {totalRows > 0 && (
                      <React.Fragment>
                        <div className="my-decisions-meeting-description">
                          <b>
                            {/* only show initiative number if not general and initiative id exists */}
                            {isProductSettingsEnabled &&
                            !objective?.isGeneralObjective &&
                            objective?.initiativeId ? (
                              <ObjectiveStatement>
                                {objective?.initiativeId.toString()?.padStart(5, '0')} -{' '}
                                {objective.objDescription}
                              </ObjectiveStatement>
                            ) : (
                              <ObjectiveStatement>{objective.objDescription}</ObjectiveStatement>
                            )}
                          </b>
                          <div className="divider"></div>
                        </div>
                        {blockers?.length > 0 && (
                          <>
                            {blockers.map((blocker, blockerIndex) => (
                              <>
                                <div>
                                  {blocker.hasMeetingDeck && (
                                    <>
                                      <Link
                                        to={`/meetingDeck`}
                                        state={{
                                          umtId: blocker.mtId,
                                          topicType: blocker.topicType,
                                          redirectTo: 'myDecisionHistory',
                                        }}
                                      >
                                        <div class="fitted-button blue">
                                          <span class="material-symbols-outlined">slideshow</span>
                                        </div>
                                      </Link>
                                    </>
                                  )}
                                </div>
                                <div className="meeting-topic-blocker">{blocker.description}</div>
                                <div className="my-decisions-comments-milestones">
                                  {blocker?.comments?.length > 0 && (
                                    <>
                                      {blocker.comments.map((comment) => (
                                        <div
                                          className="my-decisions-history-inner-grid"
                                          key={comment.commentId}
                                        >
                                          <div
                                            className="my-decisions-comment"
                                            style={{ wordBreak: 'break-all' }}
                                          >
                                            <b>{t('myDecisions.comment')}:</b>{' '}
                                            {linkifyString(comment.commentText)}
                                          </div>
                                          <div>{comment.commentOwnerName.match(/\b(\w)/g)}</div>
                                          <div class="owner">
                                            {dayMonthFilter(comment.createdAt)}
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                  )}
                                  {blocker?.milestones?.length > 0 && (
                                    <>
                                      {blocker.milestones.map((milestone) => (
                                        <div
                                          className="my-decisions-history-inner-grid"
                                          key={milestone.milestoneId}
                                        >
                                          <div
                                            className="my-decisions-milestone"
                                            style={{ wordBreak: 'break-all' }}
                                          >
                                            <b>{t('myDecisions.action')}:</b>{' '}
                                            {linkifyString(milestone.milestoneDescription)}
                                          </div>
                                          <div className="owner">
                                            {milestone.assignees.map((assignee, index) => (
                                              <span key={index}>
                                                {index > 0 && ', '}
                                                {assignee.name.match(/\b(\w)/g)}
                                              </span>
                                            ))}
                                          </div>
                                          <div class="owner">
                                            {dayMonthFilter(milestone.createdAt)}
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                  )}
                                </div>
                              </>
                            ))}
                          </>
                        )}
                        {decisions?.length > 0 && (
                          <>
                            {decisions.map((decision, decisionIndex) => (
                              <>
                                <div>
                                  {decision.hasMeetingDeck && (
                                    <>
                                      <Link
                                        to={`/meetingDeck`}
                                        state={{
                                          umtId: decision.mtId,
                                          topicType: decision.topicType,
                                          redirectTo: 'myDecisionHistory',
                                        }}
                                      >
                                        <div class="fitted-button blue">
                                          <span class="material-symbols-outlined">slideshow</span>
                                        </div>
                                      </Link>
                                    </>
                                  )}
                                </div>
                                <div className="meeting-topic-decision">{decision.description}</div>
                                <div>
                                  {decision?.comments?.length > 0 && (
                                    <>
                                      {decision.comments.map((comment) => (
                                        <div
                                          className="my-decisions-history-inner-grid"
                                          key={comment.commentId}
                                        >
                                          <div
                                            key={comment.commentId}
                                            className="my-decisions-comment"
                                            style={{ wordBreak: 'break-all' }}
                                          >
                                            <b>{t('myDecisions.comment')}:</b>{' '}
                                            {linkifyString(comment.commentText)}
                                          </div>
                                          <div>{comment.commentOwnerName.match(/\b(\w)/g)}</div>
                                          <div class="owner">
                                            {dayMonthFilter(comment.createdAt)}
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                  )}
                                  {decision?.milestones?.length > 0 && (
                                    <>
                                      {decision.milestones.map((milestone) => (
                                        <div
                                          className="my-decisions-history-inner-grid"
                                          key={milestone.milestoneId}
                                        >
                                          <div
                                            key={milestone.milestoneId}
                                            className="my-decisions-milestone"
                                            style={{ wordBreak: 'break-all' }}
                                          >
                                            <b>{t('myDecisions.action')}:</b>{' '}
                                            {linkifyString(milestone.milestoneDescription)}
                                          </div>
                                          <div className="owner">
                                            {milestone.assignees.map((assignee, index) => (
                                              <span key={index}>
                                                {index > 0 && ', '}
                                                {assignee.name.match(/\b(\w)/g)}
                                              </span>
                                            ))}
                                          </div>{' '}
                                          <div class="owner">
                                            {dayMonthFilter(milestone.createdAt)}
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                  )}
                                </div>
                              </>
                            ))}
                          </>
                        )}
                        {updates?.length > 0 && (
                          <>
                            {updates.map((update, decisionIndex) => (
                              <>
                                <div>
                                  {update.hasMeetingDeck && (
                                    <>
                                      <Link
                                        to={`/meetingDeck`}
                                        state={{
                                          umtId: update.mtId,
                                          topicType: update.topicType,
                                          redirectTo: 'myDecisionHistory',
                                        }}
                                      >
                                        <div class="fitted-button blue">
                                          <span class="material-symbols-outlined">slideshow</span>
                                        </div>
                                      </Link>
                                    </>
                                  )}
                                </div>
                                <div className="meeting-topic-update">{update.description}</div>
                                <div className="my-decisions-comments-milestones">
                                  {update?.comments?.length > 0 && (
                                    <>
                                      {update.comments.map((comment) => (
                                        <div
                                          className="my-decisions-history-inner-grid"
                                          key={comment.commentId}
                                        >
                                          <div
                                            key={comment.commentId}
                                            className="my-decisions-comment"
                                            style={{ wordBreak: 'break-all' }}
                                          >
                                            <b>{t('myDecisions.comment')}:</b>{' '}
                                            {linkifyString(comment.commentText)}
                                          </div>
                                          <div>{comment.commentOwnerName.match(/\b(\w)/g)}</div>
                                          <div class="owner">
                                            {dayMonthFilter(comment.createdAt)}
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                  )}
                                  {update?.milestones?.length > 0 && (
                                    <>
                                      {update.milestones.map((milestone) => (
                                        <div
                                          className="my-decisions-history-inner-grid"
                                          key={milestone.milestoneId}
                                        >
                                          <div
                                            key={milestone.milestoneId}
                                            className="my-decisions-milestone"
                                            style={{ wordBreak: 'break-all' }}
                                          >
                                            <b>{t('myDecisions.action')}:</b>{' '}
                                            {linkifyString(milestone.milestoneDescription)}
                                          </div>
                                          <div className="owner">
                                            {milestone.assignees.map((assignee, index) => (
                                              <span key={index}>
                                                {index > 0 && ', '}
                                                {assignee.name.match(/\b(\w)/g)}
                                              </span>
                                            ))}
                                          </div>
                                          <div class="owner">
                                            {dayMonthFilter(milestone.createdAt)}
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                  )}
                                </div>
                              </>
                            ))}
                          </>
                        )}
                        {decisionCommentsGoalOnly?.length > 0 && (
                          <>
                            {decisionCommentsGoalOnly.map((commentIsDecision, index) => (
                              <>
                                <div></div>
                                <div>{commentIsDecision.description}</div>
                                <div className="my-decisions-comments-milestones">
                                  {commentIsDecision?.comments?.length > 0 && (
                                    <>
                                      {commentIsDecision.comments.map((comment) => (
                                        <div
                                          className="my-decisions-history-inner-grid"
                                          key={comment.commentId}
                                        >
                                          <div
                                            className="my-decisions-comment"
                                            style={{ wordBreak: 'break-all' }}
                                          >
                                            <b>{t('myDecisions.comment')}:</b>{' '}
                                            {linkifyString(comment.commentText)}
                                          </div>
                                          <div>{comment.commentOwnerName.match(/\b(\w)/g)}</div>
                                          <div class="owner">
                                            {dayMonthFilter(comment.createdAt)}
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                  )}
                                </div>
                              </>
                            ))}
                          </>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )
              })}
            </div>
          </div>
        </div>
      ) : (
        <div style={{ padding: '2rem' }}>{t('myDecisions.thereAreNoLogsAtThisTime')}</div>
      )}
    </>
  )
}

export default MyDecisions

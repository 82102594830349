import React, { useState, useEffect } from 'react'
import { useAreaLeaderContext } from '../../context/areaLeaderContext'
import ObjectiveTypeHelpModal from '../../../../components/ObjectiveTypeHelpModal'
import './styles.css'
import { useTranslation } from 'react-i18next'

const TypeStepAreaLeader = () => {
  const { t } = useTranslation(['AreaLeaderWizard'])
  const { state, dispatch } = useAreaLeaderContext()

  const [isProject, setIsProject] = useState(new Array(state.data.objectives.length).fill(false))

  const handleGoalLevelChange = (goalLevel, objectiveIndex) => {
    if (state.data.objectives[objectiveIndex]) {
      let objectiveToUpdate
      objectiveToUpdate = { ...state.data.objectives[objectiveIndex], level: goalLevel }

      if (goalLevel === 3) {
        objectiveToUpdate = {
          ...state.data.objectives[objectiveIndex],
          level: goalLevel,
          type: 3,
          level1Id: null,
        }
      } else if (goalLevel === 2) {
        objectiveToUpdate = {
          ...state.data.objectives[objectiveIndex],
          level: goalLevel,
          type: 2,
          level1Id: null,
        }
      }

      dispatch({
        type: 'UPDATE_OBJECTIVE',
        objective: objectiveToUpdate,
        objectiveIndex,
      })
    }
  }

  const updateCheckbox = () => {
    const updatedProjectArray = [...isProject]
    for (let i = 0; i < state.data.objectives.length; i++) {
      if (state.data.objectives[i].level === 3) {
        updatedProjectArray[i] = true
      }
    }
    setIsProject(updatedProjectArray)
  }

  useEffect(() => {
    updateCheckbox()
  }, [])

  const handleChange = (position) => {
    const updatedCheckedState = isProject.map((item, index) => (index === position ? !item : item))
    setIsProject(updatedCheckedState)
  }

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
  }, [])

  return (
    <>
      <ObjectiveTypeHelpModal
        isOpen={state.isObjHelpModalOpen}
        handleClose={() => {
          dispatch({ type: 'CLOSE_OBJ_HELP_MODAL' })
        }}
      />

      <div className="wizard-step">
        <h2 className="wizard-step__title">{t('typeStep.title')}</h2>
        <div className="wizard-step__overview">
          <div className="wizard-step__overview__paragraph">
            <span>{t('typeStep.subtitle')}</span>
          </div>
        </div>
        <table className="wizard-step__main-content-container">
          <tbody style={{ width: '100%' }}>
            <tr className="header-row">
              <th className="goal-column" style={{ width: '60%' }}>
                <span>{t('typeStep.objective')}</span>
              </th>
              <th style={{ width: '15rem' }}>
                <span className="objective-header">{t('typeStep.isBAU')}</span>
              </th>
            </tr>

            {state.data.objectives.map((objective, index) => {
              return (
                <tr key={`objective-${index}`}>
                  <td className="goal-column" style={{ width: '60%' }}>
                    <span>{objective.statement}</span>
                  </td>
                  <td style={{ width: '15rem' }}>
                    <div className="checkbox-area">
                      <label>
                        <input
                          type="checkbox"
                          defaultValue={objective.level ? objective.level : null}
                          checked={isProject[index]}
                          data-testid={`obj-bau-${index}`}
                          onChange={(e) => {
                            handleChange(index)

                            if (isProject[index]) {
                              handleGoalLevelChange(parseInt(2), index)
                            } else {
                              handleGoalLevelChange(parseInt(3), index)
                            }
                          }}
                        />
                      </label>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default TypeStepAreaLeader

import React from 'react'
import { useSetupWizardContext } from '../../context/setupWizardContext'
import { Outlet } from 'react-router-dom'
import ProgressBar from '../ProgressBar'

import NextPreviousActions from '../NextPreviousActions'
import useSetupWizardSteps from '../../hooks/useSetupWizardSteps'

import ErrorModal from '../../../../components/ErrorModal'

import './styles.css'
import './responsive.css'

const SetupWizard = ({ existingStep }) => {
  const { state, dispatch } = useSetupWizardContext()
  const { currentStepIndex, steps, handleNext, handlePrevious } = useSetupWizardSteps(existingStep)
  const [errorMessage, setErrorMessage] = React.useState(null)

  React.useEffect(() => {
    return () => {
      if (currentStepIndex >= 0 && currentStepIndex < 8) {
        localStorage.setItem('sw-data', JSON.stringify(state.data))
      }
    }
  }, [currentStepIndex, state.data])

  const closeErrorModal = () => {
    dispatch({ type: 'CLOSE_ERROR_MODAL' })
  }

  return (
    <>
      {state.isErrorModalOpen && (
        <ErrorModal
          isOpen={state.isErrorModalOpen}
          handleClose={closeErrorModal}
          errorMessage={null}
        ></ErrorModal>
      )}
      <div className="setup-wizard-component">
        <ProgressBar currentStep={currentStepIndex} steps={steps} />
        <Outlet context={[setErrorMessage]} />
        <NextPreviousActions
          isLastStep={currentStepIndex === steps.length - 1}
          currentStep={currentStepIndex}
          handleNext={() => {
            if (state.isOverCapacity && !state.isCapacityConfirmModalOpen) {
              setErrorMessage(null)
              dispatch({ type: 'OPEN_CAPACITY_CONFIRM_MODAL' })
            } else if (currentStepIndex === 7) {
              if (state.data.topLevelObjectives.length === 0) {
                setErrorMessage('At least one valid objective is needed to continue')
                return
              } else if (state.data.topLevelObjectives.length >= 1) {
                for (var i = 0; i < state.data.topLevelObjectives.length; i++) {
                  // if (state.data.topLevelObjectives[i].isValid === false) {
                  //   setErrorMessage('All of the objectives need to be valid to continue')
                  //   return
                  // }
                }
                setErrorMessage(null)
                handleNext()
              }
            } else if (currentStepIndex === 8) {
              if (state.data.areaLevelObjectives.length === 0) {
                setErrorMessage('At least one valid objective is needed to continue')
                return
              } else if (state.data.areaLevelObjectives.length >= 1) {
                for (var i = 0; i < state.data.areaLevelObjectives.length; i++) {
                  // if (state.data.areaLevelObjectives[i].isValid === false) {
                  //   setErrorMessage('All of the objectives need to be valid to continue')
                  //   return
                  // }
                }
                setErrorMessage(null)
                handleNext()
              }
            } else {
              setErrorMessage(null)
              handleNext()
            }
          }}
          handlePrevious={handlePrevious}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      </div>
    </>
  )
}

export default SetupWizard

const colors = {
  orange: '#ed522e',
  grey: '#c8c8c8',
  red: '#e92020',
  blue: '#0926d5',
  white: '#fff',
  black: '#000',
  green: '#70AD47',
}

const KanbanCardHexColors = ['FFFFFF', 'D9EFD3', 'CBE3F9', 'F6E9C1', 'EDB8C4', 'DEBBEA', 'DEDEDB']

export { colors, KanbanCardHexColors }

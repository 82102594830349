import React from 'react'
import AddEnterpriseLeaderModal from './components/AddEnterpriseLeaderModal'
import plus from '../../../../assets/plus.svg'
import deleteCross from '../../../../assets/delete-cross.svg'
import { useSetupWizardContext } from '../../context/setupWizardContext'
import './styles.css'

const EnterpriseLeaderStep = () => {
  const { state, dispatch } = useSetupWizardContext()

  return (
    <div className="enterprise-leader-step-component">
      <AddEnterpriseLeaderModal />
      <div className="step-section team-step enterprise-leader-setup-step">
        <h2>Let’s start with your company’s top team</h2>

        <p style={{ textAlign: 'left' }} className="step-details-p">
          Add your company’s Top Team members (c-level and equivalent executives). We will connect
          objectives and teams to Top Team members.
        </p>

        <div className="table-wrapper shadow">
          <table>
            <tbody>
              <tr className="header-row">
                <th>
                  <span>Name</span>
                </th>
                <th>
                  <span>Title</span>
                </th>
              </tr>

              {state.data.enterpriseLeader?.map((enterpriseLeader, index) => (
                <tr id={enterpriseLeader.name} key={`employee-${index}`}>
                  <td>{enterpriseLeader.name}</td>
                  <td>{enterpriseLeader.title}</td>
                  <td>
                    <div
                      className="delete-button"
                      onClick={() => {
                        dispatch({ type: 'DELETE_ENTERPRISE_LEADER', enterpriseLeaderIndex: index })
                      }}
                    >
                      <img className="delete-btn" src={deleteCross} alt="Click to edit symbol" />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div
            className="add-btn"
            onClick={() => {
              dispatch({ type: 'OPEN_EMPLOYEE_MODAL' })
            }}
          >
            <img className="plus" src={plus} alt="Graphic of plus sign" />
            <span>ADD TOP TEAM MEMBER</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EnterpriseLeaderStep

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getObjectiveData = async (objectiveId) => {
  // const { data } = await axios.get(`${BASE_URL}/team_leader_objectives`)
  const queryString = `/kanban_board_activity_data/${objectiveId}`

  const { data } = await axios.get(`${BASE_URL}${queryString}`)
  return data
}

export default function useObjectiveTask(objectiveId = null) {
  return useQuery(['objectives', objectiveId], () => getObjectiveData(objectiveId), {
    refetchOnWindowFocus: false,
    enabled: Boolean(objectiveId),
  })
}

import React from 'react'
import { useUpdateContext } from '../../../../context/updateContext'
import { useNavigate } from 'react-router-dom'
import WizardButton from '../../../../../../components/WizardButton'
import Joyride from 'react-joyride'

const SubmitUpdateWizard = ({ submitIsLoading, currentStep, setErrorMessage, handleSubmit }) => {
  const { state } = useUpdateContext()
  const navigate = useNavigate()
  const [isDisabled, setIsDisabled] = React.useState(false)

  let isBlockerError = false
  for (let objId in state.blockerErrors) {
    isBlockerError = isBlockerError || state.blockerErrors[objId]
  }
  return (
    <>
      <div className="submit-update-wizard-button">
        <WizardButton
          isLoading={submitIsLoading}
          onClick={() => {
            handleSubmit()
            setIsDisabled(true)
            setTimeout(function () {
              setIsDisabled(false)
            }, 10000)
          }}
          disabled={isDisabled || isBlockerError}
        />
      </div>
    </>
  )
}

export default SubmitUpdateWizard

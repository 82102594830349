import React from 'react'
import { useTranslation } from 'react-i18next'
import { shortenName } from '../../../utils/general'
import EmployeeMeiBoxes from '../../../components/DashboardMei'
import MeiTopScrollbar from './MeiTopScrollbar'

const MeiCxoBox = ({ setIsHelpModalOpen, alData = null, setData, setIsModalOpen }) => {
  const { t } = useTranslation(['Dashboard'])

  return (
    <div className="charts_outer_box" style={{ boxShadow: 'none' }}>
      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1rem' }}>
        <div>
          <span>{t('managementExecIndex.whatIsMei')} </span>
          <span
            style={{
              cursor: 'pointer',
              fontSize: '14px',
              color: '#0926D5',
              textDecoration: 'underline',
              marginLeft: '0.5rem',
            }}
            onClick={() => {
              setIsHelpModalOpen(true)
            }}
          >
            {t('managementExecIndex.seeHelp')}
          </span>
        </div>
      </div>
      <MeiTopScrollbar>
        <div className="chart_container">
          {alData !== null && alData.length > 0 ? (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
              {alData
                .filter((metrics) => metrics?.coordinated_metrics?.length > 0)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((metrics, index) => (
                  <div style={{ display: 'flex', flexDirection: 'column', width: '10rem' }}>
                    <h4 style={{ margin: '8px' }}>{shortenName(metrics?.name)}</h4>
                    <div style={{ marginTop: '1%' }}>
                      <EmployeeMeiBoxes
                        key={index}
                        metrics={metrics}
                        isCxo={true}
                        setIsModalOpen={setIsModalOpen}
                        setData={setData}
                      />
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <p
              style={{ marginLeft: '0', marginRight: '0', marginBottom: '2rem', textAlign: 'left' }}
            >
              {t('chartsCard.noMeiData')}
            </p>
          )}
        </div>
      </MeiTopScrollbar>
    </div>
  )
}

export default MeiCxoBox

import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const deleteHigherObjective = async (higherObj) => {
  const { data } = await axios.post(`${BASE_URL}/delete_higher_obj`, higherObj, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return data
}

export default function useDeleteHigherObjective() {
  const queryClient = useQueryClient()
  return useMutation((data) => deleteHigherObjective(data), {
    onSuccess: (response) => {
      //if (response.ok) {
      void queryClient.invalidateQueries('higherObjectives')
      //}
    },
  })
}

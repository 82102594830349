import React, { useState, useEffect, useRef } from 'react'
import Card, { PriorityFlagIcon } from './common/Card'
import './mappingContent.scss'
import {
  getMeetingTopic,
  useUpdateMeetingLogs,
  meetingCommentAdd,
  meetingMilestonAdd,
} from '../../../../api/services/meetingTopic.services'
import Toast from '../../../../common/toast'
import InPageLoader from '../../../../components/InPageLoader/index'
import SelectSingleField from '../../../../components/CustomMultipleSelect/singleOption'
import AddEditMeetingTopicModal from '../../../../components/AddEditMeetingTopicModal'
import { useLocation, useOutletContext } from 'react-router-dom'
import {
  getCompanyConfigSettings,
  getConsolidatedConfigSettings,
  hasRole,
} from '../../../../utils/auth'
import { useTranslation, Trans } from 'react-i18next'
import { postHttpRequest } from '../../../../api/query/dynamicAPI'
import Swal from 'sweetalert2'
import TopScrollbar from './TopScrollbar'
import { getHttpRequest } from '../../../../api/query/dynamicAPI'
import EmployeeAllocationModal from '../../../../components/EmployeeAllocationModal'
import useGetAreaEmployees from '../../../../api/query/useGetAreaEmployees'
import Select from 'react-select'
import { getObjectivUserList } from '../../../../api/services/employee.services'
import { meetingViewModesEnum } from '../../../../utils/enumValues'
import { getSingleMeetingTopicDetails } from '../../../../api/services/meetingTopic.services'
import BGUScorecardModal from '../BGUScoreCard/BGUScoreCardModal'
import MeetingSelectionModal from '../../../../components/MeetingSelectionModal'
import TopicsReportModal from '../../../../components/TopicReportModal'
import StreamAudioRecorder from '../../../../components/StreamAudioRecorder'
import { useMeetingRecording } from '../../../../utils/meetingRecordingContext/meetingRecordingContext'
import TagSelectionModal from '../../../../components/TagSelectionModal'
import CardModal from '../../../../components/CardModal'

const MappingContent = () => {
  const VIEW_FRAME_INITIAL_SIZE = 30
  const VIEW_FRAME_INCREMENT_SIZE = 20

  const { t } = useTranslation(['MeetingHub'])
  const {
    refreshBGUScorecardFn,
    loadAgain,
    reload,
    selectedLeaderToLoadMH,
    teamLeadersData,
    setEIdsToLoadBGU,
    setSelectedViewMode,
    selectedViewMode,
    getLoadingStateFromChildComponent,
    getIfMeetingTopicExists,
    isScoreCardCollapsed,
  } = useOutletContext()
  const [isLoading, setIsLoading] = useState(false)
  const [refreshButtonLoading, setRefreshButtonLoading] = useState(false)
  const [resolved, setResolved] = useState(false)

  const isProductSettingsEnabled = getCompanyConfigSettings('productSettings')
  const enableAdminTopics = getConsolidatedConfigSettings('enable_admin_topics')

  const [Blocker, setBlocker] = useState([])
  const [Decision, setDecision] = useState([])
  const [Update, setUpdate] = useState([])
  const [adminTopic, setAdminTopics] = useState([])
  const [allDataLength, setAllDataLength] = useState(0)

  const [finalData, setFinalData] = useState([])

  const [currentSelection, setCurrentSelection] = useState([])
  const [currentViewframeSize, setCurrentViewframeSize] = useState(VIEW_FRAME_INITIAL_SIZE)
  const [isFlagLoading, setIsFlagLoading] = useState([])

  const [objectiveList, setObjectiveList] = useState([])
  const [personFilterList, setPersonFilterList] = useState([])
  const [topicsList, setTopicsList] = useState([])
  const [tagList, setTagList] = useState([])
  const [tagFilterOptions, setTagFilterOptions] = useState([])
  const [selectedPerson, setSelectedPerson] = useState([])
  const [selectedObjective, setSelectedObjective] = useState('')
  const [selectedTopic, setSelectedTopic] = useState('')
  const [selectedTag, setSelectedTag] = useState([])
  const [selectedMeetingId, setSelectedMeetingId] = useState(null)
  const [selectedTagId, setSelectedTagId] = useState(null)
  const [isMeetingSelectionModalOpen, setIsMeetingSelectionModalOpen] = useState(false)
  const [isTagSelectionModalOpen, setIsTagSelectionModalOpen] = useState(false)
  const [previousSelectedViewMode, setPreviousSelectedViewMode] = useState(selectedViewMode)

  const [employeeAlloc, setEmployeeAlloc] = React.useState({})
  const [isAllocModalOpen, setIsAllocModalOpen] = React.useState(false)

  const [isBguScoreCardModalOpen, setIsBguScoreCardModalOpen] = React.useState(false)

  const {
    data: areaEmployeesData,
    isLoading: isTeamLeaderLoading,
    isFetching: isTeamLeaderFetching,
    error: teamLeaderError,
  } = useGetAreaEmployees()

  const [currentType, setCurrentType] = useState('')
  const [flag, setFlag] = useState(false)
  const [viewHigherLevelObjectives, setViewHigherLevelObjectives] = useState(false)

  const [isAddMeetingTopicOpen, setIsAddMeetingTopicOpen] = React.useState(false)
  // Passing this from the parent component, commenting out for now. If fix looks okay, delete this comment
  // const { data: teamLeadersData, isLoading: isTeamLeadersLoading } = useAreaLeaderTeamLeaders()
  // const [teamLeadersData, setTeamLeadersData] = React.useState([])

  const [leaderName, setLeadeName] = useState('')

  const [buttonTypes, setButtonTypes] = useState([])

  const [userList, setUserList] = React.useState([])

  const cfg = JSON.parse(localStorage.getItem('cfg'))
  const showMhMe = cfg.show_mh_me ?? false
  const showMhTeam = cfg.show_mh_my_team ?? false
  const showMhObjectives = cfg.show_mh_my_objectives ?? false

  // let runJoyRide = false

  // const [reload, setReload] = React.useState(0)

  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const umtId = parseInt(queryParams.get('umtId'))
  const umtType = parseInt(queryParams.get('umtType'))
  console.log('umtId', umtId)
  console.log('umtType', umtType)
  const [isCardModalOpen, setIsCardModalOpen] = useState(umtId > 0 && umtType > 0)

  const [isMapListCalledOnLoad, setIsMapListCalledOnLoad] = useState(false)
  const [isFiltersSectionHidden, setIsFiltersSectionHidden] = useState(false)

  const [meetingTopicReportOpen, setMeetingTopicReportOpen] = useState(false)

  const isDelegate = parseInt(localStorage.getItem('dType')) === 5 ? true : false

  const canUseMeetingSummarization =
    getConsolidatedConfigSettings('can_use_meeting_summarization') === true
  const { state, dispatch, updateTopicFocusFn } = useMeetingRecording()

  const isTeamMeetingView = hasRole('ic') || (hasRole('team') && !isDelegate)
  // const isBrian = localStorage.getItem('tfmu') === 'brian.timmeny@walmart.com'
  const canViewCustomTagsMh = getConsolidatedConfigSettings('enable_custom_tags_mh') === true

  useEffect(() => {
    // if(selectedViewMode !== null && selectedViewMode !== undefined){
    //   setViewMode(selectedViewMode)
    // }

    // // by default area/cxo should see 'Me'
    // else if (hasRole('area') || hasRole('cxo')) {
    //   setViewMode(meetingViewModesEnum.ACTION_VIEW)
    // }
    // else{
    //   setViewMode(meetingViewModesEnum.PEOPLE_VIEW)
    // }

    ;(async () => {
      const response = await getObjectivUserList(0)
      if (response?.employees)
        setUserList(response.employees.map((emp) => ({ display: emp.name, id: emp.eId })))
    })()
  }, [])

  useEffect(() => {
    if (
      !isTeamMeetingView &&
      (teamLeadersData == null ||
        teamLeadersData == undefined ||
        teamLeadersData.length == 0 ||
        selectedViewMode == null ||
        selectedViewMode == undefined)
    ) {
      return
    }

    if (
      (selectedViewMode === meetingViewModesEnum.CUSTOM_MEETING && selectedMeetingId === null) ||
      isMeetingSelectionModalOpen === true
    ) {
      console.log('1')
      return
    }

    // the modal just opened, and we haven't selected a tag yet
    if (
      (selectedViewMode === meetingViewModesEnum.CUSTOM_TAG && selectedTagId === null) ||
      isTagSelectionModalOpen === true
    ) {
      console.log('2')
      return
    }

    // we don't want to call getMapList when the view mode is not custom meeting and selectedMeetingId is null
    // 1. if we don't reset selectedMeetingId to null, then click Me then Custom meeting, it will not load the data when you click save, even though the meeting is pre-selected;
    // because selectedMeetingId doesn't change and useEffect doesn't fire.
    // 2. if we reset selectedMeetingId without this condition, it will likely to trigger the getMapList twice
    // 1 for selectedViewMode change, and 1 for selectedMeetingId change

    // Note on 11.28 since we reset selectedMeetingId to null when we successfully select a tag,
    // this condition is skipped.
    if (selectedViewMode !== meetingViewModesEnum.CUSTOM_MEETING && selectedMeetingId !== null) {
      console.log('3')
      return
    }

    // vice versa of the above condition
    if (selectedViewMode !== meetingViewModesEnum.CUSTOM_TAG && selectedTagId !== null) {
      console.log('4')
      return
    }

    // Once loaded, this should never be empty because it should always contain the current user's
    // eId. Ideally we don't want to fire this useEffect when teamLeadersData is FULL (contains all
    // TLs ID), but I don't know how to solve that right now.
    // if (eIdsOfSelectedTls.length == 0) {
    //   return
    // }

    getMapList('init', resolved)
    getLoadingStateFromChildComponent('MeetingTopic', true)

    // eIdsOfSelectedTls => when there is one AL (without any TL under him/her),
    // we need 'eIdsOfSelectedTls' to trigger getMapList since when it's first called,eIdsOfSelectedTls = [] ...
  }, [
    reload,
    resolved,
    viewHigherLevelObjectives,
    teamLeadersData,
    selectedViewMode,
    selectedMeetingId,
    selectedTagId,
  ])

  React.useEffect(() => {
    if (isMapListCalledOnLoad) {
      setIsMapListCalledOnLoad(true)
      let isVnovaIc = isProductSettingsEnabled && hasRole('ic')
      if (allDataLength == 0 && !isVnovaIc) {
        // setViewMode(meetingViewModesEnum.PEOPLE_VIEW)
        setSelectedViewMode(meetingViewModesEnum.PEOPLE_VIEW)
      }
    }
  }, [isMapListCalledOnLoad])

  React.useEffect(() => {
    if (state.shouldRefresh && state.isRecordingStop && state.topicFocusEventTracker.length > 0) {
      let lastEvent = state.topicFocusEventTracker[state.topicFocusEventTracker.length - 1]
      // don't remove this...
      let timer = setTimeout(() => {
        refreshTopic(lastEvent.umtId)
      }, 1000)

      return () => {
        clearTimeout(timer)
      }
    }
    // not a good idea to use state property here, but it's okay for now...
    // because everytime you land on this page, it will trigger this useEffect,
    // but thankfully currentSelection = [] at the time it's triggered
  }, [state.isRecordingStop])

  React.useEffect(() => {
    if (state.meetingId) {
      setSelectedViewMode(meetingViewModesEnum.CUSTOM_MEETING)
      setSelectedMeetingId(state.meetingId)
    } else {
      setSelectedViewMode(meetingViewModesEnum.ACTION_VIEW)
      setSelectedMeetingId(null)
    }
  }, [state.meetingId])

  const refreshTopic = async (umtId) => {
    let topicNeededUpdate = currentSelection.find((topic) => topic.umtId === umtId)
    if (topicNeededUpdate) {
      let postObj = { umtId: topicNeededUpdate.umtId, topicType: topicNeededUpdate.topicType }
      const singleTopic = await getSingleMeetingTopicDetails(postObj)
      updateSingleTopicCard(singleTopic)
    }
  }

  // const loadAgain = () => {
  //   setReload(reload+1)
  // }

  //TODO Swetha: Commenting for now, fix later
  // useEffect(() => {
  //   if(filtersAfterRedirect) {
  //     setViewHigherLevelObjectives(filtersAfterRedirect.readOnly)
  //   }
  // }, [filtersAfterRedirect])

  useEffect(() => {
    setIsFlagLoading(Array.from({ length: currentSelection.length }, () => false))

    setCurrentViewframeSize(VIEW_FRAME_INITIAL_SIZE)
  }, [currentSelection])

  async function getMapList(type, resolved, fromRefreshButton = false) {
    if (!fromRefreshButton) {
      setIsLoading(true)
    } else {
      setRefreshButtonLoading(true)
    }
    //The toggle is available just for the area leaders
    let evaluatedViewMode = selectedViewMode
    const result = await getMeetingTopic(
      resolved,
      viewHigherLevelObjectives,
      selectedLeaderToLoadMH,
      evaluatedViewMode,
      selectedMeetingId,
      selectedTagId,
    )
    if (!fromRefreshButton) {
      setIsLoading(false)
    } else {
      setRefreshButtonLoading(false)
    }
    if (result) {
      setIsMapListCalledOnLoad(true)
      let finalArray = []

      finalArray = finalArray.concat(result.blockedObjectives)
      finalArray = finalArray.concat(result.decisionObjectives)
      finalArray = finalArray.concat(result.updateObjectives)
      finalArray = finalArray.concat(result.adminTopicObjectives)

      if (finalArray.length !== 0) {
        getIfMeetingTopicExists(true)
      }

      // finalArray.sort((a, b) => {
      //   return b.objectiveId - a.objectiveId;
      // });

      // employees.sort((a, b) => b.age - a.age);
      const key = 'objectiveId'
      const arrayUniqueByKey = [...new Map(finalArray.map((item) => [item[key], item])).values()]
      arrayUniqueByKey.sort((a, b) => {
        // Check for negative initiativeId values
        if (a.initiativeId < 0 && b.initiativeId >= 0) return 1
        if (b.initiativeId < 0 && a.initiativeId >= 0) return -1

        // If both are negative or both are non-negative, proceed with normal sorting
        if (a.initiativeId !== b.initiativeId) {
          return a.initiativeId - b.initiativeId
        }

        // If initiativeId is the same, compare by statement
        return a.statement.localeCompare(b.statement)
      })
      setObjectiveList(arrayUniqueByKey)
      const topicsList = (finalArray || []).map((item) => ({
        name: item.actionNeeded,
        id: item.actionNeeded,
      }))
      topicsList.sort((a, b) => a.name.localeCompare(b.name))
      setTopicsList(topicsList)

      const topicOwnerList = (finalArray || []).map((item) => ({
        name: item.topicOwnerName,
        id: item.topicOwnerId,
      }))
      const leadersData = (teamLeadersData?.employees || []).map((item) => ({
        name: item.name,
        id: item.eId,
      }))
      const mergedData = [...leadersData, ...topicOwnerList]

      const mergedDataUnique = [...new Map(mergedData.map((item) => [item.name, item])).values()]
      mergedDataUnique.sort((a, b) => a.name.localeCompare(b.name))
      setPersonFilterList(mergedDataUnique)
      //set tag list
      const tagListExtracted = (finalArray || []).map((item) => item.tags).flat()
      let transferredData = tagListExtracted.map((item) => ({ name: item.tagName, id: item.tagId }))
      setTagList(transferredData)
      const seenNames = new Set()
      let optionList = transferredData.filter((item) => {
        if (!seenNames.has(item.name)) {
          seenNames.add(item.name)
          return true
        }
        return false
      })
      optionList.sort((a, b) => a.name.localeCompare(b.name))
      setTagFilterOptions(optionList)

      setFinalData(finalArray)

      setBlocker(result.blockedObjectives)
      setDecision(result.decisionObjectives)
      setUpdate(result.updateObjectives)
      setAdminTopics(result.adminTopicObjectives)
      setLeadeName(result.leaderName)
      setAllDataLength(
        result.blockedObjectives.length +
          result.decisionObjectives.length +
          result.updateObjectives.length +
          result.adminTopicObjectives.length,
      )
      // whenever the action/decision is added, we don't wish to reset bgu filters.
      // setCurrentType('All')
      if (location?.state?.id) {
        if (location?.state?.redirToTeamTopics === true) {
          setSelectedViewMode(meetingViewModesEnum.PEOPLE_VIEW)
        }
        inputChangedHandler([{ id: location.state.id, name: location.state.name }], 'person')
      }
      if (location?.state?.obj) {
        inputChangedHandler({ objectiveId: parseInt(location?.state?.obj) }, 'objective')
      }
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  // useEffect(() => {
  //   setTypeMeeting(currentType);
  // }, [Update]);

  useEffect(() => {
    filterData()
  }, [currentType, selectedObjective, selectedPerson, finalData, , selectedTag])

  // remember this is called when users click on type filter
  async function setTypeMeeting(type) {
    // if same type, do nothing
    if (type == currentType) return
    setCurrentType(type)
  }

  const mutationUpdateMeetingLogs = useUpdateMeetingLogs()

  async function createFlagStatus(data, type, index) {
    const formData = {}
    if (type == 'flag') {
      formData.isPriority = !data?.isPriority
    } else if (type == 'status') {
      formData.status = !data?.status
    }

    formData.umtId = data?.umtId

    let tempIsFlagLoading = isFlagLoading
    tempIsFlagLoading[index] = true
    setIsFlagLoading(tempIsFlagLoading)

    await mutationUpdateMeetingLogs.mutateAsync(formData, {
      onSuccess: () => {
        loadAgain()
        refreshBGUScorecardFn()
      },
      onError: () => {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      },
    })
  }

  async function createActivity(data, comment, index, mentions = []) {
    const formData = {
      milestoneDesc: comment,
      goalId: data?.objectiveId,
      mentions: mentions.map((mention) => mention.id),
    }

    if (state.meetingInstanceId) {
      formData.meetingInstanceId = state.meetingInstanceId
    }

    formData.umtId = data?.umtId

    const result = await meetingMilestonAdd(formData)
    if (result) {
      // loadAgain()
      let topicNeededUpdate = currentSelection.find((topic) => topic.umtId === data.umtId)
      if (topicNeededUpdate) {
        let postObj = { umtId: topicNeededUpdate.umtId, topicType: topicNeededUpdate.topicType }

        const singleTopic = await getSingleMeetingTopicDetails(postObj)
        updateSingleTopicCard(singleTopic)
      }

      refreshBGUScorecardFn()

      Toast.fire({
        icon: 'success',
        title: "<div style='color:green'>" + t('meetingTopics.actionAddedMessage') + '</div>',
      })
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const updateSingleTopicCard = (singleTopic) => {
    let topicDetail = null
    if (singleTopic?.blockedObjectives?.length > 0) {
      topicDetail = singleTopic.blockedObjectives[0]
    } else if (singleTopic?.decisionObjectives?.length > 0) {
      topicDetail = singleTopic.decisionObjectives[0]
    } else if (singleTopic?.updateObjectives?.length > 0) {
      topicDetail = singleTopic.updateObjectives[0]
    } else if (singleTopic?.adminTopicObjectives?.length > 0) {
      topicDetail = singleTopic.adminTopicObjectives[0]
    }

    if (topicDetail === null) return
    let newCurrentSelection = currentSelection.map((topic) => {
      if (topic.umtId === topicDetail.umtId) {
        return topicDetail
      }
      return topic
    })
    setCurrentSelection(newCurrentSelection)
    let newFinalData = finalData.map((topic) => {
      if (topic.umtId === topicDetail.umtId) {
        return topicDetail
      }
      return topic
    })
    setFinalData(newFinalData)
  }

  async function createComment(data, index) {
    const result = await meetingCommentAdd(data)
    if (result) {
      // loadAgain()
      let topicNeededUpdate = currentSelection.find((topic) => topic.umtId === data.umtId)
      if (topicNeededUpdate) {
        let postObj = { umtId: topicNeededUpdate.umtId, topicType: topicNeededUpdate.topicType }
        const singleTopic = await getSingleMeetingTopicDetails(postObj)
        updateSingleTopicCard(singleTopic)
      }
      if (data?.isDecision) {
        Toast.fire({
          icon: 'success',
          title: "<div style='color:green'>" + t('meetingTopics.decisionMadeMessage') + '</div>',
        })
      }
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  async function filterFlag() {
    // if user clicks on same button twice, remove it from the array (so not underline)
    if (buttonTypes.includes('Flagged')) {
      setButtonTypes((prev) => prev.filter((item) => item !== 'Flagged'))
    } else {
      setButtonTypes((prev) => [...prev, 'Flagged'])
    }
    setFlag(!flag)
  }

  const handleViewHigherLevelObjectives = () => {
    setViewHigherLevelObjectives(!viewHigherLevelObjectives)
  }

  function inputChangedHandler(e, type) {
    // Get name of changed input, and its corresponding value
    setSelectedObjective('')
    setSelectedPerson([])
    setSelectedTopic([])
    setSelectedTag([])
    if (type == 'objective') {
      console.log('objective', e)
      e ? setSelectedObjective(e.objectiveId) : setSelectedObjective('')
      setEIdsToLoadBGU([])
    }
    if (type == 'person') {
      setSelectedPerson(e)
      setEIdsToLoadBGU(e.map((person) => person.id))
    }
    if (type == 'topic') {
      setSelectedTopic(e)
      setEIdsToLoadBGU([])
    }
    if (type == 'tag') {
      setSelectedTag(e)
      setEIdsToLoadBGU([])
    }
  }

  // TODO Swetha: We can optimize this further
  const filterData = () => {
    let filterFunction = (item) => item
    if (selectedPerson.length > 0) {
      filterFunction = (item) => selectedPerson.some((person) => person.id === item.topicOwnerId)
    } else if (selectedObjective !== '') {
      filterFunction = (item) => item.objectiveId === selectedObjective
    } else if (selectedTopic.length > 0) {
      filterFunction = (item) => selectedTopic.some((topic) => topic.id === item.actionNeeded)
    } else if (selectedTag.length > 0) {
      filterFunction = (item) =>
        selectedTag.some((tag) => item.tags?.some((itemTag) => itemTag.tagName === tag.name))
    }

    const blockers = finalData.filter((item) => item.topicType === 1 && filterFunction(item))
    const decisions = finalData.filter((item) => item.topicType === 2 && filterFunction(item))
    const updates = finalData.filter((item) => item.topicType === 3 && filterFunction(item))
    const adminTopics = finalData.filter((item) => item.topicType === 4 && filterFunction(item))

    setBlocker(blockers)
    setDecision(decisions)
    setUpdate(updates)
    setAdminTopics(adminTopics)

    setAllDataLength(blockers.length + decisions.length + updates.length)
    if (currentType === 'Blocker') {
      setCurrentSelection(blockers)
    } else if (currentType === 'Decision') {
      setCurrentSelection(decisions)
    } else if (currentType === 'Update') {
      setCurrentSelection(updates)
    } else if (currentType === 'Admin Topic') {
      setCurrentSelection(adminTopics)
    } else {
      const allData = finalData.filter(filterFunction)
      setCurrentSelection(allData)
    }
  }

  if (isLoading && reload === 0) {
    return <InPageLoader />
  }

  const handleUnFlag = async () => {
    const result = await Swal.fire({
      title: t('Common:modalMessages.areYouSureUnflagAll'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })

    if (result.isConfirmed) {
      let url = '/unflag_all_topics'
      await postHttpRequest(url)
      loadAgain()
    }
  }

  const handleMeetingTopicsReport = () => {
    setMeetingTopicReportOpen(true)
  }

  const getEmployeeAlloc = async () => {
    let url = '/emp_obj_alloc?get-all-alloc=true'
    let allocs = await getHttpRequest(url)
    let allocMaps = {}
    allocs?.objIcMappings?.forEach((empAlloc) => {
      if (!allocMaps[empAlloc.eName]) {
        allocMaps[empAlloc.eName] = []
      }
      allocMaps[empAlloc.eName].push({
        objDesc: empAlloc.objDesc,
        eName: empAlloc.eName,
        allocation: empAlloc.allocation,
      })
    })
    setEmployeeAlloc(allocMaps)
    setIsAllocModalOpen(true)
  }

  const handleModeChange = (value) => {
    // setViewMode(value)
    setSelectedViewMode(value)
  }

  const updateLocalStorageConfig = (value) => {
    let config = JSON.parse(localStorage.getItem('cfg'))
    config.meeting_hub_view_mode = selectedViewMode
    let updatedConfig = JSON.stringify(config)
    localStorage.setItem('cfg', updatedConfig)
  }

  const makeThisMyDefaultView = async () => {
    const postData = {
      meetingHubViewMode: selectedViewMode,
    }
    try {
      const data = await postHttpRequest('/edit_employees_user_preferences', postData)
      if (data) {
        updateLocalStorageConfig()
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.defaultViewUpdatedSuccessfully'),
        })
      } else {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const clearFilters = () => {
    setSelectedObjective('')
    setSelectedPerson([])
    setSelectedTopic([])
    setSelectedTag([])
  }

  const updateCardRecordingState = async (umtId, objId, newValue, newState) => {
    dispatch({
      type: 'SET_CARD_RECORDING_STARTED',
      umtId: umtId,
      newValue: newValue,
    })
    await updateTopicFocusFn(umtId, objId, newState, currentSelection, updateSingleTopicCard)
  }

  const refreshMeetingTopics = () => {
    getMapList('init', resolved, true)
  }
  return (
    <>
      {console.log('isCardModalOpen', isCardModalOpen)}
      {isCardModalOpen === true && (
        <CardModal
          isModalOpen={isCardModalOpen}
          closeModal={() => {
            setIsCardModalOpen(false)
          }}
          umtId={umtId}
          topicType={umtType}
        />
      )}
      {isAddMeetingTopicOpen && (
        <AddEditMeetingTopicModal
          isModalOpen={isAddMeetingTopicOpen}
          closeModal={() => {
            setIsAddMeetingTopicOpen(false)
            setSelectedObjective('')
            setCurrentType('')
          }}
          loadAgain={loadAgain}
          isProductSettingsEnabled={isProductSettingsEnabled}
          refreshBGUScorecardFn={refreshBGUScorecardFn}
          teamLeadersList={teamLeadersData}
          showMineButton={selectedLeaderToLoadMH == null}
          selectedLeaderToLoadMH={selectedLeaderToLoadMH}
        />
      )}
      {isAllocModalOpen === true && (
        <EmployeeAllocationModal
          isModalOpen={isAllocModalOpen}
          closeModal={() => {
            setIsAllocModalOpen(false)
          }}
          employeeAlloc={employeeAlloc}
          teamMembers={areaEmployeesData?.employees?.filter((emp) => emp.isDeactivated === false)}
        />
      )}
      {isBguScoreCardModalOpen === true && (
        <BGUScorecardModal
          loadAgain={loadAgain}
          visibility={'visible'}
          getLoadingStateFromChildComponent={getLoadingStateFromChildComponent}
          closeModal={() => setIsBguScoreCardModalOpen(false)}
          selectedViewMode={selectedViewMode}
          selectedMeetingId={selectedMeetingId}
          selectedTagId={selectedTagId}
        />
      )}
      {isMeetingSelectionModalOpen === true && (
        <MeetingSelectionModal
          closeModal={(hasSelection) => {
            setIsMeetingSelectionModalOpen(false)
            if (!hasSelection) {
              setSelectedViewMode(previousSelectedViewMode)
            } else {
              // reset selectedTagId to tackle getMapList useEffect..
              setSelectedTagId(null)
            }
          }}
          setSelectedMeetingId={setSelectedMeetingId}
          selectedMeetingId={selectedMeetingId}
        />
      )}
      {isTagSelectionModalOpen === true && (
        <TagSelectionModal
          closeModal={(hasSelection) => {
            setIsTagSelectionModalOpen(false)
            if (!hasSelection) {
              setSelectedViewMode(previousSelectedViewMode)
            } else {
              // reset selectedMeetingId
              setSelectedMeetingId(null)
            }
          }}
          setSelectedTagId={setSelectedTagId}
          selectedTagId={selectedTagId}
        />
      )}
      {meetingTopicReportOpen && (
        <TopicsReportModal
          closeModal={() => setMeetingTopicReportOpen(false)}
          loadAgain={loadAgain}
        />
      )}
      <div className="container-meeting-topic">
        {!(isProductSettingsEnabled && hasRole('ic')) && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '1rem',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '0.5rem',
                    marginTop: '0.4rem',
                  }}
                >
                  <span style={{ fontWeight: 'bold' }}>{t('meetingTopics.showTopicsBy')}</span>
                  {!(hasRole('team') || hasRole('ic')) && (
                    <div
                      style={{
                        color: '#0926D5',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      <span
                        style={{ cursor: 'pointer', fontSize: '14px' }}
                        onClick={() => {
                          makeThisMyDefaultView()
                        }}
                      >
                        {t('meetingTopics.makeThisMyDefaultView')}
                      </span>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    columnGap: '0.5rem',
                    marginBottom: '0.5rem',
                  }}
                  id="meetingHubTutorial0"
                >
                  {!(hasRole('team') || hasRole('ic')) && (
                    <div
                      className="option-button"
                      style={{
                        backgroundColor:
                          selectedViewMode === meetingViewModesEnum.ACTION_VIEW
                            ? '#4472c4'
                            : '#a6a6a6',
                      }}
                      onClick={(event) => {
                        setSelectedMeetingId(null)
                        setSelectedTagId(null)
                        handleModeChange(meetingViewModesEnum.ACTION_VIEW)
                      }}
                    >
                      {t('meetingTopics.byActions')}
                    </div>
                  )}
                  <div
                    className="option-button"
                    style={{
                      backgroundColor:
                        selectedViewMode === meetingViewModesEnum.PEOPLE_VIEW
                          ? '#4472c4'
                          : '#a6a6a6',
                    }}
                    onClick={(event) => {
                      setSelectedMeetingId(null)
                      setSelectedTagId(null)
                      handleModeChange(meetingViewModesEnum.PEOPLE_VIEW)
                    }}
                  >
                    {hasRole('team') || hasRole('ic')
                      ? t('meetingTopics.byActions')
                      : t('meetingTopics.byTeamMembers')}
                  </div>
                  {!(hasRole('team') || hasRole('ic')) && hasRole('area') && (
                    <div
                      className="option-button"
                      style={{
                        backgroundColor:
                          selectedViewMode === meetingViewModesEnum.OBJECTIVE_VIEW
                            ? '#4472c4'
                            : '#a6a6a6',
                      }}
                      onClick={(event) => {
                        setSelectedMeetingId(null)
                        setSelectedTagId(null)
                        handleModeChange(meetingViewModesEnum.OBJECTIVE_VIEW)
                      }}
                    >
                      {t('meetingTopics.byObjectives')}
                    </div>
                  )}
                  {/* temporary */}
                  <div
                    className="option-button"
                    style={{
                      backgroundColor:
                        selectedViewMode === meetingViewModesEnum.CUSTOM_MEETING
                          ? '#4472c4'
                          : '#a6a6a6',
                    }}
                    onClick={(event) => {
                      setPreviousSelectedViewMode(selectedViewMode)
                      handleModeChange(meetingViewModesEnum.CUSTOM_MEETING)
                      // if(selectedViewMode !== meetingViewModesEnum.CUSTOM_MEETING){
                      setIsMeetingSelectionModalOpen(true)
                      // }
                    }}
                  >
                    {t('meetingTopics.customMeeting')}
                  </div>
                  {canViewCustomTagsMh && (
                    <div
                      className="option-button"
                      style={{
                        backgroundColor:
                          selectedViewMode === meetingViewModesEnum.CUSTOM_TAG
                            ? '#4472c4'
                            : '#a6a6a6',
                      }}
                      onClick={(event) => {
                        setPreviousSelectedViewMode(selectedViewMode)
                        handleModeChange(meetingViewModesEnum.CUSTOM_TAG)
                        setIsTagSelectionModalOpen(true)
                      }}
                    >
                      {t('meetingTopics.customTag')}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          style={{ display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'flex-end' }}
        >
          <div className="sleek-button sleek-blue" onClick={refreshMeetingTopics}>
            <span
              className="material-symbols-outlined icon-size"
              style={{
                display: 'inline-block',
                transform: refreshButtonLoading ? 'rotate(360deg)' : 'rotate(0deg)',
                transition: 'transform 1s linear',
                transformOrigin: 'center',
                ...(refreshButtonLoading && {
                  transform: 'rotate(360deg)',
                  transition: 'none',
                  animation: 'infinite-rotation 0.5s linear infinite',
                }),
              }}
            >
              refresh
            </span>
            <span>{t('Common:filter.refresh')}</span>
          </div>
          {isProductSettingsEnabled && (
            <div
              className="sleek-button sleek-blue"
              onClick={() => {
                getEmployeeAlloc()
              }}
            >
              <span>{t('Common:commonButtons.allocation')}</span>
            </div>
          )}

          {(!hasRole('ic') || isDelegate) && (
            <div
              className="sleek-button sleek-blue"
              onClick={() => {
                handleMeetingTopicsReport()
              }}
              id="openBGUScorecard"
            >
              <span>{t('meetingTopics.recentlyCompletedTopics')}</span>
            </div>
          )}
          <div
            className="sleek-button sleek-blue"
            onClick={() => {
              setIsBguScoreCardModalOpen(true)
            }}
            id="openBGUScorecard"
          >
            <span>{t('Common:commonButtons.summaryCharts')}</span>
          </div>
          <div
            className="sleek-button sleek-blue"
            onClick={() => {
              setIsFiltersSectionHidden(!isFiltersSectionHidden)
            }}
          >
            <span class="material-symbols-outlined icon-size-l">filter_alt</span>
            <span>{t('Common:filter.filters')}</span>
          </div>

          <div
            className="sleek-button sleek-blue"
            onClick={() => {
              clearFilters()
            }}
          >
            <span>{t('Common:commonButtons.clearFilters')}</span>
          </div>

          {!viewHigherLevelObjectives && (
            <div
              className="sleek-button sleek-full-blue"
              onClick={() => {
                setResolved(false)
                setIsAddMeetingTopicOpen(true)
              }}
            >
              <span class="material-symbols-outlined icon-size-l">add_circle</span>
              <span> {t('Common:commonButtons.topic')}</span>
            </div>
          )}
        </div>
        <div
          className={`transition-mh-div ${isFiltersSectionHidden === false ? 'visible' : ''}`}
          style={{ marginTop: '1rem' }}
        >
          <div
            style={{
              fontSize: '20px',
              color: '#5780ca',
            }}
          >
            <b>{t('meetingTopics.filterTopcsBelow')}</b>
          </div>
          <div
            style={{ display: 'flex', gap: '1rem', margin: '0.5rem 0rem 1rem 0rem' }}
            id="meetingHubTutorial2"
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                minWidth: isScoreCardCollapsed ? '15rem' : '9rem',
              }}
            >
              <label>
                <span>{t('Common:formPlaceHolder.leader')}:</span>
              </label>
              <Select
                placeholder={t('Common:filter.select') + '...'}
                name="selectPerson"
                value={selectedPerson}
                isMulti
                onChange={(e) => {
                  inputChangedHandler(e, 'person')
                }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                options={personFilterList}
                menuPortalTarget={document.body}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                minWidth: isScoreCardCollapsed ? '18rem' : '9rem',
              }}
            >
              <label>
                <span>{t('Common:formPlaceHolder.objective')}:</span>
              </label>
              <SelectSingleField
                placeholder={t('Common:filter.select') + '...'}
                name="selectProject"
                value={objectiveList.filter((item) => item.objectiveId == selectedObjective)}
                options={objectiveList}
                onChange={(e) => {
                  inputChangedHandler(e, 'objective')
                }}
                getOptionLabel={(option) =>
                  isProductSettingsEnabled && option.isGeneralObjective === false
                    ? option?.initiativeId.toString()?.padStart(5, '0') + ' - ' + option.statement
                    : option.statement
                }
                isClearable
                getOptionValue={(option) => option.objectiveId}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                minWidth: isScoreCardCollapsed ? '18rem' : '9rem',
              }}
            >
              <label>
                <span>{t('Common:formPlaceHolder.meetingTopic')}:</span>
              </label>
              <Select
                placeholder={t('Common:filter.select') + '...'}
                name="selectTopic"
                value={selectedTopic}
                isMulti
                onChange={(e) => {
                  inputChangedHandler(e, 'topic')
                }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                options={topicsList}
                menuPortalTarget={document.body}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                minWidth: isScoreCardCollapsed ? '18rem' : '9rem',
              }}
            >
              <label>
                <span>{t('Common:formPlaceHolder.tagsMh')}:</span>
              </label>
              <Select
                placeholder={t('Common:filter.select') + '...'}
                name="selectTag"
                value={selectedTag}
                isMulti
                onChange={(e) => {
                  inputChangedHandler(e, 'tag')
                }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                options={tagFilterOptions}
                menuPortalTarget={document.body}
              />
            </div>
          </div>
          <div className="header_box">
            <div className="left_side_header">
              <div className="left_side_container_left" id="meetingHubTutorial1">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <button
                    type="button"
                    className="btn-common all-btn"
                    onClick={() => setTypeMeeting('All')}
                  >
                    {' '}
                    {t('Common:filter.all')}({allDataLength})
                  </button>
                  {currentType == 'All' && <div className="underline-style"></div>}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <button
                    type="button"
                    className="btn-common blocker-btn"
                    onClick={() => setTypeMeeting('Blocker')}
                  >
                    {' '}
                    {t('meetingTopics.blocker')}({Blocker.length})
                  </button>
                  {currentType == 'Blocker' && <div className="underline-style"></div>}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <button
                    type="button"
                    className="btn-common blue-btn"
                    onClick={() => setTypeMeeting('Decision')}
                  >
                    {' '}
                    {t('meetingTopics.guidance')} ({Decision.length})
                  </button>
                  {currentType == 'Decision' && <div className="underline-style"></div>}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <button
                    type="button"
                    className="btn-common update-btn"
                    onClick={() => setTypeMeeting('Update')}
                  >
                    {' '}
                    {t('meetingTopics.update')} ({Update.length})
                  </button>
                  {currentType == 'Update' && <div className="underline-style"></div>}
                </div>

                {enableAdminTopics && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <button
                      type="button"
                      className="btn-common admin-topic-btn"
                      onClick={() => setTypeMeeting('Admin Topic')}
                    >
                      {' '}
                      {t('meetingTopics.adminTopic')} ({adminTopic.length})
                    </button>
                    {currentType == 'Admin Topic' && <div className="underline-style"></div>}
                  </div>
                )}
              </div>
            </div>
            <div className="left_side_header" style={{ marginTop: '0.5rem' }}>
              <div className="left_side_container_left">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div
                    class="fitted-button blue"
                    onClick={() => {
                      if (buttonTypes.includes('Resolved')) {
                        setButtonTypes((prev) => prev.filter((item) => item !== 'Resolved'))
                      } else {
                        setButtonTypes((prev) => [...prev, 'Resolved'])
                      }
                      setResolved((prev) => !prev)
                    }}
                  >
                    <span>{t('meetingTopics.resolved')}</span>
                  </div>
                  {buttonTypes.includes('Resolved') && <div className="underline-style"></div>}
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div
                    class="fitted-button blue"
                    onClick={() => {
                      filterFlag()
                    }}
                  >
                    <span>{t('Common:filter.flaggedOnly')}</span>
                  </div>
                  {buttonTypes.includes('Flagged') && <div className="underline-style"></div>}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div class="fitted-button blue" onClick={() => handleUnFlag()}>
                    <span>{t('meetingTopics.unflaggAll')}</span>
                  </div>
                  {buttonTypes.includes('FAKE_NEVER_TRUE') && (
                    <div className="underline-style"></div>
                  )}
                </div>
                {/* TODO Swetha: Commenting for now, fix later */}
                {/* {hasRole('cxo') && (
                  <div className='right_side_header'>
                    {t('Common:filter.showExecutiveView')}
                    <input
                      type="checkbox"
                      style={{ marginLeft: '1rem' }}
                      checked={viewHigherLevelObjectives}
                      onChange={() => {
                        handleViewHigherLevelObjectives()
                      }}
                    />
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>

        {viewHigherLevelObjectives && (
          <div>
            <b>{t('meetingTopics.execViewReadOnlyMsg', { leaderName })}</b>
          </div>
        )}
        <TopScrollbar>
          <div className="Cards_container">
            {currentSelection.length == 0 && (
              <div id="noMeetingtopics">{t('meetingTopics.noTopicsMessage')}</div>
            )}
            {currentSelection
              .filter((d) => !flag || d.isPriority)
              .slice(0, currentViewframeSize)
              .map((data, index) => {
                return (
                  <Card
                    key={index}
                    data={data}
                    index={index}
                    currentType={currentType}
                    createFlagStatus={createFlagStatus}
                    flag={flag}
                    resolved={resolved}
                    reload={reload}
                    loadAgain={loadAgain}
                    showInitiativesId={isProductSettingsEnabled}
                    createComment={createComment}
                    refreshBGUScorecardFn={refreshBGUScorecardFn}
                    createActivity={createActivity}
                    teamLeadersList={teamLeadersData}
                    viewOnlyMode={viewHigherLevelObjectives}
                    flagStatusLoading={isFlagLoading[index]}
                    userList={userList}
                    updateCardRecordingState={updateCardRecordingState}
                    isPresentationMode={false}
                    refreshTopic={refreshTopic}
                  />
                )
              })}

            {currentViewframeSize <=
              currentSelection.filter((d) => !flag || d.isPriority).length && (
              <div className="Card" style={{ width: '5rem', backgroundColor: '#f8f8f8' }}>
                <div
                  style={{ height: '100%', cursor: 'pointer' }}
                  onClick={() => setCurrentViewframeSize((old) => old + VIEW_FRAME_INCREMENT_SIZE)}
                >
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  {t('meetingTopics.showMoreTopics')}
                  <br />
                  <span
                    class="material-symbols-outlined"
                    style={{ fontSize: '30px', marginLeft: '-6px' }}
                  >
                    double_arrow
                  </span>
                </div>
              </div>
            )}
          </div>
        </TopScrollbar>
      </div>
      <br />
      <br />
    </>
  )
}
export default MappingContent

import { last } from 'lodash'

const saveStateToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('AIWorkshopExerciseState', serializedState)
  } catch (error) {
    console.error('Error saving state to localStorage:', error)
  }
}

const excoExerciseReducer = (state, action) => {
  switch (action.type) {
    case 'SAVE_QUESTION_TIMER':
      if (action.questionNumber === 1) {
        return {
          ...state,
          timerQuestion1: action.time,
        }
      } else if (action.questionNumber === 2) {
        return {
          ...state,
          timerQuestion2: action.time,
        }
      } else if (action.questionNumber === 3) {
        return {
          ...state,
          timerQuestion3: action.time,
        }
      }
    case 'SAVE_TEAM_NAME':
      return {
        ...state,
        teamName: action.teamName,
      }
    case 'SAVE_GPT_VERSION':
      if (action.questionNumber === 1) {
        return {
          ...state,
          gptVersionQuestion1: action.gptVersion,
        }
      } else if (action.questionNumber === 2) {
        return {
          ...state,
          gptVersionQuestion2: action.gptVersion,
        }
      } else if (action.questionNumber === 3) {
        return {
          ...state,
          gptVersionQuestion3: action.gptVersion,
        }
      }
    case 'SAVE_LAST_PAGE_VISITED':
      let newState = {
        ...state,
        lastPageVisited: action.lastPageVisited,
      }
      saveStateToLocalStorage(newState)
      return newState

    default:
      throw new Error(`Action: ${action.type} not implemented in ExcoExercise reducer.`)
  }
}

export default excoExerciseReducer

import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { act } from 'react-dom/test-utils'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const addMultipleSubActivitiesUsingAudio = async (activityData) => {
  const formData = new FormData()
  formData.append('audio', activityData.audioFile, 'audio.wav')
  formData.append('activityId', activityData.activityId)
  const { data } = await axios.post(
    `${BASE_URL}/generate_multiple_subactivities_using_audio`,
    formData,
    { timeout: 15000 },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )

  return data
}

export default function useAddMultipleSubActivitiesUsingAudio() {
  return useMutation((data) => addMultipleSubActivitiesUsingAudio(data), {
    refetchOnWindowFocus: false,
    retry: 1,
    onSuccess: (response) => {
      return response
    },
    onError: (response) => {
      return response
    },
  })
}

const governanceReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_CEO_LEADER_MODAL':
      return {
        ...state,
        editCsuiteLeaderIndex: action.editCsuiteLeaderIndex,
        editAreaLeaderIndex: action.editAreaLeaderIndex,
        editTeamLeaderIndex: action.editTeamLeaderIndex,
        editCeoLeaderIndex: action.editCeoLeaderIndex,
        editTeamMemberIndex: action.editTeamMemberIndex,
        isEmployeeModalOpen: true,
        role: action.role ?? '',
        data: {
          ...state.data,
          ceoLeadersList: [...action.ceoLeadersList],
        },
      }
    case 'OPEN_C_SUITE_LEADER_MODAL':
      return {
        ...state,
        editCsuiteLeaderIndex: action.editCsuiteLeaderIndex,
        editAreaLeaderIndex: action.editAreaLeaderIndex,
        editTeamLeaderIndex: action.editTeamLeaderIndex,
        editCeoLeaderIndex: action.editCeoLeaderIndex,
        editTeamMemberIndex: action.editTeamMemberIndex,
        isEmployeeModalOpen: true,
        role: action.role ?? '',
        data: {
          ...state.data,
          csuiteLeadersList: [...action.csuiteLeadersList],
        },
      }
    case 'OPEN_AREA_LEADER_MODAL':
      return {
        ...state,
        editCsuiteLeaderIndex: action.editCsuiteLeaderIndex,
        editAreaLeaderIndex: action.editAreaLeaderIndex,
        editTeamLeaderIndex: action.editTeamLeaderIndex,
        editCeoLeaderIndex: action.editCeoLeaderIndex,
        editTeamMemberIndex: action.editTeamMemberIndex,
        isEmployeeModalOpen: true,
        role: action.role ?? '',
        data: {
          ...state.data,
          areaLeadersList: [...action.areaLeadersList],
        },
      }
    case 'OPEN_TEAM_LEADER_MODAL':
      return {
        ...state,
        editCsuiteLeaderIndex: action.editCsuiteLeaderIndex,
        editAreaLeaderIndex: action.editAreaLeaderIndex,
        editTeamLeaderIndex: action.editTeamLeaderIndex,
        editCeoLeaderIndex: action.editCeoLeaderIndex,
        editTeamMemberIndex: action.editTeamMemberIndex,
        isEmployeeModalOpen: true,
        role: action.role ?? '',
        data: {
          ...state.data,
          teamLeadersList: [...action.teamLeadersList],
        },
      }
    case 'OPEN_TEAM_MEMBER_MODAL':
      return {
        ...state,
        editCsuiteLeaderIndex: action.editCsuiteLeaderIndex,
        editAreaLeaderIndex: action.editAreaLeaderIndex,
        editTeamLeaderIndex: action.editTeamLeaderIndex,
        editCeoLeaderIndex: action.editCeoLeaderIndex,
        editTeamMemberIndex: action.editTeamMemberIndex,
        isEmployeeModalOpen: true,
        role: action.role ?? '',
        data: {
          ...state.data,
          teamMembersList: [...action.teamMembersList],
        },
      }
    case 'CLOSE_EMPLOYEE_MODAL':
      return {
        ...state,
        isEmployeeModalOpen: false,
      }
    case 'UPDATE_C_SUITE_LEADER':
      const csuiteLeaders = state.data.csuiteLeadersList
      csuiteLeaders[action.cSuiteLeaderIndex] = action.cSuiteLeadersList
      const allCsuiteLeaders = state.data.allCsuiteLeadersList
      return {
        ...state,
        isEmployeeModalOpen: false,
        data: {
          ...state.data,
          csuiteLeadersList: [...csuiteLeaders],
          allCsuiteLeadersList: [
            ...allCsuiteLeaders.map((cs) =>
              cs.eId === action.cSuiteLeadersList.eId ? action.cSuiteLeadersList : cs,
            ),
          ],
        },
      }
    case 'UPDATE_CEO_LEADER':
      const ceoLeaders = state.data.ceoLeadersList
      ceoLeaders[action.ceoLeaderIndex] = action.ceoLeadersList
      return {
        ...state,
        isEmployeeModalOpen: false,
        data: {
          ...state.data,
          ceoLeadersList: [...ceoLeaders],
        },
      }
    case 'UPDATE_AREA_LEADER':
      const areaLeaders = state.data.areaLeadersList
      areaLeaders[action.areaLeadeIndex] = action.areaLeadersList
      const allAreaLeaders = state.data.allAreaLeadersList

      return {
        ...state,
        isEmployeeModalOpen: false,
        isAreaLeaderListUpdated: action.isAreaLeaderListUpdated,
        data: {
          ...state.data,
          areaLeadersList: [...areaLeaders],
          allAreaLeadersList: [
            ...allAreaLeaders.map((al) =>
              al.eId === action.areaLeadersList.eId ? action.areaLeadersList : al,
            ),
          ],
        },
      }
    case 'UPDATE_TEAM_LEADER':
      const teamLeaders = state.data.teamLeadersList
      teamLeaders[action.teamLeaderIndex] = action.teamLeadersList
      const allTeamLeaders = state.data.allTeamLeadersList
      return {
        ...state,
        isEmployeeModalOpen: false,
        isTeamLeaderListUpdated: action.isTeamLeaderListUpdated,
        data: {
          ...state.data,
          teamLeadersList: [...teamLeaders],
          allTeamLeadersList: [
            ...allTeamLeaders.map((tl) =>
              tl.eId === action.teamLeadersList.eId ? action.teamLeadersList : tl,
            ),
          ],
        },
      }
    case 'UPDATE_TEAM_MEMBER':
      const teamMembers = state.data.teamMembersList
      teamMembers[action.teamMemberIndex] = action.teamMembersList
      return {
        ...state,
        isEmployeeModalOpen: false,
        data: {
          ...state.data,
          teamMembersList: [...teamMembers],
        },
      }
    case 'ADD_TEAM_LEADER':
      return {
        ...state,
        isEmployeeModalOpen: false,
        isTeamLeaderListUpdated: action.isTeamLeaderListUpdated,
        data: {
          ...state.data,
          teamLeadersList: [...state.data.teamLeadersList, action.newLeader],
          allTeamLeadersList: [...state.data.allTeamLeadersList, action.newLeader],
        },
      }
    case 'ADD_C_SUITE_LEADER':
      return {
        ...state,
        isEmployeeModalOpen: false,
        isCsuiteLeaderListUpdated: action.isCsuiteLeaderListUpdated,
        data: {
          ...state.data,
          csuiteLeadersList: [...state.data.csuiteLeadersList, action.newLeader],
          allCsuiteLeadersList: [...state.data.allCsuiteLeadersList, action.newLeader],
        },
      }
    case 'ADD_CEO_LEADER':
      return {
        ...state,
        isEmployeeModalOpen: false,
        data: {
          ...state.data,
          ceoLeadersList: [...state.data.ceoLeadersList, action.newLeader],
        },
      }
    case 'ADD_AREA_LEADER':
      return {
        ...state,
        isEmployeeModalOpen: false,
        isAreaLeaderListUpdated: action.isAreaLeaderListUpdated,
        data: {
          ...state.data,
          areaLeadersList: [...state.data.areaLeadersList, action.newLeader],
          allAreaLeadersList: [...state.data.allAreaLeadersList, action.newLeader],
        },
      }
    case 'CEO_LEADER_SELECTED':
      return {
        ...state,
        isCeoLeaderSelected: true,
        selectedCeoLeaderEId: action.selectedCeoLeaderEId,
        isCsuiteLeaderListUpdated: action.isCSuiteLeaderListUpdated,
      }
    case 'CSUITE_LEADER_SELECTED':
      return {
        ...state,
        isCSuiteLeaderSelected: true,
        selectedCsuiteLeaderEId: action.selectedCsuiteLeaderEId,
        isAreaLeaderListUpdated: action.isAreaLeaderListUpdated,
      }
    case 'AREA_LEADER_SELECTED':
      return {
        ...state,
        isAreaLeaderSelected: true,
        isTeamLeaderListUpdated: action.isTeamLeaderListUpdated,
        selectedAreaLeaderEId: action.selectedAreaLeaderEId,
      }
    case 'ADD_GENERAL_OBJECTIVE':
      return {
        ...state,
        isTeamLeaderListUpdated: action.isTeamLeaderListUpdated ?? state.isTeamLeaderListUpdated,
        isAreaLeaderListUpdated: action.isAreaLeaderListUpdated ?? state.isAreaLeaderListUpdated,
      }
    case 'DELETE_TEAM_LEADER': {
      const emps = action.teamLeadersList.filter(
        (emp, index) => index !== action.editTeamLeaderIndex,
      )

      return {
        ...state,
        isTeamLeaderListUpdated: action.isTeamLeaderListUpdated,
        data: {
          ...state.data,
          teamLeadersList: [...emps],
        },
      }
    }
    case 'UPDATE_TEAM_MEMBER_LIST':
      return {
        ...state,
        data: {
          ...state.data,
          teamMembersList: [...action.teamMembersList],
        },
      }
    default:
      throw new Error(`Action: ${action.type} not implemented in Team Leader reducer.`)
  }
}

export default governanceReducer

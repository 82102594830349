import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getAreaLeadersForCsuiteLeaderData = async (csuiteLeaderId) => {
  const queryString = `/area_leaders_for_csuite_leader/${csuiteLeaderId}`
  const { data } = await axios.get(`${BASE_URL}${queryString}`)
  return data
}

export default function useAreaLeadersForCsuiteLeader(csuiteLeaderId = null, newLength = 0) {
  return useQuery(
    ['c-suite-employees', csuiteLeaderId, newLength],
    () => getAreaLeadersForCsuiteLeaderData(csuiteLeaderId),
    {
      refetchOnWindowFocus: false,
      enabled: csuiteLeaderId !== '000' && csuiteLeaderId !== null && csuiteLeaderId !== 0,
    },
  )
}

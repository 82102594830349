import React from 'react'
import { useDemandIntakeContext } from '../../context/demandIntakeContext'
import target from '../../../../assets/target.svg'
import ObjectiveExampleText from '../../../../components/ObjectiveExampleText'
import ObjectiveForm from './ObjectiveForm'
import Modal from '../../../../components/Modal'
import { useTranslation } from 'react-i18next'
import { hasRole } from '../../../../utils/auth'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../../utils/routes'

const AddObjectiveModal = ({ objectiveType, handleNext, handleNextSkipOneStep, handleSubmit }) => {
  const { state, dispatch } = useDemandIntakeContext()
  const { t } = useTranslation(['Dashboard'])
  const navigate = useNavigate()

  return (
    <>
      {state.isObjFormModalOpen === true && (
        <Modal
          title={
            state.demandIntakeAction === 'editObj'
              ? t('addObjectivesModal.editObjective')
              : objectiveType === 'BAU'
                ? t('addObjectivesModal.addBauObjective')
                : t('addObjectivesModal.addObjective')
          }
          closeModal={() => {
            if (
              state.demandIntakeAction === 'createNewObj' ||
              state.demandIntakeAction === 'editObj' ||
              state.demandIntakeAction === 'activityBoardCreateNewObj'
            ) {
              if (state.demandIntakeActionObj?.teamLeadId !== undefined) {
                window.location.href = '/Governance/ManageObjectives'
              } else if (state.locationState?.path !== undefined) {
                if (state.locationState?.path === '/KPI/ListOfObjectives') {
                  navigate(`/${routes.kpiListOfObjectives}`, {
                    state: { showObjOnly: true },
                  })
                } else {
                  window.location.href = state.locationState?.path
                }
              } else if (state.demandIntakeAction === 'activityBoardCreateNewObj') {
                //kanban redirect
                window.location.href = '/Kanban'
              } else {
                window.location.href = '/Objectives'
              }
            } else {
              dispatch({ type: 'CLOSE_OBJ_FORM_MODAL' })
            }
          }}
          width={'45rem'}
        >
          <ObjectiveForm
            objectiveType={objectiveType}
            objectives={state?.data?.objectives}
            handleNext={handleNext}
            handleNextSkipOneStep={handleNextSkipOneStep}
            handleSubmit={handleSubmit}
            action={state.demandIntakeAction}
          ></ObjectiveForm>
        </Modal>
      )}
    </>
  )
}
export default AddObjectiveModal

import React from 'react'
import './styles.css'

const SetUpWizardEnd = () => {
  return (
    <div className="setup-leader-intro">
      <div className="intro">
        <div className="intro-title">
          <h2>Setup wizard is complete!</h2>
          <p>Check your email for information about your account login.</p>
        </div>
      </div>
    </div>
  )
}

export default SetUpWizardEnd

import React from 'react'
import WizardQuestion from '../../../../components/WizardQuestion'
import { useTranslation } from 'react-i18next'
import { useKPIContext } from '../../context/kpiContext'

const KPIShortTermQuestion = () => {
  const { t } = useTranslation(['KPIWizard', 'Common'])

  const [shortTermAnswer, setShortTermAnswer] = React.useState(null)
  const { state, dispatch } = useKPIContext()

  React.useEffect(() => {
    if (state.answers.shorttermAnswer !== null) {
      setShortTermAnswer(state.answers.shorttermAnswer)
    }
  }, [])

  React.useEffect(() => {
    dispatch({ type: 'SET_SHORTTERM_ANSWER', value: shortTermAnswer })
  }, [shortTermAnswer])

  return (
    <>
      <WizardQuestion
        question={t('shortTermQuestion')}
        answer={shortTermAnswer}
        setAnswer={setShortTermAnswer}
        loadGetFunction={() => {
          return ''
        }}
        objStatement={state.obj.statement}
      />
    </>
  )
}
export default KPIShortTermQuestion

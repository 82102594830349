import React from 'react'
import Button from '../../../../components/Button'
import { postHttpRequest, getHttpRequest } from '../../../../api/query/dynamicAPI'

const ManageInitiative = () => {
  const [fromVal, setFromVal] = React.useState('')
  const [toVal, setToVal] = React.useState('')
  const [message, setMessage] = React.useState('')

  const handleSubmit = async () => {
    if (!fromVal || !toVal || parseInt(toVal) < 1) return
    if (window.confirm('Are you sure you want to swap these initiative numbers?')) {
      let url = '/update_initiative_id'
      let postBody = {
        fromVal,
        toVal,
      }
      try {
        let val = await postHttpRequest(url, postBody)
        setFromVal('')
        setToVal('')
        setMessage(val.message)
      } catch (error) {
        setMessage(error)
      }
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={{ textAlign: 'left' }}>If you are replacing an intiative number in use, </span>
      <span style={{ textAlign: 'left' }}>
        a new number will be automatically assigned to the one in use
      </span>
      <br />
      <br />
      <div>
        <b>{message}</b>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '0.5rem',
          alignItems: 'center',
        }}
      >
        <div>
          <div style={{ textAlign: 'left' }}>From Number</div>
          <input
            name="fromInput"
            placeholder="From"
            value={fromVal}
            onChange={(e) => {
              setFromVal(e.target.value)
              setMessage('')
            }}
          />
        </div>
        <div style={{ marginTop: '0.5rem' }}>
          <div style={{ textAlign: 'left' }}>To Number</div>
          <input
            name="toinput"
            placeholder="To"
            value={toVal}
            onChange={(e) => {
              setToVal(e.target.value)
              setMessage('')
            }}
          />
        </div>
      </div>
      <div style={{ marginTop: '1rem' }}>
        <Button onClick={handleSubmit}>Submit</Button>
      </div>
    </div>
  )
}

export default ManageInitiative

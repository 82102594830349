import React from 'react'
import setupWizardReducer from './setupWizardReducer'

export const DEFAULT_GOAL_DATA = {
  type: null,
  cycle: null,
  isBlocked: false,
  blockReason: null,
  allocatedFte: 0,
  lackingCapacity: false,
}

const TODAY = new Date()
let tomorrow = new Date()
tomorrow.setDate(TODAY.getDate() + 1)

let oneWeekLater = new Date()
oneWeekLater.setDate(TODAY.getDate() + 7)

export const defaultState = {
  isCaseMappingModal: false,
  lackingEnterpriseLeader: true,
  isEmployeeModalOpen: false,
  isObjHelpModalOpen: false,
  isObjFormModalOpen: false,
  isErrorModalOpen: false,
  editObjectiveIndex: null,
  data: {
    enterpriseLeader: [],
    areaLeader: [],
    teamLeader: [],
    individualContributor: [],
    cadencePreferences: {
      frequency: 12,
      startDate: tomorrow.toISOString().split('T')[0],
      tlWizardDueDate: oneWeekLater.toISOString().split('T')[0],
    },
    topLevelObjectives: [],
    areaLevelObjectives: [],
    industryWideMeasures: [],
    customMeasures: [],
    selectedMeasures: [],
  },
}

const SetupWizardContext = React.createContext({
  state: defaultState,
  dispatch: () => {},
})

export const SetupWizardProvider = ({ children, initialState }) => {
  const [state, dispatch] = React.useReducer(setupWizardReducer, initialState)

  const context = {
    state,
    dispatch,
  }

  return <SetupWizardContext.Provider value={context}>{children}</SetupWizardContext.Provider>
}

const useSetupWizardContext = () => {
  const context = React.useContext(SetupWizardContext)
  if (!context) {
    throw new Error(
      'useTeamLeaderContext must be used within a TeamLeaderProvider. Wrap a parent component in <TeamLeaderProvider> to fix this error',
    )
  }

  return context
}

export { useSetupWizardContext }

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

export const getCompletedObjAndUmtAndKpi = async (eId = null) => {
  let url = ''
  if (eId === null) {
    url = `${BASE_URL}/completed_objectives_and_umt_and_kpi`
  } else {
    url = `${BASE_URL}/completed_objectives_and_umt_and_kpi/${eId}`
  }
  const { data } = await axios.get(url)
  return data
}

export default function useGetCompletedObjAndUmtAndKpi() {
  return useQuery(['completed-obj-and-umt'], () => getCompletedObjAndUmtAndKpi(), {
    refetchOnWindowFocus: false,
  })
}

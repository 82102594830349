import Modal from '../Modal'
import { useTranslation } from 'react-i18next'
import './style.css'
import React, { useCallback, useEffect, useState } from 'react'
import useGetCompletedObjAndUmtAndKpi, {
  getCompletedObjAndUmtAndKpi,
} from '../../api/query/useGetCompletedObjAndUmtAndKpi'
import { convertTimeStamToMMDDYYYY, dateToUnix } from '../../utils/time'
import KPIModal from '../KPI/KPIModal'
import Select from 'react-select'
import { getConsolidatedConfigSettings, hasRole } from '../../utils/auth'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import Toast from '../../common/toast'
import { API_ERROR_MESSAGE } from '../../api/constant/route'
import useEditObjectiveUnComplete from '../../api/mutation/editObjectiveUncomplete'
import Swal from 'sweetalert2'
import { getReportingEmployeesList } from '../../api/services/employee.services'
import CardModal from '../CardModal'

const CompletedObjectiveReportModal = ({ isEmailReminderModalOpen, closeModal }) => {
  const { t } = useTranslation(['Common'])
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const { data: objectiveData } = useGetCompletedObjAndUmtAndKpi()
  const [expandedItems, setExpandedItems] = useState({})
  const [filteredObjectives, setFilteredObjectives] = useState([])
  const [isKPIModalOpen, setIsKPIModalOpen] = useState(false)
  const [reloadTracker, setReloadTracker] = React.useState(false)
  const [modalObjId, setModalObjId] = useState(null)
  const [teamLeadersData, setTeamLeadersData] = useState()
  const [teamleadersList, setTeamLeadersList] = useState()
  const [selectedTeamleader, setSelectedTeamleader] = useState()
  const showGovernanceMgmntFeatures =
    getConsolidatedConfigSettings('show_governance_mgmt_features') === true
  const isDelegateTl =
    parseInt(localStorage.getItem('dType')) === 5 && hasRole('team') ? true : false
  const showSelectLeader =
    hasRole('area') || hasRole('cxo') || showGovernanceMgmntFeatures || isDelegateTl
  const [firstLoad, setFirstLoad] = useState(true)
  const mutationEditObjectiveUncomplete = useEditObjectiveUnComplete()
  const [isCardModalOpen, setIsCardModalOpen] = React.useState(false)
  const [topicId, setTopicId] = React.useState(0)
  const [topicType, setTopicType] = React.useState(0)
  const [expandBauObjectives, setExpandBauObjectives] = useState(false)

  useEffect(() => {
    getEmployeeList()
  }, [])

  async function getEmployeeList() {
    try {
      let employeeList = []

      if (showGovernanceMgmntFeatures) {
        const result = await getHttpRequest('/get_cxo_id')
        const eId = result.cxo_id
        employeeList = await getReportingEmployeesList(eId)
      } else if (hasRole('area') || hasRole('cxo') || isDelegateTl) {
        const includeDelegate = isDelegateTl
        employeeList = await getReportingEmployeesList(null, includeDelegate)
      }

      setTeamLeadersData(employeeList)
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: API_ERROR_MESSAGE,
      })
    }
  }

  useEffect(() => {
    if (teamLeadersData && showSelectLeader) {
      setTeamLeadersList(teamLeadersData.employees)
      if (firstLoad) {
        let leader = teamLeadersData?.employees?.filter(
          (emp) => emp.name == localStorage.getItem('tfmnm'),
        )[0]
        setSelectedTeamleader(leader)
        setFirstLoad(false)
      }
    }
  }, [teamLeadersData])

  useEffect(() => {
    let filteredData = dateFilter(objectiveData?.objectives)
    setFilteredObjectives(filteredData)
  }, [fromDate, toDate, objectiveData])

  useEffect(() => {
    if (reloadTracker) {
      getObjectives(selectedTeamleader.eId)
      setReloadTracker(false)
    }
  }, [reloadTracker])

  useEffect(() => {
    if (selectedTeamleader && showSelectLeader) {
      getObjectives(selectedTeamleader.eId)
    }
  }, [selectedTeamleader])

  const dateFilter = (passed_data) => {
    let filteredData = null
    if (passed_data) {
      filteredData = JSON.parse(JSON.stringify(passed_data))
    }

    const fromTimeStamp = dateToUnix(fromDate)
    const nextDay = new Date(toDate)
    nextDay.setDate(nextDay.getDate() + 1)
    nextDay.setHours(23, 59, 0, 0)
    const toTimeStamp = dateToUnix(nextDay)
    const filterByDate = (item) => {
      return (
        (!fromTimeStamp || item.completionTs >= fromTimeStamp) &&
        (!toTimeStamp || item.completionTs <= toTimeStamp)
      )
    }

    const filterDataRecursive = (data) => {
      return data?.filter((item) => {
        if (item.childObjectives.length > 0) {
          item.childObjectives = filterDataRecursive(item.childObjectives)
          return item.childObjectives.length > 0
        } else {
          return filterByDate(item) && !(item.level == 2 && item.childObjectives == 0)
        }
      })
    }

    let data = filterDataRecursive(filteredData)
    return data
  }

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value)
  }

  const handleToDateChange = (event) => {
    setToDate(event.target.value)
  }

  const updateSelectedTeamLeader = (e) => {
    setExpandedItems({})
    setSelectedTeamleader(e)
  }

  const getObjectives = async (eId = null) => {
    try {
      let result = null
      if (showSelectLeader) {
        result = await getCompletedObjAndUmtAndKpi(eId)
      } else {
        result = await getCompletedObjAndUmtAndKpi()
      }
      setFilteredObjectives(result?.objectives)
    } catch (error) {
      console.log('error', error)
    }
  }

  const generateObjective = (item, index) => {
    return (
      <div key={index} style={{ marginBottom: '1rem' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div
            style={{
              fontSize: '1.2rem',
              display: 'flex',
              alignItems: 'flex-start',
              paddingRight: '0.2rem',
            }}
          >
            <span
              style={{
                color: 'gray',
                marginRight: '0.5rem',
                cursor: 'pointer',
                transform: expandedItems[index] ? 'rotate(0deg)' : 'rotate(180deg)',
                transition: 'transform 0.5s',
              }}
              onClick={() => toggleExpand(index)}
            >
              ▲
            </span>
            {item.completionTs != 0 && (
              <span style={{ color: 'gray', marginRight: '0.5rem' }}>
                ({convertTimeStamToMMDDYYYY(item.completionTs)})
              </span>
            )}
            {item.statement}
          </div>
          {item.level !== 2 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: 'auto',
                paddingRight: '0',
                alignSelf: 'flex-start',
              }}
            >
              <div
                className={`rag-letter-box-sm ${
                  item.rag === 'RED'
                    ? 'red-rag-box'
                    : item.rag === 'YELLOW'
                      ? 'amber-rag-box'
                      : item.rag === 'GREEN'
                        ? 'green-rag-box'
                        : 'gray-rag-box'
                }`}
                style={{ marginRight: '1rem' }}
              >
                <span className="icon-size-sm">
                  {item.rag === 'RED'
                    ? 'R'
                    : item.rag === 'YELLOW'
                      ? 'A'
                      : item.rag === 'GREEN'
                        ? 'G'
                        : ''}
                </span>
              </div>
              <div
                style={{
                  color: '#0926D5',
                  textDecoration: 'underline',
                  marginRight: '0rem',
                  width: '5rem',
                }}
                onClick={() => {
                  handleViewKPIs(item.id)
                }}
              >
                <span style={{ cursor: 'pointer', fontSize: '14px' }}>
                  {t('objReportModal.viewKPIs')}
                </span>
              </div>
              <div
                style={{
                  color: '#0926D5',
                  textDecoration: 'underline',
                  marginRight: '0',
                  width: '4rem',
                }}
                onClick={() => {
                  handleUncomplete(parseInt(item.id), index)
                }}
              >
                <span style={{ cursor: 'pointer', fontSize: '14px' }}>
                  {t('objReportModal.reopenObjective')}
                </span>
              </div>
            </div>
          )}
        </div>
        <div style={{ marginTop: '0.5rem', marginLeft: '1rem' }}>
          {item.childObjectives &&
            item.childObjectives.length > 0 &&
            expandedItems[index] &&
            item.childObjectives
              .sort((a, b) => b.completionTs - a.completionTs)
              .map((childItem, childIndex) => (
                <div key={childIndex} style={{ marginBottom: '1rem' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        fontSize: '1.2rem',
                        display: 'flex',
                        alignItems: 'flex-start',
                        paddingRight: '0.2rem',
                      }}
                    >
                      <span
                        style={{
                          color: 'gray',
                          marginRight: '0.5rem',
                          cursor: 'pointer',
                          transform: expandedItems[index][childIndex]
                            ? 'rotate(0deg)'
                            : 'rotate(180deg)',
                          transition: 'transform 0.5s',
                        }}
                        onClick={() => toggleExpand(index, childIndex)}
                      >
                        ▲
                      </span>
                      <span style={{ color: 'gray', marginRight: '0.5rem' }}>
                        ({convertTimeStamToMMDDYYYY(childItem.completionTs)})
                      </span>
                      {childItem.statement}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginLeft: 'auto',
                        paddingRight: '0',
                        alignSelf: 'flex-start',
                      }}
                    >
                      <div
                        className={`rag-letter-box-sm ${
                          childItem.rag === 'RED'
                            ? 'red-rag-box'
                            : childItem.rag === 'YELLOW'
                              ? 'amber-rag-box'
                              : childItem.rag === 'GREEN'
                                ? 'green-rag-box'
                                : 'gray-rag-box'
                        }`}
                        style={{ marginRight: '1rem' }}
                      >
                        <span className="icon-size-sm">
                          {childItem.rag === 'RED'
                            ? 'R'
                            : childItem.rag === 'YELLOW'
                              ? 'A'
                              : childItem.rag === 'GREEN'
                                ? 'G'
                                : ''}
                        </span>
                      </div>
                      <div
                        style={{
                          color: '#0926D5',
                          textDecoration: 'underline',
                          marginRight: '0rem',
                          width: '5rem',
                        }}
                        onClick={() => {
                          handleViewKPIs(childItem.id)
                        }}
                      >
                        <span style={{ cursor: 'pointer', fontSize: '14px' }}>
                          {t('objReportModal.viewKPIs')}
                        </span>
                      </div>
                      <div
                        style={{
                          color: '#0926D5',
                          textDecoration: 'underline',
                          marginRight: '0',
                          width: '4rem',
                        }}
                        onClick={() => {
                          handleUncomplete(parseInt(childItem.id), index)
                        }}
                      >
                        <span style={{ cursor: 'pointer', fontSize: '14px' }}>
                          {t('objReportModal.reopenObjective')}
                        </span>
                      </div>
                    </div>
                  </div>
                  {expandedItems[index][childIndex] && (
                    <div
                      className="meeting-topic-container"
                      style={{ width: '100%', marginBottom: '1rem' }}
                    >
                      <div className="meeting-topic-table">
                        <div className="obj-meeting-topic-inner-grid">
                          <div className="my-meeting-topic-header" style={{ textAlign: 'left' }}>
                            {t('objReportModal.topic')}
                          </div>
                          <div
                            className="my-meeting-topic-header"
                            style={{ paddingLeft: '0rem', textAlign: 'left' }}
                          >
                            {t('objReportModal.dateCreated')}
                          </div>
                          <div
                            className="my-meeting-topic-header"
                            style={{ paddingLeft: '0rem', textAlign: 'left' }}
                          >
                            {t('objReportModal.owner')}
                          </div>
                          <div
                            className="my-meeting-topic-header"
                            style={{ paddingLeft: '0rem', textAlign: 'left' }}
                          ></div>
                        </div>
                        <React.Fragment>{generateMeetingTopic(childItem)}</React.Fragment>
                      </div>
                    </div>
                  )}
                </div>
              ))}
        </div>
        {expandedItems[index] && item.childObjectives.length === 0 && (
          <div className="meeting-topic-container" style={{ width: '100%', marginBottom: '1rem' }}>
            <div className="meeting-topic-table">
              <div className="obj-meeting-topic-inner-grid">
                <div className="my-meeting-topic-header" style={{ textAlign: 'left' }}>
                  {t('objReportModal.topic')}
                </div>
                <div
                  className="my-meeting-topic-header"
                  style={{ paddingLeft: '0rem', textAlign: 'left' }}
                >
                  {t('objReportModal.dateCreated')}
                </div>
                <div
                  className="my-meeting-topic-header"
                  style={{ paddingLeft: '0rem', textAlign: 'left' }}
                >
                  {t('objReportModal.owner')}
                </div>
                <div
                  className="my-meeting-topic-header"
                  style={{ paddingLeft: '0rem', textAlign: 'left' }}
                ></div>
              </div>
              <React.Fragment>{generateMeetingTopic(item)}</React.Fragment>
            </div>
          </div>
        )}
        {isKPIModalOpen && (
          <KPIModal
            objId={modalObjId}
            handleCancel={() => {
              setIsKPIModalOpen(false)
            }}
            reloadObjectiveReportModal={reload}
          />
        )}
      </div>
    )
  }

  const generateMeetingTopic = (item) => {
    const meetingTopic = item.blockers.concat(item.decisions, item.updates, item.admins)
    if (meetingTopic.length === 0 && item.childObjectives.length === 0) {
      return <div style={{ marginTop: '1rem' }}>{t('objReportModal.noTopics')}</div>
    }
    return meetingTopic
      .sort((a, b) => b.openedTime - a.openedTime)
      .map((topic, index) => {
        return (
          <div className="obj-meeting-topic-inner-grid" key={topic?.umtId}>
            <div
              className="my-meeting-topics-cell"
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            >
              {getActionBox(topic.topicType)}
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => handleClickTopicName(topic?.umtId, topic?.topicType)}
              >
                {topic.description}
              </span>
            </div>
            <div className="my-meeting-topics-cell">
              {convertTimeStamToMMDDYYYY(topic.openedTime)}
            </div>
            <div className="my-meeting-topics-cell">{topic.topicOwnerName}</div>
          </div>
        )
      })
  }

  const handleClickTopicName = (umtId, topicType) => {
    setTopicId(umtId)
    setTopicType(topicType)
    setIsCardModalOpen(true)
  }

  const getActionBox = (topicType) => {
    let type
    let color
    if (topicType === 1) {
      type = 'blocker'
      color = 'rgb(237, 82, 46)'
    }
    if (topicType === 2) {
      type = 'guidance'
      color = 'blue'
    }
    if (topicType === 3) {
      type = 'update'
      color = 'gray'
    }
    if (topicType === 4) {
      type = 'admin'
      color = '#9933FF'
    }
    return (
      <div className="meeting-topic-tagbox" style={{ backgroundColor: color }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '0.8rem',
          }}
        >
          {t(`Common:objReportModal.${type}`)}
        </div>
      </div>
    )
  }

  const toggleExpand = (index, childIndex = null) => {
    if (childIndex !== null) {
      setExpandedItems((prevState) => ({
        ...prevState,
        [index]: {
          ...prevState[index],
          [childIndex]: !prevState[index][childIndex],
        },
      }))
      return
    }
    setExpandedItems((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }))
  }

  const reload = () => {
    setReloadTracker(true)
  }

  const handleViewKPIs = useCallback((id) => {
    setModalObjId(id)
    setIsKPIModalOpen(true)
  }, [])

  const handleUncomplete = async (objId, index) => {
    const result = await Swal.fire({
      title: t('objReportModal.uncomplete'),
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    })
    if (result.isConfirmed) {
      await mutationEditObjectiveUncomplete.mutateAsync(parseInt(objId), {
        onSuccess: (response) => {
          reload()
          setExpandedItems((prevState) => {
            const { [index]: _, ...rest } = prevState
            return rest
          })
        },
        onError: (response) => {},
      })
    }
  }

  return (
    <div className="obj-report-modal">
      <Modal
        isModalOpen={isEmailReminderModalOpen}
        closeModal={() => {
          closeModal()
        }}
        width={'80rem'}
      >
        <div className="modal-inner-content">
          <div className="obj-report-modal-title">{t('objReportModal.title')}</div>
          <div style={{ marginTop: '2rem' }}>
            {showSelectLeader && (
              <div>
                <div style={{ fontWeight: 'bold', fontSize: '20px', marginBottom: '1rem' }}>
                  {t('objReportModal.loadReportFor')}
                </div>
                <Select
                  placeholder={t('objReportModal.selectLeader')}
                  name="selectOwner"
                  value={selectedTeamleader}
                  options={teamleadersList}
                  onChange={updateSelectedTeamLeader}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.eId}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '15rem',
                    }),
                    menu: (provided) => ({
                      ...provided,
                      width: '15rem',
                    }),
                  }}
                />
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '2rem',
              marginBottom: '1rem',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', marginRight: '10rem' }}>
              <div style={{ fontWeight: 'bold', fontSize: '20px', marginBottom: '1rem' }}>From</div>
              <div className="form-field">
                <input
                  type="date"
                  id="from"
                  value={fromDate}
                  onChange={handleFromDateChange}
                  style={{ width: '200px' }}
                />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ fontWeight: 'bold', fontSize: '20px', marginBottom: '1rem' }}>To</div>
              <div className="form-field">
                <input
                  type="date"
                  id="to"
                  value={toDate}
                  onChange={handleToDateChange}
                  style={{ width: '200px' }}
                />
              </div>
            </div>
          </div>
          {filteredObjectives?.length > 0 ? (
            <div
              className="modal-gray-box"
              style={{ width: '100%', borderRadius: '10px', padding: '1rem' }}
            >
              {filteredObjectives.some((obj) => obj.level != 2) && (
                <div
                  style={{
                    fontSize: '1.2rem',
                    display: 'flex',
                    alignItems: 'flex-start',
                    paddingRight: '0.2rem',
                  }}
                >
                  <span
                    style={{
                      color: 'gray',
                      marginRight: '0.5rem',
                      cursor: 'pointer',
                      transform: expandBauObjectives ? 'rotate(0deg)' : 'rotate(180deg)',
                      transition: 'transform 0.5s',
                      marginBottom: '1rem',
                    }}
                    onClick={() => setExpandBauObjectives((prev) => !prev)}
                  >
                    ▲
                  </span>
                  No L2 Objective
                </div>
              )}
              {expandBauObjectives && (
                <div style={{ marginLeft: '1rem' }}>
                  {filteredObjectives &&
                    filteredObjectives
                      ?.sort((a, b) => b.statement - a.statement)
                      .map((item, index) => {
                        if (item.level != 2) return generateObjective(item, index)
                      })}
                </div>
              )}
              {filteredObjectives &&
                filteredObjectives
                  ?.sort((a, b) => b.statement - a.statement)
                  .map((item, index) => {
                    if (item.level == 2) return generateObjective(item, index)
                  })}
            </div>
          ) : (
            <div style={{ marginTop: '1rem' }}>{t('objReportModal.noObjectives')}</div>
          )}
        </div>
      </Modal>
      {isCardModalOpen && topicId !== 0 && topicType !== 0 && (
        <CardModal
          isModalOpen={isCardModalOpen}
          closeModal={() => {
            setIsCardModalOpen(false)
            setTopicId(0)
            setTopicType(0)
          }}
          umtId={topicId}
          topicType={topicType}
        />
      )}
    </div>
  )
}

export default CompletedObjectiveReportModal

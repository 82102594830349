import React from 'react'
import Modal from '../../../../components/Modal'

const EditNoteModal = ({ noteId, noteText, noteType, handleEdit, handleClose }) => {
  const [inputText, setInputText] = React.useState(noteText)

  React.useEffect(() => {
    setInputText(noteText)
  }, [noteText])

  const inputTextChangeHandler = (e) => {
    setInputText(e.target.value)
  }

  return (
    <>
      <Modal
        title={'Edit'}
        closeModal={() => {
          handleClose()
        }}
        width={'40rem'}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <textarea
            rows="5"
            id="inputText"
            name="inputText"
            value={inputText}
            onChange={(e) => {
              inputTextChangeHandler(e)
            }}
            style={{
              resize: 'none',
              borderRadius: '10px',
              backgroundColor: 'white',
              fontSize: '14px',
              width: '25rem',
            }}
          />
          <div
            className="sleek-button sleek-full-blue"
            style={{ alignSelf: 'flex-end' }}
            onClick={() => {
              handleEdit(noteId, inputText, noteType)
              handleClose()
            }}
          >
            SAVE
          </div>
        </div>
      </Modal>
    </>
  )
}
export default EditNoteModal

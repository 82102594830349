import React from 'react'
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import styles from './Charts.module.scss'
import styled from 'styled-components'

const StyledToolTip = styled.div`
  margin: 0;
  border: 1px solid #808080;
  background-color: white;
  padding: 5px;
  opacity: 1;
  max-width: 150px; /* Adjust the max-width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #44546a;
`

const CustomTooltip = ({ active, payload, label, isPercentage }) => {
  if (active && payload && payload.length) {
    return (
      <StyledToolTip>
        {payload.map((entry) => (
          <div key={entry.dataKey} style={{ textAlign: 'center' }}>
            {entry.dataKey.charAt(0).toUpperCase() + entry.dataKey.slice(1)}:{' '}
            {isPercentage ? `${entry.value}%` : `${entry.value}`}
          </div>
        ))}
      </StyledToolTip>
    )
  }

  return null
}

const VerticalComposedChart = ({ data, chartMaxValue, percentage, stacked = false }) => {
  const stackKeys = Object.keys(data[0]).filter((key) => key !== 'name')
  const colors = ['#ED522E', '#0926D5', '#999999']
  const normalBarColor = '#F8BAAB'

  return (
    <ResponsiveContainer width="90%" height={400} className={styles.customStyling}>
      <ComposedChart
        layout="vertical"
        width={700}
        height={400}
        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="transparent" />
        <XAxis type="number" domain={[0, chartMaxValue]} />
        <YAxis dataKey="name" type="category" />
        <Tooltip
          wrapperStyle={{ outline: 'none' }}
          content={<CustomTooltip isPercentage={percentage} />}
        />
        {stacked &&
          stackKeys.map((key, index) => (
            <Bar key={index} dataKey={key} stackId="stack" barSize={25} fill={colors[index]} />
          ))}
        {!stacked && <Bar dataKey="value" barSize={25} fill={normalBarColor} />}
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default VerticalComposedChart

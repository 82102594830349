import React from 'react'
import SubmitUpdateWizard from './components/SubmitUpdateWizard'
import { stepsLabelsGenerator } from '../../hooks/useKPIWizardSteps'
import Modal from '../../../../components/Modal'
import { useTranslation } from 'react-i18next'

const NextPreviousActions = ({
  hideBackButton,
  submitIsLoading,
  isLastStep,
  currentStep,
  handleNext,
  handlePrevious,
  errorMessage,
  setErrorMessage,
  handleSubmit,
  screenPlacement = 'bottom',
  spaceBetween = false,
}) => {
  const { t } = useTranslation(['Common'])

  const stepsLabels = stepsLabelsGenerator()

  const resetErrorMessage = () => {
    setErrorMessage(null)
  }

  return (
    <>
      <div
        className={`buttons-area-container ${
          screenPlacement === 'top' ? 'top-top-top' : 'bottom-bottom-bottom'
        }`}
        style={screenPlacement === 'top' ? { margin: '0' } : {}}
      >
        <div className="error-container">
          {errorMessage ? <div className="validation-error">{errorMessage}</div> : null}
        </div>

        <div
          className="dual-button-container"
          style={{
            justifyContent:
              spaceBetween && currentStep !== stepsLabels.INTRO ? 'space-between' : 'center',
            ...(screenPlacement === 'top' ? { margin: '0' } : {}),
          }}
        >
          {currentStep === stepsLabels.INTRO && screenPlacement === 'bottom' && (
            <button
              onClick={() => {
                handleNext()
              }}
              className="start-button big-btn"
              id="uw-start-btn"
            >
              {t('commonButtons.clickToBegin')}
            </button>
          )}
          {currentStep !== stepsLabels.INTRO && (
            <>
              {!hideBackButton && (
                <button
                  className="dual-back-btn dual-btn"
                  onClick={() => {
                    handlePrevious()
                    resetErrorMessage()
                  }}
                  id="uw-back-btn"
                >
                  <span class="material-symbols-outlined">chevron_left</span>
                </button>
              )}
              {isLastStep && (
                <div style={{ display: 'flex', width: '50%', justifyContent: 'flex-end' }}>
                  <SubmitUpdateWizard
                    submitIsLoading={submitIsLoading}
                    currentStep={currentStep}
                    setErrorMessage={setErrorMessage}
                    handleSubmit={handleSubmit}
                  />
                </div>
              )}
            </>
          )}
          {!isLastStep &&
            currentStep !== stepsLabels.LIST_OF_OBJECTIVES &&
            currentStep !== stepsLabels.INTRO && (
              <button className="dual-next-btn dual-btn" onClick={handleNext} id="uw-next-btn">
                <span className="material-symbols-outlined">chevron_right</span>
              </button>
            )}

          {/* button created only to keep the same layout as the other buttons in the list of objectives page */}
          {currentStep === stepsLabels.LIST_OF_OBJECTIVES && (
            <button
              className="dual-next-btn dual-btn"
              onClick={handleNext}
              id="uw-next-btn"
              style={{ visibility: 'hidden' }}
            >
              <span className="material-symbols-outlined">chevron_right</span>
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export default NextPreviousActions

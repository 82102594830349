import ApplicationLayout from '../ApplicationLayout'
import MeetingReportComponent from './MeetingReportComponent'

const MeetingReport = () => {
  return (
    <ApplicationLayout>
      <MeetingReportComponent />
    </ApplicationLayout>
  )
}
export default MeetingReport

import React from 'react'
import './styles.css'
import plus from '../../../../../../../../assets/plus.svg'
import { useSetupWizardContext } from '../../../../../../context/setupWizardContext'

const DefinedTable = () => {
  const { state, dispatch } = useSetupWizardContext()

  const customMeasures = state.data.customMeasures

  const toggleCheckedHandler = (e, index) => {
    if (e.target.checked) {
      dispatch({
        type: 'ADD_SELECTED_MEASURE',
        selectedMeasure: customMeasures.find((measure) => measure.id === e.target.value),
      })
    } else {
      dispatch({
        type: 'REMOVE_SELECTED_MEASURE',
        measureId: e.target.value,
      })
    }
  }

  return (
    <div className="impact-measure-custom-table-component" style={{ width: '40%' }}>
      <h2 style={{ whiteSpace: 'nowrap' }}>Custom measures</h2>
      <div
        className="table-wrapper shadow"
        style={{
          minHeight: '25vh',
          maxWidth: '70%',
          minWidth: '40%',
          display: 'flex',
          justifyContent: 'flex-start',
          paddingTop: '2rem',
        }}
      >
        <div className="widget-grid-4">
          <div className="widget-grid-title" style={{ padding: '0.5rem', width: '5rem' }}>
            Measure
          </div>
          <div className="widget-grid-title" style={{ padding: '0.5rem', width: '5rem' }}>
            Unit
          </div>
          <div className="widget-grid-title" style={{ padding: '0.5rem', width: '5rem' }}>
            {' '}
            Type
          </div>
          <div className="widget-grid-title" style={{ padding: '0.5rem', width: '5rem' }}>
            Selected
          </div>

          {state.data.customMeasures?.map((measure, index) => (
            <React.Fragment key={`governanceStatus-${index}`}>
              <div
                className="widget-grid-item"
                style={{ padding: '0.5rem', justifyContent: 'flex-start', width: '5rem' }}
              >
                {measure.name}
              </div>
              <div
                className="widget-grid-item"
                style={{ padding: '0.5rem', justifyContent: 'flex-start', width: '5rem' }}
              >
                {measure.unit}
              </div>
              <div
                className="widget-grid-item"
                style={{ padding: '0.5rem', justifyContent: 'flex-start', width: '5rem' }}
              >
                {measure.topLine ? 'Top-line' : 'Bottom-line'}
              </div>
              <div
                className="widget-grid-item"
                style={{ padding: '0.5rem', justifyContent: 'flex-start', width: '5rem' }}
              >
                <input
                  value={measure.id}
                  type="checkbox"
                  onChange={(e) => toggleCheckedHandler(e)}
                  checked={state.data.selectedMeasures.find((el) => measure.id === el.id)}
                />
              </div>
            </React.Fragment>
          ))}
        </div>
        <div
          style={{ margingTop: '2.5rem' }}
          className="add-btn"
          onClick={() => {
            dispatch({ type: 'OPEN_EMPLOYEE_MODAL' })
          }}
        >
          <img className="plus" src={plus} alt="Graphic of plus sign" />
          <span>ADD A CUSTOMER MEASURE</span>
        </div>
      </div>
    </div>
  )
}

export default DefinedTable

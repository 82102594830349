import Modal from '../../../../components/Modal'
import { Formik, Field, Form } from 'formik'
import ModalSaveButton from '../../../../components/ModalSubmitButton'
import { colors } from '../../../../utils/colors'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import {
  manageTopicUW,
  postCloseMeetingTopics,
} from '../../../../api/services/meetingTopic.services'

const ResolveMeetingTopicModal = ({
  isModalOpen,
  closeModal,
  meetingTopic,
  meetingTopicsToClose,
  objId,
  setReloadTracker = null,
  reloadTracker = null,
  triggerLoadingModal = null,
}) => {
  const { t } = useTranslation(['Common'])

  const doReloadIfProvided = () => {
    if (setReloadTracker !== null && setReloadTracker !== null) {
      setReloadTracker((reloadTracker) => !reloadTracker)
    }
  }

  const handleSubmit = async (closedTime) => {
    // `meetingTopicsToClose` actually refers "close to ALL meeting topics".
    if (meetingTopicsToClose === null) {
      let data = {}
      data.action = 'delete'

      let obj = {}
      obj.mtId = meetingTopic.mtId
      obj.closedTime = closedTime
      obj.isOpen = false

      if (meetingTopic.topicType === 1) {
        data.blocker = obj
      } else if (meetingTopic.topicType === 2) {
        data.decision = obj
      } else if (meetingTopic.topicType === 3) {
        data.update = obj
      }

      manageTopicUW(meetingTopic.topicType, data)
        .then((response) => {
          doReloadIfProvided()
        })
        .catch((error) => {
          doReloadIfProvided()
        })
    } else {
      let data = {}
      data.meetingTopicsToClose = meetingTopicsToClose
      data.closedTime = closedTime
      data.objId = parseInt(objId)

      postCloseMeetingTopics(data).then((response) => {
        doReloadIfProvided()
      })
    }
  }

  return (
    <>
      <Modal
        title={t('resolveMeetingTopicsModal.title')}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
      >
        <Formik
          enableReinitialize
          initialValues={{
            closedTime: moment(new Date()).format('YYYY-MM-DD'),
          }}
          validationSchema={Yup.object().shape({
            closedTime: Yup.date().required(t('required')).nullable(),
          })}
          onSubmit={(values, { resetForm }) => {
            if (triggerLoadingModal !== null) {
              triggerLoadingModal()
            }
            handleSubmit(values.closedTime)
            closeModal()
            resetForm()
          }}
        >
          {({ errors, touched, resetForm, setFieldValue }) => (
            <Form className="objective-form">
              <div>
                <div className="form-field" style={{ alignItems: 'center' }}>
                  <Field
                    type="date"
                    id="closedTime"
                    name="closedTime"
                    // in case we decide to bring on a limit
                    // max={yyyymmddNow()}
                  />
                  {errors.closedTime && touched.closedTime && (
                    <div className="validation-error">{errors.closedTime}</div>
                  )}

                  <div className="modal-dual-btn">
                    <button
                      className="dual-btn negative-btn"
                      onClick={() => {
                        resetForm()
                        closeModal()
                      }}
                      type="button"
                      color={colors.grey}
                    >
                      {t('commonButtons.cancel')}
                    </button>
                    <ModalSaveButton />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default ResolveMeetingTopicModal

import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import useLinkageData from '../../../api/query/useLinkageData'
import useTeamCapacityData from '../../../api/query/getTeamCapacity'
import useObjectiveData from '../../../api/query/getObjectiveData'
import useEmpObjMapping from '../../../api/query/useEmpObjMapping'

export default function useCapacityData(tlId = null) {
  const { data: linkageData, isLoading: isLinkageLoading, error: linkageError } = useLinkageData()
  const {
    data: employeeData,
    isLoading: isEmployeeLoading,
    error: employeeError,
  } = useTeamCapacityData(tlId)

  const {
    data: objectiveData,
    isLoading: objectiveDataIsLoading,
    error: objectiveError,
    isFetching: objectiveDataIsFetching,
  } = useObjectiveData(tlId, false)

  const {
    data: empObjMappingData,
    isLoading: isEmpObjMappingLoading,
    error: empObjMappingError,
    isFetching: isEmpObjMappingFetching,
  } = useEmpObjMapping(tlId)

  const isLoading =
    isLinkageLoading || isEmployeeLoading || objectiveDataIsFetching || isEmpObjMappingFetching
  const error = linkageError || employeeError

  const sortedObjectives = (objectiveData?.objectives ?? []).sort((a, b) => {
    if (a.isShared) {
      return b.isGeneralObjective ? -1 : 1
    } else if (a.isGeneralObjective) {
      return 1
    } else if (b.isShared) {
      return -1
    } else if (b.isGeneralObjective) {
      return -1
    } else {
      return 0
    }
  })

  return {
    isLoading: isLoading,
    error: error,
    data: {
      objectives: objectiveData?.objectives ?? [],
      // Where is `linkageData` used?
      linkageData: linkageData?.linkageData ?? [],
      employees: employeeData?.employees ?? [],
      objIcMappings: empObjMappingData?.objIcMappings ?? [],
    },
  }
}

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getCeoLeaders = async () => {
  const { data } = await axios.get(`${BASE_URL}/all_ceo_leaders`)
  return data
}

export default function useCeoData() {
  return useQuery(['ceo-leaders'], () => getCeoLeaders(), {
    refetchOnWindowFocus: false,
  })
}

import { React, Component } from 'react'
import AddEmployeeModal from '../AddEmployeeModal'
import InvidualContributorTable from '../IndividualContributorTable'

import plus from '../../../../../../assets/plus.svg'
import next from '../../../../../../assets/next-white.svg'

import './styles.css'

class TeamLeaderCarousel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      nowIndex: 0,
      showAddEmployeeModal: false,
    }
  }

  render() {
    const { teamLeaderArray, individualContributorArray } = this.props

    return (
      <div className="area-leader-carousel-component">
        <table>
          <tbody>
            <tr>
              <div
                style={{ width: '5rem', height: '5rem' }}
                onClick={() => {
                  if (this.state.nowIndex > 0) {
                    this.setState({
                      nowIndex: this.state.nowIndex - 1,
                    })
                  }
                }}
                className={`nextprev-button previous-area-leader-btn${
                  this.state.nowIndex === 0 ? 'disabled-carousel-btn hidden' : ''
                }`}
              >
                <span class="material-symbols-outlined">arrow_back_ios</span>
              </div>

              <div className="table-wrapper shadow">
                {teamLeaderArray.map((teamLeader, index) => {
                  if (this.state.nowIndex === index) {
                    return (
                      <div className="subtitle-teamleader" key={`arealeader-${index}`}>
                        <h4>
                          {index + 1} of {teamLeaderArray.length}
                        </h4>

                        <h3>
                          Area Leader:{' '}
                          <span className="subtitle-arealeader">{teamLeader.managerName}</span>
                        </h3>

                        <h3>
                          Who are the team members that report to Team Leader:{' '}
                          <span className="subtitle-arealeader">{teamLeader.name}?</span>
                        </h3>

                        <h3>
                          Title: <span className="subtitle-arealeader">{teamLeader.title}</span>
                        </h3>

                        <AddEmployeeModal
                          teamLeaderEmail={teamLeader.email}
                          teamLeaderName={teamLeader.name}
                          onClose={() => this.setState({ showAddEmployeeModal: false })}
                          show={this.state.showAddEmployeeModal}
                        />

                        <InvidualContributorTable
                          individualContributorArray={individualContributorArray}
                          teamLeaderEmail={teamLeader.email}
                        />
                      </div>
                    )
                  } else {
                    return []
                  }
                })}
                <button
                  onClick={() => {
                    this.setState({ showAddEmployeeModal: true })
                  }}
                  className="add-btn teamleader-btn"
                >
                  <img className="plus" src={plus} alt="Graphic of plus sign" />
                  ADD TEAM MEMBERS
                </button>
              </div>
              <div
                onClick={() => {
                  if (this.state.nowIndex < this.props.teamLeaderArray.length - 1) {
                    this.setState({
                      nowIndex: this.state.nowIndex + 1,
                    })
                  }
                }}
                className={`nextprev-button next-area-leader-btn${
                  this.state.nowIndex >= this.props.teamLeaderArray.length - 1
                    ? 'disabled-carousel-btn hidden'
                    : ''
                }`}
              >
                <span class="material-symbols-outlined">arrow_forward_ios</span>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}
export default TeamLeaderCarousel

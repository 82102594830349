import Swal from 'sweetalert2'
import { postHttpRequest } from '../api/query/dynamicAPI'
import { getUpdateWizStatus } from '../utils/auth'
import { API_ERROR_MESSAGE } from '../api/constant/route'
import Toast from './toast'

export const checkForPendingUW = async (t) => {
  try {
    let hasPendingUW = getUpdateWizStatus()
    // We show the popup only if there is a pending UW
    if (hasPendingUW == 0 || hasPendingUW == 2) {
      const swalConfig = {
        text: t('Common:modalMessages.pendingUwNotification'),
        showCancelButton: true,
        confirmButtonText: t('Common:commonButtons.doItNow'),
        confirmButtonColor: '#0926D5',
        cancelButtonText: t('Common:commonButtons.doItLater'),
      }
      // Show already completed button if the user has already started the UW
      if (hasPendingUW == 2) {
        swalConfig.showDenyButton = true
        swalConfig.denyButtonColor = 'green'
        swalConfig.denyButtonText = t('Common:commonButtons.alreadyCompleted')
      }

      const result = await Swal.fire(swalConfig)

      if (result.isConfirmed) {
        window.location.href = '/Update'
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        return true
      } else {
        await handleSubmitUpdateWiz()
        return true
      }
    } else {
      return true
    }
  } catch (err) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
    return true
  }
}

const handleSubmitUpdateWiz = async () => {
  let responseObj = {
    objectives: [],
    employees: [],
    employeesObjAlloc: [],
    blockers: [],
    milestones: [],
    closeUwAction: true,
  }

  try {
    const response = await postHttpRequest('/update_wizard_submit', responseObj)
    if (response) {
      localStorage.setItem('tfuwtl', '1')
      return true
    } else {
      Toast.fire({
        icon: 'error',
        title: API_ERROR_MESSAGE,
      })
      return false
    }
  } catch (error) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
    return false
  }
}

import './styles.css'

const Intro = () => {
  return (
    <div className="setup-leader-intro">
      <div className="intro">
        <div className="intro-title">
          <h2>Let’s setup Decision+ for your company</h2>
          <p style={{ textAlign: 'left' }}>
            We will take you step-by-step through the setup progress, asking you for information in
            the three areas below. The wizard automatically saves everything. If you need to
            complete it later, you can close it and come back.
          </p>
        </div>

        <div
          className="intro-container"
          style={{
            display: 'flex',
            flexDirection: 'row',
            columnGap: '1rem',
            marginTop: '2rem',
            justifyContent: 'center',
          }}
        >
          <div className="intro-summary-point shadow">
            <div className="intro-summary-title">
              <b>The Organizational Structure</b>
            </div>

            <p className="intro-summary-details">
              We will ask you to enter the relevant leaders in your company from C-level down to
              team members, but only for areas we are deploying Decision+.
            </p>
          </div>
          <div className="intro-summary-point shadow">
            <div className="intro-summary-title">
              <b>Governance Frequency</b>
            </div>

            <p className="intro-summary-details">
              We will ask you how often you will run Decision+ and when the first cycle will start.
            </p>
          </div>
          <div className="intro-summary-point shadow">
            <div className="intro-summary-title">
              <b>Objectives</b>
            </div>

            <p className="intro-summary-details">
              We will ask you for the enterprise level and area/business unit level objectives that
              all Team Leaders will be mapping their team objectives to.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Intro

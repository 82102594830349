import React from 'react'
import Modal from '../Modal'
import { useTranslation } from 'react-i18next'
import EmployeeMeiBoxes from '../DashboardMei'

const EmployeeMEIBoxesModal = ({
  closeModal,
  metrics,
  setIsEmployeeMeiBoxesModalOpen,
  setData,
}) => {
  const { t } = useTranslation(['Common'])

  return (
    <>
      <Modal
        closeModal={() => {
          closeModal()
        }}
        width={'60rem'}
      >
        {metrics ? (
          <EmployeeMeiBoxes
            metrics={metrics}
            setIsModalOpen={setIsEmployeeMeiBoxesModalOpen}
            setData={setData}
          />
        ) : (
          <p style={{ marginLeft: 'auto', marginRight: 'auto' }}>{t('chartsCard.notChartData')}</p>
        )}
      </Modal>
    </>
  )
}
export default EmployeeMEIBoxesModal

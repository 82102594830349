import React, { useState } from 'react'

import './customNode.scss'
import './styles.scss'

function ActionDecisionNode({ data }) {
  // Default hideDropdown to false
  const getBackgroundColor = (itemType) => {
    const colors = {
      1: '#EF967A',
      2: '#c6d4ed',
    }
    return colors[itemType] || 'transparent'
  }
  return (
    <div className="nodrag text-updater-node">
      <div className={`custom-box-node topic-box`} style={{ height: '150px' }}>
        <ul className="node-text-list">
          <li className="single-node" style={{ marginBottom: '0.2rem' }}>
            <div
              className="topic-blocker"
              style={{ backgroundColor: getBackgroundColor(data.itemType) }}
            >
              {data.itemType === 1 ? 'Decision' : 'Action'}
            </div>
            <p style={{ overflow: 'auto', height: '6rem' }}>
              {data.itemType === 1 ? data.commentText : data.milestoneDescription}
            </p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ActionDecisionNode

import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { govAuthValidation } from '../api/services/employee.services'
import Loading from '../components/Loading'

// This is a route guard that checks if the user is authorized to view the governance page.
// If the user is not authorized, they will be redirected to the login page.
// Only users with the 'admin' or 'enterprise' role can access the governance page.
const GovernanceRouteGuard = ({ WrappedComponent }) => {
  const navigate = useNavigate()
  const [isAuthorized, setIsAuthorized] = useState(false)

  useEffect(() => {
    async function fetchData() {
      try {
        const hasValidRole = await govAuthValidation()

        // Check if the user has a valid role
        if (!hasValidRole.success) {
          // Redirect to login page
          const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)
          const userEmail = localStorage.getItem('tfmu') || ''
          window.location.href = `${BASE_URL}/f/logout?reason=no_gov_access&user=${userEmail}`
        } else {
          setIsAuthorized(true)
        }
      } catch (error) {
        console.error('Error fetching employee roles', error)
      }
    }

    fetchData()
  }, [navigate])

  // Render the wrapped component if authenticated
  if (isAuthorized) {
    return <WrappedComponent />
  }

  return (
    <>
      <Loading />
    </>
  )
}

export default GovernanceRouteGuard

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { setupWizardSubRoutes, routes } from '../../../utils/routes'

const setupWizardSteps = [
  setupWizardSubRoutes.intro,
  setupWizardSubRoutes.enterpriseLeaders,
  setupWizardSubRoutes.areaLeaders,
  setupWizardSubRoutes.teamLeaders,
  setupWizardSubRoutes.caseMapping,
  setupWizardSubRoutes.individualContributor,
  setupWizardSubRoutes.governanceConfig,
  setupWizardSubRoutes.topLevelObjectives,
  setupWizardSubRoutes.areaLevelObjectives,
  setupWizardSubRoutes.impactMeasures,
]

export default function useSetupWizardSteps(existingStep) {
  const navigate = useNavigate()
  const [currentStepIndex, setCurrentStepIndex] = React.useState(parseInt(existingStep))

  React.useEffect(() => {
    if (currentStepIndex >= 0) {
      navigate(`/${routes.setupwizard}/${setupWizardSteps[currentStepIndex]}`)
    }
  }, [currentStepIndex, existingStep, navigate])

  const handleNext = () => {
    if (currentStepIndex <= setupWizardSteps.length - 1) {
      const nextStep = currentStepIndex + 1
      setCurrentStepIndex(nextStep)
      localStorage.setItem('sw-step', nextStep.toString())
    }
  }

  const handlePrevious = () => {
    if (Boolean(currentStepIndex)) {
      const prevStep = currentStepIndex - 1
      setCurrentStepIndex(prevStep)
      localStorage.setItem('sw-step', prevStep.toString())
    }
  }

  return {
    currentStepIndex,
    steps: setupWizardSteps,
    handleNext,
    handlePrevious,
  }
}

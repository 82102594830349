import React, { useState, useEffect, useRef } from 'react'
import Toast from '../../../common/toast'
import InPageLoader from '../../../components/InPageLoader'
import { useLocation, useOutletContext } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getHttpRequest } from '../../../api/query/dynamicAPI'
import { dateFilter } from '../../../utils/time'
import { shortenName } from '../../../utils/general'
import ChangesReportDropdownMenu from '../changesReportDropdownMenu'
import CardModal from '../../../components/CardModal'
import useEditKanbanBoardChangeActivityStatus from '../../../api/mutation/useEditKanbanBoardChangeActivityStatus'
import {
  getCompletedMeetingTopics,
  useUpdateMeetingLogs,
} from '../../../api/services/meetingTopic.services'
import '../styles.scss'
import TopicsReportDropdownMenu from '../../../components/MeetingTopicDropDownMenu'
import { convertTimeStamToMMDDYYYY } from '../../../utils/time'
import Swal from 'sweetalert2'

const ChangesReportMeetingTopics = () => {
  const { t } = useTranslation(['ChangesReport', 'Common', 'MeetingHub'])
  const { setIsLoading, isLoading, loadAgain, reload } = useOutletContext()
  const [resolvedMeetingTopic, setResolvedMeetingTopic] = React.useState([])
  const [filteredMeetingTopics, setFilteredMeetingTopics] = React.useState(null)
  const [selectedDays, setSelectedDays] = useState(7)
  const [isTopicModalOpen, setIsTopicModalOpen] = useState(false)
  const [topicId, setTopicId] = useState(0)
  const [topicType, setTopicType] = useState(0)
  const mutationUpdateMeetingLogs = useUpdateMeetingLogs()

  React.useEffect(() => {
    getMeetingTopics()
  }, [selectedDays])

  const filterMeetingTopics = (days, topicsList = null) => {
    let originialTopics = []
    if (topicsList) {
      originialTopics = topicsList
    } else {
      originialTopics = resolvedMeetingTopic
    }
    let filteredTopics = originialTopics
    if (days) {
      const today = new Date()
      const pastDate = new Date(today)
      pastDate.setDate(today.getDate() - days)
      let filterDate = pastDate.getTime() / 1000
      filteredTopics = originialTopics.filter((topic) => {
        return topic.closedTime >= filterDate
      })
    }

    setFilteredMeetingTopics(filteredTopics)
  }

  const getMeetingTopics = async () => {
    setIsLoading(true)
    const result = await getCompletedMeetingTopics([], true)
    if (result) {
      const combinedObjectives = result?.adminTopicObjectives.concat(
        result?.blockedObjectives,
        result?.decisionObjectives,
        result?.updateObjectives,
      )
      setResolvedMeetingTopic(combinedObjectives)
      filterMeetingTopics(selectedDays, combinedObjectives)
      setIsLoading(false)
    }
  }

  const handleFilter = (days) => {
    setSelectedDays(days)
  }

  const resolve = async (status, umtId) => {
    const result = await Swal.fire({
      title:
        status === true
          ? t('Common:modalMessages.areYouSureUnresolveMeetingTopic')
          : t('Common:modalMessages.areYouSureResolveCompleteMeetingTopic'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })

    if (result.isConfirmed) {
      const formData = {}
      formData.status = !status
      formData.umtId = umtId

      await mutationUpdateMeetingLogs.mutateAsync(formData, {
        onSuccess: () => {
          Toast.fire({
            icon: 'success',
            title: t('Common:modalMessages.meetingTopicUpdatedSuccessfully'),
          })
          let tempFilteredMeetingTopics = [...filteredMeetingTopics]
          for (let i = 0; i < tempFilteredMeetingTopics.length; i++) {
            if (tempFilteredMeetingTopics[i].umtId === umtId) {
              tempFilteredMeetingTopics[i].isCompleted = !status
            }
          }
          setFilteredMeetingTopics(tempFilteredMeetingTopics)
        },
        onError: () => {
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
          })
        },
      })
    }
  }

  const generateMeetingTopic = (topics) => {
    const sortedTopics = [...topics].sort((a, b) => {
      return new Date(b.closedTime) - new Date(a.closedTime)
    })
    return sortedTopics.map((topic, index) => {
      return (
        <div className="changes-report-meeting-topic-inner-grid" key={topic?.umtId}>
          <div
            className="my-meeting-topics-cell"
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            {getActionBox(topic.topicType)}
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => onClikcViewDetails(topic.umtId, topic.topicType)}
            >
              {topic.actionNeeded}
            </span>
          </div>
          <div className="my-meeting-topics-cell">{topic.statement}</div>
          <div className="my-meeting-topics-cell">
            {convertTimeStamToMMDDYYYY(topic.closedTime)}
          </div>
          <div className="my-meeting-topics-cell">{topic.topicOwnerName}</div>
          <div className="my-meeting-topics-cell">{topic.closedByName}</div>
          <div className="my-meeting-topics-cell">
            {!topic.isCompleted ? (
              <button
                type="button"
                className="btn-card resolve-btn"
                onClick={(e) => resolve(topic.isCompleted, topic.umtId)}
                id="meetingHubTutorial12"
              >
                {t('MeetingHub:meetingTopics.resolve')}
              </button>
            ) : (
              <button
                type="button"
                className="btn-card resolve-btn"
                onClick={(e) => resolve(topic.isCompleted, topic.umtId)}
              >
                {t('MeetingHub:meetingTopics.unresolve')}
              </button>
            )}
          </div>
          <div className="my-meeting-topics-cell">
            <div style={{ alignSelf: 'end' }}>
              <TopicsReportDropdownMenu
                onClikcViewDetails={() => onClikcViewDetails(topic.umtId, topic.topicType)}
                fromChangesReport={true}
              />
            </div>
          </div>
        </div>
      )
    })
  }

  const getActionBox = (topicType) => {
    let type
    let color
    if (topicType === 1) {
      type = 'blocker'
      color = 'rgb(237, 82, 46)'
    }
    if (topicType === 2) {
      type = 'guidance'
      color = 'blue'
    }
    if (topicType === 3) {
      type = 'update'
      color = 'gray'
    }
    if (topicType === 4) {
      type = 'admin'
      color = '#9933FF'
    }
    return (
      <div className="meeting-topic-tagbox" style={{ backgroundColor: color }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '0.8rem',
          }}
        >
          {t(`MeetingHub:meetingTopicReport.${type}`)}
        </div>
      </div>
    )
  }

  const onClikcViewDetails = (umtId, topicType) => {
    setIsTopicModalOpen(true)
    setTopicId(umtId)
    setTopicType(topicType)
  }

  return (
    <>
      {isTopicModalOpen && (
        <CardModal
          isModalOpen={isTopicModalOpen}
          closeModal={() => {
            setIsTopicModalOpen(false)
            setTopicId(0)
            setTopicType(0)
          }}
          umtId={topicId}
          topicType={topicType}
        />
      )}
      {isLoading && <InPageLoader />}
      {!isLoading && (
        <div className="modal-inner-content">
          <div className="button-container" style={{ paddingLeft: '1rem' }}>
            <div
              className="button"
              onClick={() => handleFilter(7)}
              style={{ backgroundColor: selectedDays == 7 ? '#4472c4' : '#a5a5a5' }}
            >
              {t('MeetingHub:meetingTopicReport.past7Days')}
            </div>
            <div
              className="button"
              onClick={() => handleFilter(14)}
              style={{ backgroundColor: selectedDays == 14 ? '#4472c4' : '#a5a5a5' }}
            >
              {t('MeetingHub:meetingTopicReport.past14Days')}
            </div>
            <div
              className="button"
              onClick={() => handleFilter(30)}
              style={{ backgroundColor: selectedDays == 30 ? '#4472c4' : '#a5a5a5' }}
            >
              {t('MeetingHub:meetingTopicReport.past30Days')}
            </div>
            {/* <div
             className="button"
             onClick={() => handleFilter(60)}
             style={{ backgroundColor: selectedDays == 60 ? '#4472c4' : '#a5a5a5' }}
           >
             {t('MeetingHub:meetingTopicReport.past60Days')}
           </div>
           <div
             className="button"
             onClick={() => handleFilter(90)}
             style={{ backgroundColor: selectedDays == 90 ? '#4472c4' : '#a5a5a5' }}
           >
             {t('MeetingHub:meetingTopicReport.past90Days')}
           </div> */}
          </div>
          {filteredMeetingTopics?.length > 0 && (
            <div
              className="modal-gray-box"
              style={{ borderRadius: '10px', padding: '1rem', width: 'fit-content' }}
            >
              <div style={{ marginBottom: '1rem' }}>
                <div className="meeting-topic-container" style={{ marginBottom: '1rem' }}>
                  <div className="changes-report-meeting-topic-table">
                    <div className="changes-report-meeting-topic-inner-grid">
                      <div className="my-meeting-topic-header" style={{ textAlign: 'left' }}>
                        {t('MeetingHub:meetingTopicReport.topic')}
                      </div>
                      <div
                        className="my-meeting-topic-header"
                        style={{ paddingLeft: '0rem', textAlign: 'left' }}
                      >
                        {t('MeetingHub:meetingTopicReport.objective')}
                      </div>
                      <div
                        className="my-meeting-topic-header"
                        style={{ paddingLeft: '0rem', textAlign: 'left' }}
                      >
                        {t('MeetingHub:meetingTopicReport.dateClosed')}
                      </div>
                      <div
                        className="my-meeting-topic-header"
                        style={{ paddingLeft: '0rem', textAlign: 'left' }}
                      >
                        {t('MeetingHub:meetingTopicReport.topicOwner')}
                      </div>
                      <div
                        className="my-meeting-topic-header"
                        style={{ paddingLeft: '0rem', textAlign: 'left' }}
                      >
                        {t('MeetingHub:meetingTopicReport.closedBy')}
                      </div>
                      <div
                        className="my-meeting-topic-header"
                        style={{ paddingLeft: '0rem', textAlign: 'left' }}
                      >
                        {t('MeetingHub:meetingTopicReport.status')}
                      </div>
                    </div>
                    <React.Fragment>{generateMeetingTopic(filteredMeetingTopics)}</React.Fragment>
                  </div>
                </div>
              </div>
            </div>
          )}
          {filteredMeetingTopics?.length === 0 && (
            <div style={{ marginTop: '1rem' }}>{t('MeetingHub:meetingTopicReport.noTopics')}</div>
          )}
        </div>
      )}
    </>
  )
}
export default ChangesReportMeetingTopics

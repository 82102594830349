import { useQuery, useQueries } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getGovernanceAlStatusData = async () => {
  const { data } = await axios.get(`${BASE_URL}/gov_al_status_data`)
  return data
}

export default function useGovernanceAlStatusData() {
  return useQuery(['gov_al_status_cycle'], () => getGovernanceAlStatusData(), {
    refetchOnWindowFocus: false,
  })
}

import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useMicVAD } from '@ricky0123/vad-react'
import ChatBox from '../../pages/CompanySetupAiAgentSocket/Chatbox'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import { getSetUpWizardHistory } from '../../api/services/objectives.services'
import { hasRole } from '../../utils/auth'
import SpeakingAnimationBars from '../SpeakingAnimations/SpeakingAnimationBars'
import SpeakingAnimationCircle from '../SpeakingAnimations/SpeakingAnimationCircle'
import Toast from '../../common/toast'
import troyCircle from '../../assets/troy_circle.png'

const useAudioAgentPlugin = ({
  socket,
  speechEndCallback,
  onFinalJsonCallback,
  socketStartedCallback,
  speechStartCallback,
  reload,
  saveObjectivesCallback = () => Promise.resolve(),
  use_case,
  token,
  setDegradedCanvas = () => {},
  undoCallback = () => {},
  description = '',
}) => {
  // const socket = useSocket()
  const { t } = useTranslation(['Common', 'ObjectiveMap'])

  const mediaRecorderRef = React.useRef(null)
  const audioContextRef = React.useRef(null)
  const [isSocketStarted, setIsSocketStarted] = useState(false)
  const bufferQueueRef = React.useRef([])

  const audioPlayerRef = React.useRef(null)
  const mediaSourceRef = React.useRef(null)
  const sourceBufferRef = React.useRef(null)
  const [isPlaying, setIsPlaying] = React.useState(false)
  const [userState, setUserState] = React.useState(null)
  const isAudioPausedRef = React.useRef(false)
  const [isAudioPaused, setIsAudioPaused] = React.useState(false)
  const [justWaitingForUser, setJustWaitingForUser] = useState(null)
  // const [loadingObjectiveMap, setLoadingObjectiveMap] = useState(false)
  //TODO Swetha: Ideally we should not use this prop on the websocket and reuse the languageSettings.
  const [showEng, setShowEng] = useState(false)
  const [messageList, setMessageList] = useState([{ assistant_message: '' }])
  const messageListLengthRef = useRef(messageList.length)
  const [isLoading, setIsLoading] = useState(false)
  const [isRestoreMode, setIsRestoreMode] = useState(false)
  const [restoreSocketStarted, setRestoreSocketStarted] = useState(false)

  const [audioQueue, setAudioQueue] = useState([])
  const audioRef = React.useRef(null)

  const languageSettings = JSON.parse(localStorage.getItem('tfmc')).languageSettings

  // this is to handle the case when you click pause without saying anything, then se;f.start_ts in the backend
  // will be null because onSpeechStart is not fired. we need currentStartTs (do processing if it's null) to handle this case
  const currentStartTs = React.useRef(null)

  const [showRecommendations, setShowRecommendations] = useState(true)
  const [disablePause, setDisablePause] = useState(false)

  const [isDownloadedWasm, setIsDownloadedWasm] = useState(false)
  const [hideChatBox, setHideChatBox] = useState(false)

  const [activeDot, setActiveDot] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveDot((prevDot) => (prevDot + 1) % 3)
    }, 300)
    return () => clearInterval(interval)
  }, [])
  const dotStyle = {
    // fontSize: '24px',
    fontWeight: 'bold',
    // color: '#3B82F6', // a shade of blue
    opacity: 0,
    transition: 'opacity 0.2s',
    //margin: '0 2px',
  }
  const activeDotStyle = {
    ...dotStyle,
    opacity: 1,
  }

  useEffect(() => {
    // Path to the WASM file
    // const wasmFile = 'https://tfml-demo-2-kjjltjpqna-uc.a.run.app/static/js/ort-wasm-simd-threaded.wasm';
    // const wasmFile = 'http://localhost:3000/static/js/ort-wasm-simd-threaded.wasm';
    // const wasmFile = '/static/js/ort-wasm-simd-threaded.wasm';
    const wasmFile =
      'https://cdn.jsdelivr.net/npm/onnxruntime-web@1.19.0/dist/ort-wasm-simd-threaded.wasm' // Function to preload the WASM file
    const preloadWasm = async () => {
      try {
        const response = await fetch(wasmFile)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        // Wait for the download to complete
        await response.arrayBuffer()

        // When the download completes, update the state
        setIsDownloadedWasm(true)
      } catch (error) {
        console.error('Error loading WASM file:', error)
        // Optionally, handle error state here
      }
    }

    // Call the function to preload the WASM file
    preloadWasm()
  }, [])

  React.useEffect(() => {
    console.log('isDownloadedWasm', isDownloadedWasm)
  }, [isDownloadedWasm])

  const vad = useMicVAD({
    ortConfig: (ort) => {
      ort.env.wasm.wasmPaths = 'https://cdn.jsdelivr.net/npm/onnxruntime-web@1.19.0/dist/'
    },
    startOnLoad: false,
    onSpeechStart: (audio) => {
      console.log('**** Speech start detected ***')

      setDisablePause(true)
      setJustWaitingForUser(false)
      console.log('User started talking')
      setUserState('speaking')
      if (audioRef.current && audioRef.current.currentTime) {
        audioRef.current.pause()
        audioRef.current.currentTime = 0
        setIsPlaying(false)
        if (audioQueue.length > 0) {
          setAudioQueue([])
        }
      }

      if (
        audioContextRef.current &&
        audioRef.current &&
        audioRef.current.currentTime &&
        !isAudioPaused
      ) {
        audioRef.current.pause()
        audioRef.current.currentTime = 0
        setIsPlaying(false)
        if (audioQueue.length > 0) {
          setAudioQueue([])
        }
        // bufferQueueRef.current = []
        // if (sourceBufferRef.current && sourceBufferRef.current.updating) {
        //   sourceBufferRef.current.addEventListener('updateend', resetMediaSource, { once: true })
        // } else {
        //   resetMediaSource()
        // }
      }

      currentStartTs.current = Date.now()
      // if (!isAudioPausedRef.current) {
      socket.emit('vad_interrupt', {
        event: 'speech_start',
        isRecording: true,
        timestamp: Date.now(),
        first_request: false,
      })
      console.log('speech_start_time', Date.now())
      // }
    },
    onSpeechEnd: (audio) => {
      if (!isAudioPausedRef.current) {
        // Reinstated for whisper V3.
        setJustWaitingForUser(false)
        setDisablePause(false)
        console.log('User stopped talking')
        setUserState('waiting')
        if (!isAudioPaused) {
          setIsLoading(true)
        }
        speechEndCallback()
        socket.emit('vad_interrupt', {
          event: 'speech_end',
          isRecording: true,
          timestamp: Date.now(),
          first_request: false,
          currentStartTs: currentStartTs.current,
        })
        // }
        currentStartTs.current = null
      }
    },
    redemptionFrames: 9, // 25 = 2 seconds
    positiveSpeechThreshold: 0.85,
    negativeSpeechThreshold: 0.6,
  })

  // Add this function to handle playback errors
  function handlePlaybackError(event) {
    console.error('Playback error:', event)
    // Implement recovery logic here (e.g., reinitialize MediaSource, retry playback)
  }

  useEffect(() => {
    console.log('isAudioPaused', isAudioPaused)
    isAudioPausedRef.current = isAudioPaused
  }, [isAudioPaused])

  const initializeAudioMedia = async () => {
    console.log('zzzzz')

    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)()
    }

    // set up mic input
    const audioStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
      echoCancellation: true,
      noiseSuppression: true,
    })
    const audioSourceNode = audioContextRef.current.createMediaStreamSource(audioStream)

    // setAudioStreamForVisual(audioStream)

    const destinationNode = audioContextRef.current.createMediaStreamDestination()
    audioSourceNode.connect(destinationNode)
    mediaRecorderRef.current = new MediaRecorder(destinationNode.stream)

    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        let audiodata = {
          data: event.data,
          // millisec
          ts: Date.now(),
          isPaused: isAudioPausedRef.current,
        }
        // console.log('audio_chunk_input', audiodata)
        socket.emit('audio_chunk_input', audiodata)
      }
    }

    mediaRecorderRef.current.start(250)
    console.log('yyyyy')
  }

  const onTtsComplete = (data) => {
    reload(true)
    console.log('tts_complete')
    // socket.emit('started_recording', {'started_recording': true})
    console.log(data.text)

    setMessageList((prev) => {
      let copyMessageList = prev.map((m) => ({ ...m }))
      let lastChatBubble = copyMessageList[copyMessageList.length - 1]
      lastChatBubble.assistant_message += ' ' + data.text
      return copyMessageList
    })
    // setMessageList(prev => [...prev, { assistant_message: data.text }])
  }

  const onAudioChunkOutput = async (data) => {
    setUserState('listening')

    // setIsLoading(false)
    // const arrayBuffer = new Uint8Array(data.chunk).buffer
    // bufferQueueRef.current.push(arrayBuffer)
    // if (sourceBufferRef.current && !sourceBufferRef.current.updating) {
    //   appendNextChunk()
    // }

    const base64Audio = data.chunk
    const audioBlob = base64ToBlob(base64Audio)
    const audioUrl = URL.createObjectURL(audioBlob)

    setAudioQueue((prevQueue) => [...prevQueue, audioUrl])
  }

  const handleSignalFromBE = (data) => {
    console.log('handleSignalFromBE', data)

    if (data.pause) {
      setIsAudioPaused(true)
    } else if (data.unpause) {
      setIsAudioPaused(false)
    }

    if (data.revert) {
      resetOneRoundFromExternal()
    }
  }

  const handleSetMessageList = (data) => {
    console.log('setMessageList', data)
    setMessageList(data?.message_list)
  }

  const onFinalJson = (data) => {
    console.log('onFinalJson')
    setJustWaitingForUser(true)
    reload(false)
    console.log('data.json_str =', data.json_str)
    let res = JSON.parse(data.json_str)
    if (res.systemMessage) {
      let parsedMessage = JSON.parse(res.systemMessage)

      let systemMessage = parsedMessage[0].content[0].text
      let cleanSystemMessage = systemMessage
        .replace(/\\n/g, '')
        .replace(/\\t/g, '')
        .replace(/\\\"/g, '"')

      console.log('System Message', cleanSystemMessage)

      let userMessage = parsedMessage[1].content[0].text
      let cleanUserMessage = userMessage
        .replace(/\\n/g, '')
        .replace(/\\t/g, '')
        .replace(/\\\"/g, '"')
      console.log('User Message', cleanUserMessage)
    }
    console.log('GPT Response', res.gptResponse.replaceAll('\\n', '\n'))
    let response = JSON.parse(data.json_str)
    if (response) {
      onFinalJsonCallback(response)
    }
    // if (response?.finishObjectiveHierachy) {
    //   setIsAudioPaused(true)
    // }
  }

  const reStoreCallback = (data) => {
    console.log('reStoreCallback', data)
    setMessageList(data?.message_list)
  }

  // function addSourceBuffer() {
  //   sourceBufferRef.current = mediaSourceRef.current.addSourceBuffer('audio/mpeg')
  //   sourceBufferRef.current.addEventListener('updateend', appendNextChunk)
  // }

  // function initializeMediaSource() {
  //   const audioPlayerComponent = audioPlayerRef.current
  //   mediaSourceRef.current = new MediaSource()
  //   const url = URL.createObjectURL(mediaSourceRef.current)
  //   audioPlayerComponent.src = url

  //   mediaSourceRef.current.addEventListener('sourceopen', addSourceBuffer)
  // }

  // function resetMediaSource() {
  //   if (mediaSourceRef.current.readyState === 'open') {
  //     mediaSourceRef.current.endOfStream()
  //   }
  //   // if (sourceBufferRef.current) {
  //   //     sourceBufferRef.current.removeEventListener('updateend', appendNextChunk);
  //   //     sourceBufferRef.current = null;
  //   // }
  //   initializeMediaSource()
  // }

  // function appendNextChunk() {
  //   if (
  //     mediaSourceRef.current &&
  //     bufferQueueRef.current.length > 0 &&
  //     !sourceBufferRef.current.updating &&
  //     mediaSourceRef.current.readyState === 'open'
  //   ) {
  //     const chunk = bufferQueueRef.current.shift()
  //     sourceBufferRef.current.appendBuffer(chunk)
  //   }
  //   attemptAutoPlay()
  // }

  // function attemptAutoPlay() {
  //   const audioPlayerComponent = audioPlayerRef.current
  //   if (audioPlayerComponent.paused && audioPlayerComponent.readyState >= 3) {
  //     // HAVE_FUTURE_DATA
  //     audioPlayerComponent
  //       .play()
  //       .then(() => {
  //         // setIsPlaying(true)
  //       })
  //       .catch((e) => console.error('Autoplay failed exception:', e))
  //   }
  // }

  const onReceiveTranscript = (data) => {
    // add a dummy assistant message so it can be appended in onttscComplete
    let newMessages = [{ user_message: data.text }, { assistant_message: '' }]
    console.log('newMessages', newMessages)
    setMessageList((prev) => {
      // in case user transcript is shown but user decides to speak again (before assistant audio is played)
      if (prev.length > 1 && prev[prev.length - 1].assistant_message === '') {
        prev[prev.length - 2].user_message = data.text
        return prev
      }
      return [...prev, ...newMessages]
    })
    // setMessageList(prev => [...prev, ...[{ user_message: data.text }, { assistant_message: '' }]])
    // setMessageList(prev => [...prev, { user_message: data.text }])
  }

  const onTranscriptCompleted = (data) => {}

  const onQuestionText = (data) => {
    // setIsLoading(false)
    // setMessageList(prev => [...prev, { assistant_message: data.text }])
  }

  const onGptPromptAndMessageResponse = (data) => {
    try {
      console.log('onGptPromptAndMessageResponse: ', JSON.parse(data.json_str))
    } catch (error) {
      console.log(error)
    }
  }

  const logout = () => {
    const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)
    let userEmail = localStorage.getItem('tfmu') || 'none'
    window.location.href = `${BASE_URL}/f/logout?reason=invalid_session&user=${userEmail}}`
  }

  const unhandledError = () => {
    Swal.fire({
      title:
        'Sorry, there was an error with the connection. Please click "OK" to reload the page. Don\'t worry, your work has been saved.',
      showCancelButton: false,
      confirmButtonText: t('Common:commonButtons.tryAgain'),
    }).then((result) => {
      window.location.reload()
    })
  }

  const cleanupSocket = () => {
    console.log('cleanupSocket')
    vad.pause()
    socket.off('audio_chunk_output', onAudioChunkOutput)
    socket.off('tts_complete', onTtsComplete)
    socket.off('final_json', onFinalJson)
    socket.off('user_transcript', onReceiveTranscript)
    socket.off('transcript_completed', onTranscriptCompleted)
    socket.off('question_text', onQuestionText)
    socket.off('gpt_prompt_and_message_response', onGptPromptAndMessageResponse)
    socket.off('picasso_objectives_saved', saveObjectivesCallback)
    socket.off('frontend_signal', handleSignalFromBE)
    socket.off('set_message_list', handleSetMessageList)
    socket.disconnect()
    if (mediaSourceRef.current && mediaSourceRef.current.readyState === 'open') {
      mediaSourceRef.current.endOfStream()
      mediaSourceRef.current = null
    }
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop()
      if (audioContextRef.current) {
        audioContextRef.current.close()
        audioContextRef.current = null
      }
    }
  }

  useEffect(() => {
    if (!isSocketStarted) return
    ;(async () => {
      await initializeAudioMedia()
      // initializeMediaSource()
      vad.start()
      socket.connect()
      let data = {
        language: showEng ? 'en' : 'es',
        token: token,
      }
      socketStartedCallback()
      await new Promise((resolve, reject) => {
        socket.emit('enable_socket_connection', data, (response) => {
          if (response?.error) {
            return reject(response.error)
          }
          resolve(response)
        })
      })
      socket.on('connection_status', (data) => {
        if (data.status !== 'OK') {
          console.log('cleanupSocket: B')
          cleanupSocket()
          logout()
        }
      })

      socket.on('session_status', (data) => {
        if (data.status !== 'SESSION_VALID') {
          console.log('cleanupSocket: C')
          cleanupSocket()
          logout()
        }
      })

      socket.on('audio_chunk_output', onAudioChunkOutput)
      socket.on('tts_complete', onTtsComplete)
      socket.on('final_json', onFinalJson)
      socket.on('user_transcript', onReceiveTranscript)
      socket.on('question_text', onQuestionText)
      socket.on('gpt_prompt_and_message_response', onGptPromptAndMessageResponse)
      socket.on('picasso_objectives_saved', saveObjectivesCallback)
      socket.on('frontend_signal', handleSignalFromBE)
      socket.on('set_message_list', handleSetMessageList)
      socket.on('disconnect', () => {
        console.log('disconnected A: ', socket.id) // undefined
        unhandledError()
      })

      updateSharedState({
        showRecommendations: showRecommendations,
      })

      setIsLoading(true)
      setUserState('waiting')
      socket.emit('vad_interrupt', {
        first_request: true,
        isRecording: false,
        isPaused: false,
      })

      return () => {
        console.log('cleanupSocket: A')
        cleanupSocket()
      }
    })()
  }, [isSocketStarted])

  useEffect(() => {
    console.log('isAudioPaused', isAudioPaused)
    isAudioPausedRef.current = isAudioPaused

    if (socket) {
      console.log('>>>>> sending to socket isAudioPaused', isAudioPaused)
      socket.emit('update_shared_state', { __is_paused__: isAudioPaused })
    }
  }, [isAudioPaused])

  useEffect(() => {
    if (isDownloadedWasm) {
      getConversationHistory()
    }
    let language = languageSettings == 'es' ? 'es' : 'en'
    setShowEng(language === 'en')
  }, [isDownloadedWasm])

  useEffect(() => {
    const initialize = async () => {
      await initializeAudioMedia()
      // initializeMediaSource()
      vad.start()
      socket.connect()
      let data = {
        language: showEng ? 'en' : 'es',
        token: token,
      }
      await new Promise((resolve, reject) => {
        socket.emit('enable_socket_connection', data, (response) => {
          if (response?.error) {
            return reject(response.error)
          }
          resolve(response)
        })
      })

      socketStartedCallback()

      // onFinalJsonCallback(finalData)

      // updateSharedState({
      //   message_id: messageId,
      // })
      // after reset we always have pause as false and restore_mode as true
      // updateSharedState({
      //   restore_mode: true,
      //   __is_paused__: false,
      //   message_id: messageId,
      // })
      socket.on('audio_chunk_output', onAudioChunkOutput)
      socket.on('tts_complete', onTtsComplete)
      socket.on('final_json', onFinalJson)
      socket.on('user_transcript', onReceiveTranscript)
      socket.on('question_text', onQuestionText)
      socket.on('gpt_prompt_and_message_response', onGptPromptAndMessageResponse)
      socket.on('picasso_objectives_saved', saveObjectivesCallback)
      socket.on('restore_shared_cache', reStoreCallback)
      socket.on('frontend_signal', handleSignalFromBE)
      socket.on('set_message_list', handleSetMessageList)
      socket.on('disconnect', () => {
        console.log('disconnected B: ', socket.id) // undefined
        unhandledError()
      })
      // setIsLoading(true)
      await new Promise((resolve, reject) => {
        socket.emit('restore_shared_cache', {}, (response) => {
          if (response?.error) {
            return reject(response.error)
          }
          resolve(response)
        })
      })
      socket.emit('return_audio', {})
      setUserState('listening')
      socket.emit('vad_interrupt', {
        first_request: false,
        isRecording: false,
        isPaused: false,
      })
    }
    if (isRestoreMode) {
      setRestoreSocketStarted(true)
      initialize()
      setIsRestoreMode(false)
    }
  }, [isRestoreMode])

  const getConversationHistory = async () => {
    let res = await getSetUpWizardHistory(use_case)
    if (res?.has_record) {
      Swal.fire({
        title: t('Common:companyAiSetupAgent.continueConversation'),
        showCancelButton: true,
        confirmButtonText: t('Common:commonButtons.yes'),
        cancelButtonText: t('Common:commonButtons.no'),
      }).then((result) => {
        if (result.isConfirmed) {
          setIsRestoreMode(true)
        }
      })
    }
  }

  const updateSharedState = (data) => {
    console.log('update_shared_state', data)
    socket.emit('update_shared_state', data)
  }

  const resetToRound = (roundNum) => {
    const data = { round: roundNum }
    console.log('resetToRound', data)
    socket.emit('reset_to_round', data)
    undoCallback()
  }

  const resetOneRoundFromExternal = () => {
    socket.emit('reset_to_last_round', {})
  }

  const checkboxRec = (
    <>
      {!isSocketStarted && (
        <div
          style={{
            display: 'flex',
            // flexDirection:'column',
          }}
        >
          {showEng ? 'Show Recommendations' : 'Mostrar recomendaciones'}
          <input
            type="checkbox"
            checked={showRecommendations}
            onChange={() => {
              setShowRecommendations((prev) => !prev)
            }}
          ></input>
        </div>
      )}
    </>
  )

  const chatBox = (
    <>
      <div
        className="chatbot-header-clean"
        style={{
          textAlign: 'center',
          alignItems: 'center',
          fontSize: '1rem',
          color: 'rgb(68, 114, 196)',
          height: '3rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '1rem',
          paddingLeft: '1rem',
          backgroundColor: '#e9e8e9',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '1rem',
              color: 'black',
              fontSize: '1rem',
              paddingTop: '0.5rem',
              fontWeight: 'bold',
            }}
          >
            {/* color: '#AAC2F1' */}
            <span>
              {/* {t('companyAiSetupAgent.yourAiConsultant')}  */}
              I'm Odin, your AI consultant
            </span>
            {/* <span className="material-symbols-outlined">chat_bubble</span> */}
          </div>
          {/* {hideChatBox === false && (
            <span
              style={{ cursor: 'pointer' }}
              className="material-symbols-outlined"
              onClick={() => {
                setHideChatBox(true)
              }}
            >
              remove
            </span>
          )} */}
          {hideChatBox === true && (
            <span
              style={{ cursor: 'pointer' }}
              className="material-symbols-outlined"
              onClick={() => {
                setHideChatBox(false)
              }}
            >
              select_window_2
            </span>
          )}
        </div>

        {/* <div style={{display:"flex", flexDirection:"row", gap:"1rem", alignItems: 'center'}}>
        <span>{showEng ? t('aiMemo.eng') : t('aiMemo.esp')}</span>
        <div
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          onClick={() => {
            setShowEng((prev) => !prev)
          }}
        >
          {!showEng && (
            <span
              style={{ fontSize: '35px', color: 'rgb(176, 173, 173)', cursor: 'pointer' }}
              className="material-symbols-outlined"
            >
              toggle_off
            </span>
          )}

          {showEng && (
            <span
              style={{ fontSize: '35px', color: 'rgb(176, 173, 173)', cursor: 'pointer' }}
              className="material-symbols-outlined"
            >
              toggle_on
            </span>
          )}
        </div>
        </div> */}
      </div>
      {hideChatBox === false && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderLeft: '1px solid lightgrey',
            borderRight: '1px solid lightgrey',
            borderBottom: '1px solid lightgrey',
            height: '75vh',
          }}
        >
          {!(isSocketStarted || restoreSocketStarted) && isDownloadedWasm && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '90%',
              }}
            >
              <div
                onClick={() => initializeAudioAgent()}
                className="sleek-button"
                style={{
                  display: 'flex',
                  width: showEng ? '8rem' : '14rem',
                  fontSize: '1rem',
                  height: '2rem',
                  marginTop: '15rem',
                  marginBottom: '2rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  color: '#fff',
                  backgroundColor: '#4472c4',
                  border: '1px solid #fff',
                }}
              >
                <span class="material-symbols-outlined">not_started</span>
                {t('companyAiSetupAgent.clickToStart')}
              </div>
            </div>
          )}
          {isDownloadedWasm === false && <>Initializing...</>}
          {(isSocketStarted || restoreSocketStarted) && isDownloadedWasm && (
            <ChatBox messageList={messageList} isLoading={isLoading} undoFn={resetToRound} />
          )}

          {(isSocketStarted || restoreSocketStarted) && isDownloadedWasm && (
            <div
              style={{
                display: 'flex',
                height: '4rem',
                backgroundColor: '#e9e8e9',
                width: '100%',
                boxSizing: 'border-box',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '1rem',
                  // paddingTop: '0',
                  width: '100%',
                  boxSizing: 'border-box',
                  justifyItems: 'center',
                  alignItems: 'center',
                  gap: '0.5rem',
                  borderRadius: '10px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    gap: '1rem',
                  }}
                >
                  {(isSocketStarted || restoreSocketStarted) && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <div
                        onClick={() => {
                          setIsAudioPaused((p) => !p)
                        }}
                        className={`${isAudioPaused ? 'play-circle' : 'pause-circle'}`}
                        style={{
                          display: 'flex',
                          width: 'fit-content',
                          height: '1.5rem',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        {isAudioPaused ? (
                          <span style={{ fontSize: '2rem' }} class="material-symbols-outlined">
                            play_circle
                          </span>
                        ) : (
                          <span style={{ fontSize: '2rem' }} class="material-symbols-outlined">
                            pause_circle
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '1.5rem',
                    }}
                  >
                    <span style={{ height: '1.5rem', width: 'fit-conetnt' }}>
                      {userState === 'speaking' && !isAudioPaused && (
                        <>
                          <span> {t('Common:companyAiSetupAgent.listening')} &nbsp;</span>
                        </>
                      )}

                      {justWaitingForUser && (
                        <>
                          <span> {t('Common:companyAiSetupAgent.speaking')} &nbsp;</span>
                        </>
                      )}
                    </span>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    {userState === 'speaking' && !isAudioPaused && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        <SpeakingAnimationCircle size={20} />
                      </div>
                    )}

                    {justWaitingForUser && <SpeakingAnimationBars />}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )

  const initializeAudioAgent = () => {
    setIsSocketStarted(true)
  }

  // const audioPlayer = <audio ref={audioPlayerRef} style={{ display: 'none' }}></audio>

  useEffect(() => {
    if (audioQueue.length > 0 && !isPlaying) {
      console.log('Playing next audio')
      playNextAudio()
    }
    if (audioQueue.length === 0) {
      setJustWaitingForUser(false)
    }
  }, [audioQueue, isPlaying])

  const base64ToBlob = (base64) => {
    const byteCharacters = atob(base64)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    return new Blob([byteArray], { type: 'audio/mpeg' })
  }

  const playNextAudio = () => {
    if (audioQueue.length > 0) {
      setIsPlaying(true)
      const nextAudioUrl = audioQueue[0]
      audioRef.current.src = nextAudioUrl
      audioRef.current.play()
    }
  }

  const handleAudioEnded = () => {
    URL.revokeObjectURL(audioQueue[0])
    setAudioQueue((prevQueue) => prevQueue.slice(1))
    setIsPlaying(false)
  }

  const audioPlayer = (
    <>
      <div id="vadDisplay" />
      <audio ref={audioRef} style={{ display: 'block' }} onEnded={handleAudioEnded}></audio>
    </>
  )

  // // Create setInterval to display `vad.listening`
  //  useEffect(() => {
  //   const interval = setInterval(() => {
  //     console.log('vad.listening =', vad.listening)
  //     if (document.getElementById('vadDisplay')) {
  //       document.getElementById('vadDisplay').innerHTML = vad.listening ? 'Listening' : 'Not Listening'
  //     }
  //   }, 100)
  //   return () => clearInterval(interval)
  // }
  // , [])

  return {
    updateSharedState,
    chatBox,
    audioPlayer,
    isAudioPaused: isAudioPaused,
    checkboxRec,
    setIsAudioPaused,
  }
}

const SinusoidalWave = ({ width = 300, height = 100, speed = 1, color = 'blue' }) => {
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setOffset((prevOffset) => (prevOffset + speed) % width)
    }, 16) // ~60 fps for smooth animation

    return () => clearInterval(intervalId)
  }, [speed, width])

  const wavelength = width / 6
  const baseAmplitude = height / 4

  const points = Array.from({ length: width }, (_, x) => {
    const centerX = width / 2
    const distanceFromCenter = Math.abs(x - centerX)
    const amplitudeModifier = 1 - Math.pow(distanceFromCenter / (width / 2), 2)

    const amplitude = baseAmplitude * amplitudeModifier
    const y = Math.sin((x + offset) * ((2 * Math.PI) / wavelength)) * amplitude + height / 2

    return `${x},${y}`
  }).join(' ')

  // Generate a lighter version of the color for the gradient
  const lighterColor = (color) => {
    const hex = color.replace('#', '')
    const r = parseInt(hex.substr(0, 2), 16)
    const g = parseInt(hex.substr(2, 2), 16)
    const b = parseInt(hex.substr(4, 2), 16)
    // console.log(`rgb(${Math.min(255, r + 100)}, ${Math.min(255, g + 100)}, ${Math.min(255, b + 100)})`)
    // return `rgb(${Math.min(255, r + 100)}, ${Math.min(255, g + 100)}, ${Math.min(255, b + 100)})`;
    return `rgb(100,200,255)`
  }

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <defs>
        <linearGradient id="waveGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor={lighterColor(color)} />
          <stop offset="50%" stopColor={color} />
          <stop offset="100%" stopColor={lighterColor(color)} />
        </linearGradient>
      </defs>
      <polyline points={points} fill="none" stroke="url(#waveGradient)" strokeWidth="3" />
    </svg>
  )
}

const SmoothConcentricCircles = ({ size = 100, color = 'blue', pulseSpeed = 1.5 }) => {
  const circles = [0, 1, 2] // We'll create 3 circles
  const animationDuration = 1 / pulseSpeed

  return (
    <svg width={size} height={size} viewBox="0 0 100 100">
      <style>
        {`
          @keyframes pulse {
            0% {
              r: 10;
              opacity: 0;
            }
            10% {
              opacity: 0.8;
            }
            100% {
              r: 50;
              opacity: 0;
            }
          }
        `}
      </style>
      {circles.map((_, index) => (
        <circle
          key={index}
          cx="50"
          cy="50"
          fill="none"
          stroke={color}
          strokeWidth="2"
          style={{
            animation: `pulse ${animationDuration}s cubic-bezier(0.4, 0, 0.6, 1) infinite`,
            animationDelay: `${(index * animationDuration) / circles.length}s`,
          }}
        />
      ))}
    </svg>
  )
}

export default useAudioAgentPlugin

import React, { useState, useCallback } from 'react'

// we have only ONE single lock for now
const useLock = () => {
  // const [isLocked, setIsLocked] = useState(false)
  const isLocked = React.useRef(false)
  // const [currentSource, setCurrentSource] = useState(null)
  const currentSource = React.useRef(null)

  // Generate a random source ID
  const generateSourceId = useCallback(() => {
    return `source_${Math.random().toString(36).substr(2, 9)}`
  }, [])

  // React.useEffect(() => {
  //     console.log('lock e dchanged to ', isLocked)
  // }, [isLocked])

  // React.useEffect(() => {
  //     console.log('lock currentSource to ', currentSource)
  // }, [currentSource])

  const requestQueueAccess = (forceTransferLock = false) => {
    if (isLocked.current && !forceTransferLock) {
      console.log('lock used by: ', currentSource)
      console.log('requestQueueAccess is locked lock')
      return null
    }

    const newSourceId = generateSourceId()

    return newSourceId
  }

  const lockAccess = (sourceId) => {
    console.log('new source id for lock', sourceId)
    // setCurrentSource(newSourceId);
    currentSource.current = sourceId
    console.log('lock requestQueueAccess  currentSource', currentSource)
    // setIsLocked(true);
    isLocked.current = true
  }

  const releaseLock = (sourceId, name = 'eventsource') => {
    console.log('release lock', sourceId, name)
    if (sourceId !== currentSource.current) {
      console.log('sourceId !== currentSource, keep lock', sourceId, currentSource)
      return false
    }
    console.log('sourceId === currentSource, release lock', sourceId, currentSource)
    // setCurrentSource(null);
    currentSource.current = null
    console.log('lock releaselock  currentSource', currentSource)
    // setIsLocked(false);
    isLocked.current = false
    console.log('release lock')
    return true
  }

  return {
    requestQueueAccess,
    lockAccess,
    releaseLock,
  }
}

export default useLock

import React, { useState, useEffect } from 'react'

const SpeakingAnimationBars = () => {
  const [activeBar, setActiveBar] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveBar((prev) => (prev + 1) % 6)
    }, 150)

    return () => clearInterval(interval)
  }, [])

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    height: '80px',
    width: '240px',
    justifyContent: 'center',
  }

  const barStyle = (index) => ({
    width: '18px',
    backgroundColor: '#90a0fa',
    transition: 'all 100ms ease-in-out',
    height: index === activeBar ? '60%' : '40%',
    marginLeft: index > 0 ? '12px' : '0',
    position: 'relative',
    borderRadius: '6px', // Added this line to round the main container
    overflow: 'hidden', // Added this to ensure inner elements don't overflow
  })

  const innerBarStyle = {
    position: 'absolute',
    left: '0',
    right: '0',
    height: '50%',
    backgroundColor: 'inherit',
  }

  return (
    <div style={containerStyle}>
      {[0, 1, 2, 3, 4, 5].map((index) => (
        <div key={index} style={barStyle(index)}>
          <div
            style={{
              ...innerBarStyle,
              top: '0',
              borderTopLeftRadius: '6px',
              borderTopRightRadius: '6px',
            }}
          />
          <div
            style={{
              ...innerBarStyle,
              bottom: '0',
              borderBottomLeftRadius: '6px',
              borderBottomRightRadius: '6px',
            }}
          />
        </div>
      ))}
    </div>
  )
}

export default SpeakingAnimationBars

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { excoExerciseSubRoutes, routes } from '../../../utils/routes'
import {
  getConsolidatedConfigSettings,
  isAreaLeader,
  isTeamLeader,
  getCompanyConfigSettings,
} from '../../../utils/auth'

function updateStepsGenerator() {
  return [
    excoExerciseSubRoutes.intro,
    excoExerciseSubRoutes.question1,
    excoExerciseSubRoutes.question2,
    excoExerciseSubRoutes.question3,
    excoExerciseSubRoutes.results,
  ]
}

export function stepsLabelsGenerator() {
  return {
    INTRO: 0,
    QUESTION1: 1,
    QUESTION2: 2,
    QUESTION3: 3,
    RESULTS: 4,
  }
}

export default function useExcoExerciseWizardSteps(existingStep) {
  const excoExerciseSteps = updateStepsGenerator()
  const navigate = useNavigate()

  const [currentStepIndex, setCurrentStepIndex] = React.useState(parseInt(existingStep))
  const steps = stepsLabelsGenerator()

  const [wArray, setwArray] = React.useState([
    true, //INTRO 0
    true, //QUESTION1 1
    true, //QUESTION2 2
    true, //QUESTION3 3
    true, //RESULTS 4
  ])

  React.useEffect(() => {
    if (currentStepIndex >= 0) {
      navigate(`/${routes.excoExercise}/${excoExerciseSteps[currentStepIndex]}`)
    }
  }, [currentStepIndex, existingStep, navigate])

  const handleNext = () => {
    let tempWArray = [...wArray]
    if (currentStepIndex <= excoExerciseSteps.length) {
      let stepIncrease = 1
      while (true) {
        if (currentStepIndex + stepIncrease < excoExerciseSteps.length) {
          if (tempWArray[currentStepIndex + stepIncrease] === true) {
            const nextStep = currentStepIndex + stepIncrease
            setCurrentStepIndex(nextStep)
            break
          }
          stepIncrease++
        }
        if (currentStepIndex + stepIncrease === excoExerciseSteps.length) {
          break
        }
      }
      setwArray([...tempWArray])
    }
  }

  const handlePrevious = () => {
    let tempWArray = [...wArray]
    if (currentStepIndex <= excoExerciseSteps.length) {
      let stepDecrease = 1
      while (true) {
        if (tempWArray[currentStepIndex - stepDecrease] === true) {
          const prevStep = currentStepIndex - stepDecrease
          setCurrentStepIndex(prevStep)
          // saveUpdateWizardState(state.data, prevStep)
          break
        }

        stepDecrease++
        if (currentStepIndex - stepDecrease === -1) {
          break
        }
      }
      setwArray([...tempWArray])
    }
  }

  const isLastStep = () => {
    let lastIndex = -1

    for (let i = 0; i < wArray.length; i++) {
      if (wArray[i] === true) {
        lastIndex = i
      }
    }

    if (currentStepIndex === lastIndex) {
      return true
    } else {
      return false
    }
  }

  return {
    currentStepIndex,
    steps: excoExerciseSteps,
    handleNext,
    handlePrevious,
    wArray,
    isLastStep,
  }
}

import React from 'react'
import Loading from '../../components/Loading'
import ApplicationLayout from '../../components/ApplicationLayout'
import { defaultState, CapacityProvider } from './context/capacityContext'
import CapacityWizard from './components/CapacityWizard'
import useCapacityData from './hooks/useCapacityData'
import { useLocation } from 'react-router-dom'

const CapacityPage = () => {
  const location = useLocation()
  const teamLeaderId =
    location.state && location?.state?.teamLeaderId ? parseInt(location?.state?.teamLeaderId) : null
  const { data, isLoading, error } = useCapacityData(teamLeaderId)
  const [runOnce, setRunOnce] = React.useState(0)

  // concat existing data / api data

  const initialState = {
    ...defaultState,
    data,
    locationState: location.state,
  }
  const isF2wTl = parseInt(localStorage.getItem('mType')) === 4 ? true : false

  React.useEffect(() => {
    // Return if still loading, to avoid complicated unnecessary checks below.
    if (isLoading) {
      return
    }

    // This check is insufficient because it relies on initialState.data.objectives but doesn't
    // check if it has loaded.
    // TODO(cecilia): delete it.
    if (
      initialState?.data?.objIcMappings?.length > 0 &&
      initialState?.data?.employees.length &&
      runOnce === 0
    ) {
      for (let { eId, objId, allocation, eName } of initialState.data.objIcMappings) {
        let objectives = initialState?.data?.objectives ?? []
        for (let obj of objectives) {
          if (parseInt(obj?.id) === objId) {
            const teamEntry = {
              eId: eId,
              value: allocation,
              name: eName,
              canDelete: initialState?.data?.employees?.some((emp) => emp.eId === eId),
            }

            if (obj.team) {
              obj.team.push(teamEntry)
            } else {
              obj.team = [teamEntry]
            }
          }
        }
      }
      setRunOnce(1)
    }
  }, [initialState])

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    // TODO: Error Component
    return 'Error'
  }

  return (
    <ApplicationLayout>
      <CapacityProvider initialState={initialState}>
        <div
          className="wizard-container"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CapacityWizard
            existingStep={0}
            isF2wTl={isF2wTl}
            teamLeaderId={location?.state?.teamLeaderId}
          />
        </div>
      </CapacityProvider>
    </ApplicationLayout>
  )
}

export default CapacityPage

import React from 'react'
import { LineChart, Line, XAxis, YAxis, Tooltip, LabelList, ResponsiveContainer } from 'recharts'
import { useTranslation } from 'react-i18next'
import styles from '../PieCharCustomizedLabel/Charts.module.scss'

const CustomizedLabel = ({ x, y, stroke, value }) => {
  return (
    <text x={x} y={y} dy={-5} fill={stroke} fontSize={8} textAnchor="middle">
      {value}%
    </text>
  )
}

const LineChartCustomizedLabel = ({ data }) => {
  const { t } = useTranslation(['Common'])
  return (
    <ResponsiveContainer width="50%" height="100%" className={styles.customStyling}>
      <LineChart
        width={500}
        height={500}
        data={data}
        margin={{
          top: 60,
          right: 30,
          // left: 20
        }}
      >
        <text x="50%" y="8%" fill="black" textAnchor="middle" dominantBaseline="central">
          <tspan fontSize="14" fontWeight="bold">
            {t('kpi.kpiLineChartTitle')}
          </tspan>
        </text>
        <XAxis dataKey="date" height={60} tick={{ fontSize: '12px' }} />
        <YAxis domain={[0, 100]} tickFormatter={(val) => `${val}%`} tick={{ fontSize: '12px' }} />
        <Tooltip />
        <Line type="monotone" dataKey="value" stroke="#8884d8">
          <LabelList content={<CustomizedLabel />} />
        </Line>
      </LineChart>
    </ResponsiveContainer>
  )
}

export default LineChartCustomizedLabel

import { useState, useRef, React, useEffect } from 'react'
import './styles.css'

const AudioRecorder = ({ handleSubmit, isNewDesign = false, start = true }) => {
  const [recording, setRecording] = useState(false)
  const mediaRecorder = useRef(null)
  const [seconds, setSeconds] = useState(0)

  const startRecording = () => {
    setSeconds(0)
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const options = {
        audioBitsPerSecond: 64000,
        mimeType: 'audio/webm',
      }
      mediaRecorder.current = new MediaRecorder(stream, options)
      mediaRecorder.current.start()
      setRecording(true)
    })
  }

  const stopRecording = async () => {
    mediaRecorder.current.stop()
    setRecording(false)
    mediaRecorder.current.ondataavailable = (e) => {
      const audioBlob = new Blob([e.data], { type: 'audio/webm' })
      handleSubmit(audioBlob)
    }
    setSeconds(0)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds < 60) {
        setSeconds(seconds + 1)
      } else {
        clearInterval(interval)
        stopRecording()
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  })

  useEffect(() => {
    if (start) {
      startRecording()
    }
  }, [])

  const formatTime = (secs) => {
    let hours = Math.floor(secs / 3600)
    let minutes = Math.floor(secs / 60) % 60
    let seconds = secs % 60
    return [hours, minutes, seconds]
      .map((v) => ('' + v).padStart(2, '0'))
      .filter((v, i) => v !== '00' || i > 0)
      .join(':')
  }

  return (
    <>
      {isNewDesign ? (
        <>
          {!recording ? (
            <div
              class="sleek-button sleek-blue"
              style={{ cursor: 'pointer', height: '1.5rem' }}
              onClick={() => {
                startRecording()
              }}
            >
              <span class="material-symbols-outlined icon-size" title="Start Recording">
                mic
              </span>
            </div>
          ) : (
            <div class="sleek-button sleek-blue recording">
              <span class="audio-recorder-timer">{formatTime(seconds)}</span>
              <span class="audio-recorder-status  ">
                <span class="audio-recorder-status-dot"></span>Recording
              </span>
              <span
                class="material-symbols-outlined icon-size"
                onClick={stopRecording}
                title="Stop Recording"
              >
                stop_circle
              </span>
            </div>
          )}
        </>
      ) : (
        <>
          {!recording ? (
            <div class="audio-recorder">
              <span
                class="material-symbols-outlined icon-size"
                onClick={startRecording}
                title="Start Recording"
              >
                mic
              </span>
            </div>
          ) : (
            <div class="audio-recorder recording">
              <span class="audio-recorder-timer">{formatTime(seconds)}</span>
              <span class="audio-recorder-status  ">
                <span class="audio-recorder-status-dot"></span>Recording
              </span>
              <span
                class="material-symbols-outlined icon-size"
                onClick={stopRecording}
                title="Stop Recording"
              >
                stop_circle
              </span>
            </div>
          )}
        </>
      )}
    </>
  )
}
export default AudioRecorder

let colors = [
  'rgb(0, 82, 204)',
  'rgb(0, 163, 191)',
  'rgb(0, 135, 90)',
  'rgb(255, 153, 31)',
  'rgb(222, 53, 11)',
  'rgb(82, 67, 170)',
  'rgb(23, 43, 77)',
  'rgb(128,128,0)',
  'rgb(191, 21, 133)',
]

export function generateCommentBubblesColor(name) {
  let words = name.trim().split(' ')

  let s = 0
  for (let w of words) {
    s += w.charCodeAt(0)
  }

  let color = colors[s % colors.length]
  return color
}

import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const editObjectiveComplete = async (objData) => {
  const { data } = await axios.post(`${BASE_URL}/objectives/mark_as_complete_v2`, objData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return data
}

export default function useEditObjectiveComplete_v2() {
  const client = useQueryClient()

  return useMutation((obj) => editObjectiveComplete(obj), {
    onSuccess: () => {
      void client.invalidateQueries('objectives')
    },
  })
}

import React from 'react'
import './styles.css'
import { useNavigate, useOutletContext } from 'react-router-dom'
import SummaryOfObjectives from '../../../../components/SummaryOfObjectives'
import { routes } from '../../../../utils/routes'

const MilestonesOverview = () => {
  const { eIdsOfSelectedTls } = useOutletContext()
  const navigate = useNavigate()

  React.useEffect(() => {
    // Redirect to SummaryOfObjectives as we don't have SoO in MH anymore
    navigate(`/${routes.summaryOfObjectives}`)
  }, [navigate])

  return (
    <>
      <SummaryOfObjectives
        eIdsOfSelectedTls={eIdsOfSelectedTls}
        isMultiMeetingView={true}
      ></SummaryOfObjectives>
    </>
  )
}

export default MilestonesOverview

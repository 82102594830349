import { useState, useEffect } from 'react'
import Modal from '../Modal'
import { useTranslation } from 'react-i18next'
import './style.css'
import { addMultipleMeetingTopics } from '../../api/services/meetingTopic.services'
import InPageLoader from '../InPageLoader'
import TextArea from 'rc-textarea'

const ReviewGeneratedTopicsModal = ({
  isModalOpen,
  closeModal,
  generatedTopics,
  objId,
  handleTryAgain,
  setSelectedTopics,
}) => {
  const [topics, setTopics] = useState(generatedTopics)
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation(['Common'])

  const handleTextChange = (index, newText) => {
    const updatedTopics = [...topics]
    updatedTopics[index].text = newText
    setTopics(updatedTopics)
  }

  const handleTopicTypeChange = (index, newTopicType) => {
    const updatedTopics = [...topics]
    updatedTopics[index].topic = newTopicType
    setTopics(updatedTopics)
  }

  const handleIncludeChange = (index, isChecked) => {
    const updatedTopics = [...topics]
    updatedTopics[index].include = isChecked
    setTopics(updatedTopics)
  }

  const handleSave = () => {
    // Filter topics based on include flag but don't include 'include' property
    const filteredTopics = topics
      .filter((topic) => topic.include)
      .map(({ text, topic }) => ({ description: text, topicType: topic.toLowerCase(), objId }))
    if (objId) {
      setIsLoading(true)
      addMultipleMeetingTopics(filteredTopics)
        .then((response) => {
          const data = response
          setIsLoading(false)
          if (data) {
            closeModal()
          }
        })
        .catch((error) => {})
    } else {
      setSelectedTopics(filteredTopics)
      closeModal()
    }
  }

  return (
    <div className="review-generated-topics-modal">
      <Modal closeModal={closeModal} width={'50rem'}>
        {isLoading && <InPageLoader inComponent={true} />}
        {!isLoading && (
          <div className="review-generated-topics-list" style={{ marginTop: '1.3rem' }}>
            <div className="review-generated-topics-list-row">
              <div
                className="review-generated-topics-list-header"
                style={{ paddingLeft: '0.5rem' }}
              >
                {t('reviewGeneratedTopicsModal.topic')}
              </div>
              <div className="review-generated-topics-list-header">
                {t('reviewGeneratedTopicsModal.category')}
              </div>
              <div className="review-generated-topics-list-header">
                {t('reviewGeneratedTopicsModal.include')}
              </div>
            </div>
            {topics.map((topic, index) => (
              <div key={index} className="review-generated-topics-list-row">
                <div className="review-generated-topics-list-item">
                  <TextArea
                    rows={3}
                    type="text"
                    onChange={(e) => {
                      handleTextChange(index, e.target.value)
                    }}
                    value={topic.text}
                  />
                </div>
                <div className="review-generated-topics-list-item">
                  <select
                    value={topic.topic}
                    onChange={(e) => handleTopicTypeChange(index, e.target.value)}
                    className={`action-box ${topic?.topic?.toLowerCase()}`}
                  >
                    <option value="Blocker">Blocker</option>
                    <option value="Guidance">Guidance</option>
                    <option value="Update">Update</option>
                  </select>
                </div>
                <div className="review-generated-topics-list-item">
                  <input
                    type="checkbox"
                    checked={topic.include}
                    onChange={(e) => handleIncludeChange(index, e.target.checked)}
                  />
                </div>
                <div class="row-border" style={{ marginTop: '0.5rem' }}></div>
              </div>
            ))}
            <div className="review-generated-topics-buttons-container">
              <div
                className="sleek-button sleek-blue"
                style={{ cursor: 'pointer', height: '1.5rem' }}
                onClick={() => {
                  handleTryAgain()
                }}
              >
                {t('commonButtons.tryAgain')}
              </div>
              <div
                className="sleek-button sleek-blue"
                style={{ cursor: 'pointer', height: '1.5rem' }}
                onClick={() => {
                  handleSave()
                }}
              >
                {t('commonButtons.saveLowerCase')}
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}

export default ReviewGeneratedTopicsModal

import React, { useState, useEffect } from 'react'
import { useUpdateContext } from '../../context/updateContext'
import TeamCapacityAllocationTable from '../../../../components/AllocSubWizard/TeamCapacityAllocationTable'
import { useOutletContext } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const UpdateTeamCapacityAllocation = () => {
  const { t } = useTranslation(['UpdateWizard'])

  return (
    <>
      <div className="wizard-step">
        <h2 className="wizard-step__title">{t('teamCapacityAllocationStep.header')}</h2>
        <div className="wizard-step__overview">
          <div className="wizard-step__overview__paragraph">
            {t('teamCapacityAllocationStep.header2')}
          </div>
        </div>

        <div
          className="wizard-step__main-content-container"
          style={{ minWidth: '80%', maxWidth: '80%' }}
        >
          <TeamCapacityAllocationTable contextHandler={useUpdateContext} />
        </div>
      </div>
    </>
  )
}

export default UpdateTeamCapacityAllocation

import React from 'react'
import './styles.css'
import { useTranslation } from 'react-i18next'

const ErrorModal = ({ isOpen, handleClose, errorMessage }) => {
  const { t } = useTranslation(['Common'])
  return (
    <div className={`error-modal ${isOpen ? '' : 'hidden'}`}>
      <div className="modal-tf">
        <div className="modal-title-container">
          <b className="modal-title">{t('errorModal.error')}</b>
        </div>
        <div className="bullet-container">
          <div className="divider"></div>

          {errorMessage && (
            <div className="error-bullets">
              <span>{errorMessage}</span>
              <br></br>
            </div>
          )}

          {errorMessage === null && (
            <div className="error-bullets">
              <span>{t('errorModal.text1')}</span>
              <br></br>
              <span>{t('errorModal.text2')}</span>
              <br></br>
              <span>{t('errorModal.text3')}</span>
            </div>
          )}
        </div>
        <div className="modal-error-btn">
          <button className="error-btn negative-error-btn" onClick={handleClose} type="button">
            {t('commonButtons.close')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ErrorModal

import React from 'react'
import Modal from '../Modal'
import { useTranslation } from 'react-i18next'
import InPageLoader from '../InPageLoader'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import SelectField from '../CustomMultipleSelect/singleOption'

const TagSelectionModal = ({ closeModal, setSelectedTagId, selectedTagId }) => {
  console.log('selectedTagId: ', selectedTagId)

  const { t } = useTranslation(['Common'])
  const [isLoading, setIsLoading] = React.useState(false)
  const [tags, setTagss] = React.useState([])
  const [selectedTag, setSelectedTag] = React.useState(null)

  React.useEffect(() => {
    setIsLoading(true)
    listUserViewableTopicTags()
  }, [])

  const listUserViewableTopicTags = async () => {
    try {
      let response = await getHttpRequest('/list_user_viewable_topic_tags')
      setTagss(response.tags)
    } catch (error) {}
    setIsLoading(false)
  }

  React.useEffect(() => {
    setSelectedTag(tags.find((tag) => tag.tagId === selectedTagId) ?? null)
  }, [tags])

  const updateTopicMeetings = (selectedTag) => {
    setSelectedTag(selectedTag)
  }

  return (
    <Modal
      title={t('tagSelectionModal.title')}
      closeModal={closeModal}
      width={'60%'}
      titleStyle={{ marginRight: 'auto', color: '#5780ca' }}
      modalContainerStyle={{ width: '99%', padding: '0px' }}
    >
      {isLoading && <InPageLoader inComponent={true} />}
      {!isLoading && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '0.5rem',
            width: '100%',
          }}
        >
          <SelectField
            // placeholder={t("formPlaceHolder.owner")}
            name="selectTag"
            value={selectedTag}
            options={tags}
            onChange={updateTopicMeetings}
            getOptionLabel={(option) => option.tagName}
            getOptionValue={(option) => option.tagId}
            width={'45%'}
            menuPosition="fixed"
          />
          <div
            className="sleek-button sleek-blue"
            onClick={() => {
              if (selectedTag === null) {
                return
              }
              setSelectedTagId(selectedTag.tagId)
              closeModal(true)
            }}
            style={{ marginLeft: 'auto' }}
          >
            <span>{t('Common:commonButtons.apply')}</span>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default TagSelectionModal

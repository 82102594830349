import * as Yup from 'yup'

const AddEmployeeSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  teamAllocPct: Yup.number()
    .test('Is positive?', 'Has to be greater than 0%!', (value) => value > 0)
    .max(100, 'Cannot exceed 100%!')
    .required('Required'),
})

export default AddEmployeeSchema

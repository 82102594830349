import React, { useState, useEffect } from 'react'
import { useTeamLeaderContext } from '../../context/teamLeaderContext'
import help from '../../../../assets/help.svg'
import ObjectiveTypeHelpModal from '../../../../components/ObjectiveTypeHelpModal'
import './styles.css'
import { useTranslation } from 'react-i18next'

const ActivitiesStep = () => {
  const { t } = useTranslation(['TeamLeaderWizard'])
  const { state, dispatch } = useTeamLeaderContext()

  const [isBauObjective, setisBauObjective] = useState(
    new Array(state.data.objectives.length).fill(false),
  )

  const handleGoalTypeChange = (goalType, objectiveIndex) => {
    if (state.data.objectives[objectiveIndex]) {
      let objectiveToUpdate
      if (goalType === 3) {
        objectiveToUpdate = {
          ...state.data.objectives[objectiveIndex],
          type: goalType,
          cycle: null,
          level1Id: null,
          level2Id: null,
          possibleMeasure: null,
        }
      } else if (goalType === 1) {
        objectiveToUpdate = { ...state.data.objectives[objectiveIndex], type: goalType }
      }

      dispatch({
        type: 'UPDATE_OBJECTIVE',
        objective: objectiveToUpdate,
        objectiveIndex,
      })
    }
  }

  const updateCheckbox = () => {
    const updatedBauObjectiveArray = [...isBauObjective]
    for (let i = 0; i < state.data.objectives.length; i++) {
      if (state.data.objectives[i].type === 3) {
        updatedBauObjectiveArray[i] = true
      }
    }
    setisBauObjective(updatedBauObjectiveArray)
  }

  const setDefaultType = () => {
    for (let i = 0; i < state.data.objectives.length; i++) {
      if (state.data.objectives[i].type === null) {
        state.data.objectives[i].type = 1
      }
    }
  }

  useEffect(() => {
    updateCheckbox()
    setDefaultType()
  }, [])

  const handleChange = (position) => {
    const updatedCheckedState = isBauObjective.map((item, index) =>
      index === position ? !item : item,
    )
    setisBauObjective(updatedCheckedState)
  }

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
  }, [])

  return (
    <>
      <ObjectiveTypeHelpModal
        isOpen={state.isObjHelpModalOpen}
        handleClose={() => {
          dispatch({ type: 'CLOSE_OBJ_HELP_MODAL' })
        }}
      />

      <div className="wizard-step">
        <h2 className="wizard-step__title">{t('activitiesStep.title')}</h2>

        <table className="wizard-step__main-content-container">
          <tbody style={{ width: '100%' }}>
            <tr className="header-row">
              <th className="goal-column" style={{ width: '60%' }}>
                <span>{t('activitiesStep.objective')}</span>
              </th>
              <th style={{ width: '15rem' }}>
                <span className="objective-header">
                  {t('activitiesStep.isBAU')}
                  <img
                    className="header-help-btn"
                    onClick={() => {
                      dispatch({ type: 'OPEN_OBJ_HELP_MODAL' })
                    }}
                    src={help}
                    alt="Help button to learn more about Enterprise Objectives"
                  />
                </span>
              </th>
            </tr>

            {state.data.objectives.map((objective, index) => {
              return (
                <tr key={`objective-${index}`}>
                  <td className="goal-column" style={{ width: '60%' }}>
                    <span>{objective.statement}</span>
                  </td>
                  <td style={{ width: '15rem' }}>
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          defaultValue={objective.type ? objective.type : null}
                          checked={isBauObjective[index]}
                          data-testid={`obj-bau-${index}`}
                          onChange={(e) => {
                            handleChange(index)

                            if (isBauObjective[index]) {
                              handleGoalTypeChange(parseInt(1), index)
                            } else {
                              handleGoalTypeChange(parseInt(3), index)
                            }
                          }}
                        />
                      </label>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default ActivitiesStep

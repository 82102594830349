import React from 'react'
import './styles.css'
import employee from '../../../../../../assets/employee.svg'
import AddEmployeeForm from './addEmployeeForm'
import { useSetupWizardContext } from '../../../../context/setupWizardContext'
import Modal from '../../../../../../components/Modal'

const AddEmployeeModal = (props) => {
  const { dispatch } = useSetupWizardContext()
  const [resetForm, setResetForm] = React.useState(null)

  const handleSubmit = (values) => {
    let valuesWithLowerCaseEmail = {
      ...values,
      email: values.email.toLowerCase(),
    }

    dispatch({ type: 'ADD_TEAMLEADER', teamLeader: valuesWithLowerCaseEmail })

    props.onClose()
  }

  if (!props.show) {
    return null
  }

  return (
    <>
      {props.show === true && (
        <Modal
          title={'Add a Team Leader'}
          closeModal={() => {
            props.onClose()
            resetForm()
          }}
        >
          <AddEmployeeForm
            handleSubmit={handleSubmit}
            handleCancel={props.onClose}
            areaLeaderEmail={props.areaLeaderEmail}
            areaLeaderName={props.areaLeaderName}
            setResetForm={setResetForm}
          />
        </Modal>
      )}
    </>
  )
}

export default AddEmployeeModal

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getNoteTypeAspirationNotes,
  addWorkshopAspirationNote,
  editWorkshopAspirationNote,
  deleteWorkshopAspirationNote,
  generateWorkshopAspirationSummary,
} from '../../../../api/services/aspirationNotes.services'
import Toast from '../../../../common/toast'
import { Link } from 'react-router-dom'

const WorkshopAdminAspirationsView = () => {
  React.useEffect(() => {
    loadCustomerAspirationList()
    loadAssociateAspirationList()
  }, [])

  async function loadAssociateAspirationList() {
    let result = await getNoteTypeAspirationNotes({ noteType: 'Associate' })
    if (result && result.aspirationNotesList) {
      const groupedNotes = groupByOwnerName(result.aspirationNotesList)
      setAssociateAspirations(groupedNotes)
    }
  }

  async function loadCustomerAspirationList() {
    let result = await getNoteTypeAspirationNotes({ noteType: 'Customer' })
    if (result && result.aspirationNotesList) {
      const groupedNotes = groupByOwnerName(result.aspirationNotesList)
      setCustomerAspirations(groupedNotes)
    }
  }

  function groupByOwnerName(notes) {
    const grouped = {}
    notes.forEach((note) => {
      const ownerName = note.ownerName
      if (!grouped[ownerName]) {
        grouped[ownerName] = { customerNotes: [], associateNotes: [] }
      }
      if (note.noteType === 'Customer') {
        grouped[ownerName].customerNotes.push(note)
      } else if (note.noteType === 'Associate') {
        grouped[ownerName].associateNotes.push(note)
      }
    })
    return grouped
  }

  const [customerAspirations, setCustomerAspirations] = React.useState({})
  const [associateAspirations, setAssociateAspirations] = React.useState({})

  const generateSummaryNotes = async (isTop5PerPersonEnabled = false) => {
    let result = await generateWorkshopAspirationSummary(isTop5PerPersonEnabled)
    if (result) {
      Toast.fire({
        icon: 'success',
        title: 'Summary notes generated successfully',
      })
    } else {
      Toast.fire({
        icon: 'error',
        title: 'Failed to generate summary notes, try again',
      })
    }
  }

  const ownerNames = Object.keys(customerAspirations)

  return (
    <div
      style={{ display: 'flex', width: '100%', justifyContent: 'center', flexDirection: 'column' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '80%',
          gap: '2rem',
          paddingTop: '4rem',
        }}
      >
        <span
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '1rem' }}
        >
          <div className="sleek-button sleek-blue" onClick={() => generateSummaryNotes(false)}>
            Send to GPT 4
          </div>
          <div className="sleek-button sleek-blue" onClick={() => generateSummaryNotes(true)}>
            Send Top 5 notes per person to GPT 4
          </div>
          <Link
            to={`/workshopFinalAspirations`}
            state={{
              noteType: 'Customer-Summary',
            }}
            className="sleek-button sleek-blue"
          >
            View Cusp Asp
          </Link>
          <Link
            to={`/workshopFinalAspirations`}
            state={{
              noteType: 'Associate-Summary',
            }}
            className="sleek-button sleek-blue"
          >
            View Assoc Asp
          </Link>
        </span>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '80%',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '1rem 0',
          }}
        >
          <div style={{ flex: 0.2 }}>
            <div></div>
            <br />
            <br />
            <br />
            <div className="divider" />
            <br />
          </div>
          <div style={{ flex: 1 }}>
            <div>
              As Walmex we hope to leverage AI to better serve our <b>Customers</b> so that they
              can:
            </div>
            <br />
            <div className="divider" />
            <br />
          </div>
          <div style={{ flex: 1 }}>
            <div>
              As Walmex we hope to leverage AI to better serve our <b>Associates</b> so that they
              can:
            </div>
            <br />
            <div className="divider" />
            <br />
          </div>
        </div>
        {ownerNames.map((ownerName) => (
          <div
            key={ownerName}
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '80%',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '1rem 0',
            }}
          >
            <div style={{ flex: 0.2 }}>
              <h3>{ownerName}</h3>
            </div>
            <div style={{ flex: 1 }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                {customerAspirations[ownerName] &&
                  customerAspirations[ownerName].customerNotes.map((item, index) => (
                    <div key={index}>
                      <span>{item.note}</span>
                    </div>
                  ))}
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                {associateAspirations[ownerName] &&
                  associateAspirations[ownerName].associateNotes.map((item, index) => (
                    <div key={index}>
                      <span>{item.note}</span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default WorkshopAdminAspirationsView

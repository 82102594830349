import React, { useState } from 'react'
import './style.scss'
import { subActivityStatus } from '../../../../utils/activityStatus'
import useGenerateMultipleSubActivities from '../../../../api/mutation/useGenerateMultipleSubActivities'
import useAddMultipleSubActivitiesUsingAudio from '../../../../api/mutation/addMultipleSubActivitieUsingAudio'
import InPageLoader from '../../../../components/InPageLoader'
import AudioRecorder from '../../../../components/AudioRecorder'
import { getCompanyConfigSettings } from '../../../../utils/auth'

const initialForm = {
  subAcitivityDescription: '',
  status: subActivityStatus[0].value,
  multiSubActivityText: '',
}

const AddSubActivity = ({
  subActivities,
  setSubActivities,
  taskId,
  setIsRefreshSubActivities,
  setRefreshParentComponent,
}) => {
  const [form, setForm] = useState(initialForm)
  const [error, setError] = useState('')
  const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)
  const [isLoading, setIsLoading] = useState(false)
  const [displayError, setDisplayError] = React.useState(false)
  const [displayTextError, setDisplayTextError] = React.useState(false)
  const disableAiConfig = getCompanyConfigSettings('disableAi')
  const isSpanishLangEnabled = getCompanyConfigSettings('languageSettings') === 'es'

  function inputChangedHandler(event) {
    // Get name of changed input, and its corresponding value
    const { name, value } = event.target
    updateFormHandler(name, value)
    if (name === 'multiSubActivityText') {
      if (value != undefined || value != '') {
        setDisplayTextError(false)
      }
    }
  }

  function updateFormHandler(name, value) {
    setForm((prevState) => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  const addSubActivity = () => {
    if (form.subAcitivityDescription && form.status) {
      const obj = form
      setSubActivities((oldArray) => [...oldArray, obj])
      setForm(initialForm)
    }
  }

  const updateSubActivityList = (e, index) => {
    const cloneSubActivity = [...subActivities]
    cloneSubActivity[index][e.target.name] = e.target.value
    setSubActivities(cloneSubActivity)
  }
  const mutationGenerateSubActivitiesUsingText = useGenerateMultipleSubActivities()

  const addMultipleSubActivity = async () => {
    if (form.multiSubActivityText === '' || form.multiSubActivityText === undefined) {
      setDisplayTextError(true)
      return
    }
    setIsLoading(true)
    setRefreshParentComponent(true)
    await mutationGenerateSubActivitiesUsingText.mutateAsync(
      { subactivitiesText: form.multiSubActivityText, activityId: taskId },
      {
        onSuccess: (response) => {
          setIsLoading(false)
          setIsRefreshSubActivities((old) => !old)
          setRefreshParentComponent(false)
        },
        onError: (e) => {
          setDisplayError(true)
          setIsLoading(false)
          form.multiSubActivityText = ''
          setRefreshParentComponent(false)
        },
      },
    )
    setForm(initialForm)
  }

  const mutationGenerateSubActivitiesUsingAudio = useAddMultipleSubActivitiesUsingAudio()

  const generateSubActivitiesUsingAudio = async (audio) => {
    setIsLoading(true)
    setRefreshParentComponent(true)
    await mutationGenerateSubActivitiesUsingAudio.mutateAsync(
      { audioFile: audio, activityId: taskId },
      {
        onSuccess: (response) => {
          setIsLoading(false)
          setIsRefreshSubActivities((old) => !old)
          setRefreshParentComponent(false)
        },
        onError: (e) => {
          setDisplayError(true)
          setIsLoading(false)
          form.multiSubActivityText = ''
          setRefreshParentComponent(false)
        },
      },
    )
    setForm(initialForm)
  }

  return (
    <>
      {isLoading ? (
        <InPageLoader displayErrorText={true} />
      ) : (
        <div style={{ width: '100%' }}>
          {subActivities.map((data, index) => (
            <div key={index} className="form-field action-field form-field action-field--kanban">
              <div>
                <div
                  key={index}
                  className="form-field action-field form-field action-field--kanban action-field--add-subtask"
                >
                  <input
                    style={{ width: '19.5rem' }}
                    id="subAcitivityDescription"
                    name="subAcitivityDescription"
                    placeholder="Add Sub Activity name ..."
                    autoComplete="off"
                    className="custom-input"
                    value={data.subAcitivityDescription}
                    onChange={(e) => updateSubActivityList(e, index)}
                    data-testid={`subactivity-description-${index}`}
                  />

                  <select
                    as="select"
                    name="status"
                    className="custom-select"
                    value={data.status}
                    data-testid={`subactivity-status-${index}`}
                    onChange={(e) => updateSubActivityList(e, index)}
                  >
                    {subActivityStatus.map((data) => (
                      <option key={data.value} value={data.value} label={data.label} />
                    ))}
                  </select>
                </div>
              </div>
            </div>
          ))}

          <div className="form-field action-field form-field action-field--kanban action-field--add-subtask">
            <input
              style={{ width: '19.5rem' }}
              id="subAcitivityDescription"
              name="subAcitivityDescription"
              placeholder="Enter New subactivity here ..."
              autoComplete="off"
              className="custom-input"
              value={form.subAcitivityDescription}
              onChange={inputChangedHandler}
            />
            <select
              as="status"
              name="status"
              className="custom-select"
              value={form.status}
              onChange={inputChangedHandler}
            >
              {subActivityStatus.map((data) => (
                <option key={data.value} value={data.value} label={data.label} />
              ))}
            </select>

            <div
              className={'fitted-button blue '}
              data-testid="btnAddSingleSubActivity"
              onClick={() => addSubActivity()}
            >
              <span class="material-symbols-outlined">add</span>
            </div>
          </div>
          {!disableAiConfig && (
            <>
              <div className="form-field action-field form-field action-field--kanban form-field action-field--multi-subactivity">
                <textarea
                  rows="8"
                  id="multiSubActivityText"
                  name="multiSubActivityText"
                  value={form.multiSubActivityText}
                  maxLength="512"
                  onChange={inputChangedHandler}
                  style={{ width: '50%', height: '50%' }}
                  onFocus={() => {
                    setDisplayError(false)
                  }}
                />
                <div className="talk-text">
                  <div className={'fitted-button blue '} onClick={() => addMultipleSubActivity()}>
                    <span class="material-symbols-outlined">add</span> Multiple
                  </div>
                  {!isSpanishLangEnabled && (
                    <div
                      onClick={() => {
                        setDisplayError(false)
                      }}
                    >
                      <AudioRecorder handleSubmit={generateSubActivitiesUsingAudio} />
                    </div>
                  )}
                </div>
              </div>
              {displayTextError && (
                <div
                  className="validation-error"
                  style={{ paddingLeft: '0.5rem', marginTop: '-1rem' }}
                >
                  Required
                </div>
              )}
              {displayError && (
                <span style={{ color: 'red', paddingLeft: '0.5rem' }}>
                  Something went wrong. Please try again
                </span>
              )}
            </>
          )}
        </div>
      )}
    </>
  )
}
export default AddSubActivity

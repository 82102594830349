import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const submitAreaLeaderWizard = async (areaLeaderData) => {
  const { data } = await axios.post(`${BASE_URL}/area_leader_wizard_answer`, areaLeaderData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export default function useSubmitAreaLeaderWizard() {
  return useMutation((data) => submitAreaLeaderWizard(data))
}

import React from 'react'
import './styles.css'
import EnterpriseMetricsTable from './components/EnterpriseMetricsTable'
import ScoreCard from './components/ScoreCard'
import { useOutletContext } from 'react-router-dom'

const EnterpriseMetrics = () => {
  const { enterpriseMetricsData, companyWideScorecard } = useOutletContext()

  return (
    <div className="gov-enterprise">
      <table className="cardsContainer">
        <tr className="cardsContainerRow">
          <td className="cardsContainerCol">
            <ScoreCard scoreCardData={companyWideScorecard} />
          </td>
          <td className="cardsContainerCol">
            <EnterpriseMetricsTable enterpriseMetricsData={enterpriseMetricsData} />
          </td>
        </tr>
      </table>
    </div>
  )
}

export default EnterpriseMetrics

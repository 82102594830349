import { POST_KANBAN_MARK_ACTIVITY_AS_PRIORITY } from '../../../api/constant/route'
import { postHttpRequest } from '../../../api/query/dynamicAPI'

export async function markActivityAsPriority(value, milestoneId) {
  const postObject = {
    milestoneId: milestoneId,
    newStatus: value,
  }
  postHttpRequest(POST_KANBAN_MARK_ACTIVITY_AS_PRIORITY, postObject)
    .then((response) => {
      if (response) {
        // props.setIsRefreshObjective((old) => !old)
        return
      } else {
      }
    })
    .catch((error) => {})
}

import React from 'react'
import ApplicationLayout from '../../components/ApplicationLayout'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import { useTranslation } from 'react-i18next'
import './style.css'
import { dayMonthFilter } from '../../utils/time'
import { postHttpRequest } from '../../api/query/dynamicAPI'
import Toast from '../../common/toast'
import OdinLandingModal from './component/modalComponent/OdinLandingModal'
import { getObjectivUserList } from '../../api/services/employee.services'
import { removeMentionMarkupFromText } from '../../utils/parseMarkup'
import { useLocation, useSearchParams } from 'react-router-dom'

const OdinInbox = () => {
  const { t } = useTranslation(['Common'])
  const [emailData, setEmailData] = React.useState([])
  const topicType = ['Blocker', 'Guidance', 'Update', 'Admin']
  const topicColors = ['#ED522E', '#0926D5', 'rgb(127, 131, 155)', '#9933FF']
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [selectedTrackingId, setSelectedTrackingId] = React.useState(null)
  const [reload, setReload] = React.useState(false)
  const [userList, setUserList] = React.useState([])

  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  const queryParams = new URLSearchParams(location.search)
  const trackingId = queryParams.get('trackingId')

  React.useEffect(() => {
    ;(async () => {
      const response = await getObjectivUserList(0)
      if (response?.employees)
        setUserList(response.employees.map((emp) => ({ display: emp.name, id: emp.eId })))
    })()
  }, [])

  React.useEffect(() => {
    ;(async () => {
      try {
        let response = await getHttpRequest('/get_odin_inbox')
        setEmailData(response.odinInboxEmails ?? [])
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: t('modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
    })()
  }, [reload])

  React.useEffect(() => {
    if (emailData.length === 0 || !trackingId) return
    let all_tracking_ids = emailData.map((email) => email.trackingId)
    // don;t open modal if the trackingId is already accepted
    if (!all_tracking_ids.includes(trackingId)) return
    setSelectedTrackingId(trackingId)
    setIsModalOpen(true)
  }, [emailData])

  const buildActionTitle = (actionType) => {
    switch (actionType) {
      case 'TOPIC_RESOLVED':
        return t('odinInbox.topicResolved')
      case 'COMMENT_ON_TOPIC':
        return t('odinInbox.commentOnTopic')
      case 'MILESTONE_ON_TOPIC':
        return t('odinInbox.milestoneOnTopic')
      case 'COMMENT_ON_ACTION':
        return t('odinInbox.actionUpdate')
      default:
        return ''
    }
  }

  const toggleEmailAcceptance = (index, newAcceptedVal) => {
    // shallow copy of emailData
    let newEmailData = [...emailData]
    // obj is still referencing to old state, but it's ok in this case
    newEmailData[index].isAccepted = newAcceptedVal
    setEmailData(newEmailData)
  }

  return (
    <>
      {isModalOpen && selectedTrackingId && (
        <OdinLandingModal
          selectedTrackingId={selectedTrackingId}
          isModalOpen={isModalOpen}
          closeModal={() => {
            setIsModalOpen(false)
            setSelectedTrackingId(null)
            // re-fetch the data
            setReload((prev) => !prev)
            setSearchParams((params) => {
              params.delete('trackingId')
              return params
            })
          }}
          userList={userList}
        />
      )}
      <ApplicationLayout>
        <div className="dashboard_wrapper">
          <div style={{ textAlign: 'left' }}>
            <b style={{ justifySelf: 'flex-start', alignSelf: 'center', fontSize: '20px' }}>
              {t('odinInbox.pageTitle')}
            </b>
          </div>
          <div className="odin-email-inbox-list" style={{ marginTop: '1.3rem' }}>
            <div className="odin-email-inbox-list-row">
              <div className="odin-email-inbox-list-header">{'Ref No.'}</div>
              <div className="odin-email-inbox-list-header">{t('odinInbox.sentOn')}</div>
              <div className="odin-email-inbox-list-header">{t('odinInbox.inRefTo')}</div>
              <div className="odin-email-inbox-list-header">{t('odinInbox.whatDone')}</div>
              <div className="odin-email-inbox-list-header">{t('odinInbox.editOrAccept')}</div>
            </div>
            {emailData.length === 0 && (
              <>
                <div style={{ textAlign: 'center' }}>{t('odinInbox.noEmails')}</div>
              </>
            )}
            {emailData.map((email, index) => {
              return (
                <div
                  className="odin-email-inbox-list-row"
                  key={email.trackingId}
                  style={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'white' }}
                >
                  <div className="odin-email-inbox-list-table-cell">{email.trackingId}</div>
                  <div className="odin-email-inbox-list-table-cell">
                    {dayMonthFilter(email.executionTs)}
                  </div>
                  <div
                    className="odin-email-inbox-list-table-cell"
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <div
                      className="action-box"
                      style={{ backgroundColor: topicColors[email.topicType - 1] }}
                    >
                      <div
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      >
                        {topicType[email.topicType - 1]}
                      </div>
                    </div>
                    {email.topicDescription}
                  </div>
                  <div className="odin-email-inbox-list-table-cell">
                    <ul>
                      {email.top3ItemsByOdin.map((item, itemIndex) => {
                        return (
                          <li key={itemIndex}>
                            {buildActionTitle(item.type)} {removeMentionMarkupFromText(item.value)}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                  <div className="odin-email-inbox-list-table-cell">
                    <div style={{ display: 'flex', flexDirection: 'row', columnGap: '0.88rem' }}>
                      <div
                        className="fitted-button blue tooltip"
                        style={{ backgroundColor: 'white' }}
                        onClick={() => {
                          setSelectedTrackingId(email.trackingId)
                          setIsModalOpen(true)
                        }}
                      >
                        <span className="material-symbols-outlined">edit</span>
                      </div>

                      <div
                        className="fitted-button checklist"
                        onClick={async () => {
                          let newVal = !email.isAccepted
                          toggleEmailAcceptance(index, newVal)
                          try {
                            let result = await postHttpRequest(
                              `/accept_odin_email/${email.trackingId}`,
                              {
                                isAccepted: newVal,
                              },
                            )
                            if (result.success) {
                              Toast.fire({
                                icon: 'success',
                                title: t('modalMessages.updatedSuccessfully'),
                              })
                            } else {
                              Toast.fire({
                                icon: 'error',
                                title: t('modalMessages.somethingWentWrongTryAgainLater'),
                              })
                              toggleEmailAcceptance(index, !newVal)
                            }
                          } catch (error) {
                            Toast.fire({
                              icon: 'error',
                              title: t('modalMessages.somethingWentWrongTryAgainLater'),
                            })
                            toggleEmailAcceptance(index, !newVal)
                          }
                        }}
                      >
                        <span
                          className={`material-symbols-outlined ${
                            email.isAccepted ? 'green-check' : 'grey-check'
                          }`}
                        >
                          done
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </ApplicationLayout>
    </>
  )
}

export default OdinInbox

import React, { useState } from 'react'
import Modal from '../../../../components/Modal'
import generateUniqueId from 'generate-unique-id'
import CloseIcon from '../../../../assets/close-icon-white.svg'
import useCreateUserAddedList from '../../../../api/mutation/useCreateUserAddedList'
import InPageLoader from '../../../../components/InPageLoader'

const CustomFieldListModal = ({ isModalOpen, closeModal, listId, list }) => {
  const [optionInput, setOptionInput] = React.useState('')
  const [listName, setListName] = React.useState(listId ? list.listName : '')
  const [options, setOptions] = React.useState(listId ? list.listItems : [])
  const [errorMessage, setErrorMessage] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)

  function updateOptionValue(label, index) {
    const tempOptions = [...options]
    tempOptions[index] = label
    setOptions(tempOptions)
  }

  const addOption = () => {
    if (optionInput === '' || !optionInput.replace(/\s/g, '').length) {
      setErrorMessage('Add a valid option name')
      return
    }

    for (let i = 0; i < options.length; i++) {
      if (options[i].itemLabelStr.toUpperCase() === optionInput.toUpperCase().trim()) {
        setErrorMessage('This option name has already been added')
        return
      }
    }

    setErrorMessage(null)

    const id = generateUniqueId({
      length: 4,
      useLetters: false,
    })

    setOptions([
      ...options,
      {
        itemKey: id,
        itemLabelStr: optionInput.trim(),
        isNew: true,
      },
    ])

    setOptionInput('')
  }

  const removeOption = (index) => {
    const temp_teamMemerList = [...options]
    temp_teamMemerList.splice(index, 1)
    setOptions(temp_teamMemerList)
  }

  const createUserAddedListMutation = useCreateUserAddedList()

  const submitForm = async () => {
    let list = {
      listId: listId ?? null,
      listName: listName,
      options: options,
    }

    setIsLoading(true)
    await createUserAddedListMutation.mutateAsync(list, {
      onSuccess: (response) => {
        list.listId = response.list_id
        closeModal()
      },
      onError: () => {},
    })
    setIsLoading(false)
  }

  return (
    <div style={{ overlay: { zIndex: 20 } }}>
      <Modal
        title={listId ? 'Add List Options' : 'Add New List'}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        width={'40vw'}
      >
        {isLoading === true && <InPageLoader inComponent={true} />}

        {isLoading === false && (
          <>
            <>
              <div className="form-field action-field  form-field action-field--kanban ">
                <div className="form-field action-field  form-field action-field--kanban ">
                  <label>Name</label>
                  <div className="form-field action-field action-field--kanban">
                    <input
                      type="test"
                      id="listName"
                      name="listName"
                      value={listName}
                      onChange={(e) => setListName(e.target.value)}
                      className="custom-input"
                    />
                  </div>
                </div>

                <label>List Item Name</label>
                <div
                  className="form-field action-field action-field--kanban"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    columnGap: '1rem',
                    alignItems: 'center',
                  }}
                >
                  <input
                    type="test"
                    id="addOption"
                    name="addOption"
                    value={optionInput}
                    onChange={(e) => setOptionInput(e.target.value)}
                    className="custom-input"
                  />
                  <button
                    className="add-btn-comment"
                    type="button"
                    onClick={() => addOption()}
                    style={{ height: '2rem' }}
                  >
                    +
                  </button>
                </div>
              </div>
              {errorMessage && (
                <div style={{ color: 'red', textAlign: 'start' }}> {errorMessage} </div>
              )}
            </>

            {options.map((data, index) => {
              return (
                <div key={index} className="create-template-option">
                  <input
                    type="test"
                    id="addOption"
                    name="addOption"
                    value={data.itemLabelStr}
                    disabled={true}
                    onChange={(e) => updateOptionValue(e.target.value, index)}
                    className="custom-input"
                  />
                  {data.isNew === true && (
                    <div onClick={(e) => removeOption(index)}>
                      <span className="close-team-member-icon">
                        <img src={CloseIcon} alt="" />
                      </span>
                    </div>
                  )}
                </div>
              )
            })}
          </>
        )}

        <div className="modal-dual-btn">
          <button className="dual-btn negative-btn" type="button" onClick={() => closeModal()}>
            CANCEL
          </button>
          <button className="dual-btn positive-btn" type="button" onClick={() => submitForm()}>
            SAVE
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default CustomFieldListModal

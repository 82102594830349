import React, { useState, useEffect } from 'react'
import { useAreaLeaderContext } from '../../context/areaLeaderContext'
import help from '../../../../assets/help.svg'
import './styles.css'
import InfoModal from './components/InfoModal'
import MeasureHelpModal from './components/MeasureHelpModal'
import { useTranslation } from 'react-i18next'

const EnterpriseStepAreaLeader = () => {
  const { t } = useTranslation(['AreaLeaderWizard'])
  const { state, dispatch } = useAreaLeaderContext()

  const level2Objectives = state.data.objectives.filter((obj) => obj.level === 3)

  const goals = React.useMemo(() => {
    const returnGoals = []

    if (state.data.linkageData.higherObjectives) {
      state.data.linkageData.higherObjectives.forEach((goal) => {
        if (goal.level === 1) {
          returnGoals.push({
            levelOneGoal: goal,
          })
        }
      })

      // This handles orphan level 2 objectives. We create a dummy level 1 objecitve with the ID 0,
      // which can be used to represent the dropdown option for "Not linked to an enterprise objective".
      returnGoals.push({
        levelOneGoal: {
          id: '0', // This should be a string because others are strings too (why??)
          statement: t('linkageStep.notLinkedEnterpriseObjective'),
        },
      })

      return returnGoals
    }
  }, [state.data.linkageData.higherObjectives])

  const [disableAreaObjective, setDisableAreaObjective] = useState(
    new Array(state.data.objectives.length).fill(true),
  )

  const updateDropdownStatus = () => {
    const updatedDropdownArray = [...disableAreaObjective]
    for (let i = 0; i < state.data.objectives.length; i++) {
      if (state.data.objectives[i].type === 3) {
        continue
      }
      if (
        state.data.objectives[i].level1Id !== 'none' &&
        state.data.objectives[i].level1Id !== undefined &&
        state.data.objectives[i].level1Id !== null
      ) {
        updatedDropdownArray[i] = false
      }
    }
    setDisableAreaObjective(updatedDropdownArray)
  }

  useEffect(() => {
    updateDropdownStatus()
  }, [])

  const handleLevel1GoalChange = (level1Id, objectiveIndex) => {
    if (state.data.objectives[objectiveIndex]) {
      let objectiveToUpdate = {}
      if (level1Id === 'none' || level1Id === '0') {
        objectiveToUpdate = {
          ...state.data.objectives[objectiveIndex],
          type: 2,
          level: 2,
          level1Id: null,
        }
      } else {
        objectiveToUpdate = {
          ...state.data.objectives[objectiveIndex],
          type: 1,
          level: 2,
          level1Id: level1Id,
        }
      }

      const updatedDropdownArray = [...disableAreaObjective]
      if (level1Id === null) {
        updatedDropdownArray[objectiveIndex] = true
      } else {
        updatedDropdownArray[objectiveIndex] = false
      }
      setDisableAreaObjective(updatedDropdownArray)

      dispatch({
        type: 'UPDATE_OBJECTIVE',
        objectiveIndex,
        objective: objectiveToUpdate,
      })
    }
  }

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
  }, [])

  return (
    <>
      <InfoModal
        isOpen={state.isEnterpriseHelpModalOpen}
        handleClose={() => {
          dispatch({ type: 'CLOSE_ENTERPRISE_INFO_MODAL' })
        }}
        stratDescData={state.data?.stratDescData ?? []}
      />

      <MeasureHelpModal
        isOpen={state.isEnterpriseMeasureModalOpen}
        handleClose={() => {
          dispatch({ type: 'CLOSE_ENTERPRISE_MEASURE_MODAL' })
        }}
      />

      <div className="wizard-step">
        <h2 className="wizard-step__title">{t('linkageStep.title')}</h2>
        <div className="wizard-step__overview">
          <div className="wizard-step__overview__paragraph">
            <span>{t('linkageStep.subtitle')}</span>
          </div>
        </div>

        <div className="wizard-step__main-content-container">
          <table style={{ width: '100%' }}>
            <tr className="header-row" style={{ width: '100%' }}>
              <th style={{ width: '60%' }}>
                <span>{t('linkageStep.objective')}</span>
              </th>
              <th style={{ width: '40%' }}>
                <span>{t('linkageStep.enterpriseObjective')}</span>
                <img
                  className="header-help-btn"
                  onClick={() => {
                    dispatch({ type: 'OPEN_ENTERPRISE_INFO_MODAL' })
                  }}
                  src={help}
                  alt="Help button to learn more about Enterprise Objectives"
                />
              </th>
            </tr>
            {state.data.objectives.map(
              (objective, objIndex) =>
                !level2Objectives.includes(objective) && (
                  <tr key={`obj-${objIndex}`} style={{ width: '100%' }}>
                    <td style={{ width: '60%' }}>
                      <span>{objective.statement}</span>
                    </td>

                    <td style={{ width: '40%' }}>
                      <select
                        className="enterprise-goal-select"
                        defaultValue={objective.level1Id ? objective.level1Id : 'none'}
                        data-testid={`enterprise-obj-${objIndex}`}
                        onChange={(e) => {
                          handleLevel1GoalChange(e.target.value, objIndex)
                        }}
                      >
                        <option value="none">{t('linkageStep.selectEnterpriseObjective')}</option>

                        {goals.map((goal, goalIndex) => {
                          return (
                            <option
                              key={`level1-${goalIndex}`}
                              id={goal.levelOneGoal.id}
                              value={goal.levelOneGoal.id}
                            >
                              {goal.levelOneGoal.statement}
                            </option>
                          )
                        })}
                      </select>
                    </td>
                  </tr>
                ),
            )}
          </table>
        </div>
      </div>
    </>
  )
}

export default EnterpriseStepAreaLeader

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getOdinEmailDetailData = async (trackingId) => {
  const queryString = `/get_odin_email/${trackingId}`

  const { data } = await axios.get(`${BASE_URL}${queryString}`)

  return data
}

export default function useOdinEmailDetailData(trackingId = null, reload = false) {
  return useQuery(
    ['odin-email-detail', { reload: reload, trackingId: trackingId }],
    () => getOdinEmailDetailData(trackingId),
    {
      refetchOnWindowFocus: false,
    },
  )
}

import React from 'react'
import teamLeaderReducer from './teamLeaderReducer'

export const DEFAULT_GOAL_DATA = {
  type: null,
  cycle: null,

  durationWeeks: 999999,

  lackingCapacity: false,
  // FTE that the TL claims is required for this objective.
  requiredFte: 0,
  // FTE that eventually get allocated to the objective. These may be fewer than the initial
  // required value.
  allocatedFte: 0,
  // For objectives that exceed capacity. Represents how many *extra* FTEs are required for this
  // objective. (In principle, requiredFte = allocatedFte + missingFte).
  missingFte: 0,
  blockers: [],
  decisions: [],
  updates: [],
}

export const defaultState = {
  lastEmpLocaleId: -1,
  lastObjLocaleId: -1,
  lastMeetingTopicLocaleId: -1,
  isFlowToWorkLeader: false,
  isEmployeeModalOpen: false,
  isObjHelpModalOpen: false,
  isObjFormModalOpen: false,
  isEnterpriseHelpModalOpen: false,
  isEnterpriseMeasureModalOpen: false,
  isCapacityConfirmModalOpen: false,
  isObjectivesFlowToWorkModalOpen: false,
  editObjectiveIndex: null,
  isOverCapacity: false,
  totalTeamAvailableFTEs: 0,
  totalObjRequiredFTEs: 0,
  addGoal: true,
  isMultipleObjModalOpen: false,
  data: {
    employees: [],
    objectives: [],
    linkageData: {},
    stratDescData: [],
    goalValidity: [],
    externalEmpAlocs: {},
    nonReportingEmpsRealIndexes: [],
  },
  allocSubWiz: {
    updateObjectiveId: null,
    errorTeamCapAllocTable: false,
    triggerSelectAllEmp: false,
  },
}

const TeamLeaderContext = React.createContext({
  state: defaultState,
  dispatch: () => {},
})

export const TeamLeaderProvider = ({ children, initialState }) => {
  const [state, dispatch] = React.useReducer(teamLeaderReducer, initialState)

  const context = {
    state,
    dispatch,
  }

  return <TeamLeaderContext.Provider value={context}>{children}</TeamLeaderContext.Provider>
}

const useTeamLeaderContext = () => {
  const context = React.useContext(TeamLeaderContext)
  if (!context) {
    throw new Error(
      'useTeamLeaderContext must be used within a TeamLeaderProvider. Wrap a parent component in <TeamLeaderProvider> to fix this error',
    )
  }

  return context
}

export { useTeamLeaderContext }

import React, { useState } from 'react'
import { useAreaLeaderContext } from '../../context/areaLeaderContext'
import { Outlet } from 'react-router-dom'
import ProgressBar from '../ProgressBar'

import NextPreviousActions from '../NextPreviousActions'
import useAreaLeaderWizardSteps, {
  stepsLabelsGenerator,
} from '../../hooks/useAreaLeaderWizardSteps'
import { useTranslation } from 'react-i18next'

const AreaLeaderWizard = ({ existingStep }) => {
  const { t } = useTranslation(['TeamLeaderWizard'])
  const [errorMessage, setErrorMessage] = useState(null)
  const { state } = useAreaLeaderContext()
  const { currentStepIndex, steps, handleNext, handlePrevious } =
    useAreaLeaderWizardSteps(existingStep)

  const stepsLabels = stepsLabelsGenerator()

  //TODO: Remove local storage
  // React.useEffect(() => {
  //   return () => {
  //     if (currentStepIndex > 0) {
  //       // localStorage.setItem('tlw-data', JSON.stringify(state.data))
  //       saveTLWizardState(state.data, currentStepIndex)
  //     }
  //   }
  // }, [currentStepIndex, state.data])

  return (
    <>
      <ProgressBar currentStep={currentStepIndex} steps={steps} />
      <Outlet
        context={{
          setErrorMessage,
          handlePrevious,
        }}
      />
      <NextPreviousActions
        stepsLabels={stepsLabels}
        isLastStep={currentStepIndex === steps.length - 1}
        currentStep={currentStepIndex}
        handleNext={() => {
          if (currentStepIndex === stepsLabels.OBJECTIVES) {
            if (state.data.objectives.length === 0) {
              setErrorMessage(t('stepsErrorMsg.oneValidObj'))
              return
            } else if (state.data.objectives.length >= 1) {
              for (let i = 0; i < state.data.objectives.length; i++) {
                if (state.data.objectives[i].isValid === false) {
                  setErrorMessage(t('stepsErrorMsg.allObjNeedToBeValid'))
                  return
                }
              }
              setErrorMessage(null)
              handleNext()
            }
          }
          //not necessary to keep conditions if there isn't anything to check
          // else if (currentStepIndex === stepsLabels.OBJECTIVE_TYPE) {
          //   setErrorMessage(null)
          //   handleNext()
          // }

          // else if (currentStepIndex === stepsLabels.LINKAGE) {
          //   setErrorMessage(null)
          //   handleNext()
          // }
          else {
            setErrorMessage(null)
            handleNext()
          }
        }}
        handlePrevious={() => {
          handlePrevious()
        }}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </>
  )
}

export default AreaLeaderWizard

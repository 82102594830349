import React from 'react'
import Modal from '../../Modal'
import { useTranslation } from 'react-i18next'
import useGetAllActiveCompanyObj from '../../../api/query/useGetAllActiveCompanyObj'
import { getCompanyConfigSettings } from '../../../utils/auth'
import { getAllEmployeeList } from '../../../api/services/employee.services'
import { postHttpRequest } from '../../../api/query/dynamicAPI'
import Toast from '../../../common/toast'
import {
  API_ERROR_MESSAGE,
  API_SPREADHSHEET_SAVE_SUCCESS_MESSAGE,
} from '../../../api/constant/route'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Select from 'react-select'
import { meetingCommentAdd } from '../../../api/services/meetingTopic.services'

const BrainstormAddActionDecisionModal = ({ closeModal, loadAgain = () => {}, text = '' }) => {
  const { t } = useTranslation(['Common', 'MyNotebook'])
  const isProductSettingsEnabled = getCompanyConfigSettings('productSettings')
  const userId = localStorage.getItem('tfei')
  //   const [eId, setEid] = React.useState(undefined)
  const { data: objList } = useGetAllActiveCompanyObj({
    owner: userId,
    selectedLeaderToLoadMH: null,
    showL3ObjsForAlAndJuniorTls: false,
  })

  const languageSettings = JSON.parse(localStorage.getItem('tfmc'))?.languageSettings
  const [topicLists, setTopicLists] = React.useState([])
  const [selectedObjective, setSelectedObjective] = React.useState(0)
  const [selectedTopic, setSelectedTopic] = React.useState(0)

  React.useEffect(() => {
    if (objList?.objectives) {
      getTopicsByObjIds(objList?.objectives.map((item) => item.id))
    }
  }, [objList])

  const getTopicsByObjIds = async (objIds) => {
    try {
      const topics = await postHttpRequest('/get_meeting_topics_for_objectives', {
        obj_ids: objIds,
        hasLimit: false,
      })
      let finalTopics = []
      topics?.objectives.forEach((topic) => {
        const { objId, blockers, decisions, updates, admins } = topic
        console.log(objId, blockers, decisions, updates, admins)
        let tempTopics = []
        if (blockers) {
          console.log('blockers', blockers)
          tempTopics = [
            ...tempTopics,
            ...blockers.map((blocker) => ({
              label: blocker.actionItem,
              objId: objId,
              topicId: blocker.umtId,
            })),
          ]
        }
        if (decisions) {
          console.log('decisions', decisions)
          tempTopics = [
            ...tempTopics,
            ...decisions.map((decision) => ({
              label: decision.actionItem,
              objId: objId,
              topicId: decision.umtId,
            })),
          ]
        }
        if (updates) {
          console.log('updates', updates)
          tempTopics = [
            ...tempTopics,
            ...updates.map((update) => ({
              label: update.actionItem,
              objId: objId,
              topicId: update.umtId,
            })),
          ]
        }
        if (admins) {
          console.log('admins', admins)
          tempTopics = [
            ...tempTopics,
            ...admins.map((admin) => ({
              label: admin.actionItem,
              objId: objId,
              topicId: admin.umtId,
            })),
          ]
        }

        finalTopics = [...finalTopics, ...tempTopics]
      })
      console.log('finalTopics', finalTopics)
      setTopicLists(finalTopics)
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: API_ERROR_MESSAGE,
      })
    }
  }

  const submitForm = async (values, { setSubmitting }) => {
    console.log('valuessss', values)

    try {
      let params = {
        commentText: values.decisionText,
        goalId: values.objectiveId,
        umtId: values.topicId,
        isDecision: true,
        mentions: [],
        isFromMindMap: true,
      }
      await meetingCommentAdd(params)
      loadAgain()
      closeModal()
    } catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const getObjectiveLabel = (option) => {
    if (isProductSettingsEnabled && !option?.isGeneralObjective) {
      return `${option?.initiativeId?.toString()?.padStart(5, '0')} - ${!option?.isOriginal ? 'SHARED - ' : ''}${option.statement}`
    }
    return `${!option?.isOriginal ? 'SHARED - ' : ''}${option.statement}`
  }

  const validationSchema = Yup.object().shape({
    objectiveId: Yup.number()
      .required('Objective is required')
      .notOneOf([0], 'Selecting an objective is required'), // Excludes 0 as a valid selection
    topicId: Yup.number()
      .required('Topic is required')
      .notOneOf([0], 'Selecting a topic is required'), // Excludes 0 as a valid selection
    decisionText: Yup.string().required('Required'),
  })

  return (
    <Modal title={t('Common:brainstorming.addDecision')} closeModal={closeModal} width="80%">
      <Formik
        enableReinitialize
        initialValues={{
          objectiveId: 0,
          decisionText: text || '',
          topicId: 0,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          submitForm(values, resetForm)
        }}
      >
        {({ errors, touched, resetForm, setFieldValue, values }) => (
          <Form
            className="objective-form"
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="form-field action-field form-field action-field--kanban">
                <label>{t('MyNotebook:objective') + '*'}</label>
                <div style={{ width: '40rem' }}>
                  <Select
                    placeholder={t('formPlaceHolder.selectObjective')}
                    name="objectiveId"
                    id="objectiveId"
                    value={objList?.objectives?.find((item) => item.id == values.objectiveId)}
                    options={objList?.objectives}
                    onChange={(selectedOption) => {
                      console.log('selectedOption obj', selectedOption)
                      setFieldValue('objectiveId', selectedOption ? selectedOption.id : 0)
                      setFieldValue('topicId', 0)
                      // setSelectedObjective(selectedOption.id)
                      // setSelectedTopic(0)
                    }}
                    getOptionLabel={getObjectiveLabel}
                    getOptionValue={(option) => parseInt(option.id)}
                    isClearable={true}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: '100%',
                      }),
                    }}
                  />
                </div>
                <ErrorMessage name="objectiveId" component="div" className="validation-error" />
              </div>

              <div className="form-field action-field form-field action-field--kanban">
                <label>{t('formPlaceHolder:meetingTopic') + '*'}</label>
                <div style={{ width: '40rem' }}>
                  <Select
                    placeholder={t('formPlaceHolder.selectTopic')}
                    name="topicId"
                    id="topicId"
                    value={topicLists?.find((item) => item.topicId == values.topicId) || ''}
                    options={topicLists.filter((topic) => {
                      // values.objectiveId is string
                      // topic.objId is number
                      return values.objectiveId == 0 || topic.objId == values.objectiveId
                    })}
                    onChange={(selectedOption) => {
                      setFieldValue('topicId', selectedOption ? selectedOption.topicId : 0)
                      console.log('selectedOption', selectedOption)
                      setFieldValue('objectiveId', selectedOption ? selectedOption.objId : 0)
                      // setSelectedObjective(selectedOption.objId)
                      // setSelectedTopic(selectedOption.topicId)
                    }}
                    getOptionLabel={(option) => option?.label}
                    getOptionValue={(option) => option?.topicId}
                    isClearable={true}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: '100%',
                      }),
                    }}
                  />
                </div>
                <ErrorMessage name="topicId" component="div" className="validation-error" />
              </div>

              <div className="form-field action-field form-field action-field--kanban">
                <label>{t('MyNotebook:description') + '*'}</label>
                <Field
                  name="decisionText"
                  as="textarea"
                  placeholder={languageSettings === 'en' ? 'Type here...' : 'Escribe aquí...'}
                  style={{ width: '39rem', resize: 'none' }}
                />
                <ErrorMessage name="decisionText" component="div" className="validation-error" />
              </div>
            </div>

            <div className="modal-dual-btn" style={{ columnGap: '4rem' }}>
              <div
                class="fitted-button blue"
                onClick={() => {
                  closeModal()
                  resetForm()
                }}
                type="button"
              >
                {t('commonButtons.cancel')}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <button
                  type="submit"
                  className="sleek-button sleek-blue"
                  style={{ cursor: 'pointer', height: '2.2rem', backgroundColor: 'white' }}
                >
                  <span>{t('commonButtons.save')}</span>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default BrainstormAddActionDecisionModal

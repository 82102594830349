import TeamFlow from '../../../teamFlow/index'
import { useLocation, useNavigate } from 'react-router-dom'
const GTeamFlow = (props) => {
  const location = useLocation()
  const navigate = useNavigate()

  const closeGTeamFlowkView = () => {
    const redirectTo = location.state?.path
    navigate(redirectTo)
  }
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
          width: '100%',
        }}
      >
        {location.state.selectedAreaLeader && (
          <TeamFlow
            closeGTeamFlowkView={closeGTeamFlowkView}
            show={false}
            areaLeader={location.state.selectedAreaLeader}
          />
        )}
      </div>
    </>
  )
}

export default GTeamFlow

import React from 'react'
import { shortenName } from '../../utils/general'
import { useTranslation } from 'react-i18next'
import './style.scss'
import { getCurrentMondayDate } from '../../utils/general'

const EmployeeBox = ({ employee, setIsModalOpen, setData, isCxo }) => {
  const { t } = useTranslation(['Dashboard'])

  let statusColor = employee.mei_status.is_good ? '#3bc13b' : 'red'
  const textColor = 'white'
  const blockColor = employee.mei_status.is_on_vacation
    ? ''
    : employee.mei_status.is_good
      ? t('employeeBox.green')
      : t('employeeBox.red')
  const blockFontColor = employee.mei_status.is_good ? '#A9D18E' : '#F6A9A3'
  if (employee.mei_status.is_on_vacation) {
    statusColor = 'grey'
  }

  const LinkElement = () => {
    return (
      <div
        style={{
          textDecoration: 'underline',
        }}
      >
        {!employee.mei_status.is_on_vacation &&
          (employee.mei_status.is_good ? (
            <span class="material-symbols-outlined" style={{ color: `${textColor}` }}>
              done{' '}
            </span>
          ) : (
            <>
              <span
                class="material-symbols-outlined"
                style={{ color: `${blockFontColor}`, cursor: 'pointer', fontSize: '30px' }}
                onClick={() => {
                  setIsModalOpen(true)
                  setData(employee)
                }}
              >
                error{' '}
              </span>
            </>
          ))}
      </div>
    )
  }
  return (
    <div
      style={{
        // width: '100%',
        display: 'flex',
        flexDirection: isCxo ? 'row' : 'column',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          // border: `2px solid ${statusColor}`,
          borderRadius: '0.3rem',
          padding: '8px',
          margin: '8px',
          width: '10rem',
          textAlign: 'left',
          fontSize: '15px',
          wordWrap: 'break-word',
          color: `${textColor}`,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          // boxShadow: '2px 2px 10px lightblue',
          backgroundColor: `${statusColor}`,
          height: '4rem',
        }}
      >
        <div style={{ flexDirection: 'column', width: '90px' }}>
          <div style={{ color: `${blockFontColor}`, fontSize: '13px' }}>{blockColor}</div>
          <div style={{ fontWeight: 'bold', width: '7rem', wordBreak: 'break-word' }}>
            {shortenName(employee.name)}
          </div>
        </div>
        {!employee.isGood && <LinkElement />}

        {employee.isGood && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div>
              <span
                class="material-symbols-outlined"
                style={{ color: `${blockFontColor}`, fontSize: '30px' }}
              >
                check_circle{' '}
              </span>
              {/* <span class="material-symbols-outlined" style={{ opacity: '1' }}>
                question_mark{' '}
              </span> */}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const EmployeeMeiBoxes = ({
  metrics = {},
  setIsModalOpen = () => {},
  setData = () => {},
  isCxo = false,
}) => {
  const [reportingsMei, setReportingsMei] = React.useState([])

  const thresholds = {
    BenchmarkActionClosedTime: 28,
    BenchmarkTopicsCounts: metrics?.mei_thresholds?.prepared_target ?? 1,
    BenchmarkCompletionPercentage: metrics?.mei_thresholds?.taskmaster_target ?? 0.4,
    BenchmarkCoordiatedAvg: metrics?.mei_thresholds?.coordinated_target ?? 1,
  }

  React.useEffect(() => {
    processMetrics(metrics)
  }, [metrics])

  const processMetrics = (metrics) => {
    // let all_employees = []
    // if (metrics?.prepared_metrics?.length > 0) {
    //   // if TL is logged in, length of these arrays should always be 1
    //   // but if Al, could be 0 if no one is reporting to him
    //   for (let owner_data of metrics.prepared_metrics) {
    //     let topics_counts_data = owner_data.topics_counts_data
    //     let owner = {}
    //     owner.name = owner_data.owner_name
    //     owner.eId = owner_data.owner_id
    //     owner.rawData = {}
    //     owner.thresholds = thresholds
    //     if (Object.keys(topics_counts_data).length === 0) {
    //       owner.failedPreparedFigure = 0
    //       owner.isPrepared = false
    //       owner.rawData.topicsAdded = 0
    //       all_employees.push(owner)
    //       continue
    //     }
    //     let sum = 0.0
    //     // one key represents one type of week [last_week, etc], max is four, min is zero
    //     Object.keys(topics_counts_data).forEach((key) => {
    //       sum += topics_counts_data[key]
    //     })
    //     // average topics over the past four weeks >= 1
    //     if (sum/4 >= thresholds.BenchmarkTopicsCounts) {
    //       owner.isPrepared = true
    //     } else {
    //       owner.isPrepared = false
    //     }
    //     owner.failedPreparedFigure = sum / 4
    //     owner.rawData.topicsAdded = sum
    //     all_employees.push(owner)
    //   }
    // }

    // if (metrics?.coordinated_metrics?.length > 0) {
    //   for (let owner_data of metrics.coordinated_metrics) {
    //     let owner = all_employees.find((employee) => employee.eId == owner_data.owner_id)
    //     // for this owner, avg decision/action per blocker/guidance is at least one
    //     if (owner_data.avg >= thresholds.BenchmarkCoordiatedAvg) {
    //       owner.isCoordinated = true
    //     } else {
    //       owner.isCoordinated = false

    //     }

    //     if (owner_data.avg === 999) {
    //       owner.failedCoordinatedFigure = 0
    //     } else {
    //       owner.failedCoordinatedFigure = owner_data.avg
    //     }

    //     owner.rawData.qualifiedCounts = owner_data.qualified_counts
    //     owner.rawData.numOpenTopics = owner_data.num_open_topics

    //     owner.isOnVacation = false
    //     if(owner_data?.current_future_vacations?.length > 0){
    //       let monday_formatted = getCurrentMondayDate()
    //       if(owner_data.current_future_vacations.includes(monday_formatted)){
    //         owner.isOnVacation = true
    //       }
    //       else{
    //         owner.isOnVacation = false
    //       }
    //     }
    //   }
    // }

    // if (metrics?.task_master?.length > 0) {
    //   for (let owner_data of metrics.task_master) {
    //     let owner = all_employees.find((employee) => employee.eId === owner_data.owner_id)
    //     // More than 40% of actions created in last 4 weeks are closed
    //     if (owner_data.completion_percentage >= thresholds.BenchmarkCompletionPercentage
    //         || owner_data.num_actions_created_past_four_weeks === 0) {
    //       owner.isEnoughActionsClosed = true
    //     } else {
    //       owner.isEnoughActionsClosed = false

    //     }
    //     owner.failedEnoughActionsClosedFigure = parseFloat(
    //       owner_data.completion_percentage
    //     ).toFixed(3)
    //     owner.rawData.numActionsCreated = owner_data.num_actions_created_past_four_weeks_now
    //     owner.rawData.numActionsClosed = owner_data.num_completed

    //     owner.rawData.numActionsCreatedPastFourWeeksNow = owner_data.num_actions_created_past_four_weeks_now
    //     owner.rawData.numCompleted = owner_data.num_completed
    //     owner.rawData.numActionsCreatedPastFourWeeks = owner_data.num_actions_created_past_four_weeks
    //     // Average time to close action is <= 4 weeks
    //     // if (
    //     //   (owner_data.avg_completion_time > 0 &&
    //     //     owner_data.avg_completion_time <= thresholds.BenchmarkActionClosedTime) ||
    //     //   owner_data.num_completed === 0
    //     // ) {
    //     //   owner.isClosingTimeAcceptable = true
    //     // } else {
    //     //   owner.isClosingTimeAcceptable = false
    //     //   owner.failedClosingTimeAcceptableFigure = parseFloat(
    //     //     owner_data.avg_completion_time
    //     //   ).toFixed(3)
    //     // }
    //   }
    // }

    // if(metrics?.kpi_updates?.length > 0){
    //   for(let owner_data of metrics.kpi_updates){
    //     let owner = all_employees.find((employee) => employee.eId === owner_data.owner_id)
    //     if(owner_data.objectives_num === 0 || owner_data.kpi_num === 0){
    //       owner.kpiStatementType = 1
    //     }else{
    //       // user has kpis, if there is any overdue kpi
    //       if((owner_data.kpi_num >0 && owner_data.is_kpi_update_overdue)){
    //         owner.kpiStatementType = 2
    //       // user has kpis, if there is no overdue kpi,
    //       }else if(owner_data.kpi_num >0 && !owner_data.is_kpi_update_overdue){
    //         owner.kpiStatementType = 3
    //           // but there is grey kpi
    //           if(owner_data.has_grey_kpis){
    //             owner.kpiStatementType = 4
    //           }
    //       }
    //     }
    //     owner.rawData.overdueKpiCount = owner_data.overdue_kpi_count
    //   }
    // }

    // all_employees.forEach(
    //   (employee) =>
    //     (employee.isGood =
    //       employee.isPrepared &&
    //       employee.isCoordinated &&
    //       employee.isEnoughActionsClosed &&
    //       (employee.kpiStatementType !== 2))
    // )
    // // first sort by isGood, then by name
    // all_employees.sort((a, b) => {
    //   if (a.isGood !== b.isGood) {
    //     return a.isGood ? 1 : -1
    //   }
    //   return a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    // })

    // all_employees.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
    let all_employees = metrics?.all_employees_status ?? []
    all_employees.forEach((employee) => (employee.thresholds = thresholds))
    all_employees.sort((a, b) => {
      if (a.mei_status.is_good !== b.mei_status.is_good) {
        return a.mei_status.is_good ? 1 : -1
      }
      return a.name.localeCompare(b.name)
    })
    setReportingsMei(all_employees)
  }

  return (
    <div className={isCxo ? 'employee-boxes-cxo' : 'employee-boxes-al-wrap'}>
      {reportingsMei.length > 0 && (
        <>
          {reportingsMei.map((employee) => (
            <EmployeeBox
              key={employee.owner_id}
              employee={employee}
              setIsModalOpen={setIsModalOpen}
              setData={setData}
              isCxo={isCxo}
            />
          ))}
        </>
      )}
    </div>
  )
}

export default EmployeeMeiBoxes

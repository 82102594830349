import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { bool } from 'yup'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getCategoryActionDurationData = async (category) => {
  const { data } = await axios.get(
    `${BASE_URL}/get_blockers_guidances_category_action_duration_details/${category}`,
  )
  return data
}

export default function useGetCategoryActionDurationData(category) {
  return useQuery(
    ['categoryActionDurationData', category],
    () => getCategoryActionDurationData(category),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(category),
    },
  )
}

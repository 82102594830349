import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import MeetingActions from '../../../EmailReminderTab/components/MeetingActions'
import OtherActivities from '../../../EmailReminderTab/components/OtherActivities'
import { getHttpRequest } from '../../../../api/query/dynamicAPI'
import InPageLoader from '../../../../components/InPageLoader'
import { useUpdateContext } from '../../context/updateContext'
import { STATUS, EVENTS } from 'react-joyride'
import { CardContainer, CardContent } from '../../styles'

const UpdateAllMyActions = () => {
  const { t } = useTranslation(['Common', 'UpdateWizard'])
  const [meetingActionsData, setMeetingActionsData] = React.useState([])
  const [otherActivities, setOtherActivities] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [refreshActivitiesData, setRefreshActivitiesData] = React.useState(false)
  const [eIdFilter, setEIdFilter] = React.useState(null)
  const { state, dispatch } = useUpdateContext()

  const tutorialStepsIfAvailable = [
    {
      content: (
        <div style={{ textAlign: 'left' }}>{t('UpdateWizard:joyRideSteps.allMyAction2')}</div>
      ),
      // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'right',
      target: '#email-reminder-meeting-action',
      spotlightPadding: 20,
      disableBeacon: true,
      id: '2',
    },
    {
      content: (
        <div style={{ textAlign: 'left' }}>{t('UpdateWizard:joyRideSteps.allMyAction3')}</div>
      ),
      // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'right',
      target: '#email-reminder-meeting-topic',
      spotlightPadding: 20,
      id: '3',
    },
    {
      content: (
        <div style={{ textAlign: 'left' }}>{t('UpdateWizard:joyRideSteps.allMyAction4')}</div>
      ),
      // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'right',
      target: '#email-reminder-meeting-checkbox',
      spotlightPadding: 20,
      id: '4',
    },
    {
      content: (
        <div style={{ textAlign: 'left' }}>{t('UpdateWizard:joyRideSteps.allMyAction5')}</div>
      ),
      // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'right',
      target: '#email-reminder-meeting-dropdown',
      spotlightPadding: 20,
      id: '5',
    },
  ]

  React.useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        let url =
          eIdFilter === null
            ? '/get_reminder_email_actions'
            : `/get_reminder_email_actions/${eIdFilter}`
        let response = await getHttpRequest(url)
        setMeetingActionsData(response)
        setOtherActivities(response)
        setLoading(false)
      } catch (error) {}
    })()
  }, [refreshActivitiesData, eIdFilter])

  const startTutorial = (shouldStart) => {
    if (state.isTutorialOff) return
    if (!state.joyRide.isToursActive[0]) return

    if (!shouldStart) {
      // setTutorialSpecificState(!shouldStart)
      dispatch({
        type: 'SET_IS_TOUR_ACTIVE',
        isTourActive: false,
        tourIndex: 0,
      })
    } else {
      dispatch({
        type: 'UPDATE_TOUR_STEPS',
        tourSteps: tutorialStepsIfAvailable,
      })
      dispatch({
        type: 'UPDATE_TOUR_STEP_NAME',
        stepName: 'allMyActions',
      })
      dispatch({
        type: 'UPDATE_TOUR_CALLBACK',
        customJoyRideCallBackFn: handleJoyrideCallback,
      })
    }
    dispatch({
      type: 'UPDATE_TOUR_STEP_INDEX',
      tourStepIndex: 0,
    })
  }

  const refreshPendingListData = () => {
    setRefreshActivitiesData((prev) => !prev)
  }

  if (loading) {
    return <InPageLoader inComponent={true} />
  }

  const handleJoyrideCallback = (data) => {
    const { type, status, index, action } = data
    if (
      status === STATUS.FINISHED ||
      status === STATUS.SKIPPED ||
      type === EVENTS.TARGET_NOT_FOUND
    ) {
      dispatch({
        type: 'UPDATE_TOUR_INNER_STEP_INDEX',
        tourInnerStepIndex: 0,
      })
      dispatch({
        type: 'SET_IS_TOUR_ACTIVE',
        isTourActive: false,
        tourIndex: 0,
      })
    } else if (type === EVENTS.STEP_AFTER) {
      let ind = index + (action === 'prev' ? -1 : 1)
      dispatch({
        type: 'UPDATE_TOUR_INNER_STEP_INDEX',
        tourInnerStepIndex: ind,
      })
    }
  }

  return (
    <>
      <div className="wizard-step">
        <h2 className="wizard-step__title" style={{ margin: '1rem 0 1rem 0' }}>
          {t('emailReminder.title')}
        </h2>

        {!state.joyRide.isTutorialOff &&
        meetingActionsData?.actions?.length === 0 &&
        otherActivities?.activities?.length === 0 ? (
          <CardContainer>
            <CardContent>
              <div>{t('UpdateWizard:allMyActions.noActionsMsg')}</div>
            </CardContent>
          </CardContainer>
        ) : (
          <>
            <div className="wizard-step__overview">
              <div className="wizard-step__overview__paragraph">
                {t('emailReminder.myActionsDescription')}
              </div>
            </div>
            {/* the background-color here is very important for JoyrideComponent to work... */}
            <div
              className="wizard-step__main-content-container"
              style={{ padding: '1rem', backgroundColor: 'white' }}
            >
              <div className="objectivesTeamMapping">
                <>
                  <div style={{ paddingBottom: '10px' }}>
                    <MeetingActions
                      data={meetingActionsData}
                      refreshPendingListData={refreshPendingListData}
                      printMode={false}
                      startTutorial={startTutorial}
                    />
                    <OtherActivities
                      data={otherActivities}
                      refreshPendingListData={refreshPendingListData}
                      printMode={false}
                    />
                  </div>
                </>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default UpdateAllMyActions

import React, { useState, useEffect } from 'react'
import { getTeamLeaderList } from '../../../api/services/employee.services'
import InPageLoader from '../../InPageLoader'
import PlusIcon from '../../../assets/plus.svg'
import deleteCross from '../../../assets/delete-cross.svg'
import Modal from '../../Modal'

const AddCoOwnerModal = ({ contextHandler }) => {
  const { state, dispatch } = contextHandler()
  const [teamLeaderList, setTeamLeaderList] = useState([])
  const [newTeamLeaderList, setNewTeamLeaderList] = useState([])
  const [newCoowner, setNewCoowner] = useState('')
  const [newCapacity, setNewCapacity] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [coownersList, setCoownersList] = useState()
  const [displayError, setDisplayError] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [capacityRequred, setCapacityRequired] = React.useState(0)
  const { data, allocSubWiz } = state
  const updateObjectiveId = allocSubWiz.updateObjectiveId
  const [loggedInId, setLoggedInId] = React.useState(0)
  const loggedInName = localStorage.getItem('tfmnm')

  useEffect(() => {
    getLeaderList()
  }, [])

  async function getLeaderList() {
    setIsLoading(true)
    const result = await getTeamLeaderList('?level=3')
    setIsLoading(false)
    if (result && result?.employees) {
      setTeamLeaderList(result?.employees)
      const { objectives } = data
      const currObjective = objectives.find((o) => o.id === updateObjectiveId)
      setCapacityRequired(currObjective?.missingFte * 100)
      const list =
        currObjective &&
        currObjective.pendingTransferList &&
        currObjective.pendingTransferList.map((u) => u)
      setCoownersList(list || [])
      const coownerEIds = list.map((coowner) => coowner.eId)
      const addTeamLeadersList = result?.employees.filter(
        (teamLeader) => !coownerEIds.includes(teamLeader.eId),
      )
      setNewTeamLeaderList(addTeamLeadersList)
      setLoggedInId(result?.loggedInEId)
      if (coownerEIds.some((x) => x === result?.loggedInEId)) {
        setNewCoowner({ eId: addTeamLeadersList[0].eId, name: addTeamLeadersList[0].name })
      } else {
        setNewCoowner({ eId: result?.loggedInEId, name: loggedInName })
      }
    }
  }
  if (isLoading) {
    return (
      <>
        {' '}
        <InPageLoader />{' '}
      </>
    )
  }

  const handleNewCoOwnerValueChange = (event) => {
    setDisplayError(false)
    const selectedOption = event.target.options[event.target.selectedIndex]
    setNewCoowner({ eId: selectedOption.value, name: selectedOption.label })
  }

  const handleNewCapacityChanged = (event) => {
    setDisplayError(false)
    setNewCapacity(event.target.value)
  }

  const handleNewCoownerAdd = () => {
    setDisplayError(false)
    if (newCapacity == '' || newCapacity <= 0) {
      setErrorMessage('Invalid Value')
      setDisplayError(true)
    } else {
      const addCoowner = {
        eId: newCoowner.eId != 0 ? parseInt(newCoowner.eId) : loggedInId,
        name: newCoowner.name != undefined ? newCoowner.name : loggedInName,
        allocation: parseInt(newCapacity),
      }
      const updatedTeamLeaderList = newTeamLeaderList.filter((tl) => tl.eId !== addCoowner.eId)
      setNewTeamLeaderList(updatedTeamLeaderList)
      const coownersListContainsLoggedInId = updatedTeamLeaderList.some(
        (coowner) => coowner.eId === loggedInId,
      )
      setCoownersList([...coownersList, addCoowner])
      if (coownersListContainsLoggedInId) {
        setNewCoowner({ eId: loggedInId, name: loggedInName })
      } else {
        setNewCoowner({ eId: updatedTeamLeaderList[0].eId, name: updatedTeamLeaderList[0].name })
      }

      setNewCapacity('')
    }
  }
  const handleSave = () => {
    setDisplayError(false)
    const sum = coownersList.reduce((accumulator, currentValue) => {
      return accumulator + Number(currentValue.allocation)
    }, 0)
    if (sum !== capacityRequred) {
      setErrorMessage('Total Requested should be equal to ' + capacityRequred)
      setDisplayError(true)
    } else {
      const clonedObjectives = JSON.parse(JSON.stringify(state?.data?.objectives))
      clonedObjectives.map((objective) => {
        if (objective.id === updateObjectiveId) {
          objective.pendingTransferList = coownersList.filter(
            (coowner) => Number(coowner.allocation) !== 0,
          )
          objective.isExistingPendingTransferList = false
        }
        return objective
      })
      dispatch({ type: 'UPDATE_OBJECTIVES', objectives: clonedObjectives })
      dispatch({ type: 'ALLOC_SUB_WIZ_CLOSE_COOWNER_MODAL' })
    }
    // setNewCoowner("")
    setNewCapacity('')
  }

  const handleRemoveExistingCoowner = (event, data, indexToRemove) => {
    setNewTeamLeaderList((prevList) => [...prevList, data])
    setCoownersList((prevList) => prevList.filter((item, index) => index !== indexToRemove))
    if (data.eId == loggedInId) {
      setNewCoowner({ eId: loggedInId, name: loggedInName })
    } else {
      setNewCoowner({ eId: newTeamLeaderList[0].eId, name: newTeamLeaderList[0].name })
    }
  }

  const handleExistingItemChanged = (event, data) => {
    setDisplayError(false)
    let updatedCoOwner = []
    if (event.target.name === 'capacity') {
      updatedCoOwner = { ...data, allocation: event.target.value }
    } else {
      const selectedOption = event.target.options[event.target.selectedIndex]
      updatedCoOwner = { ...data, eId: parseInt(selectedOption.value), name: selectedOption.label }
    }
    const updatedList = coownersList.map((t) => (t.eId === data?.eId ? updatedCoOwner : t))
    setCoownersList(updatedList)
  }
  return (
    <>
      <Modal
        title={'Select Team'}
        closeModal={() => {
          dispatch({ type: 'ALLOC_SUB_WIZ_CLOSE_COOWNER_MODAL' })
        }}
        width="700px"
      >
        <div className="form-field action-field form-field action-field--kanban action-field--add-subtask">
          <span class="widget-grid-title" style={{ justifyContent: 'flex-start' }}>
            Team Leader
          </span>
          <span
            class="widget-grid-title"
            style={{ justifyContent: 'flex-start', paddingRight: '0.7rem' }}
          >
            Capacity Required
          </span>
          <span></span>
        </div>
        {coownersList?.map((data, index) => (
          <div key={index} className="form-field action-field form-field action-field--kanban">
            <div>
              <div
                key={index}
                className="form-field action-field form-field action-field--kanban action-field--add-subtask"
              >
                <select
                  as="coowner"
                  name="coowner"
                  className="custom-select"
                  value={data?.eId}
                  onChange={(event) => handleExistingItemChanged(event, data)}
                  disabled
                >
                  {teamLeaderList.map((list) => (
                    <option key={list.eId} value={list.eId} label={list.name} />
                  ))}
                </select>
                <div style={{ display: 'flex' }}>
                  <input
                    id="capacity"
                    name="capacity"
                    autoComplete="off"
                    className="custom-input"
                    type="number"
                    min="0"
                    value={data?.allocation}
                    onChange={(event) => handleExistingItemChanged(event, data)}
                  />
                  <div style={{ marginTop: '0.5rem' }}>%</div>
                </div>
                <button
                  className="add-btn-comment"
                  type="button"
                  onClick={(event) => handleRemoveExistingCoowner(event, data, index)}
                  style={{ height: '2rem', width: '0.5rem', marginTop: '0.1rem' }}
                >
                  <img src={deleteCross} alt="" style={{ width: '10px' }} />
                </button>
              </div>
            </div>
          </div>
        ))}

        <div style={{ width: '100%' }}>
          <div className="form-field action-field form-field action-field--kanban action-field--add-subtask">
            <select
              as="coowner"
              name="coowner"
              className="custom-select"
              value={newCoowner.eId}
              onChange={handleNewCoOwnerValueChange}
            >
              {newTeamLeaderList.map((data) => (
                <option
                  key={data.eId}
                  value={data.eId}
                  label={data.name}
                  selected={data.eId === loggedInId}
                />
              ))}
            </select>
            <div style={{ display: 'flex' }}>
              <input
                id="capacity"
                name="capacity"
                autoComplete="off"
                className="custom-input"
                type="number"
                min="0"
                value={newCapacity}
                onChange={handleNewCapacityChanged}
              />
              <div style={{ marginTop: '0.5rem' }}>%</div>
            </div>
            <button
              className="add-btn-comment"
              type="button"
              onClick={handleNewCoownerAdd}
              style={{ height: '2rem', width: '0.5rem', marginTop: '0.1rem' }}
            >
              <img src={PlusIcon} alt="" style={{ width: '10px' }} />
            </button>
          </div>
        </div>
        {displayError && (
          <div className="validation-error" style={{ paddingLeft: '0.5rem', marginTop: '-1rem' }}>
            {errorMessage}
          </div>
        )}
        <div className="modal-dual-btn">
          <button
            className="dual-btn negative-btn"
            onClick={() => dispatch({ type: 'ALLOC_SUB_WIZ_CLOSE_COOWNER_MODAL' })}
            type="submit"
          >
            CANCEL
          </button>
          <button className="dual-btn positive-btn" type="button" onClick={handleSave}>
            SAVE
          </button>
        </div>
      </Modal>
    </>
  )
}
export default AddCoOwnerModal

import { useQuery, useQueries } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getGovernanceStatusData = async () => {
  const { data } = await axios.get(`${BASE_URL}/gov_status_data`)
  return data
}

export default function useGovernanceStatusData() {
  return useQuery(['gov_status_cycle'], () => getGovernanceStatusData(), {
    refetchOnWindowFocus: false,
  })
}

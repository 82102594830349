import React, { useState, useEffect } from 'react'
import useObjectiveDataList from '../../api/query/getListObjectiveData'
import './styles.css'
import SelectSingleField from '../CustomMultipleSelect/singleOption'
import { transferActivity } from '../../api/services/activities.services'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const TransferActivity = ({ objectiveId, activityId, closeModal, showTransferObj = false }) => {
  const { t } = useTranslation(['Common'])
  const navigate = useNavigate()
  const currentLocation = useLocation()
  const { data } = useObjectiveDataList()
  const [objectives, setObjectives] = useState([])

  const [selectedSort, setSelectedSort] = useState('')

  const [error, setError] = useState('')

  useEffect(() => {
    setObjectives(data?.objectives ? data.objectives : [])
  }, [data?.objectives])

  const onSubmit = async () => {
    if (!selectedSort) {
      setError('Select Objective Required !')
    } else {
      setError('')
    }
    if (objectiveId != selectedSort) {
      const formData = {
        oldObjId: objectiveId,
        newObjId: selectedSort,
        activityId,
      }

      const result = await transferActivity(formData)
      if (result) {
        closeModal()
        //Replace this when we find a permanent solution, temporary hack for now

        localStorage.setItem('hrefReason_4A', '0x4A')
        window.location.href = currentLocation.pathname
      }
    }
  }

  function inputChangedHandler(e, data) {
    setSelectedSort(e?.id)
  }

  return (
    <>
      {showTransferObj ? (
        <>
          <div className="form-field action-field form-field action-field--kanban filter_box_custom">
            <label>{t('Common:addEditActivityModal.transferToAnotherObjective')}</label>
            <div style={{ display: 'flex' }}>
              <div className="filter_date_box">
                <SelectSingleField
                  placeholder={t('formPlaceHolder.selectObjectives')}
                  name="selectObjective"
                  value={objectives.filter((item) => item.id == selectedSort)}
                  options={objectives}
                  getOptionLabel={(option) => option.statement}
                  getOptionValue={(option) => option.id}
                  isClearable={true}
                  onChange={inputChangedHandler}
                />
                {error ? <span className="error error-add-commnet">{error}</span> : null}
              </div>
              <div
                className="add-comment"
                style={{ marginLeft: '2rem', display: 'flex', justifyContent: 'flex-end' }}
              >
                <div
                  className={'fitted-button blue'}
                  style={{ width: '5rem' }}
                  onClick={() => onSubmit()}
                >
                  {t('Common:addEditActivityModal.transfer')}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </>
  )
}
export default TransferActivity

import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import troyCircle from '../../assets/troy_circle.png'

const ChatBox = ({ messageList, isLoading, undoFn = () => {} }) => {
  const bottomRef = useRef(null)
  const containerRef = useRef(null)
  const [typingText, setTypingText] = useState('')
  const [typingIndex, setTypingIndex] = useState(-1)
  const speed = 15
  const { t } = useTranslation(['Common'])
  const charIndex = useRef(-1)

  useEffect(() => {
    const container = containerRef.current
    const contentHeight = container.scrollHeight
    const containerHeight = container.clientHeight

    if (contentHeight > containerHeight && bottomRef.current) {
      container.scrollTo({ top: container.scrollHeight, behavior: 'smooth' })
    }
  }, [messageList, isLoading, typingText])

  // useEffect(() => {
  //   if (
  //     typingIndex >= 0 &&
  //     typingIndex < messageList?.length &&
  //     !messageList[typingIndex].user_message && messageList[typingIndex].assistant_message
  //   ) {
  //     const fullText =
  //       messageList[typingIndex].assistant_message
  //     // let charIndex = -1
  //     const timer = setInterval(() => {
  //       if (charIndex.current < fullText?.length) {
  //         // charIndex++
  //         charIndex.current++
  //         setTypingText((prevText) => prevText + fullText[charIndex.current])
  //       } else {
  //         clearInterval(timer)
  //         setTypingIndex(-1)
  //         charIndex.current = -1
  //       }
  //     }, speed)

  //     return () => {
  //       clearInterval(timer)
  //     }
  //   }
  // }, [typingIndex, messageList])

  // useEffect(() => {
  //   if (messageList?.length > 0 && !messageList[messageList?.length - 1]?.user_message) {
  //     if(charIndex.current === -1){
  //       setTypingIndex(messageList.length - 1)
  //       setTypingText('')
  //     }
  //   }
  // }, [messageList])

  const [activeDot, setActiveDot] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveDot((prevDot) => (prevDot + 1) % 3)
    }, 300)

    return () => clearInterval(interval)
  }, [])

  const dotStyle = {
    fontWeight: 'bold',
    opacity: 0,
    transition: 'opacity 0.2s',
  }

  const activeDotStyle = {
    ...dotStyle,
    opacity: 1,
  }

  const resetToRound = (index) => {
    messageList.length = index
    undoFn((index - 1) / 2 + 1)
  }

  const BotAvatar = () => (
    <div className="bot-avatar">
      <div className="bot-avatar-initial">
        <img src={troyCircle} style={{ width: '1.8rem', height: '1.8rem' }} />
      </div>
    </div>
  )

  const BotName = () => (
    <div className="bot-name-container">
      <span className="bot-name">Odin</span>
      <span className="bot-label">Consultant</span>
    </div>
  )

  return (
    <div className="message-container" ref={containerRef}>
      {messageList?.length > 0 && messageList[0]['user_message'] != '' && (
        <>
          {messageList?.map((messageBubble, index) => {
            if (messageBubble.user_message) {
              return (
                <>
                  <div key={index} className="message-bubble user-bubble">
                    <span>{messageBubble.user_message}</span>
                  </div>

                  <div style={{ marginLeft: 'auto', marginTop: '-6px' }}>
                    <button
                      type="button"
                      className="btn-card text-box-grey-btn"
                      style={{ padding: '0', width: '4.2rem', backgroundColor: '#4472c4' }}
                      onClick={() => resetToRound(index)}
                    >
                      Revert
                    </button>
                  </div>
                </>
              )
            } else if (messageBubble.assistant_message) {
              return (
                <div key={index} className="bot-message-container">
                  <BotAvatar />
                  <div className="bot-content">
                    <BotName />
                    <div className="message-bubble bot-bubble">
                      <span>
                        {index === typingIndex ? typingText : messageBubble.assistant_message}
                      </span>
                    </div>
                  </div>
                </div>
              )
            }
          })}
          <span ref={bottomRef}></span>
          {(isLoading || messageList[0]['user_message'] === '') && (
            <div className="bot-message-container">
              <BotAvatar />
              <div className="bot-content">
                <BotName />
                <div className="message-bubble bot-bubble" style={{ minWidth: '50px' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {[0, 1, 2].map((index) => (
                      <span key={index} style={index === activeDot ? activeDotStyle : dotStyle}>
                        {'\u2022'}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default ChatBox

import React, { useState, useEffect } from 'react'
import { Formik, Field, Form } from 'formik'

import SelectField from '../../components/CustomMultipleSelect/customSelect'
import AddComment from './component/AddComment/addComment'
import ListComment from './component/listComment/listComment'
import Modal from '../../components/Modal/index'

import { getHttpRequest, postHttpRequest } from '../../api/query/dynamicAPI'

import InPageLoader from '../../components/InPageLoader/index'

import { getObjectivUserList } from '../../api/services/employee.services'
import {
  getSingleActivityById,
  createorUpdateActivity,
} from '../../api/services/activities.services'
import {
  CREATE_TASK,
  GET_TEAM_MEMBERS_FOR_ACTIVITY,
  GET_SINGLE_ACTIVITY,
  DELETE_ACTIVITY,
} from '../../api/constant/route'

import BasicShema from './schemas/index'

import { activityStatus } from '../../utils/activityStatus'
import { dateFilter } from '../../utils/time'
import useValidateGoal from '../../api/mutation/useValidateGoal'

import './style.scss'
import SelectSingleField from '../../components/CustomMultipleSelect/singleOption'
import { getGoalCustomTemplate } from '../../api/services/customTemplate.service'
import { customFieldList } from '../../utils/activityStatus'
import validate from './schemas/customValidation'
import OldTagInput from '../../components/OldTagInput'

const options = []

const initialValues = {
  title: '',
  status: activityStatus[0].value,
  dueDate: '',
  assign: [],
}

const CreateTask = ({ objectiveId, view, setModelView, taskId, setIsRefreshObjective }) => {
  // const { state, dispatch } = useDemandIntakeContext()
  const [formValues, setFormValues] = useState(initialValues)
  const [userList, setUserList] = useState(options)
  const [userDefinedListsContent, setUserDefinedListsContent] = useState({})
  const [dynamicListsContent, setDynamicListsContent] = useState({})
  const [userDefinedListsItemKeys, setUserDefinedListsItemKeys] = useState({})
  const [defaultForActivityNameKey, setDefaultForActivityNameKey] = useState(-1)

  const [commentList, setCommentList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const mutationValidateGoal = useValidateGoal()
  const [errorMessage, setErrorMessage] = React.useState(null)

  const [tags, setTags] = React.useState([])

  const [mandatory, setMandatoryField] = useState([])
  const [customFieldData, setCustomFieldData] = useState([])
  const [customFormValues, setCustomFormValue] = useState([])
  const [validationErrors, setValidationErrors] = useState({})

  const errorCheck = async (values, resetForm) => {
    await mutationValidateGoal.mutateAsync(`${values.title}`, {
      onSuccess: (response) => {
        if (response.is_valid === true) {
          values.isValid = true
          setErrorMessage(null)
          onSubmit(values)
        } else {
          setErrorMessage('Activity Name must start with a verb')
        }
      },
    })
  }
  const onSubmit = async (values) => {
    const postObj = values
    postObj.objectiveId = objectiveId
    if (taskId) {
      postObj.milestoneId = taskId
    } else {
      postObj.commentList = commentList
    }
    postObj.tags = [...tags]

    setIsLoading(true)
    const result = await createorUpdateActivity(postObj)
    setIsLoading(false)
    if (result) {
      setIsRefreshObjective((old) => !old)
      setModelView(false) // xxx
      return
    }
  }

  useEffect(() => {
    if (taskId) {
      getObjectiveTask()
    }
  }, [taskId])

  useEffect(() => {
    getUserList()
    getCustomTemplate()
  }, [])

  const getCustomTemplate = async () => {
    setIsLoading(true)
    const result = await getGoalCustomTemplate(objectiveId)
    setIsLoading(false)

    if (result.template_data?.customFieldsSchema?.customFields) {
      // Translate custom fields to a list for backward compatability, and order them by the given
      // order.
      let tempCustomFieldList = []

      for (let k of result.template_data.customFieldsSchema.orderedCustomFields) {
        tempCustomFieldList.push(result.template_data.customFieldsSchema.customFields[k])
      }
      // for (let k of Object.keys(result.customFieldsSchema.customFields)) {
      //   tempCustomFieldList.push(result.customFieldsSchema.customFields[k])
      // }
      // setCustomFieldData(result.customFieldsSchema.customFields);
      setCustomFieldData(tempCustomFieldList)

      // Also load the user defined lists that came in the response.
      let tmpUserDefinedListsContent = {}
      let tmpUserDefinedListsItemKeys = {}
      for (let udl of result.udls?.list ?? []) {
        tmpUserDefinedListsContent[udl.listId] = udl.listItems
        let ll = []
        for (let x of udl.listItems) {
          ll.push(x.itemKey)
        }
        tmpUserDefinedListsItemKeys[udl.listId] = ll
      }
      let tmpDynamicListsContent = {}
      let tmpDynamicListsItemKeys = {}
      for (let dl of result.dls?.list ?? []) {
        tmpDynamicListsContent[dl.listId] = dl.listItems
        let ll = []
        for (let x of dl.listItems) {
          ll.push(x.itemKey)
        }
        tmpDynamicListsItemKeys[dl.listId] = ll
      }
      setDynamicListsContent(tmpDynamicListsContent)
      setUserDefinedListsContent(tmpUserDefinedListsContent)
      setUserDefinedListsItemKeys(tmpUserDefinedListsItemKeys)

      setDefaultForActivityNameKey(
        result.template_data.customFieldsSchema.defaultForActivityNameKey,
      )
    } else {
    }
  }

  async function getObjectiveTask() {
    setIsLoading(true)
    const result = await getSingleActivityById(taskId)
    setIsLoading(false)
    if (result) {
      // FOR TESTING Purpose DEV team comment code.
      const obj = {
        title: result.description,
        status: result.progress,
        dueDate: result.strDueDate,
        assign: result.assigneeList,
      }

      if (result.fields && result.fields.length > 0) {
        setCustomFormValue(result.fields)
      }
      setFormValues(obj)
      if (Array.isArray(result.tags)) {
        setTags([...result.tags])
      }
    }
  }

  async function getUserList() {
    const result = await getObjectivUserList(objectiveId)
    if (result?.employees) setUserList(result.employees)
  }

  const renderType = (data) => {
    const searchIndex = customFieldList.findIndex((x) => x.type == data)
    return customFieldList[searchIndex].value
  }

  const renderValue = (fieldId) => {
    const index = customFormValues.findIndex((x) => x.fieldId == fieldId)

    if (index < 0) {
      return ''
    } else {
      const value = customFormValues[index].value
      const fieldId = customFormValues[index].fieldId

      const filedIndex = customFieldData.findIndex((x) => x.fieldId == fieldId)

      const fieldObject = customFieldData[filedIndex]

      if (fieldObject.fieldType === 4) {
        let ll = userDefinedListsContent[fieldObject.userDefinedListId]
        let selectedVals = []

        let obj = null
        try {
          obj = JSON.parse(customFormValues[index].value)
        } catch (e) {
          obj = customFormValues[index].value
        }

        // for (let selectedItemKey of obj) {
        for (let selectedItem of obj) {
          for (let x of ll) {
            // if (selectedItemKey === x.itemKey)
            if (typeof selectedItem === 'number') {
              if (selectedItem === x.itemKey) selectedVals.push(x)
            } else {
              if (selectedItem.itemKey === x.itemKey) selectedVals.push(x)
            }

            // selectedVals.push(x.itemKey)
          }
        }

        return selectedVals
      } else if (fieldObject.fieldType === 5) {
        return customFormValues[index].value === true || customFormValues[index].value === 'True'
      } else {
        return customFormValues[index].value
      }
    }
  }

  const getOptionLabelFromKey = (key, userDefinedListId) => {
    const index = userDefinedListsContent[userDefinedListId].findIndex((x) => x.itemKey == key)
    return userDefinedListsContent[userDefinedListId][index].itemLabelStr
  }

  const renderField = (data, index) => {
    if (data.fieldType == 4 || data.fieldType == 6) {
      return (
        <div className="form-field action-field form-field action-field--kanban" key={index}>
          <label>{data.name}</label>
          <SelectSingleField
            placeholder="select"
            name={`${data.fieldId}`}
            value={renderValue(data.fieldId)}
            // options={data.options}
            options={
              data.fieldType == 4
                ? userDefinedListsContent[data.userDefinedListId]
                : dynamicListsContent[data.dynamicListId]
            }
            // options={userDefinedListsItemKeys[data.userDefinedListId]}
            getOptionLabel={(option) => option.itemLabelStr}
            // getOptionLabel={(option) => getOptionLabelFromKey(option, data.userDefinedListId)}
            getOptionValue={(option) => option.itemKey}
            // getOptionValue={(option) => option}
            onChange={inputChangedHandlerCustomValue}
            isMulti
            isClearable
            width={'100%'}
          />
          <span className="validation-error">{validationErrors[data.fieldId]}</span>
        </div>
      )
    } else if (data.fieldType == 5) {
      return (
        <div className="form-field action-field form-field action-field--kanban" key={index}>
          <label>{data.name}</label>
          <input
            type={renderType(data.fieldType)}
            id={`${data.fieldId}`}
            name={`${data.fieldId}`}
            placeholder="Add Value"
            autoComplete="off"
            checked={renderValue(data.fieldId)}
            onChange={inputChangedHandlerCustomValue}
            className={`custom-input`}
          />
          <span className="validation-error">{validationErrors[data.fieldId]}</span>
        </div>
      )
    } else {
      return (
        <div className="form-field action-field form-field action-field--kanban" key={index}>
          <label>{data.name} </label>
          <input
            type={renderType(data.fieldType)}
            id={`${data.fieldId}`}
            name={`${data.fieldId}`}
            placeholder="Add Value"
            autoComplete="off"
            value={renderValue(data.fieldId)}
            onChange={inputChangedHandlerCustomValue}
            className={`custom-input`}
            onWheel={(e) => e.target.blur()}
          />
          <span className="validation-error">{validationErrors[data.fieldId]}</span>
        </div>
      )
    }
  }

  function updateFormHandler(name, value) {
    setFormValues((prevState) => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  function inputChangedHandler(e, data) {
    // Get name of changed input, and its corresponding value
    let name
    let value

    if (data) {
      if (data.name == 'status') {
        name = data.name
        value = e.value
      } else if (data.name == 'assign') {
        name = data.name
        value = e
      }
    } else {
      name = e.target.name
      value = e.target.value
    }

    updateFormHandler(name, value)
  }

  function inputChangedHandlerCustomValue(e, data) {
    let fieldId
    let value
    if (data) {
      fieldId = data.name
      value = e
    } else if (e.target.value !== undefined && e.target.value != 'on') {
      fieldId = e.target.name
      value = e.target.value
    } else {
      fieldId = e.target.name
      value = e.target.checked
    }

    const temp_Obj = { fieldId, value }
    const temp_value = [...customFormValues]

    const index = temp_value.findIndex((x) => x.fieldId == fieldId)

    if (index < 0) {
      temp_value.push(temp_Obj)
    } else {
      temp_value[index] = temp_Obj
    }
    setCustomFormValue(temp_value)
  }

  const onCustomSubmit = async () => {
    const postObj = formValues

    postObj.objectiveId = objectiveId
    if (taskId) {
      postObj.milestoneId = taskId
    }

    postObj.fields = [...customFormValues]
    postObj.tags = [...tags]

    for (let y = 0; customFormValues.length > y; y++) {
      const obj = customFormValues[y]

      postObj[obj.fieldId] = obj
    }
    let RequiredData = []

    RequiredData.push({ fieldId: 'status', name: 'Status' })

    const mandatoryData = mandatory.filter((e) => e.required == true)
    RequiredData = RequiredData.concat(mandatoryData)

    const customData = customFieldData.filter((e) => e.required == true)
    RequiredData = RequiredData.concat(customData)

    const errors = validate(postObj, RequiredData)

    if (Object.keys(errors).length > 0) {
      setValidationErrors({ ...errors })
      return
    } else {
      setValidationErrors({})
    }

    const indexOfDefault = customFieldData.findIndex((x) => x.fieldId === defaultForActivityNameKey)
    const activityTitle = customFieldData[indexOfDefault]
    const indexOfDefaultInValues = customFormValues.findIndex(
      (x) => x.fieldId == activityTitle.fieldId,
    )

    //
    if (Array.isArray(customFormValues[indexOfDefaultInValues].value)) {
      let elems = []
      for (let entry of customFormValues[indexOfDefaultInValues].value) {
        elems.push(entry.label)
      }
      elems.sort()
      postObj.title = elems.join(', ')
    } else {
      postObj.title = '' + customFormValues[indexOfDefaultInValues].value + ''
    }

    setIsLoading(true)
    const result = await createorUpdateActivity(postObj)
    setIsLoading(false)
    if (result.success) {
      setIsRefreshObjective((old) => !old)
      setModelView(false)
      return
    }
  }

  function deleteActivity() {
    if (window.confirm(`Are you sure you want to delete this activity?`)) {
      setIsLoading(true)
      postHttpRequest(`${DELETE_ACTIVITY}/${taskId}`)
        .then((response) => {
          setIsLoading(false)
          if (response) {
            setIsRefreshObjective((old) => !old)
            setModelView(false)
            return
          } else {
          }
        })
        .catch((error) => {
          setIsLoading(false)
        })
    }
  }

  function onKeyDown(keyEvent) {
    if (keyEvent.key === 'Enter') {
      keyEvent.preventDefault()
    }
  }

  return (
    <div className="kanban-modal">
      <Modal
        title={taskId ? 'Edit Activity' : 'Add Activity'}
        isModalOpen={view}
        data-testid="Add Objective"
        closeModal={() => setModelView(false)}
        width="700px"
      >
        {isLoading ? (
          <InPageLoader />
        ) : (
          customFieldData.length == 0 && (
            <Formik
              initialValues={formValues}
              validationSchema={BasicShema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ values, errors, touched, resetForm, setFieldValue, validateForm }) => (
                <Form className="objective-form">
                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <div
                      className="column-input-objective"
                      style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                    >
                      <div className="form-field action-field form-field action-field--kanban">
                        <label>Title</label>
                        <Field
                          id="title"
                          name="title"
                          placeholder="Add an activity name ..."
                          autoComplete="off"
                          value={values.title}
                          className={` ${
                            errors.title && touched.title ? 'input-error' : ''
                          }  custom-input`}
                        />
                        {errors.title && touched.title && (
                          <span className="validation-error">{errors.title}</span>
                        )}
                      </div>

                      <div className="form-field action-field form-field action-field--kanban">
                        <label>Status</label>
                        <Field as="select" name="status" className="custom-select">
                          {activityStatus.map((data) => (
                            <option key={data.value} value={data.value} label={data.label} />
                          ))}
                        </Field>
                      </div>

                      <div className="row-kanban-inputs">
                        <div className="form-field action-field ">
                          <label>Due Date</label>
                          <Field
                            type="date"
                            id="dueDate"
                            name="dueDate"
                            autoComplete="off"
                            className={` ${
                              errors.dueDate && touched.dueDate ? 'input-error' : ''
                            }  custom-input`}
                          />
                          {errors.dueDate && touched.dueDate && (
                            <span className="validation-error">{errors.dueDate}</span>
                          )}
                        </div>

                        <div className="form-field action-field form-field action-field--kanban">
                          <label>Assign To</label>
                          <Field {...SelectField} name="assign">
                            {({ field }) => (
                              <SelectField
                                field={field}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.eId}
                                options={userList}
                                isMulti={true}
                                isClearable={true}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                      <div className="form-field">
                        <label>Tags</label>
                        <OldTagInput existingTags={tags} setTagsExternally={setTags} />
                      </div>
                    </div>
                  </div>

                  <div className="comment-structure form-field action-field--kanban">
                    {commentList.map((comment, index) => (
                      <ListComment key={index} comment={comment} />
                    ))}
                  </div>

                  <div className="modal-dual-btn">
                    <button
                      className="dual-btn negative-btn"
                      onClick={() => {
                        resetForm()
                        setModelView(false)
                        setCommentList([])
                      }}
                      type="submit"
                    >
                      CANCEL
                    </button>
                    {taskId != undefined && taskId != 0 && (
                      <button
                        className="dual-btn positive-btn"
                        onClick={() => {
                          deleteActivity()
                        }}
                        type="button"
                        data-testid="delete-btn"
                      >
                        DELETE
                      </button>
                    )}
                    <button className="dual-btn positive-btn" type="submit">
                      {taskId ? 'UPDATE' : 'SAVE'}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )
        )}

        {!isLoading && customFieldData.length > 0 && (
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div
              className="column-input-objective"
              style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
            >
              <div className="form-field action-field form-field action-field--kanban">
                <label>Status</label>

                <SelectSingleField
                  placeholder="select"
                  name="status"
                  value={activityStatus.filter((item) => item.value == formValues.status)}
                  options={activityStatus}
                  onChange={inputChangedHandler}
                  width={'100%'}
                />
                <span className="validation-error">{validationErrors.status}</span>
              </div>

              <div className="row-kanban-inputs">
                <div className="form-field action-field ">
                  <label>Due Date</label>
                  <input
                    type="date"
                    id="dueDate"
                    name="dueDate"
                    autoComplete="off"
                    value={formValues.dueDate}
                    onChange={inputChangedHandler}
                    className={`custom-input`}
                  />
                  <span className="validation-error">{validationErrors.dueDate}</span>
                </div>

                <div className="form-field action-field form-field action-field--kanban">
                  <label>Assign To</label>
                  <SelectSingleField
                    placeholder="select"
                    name="assign"
                    value={formValues.assign}
                    options={userList}
                    onChange={inputChangedHandler}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.eId}
                    isMulti={true}
                    isClearable={true}
                    width={'100%'}
                  />
                  <span className="validation-error">{validationErrors.assign}</span>
                </div>
              </div>
              <div className="form-field">
                <label>Tags</label>
                <OldTagInput existingTags={tags} setTagsExternally={setTags} />
              </div>
              {customFieldData.map((data, index) => {
                return renderField(data, index)
              })}
              <div className="modal-dual-btn">
                <button
                  className="dual-btn negative-btn"
                  onClick={() => {
                    setModelView(false)
                  }}
                  type="submit"
                >
                  CANCEL
                </button>
                <button className="dual-btn positive-btn" type="button" onClick={onCustomSubmit}>
                  {taskId ? 'Update' : 'SAVE'}
                </button>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}
export default CreateTask

import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const updateWizardAddActivity = async (blockerData) => {
  const { data } = await axios.post(`${BASE_URL}/update_wizard_add_activity`, blockerData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export default function useUpdateWizardAddActivity() {
  const queryClient = useQueryClient()

  return useMutation((data) => updateWizardAddActivity(data), {
    onSuccess: () => {
      void queryClient.invalidateQueries('activities-spreadsheet-data')
    },
  })
}

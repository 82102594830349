import React, { useState } from 'react'
import Modal from '../Modal'
import useGetEmployeesObjective from '../../api/query/useGetEmployeesForObjective'
import InPageLoader from '../InPageLoader'
import useGetAllCopiesObjWithEmpAlloc from '../../api/query/useGetAllCopiesObjWithEmpAlloc'
import { useTranslation } from 'react-i18next'

const CoOwnedObjectiveAllocModal = ({
  isModalOpen,
  closeModal,
  objective,
  productSettings = false,
}) => {
  const { t } = useTranslation(['Common'])
  const objId = objective.id
  const {
    data: employeesList,
    isLoading: employeeListIsLoading,
    isFetching: employeeListIsFetching,
  } = useGetAllCopiesObjWithEmpAlloc(objId)
  const [teamMembers, setTeamMembers] = useState(null)

  React.useEffect(() => {
    let teamMembersRaw = []
    for (let teamLeader of employeesList?.teamLeaders ?? []) {
      for (let employee of teamLeader?.employees ?? []) {
        teamMembersRaw.push(employee)
      }
    }
    setTeamMembers(teamMembersRaw)
  }, [employeesList])

  return (
    <>
      <Modal
        title={
          productSettings
            ? `${(objective?.initiativeId ?? 0).toString().padStart(5, '0')} - ${
                objective.statement
              }`
            : `${objective.statement}`
        }
        isModalOpen={isModalOpen}
        closeModal={closeModal}
      >
        <>
          {!employeeListIsLoading && teamMembers ? (
            <table
              className="co-owner_table"
              style={{ borderCollapse: 'collapse', margin: 'auto' }}
            >
              <thead style={{ borderBottom: '1px solid grey' }}>
                <tr style={{ width: 'unset' }}>
                  <th>{t('coOwnedObjectiveAllocModal.team')}</th>
                  <th>{t('coOwnedObjectiveAllocModal.allocation')}</th>
                </tr>
              </thead>
              <tbody>
                {teamMembers?.map((tm, tlIndex) => (
                  <>
                    <tr style={{ width: 'unset' }} key={tm.eId + '0'}>
                      <td>
                        <div style={{ marginBottom: '2rem' }}>{tm?.eName}</div>
                      </td>

                      <td>
                        <div style={{ marginBottom: '2rem' }}>{tm?.allocation}%</div>
                      </td>
                    </tr>
                  </>
                ))}
                <tr key={'total'} style={{ borderBottom: '1px solid grey', width: 'unset' }}>
                  <td></td>
                  <td style={{ color: 'blue', textAlign: 'left' }}>
                    {t('coOwnedObjectiveAllocModal.total')}:&nbsp;{' '}
                    {teamMembers.reduce((sum, { allocation }) => sum + allocation, 0)}%
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <InPageLoader inComponent={true} />
          )}
        </>

        <div className="modal-error-btn">
          <button className="error-btn negative-error-btn" onClick={closeModal} type="button">
            {t('commonButtons.close')}
          </button>
        </div>
      </Modal>
    </>
  )
}

export default CoOwnedObjectiveAllocModal

import ApplicationLayout from '../ApplicationLayout'
import './styles.css'

const Loading = ({ sourceLoginProtected = true }) => {
  return (
    <ApplicationLayout loginProtected={sourceLoginProtected}>
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    </ApplicationLayout>
  )
}

export default Loading

import React, { useState } from 'react'
import Modal from '../../Modal'
import { useTranslation } from 'react-i18next'
import { getFilterViews } from '../../../api/services/filterView.services'
import InPageLoader from '../../InPageLoader'
import { deleteFilterView } from '../../../api/services/filterView.services'
import Toast from '../../../common/toast'

const SelectSavedFilterViewModal = ({ handleClose, reloadBackground }) => {
  const { t } = useTranslation(['Common'])

  const [isLoading, setIsLoading] = useState(false)
  const [filterViewsList, setFilterViewsList] = useState([])

  React.useEffect(() => {
    loadSavedFiltersList()
  }, [])

  async function loadSavedFiltersList() {
    setIsLoading(true)
    let result = await getFilterViews()
    setIsLoading(false)

    if (result && result.filterValues) {
      setFilterViewsList(result.filterValues)
    }
  }

  const deleteFilterViewFunc = async (ruleId) => {
    let formData = {}

    formData = {
      ruleId: ruleId,
    }

    setIsLoading(true)

    try {
      let result = await deleteFilterView(formData)
      if (result.success) {
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.presetDeletedSuccessfully'),
        })
        let tempFilterViewsList = filterViewsList.filter(
          (filterView) => filterView.ruleId !== ruleId,
        )
        setFilterViewsList(tempFilterViewsList)
        reloadBackground()
      } else {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
    setIsLoading(false)
  }

  return (
    <>
      <Modal
        closeModal={() => {
          handleClose()
        }}
        width={'20rem'}
        title={t('Common:filter.editPresets')}
      >
        <div>
          {isLoading ? (
            <InPageLoader inComponent={true} />
          ) : (
            <div>
              {filterViewsList.length > 0 &&
                filterViewsList.map((filterView) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      gap: '1rem',
                      textAlign: 'left',
                      marginBottom: '1rem',
                    }}
                    key={filterView.id}
                  >
                    <div>{filterView.ruleName}</div>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>
                      <div
                        className="fitted-button red"
                        style={{ backgroundColor: 'white' }}
                        onClick={() => {
                          deleteFilterViewFunc(filterView.ruleId)
                        }}
                      >
                        <span className="material-symbols-outlined icon-size">close</span>
                      </div>
                      {/* <div
                    className="fitted-button blue"
                    style={{ backgroundColor: 'white' }}
                    onClick={() => {}}
                  >
                    <span className="material-symbols-outlined icon-size">chevron_right</span>
                  </div> */}
                    </div>
                  </div>
                ))}
              {filterViewsList.length === 0 && (
                <span>{t('Common:filter.thereAreNoSavedPresets')}</span>
              )}
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}

export default SelectSavedFilterViewModal

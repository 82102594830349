import React from 'react'
import Modal from '../Modal'
import { useTranslation } from 'react-i18next'
import { postHttpRequest } from '../../api/query/dynamicAPI'
import { convertTimestampToDate } from '../../utils/time'

const WizardDateModal = ({ closeModal, objId, onSave, meetingTopicData }) => {
  let topic = meetingTopicData?.objectives?.filter((obj) => obj.objId === objId)[0]
  const [showErrorMessage, setShowErrorMessage] = React.useState(false)
  const [startDate, setStartDate] = React.useState(
    typeof topic.startDate == 'string'
      ? topic.startDate
      : topic.startDate == 0
        ? ''
        : convertTimestampToDate(topic.startDate),
  )
  const [dueDate, setDueDate] = React.useState(topic.dueDate)
  const { t } = useTranslation(['Common'])

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value
    setStartDate(newStartDate)
    // Clear error message when user adjusts the start date
    if (dueDate && newStartDate > dueDate) {
      setShowErrorMessage(true)
    } else {
      setShowErrorMessage(false)
    }
  }

  const handleDueDateChange = (event) => {
    const newDueDate = event.target.value
    setDueDate(newDueDate)
    if (startDate && newDueDate < startDate) {
      setShowErrorMessage(true)
    } else {
      setShowErrorMessage(false)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!showErrorMessage) {
      const postObj = {
        objectiveId: objId,
        startDate: startDate,
        dueDate: dueDate,
      }
      await postHttpRequest('/update_objective_date', postObj)
      onSave(objId, startDate, dueDate)
    }
  }

  return (
    <Modal closeModal={closeModal}>
      <form onSubmit={handleSubmit}>
        <div className="form-field action-field form-field action-field--kanban">
          <label>{t('date.startDate')}</label>
          <input
            type="date"
            name="startDate"
            placeholder="yyyy-mm-dd"
            value={startDate}
            onChange={handleStartDateChange}
          />
        </div>
        <div className="form-field action-field form-field action-field--kanban">
          <label>{t('date.dueDate')}</label>
          <input
            type="date"
            name="dueDate"
            placeholder="yyyy-mm-dd"
            value={dueDate}
            onChange={handleDueDateChange}
          />
        </div>
        {showErrorMessage && (
          <div style={{ textAlign: 'center', marginBottom: '15px' }} className="validation-error">
            {t('date.errorMessage')}
          </div>
        )}
        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <button
            type="submit"
            className="sleek-button sleek-blue"
            style={{ cursor: 'pointer', height: '2.2rem', backgroundColor: 'white' }}
          >
            {t('date.save')}
          </button>
        </div>
      </form>
    </Modal>
  )
}

export default WizardDateModal

import React from 'react'
import useGovernanceAlStatusData from '../../api/query/useGovernanceAlCycleStatus'
import { governanceCycleEscalationStatus } from '../../utils/enumValues'
import { CardContainer, CardHeader } from '../../pages/Home/styles'
import InPageLoader from '../InPageLoader'
import { useTranslation } from 'react-i18next'

const GovernanceAreaLeaderStatus = ({ location }) => {
  const { t } = useTranslation(['Dashboard'])
  const { data: governanceStatusData, isLoading: governanceStatusIsLoading } =
    useGovernanceAlStatusData()

  const statusMappings = {
    COMPLETED: t('governanceCard.completed'),
    NOT_COMPLETED: t('governanceCard.notCompleted'),
    CUSTOMIZED_PENDING_REVIEW: t('governanceCard.pendingReview'),
    NOT_APPLICABLE: t('governanceCard.na'),
  }

  const classMappings = {
    COMPLETED: 'good-text',
    NOT_COMPLETED: 'bad-text',
    CUSTOMIZED_PENDING_REVIEW: 'bad-text',
    NOT_APPLICABLE: 'neutral-text',
  }

  const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children

  if (governanceStatusIsLoading) {
    return (
      <ConditionalWrapper
        condition={location === 'Governance'}
        wrapper={(children) => <div style={{ width: '45%' }}>{children} </div>}
      >
        <CardContainer>
          {location === 'Home' && <CardHeader>{t('governanceCard.title')}</CardHeader>}
          <div className="table-wrapper">
            <InPageLoader inComponent={true} />
          </div>
        </CardContainer>
      </ConditionalWrapper>
    )
  }

  return (
    <>
      <ConditionalWrapper
        condition={location === 'Governance'}
        wrapper={(children) => <div style={{ width: '45%' }}>{children} </div>}
      >
        <CardContainer
          style={{
            height: '569px',
            marginTop: '13px',
            justifyContent: 'normal',
            overflowY: 'auto',
            paddingTop: '0rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1rem',
              width: '100%',
              position: 'relative',
              marginTop: '3.4rem',
            }}
          >
            <div className="underline-admin"></div>
          </div>
          {location === 'Home' && <CardHeader>{t('governanceCard.title')}</CardHeader>}
          {/* Uses same proto used for team leaders hence the name remained as teamLeaderStatuses */}
          {governanceStatusData?.teamLeaderStatuses?.length > 0 && (
            <div className="table-wrapper" style={{ paddingTop: '5px' }}>
              <div className={location === 'Home' ? 'widget-grid-6' : 'widget-grid-2'}>
                <div
                  className="widget-grid-title"
                  style={{ justifyContent: 'unset', padding: '.5rem 0.25rem' }}
                >
                  {t('governanceCard.areaLeader')}
                </div>
                <div
                  className="widget-grid-title"
                  style={{ justifyContent: 'unset', padding: '.5rem 0.25rem' }}
                >
                  {t('governanceCard.status')}
                </div>
                {/* <div
                  className="widget-grid-title"
                  style={{ justifyContent: 'unset', padding: '.5rem 0.25rem' }}
                ></div> */}
                {location === 'Home' && (
                  <>
                    <div className="widget-grid-title"></div>
                    <div className="widget-grid-title"></div>
                    <div className="widget-grid-title"></div>
                  </>
                )}
                {!governanceStatusIsLoading &&
                  governanceStatusData?.teamLeaderStatuses?.map((leader, index) => (
                    <React.Fragment key={`governanceStatus-${index}`}>
                      <div
                        className="widget-grid-item"
                        style={{
                          justifyContent: 'unset',
                          padding: '.5rem 0.25rem',
                          wordBreak: 'break-word',
                        }}
                      >
                        {leader?.name}
                      </div>
                      <div
                        className="widget-grid-item"
                        style={{ justifyContent: 'unset', padding: '.5rem 0.25rem' }}
                      >
                        <div className={classMappings[leader?.completionStatus]}>
                          {statusMappings[leader?.completionStatus]}
                        </div>
                      </div>
                      {/* <div
                        className="widget-grid-item"
                        style={{ padding: '0 0.5rem', justifyContent: 'unset' }}
                      >
                      </div> */}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          )}
          {governanceStatusData?.teamLeaderStatuses?.length === 0 && (
            <div>{t('adminDashboard.noALWizard')}</div>
          )}
        </CardContainer>
      </ConditionalWrapper>
    </>
  )
}

export default GovernanceAreaLeaderStatus

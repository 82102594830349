import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

const ChatBox = ({ messageList, isLoading }) => {
  const bottomRef = useRef(null)
  const containerRef = useRef(null)
  const [typingText, setTypingText] = useState('')
  const [typingIndex, setTypingIndex] = useState(-1)
  const speed = 15
  const { t } = useTranslation(['Common'])

  useEffect(() => {
    const container = containerRef.current
    const contentHeight = container.scrollHeight
    const containerHeight = container.clientHeight

    if (contentHeight > containerHeight && bottomRef.current) {
      container.scrollTo({ top: container.scrollHeight, behavior: 'smooth' })
    }
  }, [messageList, isLoading, typingText])

  useEffect(() => {
    if (
      typingIndex >= 0 &&
      typingIndex < messageList?.length &&
      !messageList[typingIndex].user_message
    ) {
      const fullText =
        messageList[typingIndex].assistant_message == ''
          ? t('picasso.tellUsYourNewReqiurement')
          : messageList[typingIndex].assistant_message
      let charIndex = -1
      const timer = setInterval(() => {
        if (charIndex < fullText?.length) {
          charIndex++
          setTypingText((prevText) => prevText + fullText[charIndex])
        } else {
          clearInterval(timer)
          setTypingIndex(-1)
        }
      }, speed)

      return () => clearInterval(timer)
    }
  }, [typingIndex, messageList])

  useEffect(() => {
    if (messageList?.length > 0 && !messageList[messageList?.length - 1]?.user_message) {
      setTypingIndex(messageList.length - 1)
      setTypingText('')
    }
  }, [messageList])

  const [activeDot, setActiveDot] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveDot((prevDot) => (prevDot + 1) % 3)
    }, 300)

    return () => clearInterval(interval)
  }, [])

  const dotStyle = {
    // fontSize: '24px',
    fontWeight: 'bold',
    // color: '#3B82F6', // a shade of blue
    opacity: 0,
    transition: 'opacity 0.2s',
    //margin: '0 2px',
  }

  const activeDotStyle = {
    ...dotStyle,
    opacity: 1,
  }
  return (
    <div className="message-container" ref={containerRef}>
      {messageList?.length > 0 && messageList[0]['user_message'] != '' && (
        <>
          {messageList?.map((messageBubble, index) => {
            if (messageBubble.user_message) {
              return (
                <div key={index} className="message-bubble user-bubble">
                  <span>{messageBubble.user_message}</span>
                </div>
              )
            } else {
              return (
                <div key={index} className="message-bubble bot-bubble">
                  <span>
                    {index === typingIndex
                      ? typingText
                      : messageBubble.assistant_message === ''
                        ? t('picasso.tellUsYourNewReqiurement')
                        : messageBubble.assistant_message}
                  </span>
                </div>
              )
            }
          })}
          <span ref={bottomRef}></span>
          {(isLoading || messageList[0]['user_message'] === '') && (
            <div
              className="message-bubble bot-bubble"
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <div style={{ display: 'flex' }}>
                {[0, 1, 2].map((index) => (
                  <span key={index} style={index === activeDot ? activeDotStyle : dotStyle}>
                    {'\u2022'}
                  </span>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default ChatBox

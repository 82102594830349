import React, { useEffect, useState } from 'react'
import Modal from '../../Modal'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import useGetAllActiveCompanyObj from '../../../api/query/useGetAllActiveCompanyObj'
import { postHttpRequest } from '../../../api/query/dynamicAPI'
import Toast from '../../../common/toast'
import { API_ERROR_MESSAGE } from '../../../api/constant/route'
import { getCompanyConfigSettings } from '../../../utils/auth'

const CapacityAddObjectiveModal = ({ contextHandler }) => {
  const { data: allObjList } = useGetAllActiveCompanyObj({
    isFromDashBoardAndIsRegularTl: false,
    isCapacityAddObjectiveModal: true,
  })
  const [selectedObjective, setSelectedObjective] = useState(null)

  const { t } = useTranslation(['Common'])
  const { state, dispatch } = contextHandler()
  const [objList, setObjList] = useState([])
  const isProductSettingsEnabled = getCompanyConfigSettings('productSettings')

  const handleSelectObjective = (value) => {
    setSelectedObjective(value)
  }

  const handleSave = () => {
    addObjectiveAsShared()
  }

  useEffect(() => {
    if (allObjList) {
      let filteredObjList = allObjList?.objectives.filter(
        (obj) =>
          !state.data.objectives.some((stateObj) => stateObj.id == obj.id) &&
          !obj.isGeneralObjective,
      )
      setObjList(filteredObjList)
    }
  }, [allObjList])

  async function addObjectiveAsShared() {
    const postObj = {}
    postObj.initiativeId = selectedObjective.initiativeId
    postObj.objectiveId = selectedObjective.id
    postObj.teamLeaderId = state?.locationState?.teamLeaderId

    postHttpRequest('/add_shared_rights_to_objective', postObj)
      .then((response) => {
        if (response) {
          let objectives = JSON.parse(response.obj_list)
          let team = JSON.parse(response.obj_alloc)
          const clone = [...state.data.objectives]
          let newObjective = objectives.objectives[0]

          for (let { eId, objId, allocation, eName } of team.objIcMappings) {
            const teamEntry = {
              eId: eId,
              value: allocation,
              name: eName,
              canDelete: false,
            }
            if (newObjective.team) {
              newObjective.team.push(teamEntry)
            } else {
              newObjective.team = [teamEntry]
            }
          }
          clone.push(newObjective)

          dispatch({ type: 'UPDATE_OBJECTIVES', objectives: clone })
          dispatch({ type: 'ALLOC_SUB_WIZ__CLOSE_ADD_OBJECTIVE_MODAL' })

          return
        } else {
          Toast.fire({
            icon: 'error',
            title: API_ERROR_MESSAGE,
          })
          dispatch({ type: 'ALLOC_SUB_WIZ__CLOSE_ADD_OBJECTIVE_MODAL' })
        }
      })
      .catch((error) => {
        Toast.fire({
          icon: 'error',
          title: API_ERROR_MESSAGE,
        })
        dispatch({ type: 'ALLOC_SUB_WIZ__CLOSE_ADD_OBJECTIVE_MODAL' })
      })
  }

  return (
    <>
      <Modal
        title={t('addSharedObjectiveModal.title')}
        closeModal={() => {
          dispatch({ type: 'ALLOC_SUB_WIZ__CLOSE_ADD_OBJECTIVE_MODAL' })
          setSelectedObjective(null)
        }}
      >
        <Select
          maxMenuHeight={200}
          getOptionLabel={(option) =>
            isProductSettingsEnabled && !option?.isGeneralObjective
              ? `${option?.initiativeId.toString()?.padStart(5, '0')} - ${option.statement}`
              : `${!option?.isOriginal ? 'SHARED - ' : ''}${option.statement}`
          }
          getOptionValue={(option) => option.id}
          value={selectedObjective}
          options={objList}
          menuPosition="fixed"
          isClearable={true}
          styles={{
            control: (provided) => ({ ...provided, width: '30rem' }),
            menu: (provided) => ({ ...provided, left: 0, width: '30rem', textAlign: 'left' }),
          }}
          onChange={handleSelectObjective}
        />
        <div className="modal-dual-btn">
          <button className="dual-btn positive-btn" onClick={handleSave} data-testid="save">
            {t('commonButtons.save')}
          </button>
        </div>
      </Modal>
    </>
  )
}
export default CapacityAddObjectiveModal

import _ from 'lodash'
import React from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import useSubmitMilestoneWizard from '../../../../api/mutation/useSubmitMilestoneWizard'
import {
  ButtonContainer,
  CardContainer,
  CardHeader,
  ObjectiveGrid,
  StepCaption,
  StepHeader,
} from '../../styles'
import BlockerModal from './BlockersModal'
import ObjectiveRow from './ObjectiveRow'
import { useUpdateContext } from '../../context/updateContext'
import ResolveBlockerModal from '../../../../components/ResolveBlockerModal'
import { dateToUnix } from '../../../../utils/time'

const UpdateBlockers = () => {
  return <></>
}
export default UpdateBlockers
